import React, { useEffect, useState } from "react";
import { CenterModal } from "../Common/CenterModal/CenterModal";
import { useTranslation } from "react-i18next";
import { WorkspaceResponse } from "../../types/GETTypes";
import { Button } from "../Common/Button/Button";
import useUserStore from "../../stores/useUserStore";
import { InputSelect } from "../Common/InputSelect/InputSelect";
import { ValueType } from "../../types/commonTypes";

export const WorkspaceActionModal: React.FC<{
  action:
    | "payment_mean_of_collection"
    | "message_dynamics_fields"
    | "automated_messages"
    | "operational_management_team";
  isVisible: boolean;
  onClose: () => void;
  onSelectWS: (ws: WorkspaceResponse) => void;
}> = ({ isVisible, onClose, onSelectWS }) => {
  const { t } = useTranslation();
  const { user } = useUserStore();

  const [currentVisible, setCurrentVisible] = useState<boolean>(false);
  const [selectedWSId, setSelectedWSId] = useState<ValueType>(0);

  const handleContinue = () => {
    const nextWS = user?.workspaces?.find((ws) => ws.id === selectedWSId);

    if (!nextWS) return;

    onSelectWS(nextWS);
    handleClose();
  };

  const handleClose = () => {
    setCurrentVisible(false);
    onClose();
  };

  useEffect(() => {
    if (isVisible) {
      // Auto sélection du WS si un seul WS
      if (user?.workspaces?.length === 1) {
        onSelectWS(user?.workspaces[0]);
        handleClose();
      } else {
        setCurrentVisible(true);
      }

      setSelectedWSId(user?.workspaces[0].id ?? 0);
    }
  }, [isVisible]);

  const handleSelect = (nextValue: ValueType | undefined) => {
    if (!nextValue) return;

    setSelectedWSId(nextValue);
  };

  return (
    <CenterModal
      isVisible={currentVisible}
      onClose={handleClose}
      title={t("WorkspaceActionModal.title")}
    >
      <p className="text-low-contrast font-light text-sm mb-3">
        {t("WorkspaceActionModal.description")}
      </p>

      <InputSelect
        items={
          user?.workspaces?.map((ws) => {
            return { label: ws.name, value: ws.id };
          }) ?? []
        }
        selectedValue={selectedWSId}
        onSelect={handleSelect}
      />

      <div className="flex gap-3 mt-4">
        <Button type="secondary" onClick={handleClose}>
          {t("Global.cancel")}
        </Button>
        <Button onClick={handleContinue}>
          {t("WorkspaceActionModal.continue")}
        </Button>
      </div>
    </CenterModal>
  );
};
