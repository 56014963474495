import React, {useState} from "react";
import {Button} from "../../../../../Common/Button/Button";
import {useTranslation} from "react-i18next";
import useGuestWebsiteStore from "../../../../../../stores/guestWebsiteStore/useGuestWebsiteStore";
import ArrowLeftIcon from "../../../../../../assets/icons/arrow-left.svg?react";
import TitleBlockComponent from "../../Common/TitleBlockComponent";
import PaymentFormWebsite from "../../Common/PaymentFormWebsite";
import ExtrasOrderResumeCard from "./ExtrasOrderResumeCard";
import CheckCircleGreenIcon from "../../../../../../assets/icons/check-circle-green.svg?react";
import {
  PaymentReservationStatusEnum,
  ReservationExtraOrderStatusEnum,
} from "../../../../../../enums/GETenums";
import {GuestWebsiteStep5ExtrasResponse} from "../../../../../../types/GETTypes";
import {getFormattedPrice} from "../../../../../../helpers/currencyHelper";

interface RentalDetailsExtrasPaymentPageProps {
  loading: boolean;
}

export interface ReservationStep5SendDataForm {
  reservation_id: string;
  ordered_extras_ids: string;
  ordered_extras_quantities: string;
  email: string;
  stripe_confirmation_token: string;
}

const RentalDetailsExtrasPaymentPage =
  ({}: RentalDetailsExtrasPaymentPageProps) => {
    const {t} = useTranslation();
    const {
      setRentalDetailTab,
      extrasOrder,
      rentalData,
      step4Data,
      step3Data,
      setStep5Data: setStep5DataStore,
      dataGuestWebsite,
    } = useGuestWebsiteStore();
    const {
      total_price_value,
      stripe_account_id,
      stripe_client_secret,
      extras_order_confirmation_enabled,
    } = step4Data || {};
    const {guest} = step3Data || {};
    const {styles} = dataGuestWebsite || {};
    const {color_background, color_text} = styles || {};
    const extras = rentalData?.guest_page?.extras_available;

    const [openSuccess, setOpenSuccess] = useState(false);

    const onSuccessPayment = async (
      status:
        | PaymentReservationStatusEnum
        | ReservationExtraOrderStatusEnum
        | undefined,
      response?: any,
      confirmationToken?: string
    ) => {
      const res = response as GuestWebsiteStep5ExtrasResponse;
      if (res) {
        setStep5DataStore(res);
      }
      setOpenSuccess(true);
    };

    const orderedExtras = extras
      ?.filter((extra) => extrasOrder?.some((extr) => extr.id === extra.id))
      .map((extra) => {
        const extraOrder = extrasOrder?.find((extr) => extr.id === extra.id);
        return {
          ...extra,
          quantity: extraOrder?.quantity ?? 0,
        };
      });

    return (
      <div
        className="flex items-center justify-center h-full"
        style={{backgroundColor: color_background}}
      >
        <div className="p-4 md:px-contentWebsite w-full">
          {openSuccess ? (
            <div className="flex items-center p-4 py-6 rounded-lg bg-success/10 space-x-4">
              <div className="flex items-center">
                <CheckCircleGreenIcon width={40} height={40} />
              </div>
              <div className="flex flex-col space-y-2">
                <h3 className="text-base font-bold text-success">
                  {t("GuestWebsite.Rental.ExtrasPayment.congratsBlock")}
                </h3>
                <p className="font-light text-success text-xs space-y-2">
                  {t("GuestWebsite.Rental.ExtrasPayment.thanksBlock", {
                    rentalName: rentalData?.name,
                  })}{" "}
                  <br />
                  {t("GuestWebsite.Rental.ExtrasPayment.infoByEmail")}
                </p>
              </div>
            </div>
          ) : (
            <>
              <Button
                LeftIcon={ArrowLeftIcon}
                type="secondary"
                displayLargeBtn={false}
                className=""
                buttonClassName="md:h-12"
                textClassName="md:text-xl"
                onClick={() => setRentalDetailTab("extras")}
                buttonStyleCss={{color: color_text}}
              >
                {t("GuestPage.ExtrasPayment.goBack")}
              </Button>
              <div className="md:flex md:flex-row p-6 space-y-6 md:space-y-0 pl-2 md:gap-6">
                <div className="w-full md:min-w-1/2 order-1 ">
                  <div className="rounded-lg shadow-lg p-6 space-y-4 bg-white">
                    {/* Header */}
                    <TitleBlockComponent
                      title={t("GuestWebsite.Rental.ExtrasPayment.titleBlock")}
                    />
                    {orderedExtras?.map((extrasOrder) => (
                      <ExtrasOrderResumeCard {...extrasOrder} />
                    ))}
                    <div className="flex items-center justify-between">
                      <span
                        className="font-bold text-lg"
                        style={{color: color_text}}
                      >
                        {t("Global.total")}
                      </span>
                      <span
                        className="font-bold text-xxxl"
                        style={{color: color_text}}
                      >
                        {total_price_value} €
                      </span>
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-[65%] order-2">
                  <PaymentFormWebsite
                    from="websiteExtrasPaymentForm"
                    onSuccess={onSuccessPayment}
                    guest={guest}
                    stripe_account_id={stripe_account_id}
                    stripe_client_secret={stripe_client_secret}
                    amount={
                      getFormattedPrice({
                        price: Number(total_price_value),
                        decimals: 2,
                        withCurrency: false,
                      }) ?? 0
                    }
                    isPreAuth={Boolean(extras_order_confirmation_enabled)}
                    data={step4Data}
                    
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

export default RentalDetailsExtrasPaymentPage;
