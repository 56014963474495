import React, { Dispatch, SetStateAction } from "react";
import { PageMenu } from "../../../Common/PageMenu/PageMenu";

import KeyIcon from "../../../../assets/icons/key.svg?react";
import KeyHighIcon from "../../../../assets/icons/key-high.svg?react";
import PricelabsIcon from "../../../../assets/icons/pricelabs.svg?react";

export const RentalServicesMenu = ({
  anchor,
  setAnchor,
}: {
  anchor: string;
  setAnchor: Dispatch<SetStateAction<string>>;
}) => {
  return (
    <PageMenu
      anchor={anchor}
      setAnchor={setAnchor}
      items={[
        {
          Icon: KeyIcon,
          IconActive: KeyHighIcon,
          anchor: "smartlocks-card",
          labelI18n: "Rental.Services.Smartlocks.title",
        },
        {
          Icon: PricelabsIcon,
          IconActive: PricelabsIcon,
          anchor: "pricelabs-card",
          labelI18n: "Rental.Services.Pricelabs.title",
        },
      ]}
    />
  );
};
