import React, {useEffect, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {Card} from "../../../Common/Card/Card";
import {ErrorMessage} from "../../../Common/ErrorMessage/ErrorMessage";
import {PaymentExtraProps} from "../Contact/ContactCard.type";
import {ContractCardSkeleton} from "./ContractCardSkeleton";
import CheckIcon from "../../../../assets/icons/check-white.svg?react";
import EditIcon from "../../../../assets/icons/edit.svg?react";
import {Controller, useForm} from "react-hook-form";
import ArrowLeftIcon from "../../../../assets/icons/arrow-left.svg?react";
import {TextAreaInput} from "../../../Common/TextAreaInput/TextAreaInput";
import {WifiForm} from "../Instructions/InstructionCard.type";
import {
  useUpdateEnableOrDisableContract,
  useUpdateWifi,
} from "../../../../hooks/api/guestPage";
import {TextInput} from "../../../Common/TextInput/TextInput";
import {useCheckAuth} from "../../../../hooks/useCheckAuth";
import {capitalizeFirstLetter} from "../../../../helpers/stringHelper";
import ScrollIcon from "../../../../assets/icons/scroll.svg?react";
import {getCurrentCanEdit} from "../../../../helpers/workspaceHelper";
import {
  ContractsResponse,
  RentalGuestPageResponse,
  RentalLightListItemResponse,
  RentalResponse,
  UserResponse,
} from "../../../../types/GETTypes";
import {ValueType} from "recharts/types/component/DefaultTooltipContent";

export interface ContractCardProps {
  loading: boolean;
  rental: RentalResponse;
  rentals: RentalLightListItemResponse[] | undefined;
  guestPage: RentalGuestPageResponse;
  workspaceOwner: ValueType;
  user: UserResponse | undefined;
  contracts: ContractsResponse[] | undefined;
}
export const ContractCard: React.FC<ContractCardProps> = ({
  loading,
  guestPage,
  rental,
  workspaceOwner,
  contracts,
}) => {
  const {user} = useCheckAuth();
  const {t} = useTranslation();
  const [validationLoading, setValidationLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [activeContract, setActiveContract] = useState<boolean>(
    guestPage?.contract_enabled === 1 ? true : false
  );
  const defaultContract = contracts?.find((contract) => contract?.is_default);

  const initSubmit = () => {
    setError("");
    setValidationLoading(true);
  };

  const handleEnableOrDisableContract = () => {
    const status = !activeContract;
    setActiveContract(status);
    setValidationLoading(true);

    useUpdateEnableOrDisableContract(
      Number(rental?.id),
      {status},
      (result) => {
        setValidationLoading(false);
      },
      () => {
        setActiveContract(!status);
        setValidationLoading(false);
      }
    );
  };

  useEffect(() => {}, [user]);


  if (loading) return <ContractCardSkeleton />;
  return (
    <Card
      loading={validationLoading}
      Icon={ScrollIcon}
      label={t("Rental.Infos.TravelerPage.ContractCard.title", {
        contractName: defaultContract?.name,
      })}
      anchor="contract-card"
      switchButton={{
        label: t("Rental.Infos.TravelerPage.ContractCard.switch"),
        value: activeContract,
        loading: validationLoading,
        disabled: !getCurrentCanEdit({
          user,
          workspaceOwner: String(workspaceOwner),
        }),
        onClick: () => {
          handleEnableOrDisableContract();
        },
      }}
    >
      <div className="flex flex-col gap-y-4">
        {error ? <ErrorMessage>{error}</ErrorMessage> : null}
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-y-2 text-low-contrast">
              <Trans i18nKey={"Rental.Infos.TravelerPage.ContractCard.info1"} className="text-low-contrast"/>

              <p className="text-low-contrast">
                {t("Rental.Infos.TravelerPage.ContractCard.info2")}
              </p>
              <p className="text-low-contrast">
                {t("Rental.Infos.TravelerPage.ContractCard.info3")}
              </p>
              <p className="text-low-contrast">
                {t("Rental.Infos.TravelerPage.ContractCard.info4")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};
