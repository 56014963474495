import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import KeyIcon from "../../../assets/icons/key.svg?react";
import { Card } from "../../Common/Card/Card";
import { WebsiteResponse } from "../../../types/GETTypes";
import { useTranslation } from "react-i18next";
import { useToggleCard } from "./useToggleCard";
import paths from "../../../constants/paths";
import { ValueType } from "recharts/types/component/DefaultTooltipContent";
import CheckIcon from "../../../assets/icons/check-white.svg?react";
import EditIcon from "../../../assets/icons/edit.svg?react";
import TrashIcon from "../../../assets/icons/trash.svg?react";
import ArrowLeftIcon from "../../../assets/icons/arrow-left.svg?react";
import PlusIcon from "../../../assets/icons/plus.svg?react";
import MinusIcon from "../../../assets/icons/minus.svg?react";
import PhotoIcon from "../../../assets/icons/photo.svg?react";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import { Button } from "../../Common/Button/Button";
import { TextInput } from "../../Common/TextInput/TextInput";
import { TextAreaInput } from "../../Common/TextAreaInput/TextAreaInput";
import { post } from "../../../helpers/APIHelper";

export const WebsiteServicesCard: React.FC<{
  website: WebsiteResponse | undefined;
  onUpdateWebsite: (nextWebsite: WebsiteResponse) => void;
}> = ({ website, onUpdateWebsite }) => {
  const { t } = useTranslation();

  const { isEnabled, isEnabledLoading, toggle } = useToggleCard({
    website,
    initialValue: website?.content.services.enabled,
    baseUrl: `${
      import.meta.env.VITE_API_URL
    }${paths.API.WEBSITE.UPDATE.SERVICE_TOGGLE(website?.id!)}`,
  });

  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const [updateError, setUpdateError] = useState<string | undefined>();

  const [editMode, setEditMode] = useState<boolean>(false);

  const editModeRef = useRef<{
    handleClear: () => void;
    handleCheckData: () => {
      title: string;
      subTitle: string;
      cards: CardType[];
    } | null;
  } | null>(null);

  const handleCancel = () => {
    setEditMode(false);
    editModeRef.current?.handleClear();
  };

  const handleUpdate = async () => {
    const values = editModeRef.current?.handleCheckData();

    console.log("values", values);

    if (!values) return;

    setUpdateLoading(true);
    setUpdateError(undefined);

    const formData = new FormData();
    formData.append("services_section_title", values.title);
    formData.append("services_section_subtitle", values.subTitle);

    values.cards?.forEach((c, index) => {
      if (c.isDeleted) formData.append(`del_ids[${index}]`, c.id.toString());
    });

    formData.append(
      "cards",
      JSON.stringify(
        values.cards
          .filter((c) => c.isDeleted === false && c.isNew)
          .map((c) => ({ name: c.title, description: c.text }))
      )
    );

    values.cards
      .filter((c) => c.isDeleted === false && c.isNew)
      .forEach((c, index) => {
        if (c.photoBlob !== undefined) {
          formData.append(`card_photos[${index}]`, c.photoBlob);
        }
      });

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.WEBSITE.UPDATE.SERVICE(
        website?.id!
      )}`,
      formData
    );

    if (!res?.data?.success) {
      setUpdateError(res?.response?.data?.message);
    } else {
      onUpdateWebsite(res.data?.result);
      setEditMode(false);
    }

    setUpdateLoading(false);
  };

  useEffect(() => {
    setUpdateError(undefined);
  }, [editMode]);

  return (
    <Card
      Icon={KeyIcon}
      label={t("Website.Services.title")}
      anchor="services-card"
      switchButton={{
        value: isEnabled,
        label: "",
        loading: isEnabledLoading,
        onClick: toggle,
      }}
      button={{
        Icon: editMode ? CheckIcon : EditIcon,
        type: editMode ? "primary" : "secondary",
        label: editMode ? t("Global.record") : t("Global.edit"),
        onClick: editMode ? () => handleUpdate() : () => setEditMode(!editMode),
        disabled: !website || updateLoading,
      }}
      secondaryButton={
        editMode
          ? {
              label: t("Global.cancel"),
              LeftIcon: ArrowLeftIcon,
              onClick: handleCancel,
              disabled: updateLoading,
            }
          : undefined
      }
    >
      <ErrorMessage>{updateError}</ErrorMessage>

      {editMode ? (
        <EditMode ref={editModeRef} website={website} loading={updateLoading} />
      ) : (
        <ViewMode website={website} />
      )}
    </Card>
  );
};

const ViewMode: React.FC<{ website: WebsiteResponse | undefined }> = ({
  website,
}) => {
  if (!website) return <ViewModeSkeleton />;

  return (
    <div className="flex flex-col gap-3">
      <p className="text-center text-base font-semibold text-high-contrast">
        {website?.content.services.title}
      </p>

      <p className="text-center font-light text-low-contrast text-xs">
        {website?.content.services.subtitle}
      </p>

      <div className="grid grid-cols-3 flex-wrap gap-3 w-full">
        {website?.content.services.cards.map((c) => (
          <div className="border-1 border-element-border rounded-6px bg-element-background p-2 flex flex-col gap-2">
            <div className="flex gap-2 items-center justify-center">
              {c.logo && (
                <img
                  className="rounded-6px border-1 border-element-border size-16 shrink-0"
                  src={c.logo}
                />
              )}

              <p className="font-semibold text-high-contrast">{c.name}</p>
            </div>

            <p className="font-ligh text-low-contrast">{c.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

const ViewModeSkeleton: React.FC<{}> = ({}) => {
  return (
    <div className="flex flex-col gap-3 items-center">
      <p className="text-center text-base font-semibold text-high-contrast h-2 bg-slate-300 w-72 rounded"></p>

      <p className="text-center font-light text-low-contrast text-xs h-2 bg-slate-200 w-52 rounded mt-2"></p>

      <div className="grid grid-cols-3 flex-wrap gap-3 w-full">
        {Array.from({ length: 6 }).map((c) => (
          <div className="border-1 border-element-border rounded-6px bg-element-background p-2 flex flex-col gap-2">
            <div className="flex gap-2 items-center justify-center">
              <div className="rounded-6px border-1 border-element-border size-16 shrink-0" />

              <p className="font-semibold text-high-contrast h-2 bg-slate-300 w-1/3 rounded"></p>
            </div>

            <p className="font-ligh text-low-contrast h-2 bg-slate-200 w-72 rounded"></p>
            <p className="font-ligh text-low-contrast h-2 bg-slate-200 w-72 rounded mt-2"></p>
          </div>
        ))}
      </div>
    </div>
  );
};

interface EditModeProps {
  website: WebsiteResponse | undefined;
  loading: boolean;
}

type CardType = {
  id: ValueType;
  title: string;
  text: string;
  photoBlob: Blob | undefined;
  photoUrl: string | undefined;
  isDeleted: boolean;
  isNew?: boolean;
  error: string | undefined;
};

const EditMode = forwardRef<
  {
    handleClear: () => void;
    handleCheckData: () => {
      title: string;
      subTitle: string;
      cards: CardType[];
    } | null;
  },
  EditModeProps
>(({ website, loading }, ref) => {
  const { t } = useTranslation();

  const [title, setTitle] = useState<string>("");
  const [titleError, setTitleError] = useState<string | undefined>();

  const [subTitle, setSubTitle] = useState<string>("");

  const [cards, setCards] = useState<CardType[]>([]);

  const handleClear = () => {};

  const handleCheckData = () => {
    let isError = false;

    if (!title) {
      setTitleError(t("Global.required"));
      isError = true;
    }

    cards
      .filter((c) => c.isDeleted === false)
      .forEach((c) => {
        if (c.error) {
          isError = true;
        }
      });

    if (isError) return null;

    return {
      title,
      subTitle,
      cards,
    };
  };

  useImperativeHandle(ref, () => ({
    handleClear,
    handleCheckData,
  }));

  useEffect(() => {
    if (website) {
      setTitle(website?.content.services.title);
      setTitleError(undefined);

      setSubTitle(website?.content.services.subtitle);

      setCards(
        website?.content.services.cards.map((t) => ({
          id: t.id,
          title: t.name,
          text: t.description,
          photoBlob: undefined,
          photoUrl: t.logo,
          isDeleted: false,
          error: undefined,
        }))
      );
    }
  }, [website]);

  // Title and sub title
  const handleChangeTitle = (text: string) => {
    setTitle(text);

    setTitleError(undefined);
  };

  const handleChangeSubTitle = (text: string) => {
    setSubTitle(text);
  };

  // Cards
  const handleChangeCard = (nextCard: CardType) => {
    setCards((prev) => prev.map((c) => (c.id === nextCard.id ? nextCard : c)));
  };

  const handleAddCard = () => {
    setCards((prev) => [
      ...prev,
      {
        id: new Date().toString(),
        title: "",
        text: "",
        photoBlob: undefined,
        photoUrl: undefined,
        error: t("Website.Services.required"),
        isDeleted: false,
        isNew: true,
      },
    ]);
  };

  return (
    <div className="flex flex-col gap-3">
      <TextInput
        label={t("Website.Services.titleLabel")}
        value={title}
        onChangeText={handleChangeTitle}
        error={titleError}
        required={true}
        disabled={loading}
      />

      <TextInput
        label={t("Website.Services.subTitleLabel")}
        value={subTitle}
        onChangeText={handleChangeSubTitle}
        disabled={loading}
      />

      {cards
        .filter((c) => c.isDeleted === false)
        .map((c, index) => (
          <CardItem
            key={c.id?.toString() ?? index}
            card={c}
            loading={loading}
            onChange={(value) => handleChangeCard(value)}
          />
        ))}

      <Button type="secondary" RightIcon={PlusIcon} onClick={handleAddCard}>
        {t("Website.Services.addCard")}
      </Button>
    </div>
  );
});

const CardItem: React.FC<{
  card: CardType;
  loading: boolean;
  onChange: (value: CardType) => void;
}> = ({ card, loading, onChange }) => {
  const { t } = useTranslation();

  const [title, setTitle] = useState<string>(card.title);

  const [text, setText] = useState<string>(card.text);

  const [error, setError] = useState<string | undefined>(card.error);

  // Logo
  const [photo, setPhoto] = useState<Blob>();
  const [photoURL, setPhotoURL] = useState<string | undefined>(
    card.photoUrl ?? ""
  );

  const photoInputRef = useRef<HTMLInputElement>(null);
  const handleAddPhoto = () => {
    photoInputRef.current?.click();
  };

  const handleAddPhotoFromInput = (event: any) => {
    if (event.target.files?.length === 0) return;

    setPhoto(event.target.files[0]);

    onChange({ ...card, photoBlob: event.target.files[0] });
  };

  const handleRemovePhoto = () => {
    setPhoto(undefined);
    setPhotoURL(undefined);

    onChange({ ...card, photoBlob: undefined, photoUrl: undefined });
  };

  const handleChangeTitle = (value: string) => {
    let nextError = undefined;

    setError(undefined);

    setTitle(value);

    if (value === "") {
      nextError = t("Website.Services.required");
    }

    setError(nextError);
    onChange({ ...card, title: value, error: nextError });
  };

  const handleChangeText = (value: string) => {
    let nextError = undefined;

    setError(undefined);

    setText(value);

    if (value === "") {
      nextError = t("Website.Services.required");
    }

    setError(nextError);
    onChange({ ...card, text: value, error: nextError });
  };

  return (
    <div className="flex flex-col gap-2 p-4 border-1 border-element-border rounded-6px bg-element-background">
      <div className="flex items-end gap-3 relative">
        <div className="flex flex-1 items-center gap-3">
          <div className="relative w-40 flex flex-col gap-4 mt-2">
            <input
              type="file"
              multiple={true}
              style={{ display: "none" }}
              ref={photoInputRef}
              accept="image/jpeg, image/png"
              onChange={handleAddPhotoFromInput}
            />

            {photo || photoURL ? (
              <div className="w-full h-full group">
                {card.isNew && (
                  <div
                    className="absolute items-center justify-center hidden w-6 h-6 rounded-sm cursor-pointer border-element-border border-1 group-hover:flex bg-white"
                    style={{ top: -6, right: -6 }}
                    onClick={handleRemovePhoto}
                  >
                    <MinusIcon width={20} height={20} />
                  </div>
                )}

                <div className="flex flex-col items-center justify-center w-full h-40 gap-2 overflow-hidden border-dashed bg-element-background border-1 border-element-border rounded-6px hover:bg-element-background-active">
                  <img
                    src={photo ? URL.createObjectURL(photo) : photoURL}
                    className="object-cover w-full h-full"
                  />
                </div>
              </div>
            ) : (
              <div
                className="flex flex-col items-center justify-center w-full h-full gap-2 p-4 border-dashed cursor-pointer bg-element-background border-1 border-element-border rounded-6px hover:bg-element-background-active"
                onClick={handleAddPhoto}
              >
                <PhotoIcon className="w-10 h-10" />
                <p className="font-semibold text-high-contrast text-center">
                  {t("Website.MoreInfos2.addPhoto")}
                </p>
              </div>
            )}
          </div>

          <div className="w-full">
            <TextInput
              label={t("Website.Services.titleCardLabel")}
              value={title}
              onChangeText={handleChangeTitle}
              required={true}
              disabled={loading || !card.isNew}
              size="large"
            />
          </div>
        </div>

        <div className="w-12 absolute top-0 right-0">
          <Button
            type="secondary"
            size="small"
            onClick={() =>
              onChange({
                id: card.id,
                title,
                text,
                photoBlob: photo,
                photoUrl: photoURL,
                isDeleted: true,
                error,
              })
            }
          >
            <TrashIcon />
          </Button>
        </div>
      </div>

      <TextAreaInput
        label={t("Website.Services.textCardLabel")}
        value={text}
        onTextChange={handleChangeText}
        required={true}
        disabled={loading || !card.isNew}
      />

      <ErrorMessage errorType="FORM">{error}</ErrorMessage>
    </div>
  );
};
