import React from "react";
import {useTranslation} from "react-i18next";
import useGuestWebsiteStore from "../../../../stores/guestWebsiteStore/useGuestWebsiteStore";
import VisaImg from "../../../../assets/icons/visa_guest_website.svg?react";
import MasterCardImg from "../../../../assets/icons/mastercard_guest_website.svg?react";
import DiscoverImg from "../../../../assets/icons/discover_guest_website.svg?react";
import AmexImg from "../../../../assets/icons/visa_guest_website.svg?react";
import LockIcon from "../../../../assets/icons/lock-white.svg";
import ReservationBannerComponent from "./ReservationBannerComponent";
import {cn} from "../../../../helpers/classHelper";
export const FooterTemplate2 = () => {
  const {t} = useTranslation();
  const {dataGuestWebsite, setTabGuestWebsite, tabGuestWebsite} =
    useGuestWebsiteStore();
  const {content} = dataGuestWebsite || {};
  const {logo, background} = content?.general || {};
  const {enabled: showServices} = content?.services || {};
  const {enabled: showAbout} = content?.about || {};
  const {enabled: showContact} = content?.contact || {};
  return (
    <div className="relative">
      <footer
        style={{
          backgroundImage: `linear-gradient(180deg, rgba(21, 21, 21, 0.8), rgba(21, 21, 21, 0.9)), url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="flex flex-col p-4 text-white w-full"
      >
        <div
          className={cn(
            "w-full md:max-w-4xl mx-auto pt-28 md:pt-20 mb-8",
            (tabGuestWebsite === "rentals" || tabGuestWebsite === "rental") &&
              "pt-0"
          )}
        >
          <div className=" flex md:flex-row flex-col gap-8 justify-center">
            {/* Logo and About Section */}
            <div
              className="flex w-full md:w-1/3 cursor-pointer justify-center md:justify-start"
              onClick={() => setTabGuestWebsite("home")}
            >
              <img
                src={logo}
                alt="Le Super Template Logo"
                className="w-40 h-14 md:w-44 md:h-16 mb-4 object-fill "
              />
            </div>

            {/* Navigation Links */}
            <div className="flex w-full md:w-1/3 md:flex-col md:space-y-4 justify-between md:justify-start md:gap-y-8 p-4 md:p-0">
              <div className="flex flex-col">
                <h3 className="text-lg md:text-lg font-semibold mb-2">
                  {t("GuestWebsite.about")}
                </h3>
                <ul className="space-y-2">
                  <li>
                    <a
                      onClick={() => setTabGuestWebsite("rentals")}
                      className="text-sm md:text-base hover:underline cursor-pointer"
                    >
                      {t("GuestWebsite.Footer.Nav.rentals")}
                    </a>
                  </li>
                  {showServices && (
                    <li>
                      <a
                        onClick={() => setTabGuestWebsite("services")}
                        className="text-sm md:text-base hover:underline cursor-pointer"
                      >
                        {t("GuestWebsite.Footer.Nav.services")}
                      </a>
                    </li>
                  )}
                  {showAbout && (
                    <li>
                      <a
                        onClick={() => setTabGuestWebsite("about")}
                        className="text-sm md:text-base hover:underline cursor-pointer"
                      >
                        {t("GuestWebsite.Footer.Nav.about")}
                      </a>
                    </li>
                  )}
                  {showContact && (
                    <li>
                      <a
                        onClick={() => setTabGuestWebsite("contact")}
                        className="text-sm md:text-base hover:underline cursor-pointer"
                      >
                        {t("GuestWebsite.Footer.Nav.contact")}
                      </a>
                    </li>
                  )}
                </ul>
              </div>
              <div className="flex flex-col">
                <h3 className="text-lg font-semibold mb-2">
                  {t("GuestWebsite.Footer.Link.title")}
                </h3>
                <ul className="space-y-2">
                  <li>
                    <a
                      className="text-sm md:text-base hover:underline cursor-pointer"
                      onClick={() => setTabGuestWebsite("mentions")}
                    >
                      {t("GuestWebsite.Footer.Link.mention")}
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-sm md:text-base hover:underline cursor-pointer"
                      onClick={() => setTabGuestWebsite("conditions")}
                    >
                      {t("GuestWebsite.Footer.Link.conditions")}
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-sm md:text-base hover:underline cursor-pointer"
                      onClick={() => setTabGuestWebsite("privacy")}
                    >
                      {t("GuestWebsite.Footer.Link.privacy")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            {/* Payment and Security Section */}
            <div className="flex flex-col w-full md:w-2/4 p-4 md:p-0">
              <h3 className="text-lg font-semibold mb-4">
                {t("GuestWebsite.Footer.Payment.title")}
              </h3>
              <div className="flex space-x-4 mb-4">
                <VisaImg className="w-24 h-16 md:w-auto md:h-8" />
                <MasterCardImg className="w-24 h-16 md:w-auto md:h-8" />
                <DiscoverImg className="w-24 h-16 md:w-auto md:h-8" />
                <AmexImg className="w-24 h-16 md:w-auto md:h-8" />
              </div>
              <div className="flex flex-row md:items-start space-x-2">
                <img src={LockIcon} className="mt-1" />
                <p className="text-sm flex flex-col space-y-3">
                  <span className="font-bold text-base">
                    {t("GuestWebsite.Footer.Payment.descriptionTitle")}
                  </span>
                  <span className="text-xs">
                    {t("GuestWebsite.Footer.Payment.description")}
                  </span>
                </p>
              </div>
            </div>
          </div>

          {/* Footer Bottom */}
          <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 mt-8 border-t border-gray-700 pt-8 md:pt-4 justify-between items-center">
            <p className="text-sm">{t("GuestWebsite.Footer.copyright")}</p>
            <div className="text-center text-xs items-center">
              {t("GuestWebsite.Footer.templateBy")}{" "}
              <span className="text-[#CD2B31] text-xs mr-1">❤️️</span>
              {t("GuestWebsite.Footer.bySuperhote")}
            </div>
          </div>
        </div>
      </footer>
      <ReservationBannerComponent />
    </div>
  );
};
