import React from 'react';
import {TFunction} from "i18next";
import {ResourceResponse} from "../components/Common/CardDocs/CardDocs.type";
import {Badge} from '../components/Common/Badge/Badge';
import CheckCircleGreenIcon from "../assets/icons/check-circle-green.svg?react";
import ClockWarningIcon from "../assets/icons/clock-warning.svg?react";
import CloseCircleRedIcon from "../assets/icons/close-circle-red.svg?react";
import {ContractResponse} from '../types/GETTypes';

export const getContractStatusBadge = (
  resource: ResourceResponse,
  t: TFunction<"translation", undefined>
) => {
  const isSigned = "is_signed" in resource ? resource.is_signed : false;

  switch (isSigned) {
    case true:
      return (
        <Badge
          Icon={CheckCircleGreenIcon}
          status="success"
          label={t("Rental.Contracts.Contracts.signed")}
        />
      );

    case false:
      return (
        <Badge
          Icon={CloseCircleRedIcon}
          status="fail"
          label={t("Rental.Contracts.Contracts.no_signed")}
        />
      );

    default:
      return (
        <Badge
          Icon={CloseCircleRedIcon}
          status="fail"
          label={t("Rental.Contracts.Contracts.no_signed")}
        />
      );
  }
};

export const checkAllContractsSigned = (contracts:ContractResponse[]) => {
  return contracts?.every((contract) => contract?.is_signed);
}