import React from "react";
import { useTranslation } from "react-i18next";
import { ProgressionStep } from "../../../../pages/Progression/ProgressionStep";
import { Button } from "../../../Common/Button/Button";
import { ProgressionStepConfigDirectReservationsProps } from "../../Progression.type";

export const ProgressionStepConfigDirectReservations: React.FC<
  ProgressionStepConfigDirectReservationsProps
> = ({
  status,
  number,
  visible,
  loading,
  error,
  onOpenConfigDirectReservationsModal,
  onNoOffPlatformReservations,
}) => {
  const { t } = useTranslation();

  if (!visible) return null;

  return (
    <ProgressionStep
      number={number.toString()}
      title={t("Progression.StepConfigDirectReservations.title")}
      status={status}
      error={error}
      loading={loading}
    >
      <div className="flex flex-col space-y-3">
        <p className="text-base font-light text-low-contrast text-bold">
          {t("Progression.StepConfigDirectReservations.content1")}
        </p>

        <p className="text-xs font-light text-low-contrast">
          {t("Progression.StepConfigDirectReservations.content2")}
        </p>

        <div className="flex justify-between space-x-3">
          <div className="flex-1">
            <Button
              type="secondary"
              onClick={onNoOffPlatformReservations}
              divParentClassName="w-max"
            >
              {t(
                "Progression.StepConfigDirectReservations.noPlatformReservation"
              )}
            </Button>
          </div>
          <div className="flex-1">
            <Button
              type="primary"
              onClick={onOpenConfigDirectReservationsModal}
            >
              {t("Global.configurate")}
            </Button>
          </div>
        </div>
      </div>
    </ProgressionStep>
  );
};
