import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {get, post} from "../../../../helpers/APIHelper";
import paths from "../../../../constants/paths";
import {debounce} from "lodash";
import {TextInput} from "../../../Common/TextInput/TextInput";
import {Button} from "../../../Common/Button/Button";
import SearchIcon from "../../../../assets/icons/search.svg?react";
import ArrowLeftIcon from "../../../../assets/icons/arrow-left.svg?react";
import ArrowRightIcon from "../../../../assets/icons/arrow-right.svg?react";
import {BookingConnectRentalLine} from "./BookingConnectRentalLine";
import {BookingConnectRentalLineSkeleton} from "./BookingConnectRentalLineSkeleton";
import {
  RentalLightBookingItemResponse,
  RentalLightListItemResponse,
} from "../../../../types/GETTypes";
import BookingConnectRentalCheckbox from "./BookingConnectRentalCheckbox";
import {ValueType} from "../../../../types/commonTypes";
import ArrowRight from "../../../../assets/icons/arrow-right-white.svg?react";
import {ErrorMessage} from "../../../Common/ErrorMessage/ErrorMessage";
import {BookingConnectData} from "./BookingConnectModal";
import {BookingConnectModalPurpose} from "./BookingConnectModal.type";

type BookingConnect2Props = {
  properties: RentalLightBookingItemResponse[] | undefined;
  onNext: () => void;
  setData: React.Dispatch<React.SetStateAction<BookingConnectData | undefined>>;
};

export const BookingConnectStep2: React.FC<BookingConnect2Props> = ({
  properties,
  onNext,
  setData,
}) => {
  const {t} = useTranslation();
  const [propertiesSelected, setpropertiesSelected] = useState<ValueType[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  const [error, setError] = useState("");

  const onCheck = (rentalId: ValueType, value: boolean) => {
    let properties: ValueType[] = [];
    const existed = propertiesSelected.find((id) => id === rentalId);
    if (existed && !value) {
      properties = propertiesSelected.filter((id) => id !== rentalId);
    } else if (!existed && value) {
      properties = [...propertiesSelected, rentalId];
    }
    setpropertiesSelected(properties);
  };

  const prepareDataProperties = (propertiesSelected: ValueType[]) => {
    const data: string[] = [];
    propertiesSelected.forEach((id, index) => {
      data.push(String(id));
    });
    return {property_ids: data?.join(",")};
  };

  const onSubmit = async () => {
    setLoading(true);
    const data = prepareDataProperties(propertiesSelected);
    const res = await post(
      `${import.meta.env.VITE_API_URL}${
        paths.API.BOOKING.GET_PROPERTIES_ROOMS
      }`,
      data
    );
    if (res.data?.success) {
      setData((oldConnectedData) => {
        if (oldConnectedData) {
          const newProperties = oldConnectedData?.properties?.filter(
            (property) => propertiesSelected?.includes(property.id)
          );
          return {
            ...oldConnectedData,
            roomsRates: res?.data?.result,
            propertiesSelected: newProperties,
          };
        }
      });
      setTimeout(() => {
        setLoading(false);
        onNext();
      }, 200);
    } else {
      setError(res?.response?.data?.message);
    }
    setLoading(false);
  };

  return (
    <div className="flex flex-col gap-3">
      <div className="mt-3 space-y-2">
        <p className="text-low-contrast font-bold">
          {t("Progression.importBookingInfo")}
        </p>
        <table className="table-auto w-full rounded-4px border-1 border-element-border border-separate border-spacing-0">
          {/* Table Header */}
          <tr className="bg-element-background border-b-1 border-element-border">
            <th className=" px-2 py-2 text-sm">
              <div className="flex flex-row items-center hover:underline">
                <p>{t("Progression.rentalBookingNotSync")}</p>
              </div>
            </th>
          </tr>
          {properties?.map((property: RentalLightBookingItemResponse) => (
            <BookingConnectRentalCheckbox
              property={property}
              onCheck={onCheck}
              isCheck={
                propertiesSelected.find((id) => property.id === id)
                  ? true
                  : false
              }
              loading={loading}
            />
          ))}
        </table>
      </div>
      <ErrorMessage>{error}</ErrorMessage>
      <Button
        onClick={onSubmit}
        RightIcon={ArrowRight}
        disabled={!propertiesSelected || !propertiesSelected?.length}
        className="bui-button__text"
        loading={loading}
      >
        {t("Progression.importBookingOnSH")}
      </Button>
    </div>
  );
};
