import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CenterModal } from "../../../Common/CenterModal/CenterModal";
import { Button } from "../../../Common/Button/Button";
import { post } from "../../../../helpers/APIHelper";
import paths from "../../../../constants/paths";
import { ValueType } from "../../../../types/commonTypes";
import { ErrorMessage } from "../../../Common/ErrorMessage/ErrorMessage";

export const PaymentReservationListModalCancel: React.FC<{
  data: { reservationId: ValueType; paymentId: ValueType } | undefined | null;
  isVisible: boolean;
  onSuccess: () => void;
  onClose: () => void;
}> = ({ data, isVisible, onSuccess, onClose }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleClose = () => {
    if (loading) return;
    setError(null);
    onClose();
  };

  const handleSuccess = () => {
    onSuccess();
  };

  const handleSetToCanceled = async () => {
    setLoading(true);

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.PAYMENTS.RESERVATIONS}/${
        data?.reservationId
      }/${paths.API.PAYMENTS.CANCEL_PAYMENT}`
    );

    if (res?.data?.success) handleSuccess();
    else setError(res?.response?.data?.message);

    setLoading(false);
  };

  return (
    <CenterModal isVisible={isVisible} onClose={handleClose}>
      <div className="flex flex-col gap-5">
        <p className="text-xl font-extrabold">
          {t("Payments.PaymentReservationList.removeModalTitle")}
        </p>
        <p className="text-sm text-low-contrast">
          {t("Payments.PaymentReservationList.removeModalDescription")}
        </p>

        <ErrorMessage>{error}</ErrorMessage>

        <div className="flex gap-3">
          <Button type="secondary" onClick={handleClose} disabled={loading}>
            {t("Global.cancel")}
          </Button>
          <div title={t("Global.wip")}>
            <Button
              type="destructive"
              onClick={handleSetToCanceled}
              loading={loading}
            >
              {t("Global.cancelWithConfirmation")}
            </Button>
          </div>
        </div>
      </div>
    </CenterModal>
  );
};
