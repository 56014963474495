import { t } from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import ArrowLeftIcon from "../../../../assets/icons/arrow-left.svg?react";
import CheckIcon from "../../../../assets/icons/check-white.svg?react";
import EditIcon from "../../../../assets/icons/edit.svg?react";
import MoneyIcon from "../../../../assets/icons/money.svg?react";
import { getCurrentCanEdit } from "../../../../helpers/workspaceHelper";
import useUserStore from "../../../../stores/useUserStore";
import { RequiredFields } from "../../../../types/commonTypes";
import { Card } from "../../../Common/Card/Card";
import PlusWhiteIcon from "../../../../assets/icons/plus-white.svg?react";
import {
  RentalWebsitePaymentAndReceiptCardForm,
  RentalWebsitePaymentAndReceiptCardProps,
} from "./PaymentAndReceipt.type";
import { Trans } from "react-i18next";
import { InputSelect } from "../../../Common/InputSelect/InputSelect";
import { capitalizeFirstLetter } from "../../../../helpers/stringHelper";
import { RentalWebsiteStripeResponse } from "../../../../types/GETTypes";
import { Button } from "../../../Common/Button/Button";
import { Link } from "react-router-dom";
import { post } from "../../../../helpers/APIHelper";
import paths from "../../../../constants/paths";
import { ErrorMessage } from "../../../Common/ErrorMessage/ErrorMessage";

export const RentalWebsitePaymentAndReceiptCard: React.FC<
  RentalWebsitePaymentAndReceiptCardProps
> = ({
  loading,
  rentalId,
  rentalWebsitePayments,
  workspaceOwner,
  onUpdate,
}) => {
  const userStore = useUserStore();
  const [loadingValidation, setLoadingValidation] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const [editMode, setEditMode] = useState<boolean>(false);

  const form = useForm<RentalWebsitePaymentAndReceiptCardForm>({
    mode: "all",
    defaultValues: {
      nightReceipt: null,
      depositReceipt: null,
      extraReceipt: null,
    },
  });

  useEffect(() => {
    if (rentalWebsitePayments) {
      form.setValue(
        "nightReceipt",
        rentalWebsitePayments?.reservations_account?.id
      );

      form.setValue(
        "depositReceipt",
        rentalWebsitePayments?.deposits_account?.id
      );

      form.setValue(
        "extraReceipt",
        rentalWebsitePayments?.extra_orders_account?.id
      );
    }
  }, [rentalWebsitePayments]);

  const requiredFields: RequiredFields<RentalWebsitePaymentAndReceiptCardForm> =
    {
      nightReceipt: true,
      depositReceipt: true,
      extraReceipt: true,
    };

  const noPaymentAccounts =
    !rentalWebsitePayments?.available_accounts ||
    rentalWebsitePayments.available_accounts?.payment_accounts?.length === 0;

  const isEditButtonDisabled = () => {
    if (!getCurrentCanEdit({ user: userStore.user, workspaceOwner }))
      return true;

    return noPaymentAccounts || loadingValidation;
  };

  const accounts = useMemo(
    () =>
      rentalWebsitePayments?.available_accounts?.payment_accounts?.map(
        (account) => ({ value: account.id, label: account.name })
      ) ?? [],
    [rentalWebsitePayments?.available_accounts?.payment_accounts]
  );

  const handlePaymentAndReceiptEdit = async (values: any) => {
    setLoadingValidation(true);
    setError(undefined);

    const res = await post(
      `${
        import.meta.env.VITE_API_URL
      }${paths.API.RENTAL.UPDATE_WEBSITE_PAYMENT_ACCOUNTS(rentalId!)}`,
      {
        reservations_account_id: values.nightReceipt?.toString(),
        deposits_account_id: values.depositReceipt?.toString(),
        extra_orders_account_id: values.extraReceipt?.toString(),
      }
    );

    if (res.data?.success) {
      console.log("res.data", res.data.result.stripe_payment_accounts);
      setEditMode(false);
      onUpdate(res.data.result.stripe_payment_accounts);
    } else {
      setError(res.response?.data?.message);
    }

    setLoadingValidation(false);
  };

  if (loading) return <p>Loading ...</p>;

  return (
    <Card
      Icon={MoneyIcon}
      label={t("Rental.Website.PaymentAndReceipt.title")}
      anchor="rate-card"
      loading={loadingValidation}
      button={{
        Icon: editMode ? CheckIcon : EditIcon,
        type: editMode ? "primary" : "secondary",
        label: editMode ? t("Global.record") : t("Global.edit"),
        onClick: editMode
          ? form.handleSubmit(handlePaymentAndReceiptEdit)
          : () => setEditMode(!editMode),
        disabled: isEditButtonDisabled(),
      }}
      secondaryButton={
        editMode
          ? {
              label: t("Global.cancel"),
              LeftIcon: ArrowLeftIcon,
              onClick: () => setEditMode(false),
            }
          : undefined
      }
    >
      {/* TODO: A connecter au back */}
      <div className="flex flex-col gap-y-4">
        <div className="text-center">
          <Trans
            i18nKey="Rental.Website.PaymentAndReceipt.infos"
            components={{
              strong: <span className="font-semibold"></span>,
            }}
          />
        </div>
        {noPaymentAccounts ? (
          <NoPaymentAccount />
        ) : (
          <>
            {editMode ? (
              <>
                <ErrorMessage errorType="API">{error}</ErrorMessage>

                <div>
                  <InputSelect
                    label={t(
                      "Rental.Website.PaymentAndReceipt.nightReceiptSelectLabel"
                    )}
                    items={accounts}
                    selectedValue={
                      rentalWebsitePayments?.reservations_account?.id
                    }
                    required={requiredFields.nightReceipt}
                    disabled={loadingValidation}
                  />
                  <p className="mt-2 text-xs text-low-contrast">
                    {t("Rental.Website.PaymentAndReceipt.fieldInfo")}
                  </p>
                </div>
                <div>
                  <InputSelect
                    label={t(
                      "Rental.Website.PaymentAndReceipt.depositReceiptSelectLabel"
                    )}
                    items={accounts}
                    selectedValue={rentalWebsitePayments?.deposits_account?.id}
                    required={requiredFields.depositReceipt}
                    disabled={loadingValidation}
                  />
                  <p className="mt-2 text-xs text-low-contrast">
                    {t("Rental.Website.PaymentAndReceipt.fieldInfo")}
                  </p>
                </div>
                <div>
                  <InputSelect
                    label={t("Rental.Website.PaymentAndReceipt.nightReceipt")}
                    items={accounts}
                    selectedValue={
                      rentalWebsitePayments?.extra_orders_account?.id
                    }
                    required={requiredFields.extraReceipt}
                    disabled={loadingValidation}
                  />
                  <p className="mt-2 text-xs text-low-contrast">
                    {t("Rental.Website.PaymentAndReceipt.fieldInfo")}
                  </p>
                </div>
              </>
            ) : (
              <ViewMode rentalWebsitePayments={rentalWebsitePayments} />
            )}
          </>
        )}
      </div>
    </Card>
  );
};

const NoPaymentAccount: React.FC<{}> = () => {
  return (
    <div className="flex flex-col items-center justify-center gap-3">
      <p className="font-light text-low-contrast">
        {t("Rental.Website.PaymentAndReceipt.noPaymentAccounts")}
      </p>

      <Link to={"/payments/accounts"}>
        <Button RightIcon={PlusWhiteIcon}>
          {t("Rental.Website.PaymentAndReceipt.addPaymentAccount")}
        </Button>
      </Link>
    </div>
  );
};

const ViewMode: React.FC<{
  rentalWebsitePayments: RentalWebsiteStripeResponse | undefined;
}> = ({ rentalWebsitePayments }) => {
  return (
    <div>
      {/* Encaissement des réservations */}
      <div className="flex items-center justify-between gap-x-2 mb-3">
        <div className="flex flex-col space-y-2">
          <p className="text-base font-semibold text-high-contrast">
            {t("Rental.Website.PaymentAndReceipt.nightReceipt")}
          </p>
          <p className="text-low-contrast text-xs">
            {t("Rental.Website.PaymentAndReceipt.accountType", {
              type: capitalizeFirstLetter(
                rentalWebsitePayments?.reservations_account?.type
              ),
            })}
          </p>
        </div>

        {rentalWebsitePayments?.reservations_account?.name ? (
          <p className="text-base font-semibold text-high-contrast">
            {rentalWebsitePayments?.reservations_account?.name}
          </p>
        ) : (
          <p className="text-low-contrast font-light">
            <Trans i18nKey="Rental.Website.PaymentAndReceipt.noAccountSelected" />
          </p>
        )}
      </div>

      {/* Encaissement de la caution */}
      <div className="flex items-center justify-between gap-x-2 mb-3">
        <div className="flex flex-col space-y-2">
          <p className="text-base font-semibold text-high-contrast">
            {t("Rental.Website.PaymentAndReceipt.depositReceipt")}
          </p>
          <p className="text-low-contrast text-xs">
            {t("Rental.Website.PaymentAndReceipt.accountType", {
              type: capitalizeFirstLetter(
                rentalWebsitePayments?.deposits_account?.type
              ),
            })}
          </p>
        </div>

        {rentalWebsitePayments?.deposits_account?.name ? (
          <p className="text-base font-semibold text-high-contrast">
            {rentalWebsitePayments?.deposits_account?.name}
          </p>
        ) : (
          <p className="text-low-contrast font-light">
            <Trans i18nKey="Rental.Website.PaymentAndReceipt.noAccountSelected" />
          </p>
        )}
      </div>

      {/* Encaissement des extras */}
      <div className="flex items-center justify-between gap-x-2">
        <div className="flex flex-col space-y-2">
          <p className="text-base font-semibold text-high-contrast">
            {t("Rental.Website.PaymentAndReceipt.extrasReceipt")}
          </p>
          <p className="text-low-contrast text-xs">
            {t("Rental.Website.PaymentAndReceipt.accountType", {
              type: capitalizeFirstLetter(
                rentalWebsitePayments?.extra_orders_account?.type
              ),
            })}
          </p>
        </div>

        {rentalWebsitePayments?.extra_orders_account?.name ? (
          <p className="text-base font-semibold text-high-contrast">
            {rentalWebsitePayments?.extra_orders_account?.name}
          </p>
        ) : (
          <p className="text-low-contrast font-light">
            <Trans i18nKey="Rental.Website.PaymentAndReceipt.noAccountSelected" />
          </p>
        )}
      </div>
    </div>
  );
};
