import {useCallback} from "react";
import {useTranslation} from "react-i18next";
import moment from "moment";


/**
 * For date type : 2024-01
 */
const useFormattedMonthYear = () => {
    const { t } = useTranslation();

    const formatMonthYear = useCallback((dateString: string | null | undefined): string => {
        if (!dateString || typeof dateString !== "string") {
            return t("Global.invalidDate", "Invalid Date");
        }

        const months = [
            t("Global.January"), t("Global.February"), t("Global.March"),
            t("Global.April"), t("Global.May"), t("Global.June"),
            t("Global.July"), t("Global.August"), t("Global.September"),
            t("Global.October"), t("Global.November"), t("Global.December")
        ];

        const [year, month] = dateString.split("-").map(Number);
        if (!year || !month || month < 1 || month > 12) {
            return t("Global.invalidDate", "Invalid Date");
        }

        return `${months[month - 1]} ${year}`;
    }, [t]);


    const formatDateNumber = useCallback((date: string | null | undefined): string => {
        if (!date || typeof date !== "string") {
            return "Date invalide";
        }

        try {
            const formattedDate = new Date(`${date}-01`);
            return formattedDate.toLocaleDateString('fr-FR');
        } catch {
            return "Date invalide";
        }
    }, []);


    const datetimeToDateObject = (dateString: string | null | undefined): Date | null => {
        if (!dateString || typeof dateString !== 'string') {
            return null;
        }

        const isoDateString = dateString.replace(" ", "T");
        const date = new Date(isoDateString);

        return date;
    };



    return { formatMonthYear, formatDateNumber, datetimeToDateObject };
};

export default useFormattedMonthYear;