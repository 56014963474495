import React, {forwardRef} from "react";
import {ProgressionRentalsRulesModalRef} from "../ProgressionRentalsRulesModal.type";
import {RentalPricingRules} from "../../../Rental/Pricing/PricingRules/PricingRules";
import {RentalAdditionalCost} from "../../../Rental/Pricing/AdditionalCost/RentalAdditionalCost";
import {useRentalPageContext} from "../../../../pages/RentalPage/RentalPageContext";

export const ProgressionRentalsPrice = forwardRef<
  ProgressionRentalsRulesModalRef,
  {
    onNext: () => void;
  }
>(({onNext}, ref) => {
    const {rental} = useRentalPageContext();
  return (
    <div className="flex flex-col gap-6">
      <RentalPricingRules workspaceOwner={rental?.workspace_owner} />

      <RentalAdditionalCost workspaceOwner={rental?.workspace_owner} />
    </div>
  );
});
