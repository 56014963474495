/**
 * List that displays all payments based on the selected filters.
 * I did not use the TableList component because the requirements for this feature were very specific.
 * So, I had to create a custom solution. :)
 */

import React, { useState } from "react";
import { PaymentModalFilters } from "../PaymentModalFilters";
import { PaymentListSkeleton } from "./PaymentListSkeleton";
import { ManagementPaymentPageListProps, PaymentType } from "../Payment.type";
import { useModal } from "../../../hooks/useModal";
import { PaymentModalEditAll } from "../PaymentModalEditAll";
import {
  ManagementDayPayment,
  ManagementPaymentResponseLite,
} from "../../../types/GETTypes";
import { useTranslation } from "react-i18next";
import { PaymentModalEditOne } from "../PaymentModalEditOne";
import { CheckDeletePopup } from "../../Common/CheckDeletePopup/CheckDeletePopup";
import { getFormattedPrice } from "../../../helpers/currencyHelper";
import { usePaymentOperational } from "../../../hooks/api/paymentOperational";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import { PaymentListItem } from "./PaymentListItem";

export const PaymentList: React.FC<ManagementPaymentPageListProps> = ({
  list,
  rechargeData,
  filters,
}) => {
  const { t } = useTranslation();

  const [error, setError] = useState("");
  const { deletePayment } = usePaymentOperational();

  const modalEditAll = useModal<{
    operatorName: string;
    paymentData: ManagementPaymentResponseLite;
    period: string;
    fromDate: string;
    toDate: string;
  }>();

  const modalEditOne = useModal<{
    operatorName: string;
    dayPaymentData: ManagementDayPayment;
  }>();

  const modalDelete = useModal<{
    price: number;
    operatorName: string;
    paymentId: number;
  }>();

  /**
   * Function for show modal form edit all.
   *
   * @param operatorName
   * @param data
   * @param period
   */
  const handleEditAll = (
    operatorName: string,
    data: ManagementPaymentResponseLite,
    period: string,
  ) => {
    modalEditAll.open({
      operatorName: operatorName,
      paymentData: data,
      period: period,
      fromDate: filters.from ?? "",
      toDate: filters.to ?? "",
    });
  };

  /**
   * Function for show modal form edit one item.
   *
   * @param operatorName
   * @param dayPayment
   */
  const handleEditOne = (
    operatorName: string,
    dayPayment: ManagementDayPayment,
  ) => {
    modalEditOne.open({
      operatorName: operatorName,
      dayPaymentData: dayPayment,
    });
  };

  /**
   * Function for show popup delete item.
   *
   * @param data
   */
  const handleDeletePayment = (data: any) => {
    const paymentId = data?.paymentId;
    if (!paymentId) return;
    deletePayment(paymentId)
      .then((response) => {
        rechargeData(filters);
      })
      .catch((error: Error) => {
        setError(error?.message);
      });
  };

  if (!list) return <PaymentListSkeleton />;

  return (
    <section className={"mt-4"}>
      <PaymentModalEditAll
        modal={modalEditAll}
        handleUpdateSuccess={() => {
          rechargeData(filters);
        }}
      />
      <PaymentModalEditOne
        modal={modalEditOne}
        handleUpdateSuccess={() => {
          rechargeData(filters);
        }}
      />

      <CheckDeletePopup
        modal={modalDelete}
        title={t("Management.Payments.deleteConfirmeTitle", {
          price: getFormattedPrice({ price: modalDelete.data?.price ?? 0 }),
          operatorName: modalDelete.data?.operatorName,
        })}
        description={t("Management.Payments.deleteConfirmeDescription")}
        onAcceptDelete={handleDeletePayment}
      />

      {error && <ErrorMessage>{error}</ErrorMessage>}
      {list &&
        Object.entries(list).map(([date, operators]) => {
          return Object.entries(operators).map(([operatorName, data]) => (
            <PaymentListItem
              operatorName={operatorName}
              onEditAll={handleEditAll}
              onEditOne={handleEditOne}
              onDelete={(paymentId: number, price: number) => {
                modalDelete.open({
                  operatorName: operatorName,
                  paymentId: paymentId,
                  price: price,
                });
              }}
              data={data}
              date={date}
            />
          ));
        })}
    </section>
  );
};
