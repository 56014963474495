import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ArrowLeftIcon from "../../../../assets/icons/arrow-left.svg?react";
import BookmarkIcon from "../../../../assets/icons/bookmark-2.svg?react";
import CheckIcon from "../../../../assets/icons/check-white.svg?react";
import EditIcon from "../../../../assets/icons/edit.svg?react";
import MailIcon from "../../../../assets/icons/mail.svg?react";
import { useRentalWebsiteRulesUpdate } from "../../../../hooks/api/rental";
import useUserStore from "../../../../stores/useUserStore";
import { Card } from "../../../Common/Card/Card";
import InfoCard from "../../../Common/Card/InfoCard";
import { SettingElement } from "../../../Common/SettingElement/SettingElement";
import { Switch } from "../../../Common/Switch/Switch";
import { Tooltip } from "../../../Common/Tooltip/Tooltip";
import {
  RentalWebsiteRulesCardProps,
  RentalWebsiteRulesForm,
} from "../Rules/WebsiteRulesCard.type";
import { RentalWebsiteRulesCardSkeleton } from "./WebsiteRulesCardSkeleton";

export const RentalWebsiteRulesCard: React.FC<RentalWebsiteRulesCardProps> = ({
  loading,
  rentalWebsiteRules,
  onUpdate,
  onError,
}) => {
  const userStore = useUserStore();
  const { rentalId } = useParams();
  const { t } = useTranslation();

  const form = useForm<RentalWebsiteRulesForm>({
    mode: "onChange",
    defaultValues: {
      reservation_request_required: false,
    },
  });

  const [loadingValidation, setLoadingValidation] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);

  useEffect(() => {
    if (rentalWebsiteRules) {
      form.setValue(
        "reservation_request_required",
        Boolean(rentalWebsiteRules.reservation_request_required)
      );
    }
  }, [rentalWebsiteRules]);

  const handleCancel = () => {
    setEditMode(false);

    if (rentalWebsiteRules) {
      form.setValue(
        "reservation_request_required",
        Boolean(rentalWebsiteRules.reservation_request_required)
      );
    }
  };

  const handleRulesEdit = () =>
    useRentalWebsiteRulesUpdate({
      rentalId: rentalId,
      data: {
        reservation_request_required: Number(
          form.getValues("reservation_request_required")
        ),
      },
      onSuccess: (rentalWebsiteRules) => {
        onUpdate(rentalWebsiteRules);
        setEditMode(false);
      },
      onError: (message) => onError(message),
      onStart: () => {
        onError(null);
        setLoadingValidation(true);
      },
      onEnd: () => {
        setLoadingValidation(false);
      },
    });

  // const isEditButtonDisabled = () => {
  //   if (!getCurrentCanEdit({ user: userStore.user, workspaceOwner }))
  //     return true;

  //   return editMode && (!isValid || loadingValidation);
  // };

  const getRulesNode = (
    <SettingElement
      Icon={MailIcon}
      title={t("Rental.Website.Rules.requiredReservationLabel")}
      description={
        form.getValues("reservation_request_required")
          ? t("Rental.Website.Rules.requiredReservationDescriptionActive")
          : t("Rental.Website.Rules.requiredReservationDescription")
      }
      labelTooltip={
        <Tooltip value={t("Rental.Website.Rules.requiredReservationTooltip")} />
      }
    >
      <Switch
        value={form.watch("reservation_request_required")}
        onClick={() =>
          form.setValue(
            "reservation_request_required",
            !form.getValues("reservation_request_required")
          )
        }
        disabled={!editMode}
      />
    </SettingElement>
  );

  const getInfoNode = (
    <div className="flex flex-col whitespace-pre-line gap-y-4">
      <p className="text-base font-light text-low-contrast">
        {t("Rental.Website.Rules.requiredReservationInfo1")}
      </p>

      <p className="text-base font-light text-low-contrast">
        {t("Rental.Website.Rules.requiredReservationInfo2")}
      </p>

      <p className="text-base font-bold text-low-contrast">
        {t("Rental.Website.Rules.requiredReservationInfo3")}
      </p>

      <p className="text-base font-light text-low-contrast">
        {t("Rental.Website.Rules.requiredReservationInfo4")}
      </p>
    </div>
  );

  form.watch();

  if (loading) return <RentalWebsiteRulesCardSkeleton />;

  return (
    <Card
      Icon={BookmarkIcon}
      label={t("Rental.Website.Rules.title")}
      anchor="rules-card"
      loading={loadingValidation}
      button={{
        Icon: editMode ? CheckIcon : EditIcon,
        type: editMode ? "primary" : "secondary",
        label: editMode ? t("Global.record") : t("Global.edit"),
        onClick: editMode
          ? form.handleSubmit(handleRulesEdit)
          : () => setEditMode(!editMode),
        // disabled: isEditButtonDisabled(),
      }}
      secondaryButton={
        editMode
          ? {
              label: t("Global.cancel"),
              LeftIcon: ArrowLeftIcon,
              onClick: () => handleCancel(),
            }
          : undefined
      }
    >
      <>
        <div className="flex flex-col gap-y-3">
          {getRulesNode}
          {editMode && form.getValues("reservation_request_required") && (
            <InfoCard Node={getInfoNode} />
          )}
        </div>
      </>
    </Card>
  );
};
