import React from "react";
import CaratRightIcon from "../../../../assets/icons/carat-right.svg?react";
import TitleComponent from "../../Common/Template2.0/TitleComponent";
import useGuestWebsiteStore from "../../../../stores/guestWebsiteStore/useGuestWebsiteStore";

export function FaqComponent() {
  const {dataGuestWebsite} = useGuestWebsiteStore();
  const {content,styles} = dataGuestWebsite || {};
  const {faq} = content || {};
  const faqs = faq?.cards;

  const {color_background,color_text} = styles || {}

  const [openFaq, setOpenIndex] = React.useState<number | null>(null);

  const toggleFaq = (id: number) => {
    setOpenIndex(openFaq === id ? null : id);
  };

  return (
    <div className="max-w-2xl mx-auto p-4 py-12 pb-24">
      <TitleComponent title={faq?.title ?? ""} subtitle={faq?.subtitle ?? ""} />

      <div className="space-y-4">
        {faqs?.map(({id, question, answer}, index) => (
          <div
            key={index}
            className="bg-white shadow-xl rounded-lg overflow-hidden"
            // style={{backgroundColor:color_background}}
          >
            <button
              onClick={() => toggleFaq(id)}
              className="w-full flex items-start justify-between p-4 text-left"
            >
              <span className="font-semibold" style={{color: color_text}}>{question}</span>
              <CaratRightIcon
                className={`transform transition-transform ${
                  openFaq === id ? "-rotate-90" : ""
                }`}
              />
            </button>
            <div
              className={`transition-all duration-300 overflow-hidden ${
                openFaq === id ? "max-h-screen p-4" : "max-h-0"
              }`}
            >
              <p className="text-gray-600">{answer}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
