import React from "react";
import {Navigate} from "react-router-dom";
import {isMainAccount} from "../../helpers/workspaceHelper";
import {RequireAuthProps} from "./RequireAuth.type";


const RequireAuth: React.FC<RequireAuthProps> = ({ children }) => {
  const user = JSON.parse(localStorage.getItem("user"));

  if (!isMainAccount(user) ) {
    return <Navigate to="/forbidden" replace />;
  }
  return children;
};

export default RequireAuth;
