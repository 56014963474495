import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import CheckIcon from "../../../assets/icons/check-white.svg?react";
import RobotIcon from "../../../assets/icons/robot.svg?react";
import {
  useAutomationSettings,
  useUpdateAutomationSettings,
} from "../../../hooks/api/automation";
import { UseModal } from "../../../hooks/useModal";
import { Button } from "../../Common/Button/Button";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import { RightModal } from "../../Common/RightModal/RightModal";
import { Separator } from "../../Common/Separator/Separator";
import { Switch } from "../../Common/Switch/Switch";
import { ManagementAutomationSettingsModalForm } from "./ManagementAutomationSettingsModal.type";
import { AutomationSettingsPayload } from "../../../pages/ManagementAutomationList/ManagementAutomationList.type";

type ManagementAutomationSettingsModalProps = {
  modal: UseModal<{}>;
};

export const ManagementAutomationSettingsModal: React.FC<
  ManagementAutomationSettingsModalProps
> = ({ modal }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [settings, setSettings] = useState<AutomationSettingsPayload>();
  const { getSettings } = useAutomationSettings();

  const form = useForm<ManagementAutomationSettingsModalForm>({
    mode: "all",
    defaultValues: {
      cleaningAutomation: true,
      checkinAutomation: false,
      checkoutAutomation: false,
    },
  });

  useEffect(() => {
    getSettings().then((response) => {
      setSettings(response);

      // set default settings
      form.setValue("cleaningAutomation", response.auto_create_cleaning);
      form.setValue("checkinAutomation", response.auto_create_checkin);
      form.setValue("checkoutAutomation", response.auto_create_checkout);
    });
  }, []);

  const handleUpdateSettings = async (
    values: ManagementAutomationSettingsModalForm,
  ) => {
    useUpdateAutomationSettings({
      data: {
        auto_create_cleaning: values.cleaningAutomation,
        auto_create_checkin: values.checkinAutomation,
        auto_create_checkout: values.checkoutAutomation,
      },
      onSuccess: () => {
        modal.close();
      },
      onError: (message: string | null) => setError(message),
      onStart: () => {
        setError(null);
        setLoading(true);
      },
      onEnd: () => {
        setLoading(false);
      },
    });
  };

  form.watch();

  return (
    <RightModal
      title={t("Automation.SettingsModal.title")}
      isVisible={modal.isVisible}
      onClose={loading ? () => {} : modal.close}
      classNames={{
        mainContentParent: "overflow-y-auto",
      }}
    >
      <>
        <form
          className="flex flex-col justify-between w-full"
          onSubmit={form.handleSubmit(handleUpdateSettings)}
        >
          <div className="flex flex-col justify-center overflow-y-auto gap-y-2">
            <p className="mb-4 text-xs font-semibold text-low-contrast">
              {t("Automation.SettingsModal.description")}
            </p>

            {/* Activate cleaning automation */}
            <div className="flex items-center justify-between p-2 border rounded gap-x-4 bg-element-background border-element-border">
              <div className="flex items-center space-x-2">
                <RobotIcon className="size-6" />
                <div className="flex flex-col space-y-1">
                  <p className="text-sm font-semibold text-high-contrast">
                    {t("Automation.SettingsModal.cleaningAutomationLabel")}
                  </p>
                  <p className="text-xs font-semibold text-low-contrast">
                    {t(
                      "Automation.SettingsModal.cleaningAutomationDescription",
                    )}
                  </p>
                </div>
              </div>

              <Controller
                control={form.control}
                name="cleaningAutomation"
                render={({ field: { onChange, value } }) => (
                  <Switch value={value} onClick={() => onChange(!value)} />
                )}
              />
            </div>

            {/* Activate checkin automation */}
            <div className="flex items-center justify-between p-2 border rounded gap-x-4 bg-element-background border-element-border">
              <div className="flex items-center space-x-2">
                <RobotIcon className="size-6" />
                <div className="flex flex-col space-y-1">
                  <p className="text-sm font-semibold text-high-contrast">
                    {t("Automation.SettingsModal.checkinAutomationLabel")}
                  </p>
                  <p className="text-xs font-semibold text-low-contrast">
                    {t("Automation.SettingsModal.checkinAutomationDescription")}
                  </p>
                </div>
              </div>

              <Controller
                control={form.control}
                name="checkinAutomation"
                render={({ field: { onChange, value } }) => (
                  <Switch value={value} onClick={() => onChange(!value)} />
                )}
              />
            </div>

            {/* Activate checkout automation */}
            <div className="flex items-center justify-between p-2 border rounded gap-x-4 bg-element-background border-element-border">
              <div className="flex items-center space-x-2">
                <RobotIcon className="size-6" />
                <div className="flex flex-col space-y-1">
                  <p className="text-sm font-semibold text-high-contrast">
                    {t("Automation.SettingsModal.checkoutAutomationLabel")}
                  </p>
                  <p className="text-xs font-semibold text-low-contrast">
                    {t(
                      "Automation.SettingsModal.checkoutAutomationDescription",
                    )}
                  </p>
                </div>
              </div>

              <Controller
                control={form.control}
                name="checkoutAutomation"
                render={({ field: { onChange, value } }) => (
                  <Switch value={value} onClick={() => onChange(!value)} />
                )}
              />
            </div>
          </div>

          <div className="flex flex-col">
            <Separator />
            <ErrorMessage>{error}</ErrorMessage>
            <div className="flex justify-between gap-x-4">
              <Button type="secondary" onClick={modal.close} disabled={loading}>
                {t("Global.cancel")}
              </Button>
              <Button
                type="primary"
                RightIcon={CheckIcon}
                loading={loading}
                disabled={!form.formState.isValid || loading}
              >
                {t("Global.record")}
              </Button>
            </div>
          </div>
        </form>
      </>
    </RightModal>
  );
};
