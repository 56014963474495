import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CheckIcon from "../../../../assets/icons/check-white.svg?react";
import paths from "../../../../constants/paths";
import { post } from "../../../../helpers/APIHelper";
import { UseModal } from "../../../../hooks/useModal";
import {
  PhotoListItemResponse,
  PhotoListResponse,
} from "../../../../types/GETTypes";
import { ValueType } from "../../../../types/commonTypes";
import { Button } from "../../../Common/Button/Button";
import { ErrorMessage } from "../../../Common/ErrorMessage/ErrorMessage";
import { PictureInput } from "../../../Common/PictureInput/PictureInput";
import { RightModal } from "../../../Common/RightModal/RightModal";
import { Separator } from "../../../Common/Separator/Separator";

type RentalUpdatePhotosModalProps = {
  rentalId: string | undefined;
  modal: UseModal<{}>;
  oldFiles: PhotoListItemResponse[];
  newFiles: Blob[];
  onUpdatePhotos: (photos: PhotoListResponse) => void;
};

export const RentalUpdatePhotosModal: React.FC<
  RentalUpdatePhotosModalProps
> = ({ rentalId, modal, oldFiles, newFiles, onUpdatePhotos }) => {
  const { t } = useTranslation();

  const [error, setError] = useState<string | undefined>();
  const [loadingValidation, setLoadingValidation] = useState<boolean>(false);

  const [photoIdsToDelete, setPhotoIdsToDelete] = useState<ValueType[]>([]);
  const [oldFilesTemp, setOldFilesTemp] = useState<PhotoListItemResponse[]>([]);
  const [newFilesTemp, setNewFilesTemp] = useState<Blob[]>([]);

  useEffect(() => {
    setOldFilesTemp(oldFiles);
    setNewFilesTemp(newFiles);
  }, [modal?.isVisible]);

  const handleCancel = () => {
    setPhotoIdsToDelete([]);
    modal.close();
  };

  const checkArrayEqual = (array1: Array<any>, array2: Array<any>) => {
    if (array1.length !== array2.length) {
      return false;
    }

    return array1.every((value, index) => value === array2[index]);
  };

  const handleUpdatePhotos = () => {
    if (rentalId !== undefined) {
      const updatePhotos = async () => {
        setLoadingValidation(true);

        const updatePhotosData = new FormData();
        if (!checkArrayEqual(oldFiles, oldFilesTemp)) {
          oldFilesTemp.forEach((file, index) => {
            updatePhotosData.append(
              `re_arange_photo_ids[${index}]`,
              file.id.toString()
            );
          });
        }

        updatePhotosData.append(
          "del_photo_ids",
          [...new Set(photoIdsToDelete)].join(",")
        );
        newFilesTemp.forEach((photo, i) => {
          updatePhotosData.append(`photos[${i}]`, photo);
        });

        const response = await post(
          `${import.meta.env.VITE_API_URL}${
            paths.API.RENTALS_UPDATE
          }/${rentalId}${paths.API.RENTAL.UPDATE_PHOTOS_RENTAL}`,
          updatePhotosData
        );

        if (response.data?.success) {
          const data: PhotoListResponse = {
            ...response.data.result,
            photos: response.data.result.photos.map(
              (photo: PhotoListItemResponse) => {
                const { base_64, ...rest } = photo as {
                  base_64: string;
                } & PhotoListItemResponse;
                return rest;
              }
            ),
          };

          onUpdatePhotos(data);
        } else {
          setError(response?.response?.data?.message);
        }
        setLoadingValidation(false);
      };

      updatePhotos();
    }
  };

  return (
    <RightModal
      title={t("Rental.Infos.Photos.updateModalTitle")}
      isVisible={modal?.isVisible}
      onClose={modal.close}
    >
      <div className="flex flex-col gap-3">
        <ErrorMessage>{error}</ErrorMessage>
        <p className="text-low-contrast">
          {t("Rental.Infos.Photos.updateModalLabel")}
        </p>
        <div className="flex-1">
          <PictureInput
            disabled={loadingValidation}
            mode="all"
            oldFiles={oldFilesTemp}
            newFiles={newFilesTemp}
            onChangeOldFiles={(files) => setOldFilesTemp(files)}
            onChangeNewFiles={(files) => {
              console.log("files : ", files);
              setNewFilesTemp(files);
            }}
            activePreviewSystem={true}
            onDeleteFile={(id) =>
              setPhotoIdsToDelete((prevValue) => [...prevValue, id])
            }
          />
        </div>

        <Separator />

        <div className="flex gap-x-3">
          <Button
            type="secondary"
            onClick={handleCancel}
            disabled={loadingValidation}
          >
            {t("Global.cancel")}
          </Button>
          <Button
            type="primary"
            RightIcon={CheckIcon}
            loading={loadingValidation}
            onClick={handleUpdatePhotos}
          >
            {t("Global.record")}
          </Button>
        </div>
      </div>
    </RightModal>
  );
};
