import React from "react";

const SkeletonHeaderTemplate2 = () => {
  return (
    <header className="flex flex-col p-4 bg-element-border h-2/5">
      <div className="flex justify-between mt-4">
        <div className="flex w-44 h-16 bg-gray-500 rounded animate-pulse"></div>
        {/* Flag and Button Section */}
        <div className="flex items-center space-x-4 h-1/4 mt-4">
          {/* Navigation Links */}
          <nav className="flex space-x-4">
            <div className="w-24 h-6 bg-gray-500 rounded animate-pulse"></div>
            <div className="w-24 h-6 bg-gray-500 rounded animate-pulse"></div>
            <div className="w-24 h-6 bg-gray-500 rounded animate-pulse"></div>
            <div className="w-24 h-6 bg-gray-500 rounded animate-pulse"></div>
          </nav>
          {/* Flag Placeholder */}
          <div className="w-8 h-6 bg-gray-500 rounded animate-pulse"></div>

          {/* Button Placeholder */}
          <div className="w-28 h-10 bg-gray-500 rounded animate-pulse"></div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center mt-6 space-y-8">
        <div className="w-2/5 h-28 bg-gray-500 rounded animate-pulse"></div>
        <div className="w-2/4 h-20 bg-gray-500 rounded animate-pulse"></div>
      </div>
    </header>
  );
};

export default SkeletonHeaderTemplate2;
