/**
 * Modal form for send
 * ticket reservation.
 */
import React, { useState } from "react";

import { CenterModal } from "../../../Common/CenterModal/CenterModal";
import { CheckBox } from "../../../Common/CheckBox/CheckBox";
import LangSelect from "../../../Common/LangSelect/LangSelect";
import { TextInput } from "../../../Common/TextInput/TextInput";
import { TextAreaInput } from "../../../Common/TextAreaInput/TextAreaInput";
import { Button } from "../../../Common/Button/Button";
import { useTranslation } from "react-i18next";
import SendIcon from "../../../../assets/icons/send-white.svg?react";
import { PhoneInput } from "../../../Common/PhoneInput/PhoneInput";
import { usePaymentReceipt } from "../../../../hooks/api/paymentReceipt";
import { ErrorMessage } from "../../../Common/ErrorMessage/ErrorMessage";
import {
  ContactType,
  SendReceiptTicketProps,
} from "../../../../pages/BookingInvoice/BookingInvoicePage.type";

export default function SendReceiptTicket({
  onSubmit,
  modal,
  reservationId,
}: SendReceiptTicketProps) {
  const { t } = useTranslation();
  const { sendReceiptWithSms, sendReceiptWithEmail } = usePaymentReceipt();

  const [type, setType] = useState<ContactType>("EMAIL");
  const [langId, setLangId] = useState(73);
  const [contact, setContact] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");

  const [contactError, setContactError] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [error, setError] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const checkContactType = (contact: {
    type: ContactType;
    value: string;
  }): boolean => {
    const isEmail = (value: string): boolean => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(value);
    };

    const isPhoneNumber = (value: string): boolean => {
      const phoneRegex = /^\+?[1-9]\d{1,14}$/;
      return phoneRegex.test(value);
    };

    switch (contact.type) {
      case "SMS":
        return isPhoneNumber(contact.value);
      case "EMAIL":
        return isEmail(contact.value);
      default:
        return false;
    }
  };

  const resetErrors = (): void => {
    setContactError("");
    setSubjectError("");
    setError("");
  };

  const handleSendForm = (): void => {
    resetErrors();

    const typeReceipt = modal.data?.receiptType;

    if (typeof typeReceipt === "undefined") {
      return;
    }

    if (!checkContactType({ type: type, value: contact })) {
      setContactError(t("Global.formatError"));
      return;
    }

    if (subject.length <= 0) {
      setSubjectError(t("Global.valueRequired"));
      return;
    }

    setIsLoading(true);

    switch (type) {
      case "EMAIL":
        sendReceiptWithEmail(
          reservationId as string,
          subject,
          message,
          langId,
          contact,
          typeReceipt,
        )
          .catch((error: Error) => {
            setError(error.message);
          })
          .then((response) => {
            modal.close();
            onSubmit();
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
      case "SMS":
        sendReceiptWithSms(
          reservationId as string,
          subject,
          message,
          langId,
          contact,
          typeReceipt,
        )
          .catch((error: Error) => {
            setError(error.message);
          })
          .then((response) => {
            modal.close();
            onSubmit();
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
    }
  };

  return (
    <CenterModal
      isVisible={modal.isVisible}
      title={t("Booking.sendTicket")}
      onClose={modal.close}
      classNames={{
        title: "text-lg font-bold",
      }}
    >
      <section className={"flex items-center gap-8 text-gray-600"}>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <CheckBox
          isRadio={true}
          value={type === "EMAIL"}
          label={t("Global.email")}
          onChange={(value: boolean) => {
            setContact("");
            if (!value) {
              setType("SMS");
              return;
            }
            setType("EMAIL");
          }}
        />
        <CheckBox
          isRadio={true}
          value={type === "SMS"}
          label={t("Global.sms")}
          onChange={(value: boolean) => {
            setContact("");
            if (!value) {
              setType("EMAIL");
              return;
            }
            setType("SMS");
          }}
        />
      </section>
      <section className={"flex flex-col gap-2 mt-4"}>
        <LangSelect
          label={t("Global.lang")}
          required={true}
          defaultValue={langId}
          onLangSelect={(langId: number) => {
            setLangId(langId);
          }}
        />
        {type == "EMAIL" ? (
          <TextInput
            label={t("Global.email")}
            required={true}
            value={contact}
            onChangeText={(email: string) => {
              setContact(email);
            }}
            error={contactError}
          />
        ) : (
          <PhoneInput
            label={t("Profil.phoneNumber")}
            required={true}
            value={contact}
            onChangeText={(phoneNumber: string) => {
              setContact(phoneNumber);
            }}
            error={contactError}
          />
        )}
        <TextInput
          label={t("Global.subject")}
          required={true}
          value={subject}
          onChangeText={(subject: string) => {
            setSubject(subject);
          }}
          error={subjectError}
        />
        <TextAreaInput
          label={t("Global.message")}
          value={message}
          onTextChange={(message: string) => {
            setMessage(message);
          }}
        />
      </section>
      <section className={"flex items-center justify-between gap-2 mt-4"}>
        {!isLoading && <Button type={"secondary"}>{t("Global.cancel")}</Button>}
        <Button
          RightIcon={SendIcon}
          onClick={handleSendForm}
          loading={isLoading}
        >
          {t("Global.send")}
        </Button>
      </section>
    </CenterModal>
  );
}
