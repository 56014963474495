import React, {useEffect, useState} from "react";
import {Card} from "../../../Common/Card/Card";
import ContractIcon from "../../../../assets/icons/contracts-paper.svg?react";
import PlusIcon from "../../../../assets/icons/plus.svg?react";

import {useTranslation} from "react-i18next";
import CardDocs from "../../../Common/CardDocs/CardDocs";
import {UseModal, useModal} from "../../../../hooks/useModal";
import {ModalContractsDeleteData, ModalContractsEditData} from "../../../../pages/RentalPage/RentalPage.type";
import {ContractsCardSkeleton} from "./ContractsCardSkeleton";
import {get, patch, post} from "../../../../helpers/APIHelper";
import paths from "../../../../constants/paths";
import {useNavigate} from "react-router-dom";
import {ContractResponse, ContractsResponse} from "../../../../types/GETTypes";
import {ResourceResponse} from "../../../Common/CardDocs/CardDocs.type";

type RentalContractsCardProps = {
  modalCreate: UseModal<unknown>;
  modalEdit: UseModal<ModalContractsEditData>;
  modalDelete: UseModal<ModalContractsDeleteData>;
  rentalId: number;
  contracts: ContractsResponse[] | undefined;
  isLoading: boolean;
  defaultSelectedContract: number | undefined;
  setDefaultSelectedContract: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  onSuccess: () => Promise<void>;
};
const RentalContractsCard = ({
  modalCreate,
  modalEdit,
  modalDelete,
  rentalId,
  contracts,
  isLoading,
  defaultSelectedContract,
  setDefaultSelectedContract,
  onSuccess,
}: RentalContractsCardProps) => {
  const {t} = useTranslation();
  const handleChangeDefault = async (contract: ContractResponse) => {
    const oldDefaultContract = defaultSelectedContract;
    setDefaultSelectedContract(contract.id);
    const response = await patch(
      `${
        import.meta.env.VITE_API_URL
      }${paths.API.CONTRACTS_DOCS.SET_DEFAULT_CONTRACT_BY_RENTAL(
        rentalId,
        contract.id
      )}`
    );
    if (!response?.data?.success) {
      setDefaultSelectedContract(oldDefaultContract);
    }
  };

  const handleDuplicate = async (contract: ResourceResponse) => {
    const response = await post(
      `${
        import.meta.env.VITE_API_URL
      }${paths.API.CONTRACTS_DOCS.DUPLICATE_CONTRACT(rentalId, contract.id)}`
    );
    if (response?.data?.success) {
      onSuccess();
    }
  };

  const handleEdit = async (contract: ResourceResponse) => {
    modalEdit && modalEdit.open({resource: contract});
  };

  const handleDelete = async (contract: ResourceResponse) => {
    modalDelete && modalDelete.open({resource: contract});
  };

  if (isLoading) {
    return <ContractsCardSkeleton />;
  }

  return (
    <Card
      Icon={ContractIcon}
      label={t("Rental.Contracts.Contracts.title")}
      anchor="contracts-card"
      hasBody={true}
      button={{
        Icon: PlusIcon,
        type: "secondary",
        onClick: modalCreate.open,
      }}
    >
      {contracts?.map((contract) => {
        const displayDeleteButton =
          contracts &&
          contracts?.length > 1 &&
          contract?.id !== defaultSelectedContract;
        return (
          <CardDocs
            showRadio
            showDuplicate
            showEdit
            showDelete={displayDeleteButton}
            handleEdit={handleEdit}
            modalEdit={modalEdit}
            resource={contract}
            defaultResource={defaultSelectedContract}
            handleChangeDefault={handleChangeDefault}
            handleDuplicate={handleDuplicate}
            handleDelete={handleDelete}
            from="rental"
            type="contract"
          />
        );
      })}
    </Card>
  );
};

export default RentalContractsCard;
