import React from "react";
import { GroupButton } from "../Common/GroupButton/GroupButton";
import { Trans, useTranslation } from "react-i18next";
import { ValueType } from "../../types/commonTypes";
import { Button } from "../Common/Button/Button";
import ArrowUpRighWhiteIcon from "../../assets/icons/arrow-up-right-white.svg?react";

export const WebsiteTabs: React.FC<{
  tab: ValueType;
  url: string | undefined;
  onChange: (value: ValueType) => void;
}> = ({ tab, url = "", onChange }) => {
  const { t } = useTranslation();

  return (
    <div className="pt-1 pb-6 flex justify-between">
      <GroupButton
        items={[
          {
            label: t("Website.Tabs.content"),
            value: "content",
            isActive: tab === "content",
          },
          {
            label: t("Website.Tabs.rentals"),
            value: "rentals",
            isActive: tab === "rentals",
          },
          {
            label: t("Website.Tabs.style"),
            value: "style",
            isActive: tab === "style",
          },
          {
            label: t("Website.Tabs.settings"),
            value: "settings",
            isActive: tab === "settings",
          },
        ]}
        onClick={onChange}
      />

      <div>
        <a href={url} target="_blank" rel="noreferer">
          <Button RightIcon={ArrowUpRighWhiteIcon}>
            <Trans i18nKey="Website.Tabs.view" />
          </Button>
        </a>
      </div>
    </div>
  );
};
