import React, { useEffect, useState } from "react";
import { BookingPageProps, Journal } from "./BookingPage.type";
import { useParams } from "react-router-dom";
import { BookingLayout } from "../../components/Layout/BookingLayout/BookingLayout";
import { ReservationCancelCard } from "../../components/Reservation/Cancel/ReservationCancelCard";
import ReservationExtrasCard from "../../components/Reservation/ExtrasOrder/ReservationExtrasCard";
import { ReservationGuestCard } from "../../components/Reservation/Guest/GuestCard";
import { ReservationLockCard } from "../../components/Reservation/Lock/ReservationLockCard";
import { ReservationNoteCard } from "../../components/Reservation/Note/NoteCard";
import { ReservationNoteForm } from "../../components/Reservation/Note/NoteCard.type";
import { ReservationPlatformNoteCard } from "../../components/Reservation/PlatformNote/PlatformNoteCard";
import { ReservationPlatformNoteForm } from "../../components/Reservation/PlatformNote/PlatformNoteCard.type";
import { ReservationCard } from "../../components/Reservation/ReservationCard";
import { ReservationCardForm } from "../../components/Reservation/ReservationCard.type";
import { ReservationStatusEnum } from "../../enums/GETenums";
import {
  fetchOneReservation,
  fetchReservationJournal,
} from "../../helpers/reservationHelper";
import { GuestPageExtraOrder, ReservationResponse } from "../../types/GETTypes";
import { getCurrentCanEdit } from "../../helpers/workspaceHelper";
import useUserStore from "../../stores/useUserStore";
import { ReservationDetailsMenu } from "../../components/Reservation/Menu/ReservationDetailsMenu";

export const BookingPage: React.FC<BookingPageProps> = () => {
  const { reservationId } = useParams();
  const userStore = useUserStore();

  const [reservation, setReservation] = useState<
    ReservationResponse | undefined
  >(undefined);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  const [journals, setJournals] = useState<Journal[]>([]);
  const [loadingJournal, setLoadingJournal] = useState<boolean>(true);
  const [errorJournal, setErrorJournal] = useState<string | null>(null);

  const handleReservationSuccess = (value: ReservationResponse) => {
    setReservation(value);
    setLoading(false);
  };

  const handleReservationError = (value: string) => {
    setError(value);
    setLoading(false);
  };

  const handleJournalSuccess = (value: any[]) => {
    let nextJournals: any[] = [];

    if (value) {
      Object.values(value).forEach((d) => {
        nextJournals = [...nextJournals, ...d];
      });

      setJournals(nextJournals);
      setLoadingJournal(false);
    }
  };

  const handleJournalError = (value: string) => {
    setLoadingJournal(false);
    setErrorJournal(value);
  };

  useEffect(() => {
    if (reservationId) {
      fetchOneReservation(
        reservationId,
        handleReservationSuccess,
        handleReservationError,
      );

      fetchReservationJournal(
        reservationId,
        handleJournalSuccess,
        handleJournalError,
      );
    }
  }, [reservationId]);

  const handleUpdateReservation = (newData: ReservationCardForm) => {
    if (reservation) {
      setReservation({
        ...reservation,
        ...newData,
      });
    }
  };

  const handleUpdateExtras = (newData: GuestPageExtraOrder[]) => {
    location.reload();
  };

  const handleUpdateNote = (newData: ReservationNoteForm) => {
    if (reservation) {
      setReservation({
        ...reservation,
        ...newData,
      });
    }
  };

  const handleUpdatePlatformNote = (newData: ReservationPlatformNoteForm) => {
    if (reservation) {
      setReservation({
        ...reservation,
        ...newData,
      });
    }
  };

  const handleUpdateStatus = (nextStatus: ReservationStatusEnum) => {
    if (reservation) {
      setReservation({ ...reservation, status_reservation: nextStatus });
    }
  };

  return (
    <>
      <BookingLayout
        navbarActiveItem="details"
        reservation={reservation}
        error={error}
      >
        <div className="grid grid-cols-12 h-screen">
          {/* Menu Sticky */}
          <div className="col-span-2 pr-2 sticky top-16 self-start h-fit">
            <ReservationDetailsMenu />
          </div>

          {/* Scrollable Cards */}
          <div className="col-span-8 flex flex-col gap-3 overflow-y-auto h-[calc(100vh-160px)] no-scrollbar">
            <ReservationCard
              loading={loading}
              setLoading={setLoading}
              reservation={reservation}
              disabled={
                !getCurrentCanEdit({
                  user: userStore.user,
                  workspaceOwner: reservation?.workspace_owner,
                })
              }
              onUpdate={handleUpdateReservation}
            />

            <ReservationGuestCard
              loading={loading}
              reservation={reservation}
              disabled={
                !getCurrentCanEdit({
                  user: userStore.user,
                  workspaceOwner: reservation?.workspace_owner,
                })
              }
            />

            <ReservationLockCard
              reservation={reservation}
              disabled={
                !getCurrentCanEdit({
                  user: userStore.user,
                  workspaceOwner: reservation?.workspace_owner,
                })
              }
            />

            <ReservationExtrasCard
              loading={loading}
              reservation={reservation}
              disabled={
                !getCurrentCanEdit({
                  user: userStore.user,
                  workspaceOwner: reservation?.workspace_owner,
                })
              }
              onUpdate={handleUpdateExtras}
            />

            <ReservationNoteCard
              loading={loading}
              reservation={reservation}
              disabled={
                !getCurrentCanEdit({
                  user: userStore.user,
                  workspaceOwner: reservation?.workspace_owner,
                })
              }
              onUpdate={handleUpdateNote}
            />

            <ReservationPlatformNoteCard
              loading={loading}
              reservation={reservation}
              disabled={
                !getCurrentCanEdit({
                  user: userStore.user,
                  workspaceOwner: reservation?.workspace_owner,
                })
              }
              onUpdate={handleUpdatePlatformNote}
            />

            <ReservationCancelCard
              loading={loading}
              reservation={reservation}
              disabled={
                !getCurrentCanEdit({
                  user: userStore.user,
                  workspaceOwner: reservation?.workspace_owner,
                })
              }
              onUpdate={handleUpdateStatus}
            />
          </div>

          {/* Empty Space */}
          <div className="col-span-2"></div>
        </div>
      </BookingLayout>
    </>
  );
};
