import React from "react";
import { RightModal } from "../Common/RightModal/RightModal";
import { useTranslation } from "react-i18next";
import { Button } from "../Common/Button/Button";
import CheckWhiteIcon from "../../assets/icons/check-white.svg?react";
import { IntegrateContent } from "./IntegrateContent";

export const IntegrateModal: React.FC<{
  isVisible: boolean;
  onClose: () => void;
}> = ({ isVisible, onClose }) => {
  const { t } = useTranslation();

  const handleClose = () => {
    onClose();
  };

  return (
    <RightModal
      title={t("Website.Integrate.title")}
      isVisible={isVisible}
      onClose={handleClose}
    >
      <div className="flex flex-col w-full">
        <IntegrateContent />

        <div className="flex items-center gap-3">
          <Button type="secondary" onClick={handleClose}>
            {t("Global.cancel")}
          </Button>
          <Button RightIcon={CheckWhiteIcon} onClick={handleClose}>
            {t("Global.ok")}
          </Button>
        </div>
      </div>
    </RightModal>
  );
};
