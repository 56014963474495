import React, { useState } from "react";
import { capitalizeFirstLetter } from "../../../helpers/stringHelper";
import moment from "moment";
import { TaskItemResponse, TaskListDayResponse } from "../../../types/GETTypes";
import { useTranslation } from "react-i18next";
import ChevronDownIcon from "../../../assets/icons/chevron-down.svg?react";
import ChevronUpIcon from "../../../assets/icons/chevron-up.svg?react";
import { TaskListItem } from "./TaskListItem";

export const TaskListWithTasks: React.FC<{
  date: string;
  taskListDay: TaskListDayResponse;
  openEditTaskModal: (task: TaskItemResponse) => void;
  onAcceptTask: (task: TaskItemResponse) => void;
  onDeclineTask: (task: TaskItemResponse) => void;
}> = ({
  date,
  taskListDay,
  openEditTaskModal,
  onAcceptTask,
  onDeclineTask,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const buildSummarySentence = () => {
    const types: string[] = [];

    if (taskListDay.total_cleaning_tasks > 0) {
      types.push(
        t("Task.List.countCleaning", {
          count: taskListDay.total_cleaning_tasks,
        })
      );
    }

    if (taskListDay.total_checkin_tasks > 0) {
      types.push(
        t("Task.List.countCheckin", {
          count: taskListDay.total_checkin_tasks,
        })
      );
    }

    if (taskListDay.total_checkout_tasks > 0) {
      types.push(
        t("Task.List.countCheckout", {
          count: taskListDay.total_checkout_tasks,
        })
      );
    }

    if (taskListDay.total_incident_tasks > 0) {
      types.push(
        t("Task.List.countIncident", {
          count: taskListDay.total_incident_tasks,
        })
      );
    }

    if (taskListDay.total_other_tasks > 0) {
      types.push(
        t("Task.List.countOther", {
          count: taskListDay.total_other_tasks,
        })
      );
    }

    return types?.join(", ");
  };

  return (
    <div className="border-1 border-element-border rounded-6px flex flex-col overflow-hidden">
      <div
        className="flex items-center justify-between cursor-pointer bg-element-background-light"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex flex-col p-2 ">
          <p className="font-semibold text-high-contrast text-base">
            {capitalizeFirstLetter(
              moment(new Date(date)).format("dddd DD MMMM YYYY")
            )}
          </p>

          <p className="font-light text-low-contrast">
            {buildSummarySentence()}
          </p>
        </div>

        <div className="pr-2">
          {isOpen ? (
            <ChevronUpIcon className="size-6" />
          ) : (
            <ChevronDownIcon className="size-6" />
          )}
        </div>
      </div>

      {isOpen && (
        <table>
          <tbody>
            <tr className="text-left bg-element-background border-b-1 border-element-border">
              <th className="py-2 pl-2">{t("Task.List.rental")}</th>
              <th className="py-2 text-center">{t("Task.List.name")}</th>
              <th className="py-2 text-center">{t("Task.List.time")}</th>
              <th className="py-2 text-center">{t("Task.List.assignedTo")}</th>
              <th className="py-2 text-center">{t("Task.List.status")}</th>
            </tr>

            {taskListDay.day_tasks?.map(
              (task: TaskItemResponse, index: number) => {
                return (
                  <TaskListItem
                    key={index}
                    task={task}
                    openEditTaskModal={openEditTaskModal}
                    onAcceptTask={onAcceptTask}
                    onDeclineTask={onDeclineTask}
                  />
                );
              }
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};
