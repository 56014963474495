import React from "react";
import { IconType, TextType } from "../../../types/commonTypes";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import { NumberInput } from "../../Common/NumberInput/NumberInput";
import {HelpText} from "../../Common/TextComponents/HelpText";

export const AddReservationPricesFees: React.FC<{
  Icon: IconType;
  label: TextType;
  value: number;
  error?: string;
  disabled?: boolean;
  onChange: (value: number) => void;
  currency?: string;
  helpText?: string;
}> = ({ Icon, label, value, error, disabled, onChange, currency = "€", helpText}) => {
  return (
    <div className="flex flex-col">
      <div className="flex items-center gap-2 w-full justify-between">
        <div>
          <div className={"flex items-center gap-2"}>
            <Icon className="w-5 h-5" />
            <p className="flex-1 font-semibold text-high-contrast">{label}</p>
          </div>
          {helpText && (
              <HelpText>{helpText}</HelpText>
          )}
        </div>

        <div className="pr-2">
          <NumberInput
            size="large"
            currency={currency}
            value={value}
            min={0}
            error={error}
            onChangeText={(nextValue) => onChange(Number(nextValue))}
            disabled={disabled}
          />
        </div>
      </div>

      <ErrorMessage errorType="FORM">{error}</ErrorMessage>
    </div>
  );
};
