import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {CenterModal} from "../../../Common/CenterModal/CenterModal";
import {Button} from "../../../Common/Button/Button";
import {del} from "../../../../helpers/APIHelper";
import paths from "../../../../constants/paths";
import {ModalContractsDeleteData} from "../../../../pages/RentalPage/RentalPage.type";

interface DeleteResourceModalProps {
  isVisible: boolean;
  onClose: () => void;
  data: ModalContractsDeleteData | null | undefined;
  rentalId: string | undefined;
  onSuccess: () => Promise<void>;
  defaultSelectedContract: number | undefined;
}

const DeleteContractsModal = ({
  isVisible,
  onClose,
  data,
  rentalId,
  onSuccess,
  defaultSelectedContract,
}: DeleteResourceModalProps) => {
  const {resource, onCloseFromModal} = data || {};
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const contract = resource;

  const handleDeleteContract = async () => {
    setLoading(true);
    if (contract && contract?.id !== defaultSelectedContract) {
      const response = await del(
        `${
          import.meta.env.VITE_API_URL
        }${paths.API.CONTRACTS_DOCS.DELETE_CONTRACT_BY_RENTAL(
          Number(rentalId),
          contract.id
        )}`
      );
      if (response?.data?.success) {
        if (onCloseFromModal) {
          onCloseFromModal();
        }
        onClose();
        onSuccess();
      }
    }
    setLoading(false);
  };

  return (
    <CenterModal
      title={t("Rental.Contracts.Modal.DeleteModal.title")}
      isVisible={isVisible}
      onClose={onClose}
    >
      <div className="flex flex-col space-y-6">
        <span className="text-low-contrast text-base">
          {t("Rental.Contracts.Modal.DeleteModal.description")}
        </span>
        <div className="flex flex-1 gap-x-3 justify-between">
          <Button type="secondary" onClick={onClose} disabled={loading}>
            {t("Global.cancel")}
          </Button>
          <Button type="alert" loading={loading} onClick={handleDeleteContract}>
            {t("UserExperience.delete")}
          </Button>
        </div>
      </div>
    </CenterModal>
  );
};

export default DeleteContractsModal;
