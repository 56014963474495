import React from "react";

export interface PolicyPlatformCardProps {
    children: React.ReactNode;
}

export default function PolicyPlatformCard({children}: PolicyPlatformCardProps)
{
    return (
        <div className={"flex flex-col p-4 gap-2 border-1 rounded-6px bg-subtle border-element-border"}>
            {children}
        </div>
    );
}