import React, { useEffect, useState } from "react";
import { BookingTaskPageProps } from "./BookingTaskListPage.type";
import { BookingLayout } from "../../components/Layout/BookingLayout/BookingLayout";
import { Button } from "../../components/Common/Button/Button";
import { useTranslation } from "react-i18next";

import PlusIcon from "../../assets/icons/plus-circle-white.svg?react";
import { useParams } from "react-router-dom";
import { ReservationResponse, TaskItemResponse } from "../../types/GETTypes";
import { useTablePage } from "../../hooks/useTablePage";
import paths from "../../constants/paths";
import { fetchOneReservation } from "../../helpers/reservationHelper";
import { TaskList } from "../../components/Management/TaskList/TaskList";
import { RightModal } from "../../components/Common/RightModal/RightModal";
import AddTaskModal from "../../components/Management/AddTask/AddTaskModal";
import { useModal } from "../../hooks/useModal";
import { EditTaskScheduled } from "../../components/Management/EditTaskScheduled/EditTaskScheduled";
import { EditTaskCanceled } from "../../components/Management/EditTaskCanceled/EditTaskCanceled";
import { CancelTask } from "../../components/Management/CancelTask/CancelTask";
import { EditTaskStarted } from "../../components/Management/EditTaskStarted/EditTaskStarted";
import { EditTaskDone } from "../../components/Management/EditTaskDone/EditTaskDone";
import { EditTaskEditComplete } from "../../components/Management/EditTaskEditComplete/EditTaskEditComplete";
import { AcceptTaskModal } from "../../components/Management/AcceptTaskModal/AcceptTaskModal";
import { DeclineTaskModal } from "../../components/Management/DeclineTaskModal/DeclineTaskModal";
import { NoTaskInBooking } from "../../components/Management/TaskList/NoTaskInBooking";
import { ValueType } from "../../types/commonTypes";

export const BookingTaskListPage: React.FC<BookingTaskPageProps> = () => {
  const { t } = useTranslation();

  const { reservationId } = useParams();
  const [reservation, setReservation] = useState<ReservationResponse>();
  const tableMessages = useTablePage(
    `${import.meta.env.VITE_API_URL}${paths.API.MESSAGES}/${reservationId}`,
    "messages",
    { combine: true, reverseData: true }
  );

  const handleReservationSuccess = (value: ReservationResponse) => {
    setReservation(value);
  };

  useEffect(() => {
    if (reservationId) {
      fetchOneReservation(reservationId, handleReservationSuccess, () => {});

      tableMessages.fetch({
        limit: 10,
        sort_field: "captured_at",
        sort_direction: "desc",
      });
    }
  }, [reservationId]);

  const getActions = () => {
    return (
      <div>
        <Button RightIcon={PlusIcon} onClick={handleOpenCreateModal}>
          {t("Booking.addTask")}
        </Button>
      </div>
    );
  };

  const createModal = useModal<{
    reservationId: ValueType | undefined | null;
    rentalId: ValueType | undefined | null;
  }>();
  const editTaskScheduled = useModal<TaskItemResponse>();
  const editTaskStarted = useModal<TaskItemResponse>();
  const cancelTaskModal = useModal<TaskItemResponse>();
  const editTaskCanceled = useModal<TaskItemResponse>();
  const editTaskDone = useModal<TaskItemResponse>();
  const editTaskEditComplete = useModal<TaskItemResponse>();
  const acceptTaskModal = useModal<TaskItemResponse>();
  const declineTaskModal = useModal<TaskItemResponse>();

  const handleOpenCreateModal = () => {
    createModal.open({
      reservationId: reservation?.id,
      rentalId: reservation?.rental?.id,
    });
  };

  const openEditTaskModal = (task: TaskItemResponse) => {
    if (task.status === "ASSIGNED") editTaskScheduled.open(task);
    else if (task.status === "STARTED") editTaskStarted.open(task);
    else if (task.status === "COMPLETED") editTaskDone.open(task);
    else if (task.status === "CANCELED") editTaskCanceled.open(task);
  };

  const onCloseModal = () => {
    createModal.close();
  };

  const onSuccess = () => {
    self.location.reload();
  };

  const handleStart = (task: TaskItemResponse) => {
    editTaskScheduled.close();
    editTaskStarted.open(task);

    self.location.reload();
  };

  const handleCancel = (task: TaskItemResponse) => {
    cancelTaskModal.open(task);
  };

  return (
    <>
      <RightModal
        title={t("Task.Modal.titleBooking")}
        classNames={{
          mainContentParent: "overflow-y-hidden pe-0",
        }}
        isVisible={createModal.isVisible}
        onClose={onCloseModal}
      >
        <AddTaskModal
          onClose={onCloseModal}
          onSuccess={onSuccess}
          task={undefined}
          reservationData={createModal.data}
        />
      </RightModal>

      <EditTaskScheduled
        task={editTaskScheduled.data!}
        isVisible={editTaskScheduled.isVisible}
        onClose={editTaskScheduled.close}
        onSuccess={onSuccess}
        onStart={handleStart}
        onCancel={handleCancel}
      />

      <EditTaskCanceled
        task={editTaskCanceled.data!}
        isVisible={editTaskCanceled.isVisible}
        onClose={editTaskCanceled.close}
      />

      <CancelTask
        task={cancelTaskModal.data}
        isVisible={cancelTaskModal.isVisible}
        onClose={cancelTaskModal.close}
        onSuccess={() => {
          onSuccess();
          editTaskScheduled.close();
        }}
      />

      <EditTaskStarted
        task={editTaskStarted.data}
        isVisible={editTaskStarted.isVisible}
        onClose={editTaskStarted.close}
        onSuccess={onSuccess}
      />

      <EditTaskDone
        task={editTaskDone.data}
        isVisible={editTaskDone.isVisible}
        onClose={editTaskDone.close}
        onComplete={(task) => {
          editTaskDone.close();
          editTaskEditComplete.open(task!);
        }}
      />

      <EditTaskEditComplete
        task={editTaskEditComplete.data}
        isVisible={editTaskEditComplete.isVisible}
        onClose={(task) => {
          editTaskEditComplete.close();
          editTaskDone.open(task!);
        }}
        onSuccess={(task) => {
          onSuccess();
          editTaskEditComplete.close();
          editTaskDone.open(task!);
        }}
      />

      <AcceptTaskModal
        task={acceptTaskModal.data}
        isVisible={acceptTaskModal.isVisible}
        onClose={acceptTaskModal.close}
        onSuccess={onSuccess}
      />

      <DeclineTaskModal
        task={declineTaskModal.data}
        isVisible={declineTaskModal.isVisible}
        onClose={declineTaskModal.close}
        onSuccess={onSuccess}
      />

      <BookingLayout
        navbarActiveItem="tasks"
        rightChildren={getActions()}
        reservation={reservation}
      >
        {reservation && reservation.tasks?.total > 0 && (
          <TaskList
            tasksListByDate={reservation.tasks.tasks}
            onAcceptTask={acceptTaskModal.open}
            onDeclineTask={declineTaskModal.open}
            openEditTaskModal={openEditTaskModal}
          />
        )}

        {reservation && reservation.tasks.total === 0 && (
          <NoTaskInBooking onAdd={handleOpenCreateModal} />
        )}
      </BookingLayout>
    </>
  );
};
