import React from "react";
import LayoutBlockComponent from "../Common/LayoutBlockComponent";
import {useTranslation} from "react-i18next";
import {GuestPageConnectResponse} from "../../../types/GETTypes";
import {tabType} from "../GuestPageHome/GuestPageHomeComponent";
import {Button} from "../../Common/Button/Button";
import useGuestPageDataStore from "../../../stores/guestPageStore/useGuestPageDataStore";
import MapComponent from "../Common/MapComponent";
import CodeComponent from "./CodeComponent";
import {replaceWithBr} from "../../../helpers/stringHelper";
import useWindowSize from "../../../hooks/useWindowSize";
import ArrowRightWhiteIcon from "../../../assets/icons/arrow-right-white.svg?react";
import ArrowRightIcon from "../../../assets/icons/arrow-right.svg?react";

const InstructionCheckinComponent = ({
  onChangeTab,
}: {
  onChangeTab: ((tab: tabType) => void) | undefined;
}) => {
  const {t} = useTranslation();
  const {isMobile} = useWindowSize();
  const {guestPageData, isPreviewMode, rentalPreview, userPreview} =
    useGuestPageDataStore();
  const {
    instructions,
    rental,
    welcome_guide_enabled,
    guest: guestData,
    instructions_enabled: guestPageInfosEnabled,
  } = guestPageData || {};
  const {
    guest_page: {
      instructions: instructionsPreview,
      welcome_guide_enabled: welcomeGuideEnabledPreview,
      infos_enabled: guestPageInfosEnabledPreview,
    } = {},
  } = rentalPreview || {};
  const instructionsData = isPreviewMode ? instructionsPreview : instructions;
  const instruction = instructionsData?.find(
    (instr) => instr.type === "arrival_instruction"
  );
  const {content, infos_enabled, key_box_code, entry_code} = instruction || {};
  const guest = isPreviewMode ? userPreview : guestData;
  const savedLanguage = localStorage.getItem("language");
  const lang = (savedLanguage ?? guest?.locale ?? "en")?.toLowerCase();
  const contentTrad = content?.[lang]
    ? content[lang]
    : content && content["en"]
    ? content["en"]
    : "";
  const {address, postal_code, city} = rental || {};
  const addressMap = `${address} ${postal_code} ${city}`;
  const welcomeGuideEnabled = isPreviewMode
    ? welcomeGuideEnabledPreview
    : welcome_guide_enabled;

  const instructionsEnabled = isPreviewMode
    ? guestPageInfosEnabledPreview
    : guestPageInfosEnabled;

  return instructionsEnabled && infos_enabled ? (
    <LayoutBlockComponent
      title={t("GuestPage.Reservation.instructionCheckin")}
      icon={"🗝️"}
      displayTitles
      blockStyle={{
        childrenDivStyle: "px-1 pl-2 md:flex-row items-start mb-0 md:pb-4",
        parentDivStyle: "md:w-full",
        titleStyle: "md:text-[32px]",
      }}
    >
      <div
        className="flex flex-1 flex-col md:w-1/2 md:mr-8 justify-between"
        style={{height: isMobile ? "200px" : "335px"}}
      >
        {entry_code || key_box_code ? (
          <div className="flex flex-row md:mb-2 md:text-lg  md:justify-between pb-4 md:pb-0">
            <div className="w-1/2 md:w-auto mr-1">
              <CodeComponent
                label={t("GuestPage.Reservation.codeBuilding")}
                code={entry_code ?? ""}
              />
            </div>
            <div className="w-1/2 md:w-auto ml-1">
              <CodeComponent
                label={t("GuestPage.Reservation.codeKeys")}
                code={key_box_code ?? ""}
              />
            </div>
          </div>
        ) : null}
        <p
          className="text-high-contrast text-base md:w-full line-clamp-instruction md:text-lg mt-0 md:mt-2"
          dangerouslySetInnerHTML={{__html: replaceWithBr(contentTrad)}}
        />
        {!isMobile ? (
          <div className="flex flex-row justify-between space-x-4 my-2">
            {welcomeGuideEnabled ? (
              <Button
                RightIcon={ArrowRightIcon}
                type="secondary"
                displayLargeBtn={false}
                textClassName="md:text-lg"
                buttonClassName="min-h-[50px] h-auto mb-2"
                onClick={() => onChangeTab && onChangeTab("welcomeGuide")}
              >
                {t("GuestPage.Reservation.welcomeGuide")}
              </Button>
            ) : null}
            <Button
              RightIcon={ArrowRightWhiteIcon}
              displayLargeBtn={false}
              textClassName="md:text-lg"
              buttonClassName="min-h-[50px] h-auto mb-2"
              onClick={() => onChangeTab && onChangeTab("instructionCheckin")}
            >
              {t("GuestPage.Reservation.instructionsDetails")}
            </Button>
          </div>
        ) : null}
      </div>
      {isMobile ? (
        <>
          <Button
            RightIcon={ArrowRightWhiteIcon}
            displayLargeBtn={false}
            className="w-full"
            buttonClassName=" w-full  md:w-auto md:h-auto mt-4 mb-2"
            textClassName="md:text-xl"
            onClick={() => onChangeTab && onChangeTab("instructionCheckin")}
          >
            {t("GuestPage.Reservation.instructionsDetails")}
          </Button>
        </>
      ) : null}
      <MapComponent
        address={addressMap}
        fallbackAddress={`${city} ${postal_code}`}
        mapContainerStyle={{
          height: isMobile ? "200px" : "300px",
          width: isMobile ? "100%" : "90%",
        }}
      />
      {isMobile && welcomeGuideEnabled ? (
        <Button
          RightIcon={ArrowRightIcon}
          type="secondary"
          displayLargeBtn={false}
          className="mr-1 w-full mb-2"
          buttonClassName="w-full md:w-auto md:h-auto mt-2 mb-2"
          textClassName="md:text-xl"
          onClick={() => onChangeTab && onChangeTab("welcomeGuide")}
        >
          {t("GuestPage.Reservation.welcomeGuide")}
        </Button>
      ) : null}
    </LayoutBlockComponent>
  ) : null;
};

export default InstructionCheckinComponent;
