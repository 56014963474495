import {Elements} from "@stripe/react-stripe-js";
import {
  PaymentMethodCreateParams,
  Stripe,
  StripeElementsOptions,
} from "@stripe/stripe-js";
import {t} from "i18next";
import React from "react";
import CheckCircleGreenIcon from "../../assets/icons/check-circle-green.svg?react";
import CheckDoubleGreenIcon from "../../assets/icons/check-double-green.svg?react";
import {
  PaymentReservationStatusEnum,
  ReservationExtraOrderStatusEnum,
} from "../../enums/GETenums";
import {PaymentReservationCheckoutForm} from "./PaymentReservationCheckoutForm";

export type StepForm =
  | "list"
  | "paymentForm"
  | "depositForm"
  | "extraForm"
  | "websitePaymentForm"
  | "websiteExtrasPaymentForm"
  | "facture" |
  'classicForm';
type PaymentReservationFormOrInfoProps = {
  status:
    | PaymentReservationStatusEnum
    | ReservationExtraOrderStatusEnum
    | undefined;
  amount: number | undefined;
  stripeOptions: StripeElementsOptions | undefined;
  stripePromise: Promise<Stripe | null> | undefined;
  stripeClientSecret: string | undefined;
  billingDetails: PaymentMethodCreateParams.BillingDetails | undefined;
  onValidatePayment: (
    status:
      | PaymentReservationStatusEnum
      | ReservationExtraOrderStatusEnum
      | undefined,
    response?: any
  ) => void | null;
  from?: StepForm;
  displayModalSuccessGuestPage?: boolean;
  onAction?: () => void;
  onCloseSuccess?: () => void;
};

export const PaymentReservationFormOrInfo: React.FC<
  PaymentReservationFormOrInfoProps
> = ({
  status,
  amount,
  stripeOptions,
  stripePromise,
  stripeClientSecret,
  billingDetails,
  onValidatePayment,
  from,
  displayModalSuccessGuestPage = false,
  onAction,
  onCloseSuccess,
}) => {
  const getTitle = () => {
    let title: string = t(
      "Payments.PaymentReservation.successPaymentValidated"
    );
    if (status === PaymentReservationStatusEnum.PAID) {
      title = t("Payments.PaymentReservation.successPaymentValidated");
    } else if (status === PaymentReservationStatusEnum.REFUNDED) {
      title = t("Payments.PaymentReservation.refundPayment");
    }
    return title;
  };

  const getDescription = () => {
    let description: string = t(
      "Payments.PaymentReservation.successInfoPaymentValidated",
      {
        amount: amount,
        currency: t("Global.currencySymbol"),
      }
    );
    if (status === PaymentReservationStatusEnum.PAID) {
      description = t(
        "Payments.PaymentReservation.successInfoPaymentValidated",
        {
          amount: amount,
          currency: t("Global.currencySymbol"),
        }
      );
    } else if (status === PaymentReservationStatusEnum.REFUNDED) {
      description = t("Payments.PaymentReservation.refundInfoPayment", {
        amount: amount,
        currency: t("Global.currencySymbol"),
      });
    }
    return description;
  };

  return (
    <>
      {status === PaymentReservationStatusEnum.PAID ||
      status === PaymentReservationStatusEnum.REFUNDED ? (
        <div className="flex flex-col items-center w-full p-6 space-y-4 rounded-lg bg-element-background">
          {status === PaymentReservationStatusEnum.PAID ? (
            <CheckCircleGreenIcon width={40} height={40} />
          ) : null}
          {status === PaymentReservationStatusEnum.REFUNDED ? (
            <CheckDoubleGreenIcon width={40} height={40} />
          ) : null}
          <h3 className="text-xl font-bold text-success">{getTitle()}</h3>
          <p className="font-light text-low-contrast text-md">
            {getDescription()}
          </p>
        </div>
      ) : (
        <>
          {stripeOptions ? (
            <Elements
              stripe={stripePromise!}
              options={stripeOptions}
              // key={stripeClientSecret}
            >
              <PaymentReservationCheckoutForm
                amount={amount}
                billingDetails={billingDetails!}
                onValidatePayment={onValidatePayment}
                from={from}
                displayModalSuccessGuestPage={displayModalSuccessGuestPage}
                onAction={onAction}
                onCloseSuccess={onCloseSuccess}
              />
            </Elements>
          ) : null}
        </>
      )}
    </>
  );
};
