import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SendMoneyIcon from "../../../../assets/icons/send-money-2.svg?react";
import paths from "../../../../constants/paths";
import { get } from "../../../../helpers/APIHelper";
import { useModal } from "../../../../hooks/useModal";
import { DirectAndWebsitePolicyResponse } from "../../../../types/GETTypes";
import { Card } from "../../../Common/Card/Card";
import { FlexCol } from "../../../Common/FlexComponents/FlexCol";
import DirectPolicyModal from "../../../RentalList/Policies/Modals/DirectPolicyModal";
import AirbnbPlatformPolicies from "../../../RentalList/Policies/Platforms/AirbnbPlatformPolicies";
import BookingPlatformPolicies from "../../../RentalList/Policies/Platforms/BookingPlatformPolicies";
import DirectPlatformPolicies from "../../../RentalList/Policies/Platforms/DirectPlatformPolicies";
import { RentalPaymentPolicyCardProps } from "./PaymentPolicy.type";
import { RentalPaymentPolicyCardSkeleton } from "./PaymentPolicyCardSkeleton";

/**
 * RentalPaymentPolicyCard component displays the rental payment policy information.
 * It dynamically fetches and updates policy data and provides modals for direct policies.
 *
 * @component
 * @param {RentalPaymentPolicyCardProps} props - Component props.
 * @returns {JSX.Element} A card displaying rental payment policies.
 */
export const RentalPaymentPolicyCard: React.FC<
  RentalPaymentPolicyCardProps
> = ({ loading, rental }) => {
  const directModal = useModal<{ policy: DirectAndWebsitePolicyResponse }>();
  const { t } = useTranslation();

  const [directPolicy, setDirectPolicy] =
    useState<DirectAndWebsitePolicyResponse>();
  const [rentalStore, setRentalStore] = useState(rental);

  useEffect(() => {
    setDirectPolicy(rental?.tarification?.payment_policies?.direct_and_website);
    setRentalStore(rental);
  }, [rental]);

  /**
   * Fetches the latest rental data from
   * the API and updates the state.
   */
  const reloadRental = (): void => {
    get(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTAL.FIND_BY_ID(
        Number(rental?.id)
      )}`
    ).then((res) => {
      if (res?.data?.success) {
        setRentalStore(res.data.result);
      }
    });
  };

  if (loading || !directPolicy) return <RentalPaymentPolicyCardSkeleton />;

  return (
    <Card
      loading={loading}
      Icon={SendMoneyIcon}
      label={t("Rental.Pricing.PaymentPolicy.title")}
      anchor="policies-card"
      hasBody={true}
    >
      {!loading && rentalStore ? (
        <FlexCol gap={10}>
          {/* Modals */}
          <DirectPolicyModal
            modal={directModal}
            rental={rentalStore}
            directPolicy={directPolicy}
            setDirectPolicy={setDirectPolicy}
          />

          {/* Policy Cards */}
          <DirectPlatformPolicies
            policy={directPolicy}
            modal={directModal}
            rental={rentalStore}
          />
          {Boolean(
            rental?.details.external_connexions.platform_booking?.enabled
          ) && (
            <BookingPlatformPolicies
              rental={rentalStore}
              reloadRental={reloadRental}
            />
          )}
          {Boolean(
            rental?.details.external_connexions.platform_airbnb?.enabled
          ) && (
            <AirbnbPlatformPolicies
              rental={rentalStore}
              reloadRental={reloadRental}
            />
          )}
        </FlexCol>
      ) : null}
    </Card>
  );
};

export default RentalPaymentPolicyCard;
