import React, {useEffect, useState} from "react";
import {RightModal} from "../../../Common/RightModal/RightModal";
import {UseModal} from "../../../../hooks/useModal";
import {useTranslation} from "react-i18next";
import {Button} from "../../../Common/Button/Button";
import CheckWhiteIcon from "../../../../assets/icons/check-white.svg?react";
import DownloadWhiteIcon from "../../../../assets/icons/download-white.svg?react";
import {convertFileToBlob, downloadFile} from "../../../../helpers/fileHelper";
import {patch, post} from "../../../../helpers/APIHelper";
import paths from "../../../../constants/paths";
import {PictureInput} from "../../../Common/PictureInput/PictureInput";
import {ValueType} from "../../../../types/commonTypes";
import {DocsResponse, File} from "../../../../types/GETTypes";
import PhotoIcon from "../../../../assets/icons/photo.svg?react";
import {cn} from "../../../../helpers/classHelper";
import {CustomPictureInputProps} from "../../../Common/PictureInput/PictureInput.type";
import {Controller, useForm} from "react-hook-form";
import {TextInput} from "../../../Common/TextInput/TextInput";
import {ErrorMessage} from "../../../Common/ErrorMessage/ErrorMessage";
import {ResourceResponse} from "../../../Common/CardDocs/CardDocs.type";
import {DataResourceModal} from "./ContractModal.type";
import moment from "moment";

interface EditResourceModalProps {
  isVisible: boolean;
  onClose: () => void;
  data: DataResourceModal | null | undefined;
  modalDeleteResource: UseModal<DataResourceModal | null | undefined>;
  reservationId: string | undefined;
  onSuccess: () => Promise<void>;
}

const CUSTOMINPUT = React.forwardRef(
  ({disabled, errorFile}: CustomPictureInputProps, ref) => {
    const {t} = useTranslation();
    const fileRef = ref as React.RefObject<HTMLInputElement>;

    if (disabled) {
      return <></>;
    }

    return (
      <div className="flex flex-col">
        <div
          className={cn(
            `flex flex-row items-center justify-center w-full h-10 bg-element-background rounded-6px border-1 border-element-border gap-2`,
            disabled
              ? "bg-element-border/70"
              : "cursor-pointer  hover:bg-element-background-active",
            errorFile ? "border-red-500" : ""
          )}
          onClick={() => (disabled ? {} : fileRef.current?.click())}
        >
          <p className="font-semibold text-high-contrast text-center">
            {t("Global.add")}
          </p>
          <PhotoIcon className="w-6 h-6" />
        </div>
        {errorFile && <ErrorMessage errorType="FORM">{errorFile}</ErrorMessage>}
      </div>
    );
  }
);

const EditResourceModal = ({
  isVisible,
  onClose,
  data,
  modalDeleteResource,
  reservationId,
  onSuccess,
}: EditResourceModalProps) => {
  const resource = data?.resource as DocsResponse;
  const displayMode = data?.displayMode;
  const {t} = useTranslation();
  const [isEditMode, setIsEditMode] = useState<boolean>(
    displayMode === true ? false : true
  );

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [files, setFiles] = useState<Blob[]>([]);
  const [oldFiles, setOldFiles] = useState<File[]>([]);

  const form = useForm<{name: string; file: Blob}>({
    defaultValues: {
      name: "",
      file: "",
    },
  });

  useEffect(() => {
    if (resource && isEditMode) {
      form.setValue("name", resource?.name);
      // setOldFiles([...oldFiles, resource as File]);
    }
  }, [resource, isEditMode]);

  useEffect(() => {
    if(displayMode === true){
      setIsEditMode(false)
    }else {
      setIsEditMode(true)
    }
  },[displayMode])

  const close = () => {
    setError("");
    form.setValue("name", "");
    setOldFiles([]);
    setFiles([]);
    onClose();
  };

  const handleUpdate = async (data: {name: string}) => {
    setError("");
    setLoading(true);
    if (resource) {
      const file = oldFiles
        ? (await convertFileToBlob(oldFiles[0]))
          ? await convertFileToBlob(oldFiles[0])
          : files[0]
          ? files[0]
          : ""
        : "";
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("_method", "PATCH");
      formData.append("file", file);

      const response = await post(
        `${
          import.meta.env.VITE_API_URL
        }${paths.API.CONTRACTS_DOCS.UPDATE_RESOURCE(
          Number(reservationId),
          resource?.id
        )}`,
        formData,
        {
          headers: {
            Accept: "application/json",
          },
        }
      );
      if (response?.data?.success) {
        close();
        onSuccess();
      } else {
        setError(response?.response?.data?.message || response?.message);
      }
    }

    setLoading(false);
  };

  const handleDeleteFile = (fileId: ValueType) => {
    setOldFiles(oldFiles?.filter((f) => f?.id !== fileId));
  };
  const getTitle = () =>
    isEditMode ? t("Rental.Contracts.Modal.EditModal.title") : resource?.name;

  const hasFile = () => {
    if (files.length > 0) {
      return true;
    } else if (oldFiles && oldFiles.length > 0) {
      return true;
    }
    return false;
  };

  const onCancel = () => {
    setError("");
    form.setValue("name", "");
    setOldFiles([]);
    setFiles([]);
    setIsEditMode(false);
  };

  return (
    <RightModal
      title={getTitle()}
      isVisible={isVisible}
      onClose={close}
      classNames={{divParent: "z-[90]"}}
    >
      <div className="flex flex-col space-y-6 w-full">
        <div>
          {isEditMode ? (
            <Controller
              control={form.control}
              name="name"
              rules={{
                required: {
                  value: true,
                  message: t("Rental.Contracts.Contracts.Modal.formRequired"),
                },
              }}
              render={({field: {value, onChange}}) => (
                <TextInput
                  label={t("Rental.Checklist.Upsert.name")}
                  required={true}
                  placeholder={t(
                    "Rental.Contracts.Contracts.Modal.formNamePlaceholder"
                  )}
                  disabled={loading}
                  value={value}
                  error={form.formState.errors.name?.message}
                  onChangeText={onChange}
                />
              )}
            />
          ) : null}
        </div>
        {isEditMode ? (
          <div className="flex flex-col space-y-4">
            <Controller
              control={form.control}
              name="file"
              // rules={{
              //   validate: {
              //     notEmpty: (value) => {
              //       if (!hasFile()) {
              //         return t("Rental.Contracts.Contracts.Modal.formRequired");
              //       }
              //       return true;
              //     },
              //   },
              // }}
              render={({field: {value, onChange}}) => (
                <PictureInput
                  onChangeNewFiles={setFiles}
                  onDeleteFile={handleDeleteFile}
                  newFiles={files}
                  oldFiles={oldFiles}
                  displayCover={false}
                  disabled={loading}
                  disabledCustomInput={hasFile()}
                  activePreviewSystem={false}
                  hideInput
                  CUSTOMINPUT={CUSTOMINPUT}
                  classNames={{
                    divInput: "flex-col",
                    img: "rounded h-50 w-50",
                    divImg: "h-50 w-50",
                  }}
                  errorFile={form.formState.errors.file?.message}
                />
              )}
            />
          </div>
        ) : (
          <div className="flex flex-col space-y-4">
            <img className="rounded h-50" src={`${resource?.cloudfront_url}`} />
            <span>
              {t("Rental.Contracts.Modal.EditModal.importDate", {
                date: resource?.created_at
                  ? moment(resource?.created_at).format("DD/MM/YYYY")
                  : "",
                hour: resource?.created_at
                  ? moment(resource?.created_at).format("HH[h]mm")
                  : "",
              })}
            </span>
          </div>
        )}
        <ErrorMessage>{error}</ErrorMessage>
        <div className="flex flex-1 gap-x-3 justify-between w-full">
          {isEditMode ? (
            <Button type="secondary" onClick={onCancel} disabled={loading}>
              {t("Global.cancel")}
            </Button>
          ) : (
            <Button
              type="secondary"
              onClick={() => setIsEditMode(true)}
              disabled={loading}
            >
              {t("Global.update")}
            </Button>
          )}
          <Button
            type="alert"
            disabled={loading}
            onClick={() =>
              resource &&
              modalDeleteResource.open({
                resource,
                onCloseFromModal: () => close(),
              })
            }
          >
            {t("UserExperience.delete")}
          </Button>
          {isEditMode ? (
            <Button
              type="primary"
              onClick={form.handleSubmit(handleUpdate)}
              loading={loading}
              RightIcon={CheckWhiteIcon}
            >
              {t("Global.record")}
            </Button>
          ) : (
            <Button
              type="primary"
              loading={loading}
              RightIcon={DownloadWhiteIcon}
              onClick={() =>
                downloadFile({
                  original_url: `${resource.original_url}?view=true`,
                  name: resource.name,
                })
              }
            >
              {t("BookingContract.download")}
            </Button>
          )}
        </div>
      </div>
    </RightModal>
  );
};

export default EditResourceModal;
