import React from "react";
import {GuestWebsiteRentalDetailsExtra} from "../../../../../../types/GETTypes";
import useGuestWebsiteStore from "../../../../../../stores/guestWebsiteStore/useGuestWebsiteStore";

interface ExtrasOrderResumeProps extends GuestWebsiteRentalDetailsExtra {
  quantity: number;
}
const ExtrasOrderResumeCard = ({
  photo,
  name,
  description,
  quantity,
  unit_price,
}: ExtrasOrderResumeProps) => {
  const {dataGuestWebsite} = useGuestWebsiteStore()
  const {styles} = dataGuestWebsite ||  {};
  const {color_text} = styles ||  {};
  return (
    <div className="flex space-x-4 justify-between">
      <div className="flex space-x-4">
        <img
          src={photo && photo?.length > 0 ? photo?.[0].original_url ?? "" : ""}
          alt={name["fr"] ?? ""}
          className="w-[96px] h-[96px] md:w-[150px] md:h-[150px] rounded-md object-cover cursor-pointer"
          style={{color:color_text}}
        />
        <div className="flex flex-col justify-center">
          <h2 className=" font-semibold text-base md:text-lg w-[80%] md:w-auto" style={{color:color_text}}>
            {name["fr"] ?? ""}
          </h2>
          <p className="text-low-contrast text-xs md:text-sm w-[80%] md:w-auto" >
            {description["fr"] ?? ""}
          </p>
        </div>
      </div>
      <div className="flex items-center justify-end w-1/3">
        <span
          id="quantity"
          className=" font-bold text-lg md:text-xl"
          style={{color:color_text}}
        >
          {quantity} x {unit_price} €
        </span>
      </div>
    </div>
  );
};

export default ExtrasOrderResumeCard;
