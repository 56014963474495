import React from "react";
import { useTranslation } from "react-i18next";

import MonitorIcon from "../../assets/icons/monitor.svg?react";
import PlusCircleWhiteIcon from "../../assets/icons/plus-circle-white.svg?react";
import { Button } from "../Common/Button/Button";

export const WebsiteListNoData: React.FC<{ onAdd: () => void }> = ({
  onAdd,
}) => {
  const { t } = useTranslation();

  return (
    <div className=" w-full h-full bg-element-background flex flex-col gap-2 items-center justify-center mt-4">
      <MonitorIcon className="size-12" />

      <p className="text-high-contrast font-semibold">
        {t("Website.List.NoData.title")}
      </p>

      <p className="text-low-contrast font-light">
        {t("Website.List.NoData.description")}
      </p>

      <div>
        <Button RightIcon={PlusCircleWhiteIcon} onClick={onAdd}>
          {t("Website.List.NoData.button")}
        </Button>
      </div>
    </div>
  );
};
