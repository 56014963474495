import { t } from "i18next";
import React from "react";
import { UseModal } from "../../hooks/useModal";
import { ValueType } from "../../types/commonTypes";
import { Button } from "../Common/Button/Button";
import { SelectModal } from "../Common/SelectModal/SelectModal";

type RentalListMultipleSelectionModalProps = {
  modal: UseModal<ValueType[]>;
  onClose: () => void;
  onTriggerDeactivate: () => void;
  onTriggerRemove: () => void;
};

export const RentalListMultipleSelectionModal: React.FC<
  RentalListMultipleSelectionModalProps
> = ({ modal, onClose, onTriggerDeactivate, onTriggerRemove }) => {
  return (
    <SelectModal
      isVisible={modal.isVisible}
      title={t("RentalList.SelectModal.title", {
        count: modal.data?.length,
      })}
      onClose={onClose}
    >
      <div className="flex space-x-4">
        <Button type="secondary" onClick={onTriggerDeactivate}>
          {t("Global.deactivate")}
        </Button>

        <Button type="destructive" onClick={onTriggerRemove}>
          {t("Global.remove")}
        </Button>
      </div>
    </SelectModal>
  );
};
