import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ArrowLeftIcon from "../../../../assets/icons/arrow-left.svg?react";
import CheckIcon from "../../../../assets/icons/check-white.svg?react";
import EditIcon from "../../../../assets/icons/edit.svg?react";
import FileTextIcon from "../../../../assets/icons/file-text.svg?react";
import PlusIcon from "../../../../assets/icons/plus.svg?react";
import { cn } from "../../../../helpers/classHelper";
import { getCurrentCanEdit } from "../../../../helpers/workspaceHelper";
import {
  useRentalInfosDescriptionUpdate,
  useRentalWebsiteDescriptionUpdate,
} from "../../../../hooks/api/rental";
import useUserStore from "../../../../stores/useUserStore";
import { Card } from "../../../Common/Card/Card";
import { ErrorMessage } from "../../../Common/ErrorMessage/ErrorMessage";
import {
  RentalDescriptionCardProps,
  RentalDescriptionForm,
} from "./DescriptionCard.type";
import { RentalDescriptionCardSkeleton } from "./DescriptionCardSkeleton";

export const RentalDescriptionCard: React.FC<RentalDescriptionCardProps> = ({
  loading,
  rentalId,
  part,
  infosDescriptions,
  websiteDescription,
  workspaceOwner,
  onUpdate,
  onError,
}) => {
  const userStore = useUserStore();
  const { t } = useTranslation();

  const form = useForm<RentalDescriptionForm>({
    mode: "onChange",
    defaultValues: {
      description: null,
    },
  });

  const [loadingValidation, setLoadingValidation] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);

  /**
   * Updates the description field in the form based on the current part and available descriptions.
   *
   * The function checks the value of the `part` variable and updates the form's description field
   * accordingly. It handles three cases:
   * 1. If `part` is "infos" and there are available `infosDescriptions`, it sets the description
   *    to the first `infosDescription`.
   * 2. If `part` is "website" and a `websiteDescription` is available, it sets the description
   *    to the `websiteDescription`.
   * 3. If `part` is "website", no `websiteDescription` is available, but there are `infosDescriptions`,
   *    it sets the description to the first `infosDescription`.
   *
   * Note: The function assumes that `form.setValue` is a method to update the form's description field.
   *
   */
  const updateDescription = () => {
    if (part === "infos" && infosDescriptions && infosDescriptions.length > 0) {
      // TODO [RentalDescriptionCard] Add translation support for the descriptions.
      form.setValue("description", infosDescriptions[0].description!.trim());
    } else if (part === "website" && typeof websiteDescription === "string") {
      form.setValue("description", websiteDescription.trim());
    } else if (
      part === "website" &&
      !websiteDescription &&
      infosDescriptions &&
      infosDescriptions.length > 0
    ) {
      form.setValue("description", infosDescriptions[0].description!.trim());
    }
  };

  useEffect(() => {
    updateDescription();
  }, [infosDescriptions, websiteDescription, part]);

  /**
   * Handles the editing of the description based on the current edit mode and part.
   * If in edit mode, it updates the description for either rental infos or website.
   * If not in edit mode, it enables the edit mode.
   */
  const handleDescriptionEdit = () => {
    if (editMode) {
      if (part === "infos") {
        useRentalInfosDescriptionUpdate({
          rentalId,
          data: {
            description: form.getValues("description")!.trim(),
          },
          onSuccess: (rentalDescription) => {
            onUpdate(rentalDescription.description!.trim());
            setEditMode(!editMode);
          },
          onError: (message) => onError(message),
          onStart: () => {
            onError(null);
            setLoadingValidation(true);
          },
          onEnd: () => {
            setLoadingValidation(false);
          },
        });
      } else if (part === "website") {
        useRentalWebsiteDescriptionUpdate({
          rentalId,
          data: {
            description: form.getValues("description")!.trim(),
          },
          onSuccess: (rentalWebsite) => {
            onUpdate(
              !form.getValues("description") &&
                infosDescriptions &&
                infosDescriptions.length > 0
                ? infosDescriptions[0].description!.trim()
                : rentalWebsite.description.trim()
            );
            setEditMode(!editMode);
          },
          onError: (message) => onError(message),
          onStart: () => {
            onError(null);
            setLoadingValidation(true);
          },
          onEnd: () => {
            setLoadingValidation(false);
          },
        });
      }
    } else {
      setEditMode(true);
    }
  };

  const descriptionRef = useRef<HTMLTextAreaElement | null>(null);
  const { ref, ...restDescriptionRegister } = form.register("description", {
    required: {
      value: false,
      message: t("Global.Errors.requiredField", {
        fieldName: t("Rental.Infos.Description.title"),
      }),
    },
    validate: (value) => handleValidateForm(value),
  });

  useEffect(() => {
    if (editMode) {
      if (descriptionRef.current) {
        descriptionRef.current.style.height =
          descriptionRef.current.scrollHeight + "px";
      }
    }
  }, [editMode, descriptionRef.current]);

  /**
   * Validates a form input based on the specified part and existing descriptions.
   *
   * @param value - The input value to validate. Can be a string or null.
   * @returns A boolean indicating whether the input value is valid.
   *
   * The function checks the following conditions:
   * - If the value is null or empty, it returns true.
   * - If the part is "infos", it compares the trimmed value with the first description in infosDescriptions.
   * - If the part is "website" and websiteDescription is not available, it compares the trimmed value with the first description in infosDescriptions.
   * - If the part is "website" and websiteDescription is available, it compares the trimmed value with websiteDescription.
   * - Returns false if none of the conditions are met.
   */
  const handleValidateForm = (value: string | null) => {
    if (!value) {
      return true;
    } else if (
      part === "infos" &&
      value &&
      infosDescriptions &&
      infosDescriptions.length > 0
    ) {
      return Boolean(
        value!.trim() !== infosDescriptions[0].description?.trim()
      );
    } else if (
      part === "website" &&
      value &&
      !websiteDescription &&
      infosDescriptions &&
      infosDescriptions.length > 0
    ) {
      return Boolean(value.trim() !== infosDescriptions[0].description?.trim());
    } else if (part === "website" && value && websiteDescription) {
      return Boolean(value.trim() !== websiteDescription.trim());
    }

    return false;
  };

  const handleCancel = () => {
    setEditMode(false);
    updateDescription();
  };

  const isEditButtonDisabled = () => {
    if (!getCurrentCanEdit({ user: userStore.user, workspaceOwner }))
      return true;

    return editMode && (!form.formState.isValid || loadingValidation);
  };

  if (loading) return <RentalDescriptionCardSkeleton />;

  form.watch();

  return (
    <Card
      Icon={FileTextIcon}
      label={t("Rental.Infos.Description.title")}
      anchor="description-card"
      loading={loadingValidation}
      hasBody={editMode || Boolean(form.getValues("description"))}
      button={{
        Icon: editMode
          ? CheckIcon
          : Boolean(form.getValues("description"))
          ? EditIcon
          : PlusIcon,
        type: editMode ? "primary" : "secondary",
        label: editMode
          ? t("Global.record")
          : Boolean(form.getValues("description"))
          ? t("Global.edit")
          : t("Global.add"),
        onClick: editMode
          ? form.handleSubmit(handleDescriptionEdit)
          : () => setEditMode(!editMode),
        disabled: isEditButtonDisabled(),
      }}
      secondaryButton={
        editMode
          ? {
              label: t("Global.cancel"),
              LeftIcon: ArrowLeftIcon,
              onClick: () => handleCancel(),
            }
          : undefined
      }
    >
      <div className="flex flex-col gap-y-2">
        {part === "website" && (
          <p className="text-xs text-center select-none text-low-contrast">
            {t("Rental.Website.Description.infos")}
          </p>
        )}
        {editMode ? (
          <>
            <textarea
              ref={(e) => {
                ref(e);
                descriptionRef.current = e;
              }}
              className="w-full p-2 rounded resize-y text-high-contrast border-1 border-element-border focus:outline-none h-max"
              disabled={loadingValidation}
              {...restDescriptionRegister}
            ></textarea>
            <ErrorMessage errorType="FORM">
              {form.formState.errors.description?.message}
            </ErrorMessage>
          </>
        ) : (
          <p
            className={cn("text-low-contrast whitespace-break-spaces", {
              "text-base": part === "website",
            })}
          >
            {form.getValues("description")}
          </p>
        )}
      </div>
    </Card>
  );
};
