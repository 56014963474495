import { useTranslation } from "react-i18next";

export default function useText(prefix: string) {
  const { t } = useTranslation();

  const getText = (text: string, params = {}): string => {
    return t(prefix + "." + text, params);
  };

  return {
    getText,
  };
}
