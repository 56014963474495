import React from "react";
import {CarouselWizardProps, CarouselWizardStep} from "./CarouselWizard.type";

import ChevronUpIcon from "../../../assets/icons/chevron-up.svg?react";
import {CarouselWizardStepHeader} from "./CarouselWizardStepHeader";
import {CarouselWizardUnknownStep} from "./CarouselWizardUnknowsStep";
import {cn} from "../../../helpers/classHelper";

export const CarouselWizard: React.FC<CarouselWizardProps> = ({
  currentStepIndex = -1,
  steps = [],
  disableClickBefore = false,
  stepsUnlocked = false,
  loading = false,
  onChangeStep = (step) => {},
  isValidated = true,
  disableHeader = false,
  classNames,
  hideUnknown = false,
}) => {
  const UnknownStep: CarouselWizardStep = {
    title: hideUnknown ? "" : "Unknown",
    Component: hideUnknown ? null : CarouselWizardUnknownStep,
  };
  const currentStep =
    currentStepIndex >= 0 && currentStepIndex < steps.length
      ? steps[currentStepIndex]
      : UnknownStep;

  if (loading)
    return (
      <div className="border-1 border-element-border rounded-6px first:border-t-0">
        {steps.map((step, index) => {
          return (
            <CarouselWizardStepHeader
              key={index}
              title={step.title}
              stepNumber={index + 1}
              isValidated={isValidated}
              onClick={() => {}}
              disableHeader={disableHeader}
            />
          );
        })}
      </div>
    );

  return (
    <div className="border-1 border-element-border rounded-6px first:border-t-0">
      {/* Start carousel steps */}
      {steps.map((step, index) => {
        if (index < currentStepIndex) {
          return (
            <CarouselWizardStepHeader
              key={index}
              title={step.title}
              stepNumber={index + 1}
              isValidated={true}
              onClick={
                disableClickBefore ? undefined : () => onChangeStep(index)
              }
            />
          );
        }
      })}

      {/* Body carousel step */}
      <div>
        {currentStepIndex + 1 > steps?.length && hideUnknown ? (
          <></>
        ) : (
          <>
            {/* Header */}
            <div
              className={`border-y-1 border-element-border px-2 py-3 flex flex-row items-center space-x-2 ${
                currentStepIndex === 0 && "rounded-t-6px"
              }`}
            >
              <div className="flex items-center justify-center w-6 h-6 rounded-full bg-element-background">
                <p className="font-semibold text-high-contrast">
                  {currentStepIndex + 1}
                </p>
              </div>

              <p className="flex-1 font-semibold text-high-contrast">
                {currentStep.title}
              </p>

              <ChevronUpIcon />
            </div>

            {/* Body */}
            <div className={cn("px-6 py-4", classNames?.body)}>
              {currentStep?.Component && <currentStep.Component />}
              {currentStep?.Node !== null && currentStep.Node}
            </div>
          </>
        )}
      </div>

      {/* End carousel steps */}
      {steps.map((step, index) => {
        if (index > currentStepIndex) {
          return (
            <CarouselWizardStepHeader
              key={index}
              title={step.title}
              stepNumber={index + 1}
              onClick={stepsUnlocked ? () => onChangeStep(index) : undefined}
            />
          );
        }
      })}
    </div>
  );
};
