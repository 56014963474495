import React, {ReactNode, useEffect, useState} from "react";
import {BookingLayout} from "../../components/Layout/BookingLayout/BookingLayout";
import {BookingContractPageProps} from "./BookingContractPage.type";
import {TableList} from "../../components/Common/TableList/TableList";
import {BookingContractDocsTableHeader} from "./BookingContractDocsTableHeader";
import {BookingContractDocsItem} from "./BookingContractDocsItem";
import {useTablePage} from "../../hooks/useTablePage";
import paths from "../../constants/paths";
import {useParams} from "react-router-dom";
import {IconType, ValueType} from "../../types/commonTypes";
import {BookingContractDocsSkeleton} from "./BookingContractDocsSkeleton";
import {
  ContractListResponse,
  ContractResponse,
  File,
  LanguageResponse,
  ReservationResponse,
  UserResponse,
} from "../../types/GETTypes";
import {downloadFile} from "../../helpers/fileHelper";
import {fetchOneReservation} from "../../helpers/reservationHelper";
import {ReservationContractDocMenu} from "../../components/Reservation/ContractDocs/Menu/ReservationContractDocMenu";
import ContractCard from "../../components/Reservation/ContractDocs/Contract/ContractCard";
import DocCard from "../../components/Reservation/ContractDocs/Doc/DocCard";
import {useModal} from "../../hooks/useModal";
import DeleteResourceModal from "../../components/Reservation/ContractDocs/Modal/DeleteResourceModal";
import EditResourceModal from "../../components/Reservation/ContractDocs/Modal/EditResourceModal";
import AddResourceModal from "../../components/Reservation/ContractDocs/Modal/AddResourceModal";
import ContractModal from "../../components/Reservation/ContractDocs/Modal/ContractModal";
import SendContractModal from "../../components/Reservation/ContractDocs/Modal/SendContractModal";
import {get} from "../../helpers/APIHelper";
import {getLanguageIcon} from "../../helpers/languageHelper";
import {
  DataContractModal,
  DataResourceModal,
  modeType,
} from "../../components/Reservation/ContractDocs/Modal/ContractModal.type";
import AddContractModal from "../../components/Reservation/ContractDocs/Modal/AddContractModal";
import {useCheckAuth} from "../../hooks/useCheckAuth";

export const BookingContractPage: React.FC<BookingContractPageProps> = () => {
  const {user} = useCheckAuth();
  const {reservationId} = useParams();
  const modalEditResource = useModal<DataResourceModal | null | undefined>();
  const modalDeleteResource = useModal<DataResourceModal | null | undefined>();
  const modalAddResource = useModal<DataResourceModal | null | undefined>();
  const modalContract = useModal<
    | {
        mode: modeType;
        contract: ContractResponse;
      }
    | null
    | undefined
  >();
  const modalAddContract = useModal<unknown>();
  const modalSendContract = useModal<DataContractModal | null | undefined>();
  const [languages, setLanguages] = useState<
    {
      label: string;
      value: string;
      Icon: IconType | undefined;
    }[]
  >([]);

  const [reservation, setReservation] = useState<ReservationResponse>();
  const [userLoaded, setUserLoaded] = useState<UserResponse>();

  const [contractsDocs, setContractsDocs] = useState<ContractListResponse>();

  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (user) {
      fetchData();
    }
  }, [user]);

  async function fetchData() {
    setLoading(true);
    try {
      // Run all promises simultaneously
      await Promise.all([getContractsDocs(), getReservation(), getLanguages()]);

      // You can now use the results as needed
    } catch (error) {
      // Handle any errors that occur in any of the promises
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  }

  const getContractsDocs = async () => {
    setLoading(true);
    if (reservationId) {
      const workspaceId = user?.current_workspaces_id?.[0];
      // Récupérer les contract et documents
      const res = await get(
        `${
          import.meta.env.VITE_API_URL
        }${paths.API.CONTRACTS_DOCS.GET_BY_RESERVATION(
          Number(reservationId),
          workspaceId ?? ""
        )}`
      );

      if (res.data?.success) {
        const result = res?.data?.result;
        setContractsDocs(result);
      }
    }
    setLoading(false);
  };

  const getReservation = async () => {
    // Récupérer la reservation
    const res = await get(
      `${import.meta.env.VITE_API_URL}${
        paths.API.RESERVATIONS
      }/${reservationId}`
    );

    if (res.data?.success) {
      setReservation(res.data.result);
      await getUserRental(res.data?.result?.rental?.user_id);
    }
  };

  const getUserRental = async (userId: string) => {
    if (userId) {
      // Récupérer le user de la reservation
      const res = await get(
        `${import.meta.env.VITE_API_URL}${paths.API.USER.GET_USER_BY_ID(
          userId
        )}`
      );

      if (res.data?.success) {
        setUserLoaded(res?.data?.result);
      }
    }
  };

  const getLanguages = async () => {
    // Récupérer les langues
    const respLanguages = await get(
      `${import.meta.env.VITE_API_URL}${paths.API.LANGUAGES}`
    );

    if (respLanguages.data?.success) {
      const nextLanguages = respLanguages.data?.result?.languages?.map(
        (l: LanguageResponse) => {
          return {
            label: l.name,
            value: l.code,
            Icon: getLanguageIcon(l.code),
          };
        }
      );

      setLanguages(nextLanguages);
    }
  };

  const handleChangeContractsDocs = async () => {
    await getContractsDocs();
  };

  return (
    <BookingLayout navbarActiveItem="contracts" reservation={reservation}>
      <>
        <SendContractModal
          data={modalSendContract.data}
          isVisible={modalSendContract.isVisible}
          onClose={modalSendContract.close}
          modal={modalSendContract}
          languages={languages}
          reservation={reservation}
        />
        <AddContractModal
          isVisible={modalAddContract.isVisible}
          onClose={modalAddContract.close}
          reservationId={reservationId}
          defaultContract={
            contractsDocs?.contracts &&
            contractsDocs?.contracts?.[contractsDocs?.contracts?.length - 1]
          }
          onSuccess={handleChangeContractsDocs}
        />
        <ContractModal
          data={modalContract.data}
          isVisible={modalContract.isVisible}
          onClose={modalContract.close}
          reservationId={reservationId}
          modalContract={modalContract}
          modalSendContract={modalSendContract}
          onSuccess={handleChangeContractsDocs}
          reservation={reservation}
          user={userLoaded}
        />
        <DeleteResourceModal
          data={modalDeleteResource.data}
          isVisible={modalDeleteResource.isVisible}
          onClose={modalDeleteResource.close}
          onSuccess={handleChangeContractsDocs}
          reservationId={reservationId}
        />
        <EditResourceModal
          data={modalEditResource.data}
          isVisible={modalEditResource.isVisible}
          onClose={modalEditResource.close}
          modalDeleteResource={modalDeleteResource}
          reservationId={reservationId}
          onSuccess={handleChangeContractsDocs}
        />
        <AddResourceModal
          data={modalAddResource.data}
          isVisible={modalAddResource.isVisible}
          onClose={modalAddResource.close}
          reservationId={reservationId}
          onSuccess={handleChangeContractsDocs}
        />
        <div className="grid grid-cols-12 h-screen">
          {/* Menu Sticky */}
          <div className="col-span-2 pr-2 sticky top-16 self-start h-fit">
            <ReservationContractDocMenu />
          </div>

          <div className="col-span-8 flex flex-col gap-3 overflow-y-auto h-[calc(100vh-170px)] no-scrollbar">
            <ContractCard
              modalContract={modalContract}
              modalSendContract={modalSendContract}
              modalAddContract={modalAddContract}
              contracts={contractsDocs?.contracts}
              loading={isLoading}
              reservation={reservation}
            />
            <DocCard
              modalDeleteResource={modalDeleteResource}
              modalEditResource={modalEditResource}
              modalAddResource={modalAddResource}
              resources={contractsDocs?.resources}
              loading={isLoading}
            />
          </div>
          <div />
        </div>
      </>
    </BookingLayout>
  );
};
