import React, {useEffect, useState} from "react";
import {Card} from "../../../Common/Card/Card";
import ContractIcon from "../../../../assets/icons/contracts-paper.svg?react";
import PlusIcon from "../../../../assets/icons/plus.svg?react";
import {useTranslation} from "react-i18next";
import CardDocs from "../../../Common/CardDocs/CardDocs";
import {UseModal, useModal} from "../../../../hooks/useModal";
import {ModalContractsEditData} from "../../../../pages/RentalPage/RentalPage.type";
import {DocCardSkeleton} from "./DocCardSkeleton";
import {get, patch, post} from "../../../../helpers/APIHelper";
import paths from "../../../../constants/paths";
import {useNavigate} from "react-router-dom";
import FolderIcon from "../../../../assets/icons/folder.svg?react";
import {DocsResponse} from "../../../../types/GETTypes";
import {ResourceResponse} from "../../../Common/CardDocs/CardDocs.type";
import {DataResourceModal} from "../Modal/ContractModal.type";

type DocCardProps = {
  modalDeleteResource: UseModal<DataResourceModal | null | undefined>;
  modalEditResource: UseModal<DataResourceModal | null | undefined>;
  modalAddResource: UseModal<DataResourceModal | null | undefined>;
  resources: DocsResponse[] | undefined;
  loading?: boolean;
};
const DocCard = ({
  modalDeleteResource,
  modalEditResource,
  modalAddResource,
  resources,
  loading,
}: DocCardProps) => {
  const {t} = useTranslation();
  const [loadingValidation, setLoadingValidation] = useState<boolean>(false);
  if (loading) {
    return <DocCardSkeleton />;
  }

  const handleDelete = async (resource: ResourceResponse) => {
    modalDeleteResource.open({resource: resource as DocsResponse});
  };
  const handleDisplay = async (resource: ResourceResponse) => {
    modalEditResource.open({
      resource: resource as DocsResponse,
      displayMode: true,
    });
  };
  const handleEdit = async (resource: any) => {
    modalEditResource.open({resource: resource as DocsResponse});
  };

  const isEmpty = !resources?.length;

  return (
    <Card
      Icon={FolderIcon}
      label={t("Rental.Contracts.Docs.title")}
      anchor="doc-card"
      loading={loadingValidation}
      hasBody={true}
      button={{
        Icon: PlusIcon,
        onClick: () => modalAddResource.open(),
        type: "secondary",
      }}
    >
      {isEmpty ? (
        <div className="flex justify-center">
          <span className="text-low-contrast font-light">
            {t("Rental.Contracts.Docs.noResource")}
          </span>
        </div>
      ) : (
        resources?.map((resource) => {
          return (
            <CardDocs
              handleDisplay={handleDisplay}
              showDelete
              handleDelete={handleDelete}
              showEdit
              handleEdit={handleEdit}
              showDownload
              resource={resource}
              from="reservation"
              type="doc"
            />
          );
        })
      )}
    </Card>
  );
};

export default DocCard;
