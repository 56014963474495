/**
 * Main page that manages the "Traveler" tab.
 * Any questions? Need clarification? Contact Elysio Martins.
 *
 * @author Elysio
 */

import React, { useCallback, useEffect, useState } from "react";

import Cross from "../../assets/icons/close.svg?react";

import paths from "../../constants/paths";
import { ACTION_EDIT, ACTION_TRASH } from "./List/GuestList.type";
import { Modal, ModalBody } from "@mantine/core";
import _ from "lodash";

import { Button } from "../../components/Common/Button/Button";
import { GuestFilters } from "../../components/Guest/Filters/GuestFilters";
import { useTranslation } from "react-i18next";
import { MainLayout } from "../../components/Layout/MainLayout/MainLayout";
import { TableList } from "../../components/Common/TableList/TableList";
import { GuestHeaderList } from "./List/GuestHeaderList";
import { useTablePage } from "../../hooks/useTablePage";
import { GuestItemsList } from "./List/GuestItemsList";
import { GuestItemListSkeleton } from "./List/GuestItemListSkeleton";
import { TravelerUpdateModal } from "../../components/Guest/UpdateTraveler/TravelerUpdateModal";
import { useGuests } from "../../hooks/api/guests";
import { useModal } from "../../hooks/useModal";
import { ModalMerge } from "./Modals/ModalMerge";
import GuestsPageEmpty from "./GuestsPageEmpty";
import { TravelerAddModal } from "../../components/Guest/AddTraveler/TravelerAddModal";
import { ErrorMessage } from "../../components/Common/ErrorMessage/ErrorMessage";
import { Close } from "../../components/Common/Sidebar/Sidebar.stories";

export const GuestsPage = () => {
  const { t } = useTranslation();
  const [modalFormDeleteVisible, setModalFormDeleteVisible] = useState(false);
  const [guestIdForDelete, setGuestIdForDelete] = useState<number | null>(null);
  const [selectableIds, setSelectableIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalTravelerAddVisible, setModalTravelerAddVisible] = useState(false);
  const [isVisibleTravelerUpdate, setIsVisibleTravelerUpdate] = useState(false);
  const [guest, setGuest] = useState(null);
  const [modalActionVisible, setModalActionVisible] = useState(false);
  const [globalError, setGlobalError] = useState(null);
  const { getGuest, deleteGuest, multiDeleteGuests, getAllGuests } =
    useGuests();
  const modalMerge = useModal();

  const tablePage = useTablePage(
    `${import.meta.env.VITE_API_URL}${paths.API.CRM_GUEST_MODULE.GET_ALL}`,
    "guests",
  );

  /**
   * Scrolls the user to the top of the page.
   * @param smooth - If true, the scroll will be smooth; otherwise, it will be instant.
   */
  const  scrollToTop = (smooth: boolean = true): void => {
    const container = document.getElementById('container-guest');

    if (container) {
      container.scrollTo({
        top: 0,
        behavior: smooth ? 'smooth' : 'auto',
      });
    }
  }


  useEffect(() => {
    tablePage.fetch({});
    setSelectableIds([]);
  }, []);

  /**
   * Function to handle adding guest action
   * called in <GuestFilters/>.
   *
   * @author Elysio
   */
  const handleAddGuest = (guestId: string) => {
    tablePage.fetch({});
    setSelectableIds([]);
    scrollToTop();
  };

  /**
   * Function to handle updating guest.
   *
   * @author Elysio
   * @param guestId
   */
  const handleUpdateTraveler = (guestId: string) => {
    tablePage.fetch({});
    setSelectableIds([]);
    scrollToTop();
  };

  /**
   * Function to close modal action (fusion, delete)
   *
   * @author Elysio
   */
  const handleCloseModalAction = () => {
    setModalActionVisible(false);
  };

  /**
   * Function to delete guest with API call.
   *
   * @author Elysio
   */
  const trashGuests = async () => {
    setIsLoading(true);
    if (guestIdForDelete) {
      try {
        await deleteGuest(guestIdForDelete);
      } catch (error) {
        // @ts-ignore
        setGlobalError(error.message);
        scrollToTop();
      }
      setGuestIdForDelete(null);
    } else {
      try {
        await multiDeleteGuests(selectableIds);
      } catch (error) {
        // @ts-ignore
        setGlobalError(error.message);
        scrollToTop();
      }
    }
    tablePage.fetch({});
    setModalFormDeleteVisible(false);
    setModalActionVisible(false);
    setSelectableIds([]);
    setIsLoading(false);
    scrollToTop();
  };

  /**
   * Function to handle search text action
   * called in <GuestFilters/>.
   *
   * @author Elysio
   */
  const handleSearchText = useCallback(
    _.debounce(async (search: string) => {
      const allGuests = await getAllGuests({
        search: search,
      });
      tablePage.setData(allGuests?.guests ?? []);
    }, 500),
  );

  /**
   * Function to get handling click item list
   *
   * @author Elysio
   * @param guestId
   */
  const handleClickItem = (guestId: string) => {
    // @ts-ignore
    const updatedSelectableIds = selectableIds.includes(guestId)
      ? selectableIds.filter((id) => id !== guestId)
      : [...selectableIds, guestId];

    // @ts-ignore
    setSelectableIds(updatedSelectableIds);

    setModalActionVisible(updatedSelectableIds.length > 0);
  };

  /**
   * Function to get handling merging guests action.
   *
   * @author Elysio
   */
  const handleMergeGuests = () => {
    tablePage.fetch({});
    setSelectableIds([]);
    modalMerge.close();
    scrollToTop();
  };

  /**
   * Function to handle action in the list details (trash and edit)
   *
   * @param guestId
   * @param actionType
   */
  const handleClickItemDetail = (
    guestId: number,
    actionType: string | number,
  ) => {
    switch (actionType) {
      case ACTION_TRASH:
        setModalFormDeleteVisible(true);
        setGuestIdForDelete(guestId);
        break;
      case ACTION_EDIT:
        const guestGet = tablePage.data.find((guest) => guest.id === guestId);
        setGuest(guestGet);
        setIsVisibleTravelerUpdate(true);
        break;
      case "select":
        setSelectableIds((prevSelectedItems) => {
          const exists = prevSelectedItems?.includes(guestId);
          if (exists) {
            return prevSelectedItems.filter((i) => i !== guestId);
          } else {
            return [...prevSelectedItems, guestId];
          }
        });

        setSelectableIds((prevSelectedItems) => {
          const hasSelectedItems = prevSelectedItems?.length > 0;
          setModalActionVisible(hasSelectedItems);
          return prevSelectedItems;
        });
        break;
    }
  };

  if (tablePage.total <= 0) {
    return (
      <>
        <TravelerAddModal
          isVisible={modalTravelerAddVisible}
          setIsVisible={setModalTravelerAddVisible}
          onAddTraveler={handleAddGuest}
        />
        <GuestsPageEmpty
          setModalTravelerAddVisible={setModalTravelerAddVisible}
        />
      </>
    );
  } else {
    return (
      <>
        <MainLayout
          title={t("Global.guests")}
          sidebarActiveItem="guests"
          sidebarActiveSubItem="general"
          id={"container-guest"}
        >
          {globalError && (
            <ErrorMessage childrenClassName={"flex"}>
              {globalError}
            </ErrorMessage>
          )}
          <GuestFilters
            onAddGuest={handleAddGuest}
            onSearchText={handleSearchText}
            modalTravelerAddVisible={modalTravelerAddVisible}
            tablePage={tablePage}
          />

          <ModalMerge
            isVisible={modalMerge.isVisible}
            modal={modalMerge}
            handleMergeAction={handleMergeGuests}
          ></ModalMerge>

          {/* TODO : change this section to component created by Valentin L */}
          <div
            className={
              "absolute top-10 left-1/2 -translate-x-20 rounded-xl bg-white border shadow-lg " +
              "border-gray-300 z-50 p-5 w-[380px] flex gap-6 flex-col" +
              (modalActionVisible ? "" : " hidden")
            }
          >
            <p className={"text-gray-500 flex justify-between items-center"}>
              {selectableIds.length} {t("Booking.Guest.travelerSelected")}
              <Cross
                onClick={handleCloseModalAction}
                className={"cursor-pointer"}
              />
            </p>
            <div className={"flex items-center gap-6"}>
              {selectableIds.length == 2 && (
                <Button
                  type={"secondary"}
                  onClick={async () => {
                    const guestOne = await getGuest(selectableIds[0]);
                    const guestTwo = await getGuest(selectableIds[1]);
                    modalMerge.open({
                      guestOne: guestOne,
                      guestTwo: guestTwo,
                    });
                    setModalActionVisible(false);
                  }}
                >
                  {t("Booking.Guest.fusion")}
                </Button>
              )}
              <Button
                type={"primary"}
                onClick={() => {
                  setModalFormDeleteVisible(true);
                }}
              >
                {t("Global.remove")}
              </Button>
            </div>
          </div>

          <Modal
            classNames={{
              close: "text-gray-500",
              header: "absolute right-0 p-4",
              body: "relative rounded-full mt-2",
              content: "rounded-xl",
            }}
            opened={modalFormDeleteVisible}
            onClose={() => {
              setModalFormDeleteVisible(false);
            }}
          >
            <ModalBody>
              <h1 className={"font-bold text-xl"}>
                {selectableIds.length > 1
                  ? t("Booking.Guest.confirmMultipleDelete")
                  : t("Booking.Guest.confirmDelete")}
              </h1>
              <p className={"text-md text-gray-500 mt-6"}>
                {selectableIds.length === 1
                  ? t("Booking.Guest.confirmDeleteDescription")
                  : t("Booking.Guest.confirmMultipleDeleteDescription")}
              </p>
              <div className={"mt-6 flex items-center gap-4"}>
                <Button
                  type={"secondary"}
                  onClick={() => setModalFormDeleteVisible(false)}
                >
                  {t("Global.cancel")}
                </Button>
                <Button onClick={() => trashGuests()} loading={isLoading}>
                  {t("Global.remove")}
                </Button>
              </div>
            </ModalBody>
          </Modal>

          {guest && (
            <TravelerUpdateModal
              isVisible={isVisibleTravelerUpdate}
              setIsVisible={setIsVisibleTravelerUpdate}
              onUpdateTraveler={handleUpdateTraveler}
              guest={guest}
              setGuest={setGuest}
            />
          )}

          <TableList
            i18nElement="Global.guests"
            Header={GuestHeaderList}
            tablePage={tablePage}
            Item={GuestItemsList}
            Skeleton={GuestItemListSkeleton}
            onClick={handleClickItem}
            onClickAction={handleClickItemDetail}
            selectedItems={selectableIds}
            NoData={null}
          />
        </MainLayout>
      </>
    );
  }
};
