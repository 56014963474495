import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ProgressionStepEnum } from "../../../../../enums/GETenums";
import { useCreatePaymentAccountForOnboarding } from "../../../../../hooks/api/paymentAccounts";
import { useCompleteCurrentStepProgression } from "../../../../../hooks/api/progression";
import { useRentalMultipleDepositUpdate } from "../../../../../hooks/api/rental";
import { UseModal } from "../../../../../hooks/useModal";
import { RentalLightListItemResponse } from "../../../../../types/GETTypes";
import { CarouselWizard } from "../../../../Common/CarouselWizard/CarouselWizard";
import { ErrorMessage } from "../../../../Common/ErrorMessage/ErrorMessage";
import { RightModal } from "../../../../Common/RightModal/RightModal";
import { ProgressionMultiDepositSettingsForm } from "../../../MultiDepositSettings/MultiDepositSettings";
import { DIRECT_RESERVATIONS_STEP } from "./ConfigDirectReservations.type";
import { ProgressionConfigDirectReservationDepositSettingsStep } from "./Steps/ConfigDirectReservationDepositSettingsStep";
import {
  ProgressionConfigDirectReservationRentalChoiceStep,
  ProgressionConfigDirectReservationRentalChoiceStepForm,
} from "./Steps/ConfigDirectReservationRentalChoiceStep";
import { ProgressionConfigDirectReservationStripeCollectPaymentsStep } from "./Steps/ConfigDirectReservationStripeCollectPaymentsStep";
type ProgressionConfigDirectReservationsModalProps = {
  modal: UseModal<{}>;
  step: DIRECT_RESERVATIONS_STEP;
  onChangeStep: (step: DIRECT_RESERVATIONS_STEP) => void;
  onValidate: (step: ProgressionStepEnum) => void;
};

// export type ProgressionConfigDirectReservationsModal = {
//   currentStep: DIRECT_RESERVATIONS_STEP;
//   onChangeCurrentStep: (step: DIRECT_RESERVATIONS_STEP) => void;
// };

export const ProgressionConfigDirectReservationsModal: React.FC<
  ProgressionConfigDirectReservationsModalProps
> = ({ modal, step, onChangeStep, onValidate }) => {
  const { t } = useTranslation();

  const parameters = new URLSearchParams(window.location.search);
  const codeParam = parameters.get("code");

  const [apiError, setApiError] = useState<string | null>(null);

  const [rentals, setRentals] = useState<
    RentalLightListItemResponse[] | undefined
  >();

  const [validationLoading, setValidationLoading] = useState<boolean>(false);

  const rentalChoiceForm =
    useForm<ProgressionConfigDirectReservationRentalChoiceStepForm>({
      mode: "all",
      defaultValues: {
        selectedRentals: [],
      },
    });

  const depositSettingsForm = useForm<ProgressionMultiDepositSettingsForm>({
    mode: "all",
    defaultValues: {
      deposits: [],
    },
  });

  const handleNext = (step: DIRECT_RESERVATIONS_STEP) => {
    onChangeStep(step);
  };

  const handleCollectPaymentsDifferently = async () => {
    try {
      const rentalMultipleDepositUpdatePromise = () => {
        return new Promise((resolve, reject) => {
          useRentalMultipleDepositUpdate({
            data: {
              rentals: JSON.parse(localStorage.getItem("rentals")!),
            },
            onSuccess: (data) => {
              resolve(data);
            },
            onError: (message) => {
              if (message) {
                setApiError(message);
                reject(message);
              }
            },
            onStart: () => {
              setApiError(null);
              setValidationLoading(true);
            },
            onEnd: () => {
              setValidationLoading(false);
            },
          });
        });
      };

      const completeCurrentStepProgressionPromise =
        (): Promise<ProgressionStepEnum> => {
          return new Promise((resolve, reject) => {
            useCompleteCurrentStepProgression({
              step: ProgressionStepEnum.STEP_CONFIG_DIRECT_RESERVATIONS,
              onSuccess: (data) => {
                resolve(data.progression_step);
              },
              onError: (message) => {
                if (message) {
                  setApiError(message);
                  reject(message);
                }
              },
              onStart: () => {
                setApiError(null);
                setValidationLoading(true);
              },
              onEnd: () => {
                setValidationLoading(false);
              },
            });
          });
        };

      const [resultRentalMultipleDeposit, resultStep] =
        await Promise.all([
          rentalMultipleDepositUpdatePromise(),
          completeCurrentStepProgressionPromise(),
        ]);

      console.log(
        resultRentalMultipleDeposit,
        resultStep
      );

      if (resultRentalMultipleDeposit && resultStep) {
        onValidate(resultStep);
        localStorage.removeItem("rentals");
      }
    } catch (error: any) {
      setApiError(error.message);
    }
  };

  const handleValidation = async (name: string) => {
    try {
      const rentalMultipleDepositUpdatePromise = () => {
        return new Promise((resolve, reject) => {
          useRentalMultipleDepositUpdate({
            data: {
              rentals: JSON.parse(localStorage.getItem("rentals")!),
            },
            onSuccess: (data) => {
              resolve(data);
            },
            onError: (message) => {
              if (message) {
                setApiError(message);
                reject(message);
              }
            },
            onStart: () => {
              setApiError(null);
              setValidationLoading(true);
            },
            onEnd: () => {
              setValidationLoading(false);
            },
          });
        });
      };

      const completeCurrentStepProgressionPromise =
        (): Promise<ProgressionStepEnum> => {
          return new Promise((resolve, reject) => {
            useCompleteCurrentStepProgression({
              step: ProgressionStepEnum.STEP_CONFIG_DIRECT_RESERVATIONS,
              onSuccess: (data) => {
                resolve(data.progression_step);
              },
              onError: (message) => {
                if (message) {
                  setApiError(message);
                  reject(message);
                }
              },
              onStart: () => {
                setApiError(null);
                setValidationLoading(true);
              },
              onEnd: () => {
                setValidationLoading(false);
              },
            });
          });
        };

      const createPaymentAccountPromise = () => {
        return new Promise((resolve, reject) => {
          useCreatePaymentAccountForOnboarding({
            data: {
              name: name,
              type: "stripe",
              stripe_authorization_code: codeParam!,
              workspace_id: null,
            },
            onSuccess: (paymentAccount) => resolve(paymentAccount),
            onError: (message) => {
              if (message) {
                setApiError(message);
                reject(new Error(message));
              }
            },
            onStart: () => {
              setApiError(null);
              setValidationLoading(true);
            },
            onEnd: () => {
              setValidationLoading(false);
            },
          });
        });
      };

      const [resultRentalMultipleDeposit, resultStep, resultPaymentAccount] =
        await Promise.all([
          rentalMultipleDepositUpdatePromise(),
          completeCurrentStepProgressionPromise(),
          createPaymentAccountPromise(),
        ]);

      console.log(
        resultRentalMultipleDeposit,
        resultStep,
        resultPaymentAccount
      );

      if (resultRentalMultipleDeposit && resultStep && resultPaymentAccount) {
        onValidate(resultStep);
        localStorage.removeItem("rentals");
      }
    } catch (error: any) {
      setApiError(error.message);
    }
  };

  return (
    <RightModal
      isVisible={modal.isVisible}
      onClose={modal.close}
      isLarge={false}
      title={t("Progression.StepConfigDirectReservations.Modal.title")}
    >
      <div className="flex flex-col w-full">
        <ErrorMessage>{apiError}</ErrorMessage>
        <div className="flex-1">
          <CarouselWizard
            disableClickBefore={true}
            currentStepIndex={step}
            onChangeStep={(value) => onChangeStep(value)}
            steps={[
              {
                title: t(
                  "Progression.StepConfigDirectReservations.Modal.Steps.ChoiceRental.title"
                ),
                Node: (
                  <ProgressionConfigDirectReservationRentalChoiceStep
                    loading={validationLoading}
                    form={rentalChoiceForm}
                    onRentalsIsLoaded={(rentals) => {
                      setRentals(rentals);
                    }}
                    onNext={handleNext}
                  />
                ),
              },
              {
                title: t(
                  "Progression.StepConfigDirectReservations.Modal.Steps.DepositSettings.title"
                ),
                Node: (
                  <ProgressionConfigDirectReservationDepositSettingsStep
                    loading={validationLoading}
                    rentals={rentals?.filter((rental) =>
                      rentalChoiceForm
                        .getValues("selectedRentals")
                        .includes(rental.id)
                    )}
                    form={depositSettingsForm}
                    onNext={handleNext}
                  />
                ),
              },
              {
                title: t(
                  "Progression.StepConfigDirectReservations.Modal.Steps.StripeCollectPayments.title"
                ),
                Node: (
                  <ProgressionConfigDirectReservationStripeCollectPaymentsStep
                    loading={validationLoading}
                    onValidate={handleValidation}
                    onCollectPaymentsDifferently={
                      handleCollectPaymentsDifferently
                    }
                  />
                ),
              },
            ]}
          />
        </div>

        {/* <Separator />
        <div className="flex gap-x-3">
          <Button type="secondary" onClick={modal.close}>
            {t("Global.cancel")}
          </Button>
          <Button RightIcon={CheckIcon} disabled={false} onClick={() => {}}>
            {t("Progression.startImport")}
          </Button>
        </div> */}
      </div>
    </RightModal>
  );
};
