import React, { ReactElement, useEffect, useState } from "react";
import { UseModal } from "../../../hooks/useModal";
import { CenterModal } from "../../Common/CenterModal/CenterModal";
import { Flex } from "@mantine/core";
import { FlexCol } from "../../Common/FlexComponents/FlexCol";
import { TitleText } from "../../Common/TextComponents/TitleText";
import { FlexItemCenter } from "../../Common/FlexComponents/FlexItemCenter";
import { SimpleRadio } from "../../Common/SimpleRadio/SimpleRadio";
import { HelpText } from "../../Common/TextComponents/HelpText";
import useText from "../../../hooks/useText";
import { FlexCenterBetween } from "../../Common/FlexComponents/FlexCenterBetween";
import { Button } from "../../Common/Button/Button";
import CloseIcon from "../../../assets/icons/close.svg?react";

export type DeleteActionType = "DELETE_ALL" | "KEEP_ALL";

export default function ExternalDesyncModal({
  modal,
  platform,
  onDelete,
}: {
  modal: UseModal<unknown>;
  platform: string;
  onDelete: (type: DeleteActionType) => Promise<void>;
}): ReactElement {
  const [deleteAll, setDeleteAll] = useState(false);
  const [loading, setLoading] = useState(true);

  const { getText } = useText("GuestWebsite.Rental.External");

  const handleDelete = (deleteAll: boolean) => {
    const action: DeleteActionType = deleteAll ? "DELETE_ALL" : "KEEP_ALL";
    setLoading(true);
    onDelete(action).finally(() => {
      setLoading(false);
      modal.close();
    });
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <CenterModal isVisible={modal.isVisible} onClose={modal.close}>
      <Flex className={"justify-between"}>
        <TitleText className={"text-xl"}>
          {getText("title", { name: platform })}
        </TitleText>
        <CloseIcon onClick={modal.close} className={"w-8 h-8 cursor-pointer"} />
      </Flex>
      <FlexCol gap={20} className={"mt-4"}>
        <HelpText className={"text-md"}>{getText("titleSub")}</HelpText>
        <HelpText className={"text-md"}>{getText("titleSubTwo")}</HelpText>
      </FlexCol>
      <FlexCol gap={20} className={"mt-4"}>
        <FlexItemCenter>
          <SimpleRadio
            value={deleteAll}
            onClick={() => setDeleteAll(!deleteAll)}
          />
          <FlexCol
            className={"cursor-pointer"}
            gap={0}
            onClick={() => setDeleteAll(!deleteAll)}
          >
            <TitleText className={"text-xs"}>
              {getText("checkboxTitleDelete")}
            </TitleText>
            <HelpText className={"text-xs"}>
              {getText("checkboxSubDelete")}
            </HelpText>
          </FlexCol>
        </FlexItemCenter>
        <FlexItemCenter>
          <SimpleRadio
            value={!deleteAll}
            onClick={() => setDeleteAll(!deleteAll)}
          />
          <FlexCol
            className={"cursor-pointer"}
            gap={0}
            onClick={() => setDeleteAll(!deleteAll)}
          >
            <TitleText className={"text-xs"}>
              {getText("checkboxTitleNotDelete")}
            </TitleText>
            <HelpText className={"text-xs"}>
              {getText("checkboxSubNotDelete")}
            </HelpText>
          </FlexCol>
        </FlexItemCenter>
      </FlexCol>
      <FlexCenterBetween gap={20} className={"mt-8"}>
        {!loading ? (
          <Button type={"secondary"} onClick={modal.close}>
            {getText("cancel")}
          </Button>
        ) : null}
        <Button onClick={() => handleDelete(deleteAll)} loading={loading}>
          {getText("desync")}
        </Button>
      </FlexCenterBetween>
    </CenterModal>
  );
}
