import React, {useEffect, useState} from "react";
import {Button} from "../../../../../Common/Button/Button";
import {useTranslation} from "react-i18next";
import useGuestWebsiteStore from "../../../../../../stores/guestWebsiteStore/useGuestWebsiteStore";
import ArrowLeftIcon from "../../../../../../assets/icons/arrow-left.svg?react";
import ReservationResumeCard from "./ReservationResumeCard";
import PaymentResumeCard from "./PaymentResumeCard";
import PaymentFormWebsite from "../../Common/PaymentFormWebsite";
import {
  PaymentReservationStatusEnum,
  ReservationExtraOrderStatusEnum,
} from "../../../../../../enums/GETenums";
import {ConfirmationToken} from "@stripe/stripe-js";
import useGuestWebsite from "../../../../../../hooks/useGuestWebsite";
import {GuestWebsiteStep3Response} from "../../../../../../types/GETTypes";
import {getFormattedPrice} from "../../../../../../helpers/currencyHelper";

interface RentalDetailsPaymentPageProps {
  loading: boolean;
}

export interface ReservationStep3Form {
  checkin: string;
  checkout: string;
  rental_id: string;
  adults_count: number;
  children_count: number;
  platform_id: number;
  locale: string;
  phone: string;
  first_name: string;
  last_name: string;
  email: string;
  address: string;
  postal_code: string;
  city: string;
  country_code: number | string;
  additional_information: string;
  stripe_confirmation_token: string;
}

const RentalDetailsPaymentPage = ({}: RentalDetailsPaymentPageProps) => {
  const {t} = useTranslation();
  const {
    setRentalDetailTab,
    setStep3Data: setStep3DataStore,
    step2Data,
    rentalData,
    setTabGuestWebsite,
  } = useGuestWebsiteStore();
  const {dataWebsite} = useGuestWebsite();
  const {styles} = dataWebsite || {};
  const {color_background, color_text} = styles || {};
  const [paymentFormLoading, setPaymentFormLoading] = useState(false);
  const {
    reservation_request_required,
    tarification,
    guest,
    stripe_account_id,
    stripe_client_secret,
  } = step2Data || {};
  const onSuccessPayment = async (
    status:
      | PaymentReservationStatusEnum
      | ReservationExtraOrderStatusEnum
      | undefined,
    response?: any,
    confirmationToken?: string
  ) => {
    setPaymentFormLoading(true);
    if (response) {
      const data = response as GuestWebsiteStep3Response;
      setPaymentFormLoading(true);
      setStep3DataStore({
        general_informations: data.general_informations,
        tarification: data.tarification,
        guest: data.guest,
        stripe_account_id: data.stripe_account_id,
        stripe_client_secret: data.stripe_client_secret,
        stripe_payment_status: data.stripe_payment_status,
        stripe_payment_intent_id: data.stripe_payment_intent_id,
        reservation_request_required: data.reservation_request_required,
        payment_schedule: data.payment_schedule,
        reservation: data.reservation,
      });
      setPaymentFormLoading(false);
      setRentalDetailTab("extras");
    } else {
      setStep3DataStore(undefined);
    }
    setPaymentFormLoading(false);
  };

  return (
    <div
      className="p-4 md:px-contentWebsite"
      style={{backgroundColor: color_background}}
    >
      <Button
        LeftIcon={ArrowLeftIcon}
        type="secondary"
        displayLargeBtn={false}
        className=""
        buttonClassName="md:h-12"
        textClassName="md:text-xl"
        onClick={() => setRentalDetailTab("information")}
        buttonStyleCss={{color: color_text}}
      >
        {t("GuestPage.ExtrasPayment.goBack")}
      </Button>

      <div className="md:flex md:flex-row p-6 space-y-6 md:space-y-0 pl-2 md:gap-6">
        <div className="w-full md:min-w-1/2 order-1 space-y-4">
          <ReservationResumeCard />
          <PaymentResumeCard />
        </div>
        <div className="w-full md:w-[65%] order-2">
          <PaymentFormWebsite
            from="websitePaymentForm"
            onSuccess={onSuccessPayment}
            customLoading={paymentFormLoading}
            guest={guest}
            stripe_account_id={stripe_account_id}
            stripe_client_secret={stripe_client_secret}
            amount={
              getFormattedPrice({
                price: Number(tarification?.total_ttc),
                decimals: 2,
                withCurrency: false,
              }) ?? 0
            }
            isPreAuth={Boolean(reservation_request_required)}
            data={step2Data}
          />
        </div>
      </div>
    </div>
  );
};

export default RentalDetailsPaymentPage;
