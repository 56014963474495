import { t } from "i18next";
import moment from "moment";
import React from "react";
import { Trans } from "react-i18next";
import EmojiHappyIcon from "../../../assets/icons/emoji-happy.svg?react";
import LogInIcon from "../../../assets/icons/log-in.svg?react";
import LogOutIcon from "../../../assets/icons/log-out.svg?react";
import MoonIcon from "../../../assets/icons/moon-2.svg?react";
import UsersIcon from "../../../assets/icons/users.svg?react";
import { UseModal } from "../../../hooks/useModal";
import { RightModal } from "../../Common/RightModal/RightModal";
import { AddReservationType } from "../AddReservation.type";
import { AddReservationPricesCalendar } from "./AddReservationPricesCalendar";

type AddReservationPricesModalProps = {
  modal: UseModal<AddReservationType | undefined>;
};

export const AddReservationPricesModal: React.FC<
  AddReservationPricesModalProps
> = ({ modal }) => {
  const reservation = modal.data;
  const checkin = moment(reservation?.general?.checkin);
  const checkout = moment(reservation?.general?.checkout);

  return (
    <RightModal
      title={t("AddReservation.Prices.DetailPricesModal.title")}
      isVisible={modal.isVisible}
      onClose={modal.close}
    >
      <div className="flex flex-col w-full gap-y-4">
        <div className="flex items-center justify-between">
          <div className="flex flex-col gap-y-2">
            <div className="flex space-x-2">
              <LogInIcon />
              <p className="text-base font-light text-low-contrast">
                <Trans
                  i18nKey="AddReservation.Prices.DetailPricesModal.checkinOrCheckoutDate"
                  components={{
                    strong: <span className="font-bold"></span>,
                  }}
                  values={{
                    date: checkin.format("DD/MM/YYYY"),
                    hour: reservation?.general?.time_in,
                  }}
                />
              </p>
            </div>

            <div className="flex space-x-2">
              <LogOutIcon />
              <p className="text-base font-light text-low-contrast">
                <Trans
                  i18nKey="AddReservation.Prices.DetailPricesModal.checkinOrCheckoutDate"
                  components={{
                    strong: <span className="font-bold"></span>,
                  }}
                  values={{
                    date: checkout.format("DD/MM/YYYY"),
                    hour: reservation?.general?.time_out,
                  }}
                />
              </p>
            </div>
          </div>

          <div className="flex flex-col gap-y-1">
            <div className="flex justify-end space-x-2">
              <p className="text-lg font-bold text-high-contrast">
                {t("Global.nights", {
                  count: reservation?.general?.nights_count,
                })}
              </p>
              <MoonIcon />
            </div>

            <div className="flex justify-end space-x-2">
              <p className="text-xs font-light text-low-contrast">
                {t("Global.adults", {
                  count: Number(reservation?.general?.adults_count ?? 0),
                })}
              </p>
              <UsersIcon />
            </div>

            <div className="flex justify-end space-x-2">
              <p className="text-xs font-light text-low-contrast">
                {t("Global.children", {
                  count: Number(reservation?.general?.children_count ?? 0),
                })}
              </p>
              <EmojiHappyIcon />
            </div>
          </div>
        </div>
        {/* Price calendar */}
        <AddReservationPricesCalendar calendar={reservation?.calendar!} />
      </div>
    </RightModal>
  );
};
