import React from "react";
import { useTranslation } from "react-i18next";

import ChevronDownIcon from "../../../assets/icons/chevron-down.svg?react";
import ChevronUpIcon from "../../../assets/icons/chevron-up.svg?react";
import { cn } from "../../../helpers/classHelper";
import { CheckBox } from "../../Common/CheckBox/CheckBox";
import { TableListHeaderProps } from "../../Common/TableList/TableList.type";

export const ManagementAutomationTableHeader: React.FC<
  TableListHeaderProps
> = ({
  sortField,
  sortDirection,
  areAllSelected,
  onChangeSort = () => {},
  onSelectAll = () => {},
}) => {
  const { t } = useTranslation();

  const handleChangeSort = (column: string) => {
    let nextDirection: "asc" | "desc" = "asc";

    if (column === sortField && sortDirection === "asc") nextDirection = "desc";

    onChangeSort(column);
  };

  const getIcon = (column: string) => {
    const isActive = sortField === column;

    if (isActive && sortDirection === "asc") return <ChevronDownIcon />;
    else if (isActive && sortDirection === "desc") return <ChevronUpIcon />;
    else return <ChevronDownIcon />;
  };

  return (
    <tr className="select-none bg-element-background border-b-1 border-element-border">
      <th className="w-4 py-3 text-sm ps-4 pe-2">
        <CheckBox onChange={onSelectAll} value={areAllSelected} />
      </th>

      <th
        className="px-2 py-3 text-sm"
        onClick={() => handleChangeSort("name")}
      >
        <div
          className={cn(
            `flex flex-row items-center cursor-pointer hover:underline`,
            {
              underline: sortField === "name",
            },
          )}
        >
          <p>{t("Automation.List.scheduledTask")}</p> {getIcon("name")}
        </div>
      </th>

      <th
        className="px-2 py-3 text-sm"
        onClick={() => handleChangeSort("trigger")}
      >
        <div
          className={cn(
            `flex flex-row items-center cursor-pointer hover:underline`,
            {
              underline: sortField === "trigger",
            },
          )}
        >
          <p>{t("Automation.List.trigger")}</p> {getIcon("trigger")}
        </div>
      </th>

      <th
        className="px-2 py-3 text-sm"
        onClick={() => handleChangeSort("rental")}
      >
        <div
          className={cn(
            `flex flex-row items-center cursor-pointer hover:underline`,
            {
              underline: sortField === "rental",
            },
          )}
        >
          <p>{t("Automation.List.concernedRental")}</p> {getIcon("rental")}
        </div>
      </th>

      <th
        className="px-2 py-3 text-sm w-72"
        onClick={() => handleChangeSort("assignation")}
      >
        <div
          className={cn(
            `flex flex-row justify-center items-center cursor-pointer hover:underline`,
            {
              underline: sortField === "assignation",
            },
          )}
        >
          <p>{t("Automation.List.assignation")}</p> {getIcon("assignation")}
        </div>
      </th>

      <th
        className="w-10 px-2 py-3 text-sm "
        onClick={() => handleChangeSort("enabled")}
      >
        <div
          className={cn(
            `flex flex-row items-center justify-center cursor-pointer hover:underline`,
            {
              underline: sortField === "enabled",
            },
          )}
        >
          <p>{t("Automation.List.state")}</p> {getIcon("enabled")}
        </div>
      </th>

      <th></th>
    </tr>
  );
};
