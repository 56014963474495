import FileIcon from "../../../../assets/icons/file.svg?react";
import FileMoney from "../../../../assets/icons/file-money.svg?react";

import EyeIcon from "../../../../assets/icons/eye.svg?react";
import SendIcon from "../../../../assets/icons/send.svg?react";
import DownloadIcon from "../../../../assets/icons/download.svg?react";
import { Card } from "../../../Common/Card/Card";
import { getFormattedPrice } from "../../../../helpers/currencyHelper";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReceiptType } from "../../../../types/commonTypes";
import paths from "../../../../constants/paths";
import { get } from "../../../../helpers/APIHelper";
import LoaderIcon from "../../../../assets/icons/loader.svg?react";
import { TicketsProps } from "../../../../pages/BookingInvoice/BookingInvoicePage.type";

export default function Ticket({ modal, reservationId, receipts}: TicketsProps) {
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState({
    typeDownload: "ALL",
    loading: false,
    typeView: "view",
  });

  const handleTicketAction = (
    downloadType: ReceiptType,
    action: "view" | "download",
  ) => {
    const url = `${import.meta.env.VITE_API_URL}${paths.API.RESERVATION.DOWNLOAD_RECEIPT(reservationId, downloadType)}`;

    setLoading({
      typeDownload: downloadType,
      loading: true,
      typeView: action,
    });

    get(url, {
      responseType: "blob",
    })
      .then((res) => {
        const href = URL.createObjectURL(res.data);

        if (action === "view") {
          const pdfWindow = window.open(href, "_blank");
          if (!pdfWindow) {
            console.error("Impossible d'ouvrir le PDF dans un nouvel onglet.");
          }
        } else if (action === "download") {
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", "ticket.pdf");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }

        URL.revokeObjectURL(href);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération du fichier PDF :", error);
      })
      .finally(() => {
        console.log("Action terminée.");

        setLoading({
          typeDownload: downloadType,
          loading: false,
          typeView: action,
        });
      });
  };

  const isLoadingType = (
    downloadType: ReceiptType,
    view: "view" | "download",
  ) => {
    if (!isLoading.loading) return false;
    if (isLoading.typeView === view && downloadType === isLoading.typeDownload)
      return true;
  };

  const onSendTicket = (receiptType: ReceiptType) => {
    modal.open({
      receiptType: receiptType,
    });
  };

  return (
    <Card
      Icon={FileMoney}
      label={t("Booking.recus")}
      classNames={{
        label: "text-lg",
      }}
    >
      <section
        className={
          "p-2 flex items-center justify-between bg-gray-100 border border-gray-300 rounded"
        }
      >
        <div
          className={"flex items-center gap-2 text-gray-700 text-lg font-bold"}
        >
          <FileIcon className={"w-6 h-6"} />
          <div>
            <p className={"text-black"}>{t("Booking.ticketNight")}</p>
            <p className={"font-normal text-gray-500"}>
              {getFormattedPrice({ price: receipts.night_receipt.total, decimals: 2 })}
            </p>
          </div>
        </div>
        <div className={"flex items-center gap-2"}>
          {isLoadingType("NIGHT", "view") ? (
            <LoaderIcon className={"w-6 h-6 animate-spin"} />
          ) : (
            <EyeIcon
              onClick={() => handleTicketAction("NIGHT", "view")}
              className={"w-6 h-6 cursor-pointer"}
            />
          )}
          <SendIcon
            onClick={() => onSendTicket("NIGHT")}
            className={"w-6 h-6 cursor-pointer"}
          />
          {isLoadingType("NIGHT", "download") ? (
            <LoaderIcon className={"w-6 h-6 animate-spin"} />
          ) : (
            <DownloadIcon
              onClick={() => handleTicketAction("NIGHT", "download")}
              className={"w-6 h-6 cursor-pointer"}
            />
          )}
        </div>
      </section>
      <section
        className={
          "p-2 flex items-center justify-between bg-gray-100 border border-gray-300 rounded mt-4"
        }
      >
        <div
          className={"flex items-center gap-2 text-gray-700 text-lg font-bold"}
        >
          <FileIcon className={"w-6 h-6"} />
          <div>
            <p className={"text-black"}>{t("Booking.ticketExtra")}</p>
            <p className={"font-normal text-gray-500"}>
              {getFormattedPrice({ price: receipts.extra_receipt.total, decimals: 2 })}
            </p>
          </div>
        </div>
        <div className={"flex items-center gap-2"}>
          {isLoadingType("EXTRAS", "view") ? (
            <LoaderIcon className={"w-6 h-6 animate-spin"} />
          ) : (
            <EyeIcon
              onClick={() => handleTicketAction("EXTRAS", "view")}
              className={"w-6 h-6 cursor-pointer"}
            />
          )}
          <SendIcon
            onClick={() => onSendTicket("EXTRAS")}
            className={"w-6 h-6 cursor-pointer"}
          />

          {isLoadingType("EXTRAS", "download") ? (
            <LoaderIcon className={"w-6 h-6 animate-spin"} />
          ) : (
            <DownloadIcon
              onClick={() => handleTicketAction("EXTRAS", "download")}
              className={"w-6 h-6 cursor-pointer"}
            />
          )}
        </div>
      </section>
      <section
        className={
          "p-2 flex items-center justify-between bg-gray-100 border border-gray-300 rounded mt-4"
        }
      >
        <div
          className={"flex items-center gap-2 text-gray-700 text-lg font-bold"}
        >
          <FileIcon className={"w-6 h-6"} />
          <div>
            <p className={"text-black"}>{t("Booking.ticketExtraAndNight")}</p>
            <p className={"font-normal text-gray-500"}>
              {getFormattedPrice({ price: receipts.full_receipt.total, decimals: 2 })}
            </p>
          </div>
        </div>
        <div className={"flex items-center gap-2"}>
          {isLoadingType("ALL", "view") ? (
            <LoaderIcon className={"w-6 h-6 animate-spin"} />
          ) : (
            <EyeIcon
              onClick={() => handleTicketAction("ALL", "view")}
              className={"w-6 h-6 cursor-pointer"}
            />
          )}
          <SendIcon
            onClick={() => onSendTicket("ALL")}
            className={"w-6 h-6 cursor-pointer"}
          />

          {isLoadingType("ALL", "download") ? (
            <LoaderIcon className={"w-6 h-6 animate-spin"} />
          ) : (
            <DownloadIcon
              onClick={() => handleTicketAction("ALL", "download")}
              className={"w-6 h-6 cursor-pointer"}
            />
          )}
        </div>
      </section>
    </Card>
  );
}
