import {Elements} from "@stripe/react-stripe-js";
import React, {useEffect, useState} from "react";
import {PaymentReservationCheckoutForm} from "../../../../../pages/PaymentReservation/PaymentReservationCheckoutForm";
import {StepForm} from "../../../../../pages/PaymentReservation/PaymentReservationFormOrInfo";
import useGuestWebsiteStore from "../../../../../stores/guestWebsiteStore/useGuestWebsiteStore";
import {
  PaymentReservationStatusEnum,
  ReservationExtraOrderStatusEnum,
} from "../../../../../enums/GETenums";
import {loadStripe, Stripe, StripeElementsOptions} from "@stripe/stripe-js";
import {capitalizeFirstLetter} from "../../../../../helpers/stringHelper";
import {
  GuestWebsiteStep2Guest,
  GuestWebsiteStep2WithoutRentalResponse,
  GuestWebsiteStep4ExtrasResponse,
} from "../../../../../types/GETTypes";

const PaymentFormWebsite = ({
  from,
  onSuccess,
  customLoading,
  isPreAuth,
  guest,
  stripe_account_id,
  stripe_client_secret,
  amount,
  data,
}: {
  from: StepForm;
  onSuccess: (
    status:
      | PaymentReservationStatusEnum
      | ReservationExtraOrderStatusEnum
      | undefined,
    response?: any,
    confirmationToken?: string
  ) => void;
  customLoading?: boolean;
  isPreAuth?: boolean;
  guest: GuestWebsiteStep2Guest | undefined;
  amount: number | string;
  stripe_account_id: string | undefined;
  stripe_client_secret: string | undefined;
  data:
    | GuestWebsiteStep2WithoutRentalResponse
    | GuestWebsiteStep4ExtrasResponse
    | undefined;
}) => {
  const {dataGuestWebsite} = useGuestWebsiteStore();
  const {first_name, last_name, city, country} = guest || {};
  const [isLoadingStripe, setIsLoadingStripe] = useState(false);
  const [billingDetails, setBillingDetails] = useState({});
  const [stripePromise, setStripePromise] = useState<Stripe | null>(null);
  const [stripeOptions, setStripeOptions] = useState<
    StripeElementsOptions | undefined
  >();

  const {styles} = dataGuestWebsite || {};
  const {color_main} = styles || {};

  const initStripe = async () => {
    setIsLoadingStripe(true);
    const stripeInstance = await loadStripe(import.meta.env.VITE_STRIPE_KEY, {
      stripeAccount: stripe_account_id,
    });
    setStripePromise(stripeInstance);
    let options = {};
    if (from === "websiteExtrasPaymentForm") {
      const values = data as GuestWebsiteStep4ExtrasResponse;
      options = {
        amount: values.total_price_value_in_smaller_unit ?? 0,
        captureMethod: values.extras_order_confirmation_enabled
          ? "manual"
          : "automatic",
      };
    } else if (from === "websitePaymentForm") {
      const values = data as GuestWebsiteStep2WithoutRentalResponse;
      options = {
        amount: values.payment_schedule.payments_schedule_items?.[0]?.price_value_in_smaller_unit ?? 0,
        captureMethod: values.reservation_request_required
          ? "manual"
          : "automatic",
      };
    }
    setStripeOptions({
      ...options,
      locale: "fr",
      mode: "payment",
      currency: "eur",
      paymentMethodCreation: "manual",
    });
    setBillingDetails({
      name: `${capitalizeFirstLetter(first_name)} ${capitalizeFirstLetter(
        last_name
      )}`,
      address: {
        city: capitalizeFirstLetter(city),
        country: capitalizeFirstLetter(country?.[0]?.name  ?? "France"),
      },
    });
    setIsLoadingStripe(false);
  };

  useEffect(() => {
    initStripe();
  }, [data]);
  return (
    <div className="max-w-full">
      {isLoadingStripe ? (
        <></>
      ) : (
        <Elements
          stripe={stripePromise!}
          options={stripeOptions}
          // key={stripeClientSecret}
        >
          <PaymentReservationCheckoutForm
            amount={amount}
            billingDetails={billingDetails}
            onValidatePayment={onSuccess}
            from={from}
            displaySuccessModal={false}
            classNames={{button: "bg-none hover:brightness-[0.8]"}}
            buttonStyleCss={{backgroundColor: color_main}}
            isPreAuth={isPreAuth}
            customLoading={customLoading}
          />
        </Elements>
      )}
    </div>
  );
};

export default PaymentFormWebsite;
