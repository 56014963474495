import React from "react";

import { WebsiteStyleMenu } from "./WebsiteStyleMenu";

import { WebsiteTemplatesCard } from "./Cards/WebsiteTemplatesCard";
import { WebsiteResponse } from "../../types/GETTypes";
import { WebsiteColorsCard } from "./Cards/WebsiteColorsCard";

export const WebsiteStyleCards: React.FC<{
  website: WebsiteResponse | undefined;
  onUpdateWebsite: (nextWebsite: WebsiteResponse) => void;
}> = ({ website, onUpdateWebsite }) => {
  return (
    <div className="grid grid-cols-12 h-screen">
      {/* Menu Sticky */}
      <div className="col-span-2 pr-2 sticky top-16 self-start h-fit">
        <WebsiteStyleMenu />
      </div>

      <div className="col-span-8 flex flex-col gap-3 overflow-y-auto h-[calc(100vh-170px)] no-scrollbar">
        <WebsiteTemplatesCard
          website={website}
          onUpdateWebsite={onUpdateWebsite}
        />

        <WebsiteColorsCard
          website={website}
          onUpdateWebsite={onUpdateWebsite}
        />
      </div>
    </div>
  );
};
