import React from "react";
import { useTranslation } from "react-i18next";
import { ProgressionStep } from "../../../pages/Progression/ProgressionStep";
import { ProgressionStepEliteProps } from "../Progression.type";
import { Button } from "../../Common/Button/Button";
import { useModal } from "../../../hooks/useModal";
import { CenterModal } from "../../Common/CenterModal/CenterModal";

import GiftWhiteIcon from "../../../assets/icons/gift-white.svg?react";
import ArrowRightIcon from "../../../assets/icons/arrow-right-white.svg?react";
import CloseIcon from "../../../assets/icons/close.svg?react";
import paths from "../../../constants/paths";
import { ProgressionStepEnum } from "../../../enums/GETenums";
import { post } from "../../../helpers/APIHelper";
import {useNavigate} from "react-router-dom";

export const ProgressionStepGift: React.FC<ProgressionStepEliteProps> = ({
  status,
  visible,
  number,
  loading,
  error,
}) => {
  const { t } = useTranslation();
  const giftModal = useModal();
  const navigate = useNavigate();


  const handleNavigateGift = () => {
    post(
      `${import.meta.env.VITE_API_URL}${paths.API.COMPLETE_PROGRESSION_STEP}`,
      { progression_step: ProgressionStepEnum.STEP_GIFT }
    );

    giftModal.open();
  };

  const getModalHeader = () => {
    return (
      <div className="bg-element-background-success h-32 flex items-center justify-center relative rounded-t-xl">
        <p className="text-[56px]">🎊</p>
        <div
          className="absolute right-3 top-3 cursor-pointer"
          onClick={giftModal.close}
        >
          <CloseIcon width={16} height={16} />
        </div>
      </div>
    );
  };

  const navigateToCalendar = () => {
    giftModal.close()
    navigate(paths.CALENDAR)
  }

  if (!visible) return null;

  return (
    <>
      <CenterModal
        isVisible={giftModal.isVisible}
        onClose={giftModal.close}
        CustomHeader={getModalHeader()}
      >
        <div className="flex flex-col gap-4">
          <p className="text-lg font-semibold text-high-contrast">
            {t("Progression.StepGift.Modal.title")}
          </p>
          <p className="text-low-contrast font-light">
            {t("Progression.StepGift.Modal.content")}
          </p>

          <iframe
              src="https://player.vimeo.com/video/1017869344?h=7bfc66d868&autoplay=1"
              width="350"
              height="250"
              allow="autoplay; fullscreen"
          ></iframe>


          <div className="float-left w-[100%]">
            <div className=" w-[60%] float-right">
              <Button onClick={() => navigateToCalendar()} RightIcon={ArrowRightIcon}>{t("Progression.StepGift.buttonCalendar")}</Button>
            </div>

          </div>
        </div>
      </CenterModal>

      <ProgressionStep
          number={number.toString()}
          title={t("Progression.StepGift.title")}
          status={status}
        error={error}
        loading={loading}
      >
        <div className="flex flex-row gap-4">
          <div className="w-52 mt-4">
            <Button onClick={handleNavigateGift} RightIcon={GiftWhiteIcon}>
              {t("Progression.StepGift.buttonGift")}
            </Button>
          </div>

          <div className="w-72 mt-4">
            <a
              href="https://www.facebook.com/groups/346839299444884"
              target="_blank"
            >
              <Button onClick={() => {}} type="secondary">
                {t("Progression.StepGift.buttonCommunity")}
              </Button>
            </a>
          </div>
        </div>
      </ProgressionStep>
    </>
  );
};
