import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { TableList } from "../../components/Common/TableList/TableList";
import { MainLayout } from "../../components/Layout/MainLayout/MainLayout";
import paths from "../../constants/paths";
import { useTablePage } from "../../hooks/useTablePage";
import { ValueType } from "../../types/commonTypes";
import { ReservationListPageProps } from "./ReservationListPage.type";

import ChevronDownIcon from "../../assets/icons/chevron-down.svg?react";
import ChevronUpIcon from "../../assets/icons/chevron-up.svg?react";
import { BookingFilters } from "../../components/BookingList/BookingFilters/BookingFilters";
import { NoBookingBookingList } from "../../components/BookingList/NoBooking/NoBookingBookingList";
import { GroupButton } from "../../components/Common/GroupButton/GroupButton";
import { RESERVATION_STATUS } from "../Booking/BookingPage.type";
import { ReservationItem } from "../../components/ReservationList/ReservationItem";
import { ReservationItemSkeleton } from "../../components/ReservationList/ReservationItemSkeleton";
import rentals from "../../res/rentals";
import { ReservationResponse } from "../../types/GETTypes";

export const ReservationListPage: React.FC<ReservationListPageProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedReservations, setSelectedReservations] = useState<
    ReservationResponse[]
  >([]);
  const [checkboxIsFiltered, setCheckboxIsFiltered] = useState<boolean>(false);
  const [allReservationsBase, setAllReservationsBase] = useState<
    ReservationResponse[]
  >([]);

  const tablePage = useTablePage(
    `${import.meta.env.VITE_API_URL}${paths.API.RESERVATIONS}`,
    "reservations",
    {
      otherMetas: [
        "total_request",
        "total_confirmed_finished",
        "total_confirmed_progress",
        "total_confirmed_coming",
        "total_canceled",
        "total_filtered",
      ],
    },
  );

  const handleSelectedReservations = (
    reservations: ReservationResponse[],
  ): void => {
    console.log(reservations);
    setSelectedReservations(reservations);
  };

  const [currentStatus, setCurrentStatus] = useState<RESERVATION_STATUS>(
    RESERVATION_STATUS.CONFIRMED_COMING,
  );

  const handleClickBooking = (id: ValueType) => {
    navigate(`${paths.RESERVATIONS}/${id}`);
  };

  const handleAddBooking = () => {
    navigate(paths.RESERVATIONS_ADD);
  };

  const getFiltersNode = () => {
    return (
      <BookingFilters
        allReservations={tablePage.data}
        bookingsIds={tablePage.currentRentalsId ?? []}
        onAddBooking={handleAddBooking}
        onSearchText={tablePage.handleChangeSearch}
        onSelectBookingIds={(rentalIds: ValueType[]) => {
          tablePage.handleFilterRentalsIds(rentalIds).then((reservations) => {
            if (!checkboxIsFiltered) return;

            let allReservations: ReservationResponse[] = [];

            selectedReservations.map((selectReservation) => {
              if (rentalIds.includes(selectReservation.rental.id)) {
                allReservations.push(selectReservation);
              }
            });

            tablePage.setData(allReservations);
          });
        }}
        onSelectedReservations={handleSelectedReservations}
        setCheckboxIsFiltered={setCheckboxIsFiltered}
        checkboxIsFiltered={checkboxIsFiltered}
      />
    );
  };

  const handleChangeStatus = (value: RESERVATION_STATUS) => {
    setCurrentStatus(value);
  };

  useEffect(() => {
    if (currentStatus === RESERVATION_STATUS.CONFIRMED_COMING) {
      tablePage.handleFilterStatus(currentStatus, "checkin", "asc");
    }

    if (currentStatus === RESERVATION_STATUS.REQUEST) {
      tablePage.handleFilterStatus(currentStatus, "reservation_date", "desc");
    }

    if (currentStatus === RESERVATION_STATUS.CONFIRMED_PROGRESS) {
      tablePage.handleFilterStatus(currentStatus, "checkout", "asc");
    }

    if (currentStatus === RESERVATION_STATUS.CONFIRMED_FINISHED) {
      tablePage.handleFilterStatus(currentStatus, "reservation_date", "desc");
    }

    if (currentStatus === RESERVATION_STATUS.CANCELED) {
      tablePage.handleFilterStatus(currentStatus, "checkin", "desc");
    }

    if (currentStatus === RESERVATION_STATUS.NONE) {
      tablePage.handleFilterStatus(currentStatus, "checkin", "desc");
    }
  }, [currentStatus]);

  const getFilterTotalFromStatus = () => {
    const totalFiltered = Number(
      tablePage.currentOtherMetas.find((m) => m.key === "total_filtered")
        ?.value,
    );

    if (totalFiltered > 0) return totalFiltered;

    if (currentStatus === RESERVATION_STATUS.CONFIRMED_COMING) {
      return Number(
        tablePage.currentOtherMetas.find(
          (m) => m.key === "total_confirmed_coming",
        )?.value,
      );
    }

    if (currentStatus === RESERVATION_STATUS.REQUEST) {
      return Number(
        tablePage.currentOtherMetas.find((m) => m.key === "total_request")
          ?.value,
      );
    }

    if (currentStatus === RESERVATION_STATUS.CONFIRMED_PROGRESS) {
      return Number(
        tablePage.currentOtherMetas.find(
          (m) => m.key === "total_confirmed_progress",
        )?.value,
      );
    }

    if (currentStatus === RESERVATION_STATUS.CONFIRMED_FINISHED) {
      return Number(
        tablePage.currentOtherMetas.find(
          (m) => m.key === "total_confirmed_finished",
        )?.value,
      );
    }

    if (currentStatus === RESERVATION_STATUS.CANCELED) {
      return Number(
        tablePage.currentOtherMetas.find(
          (m) => m.key === "total_confirmed_canceled",
        )?.value,
      );
    }

    if (currentStatus === RESERVATION_STATUS.NONE) {
      return undefined;
    }
  };

  return (
    <>
      <MainLayout title={t("BookingList.title")} sidebarActiveItem="bookings">
        {
          <div className="my-4">
            <GroupButton
              isFetching={tablePage.loading}
              items={[
                {
                  label: tablePage?.total!
                    ? t("Global.all", {
                        context: "female_other",
                        count: tablePage?.total!,
                      })
                    : t("Global.all", {
                        context: "female_one",
                        count: 1,
                      }),
                  value: RESERVATION_STATUS.NONE,
                  isActive: currentStatus === RESERVATION_STATUS.NONE,
                  badge: tablePage?.total?.toString(),
                },
                {
                  label: t("Booking.coming"),
                  value: RESERVATION_STATUS.CONFIRMED_COMING,
                  isActive:
                    currentStatus === RESERVATION_STATUS.CONFIRMED_COMING,
                  badge: tablePage.currentOtherMetas
                    .find((m) => m.key === "total_confirmed_coming")
                    ?.value?.toString(),
                },
                {
                  label: t("Booking.progress"),
                  value: RESERVATION_STATUS.CONFIRMED_PROGRESS,
                  isActive:
                    currentStatus === RESERVATION_STATUS.CONFIRMED_PROGRESS,
                  badge: tablePage.currentOtherMetas
                    .find((m) => m.key === "total_confirmed_progress")
                    ?.value?.toString(),
                },
                {
                  label: t("Booking.finished"),
                  value: RESERVATION_STATUS.CONFIRMED_FINISHED,
                  isActive:
                    currentStatus === RESERVATION_STATUS.CONFIRMED_FINISHED,
                  badge: tablePage.currentOtherMetas
                    .find((m) => m.key === "total_confirmed_finished")
                    ?.value?.toString(),
                },
                {
                  label: t("Booking.canceled"),
                  value: RESERVATION_STATUS.CANCELED,
                  isActive: currentStatus === RESERVATION_STATUS.CANCELED,
                  badge: tablePage.currentOtherMetas
                    .find((m) => m.key === "total_canceled")
                    ?.value?.toString(),
                },
              ]}
              onClick={(value) =>
                handleChangeStatus(value as RESERVATION_STATUS)
              }
            />
          </div>
        }

        <TableList
          i18nElement="Global.bookings"
          Filters={getFiltersNode()}
          Header={ReservationTableHeader}
          Item={ReservationItem}
          Skeleton={ReservationItemSkeleton}
          NoData={<NoBookingBookingList onAdd={handleAddBooking} />}
          filterTotal={getFilterTotalFromStatus()}
          tablePage={tablePage}
          onClick={handleClickBooking}
        />
      </MainLayout>
    </>
  );
};

export type ReservationTableHeaderProps = {
  sortField: string;
  sortDirection: "asc" | "desc";
  onChangeSort: (field: string) => void;
};

export const ReservationTableHeader: React.FC<ReservationTableHeaderProps> = ({
  sortField,
  sortDirection,
  onChangeSort = () => {},
}) => {
  const { t } = useTranslation();

  const handleChangeSort = (column: string) => {
    let nextDirection: "asc" | "desc" = "asc";
    if (column === sortField && sortDirection === "asc") nextDirection = "desc";
    onChangeSort(column);
  };

  const getIcon = (column: string) => {
    const isActive = sortField === column;

    if (isActive && sortDirection === "asc") return <ChevronDownIcon />;
    else if (isActive && sortDirection === "desc") return <ChevronUpIcon />;
    else return <ChevronDownIcon />;
  };
  return (
    <tr className="bg-element-background border-b-1 border-element-border">
      <th
        className="w-2/12 py-3 text-sm ps-4 pe-2"
        onClick={() => handleChangeSort("name")}
      >
        <div
          className={`flex flex-row items-center cursor-pointer hover:underline ${
            sortField === "name" && "underline"
          }`}
        >
          <p>{t("BookingList.rental")}</p> {getIcon("name")}
        </div>
      </th>

      <th
        className="w-1/12 px-2 py-3 text-sm"
        onClick={() => handleChangeSort("guest_name")}
      >
        <div
          className={`flex flex-row items-center cursor-pointer hover:underline ${
            sortField === "guest_name" && "underline"
          }`}
        >
          <p>{t("BookingList.guest")}</p> {getIcon("guest_name")}
        </div>
      </th>

      <th
        className="w-1/12 px-2 py-3 text-sm"
        onClick={() => handleChangeSort("checkin")}
      >
        <div
          className={`flex flex-row items-center cursor-pointer hover:underline ${
            sortField === "checkin" && "underline"
          }`}
        >
          <p>{t("BookingList.checkin")}</p> {getIcon("checkin")}
        </div>
      </th>

      <th
        className="w-1/12 px-2 py-3 text-sm"
        onClick={() => handleChangeSort("checkout")}
      >
        <div
          className={`flex flex-row items-center cursor-pointer hover:underline ${
            sortField === "checkout" && "underline"
          }`}
        >
          <p>{t("BookingList.checkout")}</p> {getIcon("checkout")}
        </div>
      </th>

      <th
        className="w-1/12 px-2 py-3 text-sm"
        onClick={() => handleChangeSort("status")}
      >
        <div
          className={`flex flex-row items-center cursor-pointer hover:underline ${
            sortField === "status" && "underline"
          }`}
        >
          <p>{t("BookingList.bookingStatus")}</p> {getIcon("status")}
        </div>
      </th>

      <th
        className="w-1/12 px-2 py-3 text-sm"
        onClick={() => handleChangeSort("status_payment")}
      >
        <div
          className={`flex flex-row items-center cursor-pointer hover:underline ${
            sortField === "status_payment" && "underline"
          }`}
        >
          <p>{t("BookingList.paymentStatus")}</p> {getIcon("status_payment")}
        </div>
      </th>
    </tr>
  );
};
