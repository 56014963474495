import React from "react";
import { CenterModal } from "../Common/CenterModal/CenterModal";
import { useTranslation } from "react-i18next";
import { Button } from "../Common/Button/Button";

export const AddWebsiteRemoveCustomURLModal: React.FC<{
  isVisible: boolean;
  onClose: () => void;
  onRemove: () => void;
}> = ({ isVisible, onClose, onRemove }) => {
  const { t } = useTranslation();

  const handleClose = () => {
    onClose();
  };

  return (
    <CenterModal
      isVisible={isVisible}
      onClose={handleClose}
      title={t("Website.Add.URL.removeTitle")}
    >
      <div>
        <p className="text-sm font-light text-low-contrast mb-2">
          {t("Website.Add.URL.removeDescription1")}
        </p>

        <p className="text-sm font-light text-low-contrast mb-6">
          {t("Website.Add.URL.removeDescription2")}
        </p>

        <div className="flex gap-3">
          <Button type="secondary" onClick={handleClose}>
            {t("Global.cancel")}
          </Button>
          <Button type="destructive" onClick={onRemove}>
            {t("Global.removeWithConfirmation")}
          </Button>
        </div>
      </div>
    </CenterModal>
  );
};
