import React from "react";
import useUserStore from "../../stores/useUserStore";
import { useTranslation } from "react-i18next";
import { Separator } from "../Common/Separator/Separator";
import FileTextIcon from "../../assets/icons/file-text.svg?react";
import { StepProgress } from "../StepProgress/StepProgress";
import { AddWesiteGeneralType } from "./AddWebsiteGeneral";
import { WebsiteAddStepDataType } from "../../pages/AddWebsite/AddWebsitePage";
import WebsiteModel2Image from "../../assets/images/website-model2.svg?react";
import WebsiteModelChicImage from "../../assets/images/website-model-chic.svg?react";

export const AddWebsiteSummary = ({
  data,
  step,
}: {
  step: "general" | "rentals" | "templates" | "url" | "legals" | "done";
  data: WebsiteAddStepDataType;
}) => {
  const userStore = useUserStore();
  const { t } = useTranslation();

  const getStep = () => {
    if (step === "general")
      return { number: 1, title: t("Website.Add.Summary.general") };
    if (step === "rentals")
      return { number: 2, title: t("Website.Add.Summary.rentals") };
    if (step === "templates")
      return { number: 3, title: t("Website.Add.Summary.templates") };
    if (step === "url")
      return { number: 4, title: t("Website.Add.Summary.url") };
    if (step === "legals")
      return { number: 5, title: t("Website.Add.Summary.legals") };
    if (step === "done")
      return { number: 5, title: t("Website.Add.Summary.done") };

    return { number: 1 };
  };

  return (
    <div className="flex flex-col justify-between w-full h-full text-sm rounded-lg bg-subtle border-1 border-border-subtle">
      {/* Card */}
      <div className="m-4 rounded-lg border border-element-border bg-subtle-light max-h-[80%] overflow-y-auto">
        {/* Cover */}
        {data?.general?.background ? (
          <div className="w-full h-24 bg-element-background rounded-t-6px border-b-1 border-element-border">
            <img
              src={URL.createObjectURL(data?.general?.background)}
              className="object-cover w-full h-full"
            />
          </div>
        ) : (
          <div className="w-full h-24 bg-element-background rounded-t-6px border-b-1 border-element-border"></div>
        )}

        <div className="flex justify-between items-center m-2">
          <div>
            <p className="font-semibold text-high-contrast">
              {t("Website.Add.Summary.websiteTo", {
                fullName: `${userStore?.user?.first_name} ${userStore?.user?.last_name}`,
              })}
            </p>
            {data?.general?.title ? (
              <>
                <p className="text-high-contrast font-semibold">
                  {data?.general?.title}
                </p>

                <p className="text-low-contrast font-light">
                  {data?.general?.subTitle}
                </p>
              </>
            ) : (
              <div className="h-2 bg-slate-300 rounded w-64 mt-1" />
            )}

            {data?.url ? (
              <div className="inline-flex bg-element-background border-1 rounded-6px p-1">
                <p className="text-center font-light text-low-contrast">
                  {data.url}
                </p>
              </div>
            ) : (
              <div className="h-8 bg-slate-100 rounded w-52 mt-2" />
            )}
          </div>

          {!data?.template && (
            <div className="h-8 bg-slate-100 rounded w-32 mt-2 flex items-center gap-1" />
          )}

          {data?.template === "MODEL_2.0" && (
            <div className="h-8 bg-slate-100 rounded w-32 mt-2 flex items-center gap-1 p-1">
              <WebsiteModel2Image className="size-4 rounded-6px" />
              <p className="font-semibold text-high-contrast">Modèle 2.0</p>
            </div>
          )}

          {data?.template === "MODEL_CHIC" && (
            <div className="h-8 bg-slate-100 rounded w-32 mt-2 flex items-center gap-1 p-1">
              <WebsiteModelChicImage className="size-4 rounded-6px" />
              <p className="font-semibold text-high-contrast">Modèle Chic</p>
            </div>
          )}
        </div>

        <Separator />

        <div className="flex gap-1 items-center m-2">
          <FileTextIcon className="size-4" />

          <p className="font-semibold text-high-contrast">
            {t("Website.Add.Summary.visibleRentals")}
          </p>
        </div>

        {data?.rentals && data?.rentals?.length > 0
          ? data?.rentals?.map((r) => (
              <div className="flex gap-2 items-center m-2">
                <img
                  src={r.cover}
                  className="rounded-6px size-6 bg-element-background border-1 border-element-border"
                />
                <p className="text-low-contrast font-semibold">{r.name}</p>
              </div>
            ))
          : Array.from({ length: 3 }).map((d, index) => (
              <div className="flex items-center gap-2 m-2" key={index}>
                <div className="size-8 rounded-6px bg-slate-200" />

                <div className="h-2 w-52 bg-slate-200 rounded" />
              </div>
            ))}

        <div className="h-32" />
      </div>

      <div className="px-16 pb-8">
        <p className="text-low-contrast font-semibold text-xs">
          {t("Global.step")} {getStep().number} / 5
        </p>

        <p className="mt-2 text-lg font-semibold text-high-contrast">
          {getStep().title}
        </p>

        <p className="mt-3 font-light text-low-contrast">
          {t("Website.Add.Summary.later")}
        </p>

        <StepProgress maxStep={5} currentStep={getStep().number} />
      </div>
    </div>
  );
};
