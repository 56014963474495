import { ReactElement, useMemo } from "react";
import {
  CalendarReservationResponse,
  ReservationResponse,
} from "../../../types/GETTypes";
import React from "react";
import Lock from "./Lock";
import { FlexCol } from "../../Common/FlexComponents/FlexCol";
import { ReservationForCalendarList } from "../../../pages/Booking/BookingPage.type";

export default function LocksSection({
  reservation,
}: {
  reservation:
    | CalendarReservationResponse
    | ReservationResponse
    | ReservationForCalendarList;
}): ReactElement {
  const locks = useMemo(() => {
    return reservation.locks;
  }, [reservation.locks]);

  return (
    <FlexCol gap={5} className={"items-start"}>
      {locks.map((lock) => (
        <Lock lock={lock} />
      ))}
    </FlexCol>
  );
}
