import React, {useEffect, useState} from "react";
import {FileInstruction} from "../../../types/GETTypes";
import ImageGalleryModal from "./ImageGalleryModalComponent";
import {isImageFile, isVideoFile} from "../../../helpers/fileHelper";
import {useTranslation} from "react-i18next";
import {ClassNamesType} from "../../../types/commonTypes";
import {cn} from "../../../helpers/classHelper";

const InstructionImagesComponent = ({
  images,
  classNames,
}: {
  images?: FileInstruction[];
  classNames?: {divVideo?: string; divImg?: string};
}) => {
  const {t} = useTranslation();
  const [isModalGalleryVideoOpen, setIsModalGalleryVideoOpen] = useState(false);
  const [isModalGalleryImageOpen, setIsModalGalleryImageOpen] = useState(false);
  const [currentIndexVideoFile, setCurrentIndexVideoFile] = useState(0);
  const [currentIndexImgFile, setCurrentIndexImgFile] = useState(0);
  const [videos, setVideos] = useState<FileInstruction[]>([]);
  const [imgs, setImgs] = useState<FileInstruction[]>([]);

  useEffect(() => {
    setImgs(images?.filter(({mime_type}) => isImageFile(mime_type)) ?? []);
    setVideos(images?.filter(({mime_type}) => isVideoFile(mime_type)) ?? []);
  }, []);

  if (!images) {
    return null;
  }

  const openModalVideo = (idx: number) => {
    setCurrentIndexVideoFile(idx);
    setIsModalGalleryVideoOpen(true);
  };
  const openModalImage = (idx: number) => {
    setCurrentIndexImgFile(idx);
    setIsModalGalleryImageOpen(true);
  };

  const classNameDivVideo =
    videos?.length > 0 && !imgs?.length ? classNames?.divVideo : "";
  const classNameDivImgs =
    imgs?.length > 0 && !videos?.length
      ? classNames?.divImg
      : imgs?.length > 0 && videos?.length > 0
      ? classNames?.divImg
      : "";

  return (
    <>
      {videos && videos?.length > 0 && (
        <div
          className={cn(
            "flex flex-col flex-wrap w-full border-b-1 border-element-border pb-2 mb-3",
            classNameDivVideo
          )}
        >
          <div className={"text-high-contrast text-lg font-bold pb-2"}>
            {t("GuestPage.Global.videos")}
          </div>
          {videos.map((video, index) => {
            const {original_url} = video;
            return (
              <div
                key={video.id}
                className="relative object-fill w-32 h-24 overflow-hidden rounded-md border-1 border-element-border/50 m-1 ml-0 cursor-pointer"
                onClick={() => openModalVideo(index)}
              >
                <div className="relative h-full w-full">
                  <video
                    src={original_url}
                    className="h-full w-full object-cover rounded-lg"
                    muted
                    preload="metadata"
                  />
                  <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-25">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 text-white"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.752 11.168l-7.197-4.132A1 1 0 006 7.868v8.264a1 1 0 001.555.832l7.197-4.132a1 1 0 000-1.664z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            );
          })}
          <ImageGalleryModal
            title={t("GuestPage.Global.videos")}
            images={videos}
            selectedImageIndex={currentIndexVideoFile}
            setSelectedImageIndex={setCurrentIndexVideoFile}
            isOpen={isModalGalleryVideoOpen}
            onClose={() => setIsModalGalleryVideoOpen(false)}
          />
        </div>
      )}
      {imgs && imgs?.length > 0 && (
        <div
          className={cn(
            "flex flex-col flex-wrap w-full border-b-1 border-element-border pb-2 mb-3",
            classNameDivImgs
          )}
        >
          <div className={"text-high-contrast text-lg font-bold pb-2"}>
            {t("GuestPage.Global.photos")}
          </div>
          <div>
            {imgs.map((image, index) => {
              const {original_url, name} = image;
              return (
                <div
                  key={image.id}
                  className="relative object-fill w-24 h-24 overflow-hidden rounded-md border-1 border-element-border/50 m-1 ml-0 cursor-pointer"
                  onClick={() => openModalImage(index)}
                >
                  <div className="relative h-full w-full">
                    <img
                      src={original_url}
                      alt={name}
                      className="object-cover w-full h-full"
                    />
                  </div>
                </div>
              );
            })}
            <ImageGalleryModal
              title={t("GuestPage.Global.photos")}
              images={imgs}
              selectedImageIndex={currentIndexImgFile}
              setSelectedImageIndex={setCurrentIndexImgFile}
              isOpen={isModalGalleryImageOpen}
              onClose={() => setIsModalGalleryImageOpen(false)}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default InstructionImagesComponent;
