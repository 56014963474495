import {getFormattedPrice} from "./currencyHelper";

export interface PlatformCalculate {
  priceByNight: string;
  tax: string;
  paidByGuest: string;
  additionalFees: string | null;
  commissions: string | null;
  paidBank: string;
  paidNet: string;
}

export interface PriceMultiplierData {
  website: PlatformCalculate;
  airbnb: PlatformCalculate;
  booking: PlatformCalculate;
  price_multiplier_airbnb: number;
  price_multiplier_booking: number;
  price_multiplier_platform_website: number;
  price_by_night: number;
  tax_by_night_per_person: number;
}

export function convertToNumber(value: string | number) {
  let val = value;
  if (typeof val === "number") {
    return val;
  } else if (typeof val === "string") {
    // Remove thousands separators
    val = val.replace(/[.,]/g, (match) => (match === "," ? "." : ""));
  }
  return Number(val);
}

export function addPercentage(number: number, percentage: number) {
  return number + number * (percentage / 100);
}

export function multiplyByPercentage(number: number, percentage: number) {
  return (number * percentage) / 100;
}

export function calculateFees(number: number) {
  return (number * 1.5) / 100 + 0.25;
}

export const prepareDataWebsite = ({
  priceMultiplierWebsite,
  priceByNight,
  tax,
}: {
  priceMultiplierWebsite: number;
  priceByNight: number;
  tax: number;
}) => {
  const priceNight = addPercentage(priceByNight, priceMultiplierWebsite);
  const paidByGuestValue = priceNight + tax;
  const additionalFeesValue = calculateFees(paidByGuestValue);
  const paidBankValue = paidByGuestValue - additionalFeesValue;
  const paidNetValue = paidBankValue - tax;

  const website: PlatformCalculate = {
    priceByNight: getFormattedPrice({
      price: priceNight,
      decimals: 2,
      withCurrency: false,
    }),
    tax: getFormattedPrice({
      price: tax,
      decimals: 2,
      withCurrency: false,
    }),
    paidByGuest: getFormattedPrice({
      price: paidByGuestValue,
      decimals: 2,
      withCurrency: false,
    }),
    additionalFees: getFormattedPrice({
      price: additionalFeesValue,
      decimals: 2,
      withCurrency: false,
    }),
    commissions: null,
    paidBank: getFormattedPrice({
      price: paidBankValue,
      decimals: 2,
      withCurrency: false,
    }),
    paidNet: getFormattedPrice({
      price: paidNetValue,
      decimals: 2,
      withCurrency: false,
    }),
  };
  return website;
};
export const prepareDataDirect = ({
  priceByNight,
  tax,
}: {
  priceByNight: number;
  tax: number;
}) => {
  const paidByGuestValue = priceByNight + tax;
  const additionalFeesValue = calculateFees(paidByGuestValue);
  const paidBankValue = paidByGuestValue - additionalFeesValue;
  const paidNetValue = paidBankValue - tax;

  const website: PlatformCalculate = {
    priceByNight: getFormattedPrice({
      price: priceByNight,
      decimals: 2,
      withCurrency: false,
    }),
    tax: getFormattedPrice({
      price: tax,
      decimals: 2,
      withCurrency: false,
    }),
    paidByGuest: getFormattedPrice({
      price: paidByGuestValue,
      decimals: 2,
      withCurrency: false,
    }),
    additionalFees: getFormattedPrice({
      price: additionalFeesValue,
      decimals: 2,
      withCurrency: false,
    }),
    commissions: null,
    paidBank: getFormattedPrice({
      price: paidBankValue,
      decimals: 2,
      withCurrency: false,
    }),
    paidNet: getFormattedPrice({
      price: paidNetValue,
      decimals: 2,
      withCurrency: false,
    }),
  };
  return website;
};

export const prepareDataBooking = ({
  priceMultiplierBooking,
  priceByNight,
  tax,
}: {
  priceMultiplierBooking: number;
  priceByNight: number;
  tax: number;
}) => {
  const priceNight = addPercentage(priceByNight, priceMultiplierBooking);
  const paidByGuestValue = priceNight + tax;
  const commissions = multiplyByPercentage(priceNight, 17);
  const additionalFees = multiplyByPercentage(paidByGuestValue, 1.4);
  const paidBankValue = paidByGuestValue - additionalFees - commissions;
  const paidNetValue = paidBankValue - tax;

  const booking: PlatformCalculate = {
    priceByNight: getFormattedPrice({
      price: priceNight,
      decimals: 2,
      withCurrency: false,
    }),
    tax: getFormattedPrice({
      price: tax,
      decimals: 2,
      withCurrency: false,
    }),
    paidByGuest: getFormattedPrice({
      price: paidByGuestValue,
      decimals: 2,
      withCurrency: false,
    }),
    additionalFees: getFormattedPrice({
      price: additionalFees,
      decimals: 2,
      withCurrency: false,
    }),
    commissions: getFormattedPrice({
      price: commissions,
      decimals: 2,
      withCurrency: false,
    }),
    paidBank: getFormattedPrice({
      price: paidBankValue,
      decimals: 2,
      withCurrency: false,
    }),
    paidNet: getFormattedPrice({
      price: paidNetValue,
      decimals: 2,
      withCurrency: false,
    }),
  };
  return booking;
};

export const prepareDataAirbnb = ({
  priceMultiplierAirbnb,
  tax,
  priceByNight,
}: {
  priceMultiplierAirbnb: number;
  tax: number;
  priceByNight: number;
}) => {
  const priceNight = addPercentage(priceByNight, priceMultiplierAirbnb);
  const paidByGuestValue = priceNight + tax;
  const commissionsHT = multiplyByPercentage(priceNight, 15);
  const commissionsTTC = commissionsHT * addPercentage(1, 20);
  const paidBankValue = paidByGuestValue - tax - commissionsTTC;
  const paidNetValue = paidBankValue;

  const airbnb: PlatformCalculate = {
    priceByNight: getFormattedPrice({
      price: priceNight,
      decimals: 2,
      withCurrency: false,
    }),
    tax: getFormattedPrice({
      price: tax,
      decimals: 2,
      withCurrency: false,
    }),
    paidByGuest: getFormattedPrice({
      price: paidByGuestValue,
      decimals: 2,
      withCurrency: false,
    }),
    additionalFees: null,
    commissions: getFormattedPrice({
      price: commissionsTTC,
      decimals: 2,
      withCurrency: false,
    }),
    paidBank: getFormattedPrice({
      price: paidBankValue,
      decimals: 2,
      withCurrency: false,
    }),
    paidNet: getFormattedPrice({
      price: paidNetValue,
      decimals: 2,
      withCurrency: false,
    }),
  };
  return airbnb;
};

function isPositive(numberString: string) {
  // Replace comma with dot and convert to a number
  const number = parseFloat(numberString.replace(",", "."));

  // Check if the conversion was successful and if the number is 0 or positive
  return !isNaN(number) && number > 0;
}

export function getSignNumber({
  number = "0",
  withNumber = true,
  showNegativeSign = true,
}: {
  number: string | undefined | null;
  withNumber?: boolean;
  showNegativeSign?: boolean;
}) {
  if (number === undefined || number === null) {
    return {result: "", positive: false};
  }
  return {
    result: isPositive(number)
      ? "+" + (withNumber ? number : "")
      : showNegativeSign
      ? "-" + (withNumber ? number : "")
      : "",
    positive: isPositive(number),
  };
}
