import React from "react";

export const PaymentReservationListPageSkeleton: React.FC<{}> = () => {
  return (
    <>
      <tr className="cursor-pointer hover:bg-element-background/40 animate-pulse">
        {/* reservation */}
        <td className="flex flex-row items-center w-full px-2 py-3 space-x-4 text-sm">
          <div className="relative">
            <div className="w-10 h-10 rounded-4px border-1 border-element-border/50 bg-slate-200" />
          </div>

          <div className="flex flex-col">
            <p className="w-32 h-2 rounded bg-slate-300"></p>
            <p className="h-2 mt-2 rounded w-52 bg-slate-200"></p>
            <p className="w-12 h-2 mt-1 rounded bg-slate-200"></p>
          </div>
        </td>

        {/* guest */}
        <td className="px-2 py-2">
          <p className="w-full h-2 mt-2 rounded bg-slate-200"></p>
        </td>

        {/* platform */}
        <td className="px-2 py-2 mt-2">
          <div className="flex items-center justify-center space-x-2">
            <div className="rounded-full bg-slate-200 size-8"></div>
            <p className="flex-1 h-2 rounded bg-slate-200"></p>
          </div>
        </td>

        {/* reservation reference */}
        <td className="px-2 py-2 text-sm">
          <p className="w-full h-2 mt-2 rounded bg-slate-200"></p>
        </td>

        {/* means of payment */}
        <td className="px-2 py-2">
          <p className="w-full h-2 mt-2 rounded bg-slate-200"></p>
        </td>

        {/* status */}
        <td className="px-2 py-2 text-sm">
          <div className="h-9 w-28 bg-slate-200 rounded-6px"></div>
        </td>

        {/* price */}
        <td className="w-40 px-2 py-2">
          <div className="flex justify-end">
            <p className="w-full h-2 mt-2 rounded bg-slate-200"></p>
          </div>
        </td>
      </tr>

      <tr className="animate-pulse">
        <td
          className="relative h-16 px-6 border-b-1 border-element-border"
          colSpan={4}
        >
          <div className="absolute w-4 transform rotate-90 border-t top-[23px] left-4 border-slate-200"></div>
          <div className="absolute transform -translate-y-1/2 border-t border-slate-200 left-6 right-4 top-1/2"></div>
        </td>
        <td className="px-2 py-2 border-b-1 border-element-border">
          <p className="w-40 h-2 mt-2 rounded bg-slate-200"></p>
        </td>
        <td className="px-2 py-2 text-sm border-b-1 border-element-border">
          <div className="h-9 w-28 bg-slate-200 rounded-6px"></div>
        </td>
        <td className="w-40 px-2 py-2 border-b-1 border-element-border">
          <div className="flex justify-end">
            <p className="w-full h-2 mt-2 rounded bg-slate-200"></p>
          </div>
        </td>
      </tr>
    </>
  );
};
