import {useTranslation} from "react-i18next";
import {ReservationExtraOrderStatusEnum} from "../enums/GETenums";
import {ExtrasOrder} from "../components/GuestPageUser/Common/ExtraComponent";
import {ResultOrder} from "../types/GETTypes";

export const getColorTextValidate = (
  status: ReservationExtraOrderStatusEnum
) => {
  const t = useTranslation().t;
  switch (status) {
    case ReservationExtraOrderStatusEnum.PAID:
    case ReservationExtraOrderStatusEnum.PARTIALLY_PAID:
      return {
        color: "text-low-contrast font-bold",
        text: t("Booking.ExtrasOrder.valid"),
      };
    case ReservationExtraOrderStatusEnum.UNPAID:
      return {color: "", text: ""};
    case ReservationExtraOrderStatusEnum.REFUNDED:
      return {color: "", text: ""};
    case ReservationExtraOrderStatusEnum.CANCELED:
      return {color: "", text: ""};
    case ReservationExtraOrderStatusEnum.PRE_AUTHORIZED:
      return {
        color: "text-active font-bold",
        text: t("Booking.ExtrasOrder.toValidate"),
      };
  }
};

export const formatExtrasOrder = (extrasOrder: ExtrasOrder[]) => {
  const orderedResult: ResultOrder = extrasOrder.reduce<ResultOrder>(
    (acc, item, index) => {
      if (index === 0) {
        acc.ordered_extras_ids = `${item.id}`;
        acc.ordered_extras_quantities = `${item.quantity}`;
      } else {
        acc.ordered_extras_ids += `,${item.id}`;
        acc.ordered_extras_quantities += `,${item.quantity}`;
      }
      return acc;
    },
    {ordered_extras_ids: "", ordered_extras_quantities: ""}
  );
  return orderedResult;
};
