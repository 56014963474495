import React from "react";
import GuestWebsiteTemplate2FooterComponent from "./Template2.0/GuestWebsiteTemplate2FooterComponent.tsx";
import useGuestWebsiteStore from "../../../stores/guestWebsiteStore/useGuestWebsiteStore.js";
import {GuestWebsiteModel} from "../../../types/GETTypes.js";

interface GuestWebsiteFooterLayoutProps {
  loading: boolean;
}

const RedirectToCorrectTemplate = (props: GuestWebsiteFooterLayoutProps) => {
  const {templateGuestWebsite} = useGuestWebsiteStore();
  switch (templateGuestWebsite) {
    case GuestWebsiteModel.MODEL_2_0:
      return <GuestWebsiteTemplate2FooterComponent {...props} />;
    case GuestWebsiteModel.MODEL_CHIC:
      return <></>;
    case GuestWebsiteModel.MODEL_MINIMAL:
      return <></>;
    default:
      return <GuestWebsiteTemplate2FooterComponent {...props} />;
  }
};

const GuestWebsiteFooterLayout = (props: GuestWebsiteFooterLayoutProps) => {
  return <RedirectToCorrectTemplate {...props} />;
};

export default GuestWebsiteFooterLayout;
