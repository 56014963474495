import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ArrowLeftIcon from "../../assets/icons/arrow-left.svg?react";
import ArrowRightIcon from "../../assets/icons/arrow-right-white.svg?react";
import CheckIcon from "../../assets/icons/check-white.svg?react";
import { useAutomation } from "../../hooks/api/automation";
import { useChecklist } from "../../hooks/api/checklist";
import {
  useOperationalManagementMember,
  useOperationalManagementTeam,
} from "../../hooks/api/operationalManagement";
import { useAllRentalAccessList } from "../../hooks/api/rental";
import { useCheckAuth } from "../../hooks/useCheckAuth";
import { UseModal } from "../../hooks/useModal";
import {
  AutomationItemResponse,
  OperationalManagementTeamMemberForUserItemResponse,
  OperationalManagementTeamMemberResponse,
  RentalChecklistsResponse,
  RentalLightListItemResponse,
} from "../../types/GETTypes";
import { Button } from "../Common/Button/Button";
import { CarouselWizard } from "../Common/CarouselWizard/CarouselWizard";
import { ErrorMessage } from "../Common/ErrorMessage/ErrorMessage";
import { RightModal } from "../Common/RightModal/RightModal";
import { Separator } from "../Common/Separator/Separator";
import { ManagementAutomationListAssignationStep } from "./AddOrUpdateModalSteps/AssignationStep/AssignationStep";
import { ManagementAutomationListAssignationStepForm } from "./AddOrUpdateModalSteps/AssignationStep/AssignationStep.type";
import { ManagementAutomationListInformationsStep } from "./AddOrUpdateModalSteps/InformationsStep/InformationsStep";
import { ManagementAutomationListInformationsStepForm } from "./AddOrUpdateModalSteps/InformationsStep/InformationsStep.type";
import { ManagementAutomationListProgramStep } from "./AddOrUpdateModalSteps/ProgramStep/ProgramStep";
import { ManagementAutomationListProgramStepForm } from "./AddOrUpdateModalSteps/ProgramStep/ProgramStep.type";

type ManagementAutomationListAddModalProps = {
  modal: UseModal<{}>;
  onAdd: (automation: AutomationItemResponse) => void;
};

export const ManagementAutomationListAddModal: React.FC<
  ManagementAutomationListAddModalProps
> = ({ modal, onAdd }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [validationLoading, setValidationLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null | undefined>(null);

  const [currentStep, setCurrentStep] = useState<number>(0);

  // * -- Informations part --
  const [rentals, setRentals] = useState<
    RentalLightListItemResponse[] | undefined
  >();

  const [checklists, setChecklists] = useState<
    RentalChecklistsResponse[] | undefined
  >();

  const informationsForm =
    useForm<ManagementAutomationListInformationsStepForm>({
      mode: "all",
      defaultValues: {
        concernedRental: "",
        automationType: "CLEANING",
        nameTask: null,
        amount_global: null,
        descriptionTask: null,
        relatedChecklist: null,
        taskRemark: null,
      },
    });

  useEffect(() => {
    if (currentStep === 0) {
      if (!rentals) {
        useAllRentalAccessList(
          (data) => {
            setLoading(false);
            setRentals(data);
          },
          (message) => setError(message),
        );
      }
    }
  }, [currentStep]);

  useEffect(() => {
    if (informationsForm.getValues("concernedRental") !== "") {
      const { getChecklistFilter } = useChecklist({
        rentalId: informationsForm.getValues("concernedRental"),
        onSuccess: (checklists) =>
          setChecklists(Array.isArray(checklists) ? checklists : undefined),
        onError: (message: string | null) => setError(message),
        onStart: () => {
          setError(null);
          setLoading(true);
        },
        onEnd: () => setLoading(false),
      });
      getChecklistFilter();
    }
  }, [currentStep, informationsForm.getValues("concernedRental")]);

  // * -- Program part --
  const programForm = useForm<ManagementAutomationListProgramStepForm>({
    mode: "all",
    defaultValues: {
      programTask: "checkout",
    },
  });

  // * -- Assignation part --
  const assignationForm = useForm<ManagementAutomationListAssignationStepForm>({
    mode: "all",
    defaultValues: {
      assignation_type: "OPERATORS",
      assigned_persons: [],
      assigned_teams: [],
    },
  });

  const userId = useCheckAuth().user?.id;
  const [persons, setPersons] = useState<
    OperationalManagementTeamMemberResponse[] | undefined
  >();
  const [teams, setTeams] = useState<
    OperationalManagementTeamMemberForUserItemResponse[] | undefined
  >();

  const getPersonsOrTeams = () => {
    if (
      assignationForm.getValues("assignation_type") === "OPERATORS" &&
      (!persons || persons?.length === 0)
    ) {
      const operationalManagementMemberApi = useOperationalManagementMember({
        onSuccess: (data) => {
          setPersons(data);
        },
        onError: (message: string | null) => setError(message),
        onStart: () => {
          setError(null);
          setLoading(true);
        },
        onEnd: () => setLoading(false),
      });

      operationalManagementMemberApi.getAll();
    } else if (
      assignationForm.getValues("assignation_type") === "TEAMS" &&
      (!teams || teams?.length === 0)
    ) {
      const operationalManagementTeamApi = useOperationalManagementTeam({
        onSuccess: (data) => {
          setTeams(data);
        },
        onError: (message: string | null) => setError(message),
        onStart: () => {
          setError(null);
          setLoading(true);
        },
        onEnd: () => setLoading(false),
      });

      operationalManagementTeamApi.getAllForUser({
        memberId: userId,
      });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (currentStep === 2) {
      getPersonsOrTeams();
    }
  }, [currentStep, assignationForm.getValues("assignation_type")]);

  useEffect(() => {
    if (
      teams &&
      teams.length > 0 &&
      assignationForm.getValues("assignation_type") === "TEAMS"
    ) {
      assignationForm.setValue("assigned_teams", [teams[0].id]);
      assignationForm.clearErrors("assigned_teams");
    }

    if (
      persons &&
      persons.length > 0 &&
      assignationForm.getValues("assignation_type") === "OPERATORS"
    ) {
      assignationForm.setValue("assigned_persons", [persons[0].id]);
      assignationForm.clearErrors("assigned_persons");
    }
  }, [teams, persons, assignationForm.getValues("assignation_type")]);

  const handleAddAutomation = async () => {
    const { create } = useAutomation({
      onSuccess: (automation) => {
        if (automation) {
          onAdd(automation as AutomationItemResponse);
        }

        setCurrentStep(0);

        informationsForm.reset();
        programForm.reset();
        assignationForm.reset();

        modal.close();
      },
      onError: (message: string | null) => {
        setError(message);
      },
      onStart: () => {
        setError(null);
        setValidationLoading(true);
      },
      onEnd: () => {
        setValidationLoading(false);
      },
    });

    create({
      automation: {
        enabled: true,
        rental_id: informationsForm.getValues("concernedRental"),
        type: informationsForm.getValues("automationType"),
        // checklist_id: null,
        checklist_id: informationsForm.getValues("relatedChecklist"),
        name: informationsForm.getValues("nameTask")!,
        description: informationsForm.getValues("descriptionTask")!,
        remarks: informationsForm.getValues("taskRemark")!,
        trigger: programForm.getValues("programTask"),
        amount_global: informationsForm.getValues("amount_global"),
        assignation_type: assignationForm.getValues("assignation_type"),
        assignee_ids:
          assignationForm.getValues("assignation_type") === "OPERATORS"
            ? assignationForm.getValues("assigned_persons")
            : assignationForm.getValues("assigned_teams"),
      },
    });
  };

  const steps = [
    {
      title: t("Automation.AddOrUpdateModal.Steps.Informations.title"),
      Node: (
        <ManagementAutomationListInformationsStep
          loading={loading}
          mode={"create"}
          form={informationsForm}
          rentals={rentals}
          checklists={checklists}
        />
      ),
    },
    {
      title: t("Automation.AddOrUpdateModal.Steps.Program.title"),
      Node: (
        <ManagementAutomationListProgramStep
          loading={loading}
          form={programForm}
          rentals={rentals}
          informationsValues={informationsForm.getValues()}
        />
      ),
    },
    {
      title: t("Automation.AddOrUpdateModal.Steps.Assignation.title"),
      Node: (
        <ManagementAutomationListAssignationStep
          loading={loading}
          form={assignationForm}
          persons={persons}
          teams={teams}
        />
      ),
    },
  ];
  const maxStep = steps.length - 1;

  const hasOneOrMoreInvalidStep =
    (currentStep === 0 &&
      Object.values(informationsForm.formState.errors).length > 0) ||
    (currentStep === 1 &&
      Object.values(programForm.formState.errors).length > 0) ||
    (currentStep === 2 &&
      Object.values(assignationForm.formState.errors).length > 0);

  const handleChangeStep = (step: number) => {
    setCurrentStep((prevStep) => {
      if (hasOneOrMoreInvalidStep) {
        return prevStep;
      }

      return step;
    });
  };

  return (
    <RightModal
      title={t("Automation.AddOrUpdateModal.addTitle")}
      isVisible={modal.isVisible}
      onClose={validationLoading ? () => {} : modal.close}
      classNames={{
        mainContentParent: "overflow-y-auto",
      }}
    >
      <>
        <div className="flex flex-col justify-between w-full">
          <div className="flex flex-col justify-center overflow-y-visible gap-y-2">
            <CarouselWizard
              currentStepIndex={currentStep}
              stepsUnlocked={true}
              steps={steps}
              onChangeStep={handleChangeStep}
            />
          </div>

          <div className="flex flex-col">
            <Separator />
            <ErrorMessage>{error}</ErrorMessage>
            <div className="flex justify-between gap-x-4">
              <Button
                type="secondary"
                disabled={validationLoading}
                LeftIcon={currentStep > 0 ? ArrowLeftIcon : undefined}
                onClick={
                  currentStep > 0
                    ? () => setCurrentStep((prevStep) => prevStep - 1)
                    : () => modal.close()
                }
              >
                {currentStep === 0 ? t("Global.cancel") : t("Global.previous")}
              </Button>
              <Button
                type="primary"
                RightIcon={currentStep < maxStep ? ArrowRightIcon : CheckIcon}
                loading={validationLoading}
                disabled={validationLoading}
                onClick={
                  currentStep < maxStep
                    ? () => setCurrentStep((prevStep) => prevStep + 1)
                    : handleAddAutomation
                }
              >
                {currentStep < maxStep
                  ? t("Global.next")
                  : t("Automation.AddOrUpdateModal.addAutomation")}
              </Button>
            </div>
          </div>
        </div>
      </>
    </RightModal>
  );
};
