import React, {useEffect, useRef, useState} from "react";
import {RightModal} from "../../../Common/RightModal/RightModal";
import {ErrorMessage} from "../../../Common/ErrorMessage/ErrorMessage";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Separator} from "../../../Common/Separator/Separator";
import {Button} from "../../../Common/Button/Button";
import {Controller, useForm} from "react-hook-form";
import {ContractsModalForm} from "./ContractsModal.type";
import {TextInput} from "../../../Common/TextInput/TextInput";
import {
  ModalContractsDeleteData,
  ModalContractsEditData,
} from "../../../../pages/RentalPage/RentalPage.type";
import {del, patch} from "../../../../helpers/APIHelper";
import paths from "../../../../constants/paths";
import {insertTextAtPosition} from "../../../../helpers/stringHelper";
import {UseModal, useModal} from "../../../../hooks/useModal";
import {ShortCodeList} from "../../../Message/ShortCodeList/ShortCodeList";
import {Switch} from "../../../Common/Switch/Switch";
import {cn} from "../../../../helpers/classHelper";
import {Title} from "../../../Common/Title/Title";
import CloseIcon from "../../../../assets/icons/close.svg?react";
import {LexicalEditor} from "lexical";
import {insertShortCodeInEditor} from "../../../../helpers/editorHelper";
import {CustomEditor} from "../../../Common/CustomEditor/CustomEditor";
import {ContractsResponse} from "../../../../types/GETTypes";

type RentalEditContractsModalProps = {
  isVisible: boolean;
  rentalId: string | undefined;
  onClose: () => void;
  data: ModalContractsEditData | null | undefined;
  contracts: ContractsResponse[] | undefined;
  defaultSelectedContract: number | undefined;
  onSuccess: () => Promise<void>;
  modalDelete: UseModal<ModalContractsDeleteData>;
};
const RentalEditContractsModal = ({
  isVisible,
  onClose,
  data,
  rentalId,
  contracts,
  defaultSelectedContract,
  onSuccess,
  modalDelete,
}: RentalEditContractsModalProps) => {
  const {resource: contract} = data || {};
  console.log("🚀 ~ contract:", contract);
  const {t} = useTranslation();
  const navigate = useNavigate();

  const [error, setError] = useState<string | undefined>();
  const [loadingValidation, setLoadingValidation] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const modalShortCode = useModal();
  const editorRef = useRef<LexicalEditor>();
  const form = useForm<ContractsModalForm>({
    defaultValues: {
      name: "",
      content: "",
    },
  });

  useEffect(() => {
    form.setValue("name", contract?.name ?? "");
    form.setValue(
      "content",
      contract && "content" in contract ? contract?.content ?? "" : ""
    );
  }, [contract]);

  const handleUpdateContract = async (data: ContractsModalForm) => {
    setLoadingValidation(true);
    if (contract) {
      const response = await patch(
        `${
          import.meta.env.VITE_API_URL
        }${paths.API.CONTRACTS_DOCS.UPDATE_CONTRACT_BY_RENTAL(
          Number(rentalId),
          contract.id
        )}`,
        data
      );
      if (response?.data?.success) {
        closeModal();
        onSuccess();
      }
    }
    setLoadingValidation(false);
  };

  const handleInsertShortCode = (trigger: string) => {
    insertShortCodeInEditor({text: trigger, modalShortCode, editorRef});
  };

  const closeModal = () => {
    form.reset();
    modalShortCode.close();
    onClose();
  };

  const getTitle = () => {
    if (modalShortCode.isVisible)
      return (
        <div className="flex">
          <div className="flex justify-between w-modal">
            <Title>{t("Templates.shortcodes")}</Title>
            <div
              className="pt-1 hover:cursor-pointer mr-6"
              onClick={() => modalShortCode.close()}
            >
              <CloseIcon />
            </div>
          </div>
          <div className="flex-1">
            <Title>{contract?.name}</Title>
          </div>
        </div>
      );
    else return contract?.name;
  };

  const displayDeleteButton =
    contracts &&
    contracts?.length > 1 &&
    contract?.id !== defaultSelectedContract;

  return (
    <>
      <RightModal
        title={getTitle()}
        isVisible={isVisible}
        onClose={closeModal}
        isSplit={modalShortCode.isVisible}
      >
        {modalShortCode.isVisible ? (
          <div
            className={cn(
              "flex-1 pr-8 h-full",
              modalShortCode.isVisible
                ? ""
                : "overflow-y-scroll"
            )}
          >
            <ShortCodeList onInsert={handleInsertShortCode} />
          </div>
        ) : null}
        <>
          <div
            className={cn(
              `flex flex-col gap-3`,
              modalShortCode.isVisible ? "w-modal-inside" : "w-full"
            )}
          >
            <ErrorMessage>{error}</ErrorMessage>
            <div>
              <Controller
                control={form.control}
                name="name"
                rules={{
                  required: {
                    value: true,
                    message: t("Rental.Contracts.Contracts.Modal.formRequired"),
                  },
                }}
                render={({field: {value, onChange}}) => (
                  <TextInput
                    label={t("Rental.Checklist.Upsert.name")}
                    required={true}
                    placeholder={t(
                      "Rental.Contracts.Contracts.Modal.formNamePlaceholder"
                    )}
                    disabled={loadingValidation}
                    value={value}
                    error={form.formState.errors.name?.message}
                    onChangeText={onChange}
                  />
                )}
              />
            </div>
            <div>
              <CustomEditor
                ref={editorRef}
                form={form}
                formControllerName="content"
                error={form.formState.errors.content?.message}
                disabled={loadingValidation}
                label={t("Rental.Contracts.Contracts.Modal.formContent")}
                placeholder={t(
                  "Rental.Contracts.Contracts.Modal.formContentPlaceholder"
                )}
                required
                requiredMessage={t(
                  "Rental.Contracts.Contracts.Modal.formRequired"
                )}
                classNames={{placeholder: error ? "top-[30.9%]" : ""}}
                modalShortCode={modalShortCode}
              />
            </div>
            <div className="flex-1"></div>

            <Separator />

            <div
              className="flex flex-1 justify-between items-center font-light text-low-contrast cursor-pointer"
              onClick={
                modalShortCode.isVisible
                  ? modalShortCode.close
                  : modalShortCode.open
              }
            >
              <p>{t("AutoMessageList.Upsert.Contents.shortcodes")}</p>

              <Switch value={modalShortCode.isVisible} />
            </div>

            <Separator />

            <div className="flex">
              <div className="flex flex-1 gap-x-3 justify-between">
                <Button
                  type="secondary"
                  onClick={closeModal}
                  disabled={loadingValidation || loadingDelete}
                >
                  {t("Global.cancel")}
                </Button>
                {displayDeleteButton && (
                  <Button
                    type="alert"
                    onClick={() =>
                      modalDelete.open({
                        onCloseFromModal: () => closeModal(),
                        resource: contract,
                      })
                    }
                    disabled={loadingValidation}
                    loading={loadingDelete}
                  >
                    {t("UserExperience.delete")}
                  </Button>
                )}
                <Button
                  type="primary"
                  loading={loadingValidation}
                  disabled={loadingDelete}
                  onClick={form.handleSubmit(handleUpdateContract)}
                >
                  {t("Global.save")}
                </Button>
              </div>
            </div>
          </div>
        </>
      </RightModal>
    </>
  );
};

export default RentalEditContractsModal;
