import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import paths from "../../constants/paths";
import {
  capitalizeFirstLetter,
  displayPriceWith2DigitsIfNeeded,
} from "../../helpers/stringHelper";
import { Button } from "../Common/Button/Button";
import { Rating } from "../Common/Rating/Rating";
import { RightModal } from "../Common/RightModal/RightModal";
import { Separator } from "../Common/Separator/Separator";
import ArrowRightIcon from "../../assets/icons/arrow-right.svg?react";
import EmojiHappyIcon from "../../assets/icons/emoji-happy.svg?react";
import SpinIcon from "../../assets/icons/loader.svg?react";
import MailIcon from "../../assets/icons/mail.svg?react";
import MoneyIcon from "../../assets/icons/money.svg?react";
import MoonIcon from "../../assets/icons/moon.svg?react";
import PhoneIcon from "../../assets/icons/phone.svg?react";
import UsersIcon from "../../assets/icons/users.svg?react";
import NotImageIcon from "../../assets/icons/not-image.svg?react";
import { get } from "../../helpers/APIHelper";
import { useEditPrivateNote } from "../../hooks/useEditPrivateNotes";
import { useFetchPaymentSchedule } from "../../pages/PaymentReservationList/PaymentReservationList.hooks";
import { TextAreaInput } from "../Common/TextAreaInput/TextAreaInput";
import {
  CalendarRestrictionResponse,
  PaymentReservationResponse,
  ReservationResponse,
} from "../../types/GETTypes";
import { useModal } from "../../hooks/useModal";
import { ValueType } from "../../types/commonTypes";
import {
  PaymentReservationDepositStatusEnum,
  ReservationStatusEnum,
} from "../../enums/GETenums";
import { ResaCancelModal } from "./ResaCancelModal";
import {
  fetchOneReservation,
  getDepositStatusBadge,
  getPaymentStatusBadge,
} from "../../helpers/reservationHelper";
import { PaymentDepositDetailsModal } from "../Payment/PaymentDepositList/PaymentDepositDetailsModal";
import { CalendarReservationModalProps } from "./Calendar.type";
import { PaymentReservationModalUseMode } from "../Payment/PaymentReservationList/PaymentReservationListModal/PaymentReservationListModal.type";
import { PaymentReservationListModal } from "../Payment/PaymentReservationList/PaymentReservationListModal/PaymentReservationListModal";
import {
  getCurrentCanEdit,
  getCurrentHidePrice,
  hasPermissionWithUser,
} from "../../helpers/workspaceHelper";
import useUserStore from "../../stores/useUserStore";
import { cn } from "../../helpers/classHelper";
import CalendarOperationalManagement from "./CalendarInfoReservation/CalendarOperationalManagement";
import { Skeleton } from "@mantine/core";
import CalendarContractBloc from "./CalendarInfoReservation/CalendarContractBloc";

export const CalendarReservationModal: React.FC<
  CalendarReservationModalProps
> = ({
  isVisible,
  reservation,
  onChangeStatusResa,
  onClose,
  reloadCalendarList = undefined,
}) => {
  const userStore = useUserStore();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [currentReservation, setCurrentReservation] = useState<
    ReservationResponse | undefined
  >();

  const editPrivateNote = useEditPrivateNote(currentReservation);
  const cancelResaModal = useModal<ValueType>();
  const depositModal = useModal();

  const handleNavigateDetails = () => {
    navigate(`${paths.RESERVATIONS}/${currentReservation?.id}`);
  };

  const handleNavigateMessage = () => {
    navigate(`${paths.RESERVATIONS}/${currentReservation?.id}/messages`);
  };

  useEffect(() => {
    if (isVisible && reservation) {
      fetchOneReservation(
        reservation.id.toString(),
        (nextReservation) => {
          setCurrentReservation(nextReservation);
        },
        () => {},
      );
      fetchReservationPayment();
    }
  }, [isVisible, reservation]);

  // Payment modal
  const [loading, setLoading] = useState<boolean>(false);
  const [
    isPaymentReservationModalVisible,
    setIsPaymentReservationModalVisible,
  ] = useState<boolean>(false);
  const [currentPaymentReservation, setCurrentPaymentReservation] = useState<
    PaymentReservationResponse | undefined
  >();
  const [paymentReservationModalUseMode, setPaymentReservationModalUseMode] =
    useState<PaymentReservationModalUseMode>("paymentReservation");

  const fetchReservationPayment = async () => {
    const res = await get(
      `${import.meta.env.VITE_API_URL}${paths.API.PAYMENTS.RESERVATIONS}/${
        reservation?.id
      }`,
    );

    if (res.data?.success) {
      setCurrentPaymentReservation(res.data.result);
    }
  };

  const isIcal = useMemo(
    () => currentReservation?.platform?.name?.toUpperCase() === "ICAL",
    [currentReservation],
  );

  const handleClickTransactionDetail = (
    paymentReservationId: number,
    paymentReservationDetailId: number,
  ) => {
    if (
      paymentReservationId !== undefined &&
      paymentReservationId >= 0 &&
      paymentReservationDetailId !== undefined &&
      paymentReservationDetailId >= 0
    ) {
      useFetchPaymentSchedule(
        setLoading,
        [currentPaymentReservation, setCurrentPaymentReservation],
        paymentReservationId,
      );
      setPaymentReservationModalUseMode("paymentReservationDetail");
    }
  };

  const handleSuccessCancelResa = (
    nextStatus: ReservationStatusEnum,
    nextRestrictions: CalendarRestrictionResponse[],
  ) => {
    cancelResaModal.close();
    fetchReservationPayment();

    onChangeStatusResa({
      reservationId: currentReservation?.id,
      rentalId: currentReservation?.rental?.id,
      status: nextStatus,
      restrictions: nextRestrictions,
    });
  };

  const handleClose = () => {
    onClose();
    setCurrentReservation(undefined);
    setCurrentPaymentReservation(undefined);
  };

  const handlePaymentStatusChanged = () => {
    fetchReservationPayment();
  };

  const isNoDeposit = () => {
    return (
      currentReservation?.status_deposit ===
        PaymentReservationDepositStatusEnum.MANAGED_BY_PLATFORM ||
      currentReservation?.status_deposit ===
        PaymentReservationDepositStatusEnum.NOT_REQUIRED
    );
  };

  const formatReservationTypeLabel = isIcal
    ? `${t("Global.reservation")} ${
        currentReservation?.rental?.details?.external_connexions
          ?.platform_icalendar?.[0]?.connexion_name
      }`
    : `${t(
        "Global.reservation",
      )} ${currentReservation?.platform?.name?.toLowerCase()}`;

  const isPriceHidden = () => {
    return getCurrentHidePrice({
      user: userStore.user,
      workspaceOwner: currentReservation?.workspace_owner,
    });
  };

  return (
    <>
      <ResaCancelModal
        isVisible={cancelResaModal.isVisible}
        onClose={cancelResaModal.close}
        reservationId={cancelResaModal.data}
        onSuccess={handleSuccessCancelResa}
      />

      <RightModal
        isVisible={isVisible}
        onClose={handleClose}
        title={t("Calendar.bookingOverview")}
      >
        <div className="flex flex-col justify-between w-full">
          <div className="flex flex-col w-full overflow-auto">
            {/* Global infos */}
            <div className="flex gap-4 justify-between items-center mb-8">
              <div>
                <p className="text-lg font-semibold text-high-contrast">
                  {formatReservationTypeLabel}
                </p>

                {currentReservation ? (
                  <p className="font-light text-low-contrast text-xs">
                    {t("Global.from")}{" "}
                    {moment(currentReservation?.checkin).format("DD MMM YYYY")}{" "}
                    {t("Global.to").toLowerCase()}{" "}
                    {moment(currentReservation?.checkout).format("DD MMM YYYY")}
                  </p>
                ) : (
                  <Skeleton width={100} height={10} />
                )}

                {currentReservation ? (
                  <>
                    {currentReservation?.platform.reservation_link ? (
                      <p className="font-light text-blue-700 text-xs underline">
                        {currentReservation.platform.reservation_link && (
                          <a
                            href={currentReservation.platform.reservation_link}
                            target="_blank"
                          >
                            {t("Booking.link")}
                          </a>
                        )}
                      </p>
                    ) : null}
                  </>
                ) : (
                  <Skeleton width={150} height={10} className="mt-1" />
                )}
              </div>

              <div>
                {currentReservation ? (
                  <p className="text-xl text-high-contrast font-extrabold text-right">
                    {currentReservation?.total_guest_price
                      ? displayPriceWith2DigitsIfNeeded(
                          currentReservation?.total_guest_price ?? 0,
                        )
                      : "-"}
                  </p>
                ) : (
                  <Skeleton width={150} height={20} className="mt-2" />
                )}

                <p className="font-light text-low-contrast text-xs">
                  {t("Booking.reservationPrice")}
                </p>
              </div>
            </div>

            {/* Guest and other infos */}
            <div className="flex gap-4">
              <div className="w-12 h-12 shrink-0">
                {currentReservation?.guest?.photo ? (
                  <img
                    alt={"guest photo"}
                    src={currentReservation?.guest?.photo!}
                    className="w-12 h-12 rounded-full shrink-0 bg-slate-200"
                  />
                ) : (
                  <div
                    className={
                      "w-12 h-12 rounded-full shrink-0 bg-slate-100 flex justify-center items-center"
                    }
                  >
                    <NotImageIcon className={"p-1 text-gray-500"} />
                  </div>
                )}
              </div>

              <div className="flex justify-between w-full">
                <div className="flex flex-col">
                  <div className="flex gap-2">
                    {currentReservation ? (
                      <>
                        <p className="text-high-contrast font-semibold">
                          {isIcal
                            ? "-"
                            : `${currentReservation.guest?.first_name} ${currentReservation.guest?.last_name}`}
                        </p>

                        {currentReservation.guest?.average_rating && (
                          <>
                            <p className="text-low-contrast font-light">-</p>

                            <div>
                              <Rating
                                loading={false}
                                maxStars={5}
                                value={currentReservation.guest?.average_rating}
                                classNames={{ label: "text-low-contrast" }}
                              />
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <div className={"flex flex-col gap-1"}>
                        <Skeleton width={150} height={10} />
                      </div>
                    )}
                  </div>

                  <div className="items-center text-low-contrast font-light">
                    <a
                      href={`mailto:${currentReservation?.guest?.email}`}
                      className="flex gap-1"
                    >
                      <MailIcon className="w-4 h-4" />{" "}
                      {currentReservation ? (
                        isIcal ? (
                          "-"
                        ) : (
                          currentReservation?.guest?.email
                        )
                      ) : (
                        <Skeleton width={150} height={10} className="mt-1" />
                      )}
                    </a>
                  </div>

                  <div className="items-center text-low-contrast font-light">
                    <a
                      href={`tel:${currentReservation?.guest?.phone}`}
                      className="flex gap-1"
                    >
                      <PhoneIcon className="w-4 h-4" />{" "}
                      {currentReservation ? (
                        isIcal ? (
                          "-"
                        ) : (
                          currentReservation.guest?.phone
                        )
                      ) : (
                        <Skeleton width={150} height={10} className="mt-1" />
                      )}
                    </a>
                  </div>
                </div>

                <div className="flex flex-col items-end text-right text-low-contrast font-light">
                  <div className="flex gap-2 items-center font-semibold text-high-contrast">
                    {currentReservation ? (
                      t("Global.night", {
                        count: moment(currentReservation?.checkout).diff(
                          moment(currentReservation?.checkin),
                          "days",
                        ),
                      })
                    ) : (
                      <Skeleton width={50} height={10} className="mt-1" />
                    )}
                    <MoonIcon className="w-5 h-5" />
                  </div>

                  <div className="flex gap-2 items-center">
                    {currentReservation ? (
                      isIcal ? (
                        "-"
                      ) : (
                        t("Global.adults", {
                          count: currentReservation?.adults_count,
                        })
                      )
                    ) : (
                      <Skeleton width={50} height={10} className="mt-1" />
                    )}
                    <UsersIcon className="w-5 h-5" />
                  </div>

                  {currentReservation?.children_count! > 0 && (
                    <div className="flex gap-2 items-center">
                      {currentReservation ? (
                        t("Global.children", {
                          count: currentReservation?.children_count,
                        })
                      ) : (
                        <Skeleton width={50} height={10} className="mt-1" />
                      )}
                      <EmojiHappyIcon className="w-5 h-5" />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="flex gap-2 my-4">
              <Button
                type="secondary"
                disabled={
                  !hasPermissionWithUser(userStore.user, "reservations_message")
                }
                onClick={handleNavigateMessage}
              >
                {t("Calendar.sendMessage")}
              </Button>

              <Button type="primary" onClick={handleNavigateDetails}>
                {t("Calendar.viewBookingDetails")}
              </Button>
            </div>

            {/* Private note (when iCal)*/}
            {isIcal && (
              <div className="mt-2">
                <p className="font-semibold text-low-contrast mb-2">
                  {t("Booking.Note.title")}
                </p>

                <TextAreaInput
                  rows={4}
                  value={editPrivateNote.privateNotes}
                  onTextChange={editPrivateNote.handleChange}
                  onSave={editPrivateNote.handleSave}
                  onCancel={editPrivateNote.handleCancel}
                  error={editPrivateNote.error}
                  disabled={
                    editPrivateNote.loading ||
                    !getCurrentCanEdit({
                      user: userStore.user,
                      workspaceOwner: currentReservation?.workspace_owner,
                    })
                  }
                />
              </div>
            )}

            <Separator />

            <div>
              <p className="font-semibold text-low-contrast mb-2">
                {t("Booking.informations")}
              </p>
              <div className="flex gap-2 mt-2 mb-1 items-center">
                {currentReservation?.guest?.photo ? (
                  <img
                    alt={"rental photo cover"}
                    className="w-12 h-12 rounded-6px bg-slate-200 border-1 border-element-border"
                    src={currentReservation?.rental?.details?.photos?.cover}
                  />
                ) : (
                  <div
                    className={
                      "w-12 h-12 rounded-6px bg-slate-100 border-element-border flex justify-center items-center"
                    }
                  >
                    <NotImageIcon className={"p-1 text-gray-500"} />
                  </div>
                )}

                <div>
                  {currentReservation ? (
                    <p className="font-semibold text-high-contrast">
                      {currentReservation?.rental?.details?.informations?.name}
                    </p>
                  ) : (
                    <Skeleton width={150} height={10} className="mt-1" />
                  )}
                  {currentReservation ? (
                    <p className="font-light text-low-contrast">
                      {
                        currentReservation?.rental?.details?.informations
                          ?.address
                      }{" "}
                      {
                        currentReservation?.rental?.details?.informations
                          ?.postal_code
                      }{" "}
                      {currentReservation?.rental?.details?.informations?.city}{" "}
                      {
                        currentReservation?.rental?.details?.informations
                          ?.country.name
                      }
                    </p>
                  ) : (
                    <Skeleton width={250} height={10} className="mt-1" />
                  )}
                </div>
              </div>
            </div>

            {/* Checkin checkout */}
            <div className="grid grid-cols-2 mt-2 mb-2">
              <div className="flex flex-col">
                <p className="font-light text-low-contrast">
                  {t("Booking.checkinDate")}
                </p>
                {currentReservation ? (
                  <p className="font-semibold text-high-contrast">
                    {capitalizeFirstLetter(
                      moment(currentReservation?.checkin).format(
                        "dddd DD MMM YYYY",
                      ),
                    )}
                  </p>
                ) : (
                  <Skeleton width={140} height={10} className="mt-1" />
                )}
              </div>

              <div className="flex flex-col">
                <p className="font-light text-low-contrast">
                  {t("Booking.timeIn")}
                </p>

                {currentReservation ? (
                  <p className="font-semibold text-high-contrast">
                    {currentReservation?.time_in ?? t("Global.NA")}
                  </p>
                ) : (
                  <Skeleton width={140} height={10} className="mt-1" />
                )}
              </div>

              <div className="flex flex-col mt-3">
                <p className="font-light text-low-contrast">
                  {t("Booking.checkoutDate")}
                </p>

                {currentReservation ? (
                  <p className="font-semibold text-high-contrast">
                    {capitalizeFirstLetter(
                      moment(currentReservation?.checkout).format(
                        "dddd DD MMM YYYY",
                      ),
                    )}
                  </p>
                ) : (
                  <Skeleton width={140} height={10} className="mt-1" />
                )}
              </div>

              <div className="flex flex-col mt-3">
                <p className="font-light text-low-contrast">
                  {t("Booking.timeOut")}
                </p>
                {currentReservation ? (
                  <p className="font-semibold text-high-contrast">
                    {currentReservation?.time_out ?? t("Global.NA")}
                  </p>
                ) : (
                  <Skeleton width={140} height={10} className="mt-1" />
                )}
              </div>
            </div>

            <Separator />

            {/* Payments */}
            <div className="bg-element-background rounded-6px w-full p-2">
              <div className="flex items-center">
                <MoneyIcon className="w-6 h-6" />

                <p className="text-low-contrast font-light">
                  {t("Booking.payments")}
                </p>
              </div>

              <div className="flex gap-4">
                <div className="flex-1">
                  <p className="font-light text-low-contrast mt-2">
                    {t("Booking.journey")}
                  </p>

                  <div
                    className={cn(
                      "flex gap-2 items-center mt-2",
                      isPriceHidden() ? "cursor-not-allowed" : "cursor-pointer",
                    )}
                    onClick={() =>
                      !isPriceHidden() &&
                      setIsPaymentReservationModalVisible(true)
                    }
                  >
                    <div>
                      {getPaymentStatusBadge(
                        currentPaymentReservation?.payment_schedule?.payment_status
                      )}
                    </div>

                    {currentReservation && (
                      <div className="w-12">
                        <Button
                          type="secondary"
                          onClick={() =>
                            !isPriceHidden() &&
                            setIsPaymentReservationModalVisible(true)
                          }
                          RightIcon={ArrowRightIcon}
                          disabled={isPriceHidden()}
                        ></Button>
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex-1">
                  <p className="font-light text-low-contrast mt-2">
                    {currentReservation?.status_deposit !== null &&
                      t("Booking.deposit")}
                  </p>

                  <div
                    className={`flex gap-2 items-center mt-2 ${
                      isNoDeposit() || isPriceHidden()
                        ? "cursor-not-allowed"
                        : "cursor-pointer"
                    }`}
                    onClick={
                      isNoDeposit() || isPriceHidden()
                        ? () => {}
                        : depositModal.open
                    }
                  >
                    <div>
                      {getDepositStatusBadge(
                        currentPaymentReservation?.payment_schedule?.deposit_payment_status
                      )}
                    </div>

                    {!isNoDeposit() &&
                      currentReservation?.status_deposit !== null &&
                      currentReservation && (
                        <div className="w-12">
                          <Button
                            type="secondary"
                            RightIcon={ArrowRightIcon}
                            disabled={isPriceHidden()}
                          ></Button>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>

            {/* Operational Management */}
            {currentReservation ? (
              <CalendarOperationalManagement
                reservation={reservation}
                reloadCalendarList={reloadCalendarList}
              />
            ) : (
              <div className="bg-element-background rounded-6px p-2 mt-4 flex justify-center items-center h-[200px]">
                <SpinIcon className="w-10 h-10 text-gray-400 text-center animate-spin" />
              </div>
            )}

            {currentReservation ? (
              <CalendarContractBloc reservation={currentReservation} />
            ) : (
              <div className="bg-element-background rounded-6px p-2 mt-4 flex justify-center items-center h-[200px]">
                <SpinIcon className="w-10 h-10 text-gray-400 text-center animate-spin" />
              </div>
            )}

            <Separator />

            {/* Private note (when not iCal)*/}
            {!isIcal && (
              <div className="mt-2 pb-4">
                <p className="font-semibold text-low-contrast mb-2">
                  {t("Booking.Note.title")}
                </p>

                <TextAreaInput
                  rows={4}
                  value={editPrivateNote.privateNotes}
                  onTextChange={editPrivateNote.handleChange}
                  onSave={editPrivateNote.handleSave}
                  onCancel={editPrivateNote.handleCancel}
                  error={editPrivateNote.error}
                  disabled={
                    editPrivateNote.loading ||
                    !getCurrentCanEdit({
                      user: userStore.user,
                      workspaceOwner: currentReservation?.workspace_owner,
                    })
                  }
                />
              </div>
            )}
          </div>

          {(currentReservation?.status_reservation ===
            ReservationStatusEnum.CONFIRMED_COMING ||
            currentReservation?.status_reservation ===
              ReservationStatusEnum.CONFIRMED_PROGRESS) &&
            (currentReservation?.platform?.name.toLowerCase() === "direct" ||
              currentReservation?.platform?.name.toLowerCase() === "ical") && (
              <div className="pt-4 border-t-1 border-element-border">
                <Button
                  type="destructive"
                  disabled={
                    !getCurrentCanEdit({
                      user: userStore.user,
                      workspaceOwner: currentReservation.workspace_owner,
                    })
                  }
                  onClick={() => cancelResaModal.open(currentReservation?.id)}
                >
                  {t("Calendar.cancelResa")}
                </Button>
              </div>
            )}
        </div>
      </RightModal>

      <PaymentReservationListModal
        loading={false}
        useMode={paymentReservationModalUseMode!}
        isVisible={isPaymentReservationModalVisible}
        reservation={currentReservation}
        paymentReservation={currentPaymentReservation}
        onBack={() => setPaymentReservationModalUseMode("paymentReservation")}
        onClose={() => setIsPaymentReservationModalVisible(false)}
        onTransactionDetailClick={handleClickTransactionDetail}
        onPaymentStatusChanged={handlePaymentStatusChanged}
      />

      <PaymentDepositDetailsModal
        isVisible={depositModal.isVisible}
        paymentReservation={currentPaymentReservation}
        onBack={depositModal.close}
        onClose={() => {}}
        onDepositStatusChanged={handlePaymentStatusChanged}
      />
    </>
  );
};
