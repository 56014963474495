import React from "react";
import {Button} from "../../Common/Button/Button";
import {GuestPageTab} from "../GuestPageHome/GuestPageHomeComponent";
import ArrowLeftIcon from "../../../assets/icons/arrow-left.svg?react";
import {useTranslation} from "react-i18next";
import useGuestPageDataStore from "../../../stores/guestPageStore/useGuestPageDataStore";
import LayoutBlockComponent from "../Common/LayoutBlockComponent";
import {
  PaymentMethodCreateParams,
  Stripe,
  StripeElementsOptions,
} from "@stripe/stripe-js";
import {PaymentDepositFormOrInfo} from "../../../pages/PaymentDeposit/PaymentDepositFormOrInfo";
import {
  PaymentDepositReservationLinkResponse,
  PaymentReservationLinkResponse,
  ValidateExtraResponse,
} from "../../../types/GETTypes";
import GuestPagePaymentFormSkeleton from "./GuestPagePaymentFormSkeleton";
import {
  PaymentReservationFormOrInfo,
  StepForm,
} from "../../../pages/PaymentReservation/PaymentReservationFormOrInfo";
import {
  useGetPaymentReservationPrice,
  useGetPaymentReservationStatus,
  useOnValidatePaymentReservation,
} from "../../../pages/PaymentReservation/PaymentReservationPage.hooks";
import {
  PaymentReservationDepositStatusEnum,
  PaymentReservationStatusEnum,
  ReservationExtraOrderStatusEnum,
} from "../../../enums/GETenums";
import {useParams} from "react-router-dom";
import CardReservationComponent from "../Common/CardReservationComponent";
import useWindowSize from "../../../hooks/useWindowSize";
import {cn} from "../../../helpers/classHelper";

interface DisplayStepProps {
  step: StepForm;
  depositData?: PaymentDepositReservationLinkResponse | undefined;
  stripePromise: Promise<Stripe | null> | undefined;
  stripeOptions: StripeElementsOptions | undefined;
  billingDetails: PaymentMethodCreateParams.BillingDetails | undefined;
  onSuccess?: (params?: any, response?: any) => void;
  paymentData?: PaymentReservationLinkResponse | undefined;
  extrasData?: ValidateExtraResponse | undefined;
  onAction?: () => void;
  onCloseSuccess?: () => void;
}
const DisplayByStep = ({
  step,
  depositData,
  stripeOptions,
  stripePromise,
  billingDetails,
  onSuccess,
  paymentData,
  extrasData,
  onAction,
  onCloseSuccess,
}: DisplayStepProps) => {
  switch (step) {
    case "depositForm":
      if (depositData) {
        return (
          <PaymentDepositFormOrInfo
            status={
              depositData.deposit_schedules.payment_schedule
                .deposit_payment_status
            }
            option={
              depositData.deposit_schedules.payment_schedule
                .deposit_payment_option
            }
            amount={Number(
              depositData.deposit_schedules.payment_schedule.deposit_value
            )}
            stripeOptions={stripeOptions}
            stripePromise={stripePromise}
            stripeClientSecret={depositData.stripe_client_secret}
            billingDetails={billingDetails}
            onValidatePayment={(
              status: PaymentReservationDepositStatusEnum | undefined
            ) => {
              onSuccess && onSuccess(status);
            }}
            displayModalSuccessGuestPage
            
          />
        );
      }
      return null;
    case "paymentForm":
      if (paymentData) {
        return (
          <PaymentReservationFormOrInfo
            status={useGetPaymentReservationStatus(
              useParams(),
              paymentData,
              paymentData.payment_schedule_current_item.payment_link
            )}
            amount={useGetPaymentReservationPrice(
              useParams(),
              paymentData,
              paymentData.payment_schedule_current_item.payment_link
            )}
            stripeOptions={stripeOptions}
            stripePromise={stripePromise}
            stripeClientSecret={paymentData?.stripe_client_secret}
            billingDetails={billingDetails}
            onValidatePayment={(
              status:
                | PaymentReservationStatusEnum
                | ReservationExtraOrderStatusEnum
                | undefined
            ) => {
              onSuccess && onSuccess(status as PaymentReservationStatusEnum);
            }}
            displayModalSuccessGuestPage
            from="paymentForm"
          />
        );
      }
      return null;
    case "extraForm":
      if (extrasData) {
        return (
          <PaymentReservationFormOrInfo
            status={ReservationExtraOrderStatusEnum.PRE_AUTHORIZED}
            amount={extrasData?.total_price_value}
            stripeOptions={stripeOptions}
            stripePromise={stripePromise}
            stripeClientSecret={extrasData?.stripe_client_secret}
            billingDetails={billingDetails}
            onValidatePayment={(
              status:
                | PaymentReservationStatusEnum
                | ReservationExtraOrderStatusEnum
                | undefined,
              response: any
            ) =>
              onSuccess
                ? onSuccess(status as ReservationExtraOrderStatusEnum, response)
                : null
            }
            from={"extraForm"}
            displayModalSuccessGuestPage
            onAction={onAction}
            onCloseSuccess={onCloseSuccess}
          />
        );
      }
      return null;
    default:
      return null;
  }
};

const GuestPagePaymentForm = (props: {
  paymentData?: PaymentReservationLinkResponse | undefined;
  setPaymentData?: React.Dispatch<
    React.SetStateAction<PaymentReservationLinkResponse | undefined>
  >;
  extrasData?: ValidateExtraResponse | undefined;
  setExtrasData?: (extrasValidateData: ValidateExtraResponse) => void;
  depositData?: PaymentDepositReservationLinkResponse | undefined;
  step: StepForm;
  setStep?: React.Dispatch<React.SetStateAction<StepForm>>;
  loading: boolean;
  billingDetails: PaymentMethodCreateParams.BillingDetails | undefined;
  stripePromise: Promise<Stripe | null> | undefined;
  stripeOptions: StripeElementsOptions | undefined;
  showGoBack?: boolean;
  showReservation?: boolean;
  onSuccess?: (params?: any, response?: any) => void;
  childrenDivStyle?: string;
  childrenStyle?: string;
  onAction?: () => void;
  onCloseSuccess?: (params?: any) => void;
}) => {
  const {
    setStep,
    loading,
    showGoBack = true,
    showReservation = true,
    childrenDivStyle,
    childrenStyle,
  } = props;
  const {t} = useTranslation();
  const {isMobile} = useWindowSize();

  return (
    <div className="flex flex-col md:w-full">
      {showGoBack && (
        <Button
          LeftIcon={ArrowLeftIcon}
          type="secondary"
          displayLargeBtn={false}
          className="m-4 mt-8 md:mt-16"
          buttonClassName="md:h-12"
          textClassName="md:text-xl"
          onClick={() => setStep && setStep("list")}
        >
          {t("GuestPage.ExtraHistorical.goBack")}
        </Button>
      )}
      <div className={cn("md:flex", childrenDivStyle)}>
        {isMobile ? (
          <>
            {loading ? (
              <GuestPagePaymentFormSkeleton />
            ) : (
              <DisplayByStep {...props} />
            )}
            {showReservation && (
              <div className="mb-24 mt-8 mx-3">
                <CardReservationComponent />
              </div>
            )}{" "}
          </>
        ) : (
          <>
            {showReservation && (
              <div className="mb-16 mt-8 mx-3 md:w-1/2">
                <CardReservationComponent />
              </div>
            )}
            {loading ? (
              <GuestPagePaymentFormSkeleton />
            ) : (
              <div className={cn("md:w-1/2 md:h-full", childrenStyle)}>
                <DisplayByStep {...props} />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default GuestPagePaymentForm;
