import { t } from "i18next";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useAutomation } from "../../hooks/api/automation";
import { UseModal } from "../../hooks/useModal";
import { ValueType } from "../../types/commonTypes";
import { AutomationItemResponse } from "../../types/GETTypes";
import { Button } from "../Common/Button/Button";
import { CenterModal } from "../Common/CenterModal/CenterModal";
import { SimpleRadio } from "../Common/SimpleRadio/SimpleRadio";

type ManagementAutomationListRemoveModalProps = {
  modal: UseModal<ValueType | ValueType[]>;
  onRemove: (data: AutomationItemResponse | AutomationItemResponse[]) => void;
  onError: (message: string | null) => void;
};

type ManagementAutomationListRemoveModalForm = {
  removeAllTasks: boolean;
};

export const ManagementAutomationListRemoveModal: React.FC<
  ManagementAutomationListRemoveModalProps
> = ({ modal, onRemove, onError }) => {
  const [validationLoading, setValidationLoading] = useState<boolean>(false);

  const form = useForm<ManagementAutomationListRemoveModalForm>({
    mode: "all",
    defaultValues: {
      removeAllTasks: true,
    },
  });

  const { remove, removeMultiple } = useAutomation({
    onSuccess: (data) => {
      if (data) {
        onRemove(data);
        modal.close();
      }
    },
    onError: (message: string | null) => onError(message),
    onStart: () => {
      onError(null);
      setValidationLoading(true);
    },
    onEnd: () => {
      setValidationLoading(false);
    },
  });

  form.watch();

  return (
    <CenterModal
      title={t("Automation.RemoveModal.title", {
        count: Array.isArray(modal.data) ? modal.data?.length : 1,
      })}
      isVisible={modal.isVisible}
      onClose={validationLoading ? () => {} : modal.close}
    >
      <div className="flex flex-col gap-y-6">
        <div>
          <p className="mb-4 text-base leading-relaxed tracking-wide text-low-contrast">
            {t("Automation.RemoveModal.description1", {
              count: Array.isArray(modal.data) ? modal.data?.length : 1,
            })}
          </p>
          <p className="text-base leading-relaxed tracking-wide text-low-contrast">
            {t("Automation.RemoveModal.description2")}
          </p>
        </div>

        <Controller
          control={form.control}
          name="removeAllTasks"
          render={({ field: { onChange, value } }) => (
            <div className="flex flex-col gap-y-4">
              <SimpleRadio
                classNames={{
                  label: "font-bold text-sm",
                  description: "text-sm",
                }}
                label={t("Automation.RemoveModal.RemoveOptions.option1")}
                description={t(
                  "Automation.RemoveModal.RemoveOptions.option1Description",
                )}
                onClick={() => onChange(true)}
                value={value === true}
              />
              <SimpleRadio
                classNames={{
                  label: "font-bold text-sm",
                  description: "text-sm",
                }}
                label={t("Automation.RemoveModal.RemoveOptions.option2")}
                onClick={() => onChange(false)}
                value={value === false}
              />
            </div>
          )}
        />

        <div className="flex justify-between space-x-2">
          <Button
            type="secondary"
            disabled={validationLoading}
            onClick={modal.close}
          >
            {t("Global.cancel")}
          </Button>
          <Button
            type="destructive"
            disabled={validationLoading}
            loading={validationLoading}
            onClick={() =>
              typeof modal.data === "string" || typeof modal.data === "number"
                ? remove(modal.data!, form.getValues("removeAllTasks"))
                : removeMultiple({
                    ids: modal.data!,
                    delete_tasks: form.getValues("removeAllTasks"),
                  })
            }
          >
            {t("Global.removeWithConfirmation")}
          </Button>
        </div>
      </div>
    </CenterModal>
  );
};
