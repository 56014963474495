import React, {useState} from "react";
import {Button} from "../../../../../Common/Button/Button";
import {PreviewImage} from "../../../../../Common/PreviewImage/PreviewImage";
import {useModal} from "../../../../../../hooks/useModal";
import CheckWhiteIcon from "../../../../../../assets/icons/check-white.svg?react";
import PlusIcon from "../../../../../../assets/icons/plus.svg?react";
import MinusIcon from "../../../../../../assets/icons/minus.svg?react";
import useGuestWebsiteStore from "../../../../../../stores/guestWebsiteStore/useGuestWebsiteStore";
import {CustomPreviewImage} from "../Home/CustomPreviewImage";

const ExtrasWebsiteCard = ({
  id,
  name,
  description,
  photo,
  unitPrice,
  maximumQuantity,
  onSelectExtras,
}: {
  id: number;
  description: string;
  name: string;
  photo: string;
  unitPrice: number;
  maximumQuantity: number;
  onSelectExtras?: (
    extraOrder: {id: number; quantity?: number},
    toRemove?: boolean
  ) => void;
}) => {
  const {extrasOrder, dataGuestWebsite} = useGuestWebsiteStore();
  const {styles} = dataGuestWebsite || {};
  const {color_main, color_text} = styles || {};
  const defaultQuantity = 1;
  const maximum_quantity = maximumQuantity;
  const previewImage = useModal();
  const extraOrder = extrasOrder?.find((extr) => extr.id === id);
  const [isOpen, setIsOpen] = useState(extraOrder ? true : false);
  const returnDefaultQuantity = () => {
    let qt = 0;
    if (
      extraOrder &&
      extraOrder.quantity !== undefined &&
      extraOrder.quantity > -1
    ) {
      qt = extraOrder.quantity;
    } else if (maximum_quantity === 0) {
      qt = maximum_quantity;
    } else {
      qt = defaultQuantity;
    }
    return qt;
  };
  const [quantity, setQuantity] = useState(returnDefaultQuantity());
  const handleOpenExtra = () => {
    const status = !isOpen;
    setIsOpen(status);
    if (!status) {
      setQuantity(defaultQuantity);
      onSelectExtras && onSelectExtras({id}, true);
    } else {
      onSelectExtras && onSelectExtras({id, quantity: defaultQuantity});
    }
  };

  const decreaseQuantity = () => {
    if (quantity > defaultQuantity) {
      setQuantity(quantity - 1);
      onSelectExtras && onSelectExtras({id, quantity: quantity - 1});
      return;
    }
    onSelectExtras && onSelectExtras({id, quantity: defaultQuantity});
  };
  const increaseQuantity = () => {
    if (maximum_quantity > quantity) {
      setQuantity(quantity + 1);
      onSelectExtras && onSelectExtras({id, quantity: quantity + 1});
    }
  };

  return (
    <div className="flex items-center space-x-4 md:w-full p-2 py-3 bg-white shadow-lg rounded-lg">
      <img
        src={photo}
        alt={name}
        className="w-[80px] h-[80px] md:w-[130px] md:h-[130px] rounded-md object-cover cursor-pointer"
        style={{color: color_text}}
        onClick={() => previewImage.open()}
      />
      <CustomPreviewImage
        imageModal={previewImage}
        imgs={[{url: photo, name}]}
      />
      <div className="flex-1">
        <div className="flex justify-between">
          <div className="flex flex-col justify-center">
            <h2
              className=" font-semibold text-base md:text-lg w-[80%] md:w-auto"
              style={{color: color_text}}
            >
              {name}
            </h2>
            <p className="text-low-contrast text-xs md:text-sm w-[80%] md:w-auto">
              {description}
            </p>

            {isOpen && (
              <div className="flex items-center justify-between w-1/3">
                <Button
                  type="secondary"
                  className="px-4 pl-0 py-1 h-8 border-none"
                  buttonClassName="border-0 shadow-none ml-0 pl-2"
                  textClassName="md:text-xl"
                  onClick={decreaseQuantity}
                >
                  <MinusIcon className="w-5 h-5" />
                </Button>
                <span
                  id="quantity"
                  className=" font-bold text-lg"
                  style={{color: color_text}}
                >
                  {quantity}
                </span>
                <Button
                  type="secondary"
                  className="px-4 py-1 h-8"
                  buttonClassName="border-0 shadow-none"
                  textClassName="md:text-xl"
                  onClick={increaseQuantity}
                >
                  <PlusIcon className="w-5 h-5" />
                </Button>
              </div>
            )}
          </div>
          <div className="flex flex-col">
            <div
              className="flex flex-1 flex-row font-semibold text-lg md:text-xl"
              style={{color: color_main}}
            >
              <span>{unitPrice} </span>
              <span className="ml-1">{"€"}</span>
            </div>
            <div className="flex mt-4">
              {isOpen ? (
                <Button
                  type="primary"
                  className="w-6 h-6 flex items-center justify-center border border-gray-300 rounded md:h-12"
                  textClassName="md:text-xl"
                  onClick={handleOpenExtra}
                  buttonClassName="bg-none hover:brightness-[0.8] border-none"
                  buttonStyleCss={{backgroundColor: color_main}}
                >
                  <CheckWhiteIcon className="w-3 h-3" />
                </Button>
              ) : (
                <Button
                  type="secondary"
                  className="w-6 h-6 flex items-center justify-center border border-gray-300 rounded md:h-12"
                  textClassName="md:text-xl"
                  onClick={handleOpenExtra}
                  disabled={quantity === 0}
                >
                  <PlusIcon className="w-3 h-3" />
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExtrasWebsiteCard;
