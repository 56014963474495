import moment from "moment";
import React, { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ChevronRightIcon from "../../assets/icons/chevron-right.svg?react";
import { CalendarInputValueRange } from "../../components/Common/CalendarInput/CalendarInput.type";
import { TableList } from "../../components/Common/TableList/TableList";
import { MainLayout } from "../../components/Layout/MainLayout/MainLayout";
import { PaymentReservationFilters } from "../../components/Payment/PaymentReservationList/Filters/PaymentReservationFilters";
import {
  PaymentReservationFiltersTotals,
  PaymentReservationStatusFilterButton,
} from "../../components/Payment/PaymentReservationList/Filters/PaymentReservationFilters.type";
import { PaymentReservationListHeader } from "../../components/Payment/PaymentReservationList/PaymentReservationListHeader";
import { PaymentReservationListItem } from "../../components/Payment/PaymentReservationList/PaymentReservationListItem";
import { PaymentReservationListModal } from "../../components/Payment/PaymentReservationList/PaymentReservationListModal/PaymentReservationListModal";
import {
  PaymentReservationModalUseMode,
  PaymentReservationModalUseModeItems,
} from "../../components/Payment/PaymentReservationList/PaymentReservationListModal/PaymentReservationListModal.type";
import paths from "../../constants/paths";
import { isCustomStringType } from "../../helpers/validationHelper";
import { UseTableFetchParams, useTablePage } from "../../hooks/useTablePage";
import { PaymentReservationResponse } from "../../types/GETTypes";
import { ValueType } from "../../types/commonTypes";
import { NoPaymentReservation } from "./NoPaymentReservation";
import { useFetchPaymentSchedule } from "./PaymentReservationList.hooks";
import { PaymentReservationListPageSkeleton } from "./PaymentReservationListPageSkeleton";

export const PaymentReservationListPage: React.FC<{}> = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);

  const tablePage = useTablePage(
    `${import.meta.env.VITE_API_URL}${paths.API.PAYMENTS.RESERVATIONS}`,
    "payment_reservations",
    {
      otherMetas: [
        "total",
        "total_canceled",
        "total_filtered",
        "total_overdue",
        "total_paid",
        "total_partially_paid",
        "total_unpaid",
      ],
    }
  );

  const { currentOtherMetas } = tablePage;

  const [currentActiveButtonFilter, setCurrentActiveButtonFilter] =
    useState<PaymentReservationStatusFilterButton>("ALL");

  const [currentRangeDate, setCurrentRangeDate] = useState<{
    startDate: Date;
    endDate: Date;
  } | null>(null);

  const [totals, setTotals] = useState<PaymentReservationFiltersTotals>({
    all: 0,
    unpaid: 0,
    partiallyPaid: 0,
    paid: 0,
    overdue: 0,
    canceled: 0,
  });

  const [fetchParams, setFetchParams] = useState<UseTableFetchParams>({});
  useEffect(() => {
    if (currentRangeDate === null) {
      setFetchParams({ status: currentActiveButtonFilter });
    } else {
      setFetchParams(() => {
        const nextParams: UseTableFetchParams = {
          status: currentActiveButtonFilter,
          from: moment(currentRangeDate.startDate).format("YYYY-MM-DD"),
          to: moment(currentRangeDate.endDate).format("YYYY-MM-DD"),
        };

        if (currentActiveButtonFilter === "ALL") {
          delete nextParams.status;
        }

        return nextParams;
      });
    }
  }, [currentActiveButtonFilter, currentRangeDate]);

  useEffect(() => {
    if (
      currentActiveButtonFilter === "ALL" &&
      currentRangeDate !== null &&
      currentOtherMetas.length > 0
    ) {
      setTotals({
        all:
          Number(
            currentOtherMetas.find((meta) => meta.key === "total_filtered")
              ?.value
          ) ?? 0,
        unpaid:
          Number(
            currentOtherMetas.find((meta) => meta.key === "total_unpaid")?.value
          ) ?? 0,
        partiallyPaid:
          Number(
            currentOtherMetas.find(
              (meta) => meta.key === "total_partially_paid"
            )?.value
          ) ?? 0,
        paid:
          Number(
            currentOtherMetas.find((meta) => meta.key === "total_paid")?.value
          ) ?? 0,
        overdue:
          Number(
            currentOtherMetas.find((meta) => meta.key === "total_overdue")
              ?.value
          ) ?? 0,
        canceled:
          Number(
            currentOtherMetas.find((meta) => meta.key === "total_canceled")
              ?.value
          ) ?? 0,
      });
    }
  }, [currentActiveButtonFilter, currentRangeDate, currentOtherMetas]);

  useEffect(() => {
    if (Object.keys(fetchParams).length > 0) {
      tablePage.fetch(fetchParams);
    }
  }, [fetchParams]);

  const handleDatesChange = (dates: CalendarInputValueRange) => {
    if (Array.isArray(dates) && dates.length >= 2) {
      const startDate: Date | null = dates[0];
      const endDate: Date | null = dates[1];
      if (
        (startDate === null && endDate === null) ||
        (startDate instanceof Date && endDate instanceof Date)
      ) {
        if (startDate !== null && endDate !== null) {
          setCurrentRangeDate({
            startDate,
            endDate,
          });

          let fetchParams: UseTableFetchParams = {
            from: moment(startDate).format("YYYY-MM-DD"),
            to: moment(endDate).format("YYYY-MM-DD"),
          };

          if (currentActiveButtonFilter !== "ALL") {
            fetchParams = {
              ...fetchParams,
              status: currentActiveButtonFilter,
            };
          }

          tablePage.fetch(fetchParams);
        }
      }
    }
  };

  const [
    isPaymentReservationModalVisible,
    setIsPaymentReservationModalVisible,
  ] = useState<boolean>(false);
  const [currentPaymentReservation, setCurrentPaymentReservation] = useState<
    PaymentReservationResponse | undefined
  >();
  const [currentPaymentScheduleId, setCurrentPaymentScheduleId] = useState<
    number | undefined
  >();

  const [paymentReservationModalUseMode, setPaymentReservationModalUseMode] =
    useState<PaymentReservationModalUseMode | null>(null);

  const handleClickItem = (id: ValueType) => {
    if (typeof id === "number") {
      useFetchPaymentSchedule(
        setLoading,
        [currentPaymentReservation, setCurrentPaymentReservation],
        id
      );
    }
    setPaymentReservationModalUseMode("paymentReservation");
  };

  const handleClickItemDetail = (ids: ValueType, useMode: ValueType) => {
    let paymentReservationId: number | undefined = undefined;
    let paymentReservationDetailId: number | undefined = undefined;
    if (typeof ids === "string") {
      const idsSplit = ids.split(",");
      paymentReservationId = Number(idsSplit[0]);
      paymentReservationDetailId = Number(idsSplit[1]);
    }
    if (
      paymentReservationId !== undefined &&
      paymentReservationId >= 0 &&
      paymentReservationDetailId !== undefined &&
      paymentReservationDetailId >= 0
    ) {
      useFetchPaymentSchedule(
        setLoading,
        [currentPaymentReservation, setCurrentPaymentReservation],
        paymentReservationId
      );
      const useModeTypeChecker = (
        value: string
      ): value is PaymentReservationModalUseMode =>
        PaymentReservationModalUseModeItems.includes(value);
      if (
        typeof useMode === "string" &&
        isCustomStringType<PaymentReservationModalUseMode>(
          useMode,
          useModeTypeChecker
        )
      ) {
        setPaymentReservationModalUseMode(useMode);
      }

      setCurrentPaymentScheduleId(paymentReservationDetailId);
    }
  };

  useEffect(() => {
    if (
      paymentReservationModalUseMode !== null &&
      !isPaymentReservationModalVisible
    ) {
      setIsPaymentReservationModalVisible(true);
    }
  }, [paymentReservationModalUseMode]);

  useEffect(() => {
    if (!isPaymentReservationModalVisible) {
      setPaymentReservationModalUseMode(null);
    }
  }, [isPaymentReservationModalVisible]);

  const handleClickTransactionDetail = (
    paymentReservationId: number,
    paymentReservationDetailId: number
  ) => {
    if (
      paymentReservationId !== undefined &&
      paymentReservationId >= 0 &&
      paymentReservationDetailId !== undefined &&
      paymentReservationDetailId >= 0
    ) {
      useFetchPaymentSchedule(
        setLoading,
        [currentPaymentReservation, setCurrentPaymentReservation],
        paymentReservationId
      );
      setPaymentReservationModalUseMode("paymentReservationDetail");
    }
  };

  const getTitle = () => {
    return (
      <p className="flex flex-row items-center gap-1">
        {t("Payments.title")} <ChevronRightIcon />
        {t("Payments.reservationList")}
      </p>
    );
  };
  //   switch (currentActiveButtonFilter) {
  //     case "UNPAID":
  //       return "total_unpaid";
  //     case "PARTIALLY_PAID":
  //       return "total_partially_paid";
  //     case "PAID":
  //       return "total_paid";
  //     case "OVERDUE":
  //       return "total_overdue";
  //     case "REFUNDED":
  //       return "total_canceled";
  //     default:
  //       return "total";
  //   }
  // };

  const getFiltersNode = (): ReactNode => {
    return (
      <PaymentReservationFilters
        loading={tablePage.loading}
        onSearchText={tablePage.handleChangeSearch}
        totals={totals}
        currentActiveButtonFilter={currentActiveButtonFilter}
        onFilterButtonClick={(value) =>
          setCurrentActiveButtonFilter(
            value as PaymentReservationStatusFilterButton
          )
        }
        onDatesChanged={handleDatesChange}
      />
    );
  };

  return (
    <>
      <MainLayout
        title={getTitle()}
        sidebarActiveItem="payments"
        sidebarActiveSubItem="reservations"
      >
        <PaymentReservationListModal
          loading={tablePage.loading}
          useMode={paymentReservationModalUseMode!}
          isVisible={isPaymentReservationModalVisible}
          paymentReservation={currentPaymentReservation}
          paymentScheduleId={currentPaymentScheduleId}
          onBack={() => setPaymentReservationModalUseMode("paymentReservation")}
          onClose={() => setIsPaymentReservationModalVisible(false)}
          onTransactionDetailClick={handleClickTransactionDetail}
        />

        <TableList
          i18nElement="Global.payment"
          Filters={getFiltersNode()}
          filterTotal={Number(
            currentOtherMetas.find((meta) => meta.key === "total_filtered")
              ?.value ?? 0
          )}
          Header={PaymentReservationListHeader}
          tablePage={tablePage}
          Item={PaymentReservationListItem}
          Skeleton={PaymentReservationListPageSkeleton}
          NoData={
            <NoPaymentReservation
              title={t("Global.noPaymentReservationTitle")}
              description={t("Global.noPaymentReservationContent")}
            />
          }
          onClick={handleClickItem}
          onClickAction={handleClickItemDetail}
        />
      </MainLayout>
    </>
  );
};
