import React from "react";
import useGuestWebsiteStore from "../../../../stores/guestWebsiteStore/useGuestWebsiteStore";
import {
  capitalizeFirstLetter,
  formatPhoneNumberWithIndicatif,
  removeSpaceFromString,
} from "../../../../helpers/stringHelper";
import {useTranslation} from "react-i18next";
import PhoneIcon from "../../../../assets/icons/phone-black.svg?react";
import MailIcon from "../../../../assets/icons/mail-black.svg?react";
import {Button} from "../../../Common/Button/Button";

const InfoContactComponent = () => {
  const {t} = useTranslation();
  const {dataGuestWebsite} = useGuestWebsiteStore();
  const {content, styles} = dataGuestWebsite || {};
  const {color_main, color_background, color_text} = styles || {};
  const {contact} = content || {};

  return (
    <div
      className="flex flex-col bg-white shadow-xl rounded-lg p-6 w-full md:w-1/4 items-center md:h-full md:justify-center"
      style={{backgroundColor: color_background}}
    >
      <p
        className=" text-center break-words text-base"
        style={{color: color_text}}
      >
        {t("GuestWebsite.Contact.contact")}
      </p>
      <div className="flex flex-col space-y-4 w-full items-center mb-4">
        <div className="flex items-center ">
          <span
            className=" font-semibold break-words"
            style={{color: color_text}}
          ></span>
        </div>
        <a
          href={`tel:${formatPhoneNumberWithIndicatif(
            removeSpaceFromString(contact?.phone ?? "")
          )}`}
          target="_top"
        >
          <Button
            type="secondary"
            textStyleCss={{color: color_text}}
            LeftIcon={PhoneIcon}
          >
            {contact?.phone}
          </Button>
        </a>
        <a href={`mailto:${contact?.email}`} target="_top">
          <Button
            type="secondary"
            textStyleCss={{color: color_text}}
            LeftIcon={MailIcon}
          >
            {capitalizeFirstLetter(contact?.email)}
          </Button>
        </a>
      </div>
      <p
        className="text-center break-words text-base"
        style={{color: color_text}}
      >
        {contact?.availability}
      </p>
    </div>
  );
};

export default InfoContactComponent;
