import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React from "react";
import { useTranslation } from "react-i18next";
import LogInIcon from "../../../assets/icons/log-in.svg?react";
import LogOutIcon from "../../../assets/icons/log-out.svg?react";
import SixDotsIcon from "../../../assets/icons/six-dots.svg?react";
import UserGroupIcon from "../../../assets/icons/user-group.svg?react";
import { PlatformNameEnum } from "../../../enums/GETenums";
import { formatTime, isValidHour } from "../../../helpers/dateHelper";
import { getPlatformIcon } from "../../../helpers/platformHelper";
import { RentalLightListItemResponse } from "../../../types/GETTypes";
import { Badge } from "../../Common/Badge/Badge";
import { CheckBox } from "../../Common/CheckBox/CheckBox";
import { TableListItemProps } from "../../Common/TableList/TableList.type";

export const RentalItem: React.FC<TableListItemProps> = ({
  data,
  isSelected,
  onClick = () => {},
  onClickAction = () => {},
  draggableItem,
}) => {
  const { t } = useTranslation();
  const rental: RentalLightListItemResponse = data;
  const { id } = data as RentalLightListItemResponse;

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  const externalConnections = {
    booking: {
      enabled: rental?.external_connexions.platform_booking?.enabled,
      name: PlatformNameEnum.BOOKING,
    },
    airbnb: {
      enabled: rental?.external_connexions.platform_airbnb?.enabled,
      name: PlatformNameEnum.AIRBNB,
    },
    pricelabs: {
      enabled: rental?.external_connexions.platform_pricelabs?.enabled,
      name: PlatformNameEnum.PRICELABS,
    },
    externals: {
      enabled:
        rental?.external_connexions.platform_icalendar !== null
          ? rental.external_connexions.platform_icalendar.length > 0
          : false,
      name: PlatformNameEnum.ICALENDAR,
    },
  };

  const externalConnectionArray = Object.entries(externalConnections)
    .map(([key, value]) => ({
      platform: key,
      enabled: value.enabled,
      name: value.name,
    }))
    ?.filter((connection) => connection?.enabled);

  const noneExternalConnectionIsEnabled =
    !externalConnectionArray || externalConnectionArray?.length === 0;

  return (
    <tr
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="outline-none cursor-pointer hover:bg-element-background/40"
    >
      {/* Checkbox selection */}
      <td className="w-4 py-2 text-sm ps-4 pe-2 border-t-1 border-element-border">
        <CheckBox
          onChange={() => onClickAction(id, "select")}
          value={isSelected}
        />
      </td>

      {/* rental */}
      <td
        className="flex flex-row items-center px-4 py-2 space-x-4 text-sm border-t-1 border-element-border"
        onClick={() => onClick(rental?.id!)}
      >
        <div className="flex items-center">
          {draggableItem && (
            <SixDotsIcon className="cursor-move" width={30} height={30} />
          )}
          <div className="relative">
            <div className="object-fill w-10 h-10 overflow-hidden rounded-4px border-1 border-element-border/50">
              <img
                src={rental.cover}
                alt={rental?.name}
                className="object-fill w-full h-full"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <p className="font-semibold text-high-contrast">{rental?.name}</p>
          <p className="font-light text-low-contrast w-[200px]">
            {rental?.address} {rental?.country?.name}
          </p>
        </div>
      </td>

      {/* status */}
      <td
        className="py-2 text-sm align-middle border-t-1 border-element-border"
        onClick={() => onClick(rental?.id!)}
      >
        {rental?.enabled ? (
          <Badge label={t("RentalList.statusActive")} status="success" />
        ) : (
          <Badge label={t("RentalList.statusInactive")} status="fail" />
        )}
      </td>

      {/* sync */}
      <td
        className="py-2 text-sm align-center border-t-1 border-element-border text-low-contrast"
        onClick={() => onClick(rental?.id!)}
      >
        {noneExternalConnectionIsEnabled ? (
          t("Global.NA_female")
        ) : (
          <div className="flex space-x-[-5px]">
            {externalConnectionArray.map((connection, index) => {
              return getPlatformIcon(connection.name, "normal", index);
            })}
          </div>
        )}
      </td>

      {/* Capacity */}
      <td
        className="py-2 text-sm align-middle border-t-1 border-element-border"
        onClick={() => onClick(rental?.id!)}
      >
        <div className="flex items-center space-x-2">
          <UserGroupIcon width={22} height={22} />
          <div className="flex flex-col">
            <span className="font-light text-low-contrast">
              {t("Global.traveler", { count: rental?.guests_max_capacity })}
            </span>
            {/*
              <span className="font-light text-low-contrast">
                {t("Global.children", { count: rental?. children_max_capacity})}
              </span>
            */}
          </div>
        </div>
      </td>

      {/* Checkin Checkout */}
      <td
        className="py-2 align-middle border-t-1 border-element-border"
        onClick={() => onClick(rental?.id!)}
      >
        <div className="flex flex-col items-center gap-y-2">
          {isValidHour(rental?.checkin_min_time) ? (
            <div className="flex space-x-2">
              <LogInIcon />
              <span className="font-light text-low-contrast">
                {formatTime(rental?.checkin_min_time)}
              </span>
            </div>
          ) : null}

          {isValidHour(rental?.checkout_max_time) ? (
            <div className="flex space-x-2">
              <LogOutIcon />
              <span className="font-light text-low-contrast">
                {formatTime(rental?.checkout_max_time)}
              </span>
            </div>
          ) : null}
        </div>
      </td>

      {/* Cleaning Fees */}
      <td
        className="py-2 align-middle border-t-1 border-element-border"
        onClick={() => onClick(rental?.id!)}
      >
        <div className="flex justify-center">
          <span className="text-lg font-extrabold text-high-contrast">
            {`${rental?.cleaning_default}${t("Global.currencySymbol")}`}
          </span>
        </div>
      </td>
    </tr>
  );
};
