import React, { CSSProperties, ReactNode } from "react";
import { Trans } from "react-i18next";
import InformationIcon from "../../../assets/icons/information.svg?react";
import { cn } from "../../../helpers/classHelper";

const InfoCard = ({
  title,
  description,
  Node,
  useTransComponent = false,
  classNames,
}: {
  title?: string | ReactNode;
  description?: string | ReactNode;
  Node?: ReactNode;
  useTransComponent?: boolean;
  classNames?: { description: CSSProperties };
}) => {
  return (
    <div className="flex items-start p-4 rounded-lg select-none gap-x-3 bg-element-background">
      <div className="flex flex-col">
        <InformationIcon />
      </div>
      {Node ? (
        Node
      ) : (
        <div className="flex flex-col whitespace-pre-line">
          <p
            className={cn("text-low-contrast", {
              "pb-3": title,
            })}
          >
            {useTransComponent && typeof title === "string" ? (
              <Trans i18nKey={title} />
            ) : (
              title
            )}
          </p>
          <p
            className={cn(
              "font-light text-low-contrast",
              classNames?.description,
            )}
          >
            {useTransComponent && typeof description === "string" ? (
              <Trans i18nKey={description} />
            ) : (
              description
            )}
          </p>
          {!useTransComponent && typeof description === "string"
            ? description
            : null}
        </div>
      )}
    </div>
  );
};

export default InfoCard;
