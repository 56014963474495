import React, { useMemo, useState } from "react";
import { Button } from "../Common/Button/Button";
import { InputSelect } from "../Common/InputSelect/InputSelect";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { ValueType } from "../../types/commonTypes";
import ArrowRightIcon from "../../assets/icons/arrow-right.svg?react";
import { useNavigate } from "react-router-dom";
import paths from "../../constants/paths";
import { CalendarLayoutNavbarActiveItemType } from "../Layout/CalendarLayout/CalendarLayout.type";

export const CalandarGoToDate = ({
  navbarActiveItem,
}: {
  navbarActiveItem: CalendarLayoutNavbarActiveItemType;
}) => {
  const { t } = useTranslation();

  const URLDate = useMemo(() => {
    const query = new URLSearchParams(window.location.search);

    if (!query.get("date")) return moment(new Date()).format("YYYY-MM-DD");
    else return moment(query.get("date")).format("YYYY-MM-DD");
  }, []);

  const [selectedDay, setSelectedDay] = useState<number>(
    Number(moment(URLDate).format("DD")),
  );
  const [selectedMonth, setSelectedMonth] = useState<number>(
    Number(moment(URLDate).format("MM")),
  );
  const [selectedYear, setSelectedYear] = useState<number>(
    Number(moment(URLDate).format("YYYY")),
  );

  const handleClickToday = () => {
    setSelectedDay(Number(moment(new Date()).format("DD")));
    setSelectedMonth(Number(moment(new Date()).format("MM")));
    setSelectedYear(Number(moment(new Date()).format("YYYY")));

    window.location.href = `${paths.CALENDAR}?date=${moment(new Date()).format(
      "YYYY-MM-DD",
    )}`;
  };

  const handleSelectMonth = (m: ValueType) => {
    setSelectedDay(1);
    setSelectedMonth(Number(m));
  };

  const handleSelectYear = (y: ValueType) => {
    setSelectedDay(1);
    setSelectedYear(Number(y));
  };

  const handleGoTo = () => {
    window.location.href = `${paths.CALENDAR}?date=${selectedYear}-${selectedMonth}-${selectedDay}`;
  };

  return (
    <div className="flex justify-between w-full mt-4 mb-1">
      {navbarActiveItem !== "list" ? (
        <>
          <div>
            <Button type="secondary" onClick={handleClickToday}>
              {t("Global.today")}
            </Button>
          </div>

          <div className="flex flex-row gap-2 items-center">
            <p className="text-high-contrast font-semibold">
              {t("Calendar.goTo")}
            </p>

            <div className="w-36">
              <InputSelect
                items={[
                  { label: t("Global.January"), value: 1 },
                  { label: t("Global.February"), value: 2 },
                  { label: t("Global.March"), value: 3 },
                  { label: t("Global.April"), value: 4 },
                  { label: t("Global.May"), value: 5 },
                  { label: t("Global.June"), value: 6 },
                  { label: t("Global.July"), value: 7 },
                  { label: t("Global.August"), value: 8 },
                  { label: t("Global.September"), value: 9 },
                  { label: t("Global.October"), value: 10 },
                  { label: t("Global.November"), value: 11 },
                  { label: t("Global.December"), value: 12 },
                ]}
                selectedValue={selectedMonth}
                onSelect={handleSelectMonth}
              />
            </div>

            <div className="w-36">
              <InputSelect
                items={Array.from({ length: 3 }).map((v, index) => ({
                  label: moment(new Date()).add(index, "years").format("YYYY"),
                  value: Number(
                    moment(new Date()).add(index, "years").format("YYYY"),
                  ),
                }))}
                selectedValue={selectedYear}
                onSelect={handleSelectYear}
              />
            </div>

            <div className="w-20">
              <Button
                type="secondary"
                RightIcon={ArrowRightIcon}
                onClick={handleGoTo}
              >
                {t("Global.view")}
              </Button>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};
