export const percentToNumber = (
  percentage: number | number[],
  total: number
): number => {
  let nextPercentage: number = 0;
  if (Array.isArray(percentage)) {
    nextPercentage = percentage.reduce((acc, value) => acc + value, 0);
  } else if (!isNaN(percentage) && Number(percentage)) {
    nextPercentage = percentage;
  }

  if (nextPercentage > 100) {
    throw new Error("The percentage is over 100%");
  } else if (nextPercentage < 0) {
    throw new Error("The percentage is less than 0%");
  }

  return (total * nextPercentage) / 100;
};

export function formatNumberWithSpace(value: number): string {
  const rounded = Math.round(value * 100) / 100;
  const [intPart, decimalPart] = rounded.toFixed(2).split(".");

  const intFormatted = intPart.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  if (decimalPart === "00") {
    return intFormatted;
  } else {
    return `${intFormatted},${decimalPart}`;
  }
}
