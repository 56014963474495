import { t } from "i18next";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { UseFieldArrayReturn, UseFormReturn } from "react-hook-form";
import { ValueType } from "recharts/types/component/DefaultTooltipContent";
import CheckWhiteIcon from "../../../assets/icons/check-white.svg?react";
import { UseModal } from "../../../hooks/useModal";
import { RequiredFields } from "../../../types/commonTypes";
import { Button } from "../../Common/Button/Button";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import { InputSelect } from "../../Common/InputSelect/InputSelect";
import { RightModal } from "../../Common/RightModal/RightModal";
import { Separator } from "../../Common/Separator/Separator";
import {
  PaymentAccount,
  PaymentPolicyForm,
  PaymentPolicyItem,
  PaymentPolicyPaymentTriggerEnum,
  PaymentPolicyResponse,
} from "../../Payment/Payment.type";
import { PaymentScheduleAndConditions } from "../../Payment/ScheduleAndConditions/ScheduleAndConditions";
import { AddReservationType } from "../AddReservation.type";
import { useFetchPaymentPolicies } from "./AddReservationPayment.hooks";

type AddReservationPaymentModalProps = {
  modal: UseModal<{}>;
  form: UseFormReturn<PaymentPolicyForm>;
  paymentAccounts: PaymentAccount[] | null;
  paymentPolicyItems: UseFieldArrayReturn<
    PaymentPolicyForm,
    "payments_policy_items",
    "id"
  >;
  reservation: AddReservationType | undefined;
  currentPolicy: PaymentPolicyResponse | undefined;
  onChangeCurrentPolicy: (
    currentPolicy: PaymentPolicyResponse | undefined
  ) => void;
  onNext: (nextPaymentPolicy: PaymentPolicyResponse | undefined) => void;
};

export const AddReservationPaymentModal: React.FC<
  AddReservationPaymentModalProps
> = ({
  modal,
  form,
  paymentAccounts,
  paymentPolicyItems,
  reservation,
  onNext,
  currentPolicy,
  onChangeCurrentPolicy,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [paymentError, setPaymentError] = useState<string | undefined>();

  const [paymentPolicies, setPaymentPolicies] = useState<
    PaymentPolicyResponse[]
  >([]);

  const requiredFields: RequiredFields<PaymentPolicyForm> = {
    name: false,
    description: false,
    is_single_payment: false,
    is_favorite_policy: false,
    payments_policy_items: false,
    is_refundable: false,
    refund_value: false,
    refund_condition: false,
    is_deposit_required: false,
    deposit_value: false,
    deposit_payment_option: false,
    deposit_payment_account_id: false,
    deposit_payment_days_delay: false,
  };

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    watch,
  } = form;

  const {
    fields: paymentFields,
    append: paymentAppend,
    update: paymentUpdate,
    remove: paymentRemove,
  } = paymentPolicyItems;

  const onSuccessFetchPaymentPolicies = (
    paymentPolicies: PaymentPolicyResponse[]
  ) => {
    setPaymentPolicies(paymentPolicies);
    onChangeCurrentPolicy(paymentPolicies.find((pp) => pp.is_favorite_policy));
  };

  const onErrorFetchPaymentPolicies = (message: string) => {
    setError(message);
  };

  useEffect(() => {
    const fetchAll = async () => {
      await useFetchPaymentPolicies(
        reservation?.rental?.id,
        onSuccessFetchPaymentPolicies,
        onErrorFetchPaymentPolicies
      );
    };

    fetchAll();
  }, []);

  const onValidation = (values: PaymentPolicyForm) => {
    if (currentPolicy && paymentAccounts && paymentAccounts.length > 0) {
      onNext({
        id: currentPolicy.id,
        name: values.name,
        description: values.description,
        is_default_policy: currentPolicy?.is_default_policy,
        is_favorite_policy: Number(values.is_favorite_policy),
        is_single_payment: Number(values.is_single_payment),
        is_refundable: Number(values.is_refundable),
        refund_value: Number(values.refund_value) ?? null,
        refund_condition: values.refund_condition ?? null,
        is_deposit_required: Number(values.is_deposit_required),
        deposit_value_type: "FIXED",
        deposit_value: Number(values.deposit_value) ?? null,
        deposit_payment_option: values.deposit_payment_option ?? null,
        deposit_payment_account_id: values.deposit_payment_account_id ?? null,
        deposit_payment_days_delay:
          Number(values.deposit_payment_days_delay) ?? null,
        payments_policy_items: values.payments_policy_items.map(
          (pp, index): PaymentPolicyItem => {
            return {
              id: index,
              trigger: pp.trigger,
              value_type: pp.valueType,
              value: pp.value,
              specific_date: moment(pp.specificDate).format("DD/MM/YYYY"),
              payment_account_type:
                paymentAccounts.find((pa) => pa.id === pp.paymentAccountId)
                  ?.type ?? null,
              payment_account:
                paymentAccounts.find((pa) => pa.id === pp.paymentAccountId) ??
                null,
            };
          }
        ),
      });

      modal.close();
    }
  };

  const handleChangePolicy = (value: ValueType) => {
    // @ts-ignore
    const index = paymentPolicies.findIndex((p) => p.id === value);
    const policy = paymentPolicies[index];

    onChangeCurrentPolicy(policy);
  };

  const getDateFromTriggerType = (): Date => {
    switch (currentPolicy?.payments_policy_items[0]?.trigger) {
      default:
        return new Date();
      case PaymentPolicyPaymentTriggerEnum.AT_CHECKIN:
        return reservation?.general?.checkin ?? new Date();
    }
  };

  watch();

  return (
    <RightModal
      title={t("AddReservation.Payment.PricingConditions.title")}
      isVisible={modal.isVisible}
      onClose={modal.close}
      classNames={{
        mainContentParent: "overflow-y-auto pe-0",
      }}
    >
      <form onSubmit={handleSubmit(onValidation)} className={"w-full pr-2"}>
        <div className="flex flex-col w-full h-full text-sm">
          <div className="flex flex-col flex-1 w-full gap-y-8">
            <InputSelect
              required={true}
              label={t("AddReservation.Payment.preFillPaymentPolicy")}
              disabled={loading}
              items={paymentPolicies?.map((p) => {
                // @ts-ignore
                return { label: p.name, value: p.id };
              })}
              // @ts-ignore
              selectedValue={currentPolicy?.id ?? 0}
              onSelect={handleChangePolicy}
            />

            <PaymentScheduleAndConditions
              useMode="reservation"
              reservation={reservation}
              form={form}
              formData={currentPolicy}
              requiredFields={requiredFields}
              nonModifiable={currentPolicy?.id === 1}
              paymentPolicyFields={paymentFields}
              paymentPolicyAppend={paymentAppend}
              paymentPolicyUpdate={paymentUpdate}
              paymentPolicyRemove={paymentRemove}
              paymentAccounts={paymentAccounts ?? []}
              totalPrice={reservation?.tarification?.total}
              baseRefundValue={Boolean(currentPolicy?.is_deposit_required)}
              infoTextItems={{
                trigger: {
                  i18nKey: "Payments.paymentTriggerInfo",
                  i18nValues: {
                    date: getDateFromTriggerType(),
                  },
                },
                refund: {
                  i18nKey: "Payments.refundInfo",
                  i18nValues: {
                    date: moment(reservation?.general?.checkin).toDate(),
                    amount: reservation?.tarification?.total ?? 0,
                  },
                },
                cbRefund: {
                  i18nKey: "Payments.depositPaymentTimeDelayCBRefundInfo",
                  i18nValues: {
                    date: moment(reservation?.general?.checkin).toDate(),
                  },
                },
              }}
              onError={(error: string | undefined) => setPaymentError(error)}
            />
          </div>

          {/* ERRORS & BUTTONS */}
          <div className="flex flex-col space-y-3">
            <Separator accent="dark" />
            <ErrorMessage>{error}</ErrorMessage>
            <ErrorMessage errorType="FORM">{paymentError}</ErrorMessage>
            <div className="flex gap-4 mt-4">
              <Button type="secondary" disabled={loading} onClick={modal.close}>
                {t("Global.cancel")}
              </Button>
              <Button
                RightIcon={CheckWhiteIcon}
                disabled={loading || Object.entries(errors).length !== 0}
                loading={loading}
              >
                {t("Global.validate")}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </RightModal>
  );
};
