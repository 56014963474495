import React from "react";
import { useTranslation } from "react-i18next";
import { TextInput } from "../Common/TextInput/TextInput";
import { TextAreaInput } from "../Common/TextAreaInput/TextAreaInput";
import { Separator } from "../Common/Separator/Separator";
import { WebsiteResponse } from "../../types/GETTypes";
import paths from "../../constants/paths";

export interface IntegrateContentProps {
  website?: WebsiteResponse | undefined;
}

export const IntegrateContent: React.FC<IntegrateContentProps> = ({
  website,
}) => {
  const { t } = useTranslation();

  const urlRental =
    import.meta.env.VITE_API_URL.replace(
      "/api",
      ":" + import.meta.env.VITE_PORT
    ) +
    paths.WEBSITES.IFRAMES.RENTALS.replace(
      ":apiKey",
      website?.integration.api_key ?? ""
    );

  const urlRentalSearchEngine =
    import.meta.env.VITE_API_URL.replace(
      "/api",
      ":" + import.meta.env.VITE_PORT
    ) +
    paths.WEBSITES.IFRAMES.RENTALS_SEARCH_ENGINE.replace(
      ":apiKey",
      website?.integration.api_key ?? ""
    );

  return (
    <div className="flex flex-col gap-3 flex-1 overflow-y-auto">
      <p className="text-high-contrast font-semibold">
        {t("Website.Integrate.integrateListTitle")}
      </p>

      <p className="text-low-contrast font-light">
        {t("Website.Integrate.integrateListDescription")}
      </p>

      <div>
        <TextInput
          type="clipboard"
          label={t("Website.Integrate.iframeList")}
          placeholder="CODE IFRAME"
          value={`<iframe src={"${urlRental}"} width="100%" height={"100%"} id="booking-engine-rentals"></iframe>`}
        />
      </div>

      <div>
        <TextAreaInput
          type="clipboard"
          value=""
          label={t("Website.Integrate.script")}
          placeholder="JAVASCRIPT"
          disabled={true}
        />
      </div>

      <Separator />

      <p className="text-high-contrast font-semibold">
        {t("Website.Integrate.integrateSearchTitle")}
      </p>

      <p className="text-low-contrast font-light">
        {t("Website.Integrate.integrateSearchDescription")}
      </p>

      <div>
        <TextInput
          type="clipboard"
          label={t("Website.Integrate.iframeSearch")}
          placeholder="CODE IFRAME"
          value={`<iframe src={"${urlRentalSearchEngine}"} width="100%" height={"100%"} id="booking-search-engine-rentals"></iframe>`}
        />
      </div>

      <div>
        <TextAreaInput
          type="clipboard"
          value=""
          label={t("Website.Integrate.script")}
          placeholder="JAVASCRIPT"
          disabled={true}
        />
      </div>
    </div>
  );
};
