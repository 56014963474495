import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button } from "../../Common/Button/Button";

import moment from "moment";
import ArrowLeftIcon from "../../../assets/icons/arrow-left.svg?react";
import ArrowRightWhiteIcon from "../../../assets/icons/arrow-right-white.svg?react";
import CheckinIcon from "../../../assets/icons/log-in.svg?react";
import CheckoutIcon from "../../../assets/icons/log-out.svg?react";
import countries from "../../../constants/countries";
import paths from "../../../constants/paths";
import { post } from "../../../helpers/APIHelper";
import { formatTime } from "../../../helpers/dateHelper";
import { RequiredFields } from "../../../types/commonTypes";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import { InputSelect } from "../../Common/InputSelect/InputSelect";
import { NumberInput } from "../../Common/NumberInput/NumberInput";
import { AddRentalType } from "../AddRental.type";

export type AddRentalRulesType = {
  minTimein: string;
  maxTimein: string;
  minTimeout: string;
  maxTimeout: string;
  minNights: number;
  maxNights: number;
};

export const AddRentalRules: React.FC<{
  rental?: AddRentalType;
  onCancel: () => void;
  onNext: (values: AddRentalRulesType) => void;
  onBack: () => void;
}> = ({ rental, onCancel, onNext, onBack }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const form = useForm<AddRentalRulesType>({
    defaultValues: {
      minTimein: "14:00",
      maxTimein: "22:00",
      minTimeout: "08:00",
      maxTimeout: "11:00",
      minNights: 1,
      maxNights: 365,
    },
  });

  const requiredFields: RequiredFields<AddRentalRulesType> = {
    minTimein: true,
    maxTimein: true,
    minTimeout: true,
    maxTimeout: true,
    minNights: true,
    maxNights: true,
  };

  useEffect(() => {
    if (rental) {
      form.setValue("minTimein", rental?.rules?.minTimein ?? "14:00");
      form.setValue("maxTimein", rental?.rules?.maxTimein ?? "22:00");
      form.setValue("minTimeout", rental?.rules?.minTimeout ?? "01:00");
      form.setValue("maxTimeout", rental?.rules?.maxTimeout ?? "11:00");
      form.setValue("minNights", rental?.rules?.minNights ?? 1);
      form.setValue("maxNights", rental?.rules?.maxNights ?? 365);
    }
  }, [rental]);

  const formattedTime = (time: string) =>
    !time ? "" : formatTime(moment(`${time}:00`, "HH:mm").format("HH:mm"));

  const handleNextStep = async (values: AddRentalRulesType) => {
    setLoading(true);
    setError(null);
    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTAL_ADD_CHECK_RULES}`,
      {
        name: rental?.infos?.name,
        rental_category_id: rental?.infos?.rentalCategory,
        rental_type_id: rental?.infos?.rentalType,
        renting_type: rental?.infos?.rentingType,
        management_type: rental?.infos?.managementType,
        address: rental?.infos?.address,
        postal_code: rental?.infos?.postal_code,
        city: rental?.infos?.city,
        country_code: countries.find(
          (country) => country.value === rental?.infos?.country
        )?.code,
        guests_max_capacity: rental?.infos?.maxGuests,
        adults_max_capacity: rental?.infos?.maxAdults,
        children_max_capacity: rental?.infos?.maxChildren,
        bathrooms_count: rental?.infos?.bathrooms,
        longitude: rental?.infos?.longitude,
        latitude: rental?.infos?.latitude,
        bedrooms: rental?.rooms?.map((r) => JSON.stringify(r)),
        description: rental?.photos?.description,
        equipments: rental?.facilities?.join(",").toUpperCase(),
        checkin_min_time: formattedTime(values.minTimein),
        checkin_max_time: formattedTime(values.maxTimein),
        checkout_min_time: formattedTime(values.minTimeout),
        checkout_max_time: formattedTime(values.maxTimeout),
        min_nights_default: values.minNights,
        max_nights_default: values.maxNights,
      }
    );

    if (res.data?.success) {
      onNext(values);
    } else {
      setError(res.response.data.message);
    }

    setLoading(false);
  };

  form.watch();

  return (
    <form onSubmit={form.handleSubmit(handleNextStep)}>
      <div className="flex flex-col w-full text-sm">
        <div className="flex justify-start pb-12">
          <div className="w-44">
            <Button
              type="secondary"
              disabled={loading}
              LeftIcon={ArrowLeftIcon}
              onClick={onBack}
            >
              {t("Global.previousStep")}
            </Button>
          </div>
        </div>

        <p className="text-lg font-semibold text-high-contrast">
          {t("AddRental.Rules.title")}
        </p>

        <p className="mt-4 mb-6 font-light text-low-contrast">
          {t("AddRental.Rules.subTitle")}
        </p>

        <div className="flex items-center gap-1">
          <CheckinIcon className="w-4 h-4" />
          <p className="font-semibold text-low-contrast">
            {t("AddRental.Rules.checkin")}
          </p>
        </div>

        <div className="flex gap-3 mt-3">
          <div className="flex-1">
            <InputSelect
              register={form.register("minTimein", {
                required: {
                  value: requiredFields.minTimein,
                  message: t("AddRental.Rules.minTimeinError"),
                },
                onChange: (e) => form.setValue("minTimein", e.target.value),
              })}
              required={requiredFields.minTimein}
              infoTooltip={t("AddRental.Rules.minTimeinTooltip")}
              label={t("AddRental.Rules.minTimein")}
              items={[
                {
                  label: "08:00",
                  value: "08:00",
                },
                {
                  label: "09:00",
                  value: "09:00",
                },
                {
                  label: "10:00",
                  value: "10:00",
                },
                {
                  label: "11:00",
                  value: "11:00",
                },
                {
                  label: "12:00",
                  value: "12:00",
                },
                {
                  label: "13:00",
                  value: "13:00",
                },
                {
                  label: "14:00",
                  value: "14:00",
                },
                {
                  label: "15:00",
                  value: "15:00",
                },
                {
                  label: "16:00",
                  value: "16:00",
                },
                {
                  label: "17:00",
                  value: "17:00",
                },
                {
                  label: "18:00",
                  value: "18:00",
                },
                {
                  label: "19:00",
                  value: "19:00",
                },
                {
                  label: "20:00",
                  value: "20:00",
                },
                {
                  label: "21:00",
                  value: "21:00",
                },
                {
                  label: "22:00",
                  value: "22:00",
                },
                {
                  label: "23:00",
                  value: "23:00",
                },
              ]}
              selectedValue={form.getValues("minTimein")}
              error={form.formState.errors.minTimein?.message}
            />
          </div>

          <div className="flex-1">
            <InputSelect
              register={form.register("maxTimein")}
              infoTooltip={t("AddRental.Rules.maxTimeinTooltip")}
              label={t("AddRental.Rules.maxTimein")}
              items={[
                {
                  label: "17:00",
                  value: "17:00",
                },
                {
                  label: "18:00",
                  value: "18:00",
                },
                {
                  label: "19:00",
                  value: "19:00",
                },
                {
                  label: "20:00",
                  value: "20:00",
                },
                {
                  label: "21:00",
                  value: "21:00",
                },
                {
                  label: "22:00",
                  value: "22:00",
                },
                {
                  label: "23:00",
                  value: "23:00",
                },
                {
                  label: "00:00",
                  value: "00:00",
                },
                {
                  label: "01:00 (jour suivant)",
                  value: "01:00 (jour suivant)",
                },
                {
                  label: "02:00 (jour suivant)",
                  value: "02:00 (jour suivant)",
                },
              ]}
              selectedValue={form.getValues("maxTimein")}
              error={form.formState.errors.maxTimein?.message}
            />
          </div>
        </div>

        <div className="flex items-center gap-1 mt-6">
          <CheckoutIcon className="w-4 h-4" />
          <p className="font-semibold text-low-contrast">
            {t("AddRental.Rules.checkout")}
          </p>
        </div>

        <div className="flex gap-3 mt-3">
          <div className="flex-1">
            <InputSelect
              register={form.register("minTimeout")}
              infoTooltip={t("AddRental.Rules.minTimeoutTooltip")}
              label={t("AddRental.Rules.minTimeout")}
              items={[
                {
                  label: "00:00",
                  value: "00:00",
                },
                {
                  label: "01:00",
                  value: "01:00",
                },
                {
                  label: "02:00",
                  value: "02:00",
                },
                {
                  label: "03:00",
                  value: "03:00",
                },
                {
                  label: "04:00",
                  value: "04:00",
                },
                {
                  label: "05:00",
                  value: "05:00",
                },
                {
                  label: "06:00",
                  value: "06:00",
                },
                {
                  label: "07:00",
                  value: "07:00",
                },
                {
                  label: "08:00",
                  value: "08:00",
                },
                {
                  label: "09:00",
                  value: "09:00",
                },
                {
                  label: "10:00",
                  value: "10:00",
                },
                {
                  label: "11:00",
                  value: "11:00",
                },
                {
                  label: "12:00",
                  value: "12:00",
                },
                {
                  label: "13:00",
                  value: "13:00",
                },
                {
                  label: "14:00",
                  value: "14:00",
                },
                {
                  label: "15:00",
                  value: "15:00",
                },
                {
                  label: "16:00",
                  value: "16:00",
                },
                {
                  label: "17:00",
                  value: "17:00",
                },
                {
                  label: "18:00",
                  value: "18:00",
                },
                {
                  label: "19:00",
                  value: "19:00",
                },
                {
                  label: "20:00",
                  value: "20:00",
                },
                {
                  label: "21:00",
                  value: "21:00",
                },
                {
                  label: "22:00",
                  value: "22:00",
                },
                {
                  label: "23:00",
                  value: "23:00",
                },
              ]}
              selectedValue={form.getValues("minTimeout")}
              error={form.formState.errors.minTimeout?.message}
            />
          </div>

          <div className="flex-1">
            <InputSelect
              register={form.register("maxTimeout", {
                required: {
                  value: requiredFields.maxTimeout,
                  message: t("AddRental.Rules.maxTimeoutError"),
                },
              })}
              required={requiredFields.maxTimeout}
              infoTooltip={t("AddRental.Rules.maxTimeoutTooltip")}
              label={t("AddRental.Rules.maxTimeout")}
              items={[
                {
                  label: "00:00",
                  value: "00:00",
                },
                {
                  label: "01:00",
                  value: "01:00",
                },
                {
                  label: "02:00",
                  value: "02:00",
                },
                {
                  label: "03:00",
                  value: "03:00",
                },
                {
                  label: "04:00",
                  value: "04:00",
                },
                {
                  label: "05:00",
                  value: "05:00",
                },
                {
                  label: "06:00",
                  value: "06:00",
                },
                {
                  label: "07:00",
                  value: "07:00",
                },
                {
                  label: "08:00",
                  value: "08:00",
                },
                {
                  label: "09:00",
                  value: "09:00",
                },
                {
                  label: "10:00",
                  value: "10:00",
                },
                {
                  label: "11:00",
                  value: "11:00",
                },
                {
                  label: "12:00",
                  value: "12:00",
                },
                {
                  label: "13:00",
                  value: "13:00",
                },
                {
                  label: "14:00",
                  value: "14:00",
                },
                {
                  label: "15:00",
                  value: "15:00",
                },
                {
                  label: "16:00",
                  value: "16:00",
                },
                {
                  label: "17:00",
                  value: "17:00",
                },
                {
                  label: "18:00",
                  value: "18:00",
                },
                {
                  label: "19:00",
                  value: "19:00",
                },
                {
                  label: "20:00",
                  value: "20:00",
                },
                {
                  label: "21:00",
                  value: "21:00",
                },
                {
                  label: "22:00",
                  value: "22:00",
                },
                {
                  label: "23:00",
                  value: "23:00",
                },
              ]}
              selectedValue={form.getValues("maxTimeout")}
              error={form.formState.errors.maxTimeout?.message}
            />
          </div>
        </div>

        <div className="flex gap-3 mt-6">
          <Controller
            control={form.control}
            name="minNights"
            render={({ field: { value, onChange } }) => (
              <NumberInput
                label={t("AddRental.Rules.minNights")}
                value={value}
                min={1}
                onChangeText={onChange}
                error={form.formState.errors.minNights?.message}
                required={true}
              />
            )}
          />

          <Controller
            control={form.control}
            name="maxNights"
            render={({ field: { value, onChange } }) => (
              <NumberInput
                label={t("AddRental.Rules.maxNights")}
                value={value}
                onChangeText={onChange}
                min={1}
                error={form.formState.errors.maxNights?.message}
                required={true}
              />
            )}
          />
        </div>

        <ErrorMessage>{error}</ErrorMessage>

        <div className="flex gap-4 pb-4 mt-12">
          <Button type="secondary" onClick={onCancel} disabled={loading}>
            {t("Global.cancel")}
          </Button>
          <Button RightIcon={ArrowRightWhiteIcon} loading={loading}>
            {t("AddReservation.Infos.nextStep")}
          </Button>
        </div>
      </div>
    </form>
  );
};
