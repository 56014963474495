import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Trans } from "react-i18next";
import CheckIcon from "../../assets/icons/check-white.svg?react";
import { useAutomation } from "../../hooks/api/automation";
import {
  useOperationalManagementMember,
  useOperationalManagementTeam,
} from "../../hooks/api/operationalManagement";
import { useCheckAuth } from "../../hooks/useCheckAuth";
import { UseModal } from "../../hooks/useModal";
import { RequiredFields, ValueType } from "../../types/commonTypes";
import {
  AutomationItemResponse,
  OperationalManagementTeamMemberForUserItemResponse,
  OperationalManagementTeamMemberResponse,
  TaskAssignationType,
  TaskPriorityType,
} from "../../types/GETTypes";
import { Button } from "../Common/Button/Button";
import InfoCard from "../Common/Card/InfoCard";
import { CenterModal } from "../Common/CenterModal/CenterModal";
import { InputMultiSelectOptionProps } from "../Common/InputMultiSelect/InputMultiSelect.type";
import { SimpleRadio } from "../Common/SimpleRadio/SimpleRadio";
import { ManagementAutomationListAssignationStepForm } from "./AddOrUpdateModalSteps/AssignationStep/AssignationStep.type";
import { TaskAssignmentFlow } from "./TaskAssignmentFlow";
import { PrestaInput } from "../Common/PrestaInput/PrestaInput";
import { MultiSelectWithTagDelete } from "../Common/MultiSelectWithTagDelete/MultiSelectWithTagDelete";
import { ErrorMessage } from "../Common/ErrorMessage/ErrorMessage";

type ManagementAutomationListAssignModalProps = {
  modal: UseModal<any>;
  onAssign?: (data: AutomationItemResponse[]) => void;
  onError: (message: string | null) => void;
  isForm?: boolean;
  onAssignNotForm?: (
    ids: string[],
    assignType: TaskAssignationType,
    reservationId: string,
  ) => Promise<void>;
  isMultiSelect?: boolean;
  showHelpDetailsForTeam?: boolean;
};

type ManagementAutomationListAssignModalForm = {
  assignation_type: "OPERATORS" | "TEAMS";
  assigned_persons: ValueType[];
  assigned_teams: ValueType[];
};

export const ManagementAutomationListAssignModal: React.FC<
  ManagementAutomationListAssignModalProps
> = ({
  modal,
  onAssign,
  onError,
  isForm = true,
  onAssignNotForm,
  isMultiSelect = true,
  showHelpDetailsForTeam = true,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [validationLoading, setValidationLoading] = useState<boolean>(false);
  const [error, setError] = useState();

  const form = useForm<ManagementAutomationListAssignModalForm>({
    mode: "all",
    defaultValues: {
      assignation_type: "OPERATORS",
      assigned_persons: [],
      assigned_teams: [],
    },
  });

  const requiredFields: RequiredFields<ManagementAutomationListAssignationStepForm> =
    {
      assignation_type: true,
      assigned_persons: true,
      assigned_teams: true,
    };

  const userId = useCheckAuth().user?.id;

  const [persons, setPersons] = useState<
    OperationalManagementTeamMemberResponse[] | undefined
  >();
  const [teams, setTeams] = useState<
    OperationalManagementTeamMemberForUserItemResponse[] | undefined
  >();

  const getPersonsOrTeams = () => {
    if (
      form.getValues("assignation_type") === "OPERATORS" &&
      (!persons || persons?.length === 0)
    ) {
      const operationalManagementMemberApi = useOperationalManagementMember({
        onSuccess: (data) => {
          setPersons(data);
        },
        onError: (message: string | null) => onError(message),
        onStart: () => {
          onError(null);
          setLoading(true);
        },
        onEnd: () => setLoading(false),
      });

      operationalManagementMemberApi.getAll();
    } else if (
      form.getValues("assignation_type") === "TEAMS" &&
      (!teams || teams?.length === 0)
    ) {
      const operationalManagementTeamApi = useOperationalManagementTeam({
        onSuccess: (data) => {
          setTeams(data);
        },
        onError: (message: string | null) => onError(message),
        onStart: () => {
          onError(null);
          setLoading(true);
        },
        onEnd: () => setLoading(false),
      });

      operationalManagementTeamApi.getAllForUser({
        memberId: userId,
      });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPersonsOrTeams();
  }, [form.getValues("assignation_type")]);

  useEffect(() => {
    // @ts-ignore
    setError("");
  }, []);

  const getPersonsOrTeamsItems = () => {
    if (
      form.getValues("assignation_type") === "OPERATORS" &&
      persons &&
      persons.length > 0
    ) {
      return persons.map((person) => {
        return {
          label: `${person.first_name} ${person.last_name}`,
          value: person.id,
        };
      });
    } else if (
      form.getValues("assignation_type") === "TEAMS" &&
      teams &&
      teams.length > 0
    ) {
      return teams.map((team) => {
        return {
          label: team.name,
          value: team.id,
        };
      });
    }
    return [];
  };

  const getSelectedTeamsText = (
    selectedTeamIds: ValueType[],
    options: InputMultiSelectOptionProps[],
  ): string => {
    const selectedTeams = options.filter((option) =>
      selectedTeamIds.includes(option.value),
    );

    const selectedLabels = selectedTeams.map((team) => team.label);

    if (selectedLabels.length > 1) {
      const displayedLabels = selectedLabels.slice(0, 1).join(", ");
      const remainingCount = selectedLabels.length - 1;
      return `${displayedLabels} + ${t("Global.others", {
        count: remainingCount,
      })}`;
    } else {
      return selectedLabels.join(", ");
    }
  };

  const handleValidation = () => {
    const allTeams = form.getValues("assigned_teams");
    const allOperators = form.getValues("assigned_persons");

    if (form.getValues("assignation_type") === "TEAMS") {
      if (allTeams.length <= 0) {
        form.setError("assigned_teams", {
          message: t("Global.Errors.requiredField", {
            fieldName: t(
              "Automation.AddOrUpdateModal.Steps.Assignation.assignationPersonsOrTeamsLabel",
            ),
          }),
        });
        return;
      }
    }

    if (form.getValues("assignation_type") === "OPERATORS") {
      if (allOperators.length <= 0) {
        form.setError("assigned_persons", {
          message: t("Global.Errors.requiredField", {
            fieldName: t(
              "Automation.AddOrUpdateModal.Steps.Assignation.assignationPersonsOrTeamsLabel",
            ),
          }),
        });
        return;
      }
    }

    if (isForm) {
      const { assignMultiple } = useAutomation({
        onSuccess: (data) => {
          if (Array.isArray(data) && typeof onAssign === "function") {
            onAssign(data);
          }
          modal.close();
        },
        onError: (message: string | null) => onError(message),
        onStart: () => {
          onError(null);
          setValidationLoading(true);
        },
        onEnd: () => setValidationLoading(false),
      });

      assignMultiple({
        ids: modal.data!,
        assignee_ids:
          form.getValues("assignation_type") === "OPERATORS"
            ? form.getValues("assigned_persons")
            : form.getValues("assigned_teams"),
        assignation_type: form.getValues("assignation_type"),
      });
    } else {
      if (typeof onAssignNotForm === "function") {
        if (modal.data?.reservationId) {
          setLoading(true);
          const ids =
            form.getValues("assignation_type") === "OPERATORS"
              ? form.getValues("assigned_persons")
              : form.getValues("assigned_teams");
          onAssignNotForm(
            ids as string[],
            form.getValues("assignation_type"),
            modal.data?.reservationId,
          )
            .catch((error: Error) => {
              // @ts-ignore
              setError(error.message);
            })
            .then(() => {
              modal.close();
              if (typeof modal.data?.reloadCalendarList === "function") {
                modal.data.reloadCalendarList();
              }
            })
            .finally(() => {
              setLoading(false);
            });
        }
      }
    }
  };

  form.watch();

  return (
    <CenterModal
      title={t("Automation.AssignModal.title", {
        count: Array.isArray(modal.data) ? modal.data?.length : 1,
      })}
      classNames={{
        divModal: "w-[500px]",
      }}
      isVisible={modal.isVisible}
      onClose={validationLoading ? () => {} : modal.close}
    >
      <div className="flex flex-col gap-y-4">
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <Controller
          control={form.control}
          name="assignation_type"
          rules={{
            required: requiredFields.assignation_type,
          }}
          render={({ field: { value, onChange } }) => {
            return (
              <div>
                <p className="mb-4 text-xs font-medium text-low-contrast">
                  {t(
                    "Automation.AddOrUpdateModal.Steps.Assignation.assignationTypeLabel",
                  )}
                </p>
                <div className="flex text-sm font-semibold gap-x-3 text-low-contrast">
                  <SimpleRadio
                    classNames={{
                      label: "text-low-contrast font-light text-xs",
                    }}
                    label={t(
                      isMultiSelect
                        ? "Automation.AddOrUpdateModal.Steps.Assignation.assignationTypePersonsLabel"
                        : "Calendar.Reservation.assignedOne",
                    )}
                    disabled={loading || validationLoading}
                    value={value === "OPERATORS"}
                    onClick={() => {
                      onChange("OPERATORS");
                      form.setValue("assignation_type", "OPERATORS");
                      form.watch();
                    }}
                  />
                  <SimpleRadio
                    classNames={{
                      label: "text-low- font-light text-xs",
                    }}
                    label={t(
                      isMultiSelect
                        ? "Automation.AddOrUpdateModal.Steps.Assignation.assignationTypeTeamLabel"
                        : "Calendar.Reservation.assignedOneTeam",
                    )}
                    disabled={loading || validationLoading}
                    value={value === "TEAMS"}
                    onClick={() => {
                      onChange("TEAMS");
                      form.setValue("assignation_type", "TEAMS");
                      form.watch();
                    }}
                  />
                </div>
              </div>
            );
          }}
        />

        {form.getValues("assignation_type") === "OPERATORS" && (
          <>
            <Controller
              control={form.control}
              disabled={loading || validationLoading}
              name="assigned_persons"
              render={({ field: { onChange } }) => (
                <PrestaInput
                  isMultiSelect={isMultiSelect}
                  disabled={loading || validationLoading}
                  error={form.formState.errors.assigned_persons?.message}
                  onChange={(ids) => {
                    onChange(ids);
                    console.log(ids);
                  }}
                  label={
                    t(
                      isMultiSelect
                        ? "Automation.AddOrUpdateModal.Steps.Assignation.assignationPersonsOrTeamsLabel"
                        : "" + "Calendar.Reservation.assignedPerson",
                    ) + " *"
                  }
                />
              )}
            />
          </>
        )}
        {form.getValues("assignation_type") === "TEAMS" && (
          <>
            <Controller
              control={form.control}
              disabled={loading || validationLoading}
              name="assigned_teams"
              render={({ field: { onChange } }) => (
                <MultiSelectWithTagDelete
                  isMultiSelect={isMultiSelect}
                  items={getPersonsOrTeamsItems()}
                  disabled={loading || validationLoading}
                  error={form.formState.errors.assigned_teams?.message}
                  onChange={(ids) => {
                    onChange(ids);
                  }}
                  label={
                    t(
                      isMultiSelect
                        ? "Automation.AddOrUpdateModal.Steps.Assignation.assignationPersonsOrTeamsLabel"
                        : "" + "Calendar.Reservation.assignedTeam",
                    ) + " *"
                  }
                />
              )}
            />
          </>
        )}

        {showHelpDetailsForTeam &&
          form.getValues("assignation_type") === "TEAMS" &&
          form.getValues("assigned_teams") &&
          form.getValues("assigned_teams").length > 0 && (
            <>
              <InfoCard
                title={
                  <Trans
                    i18nKey={
                      "Automation.AddOrUpdateModal.Steps.Assignation.assignationTeamsInfoTitle"
                    }
                    components={{
                      strong: <span className="font-bold"></span>,
                    }}
                    values={{
                      teamName: getSelectedTeamsText(
                        form.getValues("assigned_teams"),
                        getPersonsOrTeamsItems(),
                      ),
                    }}
                  />
                }
                description={
                  <Trans
                    i18nKey={
                      "Automation.AddOrUpdateModal.Steps.Assignation.assignationTeamsInfoDescription"
                    }
                    components={{
                      strong: <span className="font-bold"></span>,
                    }}
                  />
                }
              />
              <TaskAssignmentFlow />
            </>
          )}

        <div className="flex justify-between space-x-6">
          <Button
            type="secondary"
            onClick={modal.close}
            disabled={loading || validationLoading}
          >
            {t("Global.cancel")}
          </Button>
          <Button
            type="primary"
            onClick={handleValidation}
            disabled={loading || validationLoading || !form.formState.isValid}
            loading={validationLoading}
            RightIcon={CheckIcon}
          >
            {t("Global.validate")}
          </Button>
        </div>
      </div>
    </CenterModal>
  );
};
