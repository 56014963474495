import React from "react";
import {useTranslation} from "react-i18next";

function GuestWebsiteErrorPage() {
  const {t} = useTranslation();
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100 text-gray-800">
      <h1 className="text-xxxxl font-bold text-red-500">404</h1>
      <p className="text-xl mt-4">{t("GuestWebsite.404")}</p>
    </div>
  );
}

export default GuestWebsiteErrorPage;
