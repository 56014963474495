/**
 * Card Form for add contact in rentals
 * (reservation contact, invoices reservation contact ...)
 */

import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useRentalContact } from "../../../../hooks/api/rentalContact";
import { Button } from "../../../Common/Button/Button";
import { Card } from "../../../Common/Card/Card";
import { ErrorMessage } from "../../../Common/ErrorMessage/ErrorMessage";
import { FlexCenterBetween } from "../../../Common/FlexComponents/FlexCenterBetween";
import { FlexCol } from "../../../Common/FlexComponents/FlexCol";
import { FlexItemCenter } from "../../../Common/FlexComponents/FlexItemCenter";
import { PhoneInput } from "../../../Common/PhoneInput/PhoneInput";
import { Separator } from "../../../Common/Separator/Separator";
import { HelpText } from "../../../Common/TextComponents/HelpText";
import { TitleText } from "../../../Common/TextComponents/TitleText";
import { TextInput } from "../../../Common/TextInput/TextInput";

import {
  ContactType,
  RentalContact,
  RentalResponse,
} from "../../../../types/GETTypes";

// Icons
import ArrowLeft from "../../../../assets/icons/arrow-left.svg?react";
import CheckIcon from "../../../../assets/icons/check-white.svg?react";
import ContactIcon from "../../../../assets/icons/contacts.svg?react";
import EditIcon from "../../../../assets/icons/edit.svg?react";
import AddIcon from "../../../../assets/icons/plus.svg?react";
import TrashIcon from "../../../../assets/icons/trash.svg?react";

export interface ContactCardProps {
  rental: RentalResponse;
}

interface FormInterface {
  general: null | RentalContact;
  invoices: null | RentalContact;
  reservations: null | RentalContact;
  central_reservations: null | RentalContact;
  requests: null | RentalContact;
  availability: null | RentalContact;
  site_content: null | RentalContact;
  parity: null | RentalContact;
}

export const ContactCardInfo = ({ rental }: ContactCardProps) => {
  const { getContacts, createContact, deleteContactTypeIfExist } =
    useRentalContact(rental.id);

  const [form, setForm] = useState<FormInterface>({
    general: null,
    invoices: null,
    reservations: null,
    central_reservations: null,
    requests: null,
    availability: null,
    site_content: null,
    parity: null,
  });

  const [editCard, setEditCard] = useState(false);
  const [error, setError] = useState("");
  const [loading, setloading] = useState(false);
  const [contactsCache, setContactsCache] = useState<RentalContact[]>([]);

  /**
   * Function for load all data
   * with contact for rental
   */
  const loadAllData = () => {
    getContacts().then((contacts) => {
      setContactsCache(contacts);
      contacts.forEach((contact) => {
        setForm((prevForm) => ({
          ...prevForm,
          [contact.contact_type]: {
            first_name: contact.first_name,
            last_name: contact.last_name,
            email: contact.email,
            phone: contact.phone,
            contact_type: contact.contact_type,
          },
        }));
      });
    });
  };

  /**
   * Use effect loader data
   */
  useEffect(() => {
    setError("");
    loadAllData();
  }, [editCard]);

  /**
   * Function called when the "save" button is clicked,
   * and saves all data while carefully checking the cache
   * to avoid unnecessary API calls.
   */
  const handleSaveData = async () => {
    const keys = Object.keys(form);

    for (let index = 0; index < keys.length; index++) {
      const formValueKey = keys[index];
      const contactType = formToContactType(keys[index]);
      // @ts-ignore
      const item: RentalContact = form[formValueKey];

      if (!item) {
        const found = contactsCache.some(
          (contactCache) => contactCache.contact_type === contactType
        );
        if (found) {
          await deleteContactTypeIfExist(contactType);
        }
      } else {
        const existingContact = contactsCache.find(
          (contactCache) => contactCache.contact_type === contactType
        );
        if (existingContact) {
          const hasChanged =
            existingContact.first_name !== item.first_name ||
            existingContact.last_name !== item.last_name ||
            existingContact.email !== item.email ||
            existingContact.phone !== item.phone;

          if (hasChanged) {
            await createContact(
              contactType,
              item.first_name,
              item.last_name,
              item.email,
              item.phone
            );
          }
        } else {
          await createContact(
            contactType,
            item.first_name,
            item.last_name,
            item.email,
            item.phone
          );
        }
      }
    }
  };

  const formToContactType = (type: string): ContactType => {
    switch (type) {
      case "general":
        return "general";
      case "invoices":
        return "invoices";
      case "reservations":
        return "reservations";
      case "central_reservations":
        return "central_reservations";
      case "requests":
        return "requests";
      case "availability":
        return "availability";
      case "site_content":
        return "site_content";
      case "parity":
        return "parity";
      default:
        return "general";
    }
  };

  const { t } = useTranslation();
  const title = t("Global.contacts");
  const edit = t("Global.edit");
  const save = t("Global.save");
  const helpText = t("Rentals.helpText");
  const name = t("Rentals.name");
  const last_name = t("Rentals.last_name");
  const email = t("Rentals.email");
  const tel = t("Rentals.tel");
  const addContact = t("Rentals.addContact");
  const cancel = t("Global.cancel");

  const general = t("Rentals.general");
  const invoices = t("Rentals.invoices");
  const reservations = t("Rentals.reservations");
  const central_reservations = t("Rentals.centralReservations");
  const requests = t("Rentals.requests");
  const availability = t("Rentals.availability");
  const site_content = t("Rentals.siteContent");
  const parity = t("Rentals.parity");

  const handleClickAddContact = (contactType: ContactType) => {
    setForm((prevForm) => ({
      ...prevForm,
      [contactType]: {
        name: "",
        lastName: "",
        phone: "",
        email: "",
        contact_type: contactType,
      },
    }));
  };

  const handleRemoveContact = (contactType: ContactType) => {
    setForm((prevForm) => ({
      ...prevForm,
      [contactType]: null,
    }));
  };

  const addContactsButtonsItems = [
    {
      title: general,
      onClick: () => handleClickAddContact("general"),
    },
    {
      title: invoices,
      onClick: () => handleClickAddContact("invoices"),
    },
    {
      title: reservations,
      onClick: () => handleClickAddContact("reservations"),
    },
    {
      title: central_reservations,
      onClick: () => handleClickAddContact("central_reservations"),
    },
    {
      title: requests,
      onClick: () => handleClickAddContact("requests"),
    },
    {
      title: availability,
      onClick: () => handleClickAddContact("availability"),
    },
    {
      title: site_content,
      onClick: () => handleClickAddContact("site_content"),
    },
    {
      title: parity,
      onClick: () => handleClickAddContact("parity"),
    },
  ];

  return (
    <Card
      Icon={ContactIcon}
      label={title}
      anchor={"contact"}
      button={{
        label: editCard ? save : edit,
        Icon: editCard ? CheckIcon : EditIcon,
        onClick: () => {
          if (editCard) {
            setloading(true);
            handleSaveData()
              .then(() => {
                setEditCard(!editCard);
              })
              .catch((error) => {
                setError(error.message);
              })
              .finally(() => {
                setloading(false);
              });
          } else {
            setEditCard(!editCard);
          }
        },
        type: editCard ? "primary" : "secondary",
        loading: loading,
      }}
      secondaryButton={{
        label: cancel,
        LeftIcon: ArrowLeft,
        onClick: () => setEditCard(!editCard),
        type: "secondary",
        visible: editCard,
        disabled: loading,
      }}
    >
      {/*Card Edit Mode Off*/}
      <FlexCol visible={!editCard}>
        {Object.values(form).map((item, index) => {
          return (
            item && (
              <div key={index}>
                <FlexCol gap={10}>
                  <HelpText>{addContactsButtonsItems[index]?.title}</HelpText>
                  <p className={"font-bold"}>
                    {item.first_name} {item.last_name}
                  </p>
                  <p className={"font-bold text-gray-500"}>
                    {item.email}
                    {" - "}
                    {item.phone}
                  </p>
                </FlexCol>
                <Separator accent={"dark"} />
              </div>
            )
          );
        })}
      </FlexCol>

      {/*Card Edit Mode On*/}
      <FlexCol gap={20} visible={editCard}>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <HelpText>{helpText}</HelpText>
        {Object.values(form).map((item: RentalContact, index) =>
          !item ? (
            <div key={index}>
              <FlexCenterBetween>
                <TitleText>{addContactsButtonsItems[index]?.title}</TitleText>
                <div>
                  <Button
                    RightIcon={AddIcon}
                    type={"secondary"}
                    onClick={addContactsButtonsItems[index]?.onClick}
                  >
                    {addContact}
                  </Button>
                </div>
              </FlexCenterBetween>
            </div>
          ) : (
            <div key={index}>
              <FlexCenterBetween>
                <TitleText>{addContactsButtonsItems[index]?.title}</TitleText>
                <div>
                  <Button
                    type={"secondary"}
                    RightIcon={TrashIcon}
                    onClick={() => handleRemoveContact(item.contact_type)}
                  />
                </div>
              </FlexCenterBetween>
              <FlexCol gap={10}>
                <FlexItemCenter gap={20}>
                  <TextInput
                    label={name}
                    placeholder={"Lisa"}
                    value={item.first_name ?? ""}
                    onChangeText={(text: string) => {
                      setForm((prevForm) => {
                        return {
                          ...prevForm,
                          [item.contact_type]: {
                            // @ts-ignore
                            ...prevForm[item.contact_type],
                            first_name: text,
                          },
                        };
                      });
                    }}
                  />
                  <TextInput
                    label={last_name}
                    placeholder={"Montano"}
                    value={item.last_name ?? ""}
                    onChangeText={(text: string) => {
                      setForm((prevForm) => {
                        return {
                          ...prevForm,
                          [item.contact_type]: {
                            // @ts-ignore
                            ...prevForm[item.contact_type],
                            last_name: text,
                          },
                        };
                      });
                    }}
                  />
                </FlexItemCenter>
                <FlexItemCenter gap={20}>
                  <TextInput
                    type={"text"}
                    label={email}
                    placeholder={"lisa.montano@gmail.com"}
                    value={item.email ?? ""}
                    onChangeText={(text: string) => {
                      setForm((prevForm) => {
                        return {
                          ...prevForm,
                          [item.contact_type]: {
                            // @ts-ignore
                            ...prevForm[item.contact_type],
                            email: text,
                          },
                        };
                      });
                    }}
                  />
                  <PhoneInput
                    label={tel}
                    placeholder={"06 32 32 41 89"}
                    value={item.phone ?? ""}
                    onChangeText={(text: string) => {
                      setForm((prevForm) => {
                        return {
                          ...prevForm,
                          [item.contact_type]: {
                            // @ts-ignore
                            ...prevForm[item.contact_type],
                            phone: text,
                          },
                        };
                      });
                    }}
                  />
                </FlexItemCenter>
              </FlexCol>
            </div>
          )
        )}
      </FlexCol>
    </Card>
  );
};
