import React, {ReactNode} from "react";
import ArrowRightIcon from "../../assets/icons/arrow-right.svg?react";

export type DashboardServicesStatsType =
  | "payments"
  | "tasks_to_complete"
  | "tasks_not_assigned";
export type DashboardServicesStatsItemProps = {
  header: string | ReactNode;
  title: string | ReactNode;
  description: string | ReactNode;
  linkLabel: string;
  url: string;
  type: DashboardServicesStatsType;
  hide:boolean
};

const DashboardServicesStatsItem: React.FC<DashboardServicesStatsItemProps> = ({
  header,
  title,
  description,
  linkLabel,
  url,
  hide = false
}) => {
  return (
    <div className="flex flex-col h-[200px] max-h-[200px] w-[380px] rounded-8px border-1 border-element-border overflow-hidden bg-white mr-2">
      <div className="p-4 flex-1 pb-6">
        <p className="text-xxl font-bold text-high-contrast">{header}</p>

        <div className="h-full flex flex-col pt-4">
          <p className="flex flex-row font-semibold text-high-contrast items-center gap-1">
            {title}
          </p>

          <p className="flex flex-row gap-2 mt-1">
            <span className="text-low-contrast font-light">{description}</span>
          </p>
        </div>
      </div>

      {
        !hide && (
          <a
            className="flex flex-row h-10 bg-element-background border-t-1 border-element-border px-4 items-center cursor-pointer hover:bg-element-background-active"
            href={url}
          >
            <p className="flex-1 text-low-contrast font-semibold">{linkLabel}</p>

            <ArrowRightIcon />
          </a>
        )
      }
    </div>
  );
};

export default DashboardServicesStatsItem;
