import React, {useEffect, useState} from "react";
import {RightModal} from "../Common/RightModal/RightModal";
import {UseModal} from "../../hooks/useModal";
import {useTranslation} from "react-i18next";
import {PlatformEnum} from "../../enums/GETenums";
import ChevronRightIcon from "../../assets/icons/chevron-right.svg?react";
import ArrowLeftIcon from "../../assets/icons/arrow-left.svg?react";
import ArrowRightIcon from "../../assets/icons/arrow-right.svg?react";
import ArrowTopRightIcon from "../../assets/icons/arrow-up-right-black.svg?react";
import {
  getNameByPlatform,
  getPlatformIconByEnum,
  getPlatformNameByEnum,
} from "../../helpers/platformHelper";
import {Button} from "../Common/Button/Button";
import {InputSelect} from "../Common/InputSelect/InputSelect";
import paths from "../../constants/paths";
import {get} from "../../helpers/APIHelper";
import {
  DetailsStatsReservationResponse,
  DistributionKeyEnum,
  StatsNameKeysEnum,
} from "../../types/GETTypes";
import {ValueType} from "../../types/commonTypes";
import moment from "moment-timezone";

interface DetailsModalProps {
  modal: UseModal<unknown>;
}

interface ModalData {
  platform: DistributionKeyEnum;
  statName: StatsNameKeysEnum;
  years: ValueType;
  currency: ValueType;
}

const DetailsModal = ({modal}: DetailsModalProps) => {
  const {t} = useTranslation();
  const {platform, statName, years, currency} = (modal.data as ModalData) || {};
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [limitPerPage, setLimitPerPage] = useState<number>(5);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [reservations, setReservations] =
    useState<DetailsStatsReservationResponse[]>();
  const [total, setTotal] = useState<number>();

  const fetchStatsDetailedHistory = async ({
    name,
    limit,
    page,
  }: {
    name?: StatsNameKeysEnum;
    limit?: ValueType;
    page?: ValueType;
  }) => {
    const st = name ?? statName;
    setLoading(true);
    if (st && platform) {
      const params = {
        platform: getNameByPlatform(platform),
        limit: limit ?? limitPerPage,
        page: page ?? currentPage,
        years,
      };

      const response = await get(
        `${import.meta.env.VITE_API_URL}/${
          paths.API.ANALYTICS.DETAILED
        }/${st?.toLowerCase()}`,
        {params}
      );

      if (response.data?.success) {
        setReservations(response.data?.result?.reservations);
        setCurrentPage(response.data?.result?.page);
        setTotalPage(response.data?.result?.total_pages);
        setTotal(response.data?.result?.total_value);
      } else {
        setReservations([]);
        setCurrentPage(1);
        setTotalPage(1);
        setTotal(0);
      }
    }
    setLoading(false);
  };

  const getTitle = () => {
    if (loading) {
      return (
        <div className="flex flex-1 flex-row items-center text-xl font-bold text-high-contrast animate-pulse">
          <div className="h-8 w-2/3 bg-gray-300 rounded ml-2"></div>
        </div>
      );
    }
    return (
      <div className="flex flex-1 flex-row items-center text-xl font-bold text-high-contrast">
        <span>{t("Analytics.history")}</span>
        <ChevronRightIcon className="w-8 h-8" />
        {getPlatformIconByEnum(platform)}
        <span className="ml-2">{getPlatformNameByEnum(platform)}</span>
      </div>
    );
  };

  useEffect(() => {
    fetchStatsDetailedHistory({});
  }, [currentPage, modal.data]);

  const handlePageBack = () => {
    let result = currentPage - 1;

    if (result <= 1) {
      result = 1;
    }
    setCurrentPage(result);
    fetchStatsDetailedHistory({page: result});
  };

  const handlePageNext = () => {
    let next = currentPage + 1;
    if (next > totalPage) {
      next = totalPage;
    }
    setCurrentPage(next);
    fetchStatsDetailedHistory({page: next});
  };

  const handleSelect = (value: ValueType) => {
    setLimitPerPage(Number(value));
    fetchStatsDetailedHistory({limit: value});
  };


  return (
    <RightModal
      isVisible={modal.isVisible}
      onClose={modal.close}
      title={getTitle()}
    >
      {loading ? (
        <div className="w-full border-1 bg-gray-200 animate-pulse rounded-md">
          <div className="p-4 space-y-4">
            <div className="flex justify-between">
              <div className="h-6 bg-gray-300 rounded w-1/4"></div>
              <div className="h-6 bg-gray-300 rounded w-1/4"></div>
            </div>
            <div className="pb-4">
              <div className=" py-2">
                <div className="mt-4 h-8 bg-gray-300 rounded"></div>
              </div>
            </div>
            <div className="mt-8 h-10 bg-gray-300 rounded"></div>
            <div className="mt-4 h-10 bg-gray-300 rounded"></div>
            <div className="mt-4 h-10 bg-gray-300 rounded"></div>
            <div className="mt-4 h-10 bg-gray-300 rounded"></div>
            <div className="mt-4 h-10 bg-gray-300 rounded"></div>
            <div className="mt-4 h-10 bg-gray-300 rounded"></div>
            <div className="mt-4 h-10 bg-gray-300 rounded"></div>
          </div>
        </div>
      ) : (
        <div className="space-y-3">
          <div className="text-low-contrast font-light">
            {t("Analytics.historySubTitle")}
          </div>
          <div className="overflow-hidden rounded-lg border border-element-border">
            <table className="table-auto w-full rounded-4px border-1 border-element-border border-collapse overflow-hidden border-spacing-0">
              <tr className="bg-element-background border-b-1 border-element-border">
                <th className="px-2 py-2  text-start">
                  <div className="flex flex-row items-center cursor-pointer hover:underline justify-start">
                    <p>{t("Analytics.booking")}</p>
                  </div>
                </th>
                <th className="px-2 py-2 text-end">
                  <div className="flex flex-row items-center cursor-pointer hover:underline justify-end">
                    <p>{t("Analytics.payoutHost")}</p>
                  </div>
                </th>
              </tr>

              {reservations?.map((reservation) => (
                <tr className="border-b-1 border-element-border">
                  <td className="px-2 py-2 text-start">
                    <div className="flex flex-col space-y-2">
                      <div className="flex items-center font-bold">
                        {moment(reservation.checkin).format("DD/MM/YYYY")} ·{" "}
                        {reservation.id}{" "}
                        <ArrowTopRightIcon
                          className="w-4 h-4 ml-1"
                          fill="black"
                        />
                      </div>
                      <div className="flex items-center text-low-contrast text-sm">
                        {t("Analytics.reservationDate", {
                          checkin: moment(reservation.checkin).format(
                            "DD/MM/YYYY"
                          ),
                          checkout: moment(reservation.checkout).format(
                            "DD/MM/YYYY"
                          ),
                        })}
                      </div>
                      <div className="flex items-center text-low-contrast text-sm">
                        {reservation.rental_name}
                      </div>
                    </div>
                  </td>
                  <td className="px-2 py-2 font-bold text-end align-top">
                    {reservation.value?.toLocaleString('fr-FR')} {currency ?? "€"}
                  </td>
                </tr>
              ))}
            </table>
          </div>
          <div className="flex justify-between items-center">
            <span className="font-bold">{t("Analytics.total")}</span>
            <span className="font-bold text-lg">
              {total?.toLocaleString('fr-FR')} {currency ?? "€"}
            </span>
          </div>
          <div className="flex flex-col mt-4 items-center">
            <div className="flex flex-1 flex-row items-center space-x-2 w-full justify-between">
              <p className="text-high-contrast text-sm font-semibold">
                {t("Analytics.displayUpTo")}
              </p>
              <div>
                <InputSelect
                  items={[
                    {
                      value: 5,
                      label: t(
                        "Analytics.Details.Modal.displayNbReservations",
                        {nb: 5}
                      ),
                    },
                    {
                      value: 10,
                      label: t(
                        "Analytics.Details.Modal.displayNbReservations",
                        {nb: 10}
                      ),
                    },
                    {
                      value: 15,
                      label: t(
                        "Analytics.Details.Modal.displayNbReservations",
                        {nb: 15}
                      ),
                    },
                    {
                      value: 20,
                      label: t(
                        "Analytics.Details.Modal.displayNbReservations",
                        {nb: 20}
                      ),
                    },
                  ]}
                  selectedValue={limitPerPage}
                  onSelect={(value) => handleSelect(value)}
                />
              </div>
            </div>

            <div className="flex flex-row space-x-2 mt-8">
              <Button
                type="secondary"
                LeftIcon={ArrowLeftIcon}
                disabled={currentPage <= 1}
                onClick={handlePageBack}
              ></Button>
              <Button type="secondary">
                <span className="px-1">{currentPage}</span>
              </Button>
              <Button
                type="secondary"
                LeftIcon={ArrowRightIcon}
                disabled={currentPage >= totalPage}
                onClick={handlePageNext}
              ></Button>
            </div>
          </div>
        </div>
      )}
    </RightModal>
  );
};

export default DetailsModal;
