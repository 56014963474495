import React, {forwardRef, useEffect, useImperativeHandle} from "react";
import {useTranslation} from "react-i18next";
import InformationIcon from "../../../../assets/icons/information.svg?react";
import paths from "../../../../constants/paths";
import {useTablePage} from "../../../../hooks/useTablePage";
import {ValueType} from "../../../../types/commonTypes";
import {RentalLightListItemResponse} from "../../../../types/GETTypes";
import {MultiSelectRentalsTable} from "../../../Common/MultiSelectRentalsTable/MultiSelectRentalsTable";
import {ProgressionRentalsRulesModalRef} from "../ProgressionRentalsRulesModal.type";
import {RentalReservationRulesCard} from "../../../Rental/Infos/ReservationRules/ReservationRulesCard";
import {Rental} from "../../../../pages/RentalPage/RentalPage.type";
import {useRentalPageContext} from "../../../../pages/RentalPage/RentalPageContext";

export const ProgressionRentalsReservationRules = forwardRef<
  ProgressionRentalsRulesModalRef,
  {
    onNext: () => void;
  }
>(({onNext}, ref) => {
  const {rental} = useRentalPageContext();
  
  return (
    <div className="flex flex-col gap-6">
      <RentalReservationRulesCard
        rentalReservationRules={rental?.details?.rules}
        workspaceOwner={rental?.workspace_owner}
      />
    </div>
  );
});
