import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useIframe } from "../../hooks/api/iframe";
import { RentalLightListItemResponse } from "../../types/GETTypes";
import IframeRentalAccessDenied from "../../components/IframeRentals/IframeRentalAccessDenied";
import RentalsListComponent from "../../components/GuestWebsite/Common/Template2.0/RentalsListComponent";

/**
 * Page that displays accommodations
 * directly within an iframe, using an API call.
 */
export default function IframeRentalsPage() {
  const [rentals, setRentals] = useState<RentalLightListItemResponse[]>();
  const { getRentals, getUrlAllowed } = useIframe();
  const params = useParams();
  const apiKey = params.apiKey;
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (apiKey) {
        getRentals(apiKey).then((response) => {
            setRentals(response);
            setOpen(true);
        });
    }
  }, []);

  if (!isOpen) return <IframeRentalAccessDenied />;

  // TODO : skeleton rentals
  if (!rentals) return <></>;

  // TODO : show rentals with style (with the Super Template Style like)
  return (
      // <RentalsListComponent
      //     rentals={rentals}
      //     forIframe={true}
      // />

      <>
          dev
      </>
  );
}
