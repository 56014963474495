import React from "react";
import { CalendarRentalLineProps } from "./Calendar.type";
import { useTranslation } from "react-i18next";
import {
  CALENDAR_CELL_HEIGHT,
  CALENDAR_NB_DAYS_AFTER,
  CALENDAR_NB_DAYS_BEFORE,
  CALENDAR_RENTAL_NAME_WIDTH,
} from "../../constants/calendar";
import { CalendarResctrictionLine } from "./RenstrictionLine";
import { CalendarCellSkeleton } from "./CellSkeleton";
import { CalendarReservationItem } from "./ReservationItem";

import ChevronUpIcon from "../../assets/icons/chevron-up.svg?react";
import ChevronDownIcon from "../../assets/icons/chevron-down.svg?react";
import { CalendarCell } from "./Cell";

export const CalendarRentalLine: React.FC<CalendarRentalLineProps> = ({
  rental,
  from,
  to,
  daysOffset,
  restrictionVisible,
  displayBy = "platform",
  onToggleOpen = () => {},
  onReservationClick,
  onDateClick,
  onMinNightsClick,
  onMaxNightsClick,
  onNoCheckinCheckoutClick,
}) => {
  const { t } = useTranslation();

  const nbDays = to.diff(from, "days");

  const handleClickRestrictionToggle = () => {
    onToggleOpen();
  };

  return (
    <tbody>
      <tr
        className="relative"
        style={{ transform: "translateZ(0)", zIndex: 50 }}
      >
        <td
          style={{
            width: CALENDAR_RENTAL_NAME_WIDTH,
            height: CALENDAR_CELL_HEIGHT,
          }}
          className="bg-white border-t-1 border-element-border"
        >
          <div
            className="flex gap-2 items-center cursor-pointer relative pl-2 pr-4"
            onClick={handleClickRestrictionToggle}
          >
            <img src={rental.photo} className="w-8 h-8 border-1 rounded-6px" />
            <p className="text-high-contrast font-semibold flex-1 line-clamp-2 text-[13px] leading-tight">
              {rental.name}
            </p>
            {restrictionVisible ? (
              <ChevronUpIcon className="w-5 h-5" />
            ) : (
              <ChevronDownIcon className="w-5 h-5" />
            )}

            <div
              className="absolute bg-element-border"
              style={{
                width: 1,
                height: CALENDAR_CELL_HEIGHT + 3,
                left: CALENDAR_RENTAL_NAME_WIDTH - 7,
                top: -10,
              }}
            />
          </div>
        </td>

        {Array.from({ length: CALENDAR_NB_DAYS_BEFORE }).map((v, index) => (
          <CalendarCellSkeleton
            key={index}
            index={index}
            daysOffset={daysOffset}
          />
        ))}

        {Array.from({ length: nbDays }).map((v, index) => (
          <CalendarCell
            key={index}
            rental={rental}
            daysOffset={daysOffset}
            index={index}
            onClick={onDateClick}
          />
        ))}

        {Array.from({ length: CALENDAR_NB_DAYS_AFTER }).map((v, index) => (
          <CalendarCellSkeleton
            key={index}
            index={index}
            daysOffset={daysOffset}
          />
        ))}

        {rental.reservations?.map((reservation, index) => {
          return (
            <CalendarReservationItem
              key={index}
              from={from}
              mode={displayBy}
              reservation={reservation}
              onClick={onReservationClick}
            />
          );
        })}
      </tr>
      {restrictionVisible && (
        <>
          <CalendarResctrictionLine
            label={t("Calendar.price")}
            rental={rental}
            restrictionField="price"
            postfix=" €"
            nbDays={nbDays}
            daysOffset={daysOffset}
            onClick={(rental, date, restriction) =>
              onDateClick(
                rental,
                date,
                1,
                restriction.price,
                restriction.unavailability_reason,
                restriction.unavailability_reason_other,
              )
            }
          />

          <CalendarResctrictionLine
            label={t("Calendar.minNights")}
            rental={rental}
            restrictionField="min_nights"
            nbDays={nbDays}
            daysOffset={daysOffset}
            toolTip={{ i18nKeyLabel: "Calendar.minNightsTooltip", width: 335 }}
            onClick={(rental, date, restriction) =>
              onMinNightsClick(rental, date, restriction.min_nights?.toString())
            }
          />

          <CalendarResctrictionLine
            label={t("Calendar.maxNights")}
            rental={rental}
            restrictionField="max_nights"
            nbDays={nbDays}
            daysOffset={daysOffset}
            toolTip={{ i18nKeyLabel: "Calendar.maxNightsTooltip", width: 450 }}
            onClick={(rental, date, restriction) =>
              onMaxNightsClick(rental, date, restriction.max_nights?.toString())
            }
          />

          <CalendarResctrictionLine
            label={t("Calendar.noCheckin")}
            rental={rental}
            restrictionField="no_checkin"
            nbDays={nbDays}
            daysOffset={daysOffset}
            toolTip={{ i18nKeyLabel: "Calendar.noCheckinTooltip", width: 455 }}
            onClick={(rental, date, restriction) =>
              onNoCheckinCheckoutClick(
                rental,
                date,
                restriction.no_checkin,
                restriction.no_checkout,
              )
            }
          />

          <CalendarResctrictionLine
            label={t("Calendar.noCheckout")}
            rental={rental}
            restrictionField="no_checkout"
            nbDays={nbDays}
            daysOffset={daysOffset}
            toolTip={{ i18nKeyLabel: "Calendar.noCheckoutTooltip", width: 425 }}
            onClick={(rental, date, restriction) =>
              onNoCheckinCheckoutClick(
                rental,
                date,
                restriction.no_checkin,
                restriction.no_checkout,
              )
            }
          />
        </>
      )}
    </tbody>
  );
};
