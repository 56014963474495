import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ArrowLeftIcon from "../../../assets/icons/arrow-left.svg?react";
import ArrowRightWhiteIcon from "../../../assets/icons/arrow-right-white.svg?react";
import EmojiHappyIcon from "../../../assets/icons/emoji-happy.svg?react";
import HomeGreenIcon from "../../../assets/icons/home-success.svg?react";
import PlusIcon from "../../../assets/icons/plus.svg?react";
import UsersWarningIcon from "../../../assets/icons/users-warning.svg?react";
import UsersIcon from "../../../assets/icons/users.svg?react";
import countries from "../../../constants/countries";
import paths from "../../../constants/paths";
import { post } from "../../../helpers/APIHelper";
import { RentalBedRoomResponse } from "../../../types/GETTypes";
import { Button } from "../../Common/Button/Button";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import { AddRentalType } from "../AddRental.type";
import { AddRentalRoomsNoRoom } from "./NoRooms";
import { UpsertRoomModal } from "./UpsertRoomModal";

export const AddRentalRooms: React.FC<{
  rental: AddRentalType | undefined;
  onCancel: () => void;
  onNext: (rooms: RentalBedRoomResponse[]) => void;
  onBack: () => void;
}> = ({ rental, onCancel, onNext, onBack }) => {
  const { t } = useTranslation();

  const [currentRooms, setCurrentRooms] = useState<RentalBedRoomResponse[]>([]);

  const [isAddRoomVisible, setIsAddRoomVisible] = useState<boolean>(false);
  const [currentRoomIndexToEdit, setCurrentRoomIndexToEdit] = useState<
    number | undefined
  >(undefined);
  const [currentRoomToEdit, setCurrentRoomToEdit] = useState<
    RentalBedRoomResponse | undefined
  >(undefined);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (rental) {
      setCurrentRooms(rental?.rooms ?? []);
    }
  }, [rental]);

  const handleAddRoomVisible = () => {
    setIsAddRoomVisible(true);
  };

  const handleAddRoom = (values: RentalBedRoomResponse) => {
    setIsAddRoomVisible(false);
    setCurrentRooms([...currentRooms, values]);
    setCurrentRoomToEdit(undefined);
    setCurrentRoomIndexToEdit(undefined);

    setError(null);
  };

  const handleEditRoom = (values: RentalBedRoomResponse) => {
    setIsAddRoomVisible(false);

    let nextRooms = currentRooms;
    nextRooms[currentRoomIndexToEdit!] = values;

    setCurrentRooms(nextRooms);

    setCurrentRoomToEdit(undefined);
    setCurrentRoomIndexToEdit(undefined);

    setError(null);
  };

  const handleRemoveRoom = () => {
    setIsAddRoomVisible(false);

    let nextRooms = currentRooms;
    nextRooms.splice(currentRoomIndexToEdit!, 1);

    setCurrentRooms(nextRooms);

    setCurrentRoomToEdit(undefined);
    setCurrentRoomIndexToEdit(undefined);
  };

  const handleCloseAddRoomModal = () => {
    setIsAddRoomVisible(false);
    setCurrentRoomToEdit(undefined);
    setCurrentRoomIndexToEdit(undefined);
  };

  const handleClickRoom = (index: number) => {
    setCurrentRoomToEdit(currentRooms[index]);
    setCurrentRoomIndexToEdit(index);
    setIsAddRoomVisible(true);
  };

  const handleNextStep = async () => {
    setLoading(true);
    setError(null);

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTAL_ADD_CHECK_ROOMS}`,
      {
        name: rental?.infos?.name,
        rental_category_id: rental?.infos?.rentalCategory,
        rental_type_id: rental?.infos?.rentalType,
        renting_type: rental?.infos?.rentingType,
        management_type: rental?.infos?.managementType,
        address: rental?.infos?.address,
        postal_code: rental?.infos?.postal_code,
        city: rental?.infos?.city,
        longitude: rental?.infos?.longitude,
        latitude: rental?.infos?.latitude,
        country_code: countries.find(
          (country) => country.value === rental?.infos?.country
        )?.code,
        guests_max_capacity: rental?.infos?.maxGuests,
        adults_max_capacity: rental?.infos?.maxAdults,
        children_max_capacity: rental?.infos?.maxChildren,
        bathrooms_count: rental?.infos?.bathrooms,
        bedrooms: currentRooms.map((r) => JSON.stringify(r)),
      }
    );

    if (res.data?.success) {
      onNext(currentRooms);
    } else {
      setError(res.response.data.message);
    }

    setLoading(false);
  };

  return (
    <>
      <UpsertRoomModal
        isVisible={isAddRoomVisible}
        onClose={handleCloseAddRoomModal}
        isFirstRoom={currentRooms?.length === 0 || currentRoomIndexToEdit === 0}
        room={currentRoomToEdit}
        onAdd={handleAddRoom}
        onEdit={handleEditRoom}
        onRemove={handleRemoveRoom}
        nbRooms={currentRooms.length + 1}
      />

      <div className="flex flex-col w-full gap-2 text-sm">
        <div className="flex justify-start pb-12">
          <div className="w-44">
            <Button
              type="secondary"
              disabled={loading}
              LeftIcon={ArrowLeftIcon}
              onClick={onBack}
            >
              {t("Global.previousStep")}
            </Button>
          </div>
        </div>

        <p className="text-xl font-semibold text-high-contrast">
          {t("AddRental.Rooms.title")}
        </p>

        <p className="mt-4 mb-6 font-light text-low-contrast">
          {t("AddRental.Rooms.subTitle")}
        </p>

        {!currentRooms ||
          (currentRooms?.length === 0 && (
            <AddRentalRoomsNoRoom onAdd={handleAddRoomVisible} />
          ))}

        {currentRooms?.length >= 0 && (
          <div className="flex flex-col gap-2">
            {currentRooms.map((room, index) => (
              <div
                className="p-4 cursor-pointer bg-element-background border-1 border-element-border rounded-6px"
                onClick={() => handleClickRoom(index)}
              >
                <p className="font-semibold text-high-contrast">{room.name}</p>

                <div className="flex flex-row mt-1">
                  {/* Beds */}
                  <div className="flex flex-col font-light w-52 text-low-contrast">
                    <p>
                      {room.king_beds_count > 0 &&
                        t("Global.nbKingBeds", { count: room.king_beds_count })}
                    </p>
                    <p>
                      {room.queen_beds_count > 0 &&
                        t("Global.nbQueenBeds", {
                          count: room.queen_beds_count,
                        })}
                    </p>
                    <p>
                      {room.double_beds_count > 0 &&
                        t("Global.nbDoubleBeds", {
                          count: room.double_beds_count,
                        })}
                    </p>
                    <p>
                      {room.single_beds_count > 0 &&
                        t("Global.nbSingleBeds", {
                          count: room.single_beds_count,
                        })}
                    </p>
                    <p>
                      {room.children_beds_count > 0 &&
                        t("Global.nbChildrenBeds", {
                          count: room.children_beds_count,
                        })}
                    </p>
                    <p>
                      {room.infants_beds_count > 0 &&
                        t("Global.nbInfantsBeds", {
                          count: room.infants_beds_count,
                        })}
                    </p>
                    <p>
                      {room.sofa_beds_count > 0 &&
                        t("Global.nbSofaBeds", { count: room.sofa_beds_count })}
                    </p>
                    <p>
                      {room.wall_beds_count > 0 &&
                        t("Global.nbWallBeds", { count: room.wall_beds_count })}
                    </p>
                    <p>
                      {room.mezannine_beds_count > 0 &&
                        t("Global.nbMezzanineBeds", {
                          count: room.mezannine_beds_count,
                        })}
                    </p>
                  </div>

                  {/* People */}
                  <div className="flex flex-1 gap-1 font-light text-low-contrast">
                    <UsersIcon className="w-4 h-4" />
                    <p>{t("Global.adults", { count: room.adults_count })}</p>
                    <EmojiHappyIcon className="w-4 h-4" />
                    <p>
                      {t("Global.children", { count: room.children_count })}
                    </p>
                  </div>

                  {/* Room type */}
                  <div>
                    {room.private_room && (
                      <div className="flex items-center gap-1 p-2 font-semibold bg-element-background-success text-success rounded-6px">
                        <HomeGreenIcon className="w-4 h-4" />
                        {t("AddRental.Rooms.private")}
                      </div>
                    )}

                    {!room.private_room && (
                      <div className="flex items-center gap-1 p-2 font-semibold bg-element-background-warning text-warning rounded-6px">
                        <UsersWarningIcon className="w-4 h-4" />
                        {t("AddRental.Rooms.shared")}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}

            <Button
              type="secondary"
              RightIcon={PlusIcon}
              onClick={handleAddRoomVisible}
            >
              {t("AddRental.Rooms.noRoomButton")}
            </Button>
          </div>
        )}

        <ErrorMessage>{error}</ErrorMessage>

        <div className="flex gap-4 pb-4 mt-8">
          <Button type="secondary" onClick={onCancel} disabled={loading}>
            {t("Global.cancel")}
          </Button>
          <Button
            RightIcon={ArrowRightWhiteIcon}
            loading={loading}
            onClick={handleNextStep}
            disabled={currentRooms?.length == 0}
          >
            {t("AddReservation.Infos.nextStep")}
          </Button>
        </div>
      </div>
    </>
  );
};
