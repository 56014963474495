import { t } from "i18next";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  ADD_RESERVATION_STEPS,
  AddReservationCalendarType,
  AddReservationGeneralType,
  AddReservationGuestType,
  AddReservationTarificationType,
  AddReservationType,
} from "../../components/AddReservation/AddReservation.type";
import { AddReservationCloseModal } from "../../components/AddReservation/CloseModal";
import { AddReservationDone } from "../../components/AddReservation/Done/AddReservationDone";
import { AddReservationGuest } from "../../components/AddReservation/Guest/AddReservationGuest";
import { AddReservationInfos } from "../../components/AddReservation/Infos/AddReservationInfos";
import { AddReservationInfosForm } from "../../components/AddReservation/Infos/AddReservationInfos.type";
import { AddReservationPayment } from "../../components/AddReservation/Payment/AddReservationPayment";
import { AddReservationPaymentModal } from "../../components/AddReservation/Payment/AddReservationPaymentModal";
import { AddReservationPrices } from "../../components/AddReservation/Prices/AddReservationPrices";
import { AddReservationPricesModal } from "../../components/AddReservation/Prices/AddReservationPricesModal";
import { AddReservationSummary } from "../../components/AddReservation/Summary/AddReservationSummary";
import { FullScreenLayout } from "../../components/Layout/FullScreenLayout/FullScreenLayout";
import {
  PaymentAccount,
  PaymentPolicyForm,
  PaymentPolicyPaymentTriggerEnum,
  PaymentPolicyPaymentTriggerItem,
  PaymentPolicyResponse,
  PaymentPolicyValueTypeEnum,
} from "../../components/Payment/Payment.type";
import paths from "../../constants/paths";
import { post } from "../../helpers/APIHelper";
import { formatTime } from "../../helpers/dateHelper";
import { useGetMultipleRestriction } from "../../hooks/api/calendar";
import { useFetchPaymentAccounts } from "../../hooks/api/paymentAccounts";
import { useFetchAllRental } from "../../hooks/api/rental";
import { useModal } from "../../hooks/useModal";
import {
  MultipleRestrictionsGet,
  NotificationStatusesListItemResponse,
  PaymentReservationScheduleResponse,
  RentalLightListItemResponse,
} from "../../types/GETTypes";

export const AddReservationPage: React.FC<{}> = () => {
  const [closeVisible, setCloseVisible] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<ADD_RESERVATION_STEPS>(
    ADD_RESERVATION_STEPS.INFORMATIONS
  );

  const [hasSelectedGuest, setHasSelectedGuest] = useState(false);

  const [currentReservation, setCurrentReservation] =
    useState<AddReservationType>();

  const handlePreviousStep = () => {
    setCurrentStep((previousStep) => previousStep - 1);
  };

  const handleNextStepInfos = ({
    rental,
    general,
    tarification,
    calendar,
  }: {
    rental: RentalLightListItemResponse;
    general: AddReservationGeneralType;
    tarification: AddReservationTarificationType;
    calendar: AddReservationCalendarType;
  }) => {
    setCurrentReservation({
      ...currentReservation,
      rental,
      general,
      tarification,
      calendar,
    });

    setCurrentStep(ADD_RESERVATION_STEPS.PRICES);
  };

  // * -- INFOS --
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [restrictions, setRestrictions] = useState<
    MultipleRestrictionsGet[] | undefined
  >();

  const navigate = useNavigate();

  const addReservationInfosForm = useForm<AddReservationInfosForm>({
    mode: "all",
    defaultValues: {
      rental: "",
      type: 54,
      adults: 0,
      children: 0,
      dates: [new Date(), moment(new Date()).add(1, "days").toDate()],
      checkinTime: "",
      checkoutTime: "11:00",
    },
  });

  const [rentalsAccess, setRentalsAccess] = useState<
    RentalLightListItemResponse[]
  >([]);

  const [rentalChoiced, setRentalChoiced] =
    useState<RentalLightListItemResponse | null>(null);

  /**
   * Fetches all rental data and manages the state of rentals and restrictions.
   *
   * This function utilizes the `useFetchAllRental` hook to retrieve rental data.
   * On successful data retrieval, it extracts rental IDs and fetches multiple restrictions
   * associated with those rentals. It also manages loading and error states throughout the process.
   *
   * @returns {void}
   */
  const fetchRentals = useCallback(() => {
    useFetchAllRental({
      queryParams: {
        limit: -1,
      },
      onSuccess: (data) => {
        setRentalsAccess(data.rentals_access);

        let rentalIds: string[] = [];
        if (data.rentals_access && data.rentals_access.length > 0) {
          data.rentals_access.map((rental) =>
            rentalIds.push(rental.id.toString())
          );

          if (rentalIds.length > 0) {
            useGetMultipleRestriction({
              rentalIds: rentalIds,
              onSuccess: (restrictions) => {
                setRestrictions(restrictions);
              },
              onError: (message) => setError(message),
              onStart: () => {
                setError(null);
                setLoading(true);
              },
              onEnd: () => {
                setLoading(false);
              },
            });
          }
        }
      },
      onError: (message) => setError(message),
      onStart: () => {
        setError(null);
        setLoading(true);
      },
      onEnd: () => {
        setLoading(false);
      },
    });
  }, [setRestrictions, setError, setLoading]);

  useEffect(() => {
    if (!rentalChoiced) {
      fetchRentals();
    }
  }, [rentalChoiced]);

  useEffect(() => {
    if (
      !rentalChoiced &&
      currentReservation?.general &&
      currentReservation?.rental
    ) {
      addReservationInfosForm.setValue(
        "rental",
        currentReservation?.rental?.id
      );
      addReservationInfosForm.setValue(
        "type",
        currentReservation?.general.platform_id
      );
      addReservationInfosForm.setValue(
        "adults",
        Number(currentReservation?.rental.adults_max_capacity)
      );
      addReservationInfosForm.setValue(
        "children",
        Number(currentReservation?.rental.children_max_capacity)
      );
      addReservationInfosForm.setValue("dates", [
        currentReservation?.general.checkin,
        currentReservation?.general.checkout,
      ]);
      addReservationInfosForm.setValue(
        "checkinTime",
        formatTime(currentReservation?.general.time_in)
      );
      addReservationInfosForm.setValue(
        "checkoutTime",
        formatTime(currentReservation?.general.time_out)
      );
    }
  }, [rentalChoiced, currentReservation]);

  const setInAndOutTime = (rental: RentalLightListItemResponse) => {
    addReservationInfosForm.setValue(
      "checkinTime",
      formatTime(rental.checkin_min_time)
    );
    addReservationInfosForm.setValue(
      "checkoutTime",
      formatTime(rental.checkout_max_time)
    );
  };

  const handleSelectDefaultRental = (rental: RentalLightListItemResponse) => {
    setRentalChoiced(rental);
    setInAndOutTime(rental);
  };

  useEffect(() => {
    if (rentalsAccess?.length > 0) {
      if (
        !addReservationInfosForm.getValues("rental") ||
        addReservationInfosForm.getValues("rental") === ""
      ) {
        const rental: RentalLightListItemResponse = rentalsAccess[0];

        addReservationInfosForm.setValue("rental", rental?.id);
        addReservationInfosForm.setValue("adults", rental.adults_max_capacity);
        addReservationInfosForm.setValue(
          "children",
          rental.children_max_capacity
        );

        handleSelectDefaultRental(rental);

        setInitialLoading(false);
      } else {
        addReservationInfosForm.setValue(
          "rental",
          addReservationInfosForm.getValues("rental")
        );
        addReservationInfosForm.setValue(
          "adults",
          addReservationInfosForm.getValues("adults")
        );
        addReservationInfosForm.setValue(
          "children",
          addReservationInfosForm.getValues("children")
        );
      }
    }
  }, [rentalsAccess]);

  // We check if one rental exists
  useEffect(() => {
    if (!initialLoading && rentalsAccess.length === 0) {
      navigate(-1);
    }
  }, [initialLoading, rentalsAccess]);

  const handleNextStep = async (values: AddReservationInfosForm) => {
    setError(null);

    let isError = false;
    const index = rentalsAccess.findIndex(
      (rental) => rental.id === values.rental
    );
    const currentRental = rentalsAccess[index];

    if (values.adults > currentRental.adults_max_capacity) {
      isError = true;
      addReservationInfosForm.setError("adults", {
        message: t("AddReservation.Infos.Error.maxAdults", {
          count: currentRental.adults_max_capacity,
        }),
      });
    }

    if (values.children > currentRental.children_max_capacity) {
      isError = true;
      addReservationInfosForm.setError("children", {
        message: t("AddReservation.Infos.Error.maxChildren", {
          count: currentRental.children_max_capacity,
        }),
      });
    }

    if (values.adults + values.children === 0) {
      isError = true;
      addReservationInfosForm.setError("adults", {
        message: t("AddReservation.Infos.Error.minGuest"),
      });
    }

    if (isError) {
      return;
    }

    setLoading(true);

    const res = await post(
      `${import.meta.env.VITE_API_URL}${
        paths.API.RESERVATION_ADD_CHECK_AVAILABILITY
      }`,
      {
        rental_id: values.rental.toString(),
        platform_id: values.type,
        checkin: moment(values.dates[0]).format("YYYY-MM-DD"),
        checkout: moment(values.dates[1]).format("YYYY-MM-DD"),
        time_in: formatTime(values.checkinTime),
        time_out: formatTime(values.checkoutTime),
        adults_count: values.adults,
        children_count: values.children,
      }
    );

    if (res?.data?.success) {
      const result = res.data.result;

      handleNextStepInfos({
        rental: result.rental,
        general: {
          ...result.general_informations,
          checkin: moment(result.general_informations.checkin).toDate(),
          checkout: moment(result.general_informations.checkout).toDate(),
        },
        tarification: {
          nights_total_price: result.tarification.nights_total_price,
          city_tax: result.tarification.city_tax,
          cleaning_default: result.tarification.cleaning_default,
          linen_default: result.tarification.linen_default,
          additional_guests_total_price:
            result.tarification.additional_guests_total_price,
          pet_default: result.tarification.pet_default,
          infant_bed_default: result.tarification.infant_bed_default,
          other: result.tarification.other,
          total: result.tarification.total,
        },
        calendar: result.calendar,
      });

      setLoading(false);
    } else {
      setError(res?.response?.data?.message);
      setLoading(false);
      return;
    }
  };

  // * -- PRICES --
  const handleNextStepPrices = (
    tarification: AddReservationTarificationType
  ) => {
    setCurrentReservation({ ...currentReservation, tarification });

    setCurrentStep(ADD_RESERVATION_STEPS.GUEST);
  };

  const reservationModalPrices = useModal<AddReservationType | undefined>();

  const handleNextStepGuest = (guest: AddReservationGuestType) => {
    setCurrentReservation({ ...currentReservation, guest });

    setHasSelectedGuest(true);
    setCurrentStep(ADD_RESERVATION_STEPS.PAYMENT);
  };

  const handleNextStepPayment = (
    reservation_id: number,
    payment_schedule: PaymentReservationScheduleResponse,
    notification_statuses: NotificationStatusesListItemResponse[]
  ) => {
    setCurrentReservation({
      ...currentReservation,
      reservation_id,
      payment_schedule,
      notification_statuses,
    });

    setCurrentStep(ADD_RESERVATION_STEPS.DONE);
  };

  // * -- PAYMENT --
  const reservationModalPayment = useModal<{}>();

  const paymentPolicyForm = useForm<PaymentPolicyForm>({
    mode: "all",
    defaultValues: {
      name: "",
      description: "",
      is_favorite_policy: false,
      is_single_payment: true,
      payments_policy_items: [
        {
          valueType: PaymentPolicyValueTypeEnum.PERCENT,
          value: 100,
          specificDate: new Date(),
          trigger: PaymentPolicyPaymentTriggerEnum.AT_RESERVATION,
          paymentAccountId: null,
        },
      ],
      is_refundable: false,
      refund_value: null,
      refund_condition: null,
      is_deposit_required: true,
      deposit_value: 100,
      deposit_payment_option: null,
      deposit_payment_account_id: null,
      deposit_payment_days_delay: null,
    },
  });

  const [currentPolicy, setCurrentPolicy] = useState<
    PaymentPolicyResponse | undefined
  >();

  const paymentPolicyItems = useFieldArray({
    control: paymentPolicyForm.control,
    name: "payments_policy_items",
  });

  // useEffect(() => {
  //   console.log("append policy item !!!");
  //   paymentPolicyItems.append({
  //     trigger: PaymentPolicyPaymentTriggerEnum.AT_RESERVATION,
  //     valueType: PaymentPolicyValueTypeEnum.PERCENT,
  //     value: 100,
  //     specificDate: new Date(),
  //     paymentAccountId: null,
  //   });
  // }, []);

  const [paymentAccounts, setPaymentAccounts] = useState<
    PaymentAccount[] | null
  >(null);

  useEffect(() => {
    useFetchPaymentAccounts(
      (paymentAccounts: PaymentAccount[]) =>
        setPaymentAccounts(paymentAccounts),
      // (message: string | undefined) => setError(message),
      () => {
        // setError(undefined);
        // setLoading(true);
      }
      // () => setLoading(false)
    );
  }, []);

  useEffect(() => {
    if (paymentAccounts) {
      paymentPolicyForm.setValue(
        "payments_policy_items",
        currentPolicy?.payments_policy_items.map((pp) => {
          return {
            trigger: pp.trigger as PaymentPolicyPaymentTriggerItem,
            valueType: pp.value_type as PaymentPolicyValueTypeEnum,
            value: pp.value,
            specificDate: pp.specific_date
              ? moment(new Date(pp.specific_date), "DD/MM/YYYY").toDate()
              : moment(new Date(), "DD/MM/YYYY").toDate(),
            paymentAccountId:
              paymentAccounts.find((pa) => pa.id === pp.payment_account?.id)
                ?.id ?? null,
          };
        })!
      );
    }
  }, [currentPolicy, paymentAccounts]);

  return (
    <>
      <AddReservationCloseModal
        isVisible={closeVisible}
        onClose={() => setCloseVisible(false)}
      />

      {currentStep === ADD_RESERVATION_STEPS.PRICES && (
        <AddReservationPricesModal modal={reservationModalPrices} />
      )}

      {currentStep === ADD_RESERVATION_STEPS.PAYMENT && (
        <AddReservationPaymentModal
          modal={reservationModalPayment}
          form={paymentPolicyForm}
          paymentAccounts={paymentAccounts}
          paymentPolicyItems={paymentPolicyItems}
          reservation={currentReservation}
          currentPolicy={currentPolicy}
          onChangeCurrentPolicy={(currentPolicy) =>
            setCurrentPolicy(currentPolicy)
          }
          onNext={(nextPaymentPolicy) => setCurrentPolicy(nextPaymentPolicy)}
        />
      )}

      <FullScreenLayout onClose={() => setCloseVisible(true)}>
        {/* Left */}
        <div className="w-2/5 max-w-md p-2 pb-0">
          <AddReservationSummary
            reservation={currentReservation}
            currentStep={currentStep}
          />
        </div>

        {/* Right */}
        <div className="flex-1 px-12 pt-8">
          {currentStep === ADD_RESERVATION_STEPS.INFORMATIONS && (
            <AddReservationInfos
              loading={initialLoading || loading}
              form={addReservationInfosForm}
              rentalsAccess={rentalsAccess}
              restrictions={restrictions}
              rentalChoiced={rentalChoiced}
              error={error}
              onSelectDefaultRental={handleSelectDefaultRental}
              onCancel={() => setCloseVisible(true)}
              onNext={handleNextStep}
            />
          )}

          {currentStep === ADD_RESERVATION_STEPS.PRICES && (
            <AddReservationPrices
              reservation={currentReservation!}
              onNext={handleNextStepPrices}
              onCancel={() => setCloseVisible(true)}
              onBack={handlePreviousStep}
              onOpeningPricesModal={() =>
                reservationModalPrices.open(currentReservation)
              }
            />
          )}

          {currentStep === ADD_RESERVATION_STEPS.GUEST && (
            <AddReservationGuest
              hasSelectedGuest={hasSelectedGuest}
              reservation={currentReservation!}
              onNext={handleNextStepGuest}
              onCancel={() => setCloseVisible(true)}
              onBack={handlePreviousStep}
            />
          )}

          {currentStep === ADD_RESERVATION_STEPS.PAYMENT && (
            <AddReservationPayment
              modal={reservationModalPayment}
              submitError={
                Object.values(paymentPolicyForm.formState.errors).length > 0
                  ? t("AddReservation.Payment.paymentPolicyFormError")
                  : ""
              }
              paymentPolicyFormValues={paymentPolicyForm.getValues}
              reservation={currentReservation!}
              currentPolicy={currentPolicy}
              onNext={handleNextStepPayment}
              onCancel={() => setCloseVisible(true)}
              onBack={handlePreviousStep}
            />
          )}

          {currentStep === ADD_RESERVATION_STEPS.DONE && (
            <AddReservationDone reservation={currentReservation!} />
          )}
        </div>
      </FullScreenLayout>
    </>
  );
};
