import React, {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import ArrowLeftIcon from "../../../../assets/icons/arrow-left.svg?react";
import CheckGreenIcon from "../../../../assets/icons/check-circle-green.svg?react";
import CheckIcon from "../../../../assets/icons/check-white.svg?react";
import CloseCirclecon from "../../../../assets/icons/close-circle.svg?react";
import EditIcon from "../../../../assets/icons/edit.svg?react";
import WriteNoteIcon from "../../../../assets/icons/write-note.svg?react";
import CarteIdentiteImage from "../../../../assets/images/carteidentite.svg";
import CBImage from "../../../../assets/images/cb.svg";
import PasseportImage from "../../../../assets/images/passeport.svg";
import SelfieImage from "../../../../assets/images/selfie.svg";
import FileCheckmark from "../../../../assets/icons/file-checkmark.svg?react";
import {
  useDisableGuestPage,
  useEnableGuestPage,
  useUpdateFormGuestPage,
} from "../../../../hooks/api/guestPage";
import {useRentalPageContext} from "../../../../pages/RentalPage/RentalPageContext";
import {Badge} from "../../../Common/Badge/Badge";
import {BadgeStatus} from "../../../Common/Badge/Badge.type";
import {Card} from "../../../Common/Card/Card";
import {ErrorMessage} from "../../../Common/ErrorMessage/ErrorMessage";
import {Switch} from "../../../Common/Switch/Switch";
import {Tooltip} from "../../../Common/Tooltip/Tooltip";
import {FormCardSkeleton} from "./FormCardSkeleton";
import useUserStore from "../../../../stores/useUserStore";
import {getCurrentCanEdit} from "../../../../helpers/workspaceHelper";
import {ValueType} from "../../../../types/commonTypes";
import {
  RentalGuestPageResponse,
  RentalLightListItemResponse,
  RentalResponse,
  UserResponse,
} from "../../../../types/GETTypes";
import {Rental} from "../../../../pages/RentalPage/RentalPage.type";
import {TextInput} from "../../../Common/TextInput/TextInput";
import {cn} from "../../../../helpers/classHelper";

export interface FormCardProps {
  loading: boolean;
  rental: RentalResponse;
  rentals: RentalLightListItemResponse[] | undefined;
  guest_page: RentalGuestPageResponse;
  workspaceOwner: ValueType;
  user: UserResponse | undefined;
}
export const FormCard: React.FC<FormCardProps> = ({
  loading,
  rental,
  guest_page,
  workspaceOwner,
  user,
}) => {
  const userStore = useUserStore();
  const {t} = useTranslation();
  const {onUpdateRental} = useRentalPageContext();

  const [validationLoading, setValidationLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [data, setData] = useState(guest_page);
  const [activeForm, setActiveForm] = useState<boolean>(
    guest_page?.form_enabled === 1 ? true : false
  );
  const [loadingActivateForm, setLoadingActivateForm] =
    useState<boolean>(false);
    const additionnalQuestion = guest_page?.additional_question
    ? JSON.parse(String(guest_page?.additional_question))
    : "";
  const {
    handleSubmit,
    control,
    formState: {isValid, errors},
    getValues,
    setValue
  } = useForm({
    mode: "all",
    defaultValues: {
      additional_question: {
        fr: additionnalQuestion?.["fr"],
        de: "",
        en: "",
        es: "",
      },
    },
  });

  useEffect(() => {
    setValue('additional_question.fr',additionnalQuestion?.["fr"])
  },[guest_page])

  const initSubmit = () => {
    setError("");
    setValidationLoading(true);
  };

  const handleUpdateForm = () => {
    setValidationLoading(true);
    const allStatus = {
      id_card_enabled: data?.id_card_enabled ? 1 : 0,
      passport_enabled: data?.passport_enabled ? 1 : 0,
      selfie_enabled: data?.selfie_enabled ? 1 : 0,
      cb_enabled: data?.cb_enabled ? 1 : 0,
      // police_record_enabled: data?.police_record_enabled ? 1 : 0,
      additional_question: JSON.stringify(getValues("additional_question")),
    };
    useUpdateFormGuestPage(
      Number(rental.id),
      allStatus,
      (response) => {
        setValidationLoading(false);
        console.log(response, "response");
        onUpdateRental(response as any);
        setEditMode(false);
      },
      (error) => {
        setValidationLoading(false);
        setError(error);
      }
    );
  };

  const handleActiveForm = () => {
    setLoadingActivateForm(true)
    if (activeForm) {
      setActiveForm(false);
      useDisableGuestPage(
        String(rental.id),
        (response) => {},
        (error) => {
          console.log("🚀 ~ handleActiveForm ~ error:", error);
          setActiveForm(true);
        }
      );
    } else {
      setActiveForm(true);
      useEnableGuestPage(
        String(rental.id),
        (response) => {
          setActiveForm(Boolean(response.guest_page?.form_enabled));
        },
        (error) => {
          console.log("error", error);
          setActiveForm(false);
        }
      );
    }
    setLoadingActivateForm(false)
  };

  

  if (loading) return <FormCardSkeleton />;

  return (
    <Card
      loading={validationLoading}
      Icon={WriteNoteIcon}
      label={t("Rental.Infos.TravelerPage.FormCard.title")}
      anchor="form-card"
      switchButton={{
        label: t("Rental.Infos.TravelerPage.activeTravelerPage"),
        value: Boolean(activeForm),
        loading: loadingActivateForm,
        onClick: () => {
          handleActiveForm();
        },
        disabled: !getCurrentCanEdit({
          user,
          workspaceOwner: rental?.workspace_owner,
        }),
      }}
      button={{
        Icon: editMode ? CheckIcon : EditIcon,
        type: editMode ? "primary" : "secondary",
        label: editMode ? t("Global.record") : t("Global.edit"),
        disabled: !getCurrentCanEdit({user: userStore.user, workspaceOwner}),
        onClick: editMode
          ? handleSubmit(() => {
              handleUpdateForm();
            })
          : () => {
              setEditMode(!editMode);
              /** Fix reset values to inital values */
              setData(guest_page);
            },
      }}
      secondaryButton={
        editMode
          ? {
              label: t("Global.cancel"),
              LeftIcon: ArrowLeftIcon,
              onClick: () => setEditMode(false),
            }
          : undefined
      }
    >
      <div className="flex flex-col gap-y-4">
        {error ? <ErrorMessage>{error}</ErrorMessage> : null}
        {editMode ? (
          <div className="flex flex-col gap-5">
            <p className="text-low-contrast text-xs">
              {t("Rental.Infos.TravelerPage.FormCard.info1")}
            </p>
            <p
              className={cn(
                "text-low-contrast text-xs",
                editMode ? "font-bold text-high-contrast" : ""
              )}
            >
              {t("Rental.Infos.TravelerPage.FormCard.info2")}
            </p>
            <p className="text-low-contrast text-xs">
              {t("Rental.Infos.TravelerPage.FormCard.info3")}
            </p>
            <div className="flex flex-row justify-between">
              <div className="flex flex-row space-x-4 items-center">
                <img
                  src={CarteIdentiteImage}
                  className="h-12 w-20 border-2 border-element-border/50 rounded-6px object-cover object-center bg-element-background"
                />
                <p className="font-bold">
                  {t(
                    "Rental.Infos.TravelerPage.RessourceCard.national_identity_card"
                  )}
                </p>
              </div>
              <Switch
                value={Boolean(data?.id_card_enabled) ?? false}
                onClick={() => {
                  setData({
                    ...data,
                    id_card_enabled: Number(!data?.id_card_enabled),
                  });
                }}
              />
            </div>
            <div className="flex flex-row justify-between">
              <div className="flex flex-row space-x-4 items-center">
                <img
                  src={PasseportImage}
                  className="h-12 w-20 border-2 border-element-border/50 rounded-6px object-cover object-center bg-element-background"
                />
                <p className="font-bold">
                  {t("Rental.Infos.TravelerPage.RessourceCard.passport")}{" "}
                </p>
              </div>
              <Switch
                value={Boolean(data?.passport_enabled) ?? false}
                onClick={() => {
                  setData({
                    ...data,
                    passport_enabled: Number(!data?.passport_enabled),
                  });
                }}
              />
            </div>
            <div className="flex flex-row justify-between">
              <div className="flex flex-row space-x-4 items-center">
                <img
                  src={SelfieImage}
                  className="h-12 w-20 border-2 border-element-border/50 rounded-6px object-cover object-center bg-element-background"
                />
                <p className="font-bold">
                  {t("Rental.Infos.TravelerPage.RessourceCard.selfie_photo")}
                </p>
              </div>
              <Switch
                value={Boolean(data?.selfie_enabled) ?? false}
                onClick={() => {
                  setData({
                    ...data,
                    selfie_enabled: Number(!data?.selfie_enabled),
                  });
                }}
              />
            </div>
            <div className="flex flex-row justify-between">
              <div className="flex flex-row space-x-4 items-center">
                <img
                  src={CBImage}
                  className="h-12 w-20 border-2 border-element-border/50 rounded-6px object-cover object-center bg-element-background"
                />
                <p className="font-bold">
                  {t("Rental.Infos.TravelerPage.RessourceCard.cb")}
                </p>
              </div>

              <Switch
                value={Boolean(data?.cb_enabled) ?? false}
                onClick={() => {
                  setData({...data, cb_enabled: Number(!data?.cb_enabled)});
                }}
              />
            </div>
            <div className="flex flex-row justify-between">
              <Controller
                control={control}
                name="additional_question.fr"
                render={({field: {value, onChange}}) => {
                  return (
                    <TextInput
                      disabled={validationLoading}
                      label={t(
                        "Rental.Infos.TravelerPage.FormCard.additionnalQuestionLabel"
                      )}
                      required={true}
                      value={value ?? ""}
                      onChangeText={(message) => {
                        onChange(message);
                      }}
                      error={errors.additional_question?.fr?.message || ""}
                    />
                  );
                }}
              />
            </div>
            {/* <div className="flex flex-row justify-between">
                            <p className="font-bold">{t("Rental.Infos.TravelerPage.RessourceCard.police_record")}</p>
                            <Switch value={data?.police_record_enabled ?? false} onClick={() => {
                                setData({...data, police_record_enabled: !data?.police_record_enabled})
                            }}/>
                        </div> */}
          </div>
        ) : (
          <div className="flex flex-col gap-4">
            {activeForm ? (
              <>
                <p className="text-low-contrast text-xs">
                  {t("Rental.Infos.TravelerPage.FormCard.info1")}
                </p>
                <p className="text-low-contrast text-xs">
                  {t("Rental.Infos.TravelerPage.FormCard.info2")}
                </p>
                <p className="text-low-contrast text-xs">
                  {t("Rental.Infos.TravelerPage.FormCard.info3")}
                </p>
              </>
            ) : (
              <>
                <p className="text-low-contrast text-xs font-bold">
                  {t("Rental.Infos.TravelerPage.FormCard.info4")}
                </p>
                <p className="text-low-contrast text-xs">
                  {t("Rental.Infos.TravelerPage.FormCard.info5")}
                </p>
                <p className="text-low-contrast text-xs">
                  {t("Rental.Infos.TravelerPage.FormCard.info6")}
                </p>
                <p className="text-high-contrast font-bold text-xs">
                  {t("Rental.Infos.TravelerPage.FormCard.info2")}
                </p>
                <p className="text-low-contrast text-xs">
                  {t("Rental.Infos.TravelerPage.FormCard.info3")}
                </p>
              </>
            )}
            <div className="flex flex-row justify-between">
              <div className="flex flex-row space-x-4 items-center">
                <img
                  src={CarteIdentiteImage}
                  className="h-12 w-20 border-2 border-element-border/50 rounded-6px object-cover object-center bg-element-background"
                />
                <p className="font-bold">
                  {t(
                    "Rental.Infos.TravelerPage.RessourceCard.national_identity_card"
                  )}
                </p>
              </div>
              <Tooltip
                Icon={
                  <Badge
                    label={
                      guest_page.id_card_enabled
                        ? t("Rental.Infos.TravelerPage.requested")
                        : t("Rental.Infos.TravelerPage.notRequest")
                    }
                    status={
                      (guest_page?.id_card_enabled ? "success" : "failed") as
                        | BadgeStatus
                        | undefined
                    }
                    size="large"
                    customColor={
                      !guest_page?.id_card_enabled && "text-gray-800"
                    }
                    customBg={!guest_page?.id_card_enabled && "bg-gray-200"}
                    Icon={
                      !guest_page?.id_card_enabled
                        ? CloseCirclecon
                        : CheckGreenIcon
                    }
                  />
                }
                value={
                  guest_page?.id_card_enabled
                    ? t("Rental.Infos.TravelerPage.requested")
                    : t("Rental.Infos.TravelerPage.notRequest")
                }
                classNames={{
                  container: "top-full mt-1 right-0",
                }}
              />
            </div>
            <div className="flex flex-row justify-between">
              <div className="flex flex-row space-x-4 items-center">
                <img
                  src={PasseportImage}
                  className="h-12 w-20 border-2 border-element-border/50 rounded-6px object-cover object-center bg-element-background"
                />
                <p className="font-bold">
                  {t("Rental.Infos.TravelerPage.RessourceCard.passport")}
                </p>
              </div>

              <Tooltip
                Icon={
                  <Badge
                    label={
                      guest_page?.passport_enabled
                        ? t("Rental.Infos.TravelerPage.requested")
                        : t("Rental.Infos.TravelerPage.notRequest")
                    }
                    status={
                      guest_page?.passport_enabled ? "success" : undefined
                    }
                    size="large"
                    customColor={
                      !guest_page?.passport_enabled && "text-gray-800"
                    }
                    customBg={!guest_page?.passport_enabled && "bg-gray-200"}
                    Icon={
                      !guest_page?.passport_enabled
                        ? CloseCirclecon
                        : CheckGreenIcon
                    }
                  />
                }
                value={
                  guest_page?.passport_enabled
                    ? t("Rental.Infos.TravelerPage.requested")
                    : t("Rental.Infos.TravelerPage.notRequest")
                }
                classNames={{
                  container: "top-full mt-1 right-0",
                }}
              />
            </div>
            <div className="flex flex-row justify-between">
              <div className="flex flex-row space-x-4 items-center">
                <img
                  src={SelfieImage}
                  className="h-12 w-20 border-2 border-element-border/50 rounded-6px object-cover object-center bg-element-background"
                />
                <p className="font-bold">
                  {t("Rental.Infos.TravelerPage.RessourceCard.selfie_photo")}
                </p>
              </div>
              <Tooltip
                Icon={
                  <Badge
                    label={
                      guest_page?.selfie_enabled
                        ? t("Rental.Infos.TravelerPage.requested")
                        : t("Rental.Infos.TravelerPage.notRequest")
                    }
                    status={guest_page?.selfie_enabled ? "success" : undefined}
                    size="large"
                    customColor={!guest_page?.selfie_enabled && "text-gray-800"}
                    customBg={!guest_page?.selfie_enabled && "bg-gray-200"}
                    Icon={
                      !guest_page?.selfie_enabled
                        ? CloseCirclecon
                        : CheckGreenIcon
                    }
                  />
                }
                value={
                  guest_page?.selfie_enabled
                    ? t("Rental.Infos.TravelerPage.requested")
                    : t("Rental.Infos.TravelerPage.notRequest")
                }
                classNames={{
                  container: "top-full mt-1 right-0",
                }}
              />
            </div>
            <div className="flex flex-row justify-between">
              <div className="flex flex-row space-x-4 items-center">
                <img
                  src={CBImage}
                  className="h-12 w-20 border-2 border-element-border/50 rounded-6px object-cover object-center bg-element-background"
                />
                <p className="font-bold">
                  {t("Rental.Infos.TravelerPage.RessourceCard.cb")}
                </p>
              </div>
              <Tooltip
                Icon={
                  <Badge
                    label={
                      guest_page.cb_enabled
                        ? t("Rental.Infos.TravelerPage.requested")
                        : t("Rental.Infos.TravelerPage.notRequest")
                    }
                    status={guest_page?.cb_enabled ? "success" : undefined}
                    size="large"
                    customColor={!guest_page?.cb_enabled && "text-gray-800"}
                    customBg={!guest_page?.cb_enabled && "bg-gray-200"}
                    Icon={
                      !guest_page?.cb_enabled ? CloseCirclecon : CheckGreenIcon
                    }
                  />
                }
                value={
                  guest_page?.cb_enabled
                    ? t("Rental.Infos.TravelerPage.requested")
                    : t("Rental.Infos.TravelerPage.notRequest")
                }
                classNames={{
                  container: "top-full mt-1 right-0",
                }}
              />
            </div>
            <div className="flex flex-col justify-between space-y-2">
              <p className="text-low-contrast">
                {t(
                  "Rental.Infos.TravelerPage.FormCard.additionnalQuestionLabel"
                )}
              </p>
              <p className="font-bold">{additionnalQuestion?.["fr"]}</p>
            </div>
            {/* <div className="flex flex-row justify-between">
                            <p className="font-bold">{t("Rental.Infos.TravelerPage.RessourceCard.police_record")}</p>
                            <Tooltip
                                Icon={
                                    <Badge
                                        label={
                                            guest_page?.police_record_enabled
                                                ? t("Rental.Infos.TravelerPage.requested")
                                                : t("Rental.Infos.TravelerPage.notRequest")
                                        }
                                        status={guest_page?.police_record_enabled ? "success" : ""}
                                        size="large"
                                        customColor={!guest_page?.police_record_enabled && "text-gray-800"}
                                        customBg={!guest_page?.police_record_enabled && "bg-gray-200"}
                                        Icon={!guest_page?.police_record_enabled ? CloseCirclecon : CheckGreenIcon}
                                    />
                                }
                                value={
                                    guest_page?.police_record_enabled
                                        ? t("Rental.Infos.TravelerPage.requested")
                                        : t("Rental.Infos.TravelerPage.notRequest")
                                }
                                classNames={{
                                    container: "top-full mt-1 right-0",
                                }}
                            />
                        </div> */}
          </div>
        )}
      </div>
    </Card>
  );
};
