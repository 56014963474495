import React, {useEffect, useState} from "react";
import {
  RentalLightListItemResponse,
  RentalResponse,
} from "../../../types/GETTypes";
import {CenterModal} from "../CenterModal/CenterModal";
import {ErrorMessage} from "../ErrorMessage/ErrorMessage";
import {Button} from "../Button/Button";
import {useTranslation} from "react-i18next";
import CheckWhiteIcon from "../../../assets/icons/check-white.svg?react";
import {IconType, ValueType} from "../../../types/commonTypes";
import {TextInput} from "../TextInput/TextInput";
import SearchIcon from "../../../assets/icons/search.svg?react";
import {InputSelect} from "../InputSelect/InputSelect";
import {PagingButtons} from "../PagingButtons/PagingButtons";
import {get} from "../../../helpers/APIHelper";
import paths from "../../../constants/paths";
import {SimpleBox} from "../../Modal/Airbnb/Connect/AirbnbConnectStepImport";

export interface MultiSelectRentalsCheckboxModalProps {
  isVisible: boolean;
  onClose: () => void;
  onSubmit: (() => Promise<void>) | (() => void);
  selectedRentalsIds: ValueType[];
  setSelectedRentalsIds: React.Dispatch<React.SetStateAction<ValueType[]>>;
  title: string;
  titleButtonSubmit: string;
  Icon?: IconType;
  error?: string;
  disabled?: boolean;
  loading?: boolean;
}

const MultiSelectRentalsCheckboxModal = ({
  isVisible,
  onClose,
  onSubmit,
  selectedRentalsIds,
  setSelectedRentalsIds,
  title,
  titleButtonSubmit,
  Icon = CheckWhiteIcon,
  error,
  disabled = false,
  loading = false,
}: MultiSelectRentalsCheckboxModalProps) => {
  const {t} = useTranslation();
  const [loadingData, setLoadingData] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [rentalsList, setRentalsList] = useState<RentalLightListItemResponse[]>(
    []
  );
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const defaultLimit = 5;
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState<ValueType>(defaultLimit);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const displayUpToItems = [
    {
      label: `5 ${t("Global.rentals", {
        count: totalPage!,
      })?.toLowerCase()}`,
      value: 5,
    },
    {
      label: `10 ${t("Global.rentals", {
        count: totalPage!,
      })?.toLowerCase()}`,
      value: 10,
    },
    {
      label: `15 ${t("Global.rentals", {
        count: totalPage!,
      })?.toLowerCase()}`,
      value: 15,
    },
    {
      label: `20 ${t("Global.rentals", {
        count: totalPage!,
      })?.toLowerCase()}`,
      value: 20,
    },
  ];
  useEffect(() => {
    fetchRentals({});
  }, []);

  const fetchRentals = async ({
    searchParams = search,
    limitParams = limit,
    pageParams = page,
  }: {
    searchParams?: ValueType;
    limitParams?: ValueType;
    pageParams?: ValueType;
  }) => {
    setLoadingData(true);
    const params = {
      page: pageParams,
      limit: limitParams,
      search: searchParams,
    };

    const response = await get(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS}`,
      {params}
    );

    const data = response?.data;
    if (data?.success) {
      const result = data?.result;
      setPage(result.current_page);
      setLastPage(result.last_page);
      setLimit(result.per_page);
      setTotalPage(result.total);
      setRentalsList(result.rentals_access);
      onSelectAll(false, result.rentals_access);
    }
    setLoadingData(false);
  };

  const onSearch = async () => {
    setLoadingSearch(true);
    await fetchRentals({});
    setLoadingSearch(false);
  };

  const handleChangeNbPerPage = (value: ValueType) => {
    const nextLimit = Number(value);
    const nextPage = 1;

    setLimit(nextLimit);
    setPage(nextPage);

    fetchRentals({
      pageParams: nextPage,
      limitParams: nextLimit,
      searchParams: search,
    });
  };

  const handlePaginationNext = () => {
    let next = page + 1;
    if (lastPage && next > lastPage) next = lastPage;
    fetchRentals({
      pageParams: next,
      limitParams: limit,
      searchParams: search,
    });
    setPage(next);
  };

  const handlePaginationBack = () => {
    let next = page - 1;
    if (next < 1) next = 1;

    fetchRentals({
      pageParams: next,
      limitParams: limit,
      searchParams: search,
    });

    setPage(next);
  };

  const onSelect = (id: ValueType, value: boolean) => {
    if (!value) {
      setSelectedRentalsIds((oldSelectedRentalsIds) => [
        ...oldSelectedRentalsIds,
        id,
      ]);
    } else {
      setSelectedRentalsIds((oldSelectedRentalsIds) =>
        oldSelectedRentalsIds?.filter((oldId) => oldId !== id)
      );
    }
  };

  const onSelectAll = (
    val?: boolean,
    rentals?: RentalLightListItemResponse[]
  ) => {
    const value = val !== undefined ? val : !isSelectedAll;
    const array = rentals ? rentals : rentalsList;
    setIsSelectedAll(value);
    if (value) {
      setSelectedRentalsIds(array.map((rental) => rental.id));
    } else {
      setSelectedRentalsIds([]);
    }
  };

  return (
    <CenterModal
      isVisible={isVisible}
      onClose={onClose}
      title={title}
      size="large"
    >
      <div className="flex flex-row space-x-4 items-end">
        <TextInput
          Icon={SearchIcon}
          label={t("MultiSelectRentalsCheckboxModal.searchLabel")}
          placeholder={t("MultiSelectRentalsCheckboxModal.searchPlaceholder")}
          value={search}
          onChangeText={(value) => setSearch(value)}
          disabled={loadingSearch}
          size="large"
        />
        <Button
          onClick={onSearch}
          loading={loadingSearch}
          displayLargeBtn={false}
          type="secondary"
          classNames={{loader: "flex-none"}}
        >
          {t("Global.search")}
        </Button>
      </div>
      {loadingData ? (
        <div className="rounded-md overflow-hidden my-4 ">
          <table className="w-full border border-element-border-light">
            <tr className="bg-element-border-light/50 border-b-1 border-element-border-light">
              <th className="w-8 p-2">
                <SimpleBox value={false} />
              </th>

              <th className="p-2 text-left">
                <p className="h-2 mt-1 rounded w-52 bg-slate-200"></p>
              </th>
            </tr>
            {Array.from({length: 5}).map((item) => {
              return (
                <tr>
                  <td className="w-8 p-2">
                    <SimpleBox value={false} disabled={true} />
                  </td>

                  <td className="p-2">
                    <div className="flex items-center gap-2">
                      <div className="w-12 h-12 rounded-6px border-1 border-element-border-light bg-slate-200" />

                      <div className="flex flex-col">
                        <p className="h-2 rounded w-72 bg-slate-300"></p>
                        <p className="h-2 mt-1 rounded w-72 bg-slate-200"></p>
                        <p className="h-2 mt-1 rounded w-52 bg-slate-200"></p>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </table>{" "}
        </div>
      ) : (
        rentalsList?.length > 0 && (
          <div className="rounded-md overflow-hidden my-4 ">
            <table className="w-full border border-element-border-light">
              <tr
                className="bg-element-border-light/50 border-b-1 border-element-border-light"
                onClick={() => onSelectAll()}
              >
                <th className="w-8 p-2">
                  <SimpleBox value={isSelectedAll ?? false} />
                </th>

                <th className="p-2 text-left">
                  <p className="font-semibold text-high-contras text-xs">
                    {t("Progression.RentalsRules.Multi.rentals")}
                  </p>
                </th>
              </tr>
              {rentalsList?.map((rental) => {
                const isSelected = selectedRentalsIds?.includes(rental.id);
                return (
                  <tr
                    className="cursor-pointer border-b-1 border-element-border-light "
                    onClick={() => onSelect(rental?.id!, isSelected)}
                  >
                    <td className="w-8 p-2">
                      <SimpleBox value={isSelected ?? false} />
                    </td>

                    <td className="p-2">
                      <div className="flex items-center gap-2">
                        <img
                          alt="Rental thumbnail"
                          src={rental?.cover}
                          className="w-12 h-12 rounded-6px border-1 border-element-border-light text-xs"
                        />

                        <div className="flex flex-col">
                          <p className="font-semibold text-high-contrast text-xs">
                            {rental?.name}
                          </p>
                          <p className="font-light text-low-contrast text-xs">
                            {rental?.address}
                          </p>
                          <p className="font-light text-low-contrast text-xs">
                            {rental?.postal_code} {rental?.city}{" "}
                            {rental?.country?.name}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        )
      )}
      <div className="flex flex-row">
        {rentalsList?.length > 0 && (
          <PagingButtons
            i18nElement={"Global.rentals"}
            itemsPaging={displayUpToItems}
            loading={loading || loadingSearch || loadingData}
            nbPerPage={limit}
            page={page}
            lastPage={lastPage}
            total={totalPage}
            onChangeNbPerPage={handleChangeNbPerPage}
            onPaginationBack={handlePaginationBack}
            onPaginationNext={handlePaginationNext}
            displayTotal={false}
            displayTotalLoader={false}
            classNames={{
              divParent: "w-full justify-between",
              select: "flex-none",
              // button: "mr-4",
            }}
            totalForDisplay={5}
            lastPageForDisplay={0}
          />
        )}
      </div>
      <div className="flex flex-col pt-4 space-y-4">
        <ErrorMessage>{error}</ErrorMessage>
        <div className="flex flex-row space-x-4 justify-between">
          <Button type="secondary" onClick={onClose}>
            {t("Global.cancel")}
          </Button>
          <Button
            RightIcon={Icon}
            onClick={onSubmit}
            loading={loading}
            disabled={disabled}
          >
            {titleButtonSubmit}
          </Button>
        </div>
      </div>
    </CenterModal>
  );
};

export default MultiSelectRentalsCheckboxModal;
