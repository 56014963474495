import React from "react";
import {cn} from "../../../helpers/classHelper";
import ArrowRightRedIcon from "../../../assets/icons/arrow-right-red.svg?react";
import {Tooltip} from "../../Common/Tooltip/Tooltip";
import {AnalyticsDetailedMonthlyDetails} from "../../../types/GETTypes";
import moment from "moment-timezone";
import {capitalizeFirstLetter} from "../../../helpers/stringHelper";
import {useTranslation} from "react-i18next";

interface DetailsTableLineProps {
  index: number;
  row: AnalyticsDetailedMonthlyDetails;
  indexSelect: number | undefined;
  onChangeIndexSelect: (idx: number) => void;
  currency?: string;
  data: AnalyticsDetailedMonthlyDetails[];
}
const DetailsTableLineComponent = ({
  row,
  index,
  indexSelect,
  onChangeIndexSelect,
  currency,
  data,
}: DetailsTableLineProps) => {
  const {t} = useTranslation();
  const isGrey =
    indexSelect === undefined
      ? false
      : index !== undefined &&
        indexSelect !== undefined &&
        index !== indexSelect;
  const nextIndex = isGrey && indexSelect && indexSelect + 1 === index;
  const isSelect = index === indexSelect;
  const isTotal = index === data?.length - 1;

  return (
    <tr
      key={index}
      className={cn("cursor-pointer", isGrey ? "bg-element-border/25" : "")}
      onClick={() => onChangeIndexSelect(index)}
    >
      <td
        className={cn(
          "border-b py-4 pl-2 text-left text-high-contrast",
          nextIndex ? "border-t-3 border-element-border" : "",
          isSelect ? "flex text-active items-center font-bold" : "",
          isGrey ? "text-low-contrast" : "",
          isTotal ? "text-high-contrast font-bold" : ""
        )}
      >
        {isSelect && <ArrowRightRedIcon className="w-4 h-4 mr-1" />}
        {capitalizeFirstLetter(
          isTotal
            ? row.period
            : moment(row.period, "YYYY-MM").format("MMMM YYYY")
        )}
      </td>
      <td
        className={cn(
          "border-b py-4 text-right text-high-contrast",
          nextIndex ? "border-t-3 border-element-border" : "",
          isGrey ? "text-low-contrast" : "",
          isTotal ? "text-high-contrast font-bold" : ""
        )}
      >
        {row.nights_booked?.toLocaleString('fr-FR')}
      </td>
      <td
        className={cn(
          "border-b py-4 text-right text-high-contrast",
          nextIndex ? "border-t-3 border-element-border" : "",
          isGrey ? "text-low-contrast" : "",
          isTotal ? "text-high-contrast font-bold" : ""
        )}
      >
        {row.nights_available?.toLocaleString('fr-FR')}
      </td>
      <td
        className={cn(
          "border-b py-4 text-right text-high-contrast",
          nextIndex ? "border-t-3 border-element-border" : "",
          isGrey ? "text-low-contrast" : "",
          isTotal ? "text-high-contrast font-bold" : ""
        )}
      >
        {row.occupancy_rate?.toLocaleString('fr-FR')}%
      </td>
      <td
        className={cn(
          "border-b py-4 text-right text-high-contrast",
          nextIndex ? "border-t-3 border-element-border" : "",
          isGrey ? "text-low-contrast" : "",
          isTotal ? "text-high-contrast font-bold" : ""
        )}
      >
        {row.commissions?.toLocaleString('fr-FR')} {currency ?? "€"}
      </td>
      <td
        className={cn(
          "border-b py-4 text-right text-high-contrast",
          nextIndex ? "border-t-3 border-element-border" : "",
          isGrey ? "text-low-contrast" : "",
          isTotal ? "text-high-contrast font-bold" : ""
        )}
      >
        {row.tourist_tax?.toLocaleString('fr-FR')} {currency ?? "€"}
      </td>
      <td
        className={cn(
          "border-b py-4 text-right text-high-contrast",
          nextIndex ? "border-t-3 border-element-border" : "",
          isGrey ? "text-low-contrast" : "",
          isTotal ? "text-high-contrast font-bold" : ""
        )}
      >
        <div className="flex justify-end items-center gap-x-1">
          <span>
            {row.revenue?.toLocaleString('fr-FR')} {currency ?? "€"}
          </span>
          <Tooltip
            value={t("Analytics.Tooltip.General.revenue")}
            classNames={{container: "top-0"}}
          />
        </div>
      </td>
      <td
        className={cn(
          "border-b py-4 pr-2 text-right text-high-contrast ",
          nextIndex ? "border-t-3 border-element-border" : "",
          isGrey ? "text-low-contrast" : "",
          isTotal ? "text-high-contrast font-bold" : ""
        )}
      >
        {row.net_revenue?.toLocaleString('fr-FR')} {currency ?? "€"}
      </td>
    </tr>
  );
};

export default DetailsTableLineComponent;
