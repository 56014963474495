import { ContractListResponse } from "../../types/GETTypes";
import { get } from "../../helpers/APIHelper";
import paths from "../../constants/paths";

export const useReservationContract = () => {
  const getContractsAndDocs = async (
    reservationId: string,
  ): Promise<ContractListResponse> => {
    const res = await get(
      `${import.meta.env.VITE_API_URL}${paths.API.RESERVATION.GET_CONTRACTS_AND_DOCS(reservationId)}`,
    );

    if (res?.data?.success) {
      return res.data.result;
    } else {
      throw new Error(res?.data?.message ?? "An unexpected error occurred");
    }
  };

  return {
    getContractsAndDocs,
  };
};
