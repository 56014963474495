import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import paths from "../../constants/paths";
import {ValueType} from "../../types/commonTypes";
import {MessageListPageProps} from "./MessageListPage.type";

import {debounce} from "lodash";
import {ReservationSmallDetails} from "../../components/Booking/ReservationSmallDetails/ReservationSmallDetails";
import {MainLayout} from "../../components/Layout/MainLayout/MainLayout";
import {ViewAndSendMessages} from "../../components/Message/ViewAndSendMessages/ViewAndSendMessages";
import {ConversationFilterModal} from "../../components/MessageList/ConversationFilter/ConversationFilterModal";
import {ConversationList} from "../../components/MessageList/ConversationList/ConversationList";
import {ConversationListItemSkeleton} from "../../components/MessageList/ConversationList/ConversationListItemSkeleton";
import {useGetMessage} from "../../hooks/api/reservations";
import {useTablePage} from "../../hooks/useTablePage";
import {ReservationResponse} from "../../types/GETTypes";
import {NoConvMessageList} from "../../components/MessageList/NoConv/NoConvMessageList";

export const MessageListPage: React.FC<MessageListPageProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [reservations, setReservations] = useState<ReservationResponse[]>([]);
  const [error, setError] = useState<string | null>(null);



  const [currentReservationId, setCurrentReservationId] = useState<
    string | undefined
  >();
  const [currentReservation, setCurrentReservation] = useState<
    ReservationResponse | undefined
  >();

  const [currentReadStatus, setCurrentReadStatus] = useState<number>(-1);
  const [firstRequestDone, setFirstRequestDone] = useState<boolean>(false);
  const [filterMessageModalVisible, setFilterMessageModalVisible] =
    useState(false);


  const tablePageReservations = useTablePage(
      `${import.meta.env.VITE_API_URL}${paths.API.RESERVATIONS}`,
      "reservations",
      {
        otherMetas: [],
      },
  );

  const tableMessages = useTablePage(
    `${import.meta.env.VITE_API_URL}${
      paths.API.MESSAGES
    }/${currentReservationId}`,
    "messages",
    { combine: true, reverseData: true }
  );

  const tableConvervations = useTablePage(
    `${import.meta.env.VITE_API_URL}${paths.API.MESSAGES}`,
    "conversations",
    { otherMetas: ["not_read_conversations"] }
  );

  const [numberOfMessages, setNumberOfMessages] = useState(
    tableConvervations.data?.filter(
      (conv: any) => conv.is_conversation_read === false
    ).length ?? 0
  );

  const noConv =
    !tableConvervations.loading &&
    (tableConvervations?.total ?? 0) === 0 &&
    !firstRequestDone;

  const handleNavigateBookings = () => {
    navigate(paths.RESERVATIONS);
  };


  useEffect(() => {
    // TODO MANAGE LOAD MORE
    tableConvervations.fetch({ limit: 100 });
    tablePageReservations.fetch({ limit: 100 });
    //console.log("tablePageReservations", tablePageReservations.data);
    setReservations(tablePageReservations.data);
    setFirstRequestDone(true);
  }, []);

  useEffect(() => {
    if (tableConvervations.data?.length > 0 && !currentReservationId) {
      //console.log("select : ", tableConvervations.data[0].reservation_id);
      setCurrentReservationId(tableConvervations.data[0].reservation_id);
    }

    setNumberOfMessages(
      tableConvervations.data?.filter(
        (conv: any) => conv.is_conversation_read === false
      ).length
    );
  }, [tableConvervations.data]);

  const debouncedLoadMessages = React.useRef(
    debounce(async (tm) => {
      tm.handlePaginationNext();
    }, 200)
  ).current;

  const handleLoadMessages = () => {
    debouncedLoadMessages(tableMessages);
  };

  const handleReadStatus = (value: ValueType) => {
    //console.log(value, "handleReadStatus");
    setCurrentReadStatus(value as number);
    tableConvervations.handleFilterReadStatus(value as number);
    setIsNewReservationsList(true);
  };

  const handleFilterMessage = (value: any, isFiltered: boolean) => {
    //console.log(value, "value", isFiltered);
    tableConvervations.setIsFiltered(isFiltered);
    tableConvervations.handleFilterMessage(value);
  };

  useEffect(() => {
    if (currentReservationId) {
      const nextReservation = tableConvervations.data?.find(
        (conv: any) => conv.reservation_id === currentReservationId
      )?.reservation;
      //console.log(nextReservation, "nextReservation");

      setCurrentReservation(nextReservation);

      tableMessages.fetch({
        page: 1,
        limit: 100,
        search: "",
        include_ids: null,
        sort_direction: "desc",
        sort_field: "captured_at",
      });

      useGetMessage(
        currentReservationId,
        (data) => {
          const numberMessageUnRead = numberOfMessages - 1;
          if (numberMessageUnRead >= 0) {
            //console.log(numberMessageUnRead, "numberMessageUnRead", data.total);
            setNumberOfMessages(numberMessageUnRead);
          }
        },
        (error) => {
          console.log(error, "error");
        }
      );
    }
  }, [currentReservationId]);

  const [isNewReservationsList, setIsNewReservationsList] =
    useState<boolean>(false);

  useEffect(() => {
    //console.log("value: ", tableConvervations);
  }, [tableConvervations]);


  return (
    <>
      <MainLayout title={t("MessageList.title")} sidebarActiveItem="messages">
        {(tableConvervations.loading || tablePageReservations.loading) &&
          Array.from({ length: 9 }).map((value, index) => (
            <ConversationListItemSkeleton key={index} />
          ))}
        {(!tableConvervations.loading && !tablePageReservations.loading) && tablePageReservations.data.length == 0 && (
            <NoConvMessageList onNavigateBookings={handleNavigateBookings} />
        )}


        {(!tableConvervations.loading && !tablePageReservations.loading) && tablePageReservations.data.length > 0  && (
          <div className="flex flex-row w-full h-full mt-2 overflow-hidden border-r-0 rounded-6px border-1 border-element-border">
            {/* List of conversations */}
            <ConversationList
              loading={tableConvervations.loading}
              selectedReservationId={currentReservationId}
              conversations={tableConvervations.data}
              unreadConversations={numberOfMessages}
              readStatus={currentReadStatus}
              onSelect={(value) => setCurrentReservationId(value)}
              onReadStatus={handleReadStatus}
              setFilterMessageModalVisible={setFilterMessageModalVisible}
              isFiltered={tableConvervations.isFiltered}
              setIsNewReservationsList={setIsNewReservationsList}
            />
            {!tableConvervations.loading && (
              <>
                <div className="flex-1">
                  <ViewAndSendMessages
                    loading={tableMessages?.loading}
                    onLoadMessages={handleLoadMessages}
                    reservation={currentReservation}
                    tableMessages={tableMessages}
                    isNewReservationsList={isNewReservationsList}
                    numberOfConversations={tableConvervations.data.length ?? 0}
                  />
                </div>

                {/* Booking details */}
                {!isNewReservationsList && currentReservation!= null && (
                  <ReservationSmallDetails
                    loading={tableConvervations.loading}
                    reservation={currentReservation}
                  />
                )}
              </>
            )}
          </div>
        )}
        <ConversationFilterModal
          isVisible={filterMessageModalVisible}
          onClose={() => setFilterMessageModalVisible(false)}
          onFinish={() => setFilterMessageModalVisible(false)}
          onSelect={(value, isFiltered) =>
            handleFilterMessage(value, isFiltered)
          }
        />
      </MainLayout>
    </>
  );
};
