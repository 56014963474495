import React, { useEffect, useState } from "react";
import { useOperationalManagementMember } from "../../../hooks/api/operationalManagement";
import { OperationalManagementTeamMemberResponse } from "../../../types/GETTypes";
import { Loader } from "../Loader/Loader";
import { InputMultiSelect } from "../InputMultiSelect/InputMultiSelect";
import CloseIcon from "../../../assets/icons/close.svg?react";
import { useTranslation } from "react-i18next";
import { PrestaInputProps } from "./PrestaInput.type";
import { cn } from "../../../helpers/classHelper";

/**
 * PrestaInput Component
 * This component displays a multi-select input where you can select multiple team members.
 * Selected members are displayed above the input with the ability to remove them using a close button.
 * The component handles data fetching and displays a loader while fetching the data.
 *
 * Be careful ! This component is an overlay of the InputMultiSelect component.
 *
 * @param {string} className - Additional classes for styling.
 * @param {string|null} error - An error message to display.
 * @param onChange
 * @param membersId
 * @param label
 * @param disabled
 * @param isMultiSelect
 */
export const PrestaInput = ({
  className = "",
  error = "",
  onChange,
  membersId = [],
  label = "",
  disabled = false,
  isMultiSelect = true,
}: PrestaInputProps) => {
  // State to store the list of members fetched from the API
  const [members, setMembers] = useState<
    OperationalManagementTeamMemberResponse[] | null
  >(null);

  // State to store the ids of the selected members
  const [ids, setIds] = useState<number[] | string[]>(membersId);

  const { t } = useTranslation();

  // Hook to fetch the team members data from the API
  const managementMember = useOperationalManagementMember({
    onSuccess: (data) => {
      // @ts-ignore
      setMembers(data);
    },
    onError: () => {},
  });

  // re set selected ids
  useEffect(() => {
    setIds(membersId);
  }, []);

  // Fetch the members when the component is mounted
  useEffect(() => {
    managementMember.getAll(); // Trigger API call to fetch all members
  }, []);

  /**
   * Handle member selection from the InputMultiSelect component.
   * Updates the state with the newly selected members.
   *
   * @param selectedValue - The selected members' ids.
   */
  const handleSelect = (selectedValue: any) => {
    if (isMultiSelect) {
      setIds(selectedValue); // Update the selected member ids
      onChange(selectedValue);
    } else {
      if (ids.length <= 0) {
        setIds(selectedValue);
        onChange(selectedValue);
        return;
      }

      setIds((prevIds) => {
        let idAdd = null;

        selectedValue.map((id: string | number) => {
          if (!prevIds.includes(id as never)) {
            idAdd = id;
          }
        });

        if (!idAdd) {
          return [];
        }
        onChange([idAdd]);
        return [idAdd];
      });
    }
  };

  /**
   * Removes a member from the selected members list.
   * This function uses the splice method to remove the member at a specific index.
   *
   * @param index - The index of the member to remove from the list.
   */
  const removeMemberSelect = (index: number) => {
    const newIds = ids.filter((_, i) => i !== index);
    setIds(newIds as string[]);
    onChange(newIds as string[]);
  };

  // If members are not fetched yet, show the loader

  return (
    <>
      <div className={cn("relative", className)}>
        {/* Display selected members above the input */}
        <div
          className={cn(
            "absolute z-10 flex items-center gap-2 h-full pl-1",
            error ? "-translate-y-0.5" : "mt-3",
          )}
        >
          {ids && (
            <>
              {/* Display the first two selected members */}
              {ids.slice(0, 2).map((value, index) => {
                const member = members?.find((m) => m.id === value) ?? null; // Find the member by id
                return member ? (
                  <div
                    key={value}
                    className="p-1 bg-gray-100 rounded-md flex items-center gap-2 px-2 h-[30px]"
                  >
                    <div className={"w-[20px] h-[20px]"}>
                      <img
                        alt="operator photo"
                        src={member.photo}
                        height={20}
                        width={20}
                        className="rounded-full w-[20px] h-[20px] object-cover"
                      />
                    </div>
                    {member.first_name} {member.last_name}
                    <CloseIcon
                      className="cursor-pointer"
                      onClick={() => removeMemberSelect(index)} // Call the remove function on click
                    />
                  </div>
                ) : null;
              })}

              {/* Display '...' if there are more than 2 selected members */}
              {ids.length > 2 && (
                <div className="p-1 bg-gray-100 rounded-xl flex items-center gap-2 px-2">
                  ...
                </div>
              )}
            </>
          )}
        </div>

        {/* Multi-select input for selecting members */}
        <InputMultiSelect
          disabled={disabled}
          label={label || t("Management.Payments.personToPaid")}
          selectedValues={ids}
          items={
            members?.map((member) => ({
              label: `${member.first_name} ${member.last_name}`,
              value: member.id,
            })) ?? []
          }
          onSelect={handleSelect}
          error={error}
        />
      </div>
    </>
  );
};
