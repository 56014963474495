import React from "react";
import {useTranslation} from "react-i18next";
import ArrowRightIcon from "../../assets/icons/arrow-right.svg?react";
import PlusIcon from "../../assets/icons/plus.svg?react";
import CalendarIcon from "../../assets/icons/calendar.svg?react";
import {cn} from "../../helpers/classHelper";
import {Title} from "../Common/Title/Title";
import {DashboardTasksItem} from "./DashboardTasksItem";
import {DashboardTasksItemSkeleton} from "./DashboardTasksItemSkeleton";
import {
  DashboardData,
  TaskItemResponse,
  TaskListDayResponse,
  TasksByDateResponse,
  UserResponse,
} from "../../types/GETTypes";
import paths from "../../constants/paths";
import {Button} from "../Common/Button/Button";
import moment from "moment";
import {hasPermissionWithUser} from "../../helpers/workspaceHelper";

type DashBoardTasksProps = {
  isLoading: boolean;
  dashboardData: DashboardData | undefined;
  isOwner: boolean;
  openCreateTaskModal: (data?: any) => void;
  openEditTaskModal: (task: TaskItemResponse) => void;
  onAcceptTask: (task: TaskItemResponse | undefined) => void;
  onDeclineTask: (task: TaskItemResponse | undefined) => void;
  user: UserResponse | undefined;
};

export const MAX_DISPLAY_TASKS_DASHBOARD = 2;
const DashboardTasks = ({
  isLoading,
  dashboardData,
  isOwner,
  onAcceptTask,
  onDeclineTask,
  openCreateTaskModal,
  openEditTaskModal,
  user,
}: DashBoardTasksProps) => {
  const {t} = useTranslation();
  const data = isOwner
    ? dashboardData?.reservations
    : dashboardData?.operator_tasks;

  const tasks: TasksByDateResponse = data?.next_tasks?.tasks || {};
  const dates: string[] = Object.keys(tasks).sort();
  const todayString = moment().format("YYYY-MM-DD");
  const tomorrowString: string = moment().add(1, "day").format("YYYY-MM-DD");

  const todayIndex = dates.findIndex((date) => date === todayString);
  const tomorrowIndex = dates.findIndex((date) => date === tomorrowString);

  const today: TaskListDayResponse | undefined =
    todayIndex !== -1 ? tasks[dates[todayIndex]] : undefined;

  const tomorrow: TaskListDayResponse | undefined =
    tomorrowIndex !== -1 ? tasks[dates[tomorrowIndex]] : undefined;

  const hasPermissionTask = hasPermissionWithUser(
    user,
    "operational_management_tasks"
  );

  if (!hasPermissionTask) {
    return <></>;
  }

  const todayDayTasks = today?.day_tasks?.filter(
    (task) => task.status !== "COMPLETED"
  );
  const tomorrowDayTasks = tomorrow?.day_tasks?.filter(
    (task) => task.status !== "COMPLETED"
  );
  return (
    <div className="flex flex-col w-full">
      <Title>{t("Dashboard.nextTasks")}</Title>
      <div className={cn(`select-none flex flex-col justify-between p-4 pl-0`)}>
        <div className="flex flex-row gap-x-2 items-center p-3 font-semibold tracking-wider border-t-1 border-b-0 selecte-none bg-element-background text-low-contrast border-1 border-element-border rounded-t-lg">
          <CalendarIcon width={23} height={23} />{" "}
          {t("Dashboard.today", {
            count: today?.day_tasks?.length,
          }).toUpperCase()}
        </div>

        {/* Checkouts */}
        {isLoading ? (
          Array.from({length: 3}).map(() => <DashboardTasksItemSkeleton />)
        ) : !todayDayTasks?.length ? (
          <div
            className={cn(
              `flex items-center p-4 border-t-0 border-b-1 border-x-1 border-element-border hover:bg-element-background/30`
            )}
          >
            <div className="flex flex-1 flex-col items-center justify-center space-y-4 pb-6">
              <p className="text-low-contrast">
                {t("Dashboard.noneTasksScheduled")}
              </p>
              <Button
                RightIcon={PlusIcon}
                type="secondary"
                onClick={() =>
                  openCreateTaskModal({
                    date: moment(),
                  })
                }
              >
                {t("Dashboard.addTask")}
              </Button>
            </div>
          </div>
        ) : (
          todayDayTasks
            ?.filter((_, index) => index < MAX_DISPLAY_TASKS_DASHBOARD)
            ?.map((task, index) => (
              <div key={index}>
                <DashboardTasksItem
                  task={task}
                  onAcceptTask={onAcceptTask}
                  onDeclineTask={onDeclineTask}
                  openEditTaskModal={openEditTaskModal}
                />
              </div>
            ))
        )}

        <div className="flex flex-row gap-x-2 items-center p-3 font-semibold tracking-wider  selecte-none bg-element-background text-low-contrast border-x-1 border-element-border last:rounded-b-lg">
          <CalendarIcon width={23} height={23} />{" "}
          {t("Dashboard.tomorrow", {
            count: tomorrow?.day_tasks?.length,
          }).toUpperCase()}
        </div>

        {/* Checkin */}
        {isLoading ? (
          Array.from({length: 3}).map(() => <DashboardTasksItemSkeleton />)
        ) : !tomorrowDayTasks?.length ? (
          <div
            className={cn(
              `flex items-center p-4 border-t-0 border-b-1 border-x-1 border-element-border hover:cursor-pointer hover:bg-element-background/30`
            )}
          >
            <div className="flex flex-1 flex-col items-center justify-center space-y-4 pb-6">
              <p className="text-low-contrast">
                {t("Dashboard.noneTasksScheduled")}
              </p>
              <Button
                RightIcon={PlusIcon}
                type="secondary"
                onClick={() =>
                  openCreateTaskModal({
                    date: moment().add(1, "day"),
                  })
                }
              >
                {t("Dashboard.addTask")}
              </Button>
            </div>
          </div>
        ) : (
          tomorrowDayTasks
            ?.filter((_, index) => index < MAX_DISPLAY_TASKS_DASHBOARD)
            ?.map((task, index) => (
              <div key={index}>
                <DashboardTasksItem
                  task={task}
                  onAcceptTask={onAcceptTask}
                  onDeclineTask={onDeclineTask}
                  openEditTaskModal={openEditTaskModal}
                />
              </div>
            ))
        )}

        {/* Button */}
        <a
          className="flex flex-row h-10 bg-element-background border-b-1 border-x-1 border-element-border px-4 items-center cursor-pointer hover:bg-element-background-active last:rounded-b-lg"
          href={paths.MANAGEMENT_TASKS}
        >
          <p className="flex-1 text-low-contrast font-semibold">
            {t("Dashboard.buttonActionTask")} ({data?.next_tasks?.total ?? 0}{" "}
            {data?.next_tasks?.total && data.next_tasks.total > 1
              ? t("Dashboard.tasks")
              : t("Dashboard.task")}
            )
          </p>

          <ArrowRightIcon />
        </a>
      </div>
    </div>
  );
};

export default DashboardTasks;
