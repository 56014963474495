import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {get, post} from "../../../../helpers/APIHelper";
import paths from "../../../../constants/paths";
import {Button} from "../../../Common/Button/Button";
import {
  RentalLightBookingItemResponse,
  RentalLightListItemResponse,
} from "../../../../types/GETTypes";
import CheckWhiteIcon from "../../../../assets/icons/check-white.svg?react";
import {BookingConnectData} from "./BookingConnectModal";
import {ErrorMessage} from "../../../Common/ErrorMessage/ErrorMessage";
import {UseFormReturn} from "react-hook-form";
import {BookingConnectRentalRoomTypeForm} from "./BookingConnectRentalRoomType";
import BookingConnectRentalConnectMode from "./BookingConnectRentalConnectMode";
import {ConnectionLoader} from "../../../Common/ConnectionLoader/ConnectionLoader";
import {PlatformColorList} from "../../../../constants/colors";
import SuperhoteIcon from "../../../../assets/icons/superhote-circle.svg?react";
import BookingIcon from "../../../../assets/icons/booking.svg?react";
import {ProgressionStepEnum} from "../../../../enums/GETenums";

type BookingConnect4Props = {
  properties: RentalLightBookingItemResponse[] | undefined;
  setData: React.Dispatch<React.SetStateAction<BookingConnectData | undefined>>;
  importData: BookingConnectRentalRoomTypeForm[] | undefined;
  rentals: RentalLightListItemResponse[] | undefined;
  onNext: () => void;
};

export const BookingConnectStep4: React.FC<BookingConnect4Props> = ({
  properties,
  importData,
  setData,
  rentals,
  onNext,
}) => {
  const {t} = useTranslation();
  const [error, setError] = useState("");
  const [allFormsValid, setAllFormsValid] = useState(true);
  const childForms = useRef<UseFormReturn[]>([]);
  const [startSync, setStartSync] = useState(false);

  const handleValidateAll = async () => {
    const validations = await Promise.all(
      childForms.current.map(async (form) => {
        if (form) {
          const isValid = await form.trigger();
          return isValid;
        }
        return false;
      })
    );

    const allValid = validations.every((isValid) => isValid);

    if (allValid) {
      setStartSync(true);
      try {
        const data = importData;
        const response = await post(
          `${import.meta.env.VITE_API_URL}${
            paths.API.BOOKING.MAP_OR_CREATE_RENTALS
          }`,
          data
        );
        if (response?.data?.success) {
          setData((oldConnectedData) => {
            if (oldConnectedData) {
              return {
                ...oldConnectedData,
                finishImport: true,
              };
            }
          });
        } else {
          setError(response?.response?.data?.message);
          setStartSync(false);
          return;
        }
        setStartSync(false);
      } catch (e: any) {
        console.log(e.message);
        setStartSync(false);
        return;
      }
      await post(
        `${import.meta.env.VITE_API_URL}${paths.API.COMPLETE_PROGRESSION_STEP}`,
        {
          progression_step: ProgressionStepEnum.STEP_CONNECT_BOOKING,
        }
      );
      setTimeout(() => {
        setStartSync(false);
        onNext();
      }, 200);
    }
  };

  const checkFormsValidity = async () => {
    const validations = await Promise.all(
      childForms.current.map((form: UseFormReturn) => form?.trigger())
    );
    setAllFormsValid(validations.every((isValid) => isValid));
  };

  return (
    <div className="flex flex-col">
      {startSync ? (
        <div className="flex flex-col bg-element-background-light justify-center">
          <ConnectionLoader
            platformLeft={{
              icon: BookingIcon,
              color: PlatformColorList.Booking,
            }}
            type={"new"}
          />
          <div className="flex flex-col justify-center items-center p-4 pt-0 space-y-3">
            <p className="text-low-contrast font-bold">
              {t("Progression.syncInfoTitle")}
            </p>
            <div className="flex flex-col justify-center items-center">
              <p className="text-low-contrast">{t("Progression.syncInfo1")}</p>
              <p className="text-low-contrast">{t("Progression.syncInfo2")}</p>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="my-3 space-y-2">
            <p className="text-low-contrast font-bold">
              {t("Progression.paramsImportBookingInfo")}
            </p>

            {properties?.map(
              (property: RentalLightBookingItemResponse, index) => (
                <BookingConnectRentalConnectMode
                  // @ts-ignore
                  ref={(el) => (childForms.current[index] = el)}
                  property={property}
                  importData={importData}
                  rentals={rentals}
                  setData={setData}
                  onChange={checkFormsValidity}
                />
              )
            )}
          </div>
          <ErrorMessage>{error}</ErrorMessage>
          <Button
            onClick={handleValidateAll}
            RightIcon={CheckWhiteIcon}
            disabled={!allFormsValid}
          >
            {t("Global.validate")}
          </Button>
        </>
      )}
    </div>
  );
};
