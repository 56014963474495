import React from "react";

export interface PolicyItemLayoutProps {
    children: React.ReactNode;
}

export default function PolicyItemsLayout({ children }: PolicyItemLayoutProps) {
    return (
        <main className={"flex flex-col gap-2"}>
            {children}
        </main>
    );
}