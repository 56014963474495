import React, { ReactNode } from "react";
import { cn } from "../../../helpers/classHelper";

export interface FlexCenterBetweenProps {
  children?: ReactNode;
  gap?: number;
  className?: string;
  itemCenter?: boolean;
}

export const FlexCenterBetween: React.FC<FlexCenterBetweenProps> = ({
  children,
  gap = 2,
  className = "",
  itemCenter = true,
}) => {
  return (
    <section
      className={cn(
        `flex justify-between ${className}`,
        itemCenter ? "item-center" : "",
      )}
      style={{ gap: `${gap}px` }}
    >
      {children}
    </section>
  );
};
