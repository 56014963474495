import React from "react";
import { ErrorMessageProps } from "./ErrorMessage.type";
import AlertCircle from "../../../assets/icons/alert-circle.svg?react";
import CloseButtonWhite from "../../../assets/icons/close-white.svg?react";
import { cn } from "../../../helpers/classHelper";
import { FlexCenterBetween } from "../FlexComponents/FlexCenterBetween";

export const ErrorMessage: React.FC<ErrorMessageProps> = ({
  errorType = "API",
  children,
  childrenClassName = "",
  showCloseButton = false,
  onClickCloseButton = () => {},
}) => {
  const content = () => {
    if (children && typeof children === "object") {
      return Object.values(children).join(" ");
    }

    return children;
  };

  if (children) {
    return (
      <>
        {errorType === "API" && (
          <div
            className={cn(
              "p-4 rounded-6px bg-error my-2 text-white text-sm w-full relative",
              childrenClassName,
            )}
          >
            <FlexCenterBetween itemCenter={false}>
              {content()}
              {showCloseButton ? (
                <CloseButtonWhite
                  className="cursor-pointer"
                  onClick={onClickCloseButton}
                />
              ) : null}
            </FlexCenterBetween>
          </div>
        )}

        {errorType === "FORM" && (
          <div className="flex items-center mt-2 relative">
            <AlertCircle
              className="fill-error me-2 shrink-0"
              width={20}
              height={20}
            />
            <p
              className={cn(
                "text-left text-base text-error font-light",
                childrenClassName,
              )}
            >
              {content()}
            </p>
          </div>
        )}
      </>
    );
  } else {
    return null;
  }
};
