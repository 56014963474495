import React, {useEffect} from "react";
import InfoIcon from "../../../assets/icons/information.svg?react";
import {StatsItemType} from "../../Analytics/General/GeneralComponent";
import {Tooltip} from "../../Common/Tooltip/Tooltip";
import {ClassNamesType} from "../../../types/commonTypes";
import {cn} from "../../../helpers/classHelper";

export type StatsItemProps = {
  loading?: boolean;
  stats: StatsItemType | undefined;
  classNames?: ClassNamesType;
};

const StatsItem = ({loading = false, stats, classNames,}: StatsItemProps) => {
  const {
    title,
    description,
    showInfo = true,
    infoText = "",
    symbol,
  } = stats || {};

  if (loading) {
    return (
      <div
        className={cn(
          "flex flex-col h-[120px] w-[250px] rounded-8px border-1 border-gray-300 bg-gray-200 mr-2 animate-pulse",
          classNames?.divParent
        )}
      >
        <div className="p-4 flex-1">
          <div className="h-4 bg-gray-300 rounded mb-2"></div>
          <div className="w-full h-full flex flex-col pt-4">
            <div className="h-3 bg-gray-300 rounded mt-2 w-full"></div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div
      className={cn(
        "flex flex-col h-[120px] w-[250px] rounded-8px border-1 border-element-border bg-white mr-2",
        classNames?.divParent
      )}
    >
      <div className={cn("p-4 flex-1", classNames?.divInput)}>
        <p className={cn("text-xxl font-bold  text-active", classNames?.label)}>
          {title?.toLocaleString('fr-FR')} {symbol}
        </p>

        <div className="w-full h-full flex flex-col pt-4">
          <p className="flex flex-row gap-2 mt-1 justify-between">
            <span
              className={cn(
                "text-low-contrast font-semibold",
                classNames?.subLabel
              )}
            >
              {description}
            </span>

            {showInfo && (
              <Tooltip
                Icon={InfoIcon}
                value={infoText as string}
                classNames={{container: "top-0 left-[-20px]"}}
              />
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default StatsItem;
