import React from "react";
import { CarouselWizardStepHeaderProps } from "./CarouselWizard.type";

import ChevronDownIcon from "../../../assets/icons/chevron-down.svg?react";
import CheckSuccessIcon from "../../../assets/icons/check-success.svg?react";
import { callFunction } from "../../../helpers/eventHelper";

export const CarouselWizardStepHeader: React.FC<
  CarouselWizardStepHeaderProps
> = ({ title, stepNumber, isValidated = false, onClick,disableHeader }) => {
  return (
    <div>
      {/* Header */}
      <div
        className={`border-t-1 border-element-border px-2 py-3 flex flex-row items-center space-x-2
        ${onClick ? "cursor-pointer" : "cursor-not-allowed"} ${
          stepNumber === 1 && "rounded-t-6px"
           } ${disableHeader  && "bg-element-border/40"}` }
        onClick={callFunction(onClick)}
      >
        {isValidated ? (
          <div className="bg-element-background-success h-6 w-6 rounded-full flex items-center justify-center">
            <p className="font-semibold text-high-contrast">
              <CheckSuccessIcon width={16} height={16} />
            </p>
          </div>
        ) : (
          <div className="bg-element-background h-6 w-6 rounded-full flex items-center justify-center">
            <p className="font-semibold text-high-contrast">{stepNumber}</p>
          </div>
        )}

        <p className="text-high-contrast font-semibold flex-1">{title}</p>

        <ChevronDownIcon />
      </div>
    </div>
  );
};
