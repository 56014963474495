import React from "react";
import {ValueType} from "../../types/commonTypes";
import {useTranslation} from "react-i18next";
import {TextInput} from "../Common/TextInput/TextInput";
import SearchIcon from "../../assets/icons/search.svg?react";
import PlusCircleWhiteIcon from "../../assets/icons/plus-circle-white.svg?react";
import {MultiSelectRentals} from "../Common/MultiSelectRentals/MultiSelectRentals";
import {Button} from "../Common/Button/Button";
import {GroupButton} from "../Common/GroupButton/GroupButton";
import useUserStore from "../../stores/useUserStore";

export const AutoMessageListFilter: React.FC<{
  currentTrigger: ValueType;
  preCheckinCount: string;
  postCheckinCount: string;
  paymentsCount: string;
  postCheckoutCount: string;
  totalCount: string;
  onAdd: () => void;
  onSearch: (value: string) => void;
  onSelect: (value: ValueType) => void;
  onDisplaySuggestion: () => void;

}> = ({
  currentTrigger,
  preCheckinCount,
  postCheckinCount,
  paymentsCount,
  postCheckoutCount,
  totalCount,
  onSearch = () => {},
  onDisplaySuggestion = () => {},
  onAdd = () => {},
  onSelect,
}) => {
  const userStore = useUserStore();
  const { t } = useTranslation();

  return (
    <>
      <div className="flex flex-row items-center gap-2 mt-2">
        <div className="flex-1">
          <TextInput
            size="large"
            Icon={SearchIcon}
            placeholder={t("AutoMessageList.search")}
            onChangeText={onSearch}
          />
        </div>

        <div>
          <MultiSelectRentals selectedRentals={[]} onSelect={(values) => {}} />
        </div>

        <div>
          <Button
            type="secondary"
            onClick={()=> onDisplaySuggestion()}
          >
            {t("AutoMessageList.activeSuggestionAutomatedMessage")}
          </Button>
        </div>

        <div>
          <Button
            onClick={onAdd}
            disabled={!userStore.canEdit}
            RightIcon={PlusCircleWhiteIcon}
          >
            {t("AutoMessageList.add")}
          </Button>
        </div>
      </div>

      {/* Multi button */}
      <div className="mt-4">
        <GroupButton
          items={[
            {
              value: "all",
              label: t("AutoMessageList.Filter.all"),
              badge: totalCount,
              isActive: currentTrigger === "all",
            },
            {
              value: 0,
              label: t("AutoMessageList.Filter.preCheckin"),
              badge: preCheckinCount,
              isActive: currentTrigger === 0,
            },
            {
              value: 1,
              label: t("AutoMessageList.Filter.postCheckin"),
              badge: postCheckinCount,
              isActive: currentTrigger === 1,
            },
            {
              value: 2,
              label: t("AutoMessageList.Filter.paymentsDeposits"),
              badge: paymentsCount,
              isActive: currentTrigger === 2,
            },
            {
              value: 3,
              label: t("AutoMessageList.Filter.postCheckout"),
              badge: postCheckoutCount,
              isActive: currentTrigger === 3,
            },
          ]}
          onClick={onSelect}
        />
      </div>
    </>
  );
};
