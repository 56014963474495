import React from "react";
import {getFormattedPrice} from "../../../../../../helpers/currencyHelper";
import {useTranslation} from "react-i18next";
import MoneyBagIcon from "../../../../../../assets/icons/money-bag.svg?react";
import {PaymentPolicyResponse} from "../../../../../Payment/Payment.type";
import useGuestWebsiteStore from "../../../../../../stores/guestWebsiteStore/useGuestWebsiteStore";
import {displayDepositPaymentMethod} from "../../../../../../helpers/paymentHelper";

const DepositBlockComponent = ({loading}: {loading: boolean}) => {
  const {t} = useTranslation();
  const {rentalData} = useGuestWebsiteStore();
  const {payment_policy} = rentalData || {};
  const hasDeposit =
    payment_policy?.deposit_value !== undefined &&
    payment_policy?.deposit_value !== null;

  if (!hasDeposit) {
    return (
      <p className="text-base text-low-contrast">
        {t("AddReservation.Payment.noDeposit")}
      </p>
    );
  }
  return (
    <div className="flex-col items-center space-y-1">
      {loading ? (
        <p className="w-3/4 h-3 rounded-lg bg-slate-300 animate-pulse"></p>
      ) : (
        <>
          <p className="text-base text-low-contrast">
            {t("AddReservation.Payment.isDeposit", {
              amount: getFormattedPrice({
                price: payment_policy.deposit_value ?? 0,
                locale: "fr-FR",
                decimals: 0,
              }),
              paymentMethod: displayDepositPaymentMethod(payment_policy, t),
            })}
          </p>
        </>
      )}
    </div>
  );
};

export default DepositBlockComponent;
