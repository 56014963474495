import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import FileTextIcon from "../../../assets/icons/file-text.svg?react";
import { Card } from "../../Common/Card/Card";
import { WebsiteResponse } from "../../../types/GETTypes";
import { useTranslation } from "react-i18next";
import { useToggleCard } from "./useToggleCard";
import paths from "../../../constants/paths";
import { post } from "../../../helpers/APIHelper";
import CheckIcon from "../../../assets/icons/check-white.svg?react";
import EditIcon from "../../../assets/icons/edit.svg?react";
import ArrowLeftIcon from "../../../assets/icons/arrow-left.svg?react";
import MinusIcon from "../../../assets/icons/minus.svg?react";
import PhotoIcon from "../../../assets/icons/photo.svg?react";
import { TextInput } from "../../Common/TextInput/TextInput";
import { TextAreaInput } from "../../Common/TextAreaInput/TextAreaInput";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";

export const WebsiteAboutCard: React.FC<{
  website: WebsiteResponse | undefined;
  onUpdateWebsite: (nextWebsite: WebsiteResponse) => void;
}> = ({ website, onUpdateWebsite }) => {
  const { t } = useTranslation();

  const { isEnabled, isEnabledLoading, toggle } = useToggleCard({
    website,
    initialValue: website?.content.about.enabled,
    baseUrl: `${
      import.meta.env.VITE_API_URL
    }${paths.API.WEBSITE.UPDATE.ABOUT_TOGGLE(website?.id!)}`,
  });

  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const [updateError, setUpdateError] = useState<string | undefined>();

  const [editMode, setEditMode] = useState<boolean>(false);

  const editModeRef = useRef<{
    handleClear: () => void;
    handleCheckData: () => {
      photo: Blob | undefined;
      title: string;
      subTitle: string;
      text: string;
    } | null;
  } | null>(null);

  const handleCancel = () => {
    setEditMode(false);
    editModeRef.current?.handleClear();
  };

  const handleUpdate = async () => {
    const values = editModeRef.current?.handleCheckData();

    if (!values) return;

    setUpdateLoading(true);
    setUpdateError(undefined);

    const formData = new FormData();
    formData.append("about_section_title", values.title);
    formData.append("about_section_text", values.text);
    if (values.photo) {
      formData.append("photo", values.photo);
    }

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.WEBSITE.UPDATE.ABOUT(
        website?.id!
      )}`,
      formData
    );

    if (!res?.data?.success) {
      setUpdateError(res?.response?.data?.message);
    } else {
      onUpdateWebsite(res.data?.result);
      setEditMode(false);
    }

    setUpdateLoading(false);
  };

  useEffect(() => {
    setUpdateError(undefined);
  }, [editMode]);

  return (
    <Card
      Icon={FileTextIcon}
      label={t("Website.About.title")}
      anchor="about-card"
      switchButton={{
        value: isEnabled,
        label: "",
        loading: isEnabledLoading,
        onClick: toggle,
      }}
      button={{
        Icon: editMode ? CheckIcon : EditIcon,
        type: editMode ? "primary" : "secondary",
        label: editMode ? t("Global.record") : t("Global.edit"),
        onClick: editMode ? () => handleUpdate() : () => setEditMode(!editMode),
        disabled: !website || updateLoading,
      }}
      secondaryButton={
        editMode
          ? {
              label: t("Global.cancel"),
              LeftIcon: ArrowLeftIcon,
              onClick: handleCancel,
              disabled: updateLoading,
            }
          : undefined
      }
    >
      <ErrorMessage>{updateError}</ErrorMessage>

      {editMode ? (
        <EditMode ref={editModeRef} website={website} loading={updateLoading} />
      ) : (
        <ViewMode website={website} />
      )}
    </Card>
  );
};

const ViewMode: React.FC<{ website: WebsiteResponse | undefined }> = ({
  website,
}) => {
  if (!website) return <ViewModeSkeleton />;

  return (
    <div className="flex flex-col gap-3">
      <p className="font-semibold text-base text-high-contrast text-center">
        {website?.content.about.title}
      </p>

      <div className="border-1 border-element-border bg-element-background rounded-6px p-2">
        <p className="text-low-contrast font-light">
          {website?.content.about.text}
        </p>
      </div>

      <div className="flex gap-3 w-full pb-2">
        <img
          src={website?.content.about.image}
          className="h-32 flex-1 border-1 border-element-border rounded-6px object-cover bg-element-background"
        />
      </div>
    </div>
  );
};

const ViewModeSkeleton: React.FC<{}> = ({}) => {
  return (
    <div className="flex flex-col gap-3">
      <p className="font-semibold text-base text-high-contrast text-center h-2 w-72 bg-slate-300 rounded"></p>

      <div className="border-1 border-element-border bg-element-background rounded-6px p-2">
        <p className="text-low-contrast font-light h-2 w-full bg-slate-200 rounded"></p>
        <p className="text-low-contrast font-light h-2 w-full bg-slate-200 rounded mt-2"></p>
        <p className="text-low-contrast font-light h-2 w-2/3 bg-slate-200 rounded mt-2"></p>
      </div>

      <div className="flex gap-3 w-full pb-2">
        <div className="h-32 flex-1 border-1 border-element-border rounded-6px object-cover bg-element-background" />
      </div>
    </div>
  );
};

interface EditModeProps {
  website: WebsiteResponse | undefined;
  loading: boolean;
}

const EditMode = forwardRef<
  {
    handleClear: () => void;
    handleCheckData: () => {
      photo: Blob | undefined;
      title: string;
      text: string;
    } | null;
  },
  EditModeProps
>(({ website, loading }, ref) => {
  const { t } = useTranslation();

  const [title, setTitle] = useState<string>("");
  const [titleError, setTitleError] = useState<string | undefined>();

  const [text, setText] = useState<string>("");
  const [textError, setTextError] = useState<string | undefined>();

  const handleClear = () => {
    setTitle("");
    setTitleError(undefined);

    setText("");
    setTextError(undefined);
  };

  const handleCheckData = () => {
    setTitleError(undefined);

    let isError = false;

    if (!title) {
      setTitleError(t("Global.required"));
      isError = true;
    }

    if (!text) {
      setTextError(t("Global.required"));
      isError = true;
    }

    if (isError) return null;

    return {
      photo,
      title,
      text,
    };
  };

  useImperativeHandle(ref, () => ({
    handleClear,
    handleCheckData,
  }));

  useEffect(() => {
    if (website) {
      setTitle(website?.content.about.title);
      setTitleError(undefined);

      setText(website?.content.about.text);
      setTextError(undefined);

      setPhotoURL(website?.content?.about.image);
    }
  }, [website]);

  // Photo
  const [photo, setPhoto] = useState<Blob>();
  const [photoURL, setPhotoURL] = useState<string>();

  const photoInputRef = useRef<HTMLInputElement>(null);
  const handleAddPhoto = () => {
    photoInputRef.current?.click();
  };

  const handleAddPhotoFromInput = (event: any) => {
    if (event.target.files?.length === 0) return;

    setPhoto(event.target.files[0]);
  };

  const handleRemovePhoto = () => {
    setPhoto(undefined);
    setPhotoURL(undefined);
  };

  // Title and text
  const handleChangeTitle = (value: string) => {
    setTitle(value);

    setTitleError(undefined);
  };

  const handleChangeText = (value: string) => {
    setText(value);
  };

  return (
    <div className="flex flex-col gap-3">
      <TextInput
        label={t("Website.About.titleLabel")}
        value={title}
        onChangeText={handleChangeTitle}
        error={titleError}
        required={true}
        disabled={loading}
      />

      <TextAreaInput
        label={t("Website.About.textLabel")}
        value={text}
        onTextChange={handleChangeText}
        error={textError}
        required={true}
        disabled={loading}
      />

      {/* Photo */}
      <div className="relative flex-1 flex flex-col gap-4 mt-2">
        <input
          type="file"
          multiple={true}
          style={{ display: "none" }}
          ref={photoInputRef}
          accept="image/jpeg, image/png"
          onChange={handleAddPhotoFromInput}
        />

        {(photo || photoURL) && (
          <div className="w-full h-full group">
            <div
              className="absolute items-center justify-center hidden w-6 h-6 rounded-sm cursor-pointer border-element-border border-1 group-hover:flex bg-white"
              style={{ top: -6, right: -6 }}
              onClick={handleRemovePhoto}
            >
              <MinusIcon width={20} height={20} />
            </div>

            <div className="flex flex-col items-center justify-center w-full h-48 gap-2 overflow-hidden border-dashed bg-element-background border-1 border-element-border rounded-6px hover:bg-element-background-active">
              <img
                src={photo ? URL.createObjectURL(photo) : photoURL}
                className="object-cover w-full h-full"
              />
            </div>
          </div>
        )}

        <p>{t("Website.About.photoLabel")}</p>
        {!photo && !photoURL && (
          <div
            className="flex flex-col items-center justify-center w-full gap-2 p-4 border-dashed cursor-pointer bg-element-background border-1 border-element-border rounded-6px hover:bg-element-background-active"
            onClick={handleAddPhoto}
          >
            <PhotoIcon className="w-10 h-10" />
            <p className="font-semibold text-high-contrast">
              {t("Website.Infos.addPhoto")}
            </p>
            <div className="flex flex-col items-center justify-center gap-1">
              <p className="font-light text-low-contrast">
                {t("AddRental.Photos.formatAccepted")}
              </p>
              <p className="font-light text-low-contrast">
                {t("AddRental.Photos.WeightAccepted")}
              </p>
              <p className="font-light text-low-contrast">
                {t("AddRental.Photos.DimensionsMinimum")}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
});
