import React from "react";
import TitleComponent from "../../Common/Template2.0/TitleComponent";
import useGuestWebsiteStore from "../../../../stores/guestWebsiteStore/useGuestWebsiteStore";

interface GuestWebsiteTemplate2AboutComponent {
  loading: boolean;
}
const GuestWebsiteTemplate2AboutComponent = ({
  loading,
}: GuestWebsiteTemplate2AboutComponent) => {
  const {dataGuestWebsite} = useGuestWebsiteStore();
  const {content, styles} = dataGuestWebsite || {};
  const {color_background, color_text} = styles || {};
  const {about} = content || {};
  return (
    <div
      className="flex items-center justify-center px-4 h-full"
      style={{backgroundColor: color_background}}
    >
      <div className="max-w-4xl flex flex-col items-center h-full pb-20">
        <TitleComponent
          title={about?.title ?? ""}
          classNames={{divParent: "pt-6 pb-0"}}
        />
        <div className="flex flex-col md:flex-row md:justify-between space-y-4 items-center">
          {/* Image Section */}
          <div className="w-3/4 md:px-4">
            <img
              src={about?.image ?? ""}
              alt={about?.title ?? ""}
              className="rounded-lg shadow-md w-full"
            />
          </div>

          {/* Text Section */}
          <div className="pb-8 pt-4">
            <p
              className="text-low-contrast mb-4"
              dangerouslySetInnerHTML={{__html: about?.text ?? ""}}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuestWebsiteTemplate2AboutComponent;
