import React from "react";
import DetailsLineComponent, {DetailsLineItem} from "../DetailsLineComponent";
import {PlatformEnum} from "../../../enums/GETenums";
import {
  AnalyticsDetailedSummary,
  AnalyticsDetailsObjectResponse,
  DistributionKeyEnum,
  StatsNameKeysEnum,
} from "../../../types/GETTypes";
import {useTranslation} from "react-i18next";

interface BreakdownTableProps {
  data: AnalyticsDetailedSummary;
  openModalDetails: (
    statName: StatsNameKeysEnum,
    platform: DistributionKeyEnum
  ) => void;
  currency: string | undefined;
}


const BreakdownTableComponent = ({
  data,
  openModalDetails,
  currency,
}: BreakdownTableProps) => {
  const transformToBreakdown = (
    revenue_breakdown: AnalyticsDetailsObjectResponse,
    commissions: AnalyticsDetailsObjectResponse,
    city_taxes: AnalyticsDetailsObjectResponse,
    cleaning_fees: AnalyticsDetailsObjectResponse,
    net_revenue: AnalyticsDetailsObjectResponse
  ): DetailsLineItem[] => {
    const {t} = useTranslation();
    return [
      {
        label: t("Analytics.ca"),
        subLabel: `${revenue_breakdown.total?.toLocaleString('fr-FR')} ${currency}`,
        info: t("Analytics.Tooltip.Details.Revenue.ca"),
        alert: false,
        distribution: revenue_breakdown.distribution,
        distributionSymbol:currency ?? "€",
        statName: StatsNameKeysEnum.REVENUE_BREAKDOWN,
      },
      {
        label: t("Analytics.commissionsPlatform"),
        subLabel: `${commissions.total?.toLocaleString('fr-FR')} ${currency}`,
        info: t("Analytics.Tooltip.Details.Revenue.commissionsPlatform"),
        alert: true,
        distribution: commissions.distribution,
        distributionSymbol:currency ?? "€",
        statName: StatsNameKeysEnum.COMMISSIONS,
      },
      {
        label: t("Analytics.tax"),
        subLabel: `${city_taxes.total?.toLocaleString('fr-FR')} ${currency}`,
        info: t("Analytics.Tooltip.Details.Revenue.tax"),
        alert: true,
        distribution: city_taxes.distribution,
        distributionSymbol:currency ?? "€",
        statName: StatsNameKeysEnum.CITY_TAXES,
      },
      {
        label: t("Analytics.cleaning"),
        subLabel: `${cleaning_fees.total?.toLocaleString('fr-FR')} ${currency}`,
        info: t("Analytics.Tooltip.Details.Revenue.cleaning"),
        alert: true,
        distribution: cleaning_fees.distribution,
        distributionSymbol:currency ?? "€",
        statName: StatsNameKeysEnum.CLEANING_FEES,
      },
      {
        label: t("Analytics.net"),
        subLabel: `${net_revenue.total?.toLocaleString('fr-FR')} ${currency}`,
        info: t("Analytics.Tooltip.Details.Revenue.net"),
        alert: false,
        distribution: net_revenue.distribution,
        distributionSymbol:currency ?? "€",
        statName: StatsNameKeysEnum.REVENUE_NET,
      },
    ];
  };

  const breakdown = transformToBreakdown(
    data.revenue_breakdown,
    data.commissions,
    data.city_taxes,
    data.cleaning_fees,
    data?.net_revenue
  );

  return (
    <div className="mt-4 space-y-2 bg-element-border/25 mb-0">
      <div className="p-2 px-4 pb-0">
        {breakdown.map((item, index) => (
          <DetailsLineComponent
            item={item}
            openModalDetails={openModalDetails}
            border={index !== breakdown.length - 1}
            currency={currency}
          />
        ))}
      </div>
    </div>
  );
};

export default BreakdownTableComponent;
