import React, {useState} from "react";
import paths from "../constants/paths";
import {get, post} from "../helpers/APIHelper";
import {
  GuestWebsiteRental,
  GuestWebsiteRentalDetails,
  GuestWebsiteResult,
  GuestWebSiteSearchResponse,
  GuestWebsiteStep1Response,
  GuestWebsiteStep2Response,
  GuestWebsiteStep3Response,
  GuestWebsiteStep4ExtrasResponse,
  GuestWebsiteStep5ExtrasResponse,
} from "../types/GETTypes";
import {FormContactData} from "../components/GuestWebsite/Contact/Template2.0/FormComponent";
import {ReservationStep1Data, ReservationStep1Form} from "../components/GuestWebsite/Rental/Template2.0/RentalDetails/Home/RentalDetailsHomePage";
import {ReservationStep2Form} from "../components/GuestWebsite/Rental/Template2.0/RentalDetails/Info/RentalDetailInformationPage";
import {SearchBannerData, SearchBannerForm} from "../components/GuestWebsite/Common/Template2.0/SearchBannerComponent";
import {ReservationStep3Form} from "../components/GuestWebsite/Rental/Template2.0/RentalDetails/Payment/RentalDetailsPaymentPage";
import {ReservationStep4SendDataForm} from "../components/GuestWebsite/Rental/Template2.0/RentalDetails/Extras/RentalDetailsExtrasPage";
import {ReservationStep5SendDataForm} from "../components/GuestWebsite/Rental/Template2.0/RentalDetails/ExtrasPayment/RentalDetailsExtrasPaymentPage";

const useGuestWebsite = () => {
  const [isLoadingWebsite, setIsLoadingWebsite] = useState(true);
  const [isLoadingSearchRentals, setIsLoadingSearchRentals] = useState(false);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);
  const [isLoadingReservationStep1, setIsLoadingReservationStep1] =
    useState(false);
  const [isLoadingReservationStep2, setIsLoadingReservationStep2] =
    useState(false);
  const [isLoadingReservationStep3, setIsLoadingReservationStep3] =
    useState(false);
  const [isLoadingReservationStep4, setIsLoadingReservationStep4] =
    useState(false);
  const [isLoadingReservationStep5, setIsLoadingReservationStep5] =
    useState(false);
  const [isLoadingContact, setIsLoadingContact] = useState(false);
  const [dataWebsite, setData] = useState<GuestWebsiteResult>();
  const [dataRentalDetails, setDataRentalDetails] =
    useState<GuestWebsiteRentalDetails>();
  const [errorWebsite, setErrorWebsite] = useState<string>("");
  const [contactSend, setContactSend] = useState<boolean | undefined>(
    undefined
  );
  const [step1Data, setStep1Data] = useState<GuestWebsiteStep1Response>();
  const [step2Data, setStep2Data] = useState<GuestWebsiteStep2Response>();
  const [step3Data, setStep3Data] = useState<GuestWebsiteStep3Response>();
  const [step4Data, setStep4Data] = useState<GuestWebsiteStep4ExtrasResponse>();
  const [step5Data, setStep5Data] = useState<GuestWebsiteStep5ExtrasResponse>();
  const [dataSearch, setDataSearch] = useState<GuestWebsiteRental[]>();

  const getWebsite = async (websiteName: string) => {
    setErrorWebsite("");
    setIsLoadingWebsite(true);
    let dataToReturn: null | GuestWebsiteResult = null;
    let errorToReturn = true;
    const currentUrlWithoutQuery =
      window.location.origin + window.location.pathname;

    try {
      const res = await get(
        `${import.meta.env.VITE_API_URL}${paths.API.GUEST_WEBSITE.GET_WEBSITE(
          currentUrlWithoutQuery
        )}`
      );
      if (res?.data?.success) {
        const data = res?.data?.result as GuestWebsiteResult;
        if (data) {
          setData(data);
          errorToReturn = false;
          dataToReturn = data;
        }
        setErrorWebsite("");
      } else {
        setErrorWebsite(
          res?.data?.message ?? res?.response?.data?.message ?? res?.message
        );
        setData(undefined);
      }
    } catch (e: any) {
      console.log("🚀 ~ getWebsite ~ e:", e);
      setErrorWebsite(e?.message);
      setData(undefined);
    }
    setIsLoadingWebsite(false);
    return {dataToReturn, errorToReturn};
  };

  const searchRentals = async (data: SearchBannerData) => {
    setErrorWebsite("");
    setIsLoadingSearchRentals(true);
    let dataToReturn: null | GuestWebSiteSearchResponse = null;
    let errorToReturn = true;
    try {
      const res = await post(
        `${import.meta.env.VITE_API_URL}${paths.API.GUEST_WEBSITE.SEARCH}`,
        data
      );
      if (res?.data?.success) {
        const data = res?.data?.result as GuestWebsiteRental[];
        if (data) {
          setDataSearch(data);
          errorToReturn = false;
          dataToReturn = data;
        }
        setErrorWebsite("");
      } else {
        setErrorWebsite(
          res?.data?.message ?? res?.response?.data?.message ?? res?.message
        );
        setDataSearch(undefined);
      }
    } catch (e: any) {
      console.log("🚀 ~ getWebsite ~ e:", e);
      setErrorWebsite(e?.message);
      setDataSearch(undefined);
    }
    setIsLoadingSearchRentals(false);
    return {dataToReturn, errorToReturn};
  };

  const getRentalDetails = async (rentalId: string) => {
    setErrorWebsite("");
    setIsLoadingDetails(true);
    let dataToReturn: null | GuestWebsiteRentalDetails = null;
    let errorToReturn = true;
    try {
      const res = await get(
        `${
          import.meta.env.VITE_API_URL
        }${paths.API.GUEST_WEBSITE.GET_WEBSITE_RENTAL(rentalId)}`
      );
      if (res?.data?.success) {
        const data = res?.data?.result as GuestWebsiteRentalDetails;
        if (data) {
          setDataRentalDetails(data);
          errorToReturn = false;
          dataToReturn = data;
        }
        setErrorWebsite("");
      } else {
        setErrorWebsite(
          res?.data?.message ?? res?.response?.data?.message ?? res?.message
        );
        setDataRentalDetails(undefined);
      }
    } catch (e: any) {
      console.log("🚀 ~ getWebsite ~ e:", e);
      setErrorWebsite(e?.message);
      setDataRentalDetails(undefined);
    }
    setIsLoadingDetails(false);
    return {dataToReturn, errorToReturn};
  };

  const reservationStep1 = async (data: ReservationStep1Data) => {
    setErrorWebsite("");
    setIsLoadingReservationStep1(true);
    let dataToReturn: null | GuestWebsiteStep1Response = null;
    let errorToReturn = true;
    try {
      const res = await post(
        `${import.meta.env.VITE_API_URL}${
          paths.API.GUEST_WEBSITE.RESERVATION_STEP_1
        }`,
        data
      );
      if (res?.data?.success) {
        const data = res?.data?.result as GuestWebsiteStep1Response;
        if (data) {
          setStep1Data(data);
          errorToReturn = false;
          dataToReturn = data;
        }
      } else {
        setErrorWebsite(
          res?.data?.message ?? res?.response?.data?.message ?? res?.message
        );
        setStep1Data(undefined);
      }
    } catch (e: any) {
      console.log("🚀 ~ reservationStep1 ~ e:", e);
      setErrorWebsite(e?.message);
      setStep1Data(undefined);
    }
    setIsLoadingReservationStep1(false);
    return {dataToReturn, errorToReturn};
  };

  const reservationStep2 = async (data: ReservationStep2Form) => {
    setErrorWebsite("");
    setIsLoadingReservationStep2(true);
    let dataToReturn: null | GuestWebsiteStep2Response = null;
    let errorToReturn = true;
    try {
      const res = await post(
        `${import.meta.env.VITE_API_URL}${
          paths.API.GUEST_WEBSITE.RESERVATION_STEP_2
        }`,
        data
      );
      if (res?.data?.success) {
        const data = res?.data?.result as GuestWebsiteStep2Response;
        if (data) {
          setStep2Data(data);
          errorToReturn = false;
          dataToReturn = data;
        }
      } else {
        setErrorWebsite(
          res?.data?.message ?? res?.response?.data?.message ?? res?.message
        );
        setStep2Data(undefined);
      }
    } catch (e: any) {
      console.log("🚀 ~ reservationStep1 ~ e:", e);
      setErrorWebsite(e?.message);
      setStep2Data(undefined);
    }
    setIsLoadingReservationStep2(false);
    return {dataToReturn, errorToReturn};
  };

  const reservationStep3 = async (data: ReservationStep3Form) => {
    setErrorWebsite("");
    setIsLoadingReservationStep3(true);
    let dataToReturn: null | GuestWebsiteStep3Response = null;
    let errorToReturn = true;
    try {
      const res = await post(
        `${import.meta.env.VITE_API_URL}${
          paths.API.GUEST_WEBSITE.RESERVATION_STEP_3
        }`,
        data
      );
      if (res?.data?.success) {
        const data = res?.data?.result as GuestWebsiteStep3Response;
        if (data) {
          setStep3Data(data);
          errorToReturn = false;
          dataToReturn = data;
        }
      } else {
        setErrorWebsite(
          res?.data?.message ?? res?.response?.data?.message ?? res?.message
        );
        setStep3Data(undefined);
      }
    } catch (e: any) {
      console.log("🚀 ~ reservationStep1 ~ e:", e);
      setErrorWebsite(e?.message);
      setStep3Data(undefined);
    }
    setIsLoadingReservationStep3(false);
    return {dataToReturn, errorToReturn};
  };

  const reservationStep4 = async (data: ReservationStep4SendDataForm) => {
    setErrorWebsite("");
    setIsLoadingReservationStep4(true);
    let dataToReturn: null | GuestWebsiteStep4ExtrasResponse = null;
    let errorToReturn = true;
    try {
      const res = await post(
        `${import.meta.env.VITE_API_URL}${
          paths.API.GUEST_WEBSITE.RESERVATION_STEP_4
        }`,
        data
      );
      if (res?.data?.success) {
        const data = res?.data?.result as GuestWebsiteStep4ExtrasResponse;
        if (data) {
          setStep4Data(data);
          errorToReturn = false;
          dataToReturn = data;
        }
      } else {
        setErrorWebsite(
          res?.data?.message ?? res?.response?.data?.message ?? res?.message
        );
        setStep4Data(undefined);
      }
    } catch (e: any) {
      console.log("🚀 ~ reservationStep1 ~ e:", e);
      setErrorWebsite(e?.message);
      setStep4Data(undefined);
    }
    setIsLoadingReservationStep4(false);
    return {dataToReturn, errorToReturn};
  };
  const reservationStep5 = async (data: ReservationStep5SendDataForm) => {
    setErrorWebsite("");
    setIsLoadingReservationStep5(true);
    let dataToReturn: null | GuestWebsiteStep5ExtrasResponse = null;
    let errorToReturn = true;
    try {
      const res = await post(
        `${import.meta.env.VITE_API_URL}${
          paths.API.GUEST_WEBSITE.RESERVATION_STEP_5
        }`,
        data
      );
      if (res?.data?.success) {
        const data = res?.data?.result as GuestWebsiteStep5ExtrasResponse;
        if (data) {
          setStep5Data(data);
          errorToReturn = false;
          dataToReturn = data;
        }
      } else {
        setErrorWebsite(
          res?.data?.message ?? res?.response?.data?.message ?? res?.message
        );
        setStep5Data(undefined);
      }
    } catch (e: any) {
      console.log("🚀 ~ reservationStep1 ~ e:", e);
      setErrorWebsite(e?.message);
      setStep5Data(undefined);
    }
    setIsLoadingReservationStep5(false);
    return {dataToReturn, errorToReturn};
  };

  const contact = async (data: FormContactData) => {
    setErrorWebsite("");
    setIsLoadingContact(true);
    let dataToReturn: null | GuestWebsiteResult = null;
    let errorToReturn = true;
    try {
      const res = await post(
        `${import.meta.env.VITE_API_URL}${
          paths.API.GUEST_WEBSITE.SEND_CONTACT
        }`,
        data
      );
      if (res?.data?.success) {
        const data = res?.data?.result as GuestWebsiteResult;
        if (data) {
          setData(data);
          setContactSend(true);
          errorToReturn = false;
          dataToReturn = data;
        }
      } else {
        setContactSend(false);
        setErrorWebsite(
          res?.data?.message ?? res?.response?.data?.message ?? res?.message
        );
        setData(undefined);
      }
    } catch (e: any) {
      console.log("🚀 ~ getWebsite ~ e:", e);
      setContactSend(false);
      setErrorWebsite(e?.message);
      setData(undefined);
    }
    setIsLoadingContact(false);
    return {dataToReturn, errorToReturn};
  };

  return {
    isLoadingWebsite,
    setIsLoadingWebsite,
    errorWebsite,
    setErrorWebsite,
    getWebsite,
    dataWebsite,
    isLoadingSearchRentals,
    setIsLoadingSearchRentals,
    searchRentals,
    dataSearch,
    isLoadingDetails,
    setIsLoadingDetails,
    getRentalDetails,
    dataRentalDetails,
    isLoadingReservationStep1,
    setIsLoadingReservationStep1,
    reservationStep1,
    step1Data,
    isLoadingReservationStep2,
    setIsLoadingReservationStep2,
    reservationStep2,
    step2Data,
    isLoadingReservationStep3,
    setIsLoadingReservationStep3,
    reservationStep3,
    step3Data,
    isLoadingReservationStep4,
    setIsLoadingReservationStep4,
    reservationStep4,
    step4Data,
    isLoadingReservationStep5,
    setIsLoadingReservationStep5,
    reservationStep5,
    step5Data,
    isLoadingContact,
    setIsLoadingContact,
    contact,
    contactSend,
  };
};

export default useGuestWebsite;
