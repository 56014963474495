import moment from "moment";
import useText from "../../../hooks/useText";
import React, { ReactElement, useMemo } from "react";

import { FlexItemCenter } from "../../Common/FlexComponents/FlexItemCenter";
import { TitleText } from "../../Common/TextComponents/TitleText";
import { Button } from "../../Common/Button/Button";
import { FlexCenterBetween } from "../../Common/FlexComponents/FlexCenterBetween";

import AlertIcon from "../../../assets/icons/alert.svg?react";
import {
  CalendarReservationResponse,
  ReservationResponse,
  TaskPriorityType,
} from "../../../types/GETTypes";
import { useCheckAuth } from "../../../hooks/useCheckAuth";
import { ReservationForCalendarList } from "../../../pages/Booking/BookingPage.type";

export default function TaskNotAssigned({
  reservation,
  onDeny,
  onAccept,
  loading,
  taskType,
  lightComponent = false,
  customNotAssignedText,
}: {
  reservation:
    | CalendarReservationResponse
    | ReservationResponse
    | ReservationForCalendarList;
  loading: boolean;
  onDeny?: () => void;
  onAccept?: () => void;
  taskType: TaskPriorityType;
  lightComponent?: boolean;
  customNotAssignedText?: string;
}): ReactElement {
  const { getText } = useText("Calendar.Reservation");
  const { user } = useCheckAuth(true);

  const task = useMemo(() => {
    switch (taskType) {
      case "CHECKIN":
        return reservation.priority_tasks.checkin;
      case "CHECKOUT":
        return reservation.priority_tasks.checkout;
      case "CLEANING":
        return reservation.priority_tasks.cleaning;
    }
  }, [reservation.priority_tasks]);

  /**
   * Function for check if task is
   * deny by auth user.
   */
  const isAlreadyDenyByUserLogged = (): boolean => {
    let isAlreadyDeny = false;

    task.operators_tasks.map((operatorTask) => {
      if (
        operatorTask.operator_id === user?.id &&
        operatorTask.status == "DECLINED"
      ) {
        isAlreadyDeny = true;
        console.log("ici");
      }
    });

    return isAlreadyDeny;
  };

  return (
    <FlexCenterBetween gap={9}>
      <FlexItemCenter
        gap={7}
        className={"p-3 border border-gray-300 rounded-lg bg-white"}
      >
        <FlexItemCenter gap={10} className={"w-[130px]"}>
          <div
            className={
              "border-2 border-dashed rounded-full border-red-500 p-1.5"
            }
          >
            <AlertIcon className="w-5 h-5 bg-red-100 p-1 rounded-full" />
          </div>
          <TitleText className={"text-red-600 text-md"}>
            {customNotAssignedText
              ? customNotAssignedText
              : getText("noScheduled")}
          </TitleText>
        </FlexItemCenter>
        <FlexItemCenter gap={10}>
          {!isAlreadyDenyByUserLogged() ? (
            !loading ? (
              <Button type={"secondary"} onClick={onDeny}>
                {getText("deny")}
              </Button>
            ) : null
          ) : null}
          <Button onClick={onAccept} loading={loading}>
            {getText("accept")}
          </Button>
        </FlexItemCenter>
      </FlexItemCenter>
      {!lightComponent ? (
        <TitleText className={"text-md"}>
          {getText("date", {
            date: moment(reservation.checkout).format("DD/MM/YYYY"),
            hours: reservation.time_out.replace(":", "h"),
          })}
        </TitleText>
      ) : null}
    </FlexCenterBetween>
  );
}
