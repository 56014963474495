import React from "react";
import ServicesCardComponent from "./ServicesCardComponent";
import useGuestWebsiteStore from "../../../../stores/guestWebsiteStore/useGuestWebsiteStore";

interface GuestWebsiteTemplate2ServicesComponentProps {
  loading: boolean;
}
const GuestWebsiteTemplate2ServicesComponent = ({
  loading,
}: GuestWebsiteTemplate2ServicesComponentProps) => {
  const {dataGuestWebsite} = useGuestWebsiteStore();
  const {content, styles} = dataGuestWebsite || {};
  const {services} = content || {};
  const {color_background} = styles || {};
  const cards = services?.cards;
  return (
    <div
      className="py-12 px-4 md:px-contentWebsite pb-24"
      style={{backgroundColor: color_background}}
    >
      <div className="flex flex-wrap justify-center items-stretch gap-8">
        {cards?.map((card, index) => (
          <div className="w-full md:w-[290px] md:my-6 flex flex-col">
            <ServicesCardComponent key={index} {...card} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default GuestWebsiteTemplate2ServicesComponent;
