import React from "react";
import {AutoMessageLineSkeleton} from "./AutoMessageLineSkeleton";

export const AutoMessagesSkeleton: React.FC<{}> = () => {
  return (
      <table
          className="w-full border-separate table-auto overflow rounded-8px border-1 border-element-border border-spacing-0">
          {
              Array.from({length: 10}).map((v, index: number) => (
                  <AutoMessageLineSkeleton key={index}/>
              ))
          }
      </table>
  );
};
