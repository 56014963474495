import React from "react";
import {
  Bar,
  BarChart,
  Cell,
  Tooltip,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import PlatformTableComponent from "./PlatformTableComponent";
import {capitalizeFirstLetter} from "../../../helpers/stringHelper";
import {
  AnalyticsDetailedDistribution,
  AnalyticsDetailedDistributionObject,
} from "../../../types/GETTypes";
import AirbnbIcon from "../../../assets/icons/airbnb.svg?react";
import BookingIcon from "../../../assets/icons/booking.svg?react";
import SuperhoteIcon from "../../../assets/icons/superhote-circle.svg?react";
import OtherPlatformIcon from "../../../assets/icons/globe.svg?react";
import {useTranslation} from "react-i18next";

interface StackedChartProps {
  data: AnalyticsDetailedDistributionObject | undefined;
  barDataKey: string;
  chartWidth?: number;
  chartHeight?: number;
  chartBarHeight?: number;
  loading?: boolean;
  currency: string | undefined;
}

export interface StatsPlatformData {
  name: string;
  code: string;
  value: number | undefined;
  percentage: number;
  color: string;
  icon: React.JSX.Element;
}
const StackedBarChartComponent = ({
  data,
  barDataKey,
  chartWidth = 100,
  chartHeight = 70,
  chartBarHeight = 100,
  loading,
  currency,
}: StackedChartProps) => {
  const {t} = useTranslation();
  const stackedChartData: StatsPlatformData[] = [
    {
      name: "Airbnb",
      code: "airbnb",
      value: data?.airbnb.value,
      percentage: data?.airbnb.percentage ?? 0,
      color: "#FF5A5F",
      icon: <AirbnbIcon className="w-4 h-4 rounded-full mr-2" />,
    },
    {
      name: "Booking.com",
      code: "booking",
      value: data?.booking.value,
      percentage: data?.booking.percentage ?? 0,
      color: "#273B7D",
      icon: <BookingIcon className="w-4 h-4 rounded-full mr-2" />,
    },
    {
      name: t("Analytics.bookingDirect"),
      code: "superhote",
      value: data?.superhote.value,
      percentage: data?.superhote.percentage ?? 0,
      color: "#E54D2E",
      icon: <SuperhoteIcon className="w-4 h-4 rounded-full mr-2" />,
    },
    {
      name: t("Analytics.othersPlatform"),
      code: t("Analytics.others"),
      value: data?.others?.value ?? 0,
      percentage: data?.others?.percentage ?? 0,
      color: "#6F6F6F",
      icon: <OtherPlatformIcon className="w-4 h-4 rounded-full mr-2" />,
    },
  ];

  const stackedChartDataFormatted = [
    stackedChartData.reduce<Record<string, number>>((acc, item) => {
      acc[item.code] = item?.value ?? 0;
      return acc;
    }, {}),
  ];

  if (loading || !data) {
    return (
      <div className="w-full rounded-md animate-pulse mt-8">
        <div className="p-4">
          <div className="h-5 w-2/3 bg-gray-200 rounded mb-2"></div>
          <div className="h-16 w-full bg-gray-200 rounded"></div>
          <div className="pt-8">
            <div>
              <div className="flex items-center justify-between border-b py-2">
                <div className="flex items-center space-x-4">
                  <div className="h-4 w-4 bg-gray-200 rounded"></div>
                  <div>
                    <div className="w-24 h-4 bg-gray-200 rounded"></div>
                  </div>
                </div>
                <div className="flex space-x-4">
                  <span className="block h-4 w-10 bg-gray-200 rounded"></span>
                  <span className="block h-4 w-10 bg-gray-200 rounded"></span>
                </div>
              </div>
              <div className="flex items-center justify-between border-b py-2">
                <div className="flex items-center space-x-4">
                  <div className="h-4 w-4 bg-gray-200 rounded"></div>
                  <div>
                    <div className="w-24 h-4 bg-gray-200 rounded"></div>
                  </div>
                </div>
                <div className="flex space-x-4">
                  <span className="block h-4 w-10 bg-gray-200 rounded"></span>
                  <span className="block h-4 w-10 bg-gray-200 rounded"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className=" w-full border-1 border-element-border rounded-md">
      <div className="p-4">
        {/* Stacked Bar Chart */}
        <h2 className="font-bold">{t("Analytics.revenueByChannel")}</h2>
        <div className="flex flex-col">
          <div className="w-full h-8 mb-8">
            <ResponsiveContainer width="100%" height={chartHeight}>
              <BarChart
                data={stackedChartDataFormatted}
                layout="vertical"
                style={{borderRadius: 5}}
              >
                <Tooltip
                  labelFormatter={() => ""}
                  formatter={(value, name) => [
                    `${capitalizeFirstLetter(
                      name as string
                    )} : ${value.toLocaleString("fr-FR")} ${currency ?? "€"}`,
                  ]}
                  cursor={false}
                />
                <XAxis type="number" hide={true} />
                <YAxis type="category" dataKey="a" hide={true} />
                {stackedChartData.map((entry, index) => {
                  const maxValue = Math.max(
                    ...stackedChartData.map((item) => item.value ?? 0)
                  );
                  const threshold = maxValue * 0.01;
                  const firstNonZeroIndex = stackedChartData
                    .map((item) => item.value)
                    .findIndex((value) => value && value > threshold);

                  const lastNonZeroIndex = stackedChartData
                    .map((item) => item.value)
                    .findLastIndex((value) => value && value > threshold);
                  return (
                    <Bar
                      key={entry.code}
                      dataKey={entry.code}
                      stackId="a"
                      fill={entry.color}
                      radius={
                        index === firstNonZeroIndex 
                          ? [8, 0, 0, 8]
                          : index === lastNonZeroIndex
                          ? [0, 8, 8, 0]
                          : [0, 0, 0, 0]
                      }
                    />
                  );
                })}
              </BarChart>
            </ResponsiveContainer>
          </div>
          {/* Platform Breakdown */}
          <PlatformTableComponent data={stackedChartData} />
        </div>
      </div>
    </div>
  );
};

export default StackedBarChartComponent;
