import React from "react";

export const PaymentExtrasListPageSkeleton: React.FC<{}> = () => {
  return (
    <>
      <tr className="cursor-pointer hover:bg-element-background/40 animate-pulse">
        {/* reservation */}
        <td className="flex flex-row items-center w-full px-2 py-3 space-x-4 text-sm border-t-1 border-element-border">
          <div className="relative">
            <div className="w-10 h-10 rounded-4px border-1 border-element-border/50 bg-slate-200" />
          </div>

          <div className="flex flex-col">
            <p className="w-32 h-2 rounded bg-slate-300"></p>
            <p className="h-2 mt-2 rounded w-52 bg-slate-200"></p>
            <p className="w-12 h-2 mt-1 rounded bg-slate-200"></p>
          </div>
        </td>

        {/* guest */}
        <td className="px-2 py-2 border-t-1 border-element-border">
          <div className="flex justify-start">
            <p className="w-40 h-2 mt-2 rounded bg-slate-200"></p>
          </div>
        </td>

        {/* platform */}
        <td className="px-2 py-2 border-t-1 border-element-border">
          <div className="flex justify-start">
            <p className="w-40 h-2 mt-2 rounded bg-slate-200"></p>
          </div>
        </td>

        {/* reservation reference */}
        <td className="px-2 py-2 text-sm border-t-1 border-element-border">
          <div className="flex justify-center">
            <p className="w-40 h-2 mt-2 rounded bg-slate-200"></p>
          </div>
        </td>

        {/* status */}
        <td className="px-2 py-2 text-sm border-t-1 border-element-border">
          <div className="h-9 w-28 bg-slate-200 rounded-6px"></div>
        </td>

        {/* price */}
        <td className="px-2 py-2 border-t-1 border-element-border">
          <div className="flex justify-end">
            <p className="w-20 h-2 mt-2 rounded bg-slate-200"></p>
          </div>
        </td>
      </tr>
    </>
  );
};
