import React, {useEffect, useState} from "react";
import {
  InstructionContentsForm,
  InstructionModalProps,
} from "./InstructionCard.type";
import {RightModal} from "../../../Common/RightModal/RightModal";
import {useTranslation} from "react-i18next";
import {GroupButton} from "../../../Common/GroupButton/GroupButton";
import {ValueType} from "../../../../types/commonTypes";
import FrenchIcon from "../../../../assets/flags/France.svg?react";
import EnglishIcon from "../../../../assets/flags/USA.svg?react";
import SpanishIcon from "../../../../assets/flags/Spain.svg?react";
import GermanIcon from "../../../../assets/flags/Germany.svg?react";
import CheckIcon from "../../../../assets/icons/check-white.svg?react";
import CloseIcon from "../../../../assets/icons/close.svg?react";
import {Switch} from "../../../Common/Switch/Switch";
import {Controller, useForm} from "react-hook-form";
import {TextAreaInput} from "../../../Common/TextAreaInput/TextAreaInput";
import {Button} from "../../../Common/Button/Button";
import {ShortCodeList} from "../../../Message/ShortCodeList/ShortCodeList";
import {insertTextAtPosition} from "../../../../helpers/stringHelper";
import {Title} from "../../../Common/Title/Title";
import {MessageTemplatePhotoResponse} from "../../../../types/GETTypes";
import {PictureInput} from "../../../Common/PictureInput/PictureInput";
import {useUpdateInstruction} from "../../../../hooks/api/guestPage";
import {post} from "../../../../helpers/APIHelper";
import paths from "../../../../constants/paths";
import {ErrorMessage} from "../../../Common/ErrorMessage/ErrorMessage";
import {isImageFile, isVideoFile} from "../../../../helpers/fileHelper";

export const EditInstructionModal: React.FC<InstructionModalProps> = ({
  isVisible,
  onClose,
  onFinish,
  instruction,
  titleModal,
  rentalId,
  setListInstructions,
}) => {
  const {t} = useTranslation();
  const [currentLang, setCurrentLang] = useState<ValueType>("french");
  const [disabled, setDisabled] = useState<boolean>(false);
  const [displayInstruction, setDisplayInstruction] = useState<boolean>(
    instruction?.infos_enabled === 1 ? true : false
  );
  const [errorLang, setErrorLang] = useState("");
  const handleChangeLang = (nextLang: ValueType) => {
    setCurrentLang(nextLang);
  };
  const form = useForm<InstructionContentsForm>({
    defaultValues: {
      fr: {message: ""},
      en: {message: ""},
      de: {message: ""},
      es: {message: ""},
    },
  });

  const listLanguages = {
    french: "fr",
    english: "en",
    spanish: "es",
    german: "de",
  };

  const [oldFilesTemp, setOldFilesTemp] = useState<
    MessageTemplatePhotoResponse[]
  >([]);

  const [oldPhotosVideosTemp, setOldPhotosVideosTemp] = useState<
    MessageTemplatePhotoResponse[]
  >([]);
  const [photoIdsToDelete, setPhotoIdsToDelete] = useState<ValueType[]>([]);
  const [videoIdsToDelete, setVideoIdsToDelete] = useState<ValueType[]>([]);
  const [fileIdsToDelete, setFileIdsToDelete] = useState<ValueType[]>([]);
  const [newFilesTemp, setNewFilesTemp] = useState<Blob[]>([]);
  const [newPhotosVideosTemp, setNewPhotosVideosTemp] = useState<Blob[]>([]);

  const [loadingValidation, setLoadingValidation] = useState<boolean>(false);
  const [messageCursorPosition, setMessageCursorPosition] = useState<number>(0);
  const [error, setError] = useState<string | undefined>();
  const [errorPhoto, setErrorPhoto] = useState<string | undefined>();

  const handleInsertShortCode = (trigger: string) => {
    const {fr, en, es, de} = form.getValues();
    const lang = currentLang as string;
    const value =
      lang === "french"
        ? fr
        : lang === "english"
        ? en
        : lang === "spanish"
        ? es
        : de;
    const newValue = insertTextAtPosition(
      value.message,
      trigger,
      messageCursorPosition
    );
    form.setValue(listLanguages[lang], {...value, message: newValue});
  };

  const [isShortcodesVisible, setShortcodesVisible] = useState<boolean>(false);

  const getTitle = () => {
    if (isShortcodesVisible)
      return (
        <div className="flex">
          <div className="flex justify-between w-modal">
            <Title>{t("Templates.shortcodes")}</Title>
            <div
              className="pt-1 hover:cursor-pointer mr-6"
              onClick={() => setShortcodesVisible(false)}
            >
              <CloseIcon />
            </div>
          </div>
          <div className="flex-1">
            <Title>{titleModal}</Title>
          </div>
        </div>
      );
    else return titleModal;
  };

  const handleDisplayInstruction = () => {
    setDisplayInstruction(!displayInstruction);
  };

  const handleUpdateInstruction = async (value: any) => {
    if (
      displayInstruction &&
      !value.fr?.message &&
      !value.en?.message &&
      !value.de?.message &&
      !value.es?.message
    ) {
      setErrorLang(t("AutoMessageList.Upsert.Contents.contentLangRequired"));
      return;
    }
    setErrorLang("");

    const instructionData = {
      ...instruction,
      content: JSON.stringify({
        fr: value.fr.message,
        en: value.en.message,
        de: value.de.message,
        es: value.es.message,
      }),
      infos_enabled: displayInstruction ? 1 : 0,
      key_box_code: null,
      entry_code: null,
    };

    setDisabled(true);
    try {
      const {success: videoSuccess, message: messageVideo} =
        await updateVideos();
      if (!videoSuccess) throw new Error(messageVideo);

      const {success: photoSuccess, message: messagePhoto} =
        await updatePhotos();
      if (!photoSuccess) throw new Error(messagePhoto);

      const {success: fileSuccess, message: messageFile} = await updateFiles();
      if (!fileSuccess) throw new Error(messageFile);

      useUpdateInstruction(
        rentalId,
        instruction.id,
        instructionData,
        (rental) => {
          setListInstructions(rental.guest_page.instructions);
          setError("");
          onClose();
          setDisabled(false);
        },
        (message: string | undefined) => {
          console.log(message, "[message]");
          setError(message);
          setDisabled(false);
        }
      );
    } catch (error) {
      console.error(error);
      setError((error as Error).message);
      setDisabled(false);
    }
  };

  const appendByMimeType = (data: FormData, blobFile: Blob[]) => {
    blobFile.forEach((file, i) => {
      if (isImageFile(file.type)) {
        data.append(`photos[${i}]`, file);
      } else if (isVideoFile(file.type)) {
        data.append(`videos[${i}]`, file);
      } else {
        data.append(`files[${i}]`, file);
      }
    });
  };

  const updateVideos = async (): Promise<{
    success: boolean;
    message: string;
  }> => {
    setLoadingValidation(true);
    const updateVideosData = new FormData();
    updateVideosData.append(
      "del_videos_ids",
      [...new Set(videoIdsToDelete)].join(",")
    );
    appendByMimeType(updateVideosData, newPhotosVideosTemp);

    try {
      const response = await post(
        `${import.meta.env.VITE_API_URL}${
          paths.API.RENTALS_UPDATE
        }/${rentalId}${paths.API.GUEST_PAGE.INDEX}${
          paths.API.GUEST_INSTRUCTION
        }/${instruction.id}/videos`,
        updateVideosData
      );
      if (!response.data?.success) {
        const msgError =
          response?.response?.data?.message || "Failed to update videos";
        setError(msgError);
        return {success: false, message: msgError};
      }
      return {success: true, message: ""};
    } catch (error) {
      setError((error as Error).message);
      return {success: false, message: (error as Error).message};
    } finally {
      setLoadingValidation(false);
    }
  };

  const updatePhotos = async (): Promise<{
    success: boolean;
    message: string;
  }> => {
    setLoadingValidation(true);
    const updatePhotosData = new FormData();
    updatePhotosData.append(
      "del_photo_ids",
      [...new Set(photoIdsToDelete)].join(",")
    );
    appendByMimeType(updatePhotosData, newPhotosVideosTemp);

    try {
      const response = await post(
        `${import.meta.env.VITE_API_URL}${
          paths.API.RENTALS_UPDATE
        }/${rentalId}${paths.API.GUEST_PAGE.INDEX}${
          paths.API.GUEST_INSTRUCTION
        }/${instruction.id}/photos`,
        updatePhotosData
      );
      if (!response.data?.success) {
        const msgError =
          response?.response?.data?.message || "Failed to update photos";
        setError(msgError);
        return {success: false, message: msgError};
      }
      return {success: true, message: ""};
    } catch (error) {
      setError((error as Error).message);
      return {success: false, message: (error as Error).message};
    } finally {
      setLoadingValidation(false);
    }
  };

  const updateFiles = async (): Promise<{
    success: boolean;
    message: string;
  }> => {
    setLoadingValidation(true);
    const updateFilesData = new FormData();
    updateFilesData.append(
      "del_files_ids",
      [...new Set(fileIdsToDelete)].join(",")
    );
    appendByMimeType(updateFilesData, newFilesTemp);

    try {
      const response = await post(
        `${import.meta.env.VITE_API_URL}${
          paths.API.RENTALS_UPDATE
        }/${rentalId}${paths.API.GUEST_PAGE.INDEX}${
          paths.API.GUEST_INSTRUCTION
        }/${instruction.id}/files`,
        updateFilesData
      );
      if (!response.data?.success) {
        const msgError =
          response?.response?.data?.message || "Failed to update files";
        setError(msgError);
        return {success: false, message: msgError};
      }
      return {success: true, message: ""};
    } catch (error) {
      setError((error as Error).message);
      return {success: false, message: (error as Error).message};
    } finally {
      setLoadingValidation(false);
    }
  };

  const handleChangeCursor = (event: any) => {
    setMessageCursorPosition(event.target.selectionStart);
  };

  useEffect(() => {
    if (instruction.content) {
      form.setValue("fr", {message: instruction.content.fr ?? ""});
      form.setValue("en", {message: instruction.content.en ?? ""});
      form.setValue("de", {message: instruction.content.de ?? ""});
      form.setValue("es", {message: instruction.content.es ?? ""});
    }
    setDisplayInstruction(instruction?.infos_enabled === 1 ? true : false)
    setOldPhotosVideosTemp(
      instruction.files.filter(
        (file: any) =>
          isImageFile(file.mime_type) || isVideoFile(file.mime_type)
      )
    );
    setOldFilesTemp(
      instruction.files.filter(
        (file: any) =>
          !isImageFile(file.mime_type) && !isVideoFile(file.mime_type)
      )
    );
    setNewFilesTemp([]);
    setNewPhotosVideosTemp([]);
    setFileIdsToDelete([]);
    setPhotoIdsToDelete([]);
    setVideoIdsToDelete([]);
    setLoadingValidation(false);
    setDisabled(false);
    setErrorPhoto("");
  }, [instruction.content,instruction?.infos_enabled]);

  return (
    <RightModal
      isVisible={isVisible}
      onClose={onClose}
      isLarge={false}
      title={getTitle()}
      isSplit={isShortcodesVisible}
      closeOnEmpty={false}
    >
      {isShortcodesVisible && (
        <div className="flex-1 overflow-y-scroll pr-8">
          <ShortCodeList onInsert={handleInsertShortCode} />
        </div>
      )}
      <div
        className={`flex flex-col w-full h-full ${
          isShortcodesVisible ? "w-modal-inside" : "w-full"
        }`}
      >
        <form
          className="flex-1 overflow-y-auto w-full"
          onSubmit={form.handleSubmit(handleUpdateInstruction)}
        >
          {error ? <ErrorMessage>{error}</ErrorMessage> : null}
          <div className="flex flex-col h-full w-full">
            <div
              className={`"flex flex-1 flex-col gap-3 ${
                isShortcodesVisible && "ml-4"
              }`}
            >
              <div className="flex flex-1 flex-col gap-3 w-full">
                <div className="flex flex-col gap-3">
                  <div className="flex flex-row justify-between">
                    <p className="text-sm font-bold">Visible</p>
                    <Switch
                      value={displayInstruction}
                      onClick={() => handleDisplayInstruction()}
                    />
                  </div>
                  <p className="text-low-contrast">
                    {t("Rental.Infos.TravelerPage.InstructionCard.editTitle")}
                  </p>
                </div>
                <GroupButton
                  items={[
                    {
                      label: t("AutoMessageList.Upsert.Contents.french"),
                      value: "french",
                      Icon: FrenchIcon,
                      isActive: currentLang === "french",
                    },
                    {
                      label: t("AutoMessageList.Upsert.Contents.english"),
                      value: "english",
                      Icon: EnglishIcon,
                      isActive: currentLang === "english",
                    },
                    {
                      label: t("AutoMessageList.Upsert.Contents.spanish"),
                      value: "spanish",
                      Icon: SpanishIcon,
                      isActive: currentLang === "spanish",
                    },
                    {
                      label: t("AutoMessageList.Upsert.Contents.german"),
                      value: "german",
                      Icon: GermanIcon,
                      isActive: currentLang === "german",
                    },
                  ]}
                  onClick={handleChangeLang}
                />

                {currentLang === "french" && (
                  <Controller
                    control={form.control}
                    name="fr"
                    render={({field: {value, onChange}}) => {
                      return (
                        <TextAreaInput
                          label={t("GuestPage.Instruction.content")}
                          placeholder={t(
                            "AutoMessageList.Upsert.Contents.messagePlaceholder"
                          )}
                          required={displayInstruction}
                          onSelect={handleChangeCursor}
                          error={form.formState.errors?.fr?.message}
                          value={value?.message ?? ""}
                          onTextChange={(message) => {
                            onChange({...value, message});
                          }}
                        />
                      );
                    }}
                  />
                )}

                {currentLang === "english" && (
                  <Controller
                    control={form.control}
                    name="en"
                    render={({field: {value, onChange}}) => {
                      return (
                        <TextAreaInput
                          label={t("GuestPage.Instruction.content")}
                          placeholder={t(
                            "AutoMessageList.Upsert.Contents.messagePlaceholder"
                          )}
                          required={displayInstruction}
                          onSelect={handleChangeCursor}
                          error={form.formState.errors.en?.message}
                          value={value?.message ?? ""}
                          onTextChange={(message) => {
                            onChange({...value, message});
                          }}
                        />
                      );
                    }}
                  />
                )}

                {currentLang === "german" && (
                  <Controller
                    control={form.control}
                    name="de"
                    render={({field: {value, onChange}}) => {
                      return (
                        <TextAreaInput
                          label={t("GuestPage.Instruction.content")}
                          placeholder={t(
                            "AutoMessageList.Upsert.Contents.messagePlaceholder"
                          )}
                          required={displayInstruction}
                          onSelect={handleChangeCursor}
                          error={form.formState.errors.de?.message}
                          value={value?.message ?? ""}
                          onTextChange={(message) => {
                            onChange({...value, message});
                          }}
                        />
                      );
                    }}
                  />
                )}

                {currentLang === "spanish" && (
                  <Controller
                    control={form.control}
                    name="es"
                    render={({field: {value, onChange}}) => {
                      return (
                        <TextAreaInput
                          label={t("GuestPage.Instruction.content")}
                          placeholder={t(
                            "AutoMessageList.Upsert.Contents.messagePlaceholder"
                          )}
                          required={displayInstruction}
                          onSelect={handleChangeCursor}
                          error={form.formState.errors.es?.message}
                          value={value?.message ?? ""}
                          onTextChange={(message) => {
                            onChange({...value, message});
                          }}
                        />
                      );
                    }}
                  />
                )}

                <ErrorMessage>{errorLang}</ErrorMessage>

                <div className="flex flex-col gap-5">
                  <p className="text-xs text-low-contrast">
                    {t(
                      "Rental.Infos.TravelerPage.InstructionCard.addShortCodeTitle"
                    )}
                  </p>
                  <div
                    className="flex justify-between items-center font-light text-low-contrast cursor-pointer"
                    onClick={() => setShortcodesVisible(!isShortcodesVisible)}
                  >
                    <p>{t("AutoMessageList.Upsert.Contents.shortcodes")}</p>
                    <Switch value={isShortcodesVisible} />
                  </div>

                  <div className="flex flex-col gap-3">
                    <p className="font-bold">
                      {t(
                        "Rental.Infos.TravelerPage.InstructionCard.photosOrVideos"
                      )}
                    </p>
                    <div className="flex flex-col flex-1 gap-y-3">
                      <PictureInput
                        disabled={loadingValidation}
                        mode="all"
                        oldFiles={oldPhotosVideosTemp}
                        newFiles={newPhotosVideosTemp}
                        onChangeOldFiles={(files) =>
                          setOldPhotosVideosTemp(files)
                        }
                        onChangeNewFiles={(files) =>
                          setNewPhotosVideosTemp(files)
                        }
                        onDeleteFile={(id, mimeType) => {
                          if (mimeType) {
                            if (isImageFile(mimeType)) {
                              setPhotoIdsToDelete((prevValue) => [
                                ...prevValue,
                                id,
                              ]);
                            } else if (isVideoFile(mimeType)) {
                              setVideoIdsToDelete((prevValue) => [
                                ...prevValue,
                                id,
                              ]);
                            }
                          }
                        }}
                        title="Global.addPhotoAndVideo"
                        displayCover={false}
                        setErrors={setErrorPhoto}
                      />
                      {errorPhoto ? (
                        <ErrorMessage>{errorPhoto}</ErrorMessage>
                      ) : null}
                      <div className="flex flex-col gap-y-1">
                        <span className="text-xs text-low-contrast">
                          {t(
                            "Rental.Infos.TravelerPage.InstructionCard.photosVideosFormat"
                          )}
                        </span>
                        <span className="text-xs text-low-contrast">
                          {t(
                            "Rental.Infos.TravelerPage.InstructionCard.photosSize"
                          )}
                        </span>
                        <span className="text-xs text-low-contrast">
                          {t(
                            "Rental.Infos.TravelerPage.InstructionCard.videosSize"
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-3">
                    <div className="flex flex-col gap-3">
                      <p className="font-bold">
                        {t("Rental.Infos.TravelerPage.InstructionCard.file")}
                      </p>
                      <PictureInput
                        disabled={loadingValidation}
                        mode="all"
                        type="file"
                        oldFiles={oldFilesTemp}
                        newFiles={newFilesTemp}
                        onChangeOldFiles={(files) => setOldFilesTemp(files)}
                        onChangeNewFiles={(files) => setNewFilesTemp(files)}
                        onDeleteFile={(id) =>
                          setFileIdsToDelete((prevValue) => [...prevValue, id])
                        }
                        title="Global.addFileHere"
                      />
                      <span className="text-xs text-low-contrast">
                        {t(
                          "Rental.Infos.TravelerPage.InstructionCard.descriptionFile"
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex gap-3 border-t-1 border-element-border pt-4 m-2">
              <Button type="secondary" onClick={onClose}>
                {t("Global.cancel")}
              </Button>
              <Button
                RightIcon={CheckIcon}
                disabled={disabled}
                loading={disabled}
                onClick={() => {}}
              >
                {t("Profil.validate")}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </RightModal>
  );
};
