import React from "react";
import {useTranslation} from "react-i18next";
import {Button} from "../../Common/Button/Button";
import {TableListItemProps} from "../../Common/TableList/TableList.type";

import AirbnbIcon from "../../../assets/icons/airbnb.svg?react";
import AirbnbCoverIcon from "../../../assets/icons/aircover.svg?react";
import BookingIcon from "../../../assets/icons/booking.svg?react";
import EditIcon from "../../../assets/icons/edit.svg?react";
import LogInIcon from "../../../assets/icons/log-in.svg?react";
import LogOutIcon from "../../../assets/icons/log-out.svg?react";
import UserGroupIcon from "../../../assets/icons/user-group.svg?react";
import {RentalLightListItemResponse} from "../../../types/GETTypes";
import {getFormattedPrice} from "../../../helpers/currencyHelper";
import {getBookingDepositText} from "../../../helpers/platformHelper";

const CautionBlock = ({rental}: {rental: RentalLightListItemResponse}) => {
  const {t} = useTranslation();
  const isAirbnb = rental.external_connexions?.platform_airbnb;
  const isBooking = rental.external_connexions?.platform_booking;
  const isDirect = rental.deposit ? true : false;
  return (
    <div className="flex flex-col bg-element-border/30 p-1 rounded space-y-2">
      {isAirbnb ? (
        <div className="flex space-x-2 items-center">
          <AirbnbIcon />
          <AirbnbCoverIcon />
        </div>
      ) : null}
      {isBooking ? (
        <div className="flex space-x-2 items-center">
          <BookingIcon className="h-8 w-8" />
          <p className="font-bold text-xs">
            {getBookingDepositText(
              rental.external_connexions?.platform_booking,
              t
            )}
          </p>
        </div>
      ) : null}
      {isDirect ? (
        <div className="flex space-x-1 item-center">
          <p className="font-bold text-xs bg-element-border rounded p-1">
            {t("Progression.RentalsRules.Deposit.Direct.direct")}
          </p>
          <p className="font-bold text-xs p-1">
            {getFormattedPrice({price: rental.deposit, decimals: 0})}
          </p>
        </div>
      ) : null}
    </div>
  );
};

export const ProgressionRentalsRulesTableItem: React.FC<TableListItemProps> = ({
  data,
  onClick = () => {},
}) => {
  const {t} = useTranslation();
  const rental: RentalLightListItemResponse = data;

  return (
    <tr
      className="cursor-pointer hover:bg-element-background/30"
      onClick={() => onClick(rental.id!)}
    >
      {/* Rental */}
      <td className="px-4 py-2 text-sm border-t-1 border-element-border">
        <div className="flex flex-row items-center gap-2">
          <div className="flex-shrink-0 object-fill w-12 h-12 overflow-hidden rounded-4px border-1 border-element-border/50">
            <img
              src={rental.cover}
              alt={rental?.name}
              className="flex-shrink-0 object-fill w-12 h-12"
            />
          </div>

          <div className="flex flex-col max-w-[300px]">
            <p className="font-semibold text-high-contrast">{rental?.name}</p>
            <p className="font-light text-low-contrast">{rental?.address}</p>
            <p className="font-light text-low-contrast">
              {`${rental?.postal_code} ${rental?.city}, ${rental?.country.name}`}
            </p>
          </div>
        </div>
      </td>

      {/* CAPACITY */}
      <td className="p-2 border-t-1 border-element-border w-52">
        <div className="flex items-center justify-center h-full gap-2">
          <UserGroupIcon />
          <div>
            <p className="font-light text-low-contrast">
              {rental?.guests_max_capacity > 1
                ? t("Progression.RentalsRules.guests", {
                    count: rental?.guests_max_capacity,
                  })
                : t("Progression.RentalsRules.guest", {
                    count: rental?.guests_max_capacity ?? 0,
                  })}
            </p>
          </div>
        </div>
      </td>

      {/* Checkin / Checkout */}
      <td className="p-2 border-t-1 border-element-border">
        {rental?.checkin_min_time || rental?.checkout_max_time ? (
          <div className="flex flex-col items-center gap-2">
            {rental?.checkin_min_time && (
              <div className="flex flex-row space-x-1 text-low-contrast">
                <LogInIcon />
                <span>{rental?.checkin_min_time}</span>
              </div>
            )}
            <div className="flex flex-row space-x-1 text-low-contrast">
              <LogOutIcon />
              <span>{rental?.checkout_max_time ?? "-"}</span>
            </div>
          </div>
        ) : (
          <p className="text-base font-light text-center text-low-contrast">
            {t("Global.notDefined")}
          </p>
        )}
      </td>

      {/* Price */}
      <td className="w-48 p-2 border-t-1 border-element-border justify-center items-center">
        <div className="flex flex-col justify-center space-y-2">
          <div className="flex space-x-1 justify-center">
            <p className="text-xs font-light text-center text-low-contrast">
              {t("Progression.RentalsRules.minPrice")}
            </p>
            <p className="text-xs font-semibold text-center text-low-contrast">
              {getFormattedPrice({
                price: rental.price_night_min ?? 0,
                decimals: 0,
              })}
            </p>
          </div>
          <div className="flex space-x-1 justify-center">
            <p className="text-xs font-light text-center text-low-contrast">
              {t("Progression.RentalsRules.defaultPrice")}
            </p>
            <p className="text-xs font-bold text-center text-low-contrast">
              {getFormattedPrice({
                price: rental.price_night_default ?? 0,
                decimals: 0,
              })}
            </p>
          </div>
        </div>
      </td>

      {/* Deposit */}
      <td className="w-64 p-2 border-t-1 border-element-border">
        <CautionBlock rental={rental} />
      </td>

      {/* Fees */}
      <td className="w-56 p-2 border-t-1 border-element-border justify-center items-center">
        <div className="flex flex-col justify-center space-y-2">
          <div className="flex space-x-1 justify-center">
            <p className="text-xs font-light text-center text-low-contrast">
              {t("Progression.RentalsRules.cleaningFees")}
            </p>
            <p className="text-xs font-semibold text-center text-low-contrast">
              {getFormattedPrice({
                price: rental.cleaning_default ?? 0,
                decimals: 0,
              })}
            </p>
          </div>
          <div className="flex space-x-1 justify-center">
            <p className="text-xs font-light text-center text-low-contrast">
              {t("Progression.RentalsRules.extraTraveller")}
            </p>
            <p className="text-xs font-bold text-center text-high-contrast">
              +
              {getFormattedPrice({
                price: rental.price_night_added_per_guests ?? 0,
                decimals: 0,
              })}
            </p>
          </div>
        </div>
      </td>

      {/* Edit button */}
      <td className="p-4 w-28 border-t-1 border-element-border">
        <Button
          type="secondary"
          onClick={() => onClick(rental.id!)}
          RightIcon={EditIcon}
          size="small"
        />
      </td>
    </tr>
  );
};
