import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {MainLayout} from "../../components/Layout/MainLayout/MainLayout";
import {AirbnbConnectModal} from "../../components/Modal/Airbnb/Connect/AirbnbConnectModal";
import {
  BookingConnectData,
  BookingConnectModal,
} from "../../components/Modal/Booking/Connect/BookingConnectModal";
import {ListingImportFinishModal} from "../../components/Progression/ListingImportFinishModal";
import {
  Progression,
  ProgressionStatusEnum,
} from "../../components/Progression/Progression.type";
import {ProgressionRightSide} from "../../components/Progression/ProgressionRightSide";
import {RulesSyncFinishModal} from "../../components/Progression/RulesSyncFinishModal";
import {ProgressionStepAirbnb} from "../../components/Progression/Steps/Airbnb";
import {ProgressionStepGift} from "../../components/Progression/Steps/Gift";
import {ProgressionStepMessages} from "../../components/Progression/Steps/Messages";
import {ProgressionStepNone} from "../../components/Progression/Steps/None";

import {ProgressionStepRentals} from "../../components/Progression/Steps/Rentals";
import {ProgressionStepRules} from "../../components/Progression/Steps/Rules";
import paths from "../../constants/paths";
import {get, post} from "../../helpers/APIHelper";
import {ProgressionPageProps} from "./ProgressionPage.type";

import {AddStripeAccountErrorModal} from "../../components/Payment/AccountList/AddStripeAccountErrorModal";
import {AddStripeAccountModal} from "../../components/Payment/AccountList/AddStripeAccountModal";
import {AddStripeAccountSuccessModal} from "../../components/Payment/AccountList/AddStripeAccountSuccessModal";
import {ListingImportWaitingModal} from "../../components/Progression/ListingImportWaitingModal";
import {RulesSyncWaitingModal} from "../../components/Progression/RulesSyncWaitingModal";
import {ProgressionStepBooking} from "../../components/Progression/Steps/Booking";
import {ProgressionStepConfigDirectReservations} from "../../components/Progression/Steps/ConfigDirectReservations/ConfigDirectReservations";
import {DIRECT_RESERVATIONS_STEP} from "../../components/Progression/Steps/ConfigDirectReservations/Modal/ConfigDirectReservations.type";
import {ProgressionConfigDirectReservationsModal} from "../../components/Progression/Steps/ConfigDirectReservations/Modal/ConfigDirectReservationsModal";
import {ProgressionConnectStripe} from "../../components/Progression/Steps/ConnectStripe";
import {ProgressionStepEnum} from "../../enums/GETenums";
import {useCompleteCurrentStepProgression} from "../../hooks/api/progression";
import {useCheckAuth} from "../../hooks/useCheckAuth";
import {useModal} from "../../hooks/useModal";
import {useTablePage} from "../../hooks/useTablePage";
import useProgressionStore from "../../stores/useProgressStore";
import {WorkspaceResponse} from "../../types/GETTypes";

export const ProgressionPage: React.FC<ProgressionPageProps> = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {triggerRefreshProgression} = useProgressionStore();

  const queryParameters = new URLSearchParams(window.location.search);
  const syncInProgress = queryParameters.get("sync_in_progress");

  const [isAirbnbConnectModalVisible, setAirbnbConnectModalVisible] =
    useState(false);
  const [isBookingConnectModalVisible, setBookingConnectModalVisible] =
    useState(false);

  const [
    isListingImportWaitingPopupVisible,
    setIsListingImportWaitingPopupVisible,
  ] = useState<boolean>(false);

  const [isSyncWaitingPopupVisible, setIsSyncWaitingPopupVisible] =
    useState<boolean>(false);
  const [addStripeSuccessVisible, setAddStripeSuccessVisible] =
    useState<boolean>(false);
  const [addStripeErrorVisible, setAddStripeErrorVisible] =
    useState<boolean>(false);

  const [currentProgression, setCurrentProgression] = useState<Progression>({
    step: ProgressionStepEnum.STEP_NONE,
    status: ProgressionStatusEnum.INCOMPLETE,
    processedJobs: 0,
    totalJobs: 0,
    progress: null,
    progressText: "",
    bookingAlreadyAssociated: false,
  });
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const [allSteps, setAllSteps] = useState<ProgressionStepEnum[]>([
    ProgressionStepEnum.STEP_NONE,
  ]);

  const [bookingConnectData, setBookingConnectData] =
    useState<BookingConnectData>();

  const getProgressionStatus = (
    step: ProgressionStepEnum
  ): "active" | "inactive" | "success" | "error" | "pending" => {
    const stepsIndexes = Object.entries(ProgressionStepEnum).map(
      (entry) => entry[1]
    );

    const stepIndex = stepsIndexes.findIndex((s) => s === step);
    const currentStepIndex = stepsIndexes.findIndex(
      (s) => s === currentProgression.step
    );

    if (stepIndex > currentStepIndex) "inactive";
    else if (
      stepIndex === currentStepIndex &&
      currentProgression.status === ProgressionStatusEnum.ERROR
    )
      return "error";
    else if (
      stepIndex === currentStepIndex &&
      currentProgression.status === ProgressionStatusEnum.PENDING
    )
      return "pending";
    else if (
      stepIndex === currentStepIndex &&
      currentProgression.status === ProgressionStatusEnum.COMPLETE &&
      currentProgression.step === ProgressionStepEnum.STEP_GIFT
    )
      return "success";
    else if (stepIndex === currentStepIndex) return "active";
    else if (stepIndex < currentStepIndex) return "success";
    return "inactive";
  };
  const {user, setUser} = useCheckAuth();

  const updateCurrentProgression = (response: any) => {
    const result = response?.data?.result;
    const newUser = result?.user;
    setUser(newUser);

    setCurrentProgression({
      step: result?.progression_step,
      status: result?.progression_step_status,
      processedJobs: result?.progression_step_pending_processed_jobs,
      totalJobs: result?.progression_step_pending_total_jobs,
      progress: result?.progression_step_pending_progress_percents,
      progressText: result?.progression_step_pending_progress_text,
      bookingAlreadyAssociated:
        result?.progression_step_booking_already_associated,
    });
  };

  const loopProgressionStatus = () => {
    const interval = setInterval(async () => {
      const response = await get(
        `${import.meta.env.VITE_API_URL}${paths.API.PROGRESSION}`
      );
      updateCurrentProgression(response);
      triggerRefreshProgression();

      if (
        response.data?.success &&
        response.data?.result?.progression_step_status !==
          ProgressionStatusEnum.PENDING
      ) {
        clearInterval(interval);
      } else {
        setError(response?.response?.data?.message);
      }
    }, 2 * 1000);
  };

  const fetchAllSteps = async () => {
    const response = await get(
      `${import.meta.env.VITE_API_URL}${paths.API.ALL_STEPS}`
    );

    if (response.data.success) {
      setAllSteps(JSON.parse(response.data.result.progression_steps));
    }

    fetchProgression();
  };

  const fetchProgression = async () => {
    const response = await get(
      `${import.meta.env.VITE_API_URL}${paths.API.PROGRESSION}`
    );

    if (response?.data?.success) {
      updateCurrentProgression(response);

      if (
        response?.data?.result?.progression_step_status ===
        ProgressionStatusEnum.PENDING
      ) {
        loopProgressionStatus();
      }
    } else {
      setError(response?.response?.data?.message);
    }

    setLoading(false);
  };

  const completeCurrentStep = async () => {
    setError(null);
    setLoading(true);

    const response = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.COMPLETE_PROGRESSION_STEP}`,
      {progression_step: currentProgression.step}
    );

    if (!response?.data?.success) {
      setError(response?.response?.data?.message);
      setLoading(false);
    } else {
      fetchProgression();
    }
  };

  const queryCode = queryParameters.get("code");
  const queryError = queryParameters.get("error");

  useEffect(() => {
    if (queryCode && queryCode !== "") {
      addStripeModal.close();
      setAddStripeErrorVisible(false);

      if (
        currentProgression.step ===
        ProgressionStepEnum.STEP_CONNECT_STRIPE_FOR_EXTRA_ORDERS
      ) {
        setAddStripeSuccessVisible(true);
      }
    }
  }, [queryCode, currentProgression.step]);

  const handleFinishAirbnb = () => {
    console.log("AIBNB FINISHED !!!");

    setAirbnbConnectModalVisible(false);
    setIsListingImportWaitingPopupVisible(true);
    // DEBUG
    setTimeout(() => {
      loopProgressionStatus();
    }, 2 * 1000);
  };

  const handleFinishAirbnbDebug = () => {
    handleFinishAirbnb();

    setCurrentProgression({
      progress: 0,
      processedJobs: 1,
      totalJobs: 3,
      progressText: "1/3 hébergements",
      status: ProgressionStatusEnum.PENDING,
      step: ProgressionStepEnum.STEP_CONNECT_AIRBNB,
      bookingAlreadyAssociated: false,
    });

    setTimeout(() => {
      setCurrentProgression({
        progress: 50,
        processedJobs: 2,
        totalJobs: 3,
        progressText: "2/3 hébergements",
        status: ProgressionStatusEnum.PENDING,
        step: ProgressionStepEnum.STEP_CONNECT_AIRBNB,
        bookingAlreadyAssociated: false,
      });
    }, 2000);

    setTimeout(() => {
      setCurrentProgression({
        progress: 100,
        processedJobs: 3,
        totalJobs: 3,
        progressText: "3/3 hébergements",
        status: ProgressionStatusEnum.PENDING,
        step: ProgressionStepEnum.STEP_CONNECT_AIRBNB,
        bookingAlreadyAssociated: false,
      });
    }, 4000);

    setTimeout(() => {
      setCurrentProgression({
        progress: null,
        processedJobs: 0,
        totalJobs: 0,
        progressText: "",
        status: ProgressionStatusEnum.INCOMPLETE,
        step: ProgressionStepEnum.STEP_CONNECT_BOOKING,
        bookingAlreadyAssociated: false,
      });
    }, 5000);
  };

  const handleRentalRulesDebug = () => {
    setIsSyncWaitingPopupVisible(true);

    setCurrentProgression({
      progress: 0,
      processedJobs: 0,
      totalJobs: 3,
      progressText: "0/3 hébergements",
      status: ProgressionStatusEnum.PENDING,
      step: ProgressionStepEnum.STEP_SYNCHRONIZE,
      bookingAlreadyAssociated: false,
    });

    setTimeout(() => {
      setCurrentProgression({
        progress: 50,
        processedJobs: 1,
        totalJobs: 3,
        progressText: "1/3 hébergements",
        status: ProgressionStatusEnum.PENDING,
        step: ProgressionStepEnum.STEP_SYNCHRONIZE,
        bookingAlreadyAssociated: false,
      });
    }, 2000);

    setTimeout(() => {
      setCurrentProgression({
        progress: 100,
        processedJobs: 3,
        totalJobs: 3,
        progressText: "3/3 hébergements",
        status: ProgressionStatusEnum.PENDING,
        step: ProgressionStepEnum.STEP_SYNCHRONIZE,
        bookingAlreadyAssociated: false,
      });
    }, 4000);

    setTimeout(() => {
      setCurrentProgression({
        progress: null,
        processedJobs: 0,
        totalJobs: 0,
        progressText: "",
        status: ProgressionStatusEnum.INCOMPLETE,
        step: ProgressionStepEnum.STEP_CONFIG_DIRECT_RESERVATIONS,
        bookingAlreadyAssociated: false,
      });
    }, 5000);
  };

  useEffect(() => {
    fetchAllSteps();
  }, []);

  useEffect(() => {
    if (
      syncInProgress ||
      (currentProgression.step === ProgressionStepEnum.STEP_SYNCHRONIZE &&
        currentProgression.status === ProgressionStatusEnum.PENDING)
    ) {
      setIsSyncWaitingPopupVisible(true);
    }
  }, [syncInProgress, currentProgression.step, currentProgression.status]);

  const handleCloseBookingModal = () => {
    setBookingConnectModalVisible(false);
    setBookingConnectData(undefined);
    setLoading(true);
    fetchProgression();
  };

  const handleFinishAddStripe = () => {
    tablePage.fetch({});
    addStripeModal.close();
    setAddStripeSuccessVisible(false);
    setAddStripeErrorVisible(false);
  };

  const handleConfirmStripe = () => {
    //navigate(paths.PAYMENTS_ACCOUNTS);
    // completeCurrentStep();
    addStripeModal.open();
  };

  const addStripeModal = useModal();
  const [selectedWS, setSelectedWS] = useState<WorkspaceResponse | undefined>();
  const tablePage = useTablePage(
    `${import.meta.env.VITE_API_URL}${paths.API.PAYMENTS.ACCOUNTS}`,
    "payment_accounts"
  );

  useEffect(() => {
    if (!tablePage.data || tablePage.data.length === 0) {
      tablePage.fetch({});
    }
  }, []);

  const configDirectReservationsModal = useModal<{}>();
  const handleOpenConfigDirectReservationsModal = () =>
    configDirectReservationsModal.open({});

  const [
    currentConfigDirectReservationsStep,
    setCurrentConfigDirectReservationsStep,
  ] = useState<DIRECT_RESERVATIONS_STEP>(
    DIRECT_RESERVATIONS_STEP.RENTAL_CHOICE
  );

  useEffect(() => {
    if (
      queryCode &&
      currentProgression.step ===
        ProgressionStepEnum.STEP_CONFIG_DIRECT_RESERVATIONS
    ) {
      configDirectReservationsModal.open({});

      setCurrentConfigDirectReservationsStep(
        DIRECT_RESERVATIONS_STEP.STRIPE_COLLECT_PAYMENTS
      );
    }
  }, [currentProgression.step, currentConfigDirectReservationsStep, queryCode]);

  useEffect(() => {
    configDirectReservationsModal.close();
  }, [currentProgression.step]);

  const handleClickNoOffPlatformReservations = () => {
    if (currentProgression.step !== null) {
      useCompleteCurrentStepProgression({
        step: currentProgression.step,
        onSuccess: (data) =>
          setCurrentProgression((prevProgression) => ({
            ...prevProgression,
            step: data.progression_step,
          })),
        onError: (message) => setError(message),
        onStart: () => {
          setLoading(true);
          setError(null);
        },
        onEnd: () => {
          setLoading(false);
        },
      });
    }
  };

  return (
    <>
      <ListingImportWaitingModal
        progression={currentProgression.progress}
        isVisible={
          currentProgression.status !== ProgressionStatusEnum.ERROR &&
          (isListingImportWaitingPopupVisible ||
            (currentProgression.step ===
              ProgressionStepEnum.STEP_CONNECT_AIRBNB &&
              currentProgression.status === ProgressionStatusEnum.PENDING))
        }
        processedJobs={currentProgression.processedJobs ?? 0}
        totalJobs={currentProgression.totalJobs}
      />

      <ListingImportFinishModal
        isVisible={
          currentProgression.status !== ProgressionStatusEnum.ERROR &&
          isListingImportWaitingPopupVisible &&
          currentProgression.step! != ProgressionStepEnum.STEP_CONNECT_AIRBNB &&
          currentProgression.step! != ProgressionStepEnum.STEP_NONE
        }
        onClose={() => setIsListingImportWaitingPopupVisible(false)}
      />

      <RulesSyncWaitingModal
        progression={currentProgression.progress}
        progressText={currentProgression.progressText}
        isVisible={
          currentProgression.status !== ProgressionStatusEnum.ERROR &&
          ((isSyncWaitingPopupVisible &&
            currentProgression.step !==
              ProgressionStepEnum.STEP_CREATE_MESSAGES) ||
            (currentProgression.step === ProgressionStepEnum.STEP_SYNCHRONIZE &&
              currentProgression.status === ProgressionStatusEnum.PENDING))
        }
      />

      <RulesSyncFinishModal
        isVisible={
          currentProgression.status !== ProgressionStatusEnum.ERROR &&
          isSyncWaitingPopupVisible &&
          currentProgression.step ===
            ProgressionStepEnum.STEP_CREATE_MESSAGES &&
          currentProgression.status === ProgressionStatusEnum.INCOMPLETE
        }
        onClose={() => setIsSyncWaitingPopupVisible(false)}
      />

      <AirbnbConnectModal
        isVisible={isAirbnbConnectModalVisible}
        onClose={() => setAirbnbConnectModalVisible(false)}
        onFinish={handleFinishAirbnb}
        onSelectRental={(count: number) => {
          setCurrentProgression((prevValue) => {
            return {...prevValue, totalJobs: count};
          });
        }}
      />

      <BookingConnectModal
        isVisible={isBookingConnectModalVisible}
        onClose={handleCloseBookingModal}
        onFinish={handleCloseBookingModal}
        data={bookingConnectData}
        setData={setBookingConnectData}
      />

      <ProgressionConfigDirectReservationsModal
        modal={configDirectReservationsModal}
        step={currentConfigDirectReservationsStep}
        onChangeStep={(step) => setCurrentConfigDirectReservationsStep(step)}
        onValidate={(step) => {
          setCurrentProgression((prevProgression) => {
            console.log(prevProgression, "new step : ", step);
            return {
              ...prevProgression,
              step: step,
            };
          });
        }}
      />

      <MainLayout
        title={t("Progression.title")}
        sidebarActiveItem="progression"
        userData={user}
      >
        {/* <Button onClick={handleFinishAirbnbDebug}>DEBUG FINISH AIRBNB</Button> */}
        {/* <div style={{ zIndex: 99 }}>
          <Button onClick={handleRentalRulesDebug}>
            DEBUG SYNC RENTAL RULES
          </Button>
        </div> */}

        <div className="flex flex-row justify-center">
          <div className="flex flex-row flex-1 gap-4 mt-2 max-w-7xl">
            {/* Left side with steps */}
            <div className="flex-1">
              <div className="w-full overflow-hidden border-1 border-element-border rounded-6px">
                {allSteps?.map((step, index) => {
                  return (
                    <>
                      {/* Elite None just for loading */}
                      {step === ProgressionStepEnum.STEP_NONE &&
                        Array.from({
                          length: Object.values(ProgressionStepEnum).length - 1,
                        }).map((s, index) => (
                          <ProgressionStepNone
                            key={`ProgressionStepNone-${index}`}
                            status="inactive"
                            visible={step === ProgressionStepEnum.STEP_NONE}
                            number={index + 1}
                            error={error}
                            loading={true}
                            step={step}
                          />
                        ))}

                      {/* Step AirBnB */}
                      <ProgressionStepAirbnb
                        key={"ProgressionStepAirbnb"}
                        currentProgression={currentProgression}
                        status={getProgressionStatus(
                          ProgressionStepEnum.STEP_CONNECT_AIRBNB
                        )}
                        visible={
                          step === ProgressionStepEnum.STEP_CONNECT_AIRBNB
                        }
                        number={index + 1}
                        error={error}
                        loading={loading}
                        onAirbnbClick={() => setAirbnbConnectModalVisible(true)}
                      />

                      {/* Step Booking */}
                      <ProgressionStepBooking
                        key={"ProgressionStepBooking"}
                        isBookingConnected={
                          currentProgression.bookingAlreadyAssociated
                        }
                        status={getProgressionStatus(
                          ProgressionStepEnum.STEP_CONNECT_BOOKING
                        )}
                        currentProgression={currentProgression}
                        visible={
                          step === ProgressionStepEnum.STEP_CONNECT_BOOKING
                        }
                        number={index + 1}
                        error={error}
                        loading={loading}
                        onBookingClick={() =>
                          setBookingConnectModalVisible(true)
                        }
                        onCompleteStep={completeCurrentStep}
                        step={step}
                      />

                      {/* Step Rentals */}
                      <ProgressionStepRentals
                        key={"ProgressionStepRentals"}
                        currentProgression={currentProgression}
                        status={getProgressionStatus(
                          ProgressionStepEnum.STEP_CREATE_RENTAL
                        )}
                        visible={
                          step === ProgressionStepEnum.STEP_CREATE_RENTAL
                        }
                        number={index + 1}
                        error={error}
                        loading={loading}
                        onCompleteStep={completeCurrentStep}
                      />

                      {/* Step Config Direct Reservations */}
                      <ProgressionStepConfigDirectReservations
                        key={"ProgressionStepConfigDirectReservations"}
                        status={getProgressionStatus(
                          ProgressionStepEnum.STEP_CONFIG_DIRECT_RESERVATIONS
                        )}
                        visible={
                          step ===
                          ProgressionStepEnum.STEP_CONFIG_DIRECT_RESERVATIONS
                        }
                        number={index + 1}
                        error={error}
                        loading={loading}
                        onOpenConfigDirectReservationsModal={
                          handleOpenConfigDirectReservationsModal
                        }
                        onNoOffPlatformReservations={
                          handleClickNoOffPlatformReservations
                        }
                      />

                      {/* Step Rentals Rules */}
                      <ProgressionStepRules
                        key={"ProgressionStepRules"}
                        status={getProgressionStatus(
                          ProgressionStepEnum.STEP_SYNCHRONIZE
                        )}
                        currentProgression={currentProgression}
                        visible={step === ProgressionStepEnum.STEP_SYNCHRONIZE}
                        number={index + 1}
                        error={error}
                        loading={loading}
                        onRulesClick={() =>
                          navigate(paths.PROGRESSION_RENTALS_RULES)
                        }
                        step={step}
                      />

                      {/* Messages Step */}
                      <ProgressionStepMessages
                        key={"ProgressionStepMessages"}
                        status={getProgressionStatus(
                          ProgressionStepEnum.STEP_CREATE_MESSAGES
                        )}
                        visible={
                          step === ProgressionStepEnum.STEP_CREATE_MESSAGES
                        }
                        number={index + 1}
                        error={error}
                        loading={loading}
                        step={step}
                      />

                      {/* Stripe Step */}
                      <ProgressionConnectStripe
                        key={"ProgressionStepConnectStripe"}
                        status={getProgressionStatus(
                          ProgressionStepEnum.STEP_CONNECT_STRIPE_FOR_EXTRA_ORDERS
                        )}
                        accounts={tablePage.data}
                        visible={
                          step ===
                          ProgressionStepEnum.STEP_CONNECT_STRIPE_FOR_EXTRA_ORDERS
                        }
                        number={index + 1}
                        error={error}
                        loading={loading}
                        step={step}
                        onStripeClick={() => handleConfirmStripe()}
                        onNextClick={() => completeCurrentStep()}
                      />

                      {/* Gift Step */}
                      <ProgressionStepGift
                        key={"ProgressionStepGift"}
                        status={getProgressionStatus(
                          ProgressionStepEnum.STEP_GIFT
                        )}
                        visible={step === ProgressionStepEnum.STEP_GIFT}
                        number={index + 1}
                        error={error}
                        loading={loading}
                        step={step}
                      />
                    </>
                  );
                })}
              </div>
            </div>
            {/* Right side with pics */}
            <ProgressionRightSide
              getProgressionStatus={getProgressionStatus}
              user={user}
            />
          </div>
        </div>
        <AddStripeAccountModal
          isVisible={addStripeModal.isVisible}
          onClose={addStripeModal.close}
          page="progression"
        />
        <AddStripeAccountSuccessModal
          selectedWS={selectedWS}
          isVisible={addStripeSuccessVisible}
          onClose={() => setAddStripeSuccessVisible(false)}
          onFinish={handleFinishAddStripe}
        />
        <AddStripeAccountErrorModal
          isVisible={addStripeErrorVisible}
          onClose={() => setAddStripeErrorVisible(false)}
        />
      </MainLayout>
    </>
  );
};
