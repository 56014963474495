import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ProgressionStepEnum } from "../../enums/GETenums";
import useProgressionStore from "../../stores/useProgressStore";
import { Button } from "../Common/Button/Button";

export const ProgressionRightSide: React.FC<{}> = ({
  getProgressionStatus,
  user,
}) => {
  const { t } = useTranslation();
  const { refreshProgression } = useProgressionStore();

  const getProgression = () => {
    if (typeof user?.progression === "number") {
      return `${user.progression}%`;
    } else return "";
  };

  const openChat = () => {
    if (window.$crisp) {
      window.$crisp.push(["set", "user:email", [user?.email]]);
      window.$crisp.push(["set", "user:nickname", [user?.first_name]]);
      window.$crisp.push(["set", "user:phone", [user?.phone]]);
      window.$crisp.push(["do", "chat:open"]);
    }
  };

  const videoUrls = {
    [ProgressionStepEnum.STEP_CONNECT_AIRBNB]:
      "https://player.vimeo.com/video/1017868151?h=1c27d744df&autoplay=1",
    [ProgressionStepEnum.STEP_CREATE_RENTAL]:
      "https://player.vimeo.com/video/1017868235?h=9fc6d3ec03&autoplay=1",
    [ProgressionStepEnum.STEP_CONNECT_BOOKING]:
      "https://player.vimeo.com/video/1017868341?h=2dc1770e23&autoplay=1",
    [ProgressionStepEnum.STEP_SYNCHRONIZE]:
      "https://player.vimeo.com/video/1017868461?h=e5df2a9418&autoplay=1",
    [ProgressionStepEnum.STEP_CHECK_CALENDAR]:
      "https://player.vimeo.com/video/1017868975?h=995ce8568b&autoplay=1",
    [ProgressionStepEnum.STEP_CREATE_MESSAGES]:
      "https://player.vimeo.com/video/1017868661?h=87895a3dff&autoplay=1",
    [ProgressionStepEnum.STEP_CONNECT_STRIPE]:
      "https://player.vimeo.com/video/1017869188?h=b8c2376bf2&autoplay=1",
    [ProgressionStepEnum.STEP_GIFT]:
      "https://player.vimeo.com/video/1017869344?h=7bfc66d868&autoplay=1",
  };

  const tutoralUrl = {
    [ProgressionStepEnum.STEP_CONNECT_AIRBNB]:
      "https://helpsuperhotev2.crisp.help/fr/article/importer-un-logement-depuis-airbnb-ejpvho/",
    [ProgressionStepEnum.STEP_CREATE_RENTAL]:
      "https://helpsuperhotev2.crisp.help/fr/article/creer-manuellement-un-hebergement-sur-superhote-g5j8t3/?bust=1727990939354",
    [ProgressionStepEnum.STEP_CONNECT_BOOKING]: "",
    [ProgressionStepEnum.STEP_SYNCHRONIZE]: "",
    [ProgressionStepEnum.STEP_CHECK_CALENDAR]:
      "https://helpsuperhotev2.crisp.help/fr/article/comment-naviguer-dans-le-calendrier-plage-de-dates-filtres-uxl06y/?bust=1728073205919",
    [ProgressionStepEnum.STEP_CREATE_MESSAGES]:
      "https://helpsuperhotev2.crisp.help/fr/article/comment-automatiser-ma-communication-voyageur-dans-superhote-messages-automatises-1bap4mu/?bust=1728073634573",
    [ProgressionStepEnum.STEP_GIFT]:
      "https://helpsuperhotev2.crisp.help/fr/article/comment-automatiser-ma-communication-voyageur-dans-superhote-messages-automatises-1bap4mu/?bust=1728073634573",
  };

  const displayVideo = () => {
    for (const step in videoUrls) {
      if (getProgressionStatus(step) === "active") {
        return videoUrls[step];
      }
    }
    return "";
  };

  const displayTutorial = () => {
    for (const step in videoUrls) {
      if (getProgressionStatus(step) === "active") {
        console.log(tutoralUrl[step]);
        window.open(tutoralUrl[step], "_blank");
      }
    }
    return "";
  };
  useEffect(() => {}, [refreshProgression]);

  return (
    <div className="hidden xl:w-96 lg:w-80 lg:block">
      <div className="p-4 overflow-hidden border-1 border-element-border rounded-6px bg-element-background-light">
        <iframe
          src={displayVideo()}
          width="350"
          height="250"
          allow="fullscreen"
        ></iframe>

        <div className="w-full h-2 mt-2 overflow-hidden bg-white border-1 border-element-border-light rounded-6px">
          <div
            className={`w-${user?.progression} h-full bg-${
              user?.progression === 100 ? "success" : "secondary"
            }`}
          />
          sssss
        </div>

        <div className="mt-4">
          <p
            className={`font-bold text-sm ${
              user?.progression === 100
                ? "text-[#46A758]"
                : "text-high-contrast"
            }`}
          >
            {t("Progression.sideTitle")} {getProgression()}
          </p>
          <p className="mt-1 text-sm font-light text-low-contrast">
            {user?.progression === 100
              ? t("Progression.sideContent3")
              : t("Progression.sideContent")}
          </p>
          {user?.progression === 100 && (
            <p className="mt-3 text-sm font-light text-low-contrast">
              {t("Progression.sideContent4")}
            </p>
          )}
          <div className="w-full mt-4">
            <Button type="secondary" onClick={() => displayTutorial()}>
              {t("Progression.tutorialTitle")}
            </Button>
          </div>
        </div>
      </div>

      <div className="p-4 mt-4 overflow-hidden border-1 border-element-border rounded-6px">
        <p className="text-sm font-semibold text-high-contrast">
          {t("Progression.sideTitle2")}
        </p>
        <p className="mt-1 text-sm font-light text-low-contrast">
          {t("Progression.sideContent2")}
        </p>

        <div className="w-full mt-4">
          <Button type="secondary" onClick={() => openChat()}>
            {t("Progression.sideButton")}
          </Button>
        </div>
      </div>
    </div>
  );
};
