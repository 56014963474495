import { ClassValue } from "clsx";
import { t } from "i18next";
import React, { Dispatch, ReactNode, SetStateAction } from "react";
import CheckCircleIcon from "../../assets/icons/check-circle-green.svg?react";
import CloseCircleIcon from "../../assets/icons/close-circle-red.svg?react";
import WarningTriangleIcon from "../../assets/icons/warning-triangle-red.svg?react";
import { Badge } from "../../components/Common/Badge/Badge";
import {
  BadgeSize,
  BadgeStatus,
} from "../../components/Common/Badge/Badge.type";
import paths from "../../constants/paths";
import {
  PaymentReservationDepositStatusEnum,
  PaymentReservationStatusEnum,
  ReservationStatusEnum,
} from "../../enums/GETenums";
import { get } from "../../helpers/APIHelper";
import {
  PaymentReservationDepositStatus,
  PaymentReservationResponse,
} from "../../types/GETTypes";
import { IconType } from "../../types/commonTypes";

export const usePaymentReservationStatusBadge = (
  paymentStatus: string | undefined,
  size: BadgeSize = "large"
): ReactNode => {
  let status: BadgeStatus = "success";
  let icon: IconType | undefined = undefined;
  let label: string = "";

  switch (paymentStatus) {
    case PaymentReservationStatusEnum.UNPAID:
      status = "fail";
      label = t("Global.unpaid", { context: "male" });
      break;
    case PaymentReservationStatusEnum.OVERDUE:
      status = "fail";
      label = t("Global.overdue");
      icon = WarningTriangleIcon;
      break;
    case PaymentReservationStatusEnum.PARTIALLY_PAID:
      status = "pending";
      label = t("Global.partiallyPaid", { context: "male" });
      break;
    case PaymentReservationStatusEnum.REFUNDED:
      status = "success";
      label = t("Global.refunded", { context: "male", count: 1 });
      break;
    case PaymentReservationStatusEnum.PAID:
      status = "success";
      label = t("Global.paid", { context: "male", count: 1 });
      break;
    case PaymentReservationStatusEnum.CANCELED:
      status = "success";
      label = t("Global.canceled", { context: "male", count: 1 });
      break;
    default:
      status = "fail";
      label = t("Global.unpaid", { context: "male" });
      break;
  }

  return <Badge Icon={icon} status={status} label={label} size={size} />;
};

export const useReservationStatusBadge = (
  reservationStatus: number | undefined,
  size: BadgeSize = "large"
): ReactNode => {
  let status: BadgeStatus = "success";
  let icon: IconType | undefined | null = null;
  let label: string = "";
  let color: ClassValue = "";
  let bg: ClassValue = "";

  if (reservationStatus === ReservationStatusEnum.REQUEST) {
    status = "pending";
    label = t("Booking.ReservationStatus.reservationRequest");
    bg = "bg-element-background";
    color = "text-pending";
    icon = null;
  } else if (reservationStatus === ReservationStatusEnum.CONFIRMED_FINISHED) {
    status = "pending";
    label = t("Booking.ReservationStatus.stayOver");
    bg = "bg-element-background";
    color = "text-low-contrast";
    icon = null;
  } else if (reservationStatus === ReservationStatusEnum.CONFIRMED_PROGRESS) {
    status = "success";
    label = t("Booking.ReservationStatus.currentStay");
    icon = CheckCircleIcon;
  } else if (reservationStatus === ReservationStatusEnum.CONFIRMED_COMING) {
    status = "pending";
    label = t("Booking.ReservationStatus.upcomingStay");
    bg = "bg-element-background";
    color = "text-low-contrast";
    icon = null;
  } else if (reservationStatus === ReservationStatusEnum.CANCELED) {
    status = "fail";
    label = t("Booking.ReservationStatus.canceled");
    icon = CloseCircleIcon;
  } else if (reservationStatus === ReservationStatusEnum.CANCELED_BY_TRAVELER) {
    status = "fail";
    label = t("Booking.ReservationStatus.canceledByGuest");
    icon = CloseCircleIcon;
  } else if (reservationStatus === ReservationStatusEnum.PRE_APPROVED) {
    status = "pending";
    label = t("Booking.ReservationStatus.preApprovedRequest");
    bg = "bg-element-background";
    color = "text-green";
    icon = null;
  }

  return (
    <Badge
      Icon={icon}
      status={status}
      label={label}
      customColor={color}
      customBg={bg}
      size={size}
    />
  );
};

export const usePaymentReservationStatusLabel = (
  status: PaymentReservationStatusEnum | undefined
) => {
  let label: string = t("Global.notDefined");
  if (status === PaymentReservationStatusEnum.UNPAID) {
    label = t("Booking.PaymentStatus.unpaid");
  } else if (status === PaymentReservationStatusEnum.OVERDUE) {
    label = t("Booking.PaymentStatus.overdue");
  } else if (status === PaymentReservationStatusEnum.PARTIALLY_PAID) {
    label = t("Booking.PaymentStatus.inProgress");
  } else if (status === PaymentReservationStatusEnum.PAID) {
    label = t("Booking.PaymentStatus.paid");
  } else if (status === PaymentReservationStatusEnum.REFUNDED) {
    label = t("Booking.PaymentStatus.refunded");
  }
  return label;
};

export const useDepositStatusLabel = (
  status: PaymentReservationDepositStatus | undefined
) => {
  let label: string = t("Global.notDefined");
  if (status === PaymentReservationDepositStatusEnum.UNPAID) {
    label = t("Booking.DepositStatus.unpaid");
  } else if (status === PaymentReservationDepositStatusEnum.OVERDUE) {
    label = t("Booking.DepositStatus.overdue");
  } else if (status === PaymentReservationDepositStatusEnum.CANCELED) {
    label = t("Booking.DepositStatus.canceled");
  } else if (status === PaymentReservationDepositStatusEnum.PRE_AUTHORIZED) {
    label = t("Booking.DepositStatus.preAuthorized");
  } else if (status === PaymentReservationDepositStatusEnum.RELEASED) {
    label = t("Booking.DepositStatus.released");
  } else if (status === PaymentReservationDepositStatusEnum.CAPTURED) {
    label = t("Booking.DepositStatus.captured");
  } else if (status === PaymentReservationDepositStatusEnum.PAID) {
    label = t("Booking.DepositStatus.paid");
  } else if (status === PaymentReservationDepositStatusEnum.NOT_REFUNDED) {
    label = t("Booking.DepositStatus.notRefunded");
  } else if (status === PaymentReservationDepositStatusEnum.REFUNDED) {
    label = t("Booking.DepositStatus.refunded");
  } else if (
    status === PaymentReservationDepositStatusEnum.MANAGED_BY_PLATFORM
  ) {
    label = t("Booking.DepositStatus.managedByPlatform");
  } else if (status === PaymentReservationDepositStatusEnum.NOT_REQUIRED) {
    label = t("Booking.DepositStatus.notRequired");
  }

  return label;
};

export const useFetchPaymentSchedule = async (
  setLoading: Dispatch<SetStateAction<boolean>>,
  [paymentReservation, setPaymentReservation]: [
    PaymentReservationResponse | undefined,
    Dispatch<SetStateAction<PaymentReservationResponse | undefined>>
  ],
  currentPaymentReservationId: number,
  successCallback?: () => void
) => {
  if (paymentReservation?.id !== currentPaymentReservationId) {
    setLoading(true);
    const response = await get(
      `${import.meta.env.VITE_API_URL}${
        paths.API.PAYMENTS.RESERVATIONS
      }/${currentPaymentReservationId}`
    );

    if (response?.data?.success) {
      setLoading(false);
      setPaymentReservation(response?.data.result);
      if (typeof successCallback === "function") {
        successCallback();
      }
    }
  }
};
