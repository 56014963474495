import React from "react";
import { cn } from "../../../helpers/classHelper";

export const PaymentDepositListItemSkeleton: React.FC<{}> = () => {
  return (
    <>
      <tr className="cursor-pointer hover:bg-element-background/40 animate-pulse">
        <td
          className={cn(
            `flex flex-row items-center px-4 py-4 space-x-4 text-sm border-element-border border-t-1`
          )}
        >
          <div className="flex items-center">
            <div className="relative">
              <div className="object-fill w-12 h-12 overflow-hidden rounded-4px border-1 border-element-border/50 bg-slate-200"></div>
            </div>
          </div>
          <div className="flex flex-col space-y-1">
            <p className="h-2 text-base font-semibold tracking-normal rounded text-high-contrast bg-slate-300 w-52"></p>
            <div>
              <p className="w-40 h-2 mt-1 font-light tracking-normal rounded text-low-contrast bg-slate-200"></p>
              <p className="w-40 h-2 mt-1 font-light tracking-normal rounded text-low-contrast bg-slate-200"></p>
            </div>
          </div>
        </td>

        <td className={cn(`px-2 py-4 border-element-border border-t-1`)}>
          <p className="w-40 h-2 rounded bg-slate-300" />
        </td>

        <td className={cn(`px-2 py-4 border-element-border border-t-1`)}>
          <div className="flex items-center justify-start space-x-2">
            <div className="rounded-full size-8 bg-slate-300"></div>
            <p className="w-40 h-2 rounded bg-slate-300" />
          </div>
        </td>

        <td className={cn(`px-2 py-4 border-element-border border-t-1`)}>
          <div className="flex justify-center">
            <p className="w-40 h-2 rounded bg-slate-300" />
          </div>
        </td>

        <td className={cn(`px-2 py-4 border-element-border border-t-1`)}>
          <div className="flex justify-center">
            <p className="w-40 h-2 rounded bg-slate-300" />
          </div>
        </td>

        <td className={cn(`px-2 py-4 border-element-border border-t-1 w-16`)}>
          <div className="flex justify-end w-full">
            <p className="w-full h-2 rounded bg-slate-300" />
          </div>
        </td>
      </tr>
    </>
  );
};
