import React, {useRef, useState} from "react";
import {RightModal} from "../../../Common/RightModal/RightModal";
import {ErrorMessage} from "../../../Common/ErrorMessage/ErrorMessage";
import {useTranslation} from "react-i18next";
import {Separator} from "../../../Common/Separator/Separator";
import {Button} from "../../../Common/Button/Button";
import {Controller, useForm} from "react-hook-form";
import {TextInput} from "../../../Common/TextInput/TextInput";
import {ContractsModalForm} from "./ContractsModal.type";
import {post} from "../../../../helpers/APIHelper";
import paths from "../../../../constants/paths";
import {useModal} from "../../../../hooks/useModal";
import {insertTextAtPosition} from "../../../../helpers/stringHelper";
import {ShortCodeList} from "../../../Message/ShortCodeList/ShortCodeList";
import {TextAreaInput} from "../../../Common/TextAreaInput/TextAreaInput";
import {Switch} from "../../../Common/Switch/Switch";
import {cn} from "../../../../helpers/classHelper";
import {Title} from "../../../Common/Title/Title";
import CloseIcon from "../../../../assets/icons/close.svg?react";
import {CustomEditor} from "../../../Common/CustomEditor/CustomEditor";
import {insertShortCodeInEditor} from "../../../../helpers/editorHelper";
import {
  $createRangeSelection,
  $createTextNode,
  $getSelection,
  $isRangeSelection,
  LexicalEditor,
} from "lexical";

type RentalCreateContractsModalProps = {
  isVisible: boolean;
  rentalId: string | undefined;
  onClose: () => void;
  loading?: boolean;
  onSuccess: () => Promise<void>;
};
export type TextEditorNode = {
  text: string;
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
};

export type ElementEditorNode = {
  type: "paragraph" | "heading" | "list-item" | "quote" | string; // Add more types as needed
  children: (TextEditorNode | ElementEditorNode)[];
};

export type ContentEditor = ElementEditorNode[];
const RentalCreateContractsModal = ({
  isVisible,
  onClose,
  loading = false,
  rentalId,
  onSuccess,
}: RentalCreateContractsModalProps) => {
  const {t} = useTranslation();
  const [error, setError] = useState<string | undefined>();
  const [loadingValidation, setLoadingValidation] = useState<boolean>(false);
  const modalShortCode = useModal();
  const editorRef = useRef<LexicalEditor>();

  const form = useForm<ContractsModalForm>({
    defaultValues: {
      name: "",
      content: "",
    },
  });

  const handleCreateContract = async (data: ContractsModalForm) => {
    setLoadingValidation(true);
    if (rentalId) {
      const response = await post(
        `${
          import.meta.env.VITE_API_URL
        }${paths.API.CONTRACTS_DOCS.CREATE_CONTRACT_BY_RENTAL(
          Number(rentalId)
        )}`,
        data
      );
      if (response?.data?.success) {
        onClose();
        onSuccess();
      }
    }
    setLoadingValidation(false);
  };

  const handleInsertShortCode = (trigger: string) => {
    insertShortCodeInEditor({text: trigger, modalShortCode, editorRef});
  };

  const closeModal = () => {
    form.reset();
    modalShortCode.close();
    onClose();
  };

  const getTitle = () => {
    if (modalShortCode.isVisible)
      return (
        <div className="flex">
          <div className="flex justify-between w-modal">
            <Title>{t("Templates.shortcodes")}</Title>
            <div
              className="pt-1 hover:cursor-pointer mr-6"
              onClick={() => modalShortCode.close()}
            >
              <CloseIcon />
            </div>
          </div>
          <div className="flex-1">
            <Title>{t("Rental.Contracts.Contracts.Modal.title")}</Title>
          </div>
        </div>
      );
    else return t("Rental.Contracts.Contracts.Modal.title");
  };

  return (
    <RightModal
      title={getTitle()}
      isVisible={isVisible}
      onClose={closeModal}
      isSplit={modalShortCode.isVisible}
    >
      <>
        {modalShortCode && modalShortCode.isVisible ? (
          <div
            className={cn(
              "flex-1 pr-8 h-full",
              modalShortCode.isVisible ? "" : "overflow-y-scroll"
            )}
          >
            <ShortCodeList onInsert={handleInsertShortCode} />
          </div>
        ) : null}
        <div
          className={cn(
            `flex flex-col gap-3`,
            modalShortCode.isVisible ? "w-modal-inside" : "w-full"
          )}
        >
          <ErrorMessage>{error}</ErrorMessage>
          <div>
            <Controller
              control={form.control}
              name="name"
              rules={{
                required: {
                  value: true,
                  message: t("Rental.Contracts.Contracts.Modal.formRequired"),
                },
              }}
              render={({field: {value, onChange}}) => (
                <TextInput
                  label={t("Rental.Checklist.Upsert.name")}
                  required={true}
                  placeholder={t(
                    "Rental.Contracts.Contracts.Modal.formNamePlaceholder"
                  )}
                  disabled={loading || loadingValidation}
                  value={value}
                  error={form.formState.errors.name?.message}
                  onChangeText={onChange}
                />
              )}
            />
          </div>
          <div className="gap-2">
            <div>
              <CustomEditor
                ref={editorRef}
                form={form}
                formControllerName="content"
                error={form.formState.errors.content?.message}
                disabled={loading || loadingValidation}
                label={t("Rental.Contracts.Contracts.Modal.formContent")}
                placeholder={t(
                  "Rental.Contracts.Contracts.Modal.formContentPlaceholder"
                )}
                required
                requiredMessage={t(
                  "Rental.Contracts.Contracts.Modal.formRequired"
                )}
                classNames={{placeholder: error ? "top-[30.9%]" : ""}}
                modalShortCode={modalShortCode}
              />
            </div>
          </div>
          <div className="flex-1"></div>

          <Separator />

          <div
            className="flex flex-1 justify-between items-center font-light text-low-contrast cursor-pointer"
            onClick={
              modalShortCode.isVisible
                ? modalShortCode.close
                : modalShortCode.open
            }
          >
            <p>{t("AutoMessageList.Upsert.Contents.shortcodes")}</p>

            <Switch value={modalShortCode.isVisible} />
          </div>

          <Separator />

          <div className="flex">
            <div className="flex flex-1 gap-x-3 justify-between">
              <Button
                type="secondary"
                onClick={closeModal}
                disabled={loadingValidation}
              >
                {t("Global.cancel")}
              </Button>
              <Button
                type="primary"
                loading={loadingValidation}
                onClick={form.handleSubmit(handleCreateContract)}
              >
                {t("Global.save")}
              </Button>
            </div>
          </div>
        </div>
      </>
    </RightModal>
  );
};

export default RentalCreateContractsModal;
