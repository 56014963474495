import React, { useState } from "react";
import { ValueType } from "../../../../types/commonTypes";
import { CenterModal } from "../../../Common/CenterModal/CenterModal";
import { ErrorMessage } from "../../../Common/ErrorMessage/ErrorMessage";
import { useTranslation } from "react-i18next";
import { Button } from "../../../Common/Button/Button";
import { post } from "../../../../helpers/APIHelper";
import paths from "../../../../constants/paths";

export const PaymentReservationDoneModal: React.FC<{
  data: { reservationId: ValueType; paymentId: ValueType } | undefined | null;
  isVisible: boolean;
  onSuccess: () => void;
  onClose: () => void;
}> = ({ data, isVisible, onSuccess, onClose }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleClose = () => {
    if (loading) return;

    setError(null);
    onClose();
  };

  const handleSuccess = () => {
    onSuccess();
  };

  const handleSetToPaid = async () => {
    setLoading(true);

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.PAYMENTS.RESERVATIONS}/${
        data?.reservationId
      }/${paths.API.PAYMENTS.SET_TO_PAID}/${data?.paymentId}`
    );

    if (res?.data?.success) handleSuccess();
    else setError(res?.response?.data?.message);

    setLoading(false);
  };

  return (
    <CenterModal
      isVisible={isVisible}
      onClose={onClose}
      title={t("Payments.PaymentReservationList.SetDone.title")}
    >
      <div>
        <p className="text-sm font-light text-low-contrast mb-4">
          {t("Payments.PaymentReservationList.SetDone.content")}
        </p>

        <ErrorMessage>{error}</ErrorMessage>

        <div className="flex gap-3">
          <Button type="secondary" onClick={handleClose} disabled={loading}>
            {t("Global.cancel")}
          </Button>
          <Button onClick={handleSetToPaid} loading={loading}>
            {t("Payments.PaymentReservationList.SetDone.button")}
          </Button>
        </div>
      </div>
    </CenterModal>
  );
};
