/**
 * Header of the page
 * "/operational-management/payments"
 * @constructor
 */

import { TextInput } from "../Common/TextInput/TextInput";
import { Button } from "../Common/Button/Button";

import PlusIcon from "../../assets/icons/plus-white.svg?react";
import SearchIcon from "../../assets/icons/search.svg?react";
import React from "react";
import { useTranslation } from "react-i18next";
import { ManagementPaymentPageListHeaderProps } from "./Payment.type";

export const PaymentHeader: React.FC<ManagementPaymentPageListHeaderProps> = ({
  onSearch,
  onAddPayment,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <header className={"flex items-center gap-2"}>
        <div className={"w-5/6"}>
          <TextInput
            placeholder={t("Management.Payments.placeholderSearch")}
            Icon={SearchIcon}
            classNames={{
              divInput: "p-[17.5px]",
            }}
            onChangeText={(search: string) => onSearch(search)}
          />
        </div>
        <div className={"w-1/6"}>
          <Button onClick={onAddPayment} RightIcon={PlusIcon}>
            Ajouter un paiement
          </Button>
        </div>
      </header>
    </>
  );
};
