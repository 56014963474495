import React from "react";
import {GuestWebsiteGuestReviewCard} from "../../../../types/GETTypes";
import useGuestWebsiteStore from "../../../../stores/guestWebsiteStore/useGuestWebsiteStore";

const ReviewCardComponent = ({
  review,
  photo,
  name,
}: GuestWebsiteGuestReviewCard) => {
  const {dataGuestWebsite} = useGuestWebsiteStore();
  const {styles} = dataGuestWebsite || {};
  const {color_background,color_text} = styles || {};

  return (
    <div
      className="bg-white  shadow-md rounded-lg p-6 flex flex-col gap-4 flex-1"
      // style={{backgroundColor: color_background}}
    >
      {/* Review Text */}
      <p className="" style={{color: color_text}}>
        {review}
      </p>

      {/* User Info */}
      <div className="flex items-center gap-4">
        <img
          src={photo}
          alt={name}
          className="w-12 h-12 rounded-full object-cover"
        />
        <div>
          <h4 className="font-bold " style={{color: color_text}}>
            {name}
          </h4>
        </div>
      </div>
    </div>
  );
};

export default ReviewCardComponent;
