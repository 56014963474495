import { t } from "i18next";
import React, { useState } from "react";
import EditIcon from "../../../../assets/icons/edit.svg?react";
import WifiIcon from "../../../../assets/icons/wifi.svg?react";
import { getCurrentCanEdit } from "../../../../helpers/workspaceHelper";
import useUserStore from "../../../../stores/useUserStore";
import { Card } from "../../../Common/Card/Card";
import { RentalWebsiteServicesCardProps } from "./RentalWebsiteServicesCard.type";
import { RentalWebsiteServicesCardSkeleton } from "./RentalWebsiteServicesCardSkeleton";

export const RentalWebsiteServicesCard: React.FC<
  RentalWebsiteServicesCardProps
> = ({ loading, rentalId, modal, rentalServices, workspaceOwner }) => {
  const userStore = useUserStore();
  const [loadingValidation, setLoadingValidation] = useState<boolean>(false);

  const isEditButtonDisabled = () => {
    if (!getCurrentCanEdit({ user: userStore.user, workspaceOwner }))
      return true;

    return loadingValidation;
  };

  if (loading) return <RentalWebsiteServicesCardSkeleton />;

  return (
    <Card
      Icon={WifiIcon}
      label={t("Rental.Website.Services.title")}
      anchor="services-card"
      loading={loadingValidation}
      button={{
        Icon: EditIcon,
        type: "secondary",
        label: t("Global.edit"),
        onClick: () => modal.open(rentalServices),
        disabled: isEditButtonDisabled(),
      }}
    >
      <>
        <div className="flex flex-wrap justify-center">
          <div className="grid grid-cols-8 gap-3">
            {rentalServices &&
              rentalServices.length > 0 &&
              rentalServices.map((service, index) => (
                <div
                  className="flex flex-col justify-center space-y-2 w-[12,5%]"
                  key={index}
                >
                  <img
                    className="object-cover object-center w-full border rounded border-element-border-light aspect-square"
                    src={service.photo}
                    alt={service.name}
                  />
                  <p className="w-full break-all text-low-contrast">
                    {service.name}
                  </p>
                </div>
              ))}
          </div>
        </div>
      </>
    </Card>
  );
};
