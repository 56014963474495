import moment from "moment";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ArrowLeftIcon from "../../../assets/icons/arrow-left.svg?react";
import ArrowRightWhiteIcon from "../../../assets/icons/arrow-right-white.svg?react";
import BedIcon from "../../../assets/icons/bed.svg?react";
import CleanIcon from "../../../assets/icons/clean.svg?react";
import DogIcon from "../../../assets/icons/dog.svg?react";
import GivingMoneyIcon from "../../../assets/icons/giving-money.svg?react";
import InformationIcon from "../../../assets/icons/information.svg?react";
import MoneyBagIcon from "../../../assets/icons/money-bag.svg?react";
import MoneyIcon from "../../../assets/icons/money.svg?react";
import MoonIcon from "../../../assets/icons/moon-2.svg?react";
import SingleBedIcon from "../../../assets/icons/single-beg.svg?react";
import UsersIcon from "../../../assets/icons/user-group.svg?react";
import paths from "../../../constants/paths";
import { post } from "../../../helpers/APIHelper";
import { getFormattedPrice } from "../../../helpers/currencyHelper";
import { Button } from "../../Common/Button/Button";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import { NumberInput } from "../../Common/NumberInput/NumberInput";
import { Separator } from "../../Common/Separator/Separator";
import {
  AddReservationTarificationType,
  AddReservationType,
} from "../AddReservation.type";
import { AddReservationPricesFees } from "./AddReservationPricesFees";

type formSchema = AddReservationTarificationType;

export const AddReservationPrices: React.FC<{
  reservation: AddReservationType;
  onCancel: () => void;
  onNext: (tarification: AddReservationTarificationType) => void;
  onBack: () => void;
  onOpeningPricesModal: () => void;
}> = ({ reservation, onCancel, onNext, onBack, onOpeningPricesModal }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const form = useForm<formSchema>({
    defaultValues: reservation?.tarification,
  });

  const handleNextStep = async (values: formSchema) => {
    setError(null);

    let isError = false;

    if (isError) {
      return;
    }

    setLoading(true);

    const data = {
      rental_id: reservation.rental?.id.toString(),
      platform_id: reservation.general?.platform_id,
      checkin: moment(reservation.general?.checkin).format("YYYY-MM-DD"),
      checkout: moment(reservation.general?.checkout).format("YYYY-MM-DD"),
      time_in: reservation?.general?.time_in,
      time_out: reservation?.general?.time_out,
      adults_count: reservation.general?.adults_count,
      children_count: reservation.general?.children_count,
      nights_count: moment(reservation.general?.checkout).diff(
        moment(reservation.general?.checkin),
        "days"
      ),
      nights_total_price: values.nights_total_price,
      city_tax: values.city_tax ?? 0,
      cleaning_default: values.cleaning_default ?? 0,
      additional_guests_total_price: values.additional_guests_total_price ?? 0,
      pet_default: values.pet_default ?? 0,
      linen_default: values.linen_default ?? 0,
      infant_bed_default: values.infant_bed_default ?? 0,
      other: values.other ?? 0,
    };

    const res = await post(
      `${import.meta.env.VITE_API_URL}${
        paths.API.RESERVATION_ADD_CHECK_TARIFICATION
      }`,
      data
    );

    if (!res?.data?.success) {
      setError(res?.response?.data?.message);
      setLoading(false);
      return;
    }

    const result = res.data.result;

    onNext({
      ...values,
      total: result.tarification.reservation_total_price,
    });
  };

  const getTotalAdditionalFees = (): string =>
    getFormattedPrice({
      price:
        Number(form.getValues("linen_default")) +
        Number(form.getValues("additional_guests_total_price")) +
        Number(form.getValues("pet_default")) +
        Number(form.getValues("infant_bed_default")) +
        Number(form.getValues("other")),
      decimals: 2,
      currency: "EUR",
      locale: "fr-FR",
    });

  const getTotalPriceWithFees = (): string =>
    getFormattedPrice({
      price:
        Number(form.getValues("nights_total_price")) +
        Number(form.getValues("city_tax")) +
        Number(form.getValues("cleaning_default")) +
        Number(form.getValues("linen_default")) +
        Number(form.getValues("additional_guests_total_price")) +
        Number(form.getValues("pet_default")) +
        Number(form.getValues("infant_bed_default")) +
        Number(form.getValues("other")),
      decimals: 2,
      currency: "EUR",
      locale: "fr-FR",
    });

  form.watch();

  return (
    <form onSubmit={form.handleSubmit(handleNextStep)} className="h-full">
      <div className="flex flex-col justify-between h-full">
        {/* PREVIOUS STEP BUTTON */}
        <div className="flex justify-start mb-12">
          <div className="w-44">
            <Button
              type="secondary"
              disabled={loading}
              LeftIcon={ArrowLeftIcon}
              onClick={onBack}
            >
              {t("Global.previousStep")}
            </Button>
          </div>
        </div>

        {/* TITLE */}
        <p className="text-xl font-semibold text-high-contrast">
          {t("AddReservation.Prices.title")}
        </p>

        {/* SUBTITLE */}
        <p className="my-4 text-base font-light text-low-contrast">
          {t("AddReservation.Prices.subTitlePartOne")}
          <strong className={"font-bold"}>
            {t("AddReservation.Prices.subTitlePartTwo")}
          </strong>
          {t("AddReservation.Prices.subTitlePartThree")}
        </p>

        {/* CONTENT */}
        <div className="flex flex-col flex-1 overflow-y-auto text-sm">
          <div className="flex flex-col p-4 mt-4 rounded-lg gap-y-3 bg-element-background border-1 border-element-border">
            <div className="flex flex-row items-center justify-between">
              <div className="flex items-center gap-x-1">
                <MoonIcon className="size-5" />
                <p className="text-sm text-low-contrast">
                  {t("AddReservation.Prices.totalNights")}
                </p>
              </div>
              <p className="text-lg font-bold tracking-widest text-high-contrast">
                {getFormattedPrice({
                  price: Number(form.getValues("nights_total_price")) ?? 0,
                  locale: "fr-FR",
                  currency: "EUR",
                  decimals: 2,
                })}
              </p>
            </div>

            <div className="flex flex-row items-center justify-between">
              <div className="flex items-center gap-x-1">
                <GivingMoneyIcon className="size-5" />
                <p className="text-sm text-low-contrast">
                  {t("AddReservation.Prices.cityTaxes")}
                </p>
              </div>
              <p className="text-lg font-bold tracking-widest text-high-contrast">
                {getFormattedPrice({
                  price: Number(form.getValues("city_tax")) ?? 0,
                  locale: "fr-FR",
                  currency: "EUR",
                  decimals: 2,
                })}
              </p>
            </div>

            <div className="flex flex-row items-center justify-between">
              <div className="flex items-center gap-x-1">
                <CleanIcon className="size-5" />
                <p className="text-sm text-low-contrast">
                  {t("AddReservation.Prices.cleanFees")}
                </p>
              </div>
              <p className="text-lg font-bold tracking-widest text-high-contrast">
                {getFormattedPrice({
                  price: Number(form.getValues("cleaning_default")) ?? 0,
                  locale: "fr-FR",
                  currency: "EUR",
                  decimals: 2,
                })}
              </p>
            </div>

            <div className="flex flex-row items-center justify-between">
              <div className="flex items-center gap-x-1">
                <MoneyIcon className="size-5" />
                <p className="text-sm text-low-contrast">
                  {t("AddReservation.Prices.extraFees")}
                </p>
              </div>
              <p className="text-lg font-bold tracking-widest text-high-contrast">
                {getTotalAdditionalFees()}
              </p>
            </div>

            <Separator accent="dark" />

            <div className="flex flex-row items-center justify-between">
              <div className="flex items-center gap-x-1">
                <MoneyBagIcon className="size-5" />
                <p className="text-sm text-low-contrast">
                  {t("AddReservation.Prices.totalNightsAndFees")}
                </p>
              </div>
              <p className="text-xl font-bold tracking-widest text-high-contrast">
                {getTotalPriceWithFees()}
              </p>
            </div>
          </div>

          <div className="flex flex-col mt-8 gap-y-8">
            <div className="flex items-end gap-x-4">
              {/* TOTAL NIGHTS */}
              <div className="flex-1">
                <Controller
                  control={form.control}
                  name="nights_total_price"
                  rules={{
                    required: {
                      value: true,
                      message: t("Global.Errors.requiredField", {
                        fieldName: t("AddReservation.Prices.totalNights"),
                      }),
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <NumberInput
                      label={t("AddReservation.Prices.totalNights")}
                      required={true}
                      size="large"
                      acceptDecimal={true}
                      currency="€"
                      value={value}
                      onChangeText={onChange}
                      error={form.formState.errors.nights_total_price?.message}
                      disabled={loading}
                    />
                  )}
                />
              </div>

              <div className="flex-1">
                <Button
                  type="secondary"
                  RightIcon={InformationIcon}
                  onClick={onOpeningPricesModal}
                  disabled={
                    Number(form.getValues("nights_total_price")).toFixed(2) !==
                    Number(
                      reservation?.tarification?.nights_total_price
                    ).toFixed(2)
                  }
                >
                  {t("AddReservation.Prices.detailPriceNightsButton")}
                </Button>
              </div>
            </div>

            <div className="flex items-start gap-x-4">
              {/* HOLIDAY TAX */}
              <div className="flex-1">
                <Controller
                  control={form.control}
                  name="city_tax"
                  render={({ field: { onChange, value } }) => (
                    <NumberInput
                      label={t("AddReservation.Prices.cityTaxesTotal")}
                      size="large"
                      acceptDecimal={true}
                      currency="€"
                      value={value}
                      min={0}
                      onChangeText={onChange}
                      error={form.formState.errors.city_tax?.message}
                      disabled={loading}
                    />
                  )}
                />
                <p className="mt-1 text-xs font-extralight text-low-contrast">
                  {t("AddReservation.Prices.cityTaxesTotalDescription")}
                </p>
              </div>
              {/* CLEANING TAX */}
              <div className="flex-1">
                <Controller
                  control={form.control}
                  name="cleaning_default"
                  render={({ field: { onChange, value } }) => (
                    <NumberInput
                      label={t("AddReservation.Prices.cleanFees")}
                      size="large"
                      acceptDecimal={true}
                      currency="€"
                      value={value}
                      min={0}
                      onChangeText={onChange}
                      error={form.formState.errors.cleaning_default?.message}
                      disabled={loading}
                    />
                  )}
                />
              </div>
            </div>
          </div>

          <div className="mt-4">
            <Separator />
          </div>

          <div className="mt-4 mb-4">
            <p className="text-base font-semibold text-high-contrast">
              {t("AddReservation.Prices.extraFees")}
            </p>
          </div>

          <div className="flex flex-col w-full gap-4 mb-8">
            <Controller
              control={form.control}
              name="linen_default"
              render={({ field: { onChange, value } }) => (
                <AddReservationPricesFees
                  Icon={BedIcon}
                  label={t("AddReservation.Prices.linenFees")}
                  value={value}
                  onChange={(value) => onChange(value)}
                  error={form.formState.errors.linen_default?.message}
                  disabled={loading}
                />
              )}
            />

            <Controller
              control={form.control}
              name="additional_guests_total_price"
              render={({ field: { onChange, value } }) => (
                <AddReservationPricesFees
                  Icon={UsersIcon}
                  label={t("AddReservation.Prices.extraGuestFees")}
                  value={value}
                  onChange={(value) => onChange(value)}
                  error={
                    form.formState.errors.additional_guests_total_price?.message
                  }
                  disabled={loading}
                  helpText={t("Rental.Pricing.PricingRules.toGuestFeesHelp", {
                    price: value,
                    currency: "€",
                    guests: reservation.rental?.guests_default_capacity,
                  })}
                />
              )}
            />

            <Controller
              control={form.control}
              name="pet_default"
              render={({ field: { onChange, value } }) => (
                <AddReservationPricesFees
                  Icon={DogIcon}
                  label={t("AddReservation.Prices.animalsFees")}
                  value={value}
                  onChange={(value) => onChange(value)}
                  error={form.formState.errors.pet_default?.message}
                  disabled={loading}
                />
              )}
            />

            <Controller
              control={form.control}
              name="infant_bed_default"
              render={({ field: { onChange, value } }) => (
                <AddReservationPricesFees
                  Icon={SingleBedIcon}
                  label={t("AddReservation.Prices.babyBedFees")}
                  value={value}
                  onChange={(value) => onChange(value)}
                  error={form.formState.errors.infant_bed_default?.message}
                  disabled={loading}
                  currency={"€"}
                />
              )}
            />

            <Controller
              control={form.control}
              name="other"
              render={({ field: { onChange, value } }) => (
                <AddReservationPricesFees
                  Icon={MoneyIcon}
                  label={t("AddReservation.Prices.otherFees")}
                  value={value}
                  onChange={(value) => onChange(value)}
                  error={form.formState.errors.other?.message}
                  disabled={loading}
                />
              )}
            />
          </div>
        </div>

        <ErrorMessage>{error}</ErrorMessage>

        {/* BUTTONS */}
        <div className="flex gap-4 pb-4 mt-4">
          <Button type="secondary" disabled={loading} onClick={onCancel}>
            {t("Global.cancel")}
          </Button>
          <Button
            RightIcon={ArrowRightWhiteIcon}
            loading={loading}
            disabled={loading || !form.formState.isValid}
          >
            {t("AddReservation.Prices.nextStep")}
          </Button>
        </div>
      </div>
    </form>
  );
};
