import React from "react";
import ClockFastIcon from "../../../../../../assets/icons/speed-fast.svg?react";
import AvatarSquareCheckIcon from "../../../../../../assets/icons/avatar-check-square.svg?react";
import useGuestWebsiteStore from "../../../../../../stores/guestWebsiteStore/useGuestWebsiteStore";
import {useTranslation} from "react-i18next";

const InstantReservation = () => {
  const {t} = useTranslation();
  const {rentalData, dataGuestWebsite} = useGuestWebsiteStore();
  const isRequired = rentalData?.website.rules.reservation_request_required;
  const {styles} = dataGuestWebsite || {};
  const {color_text} = styles || {};
  if (!isRequired) {
    return (
      <div className="bg-white shadow-md rounded-lg p-4 flex items-center space-x-4">
        <ClockFastIcon className="text-gray-500 text-xl" />
        <div>
          <h3 className="text-sm font-bold" style={{color: color_text}}>
            {t("GuestWebsite.Rental.Home.instantBooking")}
          </h3>
          <p className="text-sm text-gray-500">
            {t("GuestWebsite.Rental.Home.instantBookingInfo")}
          </p>
        </div>
      </div>
    );
  }
  return (
    <div className="bg-white shadow-md rounded-lg p-4 flex items-center space-x-4">
      <AvatarSquareCheckIcon className="text-gray-500 text-xl" />
      <div>
        <h3 className="text-sm font-bold" style={{color: color_text}}>
          {t("GuestWebsite.Rental.Home.noInstantBooking")}
        </h3>
        <p className="text-sm text-gray-500">
          {t("GuestWebsite.Rental.Home.noInstantBookingInfo")}
        </p>
      </div>
    </div>
  );
};

export default InstantReservation;
