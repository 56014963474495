import React from "react";
import { CenterModal } from "../Common/CenterModal/CenterModal";
import CalendarIcon from "../../assets/icons/calendar.svg?react";
import { useTranslation } from "react-i18next";
import { Button } from "../Common/Button/Button";

export const CheckPriceModal: React.FC<{
  isVisible: boolean;
  onClose: () => void;
}> = ({ isVisible, onClose }) => {
  const { t } = useTranslation();

  const getHeader = () => {
    return (
      <div className="bg-element-background h-40 rounded-t-lg flex items-center justify-center">
        <CalendarIcon className="size-10" />
      </div>
    );
  };

  return (
    <CenterModal
      isVisible={isVisible}
      onClose={onClose}
      CustomHeader={getHeader()}
    >
      <div className="flex flex-col gap-3">
        <p className="text-xl font-semibold text-high-contrast">
          {t("Calendar.CheckPriceModal.title")}
        </p>
        <p className="text-sm font-light text-low-contrast pb-2">
          {t("Calendar.CheckPriceModal.content")}
        </p>

        <Button onClick={onClose}>
          {t("Calendar.CheckPriceModal.button")}
        </Button>
      </div>
    </CenterModal>
  );
};
