/**
 * Component to display multiple images in full screen
 * with the ability to navigate between them and close the full-screen mode.
 * To be used for image display: when clicking on an image,
 * this component is triggered to show it in full screen.
 */
import React, {useEffect, useState} from "react";
import {cn} from "../../../../../../helpers/classHelper";
import {UseModal} from "../../../../../../hooks/useModal";
import {CenterModal} from "../../../../../Common/CenterModal/CenterModal";
import {useTranslation} from "react-i18next";

interface CustomPreviewImageProps {
  imageModal: UseModal<unknown>;
  imgs: ImageData[];
  onClose?: () => void;
}
export interface ImageData {
  name: string;
  url: string;
}
const ImageComponent = ({
  imageData,
  className,
  type = "medium",
}: {
  imageData: ImageData | undefined;
  className?: string;
  type?: "medium" | "large";
}) => {
  const {url, name} = imageData || {};
  if (!imageData) {
    return <></>;
  }
  const getHeight = () => {
    if (type === "large") {
      return "h-[255px] md:h-[520px]";
    }
    return "h-[255px] md:h-[400px]";
  };

  const getWidth = () => {
    if (type === "large") {
      return "w-full";
    }
    return "w-full md:w-2/5";
  };

  return (
    <img
      src={url}
      alt={name}
      className={cn(
        `flex flex-1 ${getHeight()} ${getWidth()} object-cover`,
        className
      )}
    />
  );
};
export const CustomPreviewImage: React.FC<CustomPreviewImageProps> = ({
  imageModal,
  imgs = [],
  onClose = () => {},
}) => {
  const {t} = useTranslation();
  const firstImg = imgs ? imgs?.[0] : undefined;
  const otherImgs = imgs?.length > 1 ? imgs?.slice(1) : [];

  useEffect(() => {
    const body: HTMLElement | null = document.querySelector("body");
    if (body != null) {
      body.style.overflow = imageModal.isVisible ? "hidden" : "visible";
    }
  }, [imageModal.isVisible]);

  const handleOnCloseImage = () => {
    if (typeof onClose === "function") {
      onClose();
    }
    imageModal.close();
  };

  return (
    <CenterModal
      isVisible={imageModal.isVisible}
      onClose={handleOnCloseImage}
      title={t("GuestWebsite.photos")}
      size="large"
      classNames={{
        divModal: "max-h-[90vh] overflow-y-auto",
        divLabel: "sticky top-0 bg-white z-10",
      }}
    >
      <div className="flex flex-col items-center h-full overflow-y-auto">
        <ImageComponent imageData={firstImg} type="large" className="mb-2" />
        <div className="flex md:flex-row md:flex-wrap flex-col md:gap-x-2 gap-y-2 w-full">
          {otherImgs?.map((imgData) => (
            <ImageComponent imageData={imgData} type="medium" />
          ))}
        </div>
      </div>
    </CenterModal>
  );
};
