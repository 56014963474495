import React from "react";
import { ResctrictionLineProps } from "./Calendar.type";
import {
  CALENDAR_NB_DAYS_AFTER,
  CALENDAR_NB_DAYS_BEFORE,
  CALENDAR_RENTAL_NAME_WIDTH,
  CALENDAR_RESTRICTION_HEIGHT,
} from "../../constants/calendar";
import { CalendarRestrictionCellSkeleton } from "./RestrictionCellSkeleton";
import { CalendarRestrictionCell } from "./RestrictionCell";
import { Tooltip } from "../Common/Tooltip/Tooltip";
import InformationIcon from "../../assets/icons/information-fill.svg?react";
import { Badge } from "../Common/Badge/Badge";
import { useTranslation } from "react-i18next";
import { cn } from "../../helpers/classHelper";

export const CalendarResctrictionLine: React.FC<ResctrictionLineProps> = ({
  label,
  rental,
  restrictionField,
  nbDays,
  daysOffset,
  postfix = "",
  toolTip,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <tr>
      <td
        style={{
          width: CALENDAR_RENTAL_NAME_WIDTH,
          height: CALENDAR_RESTRICTION_HEIGHT,
        }}
        className="bg-gray-50 border-t-1 border-element-border"
      >
        <div className="flex gap-2 items-center cursor-pointer">
          <div className="flex items-center">
            <p className="text-low-contrast font-light flex-1">{label}</p>

            {toolTip && (
              <div className="relative ml-2 group">
                {/* Tooltip, caché par défaut et visible au survol */}
                <div
                  className={cn(
                    "absolute bg-tooltip-bg rounded-6px p-2 py-0 text-[10px] text-white hidden group-hover:flex",
                  )}
                  style={{
                    left: 16,
                    top: -3,
                    zIndex: 100,
                    width: toolTip.width,
                  }}
                >
                  {t(toolTip.i18nKeyLabel)}
                </div>

                {/* Icône sur laquelle le survol déclenche l'affichage du tooltip */}
                <div>
                  <InformationIcon />
                </div>
              </div>
            )}
          </div>

          <div
            className="absolute bg-element-border"
            style={{
              width: 1,
              height: CALENDAR_RESTRICTION_HEIGHT + 3,
              left: CALENDAR_RENTAL_NAME_WIDTH - 2,
              top: -4,
            }}
          />
        </div>
      </td>

      {Array.from({ length: CALENDAR_NB_DAYS_BEFORE }).map(() => (
        <CalendarRestrictionCellSkeleton />
      ))}

      {Array.from({ length: nbDays }).map((v, index) => (
        <CalendarRestrictionCell
          key={index}
          index={index}
          rental={rental}
          restrictionField={restrictionField}
          postfix={postfix}
          daysOffset={daysOffset}
          onClick={onClick}
        />
      ))}

      {Array.from({ length: CALENDAR_NB_DAYS_AFTER }).map(() => (
        <CalendarRestrictionCellSkeleton />
      ))}
    </tr>
  );
};
