import React from "react";
import {cn} from "../../../../helpers/classHelper";
import {ClassNamesType} from "../../../../types/commonTypes";
import useGuestWebsiteStore from "../../../../stores/guestWebsiteStore/useGuestWebsiteStore";

interface TitleProps {
  title: string;
  subtitle?: string;
  classNames?: ClassNamesType;
}
const TitleComponent = ({title, subtitle, classNames}: TitleProps) => {
  const {dataGuestWebsite} = useGuestWebsiteStore();
  const {styles} = dataGuestWebsite || {};
  const {color_text} = styles || {};
  const subtitleParts = subtitle?.split("<br />");
  return (
    <div
      className={cn(
        "flex items-center justify-center py-10",
        classNames?.divParent
      )}
    >
      <div className={cn("text-center px-4", classNames?.divLabel)}>
        {/* Title */}
        <h1
          className={cn("text-xxxl font-bold", classNames?.title)}
          style={{color: color_text}}
        >
          {title}
        </h1>

        {/* Subtitle */}
        <p
          className={cn(
            "mt-6 text-base font-bold space-y-2",
            classNames?.description
          )}
          style={{color: color_text}}
        >
          {subtitleParts?.map((part, index) => (
            <p key={index}>{part.trim()}</p>
          ))}
        </p>
      </div>
    </div>
  );
};

export default TitleComponent;
