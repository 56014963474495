import React from "react";
import {Cell, Pie, PieChart, ResponsiveContainer, Tooltip} from "recharts";
import Rental5Thumbnail from "../../../assets/images/rental5.webp";
import {COLORS_PIE_CHART_ANALYTICS} from "../../../constants/chart";
import {AnalyticsDetailedRevenueByRental} from "../../../types/GETTypes";
import {useTranslation} from "react-i18next";

interface PieChartProps {
  data: AnalyticsDetailedRevenueByRental[] | undefined;
  chartHeight?: number;
  loading?: boolean;
  currency: string | undefined;
}
const PieChartComponent = ({
  data,
  chartHeight = 200,
  currency,
  loading,
}: PieChartProps) => {
  const {t} = useTranslation();
  const COLORS_WITH_OPACITY = COLORS_PIE_CHART_ANALYTICS.map((item, index) => ({
    ...item,
    opacity: `${
      ((COLORS_PIE_CHART_ANALYTICS.length - index - 1) /
        (COLORS_PIE_CHART_ANALYTICS.length - 1)) *
      100
    }%`,
  }));

  if (loading || !data) {
    return (
      <div className="p-4  w-full rounded-md animate-pulse mt-8">
        {/* <!-- Pie Chart --> */}
        <div className="h-5 bg-gray-200 w-3/4 mb-4"></div>
        <div className="flex flex-col items-center">
          <div className="w-2/5 h-44 bg-gray-200 mb-4 rounded-md"></div>

          {/* <!-- Data List --> */}
          <div className="w-full pl-4">
            <div className="flex items-center justify-between border-b last:border-0 py-2">
              <div className="flex items-center space-x-2">
                <div className="flex items-center space-x-4">
                  <div className="w-4 h-4 rounded-4px  border-1 border-gray-200"></div>
                  <div className="w-8 h-8 rounded-4px bg-gray-200"></div>
                </div>
                <div className="flex flex-col">
                  <div className="h-4 bg-gray-200 w-2/5 mb-1"></div>
                  <div className="h-3 bg-gray-200 w-3/5"></div>
                </div>
              </div>
              <div className="flex space-x-2">
                <div className="h-5 bg-gray-200 w-16"></div>
                <div className="h-3 bg-gray-200 w-8"></div>
              </div>
            </div>
            <div className="flex items-center justify-between border-b last:border-0 py-2">
              <div className="flex items-center space-x-2">
                <div className="flex items-center space-x-4">
                  <div className="w-4 h-4 rounded-4px  border-1 border-gray-200"></div>
                  <div className="w-8 h-8 rounded-4px bg-gray-200"></div>
                </div>
                <div className="flex flex-col">
                  <div className="h-4 bg-gray-200 w-2/5 mb-1"></div>
                  <div className="h-3 bg-gray-200 w-3/5"></div>
                </div>
              </div>
              <div className="flex space-x-2">
                <div className="h-5 bg-gray-200 w-16"></div>
                <div className="h-3 bg-gray-200 w-8"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="p-4  w-full  border-1 border-element-border rounded-md">
      {/* Pie Chart */}
      <h2 className="font-bold mb-4">{t("Analytics.revenueByRentals")}</h2>
      <div className="flex flex-col items-center">
        <div className="w-full">
          <ResponsiveContainer width="100%" height={chartHeight}>
            <PieChart>
              <Pie
                data={data}
                dataKey="revenue"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={80}
                innerRadius={50}
                fill="#8884d8"
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={
                      COLORS_WITH_OPACITY[index % COLORS_WITH_OPACITY.length]
                        .color
                    }
                    opacity={
                      COLORS_WITH_OPACITY[index % COLORS_WITH_OPACITY.length]
                        .opacity
                    }
                  />
                ))}
              </Pie>
              <Tooltip formatter={(value) => `${value?.toLocaleString('fr-FR')} €`} />
            </PieChart>
          </ResponsiveContainer>
        </div>

        {/* Data List */}
        <div className="w-full pl-4">
          {data.map((item, index) => {
            return (
              <div
                key={index}
                className="flex items-center justify-between border-b last:border-0 py-2"
              >
                <div className="flex items-center space-x-2">
                  <div className="flex items-center space-x-4">
                    <div
                      className={`w-4 h-4 rounded-4px ${
                        index === 5 ? "border-1 border-element-border" : ""
                      }`}
                      style={{
                        backgroundColor:
                          COLORS_WITH_OPACITY[
                            index % COLORS_WITH_OPACITY.length
                          ].color,
                        opacity:
                          index === 5
                            ? 1
                            : COLORS_WITH_OPACITY[
                                index % COLORS_WITH_OPACITY.length
                              ].opacity,
                      }}
                    />
                    {!item.cover_url ? (
                      <div className="w-8 h-8 rounded-4px" />
                    ) : (
                      <img
                        className="w-8 h-8 rounded-4px"
                        src={item.cover_url}
                      />
                    )}
                  </div>
                  <div className="flex flex-col">
                    <div className="font-bold">{item.name}</div>
                    <div className="text-low-contrast">{item.address}</div>
                  </div>
                </div>
                <div className="flex space-x-2">
                  <span className="font-bold">
                    {item.revenue?.toLocaleString('fr-FR')} {currency ?? "€"}
                  </span>
                  <span className="text-sm text-gray-500">
                    ({item.percentage?.toLocaleString('fr-FR')}%)
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PieChartComponent;
