import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import QuestionMarkCircleIcon from "../../../assets/icons/question-mark-circle.svg?react";
import { Card } from "../../Common/Card/Card";
import { WebsiteResponse } from "../../../types/GETTypes";
import { useTranslation } from "react-i18next";
import { useToggleCard } from "./useToggleCard";
import paths from "../../../constants/paths";
import CheckIcon from "../../../assets/icons/check-white.svg?react";
import EditIcon from "../../../assets/icons/edit.svg?react";
import TrashIcon from "../../../assets/icons/trash.svg?react";
import ArrowLeftIcon from "../../../assets/icons/arrow-left.svg?react";
import PlusIcon from "../../../assets/icons/plus.svg?react";
import { TextInput } from "../../Common/TextInput/TextInput";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import { post } from "../../../helpers/APIHelper";
import { ValueType } from "recharts/types/component/DefaultTooltipContent";
import { TextAreaInput } from "../../Common/TextAreaInput/TextAreaInput";
import { Button } from "../../Common/Button/Button";

export const WebsiteFAQCard: React.FC<{
  website: WebsiteResponse | undefined;
  onUpdateWebsite: (nextWebsite: WebsiteResponse) => void;
}> = ({ website, onUpdateWebsite }) => {
  const { t } = useTranslation();

  const { isEnabled, isEnabledLoading, toggle } = useToggleCard({
    website,
    initialValue: website?.content.faq.enabled,
    baseUrl: `${
      import.meta.env.VITE_API_URL
    }${paths.API.WEBSITE.UPDATE.FAQ_TOGGLE(website?.id!)}`,
  });

  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const [updateError, setUpdateError] = useState<string | undefined>();

  const [editMode, setEditMode] = useState<boolean>(false);

  const editModeRef = useRef<{
    handleClear: () => void;
    handleCheckData: () => {
      title: string;
      subTitle: string;
      faqs: FAQType[];
    } | null;
  } | null>(null);

  const handleCancel = () => {
    setEditMode(false);
    editModeRef.current?.handleClear();
  };

  const handleUpdate = async () => {
    const values = editModeRef.current?.handleCheckData();

    if (!values) return;

    setUpdateLoading(true);
    setUpdateError(undefined);

    const formData = new FormData();
    formData.append("faq_section_title", values.title);
    formData.append("faq_section_subtitle", values.subTitle);

    values.faqs?.forEach((f, index) => {
      if (f.isDeleted) formData.append(`del_ids[${index}]`, f.id.toString());
    });

    formData.append(
      "faqs",
      JSON.stringify(
        values.faqs
          .filter((f) => f.isDeleted === false && f.isNew)
          .map((f) => ({ question: f.question, answer: f.answer }))
      )
    );

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.WEBSITE.UPDATE.FAQ(
        website?.id!
      )}`,
      formData
    );

    if (!res?.data?.success) {
      setUpdateError(res?.response?.data?.message);
    } else {
      onUpdateWebsite(res.data?.result);
      setEditMode(false);
    }

    setUpdateLoading(false);
  };

  useEffect(() => {
    setUpdateError(undefined);
  }, [editMode]);

  return (
    <Card
      Icon={QuestionMarkCircleIcon}
      label={t("Website.FAQ.title")}
      anchor="faq-card"
      switchButton={{
        value: isEnabled,
        label: "",
        loading: isEnabledLoading,
        onClick: toggle,
      }}
      button={{
        Icon: editMode ? CheckIcon : EditIcon,
        type: editMode ? "primary" : "secondary",
        label: editMode ? t("Global.record") : t("Global.edit"),
        onClick: editMode ? () => handleUpdate() : () => setEditMode(!editMode),
        disabled: !website || updateLoading,
      }}
      secondaryButton={
        editMode
          ? {
              label: t("Global.cancel"),
              LeftIcon: ArrowLeftIcon,
              onClick: handleCancel,
              disabled: updateLoading,
            }
          : undefined
      }
    >
      <ErrorMessage>{updateError}</ErrorMessage>

      {editMode ? (
        <EditMode ref={editModeRef} website={website} loading={updateLoading} />
      ) : (
        <ViewMode website={website} />
      )}
    </Card>
  );
};

const ViewMode: React.FC<{ website: WebsiteResponse | undefined }> = ({
  website,
}) => {
  if (!website) return <ViewModeSkeleton />;

  return (
    <div className="flex flex-col gap-3">
      <p className="text-center text-base font-semibold text-high-contrast">
        {website?.content.faq.title}
      </p>

      <p className="text-center font-light text-low-contrast text-xs">
        {website?.content.faq.subtitle}
      </p>

      <div className="grid grid-cols-2 flex-wrap gap-3">
        {website?.content.faq.cards?.map((c) => (
          <div className="border-1 border-element-border rounded-6px bg-element-background p-2 flex flex-col gap-2 justify-center items-center">
            <p className="font-semibold text-high-contrast text-center">
              {c.question}
            </p>

            <p className="font-ligh text-low-contrast text-center">
              {c.answer}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

const ViewModeSkeleton: React.FC<{}> = ({}) => {
  return (
    <div className="flex flex-col gap-3 animate-pulse items-center">
      <p className="text-center text-base font-semibold text-high-contrast h-2 w-72 bg-slate-300 rounded"></p>

      <p className="text-center font-light text-low-contrast text-xs h-2 w-52 bg-slate-300 rounded mt-2"></p>

      <div className="grid grid-cols-2 flex-wrap gap-3 w-full mt-3">
        {Array.from({ length: 2 }).map((c) => (
          <div className="border-1 border-element-border rounded-6px bg-element-background p-2 flex flex-col gap-2 justify-center items-center">
            <p className="font-semibold text-high-contrast text-center h-2 w-72 bg-slate-300 rounded"></p>

            <p className="font-ligh text-low-contrast text-center h-2 w-52 bg-slate-200 rounded mt-2"></p>
            <p className="font-ligh text-low-contrast text-center h-2 w-52 bg-slate-200 rounded mt-2"></p>
            <p className="font-ligh text-low-contrast text-center h-2 w-52 bg-slate-200 rounded mt-2"></p>
          </div>
        ))}
      </div>
    </div>
  );
};

interface EditModeProps {
  website: WebsiteResponse | undefined;
  loading: boolean;
}

type FAQType = {
  id: ValueType;
  question: string;
  answer: string;
  isDeleted: boolean;
  isNew?: boolean;
  error: string | undefined;
};

const EditMode = forwardRef<
  {
    handleClear: () => void;
    handleCheckData: () => {
      title: string;
      subTitle: string;
      faqs: FAQType[];
    } | null;
  },
  EditModeProps
>(({ website, loading }, ref) => {
  const { t } = useTranslation();

  const [title, setTitle] = useState<string>("");
  const [titleError, setTitleError] = useState<string | undefined>();

  const [subTitle, setSubTitle] = useState<string>("");

  const [faqs, setFaqs] = useState<FAQType[]>([]);

  const handleClear = () => {
    setTitle("");
    setTitleError(undefined);

    setSubTitle("");
  };

  const handleCheckData = () => {
    setTitleError(undefined);

    let isError = false;

    if (!title) {
      setTitleError(t("Global.required"));
      isError = true;
    }

    faqs
      .filter((f) => f.isDeleted === false)
      .forEach((faq) => {
        if (faq.error) {
          isError = true;
        }
      });

    if (isError) return null;

    return {
      title,
      subTitle,
      faqs,
    };
  };

  useImperativeHandle(ref, () => ({
    handleClear,
    handleCheckData,
  }));

  useEffect(() => {
    if (website) {
      setTitle(website?.content.faq.title);
      setTitleError(undefined);

      setSubTitle(website?.content.faq.subtitle);

      setFaqs(
        website?.content.faq.cards?.map((c) => ({
          id: c.id,
          question: c.question,
          answer: c.answer,
          isDeleted: false,
          error: undefined,
        }))
      );
    }
  }, [website]);

  // Title and sub title
  const handleChangeTitle = (text: string) => {
    setTitle(text);

    setTitleError(undefined);
  };

  const handleChangeSubTitle = (text: string) => {
    setSubTitle(text);
  };

  const handleChangeFAQ = (nextFAQ: FAQType) => {
    console.log(nextFAQ);

    setFaqs((prevFaqs) =>
      prevFaqs.map((faq) => (faq.id === nextFAQ.id ? nextFAQ : faq))
    );
  };

  const handleAddFAQ = () => {
    setFaqs((prevFaqs) => [
      ...prevFaqs,
      {
        id: new Date().toString(),
        question: "",
        answer: "",
        error: t("Website.FAQ.required"),
        isDeleted: false,
        isNew: true,
      },
    ]);
  };

  return (
    <div className="flex flex-col gap-3">
      <TextInput
        label={t("Website.Infos.titleLabel")}
        value={title}
        onChangeText={handleChangeTitle}
        error={titleError}
        required={true}
        disabled={loading}
      />

      <TextInput
        label={t("Website.Infos.subTitleLabel")}
        value={subTitle}
        onChangeText={handleChangeSubTitle}
        disabled={loading}
      />

      {faqs
        .filter((f) => f.isDeleted === false)
        .map((faq, index) => (
          <FAQItem
            key={faq.id?.toString() ?? index}
            faq={faq}
            loading={loading}
            onChange={(value) => handleChangeFAQ(value)}
          />
        ))}

      <Button type="secondary" RightIcon={PlusIcon} onClick={handleAddFAQ}>
        {t("Website.FAQ.addQuestion")}
      </Button>
    </div>
  );
});

const FAQItem: React.FC<{
  faq: FAQType;
  loading: boolean;
  onChange: (value: FAQType) => void;
}> = ({ faq, loading, onChange }) => {
  const { t } = useTranslation();

  const [question, setQuestion] = useState<string>(faq.question);

  const [answer, setAnswer] = useState<string>(faq.answer);

  const [error, setError] = useState<string | undefined>(faq.error);

  const handleChangeQuestion = (value: string) => {
    let nextError = undefined;

    setError(undefined);

    setQuestion(value);

    if (value === "") {
      nextError = t("Website.FAQ.required");
    }

    setError(nextError);
    onChange({ ...faq, question: value, error: nextError });
  };

  const handleChangeAnswer = (value: string) => {
    let nextError = undefined;

    setError(undefined);

    setAnswer(value);

    if (value === "") {
      nextError = t("Website.FAQ.required");
    }

    setError(nextError);
    onChange({ ...faq, answer: value, error: nextError });
  };

  return (
    <div className="flex flex-col gap-2 p-4 border-1 border-element-border rounded-6px bg-element-background">
      <div className="flex items-end gap-3">
        <TextInput
          label={t("Website.FAQ.question")}
          value={question}
          onChangeText={handleChangeQuestion}
          required={true}
          disabled={loading || !faq.isNew}
        />

        <div className="w-12">
          <Button
            type="secondary"
            size="small"
            onClick={() =>
              onChange({ id: faq.id, question, answer, isDeleted: true, error })
            }
          >
            <TrashIcon />
          </Button>
        </div>
      </div>

      <TextAreaInput
        label={t("Website.FAQ.answer")}
        value={answer}
        onTextChange={handleChangeAnswer}
        required={true}
        disabled={loading || !faq.isNew}
      />

      <ErrorMessage errorType="FORM">{error}</ErrorMessage>
    </div>
  );
};
