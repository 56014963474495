import React, {ReactNode} from "react";

export interface ItemPolicyWithBadgeLayoutProps {
    title: string;
    children: ReactNode;
}

export default function ItemPolicyWithBadgeLayout ({
    title,
    children
}: ItemPolicyWithBadgeLayoutProps)
{
    return (
        <div className={"rounded bg-gray-200/50 p-2"}>
            <h1 className={"text-gray-500"}>{title}</h1>
            <div className={"flex flex-col gap-3 p-2"}>
                {children}
            </div>
        </div>
    );
}