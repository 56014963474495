import React from "react";
import { Card } from "../Common/Card/Card";
import { useTranslation } from "react-i18next";
import useUserStore from "../../stores/useUserStore";
import { WorkspaceResponse } from "../../types/GETTypes";
import { Button } from "../Common/Button/Button";
import { useModal } from "../../hooks/useModal";
import LogOutIcon from "../../assets/icons/log-out-active.svg?react";
import UsersIcon from "../../assets/icons/users.svg?react";
import { LeaveWorkspaceModal } from "./LeaveWorkspaceModal";

export const WorkspaceListCard = () => {
  const { t } = useTranslation();
  const { user } = useUserStore();

  const leaveWorkspaceModal = useModal<WorkspaceResponse>();

  const handleSuccess = () => {
    self.location.reload();
  };

  return (
    <>
      <LeaveWorkspaceModal
        isVisible={leaveWorkspaceModal.isVisible}
        onClose={leaveWorkspaceModal.close}
        data={leaveWorkspaceModal.data}
        onSuccess={handleSuccess}
      />

      <Card label={t("Settings.WorkspaceList.title")} Icon={UsersIcon}>
        <div className="flex flex-col gap-2">
          {user?.workspaces?.map((ws) => (
            <WorkspaceListItem
              key={ws.id}
              ws={ws}
              onLeave={(data) => leaveWorkspaceModal.open(data)}
            />
          ))}
        </div>
      </Card>
    </>
  );
};

const WorkspaceListItem = ({
  ws,
  onLeave,
}: {
  ws: WorkspaceResponse;
  onLeave: (ws: WorkspaceResponse) => void;
}) => {
  const { t } = useTranslation();
  const { user } = useUserStore();

  return (
    <div className="flex justify-between items-center">
      <p className="text-high-contrast font-semibold">{ws.name}</p>

      <div>
        <Button
          type="alert"
          disabled={ws.created_by === user.id}
          onClick={() => onLeave(ws)}
          RightIcon={LogOutIcon}
        >
          {t("Settings.WorkspaceList.leave")}
        </Button>
      </div>
    </div>
  );
};
