import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import PhoneIcon from "../../../assets/icons/phone.svg?react";
import MailIcon from "../../../assets/icons/mail.svg?react";
import CalendarIcon from "../../../assets/icons/calendar.svg?react";

import { Card } from "../../Common/Card/Card";
import { WebsiteResponse } from "../../../types/GETTypes";
import { useTranslation } from "react-i18next";
import { useToggleCard } from "./useToggleCard";
import paths from "../../../constants/paths";
import { post } from "../../../helpers/APIHelper";
import CheckIcon from "../../../assets/icons/check-white.svg?react";
import EditIcon from "../../../assets/icons/edit.svg?react";
import ArrowLeftIcon from "../../../assets/icons/arrow-left.svg?react";
import { TextInput } from "../../Common/TextInput/TextInput";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import { PhoneInput } from "../../Common/PhoneInput/PhoneInput";

export const WebsiteContactCard: React.FC<{
  website: WebsiteResponse | undefined;
  onUpdateWebsite: (nextWebsite: WebsiteResponse) => void;
}> = ({ website, onUpdateWebsite }) => {
  const { t } = useTranslation();

  const { isEnabled, isEnabledLoading, toggle } = useToggleCard({
    website,
    initialValue: website?.content.contact.enabled,
    baseUrl: `${
      import.meta.env.VITE_API_URL
    }${paths.API.WEBSITE.UPDATE.CONTACT_TOGGLE(website?.id!)}`,
  });

  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const [updateError, setUpdateError] = useState<string | undefined>();

  const [editMode, setEditMode] = useState<boolean>(false);

  const editModeRef = useRef<{
    handleClear: () => void;
    handleCheckData: () => {
      email: string;
      phone: string;
      availabilities: string;
    } | null;
  } | null>(null);

  const handleCancel = () => {
    setEditMode(false);
    editModeRef.current?.handleClear();
  };

  const handleUpdate = async () => {
    const values = editModeRef.current?.handleCheckData();

    console.log("values", values);

    if (!values) return;

    setUpdateLoading(true);
    setUpdateError(undefined);

    const formData = new FormData();
    formData.append("contact_section_email", values.email);
    formData.append("contact_section_phone", values.phone);
    formData.append("contact_section_availability", values.availabilities);

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.WEBSITE.UPDATE.CONTACT(
        website?.id!
      )}`,
      formData
    );

    if (!res?.data?.success) {
      setUpdateError(res?.response?.data?.message);
    } else {
      onUpdateWebsite(res.data?.result);
      setEditMode(false);
    }

    setUpdateLoading(false);
  };

  useEffect(() => {
    setUpdateError(undefined);
  }, [editMode]);

  return (
    <Card
      Icon={PhoneIcon}
      label={t("Website.Contact.title")}
      anchor="contact-card"
      switchButton={{
        value: isEnabled,
        label: "",
        loading: isEnabledLoading,
        onClick: toggle,
      }}
      button={{
        Icon: editMode ? CheckIcon : EditIcon,
        type: editMode ? "primary" : "secondary",
        label: editMode ? t("Global.record") : t("Global.edit"),
        onClick: editMode ? () => handleUpdate() : () => setEditMode(!editMode),
        disabled: !website || updateLoading,
      }}
      secondaryButton={
        editMode
          ? {
              label: t("Global.cancel"),
              LeftIcon: ArrowLeftIcon,
              onClick: handleCancel,
              disabled: updateLoading,
            }
          : undefined
      }
    >
      <ErrorMessage>{updateError}</ErrorMessage>

      {editMode ? (
        <EditMode ref={editModeRef} website={website} loading={updateLoading} />
      ) : (
        <ViewMode website={website} />
      )}
    </Card>
  );
};

const ViewMode: React.FC<{ website: WebsiteResponse | undefined }> = ({
  website,
}) => {
  if (!website) return <ViewModeSkeleton />;

  return (
    <div className="flex flex-col gap-2">
      <p className="font-light text-high-contrast flex gap-1 items-center">
        <MailIcon className="size-4" /> {website?.content.contact.email}
      </p>

      <p className="font-light text-high-contrast flex gap-1 items-center">
        <PhoneIcon className="size-4" />
        {website?.content.contact.phone}
      </p>

      <p className="font-light text-high-contrast flex gap-1 items-center">
        <CalendarIcon className="size-4" />{" "}
        {website?.content.contact.availability}
      </p>
    </div>
  );
};

const ViewModeSkeleton: React.FC<{}> = ({}) => {
  return (
    <div className="flex flex-col gap-2">
      <p className="font-light text-high-contrast flex gap-1 items-center">
        <MailIcon className="size-4" />{" "}
        <p className=" h-2 w-72 bg-slate-300 rounded"></p>
      </p>

      <p className="font-light text-high-contrast flex gap-1 items-center">
        <PhoneIcon className="size-4" />{" "}
        <p className=" h-2 w-52 bg-slate-300 rounded"></p>
      </p>

      <p className="font-light text-high-contrast flex gap-1 items-center">
        <CalendarIcon className="size-4" />{" "}
        <p className=" h-2 w-72 bg-slate-300 rounded"></p>
      </p>
    </div>
  );
};

interface EditModeProps {
  website: WebsiteResponse | undefined;
  loading: boolean;
}

const EditMode = forwardRef<
  {
    handleClear: () => void;
    handleCheckData: () => {
      email: string;
      phone: string;
      availabilities: string;
    } | null;
  },
  EditModeProps
>(({ website, loading }, ref) => {
  const { t } = useTranslation();

  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string | undefined>();

  const [phone, setPhone] = useState<string>("");
  const [phoneError, setPhoneError] = useState<string | undefined>();

  const [availabilities, setAvailabilities] = useState<string>("");
  const [availabilitiesError, setAvailabilitiesError] = useState<
    string | undefined
  >();

  const handleClear = () => {
    setEmail("");
    setEmailError(undefined);

    setPhone("");
    setPhoneError(undefined);

    setAvailabilities("");
    setAvailabilitiesError(undefined);
  };

  const handleCheckData = () => {
    // Réinitialiser les erreurs
    setEmailError(undefined);
    setPhoneError(undefined);
    setAvailabilitiesError(undefined);

    let isError = false;

    // Vérification de l'email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email || !emailRegex.test(email)) {
      setEmailError(t("Global.invalid"));
      isError = true;
    }

    // Vérification du téléphone (chiffres, tiret, ou plus uniquement)
    const phoneRegex = /^[\d\-+]+$/;
    if (!phone || !phoneRegex.test(phone)) {
      setPhoneError(t("Global.invalid"));
      isError = true;
    }

    // Vérification des availabilities
    if (!availabilities || availabilities.length === 0) {
      setAvailabilitiesError(t("Global.required"));
      isError = true;
    }

    // Si une erreur est détectée, on retourne null
    if (isError) return null;

    // Retourner les données validées
    return {
      email,
      phone,
      availabilities,
    };
  };

  useImperativeHandle(ref, () => ({
    handleClear,
    handleCheckData,
  }));

  useEffect(() => {
    if (website) {
      setEmail(website?.content.contact.email);
      setEmailError(undefined);

      setPhone(website?.content.contact.phone);
      setPhoneError(undefined);

      setAvailabilities(website?.content.contact.availability);
      setAvailabilitiesError(undefined);
    }
  }, [website]);

  const handleChangeEmail = (text: string) => {
    setEmail(text);
    setEmailError(undefined);
  };

  const handleChangePhone = (text: string) => {
    setPhone(text);
    setPhoneError(undefined);
  };

  const handleChangeAvailabilities = (text: string) => {
    setAvailabilities(text);
    setAvailabilitiesError(undefined);
  };

  return (
    <div className="flex flex-col gap-3">
      <TextInput
        label={t("Website.Contact.emailLabel")}
        value={email}
        onChangeText={handleChangeEmail}
        error={emailError}
        required={true}
        disabled={loading}
      />

      <PhoneInput
        label={t("Website.Contact.phoneLabel")}
        value={phone}
        onChangeText={handleChangePhone}
        error={phoneError}
        required={true}
        disabled={loading}
      />

      <TextInput
        label={t("Website.Contact.availabilitiesLabel")}
        value={availabilities}
        onChangeText={handleChangeAvailabilities}
        error={availabilitiesError}
        required={true}
        disabled={loading}
      />
    </div>
  );
});
