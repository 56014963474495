import React, {useEffect, useRef, useState} from "react";
import {RightModal} from "../../../Common/RightModal/RightModal";
import {ErrorMessage} from "../../../Common/ErrorMessage/ErrorMessage";
import {useTranslation} from "react-i18next";
import {Separator} from "../../../Common/Separator/Separator";
import {Button} from "../../../Common/Button/Button";
import {Controller, useForm} from "react-hook-form";
import {TextInput} from "../../../Common/TextInput/TextInput";
import {UseModal} from "../../../../hooks/useModal";
import {cn} from "../../../../helpers/classHelper";
import ArrowSendWhiteIcon from "../../../../assets/icons/send-white.svg?react";
import CheckWhiteIcon from "../../../../assets/icons/check-white.svg?react";
import {CustomEditor} from "../../../Common/CustomEditor/CustomEditor";
import {LexicalEditor} from "lexical";
import {ContractForm, DataContractModal, modeType} from "./ContractModal.type";
import paths from "../../../../constants/paths";
import {patch, post} from "../../../../helpers/APIHelper";
import {ResourceResponse} from "../../../Common/CardDocs/CardDocs.type";
import {ContractResponse} from "../../../../types/GETTypes";
import {getContractStatusBadge} from "../../../../helpers/contractHelper";

type AddContractModalProps = {
  isVisible: boolean;
  onClose: () => void;
  reservationId: string | undefined;
  defaultContract: ContractResponse | undefined;
  onSuccess: () => Promise<void>;
};

const AddContractModal = ({
  isVisible,
  onClose,
  reservationId,
  defaultContract,
  onSuccess
}: AddContractModalProps) => {

  const {t} = useTranslation();
  const [error, setError] = useState<string | undefined>();
  const [loadingValidation, setLoadingValidation] = useState<boolean>(false);
  const editorRef = useRef<LexicalEditor>();
  const form = useForm<ContractForm>({
    defaultValues: {
      name: "",
      content: "",
    },
  });

  const handleCreateContract = async (data: ContractForm) => {
    console.log("🚀 ~ handleUpdateContract ~ data:", data);
    setLoadingValidation(true);
    const response = await post(
      `${
        import.meta.env.VITE_API_URL
      }${paths.API.CONTRACTS_DOCS.CREATE_CONTRACT_BY_RESERVATION(
        Number(reservationId)
      )}`,
      data
    );
    if (response?.data?.success) {
      onClose();
      onSuccess()
    }
    setLoadingValidation(false);
  };

  useEffect(() => {
    if (defaultContract) {
      const newName = `${t(
        "Rental.Contracts.Modal.AddContractModal.amendment"
      )} ${defaultContract?.name}`;
      form.setValue("name", newName ?? "");
      form.setValue("content", defaultContract?.content ?? "");
    }
  }, [defaultContract]);

  return (
    <RightModal
      title={t("Rental.Contracts.Modal.AddContractModal.title")}
      isVisible={isVisible}
      onClose={onClose}
      classNames={{divParent: "z-[90]",mainContentParent:"mb-4"}}
    >
      <>
        <div className={cn(`flex flex-col gap-3`, "w-full")}>
          <ErrorMessage>{error}</ErrorMessage>
          <>
            <div>
              <Controller
                control={form.control}
                name="name"
                rules={{
                  required: {
                    value: true,
                    message: t("Rental.Contracts.Contracts.Modal.formRequired"),
                  },
                }}
                render={({field: {value, onChange}}) => (
                  <TextInput
                    label={t("Rental.Checklist.Upsert.name")}
                    required={true}
                    placeholder={t(
                      "Rental.Contracts.Contracts.Modal.formNamePlaceholder"
                    )}
                    disabled={loadingValidation}
                    value={value}
                    error={form.formState.errors.name?.message}
                    onChangeText={onChange}
                  />
                )}
              />
            </div>
            <div className="gap-2">
              <div>
                <CustomEditor
                  ref={editorRef}
                  form={form}
                  formControllerName="content"
                  error={form.formState.errors.content?.message}
                  disabled={loadingValidation}
                  label={t("Rental.Contracts.Contracts.Modal.formContent")}
                  placeholder={t(
                    "Rental.Contracts.Contracts.Modal.formContentPlaceholder"
                  )}
                  rows={27}
                  required
                  requiredMessage={t(
                    "Rental.Contracts.Contracts.Modal.formRequired"
                  )}
                  classNames={{
                    placeholder: error
                      ? "top-[30.9%]"
                      : form.formState.errors.content?.message
                      ? "top-[23.7%]"
                      : "top-[21%]",
                  }}
                  showToolbar
                />
              </div>
            </div>
          </>
          <div className="flex-1"></div>
          <Separator />

          <div className="flex">
            <div className="flex flex-1 gap-x-3 justify-between">
              <Button
                type="secondary"
                onClick={onClose}
                disabled={loadingValidation}
              >
                {t("Global.cancel")}
              </Button>
              <Button
                type="primary"
                loading={loadingValidation}
                onClick={form.handleSubmit(handleCreateContract)}
                RightIcon={CheckWhiteIcon}
              >
                {t("Global.save")}
              </Button>
            </div>
          </div>
        </div>
      </>
    </RightModal>
  );
};

export default AddContractModal;
