import { t } from "i18next";
import React from "react";
import CheckListIcon from "../../assets/icons/check-list.svg?react";
import CleanIcon from "../../assets/icons/clean-green.svg?react";
import CopyIcon from "../../assets/icons/copy.svg?react";
import DotsVerticalIcon from "../../assets/icons/dots-vertical.svg?react";
import EditIcon from "../../assets/icons/edit.svg?react";
import LogInIcon from "../../assets/icons/log-in-warning.svg?react";
import LogOutIcon from "../../assets/icons/log-out-blue.svg?react";
import TrashActiveIcon from "../../assets/icons/trash-active.svg?react";
import WarningTriangle from "../../assets/icons/warning-triangle-red.svg?react";
import { IconType, ValueType } from "../../types/commonTypes";
import { ChecklistType } from "../../types/GETTypes";
import { Badge } from "../Common/Badge/Badge";
import { BadgeStatus } from "../Common/Badge/Badge.type";
import { Button } from "../Common/Button/Button";
import { TableListWithCustomItemsItemProps } from "../Common/TableListWithCustomItems/TableListWithCustomItems.type";
import { getCurrentCanEdit } from "../../helpers/workspaceHelper";
import useUserStore from "../../stores/useUserStore";

export const ChecklistTableItem: React.FC<
  TableListWithCustomItemsItemProps
> = ({ data, onClick, onClickAction }) => {
  const checklistType: ChecklistType = data.type;
  const userStore = useUserStore();

  const getBadgeProps = () => {
    let value: {
      status: BadgeStatus | undefined;
      icon: IconType | null | undefined;
      label: string | undefined;
    } = {
      status: undefined,
      icon: CheckListIcon,
      label: undefined,
    };
    if (checklistType === "CLEANING") {
      value = {
        status: "success",
        icon: CleanIcon,
        label: t("Rental.Checklist.Types.cleaning"),
      };
    } else if (checklistType === "CHECKIN") {
      value = {
        status: "pending",
        icon: LogInIcon,
        label: t("Rental.Checklist.Types.checkin"),
      };
    } else if (checklistType === "INCIDENT") {
      value = {
        status: "fail",
        icon: WarningTriangle,
        label: t("Rental.Checklist.Types.incident"),
      };
    }

    return value;
  };

  const handleClickAction = (value: ValueType) => {
    if (!onClickAction) return;

    onClickAction(data.id, value);
  };

  const handleClick = (id: ValueType) => {
    if (
      !getCurrentCanEdit({
        user: userStore.user,
        workspaceOwner: data.workspace_owner,
      })
    )
      return;

    onClick(id);
  };

  return (
    <tr className="cursor-pointer hover:bg-element-background/40">
      <td
        className="flex flex-col items-start py-4 space-y-1 text-sm border-t-1 border-element-border ps-4 pe-2"
        onClick={() => handleClick(data.id)}
      >
        <p className="text-base font-bold text-high-contrast">{data.name}</p>
        <p className="text-low-contrast">{data.description}</p>
      </td>

      <td
        className="px-2 py-4 text-sm border-t-1 border-element-border"
        onClick={() => handleClick(data.id)}
      >
        {checklistType === "OTHER" ? (
          <div className="flex flex-row items-center px-2 space-x-1 bg-element-background text-high-contrast h-9 w-fit rounded-6px">
            <CheckListIcon />
            <p className="text-sm font-semibold text-center">
              {t("Rental.Checklist.Types.other")}
            </p>
          </div>
        ) : null}

        {checklistType === "CHECKOUT" ? (
          <div
            className="
                bg-[#EBF2F9]
                text-[#29517C]
                h-9
                px-2
                w-fit rounded-6px flex flex-row space-x-1 items-center"
          >
            <LogOutIcon />
            <p className="text-sm font-semibold text-center">
              {t("Rental.Checklist.Types.checkout")}
            </p>
          </div>
        ) : null}

        {checklistType !== "CHECKOUT" && checklistType !== "OTHER" ? (
          <Badge
            label={getBadgeProps().label}
            status={getBadgeProps().status}
            Icon={getBadgeProps().icon}
          />
        ) : null}
      </td>

      <td
        className="px-2 py-4 text-sm border-t-1 border-element-border"
        onClick={() => handleClick(data.id)}
      >
        <p className="font-bold text-high-contrast">{data.number_of_steps}</p>
      </td>

      <td className="py-4 text-sm pe-4 ps-2 border-t-1 border-element-border">
        <div className="flex justify-end w-full">
          <div>
            <Button
              type="secondary"
              size="small"
              RightIcon={DotsVerticalIcon}
              disabled={
                !getCurrentCanEdit({
                  user: userStore.user,
                  workspaceOwner: data.workspace_owner,
                })
              }
              dropItems={[
                {
                  Icon: EditIcon,
                  value: "edit",
                  label: t("Global.edit"),
                },
                {
                  Icon: CopyIcon,
                  value: "duplicate",
                  label: t("Global.copy"),
                },
                {
                  Icon: TrashActiveIcon,
                  value: "delete",
                  label: t("Global.remove"),
                  isRed: true,
                },
              ]}
              onClick={handleClickAction}
            ></Button>
          </div>
        </div>
      </td>
    </tr>
  );
};
