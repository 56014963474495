import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../Common/Button/Button";

/**
 * Component for showing the access denied iframe
 * in the rental list.
 */
export default function IframeRentalAccessDenied() {
  const { t } = useTranslation();

  const accessDenied = t("iframe.denied");
  const accessDeniedText = t("iframe.deniedText");
  const seeDocumentation = t("iframe.documentationSee");

  const urlDocumentation =
    "https://help.superhote.com/support/solutions/articles/150000053075-int%C3%A9grer-le-moteur-de-r%C3%A9servations-superhote-sur-n-importe-quel-site-internet";

  return (
    <section className="h-screen w-full flex flex-col justify-center items-center">
      <div className="text-center p-8 bg-white shadow-lg rounded-lg">
        <h1 className="text-4xl font-bold text-red-500 mb-4">{accessDenied}</h1>
        <p className="text-gray-600 text-lg mb-4">{accessDeniedText}</p>
        <Button
          type={"primary"}
          onClick={() => {
            window.open(urlDocumentation, "_blank");
          }}
        >
          {seeDocumentation}
        </Button>
      </div>
    </section>
  );
}
