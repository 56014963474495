/**
 * Hook for interact with RentalContact module
 *
 * Folder Module :
 * https://superhote-team.postman.co/workspace/My-Workspace~470a5a9f-2247-4d3b-8f3a-857dcd566145/folder/8807109-ea919c3b-765a-4edd-bc03-19eb522b813c?action=share&source=copy-link&creator=39748180&ctx=documentation
 */

import { del, get, post } from "../../helpers/APIHelper";
import paths from "../../constants/paths";
import { ContactType, RentalContact } from "../../types/GETTypes";

export const useRentalContact = (rentalId: string | number) => {
  const apiUrl = import.meta.env.VITE_API_URL;

  const getContacts = async (): Promise<RentalContact[]> => {
    const response = await get(apiUrl + paths.API.RENTAL_CONTACT.ALL(rentalId));

    if (response.status === 200 && response?.data?.result) {
      return response.data.result;
    } else {
      throw new Error(response?.response?.data?.message ?? response.message);
    }
  };

  const createContact = async (
    contactType: ContactType,
    first_name: string | null,
    last_name: string | null,
    email: string | null,
    phone: string | null,
  ): Promise<RentalContact[]> => {
    const data = {
      contact_type: contactType,
      first_name: first_name,
      last_name: last_name,
      email: email,
      phone: phone,
    };

    const response = await post(
      apiUrl + paths.API.RENTAL_CONTACT.CREATE(rentalId),
      data,
    );

    if (response.status === 200 && response?.data?.result) {
      return response.data.result;
    } else {
      throw new Error(response?.response?.data?.message ?? response.message);
    }
  };

  const updateContact = async (
    contactType: ContactType,
    first_name: string | null,
    last_name: string | null,
    email: string | null,
    phone: string | null,
  ): Promise<RentalContact[]> => {
    const data = {
      contact_type: contactType,
      first_name: first_name,
      last_name: last_name,
      email: email,
      phone: phone,
    };

    const response = await post(
      apiUrl + paths.API.RENTAL_CONTACT.UPDATE(rentalId),
      data,
    );

    if (response.status === 200 && response?.data?.result) {
      return response.data.result;
    } else {
      throw new Error(response?.response?.data?.message ?? response.message);
    }
  };

  const deleteContact = async (
    rentalContactId: string | number,
  ): Promise<RentalContact[]> => {
    const response = await del(
      apiUrl + paths.API.RENTAL_CONTACT.DELETE(rentalId, rentalContactId),
    );

    if (response.status === 200 && response?.data?.result) {
      return response.data.result;
    } else {
      throw new Error(response?.response?.data?.message ?? response.message);
    }
  };

  const deleteContactTypeIfExist = async (
    contactType: ContactType,
  ): Promise<RentalContact[]> => {
    const response = await del(
      apiUrl + paths.API.RENTAL_CONTACT.DELETE_CONTACT_TYPE_IF_EXIST(rentalId),
      {
        data: { contact_type: contactType },
      },
    );

    if (response.status === 200 && response?.data?.result) {
      return response.data.result;
    } else {
      throw new Error(response?.response?.data?.message ?? response.message);
    }
  };

  return {
    getContacts,
    createContact,
    updateContact,
    deleteContact,
    deleteContactTypeIfExist,
  };
};
