import React, {useEffect, useState} from "react";
import {DashboardServicesPageProps} from "./DashboardServicesPage.type";
import {useTranslation} from "react-i18next";
import {Title} from "../../components/Common/Title/Title";
import {RightModal} from "../../components/Common/RightModal/RightModal";
import paths from "../../constants/paths";
import {MainLayout} from "../../components/Layout/MainLayout/MainLayout";
import {useCheckAuth} from "../../hooks/useCheckAuth";
import DashboardServicesStats from "../../components/DashboardServices/DashboardServicesStats";
import DashboardServicesTasks from "../../components/DashboardServices/DashboardServicesTasks";
import {DashboardData, TaskItemResponse} from "../../types/GETTypes";
import {get} from "../../helpers/APIHelper";
import {DeclineTaskModal} from "../../components/Management/DeclineTaskModal/DeclineTaskModal";
import {AcceptTaskModal} from "../../components/Management/AcceptTaskModal/AcceptTaskModal";
import AddTaskModal from "../../components/Management/AddTask/AddTaskModal";
import {useModal} from "../../hooks/useModal";
import {EditTaskDone} from "../../components/Management/EditTaskDone/EditTaskDone";
import {EditTaskStarted} from "../../components/Management/EditTaskStarted/EditTaskStarted";
import {CancelTask} from "../../components/Management/CancelTask/CancelTask";
import {EditTaskCanceled} from "../../components/Management/EditTaskCanceled/EditTaskCanceled";
import {EditTaskScheduled} from "../../components/Management/EditTaskScheduled/EditTaskScheduled";
import {EditTaskEditComplete} from "../../components/Management/EditTaskEditComplete/EditTaskEditComplete";

export const DashboardServicesPage: React.FC<
  DashboardServicesPageProps
> = () => {
  const {t} = useTranslation();
  const {user} = useCheckAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState<DashboardData>();
  const [refresh, setRefresh] = useState(false);
  const acceptTaskModal = useModal<TaskItemResponse>();
  const declineTaskModal = useModal<TaskItemResponse>();
  const createTaskModal = useModal<{date?: string}>();
  const editTaskScheduled = useModal<TaskItemResponse>();
  const editTaskStarted = useModal<TaskItemResponse>();
  const cancelTaskModal = useModal<TaskItemResponse>();
  const editTaskCanceled = useModal<TaskItemResponse>();
  const editTaskDone = useModal<TaskItemResponse>();
  const editTaskEditComplete = useModal<TaskItemResponse>();

  const fetchDashboardData = async () => {
    setIsLoading(true);
    const response = await get(
      `${import.meta.env.VITE_API_URL}${paths.API.DASHBOARD}`
    );

    if (response.data.success) {
      setDashboardData(response.data.result);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };

  const updateAssignation = () => {
    if (acceptTaskModal.data || declineTaskModal.data) {
      fetchDashboardData();
    }
  };

  const openEditTaskModal = (task: TaskItemResponse) => {
    if (task.status === "ASSIGNED") editTaskScheduled.open(task);
    else if (task.status === "STARTED") editTaskStarted.open(task);
    else if (task.status === "COMPLETED") editTaskDone.open(task);
    else if (task.status === "CANCELED") editTaskCanceled.open(task);
  };

  const onSuccess = () => {
    fetchDashboardData();
  };

  const handleStart = (task: TaskItemResponse) => {
    editTaskScheduled.close();
    editTaskStarted.open(task);

    fetchDashboardData();
  };

  const handleCancel = (task: TaskItemResponse) => {
    cancelTaskModal.open(task);
  };

  useEffect(() => {
    fetchDashboardData();
  }, [refresh]);

  useEffect(() => {}, [dashboardData]);

  return (
    <>
      <AcceptTaskModal
        task={acceptTaskModal.data}
        isVisible={acceptTaskModal.isVisible}
        onClose={acceptTaskModal.close}
        onSuccess={updateAssignation}
      />

      <DeclineTaskModal
        task={declineTaskModal.data}
        isVisible={declineTaskModal.isVisible}
        onClose={declineTaskModal.close}
        onSuccess={updateAssignation}
      />
      <RightModal
        title={t("Task.Modal.titleDashboard")}
        classNames={{
          mainContentParent: "overflow-y-hidden pe-0",
        }}
        isVisible={createTaskModal.isVisible}
        onClose={createTaskModal.close}
      >
        <AddTaskModal
          onClose={createTaskModal.close}
          onSuccess={() => fetchDashboardData()}
          fromDashboard={true}
          date={createTaskModal.data?.date}
        />
      </RightModal>
      <EditTaskScheduled
        task={editTaskScheduled.data!}
        isVisible={editTaskScheduled.isVisible}
        onClose={editTaskScheduled.close}
        onSuccess={onSuccess}
        onStart={handleStart}
        onCancel={handleCancel}
      />

      <EditTaskCanceled
        task={editTaskCanceled.data!}
        isVisible={editTaskCanceled.isVisible}
        onClose={editTaskCanceled.close}
      />

      <CancelTask
        task={cancelTaskModal.data}
        isVisible={cancelTaskModal.isVisible}
        onClose={cancelTaskModal.close}
        onSuccess={() => {
          onSuccess();
          editTaskScheduled.close();
        }}
      />

      <EditTaskStarted
        task={editTaskStarted.data}
        isVisible={editTaskStarted.isVisible}
        onClose={editTaskStarted.close}
        onSuccess={onSuccess}
      />

      <EditTaskDone
        task={editTaskDone.data}
        isVisible={editTaskDone.isVisible}
        onClose={editTaskDone.close}
        onComplete={(task) => {
          editTaskDone.close();
          editTaskEditComplete.open(task!);
        }}
      />

      <EditTaskEditComplete
        task={editTaskEditComplete.data}
        isVisible={editTaskEditComplete.isVisible}
        onClose={(task) => {
          editTaskEditComplete.close();
          editTaskDone.open(task!);
        }}
        onSuccess={(task) => {
          onSuccess();
          editTaskEditComplete.close();
          editTaskDone.open(task!);
        }}
      />
      <MainLayout
        title={t("Dashboard.title")}
        sidebarActiveItem="dashboard"
        sidebarActiveSubItem="dashboard_services"
      >
        <div className="flex flex-col">
          <div className="flex flex-1 flex-row mt-8 justify-between">
            <div className="flex flex-col">
              <Title>
                {t("Dashboard.welcome")} {user?.first_name}
              </Title>
              <p className="text-low-contrast text-sm">
                {t("Dashboard.summary")}
              </p>
            </div>
          </div>
          <div className="mt-8">
            <DashboardServicesStats
              dashboardData={dashboardData}
              isLoading={isLoading}
              user={user}
            />
          </div>
          <div className="flex justify-between mt-8">
            <DashboardServicesTasks
              isLoading={isLoading}
              dashboardData={dashboardData}
              onAcceptTask={acceptTaskModal.open}
              onDeclineTask={declineTaskModal.open}
              openCreateTaskModal={createTaskModal.open}
              user={user}
              openEditTaskModal={openEditTaskModal}
            />
          </div>
        </div>
      </MainLayout>
    </>
  );
};
