import { t, TFunction } from "i18next";
import moment from "moment";
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  Controller,
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFieldArrayUpdate,
  UseFormGetValues,
  UseFormReturn,
} from "react-hook-form";
import TrashIcon from "../../../../assets/icons/trash.svg?react";
import { PaymentAccountTypeEnum } from "../../../../enums/GETenums";
import { RequiredFields } from "../../../../types/commonTypes";
import { CalendarInput } from "../../../Common/CalendarInput/CalendarInput";
import { CalendarInputValue } from "../../../Common/CalendarInput/CalendarInput.type";
import { InputSelect } from "../../../Common/InputSelect/InputSelect";
import { InputSelectOptionProps } from "../../../Common/InputSelect/InputSelect.type";
import { NumberInput } from "../../../Common/NumberInput/NumberInput";
import {
  PaymentAccount,
  PaymentPolicyDepositPaymentOptionEnum,
  PaymentPolicyDepositValueTypeEnum,
  PaymentPolicyForm,
  PaymentPolicyItemForm,
  PaymentPolicyPayload,
  PaymentPolicyPaymentTriggerEnum,
  PaymentPolicyResponse,
  PaymentPolicyValueTypeEnum,
} from "../../../Payment/Payment.type";
import { PaymentScheduleAndConditionsForm } from "../../../Payment/ScheduleAndConditions/ScheduleAndConditions.type";

// * -- MODAL LOGIC HOOKS --
/**
 * This is a method to get total for payment policies
 * @param paymentPolicies
 * @returns
 */
export const useGetTotalValue = (
  paymentPolicies: PaymentPolicyItemForm[],
  exceptIndex?: number
): number => {
  let valueItems: number[] = paymentPolicies.map((pp) => pp.value ?? 0);

  if (exceptIndex !== undefined) {
    valueItems = valueItems.filter((pp, index) => exceptIndex !== index);
  }

  const sum = valueItems.reduce((acc, value) => Number(acc) + Number(value), 0);
  if (isNaN(sum)) {
    return 0;
  }

  return sum;
};

/**
 * This is a method for checking whether an erroneous value has been included in payment policy values.
 * @param paymentPolicies
 * @returns
 */
const useHasIncludeWrongValue = (
  paymentPolicies: PaymentPolicyItemForm[]
): boolean => paymentPolicies.map((pp) => pp.value).includes(0) ?? false;

/**
 * This is a method to get max value for payment policy value
 * @param useMode
 * @param valueType
 * @param totalPrice
 * @param paymentPolicies
 * @param exceptIndex
 * @returns
 */
export const useGetPaymentPolicyMaxValueRestriction = (
  useMode: "rental" | "reservation",
  valueType: PaymentPolicyValueTypeEnum,
  totalPrice: number | undefined,
  paymentPolicies: PaymentPolicyItemForm[],
  exceptIndex: number
): number => {
  if (
    (useMode === "rental" || useMode === "reservation") &&
    valueType === PaymentPolicyValueTypeEnum.PERCENT
  ) {
    return 100 - useGetTotalValue(paymentPolicies, exceptIndex);
  } else if (
    useMode === "reservation" &&
    valueType === PaymentPolicyValueTypeEnum.FIXED &&
    totalPrice
  ) {
    return totalPrice - useGetTotalValue(paymentPolicies, exceptIndex);
  }

  return 2;
};
/**
 * * This is a method to convert values to payment policy payload value
 * @param values
 * @param paymentFields
 * @returns
 */
export const useConvertValuesToPaymentPolicyPayload = (
  values: UseFormGetValues<PaymentPolicyForm>,
  paymentFields: FieldArrayWithId<
    PaymentPolicyForm,
    "payments_policy_items",
    "id"
  >[]
): PaymentPolicyPayload => {
  const paymentTriggerItems: string = values("payments_policy_items")
    .map((p) => p.trigger)
    .join(",");
  const paymentValueTypeItems: string = values("payments_policy_items")
    .map((p) => p.valueType)
    .join(",");
  const paymentValueItems: string = values("payments_policy_items")
    .map((p) => p.value)
    .join(",");
  const paymentAccountIdItems: string = values("payments_policy_items")
    .map((p) => p.paymentAccountId)
    .join(",");

  return {
    name: values("name"),
    description: values("description"),
    is_favorite_policy: values("is_favorite_policy") ? 1 : 0,
    is_single_payment: values("is_single_payment") ? 1 : 0,
    payment_trigger_items: paymentTriggerItems,
    payment_value_type_items: paymentValueTypeItems,
    payment_value_items: paymentValueItems,
    payment_specific_date: paymentFields.map(() => "null").join(","),
    payment_account_id_items: paymentAccountIdItems,
    is_refundable: !values("refund_value")
      ? 0
      : values("is_refundable")
      ? 1
      : 0,
    refund_value: values("is_refundable") ? values("refund_value") : null,
    refund_condition:
      values("is_refundable") && values("refund_value")
        ? values("refund_condition")
        : null,
    is_deposit_required: !values("deposit_value")
      ? 0
      : values("is_deposit_required")
      ? 1
      : 0,
    deposit_value:
      values("is_deposit_required") && values("deposit_value")
        ? Number(values("deposit_value"))
        : null,
    deposit_payment_option:
      values("is_deposit_required") && values("deposit_value")
        ? values("deposit_payment_option")
        : null,
    deposit_payment_account_id:
      values("is_deposit_required") && values("deposit_value")
        ? values("deposit_payment_account_id")
        : null,
    deposit_payment_days_delay:
      values("is_deposit_required") && values("deposit_value")
        ? values("deposit_payment_days_delay")
        : null,
  };
};

/**
 * * Payment policy modal states initialization
 * @returns
 */
export const usePaymentPolicyModalInitStates = () => {
  const [paymentAccounts, setPaymentAccounts] = useState<PaymentAccount[]>([]);
  const [paymentAccountsError, setPaymentAccountsError] = useState<
    string | undefined
  >();
  const [apiError, setApiError] = useState<string | undefined>();
  const [paymentError, setPaymentError] = useState<string | undefined>();
  const [validationLoading, setValidationLoading] = useState<boolean>(false);
  const [
    validationLoadingDeletePaymentPolicy,
    setValidationLoadingDeletePaymentPolicy,
  ] = useState<boolean>(false);
  const [
    isVisiblePaymentPolicyDeleteModal,
    setIsVisiblePaymentPolicyDeleteModal,
  ] = useState<boolean>(false);

  return {
    paymentAccountsState: [paymentAccounts, setPaymentAccounts],
    paymentAccountsErrorState: [paymentAccountsError, setPaymentAccountsError],
    apiErrorState: [apiError, setApiError],
    paymentErrorState: [paymentError, setPaymentError],
    validationLoadingState: [validationLoading, setValidationLoading],
    validationLoadingDeletePaymentPolicyState: [
      validationLoadingDeletePaymentPolicy,
      setValidationLoadingDeletePaymentPolicy,
    ],
    isVisiblePaymentPolicyDeleteModalState: [
      isVisiblePaymentPolicyDeleteModal,
      setIsVisiblePaymentPolicyDeleteModal,
    ],
  } as const;
};

export const usePaymentPolicyModalInit = (
  formData: PaymentPolicyResponse | undefined,
  t: TFunction<"translation", undefined>,
  form: UseFormReturn<PaymentPolicyForm, any, undefined>
) => {
  useEffect(() => {
    if (formData) {
      form.setValue("name", formData.name);
      form.setValue(
        "description",
        Boolean(formData?.is_default_policy)
          ? t(
              "Rental.Pricing.PaymentPolicy.CreateOrUpdateModal.descriptionValueDefaultPolicy"
            )
          : formData.description
      );
    }
  }, [formData]);
};

/**
 * This hook is used to retrieve the payment account option items
 * @param paymentAccounts
 * @param onlyStripe
 * @returns
 */
export const usePaymentAccountsOptionItems = (
  paymentAccounts: PaymentAccount[],
  onlyStripe: boolean = false
): InputSelectOptionProps[] => {
  if (Array.isArray(paymentAccounts) && paymentAccounts.length > 0) {
    let data: PaymentAccount[] = paymentAccounts;

    if (onlyStripe) {
      data = paymentAccounts?.filter(
        (p) => p.type.toLowerCase() === PaymentAccountTypeEnum.STRIPE
      );
    }

    return data.map((v: PaymentAccount): InputSelectOptionProps => {
      let label: string = v.name;

      if (v.name === PaymentAccountTypeEnum.CASH) {
        label = t("Global.PaymentAccounts.cash");
      } else if (v.name === PaymentAccountTypeEnum.BANK_CHECK) {
        label = t("Global.PaymentAccounts.check");
      } else if (v.name === PaymentAccountTypeEnum.BANK_TRANSFER) {
        label = t("Global.PaymentAccounts.transfer");
      }

      return {
        label: label,
        value: v.id,
      };
    });
  }

  return [];
};

/**
 * This hook is used to retrieve the value type option items
 * @param useMode
 * @returns
 */
export const useValueTypeOptionItems = (
  useMode: "rental" | "reservation"
): InputSelectOptionProps[] => {
  let items: InputSelectOptionProps[] = [
    {
      label: t("Global.percentage"),
      value: PaymentPolicyDepositValueTypeEnum.PERCENT,
    },
  ];
  if (useMode === "reservation") {
    items = [
      ...items,
      {
        label: t("Global.fixed"),
        value: PaymentPolicyDepositValueTypeEnum.FIXED,
      },
    ];
  }

  return items;
};

/**
 * This hook is used to display payment policy item
 * @param index
 * @param useMode
 * @param form
 * @param requiredFields
 * @param nonModifiable
 * @param paymentPolicyUpdate
 * @param paymentPolicyRemove
 * @param paymentAccounts
 * @param totalPrice
 * @param setTotalValue
 * @param valueTypeState
 * @returns
 */
export const usePaymentPolicyItem = (
  index: number,
  useMode: "rental" | "reservation",
  form: UseFormReturn<PaymentPolicyForm>,
  requiredFields: RequiredFields<PaymentScheduleAndConditionsForm>,
  nonModifiable: boolean,
  paymentPolicyUpdate: UseFieldArrayUpdate<
    PaymentPolicyForm,
    "payments_policy_items"
  >,
  paymentPolicyRemove: UseFieldArrayRemove,
  paymentAccounts: PaymentAccount[],
  totalPrice: number | undefined,
  amountPaymentItemsState: [
    ([number, ...number[]] & { length: 1 | 2 | 3 }) | undefined,
    Dispatch<
      SetStateAction<
        ([number, ...number[]] & { length: 1 | 2 | 3 }) | undefined
      >
    >
  ],
  setTotalValue: React.Dispatch<React.SetStateAction<number>>,
  valueTypeState: [
    PaymentPolicyValueTypeEnum,
    Dispatch<SetStateAction<PaymentPolicyValueTypeEnum>>
  ]
): ReactNode => {
  const { control, register, setValue, getValues } = form;
  const [valueType, setValueType] = valueTypeState;
  const [amountPaymentItems, setAmountPaymentItems] = amountPaymentItemsState;

  return (
    <div className="flex flex-col w-full max-w-full p-2 space-y-2 rounded-md bg-subtle border-1 border-element-border">
      <div className="flex justify-between">
        <div>
          <p className="font-bold text-high-contrast">{`${t("Global.payment", {
            count: 1,
          })} ${index + 1}`}</p>
          {/* TODO: To display when it's in add reservation page */}
          {/* {useMode === "reservation" &&
          amountPaymentItems &&
          amountPaymentItems.length > 0 ? (
            <p className="text-low-contrast">
              Soit {amountPaymentItems[index]}
            </p>
          ) : null} */}
        </div>

        {index !== 0 ? (
          <TrashIcon
            className="cursor-pointer"
            onClick={() => {
              paymentPolicyRemove(index);
              setTotalValue(
                useGetTotalValue(getValues("payments_policy_items"))
              );
            }}
          />
        ) : null}
      </div>

      <div className="flex items-center space-x-2">
        <div className="flex-1 min-w-0">
          <Controller
            control={control}
            name={`payments_policy_items.${index}.valueType`}
            rules={{
              required: {
                value: requiredFields.payments_policy_items,
                message: t("Global.Errors.requiredField", {
                  fieldName: t("Payments.amountTypeLabel"),
                }),
              },
              onChange: (e) =>
                useOnChangeValueTypePaymentPolicyItem(
                  e,
                  getValues,
                  paymentPolicyUpdate,
                  totalPrice,
                  setValueType,
                  setTotalValue
                ),
            }}
            render={({ field: { value, onChange } }) => (
              <InputSelect
                required={requiredFields.payments_policy_items}
                disabled={useMode === "rental" && nonModifiable}
                label={t("Payments.amountTypeLabel")}
                items={useValueTypeOptionItems(useMode)}
                selectedValue={value}
                onSelect={onChange}
              />
            )}
          />
        </div>

        <div className="flex-1 min-w-0">
          <Controller
            control={control}
            name={`payments_policy_items.${index}.value`}
            rules={{
              required: {
                value: requiredFields.payments_policy_items,
                message: t("Global.Errors.requiredField", {
                  fieldName: t("Payments.amountLabel"),
                }),
              },
              onChange: (e) => {
                setTotalValue(
                  useGetTotalValue(getValues("payments_policy_items"))
                );
              },
            }}
            render={({ field: { value, onChange } }) => (
              <NumberInput
                required={requiredFields.payments_policy_items}
                disabled={nonModifiable && useMode === "rental"}
                label={t("Payments.amountLabel")}
                acceptDecimal={true}
                currency={
                  getValues(`payments_policy_items.${index}.valueType`) ===
                  PaymentPolicyValueTypeEnum.FIXED
                    ? t("Global.currencySymbol")
                    : "%"
                }
                min={1}
                max={useGetPaymentPolicyMaxValueRestriction(
                  useMode,
                  valueType,
                  totalPrice,
                  getValues("payments_policy_items"),
                  index
                )}
                value={value}
                onChangeText={onChange}
              />
            )}
          />
        </div>
      </div>

      <div className="flex space-x-2">
        <div className="flex-1 min-w-0">
          <InputSelect
            required={requiredFields.payments_policy_items}
            register={register(`payments_policy_items.${index}.trigger`, {
              required: {
                value: requiredFields.payments_policy_items,
                message: t("Global.Errors.requiredField", {
                  fieldName: t("Payments.paymentTriggerLabel"),
                }),
              },
              onChange: (e) => {
                const newTrigger = e.target.value;
                if (
                  newTrigger !== PaymentPolicyPaymentTriggerEnum.SPECIFIC_DATE
                ) {
                  setValue(`payments_policy_items.${index}.specificDate`, null);
                }
              },
            })}
            disabled={nonModifiable && useMode === "rental"}
            label={t("Payments.paymentTriggerLabel")}
            items={useTriggerPaymentPolicyItems(useMode, index === 0)}
            selectedValue={getValues(`payments_policy_items.${index}.trigger`)}
          />
        </div>

        <div className="flex-1 min-w-0">
          {getValues(`payments_policy_items.${index}.trigger`) ===
          PaymentPolicyPaymentTriggerEnum.SPECIFIC_DATE ? (
            <CalendarInput
              required={requiredFields.payments_policy_items}
              register={register(
                `payments_policy_items.${index}.specificDate`,
                {
                  required: {
                    value: requiredFields.payments_policy_items,
                    message: t("Global.Errors.requiredField", {
                      fieldName: t("Payments.specificDate"),
                    }),
                  },
                }
              )}
              classNames={{
                button:
                  "h-9 bg-white py-1.5 pl-3 text-left text-gray-900 shadow-sm",
              }}
              disabled={nonModifiable && useMode === "rental"}
              dateType="default"
              label={t("Payments.specificDate")}
              onChangeDate={(value: CalendarInputValue) => {
                if (value) {
                  setValue(
                    `payments_policy_items.${index}.specificDate`,
                    value as Date
                  );
                }
              }}
              isExcludeDate={(date: Date) =>
                Boolean(moment(date).isBefore(moment(), "day"))
              }
              value={getValues(`payments_policy_items.${index}.specificDate`)}
            />
          ) : (
            <InputSelect
              required={requiredFields.payments_policy_items}
              register={register(
                `payments_policy_items.${index}.paymentAccountId`,
                {
                  required: {
                    value: requiredFields.payments_policy_items,
                    message: t("Global.Errors.requiredField", {
                      fieldName: t("Payments.meansOfPaymentLabel"),
                    }),
                  },
                }
              )}
              disabled={nonModifiable && useMode === "rental"}
              label={t("Payments.meansOfPaymentLabel")}
              items={usePaymentAccountsOptionItems(paymentAccounts)}
              selectedValue={
                getValues(`payments_policy_items.${index}.paymentAccountId`) ??
                ""
              }
            />
          )}
        </div>
      </div>

      <div className="flex space-x-2">
        <div className="flex-1">
          {getValues(`payments_policy_items.${index}.trigger`) ===
          PaymentPolicyPaymentTriggerEnum.SPECIFIC_DATE ? (
            <InputSelect
              required={requiredFields.payments_policy_items}
              register={register(
                `payments_policy_items.${index}.paymentAccountId`,
                {
                  required: {
                    value: requiredFields.payments_policy_items,
                    message: t("Global.Errors.requiredField", {
                      fieldName: t("Payments.meansOfPaymentLabel"),
                    }),
                  },
                }
              )}
              disabled={nonModifiable && useMode === "rental"}
              label={t("Payments.meansOfPaymentLabel")}
              items={usePaymentAccountsOptionItems(paymentAccounts)}
              selectedValue={
                getValues(`payments_policy_items.${index}.paymentAccountId`) ??
                ""
              }
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

/**
 * This hook is used to handle change the payment policy item
 * @param e
 * @param getValues
 * @param paymentPolicyUpdate
 * @param totalPrice
 * @param setValueType
 * @param setTotalValue
 */
export const useOnChangeValueTypePaymentPolicyItem = (
  e: any,
  getValues: UseFormGetValues<PaymentPolicyForm>,
  paymentPolicyUpdate: UseFieldArrayUpdate<
    PaymentPolicyForm,
    "payments_policy_items"
  >,
  totalPrice: number | undefined,
  setValueType: Dispatch<React.SetStateAction<PaymentPolicyValueTypeEnum>>,
  setTotalValue: Dispatch<React.SetStateAction<number>>
) => {
  if (getValues("payments_policy_items").length > 0) {
    getValues("payments_policy_items").forEach((pp, index) => {
      if (e.target.value === PaymentPolicyValueTypeEnum.PERCENT) {
        const baseValue =
          Math.floor((100 / getValues("payments_policy_items").length) * 100) /
          100;
        const remainder =
          100 - baseValue * getValues("payments_policy_items").length;

        let value = baseValue;
        if (getValues("payments_policy_items").length - 1 === index) {
          value += remainder;
        }

        paymentPolicyUpdate(index, {
          ...pp,
          valueType: e.target.value,
          value: Number(value.toFixed(2)),
        });
      } else if (e.target.value === PaymentPolicyValueTypeEnum.FIXED) {
        let value: number = 0;

        if (totalPrice) {
          value = Number(
            (totalPrice / getValues("payments_policy_items").length).toFixed(2)
          );
        }

        paymentPolicyUpdate(index, {
          ...pp,
          valueType: e.target.value,
          value: value,
        });
      }
    });
  }
  setValueType(e.target.value);
  setTotalValue(useGetTotalValue(getValues("payments_policy_items")));
};

export const useHasStripeAccount = (paymentAccounts: PaymentAccount[]) =>
  Boolean(paymentAccounts.filter((p) => p.id > 0).length ?? false);

/**
 * This hook is used to validate the payment policies
 * @param useMode
 * @param form
 * @param getValues
 * @param totalPrice
 * @param onError
 */
export const usePaymentPolicyValidation = (
  useMode: "rental" | "reservation",
  form: UseFormReturn<PaymentPolicyForm>,
  totalValue: number,
  valueType: PaymentPolicyValueTypeEnum,
  totalPrice: number | undefined,
  t: TFunction<"translation", undefined>,
  onError: (error: string | undefined) => void
) => {
  const {
    getValues,
    setValue,
    formState: { errors },
    setError,
    clearErrors,
  } = form;

  // Set or clear error when total value is updated
  useEffect(() => {
    let message: string | undefined = undefined;
    if (
      (useMode === "rental" || useMode === "reservation") &&
      valueType === PaymentPolicyValueTypeEnum.PERCENT &&
      totalValue > 100
    ) {
      // * Check that the total value doesn't exceed 100%
      message = t(
        "Rental.Pricing.PaymentPolicy.CreateOrUpdateModal.Errors.moreThan100Percent"
      );

      setError("payments_policy_items", {
        type: "manual",
        message: message,
      });
      onError(message);
    } else if (
      useMode === "reservation" &&
      valueType === PaymentPolicyValueTypeEnum.FIXED &&
      totalPrice &&
      totalValue > totalPrice
    ) {
      // * Check that the total value doesn't exceed the totalPrice
      message = t(
        "Rental.Pricing.PaymentPolicy.CreateOrUpdateModal.Errors.moreThanTotal",
        {
          total: totalPrice,
        }
      );

      setError("payments_policy_items", {
        type: "manual",
        message: message,
      });
      onError(message);
    } else if (
      (useMode === "rental" || useMode === "reservation") &&
      valueType === PaymentPolicyValueTypeEnum.PERCENT &&
      useGetTotalValue(getValues("payments_policy_items")) !== 100
    ) {
      // * Check if total value is equal to 100%
      message = t(
        "Rental.Pricing.PaymentPolicy.CreateOrUpdateModal.Errors.mustBeEqualTo100Percent"
      );

      setError("payments_policy_items", {
        type: "manual",
        message: message,
      });
      onError(message);
    } else if (
      useMode === "reservation" &&
      totalPrice &&
      valueType === PaymentPolicyValueTypeEnum.FIXED &&
      useGetTotalValue(getValues("payments_policy_items")) !== totalPrice
    ) {
      // * Check if total value is equal to totalPrice
      message = t(
        "Rental.Pricing.PaymentPolicy.CreateOrUpdateModal.Errors.mustBeEqualToTotal",
        {
          total: totalPrice,
        }
      );

      setError("payments_policy_items", {
        type: "manual",
        message: message,
      });
      onError(message);
    } else if (
      // * Check if any value is equal to 0
      useGetTotalValue(getValues("payments_policy_items")) === 0 ||
      useHasIncludeWrongValue(getValues("payments_policy_items"))
    ) {
      message = t(
        "Rental.Pricing.PaymentPolicy.CreateOrUpdateModal.Errors.moreThan0"
      );

      setError("payments_policy_items", {
        type: "manual",
        message: message,
      });
      onError(message);
    } else {
      onError(undefined);

      if (Object.keys(errors).length > 0) {
        clearErrors("payments_policy_items");
      }
    }
  }, [totalValue, valueType]);

  useEffect(() => {
    if (getValues("is_single_payment")) {
      setValue("payments_policy_items", [
        {
          valueType: PaymentPolicyValueTypeEnum.PERCENT,
          value: 100,
          specificDate: moment(new Date(), "DD/MM/YYYY").toDate(),
          trigger: PaymentPolicyPaymentTriggerEnum.AT_CHECKIN,
          paymentAccountId: null,
        },
      ]);
    }
  }, [getValues("is_single_payment")]);
};

/**
 * This hook can be used to add a new payment policy item
 * @param useMode
 * @param paymentPolicyAppend
 * @param getValues
 * @param totalPrice
 * @param valueType
 * @param totalValueState
 * @param paymentAccounts
 */
export const useAppendPaymentPolicy = (
  useMode: "rental" | "reservation",
  paymentPolicyAppend: UseFieldArrayAppend<
    PaymentPolicyForm,
    "payments_policy_items"
  >,
  getValues: UseFormGetValues<PaymentPolicyForm>,
  totalPrice: number | undefined,
  valueType: PaymentPolicyValueTypeEnum,
  totalValueState: [number, Dispatch<SetStateAction<number>>],
  paymentAccounts: PaymentAccount[]
) => {
  const [totalValue, setTotalValue] = totalValueState;

  // PERCENT TYPE
  if (
    (useMode === "rental" || useMode === "reservation") &&
    valueType === PaymentPolicyValueTypeEnum.PERCENT &&
    totalValue < 100
  ) {
    paymentPolicyAppend({
      trigger:
        getValues("payments_policy_items").length >= 1
          ? PaymentPolicyPaymentTriggerEnum.TWENTY_FOUR_HOURS_BEFORE_ARRIVAL
          : PaymentPolicyPaymentTriggerEnum.AT_RESERVATION,
      valueType: valueType,
      value: 100 - totalValue,
      specificDate: null,
      paymentAccountId:
        paymentAccounts && paymentAccounts.length > 0
          ? paymentAccounts[0].id
          : null,
    });
    setTotalValue(useGetTotalValue(getValues("payments_policy_items")));
  } else if (
    useMode === "reservation" &&
    valueType === PaymentPolicyValueTypeEnum.FIXED &&
    totalPrice &&
    totalValue < totalPrice
  ) {
    // FIXED TYPE
    let price = totalPrice - totalValue;
    let roundedValue = parseFloat(price.toFixed(2));
    if (roundedValue % 1 === 0) {
      roundedValue = parseInt(String(roundedValue));
    }

    paymentPolicyAppend({
      trigger: PaymentPolicyPaymentTriggerEnum.AT_RESERVATION,
      valueType: valueType,
      value: roundedValue,
      specificDate: null,
      paymentAccountId:
        paymentAccounts && paymentAccounts.length > 0
          ? paymentAccounts[0].id
          : null,
    });
    setTotalValue(useGetTotalValue(getValues("payments_policy_items")));
  }
};

export const useTriggerPaymentPolicyItems = (
  useMode: "rental" | "reservation",
  isFirstOrSinglePayment: boolean = false
): InputSelectOptionProps[] => {
  let items: InputSelectOptionProps[] = [];

  // show specific date trigger option only when creating a reservation
  if (useMode === "reservation") {
    items.push(
      {
        label: t("Payments.PaymentTriggerOptions.atCheckin"),
        value: PaymentPolicyPaymentTriggerEnum.AT_CHECKIN,
      },
      {
        label: t("Payments.PaymentTriggerOptions.specificDate"),
        value: PaymentPolicyPaymentTriggerEnum.SPECIFIC_DATE,
      }
    );
  }

  // don't show first trigger option for second or third payment
  if (isFirstOrSinglePayment) {
    items.push({
      label: t("Payments.PaymentTriggerOptions.atReservation"),
      value: PaymentPolicyPaymentTriggerEnum.AT_RESERVATION,
    });
  }

  // show all other trigger options only in second or third payments
  if (
    (useMode === "reservation" && !isFirstOrSinglePayment) ||
    useMode === "rental"
  ) {
    items.push(
      {
        label: t("Payments.PaymentTriggerOptions.twentyFourBeforeArrival"),
        value: PaymentPolicyPaymentTriggerEnum.TWENTY_FOUR_HOURS_BEFORE_ARRIVAL,
      },
      {
        label: t("Payments.PaymentTriggerOptions.fiveDaysBeforeArrival"),
        value: PaymentPolicyPaymentTriggerEnum.FIVE_DAYS_BEFORE_ARRIVAL,
      },
      {
        label: t("Payments.PaymentTriggerOptions.sevenDaysBeforeArrival"),
        value: PaymentPolicyPaymentTriggerEnum.SEVEN_DAYS_BEFORE_ARRIVAL,
      },
      {
        label: t("Payments.PaymentTriggerOptions.fourteenDaysBeforeArrival"),
        value: PaymentPolicyPaymentTriggerEnum.FOURTEEN_DAYS_BEFORE_ARRIVAL,
      },
      {
        label: t("Payments.PaymentTriggerOptions.thirtyDaysBeforeArrival"),
        value: PaymentPolicyPaymentTriggerEnum.THIRTY_DAYS_BEFORE_ARRIVAL,
      },
      {
        label: t("Payments.PaymentTriggerOptions.sixtyBeforeArrival"),
        value: PaymentPolicyPaymentTriggerEnum.SIXTY_DAYS_BEFORE_ARRIVAL,
      }
    );
  }

  return items;
};

export const useDepositPaymentOptionItems = (
  paymentAccounts: PaymentAccount[]
): InputSelectOptionProps[] => {
  const hasStripeAccount: boolean = Boolean(
    paymentAccounts.filter(
      (v) => v.type.toLowerCase() === PaymentAccountTypeEnum.STRIPE
    ).length > 0
  );

  let items = [
    {
      label: t("Payments.DepositPaymentOptions.checkOrCash"),
      value:
        PaymentPolicyDepositPaymentOptionEnum.BANK_CHECK_OR_CASH_AT_ARRIVAL,
    },
  ];

  if (hasStripeAccount) {
    items = [
      {
        label: t("Payments.DepositPaymentOptions.preAuthorisation"),
        value: PaymentPolicyDepositPaymentOptionEnum.PRE_AUTHORISATION,
      },
      {
        label: t("Payments.DepositPaymentOptions.cbRefund"),
        value: PaymentPolicyDepositPaymentOptionEnum.CARD_PAYMENT_TO_REFUND,
      },
      ...items,
    ];
  }

  return items;
};
