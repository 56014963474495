/**
 * Skeleton for list component in
 * payments operational.
 *
 * beautiful skeleton YAH.
 *
 * @constructor
 */
import React from "react";

export const PaymentListSkeleton = () => {
  return (
    <section className={"mt-4"}>
      <div className="flex flex-col bg-gray-50 border rounded-xl mb-4 animate-pulse">
        <div className="h-10 bg-gray-200 rounded"></div>
      </div>
      <div className="flex flex-col bg-gray-50 border rounded-xl mb-4 animate-pulse">
        <div className="h-10 bg-gray-200 rounded"></div>
      </div>
      <div className="flex flex-col bg-gray-50 border rounded-xl mb-4 animate-pulse">
        <div className="h-10 bg-gray-200 rounded"></div>
      </div>
      <div className="flex flex-col bg-gray-50 border rounded-xl mb-4 animate-pulse">
        <div className="h-10 bg-gray-200 rounded"></div>
      </div>
      <div className="flex flex-col bg-gray-50 border rounded-xl mb-4 animate-pulse">
        <div className="h-10 bg-gray-200 rounded"></div>
      </div>
      <div className="flex flex-col bg-gray-50 border rounded-xl mb-4 animate-pulse">
        <div className="h-10 bg-gray-200 rounded"></div>
      </div>
      <div className="flex flex-col bg-gray-50 border rounded-xl mb-4 animate-pulse">
        <div className="h-10 bg-gray-200 rounded"></div>
      </div>
      <div className="flex flex-col bg-gray-50 border rounded-xl mb-4 animate-pulse">
        <div className="h-10 bg-gray-200 rounded"></div>
      </div>
      <div className="flex flex-col bg-gray-50 border rounded-xl mb-4 animate-pulse">
        <div className="h-10 bg-gray-200 rounded"></div>
      </div>
      <div className="flex flex-col bg-gray-50 border rounded-xl mb-4 animate-pulse">
        <div className="h-10 bg-gray-200 rounded"></div>
      </div>
      <div className="flex flex-col bg-gray-50 border rounded-xl mb-4 animate-pulse">
        <div className="h-10 bg-gray-200 rounded"></div>
      </div>
    </section>
  );
};
