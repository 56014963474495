import React from "react";
import {GuestWebsiteCommonProps} from "../GuestWebsiteLayout";
import useGuestWebsiteStore from "../../../stores/guestWebsiteStore/useGuestWebsiteStore";
import {GuestWebsiteTemplate2HomeComponent} from "./Template2.0/GuestWebsiteTemplate2HomeComponent";
import {GuestWebsiteModel} from "../../../types/GETTypes";

interface GuestWebsiteHomePageProps extends GuestWebsiteCommonProps {}

const RedirectToCorrectTemplate = (props: GuestWebsiteHomePageProps) => {
  const {templateGuestWebsite} = useGuestWebsiteStore();
  switch (templateGuestWebsite) {
    case GuestWebsiteModel.MODEL_2_0:
      return <GuestWebsiteTemplate2HomeComponent {...props}/>;
    case GuestWebsiteModel.MODEL_CHIC:
      return <></>;
    case GuestWebsiteModel.MODEL_MINIMAL:
      return <></>;
    default:
      return <GuestWebsiteTemplate2HomeComponent {...props} />;
  }
};

const GuestWebsiteHomePage = (props: GuestWebsiteHomePageProps) => {
  return <RedirectToCorrectTemplate {...props} />;
};

export default GuestWebsiteHomePage