import React from "react";
import GuestWebsiteHeaderLayout from "./Header/GuestWebsiteHeaderLayout";
import GuestWebsiteFooterLayout from "./Footer/GuestWebsiteFooterLayout";
import SkeletonRentalsList from "./Common/Template2.0/SkeletonRentalsList";

interface GuestWebsiteLayoutProps {
  children: React.ReactNode;
  loading: boolean;
}

export interface GuestWebsiteCommonProps {
  loading: boolean;
}
const GuestWebsiteLayout = ({children, loading}: GuestWebsiteLayoutProps) => {
  return (
    <>
      <GuestWebsiteHeaderLayout loading={loading} />
      {loading ? (
        /* Skeleton Loader*/
        <div className="flex-grow">
          {/* SKeleton Brands*/}
          <div className="md:flex md:flex-col bg-element-border py-4 md:items-center space-y-8">
            <div className="container mx-auto px-4 md:py-8  grid grid-cols-2 md:grid-cols-6 gap-4 justify-center items-center">
              {[...Array(6)].map((_, index) => (
                <div className="h-20 bg-gray-500 rounded-lg animate-pulse mx-auto w-full"></div>
              ))}
            </div>
            {/* SKeleton RentalList*/}
            <div className="w-full px-4">
              <SkeletonRentalsList />
            </div>
          </div>
        </div>
      ) : (
        <main className="flex-grow">{children}</main>
      )}
      <GuestWebsiteFooterLayout loading={loading} />
    </>
  );
};

export default GuestWebsiteLayout;
