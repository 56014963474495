import React, {useEffect, useRef, useState} from "react";
import {Button} from "../../Common/Button/Button";
import {useTranslation} from "react-i18next";
import ArrowLeftIcon from "../../../assets/icons/arrow-left.svg?react";
import ArrowUndoIcon from "../../../assets/icons/arrow-undo.svg?react";
import DownloadIcon from "../../../assets/icons/download.svg?react";
import CheckWhiteIcon from "../../../assets/icons/check-white.svg?react";
import AlertCircle from "../../../assets/icons/alert-circle.svg?react";
import SuperhoteIcon from "../../../assets/icons/superhote.svg";
import {
  ContractResponse,
  ContractsDataDetails,
  ContractsResponse,
  GuestPageConnectResponse,
  GuestPageLoginDetailsResponse,
} from "../../../types/GETTypes";
import ContractInfoComponent from "../../Reservation/ContractDocs/Component/ContractInfoComponent";
import {cn} from "../../../helpers/classHelper";
import SignaturePad from "react-signature-canvas";
import {Title} from "../../Common/Title/Title";
import {get, post} from "../../../helpers/APIHelper";
import paths from "../../../constants/paths";
import {base64ToFile, downloadFile} from "../../../helpers/fileHelper";
import {ErrorMessage} from "../../Common/ErrorMessage/ErrorMessage";
import {useParams} from "react-router-dom";
import {changeTitle} from "../../../pages/GuestPageUser/GuestPageUser";
import {useModal} from "../../../hooks/useModal";
import GuestPageContractModalSuccess from "./GuestPageContractModalSuccess";

interface GuestPageContractProps {
  reservationId?: number | undefined;
  goBack?: (value: React.SetStateAction<boolean>) => void;
  handleLogin?: (guestData?: GuestPageConnectResponse) => void;
  formData?: FormData | undefined;
  guestData?: GuestPageConnectResponse | undefined;
  contracts?: ContractResponse[] | ContractsResponse[];
  rentalCover?: string | undefined;
  loginDetailsFromPage?: GuestPageLoginDetailsResponse;
  isPreviewMode?: boolean;
}

function getContractId() {
  const urlObj = new URL(window.location.href);

  const urlTabValue = urlObj.searchParams.get("contractId");

  return urlTabValue;
}
export type SuccessModalContract = {};
const GuestPageContract = ({
  reservationId,
  goBack,
  contracts,
  rentalCover,
  handleLogin,
  formData,
  loginDetailsFromPage,
  isPreviewMode,
}: GuestPageContractProps) => {
  const {t} = useTranslation();
  const [openContract, setOpenContract] = useState(false);
  const {guestPageLink} = useParams();
  const [loadingData, setLoadingData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSuccess, setLoadingSuccess] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [errorLink, setErrorLink] = useState(false);
  const [loginDetailsLoaded, setLoginDetailsLoaded] =
    useState<GuestPageLoginDetailsResponse>();
  const [error, setError] = useState("");
  const [contract, setContract] = useState<ContractResponse>();
  const modalSuccess = useModal();
  const signPadRef = useRef<SignaturePad>(null);
  const contractId = getContractId();
  const fromUrl = contractId ? true : false;
  const [resaId, setResaId] = useState<number | undefined>();
  const [signatureUrl, setSignatureUrl] = useState("");
  const [cover, setCover] = useState(rentalCover);

  const loginDetails = fromUrl ? loginDetailsLoaded : loginDetailsFromPage;

  const {form_enabled} = loginDetails || {};

  const keys: (keyof GuestPageLoginDetailsResponse)[] = [
    "guest_first_name",
    "guest_last_name",
    "guest_email",
    "guest_phone",
    "guest_address",
    "guest_postal_code",
    "guest_city",
    "guest_country",
    "guest_business",
    "billing_host_name",
    "billing_address",
    "billing_city",
    "billing_postal_code",
    "billing_country",
    "billing_email",
    "billing_phone",
    "rental_address",
    "rental_postal_code",
    "rental_name",
    "rental_city",
    "reservation_checkin",
    "reservation_checkout",
    "reservation_night_counts",
    "reservation_total_guests",
    "reservation_adults_counts",
    "reservation_children_counts",
    "reservation_payments_details",
    "reservation_total_ht",
    "reservation_total_ttc",
    "reservation_payment_schedule",
  ];

  const details: ContractsDataDetails = keys.reduce((acc, key) => {
    if (loginDetails && key in loginDetails) {
      //@ts-ignore
      acc[key as keyof ContractsDataDetails] =
        loginDetails[key as keyof GuestPageLoginDetailsResponse];
    }
    return acc;
  }, {} as ContractsDataDetails);

  useEffect(() => {
    if (fromUrl) {
      loadData();
    } else {
      setResaId(reservationId);
      if (isPreviewMode) {
        const contractsData = contracts as ContractsResponse[];
        const firstContract =
          contractsData && contractsData?.length > 0
            ? contractsData.find((contract) => contract.is_default)
            : undefined;
        if (firstContract) {
          setContract(firstContract as unknown as ContractResponse);
        }
      } else {
        const contractsData = contracts as ContractResponse[];
        const firstContract =
          contractsData && contractsData?.length > 0
            ? contractsData[0]
            : undefined;
        setContract(firstContract);
      }
    }
  }, [contractId, reservationId]);

  useEffect(() => {
    if (contract?.is_signed) {
      modalSuccess.open();
    } else {
      modalSuccess.close();
    }
  }, [contract]);

  const loadData = async () => {
    setLoadingData(true);
    const {data} =
      (await get(
        `${import.meta.env.VITE_API_URL}${
          paths.API.GUEST_PAGE.GET_SCREEN_DETAILS
        }/${guestPageLink}`
      )) || {};
    const {result, success} =
      (data as {result: GuestPageLoginDetailsResponse; success: boolean}) || {};
    if (success) {
      setCover(result.rental_cover);
      setResaId(result.reservation_id);
      setLoginDetailsLoaded(result);
      const contract = result.contracts?.contracts?.find(
        (contract) => contract.id === Number(contractId)
      );
      setContract(contract);
      changeTitle(contract?.name ?? "");
    } else {
      setErrorLink(true);
    }
    setLoadingData(false);
  };

  const handleSignature = async () => {
    setError("");
    if (isPreviewMode) {
      modalSuccess.open();
    } else if (contract && resaId) {
      setLoading(true);
      try {
        const signatureFile = signPadRef?.current
          ?.getTrimmedCanvas()
          ?.toDataURL("image/png");

        if (signatureFile) {
          const file = base64ToFile(
            signatureFile,
            contract.name?.replace(/\s+/g, "") + "_signature.png"
          );
          const formDataSignature = new FormData();
          formDataSignature.append("signature_file", file);
          const res = await post(
            `${
              import.meta.env.VITE_API_URL
            }${paths.API.CONTRACTS_DOCS.SIGN_CONTRACT_BY_RESERVATION(
              resaId,
              contract?.id
            )}`,
            formDataSignature
          );

          if (res?.data?.success) {
            const find: ContractResponse = res?.data?.result?.contracts?.find(
              (c: ContractResponse) => c.id === contract.id
            );
            if (find) {
              setError("");
              setSignatureUrl(find?.pdf_url ?? "");
              modalSuccess.open();
            } else {
              setError("Unexpected error");
            }
          } else {
            setError(res?.response?.data?.message);
          }
        } else {
          setError("Unexpected error");
        }
      } catch (e: any) {
        setError(e?.message);
      }
      setLoading(false);
    }
  };

  const connect = async ({newFormData}: {newFormData: FormData}) => {
    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.GUEST_PAGE.CONNECT}`,
      newFormData
    );
    console.log("🚀 ~ handleConnect ~ res:", res);
    if (res?.data?.success) {
      const data = res?.data?.result;
      setError("");
      handleLogin && handleLogin(data);
    } else {
      setError(res?.response?.data?.message);
    }
  };
  const handleConnect = async () => {
    setLoadingSuccess(true);
    try {
      if (isPreviewMode) {
        handleLogin && handleLogin();
      } else if (form_enabled && formData) {
        formData.delete("verification_only");
        formData.append("verification_only", "0");
        await connect({newFormData: formData});
      } else if (!form_enabled) {
        const newFormData = new FormData();
        const {reservation_id, guest_email, guest_phone} = loginDetails || {};
        newFormData.append("reservation_id", String(reservation_id));
        newFormData.append("verification_only", "0");
        newFormData.append("guest_page_connected", "1");
        newFormData.append("guest_page_link", guestPageLink ?? "");
        newFormData.append("email", guest_email ?? "");
        newFormData.append("phone", guest_phone ?? "");
        await connect({newFormData});
      } else {
        setError("Unexpected error");
      }
    } catch (e: any) {
      setError(e?.message);
    }
    setLoadingSuccess(false);
  };

  const clear = () => {
    signPadRef?.current?.clear();
  };

  const isEmptySignature = () => {
    if (signPadRef?.current?.isEmpty()) {
      setIsEmpty(true);
      return true;
    }
    setIsEmpty(false);
    return false;
  };

  const confirmSignature = async () => {
    if (!isEmptySignature() || isPreviewMode) {
      await handleSignature();
    }
  };

  const downloadContract = (url: string) => {
    if (contract && url) {
      downloadFile({
        original_url: url,
        name: contract?.name,
      });
    }
  };

  const openGuestPage = async () => {
    if (!fromUrl) {
      await handleConnect();
    }
  };

  if (loadingData) {
    return (
      <>
        <div className="relative h-44 md:h-40 bg-cover bg-center animate-pulse bg-gray-300">
          <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="text-white px-4 md:w-2/3 md:pl-0 mb-4">
              <div className="h-8 md:h-10 bg-gray-500 rounded w-3/4 animate-pulse"></div>
              <div className="mt-2 h-4 md:h-6 bg-gray-500 rounded w-1/2 animate-pulse"></div>
            </div>
          </div>
        </div>

        <div className="flex flex-col md:w-full">
          <div className="flex flex-col flex-1 justify-center items-center w-full">
            <div className="md:w-1/2 space-y-12 mb-8 mx-4 md:mx-0">
              {/* Bouton de retour ou espace vide */}
              <div className="m-4 mt-8 md:mt-14 ml-0 h-10 bg-gray-300 rounded animate-pulse"></div>

              <div className="flex flex-col border border-gray-300 bg-gray-100 rounded-lg p-4 space-y-4 animate-pulse">
                <div className="h-6 bg-gray-300 rounded w-1/4"></div>
                <div className="flex justify-between items-center">
                  <div className="h-4 bg-gray-300 rounded w-1/2"></div>
                  <div className="h-8 bg-gray-300 rounded w-20"></div>
                </div>

                <div className="h-[200px] w-full bg-gray-300 rounded"></div>

                <div className="h-4 bg-gray-300 rounded w-1/3"></div>

                <div className="flex space-y-2 md:space-y-0 md:space-x-2 flex-col md:flex-row">
                  <div className="h-10 bg-gray-300 rounded w-full"></div>
                  <div className="h-10 bg-gray-300 rounded w-full"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  if (errorLink) {
    return (
      <div className="flex flex-col flex-1 w-screen h-screen">
        <img
          src={`${SuperhoteIcon}`}
          className="w-screen h-32 px-8 mt-16 bg-cover md:h-32"
        />
        <div className="flex flex-col items-center justify-center flex-1 mx-4">
          <AlertCircle className={`fill-error`} width={50} height={50} />
          <div
            className={` text-xl font-light text-center flex flex-row items-center justify-center text-error mt-8`}
          >
            {t("GuestPage.Contract.guestPageErrorLink")}
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <GuestPageContractModalSuccess
        isVisible={modalSuccess.isVisible}
        fromUrl={fromUrl}
        onAction={openGuestPage}
        onDownload={() => !isPreviewMode && downloadContract(signatureUrl)}
        loading={loadingSuccess}
      />
      <div
        className="relative h-48 md:h-52 bg-cover bg-center"
        style={{backgroundImage: `url('${cover}')`}}
      >
        <div
          className={cn(
            `absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center`
          )}
        >
          <div
            className={`text-white p-8  md:pl-0 mb-4 md:w-full md:flex md:flex-col md:items-center md:justify-center`}
          >
            <div className="md:w-2/4 p-4 mt-16 my-12">
              <h1 className="text-[28px] md:text-[40px] font-bold text-start">
                {contract?.name}
              </h1>
              <p className="mt-1 text-base md:text-xl font-medium leading-7 text-start">
                {t("GuestPage.Contract.info")}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:w-full">
        <div className="flex flex-col flex-1 justify-center items-center w-full">
          <div className="md:w-1/2 space-y-12 mb-8 mx-4 md:mx-0">
            {!fromUrl && form_enabled ? (
              <Button
                LeftIcon={ArrowLeftIcon}
                type="secondary"
                displayLargeBtn={false}
                className="m-4 mt-8 md:mt-14 ml-0"
                buttonClassName="md:h-12"
                textClassName="md:text-xl"
                onClick={goBack}
                disabled={loading}
              >
                {t("GuestPage.ExtraHistorical.goBack")}
              </Button>
            ) : (
              <div className="m-4 mt-2 ml-0 md:h-auto h-auto"></div>
            )}
            <ContractInfoComponent
              setOpenContract={setOpenContract}
              openContract={openContract}
              content={contract?.content}
              classNameContract="border-1 border-gray-300 p-4 rounded-6px"
              details={details}
              isPreviewMode={isPreviewMode}
            />
            <div className="flex flex-col border-1 border-gray-300 bg-element-border/20 rounded-6px p-4 space-y-4">
              <Title>Signature</Title>
              <div className="flex justify-between items-center">
                <p className="text-low-contrast text-xs">
                  {t("GuestPage.Contract.drawSignature")}
                </p>
                <Button
                  type="secondary"
                  RightIcon={ArrowUndoIcon}
                  displayLargeBtn={false}
                  onClick={clear}
                  disabled={loading}
                >
                  {t("GuestPage.Contract.again")}
                </Button>
              </div>
              <SignaturePad
                canvasProps={{
                  className: cn(
                    "h-[200px] w-full bg-white",
                    isEmpty ? "border border-error" : ""
                  ),
                }}
                ref={signPadRef}
              />

              <ErrorMessage>{error}</ErrorMessage>
              <div className="flex space-y-2 md:space-y-0 md:space-x-2 flex-col md:flex-row">
                <Button
                  type="secondary"
                  RightIcon={DownloadIcon}
                  onClick={() => downloadContract(contract?.pdf_url ?? "")}
                  disabled={loading || isPreviewMode}
                >
                  {t("GuestPage.Contract.downloadContract")}
                </Button>
                <Button
                  RightIcon={CheckWhiteIcon}
                  onClick={confirmSignature}
                  loading={loading}
                  disabled={contract?.is_signed}
                >
                  {t("GuestPage.Contract.confirmContract")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GuestPageContract;
