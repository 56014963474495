import React from "react";
import {useTranslation} from "react-i18next";
import {cn} from "../../helpers/classHelper";
import {getPlatformIcon} from "../../helpers/platformHelper";
import {Button} from "../Common/Button/Button";
import ArrowRightIcon from "../../assets/icons/arrow-right.svg?react";
import EmojiHappyIcon from "../../assets/icons/emoji-happy.svg?react";
import LogInIcon from "../../assets/icons/log-in.svg?react";
import LogOutIcon from "../../assets/icons/log-out.svg?react";
import MailIcon from "../../assets/icons/mail.svg?react";
import PhoneIcon from "../../assets/icons/phone.svg?react";
import ShowIcon from "../../assets/icons/show.svg?react";
import UsersIcon from "../../assets/icons/users.svg?react";
import MoonIcon from "../../assets/icons/moon.svg?react";

export const DashboardTasksItemSkeleton = () => {
  return (
    <div
      className={cn(
        `flex items-center p-10 border-t-0 border-b-1 border-x-1 border-element-border hover:cursor-pointer hover:bg-element-background/30 cursor-pointer bg-gray-200 animate-pulse`
      )}
    >
    </div>
  );
};
