import React from "react";
import {FormContactComponent} from "./FormComponent";
import InfoContactComponent from "./InfoContactComponent";
import useGuestWebsiteStore from "../../../../stores/guestWebsiteStore/useGuestWebsiteStore";

interface GuestWebsiteTemplate2ContactComponentProps {
  loading: boolean;
}

const GuestWebsiteTemplate2ContactComponent =
  ({}: GuestWebsiteTemplate2ContactComponentProps) => {
    const {dataGuestWebsite} = useGuestWebsiteStore();
    const {styles} = dataGuestWebsite || {};
    const {color_main, color_background} = styles || {};
    return (
      <div
        className="flex h-full p-4 items-center justify-center"
        style={{backgroundColor: color_background}}
      >
        <div className="flex flex-col md:flex-row items-center justify-center h-full space-y-8 md:space-y-0 md:space-x-6 pb-20 max-w-7xl w-full">
          <FormContactComponent />
          <InfoContactComponent />
        </div>
      </div>
    );
  };

export default GuestWebsiteTemplate2ContactComponent;
