import React from "react";
import SkeletonFooterTemplate2 from "./SkeletonFooterTemplate2";
import {FooterTemplate2} from "./FooterTemplate2";

interface GuestWebsiteTemplate2FooterComponentProps {
  loading: boolean;
}
const GuestWebsiteTemplate2FooterComponent = ({
  loading,
}: GuestWebsiteTemplate2FooterComponentProps) => {
  if (loading) {
    return <SkeletonFooterTemplate2 />;
  }
  return <FooterTemplate2 />;
};

export default GuestWebsiteTemplate2FooterComponent;
