import React from "react";
import {useTranslation} from "react-i18next";
import EditIcon from "../../../assets/icons/edit.svg?react";
import EyeIcon from "../../../assets/icons/eye.svg?react";
import {
    OperationalManagementRoleListItemResponse,
    OperationalManagementTeamListItemResponse,
    OperationalManagementTeamMemberResponse,
} from "../../../types/GETTypes";
import {Badge} from "../../Common/Badge/Badge";
import {ManagementTeamRoleBadge} from "./ManagementTeamRoleBadge";
import useUserStore from "../../../stores/useUserStore";

export const ManagementTeamMemberItem: React.FC<{
  member: OperationalManagementTeamMemberResponse;
  onView: (member: OperationalManagementTeamMemberResponse) => void;
  onEdit: (member: OperationalManagementTeamMemberResponse) => void;
  currentTeam: OperationalManagementTeamListItemResponse;
}> = ({ member, onView, onEdit, currentTeam }) => {
  const { t } = useTranslation();
  const { user } = useUserStore();

  const isAccepted = () => {
    return (
      member.accepted === 1 &&
      member.first_name !== "" &&
      member.last_name !== ""
    );
  };

  return (
    <div className="flex flex-row items-center gap-2">
      {/* Avatar */}
      {isAccepted() ? (
        <img
          src={member.photo}
          className="rounded-full cursor-pointer size-12 bg-element-background border-element-border border-1 shrink-0"
          onClick={() => onView(member)}
        />
      ) : (
        <div
          className="rounded-full cursor-pointer size-12 bg-element-background border-element-border border-1 shrink-0"
          onClick={() => onView(member)}
        />
      )}

      {/* Name, phone and email */}
      <div
        className="flex flex-col cursor-pointer w-80"
        onClick={() => onView(member)}
      >
        {isAccepted() && (
          <>
            <p className="font-semibold text-high-contrast">
              {member.first_name} {member.last_name}
            </p>

            <p className="font-light text-low-contrast">
              {member.email} - {member.phone}
            </p>
          </>
        )}

        {!isAccepted() && (
          <>
            <p className="mb-1 font-light cursor-pointer text-low-contrast">
              {member.email}
            </p>

            <Badge label={t("Management.TeamList.invitSent")} size="small" />
          </>
        )}
      </div>

      {/* Nb of rentals */}
      <div className="flex-1 cursor-pointer" onClick={() => onView(member)}>
        <p className="font-light text-low-contrast">
          {t("Management.TeamList.rentalsAssigned", {
            count: member.rentals_access?.length,
          })}
        </p>
      </div>

      {/* Roles */}
      <div
        className="flex flex-row items-center gap-1 cursor-pointer w-fit"
        onClick={() => onView(member)}
      >
        {member.postes?.map((role) => (
          <ManagementTeamRoleBadge
            key={role.id}
            role={role as OperationalManagementRoleListItemResponse}
          />
        ))}
      </div>

      {/* Actions
        Only the creator of the team can edit or view the member
      */}
        {
            currentTeam.created_by === user?.id && (
                <div className="flex items-center gap-2">
                    <EyeIcon
                        className="cursor-pointer size-5"
                        onClick={() => onView(member)}
                    />
                    <EditIcon
                        className="cursor-pointer size-5"
                        onClick={() => onEdit(member)}
                    />
                </div>
            )
        }

    </div>
  );
};
