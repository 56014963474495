import React from "react";
import RentalCardComponent from "./RentalCardComponent";
import {GuestWebsiteRental} from "../../../../types/GETTypes";
import useGuestWebsiteStore from "../../../../stores/guestWebsiteStore/useGuestWebsiteStore";

const RentalsListComponent = ({
  rentals,
}: {
  rentals: GuestWebsiteRental[] | undefined;
}) => {
  const {dataGuestWebsite} = useGuestWebsiteStore();
  const {styles} = dataGuestWebsite || {};
  const {color_background} = styles || {};
  return (
    <div
      className="flex flex-wrap justify-center sh-rental-list w-full md:px-[150px] gap-8 items-stretch"
      style={{backgroundColor: color_background}}
    >
      {rentals?.map((rental, index) => (
        <div className="w-full md:w-[315px] md:mb-6 flex flex-col">
          <RentalCardComponent key={index} {...rental} />
          {/* <div className="flex bg-red-400 w-full h-full"/> */}
        </div>
      ))}
    </div>
  );
};

export default RentalsListComponent;
