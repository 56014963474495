import { ReservationLockItemResponse } from "../../../types/GETTypes";
import { FlexItemCenter } from "../../Common/FlexComponents/FlexItemCenter";
import useText from "../../../hooks/useText";
import React from "react";
import { HelpText } from "../../Common/TextComponents/HelpText";

export default function Lock({ lock }: { lock: ReservationLockItemResponse }) {
  const { getText } = useText("Calendar.Reservation.Locks");

  return (
    <FlexItemCenter gap={5} className={"bg-gray-200 p-2 rounded-md"}>
      <div className={"h-[20px] w-[20px] rounded-full bg-gray-300"}></div>
      <HelpText>
        {getText("auto")}
        {" | "}
        {lock?.device?.name}
        {" : "}
      </HelpText>
      <strong className={"text-red-500"}>{lock?.code}</strong>
    </FlexItemCenter>
  );
}
