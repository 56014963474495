import React from "react";
import { PageMenu } from "../Common/PageMenu/PageMenu";

import BoxesIcon from "../../assets/icons/boxes.svg?react";
import BoxesHighIcon from "../../assets/icons/boxes-high.svg?react";

import BrushIcon from "../../assets/icons/brush.svg?react";
import BrushHighIcon from "../../assets/icons/brush-high.svg?react";

export const WebsiteStyleMenu: React.FC<{}> = () => {
  return (
    <PageMenu
      items={[
        {
          Icon: BoxesIcon,
          IconActive: BoxesHighIcon,
          anchor: "templates-card",
          labelI18n: "Website.Templates.title",
        },
        {
          Icon: BrushIcon,
          IconActive: BrushHighIcon,
          anchor: "colors-card",
          labelI18n: "Website.Colors.title",
        },
      ]}
    />
  );
};
