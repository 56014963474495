import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Button} from "../../../Common/Button/Button";
import {CarouselWizard} from "../../../Common/CarouselWizard/CarouselWizard";
import {RightModal} from "../../../Common/RightModal/RightModal";
import {BookingConnectModalProps} from "./BookingConnectModal.type";

import CheckIcon from "../../../../assets/icons/check-white.svg?react";
import {Separator} from "../../../Common/Separator/Separator";
import {BookingConnectStep1} from "./BookingConnectStep1";
import {BookingConnectStep2} from "./BookingConnectStep2";
import {
  RentalLightBookingItemResponse,
  RentalLightListItemResponse,
} from "../../../../types/GETTypes";
import {BookingConnectStep3} from "./BookingConnectStep3";
import {BookingConnectRentalRoomTypeForm} from "./BookingConnectRentalRoomType";
import {BookingConnectStep4} from "./BookingConnectStep4";
import BookingConnectSuccessCard from "./BookingConnectSuccessCard";
import BookingSynchroSuccessCard from "./BookingSynchroSuccessCard";
import {BookingSynchroStep2} from "./BookingSynchroStep2";
import {BookingSynchroStep3} from "./BookingSynchroStep3";

export interface BookingConnectDataRentalRoomRates {
  rooms: BookingConnectDataRoom[];
  rates: BookingConnectDataRate[];
  id: string;
}

export interface BookingConnectDataRoom {
  id: string;
  name: string;
}

export interface BookingConnectDataRate {
  id: string;
  name: string;
}

export interface BookingConnectData {
  hotel_id: string;
  legal_entity_id: string;
  properties: RentalLightBookingItemResponse[];
  propertiesSelected?: RentalLightBookingItemResponse[];
  roomsRates?: BookingConnectDataRentalRoomRates[];
  importData?: BookingConnectRentalRoomTypeForm[];
  rentals?: RentalLightListItemResponse[];
  finishImport?: boolean;
  finishSynchro?: boolean;
}
export const BookingConnectModal: React.FC<BookingConnectModalProps> = ({
  isVisible,
  onClose,
  onFinish,
  data,
  setData,
  purpose = "onboarding",
  rental,
}) => {
  const {t} = useTranslation();

  const [currentStep, setCurrentStep] = useState(0);

  const handleCancel = () => {
    if (currentStep === 0) {
      onClose({});
    } else {
      setCurrentStep((prev) => prev - 1);
    }
  };

  const handleSubmit = () => {
    if (purpose === "onboarding" && currentStep === 4) {
      onFinish && onFinish();
      onClose && onClose({displayCancel: false});
    } else if (purpose === "rental_synchronization" && currentStep === 3) {
      setCurrentStep(0);
      setData(undefined);
      onFinish && onFinish();
      onClose && onClose({displayCancel: false});
    }
  };

  const openTuto = () => {};

  const close = () => {
    if (purpose === "onboarding" && currentStep === 4) {
      onFinish && onFinish();
      onClose && onClose({displayCancel: false});
    } else if (purpose === "rental_synchronization" && currentStep === 3) {
      setCurrentStep(0);
      setData(undefined);
      onFinish && onFinish();
      onClose && onClose({displayCancel: false});
    } else {
      setCurrentStep(0);
      setData(undefined);
      onClose && onClose({});
    }
  };

  const stepByPurpose = () => {
    switch (purpose) {
      case "onboarding":
        return [
          {
            title: t("Progression.bookingConnectStep1Title"),
            Node: (
              <BookingConnectStep1
                onNext={() => setCurrentStep((prev) => prev + 1)}
                setData={setData}
              />
            ),
          },
          {
            title: t("Progression.bookingConnectStep2Title"),
            Node: (
              <BookingConnectStep2
                properties={data?.properties}
                onNext={() => setCurrentStep((prev) => prev + 1)}
                setData={setData}
              />
            ),
          },
          {
            title: t("Progression.bookingConnectStep3Title"),
            Node: (
              <BookingConnectStep3
                properties={data?.propertiesSelected}
                roomsRates={data?.roomsRates}
                onNext={() => setCurrentStep((prev) => prev + 1)}
                setData={setData}
              />
            ),
          },
          {
            title: t("Progression.bookingConnectStep4Title"),
            Node: (
              <BookingConnectStep4
                properties={data?.propertiesSelected}
                importData={data?.importData}
                rentals={data?.rentals}
                setData={setData}
                onNext={() => setCurrentStep((prev) => prev + 1)}
              />
            ),
          },
        ];
      case "rental_synchronization":
        return [
          {
            title: t("Progression.bookingConnectStep1Title"),
            Node: (
              <BookingConnectStep1
                onNext={() => setCurrentStep((prev) => prev + 1)}
                setData={setData}
              />
            ),
          },
          {
            title: t("Progression.bookingSynchroStep2Title"),
            Node: (
              <BookingSynchroStep2
                properties={data?.properties}
                onNext={() => setCurrentStep((prev) => prev + 1)}
                setData={setData}
              />
            ),
          },
          {
            title: t("Progression.bookingConnectStep3Title"),
            Node: (
              <BookingSynchroStep3
                properties={data?.propertiesSelected}
                roomsRates={data?.roomsRates}
                onNext={() => setCurrentStep((prev) => prev + 1)}
                setData={setData}
                rental={rental}
              />
            ),
          },
        ];
      default:
        return [
          {
            title: t("Progression.bookingConnectStep1Title"),
            Node: (
              <BookingConnectStep1
                onNext={() => setCurrentStep((prev) => prev + 1)}
                setData={setData}
              />
            ),
          },
          {
            title: t("Progression.bookingConnectStep2Title"),
            Node: (
              <BookingConnectStep2
                properties={data?.properties}
                onNext={() => setCurrentStep((prev) => prev + 1)}
                setData={setData}
              />
            ),
          },
          {
            title: t("Progression.bookingConnectStep3Title"),
            Node: (
              <BookingConnectStep3
                properties={data?.propertiesSelected}
                roomsRates={data?.roomsRates}
                onNext={() => setCurrentStep((prev) => prev + 1)}
                setData={setData}
              />
            ),
          },
          {
            title: t("Progression.bookingConnectStep4Title"),
            Node: (
              <BookingConnectStep4
                properties={data?.propertiesSelected}
                importData={data?.importData}
                rentals={data?.rentals}
                setData={setData}
                onNext={() => setCurrentStep((prev) => prev + 1)}
              />
            ),
          },
        ];
    }
  };

  const displayEndButton =
    (purpose === "onboarding" && currentStep === 4 && data?.finishImport) ||
    (purpose === "rental_synchronization" &&
      currentStep === 3 &&
      data?.finishSynchro)
      ? true
      : false;

  const finishImport =
    currentStep === 4 && data?.finishImport && purpose === "onboarding";
  const finishSynchro =
    currentStep === 3 &&
    data?.finishSynchro &&
    purpose === "rental_synchronization";

  return (
    <RightModal
      isVisible={isVisible}
      onClose={close}
      title={
        purpose === "onboarding"
          ? t("Progression.bookingConnect")
          : t("Progression.bookingSynchro")
      }
    >
      <div className="flex flex-col w-full">
        <div className="flex-1 overflow-y-scroll">
          <CarouselWizard
            currentStepIndex={currentStep}
            onChangeStep={(value) => setCurrentStep(value)}
            classNames={{
              body: currentStep === 0 ? "" : "px-2 py-2",
            }}
            hideUnknown
            steps={stepByPurpose()}
          />
          {finishImport ? (
            <BookingConnectSuccessCard properties={data?.propertiesSelected} />
          ) : finishSynchro ? (
            <BookingSynchroSuccessCard />
          ) : null}
        </div>

        <div className="flex gap-3">
          {displayEndButton ? (
            <Button RightIcon={CheckIcon} onClick={handleSubmit}>
              {t("Global.ok")}
            </Button>
          ) : (
            <p className="text-low-contrast">
              {t("Progression.bookingTuto")}{" "}
              <span
                className="text-button-primary-default-top font-bold cursor-pointer"
                onClick={openTuto}
              >
                {t("Global.here")}
              </span>
            </p>
          )}
        </div>
      </div>
    </RightModal>
  );
};
