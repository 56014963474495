import React, {useEffect} from "react";
import CheckCircleGreenIcon from "../../../../../../assets/icons/check-circle-green.svg?react";
import TitleComponent from "../../../../Common/Template2.0/TitleComponent";
import ReservationCheckinsCard from "../../Common/ReservationCheckinsCard";
import ArrowRightWhiteIcon from "../../../../../../assets/icons/arrow-white-right.svg?react";
import useGuestWebsiteStore from "../../../../../../stores/guestWebsiteStore/useGuestWebsiteStore";
import ExtrasList from "./ExtrasList";
import useGuestWebsite from "../../../../../../hooks/useGuestWebsite";
import {formatExtrasOrder} from "../../../../../../helpers/extraOrderHelper";
import {useTranslation} from "react-i18next";

interface RentalDetailsExtrasPageProps {
  loading: boolean;
}

export interface ReservationStep4SendDataForm {
  rental_id: string;
  reservation_id: string;
  ordered_extras_ids: string;
  ordered_extras_quantities: string;
  email: string;
}

const RentalDetailsExtrasPage = ({}: RentalDetailsExtrasPageProps) => {
  const {t} = useTranslation();
  const {
    setRentalDetailTab,
    extrasOrder,
    setStep4Data: setStep4DataStore,
    rentalData,
    step3Data,
    step2Data,
    setExtrasDataSend,
    dataGuestWebsite,
  } = useGuestWebsiteStore() || {};
  const {
    reservationStep4,
    step4Data,
    errorWebsite,
    isLoadingReservationStep4: isLoading,
  } = useGuestWebsite();

  const {styles} = dataGuestWebsite || {};
  const {color_background} = styles || {};

  const onSubmitStep4 = async () => {
    if (extrasOrder) {
      const {ordered_extras_ids, ordered_extras_quantities} =
        formatExtrasOrder(extrasOrder);
      const data = {
        rental_id: String(rentalData?.id) ?? 0,
        reservation_id: String(step3Data?.reservation?.id) ?? 0,
        ordered_extras_ids,
        ordered_extras_quantities: ordered_extras_quantities,
        email: step2Data?.guest?.email ?? "",
      };
      setExtrasDataSend(data);
      await reservationStep4(data);
    }
  };

  useEffect(() => {
    if (step4Data) {
      setStep4DataStore(step4Data);
      setRentalDetailTab("extrasPayment");
    } else {
      setStep4DataStore(undefined);
    }
  }, [step4Data, errorWebsite]);

  return (
    <div
      className="p-4 md:px-contentWebsite"
      style={{backgroundColor: color_background}}
    >
      <div className="flex items-center p-4 py-6 rounded-lg bg-success/10 space-x-4">
        <div className="flex items-center">
          <CheckCircleGreenIcon width={40} height={40} />
        </div>
        <div className="flex flex-col space-y-2">
          <h3 className="text-base font-bold text-success">
            {t("GuestWebsite.Rental.Extras.success")}
          </h3>
          <p className="font-light text-success text-xs space-y-2">
            {t("GuestWebsite.Rental.Extras.thanksBlock", {
              rentalName: rentalData?.name,
            })}{" "}
            <br />
            {rentalData?.guest_page?.extras_enabled
              ? t("GuestWebsite.Rental.Extras.orderExtras")
              : null}
          </p>
        </div>
      </div>
      {rentalData?.guest_page?.extras_enabled ? (
        <div className="md:flex md:flex-col p-6 md:space-y-0 pl-2 md:gap-6">
          <div className="w-full md:min-w-1/2 ">
            <TitleComponent
              title={t("GuestWebsite.Rental.Extras.addExtras")}
              classNames={{
                divParent: "justify-start bg-none bg-transparent pb-0",
                divLabel: "pl-0",
              }}
            />
          </div>
          <div className="flex flex-col md:flex-row w-full md:gap-6 space-y-4">
            <div className="w-full md:min-w-1/2 order-1">
              <ExtrasList />
            </div>
            <div className="w-full md:w-[55%] order-2">
              <ReservationCheckinsCard
                buttonText={t('GuestWebsite.Rental.Extras.validCart')}
                showExtraSelectCard
                RightIcon={ArrowRightWhiteIcon}
                onClick={onSubmitStep4}
                disabledButton={!extrasOrder?.length || !extrasOrder}
                loading={isLoading}
              />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default RentalDetailsExtrasPage;
