import React from "react";
import { TableListItemProps } from "../Common/TableList/TableList.type";
import { RentalLightListItemResponse } from "../../types/GETTypes";
import { SimpleBox } from "../Modal/Airbnb/Connect/AirbnbConnectStepImport";

export const WebsiteRentalsItem: React.FC<TableListItemProps> = ({
  data,
  isSelected,
  disabled,
  onClick,
}) => {
  const rental: RentalLightListItemResponse & {
    payment_policy_stripe_account: string | null;
  } = data;

  return (
    <tr className="cursor-pointer" onClick={() => onClick(rental.id!)}>
      <td className="w-8 p-2">
        <SimpleBox value={isSelected ?? false} disabled={disabled} />
      </td>

      <td className="p-2">
        <div className="flex items-center gap-2">
          <img
            alt="Rental thumbnail"
            src={rental?.cover!}
            className="w-12 h-12 rounded-6px border-1 border-element-border-light"
          />

          <div className="flex flex-col">
            <p className="font-semibold text-high-contrast">{rental?.name}</p>
            <p className="font-light text-low-contrast">{rental?.address}</p>
            <p className="font-light text-low-contrast">
              {rental?.postal_code} {rental?.city} {rental?.country.name}
            </p>
          </div>
        </div>
      </td>
    </tr>
  );
};
