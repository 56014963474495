import React, {useEffect} from "react";
import {GuestWebsiteCommonProps} from "../GuestWebsiteLayout";
import {GuestWebsiteModel} from "../../../types/GETTypes";
import useGuestWebsiteStore from "../../../stores/guestWebsiteStore/useGuestWebsiteStore";
import GuestWebsiteTemplate2ContactComponent from "./Template2.0/GuestWebsiteTemplate2ContactComponent";
import useGuestWebsite from "../../../hooks/useGuestWebsite";

interface GuestWebsiteContactPageProps extends GuestWebsiteCommonProps {}

const RedirectToCorrectTemplate = (props: GuestWebsiteContactPageProps) => {
  const {templateGuestWebsite, dataGuestWebsite, setTabGuestWebsite} =
    useGuestWebsiteStore();
  const {isLoadingWebsite} = useGuestWebsite();
  const enabled = dataGuestWebsite?.content?.contact?.enabled;
  useEffect(() => {
    if (!enabled && !isLoadingWebsite) {
      setTabGuestWebsite("home");
    }
  }, []);
  switch (templateGuestWebsite) {
    case GuestWebsiteModel.MODEL_2_0:
      return <GuestWebsiteTemplate2ContactComponent {...props} />;
    case GuestWebsiteModel.MODEL_CHIC:
      return <></>;
    case GuestWebsiteModel.MODEL_MINIMAL:
      return <></>;
    default:
      return <GuestWebsiteTemplate2ContactComponent {...props} />;
  }
};

const GuestWebsiteContactPage = (props: GuestWebsiteContactPageProps) => {
  return <RedirectToCorrectTemplate {...props} />;
};

export default GuestWebsiteContactPage;
