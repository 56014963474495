import React from "react";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import OccupancyTableComponent from "./OccupancyTableComponent";
import {PlatformEnum} from "../../../enums/GETenums";
import {
  AnalyticsDetailedOccupancyStatistics,
  DistributionKeyEnum,
  StatsNameKeysEnum,
} from "../../../types/GETTypes";
import {capitalizeFirstLetter} from "../../../helpers/stringHelper";
import moment from "moment-timezone";
import {useTranslation} from "react-i18next";

interface LineChartProps {
  data: AnalyticsDetailedOccupancyStatistics | undefined;
  xAxisDataKey: string;
  lineDataKey: string;
  lineStrokeColor: string;
  heightChart?: number;
  openModalDetails: (
    statName: StatsNameKeysEnum,
    platform: DistributionKeyEnum
  ) => void;
  loading?: boolean;
  currency: string | undefined;
}

const LineChartComponent = ({
  data,
  loading,
  heightChart = 300,
  xAxisDataKey,
  lineDataKey,
  lineStrokeColor,
  openModalDetails,
  currency,
}: LineChartProps) => {
  const {t} = useTranslation()
  const lineChartData = data
    ? Object.entries(data?.monthly_occupancy.monthly_occupancy || {})
        .sort(([keyA], [keyB]) => parseInt(keyA, 10) - parseInt(keyB, 10))
        .map(([key, value]) => ({
          monthNb: key,
          month: capitalizeFirstLetter(moment(key, "MM").format("MMM")),
          occupancy: value?.toLocaleString('fr-FR'),
        }))
    : [];
  if (loading || !data) {
    return (
      <div className="pb-4">
        <div className="animate-pulse">
          {/* <!-- Container --> */}
          <div className="p-6 rounded-lg">
            {/* <!-- Header --> */}
            <div className="flex justify-between pb-8">
              <div className="h-5 bg-gray-200 rounded w-1/4"></div>
              <div className="h-4 bg-gray-200 rounded w-1/2 "></div>
            </div>
            {/* <!-- Chart --> */}
            <div className="h-44 bg-gray-200 rounded"></div>
          </div>
        </div>

        <div className="mt-4 mx-4 space-y-2 bg-element-border/25 mb-0">
          <div className="p-2 pb-0 animate-pulse">
            <div className="flex flex-col justify-between">
              <div className="flex justify-between py-4">
                <div className="flex items-center space-x-2">
                  <div className="flex items-center space-x-2 cursor-pointer">
                    <div className="h-4 w-28 bg-gray-200 rounded"></div>
                  </div>
                </div>
                <div className="flex items-center space-x-2 ">
                  <div className="h-4 w-28 bg-gray-200 rounded"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="p-2 pb-0 animate-pulse">
            <div className="flex flex-col justify-between">
              <div className="flex justify-between py-4">
                <div className="flex items-center space-x-2">
                  <div className="flex items-center space-x-2 cursor-pointer">
                    <div className="h-4 w-28 bg-gray-200 rounded"></div>
                  </div>
                </div>
                <div className="flex items-center space-x-2 ">
                  <div className="h-4 w-28 bg-gray-200 rounded"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-8 w-full border-1 border-element-border rounded-md">
      <div className="p-2 pt-4">
        {/* Occupancy Chart */}
        <div className="p-2 rounded-lg">
          <div className="flex justify-between pb-8">
            <h2 className="font-bold">{t('Analytics.occupancyRate')}</h2>
            <p className="text-low-contrast">
              {t('Analytics.occupancy')} :{" "}
              {data.monthly_occupancy.average_total_occupancy?.toLocaleString('fr-FR')}%
            </p>
          </div>

          <ResponsiveContainer width="100%" height={heightChart}>
            <ComposedChart data={lineChartData}>
              <CartesianGrid
                horizontal={true}
                vertical={false}
                stroke="#ccc"
                strokeWidth={0.5}
              />

              <XAxis
                dataKey={xAxisDataKey}
                tick={{fontSize: 12, fill: "#333"}}
                tickLine={false}
                axisLine={{stroke: "#ccc"}}
              />

              {/* Area for red background */}
              <Area
                type="monotone"
                dataKey={lineDataKey}
                stroke="none"
                fill="rgba(231, 76, 60, 0.2)"
                isAnimationActive={false}
              />
              <Tooltip
                itemStyle={{color: "black"}}
                content={({payload}) => {
                  if (payload && payload.length) {
                    const value = payload[0].value;
                    return (
                      <div className="bg-white p-3 border-[2px] border-element-border">
                        <p className="m-0">{`${t('Analytics.occupancyRate')} : ${value?.toLocaleString('fr-FR')}%`}</p>
                      </div>
                    );
                  }
                  return null;
                }}
              />
              <Line
                type="monotone"
                dataKey={lineDataKey}
                stroke={lineStrokeColor}
                strokeWidth={2}
                dot={{r: 4}}
                activeDot={{r: 6}}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
      <OccupancyTableComponent
        openModalDetails={openModalDetails}
        data={data}
        currency={currency}
      />
    </div>
  );
};

export default LineChartComponent;
