import React from "react";

import { WebsiteContentMenu } from "./WebsiteContentMenu";
import { useTranslation } from "react-i18next";
import { WebsiteResponse } from "../../types/GETTypes";
import { WebsiteInfosCard } from "./Cards/WebsiteInfosCard";
import { WebsiteLogosCard } from "./Cards/WebsiteLogosCard";
import { WebsiteRentalsCard } from "./Cards/WebsiteRentalsCard";
import { WebsiteMoreInfos1Card } from "./Cards/WebsiteMoreInfos1Card";
import { WebsiteTestimonalsCard } from "./Cards/WebsiteTestimonalsCard";
import { WebsiteMoreInfos2Card } from "./Cards/WebsiteMoreInfos2Card";
import { WebsiteFAQCard } from "./Cards/WebsiteFAQCard";
import { WebsiteAboutCard } from "./Cards/WebsiteAboutCard";
import { WebsiteServicesCard } from "./Cards/WebsiteServicesCard";
import { WebsiteContactCard } from "./Cards/WebsiteContactCard";

export const WebsiteContentCards: React.FC<{
  website: WebsiteResponse | undefined;
  onUpdateWebsite: (nextWebsite: WebsiteResponse) => void;
}> = ({ website, onUpdateWebsite }) => {
  const { t } = useTranslation();

  return (
    <div className="grid grid-cols-12 h-screen">
      {/* Menu Sticky */}
      <div className="col-span-2 pr-2 sticky top-16 self-start h-fit">
        <WebsiteContentMenu />
      </div>

      <div className="col-span-8 flex flex-col gap-3 overflow-y-auto h-[calc(100vh-170px)] no-scrollbar">
        <WebsiteInfosCard website={website} onUpdateWebsite={onUpdateWebsite} />

        <WebsiteLogosCard website={website} onUpdateWebsite={onUpdateWebsite} />

        <WebsiteRentalsCard
          website={website}
          onUpdateWebsite={onUpdateWebsite}
        />

        <WebsiteMoreInfos1Card
          website={website}
          onUpdateWebsite={onUpdateWebsite}
        />

        <WebsiteTestimonalsCard
          website={website}
          onUpdateWebsite={onUpdateWebsite}
        />

        <WebsiteMoreInfos2Card
          website={website}
          onUpdateWebsite={onUpdateWebsite}
        />

        <WebsiteFAQCard website={website} onUpdateWebsite={onUpdateWebsite} />

        <WebsiteAboutCard website={website} onUpdateWebsite={onUpdateWebsite} />

        <WebsiteServicesCard
          website={website}
          onUpdateWebsite={onUpdateWebsite}
        />

        <WebsiteContactCard
          website={website}
          onUpdateWebsite={onUpdateWebsite}
        />
      </div>
    </div>
  );
};
