import paths from "../../constants/paths";
import { get } from "../../helpers/APIHelper";
import {
  ManagementTaskListFilterPositions,
  TaskListModalFilters,
  TasksFilterStatusEnum,
} from "../../pages/ManagementTaskList/ManagementTaskListPage.type";
import { ValueType } from "../../types/commonTypes";
import { TaskListByDateResponse } from "../../types/GETTypes";

/**
 * Custom hook to manage task retrieval with success and error handling.
 *
 * @param {Object} params - The parameters for the hook.
 * @param {Function} params.onSuccess - Callback function to be called on successful result retrieval (with tasks and meta).
 * @param {Function} params.onError - Callback function to be called on error during task retrieval.
 * @param {Function} [params.onStart] - Optional callback function to be called before the task retrieval starts.
 * @param {Function} [params.onEnd] - Optional callback function to be called after the task retrieval ends.
 *
 * @returns {Object} - An object containing the `getAll` function to retrieve tasks.
 */
export const useTasks = ({
  onSuccess,
  onError,
  onStart,
  onEnd,
}: {
  onSuccess: (
    result?: {
      tasks: TaskListByDateResponse;
      total: number;
      total_canceled: number;
      total_completed: number;
      total_not_assigned: number;
      total_started: number;
      total_assigned: number;
      total_declined: number;
      total_filtered: number;
      current_page: number;
      last_page: number;
      per_page: number;
    },
    listFiltersModal?: TaskListModalFilters,
  ) => void;
  onError: (message: string | undefined) => void;
  onStart?: () => void;
  onEnd?: () => void;
}) => {
  /**
   * Retrieves all tasks based on the provided filters.
   *
   * @param {Object} params - The parameters for task retrieval.
   * @param {TasksFilterStatusEnum} [params.status=TasksFilterStatusEnum.all] - The status filter for tasks.
   * @param {moment.Moment} [params.from] - The start date for task retrieval.
   * @param {moment.Moment} [params.to] - The end date for task retrieval.
   *
   * @returns {Promise<void>} - A promise that resolves when the task retrieval is complete.
   */
  const getAll = async ({
    status = TasksFilterStatusEnum.all,
    from,
    to,
    operatorTeamIds,
    operatorIds,
    positions,
    selectedRentalIds,
  }: {
    status?: TasksFilterStatusEnum;
    from?: moment.Moment;
    to?: moment.Moment;
    operatorTeamIds?: ValueType[];
    operatorIds?: ValueType[];
    positions?: ManagementTaskListFilterPositions;
    selectedRentalIds?: Set<ValueType>;
  }) => {
    if (typeof onStart === "function") {
      onStart();
    }

    try {
      const URLParams = new URLSearchParams();

      if (status !== TasksFilterStatusEnum.all) {
        URLParams.append("status", status);
      }

      if (from) {
        URLParams.append("from", from.format("DD-MM-YYYY"));
      }

      if (to) {
        URLParams.append("to", to.format("DD-MM-YYYY"));
      }

      if (operatorTeamIds && operatorTeamIds.length > 0) {
        operatorTeamIds.forEach((id, index) =>
          URLParams.append(`operator_team_ids[${index}]`, id.toString()),
        );
      }

      if (operatorIds && operatorIds.length > 0) {
        operatorIds.forEach((id, index) =>
          URLParams.append(`operator_ids[${index}]`, id.toString()),
        );
      }

      if (positions) {
        console.log(positions);
        Object.entries(positions).forEach(([, position]) => {
          let nextPosition: number =
            Array.from(URLParams.keys()).filter((key) =>
              key.startsWith("postes"),
            ).length === 0
              ? 0
              : Array.from(URLParams.keys()).filter((key) =>
                  key.startsWith("postes"),
                ).length;
          if (position !== undefined) {
            URLParams.append(
              `postes[${nextPosition}]`,
              Number(position).toString(),
            );
          }
        });
      }

      if (selectedRentalIds && Array.from(selectedRentalIds).length > 0) {
        Array.from(selectedRentalIds).forEach((id, index) =>
          URLParams.append(`rentals[${index}]`, id.toString()),
        );
      }

      const URL = `${import.meta.env.VITE_API_URL}${
        paths.API.OPERATIONNAL_MANAGEMENT.TASKS.LISTS
      }?${URLParams.toString()}`;

      const res = await get(URL);

      if (res?.data?.success) {
        onSuccess(res.data.result, {
          operatorTeamIds,
          operatorIds,
          positions,
          selectedRentalIds,
        });
      } else {
        onError(res?.data?.message ?? res.message);
      }
    } catch (error: any) {
      onError(error.message);
    } finally {
      if (typeof onEnd === "function") {
        onEnd();
      }
    }
  };

  return { getAll };
};
