import {
  PaymentPolicyDepositPaymentOptionEnum,
  PaymentPolicyDepositPaymentTrigger,
  PaymentPolicyPayload,
  PaymentPolicyPaymentTriggerEnum,
  PaymentPolicyPaymentTriggerItem,
  PaymentPolicyRefundConditionEnum,
  PaymentPolicyResponse,
  PaymentPolicyValueTypeEnum,
} from "../../components/Payment/Payment.type";
import paths from "../../constants/paths";
import { del, get, post } from "../../helpers/APIHelper";
import {
  DirectAndWebsitePolicyResponse,
  RentalPoliciesResponse,
} from "../../types/GETTypes";
import { PaymentReservationDepositValueTypeEnum } from "../../enums/GETenums";

/**
 * This hook can be used to save payment policy in database
 * @param rentalId
 * @param paymentPolicy
 * @param {Function} onStart - Function to execute when the fetch starts.
 * @param {Function} onEnd - Function to execute when the fetch ends.
 * @param {Function} onSuccess - Function to execute when the fetch is successful.
 * @param {Function} onError - Function to execute when there is an error.
 */
export const useCreatePaymentPolicy = async (
  rentalId: string | undefined,
  paymentPolicy: PaymentPolicyPayload,
  onSuccess: (paymentPolicies: PaymentPolicyResponse[]) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void,
) => {
  if (rentalId && paymentPolicy) {
    try {
      if (typeof onStart === "function") {
        onStart();
      }
      const response = await post(
        `${import.meta.env.VITE_API_URL}${paths.API.RENTAL_UPDATE}/${rentalId}${
          paths.API.PAYMENTS.POLICIES
        }`,
        paymentPolicy,
      );
      if (response?.data?.success) {
        onSuccess(response?.data.result);
      } else {
        onError(response?.data?.message ?? response.message);
      }
    } catch (error: any) {
      onError(error.message);
    } finally {
      if (typeof onEnd === "function") {
        onEnd();
      }
    }
  }
};

/**
 * This hook can be used to update payment policy in database
 * @param rentalId
 * @param paymentPolicyId
 * @param paymentPolicy
 * @param {Function} onStart - Function to execute when the fetch starts.
 * @param {Function} onEnd - Function to execute when the fetch ends.
 * @param {Function} onSuccess - Function to execute when the fetch is successful.
 * @param {Function} onError - Function to execute when there is an error.
 */
export const useUpdatePaymentPolicy = async (
  rentalId: string | undefined,
  paymentPolicyId: number | undefined,
  paymentPolicy: PaymentPolicyPayload,
  onSuccess: (paymentPolicies: PaymentPolicyResponse[]) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void,
) => {
  if (rentalId && paymentPolicyId && paymentPolicy) {
    if (typeof onStart === "function") {
      onStart();
    }

    try {
      const response = await post(
        `${import.meta.env.VITE_API_URL}${paths.API.RENTAL_UPDATE}/${rentalId}${
          paths.API.PAYMENTS.POLICIES
        }/${paymentPolicyId}`,
        {
          _method: "PUT",
          ...paymentPolicy,
        },
      );

      if (response?.data?.success) {
        onSuccess(response?.data.result);
      } else {
        onError(response?.response?.data.message ?? response?.message);
      }
    } catch (error: any) {
      onError(error.message);
    } finally {
      if (typeof onEnd === "function") {
        onEnd();
      }
    }
  }
};

/**
 * This hook can be used to delete payment policy in database
 * @param rentalId
 * @param paymentPolicyId
 * @param {Function} onStart - Function to execute when the fetch starts.
 * @param {Function} onEnd - Function to execute when the fetch ends.
 * @param {Function} onSuccess - Function to execute when the fetch is successful.
 * @param {Function} onError - Function to execute when there is an error.
 */
export const useDeletePaymentPolicy = async (
  rentalId: string | undefined,
  paymentPolicyId: number | undefined,
  onSuccess: (paymentPolicies: PaymentPolicyResponse[]) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void,
) => {
  if (typeof onStart === "function") {
    onStart();
  }

  if (paymentPolicyId) {
    try {
      const response = await del(
        `${import.meta.env.VITE_API_URL}${paths.API.RENTAL_UPDATE}/${rentalId}${
          paths.API.PAYMENTS.POLICIES
        }/${paymentPolicyId}`,
      );

      if (response?.data?.success) {
        onSuccess(response?.data.result);
      } else {
        onError(response?.data.message ?? response.message);
      }
    } catch (error: any) {
      onError(error.message);
    } finally {
      if (typeof onEnd === "function") {
        onEnd();
      }
    }
  }
};

export interface PaymentInterface {
  payment_type: "percent";
  payment_amount: number;
  payment_trigger: PaymentPolicyPaymentTriggerEnum;
  payment_method: string;
}

export const usePoliciesPlatforms = () => {
  const updateOrCreateBookingPolicy = async (
    rentalId: number,
    isUnique: boolean,
    payments: PaymentInterface[] | null,
    paymentTrigger: string | null,
    paymentType: string | null,
    isRefund: boolean,
    isRefundNotAllFiveDays: boolean | null,
    isRefundNotAllOneDay: boolean | null,
    isRefundAll14DaysOr48Hours: boolean | null,
    cautionManagedByPlatform: boolean,
    cautionPaymentType: string | null,
    cautionAmount: number | null,
    cautionStripeAccountId: number | null,
    cautionDaysBeforeCheckinToPay: number | null,
    cautionRequired: boolean,
  ) => {
    const url = `${import.meta.env.VITE_API_URL}${paths.API.POLICIES.UPDATE_OR_CREATE_BOOKING_POLICY_RENTAL(rentalId)}`;

    const bookingPolicyData = {
      is_unique: isUnique,
      payments: payments,
      payment_trigger: paymentTrigger,
      payment_type: paymentType,
      is_refund: isRefund,
      is_refund_not_all_five_days: isRefundNotAllFiveDays,
      is_refund_not_all_one_day: isRefundNotAllOneDay,
      is_refund_all_14_days_or_48_hours: isRefundAll14DaysOr48Hours,
      caution_managed_by_platform: cautionManagedByPlatform,
      caution_payment_type: cautionPaymentType,
      caution_amount: cautionAmount,
      caution_stripe_account_id: cautionStripeAccountId,
      caution_days_before_checkin_to_pay: cautionDaysBeforeCheckinToPay,
      caution_required: cautionRequired,
    };

    const response = await post(url, bookingPolicyData);

    if (response.status !== 200) {
      throw new Error(response?.response?.data?.message ?? response.message);
    }
  };

  const updateOrCreateDirectPolicy = async (
    rentalId: number,
    isUnique: boolean,
    payments: PaymentInterface[] | null,
    paymentTrigger: string | null,
    paymentType: string | null,
    isRefund: boolean,
    cautionPaymentType: string | null,
    cautionAmount: number | null,
    cautionStripeAccountId: number | null,
    cautionDaysBeforeCheckinToPay: number | null,
    cautionRequired: boolean,
  ) => {
    const url = `${import.meta.env.VITE_API_URL}${paths.API.POLICIES.UPDATE_OR_CREATE_DIRECT_POLICY_RENTAL(rentalId)}`;

    const bookingPolicyData = {
      is_unique: isUnique,
      payments: payments,
      payment_trigger: paymentTrigger,
      payment_type: paymentType,
      is_refund: isRefund,
      caution_payment_type: cautionPaymentType,
      caution_amount: cautionAmount,
      caution_stripe_account_id: cautionStripeAccountId,
      caution_days_before_checkin_to_pay: cautionDaysBeforeCheckinToPay,
      caution_required: cautionRequired,
    };

    const response = await post(url, bookingPolicyData);

    if (response.status !== 200) {
      throw new Error(response?.response?.data?.message ?? response.message);
    }
  };

  const getPolicies = async (
    rentalId: string | number,
  ): Promise<RentalPoliciesResponse> => {
    const url = `${import.meta.env.VITE_API_URL}${paths.API.POLICIES.GET_POLICIES_RENTALS(rentalId)}`;

    const response = await get(url);

    if (response.status === 200 && response?.data?.result) {
      return response.data.result;
    } else {
      throw new Error(response?.response?.data?.message ?? response.message);
    }
  };

  const refreshAirbnb = async (rentalId: string | number): Promise<boolean> => {
    const url = `${import.meta.env.VITE_API_URL}/${paths.API.POLICIES.REFRESH_AIRBNB(rentalId)}`;

    const response = await post(url);

    if (response.status === 200) {
      return true;
    } else {
      throw new Error(response?.response?.data?.message ?? response.message);
    }
  };

  const refreshBooking = async (
    rentalId: string | number,
  ): Promise<boolean> => {
    const url = `${import.meta.env.VITE_API_URL}/${paths.API.POLICIES.REFRESH_BOOKING(rentalId)}`;

    const response = await post(url);

    if (response.status === 200) {
      return true;
    } else {
      throw new Error(response?.response?.data?.message ?? response.message);
    }
  };

  const updateDirectPolicy = async (
    rentalId: string,
    name: string,
    description: string,
    is_favorite_policy: boolean,
    is_single_payment: boolean,
    is_refundable: boolean,
    refund_value: number | null,
    refund_condition: PaymentPolicyPaymentTriggerEnum | null,
    is_deposit_required: boolean | null,
    deposit_value_type: string | null,
    deposit_value: number | null,
    deposit_payment_option: PaymentPolicyDepositPaymentOptionEnum | null,
    deposit_payment_account_id: number | null,
    deposit_payment_time_delay: number | null,
    deposit_payment_trigger: PaymentPolicyDepositPaymentTrigger | null,
    payment_value_type_items: string | null,
    payment_value_items: string | null,
    payment_trigger_items: string | null,
    payment_specific_date: string | null,
    payment_account_id_items: string | null,
  ): Promise<DirectAndWebsitePolicyResponse> => {
    const url = `${import.meta.env.VITE_API_URL}/${paths.API.POLICIES.UPDATE_DIRECT(rentalId)}`;

    const response = await post(url, {
      name: name,
      description: description,
      is_favorite_policy: is_favorite_policy,
      is_single_payment: is_single_payment,
      is_refundable: is_refundable,
      refund_value: refund_value,
      refund_condition: refund_condition,
      is_deposit_required: is_deposit_required,
      deposit_value: deposit_value,
      deposit_value_type: deposit_value_type,
      deposit_payment_option: deposit_payment_option,
      deposit_payment_account_id: deposit_payment_account_id,
      deposit_payment_days_delay: deposit_payment_time_delay,
      deposit_payment_trigger: deposit_payment_trigger,
      payment_value_type_items: payment_value_type_items,
      payment_value_items: payment_value_items,
      payment_trigger_items: payment_trigger_items,
      payment_specific_date: payment_specific_date,
      payment_account_id_items: payment_account_id_items,
    });

    if (response.status === 200 && response?.data?.result) {
      return response.data.result;
    } else {
      throw new Error(response?.response?.data?.message ?? response.message);
    }
  };

  return {
    updateOrCreateBookingPolicy,
    updateOrCreateDirectPolicy,
    getPolicies,
    updateDirectPolicy,
    refreshAirbnb,
    refreshBooking,
  };
};
