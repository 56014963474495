import React from "react";
import { useTranslation } from "react-i18next";

export const WebsiteListHeader: React.FC<{}> = ({}) => {
  const { t } = useTranslation();

  return (
    <tr className="table-header-group w-full bg-element-background border-b-1 border-element-border">
      {/* Site de réservation */}
      <th className="w-2/12 px-2 py-3 text-sm ps-4 pe-2 rounded-tl-6px">
        <div className={`flex flex-row items-center`}>
          <p>{t("Website.List.website")}</p>
        </div>
      </th>

      {/* Adresse web */}
      <th className="w-1/12 px-2 py-3 text-sm">
        <div className={`flex flex-row items-center`}>
          <p>{t("Website.List.URL")}</p>
        </div>
      </th>

      {/* Modèle */}
      <th className="w-1/12 px-2 py-3 text-sm">
        <div className={`flex flex-row items-center`}>
          <p>{t("Website.List.template")}</p>
        </div>
      </th>

      {/* Date de création */}
      <th className="w-1/12 px-2 py-3 text-sm">
        <div className={`flex flex-row items-center justify-end`}>
          <p>{t("Website.List.creationDate")}</p>
        </div>
      </th>
    </tr>
  );
};
