import { t } from "i18next";
import React from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { Trans } from "react-i18next";
import { RequiredFields, ValueType } from "../../../../../../types/commonTypes";
import { RentalLightListItemResponse } from "../../../../../../types/GETTypes";
import { MultiSelect } from "../../../../../Common/MultiSelect/MultiSelect";
import { DIRECT_RESERVATIONS_STEP } from "../ConfigDirectReservations.type";

type ProgressionConfigDirectReservationRentalChoiceStepProps = {
  loading: boolean;
  form: UseFormReturn<ProgressionConfigDirectReservationRentalChoiceStepForm>;
  onRentalsIsLoaded: (rentals: RentalLightListItemResponse[]) => void;
  onNext: (step: DIRECT_RESERVATIONS_STEP) => void;
};

export type ProgressionConfigDirectReservationRentalChoiceStepForm = {
  selectedRentals: ValueType[];
};

export const ProgressionConfigDirectReservationRentalChoiceStep: React.FC<
  ProgressionConfigDirectReservationRentalChoiceStepProps
> = ({ loading, form, onRentalsIsLoaded, onNext }) => {
  const requiredFields: RequiredFields<ProgressionConfigDirectReservationRentalChoiceStepForm> =
    {
      selectedRentals: true,
    };

  form.watch();

  return (
    <div className="flex flex-col gap-y-3">
      <p className="text-md text-low-contrast">
        <Trans
          i18nKey="Progression.StepConfigDirectReservations.Modal.Steps.ChoiceRental.description"
          components={{
            strong: <span className="font-bold"></span>,
          }}
        />
      </p>

      <Controller
        control={form.control}
        name="selectedRentals"
        rules={{
          required: {
            value: requiredFields.selectedRentals,
            message: t(
              "Progression.StepConfigDirectReservations.Modal.Steps.ChoiceRental.error"
            ),
          },
        }}
        render={({ field: { value, onChange } }) => {
          const handleChange = (id: ValueType) => {
            const index = value.findIndex((v) => v === id);
            if (index === -1) onChange([...value, id]);
            else onChange(value.filter((v) => v !== id));
          };

          return (
            <MultiSelect
              loading={loading}
              options={{
                withInfoText: false,
                withSubmitButton: true,
                i18nKeySubmitButtonText: "Global.next",
              }}
              selectedRentals={value}
              onRentalsIsLoaded={(rentals) => onRentalsIsLoaded(rentals)}
              onSelectRental={handleChange}
              onNext={() => onNext(DIRECT_RESERVATIONS_STEP.DEPOSIT_SETTINGS)}
              error={form.formState.errors.selectedRentals?.message}
            />
          );
        }}
      />
    </div>
  );
};
