import React from "react";
import { useTranslation } from "react-i18next";
import CheckGreenIcon from "../../assets/icons/check-green.svg?react";

type PaymentReservationSuccessProps = {
  amount: number | string | undefined;
};

export const PaymentReservationSuccess: React.FC<
  PaymentReservationSuccessProps
> = ({ amount }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center w-full p-6 space-y-4 rounded-lg bg-element-background">
      <CheckGreenIcon width={25} height={25} />
      <h3 className="text-xl font-bold text-success">
        {t("Payments.PaymentReservation.successPaymentValidated")}
      </h3>
      <p className="font-light text-low-contrast text-md">
        {t("Payments.PaymentReservation.successInfoPaymentValidated", {
          amount: amount,
          currency: t("Global.currencySymbol"),
        })}
      </p>
    </div>
  );
};
