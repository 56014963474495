import React, { useState } from "react";
import LeftArrowIcon from "../../assets/icons/arrow-left.svg?react";
import ArrowRightWhiteIcon from "../../assets/icons/arrow-right-white.svg?react";
import EditIcon from "../../assets/icons/edit.svg?react";
import { Button } from "../Common/Button/Button";
import { useTranslation } from "react-i18next";
import { AddWebsiteLegalModal } from "./AddWebsiteLegalModal";
import { useModal } from "../../hooks/useModal";
import { AddWebsiteCGVModal } from "./AddWebsiteCGVModal";
import { AddWebsitePoliciesModal } from "./AddWebsitePoliciesModal";
import { post } from "../../helpers/APIHelper";
import paths from "../../constants/paths";
import { WebsiteAddStepDataType } from "../../pages/AddWebsite/AddWebsitePage";
import { ErrorMessage } from "../Common/ErrorMessage/ErrorMessage";

export const AddWebsiteLegal = ({
  stepData,
  onClose,
  onNextStep,
  onPreviousStep,
}: {
  stepData: WebsiteAddStepDataType;
  onClose: () => void;
  onNextStep: () => void;
  onPreviousStep: () => void;
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [APIError, setAPIError] = useState<string | undefined>(undefined);

  const legalsModal = useModal<string>();
  const [legals, setLegals] =
    useState<string>(`Objet des Conditions Générales de vente
SuperHote édite et exploite un Logiciel de gestion, en mode SaaS, destiné à gérer et automatiser des locations courte durée.
Le Client qui dispose de l’ensemble de ses droits utiles à l’utilisation du Logiciel et après avoir étudié ce dernier et ses fonctionnalités considère que le Logiciel répond à ses besoins.
Les Conditions Générales ont ainsi pour objet de définir les conditions dans lesquelles SuperHote met à disposition le Logiciel sous réserve de la création préalable d’un Compte et de la souscription d’un Abonnement payant.
Ceci étant précisé, il a été convenu ce qui suit.

Définitions
Dans le cadre des Conditions Générales, en ce compris son préambule, les Parties conviennent que les termes et expressions commençant par une majuscule, qu’elles soient employées au singulier ou au pluriel selon le contexte de leur emploi, auront la signification suivante :

Abonnement : désigne un Abonnement payant souscrit pour une durée d’un mois renouvelable tacitement permettant au Client d’accéder, pendant la durée dudit Abonnement, à l’ensemble des Services.

Applications du Client : désignent l’ensemble des applications, logiciels, site Internet et services avec lesquels le Logiciel peut être interfacé et dont la liste exhaustive figure en Annexe 1.

Bien Immobilier : désigne le(s) immeuble(s) (appartement, maison, etc.) pour le(s)quel(s) le Client dispose de la propriété et/ou la jouissance ou encore d’un mandat lui permettant de le(s) proposer en location au public, et pour lequel le Logiciel est utilisé afin de gérer la réservation et la location de courte durée.

Client : désigne toute personne physique majeure et capable, y compris représentant dirigeant, préposé, etc. d’une personne morale agissant pour son compte, souscrivant pour ce faire l’ouverture d’un Compte et d’un Abonnement et n’étant soumise à aucune interdiction et/ou restriction légale, réglementaire ou conventionnelle l’empêchant d’utiliser le Logiciel et les Services notamment à des fins de gestions de location du Bien Immobilier et/ou en vue de son interfaçage avec les Applications du Client et ayant rempli, le cas échéant, l’ensemble des formalités légales, réglementaires et conventionnelles pour ce faire, ;

Compte : désigne l’espace personnel d’un Utilisateur uniquement accessible avec des Identifiants lui permettant d’accéder et de bénéficier des Services sous réserve des droits qui lui ont été accordés par le Client.

Conditions Générales : désigne le présent document, en ce compris son préambule et ses annexes, ainsi que ses versions successives.

Documentation : désigne la documentation technique et/ou fonctionnelle d’utilisation du Logiciel mise à disposition du Client dans le cadre des Conditions Générales, le cas échéant, sous forme de vidéos.

Données Personnelles : désignent les données à caractère personnel au sens de la Réglementation sur les Données Personnelles.

Droits Spécifiques : désignent les droits accordés aux personnes concernées par la Réglementation sur les Données Personnelles, et notamment les droits de/d’/à (i) accès, (ii) rectification, (iii) l’effacement, (iv) limitation du traitement, (v) portabilité, (vi) opposition, (vii) directives post-mortem et (ix) ne pas faire l’objet d’une décision individuelle automatisée (y compris le profilage) ;

Force Majeure : désigne un événement imprévisible et irrésistible qualifié de force majeure au sens des dispositions de l’article 1218 du Code civil et de la jurisprudence de la Cour de cassation.

Jour(s) Ouvré(s) : désigne un jour compris entre le Lundi et le Vendredi de 9h à 18h (heures françaises), hors jours fériés en France métropolitaine.

Identifiants : désigne les identifiant et mot de passe créés par le Client et Utilisateurs dans les conditions fixées aux présentes et permettant d’utiliser et d’accéder au Logiciel et de bénéficier des Services.`);

  const cgvModal = useModal<string>();
  const [cgv, setCGV] =
    useState<string>(`Objet des Conditions Générales de vente
SuperHote édite et exploite un Logiciel de gestion, en mode SaaS, destiné à gérer et automatiser des locations courte durée.
Le Client qui dispose de l’ensemble de ses droits utiles à l’utilisation du Logiciel et après avoir étudié ce dernier et ses fonctionnalités considère que le Logiciel répond à ses besoins.
Les Conditions Générales ont ainsi pour objet de définir les conditions dans lesquelles SuperHote met à disposition le Logiciel sous réserve de la création préalable d’un Compte et de la souscription d’un Abonnement payant.
Ceci étant précisé, il a été convenu ce qui suit.

Définitions
Dans le cadre des Conditions Générales, en ce compris son préambule, les Parties conviennent que les termes et expressions commençant par une majuscule, qu’elles soient employées au singulier ou au pluriel selon le contexte de leur emploi, auront la signification suivante :

Abonnement : désigne un Abonnement payant souscrit pour une durée d’un mois renouvelable tacitement permettant au Client d’accéder, pendant la durée dudit Abonnement, à l’ensemble des Services.

Applications du Client : désignent l’ensemble des applications, logiciels, site Internet et services avec lesquels le Logiciel peut être interfacé et dont la liste exhaustive figure en Annexe 1.

Bien Immobilier : désigne le(s) immeuble(s) (appartement, maison, etc.) pour le(s)quel(s) le Client dispose de la propriété et/ou la jouissance ou encore d’un mandat lui permettant de le(s) proposer en location au public, et pour lequel le Logiciel est utilisé afin de gérer la réservation et la location de courte durée.

Client : désigne toute personne physique majeure et capable, y compris représentant dirigeant, préposé, etc. d’une personne morale agissant pour son compte, souscrivant pour ce faire l’ouverture d’un Compte et d’un Abonnement et n’étant soumise à aucune interdiction et/ou restriction légale, réglementaire ou conventionnelle l’empêchant d’utiliser le Logiciel et les Services notamment à des fins de gestions de location du Bien Immobilier et/ou en vue de son interfaçage avec les Applications du Client et ayant rempli, le cas échéant, l’ensemble des formalités légales, réglementaires et conventionnelles pour ce faire, ;

Compte : désigne l’espace personnel d’un Utilisateur uniquement accessible avec des Identifiants lui permettant d’accéder et de bénéficier des Services sous réserve des droits qui lui ont été accordés par le Client.

Conditions Générales : désigne le présent document, en ce compris son préambule et ses annexes, ainsi que ses versions successives.

Documentation : désigne la documentation technique et/ou fonctionnelle d’utilisation du Logiciel mise à disposition du Client dans le cadre des Conditions Générales, le cas échéant, sous forme de vidéos.

Données Personnelles : désignent les données à caractère personnel au sens de la Réglementation sur les Données Personnelles.

Droits Spécifiques : désignent les droits accordés aux personnes concernées par la Réglementation sur les Données Personnelles, et notamment les droits de/d’/à (i) accès, (ii) rectification, (iii) l’effacement, (iv) limitation du traitement, (v) portabilité, (vi) opposition, (vii) directives post-mortem et (ix) ne pas faire l’objet d’une décision individuelle automatisée (y compris le profilage) ;

Force Majeure : désigne un événement imprévisible et irrésistible qualifié de force majeure au sens des dispositions de l’article 1218 du Code civil et de la jurisprudence de la Cour de cassation.

Jour(s) Ouvré(s) : désigne un jour compris entre le Lundi et le Vendredi de 9h à 18h (heures françaises), hors jours fériés en France métropolitaine.

Identifiants : désigne les identifiant et mot de passe créés par le Client et Utilisateurs dans les conditions fixées aux présentes et permettant d’utiliser et d’accéder au Logiciel et de bénéficier des Services.`);

  const policiesModal = useModal<string>();
  const [policies, setPolicies] =
    useState<string>(`Objet des Conditions Générales de vente
SuperHote édite et exploite un Logiciel de gestion, en mode SaaS, destiné à gérer et automatiser des locations courte durée.
Le Client qui dispose de l’ensemble de ses droits utiles à l’utilisation du Logiciel et après avoir étudié ce dernier et ses fonctionnalités considère que le Logiciel répond à ses besoins.
Les Conditions Générales ont ainsi pour objet de définir les conditions dans lesquelles SuperHote met à disposition le Logiciel sous réserve de la création préalable d’un Compte et de la souscription d’un Abonnement payant.
Ceci étant précisé, il a été convenu ce qui suit.

Définitions
Dans le cadre des Conditions Générales, en ce compris son préambule, les Parties conviennent que les termes et expressions commençant par une majuscule, qu’elles soient employées au singulier ou au pluriel selon le contexte de leur emploi, auront la signification suivante :

Abonnement : désigne un Abonnement payant souscrit pour une durée d’un mois renouvelable tacitement permettant au Client d’accéder, pendant la durée dudit Abonnement, à l’ensemble des Services.

Applications du Client : désignent l’ensemble des applications, logiciels, site Internet et services avec lesquels le Logiciel peut être interfacé et dont la liste exhaustive figure en Annexe 1.

Bien Immobilier : désigne le(s) immeuble(s) (appartement, maison, etc.) pour le(s)quel(s) le Client dispose de la propriété et/ou la jouissance ou encore d’un mandat lui permettant de le(s) proposer en location au public, et pour lequel le Logiciel est utilisé afin de gérer la réservation et la location de courte durée.

Client : désigne toute personne physique majeure et capable, y compris représentant dirigeant, préposé, etc. d’une personne morale agissant pour son compte, souscrivant pour ce faire l’ouverture d’un Compte et d’un Abonnement et n’étant soumise à aucune interdiction et/ou restriction légale, réglementaire ou conventionnelle l’empêchant d’utiliser le Logiciel et les Services notamment à des fins de gestions de location du Bien Immobilier et/ou en vue de son interfaçage avec les Applications du Client et ayant rempli, le cas échéant, l’ensemble des formalités légales, réglementaires et conventionnelles pour ce faire, ;

Compte : désigne l’espace personnel d’un Utilisateur uniquement accessible avec des Identifiants lui permettant d’accéder et de bénéficier des Services sous réserve des droits qui lui ont été accordés par le Client.

Conditions Générales : désigne le présent document, en ce compris son préambule et ses annexes, ainsi que ses versions successives.

Documentation : désigne la documentation technique et/ou fonctionnelle d’utilisation du Logiciel mise à disposition du Client dans le cadre des Conditions Générales, le cas échéant, sous forme de vidéos.

Données Personnelles : désignent les données à caractère personnel au sens de la Réglementation sur les Données Personnelles.

Droits Spécifiques : désignent les droits accordés aux personnes concernées par la Réglementation sur les Données Personnelles, et notamment les droits de/d’/à (i) accès, (ii) rectification, (iii) l’effacement, (iv) limitation du traitement, (v) portabilité, (vi) opposition, (vii) directives post-mortem et (ix) ne pas faire l’objet d’une décision individuelle automatisée (y compris le profilage) ;

Force Majeure : désigne un événement imprévisible et irrésistible qualifié de force majeure au sens des dispositions de l’article 1218 du Code civil et de la jurisprudence de la Cour de cassation.

Jour(s) Ouvré(s) : désigne un jour compris entre le Lundi et le Vendredi de 9h à 18h (heures françaises), hors jours fériés en France métropolitaine.

Identifiants : désigne les identifiant et mot de passe créés par le Client et Utilisateurs dans les conditions fixées aux présentes et permettant d’utiliser et d’accéder au Logiciel et de bénéficier des Services.`);

  const handleSave = async () => {
    setAPIError(undefined);
    setLoading(true);

    const formData = new FormData();
    formData.append("title", stepData.general?.title ?? "");
    formData.append("subtitle", stepData.general?.subTitle ?? "");
    formData.append(
      "rental_ids",
      stepData?.rentals?.map((r) => r.id).join(",") ?? ""
    );
    formData.append("model", stepData?.template ?? "");
    formData.append("url", stepData.url ?? "");
    formData.append("legal_terms", legals);
    formData.append("cgv", cgv);
    formData.append("privacy_policy", policies);

    if (stepData.general?.background) {
      formData.append("photo", stepData.general.background);
    }

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.WEBSITE.ADD.CREATE}`,
      formData
    );

    setLoading(false);

    if (!res.data?.success) {
      setAPIError(res.response?.data?.message);
      return;
    }
  };

  return (
    <>
      <AddWebsiteLegalModal
        isVisible={legalsModal.isVisible}
        onClose={legalsModal.close}
        legals={legalsModal.data}
        onSave={setLegals}
      />

      <AddWebsiteCGVModal
        isVisible={cgvModal.isVisible}
        onClose={cgvModal.close}
        cgv={cgvModal.data}
        onSave={setCGV}
      />

      <AddWebsitePoliciesModal
        isVisible={policiesModal.isVisible}
        onClose={policiesModal.close}
        policies={policiesModal.data}
        onSave={setPolicies}
      />

      <div>
        <div className="w-52">
          <Button
            type="secondary"
            LeftIcon={LeftArrowIcon}
            onClick={onPreviousStep}
          >
            {t("Global.previousStep")}
          </Button>
        </div>

        <p className="text-lg font-semibold text-high-contrast mt-8">
          {t("Website.Add.Legal.title")}
        </p>

        <p className="font-light text-low-contrast mt-4">
          {t("Website.Add.Legal.description")}
        </p>

        <div className="h-10" />

        <div className="h-24 rounded-6px bg-element-background border-1 border-element-border p-4 flex items-center justify-between my-4">
          <p className="font-semibold text-high-contrast">
            {t("Website.Add.Legal.legals")}
          </p>

          <div>
            <Button
              type="secondary"
              RightIcon={EditIcon}
              onClick={() => legalsModal.open(legals)}
            >
              {t("Global.edit")}
            </Button>
          </div>
        </div>

        <div className="h-24 rounded-6px bg-element-background border-1 border-element-border p-4 flex items-center justify-between my-4">
          <p className="font-semibold text-high-contrast">
            {t("Website.Add.Legal.cgv")}
          </p>

          <div>
            <Button
              type="secondary"
              RightIcon={EditIcon}
              onClick={() => cgvModal.open(cgv)}
            >
              {t("Global.edit")}
            </Button>
          </div>
        </div>

        <div className="h-24 rounded-6px bg-element-background border-1 border-element-border p-4 flex items-center justify-between my-4">
          <p className="font-semibold text-high-contrast">
            {t("Website.Add.Legal.policies")}
          </p>

          <div>
            <Button
              type="secondary"
              RightIcon={EditIcon}
              onClick={() => policiesModal.open(policies)}
            >
              {t("Global.edit")}
            </Button>
          </div>
        </div>

        <ErrorMessage>{APIError}</ErrorMessage>
      </div>

      <div className="flex gap-3 justify-end">
        <div>
          <Button type="secondary" onClick={onClose} disabled={loading}>
            {t("Global.cancel")}
          </Button>
        </div>
        <div>
          <Button
            RightIcon={ArrowRightWhiteIcon}
            onClick={handleSave}
            loading={loading}
          >
            {t("Global.done")}
          </Button>
        </div>
      </div>
    </>
  );
};
