import React from "react";

export const WebsiteListItemSkeleton: React.FC<{}> = ({}) => {
  return (
    <tr className="cursor-pointer hover:bg-element-background/40 animate-pulse">
      {/* Site de réservation */}
      <td className="flex flex-row items-center px-4 py-2 space-x-4 text-sm border-t-1 border-element-border">
        <div className="flex gap-2 items-center">
          <div className="size-12 rounded-6px border-1 border-element-border bg-element-background" />
          <div className="flex flex-col gap-1">
            <p className="text-high-contrast font-semibold w-52 h-2 bg-slate-300 rounded"></p>
            <p className="text-low-contrast font-light w-40 h-2 bg-slate-200 rounded"></p>
          </div>
        </div>
      </td>

      {/* Adresse web */}
      <td className="py-2 text-sm align-middle border-t-1 border-element-border">
        <div className="inline-flex bg-element-background border-1 rounded-6px p-1">
          <p className="text-center font-light text-low-contrast h-2 w-72 rounded bg-slate-200"></p>
        </div>
      </td>

      {/* Modèle */}
      <td className="py-2 text-sm align-center border-t-1 border-element-border text-low-contrast">
        <div className="bg-element-background inline-flex p-2 rounded-6px gap-2 items-center">
          <div className="size-8 rounded-6px border-1 border-element-border bg-slate-200" />
          <p className="text-low-contrast font-semibold h-2 rounded bg-slate-200 w-40"></p>
        </div>
      </td>

      {/* Date de création */}
      <td className="py-2 text-sm align-middle border-t-1 border-element-border">
        <p className="text-low-contrast font-light text-right pr-2 rounded h-2 w-52 bg-slate-200"></p>
      </td>
    </tr>
  );
};
