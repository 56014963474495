import React from "react";
import StatsItem from "../../components/Statistics/General/StatsItem";
import ColumnVerticalIcon from "../../assets/icons/columns-vertical.svg?react";
import {debounce} from "lodash";
import {DateFilter} from "../../components/Common/DateFilter/DateFilter";
import {DateRangePreset} from "../../components/Common/DateFilter/DateFilter.type";
import {
  CalendarInputValue,
  CalendarInputValueRange,
} from "../../components/Common/CalendarInput/CalendarInput.type";
import {useHandleChangeDates} from "../../components/Common/DateFilter/DateFilter.hooks";
import {AnalyticsPageType} from "../../types/GETTypes";
import {MultiSelectRentals} from "../Common/MultiSelectRentals/MultiSelectRentals";
import UploadIcon from "../../assets/icons/upload-white.svg?react";
import CalendarIcon from "../../assets/icons/calendar.svg?react";
import {Button} from "../Common/Button/Button";
import {useTranslation} from "react-i18next";
import {ValueType} from "../../types/commonTypes";
import {CalendarDay} from "../../pages/CalendarList/CalendarListPage.type";
import {GroupButton} from "../Common/GroupButton/GroupButton";
import {InputSelect} from "../Common/InputSelect/InputSelect";
import {getYearList} from "../../helpers/dateHelper";
import moment from "moment";
import {UseModal} from "../../hooks/useModal";

export interface HeaderProps {
  page: AnalyticsPageType;
  handleFilterRentals?: (ids: ValueType[]) => void;
  onChangeDates?: (dates: CalendarInputValueRange) => void;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  setError?: React.Dispatch<React.SetStateAction<string | undefined>>;
  selectedRentals?: ValueType[];
  modalExport?: UseModal<unknown>;
  currentPeriod?: ValueType;
  handleChangePeriod?: (value: ValueType) => void;
  rentals?: any;
  currentRental?: any;
  handleChangeRental?: any;
  handleContender?: () => void;
  handleModalTax?: () => void;
}

const Header = ({
  page,
  handleFilterRentals,
  onChangeDates,
  selectedRentals,
  modalExport,
  setError,
  setLoading,
  currentPeriod,
  handleChangePeriod,
  rentals,
  currentRental,
  handleChangeRental,
  handleContender,
  handleModalTax,
}: HeaderProps) => {
  const {t} = useTranslation();
  const returnByPage = () => {
    switch (page) {
      case "general":
        return (
          <div className="flex flex-row justify-between items-center space-x-2 w-full">
            <div className="flex flex-1 flex-row gap-3">
              <div className="flex">
                {/* @ts-ignore */}
                <DateFilter
                  defaultPreset="last_twelve_months"
                  onDatesChanged={onChangeDates}
                />
              </div>
            </div>

            <div className="flex flex-1 space-x-2 justify-end">
              <div>
                {handleFilterRentals && (
                  <MultiSelectRentals
                    initialRentals={selectedRentals ?? []}
                    onSelect={handleFilterRentals}
                  />
                )}
              </div>
              {/* <Button
                onClick={() => handleModalTax && handleModalTax()}
                type="secondary"
                displayLargeBtn={false}
              >
                {"Calculer les taxes de séjour"}
              </Button> */}
              {/* <Button
                onClick={() => setExportVisible && setExportVisible(true)}
                RightIcon={UploadIcon}
                displayLargeBtn={false}
              >
                {t("Analytics.export")}
              </Button> */}
            </div>
          </div>
        );
      case "detail":
        return (
          <div className="flex flex-row justify-between items-center space-x-2 w-full">
            <div className="flex flex-1 flex-row gap-3">
              <div className="w-48">
                <InputSelect
                  items={getYearList({labelSameYear: true})}
                  selectedValue={currentPeriod ?? moment()?.year()?.toString()}
                  onSelect={handleChangePeriod}
                />
              </div>
            </div>

            <div className="flex flex-1 space-x-2 justify-end">
              <div>
                {handleFilterRentals && (
                  <MultiSelectRentals
                    initialRentals={selectedRentals ?? []}
                    onSelect={handleFilterRentals}
                  />
                )}
              </div>
              {/* <Button
                onClick={() => handleModalTax && handleModalTax()}
                type="secondary"
                displayLargeBtn={false}
              >
                {"Calculer les taxes de séjour"}
              </Button> */}
              {/* <Button
                onClick={() => setExportVisible && setExportVisible(true)}
                RightIcon={UploadIcon}
                displayLargeBtn={false}
              >
                {t("Analytics.export")}
              </Button> */}
            </div>
          </div>
        );
      case "evaluation":
        return (
          <div className="flex flex-row gap-2 mt-2">
            <div className="flex">
              {/* @ts-ignore */}
              {/* <DateFilter
                {...(dates && setDates && {datesState: [dates, setDates]})}
                {...(setDateRangePreset && {
                  dateRangePresetState: [
                    dateRangePreset ?? "all",
                    setDateRangePreset,
                  ],
                })}
              /> */}
            </div>
          </div>
        );
      case "object_connected":
        return (
          <div className="flex flex-row gap-2 mt-2 justify-between">
            <div className="flex">
              <div className="flex">
                <InputSelect
                  items={rentals}
                  selectedValue={currentRental}
                  onSelect={handleChangeRental}
                />
              </div>
              <div className="flex">
                {/* @ts-ignore */}
                {/* <DateFilter
                  {...(dates && setDates && {datesState: [dates, setDates]})}
                  {...(setDateRangePreset && {
                    dateRangePresetState: [
                      dateRangePreset ?? "all",
                      setDateRangePreset,
                    ],
                  })}
                /> */}
              </div>
            </div>
          </div>
        );
      case "contender":
        return (
          <div className="flex flex-row gap-2 mt-2 justify-between">
            <div className="flex">
              <div className="flex">
                <InputSelect
                  items={rentals}
                  selectedValue={currentRental}
                  onSelect={handleChangeRental}
                />
              </div>
              <div className="flex">
                {/* @ts-ignore */}
                {/* <DateFilter
                  {...(dates && setDates && {datesState: [dates, setDates]})}
                  {...(setDateRangePreset && {
                    dateRangePresetState: [
                      dateRangePreset ?? "all",
                      setDateRangePreset,
                    ],
                  })}
                /> */}
              </div>
            </div>
            <div>
              <Button
                onClick={() => handleContender && handleContender()}
                type="secondary"
                displayLargeBtn={false}
              >
                {"Gérer les concurrents"}
              </Button>
            </div>
          </div>
        );
      default:
        return "";
    }
  };
  return returnByPage();
};

export default Header;
