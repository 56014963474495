import React, { useState } from "react";
import LeftArrowIcon from "../../assets/icons/arrow-left.svg?react";
import ArrowRightWhiteIcon from "../../assets/icons/arrow-right-white.svg?react";
import ArrowRightUpWhite from "../../assets/icons/arrow-up-right-white.svg?react";
import { Button } from "../Common/Button/Button";
import { useTranslation } from "react-i18next";
import { TextInput } from "../Common/TextInput/TextInput";

export const AddWebsiteDone = ({ url }: { url: string }) => {
  const { t } = useTranslation();

  const handleContinue = () => {};

  return (
    <>
      <div>
        <p className="text-lg font-semibold text-high-contrast mt-8">
          {t("Website.Add.Done.title")}
        </p>

        <p className="font-light text-low-contrast mt-4">
          {t("Website.Add.Done.description")}
        </p>

        <div className="h-10" />

        <div className="flex gap-3 items-end">
          <TextInput
            type="clipboard"
            value={url}
            label={t("Website.Add.Done.label")}
          />

          <div>
            <a href={url} target="_blank">
              <Button size="small" RightIcon={ArrowRightUpWhite}>
                {t("Website.Add.Done.view")}
              </Button>
            </a>
          </div>
        </div>

        <p className="font-light text-low-contrast mt-6">
          {t("Website.Add.Done.content1")}
        </p>

        <p className="font-light text-low-contrast mt-4">
          {t("Website.Add.Done.content2")}
        </p>

        <p className="font-light text-low-contrast mt-1">
          {t("Website.Add.Done.content3")}
        </p>

        <p className="font-light text-low-contrast mt-1">
          {t("Website.Add.Done.content4")}
        </p>

        <p className="font-light text-low-contrast mt-1">
          {t("Website.Add.Done.content5")}
        </p>

        <p className="font-light text-low-contrast mt-4">
          {t("Website.Add.Done.content6")}
        </p>
      </div>

      <div className="flex gap-3 justify-end">
        <Button RightIcon={ArrowRightWhiteIcon} onClick={handleContinue}>
          {t("Website.Add.Done.button")}
        </Button>
      </div>
    </>
  );
};
