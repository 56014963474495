import { useState } from "react";

export type UseModal<T> = {
  isVisible: boolean;
  data: T | null | undefined;
  open: (data?: T) => void;
  close: () => void;
};

export function useModal<T>(): UseModal<T> {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [currentData, setCurrentData] = useState<T | null | undefined>(null);
  
  const handleOpen = (data?: T) => {
    setIsVisible(true);
    setCurrentData(data);
  };
  const handleClose = () => {
    setIsVisible(false);
    setCurrentData(null);
  };

  return { isVisible, data: currentData, open: handleOpen, close: handleClose };
}
