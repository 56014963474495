import React from "react";
import useGuestWebsiteStore from "../../../../../../stores/guestWebsiteStore/useGuestWebsiteStore";
import {useTranslation} from "react-i18next";

const RefundableBlockComponent = ({loading}: {loading: boolean}) => {
  const {t} = useTranslation();
  const {rentalData} = useGuestWebsiteStore();
  const {payment_policy} = rentalData || {};
  const isRefundable = payment_policy?.is_refundable !== undefined;
  return (
    <div className="flex-col items-center space-y-1">
      {loading ? (
        <p className="w-3/4 h-3 rounded-lg bg-slate-300 animate-pulse"></p>
      ) : (
        <>
          <p className="text-base text-low-contrast">
            {t(
              `${
                isRefundable
                  ? "AddReservation.Payment.isRefundable"
                  : "AddReservation.Payment.isNotRefundable"
              }`
            )}
          </p>
        </>
      )}
    </div>
  );
};

export default RefundableBlockComponent;
