import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {get, post} from "../../../../helpers/APIHelper";
import paths from "../../../../constants/paths";
import {Button} from "../../../Common/Button/Button";
import {
  RentalLightBookingItemResponse,
  RentalLightListItemResponse,
} from "../../../../types/GETTypes";
import ArrowRight from "../../../../assets/icons/arrow-right-white.svg?react";
import {
  BookingConnectData,
  BookingConnectDataRentalRoomRates,
} from "./BookingConnectModal";
import {ErrorMessage} from "../../../Common/ErrorMessage/ErrorMessage";
import {UseFormReturn} from "react-hook-form";
import BookingConnectRentalRoomType, {
  BookingConnectRentalRoomTypeForm,
} from "./BookingConnectRentalRoomType";
import {ConnectionLoader} from "../../../Common/ConnectionLoader/ConnectionLoader";
import BookingIcon from "../../../../assets/icons/booking.svg?react";
import {PlatformColorList} from "../../../../constants/colors";

type BookingSynchro3Props = {
  properties: RentalLightBookingItemResponse[] | undefined;
  onNext: () => void;
  setData: React.Dispatch<React.SetStateAction<BookingConnectData | undefined>>;
  roomsRates: BookingConnectDataRentalRoomRates[] | undefined;
  rental: RentalLightListItemResponse | undefined;
};

export const BookingSynchroStep3: React.FC<BookingSynchro3Props> = ({
  properties,
  roomsRates,
  setData,
  onNext,
  rental,
}) => {
  const {t} = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState("");
  const [allFormsValid, setAllFormsValid] = React.useState(false);
  const childForms = useRef<UseFormReturn[]>([]);
  const [startSync, setStartSync] = useState(false);

  const handleValidateAll = async () => {
    const validations = await Promise.all(
      childForms.current.map(async (form) => {
        if (form) {
          const isValid = await form.trigger();
          return isValid;
        }
        return false;
      })
    );

    const allValid = validations.every((isValid) => isValid);

    if (allValid) {
      setStartSync(true);
      try {
        const data = await handleGetAllFormValues();
        const dataConnected: BookingConnectRentalRoomTypeForm[] = data?.map(
          (d) => {
            return {
              ...d,
              mode: "connect",
              rental_id: String(rental?.id ?? ""),
            };
          }
        );
        const response = await post(
          `${import.meta.env.VITE_API_URL}${
            paths.API.BOOKING.MAP_OR_CREATE_RENTALS
          }`,
          dataConnected
        );
        if (response?.data?.success) {
          setData((oldConnectedData) => {
            if (oldConnectedData) {
              return {
                ...oldConnectedData,
                finishSynchro: true,
              };
            }
          });
        } else {
          setError(response?.response?.data?.message);
          setStartSync(false);
          return;
        }
      } catch (e: any) {
        console.log(e.message);
        setStartSync(false);
        return;
      }
      if (rental) {
        await post(
          `${import.meta.env.VITE_API_URL}${
            paths.API.BOOKING.START_SYNCHRONIZATION
          }`,
          {
            rental_id: rental.id,
          }
        );
        setTimeout(() => {
          setStartSync(false);
          onNext();
        }, 200);
      }
    }
  };

  const handleGetAllFormValues = async (): Promise<
    BookingConnectRentalRoomTypeForm[]
  > => {
    const allFormValues = await Promise.all(
      childForms.current.map(async (form) => {
        if (form) {
          const values = form.getValues();
          return values as BookingConnectRentalRoomTypeForm;
        }
        return null;
      })
    );

    const validFormValues = allFormValues.filter(
      (values): values is BookingConnectRentalRoomTypeForm => values !== null
    );

    return validFormValues;
  };

  const checkFormsValidity = async () => {
    const validations = await Promise.all(
      childForms.current.map((form: UseFormReturn) => form?.trigger())
    );
    setAllFormsValid(validations.every((isValid) => isValid));
  };

  const getRoomRatesByRental = (id: number) => {
    const find = roomsRates?.find((r) => Number(r.id) === id);
    return find ?? undefined;
  };

  return (
    <div className="flex flex-col">
      {startSync ? (
        <div className="flex flex-col bg-element-background-light justify-center">
          <ConnectionLoader
            platformLeft={{
              icon: BookingIcon,
              color: PlatformColorList.Booking,
            }}
            type={"new"}
          />
          <div className="flex flex-col justify-center items-center p-4 pt-0 space-y-3">
            <p className="text-low-contrast font-bold">
              {t("Progression.syncInfoTitleRental")}
            </p>
            <div className="flex flex-col justify-center items-center">
              <p className="text-low-contrast">{t("Progression.syncInfo1")}</p>
              <p className="text-low-contrast">{t("Progression.syncInfo2")}</p>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="my-3 space-y-2">
            <p className="text-low-contrast font-bold">
              {t("Progression.verifBookingInfo")}
            </p>

            {properties?.map(
              (property: RentalLightBookingItemResponse, index) => (
                <BookingConnectRentalRoomType
                  // @ts-ignore
                  ref={(el) => (childForms.current[index] = el)}
                  property={property}
                  roomsRates={getRoomRatesByRental(property.id)}
                  onChange={checkFormsValidity}
                  loading={false}
                />
              )
            )}
          </div>
          <ErrorMessage>{error}</ErrorMessage>
          <Button
            onClick={handleValidateAll}
            RightIcon={ArrowRight}
            disabled={!allFormsValid}
          >
            {t("Global.validate")}
          </Button>{" "}
        </>
      )}
    </div>
  );
};
