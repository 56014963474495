import React, {useState} from "react";
import {RightModal} from "../../Common/RightModal/RightModal";
import {useTranslation} from "react-i18next";
import {Template, TemplatesModalProps} from "./TemplatesModal.type";
import {TextInput} from "../../Common/TextInput/TextInput";
import {Button} from "../../Common/Button/Button";

import SearchIcon from "../../../assets/icons/search.svg?react";
import CloseIcon from "../../../assets/icons/close.svg?react";
import {TextType} from "../../../types/commonTypes";
import {TemplateList} from "./TemplateList";
import {TemplateUpsert} from "./TemplateUpsert/TemplateUpsert";
import {RemoveTemplateModal} from "./RemoveTemplateModal";
import {del} from "../../../helpers/APIHelper";
import paths from "../../../constants/paths";
import {Title} from "../../Common/Title/Title";

export const TemplatesModal: React.FC<TemplatesModalProps> = ({
  isVisible,
  onInsert = () => {},
  onClose = () => {},
  rentalId
}) => {
  const { t } = useTranslation();

  const [isRemoveModalVisible, setRemoveModalVisible] =
    useState<boolean>(false);
  const [currentTemplateToRemove, setCurrentTemplateToRemove] = useState<
    Template | undefined | null
  >();

  const [isShortcodesVisible, setShortCodeVisible] = useState<boolean>(false);
  const [isAddModalVisible, setAddModalVisible] = useState<boolean>();
  const [currentTemplate, setCurrentTemplate] = useState<
    Template | null | undefined
  >();
  const [currentTab, setCurrentTab] = useState<"templates" | "auto">(
    "templates"
  );

  const handleClose = () => {
    setAddModalVisible(false);
    setShortCodeVisible(false);
    onClose();
  };

  const handleAddModeTemplate = () => {
    setAddModalVisible(true);
  };

  const handleEditTemplate = (template: Template) => {
    setCurrentTemplate(template);
    setAddModalVisible(true);
  };

  const handleInsertTemplate = (template: Template) => {
    handleClose();
    onInsert(template);
  };

  const handleBack = () => {
    setCurrentTemplate(null);
    setAddModalVisible(false);
    setShortCodeVisible(false);
  };

  const handleShowShortCode = () => {
    console.log("handleShowShortCode");
    setShortCodeVisible(!isShortcodesVisible);
  };

  const getTitle = () => {
    if (isShortcodesVisible)
      return (
        <div className="flex">
          <div className="flex justify-between w-modal">
            <Title>{t("Templates.shortcodes")}</Title>
            <div
              className="pt-1 hover:cursor-pointer mr-6"
              onClick={() => setShortCodeVisible(false)}
            >
              <CloseIcon />
            </div>
          </div>
          <div className="flex-1">
            {isAddModalVisible && currentTemplate ? (
              <Title>{t("Templates.editTitle")}</Title>
            ) : (
              <Title>{t("Templates.addTitle")}</Title>
            )}
          </div>
        </div>
      );
    else if (isAddModalVisible && currentTemplate)
      return t("Templates.editTitle");
    else if (isAddModalVisible && !currentTemplate)
      return t("Templates.addTitle");
    else return t("Templates.title");
  };

  const handleRemoveTemplate = async (template: Template) => {
    setCurrentTemplateToRemove(template);
    setRemoveModalVisible(true);
  };

  const handleConfirmRemoveTemplate = async () => {
    if (!currentTemplateToRemove) return;

    setRemoveModalVisible(false);

    const response = await del(
      `${import.meta.env.VITE_API_URL}${paths.API.MESSAGE_TEMPLATES}/${
        currentTemplateToRemove?.id
      }`
    );

    if (response.data?.success) {
      setAddModalVisible(false);
    }
  };

  return (
    <>
      <RightModal
        title={getTitle()}
        isVisible={isVisible}
        onClose={handleClose}
        onBack={isAddModalVisible ? handleBack : undefined}
        isSplit={isShortcodesVisible}
      >
        {isRemoveModalVisible && (
          <RemoveTemplateModal
            isVisble={isRemoveModalVisible}
            template={currentTemplateToRemove!}
            onClose={() => setRemoveModalVisible(false)}
            onRemove={handleConfirmRemoveTemplate}
          />
        )}

        <div className="flex flex-col w-full gap-4">
          {isAddModalVisible && (
            <TemplateUpsert
              template={currentTemplate}
              isShortcodesVisible={isShortcodesVisible}
              onRemove={handleRemoveTemplate}
              onClose={handleBack}
              onShowShortCode={handleShowShortCode}
            />
          )}

          {!isAddModalVisible && (
            <TemplateList
              onAdd={handleAddModeTemplate}
              onEdit={handleEditTemplate}
              onInsert={handleInsertTemplate}
              rentalId={rentalId}
            />
          )}
        </div>
      </RightModal>
    </>
  );
};

type AutoMessageListProps = {
  onInsert: (content: TextType) => void;
};

const AutoMessageList: React.FC<AutoMessageListProps> = ({
  onInsert = (content) => {},
}) => {
  const { t } = useTranslation();

  const messages = [
    {
      name: "Réservation confirmée (EN)",
      content:
        "Hi {{ guest_firstname}}, can’t wait you to discover your stay with your family, do you need any further informations regarding the neighbourhood/the city?",
    },
    {
      name: "Instructions d’arrivée (EN)",
      content:
        "Hello {{ guest_firstname}}, can’t wait you to discover your stay with your family, do you need any further informations regarding the neighbourhood/the city?",
    },
    {
      name: "Transports (EN)",
      content:
        "Hello {{ guest_firstname}}, can’t wait you to discover your stay with your family, do you need any further informations regarding the neighbourhood/the city?",
    },
  ];

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex flex-row space-x-3">
        <TextInput
          placeholder={t("Templates.placeholderSearchAuto")}
          Icon={SearchIcon}
        />

        <div>
          <Button type="secondary" size="small" onClick={() => {}}>
            {t("Templates.search")}
          </Button>
        </div>
      </div>

      <div className="mt-3 flex-1">
        <table className="table-auto w-full rounded-4px border-1 border-element-border border-separate overflow-hidden border-spacing-0">
          {/* Table Header */}
          <tr className="bg-element-background border-b-1 border-element-border">
            <th className="px-2 py-2 text-sm">
              <div className="flex flex-row items-center cursor-pointer hover:underline">
                <p>{t("Templates.auto")}</p>
              </div>
            </th>
          </tr>

          {messages?.map((m, index) => (
            <AutoMessageLine
              key={index}
              name={m.name}
              content={m.content}
              onInsert={onInsert}
            />
          ))}
        </table>
      </div>
    </div>
  );
};

export type AutoMessageLineProps = {
  name: string;
  content: string;
  onInsert: (content: TextType) => void;
};

const AutoMessageLine: React.FC<AutoMessageLineProps> = ({
  name,
  content,
  onInsert = () => {},
}) => {
  const { t } = useTranslation();

  return (
    <tr className="">
      <td className="flex flex-row space-x-2 px-2 py-3 border-t-1 border-element-border-light">
        <div className="flex-1 flex flex-col text-sm">
          <p className="text-high-contrast font-semibold">{name}</p>
          <p className="text-low-contrast font-light">{content}</p>
        </div>

        <div>
          <Button type="secondary" onClick={() => onInsert("content")}>
            {t("Templates.insert")}
          </Button>
        </div>
      </td>
    </tr>
  );
};
