import React, { useEffect, useState } from "react";

import { InputMultiSelect } from "../InputMultiSelect/InputMultiSelect";
import { cn } from "../../../helpers/classHelper";

import CloseIcon from "../../../assets/icons/close.svg?react";
import { MultiSelectWithTagDeleteProps } from "./MultiSelectWithTagDelete.type";

/**
 * MultiSelectWithTagDelete Component
 * This component displays a multi-select input where you can select multiple items.
 * Selected items are displayed above the input with the ability to remove them using a close button.
 *
 * Be careful ! This component is an overlay of the InputMultiSelect component.
 *
 * @param {string} className - Additional classes for styling.
 * @param {string|null} error - An error message to display.
 * @param onChange
 * @param membersId
 * @param label
 * @param disabled
 */
export const MultiSelectWithTagDelete = ({
  items,
  selectedValues = [],
  className = "",
  error = "",
  onChange,
  label = "",
  disabled = false,
  isMultiSelect = true,
}: MultiSelectWithTagDeleteProps) => {
  // State to store the ids of the selected members
  const [values, setValues] = useState<number[] | string[]>(selectedValues);

  /**
   * Handle item selection from the InputMultiSelect component.
   * Updates the state with the newly selected members.
   *
   * @param selectedValue - The selected members' ids.
   */
  const handleSelect = (selectedValue: any) => {
    setValues(selectedValue);
    onChange(selectedValue);
    if (isMultiSelect) {
      setValues(selectedValue);
      onChange(selectedValue);
    } else {
      if (values.length <= 0) {
        setValues(selectedValue);
        onChange(selectedValue);
        return;
      }
      setValues((prevIds) => {
        let idAdd = null;

        selectedValue.map((id: string | number) => {
          if (!prevIds.includes(id)) {
            idAdd = id;
          }
        });

        if (!idAdd) {
          return [];
        }
        onChange([idAdd]);
        return [idAdd];
      });
    }
  };

  /**
   * Removes a item from the selected members list.
   * This function uses the splice method to remove the member at a specific index.
   *
   * @param index - The index of the item to remove from the list.
   */
  const removeItemSelect = (index: number) => {
    const newValues = values.filter((_, i) => i !== index); // Remove the item at the given index
    setValues(newValues as string[]); // Update the state with the new list of selected items
    onChange(newValues as string[]);
  };

  useEffect(() => {
    setValues(selectedValues);
  }, []);

  return (
    <>
      <div className={cn("relative", className)}>
        {/* Display selected items above the input */}
        <div
          className={cn(
            "absolute z-10 flex items-center gap-2 h-full pl-1",
            error ? "-translate-y-0.5" : "mt-3",
          )}
        >
          {values && (
            <>
              {values
                .slice(0, 2)
                .map((value: number | string, index: number) => {
                  const valueItem = values?.find((m) => m === value) ?? null;
                  const itemFromValue =
                    items.find((item) => item.value === value) ?? null;
                  return valueItem ? (
                    <div
                      key={index}
                      className="p-1 bg-gray-100 rounded-md flex items-center gap-2 px-2"
                    >
                      {itemFromValue?.label}
                      <CloseIcon
                        className="cursor-pointer"
                        onClick={() => removeItemSelect(index)}
                      />
                    </div>
                  ) : null;
                })}

              {/* Display '...' if there are more than 2 selected items */}
              {values.length > 2 && (
                <div className="p-1 bg-gray-100 rounded-xl flex items-center gap-2 px-2">
                  ...
                </div>
              )}
            </>
          )}
        </div>

        {/* Multi-select input for selecting items */}
        <InputMultiSelect
          disabled={disabled}
          label={label}
          selectedValues={values}
          items={
            items?.map((item) => ({
              label: item.label,
              value: item.value,
            })) ?? []
          }
          onSelect={handleSelect}
          error={error}
        />
      </div>
    </>
  );
};
