import {create} from "zustand";
import {
  GuestWebsiteModel,
  GuestWebsiteRentalDetails,
  GuestWebsiteResult,
  GuestWebsiteStep1WithoutRentalResponse,
  GuestWebsiteStep2WithoutRentalResponse,
  GuestWebsiteStep3WithoutRentalResponse,
  GuestWebsiteStep4ExtrasResponse,
  GuestWebsiteStep5ExtrasResponse,
} from "../../types/GETTypes";
import {ReservationStep4SendDataForm} from "../../components/GuestWebsite/Rental/Template2.0/RentalDetails/Extras/RentalDetailsExtrasPage";
import {CalendarInputValueRange} from "../../components/Common/CalendarInput/CalendarInput.type";
import moment from "moment";

export type GuestWebsiteTabType =
  | "home"
  | "rentals"
  | "rental"
  | "services"
  | "about"
  | "contact"
  | "mentions"
  | "conditions"
  | "privacy"
  | "error";

export type GuestWebsiteTabUrlType =
  | "home"
  | "rentals"
  | "rental"
  | "services"
  | "about"
  | "contact"
  | "mentions"
  | "conditions"
  | "privacy"
  | "";

export type RentalReservationGuestWebsiteTabType =
  | "rental"
  | "information"
  | "payment"
  | "extras"
  | "extrasPayment"
  | "";

export type templateType =
  | GuestWebsiteModel.MODEL_2_0
  | GuestWebsiteModel.MODEL_CHIC
  | GuestWebsiteModel.MODEL_MINIMAL;

export interface ExtraOrderType {
  id: number;
  quantity?: number;
}

interface GuestWebsiteState {
  tabGuestWebsite: GuestWebsiteTabType;
  setTabGuestWebsite: (tab: GuestWebsiteTabType, rentalId?: string) => void;
  tabUrlGuestWebsite: GuestWebsiteTabUrlType;
  setTabUrlGuestWebsite: (tab: GuestWebsiteTabUrlType) => void;
  templateGuestWebsite: templateType;
  setTemplateGuestWebsite: (template: templateType) => void;
  dataGuestWebsite: GuestWebsiteResult | undefined;
  setDataGuestWebsite: (data: GuestWebsiteResult | undefined) => void;
  tabRentalId: string | undefined;
  previousTab: GuestWebsiteTabType;
  previousRentalId: string | undefined;
  rentalDetailTab: RentalReservationGuestWebsiteTabType;
  setRentalDetailTab: (tab: RentalReservationGuestWebsiteTabType) => void;
  extrasOrder: ExtraOrderType[] | undefined;
  setExtrasOrder: (extrasOrder: ExtraOrderType[]) => void;
  rentalData: GuestWebsiteRentalDetails | undefined;
  setRentalData: (data: GuestWebsiteRentalDetails | undefined) => void;
  step1Data: GuestWebsiteStep1WithoutRentalResponse | undefined;
  setStep1Data: (
    data: GuestWebsiteStep1WithoutRentalResponse | undefined
  ) => void;
  step2Data: GuestWebsiteStep2WithoutRentalResponse | undefined;
  setStep2Data: (
    data: GuestWebsiteStep2WithoutRentalResponse | undefined
  ) => void;
  step3Data: GuestWebsiteStep3WithoutRentalResponse | undefined;
  setStep3Data: (
    data: GuestWebsiteStep3WithoutRentalResponse | undefined
  ) => void;
  extrasDataSend: ReservationStep4SendDataForm | undefined;
  setExtrasDataSend: (data: ReservationStep4SendDataForm | undefined) => void;
  step4Data: GuestWebsiteStep4ExtrasResponse | undefined;
  setStep4Data: (data: GuestWebsiteStep4ExtrasResponse | undefined) => void;
  step5Data: GuestWebsiteStep5ExtrasResponse | undefined;
  setStep5Data: (data: GuestWebsiteStep5ExtrasResponse | undefined) => void;
  loadingRentalsSearch: boolean;
  setLoadingRentalsSearch: (value: boolean) => void;
  checkinsDates: CalendarInputValueRange;
  setCheckinsDates: (value: CalendarInputValueRange) => void;
}

const updateQueryParams = (tab: GuestWebsiteTabType, rentalId?: string) => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);

  // Update the "tab" query parameter
  if (tab === "home") {
    const url = new URL(window.location.href);
    const baseUrl = url.origin + url.pathname;

    // Update the URL without query parameters
    window.history.replaceState({}, "", baseUrl);
    return;
  } else {
    params.set("tab", tab);
  }

  // Update the "rentalId" query parameter if provided
  if (rentalId) {
    params.set("rentalId", rentalId);
  } else {
    params.delete("rentalId");
  }

  // Update the URL without reloading the page
  window.history.replaceState({}, "", `${url.pathname}?${params.toString()}`);
};

const useGuestWebsiteStore = create<GuestWebsiteState>((set, get) => ({
  tabGuestWebsite: "home",
  setTabGuestWebsite: (tab: GuestWebsiteTabType, rentalId?: string) => {
    set((state) => ({
      tabGuestWebsite: tab,
      tabRentalId: tab === "rental" ? rentalId : undefined,
      previousTab: state.tabGuestWebsite,
      previousRentalId:
        state.tabGuestWebsite === "rental" ? state.tabRentalId : undefined,
      rentalDetailTab: tab === "rental" ? "rental" : undefined,
      extraOrder: undefined,
      step1Data: undefined,
      step2Data: undefined,
      step3Data: undefined,
      extrasDataSend: undefined,
      step4Data: undefined,
      step5Data: undefined,
    }));

    // Update the query parameters
    updateQueryParams(tab, rentalId);
    // Scroll to the top of the page
    window.scrollTo({top: 0, behavior: "smooth"});
  },
  previousTab: "home",
  tabUrlGuestWebsite: "",
  setTabUrlGuestWebsite: (tabUrl: GuestWebsiteTabUrlType) =>
    set((state) => ({
      tabUrlGuestWebsite: tabUrl,
    })),
  templateGuestWebsite: GuestWebsiteModel.MODEL_2_0,
  setTemplateGuestWebsite: (template: templateType) =>
    set((state) => ({
      templateGuestWebsite: template,
    })),
  dataGuestWebsite: undefined,
  setDataGuestWebsite: (data: GuestWebsiteResult | undefined) =>
    set((state) => ({
      dataGuestWebsite: data,
    })),
  tabRentalId: undefined,
  setTabRentalId: (rentalId: string | undefined) =>
    set((state) => ({
      tabRentalId: rentalId,
    })),
  previousRentalId: undefined,
  rentalDetailTab: "rental",
  setRentalDetailTab: (tab: RentalReservationGuestWebsiteTabType) => {
    set((state) => ({
      rentalDetailTab: tab,
      extrasOrder:
        tab === "extras" || tab === "extrasPayment"
          ? state.extrasOrder
          : undefined,
      checkinsDates:
        tab === "extras"
          ? [new Date(), moment(new Date()).add(1, "days").toDate()]
          : state.checkinsDates,
    }));
    // Scroll to the top of the page
    window.scrollTo({top: 0, behavior: "smooth"});
  },
  extrasOrder: undefined,
  setExtrasOrder: (extrasOrder: ExtraOrderType[] | undefined) =>
    set((state) => ({
      extrasOrder: extrasOrder,
    })),
  rentalData: undefined,
  setRentalData: (data: GuestWebsiteRentalDetails | undefined) =>
    set((state) => ({
      rentalData: data,
    })),
  step1Data: undefined,
  setStep1Data: (data: GuestWebsiteStep1WithoutRentalResponse | undefined) =>
    set((state) => ({
      step1Data: data,
    })),
  step2Data: undefined,
  setStep2Data: (data: GuestWebsiteStep2WithoutRentalResponse | undefined) =>
    set((state) => ({
      step2Data: data,
    })),
  step3Data: undefined,
  setStep3Data: (data: GuestWebsiteStep3WithoutRentalResponse | undefined) =>
    set((state) => ({
      step3Data: data,
    })),
  extrasDataSend: undefined,
  setExtrasDataSend: (data: ReservationStep4SendDataForm | undefined) =>
    set((state) => ({
      extrasDataSend: data,
    })),
  step4Data: undefined,
  setStep4Data: (data: GuestWebsiteStep4ExtrasResponse | undefined) =>
    set((state) => ({
      step4Data: data,
    })),
  step5Data: undefined,
  setStep5Data: (data: GuestWebsiteStep5ExtrasResponse | undefined) =>
    set((state) => ({
      step5Data: data,
    })),
  loadingRentalsSearch: false,
  setLoadingRentalsSearch: (value: boolean) =>
    set((state) => ({
      loadingRentalsSearch: value,
    })),
  checkinsDates: [new Date(), moment(new Date()).add(1, "days").toDate()],
  setCheckinsDates: (value: CalendarInputValueRange) =>
    set((state) => ({
      checkinsDates: value,
    })),
}));

export default useGuestWebsiteStore;
