import React from "react";
import useGuestWebsiteStore from "../../../../stores/guestWebsiteStore/useGuestWebsiteStore";

interface GuestWebsiteTemplate2ConditionsComponentProps {
  loading: boolean;
}
const GuestWebsiteTemplate2ConditionsComponent =
  ({}: GuestWebsiteTemplate2ConditionsComponentProps) => {
    const {dataGuestWebsite} = useGuestWebsiteStore();
    const {integration, styles} = dataGuestWebsite || {};
    const {cgv} = integration || {};
    const {color_background,color_text} = styles || {};
    return (
      <div
        className="py-12 px-4 md:px-contentWebsite pb-24 h-full"
        style={{backgroundColor: color_background, color:color_text}}
        dangerouslySetInnerHTML={{
          __html: cgv ?? "",
        }}
      />
    );
  };

export default GuestWebsiteTemplate2ConditionsComponent;
