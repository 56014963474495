import React from "react";
import ForbiddenIcon from "../../../assets/icons/forbid-2.svg?react";
import { useTranslation } from "react-i18next";
import { Button } from "../../Common/Button/Button";
import PlusWhiteIcon from "../../../assets/icons/plus-white.svg?react";

export const NoTaskInBooking: React.FC<{ onAdd: () => void }> = ({ onAdd }) => {
  const { t } = useTranslation();

  return (
    <div className="w-full h-full bg-element-background rounded-6px border-1 border-element-border flex items-center justify-center flex-col gap-2">
      <ForbiddenIcon className="size-12" />

      <p className="text-high-contrast font-semibold">
        {t("Task.List.noTaskTitle")}
      </p>
      <p className="text-low-contrast font-light">
        {t("Task.List.noTaskDescription")}
      </p>

      <div>
        <Button onClick={() => onAdd?.()} RightIcon={PlusWhiteIcon}>
          {t("Task.Modal.title")}
        </Button>
      </div>
    </div>
  );
};
