import React from "react";
import {TFunction} from "i18next";
import {
  File as FileType,
  FileInstruction,
  MessageTemplatePhotoResponse,
} from "../types/GETTypes";

export function base64ToFile(base64String: string, fileName?: string) {
  if (!base64String) return "";
  // Extract the MIME type and Base64 data
  const [mimePart, dataPart] = base64String?.split(",");
  const mimeType = mimePart?.match(/:(.*?);/)?.[1]; // Extract MIME type (e.g., "image/png")

  // Decode the Base64 string
  const byteCharacters = atob(dataPart);
  const byteNumbers = new Array(byteCharacters.length)
    .fill(0)
    .map((_, i) => byteCharacters.charCodeAt(i));
  const byteArray = new Uint8Array(byteNumbers);

  // Create a Blob from the byte array
  const blob = new Blob([byteArray], {type: mimeType});

  // Convert the Blob into a File object
  return new File([blob], fileName ?? "", {type: mimeType});
}

export const convertBase64ToBlob = (image: MessageTemplatePhotoResponse) => {
  if (!image.base_64) return null;

  // Extraire le contenu pur en base64 en éliminant le préfixe de l'URL de données

  // Convertir la chaîne base64 en octets
  const byteCharacters = atob(image.base_64);

  // Convertir les octets en `Uint8Array`
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  // Créer un Blob à partir de l'`Uint8Array`
  const blob = new Blob([byteArray], {type: image.mime_type});
  const file = new File([blob], image.name, {type: image.mime_type});

  return file;
};

export const convertFileToBlob = async (file: FileType) => {
  try {
    // Fetch the file content from the original_url
    const response = await fetch(file.original_url);

    if (!response.ok) {
      return "";
    }

    // Convert the response to a Blob
    const fileBlob = await response.blob();

    // Set the Blob in state or use it as needed
    return fileBlob;
  } catch (error) {
    console.error("Error converting file to Blob:", error);
    return "";
  }
};

export const isPdfFile = (mime_type: string) => {
  return mime_type === "application/pdf";
};
export const isImageFile = (mime_type: string) => {
  return (
    mime_type === "image/jpeg" ||
    mime_type === "image/png" ||
    mime_type === "image/jpg" ||
    mime_type === "image/gif" ||
    mime_type === "image/webp"
  );
};

export const isVideoFile = (mime_type: string) => {
  return (
    mime_type === "video/mp4" ||
    mime_type === "video/webm" ||
    mime_type === "video/avi" ||
    mime_type === "video/mov" ||
    mime_type === "video/ogg" ||
    mime_type === "video/mkv" ||
    mime_type === "video/m4v" ||
    mime_type === "video/3gp" ||
    mime_type === "video/flv" ||
    mime_type === "mov" ||
    mime_type === "video/quicktime"
  );
};

export const filterFilesByType = (
  files: FileInstruction[] | undefined,
  key: "image" | "file"
): FileInstruction[] => {
  if (!files) {
    return [];
  }
  return files.filter((file) => {
    if (key === "image") {
      return isImageFile(file.mime_type) || isVideoFile(file.mime_type);
    } else if (key === "file") {
      return !isImageFile(file.mime_type) && !isVideoFile(file.mime_type);
    }
    return false;
  });
};

export type fileType = "image" | "video" | "pdf" | "document";

export interface MimeTypeLabel {
  mime_type: string;
  label: string;
  type: fileType;
}
export const mimeTypeLabel = (
  t: TFunction<"translation", undefined>,
  mime_type: string
): MimeTypeLabel => {
  if (isImageFile(mime_type)) {
    return {mime_type, label: t("BookingContract.image"), type: "image"};
  } else if (isVideoFile(mime_type)) {
    return {mime_type, label: t("BookingContract.video"), type: "video"};
  } else if (isPdfFile(mime_type)) {
    return {mime_type, label: t("BookingContract.image"), type: "pdf"};
  } else {
    return {mime_type, label: t("BookingContract.document"), type: "document"};
  }
};

export const downloadFile = ({
  original_url,
  name,
  close = false,
}: {
  name: string;
  original_url: string;
  close?: boolean;
}) => {
  const newWindow = window.open("", "_blank");

  if (newWindow) {
    // Create an anchor tag within the new window's document
    newWindow.document.body.innerHTML = `<a href="${original_url}" download="${name}"></a>`;

    // Trigger the download
    newWindow.document.body.querySelector("a")?.click();

    // // Close the new window after the download starts
    if (close) {
      newWindow.close();
    }
  }
};
