import React from "react";
import useGuestWebsiteStore from "../../../../stores/guestWebsiteStore/useGuestWebsiteStore";

interface GuestWebsiteTemplate2MentionsComponentProps {
  loading: boolean;
}
const GuestWebsiteTemplate2MentionsComponent =
  ({}: GuestWebsiteTemplate2MentionsComponentProps) => {
    const {dataGuestWebsite} = useGuestWebsiteStore();
    const {integration, styles} = dataGuestWebsite || {};
    const {legal_terms} = integration || {};
    const {color_background,color_text} = styles || {};
    return (
      <div
        className="py-12 px-4 md:px-contentWebsite h-full pb-24"
        style={{backgroundColor: color_background,color:color_text}}
        dangerouslySetInnerHTML={{__html: legal_terms ?? ""}}
      />
    );
  };

export default GuestWebsiteTemplate2MentionsComponent;
