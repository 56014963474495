import React, {useEffect, useState} from "react";
import ArrowLeftIcon from "../../assets/icons/arrow-left.svg?react";
import {Button} from "../Common/Button/Button";
import {Title} from "../Common/Title/Title";
import {useTranslation} from "react-i18next";
import InfoIcon from "../../assets/icons/information.svg?react";
import AirbnbIcon from "../../assets/icons/airbnb.svg?react";
import BookingIcon from "../../assets/icons/booking.svg?react";
import DirectIcon from "../../assets/icons/tv-circle.svg?react";
import CleaningIcon from "../../assets/icons/clean.svg?react";
import {Controller, useForm} from "react-hook-form";
import {TextInput} from "../Common/TextInput/TextInput";
import {getFormattedPrice} from "../../helpers/currencyHelper";
import PriceMultiplierTable from "./PriceMultiplierTable";
import {NumberInput} from "../Common/NumberInput/NumberInput";
import CheckWhiteIcon from "../../assets/icons/check-white.svg?react";
import {post} from "../../helpers/APIHelper";
import paths from "../../constants/paths";
import {ErrorMessage} from "../Common/ErrorMessage/ErrorMessage";

import {useNavigate} from "react-router-dom";
import {
  convertToNumber,
  prepareDataAirbnb,
  prepareDataBooking,
  prepareDataWebsite,
  PriceMultiplierData,
} from "../../helpers/priceMultiplierHelper";
import {ProgressionStepEnum} from "../../enums/GETenums";
import {
  RentalLightListItemResponse,
  RentalResponse,
} from "../../types/GETTypes";
import MultiSelectRentalsCheckboxModal from "../Common/MultiSelectRentalsCheckbox/MultiSelectRentalsCheckboxModal";
import {useModal} from "../../hooks/useModal";
import {ValueType} from "../../types/commonTypes";
import {GreyBlock} from "./PriceMultiplierTableBody";

interface PriceMultiplierForm {
  price_multiplier_airbnb: number;
  price_multiplier_booking: number;
  price_multiplier_platform_website: number;
}

type PriceMultiplierFrom = "onboarding" | "rental";

const PriceMultiplier = ({
  onGoBack,
  from = "onboarding",
  rental,
  onUpdateRental,
}: {
  onGoBack: () => void;
  from?: PriceMultiplierFrom;
  rental?: RentalResponse | undefined;
  onUpdateRental?: (rental: RentalResponse | undefined) => void;
}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const multiSelectRentalModal = useModal();
  const defaultPriceMultiplierAirbnb = 20;
  const defaultPriceMultiplierBooking = 21;
  const form = useForm({
    defaultValues: {
      price_multiplier_airbnb: 20,
      price_multiplier_booking: 21,
      price_multiplier_platform_website: 0,
      price_by_night: 100,
      tax_by_night_per_person: 15.6,
    },
  });
  const [loading, setLoading] = useState(false);
  const [loadingSimulation, setLoadingSimulation] = useState(false);
  const [error, setError] = useState("");
  const [errorSelectedRentals, setErrorSelectedRentals] = useState("");
  const [loadingSelectedRentals, setLoadingSelectedRentals] = useState(false);
  const [priceMultiplierData, setPriceMultiplierData] =
    useState<PriceMultiplierData>();
  const [selectedRentalsIds, setSelectedRentalsIds] = useState<ValueType[]>([]);
  const onSubmitRentals = async (data: PriceMultiplierForm) => {
    setError("");
    setLoading(true);
    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.UPDATE_ALL_MULTIPLIER}`,
      {
        price_multiplier_airbnb: Number(data.price_multiplier_airbnb),
        price_multiplier_booking: Number(data.price_multiplier_booking),
        price_multiplier_platform_website: Number(
          data.price_multiplier_platform_website
        ),
      }
    );
    if (res.data?.success) {
      const response = await post(
        `${import.meta.env.VITE_API_URL}${paths.API.COMPLETE_PROGRESSION_STEP}`,
        {progression_step: ProgressionStepEnum.STEP_SYNCHRONIZE}
      );
      if (!response?.data?.success) {
        setError(response?.response?.data?.message);
        setLoading(false);
      } else {
        navigate(`${paths.PROGRESSION}?sync_in_progress=true`);
      }
    } else {
      setError(res.response.data.message);
    }
    setLoading(false);
  };

  const onSubmitOneRental = async (data: PriceMultiplierForm) => {
    setError("");
    setLoading(true);
    if (rental) {
      const res = await post(
        `${import.meta.env.VITE_API_URL}${
          paths.API.UPDATE_MULTIPLE_MULTIPLIER
        }`,
        {
          rentals: [
            {
              rental_id: rental?.id,
              price_multiplier_booking: data.price_multiplier_booking,
              price_multiplier_airbnb: data.price_multiplier_airbnb,
              price_multiplier_platform_website:
                data.price_multiplier_platform_website,
            },
          ],
        }
      );
      if (res.data?.success) {
        if (onUpdateRental) {
          onUpdateRental({
            ...rental,
            tarification: {
              ...rental.tarification,
              price_multipliers: {
                platform_airbnb: data.price_multiplier_airbnb,
                platform_booking: data.price_multiplier_booking,
                platform_website: data.price_multiplier_platform_website,
              },
            },
          });
          onGoBack();
        }
      } else {
        setError(res.response.data.message);
      }
      setLoading(false);
    }
  };

  const onSubmitSelectedRentals = async (data: PriceMultiplierForm) => {
    setErrorSelectedRentals("");
    setLoadingSelectedRentals(true);
    if (selectedRentalsIds?.length > 0) {
      const res = await post(
        `${import.meta.env.VITE_API_URL}${
          paths.API.UPDATE_MULTIPLE_MULTIPLIER
        }`,
        {
          rentals: selectedRentalsIds
            ?.sort((a, b) => (a as number) - (b as number))
            ?.map((id) => {
              return {
                rental_id: id,
                price_multiplier_booking: data.price_multiplier_booking,
                price_multiplier_airbnb: data.price_multiplier_airbnb,
                price_multiplier_platform_website:
                  data.price_multiplier_platform_website,
              };
            }),
        }
      );
      if (res.data?.success) {
        if (
          rental &&
          onUpdateRental &&
          selectedRentalsIds.includes(rental.id)
        ) {
          onUpdateRental({
            ...rental,
            tarification: {
              ...rental.tarification,
              price_multipliers: {
                platform_website: data.price_multiplier_platform_website,
                platform_airbnb: data.price_multiplier_airbnb,
                platform_booking: data.price_multiplier_booking,
              },
            },
          });
        }
        multiSelectRentalModal.close();
        onGoBack();
      } else {
        setErrorSelectedRentals(res.response.data.message);
      }
      setLoadingSelectedRentals(false);
    }
  };

  form.watch("price_multiplier_airbnb");
  form.watch("price_multiplier_booking");
  form.watch("price_multiplier_platform_website");
  form.watch("price_by_night");
  form.watch("tax_by_night_per_person");

  const calculateWebsite = () => {
    const priceMultiplierWebsite = form.getValues(
      "price_multiplier_platform_website"
    );
    const priceByNight = Number(form.getValues("price_by_night"));
    const tax = Number(form.getValues("tax_by_night_per_person"));
    const website = prepareDataWebsite({
      priceMultiplierWebsite,
      priceByNight,
      tax,
    });
    return website;
  };

  const calculateBooking = () => {
    const priceMultiplierBooking = form.getValues("price_multiplier_booking");
    const priceByNight = Number(form.getValues("price_by_night"));
    const tax = Number(form.getValues("tax_by_night_per_person"));
    const booking = prepareDataBooking({
      priceMultiplierBooking,
      priceByNight,
      tax,
    });
    return booking;
  };
  const calculateAirbnb = () => {
    const priceMultiplierAirbnb = form.getValues("price_multiplier_airbnb");
    const priceByNight = Number(form.getValues("price_by_night"));
    const tax = Number(form.getValues("tax_by_night_per_person"));
    const airbnb = prepareDataAirbnb({
      priceMultiplierAirbnb,
      priceByNight,
      tax,
    });

    return airbnb;
  };

  const calculatePriceMultiplier = ({loading = true}: {loading?: boolean}) => {
    loading && setLoadingSimulation(true);
    setPriceMultiplierData(undefined);

    const data: PriceMultiplierData = {
      website: calculateWebsite(),
      booking: calculateBooking(),
      airbnb: calculateAirbnb(),
      ...form.getValues(),
    };
    setPriceMultiplierData(data);

    if (loading) {
      setTimeout(() => {
        setLoadingSimulation(false);
      }, 1000);
    }
  };

  useEffect(() => {
    if (from === "rental") {
      form.setValue(
        "price_multiplier_airbnb",
        rental?.tarification.price_multipliers.platform_airbnb ??
          form.getValues("price_multiplier_airbnb")
      );
      form.setValue(
        "price_multiplier_booking",
        rental?.tarification.price_multipliers.platform_booking ??
          form.getValues("price_multiplier_booking")
      );
      form.setValue(
        "price_multiplier_platform_website",
        rental?.tarification.price_multipliers.platform_website ??
          form.getValues("price_multiplier_platform_website")
      );
      setTimeout(() => {
        calculatePriceMultiplier({loading: false});
      }, 200);
    } else {
      calculatePriceMultiplier({loading: false});
    }
  }, []);

  useEffect(() => {}, [priceMultiplierData]);

  const DisplayButtons = () => {
    if (from === "onboarding") {
      return (
        <div className="flex flex-col items-end pt-4 space-y-4">
          <ErrorMessage>{error}</ErrorMessage>
          <Button
            RightIcon={CheckWhiteIcon}
            displayLargeBtn={false}
            onClick={form.handleSubmit(onSubmitRentals)}
            loading={loading}
          >
            {t("PriceMultiplier.apply")}
          </Button>
        </div>
      );
    } else if (from === "rental") {
      return (
        <div className="flex flex-col items-end pt-4 space-y-4">
          <ErrorMessage>{error}</ErrorMessage>
          <div className="flex flex-row space-x-4">
            <Button
              type="secondary"
              displayLargeBtn={false}
              onClick={multiSelectRentalModal.open}
            >
              {t("PriceMultiplier.applyToOthers")}
            </Button>
            <Button
              RightIcon={CheckWhiteIcon}
              displayLargeBtn={false}
              onClick={form.handleSubmit(onSubmitOneRental)}
              loading={loading}
            >
              {t("PriceMultiplier.applyRental")}
            </Button>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <MultiSelectRentalsCheckboxModal
        isVisible={multiSelectRentalModal.isVisible}
        onClose={multiSelectRentalModal.close}
        title={t("PriceMultiplier.priceMultiplierMultipleRentalsTitle")}
        titleButtonSubmit={t(
          "PriceMultiplier.priceMultiplierMultipleRentalsTitleSubmit"
        )}
        onSubmit={form.handleSubmit(onSubmitSelectedRentals)}
        selectedRentalsIds={selectedRentalsIds}
        setSelectedRentalsIds={setSelectedRentalsIds}
        loading={loadingSelectedRentals}
        error={errorSelectedRentals}
        disabled={!selectedRentalsIds?.length}
      />
      <div className="p-4 px-8 space-y-2 space-x-4">
        <div className="flex flex-row items-center space-x-4 mb-4">
          <Button
            type="secondary"
            LeftIcon={ArrowLeftIcon}
            displayLargeBtn={false}
            onClick={onGoBack}
          >
            {t("Global.back")}
          </Button>
          <Title>{t("PriceMultiplier.title")}</Title>
        </div>
        <div className="flex space-x-4">
          <div className="w-3/4 space-y-8">
            <div>
              <p
                className="text-sm"
                dangerouslySetInnerHTML={{__html: t("PriceMultiplier.info")}}
              />
            </div>
            <div className="flex flex-col space-y-1">
              <span className="text-base text-high-contrast font-bold">
                {t("PriceMultiplier.params")}
              </span>
              <span className="text-xs text-low-contrast">
                {t("PriceMultiplier.paramsInfo")}
              </span>
            </div>
            <div className="flex items-stretch space-x-4">
              <div className="w-1/3 bg-element-border/10 p-2 flex flex-col">
                <div className="flex items-center space-x-2">
                  <div className="flex justify-start mb-4">
                    <DirectIcon className="h-6 w-6" />
                  </div>
                  <div className="flex flex-col">
                    <div className="flex text-xs items-center">
                      {t("Filter.bookingEngine")}{" "}
                    </div>
                    <p className="text-low-contrast text-xs w-full">
                      {t("PriceMultiplier.multiplerFor", {
                        platform: "votre site de réservation",
                      })}
                    </p>
                  </div>
                  <div className="flex flex-col">
                    <Controller
                      control={form.control}
                      name="price_multiplier_platform_website"
                      rules={{
                        required: "",
                      }}
                      render={({field: {onChange, value}}) => (
                        <NumberInput
                          onChangeText={onChange}
                          value={value}
                          acceptNegative
                          acceptDecimal
                          currency={"%"}
                          error={
                            form.formState.errors
                              .price_multiplier_platform_website?.message
                          }
                          required={true}
                          disabled={loading}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="flex flex-1 items-end">
                  <GreyBlock value={""} classNames={{divParent: "w-full"}} />
                </div>
              </div>
              <div className="w-1/3 bg-element-border/10 p-2 flex flex-col">
                <div className="flex items-center space-x-2">
                  <div className="flex justify-start">
                    <AirbnbIcon className="h-5 w-5" />
                  </div>
                  <div className="flex flex-col">
                    <div className="flex text-xs items-center">
                      {t("Filter.airbnb")}{" "}
                    </div>
                    <p className="text-low-contrast text-xs">
                      {t("PriceMultiplier.multiplerFor", {platform: "Airbnb"})}
                    </p>
                  </div>
                  <div className="flex flex-col">
                    <Controller
                      control={form.control}
                      name="price_multiplier_airbnb"
                      rules={{
                        required: "",
                      }}
                      render={({field: {onChange, value}}) => (
                        <NumberInput
                          onChangeText={onChange}
                          value={value}
                          acceptNegative
                          acceptDecimal
                          currency={"%"}
                          error={
                            form.formState.errors.price_multiplier_airbnb
                              ?.message
                          }
                          required={true}
                          disabled={loading}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="flex flex-1 items-end">
                  <GreyBlock
                    value={`${t(
                      "Progression.RentalsRules.Multi.reco"
                    )} ${defaultPriceMultiplierAirbnb}%`}
                    classNames={{divParent: "w-full"}}
                  />
                </div>
              </div>
              <div className="w-1/3 bg-element-border/10 p-2 flex flex-col">
                <div className="flex items-center space-x-2">
                  <div className="flex justify-start">
                    <BookingIcon className="h-5 w-5" />
                  </div>
                  <div className="flex flex-col">
                    <div className="flex text-xs items-center">
                      {t("Filter.booking")}
                    </div>
                    <p className="text-low-contrast text-xs">
                      {t("PriceMultiplier.multiplerFor", {platform: "Booking"})}
                    </p>
                  </div>
                  <Controller
                    control={form.control}
                    name="price_multiplier_booking"
                    rules={{
                      required: "",
                    }}
                    render={({field: {onChange, value}}) => (
                      <NumberInput
                        onChangeText={onChange}
                        value={value}
                        acceptNegative
                        acceptDecimal
                        currency={"%"}
                        error={
                          form.formState.errors.price_multiplier_booking
                            ?.message
                        }
                        required={true}
                        disabled={loading}
                      />
                    )}
                  />
                </div>
                <div className="flex flex-1 items-end">
                  <GreyBlock
                    value={`${t(
                      "Progression.RentalsRules.Multi.reco"
                    )} ${defaultPriceMultiplierBooking}%`}
                    classNames={{divParent: "w-full"}}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col space-y-1">
              <span
                className="text-lg text-high-contrast font-bold"
                dangerouslySetInnerHTML={{
                  __html: t("PriceMultiplier.simulationTitle"),
                }}
              />
              <span className="text-xs text-low-contrast">
                {t("PriceMultiplier.simulationInfo1")}
              </span>
              <span className="text-xs text-low-contrast">
                {t("PriceMultiplier.simulationInfo2")}
              </span>
            </div>
            <div className="flex bg-element-border/50 p-1 py-2 rounded space-x-2 items-center h-auto justify-between">
              <Controller
                control={form.control}
                name="price_by_night"
                render={({field: {onChange, value}}) => (
                  <div className="w-3/4">
                    <NumberInput
                      label={t("PriceMultiplier.priceByNight")}
                      value={value}
                      onChangeText={onChange}
                      error={form.formState.errors.price_by_night?.message}
                      required={true}
                      disabled={loading}
                      acceptDecimal
                      showButtons={false}
                    />
                  </div>
                )}
              />
              <Controller
                control={form.control}
                name="tax_by_night_per_person"
                render={({field: {onChange, value}}) => (
                  <div className="w-1/4">
                    <NumberInput
                      label={t("PriceMultiplier.taxByNightPerPerson")}
                      value={value}
                      onChangeText={onChange}
                      error={form.formState.errors.price_by_night?.message}
                      required={true}
                      disabled={loading}
                      acceptNegative
                      showButtons={false}
                    />
                  </div>
                )}
              />
              <div className="w-1/4 pt-[22px]">
                <Button
                  type="secondary"
                  loading={loadingSimulation}
                  displayLargeBtn={false}
                  divParentClassName="h-[2rem]"
                  buttonClassName="h-[2rem]"
                  disabled={loading}
                  onClick={() => calculatePriceMultiplier({})}
                >
                  {t("PriceMultiplier.startEmulation")}
                </Button>
              </div>
            </div>
            <PriceMultiplierTable data={priceMultiplierData} />
          </div>
          <div className="flex flex-col w-1/3 bg-element-border/20 p-2 space-x-2 rounded-md h-full pr-4">
            <div className="flex space-x-2">
              <InfoIcon className="h-16 w-16 pb-8" />
              <div className="flex flex-col space-y-1 pt-2">
                <span className="text-xs text-high-contrast font-bold">
                  {t("PriceMultiplier.commissionsTitle")}
                </span>
                <p className="text-xs text-low-contrast">
                  {t("PriceMultiplier.commissionsInfo1")}
                </p>
                <p className="text-xs text-low-contrast">
                  {t("PriceMultiplier.commissionsInfo2")}
                </p>
                <div className="flex flex-row space-x-1 pt-4">
                  <AirbnbIcon />
                  <div className="flex pt-0.5">
                    <p className="text-xs text-low-contrast font-bold">
                      {t("PriceMultiplier.tutoAirbnb1")}
                      <span className="text-xs text-button-primary-default-top mx-1 cursor-pointer font-normal">
                        {t("PriceMultiplier.tutoAirbnb2")}
                      </span>
                      {t("PriceMultiplier.tutoAirbnb3")}
                    </p>
                  </div>
                </div>
                <div className="flex flex-row space-x-1 pt-2">
                  <BookingIcon />
                  <div className="flex pt-0.5">
                    <p
                      className="text-xs text-low-contrast"
                      dangerouslySetInnerHTML={{
                        __html: t("PriceMultiplier.tutoBooking1"),
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-row space-x-1 pt-4">
                  <div className="flex pt-0.5">
                    <p className="text-xs text-low-contrast">
                      {t("PriceMultiplier.tutoBooking2")}
                      <span className="text-xs text-button-primary-default-top mx-1 cursor-pointer">
                        {t("PriceMultiplier.tutoBooking3")}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="flex flex-row space-x-1 pt-4">
                  <div className="flex pt-0.5">
                    <p className="text-xs text-low-contrast">
                      {t("PriceMultiplier.commissionsText1")}
                      <span className="text-xs text-low-contrast font-bold ml-1 cursor-pointer">
                        {t("PriceMultiplier.commissionsText2")}
                      </span>
                      <span className="text-xs text-low-contrast ml-1 cursor-pointer">
                        {t("PriceMultiplier.commissionsText3")}
                      </span>
                      <span className="text-xs text-high-contrast font-bold ml-1 cursor-pointer">
                        {t("PriceMultiplier.commissionsText4")}
                      </span>
                      <span className="text-xs text-low-contrast ml-1 cursor-pointer">
                        {t("PriceMultiplier.commissionsText5")}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex space-x-2 w-full pt-4">
              <CleaningIcon className="h-8 w-8" />
              <div className="flex flex-col pt-1">
                <p className="text-xs text-low-contrast font-bold">
                  {t("PriceMultiplier.reminderTitle")}
                </p>
                <div className="flex pt-4">
                  <p className="text-xs text-low-contrast">
                    {t("PriceMultiplier.reminderInfo")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <DisplayButtons />
      </div>
    </>
  );
};

export default PriceMultiplier;
