import React from "react";
import {
  GuestWebsiteRentalDetailsExtra,
  GuestWebsiteRentalDetailsWebsiteService,
} from "../../../../../../types/GETTypes";
import useGuestWebsiteStore from "../../../../../../stores/guestWebsiteStore/useGuestWebsiteStore";

type ServicesAndExtras =
  | GuestWebsiteRentalDetailsExtra
  | GuestWebsiteRentalDetailsWebsiteService;

type ImageExtraCardProps = {
  index: number;
  image: string;
  title: string;
  subtitle?: string;
} & ServicesAndExtras;

const ImageDescriptionCard = ({
  index,
  image,
  title,
  subtitle = "",
}: ImageExtraCardProps) => {
  const {dataGuestWebsite} = useGuestWebsiteStore();
  const {styles} = dataGuestWebsite || {};
  const {color_main,color_text} = styles || {};
  return (
    <div
      key={index}
      className="flex flex-col  bg-white rounded-lg shadow-lg overflow-hidden p-2 pb-0 flex-1"
    >
      <img
        src={image}
        alt={title}
        className="w-full h-32 object-contain"
        style={{color: color_text}}
      />
      <div className="flex flex-col flex-1 justify-between p-2 pl-0 text-start space-y-2">
        <p className="text-sm font-medium" style={{color: color_text}}>
          {title}
        </p>
        {subtitle && (
          <p className="text-sm font-bold" style={{color: color_main}}>
            {subtitle}
          </p>
        )}
      </div>
    </div>
  );
};

export default ImageDescriptionCard;
