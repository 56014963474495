import React from "react";
import {GuestWebsiteCommonProps} from "../GuestWebsiteLayout";
import useGuestWebsiteStore from "../../../stores/guestWebsiteStore/useGuestWebsiteStore";
import {GuestWebsiteModel} from "../../../types/GETTypes";
import GuestWebsiteTemplate2RentalsComponent from "./Template2.0/GuestWebsiteTemplate2RentalsComponent";

interface GuestWebsiteRentalsPageProps extends GuestWebsiteCommonProps {}

const RedirectToCorrectTemplate = (props: GuestWebsiteRentalsPageProps) => {
  const {templateGuestWebsite} = useGuestWebsiteStore();
  switch (templateGuestWebsite) {
    case GuestWebsiteModel.MODEL_2_0:
      return <GuestWebsiteTemplate2RentalsComponent {...props} />;
    case GuestWebsiteModel.MODEL_CHIC:
      return <></>;
    case GuestWebsiteModel.MODEL_MINIMAL:
      return <></>;
    default:
      return <GuestWebsiteTemplate2RentalsComponent {...props} />;
  }
};

const GuestWebsiteRentalsPage = (props: GuestWebsiteRentalsPageProps) => {
  return <RedirectToCorrectTemplate {...props} />;
};

export default GuestWebsiteRentalsPage;
