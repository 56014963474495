import React, {useEffect} from "react";
import {GuestWebsiteCommonProps} from "../GuestWebsiteLayout";
import useGuestWebsiteStore from "../../../stores/guestWebsiteStore/useGuestWebsiteStore";
import {GuestWebsiteModel} from "../../../types/GETTypes";
import GuestWebsiteTemplate2RentalComponent from "./Template2.0/GuestWebsiteTemplate2RentalComponent";
import useGuestWebsite from "../../../hooks/useGuestWebsite";

interface GuestWebsiteRentalPageProps extends GuestWebsiteCommonProps {
  rentalId: string;
}

const RedirectToCorrectTemplate = (props: GuestWebsiteRentalPageProps) => {
  const {templateGuestWebsite} = useGuestWebsiteStore();
  switch (templateGuestWebsite) {
    case GuestWebsiteModel.MODEL_2_0:
      return <GuestWebsiteTemplate2RentalComponent {...props} />;
    case GuestWebsiteModel.MODEL_CHIC:
      return <></>;
    case GuestWebsiteModel.MODEL_MINIMAL:
      return <></>;
    default:
      return <GuestWebsiteTemplate2RentalComponent {...props} />;
  }
};

const GuestWebsiteRentalPage = (props: GuestWebsiteRentalPageProps) => {
  const {rentalId} = props;
  const {getRentalDetails, isLoadingDetails, dataRentalDetails, errorWebsite} =
    useGuestWebsite();
  const {setTabGuestWebsite, setRentalData} = useGuestWebsiteStore();
  useEffect(() => {
    if(rentalId){
      getRentalDetails(rentalId);
    }
  }, []);

  useEffect(() => {
    if (dataRentalDetails && !errorWebsite) {
      setRentalData(dataRentalDetails);
    } else if (errorWebsite) {
      setTabGuestWebsite("error");
    }
  }, [dataRentalDetails, errorWebsite]);

  return <RedirectToCorrectTemplate {...props} loading={isLoadingDetails} />;
};

export default GuestWebsiteRentalPage;
