import React from "react";
import CloseIcon from "../../../assets/icons/close.svg?react";
import { cn } from "../../../helpers/classHelper";
import { useCheckAuth } from "../../../hooks/useCheckAuth";
import { ChildrenType, ClassNamesType } from "../../../types/commonTypes";

export const FullScreenLayout: React.FC<{
  children: ChildrenType;
  classNames?: ClassNamesType;
  isCheckAuthEnabled?: boolean;
  onClose?: () => void;
}> = ({ children, classNames, isCheckAuthEnabled = true, onClose }) => {
  useCheckAuth(isCheckAuthEnabled);

  return (
    <>
      {/* Close */}
      {onClose && (
        <div className="absolute top-4 right-8">
          <CloseIcon className="w-8 h-8 cursor-pointer" onClick={onClose} />
        </div>
      )}

      <div className="flex items-center justify-center w-full min-h-screen">
        <div
          className={cn(
            "flex flex-row min-w-[1024px] w-4/5 max-w-[1270px] min-h-[700px] max-h-[810px]",
            classNames?.divParent
          )}
        >
          {children}
        </div>
      </div>
    </>
  );
};
