import React from "react";

const SkeletonFooterTemplate2 = () => {
  return (
    <footer className="bg-element-border text-white py-8 px-4">
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
        {/* Logo and About Section */}
        <div>
          <div className="h-12 w-32 bg-gray-500 animate-pulse mb-4"></div>
          <div className="h-4 w-48 bg-gray-500 animate-pulse"></div>
        </div>

        {/* Navigation Links */}
        <div className="flex flex-col space-y-4">
          <div>
            <div className="h-4 w-24 bg-gray-500 animate-pulse mb-2"></div>
            <ul className="space-y-2">
              <li className="h-4 w-32 bg-gray-500 animate-pulse"></li>
              <li className="h-4 w-40 bg-gray-500 animate-pulse"></li>
              <li className="h-4 w-36 bg-gray-500 animate-pulse"></li>
              <li className="h-4 w-28 bg-gray-500 animate-pulse"></li>
            </ul>
          </div>
          <div>
            <div className="h-4 w-24 bg-gray-500 animate-pulse mb-2"></div>
            <ul className="space-y-2">
              <li className="h-4 w-40 bg-gray-500 animate-pulse"></li>
              <li className="h-4 w-36 bg-gray-500 animate-pulse"></li>
              <li className="h-4 w-48 bg-gray-500 animate-pulse"></li>
            </ul>
          </div>
        </div>

        {/* Payment and Security Section */}
        <div>
          <div className="h-4 w-48 bg-gray-500 animate-pulse mb-4"></div>
          <div className="flex space-x-4 mb-4">
            <div className="h-8 w-12 bg-gray-500 animate-pulse"></div>
            <div className="h-8 w-12 bg-gray-500 animate-pulse"></div>
            <div className="h-8 w-12 bg-gray-500 animate-pulse"></div>
            <div className="h-8 w-12 bg-gray-500 animate-pulse"></div>
          </div>
          <div className="h-4 w-full bg-gray-500 animate-pulse"></div>
          <div className="h-4 w-3/4 bg-gray-500 animate-pulse mt-2"></div>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="mt-8 border-t border-gray-400 pt-4 text-center">
        <div className="h-4 w-64 bg-gray-500 animate-pulse mx-auto"></div>
      </div>
    </footer>
  );
};

export default SkeletonFooterTemplate2;
