import React from "react";
import backgroundImage from "../../../../assets/images/info1_website.jpeg";
import useGuestWebsiteStore from "../../../../stores/guestWebsiteStore/useGuestWebsiteStore";
import {useTranslation} from "react-i18next";
import CalendarIcon from "../../../../assets/icons/calendar.svg?react";

const Info1Component = () => {
  const {t} = useTranslation();
  const {setTabGuestWebsite, dataGuestWebsite} = useGuestWebsiteStore();
  const {content} = dataGuestWebsite || {};
  const {info1} = content || {};
  return (
    <div
      className="relative bg-cover bg-center py-12 px-4 md:px-contentWebsite"
      style={{backgroundImage: `url(${info1?.background})`}}
    >
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>{" "}
      {/* Overlay */}
      <div className="relative z-10 flex flex-col items-center justify-center h-full text-center text-white">
        {/* Title */}
        <h1 className="text-3xl md:text-5xl font-bold leading-tight">
          {info1?.title}
        </h1>
        {/* Subtitle */}
        <h2 className="text-lg md:text-2xl mt-4">{info1?.subtitle}</h2>
        {/* Description */}
        <p
          className="mt-6 text-sm md:text-base max-w-2xl"
          dangerouslySetInnerHTML={{__html: info1?.text ?? ""}}
        />
        {/* Button */}
        <button
          className="flex items-center mt-8 gap-x-1 bg-white text-black font-semibold py-3 px-6 rounded-lg shadow-md hover:bg-gray-200 transition"
          onClick={() => setTabGuestWebsite("rentals")}
        >
          {t("GuestWebsite.Home.book")} <CalendarIcon />
        </button>
      </div>
    </div>
  );
};

export default Info1Component;
