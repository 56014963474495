export const getPaymentMessageByPaymentSchedule: (
  isDeposit: boolean,
  isMultiplePayment: boolean,
  index: number
) => { subject: string; message: string } = (
  isDeposit,
  isMultiplePayment,
  index
) => {
  if (isDeposit) {
    return {
      subject: "Caution - [RENTAL_NAME]",
      message: `Bonjour [GUEST_FIRSTNAME],

Votre séjour approche ! Il est temps d'effectuer le dépôt de garantie par carte bancaire via ce lien : [DEPOSIT_PAYMENT_LINK] 

N'hésitez pas à nous contacter si vous avez la moindre question, nous y répondrons avec plaisir. 

A très bientôt ! [RENTAL_HOST_NAME]`,
    };
  } else if (!isDeposit) {
    if (isMultiplePayment) {
      let message = `Bonjour [GUEST_FIRSTNAME], 

Votre réservation du [RESERVATION_CHECKIN_DATE] au [RESERVATION_CHECKOUT_DATE] est bien enregistrée dans le logement [RENTAL_NAME]. 

Le montant du premier paiement de la réservation est de [FIRST_PAYMENT_AMOUNT] €. 

Je vous invite à effectuer votre premier règlement en cliquant sur le lien suivant: [FIRST_PAYMENT_LINK] 
      `;

      switch (index) {
        case 0:
          message = `Bonjour [GUEST_FIRSTNAME], 
            
Votre réservation du [RESERVATION_CHECKIN_DATE] au [RESERVATION_CHECKOUT_DATE] est bien enregistrée dans le logement [RENTAL_NAME]. 

Le montant du premier paiement de la réservation est de [FIRST_PAYMENT_AMOUNT] €. 

Je vous invite à effectuer votre premier règlement en cliquant sur le lien suivant: [FIRST_PAYMENT_LINK] 
          `;
          break;

        case 1:
          message = `Bonjour [GUEST_FIRSTNAME], 

Votre réservation du [RESERVATION_CHECKIN_DATE] au [RESERVATION_CHECKOUT_DATE] est bien enregistrée dans le logement [RENTAL_NAME]. 

Le montant du deuxième paiement de la réservation est de [SECOND_PAYMENT_AMOUNT] €. 

Je vous invite à effectuer votre deuxième règlement en cliquant sur le lien suivant: [SECOND_PAYMENT_LINK] 
            `;
          break;

        case 2:
          message = `Bonjour [GUEST_FIRSTNAME], 

Votre réservation du [RESERVATION_CHECKIN_DATE] au [RESERVATION_CHECKOUT_DATE] est bien enregistrée dans le logement [RENTAL_NAME]. 

Le montant du troisième paiement de la réservation est de [THIRD_PAYMENT_AMOUNT] €. 

Je vous invite à effectuer le règlement du solde restant en cliquant sur le lien suivant: [THIRD_PAYMENT_LINK] 
              `;
          break;

        default:
          break;
      }

      return {
        subject:
          "Confirmez le paiement de votre réservation - [RENTAL_NAME] - [GUEST_FIRSTNAME]",
        message,
      };
    } else {
      return {
        subject:
          "Confirmez le paiement de votre réservation - [RENTAL_NAME] - [GUEST_FIRSTNAME]",
        message: `Bonjour [GUEST_FIRSTNAME],

Votre réservation du [RESERVATION_CHECKIN_DATE] au [RESERVATION_CHECKOUT_DATE] est bien enregistrée dans le logement [RENTAL_NAME].

Le montant de la réservation est de [SINGLE_PAYMENT_AMOUNT] €. 

Je vous invite à effectuer le règlement en cliquant sur le lien suivant: [SINGLE_PAYMENT_LINK] 
  `,
      };
    }
  }

  return {
    subject: "Default subject",
    message: "Default message",
  };
};
