import React from "react";
import {StatsPlatformData} from "./StackedBarChartComponent";

interface PlatformTableProps {
  data: StatsPlatformData[];
}
const PlatformTableComponent = ({data}: PlatformTableProps) => {
  return (
    <div>
      
      {data.map((item, index) => (
        <div
          key={index}
          className="flex items-center justify-between border-b py-2 last:border-0"
        >
          
          <div className="flex items-center">
            
            {item.icon}
            <div>
              
              <div className="text-low-contrast">{item.name}</div>
              
            </div>
            
          </div>
          
          <div className="space-x-2">
            
            <span className="font-bold">
              {item?.value?.toLocaleString('fr-FR')} €
            </span>{" "}
            
            <span className="text-gray-500">({item?.percentage?.toLocaleString('fr-FR')}%)</span>
            
          </div>
          
        </div>
      ))}
      
    </div>
  );
};

export default PlatformTableComponent;
