import React, {useEffect} from "react";
import {GuestWebsiteCommonProps} from "../GuestWebsiteLayout";
import useGuestWebsiteStore from "../../../stores/guestWebsiteStore/useGuestWebsiteStore";
import {GuestWebsiteModel} from "../../../types/GETTypes";
import GuestWebsiteTemplate2ServicesComponent from "./Template2.0/GuestWebsiteTemplate2ServicesComponent";
import useGuestWebsite from "../../../hooks/useGuestWebsite";

interface GuestWebsiteServicesPageProps extends GuestWebsiteCommonProps {}

const RedirectToCorrectTemplate = (props: GuestWebsiteServicesPageProps) => {
  const {templateGuestWebsite, dataGuestWebsite, setTabGuestWebsite} =
    useGuestWebsiteStore();
  const {isLoadingWebsite} = useGuestWebsite();
  const enabled = dataGuestWebsite?.content?.services?.enabled;
  useEffect(() => {
    if (!enabled && !isLoadingWebsite) {
      setTabGuestWebsite("home");
    }
  }, []);
  switch (templateGuestWebsite) {
    case GuestWebsiteModel.MODEL_2_0:
      return <GuestWebsiteTemplate2ServicesComponent {...props} />;
    case GuestWebsiteModel.MODEL_CHIC:
      return <></>;
    case GuestWebsiteModel.MODEL_MINIMAL:
      return <></>;
    default:
      return <GuestWebsiteTemplate2ServicesComponent {...props} />;
  }
};

const GuestWebsiteServicesPage = (props: GuestWebsiteServicesPageProps) => {
  return <RedirectToCorrectTemplate {...props} />;
};

export default GuestWebsiteServicesPage;
