import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import ListIcon from "../../assets/icons/list.svg?react";
import TableRowsIcon from "../../assets/icons/table-rows.svg?react";
import { Button } from "../../components/Common/Button/Button";
import { CenterModal } from "../../components/Common/CenterModal/CenterModal";
import { ErrorMessage } from "../../components/Common/ErrorMessage/ErrorMessage";
import { GroupButton } from "../../components/Common/GroupButton/GroupButton";
import { GroupButtonItemType } from "../../components/Common/GroupButton/GroupButton.type";
import { TableList } from "../../components/Common/TableList/TableList";
import { TableListGroupButtonItemType } from "../../components/Common/TableList/TableList.type";
import { TableListWithGroup } from "../../components/Common/TableList/TableListWithGroup";
import { MainLayout } from "../../components/Layout/MainLayout/MainLayout";
import { RentalGroupModal } from "../../components/Modal/Rental/Group/RentalGroupModal";
import { RentalGroupUseMode } from "../../components/Modal/Rental/Group/RentalGroupModal.type";
import { RentalListDeactivateModal } from "../../components/RentalList/DeactivateModal";
import { RentalListMultipleSelectionModal } from "../../components/RentalList/MultipleSelectionModal";
import { NoRentalRentalList } from "../../components/RentalList/NoRental/NoRentalRentalList";
import { NoRentalGroupRentalList } from "../../components/RentalList/NoRentalGroup/NoRentalGroupRentalList";
import { RentalListRemoveModal } from "../../components/RentalList/RemoveModal";
import { RentalFilters } from "../../components/RentalList/RentalFilters/RentalFilters";
import { RentalItem } from "../../components/RentalList/RentalTable/RentalItem";
import { RentalItemSkeleton } from "../../components/RentalList/RentalTable/RentalItemSkeleton";
import { RentalTableHeader } from "../../components/RentalList/RentalTable/RentalTableHeader";
import paths from "../../constants/paths";
import { post } from "../../helpers/APIHelper";
import { DisableMultipleRentalItemResponse } from "../../hooks/api/rental";
import { useDeleteRentalGroup } from "../../hooks/api/rentalGroups";
import { useModal } from "../../hooks/useModal";
import { useTablePage } from "../../hooks/useTablePage";
import { ValueType } from "../../types/commonTypes";
import { RentalLightListItemResponse } from "../../types/GETTypes";
import { RentalGroup } from "../RentalPage/RentalPage.type";
import { RentalListPageProps } from "./RentalListPage.type";

export const RentalListPage: React.FC<RentalListPageProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [validationLoading, setValidationLoading] = useState<boolean>(false);

  const [createGroupError, setCreateGroupError] = useState<
    string | undefined
  >();
  const [updateGroupError, setUpdateGroupError] = useState<
    string | undefined
  >();
  const [deleteGroupError, setDeleteGroupError] = useState<
    string | undefined
  >();

  const [isAddRentalGroupVisible, setIsAddRentalGroupVisible] =
    useState<boolean>(false);
  const [modalUseMode, setModalUseMode] =
    useState<RentalGroupUseMode>("create");
  const [currentRentalGroup, setCurrentRentalGroup] = useState<
    RentalGroup | undefined
  >();

  const [isDeleteRentalGroupVisible, setIsDeleteRentalGroupVisible] =
    useState<boolean>(false);

  const [selectedViewType, setSelectedViewType] =
    useState<TableListGroupButtonItemType | null>(null);

  const { pathname } = useLocation();
  useEffect(() => {
    let splitPath = pathname.toLowerCase().split("/");

    if (
      splitPath[splitPath.length - 1] === paths.RENTALS_LIST_VIEW ||
      splitPath[splitPath.length - 1] === paths.RENTALS_GROUP_VIEW
    ) {
      setSelectedViewType(
        splitPath[splitPath.length - 1] as TableListGroupButtonItemType
      );
    } else {
      setSelectedViewType(
        paths.RENTALS_LIST_VIEW as TableListGroupButtonItemType
      );
    }
  }, [pathname]);

  // * -- Handle group button view states --
  const [viewTypeGroupButtonItems, setViewTypeGroupButtonItems] = useState<
    GroupButtonItemType[]
  >([
    {
      label: t("RentalList.listView"),
      value: paths.RENTALS_LIST_VIEW,
      Icon: ListIcon,
      isActive: false,
    },
    {
      label: t("RentalList.groupView"),
      value: paths.RENTALS_GROUP_VIEW,
      Icon: TableRowsIcon,
      isActive: false,
    },
  ]);

  // * -- Handle group button view states logic --
  const rentalsTablePage = useTablePage(
    `${import.meta.env.VITE_API_URL}${paths.API.RENTALS}`,
    "rentals"
  );

  const rentalGroupsTablePage = useTablePage(
    `${import.meta.env.VITE_API_URL}${paths.API.RENTAL_GROUPS}`,
    "rental_groups",
    { otherMetas: ["total_rentals"] }
  );

  useEffect(() => {
    // * Update view type active button
    setViewTypeGroupButtonItems((prevData) =>
      prevData.map((item) =>
        item.value === selectedViewType
          ? { ...item, isActive: true }
          : { ...item, isActive: false }
      )
    );

    // * We are using the fetch function depending on the state of selectedViewType
    switch (selectedViewType) {
      case paths.RENTALS_LIST_VIEW:
        rentalsTablePage.fetch({
          sort_field: "custom_sort_order",
        });
      case paths.RENTALS_GROUP_VIEW:
        rentalGroupsTablePage.fetch({});
    }

    if (selectedViewType) {
      window.history.pushState({}, "", `${paths.RENTALS}/${selectedViewType}`);
    }
  }, [selectedViewType]);

  // const handleClickRental = (id: ValueType | null) => {
  //   if (id !== null) {
  //     navigate(`${paths.RENTALS}/${id}`);
  //   }
  // };

  const handleAddRental = () => {
    navigate(paths.RENTALS_ADD);
  };

  const getGroupViewFiltersNode = () => {
    return (
      <RentalFilters
        rentals={rentalsTablePage.data}
        onAddRentalGroup={handleRentalGroupModal}
        onAddRental={handleAddRental}
        onSearchText={rentalGroupsTablePage.handleChangeSearch}
      />
    );
  };

  const getListViewFiltersNode = () => {
    return (
      <RentalFilters
        rentals={rentalsTablePage.data}
        onAddRental={handleAddRental}
        onSearchText={rentalsTablePage.handleChangeSearch}
      />
    );
  };

  // * Handle group button view type
  const handleClickButtonViewType = (value: ValueType) => {
    setSelectedViewType(value as TableListGroupButtonItemType);
  };

  const getGroupButtonViewTypeNode = () => {
    return (
      <div className="mb-4">
        <GroupButton
          isFetching={rentalsTablePage.loading}
          items={viewTypeGroupButtonItems}
          width="fit"
          onClick={handleClickButtonViewType}
        />
      </div>
    );
  };

  const handleRentalGroupModal = (
    useMode: RentalGroupUseMode = "create",
    isVisible: boolean = false,
    rentalGroup: RentalGroup | undefined
  ) => {
    if (rentalGroup?.id !== 0) {
      setModalUseMode(useMode);
      setIsAddRentalGroupVisible(isVisible);
      setCurrentRentalGroup(rentalGroup);
    }
  };

  const handleTriggerRentalGroup = (
    isVisible: boolean = false,
    rentalGroup: RentalGroup | undefined
  ) => {
    if (rentalGroup?.id !== 0) {
      setIsDeleteRentalGroupVisible(isVisible);
      setCurrentRentalGroup(rentalGroup);
    }
  };

  const handleRentalOrder = async (nextRentalIds: string) => {
    await post(
      `${import.meta.env.VITE_API_URL}${paths.API.UPDATE_ORDER_RENTALS}`,
      {
        rental_ids: nextRentalIds,
      }
    );
  };

  const [error, setError] = useState<string | null>(null);
  const [selectedRentals, setSelectedRentals] = useState<ValueType[]>([]);

  const parametersModal = useModal<{}>();
  const removeModal = useModal<ValueType | ValueType[]>();
  const deactivateModal = useModal<ValueType | ValueType[]>();
  const multipleSelectionModal = useModal<ValueType[]>();

  const handleClickActions = (id: ValueType, action: ValueType) => {
    if (action === "select") {
      const exists = selectedRentals?.findIndex((i) => i === id) > -1;
      setSelectedRentals((prevSelectedItems) => {
        if (prevSelectedItems) {
          if (!exists) {
            return [...prevSelectedItems, id];
          } else {
            return prevSelectedItems.filter((i) => i !== id);
          }
        }

        return [id];
      });
    } else if (action === "remove") {
      removeModal.open(id);
    } else if (action === "details") {
      if (id !== null) {
        navigate(`${paths.RENTALS}/${id}`);
      }
    } else if (action === "deactivate") {
      deactivateModal.open(id);
    }
  };

  const handleDeactivate = (data: DisableMultipleRentalItemResponse[]) => {
    const dataMap = new Map(data.map((d) => [d.id, d]));

    if (selectedViewType === paths.RENTALS_LIST_VIEW) {
      rentalsTablePage.setData((prevRentals: RentalLightListItemResponse[]) => {
        if (Array.isArray(prevRentals) && prevRentals.length > 0) {
          return prevRentals.map((rental) =>
            selectedRentals.indexOf(rental.id) !== -1
              ? { ...rental, ...dataMap.get(rental.id) }
              : rental
          );
        }

        return [];
      });
    } else if (selectedViewType === paths.RENTALS_GROUP_VIEW) {
      rentalGroupsTablePage.setData((prevGroups: RentalGroup[]) => {
        if (Array.isArray(prevGroups) && prevGroups.length > 0) {
          return prevGroups.map((group) => ({
            ...group,
            rentals: group.rentals.map((rental) =>
              selectedRentals.indexOf(rental.id) !== -1
                ? { ...rental, ...dataMap.get(rental.id) }
                : rental
            ),
          }));
        }
        return [];
      });
    }

    setSelectedRentals([]);
  };

  const handleRemove = (data: ValueType[]) => {
    if (selectedViewType === paths.RENTALS_LIST_VIEW) {
      rentalsTablePage.setData((prevRentals: RentalLightListItemResponse[]) => {
        if (Array.isArray(prevRentals) && prevRentals.length > 0) {
          return prevRentals.filter(
            (currentRental) => !data.includes(currentRental.id)
          );
        }

        return [];
      });
    } else if (selectedViewType === paths.RENTALS_GROUP_VIEW) {
      rentalGroupsTablePage.setData((prevGroups: RentalGroup[]) => {
        if (Array.isArray(prevGroups) && prevGroups.length > 0) {
          return prevGroups.map((group) => ({
            ...group,
            rentals: group.rentals.filter(
              (currentRental) => !data.includes(currentRental.id)
            ),
          }));
        }

        return [];
      });
    }

    setSelectedRentals([]);
  };

  const handleError = (message: string | null) => {
    setError(message);

    if (message) {
      parametersModal.close();
      removeModal.close();
      deactivateModal.close();

      setSelectedRentals([]);
    }
  };

  useEffect(() => {
    if (selectedRentals.length === 0) {
      multipleSelectionModal.close();
    } else {
      multipleSelectionModal.open(selectedRentals);
    }
  }, [selectedRentals]);

  return (
    <>
      {isAddRentalGroupVisible ? (
        <RentalGroupModal
          tablePage={rentalGroupsTablePage}
          isVisible={isAddRentalGroupVisible}
          rentalGroup={currentRentalGroup}
          useMode={modalUseMode}
          onTriggerRemoveRentalGroup={() => setIsDeleteRentalGroupVisible(true)}
          onClose={() => setIsAddRentalGroupVisible(false)}
          onCreateGroupError={(message: string | undefined) =>
            setCreateGroupError(message)
          }
          onUpdateGroupError={(message: string | undefined) =>
            setUpdateGroupError(message)
          }
        />
      ) : null}

      <CenterModal
        isVisible={isDeleteRentalGroupVisible}
        onClose={() => setIsDeleteRentalGroupVisible(false)}
      >
        <div className="flex flex-col gap-5">
          <p className="text-xl font-extrabold">
            {t("RentalList.deleteRentalGroupTitle")}
          </p>
          <p className="text-sm text-low-contrast">
            {t("RentalList.deleteRentalGroupSubTitle")}
          </p>
          <div className="flex gap-3">
            <Button
              type="secondary"
              disabled={validationLoading}
              onClick={() => setIsDeleteRentalGroupVisible(false)}
            >
              {t("Global.cancel")}
            </Button>
            <Button
              type="destructive"
              loading={validationLoading}
              onClick={() =>
                useDeleteRentalGroup(
                  currentRentalGroup?.id,
                  () => {
                    setIsDeleteRentalGroupVisible(false);
                    rentalGroupsTablePage.fetch({});
                  },
                  (message: string | undefined) => setCreateGroupError(message),
                  () => {
                    setDeleteGroupError(undefined);
                    setValidationLoading(true);
                  },
                  () => {
                    setValidationLoading(false);
                  }
                )
              }
            >
              {t("Global.removeWithConfirmation")}
            </Button>
          </div>
        </div>
      </CenterModal>

      <RentalListRemoveModal
        modal={removeModal}
        selectedRentals={selectedRentals}
        onRemove={handleRemove}
        onError={handleError}
        onTriggerDeactivateModal={() => {
          removeModal.close();
          deactivateModal.open(selectedRentals);
        }}
      />

      <RentalListDeactivateModal
        modal={deactivateModal}
        selectedRentals={selectedRentals}
        onDeactivate={handleDeactivate}
        onError={handleError}
      />

      <RentalListMultipleSelectionModal
        modal={multipleSelectionModal}
        onClose={() => {
          setSelectedRentals([]);
        }}
        onTriggerDeactivate={() => deactivateModal.open(selectedRentals)}
        onTriggerRemove={() => removeModal.open(selectedRentals)}
      />

      <MainLayout title={t("RentalList.title")} sidebarActiveItem="rentals">
        <>
          {selectedViewType === paths.RENTALS_LIST_VIEW && (
            <TableList
              i18nElement="Global.rentals"
              Filters={getListViewFiltersNode()}
              ViewTypeGroupButton={getGroupButtonViewTypeNode()}
              Header={RentalTableHeader}
              Item={RentalItem}
              Skeleton={RentalItemSkeleton}
              NoData={<NoRentalRentalList />}
              // onClick={handleClickRental}
              tablePage={rentalsTablePage}
              selectedItems={selectedRentals}
              onClick={(id) => handleClickActions(id, "details")}
              onClickAction={handleClickActions}
              draggableItem={true}
              onChangeOrder={handleRentalOrder}
            />
          )}

          {selectedViewType === paths.RENTALS_GROUP_VIEW && (
            <>
              <ErrorMessage>{createGroupError}</ErrorMessage>
              <ErrorMessage>{updateGroupError}</ErrorMessage>
              <ErrorMessage>{deleteGroupError}</ErrorMessage>
              <TableListWithGroup
                i18nElement="Global.rentals"
                Filters={getGroupViewFiltersNode()}
                ViewTypeGroupButton={getGroupButtonViewTypeNode()}
                Header={RentalTableHeader}
                Item={RentalItem}
                Skeleton={RentalItemSkeleton}
                NoData={
                  <NoRentalGroupRentalList
                    onAdd={() => setIsAddRentalGroupVisible(true)}
                  />
                }
                tablePage={rentalGroupsTablePage}
                nbCols={6}
                selectedItems={selectedRentals}
                // onClick={handleClickRental}
                onClick={(id) => handleClickActions(id!, "details")}
                onClickAction={handleClickActions}
                onTriggerUpdateGroup={handleRentalGroupModal}
                onTriggerRemoveGroup={handleTriggerRentalGroup}
              />
            </>
          )}
        </>
      </MainLayout>
    </>
  );
};
