import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import ArrowRightWhiteIcon from "../../../assets/icons/arrow-right-white.svg?react";
import { Button } from "../../Common/Button/Button";
import { Separator } from "../../Common/Separator/Separator";
import { Switch } from "../../Common/Switch/Switch";

import { Link, useNavigate } from "react-router-dom";
import paths from "../../../constants/paths";
import { post } from "../../../helpers/APIHelper";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import { AddRentalType } from "../AddRental.type";
import { DisableRentalModalForRentalDone } from "./DisableRentalModalForRentalDone";

export const AddRentalDone: React.FC<{
  rental: AddRentalType | undefined;
  onDisable: () => void;
  onEnable: () => void;
}> = ({ rental, onDisable, onEnable }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const queryParameters = new URLSearchParams(window.location.search);
  const fromProgression = queryParameters.get("from_progression");

  const [isDisableModalVisible, setIsDisableModalVisible] =
    useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleDisable = async () => {
    setLoading(true);
    setError(null);

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTAL_UPDATE}/${
        rental?.rental_id
      }${paths.API.RENTAL_DISABLE}`
    );

    if (res.data?.success) {
      onDisable();
    } else {
      setError(res.response.data.message);
    }

    setLoading(false);
  };

  const handleEnable = async () => {
    setLoading(true);
    setError(null);

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTAL_UPDATE}/${
        rental?.rental_id
      }${paths.API.RENTAL_ENABLE}`
    );

    if (res.data?.success) {
      onEnable();
    } else {
      setError(res.response.data.message);
    }

    setLoading(false);
  };

  const handleGotoList = () => {
    navigate(paths.RENTALS);
  };

  const handleGotoRental = () => {
    navigate(`${paths.RENTALS}/${rental?.rental_id}`);
  };

  const handleGoToProgression = () => {
    navigate(`${paths.PROGRESSION}`);
  };

  const handleManageDeactivation = () => {
    handleDisable();
    setIsDisableModalVisible(false);
  };

  {
    /* TODO: [AddRentalDone] Ajouter les liens */
  }
  const canDoList = [
    {
      key: "AddRental.Done.CanDoList.connectExternalPlatforms",
      link: "",
    },
    {
      key: "AddRental.Done.CanDoList.defineReservationRules",
      link: "https://www.loom.com/share/b41059a3ef324a42bc67d7d2ddddf3e1?sid=63840711-b5f5-4864-bd73-becf8fc01616",
    },
    {
      key: "AddRental.Done.CanDoList.definePaymentPolicies",
      link: "",
    },
    {
      key: "AddRental.Done.CanDoList.defineMarkupRates",
      link: "https://www.loom.com/share/82e4e176d76f47b091c34a50d6249fb9?sid=0b20d59b-0177-4774-bef9-4344ad26b5fa",
    },
  ];

  return (
    <>
      <DisableRentalModalForRentalDone
        isVisible={isDisableModalVisible}
        rentalId={rental?.rental_id}
        onClose={() => setIsDisableModalVisible(false)}
        onDisable={handleManageDeactivation}
      />

      <div className="text-sm">
        <p className="font-semibold text-xxl text-high-contrast">
          {t("AddRental.Done.title")}
        </p>

        <p className="mt-4 mb-6 text-base font-light text-low-contrast">
          {t("AddRental.Done.subTitle")}
        </p>

        <div className="mt-4 mb-6 text-base font-light text-low-contrast">
          {/* <Trans
            i18nKey="AddRental.Done.content" // optional -> fallbacks to defaults if not provided
            defaults="Ce que vous pouvez maintenant faire : <break /><break />
          <ul>
            <li class='list-disc'>Connecter l’hébergement aux plateformes externes</li>
            <li class='list-disc'>Définir des règles de réservations</li>
            <li class='list-disc'>Créer des règles et des tarifs selon les périodes de l’année</li>
            <li class='list-disc'>Paramètrer les autres frais supplémentaires</li>
            <li class='list-disc'>Définir des politiques de paiement (annulation, paiement, caution)</li>
            <li class='list-disc'>Créer des promotions</li>
            <li class='list-disc'>Définir des taux de majoration par plateformes</li>
            <li class='list-disc'>Connecter des serrures connectées</li>
            <li class='list-disc'>Définir des actions automatisées pour le checkin, le checkout, le ménage, ...</li>
          </ul>
          <break />Et bien plus encore !" // optional defaultValue
            values={{ what: "world" }}
            components={{ break: <br />, ul: <ul />, li: <li /> }}
          /> */}

          <p className="mb-4">{t("AddRental.Done.subTitle2")}</p>

          <ul className="ps-8">
            {canDoList.map((v) => (
              <li className="list-disc">
                <Trans
                  i18nKey={v.key}
                  components={[
                    <Link
                      to={v.link}
                      target="_blank"
                      className="px-[0.5px] font-bold underline underline-offset-4 text-low-contrast"
                    />,
                  ]}
                />
              </li>
            ))}
          </ul>

          <p className="mt-8 mb-2">{t("AddRental.Done.muchMore")}</p>
          {/* TODO: [AddRentalDone] Ajouter le bon lien vidéo */}
          <iframe
            src="https://player.vimeo.com/video/1017867678?h=4b28c39f0a&autoplay=1"
            width="350"
            height="200"
            allow="fullscreen"
          ></iframe>

          <div className="w-full mt-4">
            <Button
              type="secondary"
              onClick={() =>
                window.open(
                  "https://helpsuperhotev2.crisp.help/fr/article/definir-des-regles-dans-superhote-1amzsym/?bust=1728072863213",
                  "_blank"
                )
              }
            >
              {t("Progression.tutorialTitle")}
            </Button>
          </div>

          <Separator accent="dark" />

          {rental?.isActive && (
            <>
              <p className="mt-4 mb-6 font-light text-low-contrast">
                {t("AddRental.Done.content2active")}
              </p>

              <p className="mt-4 mb-6 font-light text-low-contrast">
                {t("AddRental.Done.content3active")}
              </p>

              <div
                className="flex items-center gap-2 cursor-pointer"
                onClick={() => setIsDisableModalVisible(true)}
              >
                <p className="font-light text-active">
                  {t("AddRental.Done.active")}
                </p>

                <Switch value={true} disabled={loading} />
              </div>
            </>
          )}

          {rental?.isActive === false && (
            <>
              <p className="mt-4 mb-6 font-light text-low-contrast">
                {t("AddRental.Done.content2inactive")}
              </p>

              <p className="mt-4 mb-6 font-light text-low-contrast">
                {t("AddRental.Done.content3inactive")}
              </p>

              <div
                className="flex items-center gap-2 cursor-pointer"
                onClick={handleEnable}
              >
                <p className="font-light text-low-contrast">
                  {t("AddRental.Done.inactive")}
                </p>

                <Switch value={false} disabled={loading} />
              </div>
            </>
          )}

          <ErrorMessage>{error}</ErrorMessage>
          {/*
            fromProgression !== "true" && (
            <>
              <Separator accent="dark" />

              <p className="mt-4 mb-6 font-light text-center text-low-contrast">
                {t("AddRental.Done.content4")}
              </p>

              <Button
                RightIcon={ArrowRightWhiteIcon}
                disabled={true || loading}
              >
                {t("AddRental.Done.gotoSeason")}
              </Button>
            </>
          )
          */}
        </div>

        {fromProgression === "true" ? (
          <div className="flex gap-4 pb-4 mt-8">
            <Button
              RightIcon={ArrowRightWhiteIcon}
              loading={loading}
              onClick={handleGoToProgression}
            >
              {t("AddRental.Done.gotoProgression")}
            </Button>
          </div>
        ) : (
          <div className="flex gap-4 pb-4 mt-8">
            <Button
              type="secondary"
              onClick={handleGotoList}
              disabled={loading}
            >
              {t("AddRental.Done.gotoList")}
            </Button>
            <Button
              RightIcon={ArrowRightWhiteIcon}
              loading={loading}
              onClick={handleGotoRental}
            >
              {t("AddRental.Done.gotoRental")}
            </Button>
          </div>
        )}
      </div>
    </>
  );
};
