import { t } from "i18next";
import React from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { Trans } from "react-i18next";
import { RequiredFields, ValueType } from "../../../../types/commonTypes";
import {
  OperationalManagementTeamMemberForUserItemResponse,
  OperationalManagementTeamMemberResponse,
} from "../../../../types/GETTypes";
import InfoCard from "../../../Common/Card/InfoCard";
import { InputMultiSelect } from "../../../Common/InputMultiSelect/InputMultiSelect";
import { InputMultiSelectOptionProps } from "../../../Common/InputMultiSelect/InputMultiSelect.type";
import { SimpleRadio } from "../../../Common/SimpleRadio/SimpleRadio";
import { TaskAssignmentFlow } from "../../TaskAssignmentFlow";
import { ManagementAutomationListAssignationStepForm } from "./AssignationStep.type";

type ManagementAutomationListAssignationStepProps = {
  loading: boolean;
  form: UseFormReturn<ManagementAutomationListAssignationStepForm>;
  persons: OperationalManagementTeamMemberResponse[] | undefined;
  teams: OperationalManagementTeamMemberForUserItemResponse[] | undefined;
};

export const ManagementAutomationListAssignationStep: React.FC<
  ManagementAutomationListAssignationStepProps
> = ({ loading, form, persons, teams }) => {
  const requiredFields: RequiredFields<ManagementAutomationListAssignationStepForm> =
    {
      assignation_type: true,
      assigned_persons: form.getValues("assignation_type") === "OPERATORS",
      assigned_teams: form.getValues("assignation_type") === "TEAMS",
    };

  const getPersonsOrTeamsItems = () => {
    if (
      form.getValues("assignation_type") === "OPERATORS" &&
      persons &&
      persons.length > 0
    ) {
      return persons.map((person) => {
        return {
          label: `${person.first_name} ${person.last_name}`,
          value: person.id,
        };
      });
    } else if (
      form.getValues("assignation_type") === "TEAMS" &&
      teams &&
      teams.length > 0
    ) {
      return teams.map((team) => {
        return {
          label: team.name,
          value: team.id,
        };
      });
    }
    return [];
  };

  const getSelectedTeamsText = (
    selectedTeamIds: ValueType[],
    options: InputMultiSelectOptionProps[],
  ): string => {
    const selectedTeams = options.filter((option) =>
      selectedTeamIds.includes(option.value),
    );

    const selectedLabels = selectedTeams.map((team) => team.label);

    if (selectedLabels.length > 1) {
      const displayedLabels = selectedLabels.slice(0, 1).join(", ");
      const remainingCount = selectedLabels.length - 1;
      return `${displayedLabels} + ${t("Global.others", {
        count: remainingCount,
      })}`;
    } else {
      return selectedLabels.join(", ");
    }
  };

  form.watch();

  return (
    <div className="flex flex-col gap-y-4">
      <p className="text-xs leading-relaxed select-none text-low-contrast text-light">
        {t("Automation.AddOrUpdateModal.Steps.Assignation.description")}
      </p>

      <Controller
        control={form.control}
        name="assignation_type"
        rules={{
          required: requiredFields.assignation_type,
        }}
        render={({ field: { value, onChange } }) => {
          return (
            <div>
              <p className="mb-4 text-xs font-medium text-low-contrast">
                {t(
                  "Automation.AddOrUpdateModal.Steps.Assignation.assignationTypeLabel",
                )}
              </p>
              <div className="flex text-sm font-semibold gap-x-3 text-low-contrast">
                <SimpleRadio
                  classNames={{
                    label: "text-low-contrast font-light text-xs",
                  }}
                  label={t(
                    "Automation.AddOrUpdateModal.Steps.Assignation.assignationTypePersonsLabel",
                  )}
                  disabled={loading}
                  value={value === "OPERATORS"}
                  onClick={() => {
                    onChange("OPERATORS");
                    form.watch();
                  }}
                />
                <SimpleRadio
                  classNames={{
                    label: "text-low- font-light text-xs",
                  }}
                  label={t(
                    "Automation.AddOrUpdateModal.Steps.Assignation.assignationTypeTeamLabel",
                  )}
                  disabled={loading}
                  value={value === "TEAMS"}
                  onClick={() => {
                    onChange("TEAMS");
                    form.watch();
                  }}
                />
              </div>
            </div>
          );
        }}
      />

      {form.getValues("assignation_type") === "OPERATORS" && (
        <InputMultiSelect
          register={form.register("assigned_persons", {
            validate: (value) => {
              if (
                value.length === 0 &&
                form.getValues("assignation_type") === "OPERATORS"
              ) {
                return t("Global.Errors.requiredField", {
                  fieldName: t(
                    "Automation.AddOrUpdateModal.Steps.Assignation.assignationPersonsOrTeamsLabel",
                  ),
                });
              }

              form.clearErrors("assigned_persons");
              return true;
            },
          })}
          label={t(
            "Automation.AddOrUpdateModal.Steps.Assignation.assignationPersonsOrTeamsLabel",
          )}
          required={requiredFields.assigned_persons}
          disabled={loading}
          items={getPersonsOrTeamsItems()}
          selectedValues={form.getValues("assigned_persons")}
          error={form.formState.errors.assigned_persons?.message}
        />
      )}

      {form.getValues("assignation_type") === "TEAMS" && (
        <InputMultiSelect
          register={form.register("assigned_teams", {
            validate: (value) => {
              if (
                value.length === 0 &&
                form.getValues("assignation_type") === "TEAMS"
              ) {
                return t("Global.Errors.requiredField", {
                  fieldName: t(
                    "Automation.AddOrUpdateModal.Steps.Assignation.assignationPersonsOrTeamsLabel",
                  ),
                });
              }

              form.clearErrors("assigned_teams");
              return true;
            },
          })}
          label={t(
            "Automation.AddOrUpdateModal.Steps.Assignation.assignationPersonsOrTeamsLabel",
          )}
          required={requiredFields.assigned_teams}
          disabled={loading}
          items={getPersonsOrTeamsItems()}
          selectedValues={form.getValues("assigned_teams")}
          error={form.formState.errors.assigned_teams?.message}
        />
      )}

      {form.getValues("assignation_type") === "TEAMS" &&
        form.getValues("assigned_teams") &&
        form.getValues("assigned_teams").length > 0 && (
          <InfoCard
            title={
              <Trans
                i18nKey={
                  "Automation.AddOrUpdateModal.Steps.Assignation.assignationTeamsInfoTitle"
                }
                components={{
                  strong: <span className="font-bold"></span>,
                }}
                values={{
                  teamName: getSelectedTeamsText(
                    form.getValues("assigned_teams"),
                    getPersonsOrTeamsItems(),
                  ),
                }}
              />
            }
            description={
              <Trans
                i18nKey={
                  "Automation.AddOrUpdateModal.Steps.Assignation.assignationTeamsInfoDescription"
                }
                components={{
                  strong: <span className="font-bold"></span>,
                }}
              />
            }
          />
        )}

      {/* TASK ASSIGNMENT FLOW */}
      {form.getValues("assignation_type") === "TEAMS" && <TaskAssignmentFlow />}
    </div>
  );
};
