import React from "react";
import { PageMenu } from "../Common/PageMenu/PageMenu";

import WWWIcon from "../../assets/icons/www.svg?react";
import WWWIconHigh from "../../assets/icons/www-high.svg?react";

import ScrollIcon from "../../assets/icons/scroll.svg?react";
import ScrollHighIcon from "../../assets/icons/scroll-high.svg?react";

import CodeIcon from "../../assets/icons/code.svg?react";
import CodehighIcon from "../../assets/icons/code-high.svg?react";

export const WebsiteSettingsMenu: React.FC<{}> = () => {
  return (
    <PageMenu
      items={[
        {
          Icon: WWWIcon,
          IconActive: WWWIconHigh,
          anchor: "url-card",
          labelI18n: "Website.URL.title",
        },
        {
          Icon: ScrollIcon,
          IconActive: ScrollHighIcon,
          anchor: "legal-card",
          labelI18n: "Website.Legal.title",
        },
        {
          Icon: CodeIcon,
          IconActive: CodehighIcon,
          anchor: "integrations-card",
          labelI18n: "Website.Integrations.title",
        },
      ]}
    />
  );
};
