import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import LayoutBottomIcon from "../../../assets/icons/layout-bottom.svg?react";
import { Card } from "../../Common/Card/Card";
import { WebsiteResponse } from "../../../types/GETTypes";
import { useTranslation } from "react-i18next";
import { useToggleCard } from "./useToggleCard";
import paths from "../../../constants/paths";
import { post } from "../../../helpers/APIHelper";
import CheckIcon from "../../../assets/icons/check-white.svg?react";
import EditIcon from "../../../assets/icons/edit.svg?react";
import ArrowLeftIcon from "../../../assets/icons/arrow-left.svg?react";
import MinusIcon from "../../../assets/icons/minus.svg?react";
import PhotoIcon from "../../../assets/icons/photo.svg?react";
import { TextInput } from "../../Common/TextInput/TextInput";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import { TextAreaInput } from "../../Common/TextAreaInput/TextAreaInput";

export const WebsiteMoreInfos1Card: React.FC<{
  website: WebsiteResponse | undefined;
  onUpdateWebsite: (nextWebsite: WebsiteResponse) => void;
}> = ({ website, onUpdateWebsite }) => {
  const { t } = useTranslation();

  const { isEnabled, isEnabledLoading, toggle } = useToggleCard({
    website,
    initialValue: website?.content.info1.enabled,
    baseUrl: `${
      import.meta.env.VITE_API_URL
    }${paths.API.WEBSITE.UPDATE.INFO1_TOGGLE(website?.id!)}`,
  });

  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const [updateError, setUpdateError] = useState<string | undefined>();

  const [editMode, setEditMode] = useState<boolean>(false);

  const editModeRef = useRef<{
    handleClear: () => void;
    handleCheckData: () => {
      background: Blob | undefined;
      title: string;
      subTitle: string;
      text: string;
    } | null;
  } | null>(null);

  const handleCancel = () => {
    setEditMode(false);
    editModeRef.current?.handleClear();
  };

  const handleUpdate = async () => {
    const values = editModeRef.current?.handleCheckData();

    if (!values) return;

    setUpdateLoading(true);
    setUpdateError(undefined);

    const formData = new FormData();
    formData.append("info1_section_title", values.title);
    formData.append("info1_section_subtitle", values.subTitle);
    formData.append("info1_section_text", values.text);

    if (values.background) {
      formData.append("info1-section-background", values.background);
    }

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.WEBSITE.UPDATE.INFO1(
        website?.id!
      )}`,
      formData
    );

    if (!res?.data?.success) {
      setUpdateError(res?.response?.data?.message);
    } else {
      onUpdateWebsite(res.data?.result);
      setEditMode(false);
    }

    setUpdateLoading(false);
  };

  useEffect(() => {
    setUpdateError(undefined);
  }, [editMode]);

  return (
    <Card
      Icon={LayoutBottomIcon}
      label={t("Website.MoreInfos1.title")}
      anchor="moreinfos1-card"
      switchButton={{
        value: isEnabled,
        label: "",
        loading: isEnabledLoading,
        onClick: toggle,
      }}
      button={{
        Icon: editMode ? CheckIcon : EditIcon,
        type: editMode ? "primary" : "secondary",
        label: editMode ? t("Global.record") : t("Global.edit"),
        onClick: editMode ? () => handleUpdate() : () => setEditMode(!editMode),
        disabled: !website || updateLoading,
      }}
      secondaryButton={
        editMode
          ? {
              label: t("Global.cancel"),
              LeftIcon: ArrowLeftIcon,
              onClick: handleCancel,
              disabled: updateLoading,
            }
          : undefined
      }
    >
      <ErrorMessage>{updateError}</ErrorMessage>

      {editMode ? (
        <EditMode ref={editModeRef} website={website} loading={updateLoading} />
      ) : (
        <ViewMode website={website} />
      )}
    </Card>
  );
};

const ViewMode: React.FC<{ website: WebsiteResponse | undefined }> = ({
  website,
}) => {
  if (!website) return <ViewModeSkeleton />;

  return (
    <div className="flex flex-col gap-3">
      <div className="flex gap-3 w-full pb-2">
        <img
          src={website?.content.info1.background}
          className="h-32 flex-1 border-1 border-element-border rounded-6px object-cover bg-element-background"
        />
      </div>

      <p className="text-high-contrast font-semibold text-center">
        {website?.content.info1.title}
      </p>
      <p className="text-low-contrast font-light text-center">
        {website?.content.info1.subtitle}
      </p>
      <p className="text-black font-light text-center">
        {website?.content.info1.text}
      </p>
    </div>
  );
};

const ViewModeSkeleton: React.FC<{}> = ({}) => {
  return (
    <div className="flex flex-col gap-3 items-center animate-pulse">
      <div className="flex gap-3 w-full pb-2">
        <div className="h-32 flex-1 border-1 border-element-border rounded-6px object-cover bg-element-background" />
      </div>

      <p className="text-high-contrast font-semibold text-center h-2 rounded bg-slate-300 w-72"></p>
      <p className="text-low-contrast font-light text-center h-2 rounded bg-slate-200 w-52 mt-2"></p>
      <p className="text-black font-light text-center h-2 rounded bg-slate-200 w-72 mt-2"></p>
    </div>
  );
};

interface EditModeProps {
  website: WebsiteResponse | undefined;
  loading: boolean;
}

const EditMode = forwardRef<
  {
    handleClear: () => void;
    handleCheckData: () => {
      background: Blob | undefined;
      title: string;
      subTitle: string;
      text: string;
    } | null;
  },
  EditModeProps
>(({ website, loading }, ref) => {
  const { t } = useTranslation();

  const [title, setTitle] = useState<string>("");
  const [titleError, setTitleError] = useState<string | undefined>();

  const [subTitle, setSubTitle] = useState<string>("");

  const [text, setText] = useState<string>();
  const [textError, setTextError] = useState<string | undefined>();

  const handleClear = () => {
    setTitle("");
    setTitleError(undefined);

    setSubTitle("");

    setText("");
    setTextError(undefined);
  };

  const handleCheckData = () => {
    setTitleError(undefined);

    let isError = false;

    if (!title) {
      setTitleError(t("Global.required"));
      isError = true;
    }

    if (!text) {
      setTextError(t("Global.required"));
      isError = true;
    }

    if (isError) return null;

    return {
      background,
      title,
      subTitle,
      text: text || "",
    };
  };

  useImperativeHandle(ref, () => ({
    handleClear,
    handleCheckData,
  }));

  useEffect(() => {
    if (website) {
      setTitle(website?.content.info1.title);
      setTitleError(undefined);

      setSubTitle(website?.content.info1.subtitle);

      setText(website?.content.info1.text);

      setBackgroundURL(website?.content?.info1.background);
    }
  }, [website]);

  // Background
  const [background, setBackground] = useState<Blob>();
  const [backgroundURL, setBackgroundURL] = useState<string>();

  const backgroundInputRef = useRef<HTMLInputElement>(null);
  const handleAddBackground = () => {
    backgroundInputRef.current?.click();
  };

  const handleAddBackgroundFromInput = (event: any) => {
    if (event.target.files?.length === 0) return;

    setBackground(event.target.files[0]);
  };

  const handleRemoveBackground = () => {
    setBackground(undefined);
    setBackgroundURL(undefined);
  };

  // Title, sub title and text
  const handleChangeTitle = (value: string) => {
    setTitle(value);

    setTitleError(undefined);
  };

  const handleChangeSubTitle = (value: string) => {
    setSubTitle(value);
  };

  const handleChangeText = (value: string) => {
    setText(value ?? "");

    setTextError(undefined);
  };

  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-row gap-3">
        {/* Background */}
        <div className="relative flex-1 flex flex-col gap-4 mt-2">
          <input
            type="file"
            multiple={true}
            style={{ display: "none" }}
            ref={backgroundInputRef}
            accept="image/jpeg, image/png"
            onChange={handleAddBackgroundFromInput}
          />

          {(background || backgroundURL) && (
            <div className="w-full h-full group">
              <div
                className="absolute items-center justify-center hidden w-6 h-6 rounded-sm cursor-pointer border-element-border border-1 group-hover:flex bg-white"
                style={{ top: -6, right: -6 }}
                onClick={handleRemoveBackground}
              >
                <MinusIcon width={20} height={20} />
              </div>

              <div className="flex flex-col items-center justify-center w-full h-48 gap-2 overflow-hidden border-dashed bg-element-background border-1 border-element-border rounded-6px hover:bg-element-background-active">
                <img
                  src={
                    background ? URL.createObjectURL(background) : backgroundURL
                  }
                  className="object-cover w-full h-full"
                />
              </div>
            </div>
          )}

          {!background && !backgroundURL && (
            <div
              className="flex flex-col items-center justify-center w-full gap-2 p-4 border-dashed cursor-pointer bg-element-background border-1 border-element-border rounded-6px hover:bg-element-background-active"
              onClick={handleAddBackground}
            >
              <PhotoIcon className="w-10 h-10" />
              <p className="font-semibold text-high-contrast">
                {t("Website.MoreInfos1.addBackground")}
              </p>
              <div className="flex flex-col items-center justify-center gap-1">
                <p className="font-light text-low-contrast">
                  {t("AddRental.Photos.formatAccepted")}
                </p>
                <p className="font-light text-low-contrast">
                  {t("AddRental.Photos.WeightAccepted")}
                </p>
                <p className="font-light text-low-contrast">
                  {t("AddRental.Photos.DimensionsMinimum")}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>

      <TextInput
        label={t("Website.MoreInfos1.titleLabel")}
        value={title}
        onChangeText={handleChangeTitle}
        error={titleError}
        required={true}
        disabled={loading}
      />

      <TextInput
        label={t("Website.MoreInfos1.subTitleLabel")}
        value={subTitle}
        onChangeText={handleChangeSubTitle}
        disabled={loading}
      />

      <TextAreaInput
        label={t("Website.MoreInfos1.textLabel")}
        value={text}
        onTextChange={handleChangeText}
        error={textError}
        required={true}
        disabled={loading}
      />
    </div>
  );
});
