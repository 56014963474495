/**
 * Page to display all payments over a user-defined date range.
 * It shows data lists containing payment statuses, dates, amounts, etc.
 */

import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import _ from "lodash";

// Layouts
import { ManagementLayout } from "../../components/Layout/ManagementLayout/ManagementLayout";

// Components
import { PaymentHeader } from "../../components/ManagementPaymentListPage/PaymentHeader";
import { PaymentFilters } from "../../components/ManagementPaymentListPage/PaymentFilters";
import { PaymentList } from "../../components/ManagementPaymentListPage/Lists/PaymentList";
import { PaymentModalCreate } from "../../components/ManagementPaymentListPage/PaymentModalCreate";
import PaymentListEmpty from "../../components/ManagementPaymentListPage/Lists/PaymentListEmpty";
import { InputSelect } from "../../components/Common/InputSelect/InputSelect";

// Hooks
import { useTranslation } from "react-i18next";
import { useModal } from "../../hooks/useModal";
import {
  FiltersPaymentsOperational,
  usePaymentOperational,
} from "../../hooks/api/paymentOperational";

// Types
import { ManagementPaymentResponse } from "../../types/GETTypes";
import { ValueType } from "../../types/commonTypes";
import { PaymentModalFilters } from "../../components/ManagementPaymentListPage/PaymentModalFilters";

export const ManagementPaymentListPage: React.FC<{}> = () => {
  const { getAllPayment } = usePaymentOperational();
  const { t } = useTranslation();
  const modalFilter = useModal();
  const modalCreate = useModal<any>();

  const [list, setList] = useState<ManagementPaymentResponse | null>(null);
  const [total, setTotal] = useState(0);
  const [hasFilter, setHasFilter] = useState(false);

  /**
   * States filters.
   * See FiltersPaymentsOperational interface for more
   * explains.
   */
  const [filters, setFilters] = useState<FiltersPaymentsOperational>({
    page: 1,
    limit: 10,
    from: moment().subtract(2, "weeks").startOf("day").format("DD-MM-YYYY"),
    to: moment().endOf("day").format("DD-MM-YYYY"),
  });

  /**
   * Function for charge data list
   * with filters.
   */
  const rechargeData = (
    updatedFilters?: Partial<FiltersPaymentsOperational>,
  ) => {
    setList(null);
    const newFilters = {
      ...filters,
      ...updatedFilters,
    };
    setFilters(newFilters);

    getAllPayment(newFilters).then((response) => {
      setList(response[0]);
      setTotal(response[1]);
    });
  };

  /**
   * Use effect for recharge
   * data after refresh page
   */
  useEffect(() => {
    rechargeData();
  }, []);

  /**
   * Functions for interacts with all
   * filters in the page.
   */
  const handleChangeLimit = (limit: number) => {
    rechargeData({
      limit: limit,
    });
  };

  /**
   * Function for filter with search query
   */
  const handleSearch = useCallback(
    _.debounce(async (search: string) => {
      rechargeData({
        search: search,
      });
    }, 500),
    [],
  );

  /**
   * function for handle recharge data with filters date.
   * @param from
   * @param to
   */
  const handleChangeDates = (from?: string, to?: string) => {
    if (!from || !to) return;
    if (filters.from !== from || filters.to !== to) {
      rechargeData({
        from: from,
        to: to,
      });
    }
  };

  const paiementText = " " + t("Management.Payments.paiments");

  return (
    <ManagementLayout subTitle="payments" sidebarActiveSubItem="payments">
      <PaymentModalCreate
        modal={modalCreate}
        handleUpdateSuccess={() => {
          rechargeData(filters);
        }}
      />

      <PaymentHeader
        onSearch={handleSearch}
        onAddPayment={() => {
          modalCreate.open();
        }}
      />

      <PaymentFilters
        onChangeDates={handleChangeDates}
        onClickFilterButton={() => {
          modalFilter.open();
        }}
        hasFilter={hasFilter}
      />

      <PaymentModalFilters
        modal={modalFilter}
        rechargeData={rechargeData}
        filters={filters}
        onResetFilters={() => {
          setHasFilter(false);
        }}
        onSubmitFilters={() => setHasFilter(true)}
      />

      {list && Object.entries(list).length <= 0 ? (
        <PaymentListEmpty modal={modalCreate} />
      ) : (
        <>
          <section className={"flex flex-col justify-between h-full"}>
            <section className={"flex flex-col"}>
              <PaymentList
                list={list}
                rechargeData={rechargeData}
                filters={filters}
              />
            </section>
            <section className={"flex items-center justify-between"}>
              <div className={"flex items-center gap-2"}>
                <p>Afficher jusqu'à</p>
                <InputSelect
                  items={[
                    {
                      value: 10,
                      label: "10" + paiementText,
                    },
                    {
                      value: 25,
                      label: "25" + paiementText,
                    },
                    {
                      value: 50,
                      label: "50" + paiementText,
                    },
                    {
                      value: 200,
                      label: "200" + paiementText,
                    },
                  ]}
                  selectedValue={filters.limit ?? 10}
                  onSelect={(limit: ValueType) => {
                    // @ts-ignore
                    handleChangeLimit(limit);
                  }}
                />
              </div>
            </section>
          </section>
        </>
      )}
    </ManagementLayout>
  );
};
