import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { t } from "i18next";
import React, {
  ChangeEvent,
  CSSProperties,
  forwardRef,
  HTMLAttributes,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CheckIcon from "../../../../assets/icons/check-white.svg?react";
import MinusIcon from "../../../../assets/icons/minus.svg?react";
import PhotoIcon from "../../../../assets/icons/photo.svg?react";
import PlusIcon from "../../../../assets/icons/plus.svg?react";
import {
  RentalWebsiteServicesPayload,
  useRentalWebsiteServicesUpdate,
} from "../../../../hooks/api/rental";
import { UseModal } from "../../../../hooks/useModal";
import { RequiredFields } from "../../../../types/commonTypes";
import {
  RentalWebsiteResponse,
  RentalWebsiteServicesItem,
} from "../../../../types/GETTypes";
import { Button } from "../../../Common/Button/Button";
import { ErrorMessage } from "../../../Common/ErrorMessage/ErrorMessage";
import { RightModal } from "../../../Common/RightModal/RightModal";
import { Separator } from "../../../Common/Separator/Separator";
import { TextInput } from "../../../Common/TextInput/TextInput";
import {
  RentalWebsiteServicesCardForm,
  RentalWebsiteServicesCardServiceItem,
} from "./RentalWebsiteServicesCard.type";

const getFirstFalsyIdIndex = (
  services: RentalWebsiteServicesCardServiceItem[]
) => {
  return services.findIndex((service) => !service.id);
};

type RentalUpdatePhotosModalProps = {
  rentalId: string | undefined;
  modal: UseModal<RentalWebsiteServicesItem[] | undefined>;
  onUpdateServices: (rentalWebsite: RentalWebsiteResponse) => void;
};

export const RentalWebsiteServicesUpdateModal: React.FC<
  RentalUpdatePhotosModalProps
> = ({ rentalId, modal, onUpdateServices }) => {
  const { t } = useTranslation();

  const [error, setError] = useState<string | null>(null);
  const [apiError, setApiError] = useState<string | null>(null);
  const [loadingValidation, setLoadingValidation] = useState<boolean>(false);

  const rentalServices = modal.data;

  const form = useForm<RentalWebsiteServicesCardForm>({
    mode: "all",
    defaultValues: {
      services: [],
    },
  });

  const requiredFields: RequiredFields<
    Omit<RentalWebsiteServicesCardServiceItem, "id" | "index" | "isNewValue">
  > = {
    name: true,
    photo: true,
  };

  useEffect(() => {
    if (
      Array.isArray(form.formState.errors.services) &&
      form.formState.errors.services.length > 0
    ) {
      setError(t("Rental.Website.Services.servicesError"));
    } else {
      setError(null);
    }
  }, [form.formState.errors.services]);

  useEffect(() => {
    if (rentalServices) {
      const mappedServices = rentalServices.map((service, index) => ({
        ...service,
        index: index,
        image: service.photo,
        name: service.name,
        isNewValue: false,
      }));

      form.setValue("services", mappedServices);
    }
  }, [rentalServices, form.setValue]);

  const [activeService, setActiveService] =
    useState<RentalWebsiteServicesCardServiceItem | null>(null);

  const [serviceIdsSelectedToRemove, setServiceIdsSelectedToRemove] = useState<
    number[] | null
  >(null);

  const [newPhotos, setNewPhotos] = useState<{ index: number; photo: File }[]>(
    []
  );

  const handleAddPhoto = ({ index, photo }: { index: number; photo: File }) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      form.setValue(`services.${index}`, {
        ...form.getValues(`services.${index}`),
        photo: reader.result as string,
      });
    };
    reader.readAsDataURL(photo);

    setNewPhotos((prevImages) =>
      prevImages
        ? [...prevImages, { index, photo }].sort((a, b) => a.index - b.index)
        : [{ index, photo }]
    );
  };

  useEffect(() => {
    if (!modal.isVisible) {
      setApiError(null);
      form.clearErrors();
      setNewPhotos([]);
    }
  }, [modal.isVisible]);

  const handleServicesUpdate = async () => {
    const services = form.getValues("services");

    const photos = newPhotos.map((photo) => photo.photo);

    const data: RentalWebsiteServicesPayload = {
      card_names: services
        .filter((service) => service.isNewValue)
        .map((service) => service.name),
      card_photos: photos,
      del_ids: serviceIdsSelectedToRemove,
      order_ids: services
        .filter((service) => service.id !== null)
        .map((service) => service.id!),
    };

    await useRentalWebsiteServicesUpdate({
      rentalId,
      data,
      onSuccess: (rentalWebsite) => {
        modal.close();
        onUpdateServices(rentalWebsite);
      },
      onError: (message) => {
        setApiError(message);
      },
      onStart: () => {
        setApiError(null);
        setLoadingValidation(true);
      },
      onEnd: () => {
        setLoadingValidation(false);
      },
    });
  };

  // * Drag and drop section
  const serviceIndexes = form
    .getValues("services")
    .map((_, index) => String(index));
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const handleDragStart = useCallback(
    (event: DragStartEvent) => {
      const activeIndex = Number(event.active.id);
      const services = form.getValues("services");

      if (activeIndex >= 0 && activeIndex < services.length) {
        setActiveService(services[activeIndex]);
      } else {
        setActiveService(null);
      }
    },
    [form]
  );

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      if (!over || active.id === over.id) return;

      const oldIndex = Number(active.id);
      const newIndex = Number(over.id);

      if (oldIndex === -1 || newIndex === -1) {
        return;
      }

      const currentServices = form.getValues("services");

      const firstFalsyIndex = getFirstFalsyIdIndex(currentServices);

      if (firstFalsyIndex !== -1 && newIndex >= firstFalsyIndex) {
        return;
      }

      const updatedServices = arrayMove(
        currentServices,
        oldIndex,
        newIndex
      ).map((service, index) => ({
        ...service,
        index,
      }));

      form.setValue("services", updatedServices, { shouldDirty: true });

      setActiveService(null);
    },
    [form]
  );

  const handleDragCancel = useCallback(() => {
    setActiveService(null);
  }, []);

  const handleCancel = () => {
    modal.close();
  };

  const handleAddService = () =>
    form.setValue("services", [
      ...form.getValues("services"),
      {
        id: null,
        index: form.getValues("services").length,
        photo: undefined,
        name: "",
        isNewValue: true,
      },
    ]);

  const handleRemoveService = (id: number) => {
    setServiceIdsSelectedToRemove((prevIds) =>
      prevIds ? [...prevIds, id] : [id]
    );
  };

  form.watch();

  return (
    <RightModal
      classNames={{
        mainContentParent: "overflow-y-hidden",
      }}
      title={t("Rental.Website.Services.title")}
      isVisible={modal.isVisible}
      onClose={modal.close}
    >
      <div className="flex flex-col justify-between w-full h-full">
        <div className="flex flex-col flex-1 h-full gap-3 overflow-y-auto">
          <ErrorMessage>{apiError}</ErrorMessage>
          <p className="text-low-contrast">
            {t("Rental.Website.Services.updateModalDescription")}
          </p>

          <div className="flex flex-wrap justify-center">
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
              onDragCancel={handleDragCancel}
            >
              <SortableContext
                items={serviceIndexes}
                strategy={rectSortingStrategy}
              >
                <div className="grid items-center grid-cols-3 gap-5">
                  {form.getValues("services").length > 0 &&
                    form
                      .getValues("services")
                      .map((service) => (
                        <SortableItem
                          key={service.index}
                          currentService={service}
                          id={service.id ? String(service.id) : undefined}
                          requiredFields={requiredFields}
                          form={form}
                          onAddPhoto={handleAddPhoto}
                          onRemoveService={handleRemoveService}
                        />
                      ))}

                  {/* Add new service */}
                  <div
                    className="flex flex-col items-center justify-center w-full p-2 space-y-2 rounded-lg cursor-pointer aspect-square bg-element-background"
                    onClick={handleAddService}
                  >
                    <PlusIcon className="size-10" />
                    <p className="font-bold text-center select-none text-high-contrast">
                      {t("Rental.Website.Services.addService")}
                    </p>
                  </div>
                </div>
              </SortableContext>
              <DragOverlay adjustScale style={{ transformOrigin: "0 0" }}>
                {activeService ? (
                  <Item
                    key={activeService.index}
                    currentService={activeService}
                    id={activeService.id ? String(activeService.id) : undefined}
                    requiredFields={requiredFields}
                    form={form}
                    isDragging
                  />
                ) : null}
              </DragOverlay>
            </DndContext>
          </div>
        </div>

        <Separator />

        <div className="flex flex-col gap-y-3">
          <ErrorMessage errorType="FORM">{error}</ErrorMessage>
          <div className="flex gap-x-3">
            <Button
              type="secondary"
              onClick={handleCancel}
              disabled={loadingValidation}
            >
              {t("Global.cancel")}
            </Button>
            <Button
              type="primary"
              RightIcon={CheckIcon}
              disabled={loadingValidation}
              loading={loadingValidation}
              onClick={form.handleSubmit(handleServicesUpdate)}
            >
              {t("Global.record")}
            </Button>
          </div>
        </div>
      </div>
    </RightModal>
  );
};

export type ItemProps = HTMLAttributes<HTMLDivElement> & {
  currentService: RentalWebsiteServicesCardServiceItem;
  form: UseFormReturn<RentalWebsiteServicesCardForm>;
  requiredFields: RequiredFields<
    Omit<RentalWebsiteServicesCardServiceItem, "id" | "index" | "isNewValue">
  >;
  withOpacity?: boolean;
  isDragging?: boolean;
  onAddPhoto?: ({ index, photo }: { index: number; photo: File }) => void;
  onRemoveService?: (id: number) => void;
};

export const Item = forwardRef<HTMLDivElement, ItemProps>(
  (
    {
      id,
      currentService,
      withOpacity,
      isDragging,
      style,
      form,
      requiredFields,
      onAddPhoto,
      onRemoveService,
      ...props
    },
    ref
  ) => {
    const handleImageUpload = (
      event: ChangeEvent<HTMLInputElement>,
      serviceIndex: number
    ) => {
      const file = event.target.files?.[0];
      if (file) {
        try {
          if (typeof onAddPhoto === "function") {
            onAddPhoto({
              index: serviceIndex,
              photo: file,
            });
          }
        } catch (err: any) {
          console.log(err.message);
          throw new Error(err.message);
        }
      }
    };

    const handleRemoveService = (id: number | null) => {
      form.setValue(
        "services",
        form
          .getValues("services")
          .filter((service) => service.index !== currentService.index)
      );

      if (typeof onRemoveService === "function" && id) {
        onRemoveService(id);
      }
    };

    const inlineStyles: CSSProperties = {
      opacity: withOpacity ? "0.8" : "1",
      boxShadow: isDragging
        ? "rgb(63 63 68 / 5%) 0px 2px 0px 2px, rgb(34 33 81 / 15%) 0px 2px 3px 2px"
        : "",
      transform: isDragging ? "scale(1.05)" : "scale(1)",
      ...style,
    };

    return (
      <div
        ref={ref}
        className="relative flex flex-col p-2 rounded-lg h-max gap-y-2 size-32 border-1 border-element-border group origin-[50%_50%] outline-none select-none bg-subtle-light"
        style={inlineStyles}
        {...props}
      >
        <div className="absolute transition-opacity duration-300 opacity-0 cursor-pointer -top-2 -right-2 w-max group-hover:opacity-100 origin-[50%_50%]">
          <Button
            type="secondary"
            buttonClassName="p-1 h-max"
            onMouseDown={(e) => e.stopPropagation()}
            onClick={() => handleRemoveService(Number(id) ?? null)}
          >
            <MinusIcon className="size-3" />
          </Button>
        </div>

        {currentService && currentService.photo === undefined ? (
          <div
            className="relative w-full h-full overflow-hidden rounded bg-element-background aspect-square"
            onMouseDown={(e) => e.stopPropagation()}
          >
            <input
              id={`add-photo-${currentService.index}`}
              className="absolute w-full h-full opacity-100 cursor-pointer -z-10"
              type="file"
              accept="image/jpeg, image/png"
              onChange={(e) => handleImageUpload(e, currentService.index)}
            />

            <label
              htmlFor={`add-photo-${currentService.index}`}
              className="flex flex-col items-center justify-center w-full h-full m-0 space-y-2 cursor-pointer"
            >
              <PhotoIcon className="size-10" />
              <p className="font-bold text-center select-none text-high-contrast">
                {t("Rental.Website.Services.addPhoto")}
              </p>
            </label>
          </div>
        ) : (
          <img
            className="object-cover object-center w-full h-auto rounded aspect-square border-1 border-element-border"
            src={
              typeof currentService.photo === "string"
                ? currentService.photo
                : ""
            }
            alt={currentService?.name ?? ""}
          />
        )}

        <TextInput
          register={form.register(
            `services.${Number(currentService.index)}.name`,
            {
              required: requiredFields.name,
            }
          )}
          disabled={!currentService.isNewValue}
          value={form.getValues(
            `services.${Number(currentService.index)}.name`
          )}
          placeholder={t("Rental.Website.Services.serviceNamePlaceholder")}
          onMouseDown={(e) => e.stopPropagation()}
        />
      </div>
    );
  }
);

const SortableItem: React.FC<ItemProps> = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: String(props.currentService.index),
      disabled: (() => {
        const services = props.form.getValues("services");
        const firstFalsyIdIndex = getFirstFalsyIdIndex(services);
        return (
          !props.id ||
          (firstFalsyIdIndex !== -1 &&
            props.currentService.index > firstFalsyIdIndex)
        ); // ✅ Empêcher le déplacement des éléments après `id = null`
      })(),
    });

  const isDisabled =
    !props.id ||
    (getFirstFalsyIdIndex(props.form.getValues("services")) !== -1 &&
      props.currentService.index >
        getFirstFalsyIdIndex(props.form.getValues("services")));

  const style: CSSProperties = {
    transform: isDisabled
      ? "none"
      : transform
      ? CSS.Transform.toString(transform)
      : undefined, // ✅ Supprime l'animation
    transition: isDisabled ? "none" : transition || "transform 200ms ease-out", // ✅ Aucune transition si désactivé
    cursor: isDisabled ? "not-allowed" : "grab",
  };

  return (
    <Item
      ref={setNodeRef}
      style={style}
      {...props}
      {...(isDisabled ? {} : attributes)} // ✅ Supprime les attributs de drag
      {...(isDisabled ? {} : listeners)} // ✅ Supprime les listeners de drag
    />
  );
};
