import React from "react";

import { useTranslation } from "react-i18next";

import CheckWhiteIcon from "../../../../assets/icons/check-white.svg?react";
import { UseModal } from "../../../../hooks/useModal";
import { ValueType } from "../../../../types/commonTypes";
import { Button } from "../../../Common/Button/Button";
import { RightModal } from "../../../Common/RightModal/RightModal";
import { Separator } from "../../../Common/Separator/Separator";
import { IntegrateContent } from "./IntegrateContent";

export const RentalIntegrateModal: React.FC<{
  modal: UseModal<{ apiKey: ValueType | undefined }>;
}> = ({ modal }) => {
  const { t } = useTranslation();

  const handleClose = () => {
    modal.close();
  };

  return (
    <RightModal
      classNames={{
        mainContentParent: "overflow-y-hidden",
      }}
      title={t("Rental.Website.IntegrateModal.title")}
      isVisible={modal.isVisible}
      onClose={handleClose}
    >
      <div className="flex flex-col w-full">
        <IntegrateContent apiKey={modal.data?.apiKey} />

        <div className="flex flex-col space-y-3">
          <Separator accent="dark" />
          <div className="flex items-center gap-3">
            <Button type="secondary" onClick={handleClose}>
              {t("Global.cancel")}
            </Button>
            <Button RightIcon={CheckWhiteIcon} onClick={handleClose}>
              {t("Global.ok")}
            </Button>
          </div>
        </div>
      </div>
    </RightModal>
  );
};
