import React from "react";
import { MainLayout } from "../MainLayout/MainLayout";
import { RentalLayoutNavbar } from "./Navbar/RentalLayoutNavbar";
import { RentalLayoutProps } from "./RentalLayout.type";
import { RentalTitle } from "./RentalTitle";
import { RentalTitleSkeleton } from "./RentalTitleSkeleton";

export const RentalLayout: React.FC<RentalLayoutProps> = ({
  children,
  rightChildren,
  navbarActiveItem,
  onActiveItem,
  // navbarActiveSubItem,
  // onActiveSubItem,
  rental,
  loading,
}) => {
  const getTitle = () => {
    if (loading || !rental) return <RentalTitleSkeleton />;
    else return <RentalTitle rental={rental} />;
  };

  return (
    <MainLayout
      title={getTitle()}
      sidebarActiveItem="rentals"
      withWorkspaceFilter={false}
    >
      {/* Navbar */}
      <div className="flex flex-row items-end gap-2 pb-5 mt-3 space-y-2">
        <div className="flex-1">
          <RentalLayoutNavbar
            onActiveItem={onActiveItem}
            activeItem={navbarActiveItem}
            // onActiveSubItem={onActiveSubItem}
            // activeSubItem={navbarActiveSubItem}
            rental={rental}
          />
        </div>

        {rightChildren && <div>{rightChildren}</div>}
      </div>

      {children}
    </MainLayout>
  );
};
