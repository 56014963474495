import { t } from "i18next";
import React, { useEffect } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import ArrowRightIcon from "../../../assets/icons/arrow-right-white.svg?react";
import ArrowDownIcon from "../../../assets/icons/small-arrow-down.svg?react";
import { RequiredFields, ValueType } from "../../../types/commonTypes";
import { RentalLightListItemResponse } from "../../../types/GETTypes";
import { Button } from "../../Common/Button/Button";
import { NumberInput } from "../../Common/NumberInput/NumberInput";
import { DIRECT_RESERVATIONS_STEP } from "../Steps/ConfigDirectReservations/Modal/ConfigDirectReservations.type";
import { ProgressionMultiDepositSettingsSkeleton } from "./MultiDepositSettingsSkeleton";

type ProgressionMultiDepositSettingsProps = {
  loading: boolean;
  rentals: RentalLightListItemResponse[] | undefined;
  form: UseFormReturn<ProgressionMultiDepositSettingsForm>;
  onNext: (step: DIRECT_RESERVATIONS_STEP) => void;
};

export type ProgressionMultiDepositSettingsForm = {
  deposits: { rental_id: ValueType; deposit: number }[];
};

export const ProgressionMultiDepositSettings: React.FC<
  ProgressionMultiDepositSettingsProps
> = ({ loading, rentals, form, onNext }) => {
  const requiredFields: RequiredFields<ProgressionMultiDepositSettingsForm> = {
    deposits: true,
  };

  useEffect(() => {
    if (rentals) {
      const rentalsWithDeposits = rentals.map((rental) => ({
        rental_id: rental.id,
        deposit: rental.deposit ?? 0,
      }));
      form.setValue("deposits", rentalsWithDeposits);
    }
  }, []);

  form.watch();

  if (loading || !rentals) return <ProgressionMultiDepositSettingsSkeleton />;

  return (
    <div className="flex flex-col justify-between gap-y-6">
      <div className="flex flex-col overflow-y-auto border rounded-lg border-element-border">
        <div className="flex items-center p-3 space-x-2 bg-element-background">
          <span className="font-bold text-high-contrast">
            {t("Progression.MultiDepositSettings.title")}
          </span>
          <ArrowDownIcon />
        </div>

        <div className="flex flex-col">
          {rentals &&
            rentals.length > 0 &&
            rentals.map((rental, index) => (
              <div
                className="flex items-center justify-between h-full p-4 gap-x-3"
                key={index}
              >
                <div className="flex items-center h-full space-x-3">
                  <img
                    className="object-cover object-center rounded w-18 h-14"
                    src={rental.cover}
                    alt={rental.name}
                  />

                  <div className="flex flex-col space-y-1">
                    <p className="font-semibold text-high-contrast">
                      {rental.name}
                    </p>
                    <p className="text-low-contrast">{rental.address}</p>
                  </div>
                </div>

                <Controller
                  control={form.control}
                  name={`deposits.${index}.deposit`}
                  rules={{
                    required: {
                      value: requiredFields.deposits,
                      message: t(
                        "Progression.MultiDepositSettings.depositError"
                      ),
                    },
                  }}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <NumberInput
                        label={t(
                          "Progression.MultiDepositSettings.depositLabel"
                        )}
                        value={value}
                        onChangeText={onChange}
                        error={
                          form.formState.errors.deposits
                            ? form.formState.errors.deposits[index]?.message
                            : undefined
                        }
                      />
                    );
                  }}
                />
              </div>
            ))}
        </div>
      </div>

      <Button
        type="primary"
        RightIcon={ArrowRightIcon}
        onClick={() => {
          onNext(DIRECT_RESERVATIONS_STEP.STRIPE_COLLECT_PAYMENTS);
          localStorage.setItem(
            "rentals",
            JSON.stringify(form.getValues("deposits"))
          );
        }}
        disabled={loading || Object.values(form.formState.errors).length > 0}
      >
        {t("Global.next")}
      </Button>
    </div>
  );
};
