import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ArrowLeftIcon from "../../../../assets/icons/arrow-left.svg?react";
import CheckIcon from "../../../../assets/icons/check-white.svg?react";
import EditIcon from "../../../../assets/icons/edit.svg?react";
import FileTextIcon from "../../../../assets/icons/file-text.svg?react";
import { getCurrentCanEdit } from "../../../../helpers/workspaceHelper";
import { useRentalWebsiteTermsUpdate } from "../../../../hooks/api/rental";
import useUserStore from "../../../../stores/useUserStore";
import { RequiredFields } from "../../../../types/commonTypes";
import { Card } from "../../../Common/Card/Card";
import { ErrorMessage } from "../../../Common/ErrorMessage/ErrorMessage";
import {
  RentalWebsiteTermsCardProps,
  RentalWebsiteTermsForm,
} from "./TermsCard.type";
import { RentalWebsiteTermsCardSkeleton } from "./TermsCardSkeleton";

export const RentalWebsiteTermsCard: React.FC<RentalWebsiteTermsCardProps> = ({
  loading,
  rentalId,
  terms,
  workspaceOwner,
  onUpdate,
  onError,
}) => {
  const userStore = useUserStore();
  const { t } = useTranslation();

  const form = useForm<RentalWebsiteTermsForm>({
    mode: "onChange",
    defaultValues: {
      terms: null,
    },
  });

  const requiredFields: RequiredFields<RentalWebsiteTermsForm> = {
    terms: true,
  };

  const [loadingValidation, setLoadingValidation] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);

  /**
   * Updates the description field in the form based on the current part and available descriptions.
   *
   * The function checks the value of the `part` variable and updates the form's description field
   * accordingly. It handles three cases:
   * 1. If `part` is "infos" and there are available `infosDescriptions`, it sets the description
   *    to the first `infosDescription`.
   * 2. If `part` is "website" and a `websiteDescription` is available, it sets the description
   *    to the `websiteDescription`.
   * 3. If `part` is "website", no `websiteDescription` is available, but there are `infosDescriptions`,
   *    it sets the description to the first `infosDescription`.
   *
   * Note: The function assumes that `form.setValue` is a method to update the form's description field.
   *
   */
  const updateDescription = () => {
    form.setValue("terms", typeof terms === "string" ? terms.trim() : null);
  };

  useEffect(() => {
    updateDescription();
  }, [terms]);

  /**
   * Handles the editing of the description based on the current edit mode and part.
   * If in edit mode, it updates the description for either rental infos or website.
   * If not in edit mode, it enables the edit mode.
   */
  const handleDescriptionEdit = () => {
    if (editMode) {
      useRentalWebsiteTermsUpdate({
        rentalId,
        data: {
          cgv: form.getValues("terms")!.trim(),
        },
        onSuccess: (rentalWebsite) => {
          setEditMode(!editMode);
          onUpdate(rentalWebsite.cgv);
        },
        onError: (message) => onError(message),
        onStart: () => {
          onError(null);
          setLoadingValidation(true);
        },
        onEnd: () => {
          setLoadingValidation(false);
        },
      });
    } else {
      setEditMode(true);
    }
  };

  const descriptionRef = useRef<HTMLTextAreaElement | null>(null);
  const { ref, ...restDescriptionRegister } = form.register("terms", {
    required: {
      value: requiredFields.terms,
      message: t("Global.Errors.requiredField", {
        fieldName: t("Rental.Website.Terms.title"),
      }),
    },
  });

  useEffect(() => {
    if (editMode) {
      if (descriptionRef.current) {
        descriptionRef.current.style.height =
          descriptionRef.current.scrollHeight + "px";
      }
    }
  }, [editMode, descriptionRef.current]);

  const handleCancel = () => {
    setEditMode(false);
    updateDescription();
  };

  const isEditButtonDisabled = () => {
    if (!getCurrentCanEdit({ user: userStore.user, workspaceOwner }))
      return true;

    return editMode && (!form.formState.isValid || loadingValidation);
  };

  if (loading) return <RentalWebsiteTermsCardSkeleton />;

  form.watch();

  return (
    <Card
      Icon={FileTextIcon}
      label={t("Rental.Website.Terms.title")}
      anchor="terms-card"
      loading={loadingValidation}
      button={{
        Icon: editMode ? CheckIcon : EditIcon,
        type: editMode ? "primary" : "secondary",
        label: editMode ? t("Global.record") : t("Global.edit"),
        onClick: editMode
          ? form.handleSubmit(handleDescriptionEdit)
          : () => setEditMode(!editMode),
        disabled: isEditButtonDisabled(),
      }}
      secondaryButton={
        editMode
          ? {
              label: t("Global.cancel"),
              LeftIcon: ArrowLeftIcon,
              onClick: () => handleCancel(),
            }
          : undefined
      }
    >
      <div className="flex flex-col gap-y-2">
        {editMode ? (
          <>
            <textarea
              ref={(e) => {
                ref(e);
                descriptionRef.current = e;
              }}
              className="w-full p-2 rounded resize-y text-high-contrast border-1 border-element-border focus:outline-none h-max"
              disabled={loadingValidation}
              required={requiredFields.terms}
              {...restDescriptionRegister}
            ></textarea>
            <ErrorMessage errorType="FORM">
              {form.formState.errors.terms?.message}
            </ErrorMessage>
          </>
        ) : (
          <p className="text-low-contrast whitespace-break-spaces">
            {form.getValues("terms")}
          </p>
        )}
      </div>
    </Card>
  );
};
