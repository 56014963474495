import {TFunction} from "i18next";
import {
  PaymentPolicyDepositPaymentOptionEnum,
  PaymentPolicyResponse,
} from "../components/Payment/Payment.type";
import {
  PaymentReservationScheduleResponse,
  RentalPaymentPolicyListItemResponse,
} from "../types/GETTypes";

export const displayDepositPaymentMethod = (
  currentPolicy:
    | PaymentPolicyResponse
    | RentalPaymentPolicyListItemResponse
    | PaymentReservationScheduleResponse,
  t: TFunction<"translation", undefined>
): string | undefined => {
  let newDepositPaymentMethod = undefined;

  if (currentPolicy) {
    if (
      currentPolicy.deposit_payment_option ===
      PaymentPolicyDepositPaymentOptionEnum.PRE_AUTHORISATION
    ) {
      newDepositPaymentMethod = t(
        "Payments.DepositPaymentOptions.preAuthorisation"
      );
    } else if (
      currentPolicy.deposit_payment_option ===
      PaymentPolicyDepositPaymentOptionEnum.CARD_PAYMENT_TO_REFUND
    ) {
      newDepositPaymentMethod = t("Payments.DepositPaymentOptions.cbRefund");
    } else if (
      currentPolicy.deposit_payment_option ===
      PaymentPolicyDepositPaymentOptionEnum.BANK_TRANSFER_TO_REFUND
    ) {
      newDepositPaymentMethod = t("Payments.DepositPaymentOptions.sepaRefund");
    } else if (
      currentPolicy.deposit_payment_option ===
      PaymentPolicyDepositPaymentOptionEnum.BANK_CHECK_OR_CASH_AT_ARRIVAL
    ) {
      newDepositPaymentMethod = t("Payments.DepositPaymentOptions.checkOrCash");
    }
  }

  return newDepositPaymentMethod?.toLowerCase();
};
