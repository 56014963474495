/**
 * Empty page for payments list
 */

import { useTranslation } from "react-i18next";

import React from "react";
import CloseIcon from "../../../assets/icons/close.svg?react";
import PlusWhite from "../../../assets/icons/plus-white.svg?react";
import { PaymentListEmptyProps } from "../Payment.type";
import { Button } from "../../Common/Button/Button";

export default function PaymentListEmpty({ modal }: PaymentListEmptyProps) {
  const { t } = useTranslation();

  return (
    <>
      <div
        className="flex bg-element-background w-full rounded-6px mt-2 items-center justify-center p-4 border"
        style={{ height: "calc(100vh - 200px)" }}
      >
        <div className="flex flex-col items-center">
          <CloseIcon width={56} height={56} />

          <p className="my-4 font-semibold text-xl text-high-contrast">
            {t("Management.Payments.anyPayment")}
          </p>

          <p className="mb-4 font-light text-base text-low-contrast text-center w-96">
            {t("Management.Payments.anyPaymentDesc")}
          </p>
          <Button
            textClassName={"font-normal"}
            RightIcon={PlusWhite}
            onClick={() => {
              modal.open();
            }}
          >
            {t("Management.Payments.add")}
          </Button>
        </div>
      </div>
    </>
  );
}
