import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../Common/Button/Button";
import { CenterModal } from "../../Common/CenterModal/CenterModal";

import CheckWhiteIcon from "../../../assets/icons/check-white.svg?react";
import paths from "../../../constants/paths";
import { post } from "../../../helpers/APIHelper";
import { PaymentReservationResponse } from "../../../types/GETTypes";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";

export const PaymentDepositRefundModal: React.FC<{
  isVisible: boolean;
  deposit: PaymentReservationResponse | undefined | null;
  onClose: () => void;
  onSuccess: () => void;
}> = ({ isVisible, deposit, onClose, onSuccess }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleClose = () => {
    if (loading) return;

    setError(null);
    onClose();
  };

  const handleRefund = async () => {
    setError(null);
    setLoading(true);

    const URL = `${import.meta.env.VITE_API_URL}${
      paths.API.PAYMENTS.DEPOSITS
    }/${deposit?.id}/${paths.API.PAYMENTS.DEPOSIT_REFUND}/${
      deposit?.payment_schedule?.deposit_payment_link
    }`;

    const res = await post(URL);

    if (res.data?.success) {
      onSuccess();
    } else {
      setError(res.response.data.message);
    }

    setLoading(false);
  };

  return (
    <CenterModal
      isVisible={isVisible}
      onClose={handleClose}
      title={t("Payments.PaymentReservationList.RefundModal.title")}
    >
      <p className="text-sm font-light text-low-contrast">
        {t("Payments.PaymentReservationList.RefundModal.content")}
      </p>

      <ErrorMessage>{error}</ErrorMessage>

      <div className="flex flex-row gap-2 pt-4">
        <Button type="secondary" onClick={handleClose} disabled={loading}>
          {t("Global.cancel")}
        </Button>
        <Button
          loading={loading}
          type="primary"
          RightIcon={CheckWhiteIcon}
          onClick={handleRefund}
        >
          {t("Payments.PaymentReservationList.RefundModal.button")}
        </Button>
      </div>
    </CenterModal>
  );
};
