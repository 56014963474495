import {
  $createTextNode,
  $getRoot,
  $getSelection,
  $isRangeSelection,
  FORMAT_TEXT_COMMAND,
  LexicalEditor,
} from "lexical";
import {UseModal} from "../hooks/useModal";
import {$generateNodesFromDOM} from '@lexical/html';

export interface TextStyleToolbar {
  bold: boolean;
  italic: boolean;
  underline: boolean;
}
interface InsertCodeEditorParams {
  text: string;
  modalShortCode: UseModal<unknown>;
  editorRef: React.MutableRefObject<LexicalEditor | undefined>;
}
export const insertShortCodeInEditor = ({
  text,
  modalShortCode,
  editorRef,
}: InsertCodeEditorParams) => {
  if (modalShortCode) {
    if (editorRef?.current) {
      const editor = editorRef.current as LexicalEditor;
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          // Get the current styles (bold, italic, underline)
          const isBold = selection.hasFormat("bold");
          const isItalic = selection.hasFormat("italic");
          const isUnderline = selection.hasFormat("underline");

          // Create a new text node with the styles applied
          const textNode = $createTextNode(text);
          if (isBold) textNode.toggleFormat("bold");
          if (isItalic) textNode.toggleFormat("italic");
          if (isUnderline) textNode.toggleFormat("underline");

          // Insert the text node at the current cursor position
          selection.insertNodes([textNode]);
        }
      });
    }
  }
};

export function isNonHtml(content: string) {
  // Regular expression to detect HTML tags
  const htmlTagRegex = /<\/?[a-z][\s\S]*>/i;
  return !htmlTagRegex.test(content); // Returns true if no HTML tags are found
}

// Function to detect current styles dynamically
export const detectCurrentStyles = (
  editor: LexicalEditor,
  setTextStyle: React.Dispatch<
    React.SetStateAction<TextStyleToolbar>
  >
) => {
  editor.getEditorState().read(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      setTextStyle({
        bold: selection.hasFormat("bold"),
        italic: selection.hasFormat("italic"),
        underline: selection.hasFormat("underline"),
      });
    }
  });
};

export type EditorButtonType = "bold" | "italic" | "underline";

export const toggleStyle = (
  editor: LexicalEditor,
  style: EditorButtonType,
  setTextStyle: React.Dispatch<
    React.SetStateAction<TextStyleToolbar>
  >
) => {
  editor.update(() => {
    const selection = $getSelection();

    if ($isRangeSelection(selection)) {
      editor.dispatchCommand(FORMAT_TEXT_COMMAND, style);

      editor.getEditorState().read(() => {
        const isActive = selection.hasFormat(style);
        setTextStyle((prev) => ({
          ...prev,
          [style]: isActive,
        }));
      });
    }
  });
};

export const updateHTML = (editor: LexicalEditor, value: string, clear: boolean) => {
  const root = $getRoot();
  const parser = new DOMParser();
  const dom = parser.parseFromString(
    isNonHtml(value) ? `<p>${value}</p>` : value,
    "text/html"
  );
  const nodes = $generateNodesFromDOM(editor, dom);
  if (clear) {
    root.clear();
  }
  root.append(...nodes);
};
