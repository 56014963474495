/**
 * Component for lang select
 */
import { InputSelect } from "../InputSelect/InputSelect";
import Countries from "../../../constants/countries";
import React, { useState } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import countries from "../../../constants/countries";

export interface LangSelectProps {
  label: string;
  error?: string;
  register?: UseFormRegisterReturn;
  defaultValue?: number;
  required?: boolean;
  onLangSelect?: (langId: number) => void;
}

export default function LangSelect({
  label,
  error = "",
  register,
  defaultValue,
  onLangSelect,
  required = false,
}: LangSelectProps) {
  const [selectValue, setSelectValue] = useState(countries[72].value); // FR

  return (
    <>
      <InputSelect
        label={label}
        items={Countries.map(({ value, label, Icon }) => ({
          value,
          label,
          Icon,
        }))}
        required={required}
        error={error && error}
        register={register}
        defaultValue={defaultValue}
        selectedValue={selectValue}
        onSelect={(value) => {
          if (typeof onLangSelect === "function") {
            onLangSelect(value as number);
          }
          setSelectValue(value as number);
        }}
      />
    </>
  );
}
