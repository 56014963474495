import React from "react";
import ExtrasWebsiteCard from "./ExtrasCard";
import useGuestWebsiteStore from "../../../../../../stores/guestWebsiteStore/useGuestWebsiteStore";

const ExtrasList = () => {
  const {extrasOrder, setExtrasOrder, rentalData} = useGuestWebsiteStore();
  const extras = rentalData?.guest_page?.extras_available;

  const onSelectExtras = (
    extraOrder: {id: number; quantity?: number},
    toRemove = false
  ) => {
    const {id, quantity} = extraOrder;
    if (toRemove) {
      if (extrasOrder) {
        setExtrasOrder(extrasOrder.filter((extra) => extra.id !== id));
      }
    } else {
      const find = extrasOrder?.find((extra) => extra.id === id);
      if (find && extrasOrder) {
        find.quantity = quantity ?? 1;
        setExtrasOrder(extrasOrder);
      } else {
        if (extrasOrder) {
          setExtrasOrder([...extrasOrder, extraOrder]);
        } else {
          setExtrasOrder([extraOrder]);
        }
      }
    }
  };

  return (
    <div className="flex flex-col w-full py-4 md:p-4 space-y-4 md:pl-0">
      {extras?.map((extra) => (
        <ExtrasWebsiteCard
          {...extra}
          name={extra?.name["fr"] ?? ""}
          description={extra?.description["fr"] ?? ""}
          unitPrice={extra?.unit_price}
          maximumQuantity={extra?.maximum_quantity}
          photo={extra?.photo?.[0]?.original_url ?? ""}
          onSelectExtras={onSelectExtras}
        />
      ))}
    </div>
  );
};

export default ExtrasList;
