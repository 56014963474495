import React, { useEffect, useState } from "react";

import { RightModal } from "../../Common/RightModal/RightModal";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { TextInput } from "../../Common/TextInput/TextInput";
import { Button } from "../../Common/Button/Button";
import CheckWhiteIcon from "../../../assets/icons/check-white.svg?react";
import { InputMultiSelect } from "../../Common/InputMultiSelect/InputMultiSelect";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import { ValueType } from "../../../types/commonTypes";
import {
  OperationalManagementTeamListItemResponse,
  OperationalManagementTeamMemberResponse,
  WorkspaceResponse,
} from "../../../types/GETTypes";
import { post } from "../../../helpers/APIHelper";
import paths from "../../../constants/paths";

type AddTeamFormSchema = {
  name: string;
  memberIds: ValueType[];
};

export const AddTeamModal: React.FC<{
  selectedWS: WorkspaceResponse | undefined;
  members: OperationalManagementTeamMemberResponse[];
  isVisible: boolean;
  onClose: () => void;
  onSuccess: (newTeam: OperationalManagementTeamListItemResponse) => void;
}> = ({ selectedWS, members, isVisible, onClose, onSuccess }) => {
  const { t } = useTranslation();

  const form = useForm<AddTeamFormSchema>({
    defaultValues: {
      name: "",
      memberIds: [],
    },
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (isVisible) {
      form.setValue("name", "");
      form.setValue("memberIds", []);
    }
  }, [isVisible]);

  const handleClose = () => {
    if (loading) return;

    onClose();
  };

  const save = async (values: AddTeamFormSchema) => {
    setLoading(true);

    const res = await post(
      `${import.meta.env.VITE_API_URL}${
        paths.API.OPERATIONNAL_MANAGEMENT.CREATE_TEAM
      }`,
      {
        name: values.name,
        user_ids: values.memberIds,
        workspace_id: selectedWS?.id,
      }
    );

    if (res.data?.success) {
      onSuccess(res.data.result);
    } else {
      setError(res.response.data.message);
    }

    setLoading(false);
  };

  return (
    <RightModal
      title={t("Management.AddTeam.title")}
      isVisible={isVisible}
      onClose={handleClose}
    >
      <form
        onSubmit={form.handleSubmit(save)}
        className="flex flex-col w-full flex-1"
      >
        <div className="w-full flex-1 flex flex-col gap-4">
          <p className="text-low-contrast font-semibold">
            {t("Management.AddTeam.informations")}
          </p>

          <div>
            <Controller
              control={form.control}
              name="name"
              rules={{
                required: {
                  value: true,
                  message: t("Management.AddTeam.nameError"),
                },
              }}
              render={({ field: { value, onChange } }) => (
                <TextInput
                  label={t("Management.AddTeam.name")}
                  size="large"
                  required={true}
                  value={value}
                  error={form.formState.errors.name?.message}
                  disabled={loading}
                  onChangeText={onChange}
                />
              )}
            />
          </div>

          <div>
            <Controller
              control={form.control}
              name="memberIds"
              render={({ field: { value, onChange } }) => (
                <InputMultiSelect
                  label={t("Management.AddTeam.members")}
                  items={members?.map((member) => {
                    const label =
                      member.first_name !== "" && member.last_name !== ""
                        ? `${member.first_name} ${member.last_name}`
                        : `${member.email} (${t(
                            "Management.AddTeam.pending"
                          )})`;

                    return { label, value: member.id, imgSrc: member.photo };
                  })}
                  disabled={loading}
                  selectedValues={value}
                  onSelect={onChange}
                />
              )}
            />
          </div>

          <ErrorMessage errorType="API">{error}</ErrorMessage>
        </div>

        {/* Buttons */}
        <div className="pt-4 border-t-1 border-element-border flex gap-4">
          <Button type="secondary" disabled={loading} onClick={handleClose}>
            {t("Global.cancel")}
          </Button>
          <Button RightIcon={CheckWhiteIcon} loading={loading}>
            {t("Management.AddTeam.addTeam")}
          </Button>
        </div>
      </form>
    </RightModal>
  );
};
