import { PaymentAccount } from "../../components/Payment/Payment.type";
import paths from "../../constants/paths";
import { get, post } from "../../helpers/APIHelper";

/**
 * Hook to fetch payment accounts.
 * @param {Function} onStart - Function to execute when the fetch starts.
 * @param {Function} onEnd - Function to execute when the fetch ends.
 * @param {Function} onSuccess - Function to execute when the fetch is successful.
 * @param {Function} onError - Function to execute when there is an error.
 */
export const useFetchPaymentAccounts = async (
  onSuccess: (paymentAccounts: PaymentAccount[]) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  if (typeof onStart === "function") {
    onStart();
  }

  try {
    const response = await get(
      `${import.meta.env.VITE_API_URL}${
        paths.API.PAYMENTS.ACCOUNTS_FOR_DROPDOWN
      }`
    );

    if (response?.data?.success) {
      onSuccess(response?.data.result?.payment_accounts);
    } else {
      onError(response?.response?.data?.message ?? response.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

export type CreatePaymentAccountForOnboardingPayload = {
  name: string;
  type: string;
  stripe_authorization_code?: string;
  owner?: string;
  iban?: string;
  bic?: string;
  automatic_check_enabled?: boolean;
  workspace_id: string | null;
};

export const useCreatePaymentAccountForOnboarding = async ({
  data,
  onSuccess,
  onError,
  onStart,
  onEnd,
}: {
  data: CreatePaymentAccountForOnboardingPayload;
  onSuccess?: (paymentAccount: PaymentAccount) => void;
  onError: (message: string | null) => void;
  onStart?: () => void;
  onEnd?: () => void;
}) => {
  if (typeof onStart === "function") {
    onStart();
  }

  try {
    const response = await post(
      `${import.meta.env.VITE_API_URL}${
        paths.API.PAYMENTS.CREATE_PAYMENT_ACCOUNT_ONBOARDING
      }`,
      data
    );

    if (response?.data?.success && typeof onSuccess === "function") {
      onSuccess(response?.data.result);
    } else {
      onError(response?.response?.data?.message ?? response.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};
