import React from "react";
import {cn} from "../../helpers/classHelper";
import {useTranslation} from "react-i18next";
import {
  getSignNumber,
  PriceMultiplierData,
} from "../../helpers/priceMultiplierHelper";
import {getFormattedPrice} from "../../helpers/currencyHelper";
import {ClassNamesType} from "../../types/commonTypes";

export const GreyBlock = ({
  value,
  classNames,
}: {
  value: string;
  classNames?: ClassNamesType;
}) => {
  return (
    <div
      className={cn(
        "p-1  text-low-contrast font-bold ml-1 rounded text-xs text-center my-2",
        !value
          ? "bg-none border-none h-[27px]"
          : "bg-element-border/30 border border-element-border",
        classNames?.divParent
      )}
    >
      {value}
    </div>
  );
};
const PriceMultiplierTableBody = ({
  isOpen,
  data,
}: {
  isOpen: boolean;
  data: PriceMultiplierData | undefined;
}) => {
  const {t} = useTranslation();

  return (
    <tbody className="text-xs">
      <tr className={cn("")}>
        <td className={cn("border-b py-4 pl-2 text-left text-low-contrast")}>
          {t("PriceMultiplier.Table.nightPrice")}
        </td>
        <td
          className={cn(
            "border-b py-4 text-center text-low-contrast font-bold"
          )}
        >
          <div className="flex items-center justify-center">
            {data?.website.priceByNight} €
            <GreyBlock
              value={`${
                getSignNumber({
                  number: String(data?.price_multiplier_platform_website),
                  withNumber: false,
                  showNegativeSign: false,
                }).result
              }${getFormattedPrice({
                price: data?.price_multiplier_platform_website ?? 0,
                decimals: 2,
                withCurrency: false,
              })}%`}
            />
          </div>
        </td>
        <td
          className={cn(
            "border-b py-4 text-center text-low-contrast font-bold"
          )}
        >
          <div className="flex items-center justify-center">
            {data?.airbnb.priceByNight} €
            <GreyBlock
              value={`${
                getSignNumber({
                  number: String(data?.price_multiplier_airbnb),
                  withNumber: false,
                  showNegativeSign: false,
                }).result
              }${getFormattedPrice({
                price: data?.price_multiplier_airbnb ?? 0,
                decimals: 2,
                withCurrency: false,
              })}%`}
            />
          </div>
        </td>
        <td
          className={cn(
            "border-b py-4 text-center text-low-contrast font-bold"
          )}
        >
          <div className="flex items-center justify-center">
            {data?.booking.priceByNight} €
            <GreyBlock
              value={`${
                getSignNumber({
                  number: String(data?.price_multiplier_booking),
                  withNumber: false,
                  showNegativeSign: false,
                }).result
              }${getFormattedPrice({
                price: data?.price_multiplier_booking ?? 0,
                decimals: 2,
                withCurrency: false,
              })}%`}
            />
          </div>
        </td>
      </tr>
      {isOpen ? (
        <tr className={cn("")}>
          <td className={cn("border-b py-4 pl-2 text-left text-low-contrast")}>
            {t("PriceMultiplier.Table.tax")}
          </td>
          <td
            className={cn(
              "border-b py-4 text-center text-low-contrast font-bold"
            )}
          >
            {data?.website.tax} €
          </td>
          <td
            className={cn(
              "border-b py-4 text-center text-low-contrast font-bold"
            )}
          >
            {data?.airbnb.tax} €
          </td>
          <td
            className={cn(
              "border-b py-4 text-center text-low-contrast font-bold"
            )}
          >
            {data?.booking.tax} €
          </td>
        </tr>
      ) : null}
      <tr className={cn("")}>
        <td
          className={cn(
            "border-b py-4 pl-2 text-left text-high-contrast font-bold"
          )}
        >
          {t("PriceMultiplier.Table.paidGuest")}
        </td>
        <td
          className={cn(
            "border-b py-4 text-center text-high-contrast font-bold"
          )}
        >
          {data?.website.paidByGuest} €
        </td>
        <td
          className={cn(
            "border-b py-4 text-center text-high-contrast font-bold"
          )}
        >
          {data?.airbnb.paidByGuest} €
        </td>
        <td
          className={cn(
            "border-b py-4 text-center text-high-contrast font-bold"
          )}
        >
          {data?.booking.paidByGuest} €
        </td>
      </tr>
      {isOpen ? (
        <tr className={cn("")}>
          <td className={cn("border-b py-4 pl-2 text-left text-low-contrast")}>
            {t("PriceMultiplier.Table.paymentFees")}
          </td>
          <td className={cn("border-b py-4 text-center text-error font-bold")}>
            <div className="flex items-center justify-center">
              {/* {getSignNumber(data?.direct.additionalFees, false).positive
                ? "-" + data?.direct.additionalFees
                : data?.direct.additionalFees} */}
              -{data?.website.additionalFees}
              €
              <GreyBlock value="1.5% + 0.25 €" />
            </div>
          </td>
          <td
            className={cn(
              "border-b py-4 text-center text-low-contrast font-bold"
            )}
          >
            x
          </td>
          <td className={cn("border-b py-4 text-center text-error font-bold")}>
            <div className="flex items-center justify-center">
              {/* {getSignNumber(data?.booking.additionalFees, false).positive
                ? "-" + data?.booking.additionalFees
                : data?.booking.additionalFees} */}
              -{data?.booking.additionalFees}
              €
              <GreyBlock value="1.4%" />
            </div>
          </td>
        </tr>
      ) : null}
      {isOpen ? (
        <tr className={cn("")}>
          <td className={cn("border-b py-4 pl-2 text-left text-low-contrast")}>
            {t("PriceMultiplier.Table.commission")}
          </td>
          <td
            className={cn(
              "border-b py-4 text-center  text-low-contrast font-bold"
            )}
          >
            x
          </td>
          <td className={cn("border-b py-4 text-center text-error font-bold")}>
            <div className="flex items-center justify-center">
              {/* {getSignNumber(data?.airbnb.commissions, false).positive
                ? "-" + getSign(data?.airbnb.commissions).result
                : getSign(data?.airbnb.commissions).result} */}
              -{data?.airbnb.commissions}
              €
              <GreyBlock value="18 % TTC" />
            </div>
          </td>
          <td className={cn("border-b py-4 text-center text-error font-bold")}>
            <div className="flex items-center justify-center">
              {/* {getSignNumber(data?.booking.commissions, false).positive
                ? "-" + data?.booking.commissions
                : data?.booking.commissions} */}
              -{data?.booking.commissions}
              €
              <GreyBlock value="17 % TTC" />
            </div>
          </td>
        </tr>
      ) : null}
      {isOpen ? (
        <tr className={cn("")}>
          <td className={cn("border-b py-4 pl-2 text-left text-low-contrast")}>
            {t("PriceMultiplier.Table.paidBank")}
          </td>
          <td
            className={cn(
              "border-b py-4 text-center text-low-contrast font-bold"
            )}
          >
            {data?.website.paidBank} €
          </td>
          <td
            className={cn(
              "border-b py-4 text-center text-low-contrast font-bold"
            )}
          >
            {data?.airbnb.paidBank} €
          </td>
          <td
            className={cn(
              "border-b py-4 text-center text-low-contrast font-bold"
            )}
          >
            {data?.booking.paidBank} €
          </td>
        </tr>
      ) : null}
      <tr className={cn("")}>
        <td className={cn("border-b py-4 pl-2 text-left font-bold")}>
          {t("PriceMultiplier.Table.paidNet")}
        </td>
        <td className={cn("border-b py-4 text-center text-success font-bold")}>
          {data?.website.paidNet} €
        </td>
        <td className={cn("border-b py-4 text-center text-success font-bold")}>
          {data?.airbnb.paidNet} €
        </td>
        <td className={cn("border-b py-4 text-center text-success font-bold")}>
          {data?.booking.paidNet} €
        </td>
      </tr>
    </tbody>
  );
};

export default PriceMultiplierTableBody;
