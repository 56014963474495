import React, {useEffect, useState} from "react";
import {Title} from "../../Common/Title/Title";
import StatsItem from "../../Statistics/General/StatsItem";
import {useTranslation} from "react-i18next";
import {Separator} from "../../Common/Separator/Separator";
import {
  AnalyticsGeneralOccupancy,
  AnalyticsGeneralReservationStatistics,
  AnalyticsGeneralResponse,
  AnalyticsGeneralRevenue,
} from "../../../types/GETTypes";
import {ValueType} from "../../../types/commonTypes";

interface GeneralComponentProps {
  stats: AnalyticsGeneralResponse | undefined;
  loading: boolean;
  refresh?:boolean
}
export type StatsItemType = {
  title: ValueType;
  description: ValueType;
  showInfo?: boolean;
  infoText?: ValueType;
  code: ValueType;
  symbol?: ValueType;
};

const GeneralComponent = ({stats, loading,refresh}: GeneralComponentProps) => {
  const {t} = useTranslation();
  const [loadingComponent, setLoadingComponent] = useState(false);
  const [statsRevenueData, setStatsRevenueData] = useState<StatsItemType[]>([
    {
      title: "",
      description: t("Analytics.revenue"),
      code: "gross_revenue",
      symbol: "€",
      showInfo: true,
      infoText: t("Analytics.Tooltip.General.revenue"),
    },
    {
      title: "",
      description: t("Analytics.commissions"),
      code: "platform_commissions",
      symbol: "€",
      showInfo: true,
      infoText: t("Analytics.Tooltip.General.commissions"),
    },
    {
      title: "",
      description: t("Analytics.cleaning"),
      code: "cleaning_fees",
      symbol: "€",
      showInfo: true,
      infoText: t("Analytics.Tooltip.General.cleaning"),
    },
    {
      title: "",
      description: t("Analytics.tax"),
      code: "tourist_tax",
      symbol: "€",
      showInfo: true,
      infoText: t("Analytics.Tooltip.General.tax"),
    },
    {
      title: "",
      description: t("Analytics.net"),
      code: "net_revenue",
      symbol: "€",
      showInfo: true,
      infoText: t("Analytics.Tooltip.General.net"),
    },
  ]);

  const [statsOccupationData, setStatsOccupationData] = useState<
    StatsItemType[]
  >([
    {
      title: "",
      description: t("Analytics.occupancyRate"),
      code: "occupancy_rate",
      symbol: "",
      showInfo: true,
      infoText: t("Analytics.Tooltip.General.occupancyRate"),
    },
    {
      title: "",
      description: t("Analytics.occupancyWithoutNightsBlocked"),
      code: "occupancy_rate_excluding_blocked",
      symbol: "",
      showInfo: false,
    },
    {
      title: "",
      description: t("Analytics.rentedNights"),
      code: "nights_booked",
      symbol: "",
      showInfo: true,
      infoText: t("Analytics.Tooltip.General.rentedNights"),
    },
    {
      title: "",
      description: t("Analytics.nightsAvailable"),
      code: "nights_available",
      symbol: "",
      showInfo: false,
      infoText: t("Analytics.Tooltip.General.nightsAvailable"),
    },
  ]);

  const [statsReservationData, setStatsReservationData] = useState<
    StatsItemType[]
  >([
    {
      title: "",
      description: t("Analytics.rentalsActivated"),
      code: "active_properties",
      symbol: "",
      showInfo: false,
      infoText: t("Analytics.Tooltip.General.rentalsActivated"),
    },
    {
      title: "",
      description: t("Analytics.averageGuestsPerResa"),
      code: "average_guests_per_reservation",
      symbol: "",
      showInfo: false,
      infoText: t("Analytics.Tooltip.General.averageGuestsPerResa"),
    },
    {
      title: "",
      description: t("Analytics.averageNightsPerResa"),
      code: "average_nights_per_reservation",
      symbol: "",
      showInfo: false,
      infoText: t("Analytics.Tooltip.General.averageNightsPerResa"),
    },
    {
      title: "",
      description: t("Analytics.totalCheckinsCheckouts"),
      code: "total_checkins_checkouts",
      symbol: "%",
      showInfo: false,
      infoText: t("Analytics.Tooltip.General.totalCheckinsCheckouts"),
    },
    {
      title: "",
      description: t("Analytics.averagePricePerResa"),
      code: "average_price_per_reservation",
      symbol: "€",
      showInfo: true,
      infoText: t("Analytics.Tooltip.General.averagePricePerResa"),
    },
    {
      title: "",
      description: t("Analytics.revPar"),
      code: "revenue_per_available_room",
      symbol: "€",
      showInfo: true,
      infoText:  t("Analytics.Tooltip.General.revPar"),
    },
  ]);

  const replaceData = (
    array: StatsItemType[],
    setterArray: (value: React.SetStateAction<StatsItemType[]>) => void,
    obj:
      | AnalyticsGeneralRevenue
      | AnalyticsGeneralOccupancy
      | AnalyticsGeneralReservationStatistics
  ) => {
    Object.entries(obj).map(([key, value]) => {
      const element = array.find((a) => a?.code === key);
      if (element) {
        element.title = value;
      }
      setterArray(array);
    });
  };

  useEffect(() => {
    if (stats) {
      setLoadingComponent(true);
      replaceData(statsRevenueData, setStatsRevenueData, stats.revenue);
      replaceData(statsOccupationData, setStatsOccupationData, stats.occupancy);
      replaceData(
        statsReservationData,
        setStatsReservationData,
        stats.reservation_statistics
      );
      setLoadingComponent(false);
    }
  }, [stats,refresh]);

  const isLoad = loading || loadingComponent;

  return (
    <div className="w-[98%] mt-4">
      <div className="flex flex-col space-y-3">
        <Title>{t("Analytics.revenus")}</Title>
        <div className="flex">
          <div className="flex flex-wrap items-center gap-3">
            {isLoad
              ? Array.from({length: 5}).map((_) => (
                  <StatsItem
                    stats={undefined}
                    loading={isLoad}
                    classNames={{divParent: "w-[230px]"}}
                  />
                ))
              : statsRevenueData.map((stat) => (
                  <StatsItem
                    stats={stat}
                    classNames={{
                      divParent: "w-[230px]",
                      divInput: "pt-6",
                      label: "text-[32px]",
                      subLabel: "text-base font-bold",
                    }}
                  />
                ))}
          </div>
        </div>
      </div>

      <Separator accent="dark" classNames={{divParent: "my-7 mr-12"}} />

      <div className="flex flex-col space-y-3">
        <Title>{t("Analytics.occupancyRate")}</Title>
        <div className="flex">
          <div className="flex flex-wrap items-center gap-3">
            {isLoad
              ? Array.from({length: 4}).map((_) => (
                  <StatsItem
                    stats={undefined}
                    loading={isLoad}
                    classNames={{divParent: "w-[230px]"}}
                  />
                ))
              : statsOccupationData.map((occupation) => (
                  <StatsItem
                    stats={occupation}
                    classNames={{
                      divParent: "w-[230px]",
                      divInput: "pt-6",
                      label: "text-[32px]",
                      subLabel: "text-base font-bold",
                    }}
                  />
                ))}
          </div>
        </div>
      </div>

      <Separator accent="dark" classNames={{divParent: "my-7 mr-12"}} />

      <div className="flex flex-col space-y-3">
        <Title>{t("Analytics.nightsStats")}</Title>
        <div className="flex">
          <div className="flex flex-wrap items-center gap-3">
            {isLoad
              ? Array.from({length: 6}).map((_) => (
                  <StatsItem
                    stats={undefined}
                    loading={isLoad}
                    classNames={{divParent: "w-[230px]"}}
                  />
                ))
              : statsReservationData.map((statNight) => (
                  <StatsItem
                    stats={statNight}
                    classNames={{
                      divParent: "w-[230px]",
                      divInput: "pt-6",
                      label: "text-[32px]",
                      subLabel: "text-base font-bold",
                    }}
                  />
                ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralComponent;
