import { t } from "i18next";
import React from "react";
import ArrowRightIcon from "../../assets/icons/arrow-right.svg?react";

type TaskAssignmentFlowProps = {};

export const TaskAssignmentFlow: React.FC<TaskAssignmentFlowProps> = ({}) => {
  return (
    <div className="flex flex-row items-center select-none">
      <div className="text-sm bg-gray-100 p-1 w-[100px] rounded-md shadow">
        {t(
          "Automation.AddOrUpdateModal.Steps.Assignation.TaskAssignmentFlow.cleaningTask",
        )}
      </div>

      {/* Arrow to sendTask */}
      <ArrowRightIcon className="w-8" />

      <div className="text-sm bg-gray-100 p-1 w-[130px] rounded-md shadow">
        {t(
          "Automation.AddOrUpdateModal.Steps.Assignation.TaskAssignmentFlow.sendTask",
        )}
      </div>

      {/* Arrows to Operators */}
      <div className="flex flex-col mt-2">
        {/* Arrow to the left */}
        <div className="flex flex-col">
          <ArrowRightIcon className="w-[4rem] -rotate-[35deg] mb-[17px] mr-1" />
        </div>

        {/* Arrow to the middle */}
        <div className="flex flex-col">
          <ArrowRightIcon className="w-[3.5rem] text-success/10 rotate-0 ml-1.5" />
        </div>

        {/* Arrow to the right */}
        <div className="flex flex-col items-center">
          <ArrowRightIcon className="w-[4rem] rotate-[35deg] mt-[17px] mr-1" />
        </div>
      </div>

      {/* Operators */}
      <div className="flex flex-col space-y-4 w-max">
        <div className="w-auto p-1 bg-gray-100 rounded-md">
          {t(
            "Automation.AddOrUpdateModal.Steps.Assignation.TaskAssignmentFlow.provider",
            {
              count: 1,
            },
          )}
        </div>
        <div className="w-auto p-1 font-bold rounded-md bg-success/20 text-success">
          {t(
            "Automation.AddOrUpdateModal.Steps.Assignation.TaskAssignmentFlow.provider",
            {
              count: 2,
            },
          )}
        </div>
        <div className="w-auto p-1 bg-gray-100 rounded-md">
          {t(
            "Automation.AddOrUpdateModal.Steps.Assignation.TaskAssignmentFlow.provider",
            {
              count: 3,
            },
          )}
        </div>
      </div>
    </div>
  );
};
