import React, {useEffect} from "react";
import ReservationCheckinsCard from "../../Common/ReservationCheckinsCard";
import ArrowRightWhiteIcon from "../../../../../../assets/icons/arrow-white-right.svg?react";
import ArrowLeftIcon from "../../../../../../assets/icons/arrow-left.svg?react";
import useGuestWebsiteStore from "../../../../../../stores/guestWebsiteStore/useGuestWebsiteStore";
import {Button} from "../../../../../Common/Button/Button";
import {useTranslation} from "react-i18next";
import TitleComponent from "../../../../Common/Template2.0/TitleComponent";
import TitleBlockComponent from "../../Common/TitleBlockComponent";
import {Controller, useForm} from "react-hook-form";
import {TextInput} from "../../../../../Common/TextInput/TextInput";
import {InputSelect} from "../../../../../Common/InputSelect/InputSelect";
import countries from "../../../../../../constants/countries";
import {TextAreaInput} from "../../../../../Common/TextAreaInput/TextAreaInput";
import useGuestWebsite from "../../../../../../hooks/useGuestWebsite";
import {lowerFirstLetter} from "../../../../../../helpers/stringHelper";

interface RentalDetailInformationPageProps {
  loading: boolean;
}
export interface ReservationStep2Form {
  checkin: string;
  checkout: string;
  rental_id: string;
  adults_count: number;
  children_count: number;
  platform_id: number;
  locale: string;
  phone: string;
  first_name: string;
  last_name: string;
  email: string;
  address: string;
  postal_code: string;
  city: string;
  country_code: number | string;
  additional_information: string;
}
const RentalDetailInformationPage = ({}: RentalDetailInformationPageProps) => {
  const {t} = useTranslation();
  const {
    step2Data,
    isLoadingReservationStep2: isLoading,
    reservationStep2,
    errorWebsite,
  } = useGuestWebsite();
  const {
    setRentalDetailTab,
    step1Data,
    rentalData,
    setStep2Data: setStep2DataStore,
    dataGuestWebsite,
  } = useGuestWebsiteStore();
  const {styles} = dataGuestWebsite || {};
  const {color_main, color_background, color_text} = styles || {};
  const {general_informations} = step1Data || {};
  const {checkin, checkout, adults_count, children_count, platform_id} =
    general_informations || {};
  const franceId =
    countries.find((country) => country.code === "FR")?.value ?? 73;
  const form = useForm<ReservationStep2Form>({
    defaultValues: {
      checkin,
      checkout,
      rental_id: "",
      adults_count,
      children_count,
      platform_id: 54,
      locale: "fr",
      additional_information: "",
      country_code: franceId,
    },
  });

  const onSubmitStep2 = async (data: ReservationStep2Form) => {
    const code = countries.find(
      (country) => country.value === Number(data.country_code)
    )?.code;
    const newData: ReservationStep2Form = {
      ...data,
      country_code: String(code) ?? "FR",
    };
    await reservationStep2(newData);
  };

  const handleDefaultValuesForm = () => {
    form.setValue("checkin", checkin ?? "");
    form.setValue("checkout", checkout ?? "");
    form.setValue("rental_id", String(rentalData?.id) ?? "");
    form.setValue("adults_count", adults_count ?? 2);
    form.setValue("children_count", children_count ?? 0);
    form.setValue("platform_id", platform_id ?? 54);
    form.setValue("locale", "fr");
    form.setValue("additional_information", "");
    form.setValue("country_code", franceId);
  };

  const handleStep2Data = () => {
    if (step2Data) {
      setStep2DataStore({
        general_informations: step2Data.general_informations,
        tarification: step2Data.tarification,
        guest: step2Data.guest,
        stripe_account_id: step2Data.stripe_account_id,
        stripe_client_secret: step2Data.stripe_client_secret,
        reservation_request_required: step2Data.reservation_request_required,
        payment_schedule: step2Data.payment_schedule,
      });
      setRentalDetailTab("payment");
    } else {
      setStep2DataStore(undefined);
    }
  };

  useEffect(() => {
    handleDefaultValuesForm();
  }, [rentalData, step1Data]);

  useEffect(() => {
    handleStep2Data();
  }, [step2Data, errorWebsite]);

  const formIsValid = form.formState.isValid;
  return (
    <div
      className="p-4 md:px-contentWebsite"
      style={{backgroundColor: color_background}}
    >
      <Button
        LeftIcon={ArrowLeftIcon}
        type="secondary"
        displayLargeBtn={false}
        className=""
        buttonClassName="md:h-12"
        textClassName="md:text-xl"
        onClick={() => setRentalDetailTab("rental")}
        buttonStyleCss={{color: color_text}}
      >
        {t("GuestPage.ExtrasPayment.goBack")}
      </Button>
      <TitleComponent
        title={t("GuestWebsite.Rental.Info.title")}
        classNames={{
          title: "text-xxxxl",
          divParent: "justify-start pb-4",
          divLabel: "text-start pl-0",
          description: "text-low-contrast font-normal",
        }}
      />
      <div className="md:flex md:flex-row p-6 space-y-6 md:space-y-0 pl-2 md:gap-6">
        <div className="w-full md:min-w-1/2 order-1">
          {/* Coordonnées Section */}
          <TitleBlockComponent
            title={t("GuestWebsite.Rental.Info.titleBlock")}
          />
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <Controller
                control={form?.control}
                name="first_name"
                rules={{
                  required: t("GuestWebsite.required", {
                    input: t("GuestWebsite.Contact.firstName"),
                  }),
                }}
                render={({field: {value, onChange}}) => (
                  <div className="w-full">
                    <TextInput
                      label={t("GuestWebsite.Contact.firstName")}
                      value={value}
                      size="large"
                      placeholder={
                        t("GuestWebsite.Contact.your") +
                        " " +
                        lowerFirstLetter(t("GuestWebsite.Contact.firstName"))
                      }
                      onChangeText={onChange}
                      error={form?.formState.errors.first_name?.message}
                      disabled={isLoading}
                      required
                      labelStyle={{color: color_text}}
                      classNames={{
                        divInput:
                          " outline-none focus:outline-none outline-none focus:ring-offset-0 focus:outline-hidden outline-transparent",
                        input: "outline-none",
                      }}
                    />
                  </div>
                )}
              />
            </div>
            <div>
              <Controller
                control={form?.control}
                name="last_name"
                rules={{
                  required: t("GuestWebsite.required", {
                    input: t("GuestWebsite.Contact.lastName"),
                  }),
                }}
                render={({field: {value, onChange}}) => (
                  <div className="w-full">
                    <TextInput
                      label={t("GuestWebsite.Contact.lastName")}
                      value={value}
                      size="large"
                      placeholder={
                        t("GuestWebsite.Contact.your") +
                        " " +
                        lowerFirstLetter(t("GuestWebsite.Contact.lastName"))
                      }
                      onChangeText={onChange}
                      error={form?.formState.errors.last_name?.message}
                      disabled={isLoading}
                      required
                      labelStyle={{color: color_text}}
                      classNames={{
                        divInput:
                          "outline-none focus:outline-none outline-none focus:ring-offset-0 focus:outline-hidden outline-transparent",
                        input: "outline-none",
                      }}
                    />
                  </div>
                )}
              />
            </div>
            <div>
              <Controller
                control={form?.control}
                name="email"
                rules={{
                  required: t("GuestWebsite.required", {
                    input: t("GuestWebsite.Contact.email"),
                  }),
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: t("GuestWebsite.Contact.invalidEmail"),
                  },
                }}
                render={({field: {value, onChange}}) => (
                  <div className="w-full">
                    <TextInput
                      label={t("GuestWebsite.Contact.email")}
                      value={value}
                      size="large"
                      placeholder={
                        t("GuestWebsite.Contact.your") +
                        " " +
                        lowerFirstLetter(t("GuestWebsite.Contact.email"))
                      }
                      onChangeText={onChange}
                      error={form?.formState.errors.email?.message}
                      disabled={isLoading}
                      required
                      labelStyle={{color: color_text}}
                      classNames={{
                        divInput:
                          " outline-none focus:outline-none outline-none focus:ring-offset-0 focus:outline-hidden outline-transparent",
                        input: "outline-none",
                      }}
                    />
                  </div>
                )}
              />
            </div>
            <div>
              <Controller
                control={form?.control}
                name="phone"
                rules={{
                  required: t("GuestWebsite.required", {
                    input: t("GuestWebsite.Contact.phone"),
                  }),
                }}
                render={({field: {value, onChange}}) => (
                  <div className="w-full">
                    <TextInput
                      label={t("GuestWebsite.Contact.phone")}
                      value={value}
                      size="large"
                      placeholder={
                        t("GuestWebsite.Contact.your") +
                        " " +
                        lowerFirstLetter(t("GuestWebsite.Contact.phone"))
                      }
                      onChangeText={onChange}
                      required
                      error={form?.formState.errors.phone?.message}
                      disabled={isLoading}
                      labelStyle={{color: color_text}}
                      classNames={{
                        divInput:
                          " outline-none focus:outline-none outline-none focus:ring-offset-0 focus:outline-hidden outline-transparent",
                        input: "outline-none",
                      }}
                    />
                  </div>
                )}
              />
            </div>
            <div>
              <Controller
                control={form?.control}
                name="address"
                render={({field: {value, onChange}}) => (
                  <div className="w-full">
                    <TextInput
                      label={t("GuestWebsite.Contact.address")}
                      value={value}
                      size="large"
                      placeholder={
                        t("GuestWebsite.Contact.your") +
                        " " +
                        lowerFirstLetter(t("GuestWebsite.Contact.address"))
                      }
                      onChangeText={onChange}
                      error={form?.formState.errors.address?.message}
                      disabled={isLoading}
                      labelStyle={{color: color_text}}
                      classNames={{
                        divInput:
                          " outline-none focus:outline-none outline-none focus:ring-offset-0 focus:outline-hidden outline-transparent",
                        input: "outline-none",
                      }}
                    />
                  </div>
                )}
              />
            </div>
            <div>
              <Controller
                control={form?.control}
                name="postal_code"
                render={({field: {value, onChange}}) => (
                  <div className="w-full">
                    <TextInput
                      label={t("GuestWebsite.Contact.postalCode")}
                      value={value}
                      size="large"
                      placeholder={
                        t("GuestWebsite.Contact.your") +
                        " " +
                        lowerFirstLetter(t("GuestWebsite.Contact.postalCode"))
                      }
                      onChangeText={onChange}
                      error={form?.formState.errors.postal_code?.message}
                      disabled={isLoading}
                      labelStyle={{color: color_text}}
                      classNames={{
                        divInput:
                          " outline-none focus:outline-none outline-none focus:ring-offset-0 focus:outline-hidden outline-transparent",
                        input: "outline-none",
                      }}
                    />
                  </div>
                )}
              />
            </div>
            <div>
              <Controller
                control={form?.control}
                name="city"
                render={({field: {value, onChange}}) => (
                  <div className="w-full">
                    <TextInput
                      label={t("GuestWebsite.Contact.city")}
                      value={value}
                      size="large"
                      placeholder={
                        t("GuestWebsite.Contact.your") +
                        " " +
                        lowerFirstLetter(t("GuestWebsite.Contact.city"))
                      }
                      onChangeText={onChange}
                      error={form?.formState.errors.city?.message}
                      disabled={isLoading}
                      labelStyle={{color: color_text}}
                      classNames={{
                        divInput:
                          " outline-none focus:outline-none outline-none focus:ring-offset-0 focus:outline-hidden outline-transparent",
                        input: "outline-none",
                      }}
                    />
                  </div>
                )}
              />
            </div>
            <div>
              <Controller
                control={form.control}
                name="country_code"
                rules={{
                  required: t("GuestWebsite.required", {
                    input: t("GuestWebsite.Contact.country"),
                  }),
                }}
                render={({field: {onChange, value}}) => (
                  <div className="flex-1">
                    <InputSelect
                      required
                      label={t("GuestWebsite.Contact.country")}
                      selectedValue={value}
                      items={countries}
                      error={form.formState.errors.country_code?.message}
                      onSelect={onChange}
                      disabled={isLoading}
                      labelStyle={{color: color_text}}
                      classNames={{
                        divInput:
                          " outline-none focus:outline-none outline-none focus:ring-offset-0 focus:outline-hidden outline-transparent",
                        input: "outline-none",
                      }}
                    />
                  </div>
                )}
              />
            </div>
          </div>

          {/* Code Promotionnel Section */}
          {/* <h2 className="text-2xl font-bold mt-8 mb-6">Code promotionnel</h2>
          <div className="flex items-center gap-4">
            <Controller
              control={form?.control}
              name="promo_code"
              render={({field: {value, onChange}}) => (
                <div className="w-full">
                  <TextInput
                    label={"Arrivée"}
                    value={value}
                    size="large"
                    placeholder={"MM / AA"}
                    onChangeText={onChange}
                    // error={form?.formState.errors.checkin?.message}
                    // disabled={isLoading}
                  />
                </div>
              )}
            />
            <button className="hover:brightness-[0.8] text-white px-4 py-2 rounded-md" style={{backgroundColor:color_main}}>
              Appliquer
            </button>
          </div> */}

          {/* Commentaire Additionnel Section */}
          <TitleBlockComponent
            title={t("GuestWebsite.Rental.Info.additionnalInfo")}
            classNames={{divLabel: "mt-8"}}
          />

          <Controller
            control={form?.control}
            name="additional_information"
            render={({field: {value, onChange}}) => (
              <div className="w-full">
                <TextAreaInput
                  label={""}
                  value={value}
                  rows={2}
                  placeholder={t(
                    "GuestWebsite.Rental.Info.additionnalInfoPlaceholder"
                  )}
                  onTextChange={onChange}
                  error={form?.formState.errors.additional_information?.message}
                  disabled={isLoading}
                  labelStyle={{color: color_text}}
                  classNames={{
                    input:
                      "resize-none text-sm hover:border-element-border-hover  outline-none focus:outline-none focus:ring-offset-0 focus:outline-transparent",
                  }}
                />
              </div>
            )}
          />
        </div>
        <div className="w-full md:w-[65%] order-2">
          <ReservationCheckinsCard
            buttonText={t("Global.validate")}
            showBookingCard
            showPaymentBreakdownDetails
            RightIcon={ArrowRightWhiteIcon}
            error={errorWebsite}
            classNames={{divButton: "mx-0"}}
            disabledButton={!formIsValid}
            loading={isLoading}
            onClick={form.handleSubmit(onSubmitStep2)}
          />
        </div>
      </div>
    </div>
  );
};

export default RentalDetailInformationPage;
