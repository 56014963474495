import React from "react";
import useGuestWebsiteStore from "../../../stores/guestWebsiteStore/useGuestWebsiteStore";
import GuestWebsiteTemplate2HeaderComponent from "./Template2.0/GuestWebsiteTemplate2HeaderComponent";
import {GuestWebsiteModel} from "../../../types/GETTypes";

interface GuestWebsiteHeaderLayoutProps {
  loading: boolean;
}

const RedirectToCorrectTemplate = (props: GuestWebsiteHeaderLayoutProps) => {
  const {templateGuestWebsite} = useGuestWebsiteStore();
  switch (templateGuestWebsite) {
    case GuestWebsiteModel.MODEL_2_0:
      return <GuestWebsiteTemplate2HeaderComponent {...props} />;
    case GuestWebsiteModel.MODEL_CHIC:
      return <></>;
    case GuestWebsiteModel.MODEL_MINIMAL:
      return <></>;
    default:
      return <GuestWebsiteTemplate2HeaderComponent {...props} />;
  }
};

const GuestWebsiteHeaderLayout = (props: GuestWebsiteHeaderLayoutProps) => {
  return <RedirectToCorrectTemplate {...props} />;
};

export default GuestWebsiteHeaderLayout;
