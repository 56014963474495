import React, {useEffect, useImperativeHandle} from "react";
import {
  RentalLightBookingItemResponse,
} from "../../../../types/GETTypes";
import {Controller, useForm} from "react-hook-form";
import {BookingConnectDataRentalRoomRates} from "./BookingConnectModal";
import {useTranslation} from "react-i18next";
import {InputSelect} from "../../../Common/InputSelect/InputSelect";
import {ValueType} from "../../../../types/commonTypes";

export type ParamsImportType = "create" | "connect";

export interface BookingConnectRentalRoomTypeForm {
  room_id: string;
  rate_id: string;
  rate_name: string;
  room_name: string;
  property_id: string;
  mode: ParamsImportType;
  rental_id: ValueType;
}

const BookingConnectRentalRoomType = React.forwardRef(
  (
    {
      property,
      onChange,
      loading,
      roomsRates,
    }: {
      property: RentalLightBookingItemResponse;
      onChange: () => Promise<void>;
      loading: boolean;
      roomsRates: BookingConnectDataRentalRoomRates | undefined;
    },
    ref
  ) => {
    const {t} = useTranslation();
    const {
      trigger,
      getValues,
      watch,
      control,
      setValue,
      formState: {errors},
    } = useForm<BookingConnectRentalRoomTypeForm>({
      defaultValues: {
        property_id: roomsRates?.id,
      },
      mode: "onChange",
    });

    useImperativeHandle(ref, () => ({
      trigger,
      getValues,
    }));

    useEffect(() => {
      const subscription = watch(() => {
        onChange();
      });
      return () => subscription.unsubscribe();
    }, [watch, onChange]);

    useEffect(() => {
      if (roomsRates) {
        setValue("property_id", roomsRates.id);
        if (roomsRates?.rooms && roomsRates.rooms.length > 0) {
          setValue("room_id", roomsRates.rooms[0].id);
          setValue("room_name", roomsRates.rooms[0].name);
        }
        if (roomsRates?.rates && roomsRates.rates.length > 0) {
          setValue("rate_id", roomsRates.rates[0].id);
          setValue("rate_name", roomsRates.rates[0].name);
        }
      }
    }, [roomsRates]);

    const itemsRooms = roomsRates?.rooms.map((room) => {
      return {
        value: room.id,
        label: room.name,
      };
    });
    const itemsRates = roomsRates?.rates.map((rate) => {
      return {
        value: rate.id,
        label: rate.name,
      };
    });

    return (
      <div className="flex flex-col p-2 px-0 space-y-3">
        <div className="flex space-x-3">
          <div className="object-fill w-16 h-12 shrink-0 rounded-4px border-1 border-element-border/50 overflow-hidden">
            <img
              src={property?.photo ?? ""}
              className="object-cover w-16 h-12 shrink-0"
            />
          </div>

          <div className="flex flex-col gap-y-3 w-full">
            <div>
              <p className="font-semibold text-high-contrast underline underline-offset-4">
                {property?.name}
              </p>
              <p className="font-light text-low-contrast mt-1">
                {`${property?.address} ${property?.zip_code} ${property?.city}`}
              </p>
            </div>
          </div>
        </div>

        <div className="flex space-x-2">
          <Controller
            control={control}
            name="room_id"
            rules={{
              required: {
                value: true,
                message: t("Progression.roomsRentalBookingRequired"),
              },
            }}
            render={({field: {value, onChange}}) => {
              const handleChange = (value: ValueType) => {
                const name = roomsRates?.rooms.find(
                  (r) => r.id === value
                )?.name;
                if (name) {
                  setValue("room_name", name);
                }
                onChange(value);
              };
              return (
                <div className="flex-1 w-1/2">
                  <InputSelect
                    label={t("Progression.roomsRentalBooking")}
                    items={itemsRooms ?? []}
                    selectedValue={value}
                    onSelect={handleChange}
                    error={errors.room_id?.message}
                    disabled={loading}
                  />
                </div>
              );
            }}
          />
          <Controller
            control={control}
            name="rate_id"
            rules={{
              required: {
                value: true,
                message: t("Progression.ratesRentalBookingRequired"),
              },
            }}
            render={({field: {value, onChange}}) => {
              const handleChange = (value: ValueType) => {
                const name = roomsRates?.rates.find(
                  (r) => r.id === value
                )?.name;
                if (name) {
                  setValue("rate_name", name);
                }
                onChange(value);
              };
              return (
                <div className="flex-1 w-1/2">
                  <InputSelect
                    label={t("Progression.ratesRentalBooking")}
                    items={itemsRates ?? []}
                    selectedValue={value}
                    onSelect={handleChange}
                    error={errors.rate_id?.message}
                    disabled={loading}
                  />
                </div>
              );
            }}
          />
        </div>
      </div>
    );
  }
);

export default BookingConnectRentalRoomType;
