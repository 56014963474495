import React, { ReactNode } from "react";

export interface FlexColProps {
  children?: ReactNode;
  gap?: number;
  className?: string;
  visible?: boolean;
  id?: string;
  onClick?: () => void;
}

export const FlexCol: React.FC<FlexColProps> = ({
  children,
  gap = 2,
  className = "",
  visible = true,
  id = "",
  onClick = () => {},
}) => {
  return (
    <section
      onClick={onClick}
      id={id}
      className={`flex flex-col ${className} ${!visible && "hidden"}`}
      style={{ gap: `${gap}px` }}
    >
      {children}
    </section>
  );
};
