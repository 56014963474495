import React, { useEffect, useState } from "react";
import { RightModal } from "../Common/RightModal/RightModal";
import { Trans, useTranslation } from "react-i18next";
import { TextInput } from "../Common/TextInput/TextInput";
import InfoIcon from "../../assets/icons/information.svg?react";
import { Button } from "../Common/Button/Button";
import CheckWhiteIcon from "../../assets/icons/check-white.svg?react";

export const UpsertCustomURLModal = ({
  url,
  isVisible,
  onClose,
  onChange,
}: {
  url: string | undefined | null;
  isVisible: boolean;
  onClose: () => void;
  onChange: (url: string) => void;
}) => {
  const { t } = useTranslation();
  const [currentURL, setCurrentURL] = useState<string | undefined | null>("");

  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    setCurrentURL(url);
  }, [isVisible]);

  const handleClose = () => {
    onClose();
  };

  const handleSave = () => {
    // Regex pour vérifier un nom de domaine valide
    const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-_]{1,63}\.)+[a-zA-Z]{2,}$/;

    if (currentURL && domainRegex.test(currentURL)) {
      setError(undefined);
      onChange(currentURL);
      handleClose();
    } else {
      setError(t("Website.Add.URL.Modal.error"));
    }
  };

  return (
    <RightModal
      onClose={handleClose}
      isVisible={isVisible}
      title={t("Website.Add.URL.Modal.title")}
    >
      <div className="flex flex-col justify-between">
        <div className="flex flex-col w-full">
          <div className="mb-1">
            <TextInput
              label={t("Website.Add.URL.Modal.label")}
              value={currentURL ?? ""}
              placeholder={t("Website.Add.URL.Modal.placeholder")}
              onChangeText={setCurrentURL}
              error={error}
            />
          </div>

          <p className="text-xs font-light text-low-contrast">
            {t("Website.Add.URL.Modal.hint")}
          </p>

          <div className="p-2 rounded-6px bg-element-background flex gap-2 mt-4">
            <InfoIcon className="w-6 shrink-0" />

            <div>
              <p className="text-high-contrast font-semibold">
                {t("Website.Add.URL.Modal.infosTitle")}
              </p>

              <div className="text-low-contrast mt-2 flex flex-col gap-0.5">
                {/* <Trans i18nKey="Website.Add.URL.Modal.infosDescription" /> */}

                <p>
                  Vous avez déjà un nom de domaine acheté auprès d’un autre
                  hébergeur ? Aucun problème !
                </p>

                <p className="my-1">
                  Voici les étapes pour le connecter facilement à votre site
                  créé avec SuperHote :
                </p>

                <ol>
                  <li>
                    1. <strong>Accédez aux paramètres DNS</strong> de votre nom
                    de domaine chez votre fournisseur actuel (exemple : GoDaddy,
                    OVH, Namecheap, etc.).
                  </li>
                  <li>
                    2.{" "}
                    <strong>Ajoutez un enregistrement de type “CNAME”</strong>{" "}
                    avec les informations suivantes :
                  </li>
                  <li>
                    &nbsp;&nbsp;&nbsp;&nbsp;Nom / Sous-domaine : www (ou
                    l’entrée souhaitée, selon vos besoins).
                  </li>
                  <li>
                    &nbsp;&nbsp;&nbsp;&nbsp;Cible :{" "}
                    <strong>
                      [adresse personnalisée générée par SuperHote]
                    </strong>{" "}
                    (cette adresse est fournie dans SuperHote).
                  </li>
                </ol>

                <p className="my-1">
                  Si vous souhaitez que votre domaine principal (sans le “www”)
                  redirige également vers votre site, ajoutez un enregistrement
                  de type “A” avec notre adresse IP [XXXXXX].
                </p>

                <p>
                  <strong>
                    3. Enregistrez les modifications dans votre gestionnaire
                    DNS.
                  </strong>
                </p>
                <p>
                  <strong>4. Retournez sur SuperHote,</strong> puis ajoutez
                  ci-dessus votre nom de domaine. Vous pourrez ainsi finaliser
                  la connexion.
                </p>
                <p>
                  <strong>5. Patientez quelques heures</strong> (jusqu’à 48h)
                  pour que les modifications prennent effet.
                </p>

                <p className="my-1">Exemples concrets :</p>
                <p>
                  Si votre domaine est monsite.com, vous pouvez configurer
                  www.monsite.com pour qu’il pointe vers votre site généré par
                  SuperHote.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex gap-3">
          <Button type="secondary" onClick={handleClose}>
            {t("Global.cancel")}
          </Button>

          <Button RightIcon={CheckWhiteIcon} onClick={handleSave}>
            {t("Global.save")}
          </Button>
        </div>
      </div>
    </RightModal>
  );
};
