import React, {forwardRef, useEffect} from "react";
import {
  RentalLightListItemResponse,
  RentalResponse,
} from "../../../../types/GETTypes";
import {
  ProgressionRentalsRulesModalNextStepOtherFields,
  ProgressionRentalsRulesModalRef,
} from "../ProgressionRentalsRulesModal.type";
import {useTranslation} from "react-i18next";
import {RentalInformationsCard} from "../../../Rental/Infos/Informations/InformationsCard";
import {Rental} from "../../../../pages/RentalPage/RentalPage.type";
import {useRentalPageContext} from "../../../../pages/RentalPage/RentalPageContext";

const ProgressionRentalsInfosPrice = forwardRef<
  ProgressionRentalsRulesModalRef,
  {
    rentals: RentalLightListItemResponse[];
    onNext: (
      nextRental: RentalLightListItemResponse,
      nextOtherFields: ProgressionRentalsRulesModalNextStepOtherFields
    ) => void;
  }
>(({onNext, rentals}, ref) => {
  const {rental} = useRentalPageContext();
  const {t} = useTranslation();
  const infoAirbnb = rental?.details?.external_connexions.platform_airbnb
    ?.enabled
    ? t("Progression.RentalsRules.Infos.info")
    : "";

  useEffect(() => {}, [rental]);

  return (
    <div className="flex flex-col gap-6">
      <p className="text-low-contrast">{infoAirbnb}</p>
      <RentalInformationsCard
        rentals={rentals}
        rentalInformations={rental?.details.informations}
        workspaceOwner={rental?.workspace_owner}
      />
    </div>
  );
});

export default ProgressionRentalsInfosPrice;
