import React from "react";
import useGuestWebsiteStore from "../../../../stores/guestWebsiteStore/useGuestWebsiteStore";
import {Button} from "../../../Common/Button/Button";
import {useTranslation} from "react-i18next";

const ReservationBannerComponent = () => {
  const {t} = useTranslation();
  const {setTabGuestWebsite, tabGuestWebsite, dataGuestWebsite} =
    useGuestWebsiteStore();
  const {styles} = dataGuestWebsite || {};
  const {color_main} = styles || {};
  const excluded = ["rentals", "rental"];
  if (excluded.includes(tabGuestWebsite)) {
    return <></>;
  }
  return (
    <div
      className="absolute -top-8 md:-top-10 left-0 right-0 mx-auto text-white py-6 px-4 md:py-4 md:px-12 rounded-lg flex flex-col md:flex-row items-center justify-between shadow-lg max-w-4xl"
      style={{backgroundColor: color_main}}
    >
      {/* Text Section */}
      <h2 className="text-lg md:text-2xl font-bold text-center md:text-left">
        {t("GuestWebsite.Footer.Banner.ready")}
      </h2>

      {/* Button Section */}
      <button
        className="mt-4 md:mt-0 bg-white font-semibold py-3 px-6 rounded-lg shadow-md hover:bg-gray-300 transition"
        onClick={() => setTabGuestWebsite("rentals")}
        style={{color: color_main}}
      >
        {t("GuestWebsite.Footer.Banner.buttonTitle")}
      </button>
    </div>
  );
};

export default ReservationBannerComponent;
