import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import ExportIcon from "../../../../assets/icons/export.svg?react";
import SearchIcon from "../../../../assets/icons/search.svg?react";
import { ReservationExtraOrderStatusEnum } from "../../../../enums/GETenums";
import { Button } from "../../../Common/Button/Button";
import { CalendarInputValue } from "../../../Common/CalendarInput/CalendarInput.type";
import { DateFilter } from "../../../Common/DateFilter/DateFilter";
import { DateRangePreset } from "../../../Common/DateFilter/DateFilter.type";
import { GroupButton } from "../../../Common/GroupButton/GroupButton";
import { GroupButtonItemType } from "../../../Common/GroupButton/GroupButton.type";
import { TextInput } from "../../../Common/TextInput/TextInput";
import { PaymentReservationExtrasFiltersProps } from "./PaymentReservationFilters.type";

export const PaymentExtrasReservationFilters: React.FC<
  PaymentReservationExtrasFiltersProps
> = ({
  loading,
  onSearchText,
  totals,
  onFilterButtonClick,
  currentActiveButtonFilter,
  onDatesChanged,
}) => {
  const { t } = useTranslation();

  const [dates, setDates] = useState<CalendarInputValue>([null, null]);
  const [nbRangeDate, setNbRangeDate] = useState<number>(0);
  const [dateRangePreset, setDateRangePreset] =
    useState<DateRangePreset>("next_seven_days");

  const getGroupButtonItems = (): GroupButtonItemType[] => [
    {
      label: t("Global.all", {
        context: "male",
        count: 2,
      }),
      value: "ALL",
      badge: loading ? null : totals.all.toString(),
      disabled: loading,
      isActive: currentActiveButtonFilter === "ALL",
    },
    {
      label: t("Global.unpaid", {
        context: "male",
      }),
      value: ReservationExtraOrderStatusEnum.UNPAID,
      badge: loading ? null : totals.unpaid.toString(),
      disabled: loading,
      isActive:
        currentActiveButtonFilter === ReservationExtraOrderStatusEnum.UNPAID,
    },
    {
      label: t("Booking.PaymentExtraStatus.partiallyPaid", {
        count: Number(totals.partiallyPaid),
      }),
      value: ReservationExtraOrderStatusEnum.PARTIALLY_PAID,
      badge: loading ? null : totals.partiallyPaid.toString(),
      disabled: loading,
      isActive:
        currentActiveButtonFilter ===
        ReservationExtraOrderStatusEnum.PARTIALLY_PAID,
    },
    {
      label: t("Global.paid", {
        context: "male",
        count: Number(totals.paid),
      }),
      value: ReservationExtraOrderStatusEnum.PAID,
      badge: loading ? null : totals.paid.toString(),
      disabled: loading,
      isActive:
        currentActiveButtonFilter === ReservationExtraOrderStatusEnum.PAID,
    },
    {
      label: t("Booking.PaymentExtraStatus.preAuthorized", {
        count: Number(totals.preAuthorized),
      }),
      value: ReservationExtraOrderStatusEnum.PRE_AUTHORIZED,
      badge: loading ? null : totals.preAuthorized.toString(),
      disabled: loading,
      isActive:
        currentActiveButtonFilter ===
        ReservationExtraOrderStatusEnum.PRE_AUTHORIZED,
    },
    {
      label: t("Global.canceled", {
        context: "male",
      }),
      value: ReservationExtraOrderStatusEnum.REFUNDED,
      badge: loading ? null : totals.canceled.toString(),
      disabled: loading,
      isActive:
        currentActiveButtonFilter === ReservationExtraOrderStatusEnum.REFUNDED,
    },
  ];

  return (
    <div className="flex flex-col gap-y-5">
      <div className="flex flex-row items-center space-x-3">
        <div className="flex-1">
          <TextInput
            size="large"
            Icon={SearchIcon}
            placeholder={t("Global.search")}
            onChangeText={onSearchText}
          />
        </div>

        {/* TODO: [PaymentReservation] Implement export button logic */}
        <div title={t("Global.wip")}>
          <Button
            type="secondary"
            onClick={() => {}}
            RightIcon={ExportIcon}
            disabled={true}
          >
            {t("Global.export")}
          </Button>
        </div>
      </div>

      <div className="flex flex-row items-center space-x-3">
        <GroupButton
          items={getGroupButtonItems()}
          onClick={onFilterButtonClick}
        />
      </div>

      <div className="flex flex-row items-center space-x-3">
        <div className="flex">
          <DateFilter
            onDatesChanged={onDatesChanged}
            onDateRangePresetChanged={(dateRangePreset) => {
              setDateRangePreset(dateRangePreset);
            }}
          />
        </div>
      </div>
    </div>
  );
};
