import React, { useState } from "react";
import { FullScreenLayout } from "../../components/Layout/FullScreenLayout/FullScreenLayout";
import { AddWebsiteSummary } from "../../components/AddWebsite/AddWebsiteSummary";
import { useModal } from "../../hooks/useModal";
import { AddWebsiteCloseModal } from "../../components/AddWebsite/AddWebsiteCloseModal";
import {
  AddWebsiteGeneral,
  AddWesiteGeneralType,
} from "../../components/AddWebsite/AddWebsiteGeneral";
import {
  AddWebsiteRentals,
  AddWesiteRentalsType,
} from "../../components/AddWebsite/AddWebsiteRentals";
import {
  AddWebsiteTemplates,
  AddWesiteTemplatesType,
} from "../../components/AddWebsite/AddWebsiteTemplates";
import {
  AddWebsiteURL,
  AddWesiteURLType,
} from "../../components/AddWebsite/AddWebsiteURL";
import { AddWebsiteLegal } from "../../components/AddWebsite/AddWebsiteLegal";
import { AddWebsiteDone } from "../../components/AddWebsite/AddWebsiteDone";

export type WebsiteAddStepDataType = {
  general?: AddWesiteGeneralType;
  rentals?: AddWesiteRentalsType;
  template?: AddWesiteTemplatesType;
  url?: AddWesiteURLType;
};

export const AddWebsitePage = () => {
  const [currentStep, setCurrentStep] = useState<
    "general" | "rentals" | "templates" | "url" | "legals" | "done"
  >("general");

  const [stepData, setStepData] = useState<WebsiteAddStepDataType>({});

  const closeModal = useModal();

  const handleNextStepGeneral = (values: AddWesiteGeneralType) => {
    setStepData({ ...stepData, general: values });
    setCurrentStep("rentals");
  };

  const handleNextStepRentals = (values: AddWesiteRentalsType) => {
    setStepData({ ...stepData, rentals: values });
    setCurrentStep("templates");
  };

  const handleNextStepTemplates = (values: AddWesiteTemplatesType) => {
    setStepData({ ...stepData, template: values });
    setCurrentStep("url");
  };

  const handleNextStepURL = (values: AddWesiteURLType) => {
    setStepData({ ...stepData, url: values });
    setCurrentStep("legals");
  };

  const handleNextStepLegals = () => {
    setCurrentStep("done");
  };

  return (
    <>
      <AddWebsiteCloseModal
        isVisible={closeModal.isVisible}
        onClose={closeModal.close}
      />

      <FullScreenLayout isCheckAuthEnabled={true} onClose={closeModal.open}>
        {/* Left */}
        <div className="w-2/5 max-w-md p-2">
          <AddWebsiteSummary step={currentStep} data={stepData} />
        </div>

        {/* Right */}
        <div className="flex-1 pt-8 ps-28 text-sm flex flex-col justify-between">
          {currentStep === "general" && (
            <AddWebsiteGeneral
              stepData={stepData}
              onNextStep={handleNextStepGeneral}
              onClose={closeModal.close}
            />
          )}

          {currentStep === "rentals" && (
            <AddWebsiteRentals
              stepData={stepData}
              onNextStep={handleNextStepRentals}
              onPreviousStep={() => setCurrentStep("general")}
              onClose={closeModal.close}
            />
          )}

          {currentStep === "templates" && (
            <AddWebsiteTemplates
              stepData={stepData}
              onNextStep={handleNextStepTemplates}
              onPreviousStep={() => setCurrentStep("rentals")}
              onClose={closeModal.close}
            />
          )}

          {currentStep === "url" && (
            <AddWebsiteURL
              stepData={stepData}
              onNextStep={handleNextStepURL}
              onPreviousStep={() => setCurrentStep("templates")}
              onClose={closeModal.close}
            />
          )}

          {currentStep === "legals" && (
            <AddWebsiteLegal
              stepData={stepData}
              onNextStep={handleNextStepLegals}
              onPreviousStep={() => setCurrentStep("url")}
              onClose={closeModal.close}
            />
          )}

          {currentStep === "done" && (
            <AddWebsiteDone url={stepData.url ?? ""} />
          )}
        </div>
      </FullScreenLayout>
    </>
  );
};
