import React, { useEffect } from "react";
import { MainLayout } from "../../components/Layout/MainLayout/MainLayout";
import { useTranslation } from "react-i18next";
import { TextInput } from "../../components/Common/TextInput/TextInput";
import { Button } from "../../components/Common/Button/Button";
import SearchIcon from "../../assets/icons/search.svg?react";
import SettingsSlidersIcon from "../../assets/icons/settings-sliders.svg?react";
import PlusCircleWhiteIcon from "../../assets/icons/plus-circle-white.svg?react";
import InfosIcon from "../../assets/icons/information.svg?react";
import CloseIcon from "../../assets/icons/close.svg?react";
import { WebsiteListNoData } from "../../components/Website/WebsiteListNoData";
import { useModal } from "../../hooks/useModal";
import { IntegrateModal } from "../../components/Website/IntegrateModal";
import { AddChoiceModal } from "../../components/Website/AddChoiceModal";
import { TableList } from "../../components/Common/TableList/TableList";
import { useTablePage } from "../../hooks/useTablePage";
import paths from "../../constants/paths";
import { useNavigate } from "react-router-dom";
import { ValueType } from "../../types/commonTypes";
import { WebsiteListHeader } from "../../components/Website/list/WebsiteListHeaderList";
import { WebsiteListItem } from "../../components/Website/list/WebsiteListItem";
import { RentalItemSkeleton } from "../../components/RentalList/RentalTable/RentalItemSkeleton";
import { WebsiteListItemSkeleton } from "../../components/Website/list/WebsiteListItemSkeleton";

export const WebsiteListPage: React.FC<{}> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const integrateModal = useModal();
  const addChoiceModal = useModal();

  const websiteTable = useTablePage(
    `${import.meta.env.VITE_API_URL}${paths.API.WEBSITE.LIST}`,
    "websites"
  );

  useEffect(() => {
    websiteTable.fetch({});
  }, []);

  const handleClickWebsite = (id: ValueType) => {
    navigate(`${paths.WEBSITES.LIST}/${id}`);
  };

  const handleClickAdd = () => {
    navigate(`${paths.WEBSITES.CREATE}`);
  };

  return (
    <>
      <IntegrateModal
        isVisible={integrateModal.isVisible}
        onClose={integrateModal.close}
      />

      <AddChoiceModal
        isVisible={addChoiceModal.isVisible}
        onClose={addChoiceModal.close}
        onAdd={handleClickAdd}
        onIntegrate={integrateModal.open}
      />

      <MainLayout sidebarActiveItem="websites" title={t("Website.List.title")}>
        <div className="flex items-center gap-3">
          <TextInput Icon={SearchIcon} placeholder={t("Website.List.search")} />

          <div>
            <Button
              type="secondary"
              RightIcon={SettingsSlidersIcon}
              onClick={integrateModal.open}
            >
              {t("Website.List.integrate")}
            </Button>
          </div>
          <div>
            <Button RightIcon={PlusCircleWhiteIcon} onClick={handleClickAdd}>
              {t("Website.List.create")}
            </Button>
          </div>
        </div>

        <div className="flex flex-col w-full rounded-6px bg-element-background p-2 mt-4 text-sm gap-1">
          <div className="flex gap-2 items-center">
            <InfosIcon className="size-4" />

            <p className="text-high-contrast font-semibold flex-1">
              {t("Website.List.WhatIs.title")}
            </p>

            <CloseIcon className="size-4 cursor-pointer" />
          </div>

          <p className="text-low-contrast font-light">
            {t("Website.List.WhatIs.description")}
          </p>
        </div>

        <TableList
          i18nElement="Website.name" // Ready
          // Filters={getListViewFiltersNode()} // Peut être oui, peut être non
          Header={WebsiteListHeader} // Ready
          Item={WebsiteListItem} // Attente endpoint Lauren
          Skeleton={WebsiteListItemSkeleton} // RentalItem d'abord
          NoData={<WebsiteListNoData onAdd={addChoiceModal.open} />} // Ready
          tablePage={websiteTable} // Ready
          onClick={handleClickWebsite} // Ready
        />
      </MainLayout>
    </>
  );
};
