import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useIframe } from "../../hooks/api/iframe";
import { RentalLightListItemResponse } from "../../types/GETTypes";
import IframeRentalAccessDenied from "../../components/IframeRentals/IframeRentalAccessDenied";
import { TextInput } from "../../components/Common/TextInput/TextInput";
import RentalsListComponent from "../../components/GuestWebsite/Common/Template2.0/RentalsListComponent";

/**
 * Page that displays accommodations
 * directly within an iframe, using an API call.
 */
export default function IframeRentalsSearchEnginePage() {
  const [rentals, setRentals] = useState<RentalLightListItemResponse[]>();
  const { getRentals, getUrlAllowed } = useIframe();
  const params = useParams();
  const apiKey = params.apiKey;
  const [isOpen, setOpen] = useState(false);
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  const updateRentals = (search: string) => {
    if (apiKey) {
      setRentals([]);
      getRentals(apiKey, search ?? "").then((response) => {
        setRentals(response);
        setOpen(true);
      });
    }
  };

  useEffect(() => {
    updateRentals("");
  }, []);

  const handleChange = (text: string) => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      updateRentals(text);
    }, 500);
  };

  if (!isOpen) return <IframeRentalAccessDenied />;

  if (!rentals) return <></>;

  // TODO : show rentals with style (with the Super Template Style like)
  return (
      <div className={"flex flex-col"}>
        <div className={"mt-4 mx-10"}>
          <TextInput placeholder={"Search..."} onChangeText={handleChange}/>
        </div>
        <div className={"mt-4"}>
          {/*<RentalsListComponent*/}
          {/*    rentals={rentals}*/}
          {/*    forIframe={true}*/}
          {/*/>*/}

          dev
        </div>
      </div>
  );
}
