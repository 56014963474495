import React, {useEffect, useState} from "react";
import {CenterModal} from "../Common/CenterModal/CenterModal";
import {Trans, useTranslation} from "react-i18next";
import {Button} from "../Common/Button/Button";
import {get, post} from "../../helpers/APIHelper";
import paths from "../../constants/paths";
import ArrowRightIcon from "../../assets/icons/arrow-right.svg?react";
import ArrowLeftIcon from "../../assets/icons/arrow-left.svg?react";
import {TextAreaInput} from "../Common/TextAreaInput/TextAreaInput";
import {Switch} from "../Common/Switch/Switch";
import AirbnbIcon from "../../assets/icons/airbnb.svg?react";
import BookingIcon from "../../assets/icons/booking.svg?react";
import LoginIcon from "../../assets/icons/log-in.svg?react";
import LoginIconActive from "../../assets/icons/log-in-active.svg?react";
import CalendarCheckActive from "../../assets/icons/calendar-check-active.svg?react";
import CalendarCheck from "../../assets/icons/calendar-check.svg?react";
import FileTextIcon from "../../assets/icons/file-text.svg?react";
import FileTextActiveIcon from "../../assets/icons/file-text-active.svg?react";
import StartIcon from "../../assets/icons/star.svg?react";
import StartActiveIcon from "../../assets/icons/star-active.svg?react";
import {useNavigate} from "react-router-dom";
import {AutoMessageSuggestionModalSkeleton} from "./AutoMessageSuggestionModalSkeleton";
import {GroupButton} from "../Common/GroupButton/GroupButton";
import FrenchIcon from "../../assets/flags/France.svg?react";
import EnglishIcon from "../../assets/flags/USA.svg?react";
import SpanishIcon from "../../assets/flags/Spain.svg?react";
import GermanIcon from "../../assets/flags/Germany.svg?react";
import {AutoMessageLanguageEnum} from "../AutoMessage/AutoMessage.type";
import {forEach} from "lodash";
import {
  DelayType,
  IconsType,
  NotificationContentListItemResponse,
  NotificationListItemResponse,
  SuggestionForm
} from "../../types/GETTypes";


export const AutoMessageSuggestionModal: React.FC<{
  isVisible: boolean;
  onSuccess: () => void;
  onClose: () => void;
}> = ({isVisible, onSuccess, onClose }) => {
  const { t } = useTranslation();
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [selected, setSelected] = useState<number>(0);
  const [suggestions, setSuggestions] = useState<NotificationListItemResponse[]>([]);
  const [suggestionsReformat, setSuggestionsReformat] = useState<SuggestionForm[]>([]);
  const [suggestion, setSuggestion] = useState<any | null>(null);
  const [isValid, setIsValid] = useState<boolean>(true);
  const navigate = useNavigate();
  const [lang, setLang] = useState<string| number>("french");

  const handleDisplaySuggestions = async () => {
    setLoading(true);
    setError(null);

    const res = await get(
        `${import.meta.env.VITE_API_URL}${paths.API.AUTO_MESSAGES_SUGGESTION}`,
    );

    if (res?.data?.success) {
      onSuccess();
      const suggestions = res?.data?.result;
      const initializedSuggestions = suggestions.map((item: any) => ({
        ...item,
        enabled: true,
      }));

      //console.log("[handleDisplaySuggestions]", initializedSuggestions);
      const updatedSuggestions = [...initializedSuggestions];

      const reformattedSuggestions: SuggestionForm[] = []

      forEach(updatedSuggestions, (suggestion) => {
        const contents =
            {
              english:getContentByLanguage(AutoMessageLanguageEnum.english, suggestion.contents),
              french: getContentByLanguage(AutoMessageLanguageEnum.french, suggestion.contents),
              spanish:  getContentByLanguage(AutoMessageLanguageEnum.spanish, suggestion.contents),
              german: getContentByLanguage(AutoMessageLanguageEnum.german, suggestion.contents),
            };
        reformattedSuggestions.push({
          ...suggestion,
          contents: contents
        });

      })

      //console.log("[reformattedSuggestions]", reformattedSuggestions);
      setSuggestions(updatedSuggestions);
      setSuggestionsReformat(reformattedSuggestions);
      setSuggestion({
        ...initializedSuggestions[0],
        contents: {
          english: getContentByLanguage(AutoMessageLanguageEnum.english,initializedSuggestions[0].contents ),
          french: getContentByLanguage(AutoMessageLanguageEnum.french, initializedSuggestions[0].contents ),
          spanish: getContentByLanguage(AutoMessageLanguageEnum.spanish, initializedSuggestions[0].contents ),
          german: getContentByLanguage(AutoMessageLanguageEnum.german, initializedSuggestions[0].contents ),
        }});
      setError(null);
      setLoading(false);

    } else {
      setError(res?.response?.data?.message);
    }

    setLoading(false);
  };

  const getContentByLanguage = (value: AutoMessageLanguageEnum, contents: NotificationContentListItemResponse[]) => {
    const index = contents.findIndex(
        (c) => c.language_id === value
    );

    if (index === -1) return {title: "", message: ""};
    else return {title: contents[index].title, message: contents[index].message};
  };

  const handleClose = () => {
    if (!loading) {
      onClose();
      setError(null);
    }
  };

  const handleSubmitAllSuggestions = async () => {
   // console.log("[handleSubmitAllSuggestions] AFTER REFORMAT", suggestionsReformat);

    const allSuggestions:any = [];
    suggestionsReformat.forEach((suggestion) => {
      allSuggestions.push({
        ...suggestion,
        contents: [
          {
            language_id: AutoMessageLanguageEnum.english,
            title: suggestion?.contents?.english.title,
            message: suggestion.contents.english.message
          },
          {
            language_id: AutoMessageLanguageEnum.french,
            title: suggestion.contents.french.title,
            message: suggestion.contents.french.message
          },
          {
            language_id: AutoMessageLanguageEnum.spanish,
            title: suggestion.contents.spanish.title,
            message: suggestion.contents.spanish.message
          },
          {
            language_id: AutoMessageLanguageEnum.german,
            title: suggestion.contents.german.title,
            message: suggestion.contents.german.message
          }
        ]
      });
    });

    setLoadingData(true);
    const res = await post(
        `${import.meta.env.VITE_API_URL}${paths.API.AUTO_MESSAGES_UPDATE_MULTIPLE}`,
        {
          notifications: allSuggestions,
        }
    );
    if (res?.data?.success){
      onSuccess();
      setError(null);
      setLoadingData(false);
      handleClose()

    }
    else setError(res?.response?.data?.message);
    setLoading(false);


  };

  useEffect(() => {
    handleDisplaySuggestions();
  }, []);

  const handleSelected = (index: number) => {
    setSelected(index);
    setSuggestion({
        ...suggestionsReformat[index],
    });

  }

  const handleNext = () => {
    setSelected(selected + 1);
    handleSelected(selected + 1);
  }
  const delays: DelayType = {
    'immediately': t('AutoMessageList.Suggestion.immediately'),
    'minus_1_day': t('AutoMessageList.Suggestion.minus_1_days'),
    'plus_2_hours': t('AutoMessageList.Suggestion.plus_2_hours'),
    'minus_12_hours': t('AutoMessageList.Suggestion.minus_12_hours'),
    'minus_2_hours': t('AutoMessageList.Suggestion.minus_2_hours'),
  };

  const icons:IconsType = {
    'immediately': suggestion?.delay === 'immediately' ?  <CalendarCheckActive /> : <CalendarCheck/>,
    'minus_1_day': suggestion?.delay === 'minus_1_day' ? <FileTextActiveIcon /> : <FileTextIcon/>,
    'minus_12_hours':suggestion?.delay === 'minus_12_hours' ? <LoginIconActive/> : <LoginIcon/>,
    'minus_2_hours': suggestion?.delay === 'minus_2_hours' ? <LoginIconActive/> : <LoginIcon/>,
    'plus_2_hours': suggestion?.delay === 'plus_2_hours' ? <StartActiveIcon/> : <StartIcon/>,
  }

  const handleSwitchChange = (index: number) => {
    const updatedSuggestions = [...suggestionsReformat];
    updatedSuggestions[index].enabled = !updatedSuggestions[index].enabled;
    setSuggestionsReformat(updatedSuggestions);
    setSuggestion(updatedSuggestions[selected]);
  }

  const getActiveMessageCount = () => {
    return suggestionsReformat.filter(suggestion => suggestion.enabled).length;
  }

  const updateSuggestionMessage = (selected: number , value: string) => {
    const updatedSuggestions = [...suggestionsReformat];

    updatedSuggestions[selected] = {
        ...suggestionsReformat[selected],
        contents: {
            ...suggestionsReformat[selected].contents,
            [lang]: {
              // @ts-ignore
              title: suggestionsReformat[selected]?.contents[lang]?.title,
              message: value
            }
        }
    };

    setSuggestionsReformat(updatedSuggestions);

    setSuggestion({
        ...suggestionsReformat[selected],
        contents: {
            ...suggestionsReformat[selected].contents,
            [lang]: {
              // @ts-ignore
              title: suggestionsReformat[selected].contents[lang].title,
              message: value
            }
        }
    });

    handleCheckMessageIsValid(updatedSuggestions);
  }

  const handleCheckMessageIsValid = (updatedSuggestions: any[]) => {
    const isAllValid = updatedSuggestions.every((suggestion) =>
        suggestion.contents['french'].message.trim() !== ""
    );
    if(isAllValid) setError(null);
    else setError(t("AutoMessageList.Suggestion.emptyMessage"));
    setIsValid(isAllValid);
  };


  return (
      <CenterModal
          isVisible={isVisible}
          onClose={handleClose}
          size="small"
          title={t("AutoMessageList.Suggestion.title")}
      >

        {
            loading && (
                <AutoMessageSuggestionModalSkeleton/>
            )
        }

        {
            !loading && (
                <div>
                  <p className="text-sm font-light text-low-contrast mb-4">
                    <Trans
                        i18nKey={
                          "AutoMessageList.Suggestion.description"
                        }
                        components={{
                          strong: (
                              <span className="font-bold text-low-contrast"></span>
                          ),
                        }}
                    />
                  </p>
                  <p className="text-sm font-light text-red-500 mb-4">
                    {t("AutoMessageList.Suggestion.tutorialTitle")}
                  </p>

                  <div className="flex flex-col border-2 border-gray-200 rounded-lg mb-4 bg-[#F8F8F8] gap-3">
                    <div className="flex flex-row p-2 gap-2 overflow-x-auto w-full">
                      {suggestionsReformat?.map((item: any, index: number) => (
                          <div className="flex flex-row gap-3 cursor-pointer" onClick={() => handleSelected(index)}
                               key={index}>
                            <div
                                className={`flex flex-col justify-center items-center bg-gray-100 rounded-lg gap-2 p-2 min-w-[200px] max-w-[200px] ${
                                    selected === index ? "border-red-500 border-1" : ""
                                }`}
                            >
                              {icons[item.delay] }
                              <p
                                  className={`text-sm text-low-contrast font-bold text-center min-w-[130px] max-w-[130px] ${
                                      selected === index && "text-red-500"
                                  }`}
                              >
                                {item.name}
                              </p>
                              <p
                                  className={`text-sm font-light text-low-contrast text-center ${
                                      selected === index && "text-red-500"
                                  }`}
                              >
                                {delays[item.delay]}
                              </p>
                            </div>
                            {index < suggestions.length - 1 && (
                                <div className="flex justify-center items-center">
                                  <ArrowRightIcon className="w-4 h-4 text-green-500"/>
                                </div>
                            )}
                          </div>
                      ))}
                    </div>


                    <div className="flex flex-col m-2">
                      <GroupButton
                          items={[
                            {
                              label: t("AutoMessageList.Upsert.Contents.french"),
                              value: "french",
                              Icon: FrenchIcon,
                              isActive: lang === "french",
                            },
                            {
                              label: t("AutoMessageList.Upsert.Contents.english"),
                              value: "english",
                              Icon: EnglishIcon,
                              isActive: lang === "english",
                            },
                            {
                              label: t("AutoMessageList.Upsert.Contents.spanish"),
                              value: "spanish",
                              Icon: SpanishIcon,
                              isActive: lang === "spanish",
                            },
                            {
                              label: t("AutoMessageList.Upsert.Contents.german"),
                              value: "german",
                              Icon: GermanIcon,
                              isActive: lang === "german",
                            },
                          ]}
                          onClick={(value)=>  setLang(value)}
                      />
                      <div className="flex flex-row justify-between">
                        <p className="font-bold m-2 text-sm"> {t("AutoMessageList.Suggestion.messageTitle")}</p>
                        <p
                            className="text-sm font-light text-low-contrast m-2 cursor-pointer"
                            onClick={() => window.open(paths.SHORTCODES, "_blank")}
                        >
                          {t("AutoMessageList.Suggestion.personalizedMessage")}
                        </p>

                      </div>
                      <TextAreaInput
                          label={""}
                          placeholder={t("AutoMessageList.Upsert.Contents.messagePlaceholder")}
                          required={true}
                          error={error}
                          value={suggestion?.contents?.[lang]?.message || ""}
                          onTextChange={(value) => updateSuggestionMessage(selected, value)}
                          onFocus={() => {
                          }}
                          onSelect={() => {
                          }}
                      />

                      <div className="mt-2">
                        <div className="flex flex-row justify-between">
                          <div
                              className={`flex flex-row gap-2 ${selected != 0 && selected != (suggestions.length - 1) && "w-[45%]"}`}>
                            {
                                selected != 0 && (
                                    <Button
                                        type="secondary"
                                        onClick={() => handleSelected(selected - 1)}
                                        LeftIcon={ArrowLeftIcon}
                                        disabled={false}
                                    >
                                      {t("AutoMessageList.Suggestion.previous")}
                                    </Button>
                                )
                            }
                            {
                                selected != suggestions.length - 1 && (
                                    <Button
                                        type="secondary"
                                        onClick={() => handleNext()}
                                        RightIcon={ArrowRightIcon}
                                        disabled={false}
                                    >
                                      {t("AutoMessageList.Suggestion.next")}
                                    </Button>
                                )
                            }
                          </div>
                          <div className="flex flex-row gap-2">
                            <p className="text-sm text-red-500 mt-1">{t("AutoMessageList.Suggestion.activateMessage")}</p>
                            <Switch value={suggestion?.enabled || false} onClick={() => handleSwitchChange(selected)}/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col gap-2 mb-4">
                    <p className="text-sm font-light text-low-contrast mb-2">
                      {t("AutoMessageList.Suggestion.messageInfo")}
                    </p>
                    <div className="flex gap-2 items-center">
                      <p className="text-high-contrast font-semibold text-sm">
                        {t("AutoMessageList.Upsert.Channels.airbnbBooking")}
                      </p>
                      <AirbnbIcon/>
                      <p className="text-low-contrast text-sm">
                        {t("AutoMessageList.Suggestion.sendBy")}
                      </p>
                      <p className="text-high-contrast text-sm">
                        {t("AutoMessageList.Upsert.Channels.byAirbnb")}
                      </p>
                    </div>
                    <div className="flex gap-2 items-center">
                      <p className="text-high-contrast font-semibold text-sm">
                        {t("AutoMessageList.Upsert.Channels.bookingBooking")}
                      </p>
                      <BookingIcon/>
                      <p className="text-low-contrast text-sm">
                        {t("AutoMessageList.Suggestion.sendBy")}
                      </p>
                      <p className="text-high-contrast text-sm">
                        {t("AutoMessageList.Upsert.Channels.byBooking")}
                      </p>
                    </div>
                    <div className="flex gap-2 items-center">
                      <p className="text-high-contrast font-semibold text-sm">
                        {t("AutoMessageList.Upsert.Channels.directBooking")}
                      </p>
                      <p className="text-low-contrast text-sm">
                        {t("AutoMessageList.Suggestion.sendBy")}
                      </p>
                      <p className="text-high-contrast text-sm">
                        {t("AutoMessageList.Upsert.Channels.email")}
                      </p>
                    </div>
                  </div>

                  <div className="flex gap-3 w-full">

                    <div className="flex flex-row ml-auto  gap-1">
                      <div>
                        <Button type="secondary" onClick={handleClose} disabled={loading} size="small">
                          {t("AutoMessageList.Suggestion.cancel")}
                        </Button>
                      </div>
                      <div>
                        <Button size="small" onClick={handleSubmitAllSuggestions} disabled={!isValid || loadingData}>
                          {t("AutoMessageList.Suggestion.validate", {count: getActiveMessageCount()})}
                        </Button>
                      </div>
                    </div>

                  </div>
                </div>
            )
        }
      </CenterModal>
  );
};
