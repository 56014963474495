import React, { useEffect, useState } from "react";
import useText from "../../../hooks/useText";

import PaperIcon from "../../../assets/icons/paper.svg?react";
import CloseCircle from "../../../assets/icons/close-circle.svg?react";
import ArrowRight from "../../../assets/icons/arrow-right.svg?react";

import { FlexItemCenter } from "../../Common/FlexComponents/FlexItemCenter";
import { FlexCol } from "../../Common/FlexComponents/FlexCol";
import { Badge } from "../../Common/Badge/Badge";
import {
  ContractListResponse,
  GuestPageLoginDetailsResponse,
  ReservationResponse,
} from "../../../types/GETTypes";
import { HelpText } from "../../Common/TextComponents/HelpText";
import { useReservationContract } from "../../../hooks/api/contract";
import { Flex, Skeleton } from "@mantine/core";
import { useGuestPage } from "../../../hooks/api/guestPage";

/**
 * CalendarContractBloc Component
 *
 * Displays contract and guest page information for a given reservation.
 * Handles contract signing status, guest page connection, and platform-specific conditions.
 *
 * @param {Object} props - Component properties
 * @param {ReservationResponse} props.reservation - Reservation details
 */
export default function CalendarContractBloc({
  reservation,
}: {
  reservation: ReservationResponse;
}) {
  const [contractAndDocs, setContractAndDocs] = useState<
    ContractListResponse | undefined
  >();
  const [guestPage, setGuestPage] = useState<
    GuestPageLoginDetailsResponse | undefined
  >();

  const { getText } = useText("Calendar.Contract");
  const { getContractsAndDocs } = useReservationContract();
  const { getPage } = useGuestPage();

  // Fetch contract and guest page data when component mounts
  useEffect(() => {
    getContractsAndDocs(String(reservation.id)).then(setContractAndDocs);
    getPage(reservation.guest_page_link).then(setGuestPage);
  }, []);

  /**
   * Guest Page Helper Functions
   */
  const guestPageIsRequested = () =>
    reservation.rental.guest_page.enabled ?? false;
  const guestPageIsSet = () => guestPage?.guest_page_connected ?? false;

  /**
   * Contract Helper Functions
   */
  const contractIsRequested = () => !!contractAndDocs;
  const contractIsSigned = () =>
    contractAndDocs?.contracts[0]?.is_signed ?? false;
  const getContractPdfUrl = () => contractAndDocs?.contracts[0]?.pdf_url ?? "";
  const reservationIsAirbnb = () => reservation.platform.id === 1; // Airbnb ID

  return (
    <div className="bg-element-background rounded-6px p-2 mt-4">
      {/* Section: Form and Contract Title */}
      <FlexItemCenter>
        <PaperIcon className="w-5 h-5 mr-1" />
        <p className="text-low-contrast font-light">
          {getText("formAndContract")}
        </p>
      </FlexItemCenter>

      {/* Section: Guest Page Status */}
      <Flex className="mt-4">
        <FlexCol className="w-full">
          <HelpText>{getText("formGuestPage")}</HelpText>
          {guestPageIsRequested() ? (
            guestPageIsSet() ? (
              <Badge label={getText("set")} status="success" />
            ) : (
              <Badge label={getText("notSet")} status="fail" />
            )
          ) : (
            <Badge label={getText("notRequested")} status="basic" />
          )}
        </FlexCol>

        {/* Section: Contract Status */}
        {contractAndDocs ? (
          <FlexCol className="w-full">
            <HelpText>{getText("contract")}</HelpText>
            {reservationIsAirbnb() ? (
              <FlexCol>
                <Badge
                  Icon={CloseCircle}
                  label={getText("notApply")}
                  status="basic"
                />
                <HelpText>{getText("anyContractAirbnbText")}</HelpText>
              </FlexCol>
            ) : !contractIsRequested() ? (
              <Badge label={getText("notRequested")} status="basic" />
            ) : (
              <FlexItemCenter gap={5}>
                <Badge
                  label={
                    contractIsSigned()
                      ? getText("signed")
                      : getText("notSigned")
                  }
                  status={contractIsSigned() ? "success" : "fail"}
                />
                <a
                  target="_blank"
                  href={getContractPdfUrl()}
                  className="hover:shadow transition font-bold flex items-center gap-2 p-1.5 border border-gray-300 rounded bg-white"
                >
                  {getText("see")}
                  <ArrowRight className="w-4" />
                </a>
              </FlexItemCenter>
            )}
          </FlexCol>
        ) : (
          <Skeleton className="w-full h-10 mt-2" />
        )}
      </Flex>
    </div>
  );
}
