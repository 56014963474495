import React from "react";


export interface BasicItemPolicyProps {
    title: string;
    text: string;
    subTitle?: string;
    className?: string;
    titleClassName?: string;
    children?: React.ReactNode;
}

export default function BasicItemPolicy ({title, text, subTitle, className, titleClassName, children} : BasicItemPolicyProps)
{
    return (
        <>
            <div className={"rounded bg-gray-200/50 p-2 " + className}>
                {subTitle ? (
                    <>
                        <h1 className={"text-gray-500"}>{title}</h1>
                        <p className={"font-bold"}>{subTitle}</p>
                        <p className={"text-gray-500"}>{text}</p>
                    </>
                ) : (
                    <>
                        <h1 className={"text-gray-500 " + titleClassName}>{title}</h1>
                        <p className={"font-bold"}>{text}</p>
                    </>
                )}
                {children && (
                    <div className={"mt-2"}>
                        {children}
                    </div>
                )}
            </div>
        </>
    );
}