import { UseModal } from "../../hooks/useModal";
import { ValueType } from "../../types/commonTypes";
import { TaskListByDateResponse } from "../../types/GETTypes";

export enum TasksFilterStatusEnum {
  all = "ALL",
  "notAssigned" = "NOT_ASSIGNED",
  "scheduled" = "ASSIGNED",
  "pending" = "PENDING",
  "completed" = "COMPLETED",
  "refused" = "DECLINED",
  "canceled" = "CANCELED",
}

export type TasksFilterStatusMeta = {
  all: undefined | string;
  notAssigned: undefined | string;
  scheduled: undefined | string;
  pending: undefined | string;
  completed: undefined | string;
  canceled: undefined | string;
  refused: undefined | string;
};

export type TaskListModalFilters = {
  operatorTeamIds?: ValueType[];
  operatorIds?: ValueType[];
  positions?: ManagementTaskListFilterPositions;
  selectedRentalIds?: Set<ValueType>;
};

// * FILTER MODAL
export type ManagementTaskListFilterModalProps = {
  modal: UseModal<ManagementTaskListFilterForm>;
  listFilters: {
    status?: TasksFilterStatusEnum;
    from?: moment.Moment;
    to?: moment.Moment;
  };
  onTaskListFiltered: (
    tasks: TaskListByDateResponse | undefined,
    listFiltersModal: TaskListModalFilters | undefined,
  ) => void;
  onError: (message: string | undefined) => void;
};

export type ManagementTaskListFilterPositions = {
  manager: ValueType | undefined;
  house_cleaner: ValueType | undefined;
  house_owner: ValueType | undefined;
};

export type ManagementTaskListFilterForm = {
  assignedPersons: ValueType[];
  positions: any;
  teams: ValueType[];
};
