import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconType } from "../../../types/commonTypes";
import { useLocation } from "react-router-dom";
import { cn } from "../../../helpers/classHelper";

export const PageMenu: React.FC<{
  items: {
    anchor: string;
    labelI18n: string;
    paramsi18?: any;
    Icon: IconType;
    IconActive: IconType;
  }[];
  anchor?: string | null;
  setAnchor?: Dispatch<SetStateAction<string>>;
}> = ({ items, anchor = null, setAnchor }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [currentHash, setCurrentHash] = useState(location.hash);

  // Met à jour l'état lorsque l'URL change
  useEffect(() => {
    setCurrentHash(location.hash);
  }, [location.hash]);

  return (
    <div className="flex flex-col gap-4">
      {items?.map((item, index) => {
        const paramsi18 = item?.paramsi18 ? { ...item.paramsi18 } : {};

        const isActive = () => {
          if (anchor && anchor !== "") {
            return anchor === item.anchor;
          }
          if (currentHash === `#${item.anchor}`) return true; // Si l'URL contient l'ancre
          return currentHash === "" && index === 0;
        };

        return (
          <div key={item.anchor} className="flex items-start gap-2">
            {isActive() ? (
              <item.IconActive className="w-4 h-4 shrink-0 mt-0.5" />
            ) : (
              <item.Icon className="w-4 h-4 shrink-0 mt-0.5" />
            )}

            <p
              className={cn(
                isActive()
                  ? "font-semibold text-high-contrast"
                  : "font-light text-low-contrast",
              )}
            >
              <a
                href={`#${item.anchor}`}
                onClick={() => {
                  if (typeof setAnchor === "function") {
                    // Code temporaire (jusqu'à ce qu'il devienne définitif) x)
                    setTimeout(() => {
                      setAnchor("");
                    }, 50);
                  }
                }}
              >
                {paramsi18
                  ? (t(item.labelI18n, paramsi18) as string)
                  : (t(item.labelI18n) as string)}
              </a>
            </p>
          </div>
        );
      })}
    </div>
  );
};
