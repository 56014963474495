import React from "react";
import UsersIcon from "../../../../../../assets/icons/users.svg?react";
import BedRoomIcon from "../../../../../../assets/icons/single-bed.svg?react";
import BathroomIcon from "../../../../../../assets/icons/bath.svg?react";
import useGuestWebsiteStore from "../../../../../../stores/guestWebsiteStore/useGuestWebsiteStore";
import {useTranslation} from "react-i18next";

const ReservationDetailsComponent = () => {
  const {t} = useTranslation()
  const {rentalData,dataGuestWebsite} = useGuestWebsiteStore();
  const {guests_max_capacity,bedrooms_count,bathrooms_count} = rentalData || {}
  const {styles} = dataGuestWebsite || {};
  const {color_text} = styles || {};
  return (
    <div className="flex flex-wrap gap-4 p-4 pt-0 pl-2 rounded-lg">
      {/* Travelers */}
      <div className="flex items-center gap-2 bg-gray-100 px-4 py-2 rounded-full">
        <UsersIcon className="text-lg" />
        <span className="text-sm font-medium" style={{color:color_text}}>{guests_max_capacity} {t('GuestWebsite.Rentals.guests')}</span>
      </div>

      {/* Bedrooms */}
      <div className="flex items-center gap-2 bg-gray-100 px-4 py-2 rounded-full">
        <BedRoomIcon className="text-lg" />
        <span className="text-sm font-medium" style={{color:color_text}}>{bedrooms_count} {t('GuestWebsite.Rentals.rooms')}</span>
      </div>

      {/* Bathrooms */}
      <div className="flex items-center gap-2 bg-gray-100 px-4 py-2 rounded-full">
        <BathroomIcon className="text-lg" />
        <span className="text-sm font-medium" style={{color:color_text}}>{bathrooms_count} {t('GuestWebsite.Rentals.bathRooms')}</span>
      </div>
    </div>
  );
};

export default ReservationDetailsComponent;
