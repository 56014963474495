import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ValueType} from "../../types/commonTypes";
import ChevronRightIcon from "../../assets/icons/chevron-right.svg?react";
import {MainLayout} from "../../components/Layout/MainLayout/MainLayout";
import {debounce} from "lodash";
import {DateRangePreset} from "../../components/Common/DateFilter/DateFilter.type";
import {
  CalendarInputValue,
  CalendarInputValueRange,
} from "../../components/Common/CalendarInput/CalendarInput.type";
import {CalendarDay} from "../CalendarList/CalendarListPage.type";
import Header from "../../components/Analytics/Header";
import GeneralComponent from "../../components/Analytics/General/GeneralComponent";
import {useModal} from "../../hooks/useModal";
import CalculateTaxModal from "../../components/Analytics/CalculateTaxModal";
import paths from "../../constants/paths";
import {get} from "../../helpers/APIHelper";
import moment from "moment";
import {AnalyticsGeneralResponse} from "../../types/GETTypes";

const AnalyticsPage = () => {
  const {t} = useTranslation();
  const modal = useModal();
  const modalExport = useModal();

  const [selectedRentalsIds, setSelectedRentalsIds] = useState<ValueType[]>([]);
  const [currentFrom, setCurrentFrom] = useState(moment().subtract(12, "months").format("DD-MM-YYYY"));
  const [currentTo, setCurrentTo] = useState(moment().format("DD-MM-YYYY"));
  const [loading, setLoading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [stats, setStats] = useState<AnalyticsGeneralResponse>();

  const onChangeDates = (dates: CalendarInputValueRange) => {
    if (dates && dates?.length > 0) {
      if (dates[0] && dates[1]) {
        const from = moment(dates[0]).format("DD-MM-YYYY");
        const to = moment(dates[1]).format("DD-MM-YYYY");
        setCurrentFrom(from);
        setCurrentTo(to);
        fetchStatsGeneral({from, to});
        setRefresh(!refresh);
      }
    }
  };

  const debouncedFilterRentals = React.useRef(
    debounce(async (ids: ValueType[]) => {
      await fetchStatsGeneral(
        {
          from: currentFrom,
          to: currentTo,
          rental_ids: ids,
        },
        true
      );

      setLoadingSearch(false);
    }, 300)
  ).current;

  const fetchStatsGeneral = async (
    {
      from,
      to,
      rental_ids,
    }: {
      from?: string;
      to?: string;
      rental_ids?: ValueType[];
    },
    refresh: boolean = false
  ) => {
    setLoading(true);

    const params = {
      from: from ?? currentFrom,
      to: to ?? currentTo,
      rental_ids: rental_ids?.join(",") ?? selectedRentalsIds?.join(",") ?? "",
    };

    const response = await get(
      `${import.meta.env.VITE_API_URL}/${paths.API.ANALYTICS.GENERAL}`,
      {params}
    );

    if (response.data?.success) {
      setStats(response.data?.result);
      refresh && setRefresh(!refresh);
    }

    setTimeout(() => {
      setLoading(false);
    }, 200);
  };

  const handleFilterRentals = async (ids: ValueType[]) => {
    setLoadingSearch(true);

    debouncedFilterRentals(ids);

    setSelectedRentalsIds(ids);
  };

  useEffect(() => {
    fetchStatsGeneral({
      from: currentFrom,
      to: currentTo,
    });
  }, []);

  const getTitle = () => {
    return (
      <p className="flex flex-row items-center gap-1">
        {t("Analytics.title")} <ChevronRightIcon />
        {t("Analytics.general")}
      </p>
    );
  };

  const isLoad = loading || loadingSearch;

  return (
    <>
      <MainLayout
        title={getTitle()}
        sidebarActiveItem="analytics"
        sidebarActiveSubItem="general"
      >
        {/* <CalculateTaxModal modal={modal} /> */}

        <Header
          page="general"
          handleFilterRentals={handleFilterRentals}
          onChangeDates={onChangeDates}
          setLoading={setLoading}
          selectedRentals={selectedRentalsIds}
          modalExport={modalExport}
          handleModalTax={modal.open}
        />
        <GeneralComponent stats={stats} loading={isLoad} refresh={refresh}/>
      </MainLayout>
    </>
  );
};

export default AnalyticsPage;
