import React from "react";
import {CenterModal} from "../../Common/CenterModal/CenterModal";
import {Badge} from "../../Common/Badge/Badge";
import {useTranslation} from "react-i18next";
import {Button} from "../../Common/Button/Button";
import ArrowRightWhiteIcon from "../../../assets/icons/arrow-right-white.svg?react";
import DownloadIcon from "../../../assets/icons/download.svg?react";

const GuestPageContractModalSuccess = ({
  isVisible,
  onAction,
  onDownload,
  fromUrl,
  loading
}: {
  isVisible: boolean;
  onAction: () => Promise<void>;
  onDownload: () => void;
  fromUrl: boolean;
  loading: boolean;
}) => {
  const {t} = useTranslation();
  return (
    <CenterModal
      isVisible={isVisible}
      onClose={() => undefined}
      CustomHeader={
        <div className="relative flex items-center justify-center flex-1 h-32 bg-element-background-success rounded-t-16px">
          <div className="absolute cursor-pointer">
            <Badge
              status={"success"}
              size="large"
              customBg="bg-transparent"
              iconSize={50}
            />
          </div>
        </div>
      }
      classNames={{
        divContent: "space-y-12",
      }}
    >
      <div className="flex flex-col space-y-6">
        <span className="font-bold text-high-contrast text-xl">
          {t("GuestPage.Contract.modalTitle")}
        </span>

        <span className="text-low-contrast text-xs">
          {t("GuestPage.Contract.modalInfo")}
        </span>
        <span className=" text-low-contrast text-xs">
          {t("GuestPage.Contract.modalInfo2")}
        </span>
      </div>

      <div className="flex flex-col gap-2 pt-4">
        <Button onClick={onDownload} RightIcon={DownloadIcon} type="secondary">
          {t("GuestPage.Contract.downloadContractSigned")}
        </Button>
        {!fromUrl && (
          <Button onClick={onAction} RightIcon={ArrowRightWhiteIcon} loading={loading}>
            {t("GuestPage.Contract.viewGuestPage")}
          </Button>
        )}
      </div>
    </CenterModal>
  );
};

export default GuestPageContractModalSuccess;
