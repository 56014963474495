import { t } from "i18next";
import React, { useEffect } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import AirbnbIcon from "../../assets/icons/airbnb.svg?react";
import BookingIcon from "../../assets/icons/booking.svg?react";
import { Separator } from "../Common/Separator/Separator";
import { SimpleRadio } from "../Common/SimpleRadio/SimpleRadio";
import { Switch } from "../Common/Switch/Switch";
import { AutoMessageUpsertChannelsForm } from "./AutoMessage.type";

export const AutoMessageUpsertChannels: React.FC<{
  form: UseFormReturn<AutoMessageUpsertChannelsForm>;
  channels: AutoMessageUpsertChannelsForm | undefined;
}> = ({ form, channels }) => {
  useEffect(() => {
    if (channels) {
      form.setValue("Airbnb", channels.Airbnb);
      form.setValue("Booking", channels.Booking);
      form.setValue("Direct", channels.Direct);
    }
  }, [channels]);

  return (
    <div className="flex flex-col gap-0">
      <p className="font-light text-low-contrast">
        {t("AutoMessageList.Upsert.Channels.infos")}
      </p>

      {/* Airbnb */}
      <div className="flex flex-col gap-2 mt-3">
        <Controller
          control={form.control}
          name="Airbnb.active"
          render={({ field: { value, onChange } }) => (
            <>
              <div
                className="flex justify-between cursor-pointer"
                onClick={() => onChange(!value)}
              >
                <div className="flex items-center gap-2">
                  <p className="font-semibold text-high-contrast">
                    {t("AutoMessageList.Upsert.Channels.airbnbBooking")}
                  </p>

                  <AirbnbIcon />
                </div>

                <Switch value={value} />
              </div>

              {value === true && (
                <div className="flex flex-col gap-2">
                  <p className="font-light text-low-contrast">
                    {t("AutoMessageList.Upsert.Channels.sendBy")}
                  </p>

                  <Controller
                    control={form.control}
                    name="Airbnb.value"
                    render={({ field: { onChange, value } }) => (
                      <div className="grid grid-cols-2 gap-2">
                        {/* <SimpleRadio
                            label={t("Global.email")}
                            value={value === "email"}
                            onClick={() => onChange("email")}
                          /> */}

                        <SimpleRadio
                          label={t(
                            "AutoMessageList.Upsert.Channels.byPlatform"
                          )}
                          value={value === "platform"}
                          disabled={false}
                          onClick={() => onChange("platform")}
                        />

                        <div className="flex items-center gap-2">
                          <SimpleRadio
                            label={t("Global.SMS")}
                            value={value === "SMS"}
                            disabled={true}
                            onClick={() => onChange("SMS")}
                          />
                          {/* <div className="p-1 rounded-6px font-semibold bg-element-background-warning text-active text-[11px] cursor-not-allowed">
                              {t(
                                "AutoMessageList.Upsert.Channels.optionToActive"
                              )}
                            </div> */}
                        </div>
                      </div>
                    )}
                  />
                </div>
              )}
            </>
          )}
        />
      </div>

      <Separator />

      {/* Booking.com */}
      <div className="flex flex-col gap-2">
        <Controller
          control={form.control}
          name="Booking.active"
          render={({ field: { value, onChange } }) => (
            <>
              <div
                className="flex justify-between cursor-pointer"
                onClick={() => onChange(!value)}
              >
                <div className="flex items-center gap-2">
                  <p className="font-semibold text-high-contrast">
                    {t("AutoMessageList.Upsert.Channels.bookingBooking")}
                  </p>

                  <BookingIcon />
                </div>

                <Switch value={value} />
              </div>

              {value === true && (
                <div className="flex flex-col gap-2">
                  <p className="font-light text-low-contrast">
                    {t("AutoMessageList.Upsert.Channels.sendBy")}
                  </p>

                  <Controller
                    control={form.control}
                    name="Booking.value"
                    render={({ field: { onChange, value } }) => (
                      <div className="grid grid-cols-2 gap-2">
                        <SimpleRadio
                          label={t(
                            "AutoMessageList.Upsert.Channels.byPlatform"
                          )}
                          value={value === "platform"}
                          disabled={false}
                          onClick={() => onChange("platform")}
                        />

                        <div className="flex items-center gap-2">
                          <SimpleRadio
                            label={t("Global.SMS")}
                            value={value === "SMS"}
                            disabled={true}
                            onClick={() => onChange("SMS")}
                          />
                          {/* <div className="p-1 rounded-6px font-semibold bg-element-background-warning text-active text-[11px] cursor-not-allowed">
                              {t(
                                "AutoMessageList.Upsert.Channels.optionToActive"
                              )}
                            </div> */}
                        </div>

                        <SimpleRadio
                          label={t("Global.email")}
                          value={value === "email"}
                          disabled={true}
                          onClick={() => onChange("email")}
                        />
                      </div>
                    )}
                  />
                </div>
              )}
            </>
          )}
        />
      </div>

      <Separator />

      {/* Direct */}
      <div className="flex flex-col gap-2">
        <Controller
          control={form.control}
          name="Direct.active"
          render={({ field: { value, onChange } }) => (
            <>
              <div
                className="flex justify-between cursor-pointer"
                onClick={() => onChange(!value)}
              >
                <div className="flex items-center gap-2">
                  <p className="font-semibold text-high-contrast">
                    {t("AutoMessageList.Upsert.Channels.directBooking")}
                  </p>
                </div>

                <Switch value={value} />
              </div>

              {value === true && (
                <div className="flex flex-col gap-2">
                  <p className="font-light text-low-contrast">
                    {t("AutoMessageList.Upsert.Channels.sendBy")}
                  </p>

                  <Controller
                    control={form.control}
                    name="Direct.value"
                    render={({ field: { onChange, value } }) => (
                      <div className="grid grid-cols-2 gap-2">
                        <SimpleRadio
                          label={t("Global.email")}
                          value={value === "email"}
                          onClick={() => onChange("email")}
                        />

                        <div className="flex items-center gap-2">
                          <SimpleRadio
                            label={t("Global.SMS")}
                            value={value === "SMS"}
                            disabled={true}
                            onClick={() => onChange("SMS")}
                          />
                          {/* <div className="p-1 rounded-6px font-semibold bg-element-background-warning text-active text-[11px] cursor-not-allowed">
                              {t(
                                "AutoMessageList.Upsert.Channels.optionToActive"
                              )}
                            </div> */}
                        </div>
                      </div>
                    )}
                  />
                </div>
              )}
            </>
          )}
        />
      </div>
    </div>
  );
};
