import React from "react";

import { WebsiteSettingsMenu } from "./WebsiteSettingsMenu";

import { WebsiteResponse } from "../../types/GETTypes";
import { WebsiteURLCard } from "./Cards/WebsiteURLCard";
import { WebsiteLegalCard } from "./Cards/WebsiteLegalCard";
import { WebsiteIntegrateCard } from "./Cards/WebsiteIntegrateCard";

export const WebsiteSettingsCards: React.FC<{
  website: WebsiteResponse | undefined;
  onUpdateWebsite: (nextWebsite: WebsiteResponse) => void;
}> = ({ website, onUpdateWebsite }) => {
  return (
    <div className="grid grid-cols-12 h-screen">
      {/* Menu Sticky */}
      <div className="col-span-2 pr-2 sticky top-16 self-start h-fit">
        <WebsiteSettingsMenu />
      </div>

      <div className="col-span-8 flex flex-col gap-3 overflow-y-auto h-[calc(100vh-170px)] no-scrollbar">
        <WebsiteURLCard website={website} onUpdateWebsite={onUpdateWebsite} />

        <WebsiteLegalCard website={website} onUpdateWebsite={onUpdateWebsite} />

        <WebsiteIntegrateCard website={website} />
      </div>
    </div>
  );
};
