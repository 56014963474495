import { useEffect, useState } from "react";

/**
 * Custom hook to track the most visible section inside a scrollable container.
 * It updates the URL anchor (#id) based on the currently visible section.
 * (algo de fou pour juste faire fonctionner les anchor les amis xD)
 *
 * @param sections - An array of section IDs to track.
 * @param containerId - The ID of the scrollable container.
 * @returns The active section ID and a setter function.
 */
const useActiveSectionAnchor = (sections: string[], containerId: string) => {
  // State to store the currently active section
  const [activeSection, setActiveSection] = useState<string>("");

  const reset = (): void => {
    setActiveSection(sections[0]);
  };

  useEffect(() => {
    // Get the scrollable container element
    const container = document.getElementById(containerId);
    if (!container) return; // Exit if the container is not found

    let isScrolling = false; // Flag to check if scrolling is in progress
    let scrollTimeout: NodeJS.Timeout; // Timer for debouncing

    /**
     * Intersection Observer to track visibility of sections inside the container.
     * It updates `activeSection` with the most visible section.
     */
    const observer = new IntersectionObserver(
      (entries) => {
        if (!isScrolling) return; // Only detect sections during scrolling

        let mostVisibleSection: string | null = null;
        let maxVisibility = 0; // Stores the highest intersection ratio found

        entries.forEach((entry) => {
          // Check if the section is intersecting and has the highest visibility so far
          if (entry.isIntersecting && entry.intersectionRatio > maxVisibility) {
            mostVisibleSection = entry.target.id;
            maxVisibility = entry.intersectionRatio;
          }
        });

        // Update state and URL if a new most visible section is found
        if (mostVisibleSection) {
          setActiveSection(mostVisibleSection);
          window.history.replaceState(null, "", `#${mostVisibleSection}`); // Update URL without reloading
        }
      },
      {
        root: container, // Observe only within the given scroll container
        rootMargin: "0px 0px -60% 0px", // Slight offset to trigger earlier
        threshold: Array.from({ length: 11 }, (_, i) => i * 0.1), // Detect multiple visibility levels
      },
    );

    /**
     * Scroll event listener to enable section detection only when scrolling.
     * Uses a debounce mechanism to prevent excessive updates.
     */
    const handleScroll = () => {
      isScrolling = true;
      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(() => (isScrolling = false), 100); // Reset flag after 100ms
    };

    // Attach the scroll event listener
    container.addEventListener("scroll", handleScroll);

    // Get all section elements and start observing them
    const elements = sections
      .map((id) => document.getElementById(id))
      .filter((el): el is HTMLElement => el !== null);

    elements.forEach((el) => observer.observe(el));

    // Cleanup function: remove event listeners and disconnect the observer
    return () => {
      elements.forEach((el) => observer.unobserve(el));
      container.removeEventListener("scroll", handleScroll);
      clearTimeout(scrollTimeout);
    };
  }, [sections]); // Re-run effect if sections or container ID changes

  return { activeSection, setActiveSection, reset }; // Return the active section and its setter
};

export default useActiveSectionAnchor;
