import React, {useEffect, useState} from "react";
import {RightModal} from "../../../Common/RightModal/RightModal";
import {useTranslation} from "react-i18next";
import {GroupButton} from "../../../Common/GroupButton/GroupButton";
import {ValueType} from "../../../../types/commonTypes";
import FrenchIcon from "../../../../assets/flags/France.svg?react";
import EnglishIcon from "../../../../assets/flags/USA.svg?react";
import SpanishIcon from "../../../../assets/flags/Spain.svg?react";
import GermanIcon from "../../../../assets/flags/Germany.svg?react";
import CheckIcon from "../../../../assets/icons/check-white.svg?react";
import CloseIcon from "../../../../assets/icons/close.svg?react";
import {Switch} from "../../../Common/Switch/Switch";
import {Controller, useForm} from "react-hook-form";
import {Button} from "../../../Common/Button/Button";
import {PictureInput} from "../../../Common/PictureInput/PictureInput";
import {CenterModal} from "../../../Common/CenterModal/CenterModal";
import {TextInput} from "../../../Common/TextInput/TextInput";
import {NumberInput} from "../../../Common/NumberInput/NumberInput";
import {InputSelect} from "../../../Common/InputSelect/InputSelect";
import {PhoneInput} from "../../../Common/PhoneInput/PhoneInput";
import {CheckBox} from "../../../Common/CheckBox/CheckBox";
import {post, update} from "../../../../helpers/APIHelper";
import paths from "../../../../constants/paths";
import PlusIcon from "../../../../assets/icons/plus.svg?react";
import DraftIcon from "../../../../assets/icons/draft.svg?react";
import {ErrorMessage} from "../../../Common/ErrorMessage/ErrorMessage";
import {useCheckAuth} from "../../../../hooks/useCheckAuth";

export const AddExtraModal: React.FC<any> = ({
  isVisible,
  onClose,
  onFinish,
  titleModal,
  rentalId,
  setListExtras,
  setIsEditModalVisible,
  selectedExtra,
  rental,
}) => {
  const {user} = useCheckAuth();
  const {t} = useTranslation();
  const [currentLang, setCurrentLang] = useState<ValueType>("french");
  const [disabled, setDisabled] = useState<boolean>(false);
  const [displayExtra, setDisplayExtra] = useState<boolean>(false);
  const handleChangeLang = (nextLang: ValueType) => {
    setCurrentLang(nextLang);
  };
  const [validationLoading, setValidationLoading] = useState<boolean>(false);
  const [requiredValidation, setRequiredValidation] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [currentFile, setCurrentFile] = useState<any>(null);
  const [delete_files, setDeleteFiles] = useState<any>(null);
  const [isDeletePictureVisible, setIsDeletePictureVisible] =
    useState<boolean>(false);
  const [currentPath, setCurrentPath] = useState<string | undefined | null>(
    null
  );
  const [file, setFile] = useState<Blob | string | null>(null);
  const [errorPhoto, setErrorPhoto] = useState<string | undefined>();
  const maxInputs = 3;
  const [marge, setMarge] = useState(
    Number(selectedExtra?.unit_price) - Number(selectedExtra?.cost_price)
  );
  const [phoneInputs, setPhoneInputs] = useState([""]);
  const [emailInputs, setEmailInputs] = useState([""]);

  /**
   * State for check if user has update cost_unit and cost_price
   */
  const [priceUpdate, setPriceUpdate] = useState({
    costPrice: false,
    costUnit: false,
  });

  type Localization = {
    fr: string;
    en: string;
    de: string;
    es: string;
  };

  type FormSchema = {
    name: Localization;
    description: Localization;
    cost_price: number;
    unit_price: number;
    maximum_quantity: number;
    available_until: number;
    confirmation_enabled: number;
  };

  const form = useForm<FormSchema>({
    defaultValues: {
      name: {
        fr: "",
        en: "",
        de: "",
        es: "",
      },
      description: {
        fr: "",
        en: "",
        de: "",
        es: "",
      },
      cost_price: 0,
      unit_price: 0,
      maximum_quantity: 0,
      available_until: 0,
      confirmation_enabled: 0,
    },
  });

  const listMaximun = [
    {value: -1, label: t("Global.unlimited")},
    {value: 0, label: 0},
    {value: 1, label: 1},
    {value: 2, label: 2},
    {value: 3, label: 3},
    {value: 4, label: 4},
    {value: 5, label: 5},
    {value: 6, label: 6},
    {value: 7, label: 7},
    {value: 8, label: 8},
    {value: 9, label: 9},
    {value: 10, label: 10},
  ];

  const listAvailableUntil = [
    {
      value: "CHECKOUT",
      label: t(
        "Rental.Infos.TravelerPage.ExtraCard.immediatelyAfterReservation"
      ),
    },
    {
      value: "CHECKOUT-1",
      label: t("Rental.Infos.TravelerPage.ExtraCard.immediatelyAfterCheckin"),
    },
    {
      value: "CHECKOUT-2",
      label: t("Rental.Infos.TravelerPage.ExtraCard.onHourLaterCheckin"),
    },
    {
      value: "CHECKOUT-3",
      label: t("Rental.Infos.TravelerPage.ExtraCard.fourHourLaterCheckin"),
    },
    {
      value: "CHECKOUT-4",
      label: t("Rental.Infos.TravelerPage.ExtraCard.onDayAfterCheckin"),
    },
    {
      value: "CHECKOUT-5",
      label: t("Rental.Infos.TravelerPage.ExtraCard.fourHourAfterCheckout"),
    },
    {
      value: "CHECKOUT-6",
      label: t("Rental.Infos.TravelerPage.ExtraCard.untilCheckout"),
    },
  ];

  const handleAddExtra = async (value: any) => {
    if (value.name?.fr?.message === "") {
      form.setError("name.fr", {
        message: t("AddReservation.Guest.lastNameRequired"),
      });
      return;
    }
    let hasError = false;

    /**
     * Check if unit price and cost price has updated by user
     */
    if (!priceUpdate.costPrice) {
      form.setError("cost_price", {
        message: t("AddReservation.Guest.costUnitRequired"),
      });
      hasError = true;
    }

    if (!priceUpdate.costUnit) {
      form.setError("unit_price", {
        message: t("AddReservation.Guest.costPriceRequired"),
      });
      hasError = true;
    }

    if (hasError) {
      return;
    }

    setPriceUpdate({
      costUnit: false,
      costPrice: false,
    });

    setDisabled(true);
    initSubmit();
    if (!selectedExtra.id) {
      const response = await post(
        `${import.meta.env.VITE_API_URL}${
          paths.API.RENTALS_UPDATE
        }/${rentalId}${paths.API.GUEST_PAGE_EXTRAS}`,
        {
          ...value,
          name: JSON.stringify({
            fr: value.name.fr.message,
            en: value.name.en.message,
            de: value.name.de.message,
            es: value.name.es.message,
          }),
          description: JSON.stringify({
            fr: value.description.fr.message,
            en: value.description.en.message,
            de: value.description.de.message,
            es: value.description.es.message,
          }),
          extra_enabled: displayExtra ? 1 : 0,
          available_until: value.available_until.toString(),
          confirmation_enabled: requiredValidation ? 1 : 0,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data?.success) {
        handleAddPhoto(response.data.result.id).then((responsePhoto) => {
          setValidationLoading(false);
          setIsEditModalVisible(false);
          setDisabled(false);
          setListExtras(responsePhoto.result.guest_page.extras_available);
        });
      } else {
        setError(response.data?.message);
        setDisabled(false);
      }
    } else {
      const response = await update(
        `${import.meta.env.VITE_API_URL}${
          paths.API.RENTALS_UPDATE
        }/${rentalId}${paths.API.GUEST_PAGE_EXTRAS}/${selectedExtra.id}`,
        {
          ...value,
          name: JSON.stringify({
            fr: value.name.fr.message,
            en: value.name.en.message,
            de: value.name.de.message,
            es: value.name.es.message,
          }),
          description: JSON.stringify({
            fr: value.description.fr.message,
            en: value.description.en.message,
            de: value.description.de.message,
            es: value.description.es.message,
          }),
          extra_enabled: displayExtra ? 1 : 0,
          available_until: value.available_until.toString(),
          confirmation_enabled: requiredValidation ? 1 : 0,
        }
      );
      if (response.data?.success) {
        handleAddPhoto(response.data.result.id).then((responsePhoto) => {
          setValidationLoading(false);
          setIsEditModalVisible(false);
          setDisabled(false);
          setListExtras(responsePhoto.result.guest_page.extras_available);
        });
      } else {
        setError(response.data?.message);
        setDisabled(false);
      }
    }
  };

  const UpdatePicture = (file: Blob | string) => {
    setCurrentFile(file as any);
    setCurrentPath(URL.createObjectURL(file[0]));
    setFile(file[0] as Blob);
  };

  const handleAddPhoto = async (extra_id: number) => {
    const formData = new FormData();
    file instanceof Blob && formData.append("photo", file);
    const del_photo_ids = delete_files != null ? delete_files.toString() : "";
    formData.append("del_photo_ids", del_photo_ids);

    const response = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS_UPDATE}/${rentalId}${
        paths.API.GUEST_PAGE_EXTRAS
      }/${extra_id}/photo`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  };

  const initSubmit = () => {
    setError("");
    setValidationLoading(true);
  };
  const handleDisplayExtra = () => {
    setDisplayExtra(!displayExtra);
  };

  const handleRequiredValidation = () => {
    setRequiredValidation(!requiredValidation);
  };

  useEffect(() => {
    form.setValue("name.fr", {message: selectedExtra?.name?.fr});
    form.setValue("name.en", {message: selectedExtra?.name?.en});
    form.setValue("name.de", {message: selectedExtra?.name?.de});
    form.setValue("name.es", {message: selectedExtra?.name?.es});
    form.setValue("description.fr", {
      message: selectedExtra?.description?.fr,
    });
    form.setValue("description.en", {
      message: selectedExtra?.description?.en,
    });
    form.setValue("description.de", {
      message: selectedExtra?.description?.de,
    });
    form.setValue("description.es", {
      message: selectedExtra?.description?.es,
    });
    form.setValue("cost_price", selectedExtra?.cost_price);
    form.setValue("unit_price", selectedExtra?.unit_price);
    if (selectedExtra) {
      setPriceUpdate({costPrice: true, costUnit: true});
    }
    form.setValue("maximum_quantity", selectedExtra?.maximum_quantity);
    form.setValue("available_until", selectedExtra?.available_until);
    form.setValue("confirmation_enabled", selectedExtra?.confirmation_enabled);
    setCurrentPath(
      selectedExtra?.photo.length > 0
        ? selectedExtra?.photo[0].original_url
        : null
    );
    setCurrentFile(null);
    setFile(
      selectedExtra?.photo.length > 0 ? (selectedExtra?.photo[0] as Blob) : null
    );
    setValidationLoading(false);
    setDisabled(false);
    setDisplayExtra(selectedExtra?.extra_enabled === 1 ? true : false);
    setRequiredValidation(
      selectedExtra?.confirmation_enabled === 1 ? true : false
    );
    setDeleteFiles(null);
    calcMarge();
  }, [isVisible, user, selectedExtra]);

  const handleAddContact = () => {
    if (maxInputs > phoneInputs.length) {
      setPhoneInputs([...phoneInputs, ""]);
    }
  };

  const handleAddEmail = () => {
    if (maxInputs > emailInputs.length) {
      setEmailInputs([...emailInputs, ""]);
    }
  };

  const handleChangeListEmail = (index: number, newValue: string) => {
    setEmailInputs(
      emailInputs.map((value, i) => (i === index ? newValue : value))
    );
  };
  const handleChange = (index: number, newValue: string) => {
    setPhoneInputs(
      phoneInputs.map((value, i) => (i === index ? newValue : value))
    );
  };

  const handleSubmitPicture = () => {
    setIsDeletePictureVisible(false);
    setCurrentPath(null);
    setCurrentFile(null);
    setDeleteFiles(
      selectedExtra?.photo?.length > 0 ? selectedExtra.photo[0].id : null
    );
  };

  const handleRemoveEmail = (index: number) => {
    if (emailInputs.length > 1) {
      setEmailInputs(emailInputs.filter((_, i) => i !== index));
    }
  };

  const handleRemoveContact = (index: number) => {
    if (phoneInputs.length > 1) {
      setPhoneInputs(phoneInputs.filter((_, i) => i !== index));
    }
  };

  const calcMarge = (unitPrice?: number, costPrice?: number) => {
    const m =
      Number(form.getValues("unit_price")) -
      Number(form.getValues("cost_price"));
    setMarge(parseFloat(m.toFixed(2)));
  };

  const currency = selectedExtra
    ? selectedExtra?.currency
      ? selectedExtra?.currency?.symbol
      : rental
      ? rental?.tarification?.rules?.rules?.currency?.symbol
      : "€"
    : "€";

  return (
    <RightModal
      isVisible={isVisible}
      onClose={() => {
        onClose();
        setPriceUpdate({
          costPrice: false,
          costUnit: false,
        });
        form.reset();
        form.clearErrors();
      }}
      isLarge={false}
      title={titleModal}
      closeOnEmpty={false}
    >
      <div className="flex flex-col w-full h-full">
        <form
          className="flex-1 overflow-y-auto w-full"
          onSubmit={form.handleSubmit(handleAddExtra)}
        >
          <div className="flex flex-col h-full w-full">
            <div className={`"flex flex-1 flex-col gap-`}>
              <div className="flex flex-1 flex-col gap-3 w-full">
                <div className="flex flex-col gap-5">
                  <div className="flex flex-row justify-between">
                    <p className="text-sm font-bold">
                      {t("Rental.Infos.TravelerPage.ExtraCard.extra_active")}
                    </p>
                    <Switch
                      value={displayExtra}
                      onClick={() => handleDisplayExtra()}
                    />
                  </div>
                  <p className="text-low-contrast">
                    {t(
                      "Rental.Infos.TravelerPage.ExtraCard.extra_active_description"
                    )}
                  </p>
                </div>
                <GroupButton
                  items={[
                    {
                      label: t("AutoMessageList.Upsert.Contents.french"),
                      value: "french",
                      Icon: FrenchIcon,
                      isActive: currentLang === "french",
                    },
                    {
                      label: t("AutoMessageList.Upsert.Contents.english"),
                      value: "english",
                      Icon: EnglishIcon,
                      isActive: currentLang === "english",
                    },
                    {
                      label: t("AutoMessageList.Upsert.Contents.spanish"),
                      value: "spanish",
                      Icon: SpanishIcon,
                      isActive: currentLang === "spanish",
                    },
                    {
                      label: t("AutoMessageList.Upsert.Contents.german"),
                      value: "german",
                      Icon: GermanIcon,
                      isActive: currentLang === "german",
                    },
                  ]}
                  onClick={handleChangeLang}
                />

                <div className="flex flex-col gap-3">
                  <div className="flex flex-col gap-10">
                    <div className="flex  flex-row space-x-4">
                      <div>
                        <p className="mb-1 text-sm font-semibold text-left">
                          {t("Booking.Guest.photoLabel")}
                        </p>
                        <PictureInput
                          mode="picture"
                          photoPath={currentPath}
                          onChangeFile={(file) => UpdatePicture(file)}
                          displayModal={isDeletePictureVisible}
                          setDisplayModal={setIsDeletePictureVisible}
                          setErrors={setErrorPhoto}
                        />

                        <CenterModal
                          isVisible={isDeletePictureVisible}
                          onClose={() => setIsDeletePictureVisible(false)}
                        >
                          <div className="flex flex-col gap-10">
                            <div className="flex flex-row  pb-0">
                              <p className="text-xl font-extrabold">
                                {t(
                                  "Rental.Infos.TravelerPage.ExtraCard.deletePictureTitle"
                                )}
                              </p>

                              <div
                                className="cursor-pointer"
                                onClick={() => setIsDeletePictureVisible(false)}
                              >
                                <CloseIcon width={18} height={16} />
                              </div>
                            </div>

                            <div className="flex gap-3">
                              <Button
                                type="secondary"
                                onClick={() => {
                                  setIsDeletePictureVisible(false);
                                }}
                              >
                                {t("Global.cancel")}
                              </Button>
                              <Button
                                type="destructive"
                                onClick={() => {
                                  handleSubmitPicture();
                                }}
                              >
                                {t("Global.removeWithConfirmation")}
                              </Button>
                            </div>
                          </div>
                        </CenterModal>
                      </div>
                      <div className="flex flex-col w-[90%] gap-4">
                        {currentLang === "french" && (
                          <>
                            <Controller
                              control={form.control}
                              name="name.fr"
                              rules={{
                                required: {
                                  value: true,
                                  message: t(
                                    "AddReservation.Guest.firstNameRequired"
                                  ),
                                },
                              }}
                              render={({field: {value, onChange}}) => {
                                return (
                                  <TextInput
                                    disabled={validationLoading}
                                    label={t(
                                      "Rental.Infos.TravelerPage.ExtraCard.name"
                                    )}
                                    required={true}
                                    value={value.message ?? ""}
                                    onChangeText={(message) => {
                                      onChange({...value, message});
                                    }}
                                    error={
                                      form.formState.errors.name?.fr?.message ||
                                      ""
                                    }
                                  />
                                );
                              }}
                            />

                            <Controller
                              control={form.control}
                              name="description.fr"
                              render={({field: {value, onChange}}) => {
                                return (
                                  <TextInput
                                    disabled={validationLoading}
                                    label={t(
                                      "Rental.Infos.TravelerPage.ExtraCard.description"
                                    )}
                                    value={value.message ?? ""}
                                    onChangeText={(message) => {
                                      onChange({...value, message});
                                    }}
                                    error={
                                      form.formState.errors.description?.fr
                                        ?.message || ""
                                    } // Affichez l'erreur si elle existe, sinon chaîne vide
                                  />
                                );
                              }}
                            />
                          </>
                        )}

                        {currentLang === "english" && (
                          <>
                            <Controller
                              control={form.control}
                              name="name.en"
                              render={({field: {value, onChange}}) => {
                                return (
                                  <TextInput
                                    disabled={validationLoading}
                                    label={t(
                                      "Rental.Infos.TravelerPage.ExtraCard.name"
                                    )}
                                    required={true}
                                    value={value?.message ?? ""}
                                    onChangeText={(message) => {
                                      onChange({...value, message});
                                    }}
                                  />
                                );
                              }}
                            />
                            <Controller
                              control={form.control}
                              name="description.en"
                              render={({field: {value, onChange}}) => {
                                return (
                                  <TextInput
                                    disabled={validationLoading}
                                    label={t(
                                      "Rental.Infos.TravelerPage.ExtraCard.description"
                                    )}
                                    value={value?.message ?? ""}
                                    onChangeText={(message) => {
                                      onChange({...value, message});
                                    }}
                                  />
                                );
                              }}
                            />
                          </>
                        )}
                        {currentLang === "german" && (
                          <>
                            <Controller
                              control={form.control}
                              name="name.de"
                              render={({field: {value, onChange}}) => {
                                return (
                                  <TextInput
                                    disabled={validationLoading}
                                    label={t(
                                      "Rental.Infos.TravelerPage.ExtraCard.name"
                                    )}
                                    required={true}
                                    value={value?.message ?? ""}
                                    onChangeText={(message) => {
                                      onChange({...value, message});
                                    }}
                                  />
                                );
                              }}
                            />
                            <Controller
                              control={form.control}
                              name="description.de"
                              render={({field: {value, onChange}}) => {
                                return (
                                  <TextInput
                                    disabled={validationLoading}
                                    label={t(
                                      "Rental.Infos.TravelerPage.ExtraCard.description"
                                    )}
                                    value={value?.message ?? ""}
                                    onChangeText={(message) => {
                                      onChange({...value, message});
                                    }}
                                  />
                                );
                              }}
                            />
                          </>
                        )}
                        {currentLang === "spanish" && (
                          <>
                            <Controller
                              control={form.control}
                              name="name.es"
                              render={({field: {value, onChange}}) => {
                                return (
                                  <TextInput
                                    disabled={validationLoading}
                                    label={t(
                                      "Rental.Infos.TravelerPage.ExtraCard.name"
                                    )}
                                    required={true}
                                    value={value?.message ?? ""}
                                    onChangeText={(message) => {
                                      onChange({...value, message});
                                    }}
                                  />
                                );
                              }}
                            />
                            <Controller
                              control={form.control}
                              name="description.es"
                              render={({field: {value, onChange}}) => {
                                return (
                                  <TextInput
                                    disabled={validationLoading}
                                    label={t(
                                      "Rental.Infos.TravelerPage.ExtraCard.description"
                                    )}
                                    value={value?.message ?? ""}
                                    onChangeText={(message) => {
                                      onChange({...value, message});
                                    }}
                                  />
                                );
                              }}
                            />
                          </>
                        )}
                      </div>
                    </div>
                    {errorPhoto ? (
                      <ErrorMessage>{errorPhoto}</ErrorMessage>
                    ) : null}
                    <div className="flex flex-row gap-4">
                      <div className="flex flex-col gap-y-2 w-full">
                        <Controller
                          control={form.control}
                          name="cost_price"
                          rules={{
                            required: {
                              value: true,
                              message: t(
                                "AddReservation.Guest.firstNameRequired"
                              ),
                            },
                          }}
                          render={({field: {value, onChange}}) => {
                            return (
                              <NumberInput
                                required={true}
                                label={t(
                                  "Rental.Infos.TravelerPage.ExtraCard.cost_price"
                                )}
                                size="large"
                                value={value}
                                min={0}
                                disabled={false}
                                onChangeText={(value) => {
                                  onChange(value);
                                  setTimeout(() => {
                                    setPriceUpdate({
                                      costPrice: true,
                                      costUnit: priceUpdate.costPrice,
                                    });
                                    calcMarge();
                                  }, 200);
                                }}
                                acceptDecimal
                                error={
                                  form.formState.errors.cost_price?.message ||
                                  ""
                                }
                              />
                            );
                          }}
                        />
                        <span className="flex flex-1 text-xs text-low-contrast">
                          {t(
                            "Rental.Infos.TravelerPage.ExtraCard.cost_price_novisible"
                          )}
                        </span>
                      </div>

                      <div className="flex flex-col gap-y-2 w-full">
                        <Controller
                          control={form.control}
                          name="unit_price"
                          render={({field: {value, onChange}}) => {
                            return (
                              <NumberInput
                                required={true}
                                label={t(
                                  "Rental.Infos.TravelerPage.ExtraCard.unit_price"
                                )}
                                size="large"
                                min={0}
                                disabled={false}
                                value={value}
                                onChangeText={(value) => {
                                  onChange(value);
                                  setTimeout(() => {
                                    calcMarge();
                                    setPriceUpdate({
                                      costPrice: priceUpdate.costPrice,
                                      costUnit: true,
                                    });
                                  }, 200);
                                }}
                                acceptDecimal
                                error={
                                  form.formState.errors.unit_price?.message ||
                                  ""
                                }
                              />
                            );
                          }}
                        />
                        <span className="flex flex-1 text-xs text-low-contrast">
                          {t("Rental.Infos.TravelerPage.ExtraCard.marge", {
                            amount: marge,
                            currency,
                          })}
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-row gap-4">
                      <Controller
                        control={form.control}
                        name="maximum_quantity"
                        rules={{
                          required: true,
                          onChange: (e) => {
                            form.setValue("maximum_quantity", e.target.value);
                          },
                        }}
                        render={({field: {onChange}}) => (
                          <div className="flex-1">
                            <InputSelect
                              disabled={validationLoading}
                              label={t(
                                "Rental.Infos.TravelerPage.ExtraCard.maximum_quantity"
                              )}
                              selectedValue={form.getValues("maximum_quantity")}
                              defaultValue={form.getValues("maximum_quantity")}
                              items={listMaximun}
                              onSelect={onChange}
                            />
                          </div>
                        )}
                      />
                      <Controller
                        control={form.control}
                        name="available_until"
                        rules={{
                          required: true,
                          onChange: (e) => {
                            form.setValue(
                              "available_until",
                              parseInt(e.target.value)
                            );
                          },
                        }}
                        render={({field: {onChange}}) => (
                          <div className="flex-1">
                            <InputSelect
                              disabled={validationLoading}
                              label={t(
                                "Rental.Infos.TravelerPage.ExtraCard.available_until"
                              )}
                              selectedValue={form.getValues("available_until")}
                              items={listAvailableUntil}
                              onSelect={(value) => {
                                form.setValue("available_until", value);
                              }}
                            />
                          </div>
                        )}
                      />
                    </div>
                    <div className="flex flex-col gap-6">
                      <div className="flex flex-col gap-4">
                        <div className={"flex flex-row justify-between"}>
                          <p className="font-bold">
                            {t(
                              "Rental.Infos.TravelerPage.ExtraCard.validationTilte"
                            )}
                          </p>
                          <Switch
                            value={requiredValidation}
                            onClick={() => handleRequiredValidation()}
                          />
                        </div>
                        <p className="text-low-contrast text-xs">
                          {t(
                            "Rental.Infos.TravelerPage.ExtraCard.validationContent"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex gap-3 border-t-1 border-element-border pt-4 m-2">
              <Button type="secondary" onClick={onClose}>
                {t("Global.cancel")}
              </Button>
              <Button
                RightIcon={CheckIcon}
                disabled={disabled}
                loading={disabled}
                onClick={() => {}}
              >
                {t("Profil.validate")}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </RightModal>
  );
};
