import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import EmojiHappyIcon from "../../assets/icons/emoji-happy.svg?react";
import LogInIcon from "../../assets/icons/log-in.svg?react";
import LogOutIcon from "../../assets/icons/log-out.svg?react";
import MoneyIcon from "../../assets/icons/money.svg?react";
import MoonIcon from "../../assets/icons/moon-2.svg?react";
import UsersIcon from "../../assets/icons/users.svg?react";
import { Separator } from "../../components/Common/Separator/Separator";
import { PaymentPolicyDepositPaymentOptionEnum } from "../../components/Payment/Payment.type";
import { getFormattedPrice } from "../../helpers/currencyHelper";
import { capitalizeFirstLetter } from "../../helpers/stringHelper";
import { PaymentDepositFormOrInfo } from "./PaymentDepositFormOrInfo";
import {
  useInitPaymentDeposit,
  useInitPaymentDepositStates,
} from "./PaymentDepositPage.hooks";
import { PaymentDepositPageSkeleton } from "./PaymentDepositPageSkeleton";

export const PaymentDepositPage: React.FC = () => {
  const { t } = useTranslation();

  const initStates = useInitPaymentDepositStates();
  const [loading, setLoading] = initStates.loadingState;
  const [depositData, setDepositData] = initStates.depositDataState;
  const [billingDetails, setBillingDetails] = initStates.billingDetailsState;
  const [stripePromise, setStripePromise] = initStates.stripePromiseState;
  const [stripeOptions, setStripeOptions] = initStates.stripeOptionsState;

  const depositPaymentOption =
    depositData?.deposit_schedules.payment_schedule.deposit_payment_option;

  useInitPaymentDeposit(
    setLoading,
    [depositData, setDepositData],
    [stripePromise, setStripePromise],
    setStripeOptions,
    setBillingDetails,
    depositPaymentOption
  );

  if (loading) return <PaymentDepositPageSkeleton />;

  return (
    <div className="flex flex-col items-center w-full h-screen gap-y-10">
      <header
        className="flex items-center justify-end w-full px-12 py-10"
        style={{
          backgroundImage: `url('${depositData?.deposit_schedules.rental.cover}')`,
        }}
      >
        <h1 className="text-xl font-bold text-white">
          {depositData?.deposit_schedules.rental.name}
        </h1>
      </header>

      <section className="flex justify-center w-full max-w-5xl gap-x-10">
        <article className="flex flex-col flex-1 space-y-4">
          <h2 className="text-[22px] font-bold tracking-wider text-high-contrast">
            {t("Payments.PaymentReservation.title")}
          </h2>

          {/* RENTAL */}
          <div className="flex items-center space-x-3">
            <img
              className="rounded w-28 h-28"
              src={depositData?.deposit_schedules.rental.cover}
              alt={depositData?.deposit_schedules.rental.name}
            />
            <div>
              <p className="text-[16px] font-bold text-high-contrast">
                {depositData?.deposit_schedules.rental.name}
              </p>
              <p className="text-[14px] text-low-contrast mt-1">
                {`${depositData?.deposit_schedules.rental.address} ${depositData?.deposit_schedules.rental.postal_code} ${depositData?.deposit_schedules.rental.city}`}
              </p>
            </div>
          </div>

          <div className="flex items-center justify-between">
            {/* CHECKIN / CHECKOUT */}
            <div className="flex flex-col justify-between space-y-5">
              <div>
                <div className="flex space-x-2">
                  <LogInIcon />
                  <h3 className="text-low-contrast">
                    {t("Global.checkin", { count: 1 })}
                  </h3>
                </div>
                <p className="font-bold text-high-contrast">
                  {capitalizeFirstLetter(
                    moment(depositData?.deposit_schedules.checkin).format(
                      "dddd DD MMMM YYYY"
                    )
                  )}
                </p>
                <p className="text-low-contrast font-light text-[14px]">
                  {t("Payments.PaymentReservation.rangeDate", {
                    minTime:
                      depositData?.deposit_schedules.rental.checkin_min_time,
                    maxTime:
                      depositData?.deposit_schedules.rental.checkin_max_time,
                  })}
                </p>
              </div>

              <div>
                <div className="flex space-x-2">
                  <LogOutIcon />
                  <h3 className="text-low-contrast">
                    {t("Global.checkout", { count: 1 })}
                  </h3>
                </div>
                <p className="font-bold text-high-contrast">
                  {capitalizeFirstLetter(
                    moment(depositData?.deposit_schedules.checkout).format(
                      "dddd DD MMMM YYYY"
                    )
                  )}
                </p>
                <p className="text-low-contrast font-light text-[14px]">
                  {t("Payments.PaymentReservation.rangeDate", {
                    minTime:
                      depositData?.deposit_schedules.rental.checkout_min_time,
                    maxTime:
                      depositData?.deposit_schedules.rental.checkout_max_time,
                  })}
                </p>
              </div>
            </div>

            <div className="flex flex-col space-y-2">
              <div className="flex items-center justify-end space-x-2">
                <p className="font-bold text-md text-high-contrast">
                  {t("Global.nights", {
                    count: depositData?.deposit_schedules.nights_count ?? 0,
                  })}
                </p>
                <MoonIcon className="w-6 h-6" />
              </div>

              <div className="flex items-center justify-end space-x-2">
                <p className="text-[14px] text-low-contrast">
                  {t("Global.adults", {
                    count: depositData?.deposit_schedules.adults_count ?? 0,
                  })}
                </p>
                <UsersIcon className="w-5 h-5" />
              </div>

              <div className="flex items-center justify-end space-x-2">
                <p className="text-[14px] text-low-contrast">
                  {t("Global.children", {
                    count: depositData?.deposit_schedules.children_count ?? 0,
                  })}
                </p>
                <EmojiHappyIcon className="w-5 h-5" />
              </div>

              <div className="flex items-center justify-end space-x-2">
                <p className="text-[14px] text-low-contrast">
                  {getFormattedPrice({
                    price: Number(
                      depositData?.deposit_schedules.payment_schedule
                        .total_price_value
                    ),
                    decimals: 2,
                    locale: "fr-FR",
                    currency: "EUR",
                  })}
                </p>
                <MoneyIcon className="w-6 h-6" />
              </div>
            </div>
          </div>

          <Separator />

          <div className="flex flex-col pt-6 gap-y-6">
            {/* DEPOSIT INFO */}
            <h4 className="text-xl font-bold text-high-contrast">
              {t("Payments.PaymentDeposit.infoTitle")}
            </h4>

            <div className="flex justify-between">
              <span className="text-[16px] text-low-contrast">
                {t(
                  `${
                    depositPaymentOption ===
                    PaymentPolicyDepositPaymentOptionEnum.PRE_AUTHORISATION
                      ? "Payments.PaymentDeposit.preAuthorisationDepositToPay"
                      : "Payments.PaymentDeposit.depositToPay"
                  }`
                )}
              </span>
              <span className="text-[18px] text-high-contrast font-bold">{`${
                depositData?.deposit_schedules.payment_schedule.deposit_value
              } ${t("Global.currencySymbol")}`}</span>
            </div>

            {depositPaymentOption ===
            PaymentPolicyDepositPaymentOptionEnum.PRE_AUTHORISATION ? (
              <>
                <p className="text-low-contrast">
                  {t("Payments.PaymentDeposit.preAuthorisationDescription1")}
                </p>
                <p className="text-low-contrast">
                  {t("Payments.PaymentDeposit.preAuthorisationDescription2")}
                </p>
              </>
            ) : (
              <>
                <p className="text-low-contrast">
                  {t("Payments.PaymentDeposit.otherDescription1")}
                </p>
                <p className="text-low-contrast">
                  {t("Payments.PaymentDeposit.otherDescription2")}
                </p>
              </>
            )}
          </div>
        </article>

        <article className="flex flex-col items-center justify-between flex-1 w-full h-full">
          {depositData ? (
            <PaymentDepositFormOrInfo
              status={
                depositData.deposit_schedules.payment_schedule
                  .deposit_payment_status
              }
              option={depositPaymentOption}
              amount={Number(
                depositData?.deposit_schedules.payment_schedule.deposit_value
              )}
              stripeOptions={stripeOptions}
              stripePromise={stripePromise}
              stripeClientSecret={depositData.stripe_client_secret}
              billingDetails={billingDetails}
            />
          ) : null}
        </article>
      </section>
    </div>
  );
};
