import React from "react";
import { useTranslation } from "react-i18next";

import ExportIcon from "../../../../assets/icons/export.svg?react";
import SearchIcon from "../../../../assets/icons/search.svg?react";
import { Button } from "../../../Common/Button/Button";
import { DateFilter } from "../../../Common/DateFilter/DateFilter";
import { GroupButton } from "../../../Common/GroupButton/GroupButton";
import { GroupButtonItemType } from "../../../Common/GroupButton/GroupButton.type";
import { TextInput } from "../../../Common/TextInput/TextInput";
import { useInitPaymentDepositFiltersStates } from "./PaymentDepositFilters.hooks";
import { PaymentDepositFiltersProps } from "./PaymentDepositFilters.type";

export const PaymentDepositFilters: React.FC<PaymentDepositFiltersProps> = ({
  loading,
  onSearchText,
  totals,
  onFilterButtonClick,
  currentActiveButtonFilter,
  onDatesChanged,
}) => {
  const { t } = useTranslation();

  const initStates = useInitPaymentDepositFiltersStates();
  const [dateRangePreset, setDateRangePreset] = initStates.dateRangePresetState;

  const getGroupButtonItems = (): GroupButtonItemType[] => [
    {
      label: t("Global.all", {
        context: "male_other",
        count: Number(totals.all),
      }),
      value: "ALL",
      badge: loading ? null : totals.all.toString(),
      disabled: loading,
      isActive: currentActiveButtonFilter === "ALL",
    },
    {
      label: t("Payments.PaymentReservationList.Filter.unpaid", {
        context: "female",
      }),
      value: "UNPAID",
      badge: loading ? null : totals.unpaid.toString(),
      disabled: loading,
      isActive: currentActiveButtonFilter === "UNPAID",
    },
    {
      label: t("Payments.PaymentReservationList.Filter.overdue", {
        context: "female",
        count: Number(totals.overdue),
      }),
      value: "OVERDUE",
      badge: loading ? null : totals.overdue.toString(),
      disabled: loading,
      isActive: currentActiveButtonFilter === "OVERDUE",
    },
    {
      label: t("Payments.PaymentReservationList.Filter.pre_authorization", {
        context: "female",
        count: Number(totals.pre_authorization),
      }),
      value: "PRE_AUTHORIZATION",
      badge: loading ? null : totals.pre_authorization.toString(),
      disabled: loading,
      isActive: currentActiveButtonFilter === "PRE_AUTHORIZATION",
    },
    {
      label: t("Payments.PaymentReservationList.Filter.payment_refundable", {
        context: "female",
        count: Number(totals.payment_refundable),
      }),
      value: "PAYMENT_REFUNDABLE",
      badge: loading ? null : totals.payment_refundable.toString(),
      disabled: loading,
      isActive: currentActiveButtonFilter === "PAYMENT_REFUNDABLE",
    },
    {
      label: t("Payments.PaymentReservationList.Filter.canceled", {
        context: "female",
        count: Number(totals.canceled),
      }),
      value: "CANCELED",
      badge: loading ? null : totals.canceled.toString(),
      disabled: loading,
      isActive: currentActiveButtonFilter === "CANCELED",
    },
  ];

  return (
    <div className="flex flex-col gap-y-5">
      <div className="flex flex-row items-center space-x-3">
        <div className="flex-1">
          <TextInput
            size="large"
            Icon={SearchIcon}
            placeholder={t("Global.search")}
            onChangeText={onSearchText}
          />
        </div>

        {/* TODO: [PaymentReservation] Implement export button logic */}
        <div title={t("Global.wip")}>
          <Button
            type="secondary"
            onClick={() => {}}
            RightIcon={ExportIcon}
            disabled={true}
          >
            {t("Global.export")}
          </Button>
        </div>
      </div>

      <div className="flex flex-row items-center space-x-3">
        <GroupButton
          items={getGroupButtonItems()}
          onClick={onFilterButtonClick}
        />
      </div>

      <div className="flex flex-row items-center space-x-3">
        <div className="flex">
          <DateFilter
            onDatesChanged={onDatesChanged}
            onDateRangePresetChanged={(dateRangePreset) => {
              setDateRangePreset(dateRangePreset);
            }}
          />
        </div>
      </div>
    </div>
  );
};
