import React, {useState} from "react";
import {Tooltip} from "../Common/Tooltip/Tooltip";
import ChevronRightIcon from "../../assets/icons/chevron-right.svg?react";
import ChevronDownIcon from "../../assets/icons/chevron-down.svg?react";
import ArrowRightIcon from "../../assets/icons/arrow-right.svg?react";
import MinusCircleErrorIcon from "../../assets/icons/minus-circle-error.svg?react";
import {cn} from "../../helpers/classHelper";
import {ClassNamesType} from "../../types/commonTypes";
import {PlatformEnum} from "../../enums/GETenums";
import {
  AnalyticsDetailedDistribution,
  AnalyticsDetailedDistributionObject,
  DistributionKeyEnum,
  StatsNameKeysEnum,
} from "../../types/GETTypes";
import AirbnbIcon from "../../assets/icons/airbnb.svg?react";
import BookingIcon from "../../assets/icons/booking.svg?react";
import SuperhoteIcon from "../../assets/icons/superhote-circle.svg?react";
import OtherPlatformIcon from "../../assets/icons/globe.svg?react";
import {useTranslation} from "react-i18next";

interface DetailsLineProps {
  item: DetailsLineItem;
  className?: ClassNamesType;
  openModalDetails: (
    statName: StatsNameKeysEnum,
    platform: DistributionKeyEnum
  ) => void;
  border?: boolean;
  currency: string | undefined;
}

export interface DetailsLineItem {
  label: string;
  subLabel: string;
  info: string;
  alert: boolean;
  distribution:
    | AnalyticsDetailedDistributionObject
    | AnalyticsDetailedDistribution;
  distributionSymbol: string;
  statName: StatsNameKeysEnum;
}
const DetailsLineComponent = ({
  item,
  className,
  openModalDetails,
  border = true,
  currency,
}: DetailsLineProps) => {
  const {t} = useTranslation();
  const {label, subLabel, info, alert = false} = item;

  const [openDetails, setOpenDetails] = useState(false);

  const nameByKey = (key: DistributionKeyEnum) => {
    switch (key) {
      case DistributionKeyEnum.AIRBNB:
        return "Airbnb";
      case DistributionKeyEnum.BOOKING:
        return "Booking";
      case DistributionKeyEnum.DIRECT:
        return "Superhote";
      case DistributionKeyEnum.OTHERS:
        return t("Analytics.othersPlatform");
      default:
        return "";
    }
  };
  const iconByKey = (key: DistributionKeyEnum) => {
    switch (key) {
      case DistributionKeyEnum.AIRBNB:
        return <AirbnbIcon className="w-4 h-4 rounded-full mr-2" />;
      case DistributionKeyEnum.BOOKING:
        return <BookingIcon className="w-4 h-4 rounded-full mr-2" />;
      case DistributionKeyEnum.DIRECT:
        return <SuperhoteIcon className="w-4 h-4 rounded-full mr-2" />;
      case DistributionKeyEnum.OTHERS:
        return <OtherPlatformIcon className="w-4 h-4 rounded-full mr-2" />;
      default:
        return "";
    }
  };

  const createArrayFromDistribution = (
    distribution:
      | AnalyticsDetailedDistributionObject
      | AnalyticsDetailedDistribution
  ) => {
    const result = [];

    if (distribution) {
      for (const [key, value] of Object.entries(distribution)) {
        // Check if the value is an object with a `.value` property
        const finalValue =
          typeof value === "object" && value !== null ? value.value : value;

        if (nameByKey(key as DistributionKeyEnum)) {
          result.push({
            name: nameByKey(key as DistributionKeyEnum),
            code: key as DistributionKeyEnum,
            value: finalValue?.toLocaleString('fr-FR'),
            icon: iconByKey(key as DistributionKeyEnum),
          });
        }
      }
    }

    return result;
  };
  const distribution = createArrayFromDistribution(item?.distribution);
  return (
    <div className="flex flex-col justify-between">
      <div
        className={cn(
          "flex justify-between py-4",
          !openDetails && border ? "border-b border-element-border" : ""
        )}
      >
        <div className="flex items-center space-x-2">
          <div
            className="flex items-center space-x-2 cursor-pointer"
            onClick={() => setOpenDetails((value) => !value)}
          >
            {openDetails ? (
              <ChevronDownIcon className="w-4 h-4" />
            ) : (
              <ChevronRightIcon className="w-4 h-4" />
            )}
            <span className={cn(className?.label)}>{label} </span>
          </div>
          {info && (
            <Tooltip
              value={info}
              classNames={{container: "top-0 left-[-20px]"}}
            />
          )}
        </div>
        <div className="flex items-center space-x-2 ">
          {alert && <MinusCircleErrorIcon className="w-4 h-4" />}
          <span className={cn("font-bold", alert ? "text-error" : "")}>
            {subLabel}
          </span>
        </div>
      </div>
      {openDetails
        ? distribution.map((d) => {
            const isEmpty = d.value === 0;
            return (
              <div className="flex items-center justify-between border-b p-4 pt-2 pr-0 ml-6">
                <div className="flex items-center">
                  {d.icon}
                  <div>
                    <div className="text-low-contrast">{d.name}</div>
                  </div>
                </div>

                <div
                  className={cn(
                    "flex space-x-3 items-center text-low-contrast",
                    isEmpty ? "font-light" : "font-bold"
                  )}
                >
                  <span>
                    {d.value} {item.distributionSymbol}
                  </span>
                  {!isEmpty && (
                    <ArrowRightIcon
                      className="w-4 h-4 cursor-pointer"
                      onClick={() => openModalDetails(item.statName, d.code)}
                    />
                  )}
                </div>
              </div>
            );
          })
        : null}
    </div>
  );
};

export default DetailsLineComponent;
