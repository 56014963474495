import React from "react";
import useGuestWebsiteStore from "../../../../stores/guestWebsiteStore/useGuestWebsiteStore";

interface GuestWebsiteTemplate2PrivacyComponentProps {
  loading: boolean;
}
const GuestWebsiteTemplate2PrivacyComponent =
  ({}: GuestWebsiteTemplate2PrivacyComponentProps) => {
    const {dataGuestWebsite} = useGuestWebsiteStore();
    const {integration,styles} = dataGuestWebsite || {};
    const {privacy_policy} = integration || {};
    const {color_background,color_text} = styles || {};
    return (
      <div
        className="py-12 px-4 md:px-contentWebsite pb-24 h-full"
        dangerouslySetInnerHTML={{__html: privacy_policy ?? ""}}
        style={{backgroundColor:color_background,color:color_text}}
      />
    );
  };

export default GuestWebsiteTemplate2PrivacyComponent;
