import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import LoaderIcon from "../../../assets/icons/loader.svg?react";
import { cn } from "../../../helpers/classHelper";
import { useOutsideDetector } from "../../../hooks/useOutsideDetector";
import { CalendarInputPopup } from "../CalendarInput/CalendarInputPopup";
import { GroupButtonItemProps } from "./GroupButton.type";

export const GroupButtonItem: React.FC<GroupButtonItemProps> = ({
  label,
  badge,
  type = "middle",
  isActive = false,
  width = "fit",
  Icon,
  isPeriod,
  from = new Date(),
  to = new Date(),
  disabled = false,
  onClick = () => {},
  onChangePeriod = () => {},
}) => {
  const { t } = useTranslation();

  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);

  const getBorder = () => {
    const result = "";

    if (type === "last") {
      return "border-y-1 border-r-1 border-element-border rounded-r-4px";
    } else if (type === "first") {
      return "border-1 border-element-border rounded-l-4px";
    } else {
      return "border-y-1 border-r-1 border-element-border";
    }
  };

  const handleClick = () => {
    if (disabled) return;

    if (isPeriod) {
      onClick();
      setIsCalendarOpen(!isCalendarOpen);
    } else {
      onClick();
    }
  };

  const ref = useRef(null);
  const handleClickOutside = () => {
    setIsCalendarOpen(false);
  };

  useOutsideDetector(ref, handleClickOutside);

  return (
    <div
      className={cn(`relative`, {
        "flex-1": width === "full",
      })}
      ref={ref}
    >
      <div
        className={cn(
          `flex items-center justify-center flex-row py-1 px-3 space-x-2 h-9 hover:bg-element-background`,
          getBorder(),
          {
            "bg-element-background": isActive,
            "bg-white": !isActive,
          },
          disabled ? "cursor-not-allowed" : "cursor-pointer"
        )}
        onClick={handleClick}
      >
        <div
          className={cn(
            `flex flex-row items-center gap-1 font-semibold text-sm`,
            {
              "text-high-contrast": isActive,
              "text-low-contrast": !isActive,
            }
          )}
        >
          {Icon ? <Icon /> : null}

          <p className="line-clamp-1">{label}</p>
        </div>

        {badge === null && <LoaderIcon className={"w-6 h-6 animate-spin"} />}

        {badge !== undefined && badge !== null && (
          <div
            className={cn(`px-2 py-1 rounded-6px font-semibold text-sm`, {
              "bg-element-background-solid text-white": isActive,
              "bg-element-background-active text-low-contrast": !isActive,
            })}
          >
            {badge}
          </div>
        )}
      </div>

      {isCalendarOpen && isPeriod ? (
        <div className="absolute left-0 z-10 flex flex-row gap-4 p-4 bg-white top-10 border-1 border-element-border rounded-6px">
          <div className="flex flex-col">
            <p className="text-sm font-semibold text-high-contrast">
              {t("Global.from")} :
            </p>
            <CalendarInputPopup
              currentDate={from}
              onChange={(value) => onChangePeriod({ type: "from", value })}
            />
          </div>

          <div className="flex flex-col">
            <p className="text-sm font-semibold text-high-contrast">
              {t("Global.to")} :
            </p>
            <CalendarInputPopup
              currentDate={to}
              onChange={(value) => onChangePeriod({ type: "to", value })}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};
