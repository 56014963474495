import { t } from "i18next";
import React, { useEffect } from "react";
import { Trans } from "react-i18next";
import { RequiredFields } from "../../../../types/commonTypes";
import InfoCard from "../../../Common/Card/InfoCard";
import { InputSelect } from "../../../Common/InputSelect/InputSelect";
import {
  ManagementAutomationListProgramStepForm,
  ManagementAutomationListProgramStepProps,
} from "./ProgramStep.type";
import { lowerCase } from "lodash";

export const ManagementAutomationListProgramStep: React.FC<
  ManagementAutomationListProgramStepProps
> = ({ loading, form, rentals, informationsValues }) => {
  useEffect(() => {
    let type: "checkin" | "checkout" = "checkout";
    switch (informationsValues.automationType) {
      case "CLEANING":
        type = "checkout";
        break;
      case "CHECKOUT":
        type = "checkout";
        break;
      case "CHECKIN":
        type = "checkin";
        break;
      case "OTHER":
        type = "checkout";
        break;
    }

    form.setValue("programTask", type);
  }, []);

  const requiredFields: RequiredFields<ManagementAutomationListProgramStepForm> =
    {
      programTask: true,
    };

  const rentalName =
    rentals && rentals.length > 0
      ? rentals.find(
          (rental) => rental.id === informationsValues.concernedRental,
        )?.name
      : "";

  const taskName = (): string => {
    if (informationsValues.automationType === "OTHER") {
      return informationsValues.nameTask!;
    } else {
      return t(
        `Automation.AddOrUpdateModal.Steps.Informations.AutomationTypeOptions.${lowerCase(informationsValues.automationType)}`,
      );

      return "";
    }
  };

  form.watch();

  return (
    <div className="flex flex-col gap-y-4">
      <InputSelect
        register={form.register("programTask", {
          required: {
            message: t("Global.Errors.requiredField", {
              fieldName: t(
                "Automation.AddOrUpdateModal.Steps.Program.programTaskLabel",
              ),
            }),
            value: requiredFields.programTask,
          },
        })}
        required={requiredFields.programTask}
        disabled={loading || informationsValues.automationType !== "OTHER"}
        label={t("Automation.AddOrUpdateModal.Steps.Program.programTaskLabel")}
        items={[
          {
            label: t(
              "Automation.AddOrUpdateModal.Steps.Program.ProgramTaskOptions.passengersArrival",
            ),
            value: "checkin",
          },
          {
            label: t(
              "Automation.AddOrUpdateModal.Steps.Program.ProgramTaskOptions.passengersDeparture",
            ),
            value: "checkout",
          },
        ]}
        selectedValue={form.getValues("programTask")}
        error={form.formState.errors.programTask?.message}
      />

      {rentalName && taskName ? (
        <InfoCard
          title={
            <Trans
              i18nKey={"Automation.AddOrUpdateModal.Steps.Program.description1"}
              components={{
                strong: <span className="font-bold"></span>,
              }}
              values={{
                rentalName: rentalName,
                taskName: taskName(),
                when: t(
                  `Automation.AddOrUpdateModal.Steps.Program.ProgramTaskValues.${
                    form.getValues("programTask") === "checkin"
                      ? "passengersArrival"
                      : "passengersDeparture"
                  }`,
                ),
              }}
            />
          }
          description={
            <Trans
              i18nKey={"Automation.AddOrUpdateModal.Steps.Program.description2"}
              components={{
                strong: <span className="font-bold"></span>,
              }}
            />
          }
        />
      ) : null}
    </div>
  );
};
