import React, { useMemo, useState } from "react";

import CheckinIcon from "../../../assets/icons/log-in.svg?react";

import { useTranslation } from "react-i18next";
import {
  CalendarReservationResponse,
  ReservationResponse,
  TaskAssignationType,
  TaskPriorityType,
} from "../../../types/GETTypes";
import { useModal, UseModal } from "../../../hooks/useModal";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import { FlexCol } from "../../Common/FlexComponents/FlexCol";

import CleaningSection from "./CleaningSection";
import CheckoutSection from "./CheckoutSection";
import CheckinSection from "./CheckinSection";
import { ReservationStatusEnum } from "../../../enums/GETenums";
import { FlexItemCenter } from "../../Common/FlexComponents/FlexItemCenter";
import { HelpText } from "../../Common/TextComponents/HelpText";
import { ManagementAutomationListAssignModal } from "../../ManagementAutomationList/AssignModal";
import { useTasks } from "../../../hooks/api/automation";

export default function CalendarOperationalManagement({
  reservation,
  reloadCalendarList,
}: {
  reservation: CalendarReservationResponse | ReservationResponse;
  reloadCalendarList?: Function;
}) {
  const { t } = useTranslation();
  const [error, setError] = useState();

  const modalAssign = useModal<{
    reservationId: string;
    reloadCalendarList?: Function;
    taskType: TaskPriorityType;
  }>();

  const reservationIsCanceled = useMemo(
    () =>
      reservation.status_reservation === ReservationStatusEnum.CANCELED ||
      reservation.status_reservation ===
        ReservationStatusEnum.CANCELED_BY_TRAVELER,
    [reservation.status_reservation],
  );

  const reservationIsRequested = useMemo(
    () => reservation.status_reservation === ReservationStatusEnum.REQUEST,
    [reservation.status_reservation],
  );

  const deleteError = () => {
    setError(undefined);
  };

  const { assign } = useTasks();
  const handleAssign = async (
    ids: string[],
    type: TaskAssignationType,
    reservationId: string,
  ): Promise<void> => {
    if (modalAssign.data?.taskType) {
      const reservationForCalendarList = await assign(
        reservationId,
        type,
        ids[0],
        modalAssign.data?.taskType,
      );

      if (reservation && reservation.priority_tasks) {
        reservation.priority_tasks = reservationForCalendarList.priority_tasks;
      }
    }
  };
  const handleAssignError = (): void => {};

  return (
    <>
      <ManagementAutomationListAssignModal
        modal={modalAssign}
        onAssignNotForm={handleAssign}
        onError={handleAssignError}
        isForm={false}
        isMultiSelect={false}
        showHelpDetailsForTeam={false}
      />

      <div className=" bg-subtle rounded-6px p-2 mt-4">
        <div className="pb-2">
          {error ? (
            <ErrorMessage
              showCloseButton={true}
              onClickCloseButton={deleteError}
            >
              {error}
            </ErrorMessage>
          ) : null}
        </div>
        <FlexItemCenter>
          <CheckinIcon className="w-5 h-5 mr-1" />
          <HelpText className={"text-md"}>
            {t("Booking.checkinCheckout")}
          </HelpText>
        </FlexItemCenter>
        {reservationIsRequested ? (
          <HelpText className={"pt-2"}>
            {t("Calendar.Reservation.requestedHelpText")}
          </HelpText>
        ) : null}
        {!reservationIsRequested ? (
          <FlexCol className={"mt-4"}>
            <CheckinSection
              reservation={reservation}
              modalAssign={modalAssign}
              reloadCalendarList={reloadCalendarList}
              setError={setError}
              isCanceled={reservationIsCanceled}
            />
            <CheckoutSection
              reservation={reservation}
              modalAssign={modalAssign}
              reloadCalendarList={reloadCalendarList}
              setError={setError}
              isCanceled={reservationIsCanceled}
            />
            <CleaningSection
              reservation={reservation}
              modalAssign={modalAssign}
              reloadCalendarList={reloadCalendarList}
              setError={setError}
              isCanceled={reservationIsCanceled}
            />
          </FlexCol>
        ) : null}
      </div>
    </>
  );
}
