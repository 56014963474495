import React from "react";
import { SimpleBox } from "../Modal/Airbnb/Connect/AirbnbConnectStepImport";
import { useTranslation } from "react-i18next";
import { TableListHeaderProps } from "../Common/TableList/TableList.type";

export const WebsiteRentalsHeader: React.FC<TableListHeaderProps> = ({
  onSelectAll,
  areAllSelected,
}) => {
  const { t } = useTranslation();

  return (
    <tr
      className="bg-element-background border-b-1 border-element-border"
      onClick={onSelectAll}
    >
      <th className="w-8 p-2">
        <SimpleBox value={areAllSelected ?? false} />
      </th>

      <th className="p-2 text-left">
        <p className="font-semibold text-high-contrast">
          {t("Website.Rentals.rentals")}
        </p>
      </th>
    </tr>
  );
};
