import React from "react";
import {Card} from "../../Common/Card/Card";
import {useTranslation} from "react-i18next";
import UsersIcon from "../../../assets/icons/users.svg?react";
import PlusIcon from "../../../assets/icons/plus.svg?react";
import EditIcon from "../../../assets/icons/edit.svg?react";
import {ManagementTeamMemberItemSkeleton} from "./ManagementTeamMemberItemSkeleton";

export const ManagementTeamListGroupItemSkeleton: React.FC<{}> = ({}) => {
  const { t } = useTranslation();

  return (
    <Card
      label={t("Management.TeamList.titlePlaceholder")}
      Icon={UsersIcon}
      button={{
        label: t("Management.addMember"),
        Icon: PlusIcon,
        skeleton: true,
      }}
      secondaryButton={{
        label: "",
        Icon: EditIcon,
        skeleton: true,
      }}
    >
      <div className="flex flex-col gap-3 animate-pulse">
        {Array.from({ length: 5 }).map((v, index) => (
          <ManagementTeamMemberItemSkeleton key={index} />
        ))}
      </div>
    </Card>
  );
};
