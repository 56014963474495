/**
 * Modal form Right for edit one payment.
 */

import { RightModal } from "../Common/RightModal/RightModal";
import { PaymentModalEditOneprops, PaymentType } from "./Payment.type";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../Common/Button/Button";
import useFormattedMonthYear from "../../hooks/useDate";
import { usePaymentOperational } from "../../hooks/api/paymentOperational";
import { ErrorMessage } from "../Common/ErrorMessage/ErrorMessage";
import { useOperationalManagementMember } from "../../hooks/api/operationalManagement";
import {
  OperationalManagementTeamMemberResponse,
  RentalInformationsResponse,
} from "../../types/GETTypes";
import { InputSelect } from "../Common/InputSelect/InputSelect";
import { TextInput } from "../Common/TextInput/TextInput";
import { Controller, useForm } from "react-hook-form";
import { useRentalList } from "../../hooks/api/rental";
import { DatePickerInput } from "@mantine/dates";
import { NumberInput } from "../Common/NumberInput/NumberInput";
import { Separator } from "../Common/Separator/Separator";
import { CheckBox } from "../Common/CheckBox/CheckBox";

interface formType {
  memberId: string;
  prestaName: string;
  rentalIds: Array<string | number>;
  prestaDate: Date;
  changedBeds: number;
  amount: number;
}

export const PaymentModalEditOne = ({
  modal,
  handleUpdateSuccess,
}: PaymentModalEditOneprops) => {
  const { t } = useTranslation();
  const { datetimeToDateObject } = useFormattedMonthYear();
  const { updatePayment } = usePaymentOperational();
  const [members, setMembers] =
    useState<OperationalManagementTeamMemberResponse[]>();
  const [rentals, setRentals] = useState<RentalInformationsResponse[]>();

  const form = useForm<formType>({
    defaultValues: {
      memberId: "",
      prestaName: "",
      rentalIds: [],
      prestaDate: new Date(),
      changedBeds: 0,
      amount: 0,
    },
  });

  const membersHook = useOperationalManagementMember({
    onSuccess: (data) => {
      setMembers(data);
    },
    onError: () => {
      setError("Failed to load members");
    },
  });

  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [rentalError, setRentalError] = useState("");

  /**
   * Use effect for place default value in form
   * and recharge rental list for input select.
   */
  useEffect(() => {
    if (modal.data?.dayPaymentData) {
      let baseRentalIds: Array<string | number> = [];
      modal.data?.dayPaymentData?.rentals?.map((rental) => {
        baseRentalIds.push(rental.id);
      });

      form.setValue("memberId", modal.data?.dayPaymentData?.operator_id ?? "");
      form.setValue("prestaName", modal.data?.dayPaymentData?.name ?? "");
      form.setValue("rentalIds", baseRentalIds ?? []);
      form.setValue(
        "prestaDate",
        // @ts-ignore
        modal.data?.dayPaymentData?.end_date
          ? datetimeToDateObject(modal.data.dayPaymentData.end_date)
          : new Date(),
      );
      form.setValue(
        "changedBeds",
        modal.data?.dayPaymentData?.beds_changed_count ?? 0,
      );
      form.setValue("amount", modal.data?.dayPaymentData?.amount ?? 0);
    }

    membersHook.getAll();

    useRentalList(
      (rentals) => {
        setRentals(rentals);
      },
      (error) => {
        setError("Failed to load rentals");
      },
    );
  }, [modal]);

  /**
   * Function for submit new data to SuperHote API.
   * @param data
   */
  const handleSubmitForm = (data: formType) => {
    if (!modal.data?.dayPaymentData) return;

    /** Form checks values */
    const errors: Record<string, string> = {};
    form.clearErrors();
    setRentalError("");
    if (data.amount <= 0) {
      errors.amount = t("Management.Payments.formErrors.amountZero");
    }

    if (!data.prestaName.trim()) {
      errors.prestaName = t("Management.Payments.formErrors.required");
    }

    if (!data.memberId) {
      errors.memberId = t("Management.Payments.formErrors.required");
    }

    if (!data.prestaDate) {
      errors.prestaDate = t("Management.Payments.formErrors.required");
    }

    if (!data.rentalIds || data.rentalIds.length <= 0) {
      errors.rentalId = t("Management.Payments.formErrors.required");
      setRentalError(t("Management.Payments.formErrors.required"));
    }

    if (Object.keys(errors).length > 0) {
      Object.entries(errors).forEach(([key, message]) => {
        form.setError(key as keyof formType, { message });
      });
      return;
    }

    setIsLoading(true);
    updatePayment(
      modal.data?.dayPaymentData.id,
      data.memberId,
      data.prestaName,
      data.prestaDate,
      data.rentalIds,
      data.changedBeds,
      data.amount,
    )
      .then((response) => {
        handleUpdateSuccess();
        modal.close();
      })
      .catch((error: Error) => {
        setError(error?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <RightModal
        classNames={{ mainContentParent: "overflow-y-auto" }}
        title={t("Management.Payments.paidFor")}
        isVisible={modal.isVisible}
        onClose={modal.close}
      >
        <div className="flex justify-between flex-col w-full">
          <section id="form">
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <p className="text-xs text-gray-500 font-bold">
              {t("Global.info")}
            </p>

            {/* Member Select */}
            <div className="mt-4 flex flex-col gap-1">
              <Controller
                control={form.control}
                name="memberId"
                render={({ field: { value, onChange } }) => (
                  <div className="flex-1">
                    <InputSelect
                      selectedValue={value}
                      items={
                        members?.map((member) => {
                          return {
                            label: `${member.first_name} ${member.last_name}`,
                            value: member.id,
                          };
                        }) ?? []
                      }
                      onSelect={onChange}
                      label={t("Management.Payments.personToPaid")}
                      required={true}
                      error={form.formState.errors.memberId?.message}
                    />
                  </div>
                )}
              />
            </div>

            {/* Prestashop Name */}
            <div className="mt-4 flex flex-col gap-1">
              <Controller
                control={form.control}
                name="prestaName"
                render={({ field: { value, onChange } }) => (
                  <div className="flex-1">
                    <TextInput
                      onChangeText={onChange}
                      label={t("Global.name")}
                      required={true}
                      value={value}
                      error={form.formState.errors.prestaName?.message}
                    />
                  </div>
                )}
              />
            </div>

            {/* Rental Select */}
            <div className="mt-4 flex flex-col gap-1">
              <Separator />
              <Controller
                control={form.control}
                name="rentalIds"
                render={({ field: { value, onChange } }) => (
                  <div className="flex-1">
                    <p className={"font-bold"}>
                      {t("Management.Payments.rentalIs")}
                      <span className={"text-error font-normal"}> *</span>
                    </p>
                    <div className={"flex flex-col gap-2 mt-2"}>
                      {rentalError && (
                        <ErrorMessage errorType={"FORM"}>
                          {rentalError}
                        </ErrorMessage>
                      )}
                      {rentals?.map((rental) => {
                        const isChecked = value.includes(rental.id);
                        return (
                          <div
                            key={rental.id}
                            className="flex items-center gap-2"
                          >
                            <CheckBox
                              value={isChecked}
                              onChange={(checked) => {
                                if (checked) {
                                  onChange([...value, rental.id]);
                                } else {
                                  onChange(
                                    value.filter((id) => id !== rental.id),
                                  );
                                }
                              }}
                            />
                            <p
                              className="text-gray-500 cursor-pointer"
                              onClick={() => {
                                if (isChecked) {
                                  onChange(
                                    value.filter((id) => id !== rental.id),
                                  );
                                } else {
                                  onChange([...value, rental.id]);
                                }
                              }}
                            >
                              {rental.name}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              />
              <Separator />
            </div>

            {/* Date Picker */}
            <div className="mt-4 flex flex-col gap-1">
              <Controller
                control={form.control}
                name="prestaDate"
                render={({ field: { value, onChange } }) => (
                  <div className="flex-1">
                    <DatePickerInput
                      value={value}
                      onChange={onChange}
                      label={t("Management.Payments.prestaDate")}
                      required={true}
                      error={form.formState.errors.prestaDate?.message}
                    />
                  </div>
                )}
              />
            </div>

            {/* bed changed */}
            <div className="mt-4 flex flex-col gap-1">
              <Controller
                control={form.control}
                name="changedBeds"
                render={({ field: { value, onChange } }) => (
                  <div className="flex-1">
                    <NumberInput
                      value={value}
                      onChangeText={onChange}
                      label={t("Management.Payments.bedrooms")}
                      required={true}
                      error={form.formState.errors.changedBeds?.message}
                    />
                  </div>
                )}
              />
            </div>

            {/* Amount total */}
            <div className="mt-4 flex flex-col gap-1">
              <Controller
                control={form.control}
                name="amount"
                render={({ field: { value, onChange } }) => (
                  <div className="flex-1">
                    <NumberInput
                      value={value}
                      onChangeText={onChange}
                      label={t("Management.Payments.amount")}
                      required={true}
                      acceptDecimal={true}
                      error={form.formState.errors.changedBeds?.message}
                    />
                  </div>
                )}
              />
            </div>
          </section>
          <section
            id="buttons"
            className="flex items-center justify-between gap-2"
          >
            {!isLoading && (
              <>
                <Button onClick={modal.close} type="secondary">
                  {t("Global.cancel")}
                </Button>
                <Button onClick={modal.close} type="destructive">
                  {t("Global.remove")}
                </Button>
              </>
            )}
            <Button
              onClick={() => {
                handleSubmitForm(form.getValues());
              }}
              loading={isLoading}
            >
              {t("Global.record")}
            </Button>
          </section>
        </div>
      </RightModal>
    </>
  );
};
