import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      // WIP: {
      //   title: "Welcome to the New Version of SuperHote",
      //   content1:
      //     "We are excited to introduce Version 2 of SuperHote, currently under active development. Our team is committed to providing an enhanced experience with innovative features tailored to meet your needs. The construction of this new version is underway, and we are working diligently to deliver it to you as soon as possible.",
      //   content2:
      //     "Stay tuned! We will regularly keep you updated on the progress and upcoming new features. Your patience and support during this transition period are greatly appreciated.",
      //   content3: "See you soon for the SuperHote V2 adventure!",
      // },
      // Register: {
      //   Personnal: {
      //     title: "Create an account",
      //   },
      //   Billing: {
      //     title: "How many accommodation units do you manage ?",
      //     yearlyTitle: "Yearly Subscription",
      //     yearlyPrice: "{{monthlyPrice}} € / month",
      //     yearlyBonusPrice: "(2 months free) {{yearlyPrice}} € excl. tax",
      //     yearlyDescription: "14-day free trial",
      //     yearlyBonusItem1: "2 months free subscription",
      //     yearlyBonusItem2: "High-performance automatic booking site model",
      //     yearlyBonusItem3: "The 21-day challenge to boost your bookings",
      //     yearlyBonusItem4: "VIP onboarding with a solution expert",
      //     monthlyTitle: "Monthly subscription",
      //     monthlyPrice: "{{monthlyPrice}} € / month excl. tax",
      //     monthlyDescription: "14-day free trial",
      //     monthlyBonusItem1: "Availability & automated pricing - peace of mind",
      //     monthlyBonusItem2:
      //       "Centralised & automatic conversations, for an exceptional travel experience",
      //     monthlyBonusItem3:
      //       "Detailed performance analysis for continuous improvement",
      //     monthlyBonusItem4: "Unique traveller CRM to ensure loyalty",
      //     monthlyBonusItem5:
      //       "Complete direct booking system to break away from platforms",
      //     monthlyBonusItem6:
      //       "Upsell system & payment taking to increase your RevPar",
      //     monthlyBonusItem7:
      //       "Organising your team for smooth day-to-day management",
      //     monthlyBonusItem8: "Automatic generation of tasks and schedules",
      //     monthlyBonusItem9:
      //       "Your payments and guarantees, fully managed for you",
      //     monthlyBonusItem10:
      //       "Contract management, invoicing... and all your administrative tasks automated!",
      //     CB: "Card number",
      //     expiration: "Expiry date",a
      //     CCV: "GTC",
      //     submit: "Choose",
      //   },
      // },
      Tab: {
        guestPageTitle: "Page • voyageur",
      },
      Global: {
        ok: "OK",
        ko: "KO",
        back: "Go back",
        cancel: "Cancel",
        validate: "Validate",
        total: "Total",
        nights_one: "{{count}} night",
        nights_other: "{{count}} nights",
        adults_one: "{{count}} adult",
        adults_other: "{{count}} adults",
        children_one: "{{count}} kid",
        children_other: "{{count}} kids",
        search: "Rechercher",
        send: "Envoyer",
        validate: "Valider",
      },
      Booking: {
        DepositStatus: {
          unpaid: "Unpaid",
          overdue: "Overdue",
          canceled: "Canceled",
          preAuthorized: "Pre-authorized",
          released: "Pre-authorization released",
          captured: "Pre-authorization captured",
          paid: "Paid",
          notRefunded: "Not refunded",
          refunded: "Refunded",
          managedByPlatform: "Managed by the platform",
          notRequired: "Not required",
        },
        PaymentStatus: {
          unpaid: "Unpaid",
          inProgress: "Transfer made",
          paid: "Paid",
          overdue: "Overdue",
          partiallyPaid: "Partially paid",
          preAuthorized: "Pre-authorization",
          refunded: "Refunded",
          canceled: "Canceled",
        },
      },
      GuestPage: {
        Global: {
          guestPageDisable: "The reservation page is no longer accessible.",
          guestPageErrorLink: "The link is no longer valid.",
          videos: "Videos",
          photos: "Pictures",
        },
        Contact: {
          firstName: "First name",
          firstNameFieldLabel: "First name",
          lastName: "Name",
          email: "E-mail",
          lastNameFieldLabel: "Name",
          emailVisible: "visible",
          addContact: "Add a contact",
          emailNotVisible: "not visible",
          phoneVisible: "visible",
          phoneNotVisible: "not visible",
          title: "Contact",
          contactHost: "Contact your hosts",
          contactViaAirbnb: "Contact via Airbnb",
          contactViaWhatsapp: "Contact via Whatsapp",
          sendEmail: "Send an email",
        },
        Instruction: {
          content: "Content",
        },
        LoginDetails: {
          hello: "Welcome",
          descriptionAccess:
            "In order to access the check-in procedure and other information regarding your stay, we need some information.",
          language: "Your spoken language",
          infos: "Informations",
          email: "Email to contact you",
          emailPlaceholder: "Your email",
          phone: "Mobile phone to contact you",
          phonePlaceholder: "Your phone number",
          phonePlaceholderMobile: "Your phone",
          beds: "How much bed do you need?",
          bedsDetails:
            "If you need a travel cot, please do not include them and ask for them in the additional note.",
          checkinCheckout: "Arrival and departure",
          timeIn: "Your expected arrival time",
          timeOut: "Your scheduled departure time",
          timeInReached: "Arrival time must be after {{time_in}}",
          timeOutReached: "Departure time must be before {{time_out}}",
          successMessageTime:
            "Your arrival and departure times have been taken into account",
          fieldRequired: "The field is required",
          descriptionTimes:
            "You can declare your arrival and departure time later if you do not know yet.",
          filesRequired: "Mandatory documents",
          filesRequiredDescription:
            "Your host kindly asks you to add the following documents necessary for your stay:",
          card_id: "Identity card (front and back)",
          cardIdButtonLabel: "Add your ID card",
          cardIdModalTitle:
            "Instructions for importing the national identity card",
          cardIdDescription:
            "Please take a photo of the front and back of your national identity card.",
          passport: "Passport",
          passeportButtonLabel: "Add your passport",
          passeportModalTitle: "Notice for passport import",
          passeportDescription:
            "Please take a photo of your main page of your passport.",
          selfie: 'Photo "selfie"',
          selfieButtonLabel: 'Add your photo "selfie"',
          selfieModalTitle: 'Instructions for importing the photo "selfie"',
          selfieDescription:
            "Please take a selfie photo of yourself with your ID card/passport in your hand.",
          cb: "Bank card with hidden fields",
          cbButtonLabel: "Add your bank card",
          cbModalTitle: "Instructions for importing the bank card.",
          cbDescription:
            "Please take a photo of the front of your bank card, hiding the numbers.",
          police: "Police sheet",
          addFileBadge: "Added",
          addFileLabel: "Add photo",
          addFileRectoLabel: "Add the front",
          addFileVersoLabel: "Add the back",
          remarks: "Additional Notes",
          remarksPlaceholder: "Need a baby bed or something else?",
          additionalQuestionPlaceholder: "Your answer here",
          cguCheckbox: "By checking this box, I accept the",
          cgu: "General conditions of use",
          acceptCgu: "Please accept the general conditions of use",
          preview: "Preview",
          checkFile: "Check that",
          photoWellFramed: "The photo is well framed",
          noReflection: "There is no reflection",
          photoClearGoodQuality: "The photo is clear and of good quality",
          allInformationReadable: "All information is readable",
          nameMatchesReservation:
            "The first and last name correspond to those on the reservation.",
          cardFieldsHiddenNameVisible:
            "Card fields are hidden, but the name remains visible",
          faceVisibleNotHiddenByCard:
            "Your face is visible, not hidden by the card",
          cardYouAreHoldingIsReadable: "The card you are holding is readable",
          rulesFile: "Rules",
          acceptedFormatsPdfPngJpgJpeg:
            "Accepted formats: pdf, png, jpg and jpeg",
          acceptedFormatsPngJpgJpeg: "Accepted formats: png, jpg and jpeg",
          maxFileSize10Mb: "Maximum size per file: 10 MB",
          importFile: "Upload your photo below",
        },
        Contract: {
          info: "To ensure everything goes smoothly during your stay, please read and sign the following contract.",
          drawSignature: "Please draw your signature",
          again: "Start over",
          downloadContract: "Download the contract",
          modalTitle: "Contract signed",
          modalInfo: "Your contract has been signed",
          modalInfo2:
            "Download it and keep it for up to 15 days after your stay.",
          downloadContractSigned: "Download the signed contract",
          confirmContract: "Confirm the signature",
          guestPageErrorLink: "The link is no longer valid",
          viewGuestPage: "View the guest page.",
          Info: {
            dates: "Dates",
            guests: "Guests",
            recap: "Summary of the reservation amount",
            paymentDetailName: "Title",
            paymentDetailPrice: "Amount",
            total: "Total (EUR)",
            cancel: "Cancellation",
            deposit: "Deposit",
            firstName: "First Name",
            name: "Last Name",
            address: "Address",
            city: "City",
            postalCode: "Postal Code",
            country: "Country",
            email: "Email",
            phone: "Phone Number",
            guestType: "Guest Type",
            particular: "Individual",
            pro: "Company",
          },
        },
        Tab: {
          reservation: "Booking",
          extra: "Extras",
          payment: "Payments",
          contact: "Contact",
          contract: "Contract",
        },
        Reviews: {
          title: "Leave us a review!",
          writeReview: "Rate your stay",
          leaveAReview: "Leave a review",
          yourComment: "Your comment (Optional)",
          successMessage: "Your review has been successfully sent",
        },
        Wifi: {
          title: "WIFI connection",
          wifi_name: "Network",
          wifi_password: "Password",
          connect: "Log in",
          copy: "Copy",
        },
        Reservation: {
          thanks: "Thanks {{firstName}} !",
          helloFirstName: "Welcome {{firstName}}",
          dDay: "D-Day {{day}}",
          dayUntilReservation: "Only {{day}} days left before your stay.",
          daysUntilReservation: "Only {{day}} days left before your stay.",
          todayUntilReservation: "Your reservation is today",
          pastUntilReservation: "Your reservation has passed",
          title: "Your reservation",
          checkin: "Arrival",
          checkout: "Departure",
          between: "Between",
          and: "And",
          arrival_from: "From",
          departure_before: "Before",
          theDate: "on {{date}}",
          instructionCheckin: "Arrival instructions",
          instructionCheckout: "Exit Instructions",
          extras: "Need anything else?",
          extraSubTitle: "Add extras for an unforgettable stay.",
          welcomeGuide: "Welcome Guide",
          instructionsDetails: "See instructions in detail",
          codeBuilding: "Building entry code",
          codeKeys: "Key box code",
          codeDescription: "The codes below will be visible",
          codeDescription2: "ONLY IF",
          codeDescription3: "THE",
          codeDescription4: "arrival instructions",
          codeDescription5: "above are activated for the traveler.",
          goMaps: "Getting there",
          info: "Information",
          photosAndVideos: "Photos and videos",
        },
        InstructionCheckin: {
          title: "Arrival instructions",
          description: "So that the entrance goes perfectly",
          codeDescription:
            "The codes will be active from your scheduled arrival time.",
        },
        InstructionCheckout: {
          title: "Exit Instructions",
          description: "So that the exit goes perfectly",
        },
        WelcomeGuide: {
          title: "Welcome Guide",
          description: "The guide for your stay",
        },
        Extras: {
          validateExtras: "Validate my extras",
          displayCart: "See all extras",
        },
        ExtrasPayment: {
          goBack: "Go back",
          title: "My extras",
          elementInCart: "{{nb}} item in your cart",
          elementsInCart: "{{nb}} items in your cart",
          elementInCartEmpty: "No items in your cart",
          total: "Total",
          goBackShop: "Return to the store",
          preAuthorized: "Bank pre-authorization {{amount}}",
          preAuthorizedDescription:
            "You will only be charged if the host validates your extras.",
          validatePreAuthorized: "Validate pre-authorization",
          extraToValidateByHost:
            "Some products require verification of availability by your host.",
          cgv: "General conditions of sale",
          acceptCgv:
            "By providing your credit card information, you agree to the",
        },
        ExtraHistorical: {
          displayHistorique: "View my history",
          goBack: "Go Back",
          title: "My history",
          nbOrder: "{{nb}} order completed.",
          nbOrders: "{{nb}} orders completed.",
          nbOrderEmpty: "No orders placed.",
          commandeDate: "Order from {{date}}",
          idCommand: "Number {{numCommand}}",
          historical: "Order History",
        },
        Payment: {
          title: "Payments",
          subTitle: "Track your payments",
          deposit: "Deposit to be pre-authorized",
          bankImprint: "Bank imprint - expected on {{date}}",
          account: "Scheduled for {{date}}",
          payNow: "Pay now",
          displayInvoice: "See the booking invoice",
        },
        PaymentModal: {
          title: "Payment validated",
          description: "Your payment has been taken into account",
          preAuthTitle: "Pre-authorization validated",
          preAuthDescription1: "Your extra has been ordered.",
          preAuthDescription2:
            "If the host validates it, you will be charged and receive a validation message.",
          preAuthDescription3:
            "Otherwise, you will be kept informed of its unavailability.",
          button: "Ok",
        },
        Invoice: {
          title: "Invoice",
          yourInvoice: "Your invoice",
          name: "Titled",
          amount: "Amount",
          totalTTC: "Total including tax",
          quantity: "Quantity",
          totalHT: "Total excluding tax",
          tva: "VAT",
          guest: "Guest",
          updateInfo: "Edit billing information",
          download: "Download",
        },
      },
      GuestWebsite: {
        Header: {
          Nav: {
            rentals: "",
            services: "",
            about: "",
            contact: "",
          },
          titleRentals: "",
          subtitleRentals: "",
          titleServices: "",
          subtitleServices: "",
          titleAbout: "",
          subtitleAbout: "",
          titleContact: "",
          subtitleContact: "",
        },
      },
      Rental: {
        Infos: {
          TravelerPage: {
            WifiCard: {
              title: "WiFi Connection",
              wifiName: "Network",
              wifiPassword: "Password",
              wifiNamePlaceholder: "Wifi_{{hostname}}",
              wifiRequired: "Le nom du WiFi et le mot de passe sont requis.",
            },
            ExtraCard: {
              available_until: "Available from",
              duplicate: "Duplicate extra",
              sms: "SMS",
              mail: "Email",
              available: "Available",
              immediatelyAfterReservation: "Immediately after reservation",
              immediatelyAfterCheckin: "Immediately after check-in",
              onHourLaterCheckin: "1 hour after check-in",
              fourHourLaterCheckin: "4 hours after check-in",
              fourHourAfterCheckout: "4 hours after check-out",
              untilCheckout: "Until check-out",
              onDayAfterCheckin: "1 day after check-in",
              emptyExtra: "No extras added",
            },
          },
        },
      },
    },
  },
  fr: {
    translation: {
      WIP: {
        title: "Bienvenue sur la Nouvelle Version de SuperHote",
        content1:
          "Nous sommes ravis de vous présenter la V2 de SuperHote, actuellement en plein développement. Notre équipe s'engage à offrir une expérience améliorée, intégrant des fonctionnalités innovantes conçues pour répondre au mieux à vos besoins. La construction de cette nouvelle version est en cours, et nous mettons tout en œuvre pour vous la livrer dans les meilleurs délais.",
        content2:
          "Restez connectés ! Nous vous tiendrons régulièrement informés des avancées et des nouveautés à venir. Votre patience et votre soutien pendant cette période de transition sont très appréciés.",
        content3: "À très bientôt pour l'aventure SuperHote V2 !",
      },
      Global: {
        here: "ici",
        activateWithConfirmation: "Activer",
        addPhotoAndVideo: "Ajouter des photo ou vidéos ici",
        addFileHere: "Importer un fichier ici",
        wip: "En cours de développement, bientôt disponible",
        noPlatformsConnected: "Aucune plateforme connectée à SuperHote",
        currencySymbol: "€",
        percentSymbol: "%",
        returned: "Retour",
        leave: "Quitter",
        unlimited: "Illimité",
        notMessageUnread: "Aucun message non lu.",
        noConversationSelected:
          "Cliquez sur une conversation pour voir les messages.",

        unknownErrorPhoto:
          "Une erreur inconnue s'est produite, veuillez réessayer",
        deletePictureTitle:
          "Voulez-vous vraiment supprimer votre photo de profil ?",
        payment_one: "Paiement",
        payment_other: "Paiements",
        management: "Gestion opérationnelle",
        settings: "Paramètres",
        profil: "Profil SuperHote",
        user_information: "Informations de profil",
        deposit_one: "Caution",
        deposit_other: "Cautions",
        fees: "frais",
        previousStep: "Étape précédente",
        back: "Retour",
        platforms: "Intégrations tierces",
        from: "À partir du",
        to: "Jusqu'au",
        today: "Aujourd'hui",
        allRentalsSelected: "Tous les hébergements",
        noRentalSelected: "Aucun hébergement",
        rentals_one: "Hébergement",
        rentals_other: "Hébergements",
        bookings: "Réservations",
        search: "Rechercher",
        SMS: "SMS",
        email: "E-mail",
        reinitialize: "Réinitialiser",
        placeholderEmail: "adresse@email.com",
        password: "Mot de passe",
        placeholderPassword: "********",
        apply: "Appliquer",
        or: "ou",
        cancel: "Annuler",
        constructionTitle: "Fonctionnalité disponible prochainement",
        forbidden: "Accès interdit",
        forbiddenTitle: "Accès non autorisé",
        forbiddenContent: "Vous n'avez pas accès à cette page.",
        constructionContent:
          "Vous participez actuellement à la version Alpha de SuperHote V2. Cette fonctionnalité est en cours de développement et sera bientôt disponible.",
        canceled_male: "Annulé",
        canceled_female: "Annulée",
        edit: "Modifier",
        add: "Ajouter",
        integrate: "Intégrer",
        assign: "Assigner",
        remove: "Supprimer",
        refresh: "Rafraîchir",
        refund: "Rembourser",
        resend: "Renvoyer",
        refunded_male_one: "Remboursé",
        refunded_male_other: "Remboursés",
        refunded_female_one: "Remboursée",
        refunded_female_other: "Remboursées",
        clickHere: "Cliquez ici",
        removeWithConfirmation: "Oui, supprimer",
        cancelWithConfirmation: "Oui, annuler",
        verified: "J'ai vérifié",
        deactivateWithConfirmation: "Oui, désactiver",
        deactivateWithDelete: "Oui, supprimer",
        refundWithConfirmation: "Oui, rembourser",
        info: "Informations",
        title: "Titre",
        content: "Contenu",
        ok: "OK",
        ko: "KO",
        notDefined: "-",
        invalid: "Invalide",
        address: "Adresse",
        code: "Télécode",
        connect: "Connecter",
        validate: "Valider",
        finish: "Terminer",
        save: "Sauvegarder",
        record: "Enregistrer",
        send: "Envoyer",
        export: "Exporter",
        disconnect: "Déconnecter",
        desynchronize: "Désynchroniser",
        synchronize: "Synchroniser",
        payNow: "Payer maintenant",
        previous: "Précédent",
        nextStep: "Passer à l'étape suivante",
        confirmed: "Confirmée",
        notConfirmed: "Non confirmée",
        deactivate: "Désactiver",
        activate: "Activer",
        copy: "Dupliquer",
        configurate: "Configurer",
        total: "Total",
        displayUpTo: "Afficher jusqu'à",
        call: "Appel",
        yes: "Oui",
        no: "Non",
        and: "et",
        children: "Enfants",
        byNights: "/ nuits",
        nights_one: "{{count}} nuit",
        nights_other: "{{count}} nuits",
        shortHour: "{{hour}} h",
        noChildren: "- Enfant",
        hours: "Heures",
        days: "Jours",
        next: "Suivant",
        retry: "Réessayer",
        checkin_one: "Arrivée",
        checkin_other: "Arrivées",
        checkout_one: "Départ",
        checkout_other: "Départs",
        NA_male: "Aucun",
        NA_female: "Aucune",
        guests: "Voyageurs",
        all_male_one: "Tout",
        all_male_other: "Tous",
        all_female_one: "Toutes",
        all_female_other: "Toutes",
        unpaid_male: "Non payé",
        unpaid_female: "Non payée",
        overdue: "En retard",
        partiallyPaid_male: "Payé partiellement",
        partiallyPaid_female: "Payée partiellement",
        paid_male_one: "Payé",
        paid_male_other: "Payés",
        paid_female_one: "Payée",
        paid_female_other: "Payées",
        view: "Voir",
        required: "Requis",
        generate: "Générer",
        Units: {
          digits_one: "chiffre",
          digits_other: "chiffres",
        },
        infoTextSubmitButton: "Appuyez sur {{key}}",
        placeholderPassword: "Mot de passe",
        Keys: {
          Enter: "Entrée ⏎",
          Backspace: "Retour",
        },
        Errors: {
          minLengthField:
            "Le champ '{{fieldName}}' doit avoir une longueur minimum de {{length}} {{unit}}",
          maxLengthField:
            "Le champ '{{fieldName}}' doit avoir une longueur maximum de {{length}} {{unit}}",
          requiredField: "Le champ '{{fieldName}}' est requis",
          invalidField: "Le champ '{{fieldName}}' est invalide",
        },
        PasswordValidation: {
          min: "Votre mot de passe doit avoir plus de {{length}} caractères.",
          max: "Votre mot de passe doit avoir moins de {{length}} caractères.",
        },
        sortBy: "Trier par",
        duplicate: "Dupliquer",
        addPhoto: "Ajouter des photos ici",
        connected: "Connecté",
        contact: "Contacter",
        rental_count_one: "{{count}} hébergement",
        rental_count_other: "{{count}} hébergements",
        plus: "Plus",
        unknown_male: "Inconnu",
        unknown_female: "Inconnue",
        done: "Terminer",
        step: "Étape",
        hereItIs: "Et voilà ...",
        optionalStep: "Étape optionnelle",
        priceWithTaxes: "Total TTC",
        reservation: "Réservation",
        at: "à",
        night_one: "{{count}} nuit",
        night_other: "{{count}} nuits",
        adults_one: "{{count}} adulte",
        traveler_other: "{{count}} voyageurs",
        traveler_one: "{{count}} voyageur",
        adults_other: "{{count}} adultes",
        children_one: "{{count}} enfant",
        children_other: "{{count}} enfants",
        percentage: "Pourcentage",
        fixed: "Fixe",
        PlatformTooltipItems: {
          airbnb: "Ces données sont synchronisées automatiquement avec Airbnb",
          booking:
            "Ces données sont synchronisées automatiquement avec Booking",
          vrbo: "Ces données sont synchronisées automatiquement avec VRBO / Abritel",
          googleRentals:
            "Ces données sont synchronisées automatiquement avec Google Rentals",
          superhote:
            "Ces données sont synchronisées automatiquement avec Superhote et votre site de réservation en direct",
        },
        PaymentAccounts: {
          by_cash: "en espèces",
          by_check: "par chèque",
          by_transfer: "par virement",
          by_stripe: "par stripe",
          cash: "Espèces",
          check: "Chèques",
          bank_check: "Chèques",
          transfer: "Virement",
          stripe: "Stripe",
        },
        guest_one: "{{count}} voyageur",
        guest_other: "{{count}} voyageurs",
        bedroom_one: "{{count}} chambre",
        bedroom_other: "{{count}} chambres",
        bed_one: "{{count}} lit",
        bed_other: "{{count}} lits",
        bathroom_one: "{{count}} salle de bain",
        bathroom_other: "{{count}} salles de bains",
        reinit: "Réinitialiser",
        applyOnDays: "Applicable uniquement pour certains jours de la semaine",
        daysConcerned: "Jours concernés",
        applyOnOtherRentals: "Appliquer à d'autres hébergements",
        rentals: "Hébergements",
        rental: "Hébergement",
        filter: "Filtrer",
        payment: "Paiement",
        active_male: "Actif",
        active_female: "Active",
        inactive_male: "Inactif",
        inactive_female: "Inactive",
        securedTransaction: "Transaction sécurisée",
        nbKingBeds_one: "{{count}} x Lit King Size",
        nbKingBeds_other: "{{count}} x Lits King Size",
        nbQueenBeds_one: "{{count}} x Lit Queen Size",
        nbQueenBeds_other: "{{count}} x Lits Queen Size",
        nbDoubleBeds_one: "{{count}} x Lit Double",
        nbDoubleBeds_other: "{{count}} x Lits Double",
        nbSingleBeds_one: "{{count}} x Lit Simple",
        nbSingleBeds_other: "{{count}} x Lits Simple",
        nbChildrenBeds_one: "{{count}} x Lit Enfant",
        nbChildrenBeds_other: "{{count}} x Lits Enfants",
        nbInfantsBeds_one: "{{count}} x Lit Bébé",
        nbInfantsBeds_other: "{{count}} x Lits Bébé",
        nbSofaBeds_one: "{{count}} x Canapé lit",
        nbSofaBeds_other: "{{count}} x Canapés lit",
        nbWallBeds_one: "{{count}} x Lit Escamotable",
        nbWallBeds_other: "{{count}} x Lits Escamotable",
        nbMezzanineBeds_one: "{{count}} x Lit Mezzanine",
        nbMezzanineBeds_other: "{{count}} x Lits Mezzanine",
        noPaymentReservationTitle: "Aucun paiement de réservation",
        noPaymentReservationContent:
          " Aucun paiement de réservation n'est actuellement présent.",
        noCautionTitle: "Aucune caution existante",
        noCautionContent: "Aucune caution n'est actuellement présente.",
        noPaiementExtraTitle: "Aucun paiement extra existant",
        noPaiementExtraContent:
          "Aucun paiement extra n'est actuellement présent.",
        others_one: "{{count}} autre",
        others_other: "{{count}} autres",
        searchRental: "Recherchez un hébergement",
        automations: "Automatisations",
        update: "Modifier",
        rentals_guest: "Voyageur",
        rentals_reservation: "Réservation",
        rentals_payments: "Paiements",
        rentals_guest_page: "Page voyageur",
        rentals_rental: "Hébergement",
        rentals_custom: "Champs dynamiques personnalisés",
        yearInProgress: "Année en cours",
        resetFilter: "Réinitialiser les filtres",
        January: "Janvier",
        February: "Février",
        March: "Mars",
        April: "Avril",
        May: "Mai",
        June: "Juin",
        July: "Juillet",
        August: "Août",
        September: "Septembre",
        October: "Octobre",
        November: "Novembre",
        December: "Décembre",
        name: "Nom",
        confirmeDelete: "Oui, supprimer",
        downloadImg: "Télécharger l'image",
        checkinToCheckout: "Séjour du {{checkin}} au {{checkout}} ↗",
        stays: "Séjours",
        noReservations: "Aucun réservations.",
        active: "Activé",
        noActive: "Non activé",
        metadata: "Ajouter des informations additionnelles",
        contacts: "Contacts",
        sms: "SMS",
        lang: "Langue",
        subject: "Sujet",
        message: "Message",
        formatError: "Le format n'est pas valide.",
        valueRequired: "Valeur requise.",
        unactived: "Désactivé",
        errorMax: "La valeur maximum est de {{max}}",
        errorMin: "La valeur minimum est de {{min}}",
        book: "Réserver",
        maxGuest: "{{count}} personnes maximum",
        registerNumber: "Numéro d'enregistrement : ",
        deposit: "Caution",
        checkinHours: "Plage horaire pour les arrivées",
        checkoutHours: "Plage horaire pour les départs",
      },
      Filter: {
        reservationRequest: "Demande de réservation",
        apply: "Appliquer",
        upcomingStay: "Séjour à venir",
        stayEnded: "Séjour terminé",
        cancelByTaveler: "Annulé par le voyageur",
        preApprovedRequest: "Demande pré-approuvée",
        currentStay: "Séjour en cours",
        canceled: "Annulé",
        airbnb: "Airbnb",
        booking: "Booking",
        bookingEngine: "Site de réservation",
        abritel: "Abritel",
        filterByHebergement: "Filtrer par hébergement",
        filterByHebergementTitle:
          "Seuls les hébergements cochés seront visibles dans votre messagerie.",
        filtreAccommodationTitle: "Filtrer les hébergements",
        startDateTime: "Message reçu entre le ",
        endDateTime: "Et le ",
        filterBy: "Filtrer par",
        statutReservation: "Statut de la réservation",
        platformReservation: "Plateforme de réservation",
        filterByAccommodationTitle:
          "Seuls les hébergements cochés seront visibles dans votre messagerie.",
        docs: "Documents",
        doc: "Document",
      },
      Components: {
        DateFilter: {
          nextSevenDays: "7 prochains jours",
          lastFourWeeks: "4 dernières semaines",
          lastTreeMonths: "3 derniers mois",
          lastTwelveMonths: "12 derniers mois",
          notFiltered: "Non filtré",
          lastTwoWeeks: "Deux dernières semaines",
          lastMonth: "Mois dernier",
          lastThreeMonths: "Trois derniers mois",
          lastYear: "Année dernière",
          nextThirtyDays: "Prochains 30 jours",
          nextThreeMonths: "Trois prochains mois",
          nextYear: "Année prochaine",
          custom: "Personnalisée",
          currentMonth: "Mois en cours",
          nextMonth: "Mois prochain",
        },
        MultiFilterRentals: {
          title: "Filtrer les hébergements à afficher",
        },
        GuestFilters: {
          title: "Filtrer les voyageurs",
          spanFilter: "Filter par",
          stayCount: "Nombre de séjour effectué entre",
          and: "Et",
          totalAmountSpent: "Montant total dépensé entre",
          exportCsv: "Exporter en csv",
          exportPdf: "Exporter en pdf",
          exportXls: "Exporter en xls",
        },
      },
      Stripe: {
        noStripe: "Stripe n'est pas correctement chargé",
      },
      Login: {
        leftTitle: "Ravi de vous revoir !",
        leftSubTitle:
          "Gérez toutes vos annonces courte durée, automatisez vos tâches et booster vos revenus — et cela depuis une seule plateforme.",
        title: "Se connecter",
        subTitle1: "Bienvenue sur SuperHote !",
        subTitle2: "Vous n’avez pas de compte ?",
        register: "Créer un compte SuperHote",
        loginFacebook: "Se connecter avec Facebook",
        loginGoogle: "Se connecter avec Google",
        or: "ou",
        email: "E-mail",
        emailRequired: "L'adresse e-mail est requise.",
        emailInvalid: "L'adresse n'a pas le bon format.",
        password: "Mot de passe",
        passwordRequired: "Le mot de passe est requis.",
        passwordMin: "Le mot de passe doit faire au moins 8 caractères",
        submit: "Se connecter",
        forget: "Mot de passe oublié ?",
      },
      Settings: {
        subscription: "Abonnement",
        detailsFacturation: "Détails de facturation",
        yourSubscription: "Votre abonnement",
        title: "Paramètres",
        profil: "Profil",
        notifications: "Notifications",
        activities: "Activités",
        edit: "Modifier",
        edit_password: "Modifier votre mot de passe",
        status: "Statut",
        cost: "Coût par mois",
        accessAllFunctionality: "Accès à toutes les fonctionnalités",
        messageAutomatic: "Messages automatiques",
        supportAvailable: "Support dédié 24/7",
        connexionPlatformLabel: "Connexion à Airbnb, Booking",
        actif: "Actif",
        disabled: "Désactiver",
        enabled: "Activer",
        free: "Offert",
        planActuality: "Plan actuel",
        Accommodation: "Vos Hébergements",
        member_since: "Membre depuis ",
        superhoteAlphaTest: "Superhote Alpha Test",
        message1: "Vous êtes actuellement en version Alpha offerte",
        message2:
          "L’essai offert prendra fin lors de la sortie de la V2 de SuperHote",
        WorkspaceList: {
          title: "Espaces de travail",
          leave: "Quitter l'espace de travail",
        },
        LeaveWorkspace: {
          title: "Voulez-vous vraiment quitter cet espace de travail ?",

          content:
            "Cela aura pour effet de vous retirer de l'espace et des équipes de cet espace. Vous ne receverez plus les notifications et vous ne pourrez plus accéder à aucun élement de cet espace",
          button: "Oui, quitter",
        },
      },
      Register: {
        back: "Retour",
        PlatformText1: "Plateformes connectées",
        PlatformText2:
          "Nous remontons les plateformes connectées à vos hébergements.",
        connexionDeniedTitle: "Connexion à SuperHote V2  impossible.",
        connexionDeniedSubTitle1:
          "Vous devez posséder un lien d’invitation valide pour pouvoir vous enregistrer sur la phase Alpha de SuperHote V2.",
        connexionDeniedTitle2:
          "Vous pouvez contacter le support pour tenter d’obtenir un accès à la phase Alpha de SuperHote V2.",
        contactSupport: "Contacter le support",
        goBack: "Retour à l'accueil",
        carousel1Title:
          "Une communauté de +45 000 membres dans le monde entier",
        carousel1SubTitle:
          "Loueurs, sous-loueurs, conciergeries et agences s'entraident, grâce à SuperHote",
        carousel2Title:
          "+ 3500 propriétés connectées par les utilisateurs SuperHote",
        carousel2SubTitle:
          "Nous sommes compatibles avec les plateformes Airbnb, Booking.com ou Abritel.",
        carousel3Title:
          "Une plateforme adorée de ses membres, une expérience unique",
        carousel3SubTitle:
          "SuperHote est la plateforme de référence pour les loueurs courte-durée et conciergeries",
        step: "Étape {{currentStep}} / {{maxStep}}",
        typeSubTitle1:
          "Gérez toutes vos annonces courte durée, automatisez vos tâches et booster vos revenus — et cela depuis une seule plateforme.",
        typeSubTitle2:
          "Gérez toutes vos annonces courte durée, automatisez vos tâches et booster vos revenus — et cela depuis une seule plateforme.",
        typeSubTitle3:
          "Gérez toutes vos annonces courte durée, automatisez vos tâches et booster vos revenus — et cela depuis une seule plateforme.",
        typeSubTitle4:
          "Gérer les calendriers de vos annonces, automatisez vos tâches et booster vos revenus — et cela depuis une seule plateforme.",
        "1Step": "Votre activité",
        "2Step": "Vos plateformes",
        "3Step": "Votre mot de passe",
        "4Step": "Récapitulatif",
        "5Step": "Récapitulatif",
        Sum: {
          new: "Nouveau membre SuperHote",
          types: "Activités",
          investor: "Investisseur",
          sub: "Sous-loueur",
          janitorial: "Conciergerie",
          agency: "Agence",
          platforms: "Plateformes",
        },
        Personnal: {
          title: "Créer un compte",
          subTitle:
            "Tester SuperHote gratuitement pendant notre phase d'Alpha tests. Cet accès restera gratuit jusqu'au lancement de la V2 complète de l'outil.",
          alreadyAnAccount: "Vous avez déjà un compte ?",
          login: "Connectez-vous",
          registerFacebook: "S'inscrire avec Facebook",
          registerGoogle: "S'inscrire avec Google",
          or: "ou",
          firstName: "Prénom",
          lastName: "Nom",
          email: "E-mail",
          code: "Numéro de téléphone",
          submit: "Rejoindre SuperHote",
          acceptGCUandPPandEthic:
            "En cochant cette case,j'accepte les <gcu>Conditions Générales d'Utilisation</gcu>, la <ethic>Charte éthique</ethic>  et la <pp>Politique de Confidentialité</pp> de SuperHote",
          firstNameError: "Le prénom est requis",
          lastNameError: "Le nom est requis",
          emailError: "L'adresse email est requise",
          emailErrorInvalid: "L'adresse email est requise",
          phoneError: "Le numéro de téléphone est requis",
          phoneErrorInvalid: "Le numéro de téléphone est invalide",
          acceptError: "Veuillez accepter les conditions",
          phone: "Numéro de téléphone",
        },
        Type: {
          checkboxesError: "Sélectionnez au moins une activité",
          hello: "Bienvenue",
          title: "Parlez-nous un peu de vous",
          text1: "Qu'est-ce qui décrit le mieux votre activité ?",
          text2:
            "Cela nous aidera à mieux personnaliser votre expérience sur SuperHote en mettant en évidence les fonctionnalités pertinentes.",
          submit: "Étape suivante",
          select: "Sélectionnez-en autant que nécessaire",
          investor: "Investisseur",
          investorRealEstate: "Investisseur en immobilier",
          sub: "Sous-loueur",
          janitorial: "Conciergerie",
          agency: "Agence immobilière",
          typesError: "Veuillez préciser au moins une activité",
        },
        Platform: {
          platformError: "Sélectionnez au moins une plateforme",
          title:
            "Très bien c’est noté, quelles plateformes de réservation utilisez-vous actuellement pour votre activité ?",
          subTitle:
            "Nous ne prenons pas nécessairement en charge toutes les plateformes de réservation, n’hésitez pas à compléter avec lesquelles vous travaillez.",
          submit: "Étape suivante",
          select: "Plateformes que vous pouvez connecter sur SuperHote",
          airbnb: "Airbnb",
          booking: "Booking.com",
          vrbo: "Abritel/ VRBO (à venir)",
          other: "Je souhaite renseigner une autre plateforme",
          addOther:
            "Ces plateformes ne sont pas encore connectés avec Superhote, mais cela nous permettra de prioriser leur intégration en fonction de vos besoins.",
          add: "Ajouter une autre plateforme",
          platformName: "Nom de la plateforme",
          availableAlphaTest: "(Disponible pour  L'Alpha Test)",
          unavailableAlphaTest: "(Non disponible pour  L'Alpha Test)",
          maxOther: "Vous avez atteint le nombre maximum de plateformes",
          invalidPlatformError:
            "Ajoutez au moins une autre plateforme pour continuer.",
        },
        Range: {
          title:
            "Dernière question, combien de biens gérez-vous dans le cadre de votre activité ?",
          submit: "Étape suivante",
          select: "Choisissez une option",
          "1-5rentals": "Entre 1 et 5 biens",
          more5rentals: "Plus de 5 biens",
        },
        Billing: {
          title: "Combien d’hébergements gérez-vous ?",
          yearlyTitle: "Abonnement Annuel",
          yearlyPrice: "{{monthlyPrice}} € / mois",
          yearlyBonusPrice: "(2 mois offert) {{yearlyPrice}} € HT",
          yearlyDescription: "Essai gratuit de 14 jours",
          yearlyBonusItem1: "2 mois d’abonnement offert",
          yearlyBonusItem2:
            "Modèle de site de réservation automatique ultra-performant",
          yearlyBonusItem3:
            "Le challenge 21 jours pour booster vos réservations",
          yearlyBonusItem4: "Onboarding VIP avec un expert de la solution",
          monthlyTitle: "Abonnement Mensuel",
          monthlyPrice: "{{monthlyPrice}} € / mois HT",
          monthlyDescription: "Essai gratuit de 14 jours",
          monthlyBonusItem1:
            "Disponibilité & prix automatisé — de quoi avoir l’esprit tranquille",
          monthlyBonusItem2:
            "Conversations centralisées & automatiques, pour une expérience voyageur exceptionnelle",
          monthlyBonusItem3:
            "Analyse fine des performances pour vous améliorer en continue",
          monthlyBonusItem4:
            "CRM voyageur unique qui s’assurer de les fidéliser",
          monthlyBonusItem5:
            "Système complet de réservation en direct pour s’émanciper des plateformes",
          monthlyBonusItem6:
            "Système d’upsell & prise de paiement pour augmenter votre RevPar",
          monthlyBonusItem7:
            "Organisation de votre équipe pour une gestion fluide au quotidien",
          monthlyBonusItem8: "Génération automatique des tâches & plannings",
          monthlyBonusItem9:
            "Vos paiements & cautions, entièrement gérés pour vous",
          monthlyBonusItem10:
            "Gestions des contrats, facturation... et tout votre administratif automatisé !",
          CB: "Numéro de carte",
          expiration: "Date d'expiration",
          CCV: "CCV",
          submit: "Choisir",
        },
        Password: {
          title:
            "Parfait, finalisons la création de votre compte en créant un mot de passe sécurisé.",
          required: "Mot de passe requis",
          min: "Votre mot de passe doit avoir plus de {{length}} caractères.",
          max: "Votre mot de passe doit avoir moins de {{length}} caractères.",
          info: "Votre mot de passe doit comporter au moins 8 caractères et contenir au minimum une majuscule, un chiffre et un caractère spécial pour être valide.",
          invalidFormat:
            "Votre mot de passe  ne doit pas contenir des espaces pour être valide.",
          create: "Créer votre mot de passe",
          notMatch:
            "Votre confirmation ne correspond pas à votre mot de passe.",
          confirm: "Confirmez votre mot de passe",
          submit: "Étape suivante",
          placeholder: "P4ss-sh-wOrd!",
        },
        Thanks: {
          title: "Bienvenue sur SuperHote",
          objectiveTitle: "Quels sont vos objectifs principaux ?",
          subTitle1:
            "Nous sommes ravis de vous voir rejoindre notre plateforme. Nous savons que gérer une activité de locations courte durée est difficile.",
          subTitle2:
            "C'est pourquoi SuperHote est là pour vous aider. Les loueurs en courte durée SuperHote bénéficient de notre technologie de pointe pour gagner du temps et booster leurs revenus.",
          subTitle3:
            "Si vous avez 1 minute, dites-nous en plus sur votre utilisation future de Superhote !",
          submit: "Découvrir SuperHote",
          configure: "Configurer SuperHote avec un membre de l'équipe",
          paymentSuccess: "Paiement réussi",
          usageTitle: "Comment avez-vous connu Superhote ?",
          choice1: "Choisir",
          bySearch: "Recherche en ligne",
          byReference: "Référence d'un ami ou d'un collègue",
          byAdvertising: "Publicité en ligne",
          socialNetwork: "Réseaux sociaux",
          byEvent: "Lors d'un événement ou salon professionnel",
          byWordOfMouth: "Bouche à oreille",
          byEmail: "Par e-mail via la newsletter",
          byOther: "Autre",
          choose: "Choisir",
          UsageError: "Veuillez sélectionner une option",
          Objectives: {
            moreReservations: "Obtenir plus de réservations",
            saveTime: "Gagner du temps",
            automateRentalBusiness:
              " Automatiser mon activité de loueur en courte durée",
            becomePlatformIndependent: "Dévenir indépendant des plateformes",
            exchangeWithOtherRentalBusiness:
              "Échanger avec d'autres loueurs en courte durée",
            other: "Autre",
            otherValuePlaceholder: "Précisez vos objectifs",
            otherValueError: "Veuillez précisez vos objectifs",
            objectivesError: "Veuillez renseigner vos objectifs",
          },
        },
      },
      RegisterMember: {
        Infos: {
          title: "Créer un compte SuperHote",
          subTitle:
            "Rejoignez votre espace prestataire sur SuperHote gratuitement.",
          email: "E-mail",
          firstName: "Prénom",
          firstNameRequired: "Prénom requis",
          lastName: "Nom",
          lastNameRequired: "Nom requis",
          company: "Entreprise",
          phone: "Numéro de téléphone",
          phoneRequired: "Numéro de téléphone requis",
          address: "Adresse",
          city: "Ville",
          zip: "Code postal",
          country: "Pays",
          language: "Langage pour communication",
          currency: "Devise pour paiement",
          acceptError: "Veuillez accepter les conditions",
        },
        Password: {
          title:
            "Parfait, finalisons la création de votre compte en créant un mot de passe sécurisé.",
          button: "Créer mon compte",
        },
        Denied: {
          title: "Ce lien d’invitation n’est plus valide.",
          content1:
            "Vous pouvez vous connectez-vous avec vos identifiants si vous avez déjà un compte.",
          content2:
            "Si vous pensez que c’est une erreur, vous pouvez contacter le support.",
          contact: "Contacter le support",
          login: "Se connecter",
        },
        Accepted: {
          title:
            "Voulez-vous accepter la demande d'invitation pour l'espace de {{firstName}} {{lastName}} ?",
          content:
            "Vous serez ajouté dans l'espace de {{firstName}} {{lastName}} et vous aurez accès aux tâches de cet espace.",
          refuse: "Refuser",
          accept: "Rejoindre l'espace",
        },
      },
      Recover: {
        leftTitle1: "Mot de passe oublié ?",
        leftTitle2: "Aucun soucis !",
        leftSubTitle: "Suivez les étapes et retrouvez l’accès à votre compte.",
        title: "Mot de passe oublié",
        subTitle:
          "Tapez l’adresse e-mail que vous avez utilisée pour vous inscrire sur SuperHote et nous vous enverrons un e-mail de réinitialisation de mot de passe.",
        email: "E-mail",
        emailRequired: "L'adresse e-mail est requise",
        emailInvalid: "L'adresse e-mail n'a pas le bon format",
        submit: "Envoyer un email de récupération",
        phoneRequired: "Le numéro de téléphone est obligatoire.",
        phoneInvalid: "Veuillez entrer un numéro de téléphone valide.",
        phoneAlreadyExists: "Ce numéro de téléphone est déjà utilisé.",
      },
      RecoverThanks: {
        title: "Mot de passe oublié",
        calloutTitle: "E-mail de réinitialisation envoyé",
        calloutMessageP1:
          "Un e-mail contenant un lien afin de réinitialiser votre de mot de passe vient d’être envoyé à",
        calloutMessageP2: "pensez à consulter vos spams.",
        login: "Se connecter",
      },
      Reset: {
        title: "Réinitialisation de votre mot de passe",
        subTitle: "Tapez et confirmez votre nouveau mot de passe.",
        min: "Votre mot de passe doit avoir plus de 8 caractères.",
        max: "Votre mot de passe doit avoir moins de 24 caractères.",
        required: "Le nouveau mot de passe est requis",
        minLength: "Le nouveau mot de passe doit faire au moins 8 caractères",
        maxLength:
          "Le nouveau mot de passe doit faire au maximum 24 caractères",
        rule1Title: "Minimum 8 caractères",
        rule2Title: "Au moins un chiffre",
        rule3Title: " Au moins un caractère spécial",
        rule4Title: " Au moins une majuscule",
        rule5Title: " Au moins une minuscule",

        invalidFormat:
          "Votre mot de passe doit contenir au minimum une majuscule, un chiffre et un caractère spécial pour être valide.",
        create: "Créer votre nouveau mot de passe",
        notMatch: "Votre confirmation ne correspond pas à votre mot de passe.",
        confirm: "Confirmez votre mot de passe",
        submit: "Enregister",
      },
      Tab: {
        calendarTitle: "Calendrier • SuperHote",
        calendarMonthTitle: "Calendrier mensuel • SuperHote",
        calendarYearTitle: "Calendrier annuel • SuperHote",
        dashboardTitle: "Dashboard • SuperHote",
        dashboardServicesTitle: "Prestations • SuperHote",
        booking: "Réservations • SuperHote",
        rental: "Hébergements • SuperHote",
        payment: "Paiements • SuperHote",
        message: "Messages • SuperHote",
        settings: "Paramètres • SuperHote",
        registerTitle: "Inscription • SuperHote",
        errorTitle: "Erreur• SuperHote",
        subscriptionTitle: "Abonnement • SuperHote",
        profilTitle: "Profil • SuperHote",
        helpTitle: "Aide • SuperHote",
        loginTitle: "Connexion • SuperHote",
        reservationTitle: "Réservation • SuperHote",
        addReservationTitle: "Ajouter une réservation • SuperHote",
        forgotPasswordTitle: "Mot de passe oublié • SuperHote",
        resetPasswordTitle:
          "Réinitialisation de votre mot de passe • SuperHote",
        bookingMessageTitle: "Messagerie • SuperHote",
        bookingInvoiceTitle: "Paiements et factures • SuperHote",
        bookingContractTitle: "Contrats et documents • SuperHote",
        bookingResourceTitle: "Ressources • SuperHote",
        bookingTaskTitle: "Tâches • SuperHote",
        messageListTitle: "Messagerie • SuperHote",
        autoMessageTitle: "Message automatisé • SuperHote",
        shotCodeTitle: "Champs dynamiques • SuperHote",
        analyticsTitle: "Statistiques • SuperHote",
        analyticsDetailsTitle: "Statistiques • Détails • SuperHote",
        analyticsEvaluationsTitle: "Statistiques • Évaluations • SuperHote",
        analyticsObjectConnectedTitle:
          "Statistiques • Objets connectés • SuperHote",
        analyticsContenderTitle: "Statistiques • Concurence • SuperHote",
        addRentalTitle: "Ajouter un hébergement • SuperHote",
        rentaListTitle: "Liste des hébergements • SuperHote",
        rentaTitle: "Hébergement • SuperHote",
        paymentReservationTitle: "Paiements • SuperHote",
        paymentDepositTitle: "Cautions • SuperHote",
        paymentExtraTitle: "Extras • SuperHote",
        paymentAccountTitle: "Moyens d'encaissement • SuperHote",
        paymentInvoiceTitle: "Factures • SuperHote",
        paymentActivityTitle: "Activité • SuperHote",
        settingsProfilTitle: "Profil • SuperHote",
        depositTitle: "Caution • SuperHote",
        depositPreAuthorizationTitle: "Pré-autorisation de caution • SuperHote",
        progressionTitle: "Votre progression • SuperHote",
        progressionRentalRuleTitle: "Règles de progression • SuperHote",
        guestTitle: "Voyageurs • SuperHote",
        platformTitle: "Intégrations tierces • SuperHote",
        managementTitle: "Gestion opérationnelle • SuperHote",
        managementTasksTitle: "Gestion des tâches • SuperHote",
        managementAutomationsTitle: "Automatisations • SuperHote",
        managementPaymentsTitle: "Paiements • SuperHote",
        managementActivityTitle: "Activité • SuperHote",
        settingTitle: "Paramètres • SuperHote",
        settingSubscriptionTitle: "Abonnement • SuperHote",
        settingFacturationTitle: "Détails de facturation • SuperHote",
        settingNotificationTitle: "Notifications • SuperHote",
        settingActivitiesTitle: "Activités • SuperHote",
        settingsProfil: "Profil • SuperHote",
        registerMemberTitle: "Inscription • SuperHote",
        guestPageTitle: "Page • voyageur",
        guestWebsiteTitle: "Site de réservation",
        websitesPageTitle: "Site de réservations • SuperHote",
      },
      ResetThanks: {
        title: "Réinitialisation de votre mot de passe",
        subTitle: "Tapez et confirmez votre nouveau mot de passe.",
        calloutTitle: "Mot de passe réinitialisé avec succès !",
        submit: "Connectez-vous à SuperHote",
      },
      Menu: {
        subscription: "Abonnement",
        activities: "Activités",
        dashboard: "Tableau de bord",
        dashboardReservations: "Réservations",
        dashboardServices: "Vos prestations",
        calendars: "Calendriers",
        bookings: "Réservations",
        rentals: "Hébergements",
        activity: "Activité",
        payments: "Paiements",
        paymentsReservations: "Réservations",
        paymentsDeposits: "Cautions",
        paymentExtras: "Extras",
        paymentAccounts: "Moyens d'encaissement",
        paymentInvoices: "Factures",
        paymentActivities: "Activité",
        messages: "Messages",
        autoMessages: "Messages automatisés",
        shortcode: "Champs dynamiques",
        management: "Gestion opérationnelle",
        tasks: "Tâches",
        teams: "Équipes",
        automations: "Automatisations",
        notifications: "Notifications",
        analytics: "Statistiques",
        analyticsGeneral: "Général",
        analyticsDetails: "Détaillé",
        analyticsEvaluations: "Évaluations",
        analyticsObjectConnected: "Objets connectés",
        analyticsContender: "Concurrence",
        platforms: "Intégrations tierces",
        booking_site: "Site de réservation",
        guests: "Voyageurs",
        settings: "Paramètres",
        settingsProfil: "Profil",
        help: "Aide",
        all: "Tous",
        analyticsSummary: "Synthèse annuelle",
        progression: "Votre progression",
        logOut: "Se déconnecter",
        websites: "Site de réservations",
      },
      BookingList: {
        title: "Réservations",
        noBookingTitle: "Aucune réservation détectée",
        noBookingSubTitle:
          "Vérifiez que vous êtes bien connecté à une des plateformes disponibes ou ajoutez manuellement une réservation sur un hébergement existant.",
        addBooking: "Ajouter une réservation",
        connectPlatform: "Connecter une plateforme",
        rental: "Hébergement",
        guest: "Voyageur",
        checkin: "Arrivée",
        checkout: "Départ",
        bookingStatus: "Statut réservation",
        paymentStatus: "Statut paiement",
        search: "Rechercher une réservation",
        displayUpTo: "Afficher jusqu'à",
        paging5: "5 réservations",
        paging10: "10 réservations",
        notConfirmed: "Non confirmée",
        notPaid: "Non payée",
        preConfirmed: "Pré-confirmée",
        paid: "Payée",
        cancel: "Annulée",
        adults_one: "{{count}} adulte",
        adults_other: "{{count}} adultes",
        children_one: "{{count}} enfant",
        children_other: "{{count}} enfants",
        confirmed: "Confirmé",
        preApprouved: "Demande Pré-approuvée",
        toArrive: "À venir",
        error: "Erreur",
        canceled: "Annulée",
        bookingDate: "Date de réservation",
      },
      Profil: {
        timeZone: "Fuseau horaire",
        languageForCommunication: "Langue de communication",
        country: "Pays",
        email: "E-mail",
        postal_code: "Code postal",
        city: "Ville",
        address: "Adresse",
        phone: "Numéro de téléphone",
        firstName: "Prénom",
        lastName: "Nom",
        yourActivity: "Votre activité",
        platformsUsed: "Plateformes utilisées",
        updatePasswordTitle: "Modifier votre mot de passe",
        validate: "Valider",
        oldPasswordLabel: "Ancien mot de passe",
        oldPasswordPlaceholder: "Votre ancien mot de passe",
        newPasswordLabel: "Nouveau mot de passe",
        newPasswordPlaceholder: "Votre nouveau mot de passe",
        confirmPasswordLabel: "Confirmer le nouveau mot de passe",
        confirmPasswordPlaceholder: "Confirmer de votre  nouveau mot de passe",
        newPasswordInfo:
          "Votre mot de passe doit comporter au moins 8 caractères et contenir au minimum une majuscule, un chiffre pour être valide",
        newPasswordRequired: "Le nouveau mot de passe est requis",
        newPasswordMin:
          "Le nouveau mot de passe doit faire au moins 8 caractères",
        newPasswordMax:
          "Le nouveau mot de passe doit faire au maximum 24 caractères",
        newPasswordInvalidFormat:
          "Le nouveau mot de passe doit contenir au moins une majuscule, un chiffre pour être valide.",
        PasswordNotMatch:
          "Votre confirmation ne correspond pas à votre mot de passe.",
        phoneNumber: "Numéro de téléphone",
        Administrative: {
          title: "Administratif",
          hostType: "Type d'hôte",
          particular: "Particulier",
          pro: "Professionnel",
          Particular: {
            hostName: "Nom de l'hôte",
            taxIdentityNumber: "Numéro d'identification fiscal",
          },
          Pro: {
            companyName: "Raison sociale (nom complet de l'entreprise)",
            siren: "SIREN",
          },
        },
      },
      Booking: {
        title: "Réservations",
        details: "Détails",
        messages: "Messagerie",
        paymentsAndInvoices: "Paiements et reçus",
        payments: "Paiements",
        contracts: "Contrats et documents",
        checklists: "Tâches",
        activity: "Activités",
        contact: "Contacter",
        reservationLink: "Lien de la réservation",
        guestpageLink: "Lien de la page voyageur",
        guestContact: "Contacter le voyageur",
        guest: "Voyageur",
        edit: "Modifier",
        email: "E-mail",
        phone: "Numéro de téléphone",
        timeZone: "Fuseau horaire",
        rate: "Avis",
        booking: "Réservation",
        confirmed: "Confirmé",
        canceled: "Annulées",
        canceledByTraveler: "Annulée voyageur",
        noShow: "No show",
        request: "Demandes de réservation",
        unconfirmed: "Non confirmé",
        preApprouved: "Demande Pré-approuvée",
        reference: "Référence",
        nbGuests: "Nombre de voyageurs",
        checkin: "Arrivée",
        checkout: "Départ",
        nbNights_one: "Nombre de nuit",
        nbNights_other: "Nombre de nuits",
        total: "Total réservation",
        labelAddNote: "Qu’avez-vous à dire à propos de cette réservation?",
        addNote: "Ajouter",
        privateNote:
          "Cette note est privée et n’est pas partagée avec les voyageurs.",
        deposit: "Caution",
        payment: "Paiement",
        contract: "Contrats",
        paid: "Payé",
        received: "Reçu",
        signed: "Signé",
        historical: "Historique",
        statusConfirmed: "Confirmé",
        statusPending: "En attente",
        save: "Enregistrer",
        firstName: "Prénom",
        lastName: "Nom",
        language: "Langue",
        notes: "Notes privées",
        nbAdults: "Nombre d'adultes",
        nbChildren: "Nombre d'enfants",
        checkinDate: "Date d'arrivée",
        checkinTime: "Heure d'arrivée",
        checkoutDate: "Date de départ",
        checkoutTime: "Heure de départ",
        priceByNight: "Prix par nuité",
        cleaning: "Frais de ménage",
        tax: "Taxe de déjour",
        commission: "Commissions",
        serviceFees: "Frais de service",
        otherFees: "Autres frais",
        status: "Statut",
        address: "Adresse",
        zipCode: "Code postal",
        city: "Ville",
        country: "Pays",
        view: "Voir",
        sendContract: "Envoyer le contract",
        sendInvoice: "Envoyer la facture",
        adults: "Adultes",
        children: "Enfants",
        nights: "Nuits",
        addTask: "Créer une tâche",
        addPayment: "Créer un paiement",
        timeIn: "Heure d'arrivée",
        timeOut: "Heure de départ",
        automatedMessages: "Messages automatisés",
        timezone: "Fuseau horaire",
        coming: "À venir",
        finished: "Terminées",
        progress: "En cours",
        link: "Lien de réservation",
        reservationPrice: "Montant de la réservation",
        checkinCheckout: "Arrivée et départ",
        journey: "Séjour",
        tasks: "Tâches",
        totalForGuest: "Payé par le voyageur : {{total}}{{currency}}",
        totalForHost: "Versé à l'hôte : {{total}}{{currency}}",
        paymentsInOne:
          "Le séjour sera payé en une fois au moment de la réservation, par Stripe.",
        paymentsInTwo:
          "Le séjour sera payé en deux au moment de la réservation et {{days}} jours avant l’arrivée, par Stripe.",
        paymentsInMultiple:
          "Le séjour sera payé en {{number}} fois au moment de la réservation et {{days}} jours avant l’arrivée, par Stripe.",
        msgAutoWithPayment:
          "Un message automatisé avec le lien du paiement sera envoyé au voyageur juste après la réservation.",
        msgAutoWithPaymentNumber:
          "Un message automatisé avec le lien du paiement {{number}} sera envoyé au voyageur {{days}} jours avant l'arrivée, le {{date}} à {{hours}}",
        cautionParam:
          "Une caution de {{money}} est demandée, sous la forme d’une pré-autorisation bancaire.",
        msgAutoWithDate:
          "Un message automatisé avec le lien du paiement sera envoyé au voyageur un jour avant son arrivée, le {{date}} à {{hours}}.",
        cancelCondition: "Conditions d'annulation",
        daysNoRefund: "Le séjour n’est pas remboursable.",
        daysRefund: "Le séjour est remboursable.",
        intitule: "Intitulé",
        amount: "Montant",
        quantity: "Quantité",
        totalHt: "total HT",
        tva: "TVA",
        ttc: "Total TTC",
        daysWith: "Séjour du {{dateOne}} au {{dateTwo}}",
        menageFees: "Frais de ménage",
        guestFees: "Fraus de service voyageur",
        totalEur: "Total (EUR)",
        airbnbFees: "Frais de service hote Airbnb",
        hotePay: "Versement de l'hôte",
        ticketNight: "Reçu Nuitées",
        ticketExtra: "Reçu Extras",
        ticketExtraAndNight: "Reçu Nuitées et Extras",
        paymentNumberWithDate: "Paiement {{number}} - Le {{date}}",
        paymentLinknumber: "Lien du paiement {{number}}",
        sendTicket: "Envoyer le reçu",
        downloadTicket: "Télécharger le reçu",
        managedBy:
          "Le paiement du séjour est géré par la plateforme tierce directement.",
        cautionManagedBy: "Gérée par la plateforme",
        cautionDirectlyManaged:
          "La caution est gérée directement par la plateforme.",
        thisDate: "Le {{date}}",
        via: "via",

        CancelCard: {
          title: "Annulation de la réservation",
          button: "Annuler la réservation",
        },

        PaymentStatus: {
          unpaid: "Non payé",
          inProgress: "Virement effectué",
          paid: "Payé",
          overdue: "En retard",
          partiallyPaid: "Payé partiellement",
          preAuthorized: "Pré-autorisation",
          refunded: "Remboursé",
          canceled: "Annulé",
        },

        PaymentExtraStatus: {
          unpaid: "Non payé",
          inProgress: "Virement effectué",
          paid: "Payé",
          overdue: "En retard",
          partiallyPaid: "Validé partiellement",
          preAuthorized: "À valider",
          refunded: "Remboursé",
          canceled: "Annulé",
        },

        ReservationStatus: {
          reservationRequest: "Demande de réservation",
          stayOver: "Séjour terminé",
          currentStay: "Séjour en cours",
          upcomingStay: "Séjour à venir",
          canceled: "Annulée",
          canceledByGuest: "Annulée par le voyageur",
          preApprovedRequest: "Demande pré-approuvée",
        },

        DepositStatus: {
          unpaid: "Non payée",
          overdue: "En retard",
          canceled: "Annulée",
          preAuthorized: "Pré-autorisée",
          released: "Pré-autorisée libérée",
          captured: "Pré-autorisée encaissée",
          paid: "Payée",
          notRefunded: "Non remboursée",
          refunded: "Remboursée",
          managedByPlatform: "Gérée par la plateforme",
          noCaution: "Non demandée",
          notRequired: "Non requis",
        },

        Reservation: {
          title: "Réservation",
          reservationStatusLabel: "Statut de réservation",
          nbAdultsLabel: "Nombre d'adultes",
          nbChildrenLabel: "Nombre d'enfants",
          paymentStatusLabel: "Séjour",
          checkinCheckoutTitle: "Arrivée et départ",
          checkinLabel: "Arrivée",
          checkoutLabel: "Départ",
          cleaningLabel: "Ménage",
          contractLabel: "Contrat",
          Filters: {
            filterPlatform: "Filtrer par plateforme de réservation",
            filterRentals: "Filtrer par hébergement",
            airbnb: "Airbnb",
            website: "Site de réservation",
            booking: "Booking.com",
            manual: "Réservation manuelles",
            otherIcal: "Autres réservations Ical",
          },
        },

        Guest: {
          title: "Voyageur",
          photoLabel: "Photo",
          firstNameLabel: "Prénom",
          firstNamePlaceholder: "Saisissez votre prénom",
          lastNameLabel: "Nom",
          lastNamePlaceholder: "Saisissez votre nom",
          addressLabel: "Adresse",
          addressPlaceholder: "Saisissez votre adresse",
          cityLabel: "Ville",
          cityPlaceholder: "Saisissez votre ville",
          postalCodeLabel: "Code postal",
          postalCodePlaceholder: "Saisissez votre code postal",
          countryLabel: "Pays",
          countryPlaceholder: "Saisissez votre pays",
          hiddenInformation: "Informations masquées par le voyageur",
          ratingNotDefined: "Aucun avis disponible",
          emailLabel: "E-mail",
          emailPlaceholder: "Saisissez votre email",
          phoneNumberLabel: "Numéro de téléphone",
          phoneNumberPlaceholder: "Saisissez votre numéro de téléphone",
          timezoneLabel: "Fuseau horaire",
          languagesSpokenLabel: "Langues parlées",
          languagesSpokenPlaceholder: "Saisissez votre langue",
          guestTitleEmpty: "Aucun voyageur dans votre base de voyageurs.",
          guestContentEmpty:
            "Vous n’avez actuellement aucun voyageur. Ajoutez-en manuellement ou obtenez une première réservation pour ajouter des voyageurs.",
          addTextButton: "Ajouter un voyageur",
          search: "Rechercher un voyageur",
          addTraveler: "Ajouter un voyageur",
          profilLabel: "Profil",
          travelerType: "Type de voyageur",
          businessType: "Entreprise",
          guestType: "Particulier",
          detailTraveler: "Remarques sur le voyageur",
          addTravelerConfirm: "Ajouter le voyageur",
          updateTravelerConfirm: "Modifier le voyageur",
          days: "Sejours",
          reviews: "Recommandable",
          allSpend: "Total dépensé",
          confirmDelete: "Voulez-vous vraiment supprimer ce voyageur ?",
          confirmDeleteDescription:
            "Cela entrainera la suppression du voyageur de SuperHote ainsi que ses éléments associés.",
          confirmMultipleDelete:
            "Voulez-vous vraiment supprimer ces voyageurs ?",
          confirmMultipleDeleteDescription:
            "Cela entraînera la suppression des voyageurs de SuperHote ainsi que de leurs éléments associés.",
          addressRequired: "L'adresse est requis.",
          zipRequired: "Code postal requis.",
          cityRequired: "Ville requise.",
          countryRequired: "Pays requis.",
          timeZoneRequired: "TimeZone requise.",
          currencyRequired: "Devise requise.",
          businessName: "Nom de l'entreprise",
          businessNameRequired: "Nom de l'entreprise requis.",
          invoiceAddressRequired: "Adresse de facturation requise.",
          invoiceAddress: "Adresse de facturation",
          siret: "SIRET",
          siretRequired: "SIRET requis.",
          languageCommunication: "Langage pour communication",
          languageRequired: "Language requis.",
          travelerSelected: "voyageur sélectionné(s)",
          fusion: "Fusionner",
          mergeTitle: "Fusionner les voyageurs",
          traveler: "Voyageur",
          addTo: "ajouté le",
          mergeOneToTwo: "Fusionner en gardant les champs du voyageur 1",
          mergeTwoToOne: "Fusionner en gardant les champs du voyageur 2",
          mergeMostRecent: "Fusionner en gardant les champs les plus récents",
          accountAdd: "Compte ajouté le",
          updateTraveler: "Modifier un voyageur",
          review: "Note du voyageur",
          invoiceAddressInvalid: "Veuillez entrer une adresse valide.",
          invoiceAddressInvalidDynamic:
            "L'adresse saisie n'est pas reconnue. Veuillez vérifier.",
          zipCode: "Code postal",
          zipCodeRequired: "Le code postal est obligatoire.",
          zipCodeInvalid: "Veuillez entrer un code postal valide.",
          zipCodeInvalidDynamic:
            "Le code postal saisi est invalide. Veuillez vérifier.",
        },

        Note: {
          title:
            "Remarques sur la réservation et autres informations plateforme",
          label:
            "Les remarques ci-dessous ne seront pas visibles par les voyageurs.",
        },

        ExtrasOrder: {
          title: "Commandes d'extras",
          order: "Commande {{uuid}}",
          orderDate: "Le {{date}} à {{hour}}",
          valid: "Validé",
          toValidate: "À valider",
          noExtras: "Aucune commande d'extra pour cette réservation.",
          ExtraOrderModal: {
            context: "Contexte",
            extrasInOrder: "Extras dans la commande",
            total: "Montant total",
            toRefund: "Rembourser",
            available: "Disponible",
            unavailable: "Non disponible",
            refunded: "Remboursé",
            displayDetails: "Voir en détail",
            info: "Informations",
            email: "Email",
            paidAt: "Payé le",
            extraToRefundDescription:
              "Si finalement un extra n’est pas disponible ou que vous ne pouvez pas vous occuper de cet extra, cliquez sur “Rembourser”.",
            extraAvailableDescription:
              "Si les extras sont disponibles, vous pouvez juster cliquer sur “Valider”. Cela activera la pré-autorisation du voyageur et vous recevrez l’argent.\n\nSi l'un des extras n’est pas disponible ou que vous ne pouvez pas vous occuper de cet extra, désactivez l’option “Disponible” puis validez. Vous ne recevrez alors que le montant correspondant aux extras disponible.",
            extraUnavailableTitle: "Extra non disponible",
            extraUnavilableDescription:
              "La pré-autorisation du voyageur a été désactivée. Il a reçu une notification d’annulation d’extra.",
            PopUp: {
              refundTitle: "Rembourser l'extra ?",
              refundDescription:
                "Cela aura pour effet de rembourser le voyageur du montant de {{value}} € sur son compte.",
              confirmRefund: "Oui, rembourser",
              extraUnavailableTitle:
                "Attention ! Aucun extra n’est disponible.",
              extraUnavailableDescription:
                "Cela aura pour effet de désactiver totalement la pré-autorisation bancaire du voyageur.",
              confirmExtraUnavailable: "Oui, aucun extra n’est disponible",
              oneExtraUnavailableTitle:
                "Attention ! Vous avez sélectionné l’indisponibilité d’au moins un extra.",
              oneExtraUnavailableDescription:
                "Cela aura pour effet de facturer au voyageur seulement le montant des extras disponibles. Êtes vous certains de votre choix ?",
              confirmOneExtraUnavailable:
                "Oui, certains extras sont indisponibles",
            },
          },
        },

        PlatformNote: {
          title: "Remarques complémentaires du voyageur",
          bedsCount_zero:
            "Le voyageur a declaré avoir besoin de {{count}} lit.",
          bedsCount_one: "Le voyageur a declaré avoir besoin de {{count}} lit.",
          bedsCount_other:
            "Le voyageur a declaré avoir besoin de {{count}} lits.",
          bedsCountInfo:
            "(Information récupérée depuis le formulaire de la page voyageur)",
        },

        Payment: {
          description: "Description",
          creation: "Création",
          bookingStatus: "Statut",
          total: "Total",
        },

        Locks: {
          title: "Serrures connectées et code d'accès",
          noLocks:
            "Aucune serrure connectée ou code d'accès renseigné pour cet hébergement",
          addLock: "Ajouter une serrure",
          accessCode: "Code d'accès",
          fixedCode: "Code fixe",
          dynamicCode: "Code dynamique",
          alwaysActive: "Actif tout le temps",
          staticLockType: "Serrure Manuelle",
          lockType: "Serrure {{name}}",
          activeTime:
            "Actif du <strong>{{date}}</strong> à <strong>{{hour}}</strong>",
          untilTime:
            "Jusqu'au <strong>{{date}}</strong> à <strong>{{hour}}</strong>",
        },

        Status: {
          canceled: "Annulée",
          canceledByTraveler: "Annulée par le voyageur",
          request: "Demande de réservation",
          preApprouved: "Demande pré-approuvée",
          coming: "Séjour à venir",
          finished: "Séjour terminé",
          progress: "Séjour en cours",
        },

        Summary: {
          title: "Récapitulatif",
        },

        PaymentAndDepositLink: {
          title: "Liens de paiement et caution",
          depositLabel: "Lien de Caution",
          paymentLabel: "Lien de paiement {{number}}",
          sendDeposit: "Envoyer la caution",
          sendPayment: "Envoyer le paiement",
        },

        Invoice: {
          title: "Factures",
        },

        removeAutoMessageTitle: "Supprimer le message automatique",
        removeAutoMessageContent:
          "Le message automatique sera supprimé pour cette réservation.",
        copyAutoMessageTitle: "Message automatique dupliqué",
        copyAutoMessageContent:
          "Le message automatique a été dupliqué pour cette réservation.",
        resourceType: "Type de document",
        receivedDate: "Date de réception",
        actions: "Actions",
        viewResource: "Voir",
        rental: "Hébergement",
        creationDate: "Date de création",
        taskType: "Type",
        addResource: "Ajouter une ressource",
        informations: "Informations",
        policies: {
          direct: {
            titleModal: "Paramètrer la politique du site de réservation",
            help: "Cette politique sera celle utilisée pour les réservations venant du site de réservation.",
            calendar: "Calendrier de paiement",
            paymentUnique: "Paiement unique",
            multiplePayment: "Paiement en plusieurs fois",
            inReservation: "Lors de la réservation",
            paymentMethod: "Moyen d'encaissement",
            fiducial: "Espèce",
            time: "Quand ?",
            cancelCondition: "Conditions d'annulation",
            nonRefund: "Non remboursable",
            refund: "Remboursable",
            caution: "Caution ou dépôt de garantie",
            required: "Obligatoire",
            noRequired: "Non obligatoire",
            type: "Type d'encaissement du dépôt de garantie",
            stripeInfo:
              "Une pré-autorisation dure 7 jours au maximum. Faites attention à bien être couvert jusqu'à la fin du séjour.\n" +
              "\n" +
              "Pour activer jusqu'à 30 jours de caution, rendez-vous directement sur Stripe ",
            is: "ici",
            amount: "Montant",
            stripeAccountChoice: "Choix du compte Stripe",
            dayBeforeArrival: "Jours avant l'arrivée",
            toPay: "À payer",
            percent: "Pourcentage remboursable",
            ifCancel: "Si annulation",
            payment: "Paiement {{number}}",
            typePayment: "Type de montant",
            howMuch: "Combien ?",
            percentage: "Pourcentage",
            addPayment: "Ajouter un paiement",
            sumPercentageError:
              "La somme en pourcentages de vos paiements est déjà à 100%.",
          },
          booking: {
            help: "Cette politique sera celle utilisée pour les réservations venant de Booking.com",
            helpCancel:
              "Vous devez choisir au moins une annulation possible. Vous pouvez en activer autant que vous le souhaitez.",
            noRefund: "Non-remboursable",
            noRefundHelp:
              "Les voyageurs paient 10 % de moins, mais votre versement est maintenu quelle que soit leur date d'annulation",
            refund5day:
              "Remboursable partiellement jusqu'à 5 jours avant l'arrivée",
            refund5dayHelp:
              "Les voyageurs reçoivent un remboursement de 50% en cas d'annulation jusqu'à 5 jours avant l'arrivée",
            refund1day:
              "Remboursable partiellement jusqu'à 1 jours avant l'arrivée",
            refund1dayHelp:
              "Les voyageurs reçoivent un remboursement de 50% en cas d'annulation jusqu'à un jours avant l'arrivée",
            refundAll14daysOr48h:
              "Remboursable totalement jusqu'à 14 jours avant l'arrivée ou 48h après la réservation",
            refundAll14daysOr48hHelp:
              "Les voyageurs paient 10% de plus, et reçoivent un remboursement intégrals'ils annulents dans les 48 heures qui suivent la réservation et au moins 14 jours avant l'arrivée",
            caution: "Caution",
            direct: "Gérée en direct",
            platform: "Gérée sur Booking.com",
          },
        },
        suiviePayment: "Suivi des paiements et de la caution",
        recapPayment: "Récapitulatif du paiement du séjour",
        recus: "Reçus",
        paymentSend: "Le lien du paiement à été envoyé",
        receiptSend: "Le ticket de reçu à été envoyé avec succées",
        sendCaution: "Envoyer la caution",
        sendPayment: "Envoyer le paiement",
      },
      MessageList: {
        title: "Messages",
        noConversations: "Aucune conversation disponible",
        noConvTitle: "Aucune conversation disponible",
        noConvSubTitle:
          "Vérifiez que vous avez des réservations synchronisées avec SuperHote ou ajoutez-en manuellement",
        noConvSelectedTitle: "Aucune conversation sélectionnée",
        noConvSelectedSubTitle:
          "Sélectionnez une conversation avec l’un de vos voyageurs",
        search: "Rechercher",
        all: "Tous",
        unread: "Non-lus",
        navigateBookings: "Consulter les réservations",
        statusSuccess: "Réservation confirmée",
        statusPending: "Demande de réservation",
        statusError: "Réservation annulée",
        autoTranslation: "Conversation automatiquement traduite",
        detectedLanguage: "Langue détectée",
        viewOriginal: "Voir l'original",
        automated: "Message automatique",
        typeYourMessage: "Tapez ici votre message",
        automatedMessages: "Super IA",
        templates: "Modèles",
        send: "Envoyer",
        viewBookingDetails: "Détails de la réservation",
        internalNote: "Note interne",
        you: "Vous",
        noMessages: "Il n'y a pas de message pour cette réservation",
        otherInformations: "Autres informations plateforme",
        remarks:
          "Remarques sur la réservation et autres informations plateforme",
        checkinAt: "Arrivée le",
        checkoutAt: "Départ le",
        at: "à",
        filtreMessageTitle: "Filtrer les messages",
        messageInfo1:
          "Réservation manuelle: les messages envoyés ici seront transmis par e-mail au voyayeur via une adreese SuperHote noreply.",
        messageInfo2:
          "Dans le cadre de l'alpha, les réponses par e-mail du voyageur ne sont pas affichés dans cette messagerie.",
      },
      UserExperience: {
        title: "Messages automatisés",
        search: "Rechercher un message automatique",
        information: "En savoir plus",
        addAutoMessage: "Créer un message automatisé",
        status: "Statut",
        name: "Nom du message automatisé",
        trigger: "Déclenchement",
        rentals: "Hébergements",
        platforms: "Plateformes",
        channel: "Canal",
        actions: "Actions",
        edit: "Éditer",
        duplicate: "Dupliquer",
        test: "Tester",
        delete: "Supprimer",
        displayUpTo: "Afficher jusqu'à",
        paging5: "5 messages automatisés",
        paging10: "10 messages automatisés",
        noMessageTitle: "Aucun message automatisé",
        noMessageSubTitle:
          "Vous n'avez pour l'instant aucun message automatisé. Prennez du temps pour en ajouter et créez une expérience  incroyable pour vos voyageurs.",
      },
      AutoMessageUpsert: {
        titleAdd: "Ajouter un message automatisé",
        titleEdit: "Éditer le message automatisé",
        general: "Général",
        name: "Nom du message",
        placeholderName: "Message automatique de confirmation",
        trigger: "Déclenchement",
        triggerNewBooking: "Nouvelle réservation",
        delay: "Délais",
        delayNow: "Immédiat",
        channel: "Canal",
        channelEmail: "E-mail",
        message: "Message",
        rentals: "Hébergements",
        rental: "Hébergement",
        platforms: "Plateformes",
        searchRental: "Rechercher un hébergement",
        placeholderSearchRental: "Appartement paris",
        next: "Étape suivante",
        submitAdd: "Ajouter le message automatisé",
        submitEdit: "Modifier le message automatisé",
        french: "Français",
        english: "English",
        italian: "Italiano",
        auto: "Auto",
        subject: "Sujet",
        placeholderSubject: "Titre de votre message",
        translateTitle: "SuperHote traduit automatiquement vos messages",
        translateSubTitle:
          "Écrivez votre message dans la langue de votre choix, il sera traduit dans la langue préférée des voyageurs. Vous pouvez visualiser les différentes versions et les modifier.",
        titleShortCode: "Champs dynamiques",
        shortCodeInfosTitle: "Fonctionnement des champs automatiques",
        shortCodeInfos:
          "Les champs dynamiques permettent d'insérer automatiquement des informations personnalisées dans vos messages automatisées dans vos messages automatisés, comme le prénom du voyageur avec <strong>[GUEST_FIRSTNAME]</strong> par exemple. Ainsi, vous gagnez du temps en créant des messages adaptables qui s'ajustent à chaque réservation sans effort supplémentaire.",
        shortCodeName: "Champ dynamique",
        shortCodeDesc: "Description",
        shortCodeAction: "Action",
        insert: "Insérer",
        searchShortCode: "Rechercher un champ dynamique",
        placeholderSearchShortCode: "Nom du champ dynamique",
        successAddTitle: "Votre message automatisé a été ajoutée",
        successEditTitle: "Votre message automatisé a été modifiée",
        successP1:
          "Vous pouvez le retrouver dans votre tableau des messages automatisés.",
        successP2:
          "Afin de permettre au voyageur d'avoir une expérience incroyable n'oubliez pas de le tester et de l'activer.",
        successTest: "Tester votre message automatisé",
      },
      AutoMessageList: {
        title: "Messages automatisés",
        edit: "Modifier",
        delete: "Supprimer",
        duplicate: "Dupliquer",
        activities: "Activités",
        search: "Rechercher un message automatisé",
        knowMore: "En savoir plus",
        activeSuggestionAutomatedMessage: "Activer les messages recommandés",
        add: "Créer un message automatisé",
        platform: "Plateforme",
        email: "Email",
        autoMessage: "Message automatique",
        Filter: {
          preCheckin: "Avant séjour",
          postCheckin: "Pendant séjour",
          paymentsDeposits: "Paiements et cautions",
          postCheckout: "Post-séjour",
          all: "Tous",
        },
        Suggestion: {
          immediately: "Immédiatement après nouvelle réservation",
          minus_1_days: "1 jour avant arrivée dans l’hébergement",
          plus_2_hours: "2 heures après sortie de l'hébergement",
          minus_12_hours: "12 heures avant sortie de l'hébergement",
          minus_2_hours: "2 heures avant arrivée dans l'hébergement",
          title:
            "Nous vous suggérons d'activer les messages automatisés suivants",
          description:
            "N’hésitez pas à les modifier selon vos besoins. Pensez à compléter le bloc <strong>Informations Voyageurs</strong> dans l’onglet <strong>Page Voyageur</strong> de vos hébergements, afin que les voyageurs puissent avoir accès à toutes les informations clés pour leur séjour.",
          tutorialTitle:
            "Tuto: Qu'est ce que la page Voyageur et comment l'utiliser ?",
          sendBy: "Envoie via",
          messageTitle: "Message qui sera envoyé au voyageur",
          personalizedMessage:
            "Personnaliser votre message grâce aux champs dynamiques disponibles ↗",
          activateMessage: "Activer ce message automatisé",
          next: "Message suivant",
          previous: "Message précédent",
          messageInfo:
            "Ces messages seront envoyés sur les canaux suivants, selon la source de la réservation :",
          cancel: "Je ne souhaite pas activer ces messages automatisés",
          validate_one: "Valider",
          validate: "Valider",
          validate_other: "Valider ({{count}} messages automatisés activés) ",
          emptyMessage: "Tous les contenus doivent être remplis.",
          number_message_active: "messages automatisés activés activés",
        },
        status: "Statut",
        name: "Nom du message automatisé",
        trigger: "Déclenchement",
        rentals: "Hébergements",
        channel: "Canal",
        actions: "Actions",
        allRentals: "Tous",
        noRentals: "Aucun",
        otherRentals_one: "{{count}} autre",
        otherRentals_other: "{{count}} autres",
        Trigger: {
          newReservation: "Nouvelle réservation",
          cancelReservation: "Annulation de la réservation",
          reservationRefunded: "Paiement de la réservation remboursée",
          checkin: "Arrivée dans l'hébergement",
          firstNightStay: "Première nuit passée",
          guestPageViewed: "Page voyageur visualisée",
          guestFormViewed: "Formulaire page voyageur rempli",
          guestReviewDone: "Avis laissé par le voyageur",
          tempHigh: "Température trop élevée",
          tempLow: "Température trop basse",
          co2High: "CO2 trop élevé",
          co2Low: "CO2 trop bas",
          soundHigh: "Volume trop élevé",
          contractViewed: "Contrat visualisé",
          contractSigned: "Contrat signé",
          cautionViewed: "Caution visualisée",
          cautionPaid: "Caution payée",
          paymentLinkViewed: "Lien de paiement visualisé",
          paymentLinkPaid: "Paiement effectué",
          checkout: "Sortie de l'hébergement",
          reservation: "A la réservation",
        },
        Conditions: {
          reservation_not_paid: "Réservation non payée totalement",
          reservation_paid: "Réservation payée totalement",
          caution_paid: "Caution payée",
          caution_not_paid: "Caution non payée",
          guest_contract_signed: "Contrat signé",
          guest_contract_not_signed: "Contrat non signé",
          guest_form_completed: "Formulaire page voyageur complété",
          guest_form_not_completed: "Formulaire page voyageur non complété",
          reservation_single_payment: "Réservation payée en une fois",
          reservation_two_payments: "Réservation payée en 2 fois",
          reservation_three_payments: "Réservation payée en 3 fois",
        },
        Delay: {
          immediately: "Immédiatement",
          minus5Minutes: "5 minutes avant",
          minus30Minutes: "30 minutes avant",
          minus1Hour: "1 heure avant",
          minus3Hours: "3 heures avant",
          minus5Hours: "5 heures avant",
          minus12Hours: "12 heures avant",
          minus1Day: "1 jour avant",
          minus2Days: "2 jours avant",
          minus3Days: "3 jours avant",
          minus5Days: "5 jours avant",
          minus7Days: "7 jours avant",
          minus10Days: "10 jours avant",
          minus20Days: "20 jours avant",
          minus30Days: "30 jours avant",
          minus60Days: "60 jours avant",
          plus5Minutes: "5 minutes après",
          plus30Minutes: "30 minutes après",
          plus1Hour: "1 heure après",
          plus3Hours: "3 heures après",
          plus5Hours: "5 heures après",
          plus12Hours: "12 heures après",
          plus1Day: "1 jour après",
          plus2Days: "2 jours après",
          plus3Days: "3 jours après",
          plus5Days: "5 jours après",
          plus7Days: "7 jours après",
          plus10Days: "10 jours après",
          plus20Days: "20 jours après",
          plus30Days: "30 jours après",
          plus60Days: "60 jours après",
        },
        RemoveModal: {
          title: "Voulez-vous vraiment supprimer ce message automatisé ?",
          content:
            "Cela aura pour effet de supprimer les informations et les messages programmés associés",
          button: "Oui, supprimer",
        },
        DuplicateModal: {
          title: "Voulez-vous dupliquer ce message automatisé ?",
          content:
            "Cela aura pour effet de créer un nouveau message automatisé avec les mêmes paramètres.",
          button: "Oui, dupliquer",
        },
        Upsert: {
          addTitle: "Créer un message automatisé",
          editTitle: "Éditer le message automatisé",
          save: "Enregister",
          add: "Créer",
          General: {
            title: "Déclencheur et conditions",
            infoTextPaymentOrDeposit1:
              "Le message <strong>Envoi du lien de paiement de la caution</strong> sera envoyé automatiquement au voyageur pour tous les séjours, <strong>à la date prévue pour le paiement de la caution.</strong>",
            infoTextPaymentOrDeposit2:
              "Cette date peut varier selon la plateforme de la réservation, et selon la politique de paiement renseignée.",
            infoTextPaymentOrDeposit3:
              "Par exemple, si vous avez prévue dans votre politique de paiement que la caution sera à payer 3 jours avant l'arrivée, dans ce cas, le message sera envoyé 3 jours avant l'arrivée des voyageurs, à 12h00.",
            name: "Nom du message",
            namePlaceholder: "Le nom du message",
            nameError: "Nom du message requis",
            usage:
              "Le message Instructions d'arrivée sera envoyé automatiquement au voyageur pour tous les séjours",
            usage1: "24 heures avant l'arrivée dans l'hébergement.",
            trigger: "Déclenchement",
            delay: "Délai",
            conditions: "Conditions",
            conditionsError: "Au moins une condition doit être sélectionnée",
            rentals: "Ce message automatisé est :",
            allRentals: "Pour tous les hébergements",
            fewRentals: "Spécifique à des hébergements",
          },
          Contents: {
            title: "Message",
            french: "Français",
            contentLangRequired:
              "Le contenu est obligatoire dans au moins une langue",
            frenchRequired: "Le français est obligatoire",
            english: "English",
            spanish: "Espagnol",
            german: "Deutsch",
            subject: "Objet",
            subjectPlaceholder: "Objet du mail",
            subjectInfos:
              "Non affiché si vous envoyez par SMS ou via les plateformes.",
            message: "Message",
            messagePlaceholder: "Contenu du message",
            shortcodes: "Afficher la liste des champs dynamiques disponibles",
          },
          Channels: {
            title: "Canal et plateformes",
            infos:
              "Choisissez le canal d'envoi de votre message automatisé pour chaque plateforme de réservation. Si vous ne souhaitez pas envoyer ce message pour une plateforme en particulier, désactivez-là.",
            airbnbBooking: "Réservation via Airbnb",
            bookingBooking: "Réservation via Booking",
            email: "E-mail",
            directBooking: "Réservation en direct",
            sendBy: "Envoyer via",
            optionToActive: "Option à activer",
            byPlatform: "Messagerie plateforme",
            byAirbnb: "Messagerie Airbnb",
            byBooking: "Messagerie Booking.com",
          },
        },
        CheckBanner: {
          title: "Activez un message automatisé",
          content:
            "Vous pouvez à présent créer, éditer et activer des messages automatisés pour vos voyageurs.",
          button: "J'ai compris",
        },
      },
      ScheduledMessageList: {
        title: "Messages automatisés programmés",
        see: "Voir",
        reSend: "Réenvoyer",
        scheduledTo: "Programmé pour le",
        edit: "Modifier",
        cancel: "Ne pas envoyer",
        sendCanceled: "Envoi annulé",
        reEnable: "Réactiver l'envoi",
        errorMessage: "Erreur lors de l'envoi du message",
        ReSend: {
          title: "Envoyer ce message à nouveau",
          content:
            "Cela enverra ce message à nouveau. Il s'enverra dans les minutes qui suivent.",
          send: "Envoyer",
        },
        Cancel: {
          title: "Voulez-vous vraiement annuler l'envoi de ce message ?",
          content:
            "Cela aura pour effet de ne pas envoyer le message. Vous pourrez toujours le renvoyer plus tard.",
          cancel: "Annuler l'envoi",
        },
        Enable: {
          title: "Réactiver l'envoi de ce message",
          description: "Paramétrer la date d'envoi de ce message automatique",
          schedule: "Programmer l'envoi le",
          scheduleError: "Heure invalide",
          save: "Enregistrer",
          formErrorDay: "Renseigner une date ultérieure au {{value}}",
          formErrorTime: "Renseigner une heure ultérieure à {{value}}",
          formErrorMissing: "Renseigner une date et une heure",
        },
        Edit: {
          title: "Éditer ponctuellement le message automatisé",
          infoTitle:
            "La modification ne portera que sur cette occurence du message automatisé, pour ce voyageur",
          infoDescription:
            "Pour modifier ce message automatisé pour ce voyageur et pour tous les suivants, modifiez le message automatisé à sa source :",
          infoButton: "Modifier le message automatisé",
          content: "Message",
          contentError: "Le message est requis",
          schedule: "Programmer l'envoi le",
          showShortcode: "Afficher la liste des champs dynamiques disponibles",
          formErrorDay: "Renseigner une date ultérieure au {{value}}",
          formErrorTime: "Renseigner une heure ultérieure à {{value}}",
          formErrorMissing: "Renseigner une date et une heure",
        },
        NoData: {
          title: "Aucun message automatique programmé",
          content1: "Ajoutez-en depuis la section ",
          content2: "Messages automatisés ",
          content3: "de SuperHote",
        },
      },
      ShortcodeList: {
        title: "Champs dynamiques",
        shortcode: "Champ dynamique",
        name: "Champs dynamique",
        description: "Description",
        actions: "Actions",
        all: "Tous",
        default: "Champs dynamiques par défaut",
        custom: "Champs dynamiques personnalisés",
        add: "Créer un champ dynamique personnalisé",
        RemoveModal: {
          title: "Voulez-vous vraiment supprimer ce champ dynamique ?",
          content:
            "Cela aura pour effet de le rendre non fonctionnel dans les messages automatisés et les modèles de messages.",
          button: "Oui, supprimer",
        },
        Upsert: {
          addTitle: "Nouveau champ dynamique personnalisé",
          editTitle: "Modifier le champ dynamique",
          name: "Nom",
          namePlaceholder: "Votre nom de champ dynamique",
          nameError: "Le nom est requis",
          description: "Description",
          descriptionPlaceholder: "Description de votre champ dynamique",
          askIsGeneric: "Ce champ dynamique est :",
          isGeneric: "Générique",
          isSpecific: "Différent par hébergement",
          genericContent: "Contenu du champ dynamique générique",
          genericContentError: "Le contenu est requis",
          genericContentPlaceholder:
            "Contenu du champ dynamique pour tous les hébergements",
        },
        reservation: {
          all_door_codes:
            "Liste de l’ensemble des codes serrures de l’hébergement (Nom des serrures + codes)",
        },
      },
      Analytics: {
        title: "Statistiques",
        nightsStats: "Statistiques sur les nuitées",
        allRentals: "Tous les hébergements",
        export: "Exporter",
        brutRevenues: "Revenus brut des réservations pour ",
        revenue: "Chiffres d'affaires brut",
        revenue_single: "Chiffre d'affaires brut",
        ca: "Chiffre d'affaires bruts",
        revenueBrutsDate: "Chiffres d'affaires bruts des réservations pour",
        revenueInfo:
          "Le taux d’occupation est le rapport entre le nombre de chambres, logements, emplacements occupés et ceux qui sont offerts dans une location. C’est un indicateur clé de performance du secteur de l’hôtellerie ou de l’hébergement de tourisme tel que la location de vacances.",
        commissions: "Commission perçues par la / les plateformes",
        commissionsPlatform: "Commissions des plateformes",
        general: "Info",
        generalInfo: "Info générale",
        tax: "Taxes de séjour",
        cleaning: "Frais de ménage",
        net: "Revenus net",
        revenus: "Revenus",
        amountReceived: "Montant reçu après retrait de l'ensemble des charges",
        occupancyRate: "Taux d'occupation",
        occupancy: "Taux d’occupation moyen",
        occupancyWithoutNightsBlocked:
          "Taux d’occupation en excluant les nuits bloquées",
        rentedNights: "Nuits louées",
        nightsAvailable: "Nuits restantes non louées",
        notRentedNights: "Nuits non louées",
        canceledNights: "Nuits annulées",
        bookingLeadTime: "Booking lead time",
        nightsPerBooking: "Nombre moyen de nuits par réservation",
        averagePrice: "Prix moyen par réservation",
        rentals: "Hébergements",
        rentalsActivated: "Hébergements actifs",
        nonRentedRentals: "Afficher les hébergements non-occupés",
        general: "Général",
        details: "Détaillé",
        objectConnected: "Objets connectés",
        contender: "Concurrence",
        average: "Moyenne",
        averageGuestsPerResa: "Nombre moyens de voyageurs par réservation",
        averageNightsPerResa: "Nombre de nuits par réservation en moyenne",
        totalCheckinsCheckouts: "Nombre de Checkin et Checkout",
        averagePricePerResa: "Prix moyen par réservation",
        revPar: "RevPAR",
        allBookings: "Toutes les réservations",
        revenueByChannel: "Chiffres d'affaire par plateforme",
        revenueByRentals: "Chiffre d'affaires par hébergements",
        exportSubTitle:
          "Vous pouvez exporter l’ensemble de vos données sur l’ensemble des plateformes utilisées afin d’avoir une synthèse optimale en vue d’un futur investissement ou d’une simple discussion avec votre banque.",
        exportStart: "Début de la période",
        exportEnd: "Fin de la période",
        exportPerform: "Télécharger",
        infoOk: "C'est compris",
        period: "Période",
        nights: "Nuits louées",
        remaining: "Nuits restantes",
        occRate: "Taux d'occupation",
        cancelRate: "Taux d’annulation",
        platform: "Plateforme",
        guest: "Voyageur",
        date: "Date",
        rate: "Avis",
        publicComment: "Commentaire public",
        displayUpTo: "Afficher jusqu'à",
        pagingEvaluations5: "5 commentaires",
        pagingEvaluations10: "10 commentaires",
        evaluations: "Évaluations",
        summary: "Synthèse annuelle",
        history: "Historique",
        historySubTitle:
          "Retrouvez ici l’historique de l’ensemble des réservations effectuées via Airbnb pour l’ensemble de vos hébergements sur la période sélectionnée.",
        booking: "Réservation",
        bookingDirect: "Réservations en direct",
        othersPlatform: "Autres plateformes",
        others: "Autres",
        payout: "Versement",
        payoutHost: "Versé à l'hote",
        alreadyPaid: "Déja versé",
        upcomingPaid: "À venir",
        reservationDate: "Séjour du {{checkin}} au {{checkout}}",
        total: "Total",
        bookings5: "5 réservations",
        bookings10: "10 réservations",
        placeholderSearchBooking: "Rechercher une réservation",
        search: "Rechercher",
        infosTitle: "Informations sur votre tableau de bord financier",
        infosContent1:
          "Superhote n'est pas une solution de comptabilité. La fonction Analytics vous permet d'avoir une vue globale de la performance financière de vos annonces.",
        infosContent2:
          "Les résultats sont affichés sur une base mensuelle du 1er au dernier jour du mois.",
        Details: {
          TaxModal: {
            requiredRental: "Veuillez sélectionner un hébergement.",
            requiredMonth: "Veuillez sélectionner un mois.",
            requiredYear: "Veuillez sélectionner une année.",
          },
          Modal: {
            displayNbReservations: "Afficher jusqu'à {{nb}} réservations",
          },
        },
        DetailsTable: {
          period: "Période",
          nightsBooked: "Nuits louées",
          nightsAvailable: "Nuits non louées",
          occupancyRate: "Taux d'occupation",
          commissions: "Commissions",
          tourist_tax: "Taxes",
        },
        Tooltip: {
          General: {
            revenue:
              "Total des revenus incluant nuitées, commissions plateformes, frais de ménage, et taxes de séjour.",
            commissions:
              "Montant des commissions appliquées par les plateformes de réservation.",
            cleaning:
              "Montant total des frais de ménage facturés aux voyageurs.",
            tax: "Montant total des taxes de séjour perçues pour la période.",
            net: "Montant calculé en soustrayant les commissions et taxes de séjour du chiffre d’affaires brut.",
            occupancyRate:
              "Calculé en fonction des nuits effectivement louées par rapport aux nuits disponibles (hors nuits bloquées pour maintenance ou autres raisons).",
            rentedNights: "Nombre total de nuits louées sur la période.",
            nightsAvailable:
              "Nombre total de nuits disponibles mais non louées sur la période.",
            rentalsActivated:
              "Nombre d’hébergements disponibles pour la période sélectionnée.",
            averageGuestsPerResa:
              "Moyenne du nombre de voyageurs par réservation sur la période.",
            averageNightsPerResa:
              "Moyenne de la durée des réservations en nuits.",
            totalCheckinsCheckouts:
              "Total des check-ins et check-outs enregistrés.",
            averagePricePerResa: "Montant moyen facturé par réservation.",
            revPar:
              "Calculé en divisant les revenus totaux par le nombre total de nuits disponibles (métrique de performance par unité).",
          },
          Details: {
            Revenue: {
              ca: "Somme des revenus des nuitées + commissions des plateformes + frais de ménage + taxes de séjour.",
              commissionsPlatform:
                "Somme des commissions appliquées par les plateformes pour chaque réservation.",
              tax: "Somme des taxes de séjour perçues pour toutes les réservations sur la période sélectionnée.",
              cleaning:
                "Somme des frais de ménage pour toutes les réservations sur la période sélectionnée.",
              net: "Somme des frais de ménage pour toutes les réservations sur la période sélectionnée.",
            },
            Occupancy: {
              rentedNights:
                "Nombre total de nuits pour lesquelles une réservation a été effectuée.",
              notRentedNights:
                "Nombre total de nuits disponibles - Nuits louées.",
              canceledNights:
                "Réservations annulées sur la période sélectionnée",
              cancelRate: "% d’annulation sur l’ensemble des réservations",
              averageNightsPerResa:
                "Moyenne de la durée des réservations en nuits.",
              averagePricePerResa: "Moyenne du prix par réservation.",
            },
          },
        },
      },
      Templates: {
        title: "Modèles de messages",
        addTitle: "Nouveau modèle de message",
        search: "Rechercher",
        placeholderSearch: "Recherchez un modèle de message",
        placeholderSearchAuto: "Recherchez un message automatique",
        template: "Modèles de messages",
        insert: "Insérer",
        add: "Créer un nouveau modèle",
        addAction: "Créer le modèle",
        edit: "Modifier le modèle",
        auto: "Messages automatisés",
        sortByRentals: "Hébergements",
        sortByLanguages: "Langue",
        generic: "Modèles génériques",
        removeTitle: "Voulez-vous vraiment supprimer",
        removeContent:
          "Cela aura pour effet de supprimer le modèle et les informations associées.",
        content: "Contenu",
        name: "Nom du message",
        namePlaceholder: "Titre de votre message",
        message: "Message",
        messagePlaceholder: "Contenu de votre message",
        showShortcodes: "Afficher la liste des champs dynamiques disponibles",
        photos: "Photos",
        informations: "Informations",
        language: "Langue",
        thisTemplateIs: "Ce modèle de message est :",
        isGeneric: "Générique",
        isSpecific: "Spécifique à un hébergement",
        rentals: "Hébergement",
        errorTitle: "Titre requis",
        errorMessage: "Contenu du message requis",
        shortcodes: "Champs dynamiques",
        editTitle: "Modifier le modèle de message",
        emptyTitle: "Aucun modèle de message disponible",
        emptyContent:
          "Vous pouvez commencer à créer vos propres modèles de message.",
      },
      ReservationList: {
        noReservationTitle:
          "Aucun départ ni aucune arrivée prévus pour les dates sélectionnées",
      },
      RentalList: {
        title: "Hébergements",
        capacityTraveler: "Capacité maximum",
        search: "Rechercher des hébergements",
        addRental: "Ajouter un hébergement",
        addRentalGroup: "Créer un groupe",
        createRentalGroup: "Créer le groupe",
        connectPlatform: "Connecter une plateforme",
        listView: "Vue liste",
        groupView: "Vue par groupe",
        addRentalGroupNamePlaceholder: "Hébergements sur Paris",
        rentalGroupTitle: "Hébergements dans ce groupe",
        rentalGroupNameNotEmptyError:
          "Vous devez selectionner au minimum un hébergement",
        addRentalGroupTitle: "Créer un groupe d'hébergements",
        updateRentalGroupTitle: "Modifier un groupe d'hébergements",
        deleteRentalGroupTitle:
          "Voulez-vous vraiment supprimer ce groupe d'hébergements ?",
        deleteRentalGroupSubTitle:
          "Cela ne supprimera pas les hébergements de ce groupe.",
        emptyViewRentalGroupTitle: "Aucun groupe ajouté",
        emptyViewRentalGroupDescription1:
          "Vous n'avez actuellement aucun groupe d'hébergement créé.",
        emptyViewRentalGroupDescription2: "Ajoutez-en un dès maintenant.",
        FieldNames: {
          rentalGroupName: "Nom du groupe",
        },
        rental: "Hébergement",
        status: "Statut",
        sync: "Synchronisation",
        capacity: "Capacité",
        checkinCheckout: "Arrivée | Départ",
        extraTraveler: "Voyageur supplémentaire",
        cleaningFees: "Frais ménage",
        displayUpTo: "Afficher jusqu'à",
        paging5: "5 hébergements",
        paging10: "10 hébergements",
        statusActive: "Actif",
        statusInactive: "Désactivé",
        syncOK: "Activée",
        syncKO: "Désactivée",
        noRentalTitle: "Aucun hébergement présent sur SuperHote",
        noAutoMessageTitle: "Aucun message automatisé disponible",
        noAutoMessageSubTitle:
          "Vérifiez que vous êtes bien connecté à une des plateformes disponibes ou ajoutez manuellement un premier hébergement.",
        addReservation: "Ajouter un hébergement",
        noRentalSubTitle:
          "Vérifiez que vous avez des hébergements synchronisés avec SuperHote ou ajoutez-en manuellement.",
        SelectModal: {
          title_one: "{{count}} hébergement sélectionné",
          title_other: "{{count}} hébergements sélectionnés",
        },
        DeactivateModal: {
          title_one: "Voulez-vous vraiment désactiver cet hébergement ?",
          title_other: "Voulez-vous vraiment désactiver ces hébergements ?",
          description1_one:
            "Cela aura pour effet de couper toutes les fonctionnalités de Superhote sur ce logement, dont les messages automatisés.",
          description1_other:
            "Cela aura pour effet de couper toutes les fonctionnalités de Superhote sur ces logements, dont les messages automatisés.",
          description2_one:
            "Vous pouvez réactiver ce logement dès que vous le souhaitez.",
          description2_other:
            "Vous pouvez réactiver ces logements dès que vous le souhaitez.",
        },
        RemoveModal: {
          title_one: "Voulez-vous vraiment supprimer cet hébergement ?",
          title_other: "Voulez-vous vraiment supprimer ces hébergements ?",
          description1_one:
            "Si vous supprimer, vous perdrez toutes les informations associées à cet hébergement. Si vous souhaitez seulement désactiver le logement, cliquez <strong>ici</strong>.",
          description1_other:
            "Si vous supprimer, vous perdrez toutes les informations associées à ces hébergements. Si vous souhaitez seulement désactiver les logements, cliquez <strong>ici</strong>.",
        },
      },
      MultiSelectRentalsCheckboxModal: {
        searchLabel: "Hébergement",
        searchPlaceholder: "Appartement Lafayette",
        property: "propriétés",
      },
      Dashboard: {
        title: "Tableau de bord",
        welcome: "Bienvenue",
        program: "au programme",
        from: "Du",
        to: "au",
        today: "Aujourd'hui",
        tomorrow: "Demain",
        buttonActionCalendar: "Voir le calendrier",
        buttonActionTask: "Voir toutes les tâches",
        thisWeek: "Cette semaine",
        thisMonth: "Ce mois",
        thisYear: "Cette année",
        summary: "Voici un résumé de votre activité de loueur courte durée",
        scheduledPayments: "Versements prévus",
        hoursMin: "{{hours}} heures et {{min}} minutes",
        hours: "{{hours}} heures",
        timeGained: "Temps gagné grâce à Superhote depuis le début",
        infoTimeGained:
          "La durée affichée ici est basée sur une moyenne de 20 minutes gagnées par réservation en utilisant SuperHote (messages automatisés, page voyageur, ...).",
        yesterday: "hier",
        stats: "",
        nextCheckins: "Prochains départs et arrivées",
        nextTasks: "Prochaines tâches dans vos hébergements",
        bookings: "Réservations",
        notPaid: "non-payées",
        deposits: "Cautions",
        toCollect: "à encaisser",
        tasksToAssign: "Tâches à assigner",
        goAnalytics: "Consulter les statistiques",
        goBookings: "Accéder aux réservations",
        goTasks: "Gérer les tâches",
        titleActivities: "Activité dans vos hébergements",
        checkin: "Départs",
        checkout: "Arrivées",
        placeholderSearchRentals: "Rechercher des hébergements",
        placeholderSearchBookings: "Rechercher des réservations",
        searchRentals: "Rechercher",
        rental: "Hébergement",
        guest: "Voyageur",
        checkinDate: "Date d'arrivée",
        checkinTime: "Heure d'arrivée",
        cleaning: "Ménage",
        actions: "Actions",
        bookingDetails: "Voir les détails",
        tips: "Recommandations",
        paging5: "5 réservations",
        paging10: "10 réservations",
        sendMessage: "Envoyer un message",
        scheduledMessages: "Messages automatisés",
        operational: "Gestion opérationnelle",
        booking: "Réservation",
        email: "Email",
        phone: "Téléphone",
        timeZone: "Fuseau horaire",
        rate: "Avis",
        titleCheckinDetails: "Détails arrivée",
        allTime: "Toujours",
        checkoutDate: "Date de départ",
        checkoutTime: "Heure de départ",
        lastMonth: "mois dernier",
        tasks: "Tâches",
        toAssign: "à assigner",
        noneAssigned: "Non assignées",
        noneAssignedSingular: "Non assigné",
        noneTasks: "Vous n'avez aucune tâche.",
        noneTasksScheduled: "Aucune tâche prévue",
        addTask: "Ajouter une tâche",
        tasksForTeam: "Tâches d’équipe à assigner",
        tasksForMe: "Mes tâches à réaliser",
        tasksToday: "à réaliser aujourd'hui",
        active: "Activer",
        discover: "Découvrir",
        automateMessages: "Automatisez l’envoi de vos message",
        automateMessages2: "Automatisez vos message 2",
        installSmartLock: "Installez une serrure connectée",
        installSmartLock2: "Installez une serrure connectée 2",
        createWelcomeGuide: "Créez un guide de bienvenue",
        fromArrivalToDeparture: "de l’arrivée au départ des voyageurs",
        gainAutonomy: "et gagnez en autonomie",
        shareAdvice: "partagez tous vos conseils et adresses",
        occupancyRate: "Taux d'occupation",
        countTasksDoneDate: "Montant des prestations réalisées en",
        monthPrevious: "vs. mois dernier",
        noneBookings: "Vous n'avez aucune réservation.",
        allPaidBookings: "Toutes les réservations ont été payés",
        paidAllDeposits: "Toutes les cautions ont été payés",
        noneDeposits: "Vous n’avez aucune caution en attente de paiement.",
        extrasOrders: "Commandes d'extras",
        toValidate: "à valider",
        noneExtrasOrders: "Vous n'avez aucune commande d'extra à valider.",
        viewStats: "Consulter les statistiques",
        viewPayments: "Accéder aux paiements",
        viewDeposits: "Accéder aux cautions",
        consultPayments: "Consulter les paiements",
        viewTasks: "Accéder aux tâches",
        viewExtras: "Accéder aux extras",
        viewMyTasks: "Accéder à mes tâches",
        viewTasksAvailableNoneAssigned:
          "Accéder aux tâches disponibles non assignées",
        startingTime: "À partir de",
        progressReservation: "Séjour en cours",
        lastReservation: "Dernier départ",
        nextReservation: "Prochaine arrivée",
        refuse: "Refuser",
        accept: "Accepter",
        emptyLastReservation: "Aucun départ",
        emptyNextReservation: "Aucun départ",
        relativeDayBeforeYesterday: "Avant Hier",
        relativeDayYesterday: "Hier",
        relativeDayToday: "Aujourd'hui",
        relativeDayTomorrow: "Demain",
        relativeDayAfterTomorrow: "Après-demain",
        relativeDays: "Dans {{days}} jours",
        at: "à",
        tasks: "tâches",
        task: "tâche",
      },
      Calendar: {
        cleaningRequired: "Ménage à assigner",
        cleaningScheduled: "Ménage à réaliser",
        cleaningStart: "Ménage commencé",
        cleaningCancel: "Ménage annulé",
        cleaningAssigned: "Assigné à {{name}}",
        cleaningFinishCheckin: "Prêt pour checkin",
        cleaningFinish: "Ménage terminé",
        start: "En cours",
        title: "Calendrier",
        daily: "Journalier",
        weekly: "Semaine",
        monthly: "Mensuel",
        tasks: "Tâches",
        planning: "Planning",
        addBooking: "Ajouter une réservation",
        rentals: "hébergements",
        placeholderSearch: "Rechercher ...",
        noBookingTitle: "Aucune réservation détectée",
        noBookingSubTitle:
          "Vérifiez que vous êtes bien connecté à une des plateformes disponibes ou ajoutez manuellement une réservation.",
        connectPlatform: "Connecter une plateforme",
        minNights: "Nombre de nuits minimum",
        maxNights: "Nombre de nuits maximum",
        minNightsTooltip:
          "Le nombre de nuits minimum est transmis à Airbnb et Booking.com.",
        maxNightsTooltip:
          "Le nombre de nuits maximum est transmis à Booking.com mais n’est pas transmis à Airbnb.",
        noCheckinTooltip:
          "Les arrivées bloquées sont transmises à Booking.com mais ne sont pas transmises à Airbnb.",
        noCheckoutTooltip:
          "Les départs bloqués sont transmis à Booking.com mais ne sont pas transmis à Airbnb.",
        price: "Prix nuitée",
        noCheckin: "Arrivées bloquées",
        noCheckout: "Départs bloqués",
        checkin_time: "Heure d'arrivée",
        checkout_time: "Heure de départ",
        toAssign: "À assigner",
        bookingDetails: "Voir les détails",
        listView: "Vue liste",
        weekView: "Vue semaine",
        monthView: "Vue mois",
        yearView: "Vue année",
        emptyJournal: "Il n'y a pas d'historique pour cette réservation",
        bookingOverview: "Aperçu de la réservation",
        bookingCreate: "Réservation créée le",
        viewBookingDetails: "Détails de la réservation",
        sendMessage: "Envoyer un message",
        displayBy: "Afficher par",
        displayByPlatforms: "Plateformes",
        displayByPaymentStatus: "État de paiement",
        previousPeriod: "Période précédente",
        nextPeriod: "Période suivante",
        cancelResa: "Annuler la réservation",
        rangeRequired: "Veuillez sélectionner une plage de deux dates valides.",
        goTo: "Allez à",
        totalGuest: "Total payé",
        netRevenue: "Revenu net",

        CheckPrice: {
          title: "Vérifiez vos prix sur vos calendriers",
          content:
            "Vous pouvez à présent ajuster vos prix pour une période particulière ou pour toute date de votre choix directement sur le calendrier.",
          button: "J’ai vérifié mes prix",
        },

        CheckPriceModal: {
          title: "Vérifiez vos calendriers et vos prix.",
          content:
            'Vous allez maintenant pouvoir vérifier les calendrier de vos hébergements et leurs prix. Une fois cela fait, vous pouvez cliquer sur "J\'ai vérifier mes prix" en haut à droite de la page.',
          button: "Ok",
        },

        MinNightsModal: {
          title: "Gérer le nombre de nuit minimum de ",
          nbNights: "Nombre de nuit minimum",
          dates: "Période",
          save: "Valider les modifications",
        },

        MaxNightsModal: {
          title: "Gérer le nombre de nuit maximum de ",
          nbNights: "Nombre de nuit maximum",
          dates: "Période",
          save: "Valider les modifications",
        },

        PriceAndAvailabilityModal: {
          title: "Gérer le prix et la disponibilité de ",
          editPrice: "Gérer le prix",
          editAvailability: "Gérer la disponibilité",
          price: "Prix de la nuit",
          isAvailable: "Disponible",
          isNotAvailable: "Indisponible",
          unavailabilityReason: "Raison de l'indisponibilité",
          dates: "Période",
          savePrice: "Valider les prix",
          saveAvailability: "Valider les disponibilités",
        },

        NoCheckinCheckoutModal: {
          title: "Gérer les arrivées et les départs bloqués de ",
          noCheckin: "Arrivées bloquées",
          noCheckout: "Départs bloqués",
          dates: "Période",
          save: "Valider les modifications",
        },

        UnavailabilityReason: {
          holidays: "Vacances",
          construction: "Construction",
          housework: "Travaux",
          closed: "Fermé",
          unavailable: "Indisponible",
          owner: "Occupé par le propriétaire",
          ownerShort: "Propriétaire",
          other: "Autre",
        },

        PaymentLabel: {
          paid: "Séjour payé",
          overdue: "Paiement séjour en retard",
          partiallyPaid: "Séjour payé partiellement",
          refunded: "Séjour remboursé",
          unpaid: "Séjour non payé",
          canceled: "Séjour annulé",
        },

        CancelResaModal: {
          title: "Voulez-vous vraiment annuler la réservation ?",
          content1:
            "Attention, vous êtes sur le point d’annuler la réservation. Êtes-vous certain de vouloir annuler ? ",
          content2:
            "Les paiements non payés seront annulés, et vous devrez rembourser au voyageur les paiements déjà effectués.",
          content3: "Choissisez le type d’annulation :",
          ByGuest: {
            title: "Annulation demandée par le voyageur",
            content:
              "Dans le cas ou l’annulation est spécifiquement demandée par le voyageur",
          },
          ByHost: {
            title: "Annulation standard",
            content:
              "Dans le cas ou l’annulation est causée par le propriétaire ou le gérant du logement",
          },
          button: "Oui, annuler",
        },

        Reservation: {
          checkin: "Checkin",
          checkout: "Checkout",
          cleaning: "Ménage après séjour",
          deny: "Refuser",
          accept: "Accepter",
          withNuki: "Autonome avec Serrure Nuki",
          withCodelock: "Autonome avec code sécurisé",
          withName: "Autonome avec {{name}}",
          auto: "Autonome",
          noScheduled: "Non assigné",
          assignTo: "Assigner le ménage à quelqu'un",
          door: "Porte de la piscine",
          codelock: "Code de la serrure",
          assignedTo: "Ménage assigné à",
          assignedOne: "À une personne",
          assignedOneTeam: "À une équipe",
          assignedPerson: "Assigné une personne",
          assignedTeam: "Assigné une équipe",
          codeLockName: "Code {{name}}",
          notDefined: "Non défini",
          date: "Le {{date}} À partir de {{hours}}",
          requestedHelpText: "Le checkin, checkout et le ménage seront mis à jour lors de la validation de la réservation.",
          notProgramedCleaning: "Aucune tâche de ménage planifiée",
          notProgramedCheckin: "Aucune tâche d'arrivée planifiée",
          notProgramedCheckout: "Aucune tâche de départ planifiée",
          createCleaningTask: "Créer une tâche de ménage",
          denyTaskTitle: "Voulez-vous vraiment refuser cette tâche ?",
          denyTaskDescription: "Vous ne serez pas assigné sur cette tâche, ce sera pour quelqu'un d'autre.",
          yesDeny: "Oui, refuser",
          cancel: "Annuler",
          tasks: {
            assigned: "Planifiée",
            notAssigned: "Non assignée",
            started: "Commencée",
            completed: "Finalisée",
            canceled: "Annulée",
            partiallyCompleted: "Bientôt finalisée",
            notCreated: "Non créée",
            person: "Personne physique",
            cleaningNotAssigned: "Ménage Non assigné",
            checkinNotAssigned: "Checkin Non assigné"
          },
          Locks: {
            auto: "Autonome"
          }
        },

        Contract: {
          formAndContract: "Formulaire et contrat",
          formGuestPage: "Formulaire Page Voyageur",
          contract: "Contrat",
          see: "Voir",
          notSigned: "Non signé",
          notSet: "Non rempli",
          set: "Rempli",
          notRequested: "Non demandé",
          notApply: "Non applicable",
          anyContractAirbnbText:
            "Aucun contrat ne peux être signé car la réservation vient d'Airbnb",
          signed: "Signé",
        },
      },
      PriceMultiplier: {
        title: "Paramètrage pour compenser les commissions plateformes",
        info: "Si vous souhaitez percevoir les mêmes revenus quelque soit la source, vous pouvez paramétrer ici un <b>multiplicateur</b> qui s’appliquera sur votre prix calendrier avant l’envoi aux différentes plateformes.",
        params:
          "Paramètrage du multiplicateur de prix pour tous vos hébergements",
        paramsInfo:
          "Vous pourrez le paramètrer hébergement par hébergement plus tard, au besoin, dans la section Tarification de vos hébergements.",
        simulationTitle:
          "<b>Simulation du Multiplicateur de prix</b> (Calculs basés sur les taux de commissions plateformes détaillés à droite)",
        simulationInfo1:
          "Cette simulation vous permet de mieux vous rendre compte de l’impact des commissions plateformes sur vos prix et vos revenus, et de l’intérêt de mettre en place un multiplicateur de prix.",
        simulationInfo2:
          "Le prix de la nuitée et la taxe de séjour sont purement indicatifs et utilisés uniquement pour la simulation.",
        commissionsTitle: "Informations sur les commissions plateformes",
        commissionsInfo1:
          "Le simulateur ci-contre intègre la commission correspondant aux frais simplifiés pour Airbnb.",
        commissionsInfo2:
          "Nous vous recommandons, de passer aux frais simplifiés dans le paramètrage de votre compte car cela vous permettra d'être mieux référencé sur Airbnb.",
        tutoAirbnb1: "Tutoriel Airbnb :",
        tutoAirbnb2:
          "Ajouter tous les frais de service Airbnb dans votre prix ? ↗",
        tutoAirbnb3: "(15% sur le prix fixé HT + TVA = 18% TTC)",
        tutoBooking1:
          "<b>Booking.com</b> : La plupart du temps, le taux de commission mis en place par Booking.com est de 17%.",
        tutoBooking2: "Tuto Booking.com : ",
        tutoBooking3: "Connaître votre commission Booking.com ? ↗",
        commissionsText1: "Ainsi, vous pouvez faire en sorte de",
        commissionsText2:
          "rajouter le pourcentage de commission à votre prix de base,",
        commissionsText3: "et vous vous assurez d’obtenir le",
        commissionsText4: "même montant à la nuitée,",
        commissionsText5: "quelle que soit la source de vos réservations.",
        reminderTitle:
          "Pour rappel, les commissions des plateformes s’appliquent également sur les frais de ménage",
        reminderInfo:
          "Pour compenser, pensez à augmenter le montant des frais de ménage directement sur les plateformes.",
        priceByNight: "Prix de base par nuit",
        taxByNightPerPerson: "Taxe de séjour",
        startEmulation: "Lancer la simulation",
        showDetails: "Afficher les détails",
        hideDetails: "Masquer les détails",
        multiplerFor: "Multiplicateur de prix pour {{platform}}",
        Table: {
          nightPrice: "Prix nuitée",
          tax: "Taxe de séjour",
          paidGuest: "Payé par le voyageur",
          paymentFees: "Frais de paiement (Stripe ou Booking.com)",
          commission: "Commission Plateforme TTC",
          paidBank: "Versé sur votre compte",
          paidNet: "Net après paiement des taxes de séjour",
        },
        apply:
          "Appliquer le multiplicateur à tous mes hébergements et lancer la synchronisation ",
        applyRental: "Appliquer le multiplicateur à cet hébergement",
        applyToOthers: "Appliquer ce multiplicateur à d’autres hébergements",
        priceMultiplierMultipleRentalsTitle:
          "À quels hébergements voulez-vous également appliquer ce multiplicateur de prix ?",
        priceMultiplierMultipleRentalsTitleSubmit:
          "Appliquer le multiplicateur à ces hébergements",
      },
      Progression: {
        title: "Votre progression sur SuperHote",
        progression: "Votre progression",
        connect: "Connecter",
        tutorialTitle: "Voir le tutoriel de l'étape",

        StepAirbnb: {
          title: "Ajoutez vos hébergements Airbnb sur SuperHote",
          content1:
            "Importez vos hébergements Airbnb sur SuperHote, vous pourrez configurer vos prix, vos conditions, automatiser vos messages.",
        },
        ConnectStripe: {
          title: "Encaissez vos réservations et vendez des extras en direct",
          content:
            "Stripe est nécessaire pour vos réservations manuelles et pour la gestion des extras (les ventes de produits et de services supplémentaires lors des réservations)",
          addStripe: "Ajouter un compte Stripe",
          nextStep: "Passer à l'étape suivante",
        },

        StepRentals: {
          title: "Ajoutez manuellement des hébergements",
          content1:
            "Pour gérer des hébergements non présents sur Airbnb, vous devez les créer manuellement sur Superhote. Vous pourrez les connecter à vos annonces existantes dans la prochaine étape.",
          tableTitle: "Hébergement sur SuperHote",
          addRental: "Ajouter un hébergement",
          next: "Passer à l'étape suivante",
        },

        StepBooking: {
          title: "Ajoutez vos hébergements Booking.com sur SuperHote",
          content1:
            "D’après vos informations, vous utilisez d'autres plateformes. Une fois synchronisées avec SuperHote, vous pourrez automatiser vos messages, optimiser vos prix et obtenir des recommandations personnalisées",
          addOther: "Connecter une autre plateforme",
          next: "Passer à l'étape suivante",
        },
        MultiDepositSettings: {
          title: "Vos hébergements pour les réservations en direct",
          depositLabel: "Caution",
          depositError: "Le champ ne peut pas être vide",
        },
        StepConfigDirectReservations: {
          title: "Préparez vos réservations en direct",
          content1:
            "Si vous désirez effectuer des réservations en direct ou avec un site de réservation hors plateforme, vous devez effectuer quelques paramètrages.",
          content2:
            "Vous pourrez toujours le modifier plus tard, depuis les détails de vos hébergements.",
          noPlatformReservation:
            "Je ne souhaite effectuer aucune réservation hors plateforme",
          Modal: {
            title: "Configurez les réservations en direct",

            Steps: {
              ChoiceRental: {
                title: "Choix des hébergements pour réservations en direct",
                description:
                  "Veuillez sélectionner <strong>tous les hébergements</strong> où vous pensez effectuer des réservations en direct, hors plateforme.",
                error: "Vous devez sélectionner au minimum 1 hébergement",
              },
              DepositSettings: {
                title: "Paramètrage des cautions",
                description:
                  "Veuillez choisir le montant de la caution pour les réservations en direct de ces hébergements.",
              },
              StripeCollectPayments: {
                title: "Compte Stripe pour encaisser les paiements",
                content1:
                  "Pour encaisser les paiements et les cautions de vos réservations en direct, SuperHote s'associe avec Stripe, une solution de paiement en ligne.",
                content2:
                  "Vous pourrez soit demander une pré-autorisation pour la caution, soit l'encaisser directement et procéder un remboursement après le séjour.",
                content3:
                  "Pour les pré-autorisations, le montant sera alors bloqué via Stripe sans être encaissé, et prélevé uniquement en cas de dégâts effectifs.",
                content4:
                  "Pour rappel, une pré-autorisation dure 7 jours au maximum. Faites attention à bien être couvert jusqu'à la fin du séjour.",
                loginOrRegisterStripeAccount:
                  "Connecter ou créer votre compte Stripe",
                loginOrRegisterStripeAccountDescription:
                  "Nous utilisons Stripe Connect pour vous permettre d'encaisser les paiements de vos voyageurs de manière simple et sécurisée.",
                addStripeAccount: "Ajouter un compte Stripe",
                addStripeAccountSuccessTitle: "Connexion OK",
                addStripeAccountSuccessDescription:
                  "SuperHote est bien connecté à votre compte Stripe, cliquez à présent sur Terminer pour l'ajouter à vos modes d'encaissement",
                accountNameLabel: "Intitulé du compte",
                accountNamePlaceholder:
                  "Compte Stripe SCl, compte personnel, compte pour extra, ...",
                accountNameError: "Vous devez renseigner un intitulé de compte",
                collectPaymentDifferent:
                  "Je souhaite encaisser les paiements autrement",
                collectPaymentDifferentInfo:
                  "(En espèces, virement ou chèques)",
              },
            },
          },
        },
        StepRules: {
          title: "Vérifiez les paramètres de vos hébergements",
          content1:
            "Votre calendrier est à présent initialisé sur Superhote, vérifions ensemble les différentes règles qui impactent votre activité de loueur en courte durée.",
          config: "Configurer mes règles",
          titlePage: "Paramètrage de vos hébergements",
        },

        StepCalendar: {
          title: "Vérifiez vos prix sur vos calendriers",
          content:
            "Vous pouvez ajuster vos prix pour une période particulière ou pour toute date de votre choix directement sur le calendrier.",
          button: "Vérifier mon calendrier",
        },

        StepMessages: {
          title: "Automatisez vos messages avec vos voyageurs",
          content:
            "Créez des messages automatisés pour gagner du temps lors des réservations.",
          button: "Créer des messages automatisés",
        },

        StepTeam: {
          title: "Ajouter votre équipe opérationnelle",
          content:
            "Ajouter dans SuperHote vos partenaires : conciergerie, femmes de ménage, ...",
          button: "Ajouter des membres à mon équipe",
        },

        StepGift: {
          title: "Obtenez votre cadeau",
          buttonGift: "Recevoir mon cadeau",
          buttonCalendar: "Voir mon calendrier",
          buttonCommunity: "Rejoindre la communauté",
          Modal: {
            title: "Félicitations !",
            content:
              "Surveillez votre boîte mail, vous allez recevoir une surprise dans quelques instants !",
          },
        },

        sideTitle: "Progression réalisée à",
        sideContent:
          "Activez les étapes suivantes pour tirer le meilleur de SuperHote !",
        sideTitle2: "Une question, un problème ?",
        sideContent2:
          "Notre équipe d’experts se tient prête pour vous aider en cas de besoin.",
        sideContent3:
          "Vous avez activé l’ensemble des fonctionnalités disponibles dans l’Alpha test.",
        sideContent4:
          "Commencez maintenant à utiliser pleinement SuperHote, nous attendons vos retours !",
        sideButton: "Contacter le support",
        continue: "Continuer",
        verify: "Vérifier",
        import: "Importer {{count}} hébergement{{s}}",
        airbnbModalTitle: "Ajout de vos hébergements Airbnb sur SuperHote ",
        airbnbConnectStep5Title: "Importer vos hébergements",
        airbnbConnectStep7Content2:
          "Cliquez sur Lancer l’importation démarrer la récupération. Cela peut prendre quelques minutes.",

        airbnbConnectStep7Content3_one:
          "Vous êtes maintenant prêt à lancer la récupération de {{count}} hébergement Airbnb avec l’application d’une décote de {{priceCut}}% à appliquer pour l’initialisation de vos réservations en direct.",
        airbnbConnectStep7Content3_other:
          "Vous êtes maintenant prêt à lancer la récupération de {{count}} hébergements Airbnb avec l’application d’une décote de {{priceCut}}% à appliquer pour l’initialisation de vos réservations en direct.",

        searchRental: "Rechercher un hébergement",
        placeholderSearchRental: "Nom de l'hébergement",
        activeRentalsAirbnb_one: "Hébergement actif présent sur Airbnb",
        activeRentalsAirbnb_other: "Hébergements actifs présents sur Airbnb ",
        internalTitle: "Titre SuperHote",
        yourActivity: "Votre activité associée",
        rules: "Règles Airbnb",
        displayUpTo: "Afficher jusqu'à",
        paging5: "5 hébergements",
        paging10: "10 hébergements",
        rentalOn: "hébergement sur",

        finishTitle: "Patientiez un instant s’il vous plaît",
        finishContent:
          "Nous importons les 3 biens que vous avez sélectionnés, cela peut prendre jusqu’à quelques minutes...",
        startImport: "Lancer l'importation",

        bookingConnect: "Importer des hébergements depuis Booking.com",
        bookingSynchro:
          "Synchronisation de l’hébergement avec une annonce Booking.com",
        airbnbConnect: "Importer des hébergements depuis Airbnb",
        bookingTuto: "Regarder notre tutoriel d'intégration de Booking.com",
        bookingConnectStep1Title: "Connexion à Booking.com",
        bookingConnectStep1Info1:
          "Avant toute chose, vous devez vous activer SuperHote comme fournisseur de connectivité sur Booking.com.",
        bookingConnectStep1Info2: "Le bouton ci-dessous ouvrira une fenêtre.",
        bookingConnectStep1InfoList1:
          "1. Renseignez vos identifiants Booking.com",
        bookingConnectStep1InfoList2:
          "2. Activez le fournisseur sur toutes les propriétés que vous souhaitez connecter",
        bookingConnectStep1InfoList3:
          "3. Fermez la fenêtre et revenez sur cette page",
        bookingConnectStep1InfoList4:
          "4. Renseignez vos identifiants d’hébergements ci-dessous",
        bookingConnectStep1InfoButton: "Activer SuperHote comme fournisseur",
        bookingConnectStep1InfoButton2: "Si ce n'est pas déja fait",
        bookingConnectStep1Content1: "Que voulez-vous importer ?",
        oneRental: "Un seul hébergement",
        multipleRentals: "Plusieurs hébergement",
        hotelId: "Identifiant de l’hébergement",
        legalEntityId: "Identifiant de l'entité juridique",
        infoLegalEntityId:
          "Qu'est ce que l’identifiant de l’entité juridique et où le trouver ↗",
        bookingConnectStep1Content2:
          "Une fois connecté, cliquez sur l’icône de profil en haut à droite, puis cliquez sur",
        bookingConnectStep1Content3: "Fournisseur de connectivité.",
        bookingConnectStep1Content4:
          "Recherchez SuperHote puis acceptez l’ensemble des demandes d’autorisation Booking.com",
        bookingConnectButton1: "J’ai bien activé Booking.com comme fournisseur",
        bookingConnectStep1Content5:
          "Nous synchronisons l'hébergement que vous avez sélectionné ainsi que son calendrier depuis la plateforme.",
        bookingConnectStep1Content6:
          "Cela peut prendre juqu'à quelques minutes...",
        bookingConnectStep1Content7: "Récupération des élements..",
        bookingConnectStep1Content8: "Hébergement synchronisé avec SuperHote",
        bookingConnectStep1Content9:
          "Votre hébergement est maintenant synchronisé avec SuperHote. Prenez un moment pour vérifier le prix des nuitées.",
        ok: "Ok",
        bookingConnectStep2Title: "Sélection des hébergements",
        bookingSynchroStep2Title: "Sélection de l’établissement à synchroniser",
        bookingConnectStep3Title: "Vérification",
        bookingConnectStep4Title: "Paramètres d'importation",
        bookingConnectSingleRentalStep2Title:
          "Association avec votre hébergement déjà présent sur SuperHote",
        rentalInSH: "Hébergements présents sur SuperHote",
        rentalBookingNotSync:
          "Hébergements Booking.com pas encore synchronisés",
        importBookingOnSH: "Importer sur SuperHote",
        importBookingInfo:
          "Veuillez choisir les propriétés Booking.com que vous souhaitez importer sur SuperHote",
        rentalBookingNotSync:
          "Hébergements Booking.com pas encore synchronisés",
        rentalBookingNotSyncSynchroModal: "Etablissements Booking.com",
        importBookingOnSH: "Importer sur SuperHote",
        synchroBookingOnSH: "Synchroniser avec mon hébergement sur SuperHote",
        importBookingInfo:
          "Veuillez choisir les propriétés Booking.com que vous souhaitez importer sur SuperHote",
        synchroBookingInfo:
          "Veuillez choisir les propriétés Booking.com que vous souhaitez importer sur SuperHote",
        hotelID: "Hotel ID",
        room: "Hébergement Booking",
        associate: "Associer",
        associateToBookingid: "Associé à l’identifiant Booking",
        bookingConnectButton2: "Valider",
        roomsRentalBooking: "Type d'hébergement",
        roomsRentalBookingRequired:
          "Le type d'hébergement doit être selectionné",
        ratesRentalBooking: "Plan tarifaire",
        ratesRentalBookingRequired: "Le plan tarifaire doit être selectionné",
        rate: "Rate",
        verifBookingInfo:
          "Merci de vérifier les informations que nous avons récupéré de Booking.com",
        paramsImportBookingInfo:
          "Voulez-vous créer ces propriétés directement dans Superhote, ou voulez-vous les relier à une propriété existante de Superhote ?",
        createBookingRental: "Créer un nouvel hébergement sur SuperHote",
        connectBookingRental:
          "Connecter à un hébergement SuperHote déjà existant",
        connectSelectBookingRental:
          "Sélectionnez l'hébergement SuperHote que vous souhaitez lier",
        syncInfoTitle: "Import et connexion de vos hébergements en cours...",
        syncInfo1: "Cela peut prendre jusqu'à plusieurs minutes.",
        syncInfo2: "Ne fermez pas cette fenêtre.",
        syncInfoTitleRental: "Synchronisation de votre hébergement en cours...",
        searchHotelID: "Chercher",
        errorNoHotelID: "L'hôtel ID est requis",
        errorRoomAndRateRequired: "Room et rate requis",
        associateToBookingRoom: "Associé à",
        synchroSuccessTitle:
          "L’hébergement Booking.com a bien été synchronisé avec votre hébergement présent sur SuperHote.",
        importSuccessTitle:
          " Ces hébergements Booking.com ont été importées avec succès sur SuperHote.",
        importSuccessTitle2: "Mais il ne sont pas encore synchronisés.",
        importSuccessTitle3:
          "Vous devez vérifier les paramètres et les prix de chaque hébergement avant que ces hébergements ne soient entièrement synchronisés.",
        Airbnb: {
          Creds: {
            title: "Connecter Airbnb et SuperHote",
            content1:
              "Pour vous faire gagner du temps, SuperHote importe automatiquement vos hébergements ainsi que ceux pour lesquels vous êtes co-hôte grâce à son intégration sécurisée avec Airbnb.. ",
            content2:
              "Si vous changez votre mot de passe Airbnb, ou votre méthode de connexion Airbnb, nos autorisations sur votre compte seront révoquées.",
            content3:
              "Vous vous connectez à Airbnb avec Facebook ou Google ? Vous devez effectuer une demande de mot de passe Airbnb pour votre compte.",
            content4:
              "Avant de cliquer sur “Connecter”, connectez-vous avec le compte Airbnb que vous souhaitez connecter à SuperHote.",
            forgotPwdLabel: "Vous avez oublié votre mot de passe ? ↗",
            emailLabel: "E-mail de connexion Airbnb",
            errorEmail: "Adresse e-mail requise",
            emailErrorInvalid: "L'adresse email est invalide",
            passwordLabel: "Mot de passe Airbnb",
            errorPassword: "Mot de passe requis",
            errorInvalidCred: "Adresse email et mot de passe invalides.",
            errorProxy:
              "Erreur interne (proxy). Veuillez réessayer dans quelques minutes.",
            errorAccountLinked: "Ce compte Airbnb est déjà utilisé.",
            errorSocialLinked:
              "Ce mompte est connecté avec Google, Facebook, ... Veuillez contacter le support pour plus d'informations.",
            errorAirlockV2:
              "Erreur interne (Airlock v2). Veuillez réessayer dans quelques minutes.",
            errorUnmanaged:
              "Erreur interne (Unmanaged). Veuillez réessayer dans quelques minutes.",
            errorAbb:
              "Erreur interne (Airbnb). Veuillez réessayer dans quelques minutes.",
            errorAccountNotFound:
              "Erreur interne (Account not found). Veuillez réessayer dans quelques minutes.",
            mfaNotAvailable:
              "Erreur interne (MFA non correspondant). Veuillez contacter le support.",
            errorAccountBlocked:
              "Votre compte Airbnb n'est pas finalisé. Veuillez contacter le support.",
            errorCodeExpired: "Votre code Airbnb a expiré, veuillez réessayer.",
            errorMfaSentFailed:
              "Impossible d'envoyer le code de vérification Airbnb.",
          },
          Validation: {
            title: "En attente de validation",
            content1: "Merci de patienter et de ne pas fermer cette fenêtre.",
            content2:
              "L’opération peut prendre jusqu’à 15 minutes pendant les heures ouvrées (du lundi au vendredi de 7h à 20h).",
            content3:
              "Si la demande est faite en dehors des heures ouvrées, elle sera validée le lendemain. Pour toute information, veuillez contacter le support sur",
            supportEmail: "support@superhote.com",
          },
          SendMFA: {
            title: "Vérification multi-facteurs",
            content1:
              "Airbnb veut vérifier votre compte. Veuillez choisir l’une des options ci-dessous.",
            content2:
              "L’opération peut prendre jusqu’à 15 minutes pendant les heures ouvrées (du Lundi au Vendredi de 7h à 20h.",
            content3:
              "Si la demande est faite en dehors des heures ouvrées, elle sera validée le lendemain. Pour toute information, veuillez contacter le support sur",
            supportEmail: "support@superhote.com",
            send: "Envoyer la vérification",
          },
          CheckMFA: {
            title: "Confirmation du code",
            content1: "Nous vous avons envoyé un code à 6 chiffres par email.",
            content2: "Ce code est valable 15 minutes.",
            code: "Code reçu",
            confirm: "Confirmer",
            errorCode: "Le code est requis",
            reset: "Renvoyer",
            validate: "Valider",
          },
          Import: {
            submit_one: "Importer {{count}} hébergement",
            submit_other: "Importer {{count}} hébergements",
          },
          Listings: {
            errorRequired: "Veuillez sélectionner au moins un hébergement.",
          },
          Optimization: {
            content1:
              "Pour initialiser vos prix pour les réservations en direct, nous pouvons importer vos prix Airbnb: tels quels ou en appliquant une décote.",
            priceRadio: "Importer les prix tels quels",
            discountRadio: "Appliquer une décote",

            labelDiscountField: "Décote à appliquer sur vos prix Airbnb",
            content2:
              "Par exemple, si vous appliquez une décote de {{discount}}% pour une nuitée à 100 €, votre tarif calendrier pour les réservation en direct sera à {{priceWithDiscount}} €.",
            content3: "Vous pourrez revérifier vos tarifs plus tard.",
            button: "Importer les prix",
          },
          Almost: {
            title: "Vous y êtes presque !",
            content1: "Vérifiez votre boite e-mail pour pouvoir commencer.",
            content2:
              "Nous importons actuellement vos données. Nous  vous enverrons un e-mail quand ce sera fini.",
            content3:
              "Pendant ce temps, prenez une pause, vos données seront prêtes en 1 heure.",
          },
          Sync: {
            title: "Patientez un instant s’il vous plaît",
            content_one:
              "Nous importons l'hébergement que vous avez sélectionné ainsi que son calendrier depuis Airbnb.",
            content_other:
              "Nous importons les {{count}} hébergements que vous avez sélectionnés ainsi que leurs calendriers depuis Airbnb.",
            content2: "Cela peut prendre quelques minutes...",
            progressInfo_one: "{{processedJobs}} / {{count}} hébergement",
            progressInfo_other: "{{processedJobs}} / {{count}} hébergements",
            successTitle:
              "Vous avez réussi à importer vos hébergements depuis Airbnb !",
            successContent:
              "À savoir : vos hébergements importés ne sont pas encore synchronisés avec SuperHote. Vous pouvez commencer la synchronisation en vérifiant les paramètres de vos hébergements.",
          },
        },

        Booking: {
          hotelIdBookingLabel: "Hotel ID Booking",
          roomIdBookingLabel: "Hébergement Booking",
          rateIdBookingLabel: "Rate",
        },

        RentalsRules: {
          pageTitle: "Vérifiez les paramètres de vos hébergements",
          title: "Paramétrage de vos hébergements",
          bannerTitle:
            "Voici tous les hébergements associés à votre compte SuperHote",
          bannerContent:
            "À présent vous pouvez facilement vérifier et modifier les différentes règles de prix et de disponibilités qui impactent vos hébergements",
          bannerSubContent:
            "Nous avons initialisé votre calendrier avec les informations récupérées des plateformes.",
          bannerSubContent2:
            "Après la synchronisation, les prix / disponibilités seront envoyés par Superhote aux plateformes.",
          rentalsInSH_one: "hébergement présent sur Superhote",
          rentalsInSH_other: "hébergements présents sur Superhote",
          placeholderSearchRentals: "Rechercher des logements ...",
          capacity: "Capacité max",
          checkin: "Arrivée",
          checkout: "Départ",
          extraTraveller: "Voyageur supplémentaire",
          price: "Tarif",
          cleaningFees: "Frais de ménage",
          fees: "Frais",
          deposit: "Caution",
          holidayTax: "Taxe de séjour",
          priceMultiplier: "Price Multiplier",
          adults_one: "{{count}} adulte",
          adults_other: "{{count}} adultes",
          children_one: "{{count}} enfant",
          children_other: "{{count}} enfants",
          guest: "{{count}} voyageur",
          guests: "{{count}} voyageurs",
          basePrice: "Prix de base",
          minPrice: "Prix minimum",
          pricePerGuest: "Prix principal au-delà de",
          stay: "nuitée",
          timeIn: "Heure d'arrivée",
          timeOut: "Heure de départ",
          maxDaysBeforeCheckin: "Réservation maximum avant arrivée",
          minNights_one: "Nombre de nuit minimum : {{count}}",
          minNights_other: "Nombre de nuits minimum : {{count}}",
          maxNights_one: "Nombre de nuit maximum : {{count}}",
          maxNights_other: "Nombre de nuits maximum : {{count}}",
          validate: "Valider ces règles et lancer la synchronisation",
          updateModalTitle:
            "Modifier les règles et le prix de l'hébergement de ",
          minPrice: "Prix minimum",
          defaultPrice: "Prix de base",
          priceMultiplierOption:
            "Option avancée : compenser les commissions plateformes avec un multiplicateur de prix",

          Price: {
            title: "Prix",
            basePrice: "Prix de base",
            minPrice: "Prix minimum",
            maxGuest: "Nombre de voyageurs max",
            extraGuest: "Prix / personne supplémentaire",
            priceMultiplier:
              "Définissez vos majorations pour chaque plateforme",
            priceMultiplierDescription:
              "Nous utiliserons ce taux pour calculer le tarif final que nous transmettrons à chaque plateforme via les intégrations de tarification.",
            airbnbPriceMultiplier: "Airbnb PriceMultiplier",
            bookingPriceMultiplier: "Booking PriceMultiplier",
            holidayTypeTax: "Type taxe de séjour",
            fixed: "Fixe",
            perPercentage: "En pourcentage",
            adultTaxPerNight: "Taxe par adulte par nuit",
            nightPricePercentage: "Pourcentage du prix de la nuit",
            nightPriceLimitGuest: "Plafond par personne par nuit",
            adultTaxPercentagePerNight: "Taxes additionnelles",
            adultTaxPercentagePerNightInfo: "Somme des taxes additionnelles",
            priceMultiplierImpact: "Impact PriceMultiplier sur le prix de base",
            cleaning: "Frais de ménage",
            titleStep: "Tarification",
          },

          Deposit: {
            Booking: {
              noDeposit: "Aucune caution relative aux dommages",
              handleByBooking:
                "Gérée par Booking.com jusqu’à {{price}} par séjour",
              handleBySuperhote:
                "Gérée par vous-même jusqu’à {{price}} par séjour",
            },
            Direct: {
              direct: "En direct",
            },
          },

          Rules: {
            title: "Règles de disponibilité",
            checkin: "Plage horaire pour les arrivées",
            minTimeIn: "Arrivée à partir de",
            maxTimeIn: "Jusqu'à",
            checkout: "Plage horaire pour les départs",
            minTimeOut: "Départ à partir de",
            maxTimeOut: "Jusqu'à",
            minDaysBeforeCheckin:
              "Règle de réservation minimum avant arrivée (jours)",
            daytimeLimit: "Délai de réservation avant arrivée",
            minNights: "Nombre de nuits minimum",
            maxNights: "Nombre de nuits maximum",
            information:
              "Vous pourrez paramètrer des règles plus précises directement depuis la page de l'hébergement",

            limit_day: "Jours avant début du séjour",
            limit_hour: "Heure limite le jour même",
          },

          Multi: {
            title: "Appliquer sur d’autres hébergements",
            info: "Seules les règles de disponibilité et le pourcentage appliqué à votre prix plateforme seront appliqués aux hébergement selectionnés ci-dessous.",
            rentals: "Hébergements présents sur SuperHote",
            content:
              "En sélectionnant vos autres hébergements sur la liste ci-dessous, nous appliquerons les mêmes règles suivantes sur ces hébergements :",
            list1: "- % appliqué à votre prix plateforme (PriceMultiplier)",
            list2: "- Règles de disponibilité",
            reco: "Notre recommendation",
          },

          Sync: {
            title: "Patientez un instant s’il vous plaît",
            content:
              "Nous importons vos réservations des différentes plateformes et enregistrons vos règles d'hébergements sur SuperHote, cela peut prendre jusqu’à quelques minutes...",
            progressInfoAirbnbImport: "Import des réservations...",
            progressInfoBookingImport:
              "Transmission des règles d'hébergements...",
            progressInfoSendRestriction: "Synchronisation des calendriers...",
            successTitle: "Hébergements synchronisés avec SuperHote !",
            successContent:
              "Vos hébergements sont maintenant tous synchronisés avec SuperHote. Vous pouvez dès la prochaine étape vérifier les tarifs des nuitées.",
          },

          Infos: {
            title: "Informations",
            info: "Ces informations ont été récupérés depuis Airbnb. En cas de modification, cela n'impactera pas la plateforme.",
          },
          ReservationRules: {
            title: "Règles de réservations",
          },
          PaymentPolicies: {
            title: "Politique de paiement",
          },
        },
      },
      Subscription: {
        title: "Localisation",
        subTitle:
          "Vous n'avez pas encore sélectionné d'abonnement ou votre moyen de paiement est invalide. Veuillez choisir une offre afin d'accéder à SuperHote.",
        logout: "Se déconnecter",
      },
      Rental: {
        title: "Hébergements",
        infos: "Détails",
        prices: "Tarification",
        contract: "Contrats et reçus",
        services: "Services",
        platforms: "Plateformes",
        invoice: "Facture",
        checklists: "Checklists",
        guestPage: "Page voyageur",
        sync: "Synchroniser",
        traveler_page: "Page voyageur",
        websitePage: "Site de réservation",
        Localisation: {
          address: "Adresse de l'hébergement",
          title: "Localisation",
          longitude: "Longitude",
          latitude: "Latitude",
          content1:
            "Epinglez l'emplacement exact de votre hébergement, afin de mettre à jour la latitude et la longitude généréees par défaut.",
          content2:
            "Ces élements sont nécessaires pour les instructions d'arrivée et pour la tarification dynamique.",
        },

        RentalDeleteModal: {
          title:
            "Voulez-vous vraiment supprimer définitivement cet hébergement ?",
          description:
            "ATTENTION: La suppression de cet hébergement est irréversible.",
        },

        Infos: {
          TravelerPage: {
            contact: "Contact",
            FormCard: {
              title: "Formulaire d’accès à la page voyageur",
              additionnalQuestionLabel:
                "Question supplémentaire pour le voyageur",
              additionnalQuestionAnswerLabel:
                "Réponse à la question supplémentaire pour le voyageur",
              info1:
                "Le voyageur n’aura pas accès directement à la page voyageur. Il devra remplir ses informations dans un formulaire avant.",
              info2: "Documents demandés",
              info3:
                "Ces documents seront demandés au voyageur dans le formulaire d’accès à la Page voyageur. Vous retrouverez les documents importés par le voyageur dans le détail de la réservation.",
              info4: "Formulaire d'accès à la page voyageur désactivé.",
              info5:
                "Le voyageur aura accès directement à la page voyageur, sans qu’il ait besoin de remplir ses informations ou qu’il ait à répondre à des questions.",
              info6:
                "Si vous souhaitez demander des documents (carte d’identité par exemple), nous vous conseillons d’activer le formulaire d’accès à la page voyageur.",
            },
            ContractCard: {
              title: "Contrat de location à signer : {{contractName}}",
              info1:
                "<p className='text-low-contrast'>Si vous activez le contrat de location à signer, chaque voyageur devra <strong>signer le contrat de location</strong> tel que défini dans l’onglet <strong>Contrats</strong> de votre hébergement</p>",
              info2:
                "La signature de ce contrat sera demandée lors de la première connexion du voyageur à la page voyageur, après le formulaire d’accès si celui-ci est activé.",
              info3:
                "Sans signer ce contrat, le voyageur ne pourra pas accéder aux autres informations.",
              info4:
                "Le contrat ne sera par contre jamais demandé pour les réservations venant d’Airbnb. ",
            },
            resource_requested: "Resources demandées",
            requested: "Demandée",
            notRequest: "Non demandée",
            arrival_instruction: "Instructions d'arrivée",
            checkout_procedure: "Procédure de départ",
            welcome_guide: "Guide de bienvenue",
            activeTravelerPage: "Activer la page voyageur pour le voyageur",
            openPreview: "Voir l'aperçu",
            InstructionCard: {
              title: "Informations pour le voyageur",
              editTitle:
                " Si cette option est activée, les instructions d'arrivée seront\n" +
                "visible par le voyageur.",
              addShortCodeTitle:
                "N’hésitez pas à utiliser des champs dynamiques comme [Guest_Firstname] ou [RESERVATION_ALL_DOOR_CODE] par exemple, pour personnaliser le contenu",
              photosOrVideos: "Photos ou vidéos",
              file: "Fichier",
              frenchRequired: "Le français est obligatoire",
              photosVideosFormat: "Formats acceptés : jpg,jpeg,png,mp4,avi,mov",
              photosSize: "Poids maximum par image : 10 Mo",
              videosSize: "Poids maximum par vidéo : 500 Mo",
              videosSizeError:
                "Le poids maximum par vidéo ne doit pas dépasser 500 Mo",
              dimensionsMin: "Dimensions minimum pour les images : 1024 x 683",
              descriptionFile:
                "Si vous avez déja un document pdf qui explique la procédure par exemple.",
              checkinDescription: "Pour l'entrée dans l'hébergement",
              checkoutDescription: "Pour la sortie de l'hébergement",
              welcomeDescription: "Guide de l'hôte",
            },
            RessourceCard: {
              title:
                "Ces documents seront demandés au voyage sur la page de\n" +
                "connexion de la Page voyageur. Vous retrouverez depuis le detail de la réservation.",
              national_identity_card: "Carte nationale d'identité",
              passport: "Passeport",
              selfie_photo: 'Photo "selfie"',
              cb: "Carte bancaire avec champs cachés",
              police_record: "Fiche de police",
            },
            PaymentExtraCard: {
              title: "Paiement des extras",
              stripeTitle: "Compte Stripe pour encaisser les extras",
              emptyStripeTitle: "Aucun compte stripe connecté à Superhote.",
              addStripeAccount: "Ajouter un compte Stripe",
              by_stripe: "Via Stripe",
              choiceAccountStripe:
                "Choisir un compte Stripe pour encaisser les extras",
            },
            RequestOptionCard: {
              title: "Demande d'avis",
              visibility: "Visibilité",
              textDemand: "Texte de la demande",
              daysAfterStay: "Jours après le séjour",
              dayAfterStay: "Jour après le séjour",
            },
            WifiCard: {
              title: "Connexion Wifi",
              wifiName: "Réseau",
              wifiPassword: "Mot de passe",
              wifiNamePlaceholder: "Wifi_{{hostname}}",
              wifiRequired: "Le nom du WiFi et le mot de passe sont requis.",
            },
            CodeCard: {
              title: "Code d'accès",
            },
            CgvCard: {
              title: "Conditions générales de vente des extras",
              cgvPlaceholder:
                "Renseigner les conditions générales de vente des extras qui seront affichées au voyageur.",
              requiredCgv:
                "Les conditions générales de vente sont obligatoires.",
            },
            ExtraCard: {
              title: "Extras",
              descriptionExtra:
                "Les extras sont un nouveau moyen pour vous de vendre des produits ou des services à votre voyageur, afin d'augmenter votre chiffre d'affaires.",
              infosExtra:
                "Ces extras ont été récupérés automatiquement depuis l'onglet Page Voyageur de cet hébergement. Ils sont synchronisés automatiquement avec cette page.",
              extra: "Extra",
              add: "Ajouter un extra",
              edit: "Modifier l'extra",
              price: "Prix",
              addExtra: "Ajouter un extra",
              actif: "Actif",
              inactif: "Inactif",
              extra_active_description:
                "Si l'extra est actif, il est achetable par le voyageur.Sinon , il n'apparaitra pas dans la liste des extras.",
              extra_active: "Extra actif",
              switchDisableToolTip:
                "Veuillez ajouter un compte stripe pour encaisser des extras.",
              name: "Nom",
              description: "Description",
              cost_price: "Coût de revient",
              cost_price_novisible: "Non visible par le voyageur",
              unit_price: "Prix unitaire",
              marge: "Marge : {{amount}} {{currency}}",
              maximum_quantity: "Maximum par réservation",
              available_until: "Disponible à partir de",
              duplicate: "Dupliquer l'extra",
              sms: "SMS",
              mail: "E-mail",
              deleteExtraTitle: "Voulez-vous vraiment supprimer cet extra ?",
              deleteExtraSubtitle:
                "Cela aura pour effet de supprmer l'extra et les informations associées.",
              validationTilte: "Validation nécessaire",
              validationContent:
                "Vous aurez 48h pour accepter ou refuser la demande. Dans le cas d’un refus, le voyageur sera remboursé. Si la validation n’est pas cochée, le voyageur sera débitée immédiatement, sauf dans le cas d’une commande groupée.",
              deletePictureTitle:
                "Voulez-vous vraiment supprimer votre photo ?",
              available: "Disponible",
              immediatelyAfterReservation: "Immédiatement après la réservation",
              immediatelyAfterCheckin: "Immédiatement après l'arrivée",
              onHourLaterCheckin: "1 heure après l'arrivée",
              fourHourLaterCheckin: "4 heures après l'arrivée",
              fourHourAfterCheckout: "4 heures après le départ",
              untilCheckout: "Jusqu'au départ",
              onDayAfterCheckin: "1 jour après l'arrivée",
              emptyExtra: "Aucun extra ajouté",
            },
            ContactCard: {
              emailFieldLabel: "Email",
              nameFieldLabel: "Nom",
              firstNameFieldLabel: "Prénom",
              firstnameAndName: "Prénom et nom",
              phoneFieldLabel: "Téléphone",
              phoneFieldErrorLabel: "Numéro de téléphone",
            },
            Preview: {
              mobile: "Mobile",
              desktop: "Bureau",
            },
          },
          Infos: {
            activeRentalModalTitle:
              "Voulez-vous vraiment activer cet hébergement ?",
            activeRentalModalDescription1:
              "Cela aura pour effet de vous donner accès à toutes les fonctionnalités de Superhote sur ce logement, dont les messages automatisés.",
            activeRentalModalDescription3:
              "Vous pouvez désactiver ce logement dès que vous le souhaitez.",
            title: "Informations",
            nameFieldLabel: "Nom de votre hébergement",
            categoryFieldLabel: "Catégorie d'hébergement",
            typeFieldLabel: "Type d'hébergement",
            rentingTypeFieldLabel: "Type d'annonce",
            managementTypeFieldLabel: "Type de gestion",
            addressFieldLabel: "Adresse",
            cityFieldLabel: "Ville",
            postalCodeFieldLabel: "Code postal",
            countryFieldLabel: "Pays",
            maxAdultsFieldLabel: "Nombres d'adultes maximum",
            maxChildrenFieldLabel: "Nombres d'enfants maximum",
            registerNumber: "Numéro d'enregistrement du bien",
            maxTravellersFieldLabel: "Nombres de voyageurs maximum",
            maxBathroomsFieldLabel: "Salles de bains",
            minCheckinHourFieldLabel: "Heure d'arrivée minimum",
            minCheckinHourError:
              "L'heure d'arrivée minimum doit être avant l'heure d'arrivée maximum",
            maxCheckinHourFieldLabel: "Heure d'arrivée maximum",
            minCheckoutHourFieldLabel: "Heure de départ minimum",
            minCheckoutHourError:
              "L'heure de départ minimum doit être avant l'heure de départ maximum",
            maxCheckoutHourFieldLabel: "Heure de départ maximum",
            minNightsFieldLabel: "Nombres de nuits minimum",
            maxNightsFieldLabel: "Nombres de nuits maximum",
            parentRentalFieldLabel: "Hébergement parent",
            childRentalFieldLabel_one: "Hébergement enfant",
            childRentalFieldLabel_other: "Hébergements enfants",
            removeActiveRentalModalTitle:
              "Voulez-vous vraiment désactiver cet hébergement ?",
            removeActiveRentalModalDescription1:
              "Cela aura pour effet de couper toutes les fonctionnalités de Superhote sur ce logement, dont les messages automatisés.",
            removeActiveRentalModalDescription3:
              "Vous pouvez réactiver ce logement dès que vous le souhaitez.",
            activedRental: "Hébergement actif",
            activedRentalSubtitle: "Le logement est publié et visible.",
            inactivedRental: "Hébergement inactif",
            inactivedRentalSubtitle:
              "Le logement n'est pas synchronisé avec les plateformes et les fonctionnalités SuperHote sont désactivées.",
            bedroom_one: "Chambre : {{count}}",
            bedroom_other: "Chambres : {{count}}",
            bed_one: "Lit : {{count}}",
            bed_other: "Lits : {{count}}",
            bathroom_one: "Salle de bain : {{count}}",
            bathroom_other: "Salles de bains : {{count}}",
            RentalTypeItems: {
              loft: "Loft",
            },
            RentingTypeItems: {
              entireHouse: "Logement entier",
              singleRoom: "Chambre seule",
              sharedRoom: "Chambre partagée",
            },
            ManagementTypeItems: {
              ownProperty: "Investissement immobilier (Propriétaire du bien)",
              concierge: "Conciergerie",
              subletting: "Sous-location",
            },
            inactiveStatusTooltip:
              "Le logement n'est pas synchronisé avec les plateformes et les fonctionnalités Superhote sont désactivées",
            activeStatusTooltip:
              "Le logement est synchronisé avec les plateformes et les fonctionnalités Superhote sont activées",
            startTimeArrival: "Heure début arrivée",
            endTimeArrival: "Heure max arrivée",
            startTime: "Heure de départ",
            tooltipMinCheckin:
              "L'heure d'arrivée minimum est l'heure à partir de laquelle le voyageur peut arriver dans l'hébergement",
            tooltipMaxCheckin:
              "L'heure d'arrivée maximum est l'heure jusqu'à laquelle le voyageur peut arriver dans l'hébergement",
            tooltipMinCheckout:
              "L'heure de départ minimum est l'heure à partir de laquelle le voyageur peut rendre l'hébergement",
            tooltipMaxCheckout:
              "L'heure de départ maximum est l'heure jusqu'à laquelle le voyageur peut rendre l'hébergement",
            tooltipStartTime:
              "L'heure de départ est l'heure maximum à laquelle le voyageur doit rendre l'hébergement",
            minNight: "Nombre de nuits minimum",
            maxNight: "Nombre de nuits maximum",
            selectRentalLabel: "Sélectionnez un hébergement",
            descriptionParentRental:
              "Ce logement est disponible seulement si celui-ci est disponible :",
            labelParentRental: "Hébergement parent",
            tooltipParentRental:
              "L'hébergement parent est l'hébergement qui contient cet hébergement",
            descriptionChildRental:
              "Ce logement influera sur la disponibilité de ceux-ci : ",
            tooltipChildRental:
              "Les hébergements enfants sont les sous-hébergements de cet hébergement",
          },
          Photos: {
            title: "Photos",
            updateModalTitle: "Modifier les photos",
            updateModalLabel:
              "Faites glisser et déposez les images pour modifier leur ordre sur les plateformes.",
          },
          Description: {
            title: "Description",
          },
          Platforms: {
            title: "Connexions externes",
            bookingPlatforms: "Plateformes de réservations",
            active: "Actif",
            inactive: "Inactif",
            airbnbAssign: "Associé au compte",
            bookingAssign: "Associé à l'identifiant Booking",
            ical: "Calendrier iCal de l'hébergement",
            copyIcal: "Copier le lien du calendrier iCal",
            desynchronize: "Désynchroniser",
            synchronize: "Synchroniser",
            Add: {
              title: "Ajouter une autre plateforme",
              description1:
                "Pour le moment, seules les plateformes suivantes sont officiellement connectées à SuperHote : Airbnb, Booking et Abritel (VRBO).",
              description2:
                "Néanmoins, vous pouvez tout de même renseiger le nom des plateformes que vous utilisez, pour que nous puissions les connecter dans le futur.",
              nameFieldLabel: "Nom de la plateforme",
              nameFieldPlaceHolder: "Le nom de la plateforme",
              icalFieldLabel: "Lien Ical",
              descriptionIcal:
                "Pour importer automatiquement vos réservations depuis cette plateforme vers SuperHote.",
              availabilityFieldLabel: "Gestion des disponibilités",
              importNonAvailableDatesFieldLabel:
                "Importer les dates bloquées ou déjà présentes dans le calendrier SuperHote ?",
            },
            Airbnb: {
              title: "Paramètrer Airbnb",
              view: "Voir l'annonce",
              emailFieldLabel: "Email",
              passwordFieldLabel: "Mot de passe Airbnb",
              adFieldLabel: "Annonce",
            },
            SynchronizeModal: {
              title: "Synchronisation de l'hébergement avec Booking.com",
              content1:
                "Une fois l'hébergement synchronisé avec Booking.com, les éléments suivants de SuperHote seront transmis à la plateforme :",
              content2:
                "Veuillez donc attentivement les vérifier avant la synchronisation.",
              content3: "Heure d'arrivée et de départ",
              content4: "Prix des nuitées définis dans le calendrier",
              content5: "Nombre de nuits minimum et maximum",
              content6:
                "La TVA, le montant de la caution, les frais de ménages et les taxes de séjour sont à configuer directement sur admin.Booking.com",
              cancelTitle:
                "Attention, vous quittez le parcours de synchronisation de Booking.com sans avoir validé.",
              cancelTitleAirbnb:
                "Attention, vous quittez le parcours de synchronisation de Airbnb sans avoir validé.",
              cancelDescription:
                "Vous modifications ne seront pas prises en compte. Pour les enregistrer, veuillez  finaliser le parcours et cliquer sur 'Synchroniser'.",
              titleAirbnb: "Synchronisation de l'hébergement avec Airbnb",
              contentAirbnb1:
                "Une fois l'hébergement synchronisé avec Airbnb, les éléments suivants de SuperHote seront transmis à la plateforme :",
              contentAirbnb2: "Nombre de voyageurs",
              contentAirbnb3: "Frais de ménage et de voyageur supplémentaire",
              Airbnb: {
                airbnbSynchronizeStep1Title: "Choix du compte Airbnb",
                airbnbSynchronizeStep2Title: "Choix de l'annonce à importer",
                airbnbSynchronizeStep1SelectTitle: "Compte airbnb",
                airbnbSynchronizeStep2Description:
                  "Choissisez l'annonce qui correspond à l'hébergement",
                airbnbSynchronizeStep2SelectTitle: "Annonce",
              },
            },
            Booking: {
              title: "Paramètrer Booking",
              view: "Voir l'annonce",
              email: "E-mail",
              emailCopy: "Email en copie des communications / notifications",
              hotelId: "Hotel id",
              getCode: "Obtenir les codes",
              roomId: "Type d'hébergement",
              rate: "Plan tarifaire",
              tutorial:
                "Tutoriel : Comment configurer et  synchroniser Booking ?",
              disconnect: "Déconnecter",
            },
            ConfirmDesynchronizeModal: {
              title:
                "Voulez-vous vraiment déconnecter cet hébergement de {{platform}}",
              description:
                "Cela aura pour effet de supprimer la synchronisation du compte. Vos réservations passées ne seront pas impactées.",
              description2:
                "Veuillez choisir comment vous voulez gérer vos réservations futures :",
              deleteFutureReservations:
                "Supprimer toutes les réservations futures",
              deleteFutureReservationsDescription:
                "Les réservations futures seront supprimées de SuperHote mais toujours accessibles depuis {{platform}}.",
              keepFutureReservations:
                "Conserver toutes les réservations futures",
              keepFutureReservationsDescription:
                "Les messages / conversations ne seront plus synchronisés sur SuperHote mais toujours visibles.",
            },
            otherSource: "Autres sources de réservation",
            actif: "Actif",
            inactif: "Inactif",
            connectedOther: "Connecter une autre plateforme",
            isCopyIcal: "Lien du calendrier iCal copié !",
            syncWithOtherCalendar:
              "Synchroniser le calendrier avec une autre plateforme",
            syncWith: "Synchronisation avec {{platform}}",
            stepOne:
              "Si vous souhaitez synchroniser le calendrier de cette autre plateforme avec le calendrier SuperHote, vous devez ajouter le lien ICAL Superhote sur l’autre plateforme.",
            stepTwo:
              "Puis, vous devez renseigner le nom de la plateforme et le lien ICal fournit par la plateforme.",
            platformName: "Nom de la plateforme",
            platformPlaceholder: "Le nom de la plateforme",
            linkIcal: "Lien ICal SuperHote",
            externalLinkIcal: "Lien ICal (finit par .ics)",
            externalLinkIcalPlaceholder:
              "Le lien ICal fournit par la plateforme",
            help: "Ainsi, les deux calendriers seront mis à jour chaque fois qu’une nuit est réservée.",
            requiredPlatformName: "Nom de la plateforme requise.",
            requiredIcalendarLink: "Lien icalendar requis.",
            invalidIcalendarLink: "Lien icalendar invalide.",
          },
          Facilities: {
            title: "Équipements",
            title_common: "Les plus communs",
            title_room: "Chambre",
            title_bathroom: "Salle de bain et buanderie",
            title_kitchen: "Cuisine",
            title_clim: "Chauffage et climatisation",
            title_parking: "Parking et installations",
            title_desk: "Internet et bureau",
            title_place: "Caractéristiques de l’emplacement",
            title_services: "Services",

            base_equipments:
              "Équipements de base (Serviettes, draps, savon, papier toilette et oreillers)",
            linens: "Linge de maison fourni",
            kitchen_base:
              "Équipements de cuisine de base (Casseroles et poêles, huile, sel et poivre)",
            cleaning: "Produits de nettoyage",
            cutlery: "Vaisselle et couverts",
            clim: "Climatisation",
            heating: "Chauffage",
            washing_machine: "Machine à laver",
            dryer: "Sèche-linge",
            hair_dryer: "Sèche-cheveux",
            parking: "Parking gratuit sur place",
            Wifi: "Internet sans fil - Wifi",
            simple_bed: "Lit simple",
            double_bed: "Lit double",
            king_size_bed: "Lit king size",
            murphy_bed: "Lit escamotable",
            queen_size_bed: "Lit grand format (queen)",
            sofa_bed: "Canapé-lit/futon",
            cradle_bed: "Berceau",
            crib_bed: "Lit de bébé portatif",
            bunk_bed: "Lit superposé",
            pack_n_play_travel_crib: "Lit parapluie",
            wardrobe_or_closet: "Espace de rangement pour les vêtements",

            bath_tub: "Baignoire",
            jetted_tub: "Baignoire à jets",
            bidet: "Bidet",
            shower: "Douche",
            sink: "Lavabo",
            soap: "Savon pour le corps",
            conditioner: "Après-shampoing",
            outdoor_shower: "Douche extérieure",
            shampoo: "Shampooing",
            shower_gel: "Gel douche",
            wc: "WC",
            towels: "Serviettes de bain",
            laundry_dryer: "Étendoir à linge",
            iron: "Planche et fer à repasser",
            hot_water: "Eau chaude",
            baby_bath: "Baignoire pour bébés",
            changing_table: "Table à langer",
            beach_essentials: "Produits indispensables pour la plage",

            hotplates: "Plaques de cuisson",
            bbq: "Ustensiles de barbecue (Barbecue, charbon, brochettes en bambou ou en métal, etc.)",
            bread_maker: "Machine à pain",
            blender: "Blender",
            coffee: "Café",
            coffee_maker: "Cafetière",
            dinner_table: "Table à manger",
            dishwasher: "Lave-vaisselle",
            freezer: "Congélateur",
            electric_kettle: "Bouilloire électrique",
            kitchen: "Cuisine",
            kitchenette: "Kitchenette",
            microwave: "Four à micro-ondes",
            mini_fridge: "Mini réfrigérateur",
            hoven: "Four",
            fridge: "Réfrigérateur",
            rice_cooker: "Cuiseur à riz",
            stove: "Cuisinière",
            toaster: "Grille-pain",
            waste_compactor: "Compacteur de déchets",
            wine_glasses: "Verres à vin",
            wood_stove: "Poêle à bois",
            spices: "Fonds de cuisine / épices",
            high_chair: "Chaise haute",
            ice_machine: "Machine à glaçons",
            kitchen_island: "Îlot de cuisine",
            vacuum: "Aspirateur",
            childrens_dinnerware: "Vaisselle pour enfants",
            barbeque_utensils: "Ustensiles de barbecue",
            dishes_and_silverware: "Vaisselle et couverts",
            refrigerator: "Réfrigérateur",
            rice_maker: "Cuiseur à riz",

            ceiling_fan: "Ventilateur de plafond",
            chimney: "Cheminée",
            portable_fan: "Ventilateurs portables",
            shared_pool: "Piscine commune",
            heated_pool: "Piscine chauffée",
            indoor_pool: "Piscine intérieure",
            private_pool: "Piscine privée",
            jacuzzi: "Jacuzzi",
            sauna: "Sauna",
            parking_street: "Parking gratuit dans la rue",
            gym: "Salle de sport",
            parking_paid_outside:
              "Stationnement payant à l'extérieur de la propriété",
            parking_paid: "Parking payant sur place",
            garden: "Jardin",
            monoxide_detector: "Détecteur de monoxyde de carbone",
            extinguisher: "Extincteur d'incendie",
            first_aid_kit: "Kit de 1er secours",
            smoke_detector: "Détecteur de fumée",
            game_room: "Salle de jeux",
            garage: "Garage",
            children_books: "Livres pour enfants",
            books: "Livres",
            dvd: "Lecteur DVD",
            baby_foot: "Baby-foot",
            games: "Jeux",
            cds: "CD-thèque",
            pingpong: "Table de ping-pong",
            pool_table: "Table de billard",
            cable_satellite: "Câble/satellite",
            connected_tv: "Téléviseur connecté",
            hifi_system: "Chaîne Hi-Fi",
            tv: "Télévision",
            toys: "Jouets",
            video_games: "Jeux vidéo",
            movies: "Vidéothèque",
            car_charger: "Chargeur de voiture",
            fire_emergency_contact: "Contact d'urgence incendie",
            brasero: "Brasero",
            baby_monitor: "Système de surveillance de bébés",
            arcade_machine: "Jeux d'arcade",
            batting_cage: "Cage d'entraînement de baseball",
            bowling_alley: "Piste de bowling",
            climbing_wall: "Mur d'escalade",
            laser_tag: "Laser game",
            life_size_games: "Jeux géants",
            mini_golf: "Minigolf",
            home_theater: "Cinéma",
            piano: "Piano",
            record_player: "Tourne disque",
            sound_system: "Système audio",
            theme_room: "Pièce à thème",
            childrens_bike: "Vélos pour enfants",
            playroom: "Salle de jeux pour enfants",
            stair_gates: "Barrières de sécurité pour bébé",
            board_games: "Jeux de société",
            fireplace_guards: "Pare-feu pour la cheminée",
            playground: "Aire de jeux extérieure",
            outlet_covers: "Caches-prises",
            table_corner_guards: "Protections sur les coins de tables",
            window_guards: "Protections sur les fenêtres",
            laptop_friendly_workspace: "Espace de travail dédié",
            resort_access: "Accès au complexe hôtelier",
            bicycle: "Vélos",
            boat_slip: "Cale de mise à l'eau pour bateaux",
            alfresco_dining: "Espace repas en plein air",
            outdoor_seating: "Mobilier d'extérieur",
            patio_or_balcony: "Patio ou balcon",
            sun_loungers: "Chaises longues",
            elevator: "Ascenseur",
            hockey_rink: "Patinoire de hockey",
            pool: "Piscine",

            private_living_room: "Salon privé",

            desk: "Espace / bureau de travail dédié",
            computer_screen: "Écran d’ordinateur",
            desk_chair: "Chaise de bureau",
            wifi: "Internet sans fil - Wifi",
            wif_portable: "Wi-Fi portable",
            wifi_paid: "Wi-Fi en supplément",
            printer: "Imprimante",
            phone: "Téléphone",
            cabinet_locks: "Serrures d’armoires",
            ethernet_connection: "Connexion Ethernet",

            beach: "Plage",
            laundromat: "Laverie automatique à proximité",
            private_access: "Entrée privée",
            hotel_access: "Accès au complexe hôtelier",
            ski_access: "Accessible à skis",
            beachfront: "Front de mer",
            duty_free: "Magasins duty-free",
            spa: "Centre spa/bien-être",
            marina: "Marina",
            museums: "Musées",
            amusement_park: "Parcs d'attraction",
            water_park: "Parcs aquatiques",
            wine_tours: "Visites de vignobles",
            zoo: "Zoo",
            bird_watching: "Observation des oiseaux",
            casino: "Casinos",
            horse_riding: "Équitation",
            factory_outlets: "Magasins d’usine",
            shopping: "shopping",
            water_sports: "Sports aquatiques",
            whale_watching: "Observation des baleines",
            fauna_watching: "Observation de la faune et de la flore",
            fitness: "Centre de fitness",
            hospital: "Hôpital",
            city_center: "Centre-ville",
            golf: "À proximité du terrain de golf",
            golf_view: "Vue sur le terrain de golf",
            lake: "Lac",
            lake_border: "Bord de plan d'eau",
            lake_view: "Vue sur le lac",
            mountain: "Montagne",
            mountain_view: "Vue sur la montagne",
            see: "Proche de la mer",
            see_border: "Bord de mer",
            see_view: "Vue sur la mer",
            holiday_village: "Village vacances tout compris",
            river: "Fleuve/rivière",
            campaign: "Campagne",
            slopes_access: "Accès depuis les pistes",
            access_to_slopes: "Accès aux pistes",
            city: "Ville",
            Village: "Village",
            river_view: "Vue sur lac/rivière",
            basket_ball: "Terrain de basket-ball",
            alpin_ski: "Ski alpin",
            cycling: "Cyclisme",
            deep_sea_fishing: "Pêche en haute-mer",
            fishing: "Pêche",
            coastal_fishing: "Pêche côtière",
            fly_fishing: "Pêche à la mouche",
            freshwater_fishing: "Pêche en eau douce",
            angling: "Pêche à la ligne",
            optional_golf_package: "Forfait golf optionnel",
            hiking: "Randonnée",
            hunting: "Chasse",
            ice_skating: "Patinage sur glace",
            jet_ski: "Jet-ski",
            kayaking: "kayaking",
            vtt: "VTT",
            escalation: "Escalade",
            alpinism: "Alpinisme",
            parasailing: "Parachute ascensionnel",
            pontoon_fishing: "Pêche de ponton",
            rafting: "Rafting en eau blanche",
            rock_climbing: "rock climbing",
            veil: "Voile",
            diving: "Plongée",
            optional_lift_pass: "Forfait remontées mécaniques optionnel",
            ski_lift_pass: "Forfait remontées mécaniques",
            ski: "Ski",
            ski_nautic: "Ski nautique",
            snorkelling: "Plongée avec tuba",
            snowboarding: "Snowboarding",
            snowmobile: "Motoneige",
            potholing: "Spéléologie",
            surf: "Surf",
            bathing: "Baignade",
            towed_buoy: "Bouée tractée",
            sailboard: "Planche à voile",
            wheelchair_accessible: "Accessible en fauteuil roulant",
            wheelchair_not_accessible: "Non accessible en fauteuil roulant",
            family: "En famille",
            historical: "Historique",
            romantic: "Romantique",
            single_level_home: "Logement de plain-pied",

            breakfast_reservation: "Petit déjeuner sur réservation",
            breakfast_included: "Petit déjeuner inclus dans le prix",
            housekeeping_available: "Ménage disponible pendant le séjour",
            housekeeping_included: "Ménage inclus",
            housekeeping_optional: "Ménage optionnel",
            long_stay_ok:
              "Séjours longue durée autorisés (Séjours de 28 jours ou plus autorisés)",
            luggage_storage_ok:
              "Dépôt de bagages autorisé (Pour le confort des voyageurs en cas d'arrivée anticipée ou de départ tardif)",
            meal_delivery_service: "Service de livraison de repas",
            car_available: "Voiture disponible",
            driver: "Chauffeur",
            concierge: "Concierge",
            massage: "Massage",
            private_cooker: "Chef cuisinier privé",
            babysitter_recommendations: "Recommandations de baby-sitters",
          },
          ReservationRules: {
            title: "Règles de réservations",
            reservationRules: "Règles sur les réservations",
            dayBeforeLabel_one: "{{count}} jour avant",
            dayBeforeLabel_other: "{{count}} jours avant",
            dayBeforeLabel_0: "Le jour même jusqu'a {{time}}",
            advanceReservationNoticeTitle: "Délai de réservation avant arrivée",
            advanceReservationNoticeDescription:
              "Durée jusqu'à laquelle il est possible de réserver",
            advanceReservationNoticePlaceholder: "Heure limite pour réserver",
            hourLabel: "Heure limite le jour meme",
            dayLabel: "Jours avant début du séjour",
            beforeStartHoliday: "Avant début du séjour",
            windowReservationTitle: "Plage de disponibilité",
            windowReservationDescription:
              "Jusqu'à quand peut-on réserver à l'avance ?",
            WindowReservationFields: {
              onTheDay: "Le jour même",
              atLeastDay_one: "Au moins {{count}} jour",
              atLeastMonth: "{{count}} mois",
              atLeastDay_other: "Au moins {{count}} jours",
            },
            setUpTimeTitle: "Temps de préparation",
            setUpTimeDescription:
              "Nuits bloquées avant et après chaque réservation",
            SetUpFields: {
              none: "Aucune",
              nbNightAfterReservation_one:
                "{{count}} nuit avant et après chaque réservation",
              nbNightAfterReservation_other:
                "{{count}} nuits avant et après chaque réservation",
            },
            rentalRules: "Règles sur l'hébergement",
            smokingRentalTitle: "Logement fumeur",
            smokingRentalDescription:
              "Il est autorisé de fumer dans l'hébergement",
            smokingRentalTooltip:
              "Utilisé dans le moteur de réservation et sur Airbnb",
            babiesAllowedTitle: "Bébés autorisés",
            babiesAllowedDescription: "Enfant de moins de 2 ans",
            babiesAllowedTooltip:
              "Utilisé dans le moteur de réservation et sur Airbnb",
            childrenAllowedTitle: "Enfants autorisés",
            childrenAllowedDescription: "Mineurs autorisés",
            childrenAllowedTooltip:
              "Utilisé dans le moteur de réservation et sur Airbnb",
            animalsAllowedTitle: "Animaux autorisés",
            animalsAllowedDescription:
              "Chiens, chats et autres animaux autorisés",
            animalsAllowedTooltip:
              "Utilisé dans le moteur de réservation et sur Airbnb",
            eventsAllowedTitle: "Évenements autorisés",
            eventsAllowedDescription: "Fêtes, anniversaires, ...",
            eventsAllowedTooltip:
              "Utilisé dans le moteur de réservation et sur Airbnb",
            otherConditionsTitle: "Autres conditions",
            otherConditionsDescription:
              "Conditions personnalisées : texte ou URL",
            otherCondtionsPlaceholder: "Décrivez vos conditions",
          },
          Note: {
            title: "Remarques",
            noteFieldLabel:
              "Les remarques ci-dessous ne seront pas visible par les utilisateurs",
          },
          Bedrooms: {
            title: "Pièces",
            noteFieldLabel:
              "Les remarques ci-dessous ne seront pas visible par les utilisateurs",
          },
        },
        Pricing: {
          PricingRules: {
            title: "Règles de tarification",
            currencyTitle: "Devise",
            currencyDesc: "Pour l'ensemble des tarifs de cet hébergement",
            priceTitle: "Prix de base",
            priceDesc: "Par nuit",
            minPriceTitle: "Prix minimum",
            minPriceDesc: "Par nuit",
            vatTitle: "TVA",
            vatDesc: "Par nuit",
            taxTitle: "Taxe de séjour",
            taxDesc: "La taxe de séjour par voyageur par nuit",
            taxType: "Type taxe de séjour",
            taxFixed: "Fixe",
            taxPercent: "Pourcentage",
            priceNightPerPerson: "Prix par personne par nuit",
            percentPriceNight: "Pourcentage du prix de la nuit",
            ceilingPerPersonPerNight: "Plafond par personne par nuit",
            additionalTax: "Taxe additionelles",
            currencyError: "Requis",
            priceError: "Requis",
            minPriceError: "Requis",
            vatError: "Requis",
            taxError: "Requis",
            MeansOfPaymentSettingsModal: {
              title: "Paramètrer les moyens d'encaissement",
              description:
                "Ces moyens d'encaissements pourront ensuite être utilisés dans vos politiques de paiements afin de créer vos calendriers de paiement.",
              typeFieldLabel: "Type",
              addAccountTitle: "Vous souhaitez ajouter un compte Stripe ?",
              addAccountDescription:
                "Stripe est utilisé par Superhote pour encaisser les paiements. Si vous souhaitez créer un compte ou en connecter un à Superhote, cliquez ci-dessous.",
              addAccountButtonLabel: "Ajouter un compte Stripe",
            },
            toGuests: "Au delà de combien de voyageur ?",
            ToGuestFees: "Au delà de {{count}} voyageurs",
            toGuestFeesUnactived:
              "Ce frais est applicable uniquement pour des logements de plus d'une personne.",
            toGuestFeesHelp:
              "{{price}} {{currency}} par voyageur supplémentaire et par nuit, au delà de {{guests}} voyageurs (Calculé automatiquement)",
          },
          MeansOfPayment: {
            title: "Moyens de règlement",
            airbnbPaymentTitle: "Paiement Airbnb",
            airbnbPaymentDescription: "Moyen de règlement",
            airbnbDepositTitle: "Caution Airbnb",
            airbnbDepositDescription: "Moyen de règlement",
            bookingPaymentTitle: "Paiement Booking",
            bookingPaymentDescription: "Moyen de règlement",
            bookingDepositTitle: "Caution Booking",
            bookingDepositDescription: "Moyen de règlement",
            paymentWebsiteTitle:
              "Paiement Moteur de réservations / site internet",
            paymentWebsiteDescription: "Moyen de règlement",
            byPlatform: "Via plateforme",
          },
          PaymentWays: {
            title: "Moyens de règlement",
            airbnbPaymentTitle: "Paiement Airbnb",
            airbnbPaymentDescription: "Moyen de règlement non modifiable",
            airbnbDepositTitle: "Caution Airbnb",
            airbnbDepositDescription: "Moyen de règlement non modifiable",
            bookingPaymentTitle: "Paiement Booking",
            bookingPaymentDescription: "Moyen de règlement",
            bookingDepositTitle: "Caution Booking",
            bookingDepositDescription: "Moyen de règlement",
            paymentWebsiteTitle:
              "Paiement Moteur de réservations / site internet",
            paymentWebsiteDescription: "Moyen de règlement",
            byPlatform: "Via plateforme {{platform}}",
            bySuperhote: "Via Superhote",
            bankTransfer: "Virement",
            BookingModal: {
              title: "Paramétrer le paiement et la caution Booking",
              infosLabel: "Informations",
              infosTitle:
                "Si vous choisissez le paiement via Booking, voici les frais :",
              infosDescription:
                "Booking.com prélève 7% de frais sur les réservations. Pour une nuit à 100€, vous toucherez 93€.",
              paymentFieldLabel: "Paiement",
              depositFieldLabel: "Caution",
              depositInfo:
                "Attention : si vous choisissez la caution via Booking, alors obligatoirement le paiement sera également via Booking",
              paymentPolicyFieldLabel: "Politique de paiement",
            },
          },
          PriceMultipliers: {
            title: "Multiplicateur de prix",
            tooltip: "Utilisé dans le moteur de réservation et sur Airbnb",
            airbnbTitle: "Airbnb",
            airbnbDescription: "Taux de majoration pour Airbnb",
            bookingTitle: "Booking",
            bookingDescription: "Taux de majoration pour Booking",
            websiteTitle: "Site de réservation",
            websiteDescription:
              "Taux de majoration pour votre site de réservation",
            platformRateAirbnbError: "Requis",
            platformRateBookingError: "Requis",
          },
          SurchargeRate: {
            surchargeRate: "Taux de majoration par plateforme",
            surchargeRateTitle: "{{platform}}",
            surchargeRateSubTitle: "Taux de majoration pour {{platform}}",
          },
          AdditionalCost: {
            title: "Frais supplémentaires",
            additionalCostInformationTooltipText:
              "Utilisé dans le moteur de réservation et sur Airbnb.",
            additionalCostCleaningTitle: "Frais de ménage",
            additionalCostCleaningSubtitle: "Les frais de ménage",
            additionalCostCleaningTooltip:
              "Utilisé dans le moteur de réservation et sur Airbnb",
            additionalCostCleaningError: "Requis",
            additionalCostLinenTitle: "Frais de linge",
            additionalCostLinenSubtitle: "Les frais de linge et serviettes",
            additionalCostLinenTooltip:
              "Utilisé dans le moteur de réservation et sur Airbnb",
            additionalCostLinenError: "Requis",
            additionalCostGuestTitle: "Frais par personne supplémentaire",
            additionalCostGuestSubtitle:
              "Appliquer pour chaque voyageur supplémentaire",
            costByDayLabel: "{{count}} € / jour",
            additionalCostGuestTooltip:
              "Utilisé dans le moteur de réservation et sur Airbnb",
            additionalCostGuestError: "Requis",
            additionalCostAnimalTitle: "Frais d'animaux",
            additionalCostAnimalSubtitle:
              "Les frais lors d'animaux domestiques",
            additionalCostAnimalTooltip:
              "Utilisé dans le moteur de réservation et sur Airbnb",
            additionalCostAnimalError: "Requis",
            additionalCostCotTitle: "Frais des lits bébés",
            additionalCostCotSubtitle: "Les frais pour un lit bébé",
            additionalCostCotTooltip:
              "Utilisé dans le moteur de réservation et sur Airbnb",
            additionalCostCotError: "Requis",
          },
          PaymentPolicy: {
            title: "Politiques de paiement",
            paymentScheduleInfo:
              "Calendrier de paiement : En {{nb}} fois {{description}}",
            cancellationInfo:
              "Annulation : {{value}} à {{percentage}}% si annuler {{canceled_condition}}",
            cancellationInfo0Percent: "Annulation: Non remboursable",
            depositInfo: "Caution : {{value}} par {{depositPaymentOption}}",
            depositInfoNone: "Caution : Aucune",
            createModalTitle: "Nouvelle politique",
            updateModalTitle: "Paramètrer la politique",
            namePolicyLabel: "Nom de la politique de paiement",
            namePolicyPlaceholder: "Nom de votre politique",
            descriptionPolicyLabel: "Description",
            descriptionPolicyPlaceholder: "Description de la politique",
            favoritePolicyLabel:
              "Sélectionner comme politique par défaut lors d'une nouvelle réservation",
            validateButtonLabel: "Ajouter la politique",

            Item: {
              selectedPaymentPolicyLabel: "Politique sélectionnée",
            },

            CreateOrUpdateModal: {
              stripeNotConnectedTitle:
                "Vous n'avez actuellement aucun moyen d'encaisser l'argent des voyageurs. Connectez dès maintenant un compte Stripe pour commencer à recevoir de l'argent.",
              stripeNotConnectedButtonLabel: "Ajouter un compte Stripe",
              descriptionValueDefaultPolicy:
                "La politique de paiement par défaut de SuperHote. Cette politique n'est ni modifiable, ni supprimable.",
              addAnotherPayment: "Ajouter un autre paiement",

              Errors: {
                moreThan100Percent:
                  "La somme total des paiement ne peut pas dépasser 100%",
                moreThanTotal:
                  "La somme total des paiements ne peut pas dépasser {{total}}€",
                mustBeEqualTo100Percent:
                  "La somme total des paiements doit être égale à 100%",
                mustBeEqualToTotal:
                  "La somme total des paiements doit être égale à {{total}}€",
                moreThan0: "Les paiements ne peuvent pas avoir une valeur à 0",
              },
            },
          },
        },
        Services: {
          Smartlocks: {
            title: "Serrures connectées et code d'accès",
            noLocks: "Aucune une serrure connectée",
            addLocks: "Ajouter une serrure ou un code",
            accessCode: "Code d'accès",
            fixedCode: "Code fixe",
            dynamicCode: "Code dynamique",
            AddModal: {
              title: "Ajouter une serrure connectée",
              Steps: {
                Login: {
                  title: "Connexion",
                  label: "Type de serrure",
                  manualOptionLabel: "Manuelle",
                  informationsLabel: "Informations",
                  emailLabel: "Nom d'utilisateur",
                  emailPlaceholder: "Saisisser votre nom d'utilisateur",
                  emailError: "Format invalide.",
                  passwordLabel: "Mot de passe",
                  passwordPlaceholder: "Saisisser votre mot de passe",
                  accessCodeNameLabel: "Nom du code d'accès",
                  accessCodeNamePlaceholder:
                    "Saisissez votre nom de code d'accès",
                  lockNameLabel: "Nom de la serrure",
                  lockNamePlaceholder: "Saisissez votre nom de serrure",
                  connectLock: "Connecter une serrure liée à ce compte",
                  igloohomeConnectedAccountsLabel:
                    "Compte Igloohome déjà connecté à Superhote",
                  connectionIgloohomeCta: "Connecter une serrure Igloohome",
                  connectionIgloohomeCta2:
                    "Connecter une serrure Igloohome d'un autre compte",
                  descriptionIgloohome:
                    "Pour connecter votre serrure Igloohome, vous devez cliquer sur le bouton ci-dessus. Cela ouvrira une page web de connexion à Igloohome. Connectez-vous avec vos identifiants et validez.",
                  nukiConnectedAccountsLabel:
                    "Compte Nuki déjà connecté à Superhote",
                  connectionNukiCta: "Connecter une serrure Nuki",
                  connectionNukiCta2:
                    "Connecter une serrure Nuki d'un autre compte",
                  descriptionNuki:
                    "Pour connecter votre serrure Nuki, vous devez cliquer sur le bouton ci-dessus. Cela ouvrira une page web de connexion à Nuki. Connectez-vous avec vos identifiants et validez.",
                  ttlockConnectedAccountsLabel:
                    "Compte TTLock déjà connecté à Superhote",
                  connectionTtlockCta: "Connecter",
                  connectionTtlockCta2:
                    "Connecter une serrure Ttlock d'un autre compte",
                  descriptionTtlock:
                    "Pour connecter votre serrure TTLock, vous devez cliquer sur le bouton ci-dessus. Cela ouvrira une page web de connexion à TTLock. Connectez-vous avec vos identifiants et validez.",
                  loadingTitle: "En attente de la connexion à {{name}}",
                  loadingSubtitle1: "Cela peut prendre quelques minutes..",
                  loadingSubtitle2: "Ne fermez pas cette page.",
                  successConnection: "Connexion à {{name}} validée.",
                  errorConnection: "Connexion à {{name}} échouée.",
                },
                Informations: {
                  title: "Informations",
                  lockCodeLabel: "Nom du code",
                  lockCodePlaceholder: "Saisissez le code",
                  lockIdLabel: "Identifiant de la serrure",
                  lockIdPlaceholder: "Saisissez l'identifiant de la serrure",
                  lockNameLabel: "Nom de la serrure",
                  lockNamePlaceholder: "Saisissez le nom de la serrure",
                  checkinTimeLabel: "Heure d'arrivée",
                  checkoutTimeLabel: "Heure de départ",
                  description:
                    "Les codes d'accès seront générés par rapport aux heures <strong>d'arrivée</strong> et de <strong>départ</strong> de l'hébergement.",
                  description2:
                    "Néanmoins, en cas de modification des heures pour une réservation donnée, les codes seront générés par rapport aux heures définis dans la réservation.",
                  addLock: "Ajouter la serrure",
                  addLockCode: "Ajouter le code",
                  noLocksTitle:
                    "Nous n'avons détecté aucune serrure rattachée à votre compte {{providerName}}. Assurez-vous d'avoir ajouté une serrure à votre compte auprès du fournisseur avant de continuer.",
                  noLocksDescription:
                    "Si vous venez de l'ajouter, veuillez patienter quelques instants ou actualisez la page pour réessayer",
                  noLocksAddLock: "Ajouter une serrure chez {{providerName}}",
                },
                AutomatedMessages: {
                  title: "Messages automatisés",
                  description:
                    "La serrure connectée a bien été ajoutée a l'hébergement.",
                  manualDescription:
                    "Le code d'accès a bien été ajouté à l'hébergement.",
                  description2:
                    "Regardez maintenant comment automatiser les entrées avec la page voyageur et les messages automatisées :",
                  ctaAutomatedMessages: "Voir les messages automatisées",
                },
              },
              textTutorial:
                "Tutoriel : Comment ajouter une serrure connectée ?",
            },
            UpdateModal: {
              title: "Paramétrer une serrure connectée",
              manualTitle: "Paramétrer un code d'accès manuel",
              informationsSubtitle: "Informations",
              deviceIdLabel: "Identifiant de la serrure",
              lockNameLabel: "Nom de la serrure",
              lockNamePlaceholder: "Saisissez le nom de la serrure",
              lockNameError:
                "Le nom de serrure est invalide, saisissez un nom sans accents et caractères spéciaux !",
              dynamicFieldCreateLabel:
                "Le code de cette serrure sera ajouté dans le champ dynamique de toutes les serrures de cet hébergement, dynamiquement pour chaque réservation. IL sera utilisable avec :",
              dynamicFieldCreatedLabel:
                "Le champ dynamique de cette serrure a été créé. Vous pouvez l'utiliser dès maintenant avec :",
              lockCodeLabel: "Code",
              lockCodePlaceholder: "Saisissez le code de la serrure",
              lockCheckinTimeLabel: "Heure d'arrivée",
              lockCheckoutTimeLabel: "Heure de départ",
              smartlockDescription:
                "Les codes d'accès seront générés par rapport aux heures d'arrivée et de départ de l'hébergement.",
              smartlockDescription2:
                "Néanmoins, en cas de modification des heures pour une réservation donnée, les codes seront générés et actifs par rapport aux heures définis dans la réservation.",
            },
            DeleteModal: {
              title: "Voulez-vous vraiment déconnecter cette serrure ?",
              manualTitle: "Voulez-vous vraiment supprimer ce code ?",
              description:
                "Cela aura pour effet de supprimer la serrure de SuperHote et les informations associées.",
              manualDescription:
                "Cela aura pour effet de supprimer le code de SuperHote et les informations associées.",
              removeWithConfirmation: "Oui, supprimer",
              disconnectWithConfirmation: "Oui, déconnecter",
            },
          },
          Pricelabs: {
            title: "Pricelabs",
            notConnected: "Pricelabs n’est pas connecté.",
            connectButtonLabel: "Connecter Pricelabs",
            connectedTitle: "Pricelabs, solution de tarification dynamique",
            connectedState: "Activé",
            connectedListingIdLabel: "Identifiant pricelabs : ",
            connectedLastSyncLabel: "Dernière synchronisation : ",
            connectedNotSyncYet: "Pas encore synchronisé",
            pricelabsButtonLabel: "Paramétrer Pricelabs",
            pendingBadgeLabel: "En attente",
            ConnectionModal: {
              title: "Connexion à Pricelabs",
              text: "Afin de connecter Pricelabs, veuillez d’abord ajouter vos prix sur les 12 prochains mois dans le calendrier de SuperHote, et définir la <strong>latitude</strong> et <strong>longitude</strong> de vos hébergement, dans la section <strong>Localisation</strong> des détails des hébergements.",
              emailLabel: "Votre email Pricelabs",
              emailPlaceholder: "superhote.fr@gmail.com",
              emailRequired:
                "L'email de connexion à votre compte Pricelabs est requis.",
              emailInvalid: "L'adresse e-mail n'a pas le bon format",
              tutorialTitle: "Tutoriel : Comment synchroniser Pricelabs ?",
              info1:
                "Une fois synchronisé, vos prix ne seront modifiables <strong>que sur Pricelabs directement.</strong>",
              info2:
                "Si vous souhaitez avoir plus de liberté dans la gestion de vos prix depuis SuperHote pour les hébergements connectés à Pricelabs, vous devrez ajouter votre <strong>clé d’API, une fois la connexion à Pricelabs effectuée.</strong>",
            },
            SettingsModal: {
              title: "Paramétrer la connexion à Pricelabs",
              syncInfo1:
                "Afin de pouvoir activer la synchronisation à Pricelabs, vous devez paramétrer Pricelabs directement depuis leur site web.",
              syncInfo2: "Il vous faudra :",
              syncInfoStep1: "⇒ Définir votre prix minimum",
              syncInfoStep2: "⇒ Définir le prix de base",
              syncInfoStep3: "⇒ Activer le switch Synchroniser les prix",
              syncInfoStep4: "⇒ Cliquer sur Enregistrer et actualiser",
              syncSwitchEnabledLabel: "Synchronisation Pricelabs activée",
              syncSwitchDisabledLabel: "Synchronisation Pricelabs désactivée",
              emailLabel: "Votre email Pricelabs",
              apiKeyLabel: "Clé d’API Pricelabs",
              apiKeyPlaceholder: "Ajoutez ici votre clé d’API",
              emailInvalid: "L'adresse e-mail n'a pas le bon format",
              info1:
                "Par défaut, vos prix ne sont modifiables <strong>que sur Pricelabs.</strong>",
              info2:
                "Si vous souhaitez avoir plus de liberté dans la gestion de vos prix depuis SuperHote pour les hébergements connectés à Pricelabs, vous devez ajouter ci-dessus votre <strong>clé d’API.</strong>",
              info3:
                "L’option clé d’API est facturée par Pricelabs, par hébergement.",
              tutorialLink: "Tutoriel : Ajouter une clé API Pricelabs",
            },
            DisconnectionModal: {
              title:
                "Voulez-vous vraiment supprimer la connexion de cet hébergement de Pricelabs ?",
              info1:
                "Cela aura pour effet de supprimer la connexion à Pricelabs pour cet hébergement ainsi que les éléments associés.",
              info2: "Vous pouvez toujours reconnecter plus tard.",
            },
          },
        },
        Contracts: {
          Contracts: {
            title: "Contrats de location",
            contract: "Contrat",
            contracts: "Contrats",
            noContractPlatform:
              "La réservation venant {{platform}}, vous ne pouvez pas éditez de contrat.",
            de: "de",
            deApostroph: "d'",
            noContract: "Aucun contrat ajouté.",
            CardDocs: {
              radioSelect: "Sélectionné",
              updatedAt: "Modifié le",
              importedAt: "Importé le",
              at: "à",
            },
            Modal: {
              title: "Nouveau modèle de contrat",
              formName: "Nom",
              formNamePlaceholder: "Le nom du modèle de contrat",
              formContent: "Contenu",
              formContentPlaceholder: "Votre contenu ici",
              formRequired: "Le champ est obligatoire.",
              errorGetContract:
                "Une erreur est survenue lors de la récupération du contract",
            },
            signed: "Signé",
            no_signed: "Non signé",
          },
          Docs: {
            title: "Ressources",
            noResource: "Aucune ressource ajoutée par le voyageur.",
          },
          Modal: {
            DeleteModal: {
              title: "Voulez-vous vraiment supprimer cette ressource ?",
              description:
                "Cela aura pour effet de supprimer la ressource ainsi que les elements associés.",
            },
            EditModal: {
              title: "Modifier la ressource",
              importDate: "Importé le {{date}} à {{hour}}",
              replace: "Remplacer",
            },
            AddModal: {
              title: "Ajouter la ressource",
              addFiles: "Ajouter une photo, une vidéo ou un fichier ici",
            },
            AddContractModal: {
              title: "Créer un contrat",
              amendment: "Avenant",
            },
            ContractModal: {
              updateContract: "Modifier le contrat",
              titleSendContract: "Envoyer le contrat",
              displayContract: "Voir le contrat",
              closeContract: "Fermer le contrat",
            },
            SendContractModal: {
              title: "Envoyer le contrat",
              titleButton: "Envoyer",
              lang: "Langue",
              email: "Email",
              emailFormat: "Format invalide.",
              subject: "Sujet",
              defaultSubject: "Votre contrat {{contractId}} - {{rentalName}}",
              message: "Message",
            },
          },
          Preview: {
            recapTitle: "Récapitulatif de la réservation",
            hostTitle: "Bailleur",
            guest: "Preneur",
          },
        },
        Checklist: {
          List: {
            nameColumn: "Nom",
            typeColumn: "Type",
            stepsColumn: "Étapes",
            whatsChecklistTitle: "Qu'est ce qu'une checklist ?",
            whatsChecklistDescription:
              "Les checklists sont des listes à cocher pour vous ou vos prestataires. Vous pouvez utiliser dans vos tâches dans l'onglet <strong>gestion opérationnelle</strong>.",
            whatsChecklistDescription2:
              "Par défaut, chaque checklist est liée à une <strong>automatisation</strong> pour vous permettre de gérer plus facilement vos ménages, checkin et checkout.",
          },
          title: "Nouvelle checklist",
          Upsert: {
            title: "Nouvelle checklist",
            informations: "Informations",
            name: "Nom",
            namePlaceholder: "Le nom de votre checklist",
            nameRequired: "Le nom est requis.",
            description: "Description",
            descriptionPlaceholder: "La description courte de votre checklist",
            add: "Créer la checklist",
            type: "Type",
            cleaning: "Ménage",
            checkin: "Checkin",
            checkout: "Checkout",
            incident: "Incident",
            other: "Autre",
            howToCalculateCleaning:
              "Comment voulez-vous calculer la rémunération de votre ménage ?",
            amount: "Rémunération de la tâche",
            amountRequired: "Rémunération requise",
            fixed: "Rémunération fixe",
            by_bed: "Variable en fonction des couchages",
            cleaningCharge: "Forfait ménage",
            cleaningChargeRequired: "Forfait ménage requis",
            byBedCharge: "Forfait par couchage",
            byBedChargeRequired: "Forfait par couchage requis",
            descriptionExampleCalc:
              "Exemple : pour un ménage avec 3 couchage à changer,",
            exampleCalc:
              "la rémunération sera de {{cleaning}}€ + 3 x {{byBed}}€ = <strong> {{result}} </strong> €.",
            photos: "Photo(s) ou vidéo(s) pour mieux réaliser la tâche",
            stepName: "Étape 1 de votre checklist",
            checklist: "Checklist",
            newSection: "Nouvelle Section",
            newStep: "Nouvelle étape",
            sectionsRequired: "Au moins 1 section avec 1 step est requis",
          },
          Delete: {
            title: "Voulez-vous vraiment supprimer cette checklist ?",
            description:
              "Cette action aura pour action de supprimer la checklist et les informations associées.",
          },
          Types: {
            cleaning: "Ménage",
            checkin: "Check In",
            checkout: "Check Out",
            incident: "Incident",
            other: "Autre",
          },
          Section: {
            addStep: "Ajouter une étape",
            addSection: "Ajouter une section",
          },
        },
        Website: {
          seeWebsiteList: "Voir la liste de mes sites",
          IntegrateModal: {
            title: "Intégrer le Site de réservation",
            rentalIframeTitle: "Intégrer la page de cet hébergement",
            rentalIframeDescription:
              "Pour intégrer le moteur de réseration dans votre site web, vous devez ajouter ce code iframe dans votre page.",
            rentalIframeLabel: "Iframe de l'hébergement",
            rentalIframeListTitle: "Intégrer la liste de tous les hébergements",
            rentalIframeListDescription:
              "Pour intégrer la liste de tous les hébergements dans votre site web, vous devez ajouter ce code iframe dans votre page.",
            rentalIframeListLabel: "Iframe de la liste",
            rentalSearchIframeTitle:
              "Intégrer la recherche de tous les hébergements",
            rentalSearchIframeDescription:
              "Pour intégrer la recherche dynamqiue de tous les hébergements dans votre site web, vous devez ajouter ce code iframe dans votre page.",
            rentalSearchIframeLabel: "Iframe de la recherche",
            seeTutorial: "Voir le tutoriel",
            webkeyLabel: "Webkey personnel",
          },
          Rules: {
            title: "Règles",
            requiredReservationLabel: "Demande de réservation obligatoire",
            requiredReservationDescription:
              "Si ce n'est pas coché, les réservations sont non soumises à votre confirmation",
            requiredReservationDescriptionActive:
              "Par email, à valider sous 24h",
            requiredReservationTooltip:
              "Si cette option est activée, aucun paiement ne sera possible depuis le moteur de réservation. Vous recevrez les demandes de réservation par mail.",
            requiredReservationInfo1:
              'Puisque vous avez choisi le mode "demande de réservation", le voyageur payera le séjour avec une pré-autorisation, et vous aurez 24 heures pour accepter ou refuser la demande. Si vous acceptez, le montant de la pré-autorisation sera encaissé. Si vous refusez, le voyageur ne sera pas débité.',
            requiredReservationInfo2:
              "Les dates seront bloquées dans le calendrier le temps que vous décidiez.",
            requiredReservationInfo3:
              "Important : en mode demande de réservation, la réservation et le paiement des extras directement depuis le site de réservation n'est pas possible. Si vous voulez vendre des extras directement depuis votre site, vous devez passer en mode réservation instantané.",
            requiredReservationInfo4:
              "Vous pourrez toujours proposer des extras à votre voyageur avec la page voyageur.",
          },
          CarouselPhotos: {
            title: "Photos du carrousel",
            infos:
              "Ces photos ont été récupérés automatiquement depuis l'onglet Détails de cet hébergement. Elles sont synchronisés automatiquement avec cette page.",
          },
          Description: {
            title: "Description",
            infos:
              "Cette description a été récupéré automatiquement depuis l'onglet Détails de cet hébergement. Vous pouvez la modifier, cela ne modifiera pas la description principale de votre hébergement.",
          },
          Services: {
            title: "Services",
            titleLabel: "Titre",
            subTitleLabel: "Sous-titre",
            updateModalTitle: "Modifier les services",
            updateModalDescription:
              "Faites glisser et déposez les images pour modifier leur ordre.",
            serviceNamePlaceholder: "Nom du service",
            addPhoto: "Ajouter une photo ici",
            addService: "Ajouter un service ici",
            servicesError: "Vous devez obligatoirement remplir tous les champs",
          },
          Extras: {
            title: "Extras",
          },
          PaymentAndReceipt: {
            title: "Paiement et encaissement",
            infos:
              "Ces comptes Stripe proviennent de votre politique de paiement (onglet <strong>Tarification</strong> de l'hébergement) et des extras de la <strong>Page Voyageur</strong>. Si vous les modifiez ici, elles seront mises à jour partout.",
            nightReceiptSelectLabel:
              "Choix du compte Stripe pour l'encaissement des nuitées",
            depositReceiptSelectLabel:
              "Choix du compte Stripe pour l'encaissement de la caution",
            extrasReceiptSelectLabel:
              "Choix du compte Stripe pour l'encaissement des extras",
            fieldInfo: "Parmi les comptes Stripe déjà connectés à Superhote.",
            noAccountSelected: "Aucun compte sélectionné",
            accountType: "Via {{type}}",
            nightReceipt: "Compte Stripe pour encaisser les nuitées",
            depositReceipt: "Compte Stripe pour encaisser la caution",
            extrasReceipt: "Compte Stripe pour encaisser les extras",
            noPaymentAccounts: "Aucun compte Stripe connecté à SuperHote",
            addPaymentAccount: "Ajouter un compte Stripe",
          },
          UpsellMarketing: {
            title: "Upsell Marketing",
          },
          Terms: {
            title: "Conditions générales de ventes",
          },
        },
        BillingInfos: {
          title: "Facturation et administratif",
          hostType: "Type d'hôte",
          particular: "Particulier",
          pro: "Professionnel",
          fillDataInfo:
            "Nous avons rempli automatiquement certaines informations sur la base de votre profil.",
          Particular: {
            hostName: "Nom de l'hôte",
            taxIdentityNumber: "Numéro d'identification fiscal",
          },
          Pro: {
            companyName: "Raison sociale (nom complet de l'entreprise)",
            siren: "SIREN",
          },
          billingAddress: "Adresse de facturation",
          country: "Pays",
          postalCode: "Code postal",
          city: "Ville",
          propertyRegistrationNumber: "Numéro d’enregistrement du bien",
        },
        Policies: {
          Direct: {
            title:
              "Politique pour les réservations venant du site de réservation",
            paymentSejour: "Le séjour sera payé en {{number}} fois.",
            paymentSejourComplet:
              "Le séjour sera payé en {{number}} fois {{trigger}} par {{method}}",
            une: "une",
            stripe: "stripe",
            checkin: "à l'arrivée des voyageurs",
            payment: "Paiement #{{number}} par {{method}}",
            specificDate: "le {{date}}",
            toReservation: "le jour de la réservation",
            toDaysBefore: "{{days}} jours avant l'arrivée des voyageurs",
            bankOrCash: "chèque ou espèce",
            platform: "la plateforme",
            vir: "virement bancaire",
            cash: "espèce",
            card: "carte bancaire",
            check: "chèque bancaire",
            superhote: "SuperHote",
            notRefundable: "Non-remboursable",
            refundable: "Remboursable",
            cancelConditionTitle: "Annulation",
            caution: "Caution",
            cautionNotRequested: "Caution non demandée.",
            cautionText:
              "Une caution de {{amount}} est demandée, par {{method}} {{delay}}",
            delay: "{{days}} jour(s) avant l'arrivée des voyageurs",
            pre: "empreinte bancaire",
          },
          Booking: {
            title: "Politique pour les réservations Booking.com",
            cancellationTitle: "Conditions d'annulation et pré-paiement :",
            cancellationDateLimit: "Date limite d'annulation : ",
            tarif: "Plans tarifaires",
            caution: "Caution",
            managed: "Gérée par Booking.com,  {{amount}} est demandé",
            managedAny: "Gérée par Booking.com",
            managedBySuperhoteAny: "Gérée par le propriétaire",
            managedBySuperhote:
              "Gérée par le propriétaire, {{amount}} est demandé",
            payment: "Paiement",
            anyCaution: "Aucune caution",
            anyCautionDamage: "Aucune caution relative aux dommages",
          },
          Airbnb: {
            title: "Politique pour les réservations Airbnb",
            payment: "Paiement",
            managed: "Gérée par Airbnb",
            cancel: "Annulation",
            strict: "Stricte",
            textStrictCancel:
              "Les voyageurs reçoivent un remboursement intégral s'ils annulent dans les 48 heures qui suivent la réservation et au moins 14 jours avant l'arrivée.",
            caution: "Caution",
          },
        },
      },
      Payments: {
        title: "Paiements",
        reservationList: "Paiements des réservations",
        depositList: "Paiements des cautions",
        extraList: "Paiements des extras",
        accountList: "Moyens d'encaissement",
        invoiceList: "Factures",
        activityList: "Activité",
        addIBAN: "Ajouter un IBAN",
        addStripe: "Ajouter un compte Stripe",
        NoData: {
          title: "Aucun moyen d'encaissement renseigné.",
          description:
            "Vous n'avez actuellement aucun moyen d'encaisser l'argent des voyageurs. Connectez vous dès maintenant à un compte Stripe pour commencer à recevoir de l'argent.",
          addIBAN: "Ajouter un IBAN",
          addStripeAccount: "Ajouter un compte Stripe",
        },
        Infos: {
          title: "Voici vos différents moyens d’encaissement",
          content:
            "Les moyens d’encaissement vous permettent de définir la façon dont vous allez recevoir de l’argent. Une fois vos comptes bancaires ajoutés, rendez vous dans le détails des hébergements pour choisir les moyens d’encaissement propres à chaque hébergement.",
        },

        Stripe: {
          addTitle: "Connecter votre compte bancaire",
          addContent:
            "Nous utilisons Stripe Connect pour vous permettre d’encaisser les paiements de vos voyageurs de manière simple et sécurisée.",
          addTuto:
            "Regardez notre tutoriel : Qu’est ce que Stripe et comment le connecter",
          successTitle: "Connexion OK",
          successContent:
            "SuperHote est bien connecté à votre compte Stripe, cliquez à présent sur Terminer pour l’ajouter à vos modes d’encaissement",
          name: "Intitulé du compte",
          defaultName: "Compte Stripe principal",
          namePlaceholder:
            "Compte Stripe SCI, compte personnel, compte pour extra, ...",
          nameHint: "Pour vous y retrouver plus facilement",
          nameError: "L'intitulé est requis",
          stripeConnectBackLinkClicked:
            "Nous n’avons pas pu accéder à votre compte Stripe, veuillez réessayer ultérieurement.",
        },

        accountName: "Intitulé du compte",
        accountDetails: "Détails",
        removeAccountTitle:
          "Voulez-vous vraiment supprimer la connexion de ce compte à Superhote ?",
        removeAccountContent:
          "En déconnectant votre compte Stripe, les futures réservations ne pourront plus utiliser ce compte pour les paiements, cautions ou extras. Vous ne pourrez plus recevoir de paiements, rembourser des paiements passés, ni capturer des cautions en attente dans la période des 7 jours.",
        accountActivity: "Dernière activité",
        accountType: "Type",
        paymentScheduleLabel: "Calendrier de paiement",
        singlePaymentError:
          "Le séjour a lieu dans moins de 3 jours. Le paiement en plusieurs fois n'est donc pas disponible.",
        singlePaymentLabel: "Paiement unique",
        multiplePaymentsLabel: "Paiement en plusieurs fois",
        paymentTriggerLabel: "Quand ?",
        PaymentTriggerOptions: {
          atReservation: "Lors de la réservation (aujourd'hui)",
          atCheckin: "Lors de l'arrivée",
          specificDate: "Date spécifique",
          twentyFourBeforeArrival: "24 heures avant arrivée",
          fiveDaysBeforeArrival: "5 jours avant arrivée",
          sevenDaysBeforeArrival: "7 jours avant arrivée",
          fourteenDaysBeforeArrival: "14 jours avant arrivée",
          thirtyDaysBeforeArrival: "30 jours avant arrivée",
          sixtyBeforeArrival: "60 jours avant arrivée",
        },
        paymentTriggerInfo:
          "Le paiement est prévu le <strong>{{date}}</strong>",
        amountTypeLabel: "Type de montant",
        amountLabel: "Combien ?",
        specificDate: "Date spécifique",
        meansOfPaymentLabel: "Moyen d'encaissement",
        cancellationConditionsLabel: "Conditions d'annulation",
        cancellationConditionsError:
          "Le séjour a lieu dans moins de 3 jours. Il n'est donc pas remboursable.",
        nonRefundableLabel: "Non remboursable",
        refundableLabel: "Remboursable",
        refundPercentageLabel: "Pourcentage remboursable",
        refundPercentagePlaceholder: "Saisissez le pourcentage remboursable",
        refundPercentageValueError:
          "Le pourcentage remboursable doit être compris entre 1 et {{maxValue}}%",
        refundConditionLabel: "Si annulation",
        RefundConditions: {
          TwentyFourHoursBeforeArrival: "24 heures avant arrivée",
          FiveDaysBeforeArrival: "5 jours avant arrivée",
          SevenDaysBeforeArrival: "7 jours avant arrivée",
          FourteenDaysBeforeArrival: "14 jours avant arrivée",
          ThirtyDaysBeforeArrival: "30 jours avant arrivée",
          SixtyBeforeArrival: "60 jours avant arrivée",
        },
        refundInfo:
          "Si annulation avant le <strong>{{date}}</strong> inclus, le voyageur devra être remboursé de <strong>{{amount}}</strong>.",
        depositLabel: "Caution ou dépôt de garantie",
        notMandatoryLabel: "Non obligatoire",
        mandatoryLabel: "Obligatoire",
        depositTypeLabel: "Type de montant",
        DepositTypes: {
          fixed: "Fixe",
          percentage: "Pourcentage du montant total de la réservation",
        },
        depositAmountLabel: "Montant",
        depositAmountPlaceholder: "Saisissez le montant",
        depositAmountValueError:
          "Le montant de la caution doit être compris entre 1 et {{maxValue}} {{currency}}",
        depositPaymentOptionLabel: "Type d'encaissement du dépôt de garantie",
        DepositPaymentOptions: {
          preAuthorisation: "Caution par empreinte bancaire",
          cbRefund: "Caution par paiement remboursable",
          sepaRefund: "Viremement SEPA à rembourser à l'issue du séjour",
          checkOrCash: "Chèque ou espèces à l'arrivée",
        },
        accountChoice: "Choix du compte Stripe",
        preAuthorisationInfo:
          "Si vous choisissez cette option, un message automatisé sera envoyé un jour avant l'arrivée du voyageur, avec un lien pour pré-autoriser le paiement du dépot de garantie. Le montant sera alors bloqué via Stripe sans être encaissé, et prélevé uniquement en cas de dégâts effectifs. ",
        cbRefundInfo:
          "Si vous choisissez cette option vous aurez un lien de paiement à envoyer à votre voyageur. Il faudra rembourser ce montant à l'issu du séjour.",
        sepaRefundInfo:
          "Si vous choisissez cette option, vos informations bancaires (IBAN) seront à envoyer à votre voyageur. Il faudra rembrouser ce montant à l'issue du séjour.",
        depositInfo1:
          "La pré-autorisation sera envoyée au voyageur le <strong>{{startDate}}</strong> et sera libérée le <strong>{{endDate}}</strong>.",
        depositInfo2:
          "Une pré-autorisation dure 7 jours au maximum. Faites attention à bien être couvert jusqu'à la fin du séjour.",
        depositPaymentTimeDelayPreAuthorisationInfoLabel: "À pré-autoriser",
        depositPaymentTimeDelayCBRefundInfo:
          "La caution sera à payer le <strong>{{date}}</strong>. S'il est activé, le message automatisé sera envoyé au voyageur ce jour là.",
        depositPaymentTimeDelayPreAuthorisationInfo:
          "La caution sera à pré-autoriser le <strong>{{preAuthorizedAt}}</strong> et sera libérée le <strong>{{releasedAt}}</strong>. Une pré-autorisation dure 30 jours au maximum. Faites attention à bien être couvert jusqu'à la fin du séjour.",
        depositPaymentTimeDelayLabel: "À payer",
        daysBeforeArrival: "Jours avant l'arrivée",
        DepositPaymentTriggerOptions: {
          daysBeforeArrival: "Jours avant l'arrivée",
          hoursBeforeArrival: "Heures avant l'arrivée",
        },
        PaymentReservation: {
          title: "Votre réservation",
          rangeDate: "Entre {{minTime}} et {{maxTime}}",
          secondTitle: "Vous payerez",
          onCustomDate: "Le {{date}}",
          cbPayment: "Paiement CB",
          byCb: "Par carte bancaire",
          toPay: "À payer aujourd'hui {{amount}}",
          successPaymentValidated: "Paiement validé",
          successInfoPaymentValidated:
            "Votre paiement de {{amount}} {{currency}} par carte bancaire à bien été pris en compte et validé. Vous allez recevoir une confirmation par e-mail dans les prochaines minutes.",
          refundPayment: "Paiement remboursé",
          refundInfoPayment:
            "Votre paiement de {{amount}} {{currency}} à été remboursé.",
        },
        PaymentReservationList: {
          rentalLabel: "Hébergement",
          platformLabel: "Plateforme",
          paymentDateLabel: "Date paiement",
          paymentWaysLabel: "Moyen de paiement",
          platformPayment: "Paiement plateforme",
          idCommandLabel: "Numéro de commande",
          idCommand: "Commande {{id}}",
          bankTransferPayment: "Virement",
          stripePayment: "Stripe",
          bankCheckPayment: "Chèques",
          cashPayment: "Espèces",
          statusLabel: "Statut",
          amountLabel: "Montant",
          modalTitle: "Paiement {{ id }}",
          contextLabel: "Contexte",
          guestLabel: "Voyageur",
          ratingNotDefined: "Aucune note disponible",
          reference: "Référence : {{value}}",
          rangeDate: "Du {{startDate}} au {{endDate}}",
          directReservationLabel: "Réservation en direct",
          platformReservationLabel: "Réservation plateforme",
          transactionDetailsLabel: "Détails des transactions",
          onDate: "Le {{date}}",
          infoTitle: "Ce paiement fait partie d'un plan de paiement.",
          infoDescription:
            "Pour cette réservation, il existe plusieurs paiements. Même si ce paiement est payé, cela ne signifie pas que la totalité de la réservation a été payée.",
          infoButton: "Voir en détail le paiement total",
          sendPaymentButton: "Envoyer le paiement",
          cancelPaymentButton: "Annuler le paiement",
          infosLabel: "Informations",
          paymentLinkLabel: "Lien du paiement",
          cautionLinkLabel: "Lien de la caution",
          copyPaymentLink: "Copier le lien",
          paymentLinkCopied: "Lien copié",
          amountLabel: "Montant",
          meansOfPaymentLabel: "Moyen de paiement",
          emailLabel: "Email",
          paidOnLabel: "Payé le",
          paymentDone: "Le paiement a été effectué",
          paymentDoneByOtherWay: "Ce paiement a été payé par un autre moyen",
          payment: "Paiement",
          subPayment: "Sous-paiement",
          direct: "direct",
          platform: "plateforme",
          platformDirectSubPayment: "Sous-paiement direct",
          platformDirect: "Paiement direct",
          platformAirbnb: "Paiement via Airbnb",
          platformBooking: "Paiement via Booking.com",
          platformIcalendar: "Paiement via plateforme (iCal)",
          SetDone: {
            title: "Voulez-vous vraiment déclarer le paiement comme effectué ?",
            content:
              'Cela aura pour effet de passer le paiement en statut "payé".',
            button: "Oui, valider",
          },
          AirbnbInfo: {
            paymentDetailsTitle:
              "Détails du paiement du voyageur (données transmises par Airbnb)",
            paymentDetailsCleaningFeesLabel: "Frais de ménage",
            paymentDetailsGuestFeesLabel: "Frais de service voyageur",
            paymentDetailsHolidayTaxLabel: "Taxes de séjour",
            hostTransferTitle:
              "Versement à l'hôte (données transmises par Airbnb)",
            hostTransferBedroomFeesLabel_one:
              "Frais de chambre pour {{count}} nuit",
            hostTransferBedroomFeesLabel_other:
              "Frais de chambre pour {{count}} nuits",
            hostTransferCleaningFeesLabel: "Frais de ménage",
            hostTransferServiceFeesLabel:
              "Frais de service hôte ({{calculation}})",
          },
          BookingInfo: {
            paymentDetailsTitle:
              "Détails du paiement du voyageur (données transmises par Booking)",
            paymentDetailsCleaningFeesLabel: "Frais de ménage",
            paymentDetailsGuestFeesLabel: "Frais de service voyageur",
            paymentDetailsHolidayTaxLabel: "Taxes de séjour",
            hostTransferTitle:
              "Versement à l'hôte (données transmises par Booking)",
            hostTransferBedroomFeesLabel_one:
              "Frais de chambre pour {{count}} nuit",
            hostTransferBedroomFeesLabel_other:
              "Frais de chambre pour {{count}} nuits",
            hostTransferCleaningFeesLabel: "Frais de ménage",
            hostTransferServiceFeesLabel:
              "Frais de service hôte ({{calculation}})",
          },
          removeModalTitle: "Voulez-vous vraiment annuler le paiement ?",
          removeModalDescription:
            "Cela aura pour effet d’annuler le paiement. La page de ce paiement ne sera plus accessible.",
          refundModalTitle: "Voulez-vous vraiment rembourser le paiement ?",
          refundModalDescription:
            "Cela aura pour effet de rembourser le paiement. Le remboursement sera effectué via {{refundType}}.",
          sendPaymentModalTitle: "Envoyer le paiement",
          typeLabel: "Type",
          typeEmailLabel: "Email",
          typeSMSLabel: "SMS",
          languageLabel: "Langue",
          subjectLabel: "Sujet",
          messageLabel: "Message",
          reservationPlatform: "Réservation {{name}}",
          reservationViaPlatform: "Réservation via {{name}}",
          release: "Libérer",
          capture: "Encaisser",
          refund: "Rembourser",
          notRefund: "Ne pas rembourser",
          paymentDetails: "Détails du paiement",
          ReleaseModal: {
            title:
              "Voulez-vous vraiment libérer la pré-autorisation de a caution ?",
            content1:
              "Cela aura pour effet d’annuler la pré-autorisation de la caution. Vous ne pourrez donc plus l’encaisser et n’êtes plus couvert en cas de problème.",
            content2:
              "Cette pré-autorisation sera dans tous les cas libéré automatiquement le {{maxDate}}, car la pré-autorisation dure 30 jours au maximum.",
            button: "Oui, libérer",
          },
          CaptureModal: {
            title: "Voulez-vous vraiment encaisser la caution ?",
            content:
              "Cela aura pour effet de déclencher le paiement de la pré-autorisation de la caution. L’encaissement de la caution doit avoir une raison (matériel abimé, vol, ...)",
            button: "Oui, encaisser",
          },
          RefundModal: {
            title: "Voulez-vous rembourser la caution ?",
            content:
              "Cela aura pour effet de rembourser le voyageur du montant de la caution. Le remboursement sera effectué via Stripe.",
            button: "Oui, rembourser",
          },
          NoRefundModal: {
            title: "Voulez-vous vraiment ne pas rembourser la caution ?",
            content1:
              "Vous ne rembourserai pas le voyageur du montant de la caution. Le non remboursement de la caution doit avoir une raison (matériel abimé, vol, ...)",
            content2:
              "Vous pourrez toujours rembourser le voyageur plus tard, jusqu’à 90 jours après son paiement initial.",
            button: "Ok",
          },
          Filter: {
            unpaid: "Non payée",
            overdue: "En retard",
            pre_authorization: "Pré-autorisation",
            payment_refundable: "Paiement remboursable",
            canceled: "Annulée",
          },
        },
        PaymentDepositList: {
          rentalLabel: "Hébergement",
          guestLabel: "Voyageur",
          platformLabel: "Plateforme",
          commandDateLabel: "Date de commande",
          paymentDateLabel: "Date paiement",
          depositTypeLabel: "Type et statut de la caution",
          statusLabel: "Statut",
          amountLabel: "Montant",
          preAuthorisationStatus: "Caution par empreinte bancaire",
          preAuthorisationStatusShort: "Empreinte bancaire",
          cardPaymentStatus: "Caution par paiement remboursable",
          cardPaymentStatusShort: "Paiement remboursable",
          bankTransferStatus: "Virement SEPA à rembourser à l’issue du séjour",
          cashStatus: "Chèque ou espèces à l’arrivée",
          depositValue: "Montant",
          unknown: "Aucune caution",
          depositType: "Type de caution",
        },
        PaymentDeposit: {
          modalTitle: "Caution",
          infoTitle: "La caution",
          preAuthorisationDepositToPay: "Caution à payer (empreinte bancaire)",
          depositToPay: "Caution à payer",
          preAuthorisationDescription1:
            "Le propriétaire a choisi comme type de caution une empreinte bancaire. Celle-ci expirera au bout de 7 jours.",
          preAuthorisationDescription2:
            "Votre compte bancaire ne sera pas débité. Il s'agit d'une pré-autorisation de carte de crédit.",
          otherDescription1:
            "Le propriétaire a choisi comme type de caution un paiement immédiat remboursable.",
          otherDescription2:
            "Cela signifie que vous serez débité de ce montant. À l'issue de votre séjour, vous recevrez à nouveau ce montant sur votre compte bancaire.",
          depositTitle: "Caution à payer : {{amount}}",

          successPaymentValidated: "Caution validée",
          successInfoPaymentValidated:
            "Votre caution d'un montant de {{amount}} {{currency}} par carte bancaire à bien été pris en compte et validée. Vous allez recevoir une confirmation par e-mail dans les prochaines minutes.",
          preAuthorizedPayment: "Pré-autorisation de la caution autorisée",
          preAuthorizedInfoPayment:
            "Votre caution d'un montant de {{amount}} {{currency}} à été autorisée.",
          releasedPayment: "Caution libérée",
          releasedInfoPayment:
            "Votre caution d'un montant de {{amount}} {{currency}} à été libérée.",
          capturedPayment: "Caution encaissée",
          capturedInfoPayment:
            "Votre caution d'un montant de {{amount}} {{currency}} à été encaissée.",
          refundedPayment: "Caution remboursée",
          refundedInfoPayment:
            "Votre caution d'un montant de {{amount}} {{currency}} à été remboursée.",
          notRefundedPayment: "Caution non-remboursée",
          notRefundedInfoPayment:
            "Votre caution d'un montant de {{amount}} {{currency}} n'a pas été remboursée.",
          managedByPlatformPayment: "Caution gérée par la plateforme",
          managedByPlatformInfoPayment:
            "Votre caution d'un montant de {{amount}} {{currency}} est gérée par la plateforme.",

          PreAuthorization: {
            depositTitle: "Pré-autorisation de la caution : {{amount}}",
            successPaymentValidated: "Pré-autorisation de la caution validée",
            successInfoPaymentValidated:
              "Votre pré-autorisation de caution d'un montant de {{amount}} {{currency}} par carte bancaire à bien été pris en compte et validée. Vous allez recevoir une confirmation par e-mail dans les prochaines minutes.",
            preAuthorizedPayment: "Pré-autorisation de la caution autorisée",
            preAuthorizedInfoPayment:
              "Votre pré-autorisation de caution d'un montant de {{amount}} {{currency}} à été autorisée.",
            releasedPayment: "Pré-autorisation de la caution libérée",
            releasedInfoPayment:
              "Votre pré-autorisation de caution d'un montant de {{amount}} {{currency}} à été libérée.",
            capturedPayment: "Pré-autorisation de la caution encaissée",
            capturedInfoPayment:
              "Votre pré-autorisation de caution d'un montant de {{amount}} {{currency}} à été encaissée.",
            refundedPayment: "Pré-autorisation de la caution remboursée",
            refundedInfoPayment:
              "Votre pré-autorisation de caution d'un montant de {{amount}} {{currency}} à été remboursée.",
            notRefundedPayment: "Pré-autorisation de la caution non-remboursée",
            notRefundedInfoPayment:
              "Votre pré-autorisation de caution d'un montant de {{amount}} {{currency}} n'a pas été remboursée.",
            managedByPlatformPayment:
              "Pré-autorisation de la caution gérée par la plateforme",
            managedByPlatformInfoPayment:
              "Votre pré-autorisation de caution d'un montant de {{amount}} {{currency}} est gérée par la plateforme.",
            validatePreAuthorisation: "Valider la pré-autorisation",
          },
        },
      },
      BookingContract: {
        docName: "Nom du document",
        type: "Type de document",
        download: "Télécharger",
        pdf: "PDF",
        image: "Image",
        video: "Vidéo",
        document: "Document",
        date: "Date",
      },
      AddReservation: {
        newReservation: "Nouvelle réservation",
        globalInfos: "Informations générales",
        prices: "Tarifs et paiement",
        guest: "Voyageur",
        Summary: {
          infosTitle: "Informations générales",
          infosDesc: "Les informations basiques de votre réservation",
          pricesTitle: "Tarifs et frais supplémentaires",
          pricesDesc: "Les conditions tarifaires de la réservation",
          guestTitle: "Informations voyageurs",
          guestDesc: "Qui séjourne ?",
          paymentTitle: "Paiement",
          paymentDesc: "Moyen de paiement, annulation, caution.",
          doneTitle: "Terminé !",
          doneDesc: "Vous avez fini la création de la réservation",
          totalNights: "Total nuitées {{price}}",
          extraFees: "frais supplémentaires",
          extra: "Frais supplémentaires {{amount}} {{extras}}",
          ExtraNames: {
            city: "taxe de séjour",
            clean: "ménage",
            guest: "voyageurs supplémentaires",
            animal: "animaux",
            baby: "lits bébés",
            other: "autres frais",
          },
        },
        Close: {
          title: "Voulez-vous vraiment quitter l’ajout de cette réservation ?",
          content:
            "Si vous quittez maintenant, vous perdrez les informations remplies jusqu’à présent. ",
          button: "Oui, quitter",
        },
        Infos: {
          title: "Ajouter une réservation",
          subTitle: "Ajouter une réservation manuellement dès maintenant.",
          nextStep: "Étape suivante",
          rental: "Hébergement",
          alert: "Alerte",
          type: "Type de réservation",
          direct: "Direct",
          website: "Site de réservation",
          ical: "iCal",
          adults: "Nombre d'adultes",
          children: "Nombre d'enfants",
          dates: "Dates du séjour",
          checkin: "Arrivée",
          checkinTime: "Heure d'arrivée",
          checkout: "Départ",
          checkoutTime: "Heure de départ",
          Error: {
            maxAdults_one: "Cet hébergment accepte {{count}} adulte max",
            maxAdults_other: "Cet hébergment accepte {{count}} adultes max",
            maxChildren_one: "Cet hébergment accepte {{count}} enfant max",
            maxChildren_other: "Cet hébergment accepte {{count}} enfants max",
            minGuest: "Il doit y avoir au moins 1 voyageur",
            minNights:
              " La durée minimale de {{count}} nuits prévue pour ces dates n'est pas respectée",
            maxNights:
              " La durée maximale de {{count}} nuits prévue pour ces dates n'est pas respectée",
            noCheckin: "Arrivée non autorisée le {{date}}",
            noCheckout: "Départ non autorisé le {{date}}",
            dates: "Vous devez sélectionner une date de début et de fin",
          },
        },
        Prices: {
          title: "Définissez le tarif et les frais",
          subTitlePartOne: "Les informations sont pré-remplies selon ",
          nextStep: "Étape suivante",
          subTitlePartTwo: "les prix définis dans le calendrier ",
          subTitlePartThree:
            "et vos règles de tarification définies dans la section tarification de l’hébergement. Vous pouvez les modifier à votre guise.",
          totalNightsAndFees: "Total nuitées + frais",
          totalNights: "Total nuitées",
          detailPriceNightsButton: "Voir le détail des prix par nuits",
          extraFees: "Frais supplémentaires",
          cityTaxes: "Taxe de séjour",
          cityTaxesTotal: "Taxe de séjour (total)",
          cityTaxesTotalDescription:
            "Calculé automatiquement à partir des règles de tarification de votre hébergement",
          cleanFees: "Frais de ménage",
          linenFees: "Frais de linge",
          extraGuestFees: "Frais de voyageurs supplémentaires",
          animalsFees: "Frais d'animaux",
          babyBedFees: "Frais de lits bébés",
          otherFees: "Autres frais",
          DetailPricesModal: {
            title: "Prix des nuitées du séjour",
            priceByNight: "Prix par nuit",
            checkinOrCheckoutDate: "<strong>{{date}}</strong> à {{hour}}",
            totalNightsFull: "Soit un total nuitées de {{ total }}",
          },
        },
        Guest: {
          title: "Qui séjourne dans votre hébergement ?",
          subTitle:
            "Ajoutez un voyageur depuis votre base voyageurs, ou ajoutez-le manuellement. Il sera alors automatiquement enregistré dans votre base (CRM) voyageurs.",
          nextStep: "Étape suivante",
          search: "Rechercher un voyageur",
          searchPlaceholder: "Nom, Prénom, E-mail ou Téléphone",
          searchHint:
            "Parcourez l’ensemble des voyageurs enregistrés, ou ajoutez-le manuellement.",
          firstName: "Prénom",
          firstNamePlaceholder: "Saisissez un prénom",
          firstNameRequired: "Prénom requis",
          lastName: "Nom",
          lastNamePlaceholder: "Saisissez un nom",
          lastNameRequired: "Nom requis",
          email: "Email",
          emailPlaceholder: "Saisissez un email",
          emailInfoText:
            "L'email est obligatoire pour pouvoir envoyer les liens de paiements et cautions",
          phone: "Téléphone",
          phonePlaceholder: "Saisissez un numéro de téléphone",
          address: "Adresse",
          addressPlaceholder: "Saisissez une adresse",
          zip: "Code postal",
          zipPlaceholder: "Saisissez un code postal",
          city: "Ville",
          cityPlaceholder: "Saisissez une ville",
          country: "Pays",
          countryRequired: "Pays requis",
          costUnitRequired: "Coût de revient requis.",
          costPriceRequired: "Prix unitaire requis.",
        },
        Payment: {
          title:
            "Enfin, définissez le calendrier de paiement et les conditions",
          subTitle:
            "Renseignez le statut du paiement, les conditions d'annulation et les règles de caution.",
          preFillPaymentPolicy: "Pré-remplir avec une politique de paiement",
          paymentPolicyFormError:
            "Vous devez remplir correctement vos conditions de paiement",
          nextStep: "Créer la réservation",

          calendarPayment: "Calendrier de paiement",
          calendarPaymentDescription:
            "Le séjour sera payé en {{nbTimes}} fois {{time}}, par {{paymentMethods}}",
          calendarArrival: "à l'arrivée des voyageurs",
          calendarDate: "le {{date}}",
          calendarResa: "lors de la réservation ({{date}})",
          NbTimes: {
            one: "une",
            two: "deux",
            three: "trois",
          },
          cancellationPolicy: "Conditions d'annulation",
          isRefundable: "Le séjour est remboursable",
          isNotRefundable: "Le séjour n'est pas remboursable",
          deposit: "Caution ou dépôt de garantie",
          isDeposit:
            "Une caution de {{amount}} est demandée, sous la forme de {{paymentMethod}}",
          isNotDeposit: "Aucune caution ne sera demandée",
          updatePricingConditionsButton: "Modifier les conditions",
          msgAuto:
            "Un message automatisé avec le lien du paiement {{number}} sera envoyé au voyageur dès la finalisation de la réservation.",
          msgAutoBefore:
            "Un message automatisé avec le lien du paiement sera envoyé au voyageur {{number}} jour avant son arrivée, le {{date}} à {{hours}}h",
          msgAutoBeforeWithoutDate:
            "Un message automatisé avec le lien du paiement {{number}} sera envoyé au voyageur {{days}} jour avant l'arrivée.",
          PricingConditions: {
            title:
              "Modifier le calendrier de paiements et les conditions tarifaires",
          },
        },
        Done: {
          title: "Votre réservation a été ajoutée !",
          subTitle:
            "Vous pouvez la retrouver dans votre tableau réservation et modifier les informations.",
          nextStep: "Voir la réservation",
          content1:
            "Afin de permettre au voyageur de procéder au paiement, vous pouvez lui envoyer ces liens de paiement sécurisés :",
          paymentLink: "Lien de paiement",
          sendPayment: "Envoyer le paiement",
          sendDeposit: "Envoyer la caution",
          content2:
            "Ces liens pourront être retrouvés dans l’onglet Paiement et factures du détail de votre réservation.",
          messageTitle: "Vos messages automatisés programmés",
          addMessage: "Ajouter un message automatisé",

          Send: {
            titlePayment: "Envoyer le paiement",
            titleDeposit: "Envoyer la caution",
            button: "Envoyer",
            type: "Type",
            language: "Langue",
            email: "Email *",
            emailRequired: "L'adresse email est requises.",
            emailFormat: "Format invalide.",
            subject: "Sujet *",
            subjectRequired: "Le sujet est requis.",
            message: "Message *",
            messageRequired: "Le message est requis.",
          },

          NoMessage: {
            title: "Aucun message automatisé activé",
            content:
              "Activez-les depuis la section Messages automatisés pour gagner du temps et de la tranquillité. Une fois activé, les messages futurs seront envoyés automatiquement.",
          },
        },
      },
      GuestPage: {
        Global: {
          guestPageDisable: "La page de réservation n'est plus accessible.",
          guestPageErrorLink: "Le lien n'est plus valide.",
          videos: "Vidéos",
          photos: "Photos",
        },
        Contact: {
          firstName: "Prénom",
          firstNameFieldLabel: "Prénom",
          lastName: "Nom",
          email: "Email",
          lastNameFieldLabel: "Nom",
          emailVisible: "visible",
          addContact: "Ajouter un contact",
          emailNotVisible: "non visible",
          phoneVisible: "visible",
          phoneNotVisible: "non visible",
          title: "Contact",
          contactHost: "Contacter vos hôtes",
          contactViaAirbnb: "Contacter via Airbnb",
          contactViaWhatsapp: "Contacter via Whatsapp",
          sendEmail: "Envoyer un email",
        },
        Contract: {
          info: "Pour que tout se passe au mieux pendant votre séjour, merci de lire et signer le contrat suivant.",
          drawSignature: "Veuillez dessiner votre signature",
          again: "Recommencer",
          downloadContract: "Télécharger le contrat",
          downloadContractSigned: "Télécharger le contrat signé",
          confirmContract: "Confirmer la signature",
          guestPageErrorLink: "Le lien n'est plus valide",
          modalTitle: "Contrat signé",
          modalInfo: "Votre contrat a bien été signé",
          modalInfo2:
            "Téléchargez-le et gardez-le jusqu'à 15 jours après votre séjour.",
          viewGuestPage: "Voir la page voyageur",
          Info: {
            dates: "Dates",
            guests: "Voyageurs",
            recap: "Récapitulatif du montant de la réservation",
            paymentDetailName: "Intitulé",
            paymentDetailPrice: "Montant",
            total: "Total (EUR)",
            cancel: "Annulation",
            deposit: "Caution",
            firstName: "Prénom",
            name: "Nom",
            address: "Adresse",
            city: "Ville",
            postalCode: "Code postal",
            country: "Pays",
            email: "Email",
            phone: "Numéro de téléphone",
            guestType: "Type de voyageur",
            particular: "Particulier",
            pro: "Entreprise",
          },
        },
        Instruction: {
          content: "Contenu",
        },
        LoginDetails: {
          hello: "Bienvenue",
          descriptionAccess:
            "Afin d'accéder à la procédure de checkin et autres informations concernant votre séjour, nous avons besoin de quelques informations.",
          language: "Votre langue parlée",
          infos: "Informations",
          email: "E-mail pour vous contacter",
          emailPlaceholder: "Votre email",
          phone: "Téléphone mobile pour vous contacter",
          phonePlaceholder: "Votre numéro de téléphone",
          phonePlaceholderMobile: "Votre téléphone",
          beds: "De combien de lit avez-vous besoin ?",
          bedsDetails:
            "Si vous avez besoin de lit parapluie, merci de ne pas les comptabiliser et de les demander dans la remarque complémentaire.",
          checkinCheckout: "Arrivée et départ",
          timeIn: "Votre heure d'arrivée prévue",
          timeOut: "Votre heure de départ prévue",
          timeInReached: "L'heure d'arrivée doit être après {{time_in}}",
          timeOutReached: "L'heure de départ doit être avant {{time_out}}",
          successMessageTime:
            "Vos heures d'arrivée et de départ ont bien été prise en compte",
          fieldRequired: "Le champ est obligatoire",
          descriptionTimes:
            "Vous pourrez déclarer votre heure d’arrivée et de départ ultérieurement si vous ne savez pas encore.",
          filesRequired: "Documents obligatoires",
          filesRequiredDescription:
            "Votre hôte vous demande de bien vouloir ajouter les documents suivants, nécessaires pour votre séjour :",
          card_id: "Carte d'identité (recto et verso)",
          cardIdButtonLabel: "Ajoutez votre carte d'identité",
          cardIdModalTitle:
            "Notice pour l’import de la carte nationale d’identité",
          cardIdDescription:
            "Veuillez prendre en photo le recto et le verso de votre carte nationale d’identité.",
          passport: "Passeport",
          passeportButtonLabel: "Ajoutez votre passeport",
          passeportModalTitle: "Notice pour l’import du passeport",
          passeportDescription:
            "Veuillez prendre en photo votre page principale de votre passeport.",
          selfie: 'Photo "selfie"',
          selfieButtonLabel: 'Ajoutez votre photo "selfie"',
          selfieModalTitle: 'Notice pour l’import de la photo "selfie"',
          selfieDescription:
            "Veuillez vous prendre en photo en selfie avec votre carte d’identité / votre passeport dans la main.",
          cb: "Carte bancaire avec champs cachés",
          cbButtonLabel: "Ajoutez votre carte bancaire",
          cbModalTitle: "Notice pour l’import de la carte bancaire.",
          cbDescription:
            "Veuillez prendre en photo le recto de votre carte bancaire, en cachant les numéros.",
          police: "Fiche de police",
          addFileBadge: "Ajoutée",
          addFileLabel: "Ajouter la photo",
          addFileRectoLabel: "Ajouter le recto",
          addFileVersoLabel: "Ajouter le verso",
          remarks: "Remarques Complémentaires",
          remarksPlaceholder: "Besoin d'un lit de bébé ou autre ?",
          additionalQuestionPlaceholder: "Votre réponse ici",
          cguCheckbox: "En cochant cette case, j'accepte les",
          cgu: "Conditions générales d'utilisation",
          acceptCgu: "Veuillez accepter les conditions générales d'utilisation",
          preview: "Aperçu",
          checkFile: "Verifiez que",
          photoWellFramed: "La photo est bien cadrée",
          noReflection: "Il n’y a pas de reflet",
          photoClearGoodQuality: "La photo est nette et de bonne qualité",
          allInformationReadable: "L’ensemble des informations sont lisibles",
          nameMatchesReservation:
            "Le nom et prénom correspondent à ceux de la réservation.",
          cardFieldsHiddenNameVisible:
            "Les champs de la carte sont cachés, mais le nom reste visible",
          faceVisibleNotHiddenByCard:
            "Votre visage est visible, non caché par la carte",
          cardYouAreHoldingIsReadable: "La carte que vous tenez est lisible",
          rulesFile: "Règles",
          acceptedFormatsPdfPngJpgJpeg:
            "Formats acceptés : pdf, png, jpg et jpeg",
          acceptedFormatsPngJpgJpeg: "Formats acceptés : png, jpg et jpeg",
          maxFileSize10Mb: "Taille maximale par fichier : 10 Mo",
          importFile: "Importer votre photo ci-dessous",
        },
        Tab: {
          reservation: "Séjour",
          extra: "Extras",
          payment: "Paiements",
          contact: "Contact",
          contract: "Contrat",
        },
        Reviews: {
          title: "Laissez nous un avis !",
          writeReview: "Noter votre séjour",
          leaveAReview: "Laisser un avis",
          yourComment: "Votre commentaire (Optionnel)",
          successMessage: "Votre avis a été envoyé avec succès",
        },
        Wifi: {
          title: "Connexion WIFI",
          wifi_name: "Réseau",
          wifi_password: "Mot de passe",
          connect: "Se connecter",
          copy: "Copié",
        },
        Reservation: {
          thanks: "Merci {{firstName}} !",
          helloFirstName: "Bienvenue {{firstName}}",
          dDay: "J-{{day}}",
          dayUntilReservation: "Plus que {{day}} jour avant votre séjour.",
          daysUntilReservation: "Plus que {{day}} jours avant votre séjour.",
          todayUntilReservation: "Votre séjour est aujourd'hui",
          pastUntilReservation: "Votre séjour est passé",
          title: "Votre réservation",
          checkin: "Arrivée",
          checkout: "Départ",
          between: "Entre",
          and: "Et",
          arrival_from: "À partir de",
          departure_before: "Avant",
          theDate: "le {{date}}",
          instructionCheckin: "Instructions d'arrivée",
          instructionCheckout: "Instructions de sortie",
          extras: "Besoin d'autre chose ?",
          extraSubTitle:
            "Ajoutez des extras ou des suppléments pour un séjour inoubliable.",
          welcomeGuide: "Guide de bienvenue",
          instructionsDetails: "Voir les instructions en détails",
          codeBuilding: "Code d'entrée de l'immeuble",
          codeKeys: "Code de la boîte à clé",
          codeDescription: "Les codes ci-dessous seront visibles",
          codeDescription2: "SEULEMENT SI",
          codeDescription3: "les",
          codeDescription4: "instructions d'arrivée",
          codeDescription5: "ci-dessus sont activées pour le voyageur.",
          goMaps: "S'y rendre",
          info: "Informations",
          photosAndVideos: "Photos et vidéos",
        },
        InstructionCheckin: {
          title: "Instructions d'arrivée",
          description: "Pour que l'entrée se passe parfaitement",
          codeDescription:
            "Les codes seront actifs à partir de l’heure prévue de votre arrivée.",
        },
        InstructionCheckout: {
          title: "Instructions de départ",
          description: "Pour que la sortie se passe parfaitement",
        },
        WelcomeGuide: {
          title: "Guide de bienvenue",
          description: "Le guide pour votre séjour",
        },
        Extras: {
          validateExtras: "Valider mes extras",
          displayCart: "Voir tous les extras",
        },
        ExtrasPayment: {
          goBack: "Retour",
          title: "Mes extras",
          elementInCart: "{{nb}} élément dans votre panier",
          elementsInCart: "{{nb}} éléments dans votre panier",
          elementInCartEmpty: "Aucun élement dans votre panier",
          total: "Total",
          goBackShop: "Retour à la boutique",
          preAuthorized: "Pré-autorisation bancaire {{amount}}",
          preAuthorizedDescription:
            "Vous ne serez débité que si l'hôte valide vos extras.",
          validatePreAuthorized: "Valider la pré-autorisation",
          extraToValidateByHost:
            "Certains produits nécéssitent une vérification des disponibilités par votre hôte.",
          cgv: "Conditions générales de vente",
          acceptCgv:
            "En fournissant vos informations de carte bancaire, vous acceptez les",
        },
        ExtraHistorical: {
          displayHistorique: "Voir mon historique",
          goBack: "Retour",
          title: "Mon historique",
          nbOrder: "{{nb}} commande réalisée.",
          nbOrders: "{{nb}} commandes réalisées.",
          nbOrderEmpty: "Aucune commande réalisée.",
          commandeDate: "Commande du {{date}}",
          idCommand: "Numéro {{numCommand}}",
          historical: "Historique",
        },
        Payment: {
          title: "Paiements",
          subTitle: "Suivi de vos paiements",
          deposit: "Caution à pré-autoriser",
          bankImprint: "Empreinte bancaire - prévu le {{date}}",
          account: "Prévu le {{date}}",
          payNow: "Payer maintenant",
          displayInvoice: "Voir la facture du séjour",
        },
        PaymentModal: {
          title: "Paiement validé",
          description: "Votre paiement a bien été pris en compte",
          preAuthTitle: "Pré-autorisation validée",
          preAuthDescription1: "Votre extra a bien été commandé.",
          preAuthDescription2:
            "Si l'hôte le valide, vous serez débité et recevrez un message de validation.",
          preAuthDescription3:
            "Sinon, vous serez tenu informé de son indisponibilité.",
          button: "Ok",
        },
        Invoice: {
          title: "Facture",
          yourInvoice: "Votre facture",
          name: "Intitulé",
          amount: "Montant",
          totalTTC: "Total TTC",
          quantity: "Quantité",
          totalHT: "Total HT",
          tva: "TVA",
          guest: "Client",
          updateInfo: "Modifier les informations de facturation",
          download: "Télecharger",
        },
      },
      GuestWebsite: {
        404: "Oops! La page que vous rechercher n'existe pas.",
        book: "RÉSERVER",
        about: "À propos",
        adults: "Adultes",
        adults_small: "adultes",
        children: "Enfants",
        children_small: "enfants",
        children_singular: "enfant",
        checkin: "Arrivée",
        checkout: "Départ",
        required: "Le champ {{input}} est requis",
        betweenHours: "Entre {{date}} et {{date2}}",
        nights: "nuits",
        night: "nuit",
        checkins: "Arrivée et Départ",
        photos: "Photos",
        seePhotos: "Voir toutes les photos",
        ht: "HT",
        tva: "TVA",
        ttc: "TTC",
        stay: "Séjour",
        taxStay: "Taxe de séjour",
        cleaningFees: "Frais de ménage",
        otherFees: "Autres frais",
        otherGuests: "Frais pour un voyageur supplémentaire",
        Header: {
          Nav: {
            rentals: "NOS LOGEMENTS",
            services: "SERVICES",
            about: "À PROPOS",
            contact: "CONTACT",
          },
          titleRentals: "Réservez votre séjour",
          subtitleRentals:
            "Vous êtes à 2-clicks d'une expérience extraordinaire",
          titleServices: "Nous sommes aux petits soins des voyageurs",
          subtitleServices: "Découvrez les services inclus avec votre séjour",
          titleAbout:
            "Des hôtes avec pour objectif de vous faire passer un séjour inoubliable",
          subtitleAbout: "Découvrez qui est derrière SuperTemplate",
          titleContact: "Des questions ?",
          subtitleContact: "Discutons !",
          titleMentions: "Mentions légales",
          titleConditions: "Conditions générales",
          titlePrivacy: "Politique de confidentialité",
        },
        Footer: {
          copyright: "© 2025 — Tous droits réservés",
          templateBy: "Modèle de site fait avec",
          bySuperhote: "par Superhote",
          Payment: {
            title: "Mode de paiements acceptés",
            descriptionTitle: "Notre service de paiement est 100% sécurisé.",
            description:
              "Nous utilisons l'encryption AES-256-bits de niveau \n bancaire fournis par notre intermédiaire de paiement \n (Stripe) pour vous garantir une sécurité maximale lors de \n vos réservations.",
          },
          Link: {
            title: "Liens utiles",
            mention: "Mentions Légales",
            conditions: "Conditions Générales",
            privacy: "Politique de confidentialité",
          },
          Nav: {
            rentals: "Nos logements",
            services: "Services",
            about: "Qui sommes-nous ?",
            contact: "Contact",
          },
          Banner: {
            ready: "Prêt à passer un excellent séjour ?",
            buttonTitle: "RÉSERVER MAINTENANT",
          },
        },
        Home: {
          book: "RÉSERVER MON SÉJOUR",
          reviewTitle: "Découvrez quelques témoignages de précédents voyageurs",
        },
        Rentals: {
          bookNow: "Réserver maintenant",
          guests: "voyageurs",
          rooms: "chambres",
          bathRooms: "salles de bains",
          title: "Nos logements",
          subTitle: "",
        },
        Rental: {
          Home: {
            instantBooking: "Réservation instantanée",
            instantBookingInfo:
              "Vous pouvez réserver cet hébergement sans confirmation de l’hôte",
            noInstantBooking: "Demande de réservation nécessaire",
            noInstantBookingInfo: "Demande de réservation nécessaire",
            titleBlockInfo: "À savoir",
            noSmoking: "Logement non fumeur",
            noPets: "Animaux non autorisés",
            noParties: "Fêtes interdites",
            checkinStart: "Checkin à partir de {{hour}}",
            checkinEnd: "Checkout jusqu’à {{hour}}",
            titleBlock1: "Pourquoi venir ?",
            titleBlock2: "Services proposées",
            titleBlock3: "Extras",
            subTitleBlock3: "Vous pourrez les ajouter à l’étape suivante",
            mapText: "Adresse",
            titleBlock4: "Conditions d'annulation",
            titleBlock5: "Caution",
            Error: {
              diffDates:
                "Les dates d'arrivée et de départ doivent être différents",
              dateCheckinBeforeCheckout:
                "Les dates d'arrivée doivent être avant celle de départ",
            },
          },
          Info: {
            title: "Vos informations",
            titleBlock: "Coordonnées",
            additionnalInfo: "Commentaire additionnel",
            additionnalInfoPlaceholder:
              "Dites-nous en plus sur votre venue ou sur vos besoins éventuels !",
          },
          Payment: {
            yourReservation: "Votre réservation",
          },
          Extras: {
            success: "Réservation validée !",
            orderExtras:
              "Ne fermez pas cette page, nous avons une dernière proposition pour vous !",
            thanksBlock:
              "Merci d’avoir réservé un séjour chez {{rentalName}}. ",
            addExtras: "Ajouter des extras à votre séjour",
            validCart: "Valider mon panier",
            buttonAddExtras: "Non merci, j'ai ce qu'il me faut",
          },
          ExtrasPayment: {
            titleBlock: "Extras",
            congratsBlock: "Réservation et extras validés !",
            thanksBlock:
              "Merci d’avoir réservé un séjour chez {{rentalName}}. ",
            infoByEmail: "Vous retrouverez vos informations par email.",
          },
          External: {
            title:
              "Voulez-vous vraiment désynchroniser cet hébergement de {{name}}",
            titleSub:
              "Cela aura pour effet de supprimer la connexion à la plateforme pour cet hébergement. Vos réservations passées ne seront pas impactées.",
            titleSubTwo:
              "Veuillez choisir comment vous voulez gérer vos réservations futures :",
            checkboxTitleDelete: "Supprimer toute les réservations futures",
            checkboxSubDelete:
              "Les réservations futures seront supprimées de SuperHote.",
            checkboxTitleNotDelete: "Conserver toute les réservatiosn futures",
            checkboxSubNotDelete:
              "Les messages / conversations ne seront plus synchronisés sur SuperHote mais toujours visible.",
            cancel: "Annuler",
            desync: "Désynchroniser",
          },
        },
        Contact: {
          congrats: "Félicitations",
          sendSuccess: "Message envoyés avec succès",
          formContact: "Formulaire de contact",
          firstName: "Prénom",
          lastName: "Nom",
          email: "Email",
          invalidEmail: "Email invalide",
          subject: "Sujet",
          message: "Message",
          address: "Adresse",
          phone: "Téléphone",
          postalCode: "Code postal",
          city: "Ville",
          country: "Pays",
          your: "Votre",
          contact:
            "Ou contactez-nous directement par téléphone ou email en cliquant sur les boutons ci-dessous:",
        },
        Payment: {
          Form: {
            preAuthorizedDescriptionReservation:
              "Vous ne serez débité que si l'hôte valide votre réservation.",
          },
        },
      },
      AddRental: {
        Summary: {
          infosTitle: "Informations générales",
          infosDesc:
            "Vous pourrez compléter les détails de l’hébergement plus tard.",
          roomsTitle: "Chambres et lits",
          roomsDesc:
            "Vous pourrez compléter les détails de l’hébergement plus tard.",
          photosTitle: "Photos et description",
          photosDesc:
            "Vous pourrez compléter les détails de l’hébergement plus tard.",
          facilitiesTitle: "Équipements",
          facilitiesDesc:
            "Vous pourrez compléter les détails de l’hébergement plus tard.",
          rulesTitle: "Horaires et règles",
          rulesDesc:
            "Vous pourrez compléter les détails de l’hébergement plus tard.",
          tarificationTitle: "Tarification",
          tarificationDesc:
            "Vous pourrez compléter les détails de l’hébergement plus tard.",
          doneTitle: "Terminé !",
          doneDesc: "Vous avez fini la création de l’hébergement",
          seasonTitle: "Ajout des saisons",
          seasonDesc: "Optimisation du prix et règles spécifiques par période.",

          yourRental: "Votre logement",
          description: "Description",
          facilities: "Équipements",
          minCheckin: "Heure début arrivée",
          maxCheckin: "Heure max arrivée",
          checkout: "Heure de départ",
          rooms_bathrooms_one:
            "Chambre(s) : ? Lit(s) : ? Salle de bain : {{count}}",
          rooms_bathrooms_other:
            "Chambre(s) : ? Lit(s) : ? Salles de bain : {{count}}",
          rooms_only_one: "Chambre : {{count}}",
          rooms_only_other: "Chambres : {{count}}",
          beds_only_one: "Lit : {{count}}",
          beds_only_other: "Lits : {{count}}",
          bathrooms_only_one: "Salle de bain : {{count}}",
          bathrooms_only_other: "Salles de bain : {{count}}",
        },
        Close: {
          title: "Voulez-vous vraiment quitter l’ajout de cet hébergement ?",
          content:
            "Si vous quittez maintenant, vous perdrez les informations remplies jusqu’à présent.",
          button: "Oui, quitter",
        },
        Infos: {
          title: "Ajouter un hébergement",
          cityPlaceholder: "Ville",
          city: "Ville",
          cityError: "La ville est requise",
          addressPlaceholder: "Adresse",
          postalCodeError: "Le code postal est requis",
          postalCode: "Code postal",
          trackNumber: "Numéro de voie et rue ",
          trackNumberPlaceholder: "Numéro de voie et rue ",
          postalCodePlaceholder: "Code postal",
          countryError: "Le pays est requis",
          country: "Pays",
          countryPlaceholder: "Pays",
          useGoogleAddress: "Revenir à l'adresse en une ligne",
          useOtherAddress: "Je ne trouve pas mon adresse",
          subTitle:
            "Ajouter maintenant un hébergement manuellement à Superhote en remplissant quelques informations sur celui-ci.",
          name: "Nom de votre hébergement",
          namePlaceholder: "Appartement Le Marais",
          nameError: "Le nom est requis",
          rentalCategory: "Catégorie d’hébergement",
          rentalType: "Type d’hébergement",
          rentalTypeError: "Le type d'hébergement est requis.",
          rentingType: "Type d’annonce",
          RentingTypeLabel: {
            full: "Logement entier",
            room: "Une chambre",
            shared: "Une chambre partagée",
          },
          managementType: "Type de gestion",
          ManagementTypeLabel: {
            mine: "Investissement immobilier (Propriétaire du bien)",
            location: "Sous-location",
            conciergerie: "Conciergerie",
          },
          address: "Adresse",
          addressError: "Veuillez saisir une adresse valide",
          maxGuests: "Nombre de voyageurs maximum",
          bathrooms: "Salles de bains",
          maxAdults: "Nombre d’adultes maximum",
          maxChildren: "Nombre d’enfants maximum",
        },
        ConfirmAddress: {
          title: "Validez votre localisation exacte",
          subTitle:
            "Epinglez l'emplacement exact de votre hébergement, afin de mette à jour la latitude et la longitude. générées par défaut.",
          subTitle2:
            "Ces élements sont importants pour les instructions d'arrivée et la tarification dynamique.",
          subTitle3: "Adresse de l'hébergement",
          latitude: "Latitude",
          longitude: "Longitude",
        },
        Rooms: {
          title: "Ajoutez des chambres et leurs détails",
          subTitle:
            "Ajoutez les pièces dans lesquelles les voyageurs dormiront.",
          noRoomTitle: "Aucune chambre ajoutée",
          noRoomDesc: "Il n’y a aucune chambre ajoutée pour cet hébergement.",
          noRoomButton: "Ajouter une pièce",
          private: "Privatif",
          shared: "Partagé",
          addTitle: "Ajouter une pièce",
          editTitle: "Modifier une pièce",
          informations: "Informations",
          name: "Nom de la pièce",
          nameError: "Le nom est requis",
          namePlaceholder: "Chambre",
          roomType: "Type de la pièce",
          privateBathroom: "Salle de bain privative",
          privateRoom: "Espace partagé avec d’autres",
          privateRoomDesc: "Pour un salon par exemple",
          nbAdults: "Nombre d’adultes",
          nbAdultsMinAdultError:
            "Ceci est la première chambre, il faut au moins 1 adulte.",
          nbAdultsMinError: "Il faut au moins 1 adulte ou 1 enfant.",
          nbChildren: "Nombre d’enfants",
          bedsInRoom: "Couchages dans la pièce",
          bedsMinError: "Il faut au moins 1 couchage dans la pièce.",
          nbInfantsBeds: "Lit bébé",
          nbChildrenBeds: "Lit d’enfant",
          nbDoubleBeds: "Lit double",
          nbWallBeds: "Lit escamotable",
          nbQueenBeds: "Lit Queen Size",
          nbKingBeds: "Lit King Size",
          nbMezzanineBeds: "Lit Mezzanine",
          nbSofaBeds: "Canapé lit",
          nbSingleBeds: "Lit simple",
        },
        Photos: {
          noPhotos: "Vous devez ajouter au moins une photo",
          title: "Ajoutez une description et des photos",
          subTitle:
            "Donnez une identité unique à votre logement avec des photos et une description globale de l’hébergement.",
          subTitle2: "Pour vous aider, découvrez",
          description: "Description",
          descriptionError: "La description est requise",
          addPhoto: "Faites glisser vos photos ici",
          upTo1: "Choisissez au moins 1 photo",
          formatAccepted: "Formats acceptés : jpeg, png",
          WeightAccepted: "Poids maximum : 10 Mo",
          DimensionsMinimum: "Dimensions minimum par image : 1024x683",
          download: "Télécharger depuis votre appareil",
          photosError: "Il faut au moins 5 photos",
        },
        Facilities: {
          title: "Ajoutez des équipements",
          subTitle:
            "Sélectionnez les équipements disponibles dans votre logement. Vous pourrez en ajouter d’autres plus tard.",
        },
        Rules: {
          title: "Définissez les horaires et les règles",
          subTitle:
            "Les règles plus avancées pourront être paramétrées une fois l’hébergement créé.",
          checkin: "Arrivée",
          minTimein: "Arrivée à partir de",
          minTimeinError: "Heure d'arrivée minimum non valide",
          minTimeinTooltip:
            "L'heure d'arrivée minimum est l'heure à partir de laquelle le voyageur peut arriver dans l'hébergement.",
          maxTimein: "Jusqu’à",
          maxTimeinError: "Heure d'arrivée maximum non valide",
          maxTimeinTooltip:
            "L'heure d'arrivée maximum est l'heure jusqu'à laquelle le voyageur peut arriver dans l'hébergement.",
          checkout: "Départ",
          minTimeout: "Départ à partir de",
          minTimeoutTooltip:
            "L'heure de départ minimum est l'heure à partir de laquelle le voyageur peut partir de l'hébergement.",
          maxTimeout: "Jusqu’à",
          maxTimeoutError: "Heure de départ maximum non valide",
          maxTimeoutTooltip:
            "L'heure de départ maximum est l'heure jusqu'à laquelle le voyageur peut partir de l'hébergement.",
          minNights: "Nombre de nuits minimum",
          maxNights: "Nombre de nuits maximum",
        },
        Tarification: {
          title: "Enfin, parlons argent.",
          subTitle:
            "Définissez les tarifs de votre logement. Les paramètres avancés pourront être définis plus tard.",
          priceByNight: "Prix par nuit",
          currency: "Devise",
          vat: "TVA",
          cleaning: "Frais de ménage",
          linen: "Frais de linge",
          extraGuestPrice: "Frais de voyageur supplémentaire",
          extraGuestCapacity: "À partir de combien de voyageurs ?",
          extraGuestCapacityError:
            "Est supérieur au nombre max de voyageur ({{count}})",
          taxType: "Type taxe de séjour",
          taxTypeFix: "Fixe",
          taxTypePercent: "Pourcentage",
          fixValue: "Prix par personne par nuit",
          percentValue: "Pourcentage du prix de la nuit",
          percentMax: "Plafond par personne par nuit",
          percentExtra: "Taxes additionnelles",
          nextStep: "Créer l'hébergement",
        },
        Done: {
          title: "Votre hébergement est créé !",
          subTitle:
            "Il est maintenant disponible dans la liste de vos hébergements.",
          subTitle2: "Ce que vous pouvez maintenant faire :",
          CanDoList: {
            connectExternalPlatforms:
              "Connecter l'hébergement aux <0>plateformes externes</0>",
            defineReservationRules: "Définir des <0>règles de réservations</0>",
            definePaymentPolicies:
              "Définir des <0>politiques de paiement</0> (annulation, paiement, caution)",
            defineMarkupRates:
              "Définir des <0>taux de majoration</0> par plateformes",
          },
          muchMore: "Et bien plus encore ! ",
          content2active: "L'hébergement est actif. ",
          content3active:
            "Si vous ne souhaitez pas que l'hébergement soit actif, merci de décocher le bouton ci-dessous :",
          content2inactive: "L'hébergement est inactif. ",
          content3inactive:
            "Si vous souhaitez activer l'hébergement, merci de cocher le bouton ci-dessous :",
          content4:
            "Si vous le souhaitez, vous pouvez paramétrer les saisons dès maintenant. Sinon, ce seront les paramètres par défaut qui seront sélectionnés.",
          active: "Hébergement actif",
          inactive: "Hébergement inactif",
          gotoSeason: "Paramètrer les saisons",
          gotoList: "Retourner à la liste des hébergements",
          gotoRental: "Voir l'hébergement",
          gotoProgression: "Retournez à la page progression",
        },
      },
      Management: {
        title: "Gestion opérationnelle",
        teams: "Équipes",
        roles: "Postes",
        owners: "Propriétaires",
        tasks: "Tâches",
        automations: "Automatisations",
        payments: "Payments",
        activity: "Activité",
        addTeam: "Ajouter une équipe",
        addMember: "Inviter un nouveau membre",
        addOwner: "Ajouter un propriétaire",
        searchMember: "Rechercher un membre",
        searchRole: "Rechercher un poste via son nom et ses membres ...",
        searchOwner: "Rechercher un propriétaire",
        noRoles: "Il n'y a pas de poste",
        leaveTeam: "Quitter l'équipe",
        TeamList: {
          titlePlaceholder: "Équipe opérationnelle",
          rentalsAssigned_one: "{{count}} hébergement assigné",
          rentalsAssigned_other: "{{count}} hébergements assignés",
          invitSent: "En attente",
          teamOf: "{{name}} de",
          Empty: {
            title: "Aucun membre dans l'équipe",
            content: "Vous n'avez actuellement aucun membre dans l'équipe",
          },
        },
        RoleList: {
          name: "Nom",
          membersWithThisRole: "Membres ayant ce poste",
          roles: "Roles",
          member_one: "{{count}} membre",
          member_other: "{{count}} membres",
          access_one: "{{count}} accès",
          access_other: "{{count}} accès",
          addRole: "Ajouter un poste",
        },
        OwnerList: {
          name: "Nom",
          mail: "E-mail",
          phone: "Téléphone",
          rentals: "Hébergements",
          roles: "Postes",
        },
        ViewMember: {
          informations: "Informations",
          roles: "Postes",
          noRole: "Aucun rôle assigné",
          noRental: "Aucun hébergement assigné",
          teams: "Équipes",
          teamsAccess: "Équipes auxquelles {{firstName}} a accès",
          rentals: "Hébergements",
          rentalsAccess: "Hébergements auxquels {{firstName}} a accès",
          active: "Actif",
          accessTitle:
            "Accès (défini par défaut à partir des postes sélectionnés)",

          reSendInvit: "Réenvoyer le lien d'invitation",
        },
        EditMember: {
          title: "Modifier les droits de {{fullName}}",
          roles: "Postes",
          rolesIdRequired: "Sélectionnez 1 poste",
          assignedTeams: "Équipes assignées",
          teamsIdRequired: "Sélectionnez au moins 1 équipe",
          assignedRentals: "Hébergements assignées",
          rentalsIdRequired: "Sélectionnez au moins 1 hébergement",
        },
        RemoveMember: {
          title: "Voulez-vous vraiment supprimer le membre {{fullName}} ?",
          content:
            "Cela aura pour effet de supprimer la personne de votre espace SuperHote.",
          button: "Oui, supprimer",
        },
        RemoveTeam: {
          title: "Voulez-vous vraiment supprimer cette équipe ?",

          content:
            "Cela aura pour effet de supprimer l’équipe de Superhote et les membres faisant uniquement partie de cette équipe. Assurez-vous donc que l’équipe est vide avant de la supprimer.",
          button: "Oui, supprimer",
        },
        LeaveTeam: {
          title: "Voulez-vous vraiment quitter cette équipe ?",
          title2:
            "Voulez-vous vraiment quitter cette équipe et cet espace de travail",

          content:
            "Cela aura pour effet de vous retirer de l'équipe. Vous ne recevrez plus les notifications et vous ne pourrez plus accéder aux tâches de l'équipe.",
          content2:
            "Cela aura pour effet de vous retirer de l'équipe et de l'espace associé de cette Team . Vous ne recevrez plus les notifications et vous ne pourrez plus accéder à aucun élement de cet espace.",
          button: "Oui, quitter",
        },
        AddTeam: {
          title: "Ajouter une équipe",
          informations: "Informations",
          name: "Nom",
          nameError: "Le nom est requis",
          members: "Membres dans l'équipe",
          pending: "En attente",
          addTeam: "Ajouter l'équipe",
        },
        EditTeam: {
          informations: "Informations",
          name: "Nom",
          nameError: "Le nom est requis",
          members: "Membres dans l'équipe",
          pending: "En attente",
        },
        Right: {
          accessTitle: "Accès aux sections du menu :",
          accessSubTitle:
            "Tous les onglets activées seront accessibles et visibles par le prestataire.",

          dashboardTitle: "Tableau de bord",
          dashboardSubTitle:
            "Affichage synthétique du CA réalisé et des prochaines tâches à effectuer",
          dashboard_reservationsTitle: "Tableau de bord de vos réservations",
          dashboard_reservationsSubTitle:
            "Affichage synthétique du CA de vos réservations et du taux d’occupation",
          dashboard_prestationTitle:
            "Tableau de bord des prestations du membre",
          dashboard_prestationSubTitle:
            "Affichage synthétique du CA des prestations réalisées par le membre d’équipe",

          calendarsTitle: "Calendriers",
          calendarsSubTitle: "Affichage du calendrier des réservations",

          reservationsTitle: "Réservations",
          reservationsSubTitle:
            "Affichage des réservations et accès aux détails",

          reservations_messageTitle: "Messages",
          reservations_messageSubTitle:
            "Affichage et gestion des messages des réservations",
          reservations_payment_and_invoiceTitle: "Paiements et factures",
          reservations_payment_and_invoiceSubTitle:
            "Affichage et gestion des paiements et factures des réservations",
          reservations_contrats_and_documentTitle: "Contrats et documents",
          reservations_contrats_and_documentSubTitle:
            "Affichage et gestion des contrats et documents des réservations",
          reservations_taskTitle: "Tâches",
          reservations_taskSubTitle:
            "Affichage et gestion des tâches des réservations",

          rentalsTitle: "Hébergements",
          rentalsSubTitle: "Affichage des hébergements",
          rental_tarificationTitle: "Tarification",
          rental_tarificationSubTitle:
            "Affichage et gestion de la tarification de l’hébergement",
          rental_serviceTitle: "Services",
          rental_serviceSubTitle:
            "Affichage et gestion des services de l’hébergement",
          rental_documentTitle: "Documents",
          rental_documentSubTitle:
            "Affichage et gestion des documents de l’hébergement",
          rental_checklistTitle: "Checklists",
          rental_checklistSubTitle:
            "Affichage et gestion des checklists de l’hébergement",
          rental_guest_pageTitle: "Page voyageur",
          rental_guest_pageSubTitle:
            "Affichage et gestion de la page voyageur de l’hébergement",
          rental_reservation_brownerTitle: "Moteur de réservations",
          rental_reservation_brownerSubTitle:
            "Affichage et gestion du moteur de réservations de l’hébergement",

          paymentsTitle: "Paiements",
          paymentsSubTitle:
            "Affichage des différentes fonctionnalités liés aux paiements",
          payment_reservationsTitle: "Réservations",
          payment_reservationsSubTitle:
            "Affichage et gestion des paiements des réservations",
          payment_cautionsTitle: "Cautions",
          payment_cautionsSubTitle:
            "Affichage et gestion des paiements des cautions",
          payment_extrasTitle: "Extras",
          payment_extrasSubTitle: "Affichage et gestion des commandes d’extras",
          payment_mean_of_collectionTitle: "Moyens de d'encaissement",
          payment_mean_of_collectionSubTitle:
            "Affichage et gestion des moyens d’encaissements (Stripe)",
          payment_invoicesTitle: "Factures",
          payment_invoicesSubTitle: "Affichage et gestion des factures",

          messagesTitle: "Messages",
          messagesSubTitle:
            "Affichage et gestion des conversations avec les voyageurs",

          auto_messagesTitle: "Messages automatisés",
          auto_messagesSubTitle:
            "Affichage des différentes fonctionnalités liés aux messages automatisés",

          automated_messagesSubTitle: "Messages automatisés",
          automated_messagesTitle:
            "Affichage et gestion des messages automatisés",
          message_dynamics_fieldsTitle: "Champs dynamiques",
          message_dynamics_fieldsSubTitle:
            "Affichage des différentes fonctionnalités liés à la gestion opérationnelle",

          managementTitle: "Gestion opérationnelle",
          managementSubTitle: "Gestion opérationnelle",

          operational_management_tasksTitle: "Tâches",
          operational_management_tasksSubTitle:
            "Affichage et gestion des tâches concernant le prestataire",

          operational_management_teamTitle: "Équipes",
          operational_management_teamSubTitle:
            "Affichage et gestion des équipes",

          operational_management_automationTitle: "Automatisations",
          operational_management_automationSubTitle:
            "Affichage et gestion des automatisations des tâches",

          operational_management_paymentTitle: "Paiements",
          operational_management_paymentSubTitle:
            "Affichage et gestion des prestations concernant le prestataire",

          analyticsTitle: "Statistiques",
          analyticsSubTitle:
            "Affichage et gestion des performances de l’activité",

          visibilityTitle: "Droits et visibilité : ",
          hidePrices: "Masquer les prix des séjours",
          hidePricesDescription:
            "Si cette option est activée, tous les prix seront remplacés par des étoiles “***”.",
          hideInfos: "Masquer les informations sensibles",
          hideInfosDescription:
            "Si cette option est activée, le nom, prénom, mail, numéro de téléphone et l’adresse du voyageur seront remplacés par des étoiles “***”.",
          noEdit: "Interdire les modifications",
          noEditDescription:
            "Si cette option est activée, le prestataire ne pourra modifier aucune information des fonctionnalités suivantes : calendriers, réservations, hébergements, paiements, messages, gestion opérationnelle et messages automatisés.",
        },

        AddMember: {
          title: "Inviter un nouveau membre à l'équipe",
          sendInvit: "Envoyer l'invitation",
          Email: {
            title: "Adresse e-mail du membre d'équipe",
            email: "E-mail",
            emailPlaceholder: "kim.milan@gmail.com",
            emailRequired: "L'adresse e-mail est requise",
            emailInvalid: "L'adresse e-mail n'a pas le bon format",
            content1:
              "Veuillez renseigner l'adresse email de votre nouveau membre d'équipe. Une invitation lui sera automatiquement envoyée par e-mail.",
            content2:
              "Pour ajouter à cette équipe un membre déjà présent dans une autre équipe, vous devez modifier l’équipe ou cliquer sur le membre directement. ",
          },
          Rentals: {
            title: "Hébergements",
            rentalsIdRequired: "Sélectionnez au moins 1 hébergement",
            content1:
              "Veuillez sélectionner les hébergements auxquels le membre aura accès.",
          },
          Roles: {
            title: "Postes",
            rolesIdRequired: "Sélectionnez 1 poste",
            content1:
              "Veuillez sélectionner le ou les postes du membre. Cela appliquera donc les accès définies pour ce ou ces postes.",
            content2: "Choissisez en au minimum un.",
            roles: "Postes",
          },
        },
        RemoveRole: {
          title: "Voulez-vous vraiment supprimer ce poste ?",
          content: "Cela supprimera ce poste des utilisateurs concernés.",
          button: "Oui, supprimer",
        },
        AddRole: {
          title: "Ajouter un poste",
          informations: "Informations",
          name: "Nom",
          nameError: "Le nom est requis",
          namePlaceholder: "Le nom du poste",
          members: "Personnes à ce poste",
          addRole: "Créer le poste",
        },
        EditRole: {
          title: 'Modifier le poste "{{roleName}}"',
          informations: "Informations",
          name: "Nom",
          nameHint:
            "Ce poste est un poste par défaut créé par Superhote. Vous ne pouvez pas modifier son nom.",
          nameError: "Le nom est requis",
          namePlaceholder: "Le nom du poste",
          members: "Personnes à ce poste",
          addRole: "Créer le poste",
        },
        Workspace: {
          filter_zero: "Aucun workspace",
          filter_one: "{{count}} filtre appliqué",
          filter_other: "{{count}} filtres appliqués",
          myWorkpace: "Mon espace",
          choose: "Choisissez les espaces que vous souhaitez afficher : ",
          save: "Filtrer",
        },
        Payments: {
          search: "Rechercher un paiement",
          add: "Ajouter un paiement",
          all: "Tout",
          paiments: "paiments",
          total: "Montant total des prestation",
          bedrooms: "Couchages changées",
          amount: "Montant",
          date: "Date",
          status: "Status",
          paid: "Payé",
          invoiceReceived: "Facture reçue",
          toPaid: "À payer",
          paymentType: "Type de paiement",
          amountFromTo: "Montant compris entre",
          and: "Et",
          prestaDate: "Date de la préstation",
          dateFromTo: "Date de la préstation : entre le",
          andThe: "Et le",
          rentalIs: "Hébergement concerné",
          personToPaid: "Personnes à payer",
          filterBy: "Filter par",
          filterPayment: "Filtrer les paiements",
          resetFilter: "Réinitialiser les filtres",
          apply: "Appliquer",
          placeholderSearch: "Rechercher un paiement",
          filtersTitle: "Filtrer les paiements",
          paidFor: "Paiement pour ",
          period: "Période",
          calculateAt:
            "Calculé à partir de la somme de tous les paiements du {{from}} au {{to}}",
          paymentState: "État du paiement *",
          paymentTypeRequired: "État du paiement requis.",
          deleteConfirmeTitle:
            "Voulez-vous vraiment supprimer le paiement de {{price}} pour {{operatorName}}",
          deleteConfirmeDescription:
            "Cela aura pour effet de supprimer le paiement de SuperHote et toutes les informations associées.",
          anyPayment: "Aucun paiement détecté.",
          anyPaymentDesc:
            "Aucun paiement détecté pour la période selectionnée.",
          formErrors: {
            amountZero: "Montant supérieur à 0 requis.",
            required: "Valeur requise.",
          },
        },
      },
      Task: {
        Filter: {
          Status: {
            all: "Toutes les tâches",
            notAssigned: "Non assignées",
            scheduled: "Assignées",
            pending: "En cours",
            completed: "Réalisées",
            refused: "Refusées",
            canceled: "Annulées",
          },
          Errors: {
            noRentalSelected:
              "Veuillez sélectionner au moins un hébergement dans le filtre.",
            noPositionSelected:
              "Veuillez sélectionner au moins un poste dans le filtre.",
          },
        },
        List: {
          print: "Imprimer",
          create: "Ajouter une tâche",
          noTaskToday: "Aucune tâche",
          countCleaning_one: "{{count}} ménage",
          countCleaning_other: "{{count}} ménages",
          countCheckin_one: "{{count}} checkin",
          countCheckin_other: "{{count}} checkins",
          countCheckout_one: "{{count}} checkout",
          countCheckout_other: "{{count}} checkouts",
          countIncident_one: "{{count}} incident",
          countIncident_other: "{{count}} incidents",
          countOther_one: "{{count}} autre",
          countOther_other: "{{count}} autres",
          rental: "Hébergement",
          name: "Nom",
          time: "Heure",
          assignedTo: "Assignée(s)",
          status: "Statut",
          fromTime: "À partir de {{time}}",
          scheduled: "Planifiée",
          completed: "Réalisée",
          inProgress: "En cours",
          notAssigned: "Non assignée",
          accept: "Accepter",
          refuse: "Refuser",
          canceled: "Annulé",
          refusedInfoTitle:
            "Les tâches refusées sont les tâches non assignées que vous avez refusé.",
          refusedInfoContent:
            "Vous pouvez décider de les accepter si finalement vous le souhaitez.",
          noTaskTitle: "Aucune tâche pour cette réservation",
          noTaskDescription:
            "Aucune tâche n'est prévue ou effectuée pour cette réservation",
        },
        Modal: {
          addTask: "Ajouter la tâche",
          title: "Ajouter une tâche",
          titleBooking: "Ajouter une tâche pour cette réservation",
          titleDashboard: "Ajouter une tâche",
          name: "Nom",
          rental: "Hébergement",
          taskType: "Type de la tâche",
          checklist: "Checklist",
          checklistLink: "Checklist liée",
          description: "Description texte",
          descriptionTask: "Description de la tâche",
          cleaning: "Ménage",
          checkin: "Check In",
          checkout: "Check Out",
          incident: "Incident",
          other: "Autres",
          assignationType: "Type d'assignation",
          assignationTypeDescription: "À une ou plusieurs personnes",
          assignationTypeTeamDescription:
            "À une équipe (premier arrivé premier servi)",
          assignationTypeTeamDescriptionInfo:
            "L'ensemble des membres ajoutés dans <strong> les équipes </strong> recevront la tâche et pourront choisir de s'assigner dessus.",
          assignationTypeTeamDescriptionInfo2:
            "Une <strong> seule personne </strong> peut s'assigner sur la tâche (premier arrivé, premier servi).",
          cleaningTask: "Tâche ménage",
          sendTask: "Envoi de la tâche non assignée",
          operator: "Prestataire",
          assigned: "Assigné(s)",
          howToCalc:
            "Comment voulez-vous calculer la rémuneration de cette tâche ?",
          amountGlobal: "Rémunération fixe",
          amountTask: "Rémunération de la tâche",
          amountVariable: "Variable en fonction des couchages",
          amountGlobalByCleaning: "Rémunération fixe par ménage",
          amountByBed: "Rémunération par couchage",
          exampleAmount:
            "Exemple : pour un ménage avec 3 couchages à changer, la rémunération sera de 30 € + 3 x 10 € = 60 €.",
          scheduleDate: "Programmé le",
          scheduleTime: "À",
          remark: "Remarque sur la tâche",
          remarkPlaceholder: "Remarque additionnelle sur la tâche",
          titleEdit: "Modifier la tâche",
          editTaks: "Modifier",
          rentalPhotos: "Photos de l’hébergement tel qu’il devra être",
          linkedChecklist: "Checklist liée",
          editTask: "Enregistrer",
          startTask: "Commencer",
          dateToDo: "Tâche à effectuer le",
          personAssigned: "Personne assignée",
          lastCheckout: "Dernier départ",
          nextCheckin: "Prochaine arrivée",
          checklistToDo: "Checklist à remplir",
          remarkNextGuest: "Remarques du prochain voyageur",
          remarkTask: "Remarques sur la tâche",
          remarkNextReservation: "Remarques de la prochaine réservation",
          photosOrVideos: "Photos ou vidéos de la réalisation de la tâche",
          noReservation: "Aucun",
          Accept: {
            title: "Voulez-vous vraiment vous assigner sur cette tâche ?",
            content: "Cela vous assignera sur la tâche.",
            button: "Oui, s'assigner",
          },
          Decline: {
            title: "Voulez-vous vraiment refuser cette tâche ?",
            content:
              "Vous ne serez pas assigné sur cette tâche, ce sera pour quelqu’un d’autre.",
            button: "Oui, refuser",
          },
          Cleaniness: {
            dirty: "Sale",
            medium: "Moyen",
            clean: "Propre",
          },
          howManyBeds: "Combien de lits avez-vous changé",
          cleaniness: "Propreté",
          cleaninessError: "Veuillez saisir l'état de propreté du logement",
          cleaninessQuestion:
            "Est-ce que l’hébergement a été rendu en bon état ?",
          cleaninessRemark:
            "Si l’hébergement est sale, la relance voyageur ne sera pas envoyée.",
          remarks: "Remarques complémentaires",
          remarksPlaceholder:
            "Au besoin, ajoutez des commentaires sur le séjour / sur la tâche",
          taskValidated: "Tâche validée le {{date}} à {{time}}",
          cancelTask: "Annuler la tâche",
          taskCanceledAt: "Tâche annulée le {{date}} à {{time}} par",
          cancelReason: "Motif d’annulation :",
          cancelRemarks: "Remarque d’annulation :",
          completeOrEdit: "Compléter ou modifier",
          importedPhotos: "Photos ou vidéos importées",
          notesRemarks: "Note et remarques",
          bedsChanged: "Lits changés",
          sleeping_one: "{{count}} couchage",
          sleeping_other: "{{count}} couchages",
        },
        Cancel: {
          title: "Voulez-vous vraiment annuler cette tâche ?",
          content1:
            "Cela aura pour effet d’annuler la tâche et de notifier les personnes assignées.",
          content2:
            "Cela aura pour effet d’annuler la tâche et de notifier le responsable. Vous devez choisir un motif d’annulation.",
          USELESS_TASKTitle: "Tâche non utile / non nécessaire",
          USELESS_TASKContent:
            "La tâche n’est plus pertinente pour une raison ou une autre",
          NOT_AVAILABLE_FOR_TASKTitle:
            "Je ne suis pas disponible pour réaliser la tâche",
          NOT_AVAILABLE_FOR_TASKContent:
            "Un empêchement personnel ou professionnel vous empêche de réaliser la tâche",
          PROBLEM_TO_DO_TASKTitle:
            "Problème empêchant la réalisation de la tâche",
          PROBLEM_TO_DO_TASKContent:
            "Par exemple impossibilité d’accéder au lieu, matériel non disponible, etc",
          OTHERTitle: "Autre",
          OTHERContent: "À préciser",
          CANCELED_BY_OWNERTitle: "Annulée par le créateur de la tâche",
          remarks: "Remarque complémentaire sur l’annulation",
          remarksPlaceholder: "Votre raison ici, si besoin",
          button: "Oui, annuler",
          reasonRequired: "La raison est obligatoire",
        },
        FilterModal: {
          title: "Filtrer les tâches",
          filterLabel: "Filtrer par",
          assignedPersons: "Personnes assignées",
          positionsLabel: "Postes",
          teams: "Équipes",
          rentalFilterLabel: "Filtrer par Hébergements",
          resetFilters: "Réinitialiser les filtres",
        },
      },
      Automation: {
        cleaning: "Ménage",
        all: "Toutes les automatisations",
        passengers_departure: "Départ des voyageurs",
        others: "Autres",
        FiltersModal: {
          title: "Filtrer les automatisations",
          description: "Filtrer par hébergement",
          rentalLabel: "Hébergement",
          resetFiltersButton: "Réinitialiser les filtres",
        },
        SelectModal: {
          title_one: "{{count}} automatisation sélectionnée",
          title_other: "{{count}} automatisations sélectionnées",
        },
        AddOrUpdateModal: {
          addTitle: "Ajouter une automatisation",
          editTitle: "Éditer l'automatisation {{automationName}}",
          addAutomation: "Ajouter",
          editAutomation: "Éditer",

          Steps: {
            Informations: {
              title: "Informations",
              concernedRentalLabel: "Hébergement concerné",
              concernedRentalDefaultLabel: "Choisissez un hébergement",
              automationTypeLabel: "Type d'automatisation (tâche récurrente)",
              AutomationTypeOptions: {
                cleaning: "Ménage",
                checkin: "Arrivée des voyageurs",
                checkout: "Départ des voyageurs",
                incident: "Incident",
                other: "Tâche récurrente personnalisée",
              },
              nameTaskLabel: "Nom de la tâche qui sera créée",
              amountTask: "Rémunération de la tâche",
              nameTaskPlaceholder: "Nom de la tâche",
              descriptionTaskLabel: "Description de la tâche",
              descriptionTaskPlaceholder: "Détail de la tâche",
              relatedChecklistLabel: "Checklist liée",
              relatedChecklistDefaultLabel: "Choisissez une checklist",
              relatedChecklistDescription:
                "Choisissez uniquement parmi les checklists de l'hébergement <strong>{{rentalName}}</strong> qui n'ont pas encore d'automatisations liées.",
              RelatedChecklistOptions: {
                cleaningChecklist: "Checklist Ménage",
              },
              taskRemarkLabel: "Remarque sur la tâche",
              taskRemarkPlaceholder: "Remarque additionnelle sur la tâche",
            },
            Program: {
              title: "Programmation",
              programTaskLabel: "Programmer la tâche lors de",
              ProgramTaskOptions: {
                passengersArrival: "Arrivée des voyageurs (heure checkin)",
                passengersDeparture: "Départ des voyageurs (heure checkout)",
              },
              ProgramTaskValues: {
                passengersArrival: "à l'entrée dans l'hébergement",
                passengersDeparture: "après la sortie de l'hébergement",
              },
              description1:
                "Pour l'hébergement <strong>{{rentalName}}</strong>, la tâche <strong>{{taskName}}</strong> sera programmée pour tous les séjours, <strong>{{when}}</strong> des voyageurs.",
              description2:
                "Le ou les prestataires seront notifiés automatiquement cette tâche <strong>la veille</strong>. Dans le cas d'une réservation de dernière minute, cette notification sera envoyée immédiatement lors de la réservation.",
            },
            Assignation: {
              title: "Assignation",
              description:
                "Choisissez les personnes ou les équipes assignées. Vous pouvez assigner plusieurs personnes : dans ce cas, il faudra définir le tarif pour chacune des personnes assignées. ",
              assignationTypeLabel: "Type d'assignation",
              assignationTypePersonsLabel: "À une ou plusieurs personnes",
              assignationTypeTeamLabel:
                "À une équipe (premier arrivé, premier servi)",
              assignationPersonsOrTeamsLabel: "Assignés de manière récurrente",
              assignationTeamsInfoTitle:
                "L'ensemble des membres ajoutés dans <strong>{{teamName}}</strong> recevront la tâche et pourront choisir de s'assigner dessus.",
              assignationTeamsInfoDescription:
                "Une <strong>seule personne</strong> peut s'assigner sur la tâche (premier arrivé, premier servi).",
              TaskAssignmentFlow: {
                cleaningTask: "Tâche Ménage",
                sendTask: "Envoi de la tâche non assignée",
                provider: "Prestataire {{count}}",
              },
            },
          },
        },
        SettingsModal: {
          title: "Paramètres des automatisations",
          description: "Paramètrer la création automatique des automatisations",
          cleaningAutomationLabel:
            "Activer automatiquement l'automatisation Ménage",
          cleaningAutomationDescription: "Lors de l'ajout d'un hébergement",
          checkinAutomationLabel:
            "Activer automatiquement l'automatisation Arrivée des voyageurs",
          checkinAutomationDescription: "Lors de l'ajout d'un hébergement",
          checkoutAutomationLabel:
            "Activer automatiquement l'automatisation Départ des voyageurs",
          checkoutAutomationDescription: "Lors de l'ajout d'un hébergement",
        },
        List: {
          searchPlaceholder: "Rechercher une automatisation",
          add: "Ajouter une automatisation",
          whatIsTitle: "Qu'est ce qu'une automatisation ?",
          whatIsContent:
            "Les automatisations sont un moyen pour vous d'automatiser la programmation de tâches récurrentes sur vos hébergements. Par exemple, vous pouvez automatiquement assigner votre prestataire de ménage sur les ménages de vos hébergements en fonction des sorties des voyageurs.",
          scheduledTask: "Tâche programmée",
          trigger: "Déclencheur",
          concernedRental: "Hébergement concerné",
          assignation: "Assignation",
          state: "État",
          scheduleTask: "Programmer la tâche",
          trigger: "Au moment de",
          TriggerOptions: {
            checkin: "Arrivée dans l'hébergement",
            checkout: "Départ de l'hébergement",
          },
        },
        AssignModal: {
          title_one: "À qui voulez-vous assigner ce ménage ?",
          title_other: "À qui voulez-vous assigner ces ménages ?",
          date: "le {{date}} à partir de {{time}}",
        },
        RemoveModal: {
          title_one: "Voulez-vous vraiment supprimer cette automatisation ?",
          title_other: "Voulez-vous vraiment supprimer ces automatisations ?",
          description1_one:
            "Cela aura pour effet de supprimer l'automatisation de Superhote et toutes les informations associées.",
          description1_other:
            "Cela aura pour effet de supprimer les automatisations de Superhote et toutes les informations associées.",
          description2:
            "Cela ne supprimera pas les tâches déjà ajoutées grâce à ces automatisations.",
          RemoveOptions: {
            option1:
              "Supprimer toutes les tâches ajoutées par cette automatisation",
            option1Description: "Cela supprimera les tâches planifiées",
            option2:
              "Conserver toutes les tâches ajoutées par cette automatisation",
          },
        },
        DeactivateModal: {
          title_one: "Voulez-vous vraiment désactiver cette automatisation ?",
          title_other: "Voulez-vous vraiment désactiver ces automatisations ?",
          description1_one:
            "Cela aura pour effet de mettre en pause cette automatisation.",
          description1_other:
            "Cela aura pour effet de mettre en pause ces automatisations.",
          description2_one: "Cela ne la supprimera pas.",
          description2_other: "Cela ne les supprimera pas.",
          description3_one:
            "Vous pouvez la ré-activer quand vous le souhaitez.",
          description3_other:
            "Vous pouvez les ré-activer quand vous le souhaitez.",
          RemoveOptions: {
            option1:
              "Supprimer toutes les tâches ajoutées par cette automatisation",
            option1Description: "Cela supprimera les tâches planifiées",
            option2:
              "Conserver toutes les tâches ajoutées par cette automatisation",
          },
        },
        NoAutomation: {
          title: "Aucune automatisation présente sur SuperHote",
          addAutomation: "Ajouter une automatisation",
        },
      },
      ThirdParty: {
        search: "Rechercher une intégration",
        platforms: "Plateformes",
        tarifs: "Paiements et tarification",
        locks: "Serrures connectés",
        auto: "Automatisation",
        connected: "Connecté",
        noConnected: "Non connecté",
        tutoFound: "Retrouvez tous nos tutoriels ici",
        tutoVideo: "Tutoriel vidéo",
        addOtherAccount: "Ajouter un autre compte",
        communication: "Communication",
        manageProject: "Gestion de projets",
        crm: "CRM",
        copied: "Votre clé d'API est copié dans votre presse papier !",
        emailAds: "Marketing par e-email",
        ecommerce: "E-commerce",
        support: "Support client",
        money: "Finance et comptabilité",
        storage: "Stockage et partage de fichiers",
        team: "Choisissez votre équipe",
        apiKey:
          "Appuyez sur le bouton généré pour obtenir la clé API, puis copiez-collez-la et fournissez-la dans votre compte Make.com.",
        teamId: "Entrer l'ID de votre équipe",
        organization: "Choisissez l'organisation",
        makeConnected: "Votre compte Make est désormais connecté à SuperHote !",
        warningFree:
          'Attention ! Votre compte Make est sous le forfait "Free". Nous ne pouvons pas récupérer les identifiants des équipes automatiquement. Veuillez entrer l\'ID de votre équipe manuellement.',
        helpTextTeam:
          "Afin que SuperHote puisse envoyer les données vers votre environnement Make, veuillez choisir l'organisation et l'équipe qui accueilleront les scénarios de SuperHote",
        Airbnb: {
          title: "Airbnb",
          description: "Plateforme de location d’hébergements en courte durée",
          longDescription:
            "Plateforme de location d’hébergements en courte durée. Dans Superhote, Airbnb est utilisé comme source de réservations.",
          buttonConnect: "Connecter Airbnb",
          howToConnect: "Comment connecter Airbnb",
          titleTuto:
            "Connectez-vous avec vos identifiants sur https://airbnb.fr",
          stepOne:
            "Une fois connecté, cliquez sur l’icône de profil en haut à roite, puis cliquez sur Fournisseur de connectivité.",
          stepTwo: "Puis validez vos informations",
          stepThree:
            "Enfin, Recherchez SuperHote puis acceptez l’ensemble des demandes d’autorisations Stripe",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
          titleList: "Hébergements",
          titleState: "Etat",
          titleRentals: "Hébergements connectés à Airbnb",
          titleAccount: "Compte Airbnb",
        },
        Booking: {
          title: "Booking.com",
          description: "Plateforme de location d’hébergements en courte durée",
          longDescription:
            "Plateforme de location d’hébergements en courte durée. Dans Superhote, Airbnb est utilisé comme source de réservations.",
          buttonConnect: "Connecter Booking.com",
          howToConnect: "Comment connecter Booking.com",
          titleTuto:
            "Connectez-vous avec vos identifiants sur https://booking.com",
          stepOne:
            "Une fois connecté, cliquez sur l’icône de profil en haut à roite, puis cliquez sur Fournisseur de connectivité.",
          stepTwo: "Puis validez vos informations",
          stepThree:
            "Enfin, Recherchez SuperHote puis acceptez l’ensemble des demandes d’autorisations Stripe",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        Stripe: {
          title: "Stripe",
          description: "Solution financière pour encaisser des paiements CB.",
          longDescription:
            "Solution financière pour encaisser des paiements CB. Dans Superhote, Stripe est utilisé pour encaisser les réservations, les extras et gérer les cautions. ",
          buttonConnect: "Connecter Stripe",
          howToConnect: "Comment connecter Stripe",
          titleTuto:
            "Connectez-vous avec vos identifiants sur https://stripe.com",
          stepOne:
            "Une fois connecté, cliquez sur l’icône de profil en haut à roite, puis cliquez sur Fournisseur de connectivité.",
          stepTwo: "Puis validez vos informations",
          stepThree:
            "Enfin, Recherchez SuperHote puis acceptez l’ensemble des demandes d’autorisations Stripe",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        Pricelabs: {
          title: "Pricelabs",
          description:
            "Tarification dynamique intelligente pour booster vos réservations.",
          longDescription:
            "arification dynamique intelligente pour booster vos réservations. Dans Superhote, Stripe est utilisé pour modifier la tarification de vos nuitées de façon dynamique.",
          buttonConnect: "Connecter Igloohome",
          howToConnect: "Comment connecter Pricelabs",
          titleTuto:
            "Connectez-vous avec vos identifiants sur https://pricelabs.com",
          stepOne:
            "Une fois connecté, cliquez sur l’icône de profil en haut à roite, puis cliquez sur Fournisseur de connectivité.",
          stepTwo: "Puis validez vos informations",
          stepThree:
            "Enfin, Recherchez SuperHote puis acceptez l’ensemble des demandes d’autorisations Stripe",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        Igloohome: {
          title: "Igloohome",
          description:
            "Tarification dynamique intelligente pour booster vos réservations.",
          longDescription:
            "Tarification dynamique intelligente pour booster vos réservations. Dans Superhote, Igloohome est utilisé pour gérer les serrures connectées et les codes d’accès à vos hébergements.",
          buttonConnect: "Connecter Igloohome",
          howToConnect: "Comment connecter Igloohome",
          titleTuto:
            "Connectez-vous avec vos identifiants sur https://igloohome.com",
          stepOne:
            "Une fois connecté, cliquez sur l’icône de profil en haut à roite, puis cliquez sur Fournisseur de connectivité.",
          stepTwo: "Puis validez vos informations",
          stepThree:
            "Enfin, Recherchez SuperHote puis acceptez l’ensemble des demandes d’autorisations Stripe",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        Nuki: {
          title: "Nuki",
          description:
            "Avec Nuki, votre serrure actuelle devient une solution d’accès numérique. Entrez dans le monde de la domotique.",
          longDescription:
            "Avec Nuki, votre serrure actuelle devient une solution d’accès numérique. Entrez dans le monde de la domotique. Dans Superhote, Nuki est utilisé pour gérer les serrures connectées et les codes d’accès à vos hébergements.",
          buttonConnect: "Connecter Nuki",
          howToConnect: "Comment connecter Nuki",
          titleTuto:
            "Connectez-vous avec vos identifiants sur https://nuki.com",
          stepOne:
            "Une fois connecté, cliquez sur l’icône de profil en haut à roite, puis cliquez sur Fournisseur de connectivité.",
          stepTwo: "Puis validez vos informations",
          stepThree:
            "Enfin, Recherchez SuperHote puis acceptez l’ensemble des demandes d’autorisations Stripe",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        TTlock: {
          title: "TTlock",
          description:
            "Pilotez votre serrure avec votre Smartphone via Bluetooth ou via Internet (en option) et l'application en français TTLock disponible gratuitement pour Android.",
          longDescription:
            "Pilotez votre serrure avec votre Smartphone via Bluetooth ou via Internet (en option) et l'application en français TTLock disponible gratuitement pour Android. Dans Superhote, TTLock est utilisé pour gérer les serrures connectées et les codes d’accès à vos hébergements.",
          buttonConnect: "Connecter TTlock",
          howToConnect: "Comment connecter TTlock",
          titleTuto:
            "Connectez-vous avec vos identifiants sur https://ttlock.com",
          stepOne:
            "Une fois connecté, cliquez sur l’icône de profil en haut à roite, puis cliquez sur Fournisseur de connectivité.",
          stepTwo: "Puis validez vos informations",
          stepThree:
            "Enfin, Recherchez SuperHote puis acceptez l’ensemble des demandes d’autorisations Stripe",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        Make: {
          title: "Make",
          description:
            "Make est une plateforme visuelle qui permet à chacun de concevoir, de construire et d'automatiser sans codage.",
          longDescription:
            "Make est une plateforme visuelle qui permet à chacun de concevoir, de construire et d'automatiser sans codage. Dans Superhote, Make peut être connecté pour automatiser certaines tâches.",
          buttonConnect: "Connecter Make",
          howToConnect: "Comment connecter Make",
          titleTuto:
            "Connectez-vous avec vos identifiants sur https://make.com",
          stepOne:
            "Créer un compte sur Make\n" +
            "\t1. Rendez-vous sur le site de Make.com\n" +
            "\t2. Cliquez sur S’inscrire ou Créer un compte si vous n’avez pas encore de compte.\n" +
            "\t3. Remplissez le formulaire d’inscription ou connectez-vous avec vos identifiants Google/Apple si disponibles.\n" +
            "\t4. Une fois inscrit, accédez à votre tableau de bord Make.",
          stepTwo:
            "Configurer Make dans SuperHote\n" +
            "\t1. Connectez-vous à votre compte SuperHote.\n" +
            "\t2. Accédez aux Paramètres de votre application.\n" +
            "\t3. Recherchez la section Intégrations et cliquez sur Make.\n" +
            "\t4. Suivez les instructions pour générer une clé API ou pour autoriser Make à accéder à votre compte SuperHote.\n" +
            "\t5. Copiez cette clé API ou validez l’autorisation pour l’utiliser dans Make.",
          stepThree:
            "Créer un scénario sur Make\n" +
            "\t1. Retournez dans le tableau de bord Make.\n" +
            "\t2. Cliquez sur le bouton Créer un scénario.\n" +
            "\t3. Ajoutez un module en cliquant sur le cercle “+” au centre de l’écran.\n" +
            "\t4. Recherchez SuperHote V2 dans la liste des applications disponibles.\n" +
            "\t5. Sélectionnez l’un des événements de déclenchement disponibles :\n" +
            "\t\t• After Booking : Déclenché après une nouvelle réservation.\n" +
            "\t\t• After Booking Modified : Déclenché après une modification de réservation.\n" +
            "\t\t• After Contract Sign : Déclenché après une signature de contrat.\n" +
            "\t\t• After Payment : Déclenché après un paiement.\n" +
            "\t6. Configurez les détails de l’événement en utilisant les options proposées.",
          stepFour:
            "Ajouter des actions pour d’autres applications\n" +
            "\t1. Après avoir configuré le module SuperHote, ajoutez un second module en cliquant sur le cercle “+”.\n" +
            "\t2. Recherchez et sélectionnez une autre application avec laquelle vous souhaitez intégrer.\n" +
            "\t3. Configurez l’action à effectuer.\n" +
            "\t4. Testez le scénario pour vérifier que tout fonctionne comme prévu.",
          stepFive:
            "Activer et tester le scénario\n" +
            "\t1. Une fois votre scénario configuré, cliquez sur Activer pour qu’il commence à fonctionner automatiquement.\n" +
            "\t2. Effectuez un test dans SuperHote pour déclencher l’événement choisi (par exemple, créer une nouvelle réservation ou enregistrer un paiement).\n" +
            "\t3. Vérifiez que l’action configurée dans l’autre application s’exécute correctement.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
          connection: "Connexion à Make",
          api_key_input: "Veuillez remplir ci-dessous votre API Key de Make.",
          api_key: "API Key",
          required: "La clé d'api est requise.",
        },
        Slack: {
          title: "Slack",
          description:
            "Plateforme de messagerie collaborative pour les équipes.",
          longDescription:
            "Slack est une plateforme de messagerie collaborative pour les équipes, facilitant la communication en temps réel et le partage de fichiers.",
          buttonConnect: "Connecter Slack",
          howToConnect: "Comment connecter Slack",
          titleTuto: "Tutoriel de connexion Slack",
          stepOne: "Créez un compte ou connectez-vous à votre espace Slack.",
          stepTwo:
            "Accédez aux paramètres de votre application sur notre plateforme.",
          stepThree: "Autorisez l'accès à Slack via OAuth.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        MicrosoftTeams: {
          title: "Microsoft Teams",
          description:
            "Outil de collaboration et de communication d’entreprise.",
          longDescription:
            "Microsoft Teams intègre des fonctionnalités de chat, de réunion et de partage de documents pour une collaboration efficace.",
          buttonConnect: "Connecter Microsoft Teams",
          howToConnect: "Comment connecter Microsoft Teams",
          titleTuto: "Tutoriel de connexion Microsoft Teams",
          stepOne: "Connectez-vous avec votre compte Microsoft Teams.",
          stepTwo: "Rendez-vous sur les intégrations dans notre plateforme.",
          stepThree:
            "Autorisez l'accès à Microsoft Teams pour finaliser la connexion.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        Zoom: {
          title: "Zoom",
          description: "Service de visioconférence.",
          longDescription:
            "Zoom offre des réunions en ligne, des webinaires et des salles de discussion, facilitant la collaboration à distance.",
          buttonConnect: "Connecter Zoom",
          howToConnect: "Comment connecter Zoom",
          titleTuto: "Tutoriel de connexion Zoom",
          stepOne: "Inscrivez-vous ou connectez-vous à votre compte Zoom.",
          stepTwo: "Ouvrez la section des intégrations de notre plateforme.",
          stepThree:
            "Autorisez notre application à accéder à vos données Zoom.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        Skype: {
          title: "Skype",
          description: "Application de communication pour appels et messages.",
          longDescription:
            "Skype permet des appels audio et vidéo, des messages instantanés et le partage de fichiers dans un cadre personnel ou professionnel.",
          buttonConnect: "Connecter Skype",
          howToConnect: "Comment connecter Skype",
          titleTuto: "Tutoriel de connexion Skype",
          stepOne: "Créez ou connectez-vous à votre compte Skype.",
          stepTwo:
            "Accédez aux paramètres d'intégration dans notre application.",
          stepThree: "Autorisez Skype à se connecter à votre compte.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        Discord: {
          title: "Discord",
          description:
            "Plateforme de communication vocale, vidéo et textuelle.",
          longDescription:
            "Discord offre des outils de communication polyvalents pour les communautés, incluant des appels vocaux, des vidéos et des messages textes.",
          buttonConnect: "Connecter Discord",
          howToConnect: "Comment connecter Discord",
          titleTuto: "Tutoriel de connexion Discord",
          stepOne: "Connectez-vous ou inscrivez-vous sur Discord.",
          stepTwo: "Accédez à la page des intégrations de notre plateforme.",
          stepThree:
            "Validez l'autorisation OAuth pour relier votre compte Discord.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        WhatsApp: {
          title: "WhatsApp",
          description: "Application de messagerie instantanée.",
          longDescription:
            "WhatsApp permet l’envoi de messages texte, vocaux et d’images, facilitant une communication instantanée avec vos contacts.",
          buttonConnect: "Connecter WhatsApp",
          howToConnect: "Comment connecter WhatsApp",
          titleTuto: "Tutoriel de connexion WhatsApp",
          stepOne: "Connectez-vous à votre compte WhatsApp Business.",
          stepTwo: "Scannez le QR Code fourni dans la section d'intégration.",
          stepThree:
            "Configurez les autorisations nécessaires pour synchroniser les messages.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        Telegram: {
          title: "Telegram",
          description: "Application de messagerie rapide et sécurisée.",
          longDescription:
            "Telegram est une application de messagerie axée sur la vitesse et la sécurité, avec des fonctionnalités telles que les chats secrets.",
          buttonConnect: "Connecter Telegram",
          howToConnect: "Comment connecter Telegram",
          titleTuto: "Tutoriel de connexion Telegram",
          stepOne: "Téléchargez l'application Telegram et créez un compte.",
          stepTwo: "Générez une clé d'API dans les paramètres Telegram Bot.",
          stepThree:
            "Ajoutez cette clé dans notre section intégrations pour finaliser la connexion.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        FacebookMessenger: {
          title: "Facebook Messenger",
          description: "Service de messagerie instantanée intégré à Facebook.",
          longDescription:
            "Facebook Messenger permet des conversations textuelles, vocales et vidéo avec vos contacts Facebook.",
          buttonConnect: "Connecter Facebook Messenger",
          howToConnect: "Comment connecter Facebook Messenger",
          titleTuto: "Tutoriel de connexion Facebook Messenger",
          stepOne: "Connectez-vous à votre compte Facebook.",
          stepTwo: "Accédez aux paramètres d'intégration de notre application.",
          stepThree:
            "Autorisez l'accès à Messenger pour synchroniser vos conversations.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        Trello: {
          title: "Trello",
          description:
            "Application de gestion de projets basée sur des tableaux.",
          longDescription:
            "Trello aide les équipes à organiser leurs projets grâce à des tableaux, des listes et des cartes pour un suivi efficace des tâches.",
          buttonConnect: "Connecter Trello",
          howToConnect: "Comment connecter Trello",
          titleTuto: "Tutoriel de connexion Trello",
          stepOne: "Connectez-vous à votre compte Trello.",
          stepTwo:
            "Accédez à la section des intégrations sur notre plateforme.",
          stepThree:
            "Autorisez l'accès à Trello via l'API pour synchroniser vos projets.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        Asana: {
          title: "Asana",
          description: "Outil de gestion de travail pour les équipes.",
          longDescription:
            "Asana permet aux équipes de collaborer, organiser et suivre leur travail pour atteindre leurs objectifs.",
          buttonConnect: "Connecter Asana",
          howToConnect: "Comment connecter Asana",
          titleTuto: "Tutoriel de connexion Asana",
          stepOne: "Créez ou connectez-vous à votre compte Asana.",
          stepTwo:
            "Rendez-vous dans les paramètres d'intégration de notre application.",
          stepThree:
            "Autorisez la connexion à votre espace Asana pour synchroniser vos tâches.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        MondayCom: {
          title: "Monday.com",
          description: "Plateforme de gestion de travail tout-en-un.",
          longDescription:
            "Monday.com permet de planifier, suivre et collaborer sur des projets et tâches dans une interface intuitive.",
          buttonConnect: "Connecter Monday.com",
          howToConnect: "Comment connecter Monday.com",
          titleTuto: "Tutoriel de connexion Monday.com",
          stepOne: "Inscrivez-vous ou connectez-vous à Monday.com.",
          stepTwo: "Accédez à la section des intégrations sur notre site.",
          stepThree:
            "Autorisez notre application à accéder à votre espace de travail Monday.com.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        Jira: {
          title: "Jira",
          description: "Outil de gestion de projets agile.",
          longDescription:
            "Jira est utilisé pour le suivi des bugs, la gestion des tâches et le développement agile.",
          buttonConnect: "Connecter Jira",
          howToConnect: "Comment connecter Jira",
          titleTuto: "Tutoriel de connexion Jira",
          stepOne: "Connectez-vous à votre compte Jira.",
          stepTwo:
            "Naviguez vers les paramètres d'intégration de notre application.",
          stepThree:
            "Autorisez Jira à synchroniser vos tickets avec notre plateforme.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        Basecamp: {
          title: "Basecamp",
          description: "Application de gestion de projets et collaboration.",
          longDescription:
            "Basecamp offre des outils pour organiser les projets, gérer les tâches et collaborer avec les équipes.",
          buttonConnect: "Connecter Basecamp",
          howToConnect: "Comment connecter Basecamp",
          titleTuto: "Tutoriel de connexion Basecamp",
          stepOne: "Connectez-vous ou créez un compte Basecamp.",
          stepTwo: "Ouvrez la section intégrations de notre application.",
          stepThree:
            "Ajoutez les permissions pour connecter votre espace Basecamp.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        ClickUp: {
          title: "ClickUp",
          description: "Plateforme unifiée pour la gestion des tâches.",
          longDescription:
            "ClickUp centralise la gestion des tâches, des documents, des objectifs et des conversations pour une meilleure productivité.",
          buttonConnect: "Connecter ClickUp",
          howToConnect: "Comment connecter ClickUp",
          titleTuto: "Tutoriel de connexion ClickUp",
          stepOne: "Inscrivez-vous ou connectez-vous à ClickUp.",
          stepTwo: "Rendez-vous sur notre interface d'intégration.",
          stepThree:
            "Autorisez notre application à accéder à votre compte ClickUp.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        Wrike: {
          title: "Wrike",
          description: "Outil de gestion de projets et collaboration en ligne.",
          longDescription:
            "Wrike permet aux équipes de collaborer efficacement en suivant les tâches, projets et délais.",
          buttonConnect: "Connecter Wrike",
          howToConnect: "Comment connecter Wrike",
          titleTuto: "Tutoriel de connexion Wrike",
          stepOne: "Créez ou connectez-vous à votre compte Wrike.",
          stepTwo: "Accédez à la section des intégrations de notre plateforme.",
          stepThree: "Approuvez l'accès pour synchroniser vos projets Wrike.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        Notion: {
          title: "Notion",
          description: "Application tout-en-un pour les notes et projets.",
          longDescription:
            "Notion combine la prise de notes, la gestion de projets et la collaboration pour une expérience utilisateur tout-en-un.",
          buttonConnect: "Connecter Notion",
          howToConnect: "Comment connecter Notion",
          titleTuto: "Tutoriel de connexion Notion",
          stepOne: "Connectez-vous à votre compte Notion.",
          stepTwo: "Allez dans la section intégrations de notre application.",
          stepThree:
            "Donnez l'autorisation pour synchroniser vos bases de données Notion.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        Salesforce: {
          title: "Salesforce",
          description: "Plateforme CRM leader.",
          longDescription:
            "Salesforce propose des solutions complètes pour la gestion des ventes, du marketing et du service client.",
          buttonConnect: "Connecter Salesforce",
          howToConnect: "Comment connecter Salesforce",
          titleTuto: "Tutoriel de connexion Salesforce",
          stepOne: "Créez ou connectez-vous à votre compte Salesforce.",
          stepTwo:
            "Rendez-vous sur la section intégrations de notre plateforme.",
          stepThree:
            "Autorisez l'accès à Salesforce pour synchroniser vos données CRM.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        HubSpot: {
          title: "HubSpot",
          description: "Suite d'outils CRM intégrée.",
          longDescription:
            "HubSpot centralise les fonctionnalités de marketing, ventes et support client avec un CRM gratuit.",
          buttonConnect: "Connecter HubSpot",
          howToConnect: "Comment connecter HubSpot",
          titleTuto: "Tutoriel de connexion HubSpot",
          stepOne: "Créez ou connectez-vous à votre compte HubSpot.",
          stepTwo: "Accédez à la section intégrations de notre site.",
          stepThree:
            "Autorisez notre application à accéder à votre compte HubSpot.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        ZohoCRM: {
          title: "Zoho CRM",
          description: "Solution CRM tout-en-un.",
          longDescription:
            "Zoho CRM offre des outils pour la gestion des ventes, marketing, support client et plus encore.",
          buttonConnect: "Connecter Zoho CRM",
          howToConnect: "Comment connecter Zoho CRM",
          titleTuto: "Tutoriel de connexion Zoho CRM",
          stepOne: "Connectez-vous ou inscrivez-vous à Zoho CRM.",
          stepTwo: "Rendez-vous sur notre section des intégrations.",
          stepThree:
            "Approuvez l'accès à Zoho CRM pour synchroniser vos données.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        Pipedrive: {
          title: "Pipedrive",
          description: "CRM axé sur la gestion des pipelines de ventes.",
          longDescription:
            "Pipedrive aide à organiser et à optimiser le processus de vente pour maximiser les performances commerciales.",
          buttonConnect: "Connecter Pipedrive",
          howToConnect: "Comment connecter Pipedrive",
          titleTuto: "Tutoriel de connexion Pipedrive",
          stepOne: "Connectez-vous à votre compte Pipedrive.",
          stepTwo:
            "Accédez à la section des intégrations sur notre plateforme.",
          stepThree:
            "Autorisez notre application à accéder à votre pipeline de ventes.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        Freshsales: {
          title: "Freshsales",
          description: "CRM basé sur le cloud.",
          longDescription:
            "Freshsales propose des outils d’automatisation des ventes, gestion des leads et intégration de CRM.",
          buttonConnect: "Connecter Freshsales",
          howToConnect: "Comment connecter Freshsales",
          titleTuto: "Tutoriel de connexion Freshsales",
          stepOne: "Créez ou connectez-vous à votre compte Freshsales.",
          stepTwo:
            "Rendez-vous dans les paramètres d'intégration de notre application.",
          stepThree:
            "Autorisez Freshsales à synchroniser vos leads et contacts.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        AgileCRM: {
          title: "Agile CRM",
          description: "CRM complet pour petites entreprises.",
          longDescription:
            "Agile CRM intègre des outils de gestion des ventes, de marketing et de support client dans une seule plateforme.",
          buttonConnect: "Connecter Agile CRM",
          howToConnect: "Comment connecter Agile CRM",
          titleTuto: "Tutoriel de connexion Agile CRM",
          stepOne: "Connectez-vous ou inscrivez-vous à Agile CRM.",
          stepTwo: "Ouvrez la section intégrations de notre plateforme.",
          stepThree:
            "Ajoutez les autorisations nécessaires pour connecter votre compte Agile CRM.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        Insightly: {
          title: "Insightly",
          description: "CRM conçu pour les ventes et les projets.",
          longDescription:
            "Insightly combine gestion des ventes et gestion de projets pour une vue d'ensemble des processus.",
          buttonConnect: "Connecter Insightly",
          howToConnect: "Comment connecter Insightly",
          titleTuto: "Tutoriel de connexion Insightly",
          stepOne: "Créez ou connectez-vous à votre compte Insightly.",
          stepTwo: "Accédez aux paramètres d'intégration de notre site.",
          stepThree:
            "Autorisez notre application à accéder à vos données Insightly.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        Keap: {
          title: "Keap (Infusionsoft)",
          description: "CRM simplifié pour les petites entreprises.",
          longDescription:
            "Keap propose des fonctionnalités d’automatisation des ventes, gestion de leads et gestion des clients.",
          buttonConnect: "Connecter Keap",
          howToConnect: "Comment connecter Keap",
          titleTuto: "Tutoriel de connexion Keap",
          stepOne: "Connectez-vous ou inscrivez-vous à Keap.",
          stepTwo: "Rendez-vous sur notre interface d'intégration.",
          stepThree:
            "Donnez les autorisations nécessaires pour synchroniser vos données.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        Mailchimp: {
          title: "Mailchimp",
          description:
            "Plateforme de marketing par e-mail et d’automatisation.",
          longDescription:
            "Mailchimp aide à créer, envoyer et analyser des campagnes d’e-mailing avec des outils d’automatisation performants.",
          buttonConnect: "Connecter Mailchimp",
          howToConnect: "Comment connecter Mailchimp",
          titleTuto: "Tutoriel de connexion Mailchimp",
          stepOne: "Créez ou connectez-vous à votre compte Mailchimp.",
          stepTwo: "Accédez à la section intégrations de notre site.",
          stepThree:
            "Autorisez notre plateforme à accéder à vos campagnes Mailchimp.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        SendinBlue: {
          title: "SendinBlue (Brevo)",
          description: "Solution de marketing numérique.",
          longDescription:
            "SendinBlue offre des services d’e-mailing, de SMS marketing et d’automatisation pour votre entreprise.",
          buttonConnect: "Connecter SendinBlue",
          howToConnect: "Comment connecter SendinBlue",
          titleTuto: "Tutoriel de connexion SendinBlue",
          stepOne: "Inscrivez-vous ou connectez-vous à SendinBlue.",
          stepTwo:
            "Rendez-vous dans la section intégrations de notre application.",
          stepThree:
            "Donnez l'autorisation pour synchroniser vos campagnes SendinBlue.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        ActiveCampaign: {
          title: "ActiveCampaign",
          description: "Plateforme intégrée de marketing automation et CRM.",
          longDescription:
            "ActiveCampaign combine marketing par e-mail, CRM et automatisation pour aider à la gestion de la relation client et à la conversion des prospects.",
          buttonConnect: "Connecter ActiveCampaign",
          howToConnect: "Comment connecter ActiveCampaign",
          titleTuto: "Tutoriel de connexion ActiveCampaign",
          stepOne: "Créez ou connectez-vous à votre compte ActiveCampaign.",
          stepTwo:
            "Rendez-vous dans la section intégrations de notre plateforme.",
          stepThree:
            "Approuvez les permissions nécessaires pour synchroniser vos données.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        GetResponse: {
          title: "GetResponse",
          description: "Solution complète pour le marketing par e-mail.",
          longDescription:
            "GetResponse propose des outils de création de campagnes, d’automatisation et de landing pages pour maximiser vos performances marketing.",
          buttonConnect: "Connecter GetResponse",
          howToConnect: "Comment connecter GetResponse",
          titleTuto: "Tutoriel de connexion GetResponse",
          stepOne: "Créez ou connectez-vous à votre compte GetResponse.",
          stepTwo:
            "Rendez-vous dans les paramètres d’intégration sur notre plateforme.",
          stepThree:
            "Approuvez l'accès à votre compte GetResponse pour synchroniser vos campagnes.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        ConvertKit: {
          title: "ConvertKit",
          description: "Outil de marketing par e-mail pour les créateurs.",
          longDescription:
            "ConvertKit est conçu pour les créateurs de contenu, offrant des fonctionnalités simples pour gérer les abonnés et les campagnes.",
          buttonConnect: "Connecter ConvertKit",
          howToConnect: "Comment connecter ConvertKit",
          titleTuto: "Tutoriel de connexion ConvertKit",
          stepOne: "Inscrivez-vous ou connectez-vous à ConvertKit.",
          stepTwo: "Rendez-vous sur notre section intégrations.",
          stepThree:
            "Donnez les autorisations nécessaires pour connecter votre compte ConvertKit.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        AWeber: {
          title: "AWeber",
          description: "Plateforme populaire pour le marketing par e-mail.",
          longDescription:
            "AWeber aide les petites entreprises à concevoir des campagnes e-mail performantes et à gérer leurs abonnés.",
          buttonConnect: "Connecter AWeber",
          howToConnect: "Comment connecter AWeber",
          titleTuto: "Tutoriel de connexion AWeber",
          stepOne: "Créez ou connectez-vous à votre compte AWeber.",
          stepTwo:
            "Accédez à la section des intégrations de notre application.",
          stepThree:
            "Approuvez l'accès pour synchroniser vos campagnes et abonnés.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        CampaignMonitor: {
          title: "Campaign Monitor",
          description: "Solution d’e-mail marketing avancée.",
          longDescription:
            "Campaign Monitor offre des outils puissants pour la création de campagnes e-mail personnalisées et leur analyse.",
          buttonConnect: "Connecter Campaign Monitor",
          howToConnect: "Comment connecter Campaign Monitor",
          titleTuto: "Tutoriel de connexion Campaign Monitor",
          stepOne: "Créez ou connectez-vous à votre compte Campaign Monitor.",
          stepTwo: "Rendez-vous sur notre page d’intégrations.",
          stepThree:
            "Approuvez l'accès à vos campagnes pour automatiser les actions.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        Klaviyo: {
          title: "Klaviyo",
          description: "Plateforme d’automatisation axée sur l’e-commerce.",
          longDescription:
            "Klaviyo optimise les campagnes marketing des boutiques en ligne avec des outils avancés de segmentation et de personnalisation.",
          buttonConnect: "Connecter Klaviyo",
          howToConnect: "Comment connecter Klaviyo",
          titleTuto: "Tutoriel de connexion Klaviyo",
          stepOne: "Créez ou connectez-vous à votre compte Klaviyo.",
          stepTwo: "Ouvrez la section intégrations de notre plateforme.",
          stepThree:
            "Ajoutez les autorisations nécessaires pour connecter vos campagnes.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        MailerLite: {
          title: "MailerLite",
          description: "Solution simple pour le marketing par e-mail.",
          longDescription:
            "MailerLite est une plateforme intuitive idéale pour les petites entreprises et startups, offrant des outils de création de newsletters.",
          buttonConnect: "Connecter MailerLite",
          howToConnect: "Comment connecter MailerLite",
          titleTuto: "Tutoriel de connexion MailerLite",
          stepOne: "Créez ou connectez-vous à votre compte MailerLite.",
          stepTwo: "Accédez à la section intégrations sur notre plateforme.",
          stepThree:
            "Autorisez notre application à gérer vos campagnes e-mail.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        BenchmarkEmail: {
          title: "Benchmark Email",
          description: "Outil d’e-mail marketing avancé.",
          longDescription:
            "Benchmark Email propose des modèles de newsletters personnalisables et des analyses approfondies des campagnes.",
          buttonConnect: "Connecter Benchmark Email",
          howToConnect: "Comment connecter Benchmark Email",
          titleTuto: "Tutoriel de connexion Benchmark Email",
          stepOne: "Inscrivez-vous ou connectez-vous à Benchmark Email.",
          stepTwo: "Rendez-vous dans la section intégrations de notre site.",
          stepThree:
            "Donnez les autorisations pour synchroniser vos campagnes e-mail.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        Shopify: {
          title: "Shopify",
          description: "Plateforme de commerce en ligne.",
          longDescription:
            "Shopify permet de créer, personnaliser et gérer facilement une boutique en ligne performante.",
          buttonConnect: "Connecter Shopify",
          howToConnect: "Comment connecter Shopify",
          titleTuto: "Tutoriel de connexion Shopify",
          stepOne: "Créez ou connectez-vous à votre compte Shopify.",
          stepTwo: "Rendez-vous sur notre interface d'intégration.",
          stepThree:
            "Autorisez notre application à accéder à votre boutique pour synchroniser les données.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        WooCommerce: {
          title: "WooCommerce",
          description: "Extension de commerce pour WordPress.",
          longDescription:
            "WooCommerce transforme un site WordPress en une boutique en ligne complète et personnalisable.",
          buttonConnect: "Connecter WooCommerce",
          howToConnect: "Comment connecter WooCommerce",
          titleTuto: "Tutoriel de connexion WooCommerce",
          stepOne:
            "Assurez-vous que votre site utilise WordPress avec WooCommerce.",
          stepTwo: "Rendez-vous sur notre plateforme pour lier votre boutique.",
          stepThree:
            "Donnez les autorisations nécessaires pour synchroniser vos produits et commandes.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        Magento: {
          title: "Magento",
          description: "Plateforme d’e-commerce robuste.",
          longDescription:
            "Magento propose des fonctionnalités avancées pour créer et gérer des boutiques en ligne adaptées aux grandes entreprises.",
          buttonConnect: "Connecter Magento",
          howToConnect: "Comment connecter Magento",
          titleTuto: "Tutoriel de connexion Magento",
          stepOne: "Créez ou connectez-vous à votre compte Magento.",
          stepTwo: "Allez dans la section intégrations de notre plateforme.",
          stepThree:
            "Ajoutez les permissions nécessaires pour synchroniser vos données.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        BigCommerce: {
          title: "BigCommerce",
          description: "Solution d’e-commerce tout-en-un.",
          longDescription:
            "BigCommerce est conçu pour les entreprises en croissance, offrant des outils puissants pour développer leur activité en ligne.",
          buttonConnect: "Connecter BigCommerce",
          howToConnect: "Comment connecter BigCommerce",
          titleTuto: "Tutoriel de connexion BigCommerce",
          stepOne: "Créez ou connectez-vous à votre compte BigCommerce.",
          stepTwo: "Accédez à notre section d’intégrations.",
          stepThree:
            "Autorisez l'accès à votre compte pour synchroniser vos produits.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        PrestaShop: {
          title: "PrestaShop",
          description: "Plateforme open-source pour l’e-commerce.",
          longDescription:
            "PrestaShop permet de personnaliser entièrement une boutique en ligne pour répondre aux besoins des entreprises.",
          buttonConnect: "Connecter PrestaShop",
          howToConnect: "Comment connecter PrestaShop",
          titleTuto: "Tutoriel de connexion PrestaShop",
          stepOne: "Installez ou connectez-vous à votre boutique PrestaShop.",
          stepTwo: "Rendez-vous sur notre page d’intégrations.",
          stepThree:
            "Ajoutez les autorisations pour connecter vos produits et commandes.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        SquarespaceCommerce: {
          title: "Squarespace Commerce",
          description: "Solution intégrée pour boutiques en ligne.",
          longDescription:
            "Squarespace Commerce combine design élégant et fonctionnalités e-commerce pour les petites entreprises.",
          buttonConnect: "Connecter Squarespace Commerce",
          howToConnect: "Comment connecter Squarespace Commerce",
          titleTuto: "Tutoriel de connexion Squarespace Commerce",
          stepOne: "Créez ou connectez-vous à votre compte Squarespace.",
          stepTwo:
            "Rendez-vous sur notre plateforme pour connecter votre boutique.",
          stepThree:
            "Approuvez les permissions nécessaires pour synchroniser vos produits.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        Etsy: {
          title: "Etsy",
          description: "Marketplace spécialisée pour les produits artisanaux.",
          longDescription:
            "Etsy est la plateforme idéale pour vendre des produits artisanaux, vintage ou créatifs à une audience mondiale.",
          buttonConnect: "Connecter Etsy",
          howToConnect: "Comment connecter Etsy",
          titleTuto: "Tutoriel de connexion Etsy",
          stepOne: "Connectez-vous à votre compte Etsy ou créez-en un.",
          stepTwo: "Accédez à notre section intégrations.",
          stepThree:
            "Donnez les autorisations nécessaires pour synchroniser vos produits Etsy.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        Zendesk: {
          title: "Zendesk",
          description: "Plateforme de service client.",
          longDescription:
            "Zendesk propose des outils de gestion des tickets, de chat et de support client pour améliorer l'expérience utilisateur.",
          buttonConnect: "Connecter Zendesk",
          howToConnect: "Comment connecter Zendesk",
          titleTuto: "Tutoriel de connexion Zendesk",
          stepOne: "Connectez-vous ou créez un compte Zendesk.",
          stepTwo: "Rendez-vous sur notre interface d’intégration.",
          stepThree: "Autorisez l'accès pour connecter vos données de support.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        Freshdesk: {
          title: "Freshdesk",
          description: "Logiciel de support client basé sur le cloud.",
          longDescription:
            "Freshdesk aide les entreprises à centraliser leurs requêtes clients et à fournir un support rapide et efficace.",
          buttonConnect: "Connecter Freshdesk",
          howToConnect: "Comment connecter Freshdesk",
          titleTuto: "Tutoriel de connexion Freshdesk",
          stepOne: "Créez ou connectez-vous à votre compte Freshdesk.",
          stepTwo: "Ouvrez notre section d'intégrations.",
          stepThree:
            "Donnez les autorisations nécessaires pour synchroniser vos tickets de support.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        Intercom: {
          title: "Intercom",
          description: "Plateforme de communication client.",
          longDescription:
            "Intercom facilite l'engagement client grâce à des outils de chat, d’automatisation et de support.",
          buttonConnect: "Connecter Intercom",
          howToConnect: "Comment connecter Intercom",
          titleTuto: "Tutoriel de connexion Intercom",
          stepOne: "Connectez-vous ou inscrivez-vous sur Intercom.",
          stepTwo: "Allez dans la section intégrations de notre application.",
          stepThree:
            "Autorisez notre plateforme à accéder à vos conversations et données.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        HelpScout: {
          title: "Help Scout",
          description: "Outil de support client axé sur les e-mails.",
          longDescription:
            "Help Scout est une solution adaptée aux petites et moyennes entreprises pour centraliser les requêtes clients.",
          buttonConnect: "Connecter Help Scout",
          howToConnect: "Comment connecter Help Scout",
          titleTuto: "Tutoriel de connexion Help Scout",
          stepOne: "Créez ou connectez-vous à votre compte Help Scout.",
          stepTwo: "Accédez à notre page d’intégrations.",
          stepThree:
            "Approuvez les permissions pour synchroniser vos tickets d'assistance.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        LiveChat: {
          title: "LiveChat",
          description: "Application de chat en direct.",
          longDescription:
            "LiveChat offre un support client en temps réel avec des outils simples et efficaces pour engager les visiteurs du site.",
          buttonConnect: "Connecter LiveChat",
          howToConnect: "Comment connecter LiveChat",
          titleTuto: "Tutoriel de connexion LiveChat",
          stepOne: "Créez ou connectez-vous à votre compte LiveChat.",
          stepTwo: "Ouvrez la section des intégrations de notre application.",
          stepThree:
            "Ajoutez les autorisations pour synchroniser vos conversations.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        Olark: {
          title: "Olark",
          description: "Plateforme de chat en direct.",
          longDescription:
            "Olark est conçue pour les petites entreprises souhaitant offrir un support client en temps réel.",
          buttonConnect: "Connecter Olark",
          howToConnect: "Comment connecter Olark",
          titleTuto: "Tutoriel de connexion Olark",
          stepOne: "Créez ou connectez-vous à votre compte Olark.",
          stepTwo: "Rendez-vous sur notre page des intégrations.",
          stepThree:
            "Autorisez notre plateforme à accéder à vos données de chat.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        Crisp: {
          title: "Crisp",
          description: "Solution de chat en direct.",
          longDescription:
            "Crisp propose des outils d'engagement client tout-en-un pour le support et les conversations en temps réel.",
          buttonConnect: "Connecter Crisp",
          howToConnect: "Comment connecter Crisp",
          titleTuto: "Tutoriel de connexion Crisp",
          stepOne: "Inscrivez-vous ou connectez-vous à votre compte Crisp.",
          stepTwo: "Ouvrez la section d’intégrations de notre plateforme.",
          stepThree:
            "Approuvez les permissions pour synchroniser vos conversations client.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        TawkTo: {
          title: "Tawk.to",
          description: "Application de chat en direct gratuite.",
          longDescription:
            "Tawk.to est une solution économique pour améliorer l’engagement client grâce à un chat en direct.",
          buttonConnect: "Connecter Tawk.to",
          howToConnect: "Comment connecter Tawk.to",
          titleTuto: "Tutoriel de connexion Tawk.to",
          stepOne: "Connectez-vous ou inscrivez-vous sur Tawk.to.",
          stepTwo: "Allez dans la section des intégrations sur notre site.",
          stepThree:
            "Donnez les autorisations nécessaires pour connecter vos conversations.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        Userlike: {
          title: "Userlike",
          description: "Plateforme de chat pour le support client.",
          longDescription:
            "Userlike offre des outils de chat en direct et d’automatisation pour améliorer la personnalisation des interactions client.",
          buttonConnect: "Connecter Userlike",
          howToConnect: "Comment connecter Userlike",
          titleTuto: "Tutoriel de connexion Userlike",
          stepOne: "Créez ou connectez-vous à votre compte Userlike.",
          stepTwo: "Ouvrez notre section d'intégrations.",
          stepThree:
            "Autorisez notre plateforme à accéder à vos données de chat.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        ZohoDesk: {
          title: "Zoho Desk",
          description: "Outil de gestion du support client.",
          longDescription:
            "Zoho Desk propose des fonctionnalités d’automatisation avancées pour gérer les requêtes et améliorer la satisfaction client.",
          buttonConnect: "Connecter Zoho Desk",
          howToConnect: "Comment connecter Zoho Desk",
          titleTuto: "Tutoriel de connexion Zoho Desk",
          stepOne: "Inscrivez-vous ou connectez-vous à Zoho Desk.",
          stepTwo: "Accédez à notre page des intégrations.",
          stepThree:
            "Ajoutez les autorisations nécessaires pour synchroniser vos tickets.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        QuickBooks: {
          title: "QuickBooks",
          description: "Logiciel de comptabilité.",
          longDescription:
            "QuickBooks aide les petites et moyennes entreprises à gérer leur comptabilité, la facturation et les paiements.",
          buttonConnect: "Connecter QuickBooks",
          howToConnect: "Comment connecter QuickBooks",
          titleTuto: "Tutoriel de connexion QuickBooks",
          stepOne: "Créez ou connectez-vous à votre compte QuickBooks.",
          stepTwo: "Accédez à notre section d’intégrations.",
          stepThree:
            "Autorisez l'accès pour synchroniser vos données financières.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        Xero: {
          title: "Xero",
          description: "Logiciel de comptabilité en ligne.",
          longDescription:
            "Xero propose des outils simples et intuitifs pour gérer les finances des petites entreprises.",
          buttonConnect: "Connecter Xero",
          howToConnect: "Comment connecter Xero",
          titleTuto: "Tutoriel de connexion Xero",
          stepOne: "Connectez-vous ou créez un compte Xero.",
          stepTwo: "Rendez-vous dans notre section des intégrations.",
          stepThree:
            "Approuvez les autorisations pour connecter vos données comptables.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        Agicap: {
          title: "Agicap",
          description: "Outil de gestion de trésorerie.",
          longDescription:
            "Agicap permet aux PME de suivre leurs flux de trésorerie et de créer des prévisions financières en temps réel.",
          buttonConnect: "Connecter Agicap",
          howToConnect: "Comment connecter Agicap",
          titleTuto: "Tutoriel de connexion Agicap",
          stepOne: "Créez ou connectez-vous à votre compte Agicap.",
          stepTwo: "Accédez à la section des intégrations sur notre site.",
          stepThree:
            "Ajoutez les autorisations pour synchroniser vos données financières.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        Pennylane: {
          title: "Pennylane",
          description: "Plateforme de gestion financière.",
          longDescription:
            "Pennylane connecte la comptabilité et les opérations des PME pour une gestion simplifiée.",
          buttonConnect: "Connecter Pennylane",
          howToConnect: "Comment connecter Pennylane",
          titleTuto: "Tutoriel de connexion Pennylane",
          stepOne: "Inscrivez-vous ou connectez-vous à Pennylane.",
          stepTwo: "Ouvrez notre page d’intégrations.",
          stepThree: "Autorisez l'accès pour connecter vos données comptables.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        PayPal: {
          title: "PayPal",
          description: "Solution de paiement en ligne.",
          longDescription:
            "PayPal est une plateforme de paiement acceptée mondialement, utilisée pour gérer les transactions en ligne.",
          buttonConnect: "Connecter PayPal",
          howToConnect: "Comment connecter PayPal",
          titleTuto: "Tutoriel de connexion PayPal",
          stepOne: "Créez ou connectez-vous à votre compte PayPal.",
          stepTwo:
            "Rendez-vous dans la section d'intégrations de notre plateforme.",
          stepThree: "Autorisez l'accès pour synchroniser vos paiements.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        Square: {
          title: "Square",
          description: "Plateforme de paiement et outils de gestion.",
          longDescription:
            "Square offre des solutions intégrées pour gérer les paiements, la facturation et bien plus encore.",
          buttonConnect: "Connecter Square",
          howToConnect: "Comment connecter Square",
          titleTuto: "Tutoriel de connexion Square",
          stepOne: "Connectez-vous ou créez un compte Square.",
          stepTwo: "Accédez à notre page des intégrations.",
          stepThree:
            "Approuvez les autorisations nécessaires pour connecter vos données.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        Wave: {
          title: "Wave",
          description: "Logiciel de comptabilité gratuit.",
          longDescription:
            "Wave propose des outils simples pour la comptabilité et la facturation, spécialement conçus pour les petites entreprises.",
          buttonConnect: "Connecter Wave",
          howToConnect: "Comment connecter Wave",
          titleTuto: "Tutoriel de connexion Wave",
          stepOne: "Inscrivez-vous ou connectez-vous à votre compte Wave.",
          stepTwo: "Rendez-vous sur notre section des intégrations.",
          stepThree:
            "Donnez les permissions pour synchroniser vos données financières.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        RevolutBusiness: {
          title: "Revolut Business",
          description: "Solution bancaire numérique.",
          longDescription:
            "Revolut Business propose des outils modernes pour gérer les paiements et les opérations des entreprises.",
          buttonConnect: "Connecter Revolut Business",
          howToConnect: "Comment connecter Revolut Business",
          titleTuto: "Tutoriel de connexion Revolut Business",
          stepOne: "Créez ou connectez-vous à votre compte Revolut Business.",
          stepTwo:
            "Rendez-vous dans la section des intégrations sur notre site.",
          stepThree:
            "Autorisez l'accès pour synchroniser vos données financières.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        Wise: {
          title: "Wise",
          description: "Plateforme de transfert d’argent international.",
          longDescription:
            "Wise permet de réaliser des transferts d'argent internationaux avec des frais réduits, adaptés aux entreprises et particuliers.",
          buttonConnect: "Connecter Wise",
          howToConnect: "Comment connecter Wise",
          titleTuto: "Tutoriel de connexion Wise",
          stepOne: "Connectez-vous ou créez un compte Wise.",
          stepTwo: "Accédez à notre section des intégrations.",
          stepThree:
            "Approuvez les permissions nécessaires pour connecter vos paiements.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        GoogleDrive: {
          title: "Google Drive",
          description: "Service de stockage cloud.",
          longDescription:
            "Google Drive permet de sauvegarder, partager et collaborer sur des fichiers en ligne, tout en restant sécurisé.",
          buttonConnect: "Connecter Google Drive",
          howToConnect: "Comment connecter Google Drive",
          titleTuto: "Tutoriel de connexion Google Drive",
          stepOne: "Connectez-vous à votre compte Google.",
          stepTwo:
            "Allez dans la section des intégrations de notre plateforme.",
          stepThree:
            "Donnez les autorisations nécessaires pour synchroniser vos fichiers.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        Dropbox: {
          title: "Dropbox",
          description: "Service de stockage et de partage de fichiers.",
          longDescription:
            "Dropbox est une solution en ligne pour collaborer et stocker vos fichiers en toute simplicité.",
          buttonConnect: "Connecter Dropbox",
          howToConnect: "Comment connecter Dropbox",
          titleTuto: "Tutoriel de connexion Dropbox",
          stepOne: "Inscrivez-vous ou connectez-vous à Dropbox.",
          stepTwo:
            "Rendez-vous dans la section des intégrations de notre site.",
          stepThree: "Autorisez notre plateforme à accéder à vos fichiers.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        OneDrive: {
          title: "OneDrive",
          description: "Service de stockage cloud de Microsoft.",
          longDescription:
            "OneDrive est intégré à Microsoft 365, permettant un stockage sécurisé et des collaborations en temps réel.",
          buttonConnect: "Connecter OneDrive",
          howToConnect: "Comment connecter OneDrive",
          titleTuto: "Tutoriel de connexion OneDrive",
          stepOne: "Connectez-vous à votre compte Microsoft.",
          stepTwo: "Accédez à notre section d’intégrations.",
          stepThree:
            "Ajoutez les autorisations nécessaires pour synchroniser vos fichiers.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        Box: {
          title: "Box",
          description: "Plateforme de stockage cloud sécurisée.",
          longDescription:
            "Box offre des outils de collaboration avancés, adaptés aux entreprises ayant besoin de sécurité renforcée.",
          buttonConnect: "Connecter Box",
          howToConnect: "Comment connecter Box",
          titleTuto: "Tutoriel de connexion Box",
          stepOne: "Inscrivez-vous ou connectez-vous à Box.",
          stepTwo: "Ouvrez notre page des intégrations.",
          stepThree: "Approuvez les autorisations pour connecter vos fichiers.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        Zapier: {
          title: "Zapier",
          description: "Plateforme d’automatisation.",
          longDescription:
            "Zapier connecte des applications pour automatiser vos workflows sans effort.",
          buttonConnect: "Connecter Zapier",
          howToConnect: "Comment connecter Zapier",
          titleTuto: "Tutoriel de connexion Zapier",
          stepOne: "Créez ou connectez-vous à votre compte Zapier.",
          stepTwo: "Rendez-vous dans notre section d'intégrations.",
          stepThree: "Ajoutez les autorisations pour automatiser vos tâches.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        IFTTT: {
          title: "IFTTT",
          description: "Outil d’automatisation basé sur des applets.",
          longDescription:
            "IFTTT permet de connecter diverses applications et appareils en créant des applets d’automatisation.",
          buttonConnect: "Connecter IFTTT",
          howToConnect: "Comment connecter IFTTT",
          titleTuto: "Tutoriel de connexion IFTTT",
          stepOne: "Créez ou connectez-vous à votre compte IFTTT.",
          stepTwo: "Accédez à notre page des intégrations.",
          stepThree: "Approuvez les autorisations pour automatiser vos tâches.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        AutomateIO: {
          title: "Automate.io",
          description: "Plateforme d’automatisation simple.",
          longDescription:
            "Automate.io permet de connecter des applications et d’automatiser des workflows avec une interface intuitive.",
          buttonConnect: "Connecter Automate.io",
          howToConnect: "Comment connecter Automate.io",
          titleTuto: "Tutoriel de connexion Automate.io",
          stepOne: "Créez ou connectez-vous à votre compte Automate.io.",
          stepTwo: "Rendez-vous dans notre section des intégrations.",
          stepThree:
            "Accordez les permissions nécessaires pour automatiser vos flux de travail.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        N8n: {
          title: "N8n",
          description: "Plateforme d’automatisation open-source.",
          longDescription:
            "N8n permet de créer des flux d’automatisation personnalisés avec de nombreuses intégrations et un code ouvert.",
          buttonConnect: "Connecter N8n",
          howToConnect: "Comment connecter N8n",
          titleTuto: "Tutoriel de connexion N8n",
          stepOne: "Créez ou connectez-vous à votre compte N8n.",
          stepTwo: "Accédez à la section des intégrations de notre plateforme.",
          stepThree:
            "Ajoutez les autorisations nécessaires pour automatiser vos processus.",
          videoUrl:
            "https://player.vimeo.com/video/1033039585?h=3561edb11d&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        Monday: {
          title: "Monday.com",
          description: "Plateforme de gestion de projets et de workflows.",
          longDescription:
            "Monday.com aide à gérer vos projets, suivre vos tâches et collaborer efficacement avec votre équipe.",
          buttonConnect: "Connecter Monday.com",
          howToConnect: "Comment connecter Monday.com",
          titleTuto: "Tutoriel de connexion Monday.com",
          stepOne: "Créez ou connectez-vous à votre compte Monday.com.",
          stepTwo: "Générez une clé API dans les paramètres de votre compte.",
          stepThree:
            "Ajoutez cette clé API dans notre section intégrations pour établir la connexion.",
          videoUrl:
            "https://player.vimeo.com/video/1017868152?h=1c27d744df&autoplay=1",
        },
        Clickup: {
          title: "ClickUp",
          description: "Plateforme de gestion de projets et de tâches.",
          longDescription:
            "ClickUp centralise vos projets, vos tâches et vos objectifs, tout en offrant une flexibilité maximale.",
          buttonConnect: "Connecter ClickUp",
          howToConnect: "Comment connecter ClickUp",
          titleTuto: "Tutoriel de connexion ClickUp",
          stepOne: "Créez ou connectez-vous à votre compte ClickUp.",
          stepTwo:
            "Générez un token API dans les paramètres de votre espace de travail.",
          stepThree:
            "Ajoutez ce token dans notre section intégrations pour connecter ClickUp.",
          videoUrl:
            "https://player.vimeo.com/video/1017868153?h=1c27d744df&autoplay=1",
        },
        Hubspot: {
          title: "HubSpot",
          description: "Plateforme de gestion des relations clients (CRM).",
          longDescription:
            "HubSpot aide à gérer vos ventes, vos contacts et vos campagnes marketing de manière centralisée.",
          buttonConnect: "Connecter HubSpot",
          howToConnect: "Comment connecter HubSpot",
          titleTuto: "Tutoriel de connexion HubSpot",
          stepOne: "Créez ou connectez-vous à votre compte HubSpot.",
          stepTwo:
            "Accordez les autorisations nécessaires pour accéder aux données de votre compte.",
          stepThree:
            "Configurez les automatisations souhaitées via notre interface.",
          videoUrl:
            "https://player.vimeo.com/video/1017868154?h=1c27d744df&autoplay=1",
        },
        Sendinblue: {
          title: "Sendinblue",
          description: "Plateforme de marketing par email et automation.",
          longDescription:
            "Sendinblue permet de créer des campagnes d'emailing, gérer vos listes de contacts et automatiser vos processus marketing.",
          buttonConnect: "Connecter Sendinblue",
          howToConnect: "Comment connecter Sendinblue",
          titleTuto: "Tutoriel de connexion Sendinblue",
          stepOne: "Créez ou connectez-vous à votre compte Sendinblue.",
          stepTwo: "Générez une clé API dans les paramètres de votre compte.",
          stepThree:
            "Ajoutez cette clé dans la section intégrations pour établir la connexion.",
          videoUrl:
            "https://player.vimeo.com/video/1017868155?h=1c27d744df&autoplay=1",
        },
        Activecampaign: {
          title: "ActiveCampaign",
          description: "Plateforme de marketing automation et CRM.",
          longDescription:
            "ActiveCampaign aide à automatiser vos campagnes marketing et à gérer vos relations clients de manière efficace.",
          buttonConnect: "Connecter ActiveCampaign",
          howToConnect: "Comment connecter ActiveCampaign",
          titleTuto: "Tutoriel de connexion ActiveCampaign",
          stepOne: "Créez ou connectez-vous à votre compte ActiveCampaign.",
          stepTwo: "Générez une clé API dans les paramètres de votre compte.",
          stepThree:
            "Ajoutez cette clé dans notre section intégrations pour lier ActiveCampaign.",
          videoUrl:
            "https://player.vimeo.com/video/1017868156?h=1c27d744df&autoplay=1",
        },
        Getresponse: {
          title: "GetResponse",
          description: "Plateforme de marketing par email.",
          longDescription:
            "GetResponse offre des outils pour créer des campagnes email, des pages d'atterrissage et automatiser vos flux marketing.",
          buttonConnect: "Connecter GetResponse",
          howToConnect: "Comment connecter GetResponse",
          titleTuto: "Tutoriel de connexion GetResponse",
          stepOne: "Créez ou connectez-vous à votre compte GetResponse.",
          stepTwo: "Générez une clé API dans les paramètres de votre compte.",
          stepThree:
            "Ajoutez cette clé API dans la section intégrations pour terminer la configuration.",
          videoUrl:
            "https://player.vimeo.com/video/1017868157?h=1c27d744df&autoplay=1",
        },
        Convertkit: {
          title: "ConvertKit",
          description: "Plateforme de marketing par email pour les créateurs.",
          longDescription:
            "ConvertKit est conçu pour aider les créateurs à développer et engager leur audience via des campagnes email personnalisées.",
          buttonConnect: "Connecter ConvertKit",
          howToConnect: "Comment connecter ConvertKit",
          titleTuto: "Tutoriel de connexion ConvertKit",
          stepOne: "Créez ou connectez-vous à votre compte ConvertKit.",
          stepTwo: "Générez une clé API dans les paramètres de votre compte.",
          stepThree:
            "Ajoutez cette clé API dans la section intégrations pour établir la connexion.",
          videoUrl:
            "https://player.vimeo.com/video/1017868158?h=1c27d744df&autoplay=1",
        },
        Aweber: {
          title: "AWeber",
          description: "Plateforme de marketing par email.",
          longDescription:
            "AWeber offre des outils pour créer des newsletters, automatiser vos emails et gérer vos abonnés.",
          buttonConnect: "Connecter AWeber",
          howToConnect: "Comment connecter AWeber",
          titleTuto: "Tutoriel de connexion AWeber",
          stepOne: "Créez ou connectez-vous à votre compte AWeber.",
          stepTwo:
            "Accordez les autorisations nécessaires via notre interface.",
          stepThree:
            "Configurez vos automatisations email pour tirer parti de l'intégration.",
          videoUrl:
            "https://player.vimeo.com/video/1017868159?h=1c27d744df&autoplay=1",
        },
        Tawk: {
          title: "Tawk.to",
          description: "Outil de chat en direct et de support client.",
          longDescription:
            "Tawk.to permet de communiquer avec vos clients en temps réel grâce à un outil de chat simple et efficace.",
          buttonConnect: "Connecter Tawk.to",
          howToConnect: "Comment connecter Tawk.to",
          titleTuto: "Tutoriel de connexion Tawk.to",
          stepOne: "Créez ou connectez-vous à votre compte Tawk.to.",
          stepTwo:
            "Ajoutez l'ID de votre widget Tawk.to dans notre section intégrations.",
          stepThree:
            "Activez le chat pour commencer à interagir avec vos visiteurs.",
          videoUrl:
            "https://player.vimeo.com/video/1017868160?h=1c27d744df&autoplay=1",
        },
        Paypal: {
          title: "PayPal",
          description: "Plateforme de paiement en ligne.",
          longDescription:
            "PayPal facilite les paiements en ligne sécurisés et rapides pour vos clients.",
          buttonConnect: "Connecter PayPal",
          howToConnect: "Comment connecter PayPal",
          titleTuto: "Tutoriel de connexion PayPal",
          stepOne: "Créez ou connectez-vous à votre compte PayPal.",
          stepTwo:
            "Ajoutez vos informations d'identification API (Client ID et Secret) dans la section intégrations.",
          stepThree:
            "Activez l'intégration pour recevoir des paiements via PayPal.",
          videoUrl:
            "https://player.vimeo.com/video/1017868161?h=1c27d744df&autoplay=1",
        },
        Ifttt: {
          title: "IFTTT",
          description:
            "Plateforme d’automatisation pour connecter vos applications.",
          longDescription:
            "IFTTT permet de créer des automatisations simples entre vos outils préférés via des 'Applets'.",
          buttonConnect: "Connecter IFTTT",
          howToConnect: "Comment connecter IFTTT",
          titleTuto: "Tutoriel de connexion IFTTT",
          stepOne: "Créez ou connectez-vous à votre compte IFTTT.",
          stepTwo:
            "Ajoutez vos services préférés et activez les Applets correspondants.",
          stepThree:
            "Associez vos Applets avec notre plateforme pour automatiser vos processus.",
          videoUrl:
            "https://player.vimeo.com/video/1017868162?h=1c27d744df&autoplay=1",
        },
        Automate: {
          title: "Automate.io",
          description:
            "Plateforme d’automatisation pour connecter vos applications.",
          longDescription:
            "Automate.io permet de connecter vos applications pour automatiser vos workflows en toute simplicité.",
          buttonConnect: "Connecter Automate.io",
          howToConnect: "Comment connecter Automate.io",
          titleTuto: "Tutoriel de connexion Automate.io",
          stepOne: "Créez ou connectez-vous à votre compte Automate.io.",
          stepTwo:
            "Créez un Bot pour connecter deux ou plusieurs applications.",
          stepThree:
            "Ajoutez vos autorisations API dans la section intégrations pour activer les automatisations.",
          videoUrl:
            "https://player.vimeo.com/video/1017868163?h=1c27d744df&autoplay=1",
        },
      },
      WorkspaceActionModal: {
        title:
          "Pour quel espace de travail voulez-vous effectuer cette action ?",
        description:
          "Vous êtes présents dans plusieurs espaces de travail. Choisissez-en donc un.",
        continue: "Continuer",
      },
      Website: {
        name: "Site de réservations",
        List: {
          title: "Liste des sites de réservations",
          search: "Rechercher un site de réservations",
          integrate: "Intégrer un moteur de réservation",
          create: "Créer un nouveau site de réservation",
          website: "Site de réservation",
          URL: "Adresse web",
          template: "Modèle",
          creationDate: "Date de création",
          rentalCount_one: "{{count}} hébergement",
          rentalCount_other: "{{count}} hébergements",
          WhatIs: {
            title: "Qu’est ce que le site de réservations ?",
            description:
              "Le site de réservation est un site web proposé par SuperHote ou que vous gérez en direct, synchronisé à SuperHote. Cela vous permet de proposer à vos voyageurs de passer “en direct”, sans les commisions des plateformes. Vous pouvez choisir de créer ce site web de votre côté, et de le connecter à SuperHote, ou bien vous pouvez créer ce site web directement depuis SuperHote.",
          },
          NoData: {
            title: "Aucun site de réservation ajouté",
            description: "Vous pouvez en ajouter un dès maintenant.",
            button: "Ajouter un site de réservation",
          },
        },
        Integrate: {
          title: "Intégrer le moteur de réservation à votre site",
          integrateListTitle: "Intégrer la liste de tous les hébergements",
          integrateListDescription:
            "Pour intégrer la liste de tous les hébergements dans votre site web, vous devez ajouter ce code iframe dans votre page.",
          iframeList: "Iframe de la liste",
          integrateSearchTitle:
            "Intégrer la recherche de tous les hébergements",
          integrateSearchDescription:
            "Pour intégrer la recherche dynamqiue de tous les hébergements dans votre site web, vous devez ajouter ce code iframe dans votre page.",
          iframeSearch: "Iframe de la recherche",
          script: "Script JavaScript",
        },
        AddChoice: {
          title: "Ajouter un site de réservation",
          description: "Qu’est ce qui vous correspond le mieux ?",
          integrateTitle: "Intégrer un site de réservation existant",
          integrateDescription:
            "Je veux ajouter mes hébergements dessus et le synchroniser avec SuperHote",
          addTitle: "Créer un nouveau site de réservation",
        },
        Tabs: {
          content: "Contenu",
          rentals: "Hébergements",
          style: "Style",
          settings: "Paramètres et intégration",
          view: "Voir le site",
        },
        Infos: {
          title: "Informations générales",
          name: "Titre",
          subName: "Sous-titre",
          language: "Langue",
          addBackground: "Faites glisser une photo pour la bannière ici",
          addLogo: "Faites glisser une image pour le logo ici",
          titleLabel: "Title",
          subTitleLabel: "Sous-titre",
          languageLabel: "Langue",
        },
        Logos: {
          title: "Bandeaux logos partenaires",
          addLogo: "Ajouter un autre logo",
        },
        Rentals: {
          title: "Liste logements",
          infos:
            "Pour paramétrer les détails d’un hébergement en particulier, ainsi que leur mode de réservation, rendez-vous dans les détails de l’hébergement, onglet Moteur de réservation",
          rentalsVisible: "Hébergements visibles sur le site",
          checkRentals:
            "Cochez les hébergements que vous souhaitez pouvoir être visibles sur votre site de réservations. Chaque hébergement doit avoir un compte Stripe actif pour pouvoir être réservé par les voyageurs.",
          rentals: "Hébergements",
          stripeAccount: "Compte Stripe pour encaisser les voyageurs",
          noStripe: "Aucun",
          stripeActive:
            "Chaque hébergement doit avoir un compte Stripe pour pouvoir être réservé par les voyageurs.",
          edit: "Modifier les hébergements visibles sur le site",
          required: "Sélectionnez au moins 1 hébergement.",
        },
        MoreInfos1: {
          title: "Bloc d’informations supplémentaires 1",
          addBackground: "Faites glisser une photo de fond ici",
          titleLabel: "Titre",
          subTitleLabel: "Sous-titre",
          textLabel: "Texte",
        },
        Testimonials: {
          title: "Témoignages voyageurs",
          addTestimonial: "Ajouter un témoignage voyageur",
          required: "Nom, témoignage et photo requis",
          name: "Nom",
          review: "Témoignage",
          addPhoto: "Ajouter une photo ici",
        },
        MoreInfos2: {
          title: "Bloc d’informations supplémentaires 2",
          required: "Titre et texte requis",
          titleLabel: "Titre",
          subTitleLabel: "Sous-titre",
          addCard: "Ajouter une carte",
          required: "Titre et texte requis",
          addPhoto: "Ajouter une image ici",
          titleCardLabel: "Titre de la carte",
          textCardLabel: "Texte",
        },
        FAQ: {
          title: "Questions fréquentes",
          question: "Question",
          answer: "Réponse",
          required: "Question et réponse requises",
          addQuestion: "Ajouter une question",
        },
        About: {
          title: "Section à propos",
          titleLabel: "Titre",
          textLabel: "Texte",
          photoLabel: "Image d'illustration",
        },
        Services: {
          title: "Section services",
          addCard: "Ajouter un service",
          required: "Nom et description requis",
          addPhoto: "Ajouter une image ici",
          titleCardLabel: "Nom du service",
          textCardLabel: "Description",
        },
        Contact: {
          title: "Section contact",
          emailLabel: "Adresse email de contact",
          phoneLabel: "Numéro de téléphone de contact",
          availabilitiesLabel: "Disponibilité",
        },
        Templates: {
          title: "Modèle du site",
          choosenTemplate: "Modèle choisi",
        },
        Colors: {
          title: "Couleurs",
          mainColor: "Couleur principale",
          textColor: "Couleur de texte",
          bgColor: "Couleur de fond",
          invalidHex: "Couleur héxadécimale invalide",
        },
        URL: {
          title: "Adresse du site",
          label: "Adresse de votre site",
        },
        Legal: {
          title: "Mentions légales et conditions",
          legalTerms: "Mentions légales",
          cgv: "Conditions générales de vente",
          privacy: "Politique de confidentialité",
        },
        Integrations: {
          title: "Intégrations",
          webkey: "Webkey personnel",
          tuto: "Voir le tutoriel d’intégration",
        },
        Add: {
          Close: {
            title:
              "Voulez-vous vraiment quitter l’ajout de ce site de réservation ?",
            content:
              "Si vous quittez maintenant, vous perdrez les informations remplies jusqu’à présent. ",
            button: "Oui, quitter",
          },
          Summary: {
            websiteTo: "Site de réservation de {{fullName}}",
            visibleRentals: "Hébergements visibles sur le site de réservations",
            general: "Informations générales",
            rentals: "Hébergements concernés",
            templates: "Modèle du site",
            url: "Adresse du site",
            legals: "Mentions légales et conditions",
            done: "Terminé !",
            later:
              "Vous pourrez compléter les détails de votre site de réservation plus tard. ",
          },
          General: {
            title: "Créons ensemble votre site de réservations !",
            description:
              "Pour le moment, Veuillez choisir un titre et un sous titre, ainsi qu’une image de bannière.",
            background: "Image de fond",
            mainTitle: "Titre principal",
            mainTitlePlaceholder: "Votre séjour de rêve à Paris",
            subTitle: "Sous-titre",
            subTitlePlaceholder: "Réservez maintenant un de nos hébergements",
          },
          Rentals: {
            title:
              "Choisissez les hébergements visibles sur votre site de réservations",
            description:
              "Veuillez choisir quels hébergements seront affichés sur votre site.",
          },
          Templates: {
            title: "Choississez maintenant un modèle pour votre site",
            description:
              "SuperHote vous propose de choisir parmi trois modèles, selon le style que vous souhaitez donner à votre site.",
            view: "Voir l'aperçu",
          },
          URL: {
            title:
              "Quel sera l’adresse URL de votre site internet de réservation ?",
            description:
              "Décidez maintenant quelle sera l’adresse URL de votre site internet de réservation. Cette adresse est obligatoire pour que les voyageurs puisse trouver votre site.",
            infosTitle:
              "Pourquoi l’adresse de mon site commence par “connect.superhote.com/website/” ?",
            infosDescription:
              "Par défaut, votre site web sera hébergé chez Superhote, c’est pour cela que l’adresse commence par connect.superhote.com. Sinon, vous pouvez ajouter un nom de domaine personnalisé.",
            label: "Adresse de votre site de réservation",
            hint: "Vous pourrez modifier l’adresse plus tard, au besoin.",
            custom:
              "Sinon, vous pouvez ajouter une adresse web personnalisé, avec un nom de domaine que vous possédez déjà (exemple : cosyappartement.com)",
            customLabel: "Nom de domaine personnalisé",
            saved: "Enregistré",
            addCustom: "Ajouter un nom de domaine personnalisé",
            editCustom: "Modifier le nom de domaine personnalisé",
            removeCustom: "Supprimer la liaison avec ce nom de domaine",
            removeTitle:
              "Voulez-vous vraiment supprimer la liaison entre votre site de réservation et ce nom de domaine ?",
            removeDescription1:
              "Vous repasserez sur une adresse standard, qui commence par connect.superhote.com/website/.",
            removeDescription2:
              "Vous pourrez toujours ajouter à nouveau un nom de domaine si vous le souhaitez.",
            Modal: {
              title: "Nom de domaine personnalisé",
              label: "Votre nom de domaine",
              error: "Veuillez entrer un nom de domaine valide.",
              placeholder: "reserveztoulon.com",
              hint: "La mise en place de votre nom de domaine peut prendre jusqu’à 48h pour être effectif.Veuillez ajouter les liens CNAME dans le paramètrage de votre hébergeur.",
              infosTitle: "Comment ajouter un nom de domaine personnalisé ?",
              infosDescription: `
                  <p><strong>Vous avez déjà un nom de domaine acheté auprès d’un autre hébergeur ? Aucun problème !</strong></p>
                  <p>Voici les étapes pour le connecter facilement à votre site créé avec SuperHote :</p>
                  <ol>
                    <li><strong>Accédez aux paramètres DNS</strong> de votre nom de domaine chez votre fournisseur actuel (exemple : GoDaddy, OVH, Namecheap, etc.).</li>
                    <li>Ajoutez un enregistrement de type <strong>CNAME</strong> avec les informations suivantes :
                      <ul>
                        <li><strong>Nom / Sous-domaine</strong> : www (ou l’entrée souhaitée, selon vos besoins).</li>
                        <li><strong>Cible</strong> : [adresse personnalisée générée par SuperHote] (cette adresse est fournie dans SuperHote).</li>
                      </ul>
                    </li>
                    <li>Si vous souhaitez que votre domaine principal (sans le "www") redirige également vers votre site, ajoutez un enregistrement de type <strong>A</strong> avec notre adresse IP [XXXXXX].</li>
                    <li>Enregistrez les modifications dans votre gestionnaire DNS.</li>
                    <li>Retournez sur SuperHote, puis ajoutez ci-dessus votre nom de domaine. Vous pourrez ainsi finaliser la connexion.</li>
                    <li>Patientez quelques heures (jusqu’à 48h) pour que les modifications prennent effet.</li>
                  </ol>
                  <p><strong>Exemples concrets :</strong></p>
                  <p>Si votre domaine est monsite.com, vous pouvez configurer www.monsite.com pour qu’il pointe vers votre site généré par SuperHote.</p>
                `,
            },
          },
          Legal: {
            title: "Enfin, vérifiez vos mentions légales et vos conditions",
            description:
              "Les mentions légales, les conditions générales et la politique de confidentialité sont obligatoires sur votre site de réservations. SuperHote vous en propose par défaut, mais vous devez les vérifier.",
            legals: "Mentions légales",
            cgv: "Conditions générales de vente",
            policies: "Politique de confidentialité",
          },
          Done: {
            title: "Votre site de réservations est créé !",
            description:
              "Vous pouvez dès maintenant le voir à l’adresse suivante : ",
            label: "Adresse de votre site",
            view: "Voir le site",
            content1:
              "Maintenant, vous pouvez continuer la personnalisation de votre site de réservation : ",
            content2: "· Remplissez les sections de votre site",
            content3: "· Définissez une image de bannière",
            content4: "· Importez votre logo",
            content5:
              "· Définissez le compte Stripe pour l’encaissement des paiements",
            content6: "Et plus encore !",
            button: "Paramétrer le site",
          },
        },
      },
      iframe: {
        denied: "Accès Refusé",
        deniedText:
          "Vous n'avez pas la permission d'accéder à cette ressource.",
        documentationSee: "Pourquoi n’ai-je pas accès à cette ressource ?",
      },
      Rentals: {
        name: "Prénom",
        last_name: "Nom de famille",
        email: "Email",
        tel: "Numéro de téléphone",
        general: "Contact Principal",
        invoices: "Contact Factures",
        reservations: "Contact Réservation",
        centralReservations: "Contact Centre de Réservations",
        requests: "Contact Requêtes Spéciales",
        availability: "Contact pour la Disponibilité",
        siteContent: "Contact Photo et Description",
        parity: "Contact Tarification",
        helpText:
          "Nous avons rempli automatiquement certaines informations sur la base de votre profil.",
        addContact: "Ajouter un contact",
        admin: "Administratif",
        anyContact: "Aucun contact d'enregistré.",
        invoice: "Facturation et administratif",
        hoteType: "Type d'hôte",
        hoteName: "Nom de l'hôte",
        fiscalNumber: "Numéro d'identification fiscal",
        invoiceAddress: "Adresse de facturation",
        country: "Pays",
        postalCode: "Code postal",
        city: "Ville",
        numberHouse: "Numéro d'enregistrement du bien",
        socialReason: "Raison sociale (nom complet de l'entreprise)",
        siren: "SIREN",
        pro: "Professionnel",
        guest: "Particulier",
      },
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "fr", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    transSupportBasicHtmlNodes: true,
  });

export default i18n;
