import React, {useEffect, useState} from "react";
import {Card} from "../../../Common/Card/Card";
import ContractIcon from "../../../../assets/icons/contracts-paper.svg?react";
import PlusIcon from "../../../../assets/icons/plus.svg?react";
import {useTranslation} from "react-i18next";
import CardDocs from "../../../Common/CardDocs/CardDocs";
import {UseModal, useModal} from "../../../../hooks/useModal";
import {ContractCardSkeleton} from "../Contract/ContractCardSkeleton";
import {
  ContractResponse,
  ReservationResponse,
} from "../../../../types/GETTypes";
import {ResourceResponse} from "../../../Common/CardDocs/CardDocs.type";
import {DataContractModal, modeType} from "../Modal/ContractModal.type";
import {
  getPlatformByName,
  getPlatformNameByEnum,
} from "../../../../helpers/platformHelper";
import {PLATFORM_RESERVATION} from "../../../../pages/Booking/BookingPage.type";
import {checkAllContractsSigned} from "../../../../helpers/contractHelper";

type ContractsCardProps = {
  modalContract: UseModal<
    {mode: modeType; contract: ContractResponse} | null | undefined
  >;
  modalSendContract: UseModal<DataContractModal | null | undefined>;
  modalAddContract: UseModal<unknown>;
  contracts: ContractResponse[] | undefined;
  loading?: boolean;
  reservation: ReservationResponse | undefined;
};

const ContractCard = ({
  modalContract,
  modalSendContract,
  contracts,
  loading,
  reservation,
  modalAddContract,
}: ContractsCardProps) => {
  const {t} = useTranslation();
  const maxContracts = 5;

  const handleDisplay = async (contract: ResourceResponse) => {
    modalContract.open({
      contract: contract as ContractResponse,
      mode: "display",
    });
  };
  const handleSend = async (contract: ResourceResponse) => {
    modalSendContract.open({contract: contract as ContractResponse});
  };
  const handleEdit = async (contract: ResourceResponse) => {
    modalContract.open({contract: contract as ContractResponse, mode: "edit"});
  };
  const handleLink = async (contract: ResourceResponse) => {
    const hostname = new URL(window.location.href).origin;
    const link = `${hostname}/reservation/${reservation?.id}/guest-page/${reservation?.guest_page_link}?tab=contract&contractId=${contract.id}`;
    window.open(link, "_blank");
  };
  const isPlatform =
    reservation?.platform.name &&
    getPlatformByName(reservation?.platform.name) ===
      PLATFORM_RESERVATION.AIRBNB;

  const isEmpty = !contracts?.length;
  const allSigned =
    !isEmpty &&
    checkAllContractsSigned(contracts) &&
    maxContracts >= contracts.length;

  const platformText = () => {
    switch (getPlatformByName(reservation?.platform.name)) {
      case PLATFORM_RESERVATION.AIRBNB:
        return `${t("Rental.Contracts.Contracts.deApostroph")}${
          PLATFORM_RESERVATION.AIRBNB
        }`;
      // case PLATFORM_RESERVATION.BOOKING:
      //   return `${t("Rental.Contracts.Contracts.de")} ${
      //     PLATFORM_RESERVATION.BOOKING
      //   }`;
      default:
        return "";
    }
  };

  useEffect(() => {}, [reservation]);

  const Display = () => {
    if (isPlatform) {
      return (
        <div className="flex justify-center">
          <span className="text-low-contrast font-light">
            {t("Rental.Contracts.Contracts.noContractPlatform", {
              platform: platformText(),
            })}
          </span>
        </div>
      );
    } else if (isEmpty) {
      return (
        <div className="flex justify-center">
          <span className="text-low-contrast font-light">
            {t("Rental.Contracts.Contracts.noContract")}
          </span>
        </div>
      );
    } else if (!isEmpty && !isPlatform && contracts && reservation) {
      return contracts?.map((contract) => {
        return (
          <CardDocs
            showLink
            handleLink={handleLink}
            handleDisplay={handleDisplay}
            showSend
            handleSend={handleSend}
            showEdit={!contract.is_signed}
            handleEdit={handleEdit}
            showDownload
            resource={contract}
            showBadge
            from="reservation"
            type="contract"
          />
        );
      });
    }
    return (
      <>
        <div className="flex flex-row items-center justify-between h-12 p-4 space-x-2 border-b-1 border-gray-200">
          <div className="flex items-center space-x-2">
            <div className="w-6 h-6 bg-gray-200 rounded"></div>
            <div className="flex-1 h-4 bg-gray-200 rounded"></div>
          </div>
          <div className="w-24 h-8 rounded bg-gray-200"></div>
        </div>

        {/* Body */}
        <div className="p-5 text-sm flex-1 rounded-b-6px bg-gray-100">
          <div className="h-4 bg-gray-200 rounded w-full my-1"></div>
          <div className="h-4 bg-gray-200 rounded w-2/3 my-1"></div>
          <div className="h-4 bg-gray-200 rounded w-1/2 my-1"></div>
        </div>
        <div className="p-5 text-sm flex-1 rounded-b-6px bg-gray-100">
          <div className="h-4 bg-gray-200 rounded w-full my-1"></div>
          <div className="h-4 bg-gray-200 rounded w-2/3 my-1"></div>
          <div className="h-4 bg-gray-200 rounded w-1/2 my-1"></div>
        </div>
        <div className="p-5 text-sm flex-1 rounded-b-6px bg-gray-100">
          <div className="h-4 bg-gray-200 rounded w-full my-1"></div>
          <div className="h-4 bg-gray-200 rounded w-2/3 my-1"></div>
          <div className="h-4 bg-gray-200 rounded w-1/2 my-1"></div>
        </div>
      </>
    );
  };

  if (loading && !reservation) {
    return <ContractCardSkeleton />;
  }

  return (
    <Card
      Icon={ContractIcon}
      label={t("Rental.Contracts.Contracts.contracts")}
      anchor="contract-card"
      hasBody={true}
      button={
        allSigned
          ? {
              Icon: PlusIcon,
              onClick: () => modalAddContract.open(),
              type: "secondary",
            }
          : undefined
      }
    >
      <Display />
    </Card>
  );
};

export default ContractCard;
