import {
  GoogleMap,
  Libraries,
  MarkerF,
  useLoadScript,
} from "@react-google-maps/api";
import React from "react";
import { geocodeLatLng } from "../../helpers/geocodeHelper";

const mapContainerStyle = {
  width: "40vw",
  height: "40vh",
};

export const MapView: React.FC<{
  marker: { lat: number; lng: number };
  setMarker: (p: { lng: any; lat: any }) => void;
  setNewAddress: React.Dispatch<React.SetStateAction<string>>;
}> = ({ marker, setMarker, setNewAddress }) => {
  const libraries: Libraries = ["places"];
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps</div>;

  const handleMarkerDragEnd = async (e: google.maps.MapMouseEvent) => {
    const newLat = e.latLng?.lat();
    const newLng = e.latLng?.lng();

    if (newLat && newLng) {
      setMarker({ lat: newLat, lng: newLng });
      try {
        const address = await geocodeLatLng(newLat, newLng);
        setNewAddress(address);
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <div className="flex flex-col flex-1">
      <div className="w-[50%]">
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={18}
          center={marker}
        >
          <MarkerF
            position={marker}
            draggable={true}
            onDragEnd={handleMarkerDragEnd}
            visible
          />
        </GoogleMap>
      </div>
    </div>
  );
};
