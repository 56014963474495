import React, { useState } from "react";
import { CenterModal } from "../CenterModal/CenterModal";
import { UseModal } from "../../../hooks/useModal";
import { TitleText } from "../TextComponents/TitleText";
import { HelpText } from "../TextComponents/HelpText";
import { FlexCenterBetween } from "../FlexComponents/FlexCenterBetween";
import { Button } from "../Button/Button";
import { IconType } from "../../../types/commonTypes";
import CloseIcon from "../../../assets/icons/close.svg?react";

export default function ModalChoice({
  modal,
  title,
  description,
  cancelTextButon,
  applyTextButton,
  onCancel,
  onApply,
  onClose,
  IconButtonApply,
  IconButtonCancel,
}: {
  modal: UseModal<any>;
  title: string;
  description: string;
  cancelTextButon: string;
  applyTextButton: string;
  onCancel?: () => void;
  onApply: () => Promise<void>;
  onClose?: () => void;
  IconButtonApply?: IconType;
  IconButtonCancel?: IconType;
}) {
  const [loading, setLoading] = useState(false);

  const handleCloseModal = () => {
    if (typeof onClose === "function") {
      onClose();
    }
    modal.close();
    setLoading(false);
  };

  const handleCancel = () => {
    if (typeof onCancel === "function") {
      onCancel();
    }
    handleCloseModal();
  };

  const handleApply = () => {
    setLoading(true);
    onApply().finally(handleCloseModal);
  };

  return (
    <CenterModal isVisible={modal.isVisible} onClose={handleCloseModal}>
      <CloseIcon
        className={"absolute right-4 top-4 cursor-pointer"}
        onClick={handleCloseModal}
      />
      <TitleText className={"text-xl w-4/5"}>{title}</TitleText>
      <HelpText className={"py-6"}>{description}</HelpText>
      <FlexCenterBetween gap={10}>
        {!loading ? (
          <Button
            type={"secondary"}
            onClick={handleCancel}
            RightIcon={IconButtonCancel}
          >
            {cancelTextButon}
          </Button>
        ) : null}
        <Button
          onClick={handleApply}
          RightIcon={IconButtonApply}
          loading={loading}
        >
          {applyTextButton}
        </Button>
      </FlexCenterBetween>
    </CenterModal>
  );
}
