import React, { useEffect, useRef, useState } from "react";
import { RightModal } from "../Common/RightModal/RightModal";
import { useTranslation } from "react-i18next";
import { TextAreaInput } from "../Common/TextAreaInput/TextAreaInput";
import { Button } from "../Common/Button/Button";
import CheckWhiteIcon from "../../assets/icons/check-white.svg?react";
import { useForm } from "react-hook-form";
import { LexicalEditor } from "lexical";
import { CustomEditor } from "../Common/CustomEditor/CustomEditor";

interface PoliciesModalForm {
  content: string;
}

export const AddWebsitePoliciesModal = ({
  policies,
  isVisible,
  onClose,
  onSave,
}: {
  policies: string | undefined | null;
  isVisible: boolean;
  onClose: () => void;
  onSave: (text: string) => void;
}) => {
  const { t } = useTranslation();

  const form = useForm<PoliciesModalForm>({
    defaultValues: {
      content: "",
    },
  });

  const editorRef = useRef<LexicalEditor>();

  const handleClose = () => {
    onClose();
  };

  const handleSave = () => {
    onSave(form.getValues("content") ?? "");
    handleClose();
  };

  useEffect(() => {
    if (policies) form.setValue("content", policies ?? "");
  }, [isVisible]);

  return (
    <RightModal
      isVisible={isVisible}
      onClose={handleClose}
      title={t("Website.Add.Legal.policies")}
    >
      <div className="flex flex-col justify-between w-full flex-1">
        <div className="w-full flex-1">
          <CustomEditor
            ref={editorRef}
            form={form}
            formControllerName="content"
            error={""}
            disabled={false}
            label={t("Website.Add.Legal.policies")}
            placeholder={t("Website.Add.Legal.policies")}
            classNames={{
              divParent: "flex-1",
              input: "h-[calc(75vh)]",
              divInput: "flex-1",
            }}
          />
        </div>

        <div className="flex gap-3 pt-8 pb-4">
          <Button type="secondary" onClick={handleClose}>
            {t("Global.cancel")}
          </Button>

          <Button RightIcon={CheckWhiteIcon} onClick={handleSave}>
            {t("Global.save")}
          </Button>
        </div>
      </div>
    </RightModal>
  );
};
