import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextInput } from "../Common/TextInput/TextInput";
import { Button } from "../Common/Button/Button";
import ArrowRightWhiteIcon from "../../assets/icons/arrow-right-white.svg?react";
import PhotoIcon from "../../assets/icons/photo.svg?react";
import MinusIcon from "../../assets/icons/minus.svg?react";
import { get, post } from "../../helpers/APIHelper";
import paths from "../../constants/paths";
import { ErrorMessage } from "../Common/ErrorMessage/ErrorMessage";
import { WebsiteAddStepDataType } from "../../pages/AddWebsite/AddWebsitePage";

export type AddWesiteGeneralType = {
  background: Blob | undefined;
  title: string;
  subTitle: string;
};

export const AddWebsiteGeneral = ({
  stepData,
  onClose,
  onNextStep,
}: {
  stepData?: WebsiteAddStepDataType;
  onClose: () => void;
  onNextStep: (values: AddWesiteGeneralType) => void;
}) => {
  const { t } = useTranslation();

  const [APIError, setAPIError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const [background, setBackground] = useState<Blob>();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleAdd = () => {
    fileInputRef.current?.click();
  };

  const [title, setTitle] = useState<string>("");
  const [titleError, setTitleError] = useState<string | undefined>();

  const [subTitle, setSubTitle] = useState<string>("");
  const [subTitleError, setSubTitleError] = useState<string | undefined>();

  useEffect(() => {
    if (stepData?.general) {
      setBackground(stepData.general.background);
      setTitle(stepData.general.title);
      setSubTitle(stepData.general.subTitle);
    }
  }, [stepData?.general]);

  const handleAddFromInput = (event: any) => {
    console.log(event.target.value);
    console.log(event.target.files);

    if (event.target.files?.length === 0) return;

    setBackground(event.target.files[0]);
  };

  const handleRemove = () => {
    setBackground(undefined);
  };

  const handleNextStep = async () => {
    setAPIError(undefined);
    let hasError = false;

    if (!title || title.trim() === "") {
      setTitleError(t("Global.required"));
      hasError = true;
    } else {
      setTitleError(undefined);
    }

    if (!subTitle || subTitle.trim() === "") {
      setSubTitleError(t("Global.required"));
      hasError = true;
    } else {
      setSubTitleError(undefined);
    }

    if (hasError) {
      return; // Stop execution if there are validation errors
    }

    setLoading(true);
    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.WEBSITE.ADD.GENERAL}`,
      {
        title,
        subtitle: subTitle,
      }
    );

    setLoading(false);

    if (!res.data?.success) {
      setAPIError(res.response.data.message);
      return;
    }

    // Proceed to the next step if no errors
    onNextStep({
      background,
      title,
      subTitle,
    });
  };

  return (
    <>
      <div>
        <p className="text-lg font-semibold text-high-contrast">
          {t("Website.Add.General.title")}
        </p>

        <p className="font-light text-low-contrast mt-4">
          {t("Website.Add.General.description")}
        </p>

        <div className="h-24" />

        <p className="text-high-contrast font-semibold">
          {t("Website.Add.General.background")}
        </p>

        <div className="relative flex flex-col gap-4 mt-2">
          <input
            type="file"
            multiple={true}
            style={{ display: "none" }}
            ref={fileInputRef}
            accept="image/jpeg, image/png"
            onChange={handleAddFromInput}
          />

          {background ? (
            <div className="w-full h-full group">
              <div
                className="absolute items-center justify-center hidden w-6 h-6 rounded-sm cursor-pointer border-element-border border-1 group-hover:flex bg-white"
                style={{ top: -6, right: -6 }}
                onClick={handleRemove}
              >
                <MinusIcon width={20} height={20} />
              </div>

              <div className="flex flex-col items-center justify-center w-full h-48 gap-2 overflow-hidden border-dashed bg-element-background border-1 border-element-border rounded-6px hover:bg-element-background-active">
                <img
                  src={URL.createObjectURL(background)}
                  className="object-cover w-full h-full"
                />
              </div>
            </div>
          ) : (
            <div
              className="flex flex-col items-center justify-center w-full gap-2 p-4 border-dashed cursor-pointer bg-element-background border-1 border-element-border rounded-6px hover:bg-element-background-active"
              onClick={handleAdd}
            >
              <PhotoIcon className="w-10 h-10" />
              <p className="font-semibold text-high-contrast">
                {t("AddRental.Photos.addPhoto")}
              </p>
              <div className="flex flex-col items-center justify-center gap-1">
                <p className="font-light text-low-contrast">
                  {t("AddRental.Photos.upTo1")}
                </p>
                <p className="font-light text-low-contrast">
                  {t("AddRental.Photos.formatAccepted")}
                </p>
                <p className="font-light text-low-contrast">
                  {t("AddRental.Photos.WeightAccepted")}
                </p>
                <p className="font-light text-low-contrast">
                  {t("AddRental.Photos.DimensionsMinimum")}
                </p>
              </div>

              <p className="mt-4 font-semibold underline text-low-contrast">
                {t("AddRental.Photos.download")}
              </p>
            </div>
          )}
        </div>

        <div className="h-8" />

        <TextInput
          label={t("Website.Add.General.mainTitle")}
          required={true}
          placeholder={t("Website.Add.General.mainTitlePlaceholder")}
          value={title}
          onChangeText={setTitle}
          error={titleError}
        />

        <div className="h-4" />

        <TextInput
          label={t("Website.Add.General.subTitle")}
          required={true}
          placeholder={t("Website.Add.General.subTitlePlaceholder")}
          value={subTitle}
          onChangeText={setSubTitle}
          error={subTitleError}
        />

        <ErrorMessage errorType="API">{APIError}</ErrorMessage>
      </div>

      <div className="flex gap-3 justify-end">
        <div>
          <Button type="secondary" onClick={onClose} disabled={loading}>
            {t("Global.cancel")}
          </Button>
        </div>
        <div>
          <Button
            RightIcon={ArrowRightWhiteIcon}
            onClick={handleNextStep}
            loading={loading}
          >
            {t("Global.nextStep")}
          </Button>
        </div>
      </div>
    </>
  );
};
