import React from "react";
import {useTranslation} from "react-i18next";
import {Button} from "../Common/Button/Button";
import AirbnbIcon from "../../assets/icons/airbnb-white.svg?react";
import BookingIcon from "../../assets/icons/booking.svg?react";
import DirectIcon from "../../assets/icons/tv-circle.svg?react";

const PriceMultiplierTableHeader = ({
  isOpen,
  open,
}: {
  isOpen: boolean;
  open: () => void;
}) => {
  const {t} = useTranslation();
  return (
    <thead className="">
      <tr className="">
        <th className="border-b py-4 pl-2 text-left">
          <Button onClick={open} type="secondary" displayLargeBtn={false}>
            {isOpen
              ? t("PriceMultiplier.hideDetails")
              : t("PriceMultiplier.showDetails")}
          </Button>
        </th>
        <th className="border-b py-4 text-center">
          <div className="flex space-x-1 font-bold text-base items-center justify-center">
            <DirectIcon className="w-6 h-6 mr-2" />
            {t("Filter.bookingEngine")}
          </div>
        </th>
        <th className="border-b py-4 text-center">
          <div className="flex space-x-1 font-bold text-base items-center justify-center">
            <AirbnbIcon className="w-6 h-6 mr-2" />
            {t("Filter.airbnb")}
          </div>
        </th>
        <th className="border-b py-4 text-center">
          <div className="flex space-x-1 font-bold text-base items-center justify-center">
            <BookingIcon className="w-6 h-6 mr-2" />
            {t("Filter.booking")}
          </div>
        </th>
      </tr>
    </thead>
  );
};

export default PriceMultiplierTableHeader;
