import moment from "moment";
import React from "react";
import {
  CALENDAR_CELL_HEIGHT,
  CALENDAR_CELL_WIDTH,
} from "../../constants/calendar";

import Forbid2Icon from "../../assets/icons/forbid-2.svg?react";
import {
  displayUnavailabilityReason,
  hiddenDevice,
} from "../../helpers/calendarHelper";

export const CalendarCell: React.FC<{
  rental: any;
  index: number;
  daysOffset: number;
  onClick: (
    rental: any,
    date: moment.Moment,
    isAvailable: number,
    price: number,
    reason: string,
    reasonOther: string | undefined,
  ) => void;
}> = ({ rental, index, daysOffset, onClick }) => {
  const today = moment();
  const date = moment(new Date()).add(index - daysOffset, "days");
  const isToday = moment().format("YYYY-MM-DD") === date.format("YYYY-MM-DD");
  const beforeDayWhenToday =
    moment().subtract(1, "day").format("YYYY-MM-DD") ===
    date.format("YYYY-MM-DD");
  const restriction = rental.restrictions.find(
    (r: any) => r.date === date.format("YYYY-MM-DD"),
  );

  const getBorder = () => {
    if (beforeDayWhenToday) {
      return "border-r-2 border-r-[#E54D2E]";
    } else if (date.format("DD") === "01") {
      return "border-l-1 border-[#5C6163]/20";
    } else return "";
  };

  const getBg = () => {
    if (date.isBefore(today)) return "bg-element-background-active";
    else if (restriction?.is_reserved === 0 && restriction?.is_available === 0)
      return "bg-element-background-active";
    else return "bg-white";
  };

  return (
    <td
      style={{
        minWidth: CALENDAR_CELL_WIDTH,
        height: CALENDAR_CELL_HEIGHT,
      }}
      className={`relative border-l-1 border-t-1 border-element-border cursor-pointer ${getBorder()} ${getBg()}`}
      onClick={() =>
        onClick(
          rental,
          date,
          restriction?.is_available,
          restriction?.price,
          restriction?.unavailability_reason,
          restriction?.unavailability_reason_other,
        )
      }
    >
      {/* <div>
        re:{restriction?.is_reserved}/av:{restriction?.is_available}
      </div> */}

      {/* La date n'est pas dispo car il y une résa */}
      {restriction?.is_reserved === 1 && restriction?.is_available === 1 && (
        <div />
      )}

      {/* La date est full dispo */}
      {restriction?.is_reserved === 0 && restriction?.is_available === 1 && (
        <div className="absolute top-1 right-1 text-high-contrast font-normal">
          <p className="text-[11px]">
            {restriction?.price}
            {hiddenDevice(restriction?.price, "€")}
          </p>
        </div>
      )}

      {/* Ce cas ne devrait théoriquement pas exister */}
      {restriction?.is_reserved === 1 && restriction?.is_available === 0 && (
        <div />
      )}

      {/* Date bloquée par user (et on affiche la raison) */}
      {restriction?.is_reserved === 0 && restriction?.is_available === 0 && (
        <div className="flex flex-col justify-center items-center gap-0">
          <Forbid2Icon
            width={CALENDAR_CELL_WIDTH - 20}
            height={CALENDAR_CELL_HEIGHT - 20}
          />
          <p
            className="text-[11px] font-light text-low-contrast"
            style={{ lineHeight: 1, paddingBottom: "5px" }}
          >
            {displayUnavailabilityReason(restriction?.unavailability_reason)}
          </p>
        </div>
      )}
    </td>
  );
};
