import React, {useEffect, useImperativeHandle} from "react";
import {
  RentalLightBookingItemResponse,
  RentalLightListItemResponse,
} from "../../../../types/GETTypes";
import {Controller, useForm} from "react-hook-form";
import {
  BookingConnectData,
  BookingConnectDataRentalRoomRates,
} from "./BookingConnectModal";
import {useTranslation} from "react-i18next";
import {InputSelect} from "../../../Common/InputSelect/InputSelect";
import {ValueType} from "../../../../types/commonTypes";
import {
  BookingConnectRentalRoomTypeForm,
  ParamsImportType,
} from "./BookingConnectRentalRoomType";
import {CheckBox} from "../../../Common/CheckBox/CheckBox";

export interface BookingConnectRentalConnectMode {
  mode: ParamsImportType;
  rental_id: ValueType;
}

const BookingConnectRentalConnectMode = React.forwardRef(
  (
    {
      property,
      onChange,
      loading,
      rentals,
      setData,
      importData,
    }: {
      property: RentalLightBookingItemResponse;
      onChange?: () => Promise<void>;
      loading?: boolean;
      setData: React.Dispatch<
        React.SetStateAction<BookingConnectData | undefined>
      >;
      importData: BookingConnectRentalRoomTypeForm[] | undefined;
      rentals: RentalLightListItemResponse[] | undefined;
    },
    ref
  ) => {
    const {t} = useTranslation();
    const {
      trigger,
      getValues,
      watch,
      control,
      setValue,
      formState: {errors},
    } = useForm<BookingConnectRentalConnectMode>({
      defaultValues: {
        mode: "create",
        rental_id: "",
      },
      mode: "all",
    });

    useImperativeHandle(ref, () => ({
      trigger,
      getValues,
    }));

    useEffect(() => {
      const subscription = watch(() => {
        if (onChange) {
          onChange();
        }
      });
      return () => subscription.unsubscribe();
    }, [watch, onChange]);

    const selectedMode = watch("mode");
    const selectedRental = watch("rental_id");
    const rentalsSelected = rentals?.map((rental) => {
      return {
        value: rental.id,
        label: rental.name,
      };
    });

    useEffect(() => {
      if (importData) {
        setData((oldConnectedData) => {
          if (oldConnectedData) {
            return {
              ...oldConnectedData,
              importData: oldConnectedData?.importData?.map((d) =>
                Number(d.property_id) === property.id
                  ? {...d, mode: selectedMode, rental_id: String(selectedRental)}
                  : d
              ),
            };
          }
        });
      }
    }, [selectedMode, selectedRental]);

    return (
      <div className="flex flex-col p-2 px-0 space-y-3">
        <div className="flex space-x-3">
          <div className="object-fill w-16 h-12 shrink-0 rounded-4px border-1 border-element-border/50 overflow-hidden">
            <img
              src={property?.photo ?? ""}
              className="object-cover w-16 h-12 shrink-0"
            />
          </div>

          <div className="flex flex-col gap-y-3 w-full">
            <div>
              <p className="font-semibold text-high-contrast underline underline-offset-4">
                {property?.name}
              </p>
              <p className="font-light text-low-contrast mt-1">
                {`${property?.address} ${property?.zip_code} ${property?.city}`}
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col space-y-3 p-4">
          <Controller
            control={control}
            name="mode"
            rules={{}}
            render={({field: {value, onChange}}) => {
              return (
                <CheckBox
                  isRadio
                  label={t("Progression.createBookingRental")}
                  value={value === "create"}
                  onChange={() => onChange("create")}
                  toggleLabelActive
                  disabled={loading}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="mode"
            rules={{}}
            render={({field: {value, onChange}}) => {
              const handleChange = () => {
                if (rentalsSelected && rentalsSelected?.length > 0) {
                  setValue("rental_id", rentalsSelected[0].value);
                }
                onChange("connect");
              };
              return (
                <CheckBox
                  isRadio
                  label={t("Progression.connectBookingRental")}
                  value={value === "connect"}
                  onChange={handleChange}
                  toggleLabelActive
                  disabled={loading}
                />
              );
            }}
          />
          {selectedMode === "connect" ? (
            <Controller
              control={control}
              name="rental_id"
              rules={{
                required: {
                  value: true,
                  message: t("GuestPage.LoginDetails.fieldRequired"),
                },
              }}
              render={({field: {value, onChange}}) => {
                return (
                  <div className="w-full">
                    <InputSelect
                      label={t("Progression.connectSelectBookingRental")}
                      items={rentalsSelected ?? []}
                      selectedValue={value}
                      onSelect={onChange}
                      error={errors.rental_id?.message}
                      disabled={loading}
                    />
                  </div>
                );
              }}
            />
          ) : null}
        </div>
      </div>
    );
  }
);

export default BookingConnectRentalConnectMode;
