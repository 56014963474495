import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import ListIcon from "../../../assets/icons/list.svg?react";
import { Card } from "../../Common/Card/Card";
import { WebsiteResponse } from "../../../types/GETTypes";
import { useTranslation } from "react-i18next";
import { post } from "../../../helpers/APIHelper";
import paths from "../../../constants/paths";
import { TextInput } from "../../Common/TextInput/TextInput";
import CheckIcon from "../../../assets/icons/check-white.svg?react";
import EditIcon from "../../../assets/icons/edit.svg?react";
import ArrowLeftIcon from "../../../assets/icons/arrow-left.svg?react";
import { TextAreaInput } from "../../Common/TextAreaInput/TextAreaInput";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";

export const WebsiteRentalsCard: React.FC<{
  website: WebsiteResponse | undefined;
  onUpdateWebsite: (nextWebsite: WebsiteResponse) => void;
}> = ({ website, onUpdateWebsite }) => {
  const { t } = useTranslation();

  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const [updateError, setUpdateError] = useState<string | undefined>();

  const [editMode, setEditMode] = useState<boolean>(false);

  const editModeRef = useRef<{
    handleClear: () => void;
    handleCheckData: () => {
      title: string;
      subTitle: string;
    } | null;
  } | null>(null);

  const handleCancel = () => {
    setEditMode(false);
    editModeRef.current?.handleClear();
  };

  const handleUpdate = async () => {
    const values = editModeRef.current?.handleCheckData();

    if (!values) return;

    setUpdateLoading(true);
    setUpdateError(undefined);

    const formData = new FormData();
    formData.append("rental_section_title", values.title);
    formData.append("rental_section_text", values.subTitle);

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.WEBSITE.UPDATE.RENTALS(
        website?.id!
      )}`,
      formData
    );

    if (!res?.data?.success) {
      setUpdateError(res?.response?.data?.message);
    } else {
      onUpdateWebsite(res.data?.result);
      setEditMode(false);
    }

    setUpdateLoading(false);
  };

  useEffect(() => {
    setUpdateError(undefined);
  }, [editMode]);

  return (
    <Card
      Icon={ListIcon}
      label={t("Website.Rentals.title")}
      anchor="rentals-card"
      button={{
        Icon: editMode ? CheckIcon : EditIcon,
        type: editMode ? "primary" : "secondary",
        label: editMode ? t("Global.record") : t("Global.edit"),
        onClick: editMode ? () => handleUpdate() : () => setEditMode(!editMode),
        disabled: !website || updateLoading,
      }}
      secondaryButton={
        editMode
          ? {
              label: t("Global.cancel"),
              LeftIcon: ArrowLeftIcon,
              onClick: handleCancel,
              disabled: updateLoading,
            }
          : undefined
      }
    >
      <ErrorMessage>{updateError}</ErrorMessage>

      {editMode ? (
        <EditMode ref={editModeRef} website={website} loading={updateLoading} />
      ) : (
        <ViewMode website={website} />
      )}
    </Card>
  );
};

const ViewMode: React.FC<{ website: WebsiteResponse | undefined }> = ({
  website,
}) => {
  if (!website) return <ViewModeSkeleton />;

  return (
    <div className="flex flex-col gap-3 ">
      <p className="text-high-contrast font-semibold text-center">
        {website?.content.rentals.title}
      </p>
      <p className="text-low-contrast font-light text-center">
        {website?.content.rentals.text}
      </p>
    </div>
  );
};

const ViewModeSkeleton: React.FC<{}> = ({}) => {
  return (
    <div className="flex flex-col gap-3 items-center justify-center animate-pulse">
      <p className="text-high-contrast font-semibold text-center h-2 bg-slate-300 w-72 rounded"></p>
      <p className="text-low-contrast font-light text-center h-2 bg-slate-200 w-52 rounded mt-2"></p>
    </div>
  );
};

interface EditModeProps {
  website: WebsiteResponse | undefined;
  loading: boolean;
}

const EditMode = forwardRef<
  {
    handleClear: () => void;
    handleCheckData: () => {
      title: string;
      subTitle: string;
    } | null;
  },
  EditModeProps
>(({ website, loading }, ref) => {
  const { t } = useTranslation();

  const [title, setTitle] = useState<string>("");
  const [titleError, setTitleError] = useState<string | undefined>();

  const [subTitle, setSubTitle] = useState<string>("");

  const handleClear = () => {
    setTitle("");
    setTitleError(undefined);

    setSubTitle("");
  };

  const handleCheckData = () => {
    setTitleError(undefined);

    let isError = false;

    if (!title) {
      setTitleError(t("Global.required"));
      isError = true;
    }

    if (isError) return null;

    return {
      title,
      subTitle,
    };
  };

  useImperativeHandle(ref, () => ({
    handleClear,
    handleCheckData,
  }));

  useEffect(() => {
    if (website) {
      setTitle(website?.content.rentals.title);
      setTitleError(undefined);

      setSubTitle(website?.content.rentals.text);
    }
  }, [website]);

  // Title and sub title
  const handleChangeTitle = (text: string) => {
    setTitle(text);

    setTitleError(undefined);
  };

  const handleChangeSubTitle = (text: string) => {
    setSubTitle(text);
  };

  return (
    <div className="flex flex-col gap-3">
      <TextInput
        label={t("Website.Infos.titleLabel")}
        value={title}
        onChangeText={handleChangeTitle}
        error={titleError}
        required={true}
        disabled={loading}
      />

      <TextAreaInput
        label={t("Website.Infos.subTitleLabel")}
        value={subTitle}
        onTextChange={handleChangeSubTitle}
        disabled={loading}
      />
    </div>
  );
});
