import React from "react";

import {cn} from "../../../helpers/classHelper";
import {ReactNode} from "react";

export interface HelpTextProps {
    children: ReactNode;
    className?: string;
}

export const HelpText = ({children, className = ""}: HelpTextProps) =>
{
    return (
        <>
            <p className={cn("text-gray-500 text-xs", className)}>
                {children}
            </p>
        </>
    );
}