import React from "react";
import { PageMenu } from "../../../Common/PageMenu/PageMenu";
import ContractIcon from "../../../../assets/icons/contracts-paper.svg?react";
import ContractHighIcon from "../../../../assets/icons/contracts-paper.svg?react";
import FolderIcon from "../../../../assets/icons/folder.svg?react";


export const ReservationContractDocMenu = () => {
  return (
    <PageMenu
      items={[
        {
          Icon: ContractIcon,
          IconActive: ContractHighIcon,
          anchor: "contract-card",
          labelI18n: "Rental.Contracts.Contracts.contracts",
        },
        {
          Icon: FolderIcon,
          IconActive: FolderIcon,
          anchor: "doc-card",
          labelI18n: "Rental.Contracts.Docs.title",
        },
      ]}
    />
  );
};
