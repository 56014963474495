import React, {useEffect, useState} from "react";
import {ClassNamesType, IconType} from "../../../../../types/commonTypes";
import {cn} from "../../../../../helpers/classHelper";
import ChildrenIcon from "../../../../../assets/icons/emoji-happy.svg?react";
import UsersIcon from "../../../../../assets/icons/users.svg?react";
import NightIcon from "../../../../../assets/icons/moon-2.svg?react";
import CheckinIcon from "../../../../../assets/icons/log-in.svg?react";
import CheckoutIcon from "../../../../../assets/icons/log-out.svg?react";
import useGuestWebsiteStore from "../../../../../stores/guestWebsiteStore/useGuestWebsiteStore";
import {Controller, UseFormReturn} from "react-hook-form";
import {ReservationStep1Form} from "../RentalDetails/Home/RentalDetailsHomePage";
import {TextInput} from "../../../../Common/TextInput/TextInput";
import {DateInput} from "../../../../Common/DateInput/DateInput";
import {NumberInput} from "../../../../Common/NumberInput/NumberInput";
import {Button} from "../../../../Common/Button/Button";
import {DEFAULT_COLOR_WEBSITE_TEMPLATE_2} from "../../../../../constants/colors";
import {ErrorMessage} from "../../../../Common/ErrorMessage/ErrorMessage";
import moment from "moment";
import {getFormattedPrice} from "../../../../../helpers/currencyHelper";
import {areAllExtrasDisabled} from "../../../../GuestPageUser/Common/ExtraComponent";
import {CalendarInput} from "../../../../Common/CalendarInput/CalendarInput";
import {getDatesBetweenDates} from "../../../../../helpers/dateHelper";
import {useTranslation} from "react-i18next";

interface LinePriceBreakdown {
  label: string;
  price: string;
}
const PriceBreakdown = () => {
  const {t} = useTranslation();
  const {step1Data, dataGuestWebsite} = useGuestWebsiteStore();
  const {styles} = dataGuestWebsite || {};
  const {color_text} = styles || {};
  const {nights_count} = step1Data?.general_informations || {};
  const {
    nights_total_price,
    single_night_price,
    cleaning_default,
    city_tax,
    additional_guests_total_price,
    total_ttc,
  } = step1Data?.tarification || {};
  const [lines, setLines] = useState<LinePriceBreakdown[]>();

  useEffect(() => {
    setLines([
      {
        label: `${getFormattedPrice({
          price: single_night_price ?? 0,
          decimals: 2,
          withCurrency: false,
        })} € x ${nights_count} ${t("GuestWebsite.night")}`,
        price: `${getFormattedPrice({
          price: nights_total_price ?? 0,
          decimals: 2,
        })}`,
      },
      {
        label: t("GuestWebsite.taxStay"),
        price: `${getFormattedPrice({price: city_tax ?? 0, decimals: 2})}`,
      },
      {
        label: t("GuestWebsite.cleaningFees"),
        price: `${getFormattedPrice({
          price: cleaning_default ?? 0,
          decimals: 2,
        })}`,
      },
      {
        label: t("GuestWebsite.otherGuests"),
        price: `${getFormattedPrice({
          price: additional_guests_total_price ?? 0,
          decimals: 2,
        })}`,
      },
    ]);
  }, [step1Data]);

  if (!step1Data) {
    return <></>;
  }
  return (
    <div className="py-4 w-full pb-0">
      <div className="bg-gray-100 p-4 rounded-md space-y-2">
        {lines?.map((item, index) => (
          <div
            key={index}
            className="flex justify-between text-low-contrast text-sm"
          >
            <span>{item.label}</span>
            <span>{item.price}</span>
          </div>
        ))}
      </div>
      <div className="border-t border-gray-300 mt-4 pt-4 flex justify-between items-center">
        <span className="font-bold " style={{color: color_text}}>
          {t("Global.total")}
        </span>
        <span className="font-bold" style={{color: color_text}}>
          {getFormattedPrice({price: total_ttc ?? 0, decimals: 2})}
        </span>
      </div>
    </div>
  );
};

function BookingCard() {
  const {t} = useTranslation();
  const {rentalData, step1Data, dataGuestWebsite} = useGuestWebsiteStore();
  const {styles} = dataGuestWebsite || {};
  const {color_text} = styles || {};
  const {name, address, city, postal_code, cover} = rentalData || {};
  const {general_informations, tarification} = step1Data || {};
  const {nights_count, adults_count, children_count, checkin, checkout} =
    general_informations || {};
  const {total_ttc} = tarification || {};

  const formatDate = (date: string | undefined) => {
    if (!date) {
      return "";
    }
    return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
  };
  return (
    <div className="py-4  w-full pb-0">
      {/* Image and Title */}
      <div className="flex items-center">
        <img
          src={cover}
          alt="Property"
          className="w-20 h-20 rounded-md object-cover"
          style={{color: color_text}}
        />
        <div className="ml-4">
          <h2 className="text-lg font-bold" style={{color: color_text}}>
            {name}
          </h2>
          <p className="text-sm text-gray-500">
            {address}, {postal_code} {city}
          </p>
        </div>
      </div>

      {/* Booking Details */}
      <div className="rounded-b-lg pt-4">
        <div className="flex flex-col p-2 gap-4 text-sm text-gray-700 bg-gray-100 rounded-md">
          <div className="flex justify-between">
            <div className="flex items-center space-x-1">
              <CheckinIcon className="h-5 w-5 text-gray-500" />
              <span>{formatDate(checkin)}</span>
            </div>
            <div className="flex items-center space-x-1">
              <CheckoutIcon className="h-5 w-5 text-gray-500" />
              <span>{formatDate(checkout)}</span>
            </div>
          </div>
          <div className="flex justify-between">
            <div className="flex items-center space-x-1">
              <NightIcon className="h-5 w-5 text-gray-500" />
              <span>
                {nights_count} {t("GuestWebsite.night")}
              </span>
            </div>
            <div className="flex items-center space-x-1">
              <UsersIcon className="h-5 w-5 text-gray-500" />
              <span>
                {adults_count} {t("GuestWebsite.adults_small")}
              </span>
            </div>
            <div className="flex items-center space-x-1">
              <ChildrenIcon className="h-5 w-5 text-gray-500" />
              <span>
                {children_count} {t("GuestWebsite.children_singular")}
              </span>
            </div>
          </div>
        </div>

        {/* Total Price */}
        <div className="mt-4 flex justify-between items-center">
          <span className="text-lg font-bold" style={{color: color_text}}>
            {t("GuestWebsite.stay")}
          </span>
          <span className="text-lg font-bold" style={{color: color_text}}>
            {getFormattedPrice({price: total_ttc ?? 0, decimals: 2})}{" "}
          </span>
        </div>
      </div>
    </div>
  );
}
function ExtraSelectBookingCard() {
  const {t} = useTranslation();
  const {rentalData, extrasOrder, dataGuestWebsite} = useGuestWebsiteStore();
  const {styles} = dataGuestWebsite || {};
  const {color_text} = styles || {};
  const {name, address, city, postal_code, cover, guest_page} =
    rentalData || {};
  const {extras_available} = guest_page || {};

  const total = extrasOrder?.reduce((total, order) => {
    const matchingExtra = extras_available?.find(
      (extra) => extra.id === order.id
    );
    if (matchingExtra && order.quantity) {
      total += matchingExtra.unit_price * order.quantity;
    }
    return total;
  }, 0);

  const allDisabled = areAllExtrasDisabled(extras_available);

  const hasExtraAvailable =
    extras_available &&
    extras_available?.length > 0 &&
    !allDisabled &&
    extrasOrder &&
    extrasOrder?.length > 0;

  return (
    <div className=" p-4  w-full pb-0">
      {/* Image and Title */}
      <div className="flex items-center">
        <img
          src={cover}
          alt="Property"
          className="w-20 h-20 rounded-md object-cover"
          style={{color: color_text}}
        />
        <div className="ml-4">
          <h2 className="text-lg font-bold" style={{color: color_text}}>
            {name}
          </h2>
          <p className="text-sm text-gray-500">
            {address}, {postal_code} {city}
          </p>
        </div>
      </div>

      {/* Booking Details */}
      <div className="rounded-b-lg pt-4">
        {/* Total Price */}
        <div className="mt-4 flex justify-between items-center">
          <span className="text-lg font-bold" style={{color: color_text}}>
            {t("GuestWebsite.Rental.ExtrasPayment.titleBlock")}
          </span>
          <span
            className="text-lg font-bold"
            style={{color: color_text}}
          ></span>
        </div>
        <div
          className={cn(
            "flex flex-col p-2 gap-4 text-sm text-gray-700 rounded-md mt-2",
            hasExtraAvailable && "bg-gray-100"
          )}
        >
          {hasExtraAvailable &&
            extrasOrder?.map((order) => {
              const extra = extras_available?.find(
                (extra_available) => extra_available.id === order.id
              );
              if (extra) {
                return (
                  <div className="flex items-center justify-between">
                    <span className="text-xs text-low-contrast w-3/4">
                      {extra.name["fr"]} x {order.quantity}
                    </span>
                    <span className="text-xs text-low-contrast">
                      {getFormattedPrice({
                        price: extra.unit_price * (order.quantity ?? 0),
                        decimals: 2,
                      })}{" "}
                    </span>
                  </div>
                );
              }
              return "";
            })}
        </div>
        <div className="mt-4 flex justify-between items-center">
          <span className="text-lg font-bold" style={{color: color_text}}>
            {t("Global.total")}
          </span>
          <span className="text-lg font-bold" style={{color: color_text}}>
            {getFormattedPrice({price: total ?? 0, decimals: 2})}
          </span>
        </div>
      </div>
    </div>
  );
}

const SelectReservationCard = ({
  form,
  isLoading,
}: {
  form?: UseFormReturn<ReservationStep1Form, any, undefined>;
  isLoading?: boolean;
}) => {
  const {t} = useTranslation();
  const {rentalData, dataGuestWebsite} = useGuestWebsiteStore();
  const {name, address, city, postal_code} = rentalData || {};
  const {styles} = dataGuestWebsite || {};
  const {color_text} = styles || {};
  const [excludeDates, setExcludeDates] = useState<string[] | undefined>();
  useEffect(() => {
    setExcludeDates(() => {
      const disabledDates: Set<string> = new Set();

      if (rentalData) {
        const calendarReservations = rentalData?.reservations;
        const calendarRestrictions = rentalData?.restrictions;
        const restrictedPreviousDate = calendarRestrictions
          ?.filter((v) => moment(v.date).diff(Date.now(), "days") < 0)
          .map((v) => v.date);

        restrictedPreviousDate?.forEach((date) => disabledDates.add(date));

        const checkinDates = calendarReservations
          ?.filter((v) => {
            if (Boolean(v.checkin)) {
              return Boolean(v.checkin);
            }

            return false;
          })
          .map((v) => v.checkin);

        const checkoutDates = calendarReservations
          ?.filter((v) => {
            if (Boolean(v.checkout)) {
              return Boolean(v.checkout);
            }

            return false;
          })
          .map((v) => v.checkout);

        calendarReservations?.forEach((reservation, index) => {
          getDatesBetweenDates(
            reservation.checkin,
            reservation.checkout
          )?.forEach((d, index) => {
            const existsInCheckin = checkinDates.includes(d);
            const existsInCheckout = checkoutDates.includes(d);

            if (
              (existsInCheckin && existsInCheckout) ||
              (reservation.checkin < d && reservation.checkout > d)
            ) {
              disabledDates.add(d);
            }
          });
        });
      }

      return Array.from(disabledDates);
    });
  }, []);

  return (
    <div className="space-y-4">
      <h2 className="text-lg font-bold" style={{color: color_text}}>
        {name}
      </h2>
      <p className="text-sm text-gray-500">
        {address}, {postal_code} {city}
      </p>
      <div className="">
        <div className="">
          <Controller
            control={form?.control}
            name="checkinsDates"
            rules={{
              required: true,
            }}
            render={({field: {onChange, value}}) => (
              <>
                <div className="flex items-start text-sm font-semibold text-left">
                  {t("GuestWebsite.checkins")}
                </div>
                <CalendarInput
                  classNames={{
                    button: `rounded-md bg-white p-2`,
                    divLabel: "hidden",
                  }}
                  disabled={isLoading}
                  height="full"
                  dateType={"range"}
                  allowSingleDateInRange={false}
                  value={value}
                  onChangeDate={onChange}
                  onChangeStartDateInput={() => {}}
                  onChangeEndDateInput={() => {}}
                  isExcludeDate={(date: Date) => {
                    if (excludeDates && excludeDates?.length > 0) {
                      return Boolean(
                        excludeDates?.find(
                          (v) =>
                            moment(date).format("YYYY-MM-DD").toString() === v
                        )
                      );
                    }

                    return false;
                  }}
                  error={form?.formState.errors?.checkinsDates?.message}
                />
              </>
            )}
          />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <Controller
            control={form?.control}
            name="adults_count"
            render={({field: {value, onChange}}) => (
              <div className="w-full">
                <NumberInput
                  label={t("GuestWebsite.adults")}
                  value={value}
                  size="large"
                  onChangeText={onChange}
                  // error={form?.formState.errors.adults_count?.message}
                  disabled={isLoading}
                  labelStyle={{color: color_text}}
                  classNames={{
                    divInput:
                      "outline-none focus:outline-none outline-none focus:ring-offset-0 focus:outline-hidden outline-transparent",
                    input: "outline-none",
                  }}
                />
              </div>
            )}
          />
        </div>
        <div>
          <Controller
            control={form?.control}
            name="children_count"
            render={({field: {value, onChange}}) => (
              <div className="w-full">
                <NumberInput
                  label={t("GuestWebsite.children")}
                  value={value}
                  size="large"
                  onChangeText={onChange}
                  // error={form?.formState.errors.children_count?.message}
                  disabled={isLoading}
                  labelStyle={{color: color_text}}
                  classNames={{
                    divInput:
                      "outline-none focus:outline-none outline-none focus:ring-offset-0 focus:outline-hidden outline-transparent",
                    input: "outline-none",
                  }}
                />
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

const ReservationCheckinsCard = ({
  showPaymentBreakdownDetails = false,
  showInputsDate = false,
  showBookingCard = false,
  showExtraSelectCard = false,
  buttonText = "Réserver",
  disabledButton = false,
  RightIcon,
  classNames,
  onClick,
  formStep1,
  loading,
  error = "",
}: {
  showPaymentBreakdownDetails?: boolean;
  showInputsDate?: boolean;
  showBookingCard?: boolean;
  showExtraSelectCard?: boolean;
  buttonText?: string;
  disabledButton?: boolean;
  RightIcon?: IconType;
  classNames?: ClassNamesType;
  onClick?: () => void;
  formStep1?: UseFormReturn<ReservationStep1Form, any, undefined>;
  loading?: boolean;
  error?: string;
}) => {
  const {t} = useTranslation();
  const {setTabGuestWebsite, dataGuestWebsite} = useGuestWebsiteStore();
  const {styles} = dataGuestWebsite || {};
  const {color_main, color_background} = styles || {};
  return (
    <div
      className="bg-white shadow-md rounded-lg p-4"
      // style={{backgroundColor:color_background}}
    >
      {showBookingCard && <BookingCard />}
      {showExtraSelectCard && <ExtraSelectBookingCard />}
      {showInputsDate && (
        <SelectReservationCard form={formStep1} isLoading={loading} />
      )}
      {showPaymentBreakdownDetails && <PriceBreakdown />}
      <div className={cn("mt-4 mx-4", classNames?.divButton)}>
        <ErrorMessage>{error}</ErrorMessage>
        <Button
          RightIcon={RightIcon}
          buttonClassName={cn(
            "flex items-center justify-center w-full bg-none text-white font-medium py-2 rounded-md gap-x-2 hover:brightness-[0.8]",
            disabledButton && "opacity-50",
            classNames?.button
          )}
          buttonStyleCss={{backgroundColor: color_main}}
          disabled={disabledButton}
          onClick={() => (onClick ? onClick() : undefined)}
          loading={loading}
          colorLoader={color_main}
        >
          {buttonText}
        </Button>
        {showExtraSelectCard && (
          <div className="flex items-center justify-center mt-2">
            <button
              className={cn(
                "underline text-low-contrast font-bold mt-2 text-xs"
              )}
              onClick={() => setTabGuestWebsite("home")}
            >
              {t("GuestWebsite.Rental.Extras.buttonAddExtras")}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReservationCheckinsCard;
