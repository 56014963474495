import React, {useState} from "react";
import {TextInputOnBlur, TextInputOnChange} from "../TextInput/TextInput.type";
import {NumberInputProps} from "./NumberInput.type";

import {Trans, useTranslation} from "react-i18next";
import InformationIcon from "../../../assets/icons/information-fill.svg?react";
import MinusIcon from "../../../assets/icons/minus.svg?react";
import PlusIcon from "../../../assets/icons/plus.svg?react";
import {cn} from "../../../helpers/classHelper";
import {Tooltip} from "../Tooltip/Tooltip";

export const NumberInput: React.FC<NumberInputProps> = ({
  register,
  required = false,
  label,
  infoTooltip,
  value,
  min,
  max,
  currency,
  Icon,
  error,
  disabled,
  size,
  placeholder,
  infoTextI18nKey,
  infoTextI18nValues,
  acceptNegative = false,
  acceptDecimal = false,
  onChangeText,
  classNames,
  showButtons = true,
  labelStyle
}) => {
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [errorMax, setErrorMax] = useState<boolean>(false);
  const [errorMin, setErrorMin] = useState<boolean>(false);

  const {t} = useTranslation();

  const getBorder = () => {
    return error || errorMax
      ? "border-element-border-error"
      : `
      border-element-border
      hover:border-element-border-hover`;
  };

  const getOutline = () => {
    if (isFocus && (error || errorMax)) {
      return "outline outline-2 outline-error/50";
    } else if (isFocus && !(error || errorMax)) {
      return "outline outline-2 outline-focus";
    } else {
      return "";
    }
  };

  const bgColor = disabled ? "bg-element-background-disabled" : "bg-white";
  const cursor = disabled ? "cursor-not-allowed" : "cursor-text";
  const cursorButton = disabled ? "cursor-not-allowed" : "cursor-pointer";
  const textColor = disabled ? "text-low-contrast " : "text-high-contrast ";
  const textSize = size === "large" ? "text-sm" : "text-sm";
  const height = size === "large" ? "h-9" : "h-8";
  const otherWidth = size === "large" ? "w-9" : "w-8";

  const handleBlur: TextInputOnBlur = (e) => {
    setIsFocus(false);
  };

  const sendChange = (nextValue: number | string) => {
    if (typeof onChangeText === "function") {
      onChangeText(nextValue ?? "");
    }

    register?.onChange({target: {name: register.name, value: nextValue}});
  };

  const cleanMinMax = (nextValue: number) => {
    let result = nextValue;

    if (min !== undefined && nextValue < min) result = min;
    if (max !== undefined && nextValue > max) result = max;

    return result;
  };

  const handleChange: TextInputOnChange = (e) => {
    setErrorMax(false);
    setErrorMin(false);
    let {value} = e.currentTarget;

    value = value.replace(/[^0-9.,-]/g, "");

    if (value === "") {
      sendChange(min ?? "");
    } else if (acceptDecimal && value.charAt(value.length - 1) === ".") {
      sendChange(value);
    } else if (acceptDecimal && value.charAt(value.length - 1) === ",") {
      sendChange(value.replace(",", "."));
    } else if (acceptDecimal && value.length === 1 && value.charAt(0) === "-") {
      sendChange(value);
    } else {
      let nextValue = parseFloat(value);

      if (!acceptNegative && nextValue < 0) nextValue = 0;

      nextValue = cleanMinMax(nextValue);

      sendChange(nextValue);
    }

    if (max) {
      if (Number(value) > max) {
        setErrorMax(true);
      }
    }

    if (min) {
      if (Number(value) < min) {
        setErrorMin(true);
      }
    }
  };

  const handleUp = () => {
    setErrorMax(false);
    setErrorMin(false);
    let nextValue = Number(value) + 1;

    if (!acceptNegative && nextValue < 0) nextValue = 0;

    nextValue = cleanMinMax(nextValue);

    if (typeof onChangeText === "function" && typeof nextValue === "number") {
      if (acceptDecimal) onChangeText(nextValue.toFixed(2));
      else onChangeText(nextValue);
    }

    register?.onChange({target: {name: register.name, value: nextValue}});
  };

  const handleDown = () => {
    setErrorMax(false);
    setErrorMin(false);
    let nextValue = Number(value) - 1;

    if (!acceptNegative && nextValue < 0) nextValue = 0;

    nextValue = cleanMinMax(nextValue);

    if (typeof onChangeText === "function" && typeof nextValue === "number") {
      if (acceptDecimal) onChangeText(nextValue.toFixed(2));
      else onChangeText(nextValue);
    }

    register?.onChange({target: {name: register.name, value: nextValue}});
  };

  return (
    <div className="flex flex-col flex-1">
      {label && (
        <div
          className={`flex space-x-1 ${
            required ? "items-start" : "items-center"
          }`}
        >
          <label
            className={cn("text-sm font-semibold text-left", classNames?.label)}
            htmlFor={register?.name ? `input-${register.name}` : undefined}
            style={labelStyle}
          >
            {label}
          </label>
          {required ? (
            <span
              className={cn(
                "relative text-lg -top-1 text-active",
                classNames?.labelRequired
              )}
            >
              *
            </span>
          ) : null}
          {infoTooltip && (
            <Tooltip Icon={InformationIcon} value={infoTooltip} />
          )}
        </div>
      )}
      <div
        className={cn(
          `
        flex flex-row items-center
        overflow-hidden
        pl-2
        ${getBorder()}
        ${bgColor}
        ${cursor}
        ${getOutline()}
        ${height}
        border-1 rounded-6px`,
          classNames?.divInput
        )}
      >
        {Icon && (
          <label
            htmlFor={register?.name ? `input-${register.name}` : undefined}
          >
            <Icon className="mr-2 text-icon-default" />
          </label>
        )}

        <input
          {...register}
          value={value ?? undefined}
          id={`input-${register?.name}`}
          className={cn(
            `flex-1
            w-12
            ${textColor}
          placeholder:text-low-contrast placeholder:font-light
            focus:outline-none
            ${bgColor}
            ${cursor}
            ${textSize}
            `,
            classNames?.input
          )}
          // style={{...styleTextColor}}
          placeholder={placeholder?.toString()}
          disabled={disabled}
          onChange={handleChange}
          onFocus={() => setIsFocus(true)}
          onBlur={handleBlur}
          type="text"
        />

        {currency ? (
          <div
            className={`flex items-center justify-center border-l-1 border-element-border/75 bg-element-background text-low-contrast font-light text-[15px] ${height} ${otherWidth} select-none cursor-default`}
            // style={{...styleTextColor}}
          >
            {currency}
          </div>
        ) : null}

        {showButtons ? (
          <>
            <button
              className={`flex items-center justify-center border-l-1 border-element-border cursor-pointer bg-gradient-to-b from-button-secondary-default-top to-button-secondary-default-bottom hover:to-element-border-light/50 active:from-element-border-light/50 ${height} ${otherWidth} ${cursorButton}`}
              onClick={handleDown}
              disabled={disabled}
              type="button"
            >
              <MinusIcon className="w-4 h-4" />
            </button>
            <button
              className={`flex items-center justify-center border-l-1 border-element-border cursor-pointer bg-gradient-to-b from-button-secondary-default-top to-button-secondary-default-bottom hover:to-element-border-light/50 active:from-element-border-light/50 ${height} ${otherWidth} ${cursorButton}`}
              onClick={handleUp}
              disabled={disabled}
              type="button"
            >
              <PlusIcon className="w-4 h-4" />
            </button>{" "}
          </>
        ) : null}
      </div>

      {infoTextI18nKey && infoTextI18nValues ? (
        <p className="mt-2 text-low-contrast">
          <Trans i18nKey={infoTextI18nKey} values={infoTextI18nValues} />
        </p>
      ) : null}

      {error && (
        <p
          className={cn(
            "mt-1 text-sm font-light text-left text-error",
            classNames?.error
          )}
        >
          {error}
        </p>
      )}

      {!error && errorMax && (
        <>
          <p
            className={cn(
              "mt-1 text-sm font-light text-left text-error",
              classNames?.error
            )}
          >
            {t("Global.errorMax", {
              max: max,
            })}
          </p>
        </>
      )}

      {!error && errorMin && (
        <>
          <p
            className={cn(
              "mt-1 text-sm font-light text-left text-error",
              classNames?.error
            )}
          >
            {t("Global.errorMin", {
              min: min,
            })}
          </p>
        </>
      )}
    </div>
  );
};
