import React, {useEffect, useRef, useState} from "react";
import {MainLayout} from "../../components/Layout/MainLayout/MainLayout";
import {useTranslation} from "react-i18next";
import ChevronRightIcon from "../../assets/icons/chevron-right.svg?react";
import {useGetRentals} from "../../hooks/useGetRentals";
import Header from "../../components/Analytics/Header";
import {ValueType} from "../../types/commonTypes";
import {CalendarInputValue} from "../../components/Common/CalendarInput/CalendarInput.type";
import {DateRangePreset} from "../../components/Common/DateFilter/DateFilter.type";
import {CalendarDay} from "../CalendarList/CalendarListPage.type";
import ObjectConnectedComponent from "../../components/Analytics/ObjectConnected/ObjectConnectedComponent";
import {WorkInProgress} from "../../components/WorkInProgress/WorkInProgress";

const AnalyticsObjectConnectedPage = () => {
  const {t} = useTranslation();

  const {currentRentals, rentalGroups, setCurrentRentals} = useGetRentals();

  const [currentRental, setCurrentRental] = useState(1);
  const [currentPeriod, setCurrentPeriod] = useState("monthly");
  const [exportVisible, setExportVisible] = useState(false);
  const [selectedRentals, setSelectedRentals] = useState<ValueType[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [dates, setDates] = useState<CalendarInputValue>([null, null]);
  const [dateRangePreset, setDateRangePreset] =
    useState<DateRangePreset>("next_seven_days");
  const [nbRangeDate, setNbRangeDate] = useState<number>(0);
  const [days, setDays] = useState<CalendarDay | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>();

  const handleChangeRental = () => {};

  const getTitle = () => {
    return (
      <p className="flex flex-row items-center gap-1">
        {t("Analytics.title")} <ChevronRightIcon />
        {t("Analytics.objectConnected")}
      </p>
    );
  };

  return (
    <>
      <MainLayout
        title={getTitle()}
        sidebarActiveItem="analytics"
        sidebarActiveSubItem="object_connected"
      >
        {/* <Header
          page="object_connected"
          rentals={[
            {
              label: `${t("Dashboard.today")}`,
              value: "daily",
            },
            {
              label: `${t("Dashboard.thisWeek")}`,
              value: "weekly",
            },
            {
              label: `${t("Dashboard.thisMonth")}`,
              value: "monthly",
            },
            {
              label: `${t("Dashboard.thisYear")}`,
              value: "yearly",
            },
          ]}
          currentRental={currentRental}
          dateRangePreset={dateRangePreset}
          setDateRangePreset={setDateRangePreset}
          dates={dates}
          setDates={setDates}
          setNbRangeDate={setNbRangeDate}
          setLoading={setLoading}
          setError={setError}
          setDays={setDays}
          handleChangeRental={handleChangeRental}
        />

        <ObjectConnectedComponent /> */}

        <WorkInProgress />
      </MainLayout>
    </>
  );
};

export default AnalyticsObjectConnectedPage;
