import React from "react";
import FileIcon from "../../../assets/icons/file.svg?react";
import CopyIcon from "../../../assets/icons/copy.svg?react";
import EyeIcon from "../../../assets/icons/eye.svg?react";
import SendIcon from "../../../assets/icons/send.svg?react";
import EditIcon from "../../../assets/icons/edit.svg?react";
import DownloadIcon from "../../../assets/icons/download.svg?react";
import TrashIcon from "../../../assets/icons/trash.svg?react";
import LinkIcon from "../../../assets/icons/link.svg?react";
import {useTranslation} from "react-i18next";
import {CardDocsProps} from "./CardDocs.type";
import {SimpleRadio} from "../SimpleRadio/SimpleRadio";
import {downloadFile} from "../../../helpers/fileHelper";
import {
  ContractResponse,
  ContractsResponse,
  DocsResponse,
} from "../../../types/GETTypes";
import {cn} from "../../../helpers/classHelper";
import {getContractStatusBadge} from "../../../helpers/contractHelper";
import moment from "moment";
import {Link} from "react-router-dom";

const CardDocs = ({
  showRadio,
  showLink,
  handleLink,
  modalEdit,
  resource,
  defaultResource,
  handleChangeDefault,
  handleDuplicate,
  showDuplicate = false,
  handleDisplay,
  showDisplay = false,
  handleSend,
  showSend = false,
  handleDelete,
  showDelete = false,
  handleEdit,
  showEdit = false,
  showDownload = false,
  showBadge = false,
  classNames,
  from,
  type,
}: CardDocsProps) => {
  const {t} = useTranslation();
  const isDefault = defaultResource === resource?.id;

  const handleDownload = async () => {
    if (resource) {
      const url =
        "pdf_url" in resource
          ? resource.pdf_url
          : "download_url" in resource
          ? resource.download_url
          : "";
      downloadFile({original_url: url, name: resource.name});
    }
  };

  const isDownloable = showDownload
    ? "pdf_url" in resource
      ? resource.pdf_url
      : "original_url" in resource
      ? `${resource.original_url}?view=true`
      : false
    : false;

  const formattedUpdatedAt =
    from === "rental"
      ? resource?.updated_at
        ? moment(resource?.updated_at).format(
            `[${t(
              "Rental.Contracts.Contracts.CardDocs.updatedAt"
            )}] DD/MM/YYYY [${t(
              "Rental.Contracts.Contracts.CardDocs.at"
            )}] HH[h]mm`
          )
        : ""
      : "";

  const formattedImportedAt =
    from === "reservation" && type === "doc"
      ? resource?.created_at
        ? moment(resource?.created_at).format(
            `[${t(
              "Rental.Contracts.Contracts.CardDocs.importedAt"
            )}] DD/MM/YYYY [${t(
              "Rental.Contracts.Contracts.CardDocs.at"
            )}] HH[h]mm`
          )
        : ""
      : "";

  const info =
    from === "reservation" && type === "doc"
      ? formattedImportedAt
      : formattedUpdatedAt;

  return (
    <div
      className={cn(
        "p-4 mt-4 rounded-md bg-element-background border-1 border-element-border space-y-2",
        classNames?.divParent
      )}
    >
      <div className="flex flex-row items-center space-x-3 justify-between">
        <div
          className={cn("flex w-[65%]", handleDisplay ? "cursor-pointer" : "")}
          onClick={() => (handleDisplay ? handleDisplay(resource) : undefined)}
        >
          <div className="flex items-center space-x-3">
            {showRadio && (
              <div className="flex flex-row items-center space-x-3">
                <div className="">
                  <SimpleRadio
                    value={isDefault}
                    onClick={() =>
                      handleChangeDefault &&
                      handleChangeDefault(resource as ContractResponse)
                    }
                  />
                </div>
              </div>
            )}
            <div className="">
              <FileIcon />
            </div>
            <div className="flex flex-col">
              <span className="font-bold text-high-contrast">
                {resource.name}
              </span>
              <span className="text-low-contrast">{info}</span>
            </div>
          </div>
        </div>
        <div className="flex space-x-1 items-center">
          {showBadge && getContractStatusBadge(resource as ContractResponse, t)}
          {showRadio && (
            <p
              className={cn(
                `text-high-contrast select-none tracking-wide mr-2`,
                isDefault ? "text-active" : ""
              )}
            >
              {isDefault
                ? t("Rental.Contracts.Contracts.CardDocs.radioSelect")
                : ""}
            </p>
          )}
          {showDisplay && (
            <EyeIcon
              onClick={() => handleDisplay && handleDisplay(resource)}
              className="cursor-pointer"
            />
          )}
          {showSend && (
            <SendIcon
              onClick={() => handleSend && handleSend(resource)}
              className="cursor-pointer"
            />
          )}
          {showDelete && (
            <TrashIcon
              onClick={() => handleDelete && handleDelete(resource)}
              className="cursor-pointer"
            />
          )}
          {showEdit && (
            <EditIcon
              onClick={() => handleEdit && handleEdit(resource)}
              className="cursor-pointer"
            />
          )}
          {showDuplicate && (
            <CopyIcon
              onClick={() => handleDuplicate && handleDuplicate(resource)}
              className="cursor-pointer"
            />
          )}
          {showLink && (
            <LinkIcon
              className="cursor-pointer"
              onClick={() => handleLink && handleLink(resource)}
            />
          )}
          {isDownloable && (
            <DownloadIcon
              onClick={() => handleDownload && handleDownload()}
              className="cursor-pointer"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CardDocs;
