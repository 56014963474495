/**
 * Payment Listing Item.
 * I did not use the TableList component because the
 * requirements for this feature were very specific.
 * I couldn't figure out how to achieve it with TableList.
 */

import { Badge } from "../../Common/Badge/Badge";
import React, { useState } from "react";
import ArrowDown from "../../../assets/icons/small-arrow-down.svg?react";
import ArrowUp from "../../../assets/icons/small-arrow-up.svg?react";
import LineIcon from "../../../assets/icons/line.svg?react";
import EditIcon from "../../../assets/icons/edit.svg?react";
import TrashIcon from "../../../assets/icons/trash.svg?react";
import { useTranslation } from "react-i18next";
import useFormattedMonthYear from "../../../hooks/useDate";

import { PaymentListItemProps, PaymentType } from "../Payment.type";
import { BadgeStatus } from "../../Common/Badge/Badge.type";

export const PaymentListItem = ({
  operatorName,
  onEditAll,
  onEditOne,
  onDelete,
  data,
  date,
}: PaymentListItemProps) => {
  const [visibility, setVisibility] = useState<{ [key: string]: boolean }>({});

  const { t } = useTranslation();
  const { formatMonthYear, formatDateNumber } = useFormattedMonthYear();

  // TODO : add photo in summary object in data (backend)
  const profilPhoto = "https://cdn-icons-png.flaticon.com/512/3135/3135715.png";

  const toggleTableVisibility = (operatorName: string) => {
    setVisibility((prevState) => ({
      ...prevState,
      [operatorName]: !prevState[operatorName],
    }));
  };

  const getStatusBadgeByPaymentType = (status: PaymentType): BadgeStatus => {
    switch (status) {
      case "PAID":
        return "success";
      case "PENDING":
        return "fail";
      case "INVOICE_RECEIVED":
        return "pending";
    }
  };

  const getLabelBadgeByPaymentType = (status: PaymentType): BadgeStatus => {
    switch (status) {
      case "PAID":
        return t("Management.Payments.paid");
      case "PENDING":
        return t("Management.Payments.toPaid");
      case "INVOICE_RECEIVED":
        return t("Management.Payments.invoiceReceived");
    }
  };

  return (
    <div
      className="flex flex-col bg-gray-50 border rounded-xl mb-4"
      key={operatorName}
    >
      <header
        onClick={() => toggleTableVisibility(operatorName)}
        className="flex items-center justify-between p-2 px-4 cursor-pointer"
      >
        <div className="flex items-center gap-2">
          <img
            src={profilPhoto}
            className="w-10 h-10 rounded-full"
            alt="Operator Profile"
          />
          <p className="font-bold">{operatorName}</p>
          <p className={"p-1 px-4  bg-gray-200 rounded ml-2 text-gray-500"}>
            {formatMonthYear(date)}
          </p>
        </div>
        <div>
          {!visibility[operatorName] ? (
            <ArrowUp className="w-4 h-4" />
          ) : (
            <ArrowDown className="w-4 h-4" />
          )}
        </div>
      </header>

      {!visibility[operatorName] && (
        <table className="min-w-full table-auto bg-white rounded-md">
          <thead>
            <tr className="bg-gray-100 border-b">
              <th className="px-4 py-2 text-left text-sm font-semibold">
                <div className={"flex items-center gap-2"}>Nom</div>
              </th>
              <th className="px-4 py-2 text-left text-sm font-semibold">
                <div className={"flex items-center gap-2"}>
                  Couchages changées
                </div>
              </th>
              <th className="px-4 py-2 text-left text-sm font-semibold">
                <div className={"flex items-center gap-2"}>Montant</div>
              </th>
              <th className="px-4 py-2 text-left text-sm font-semibold">
                <div className={"flex items-center gap-2"}>Date</div>
              </th>
              <th className="px-4 py-2 text-left text-sm font-semibold">
                <div className={"flex items-center gap-2"}>Statut</div>
              </th>
              <th className="px-4 py-2 text-left text-sm font-semibold"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="px-4 py-5 font-bold text-lg">
                Montant total des prestations
              </td>
              <td className="px-4 py-5 font-bold text-lg">
                {data.summary.total_bed_changed_count}
              </td>
              <td className="px-4 py-5 font-bold text-lg">
                {data.summary.total_amount}€
              </td>
              <td className="px-4 py-5 font-bold text-gray-500 text-lg">
                {formatMonthYear(date)}
              </td>
              <td className="px-4 py-5 font-bold text-lg">
                <Badge
                  size={"large"}
                  status={getStatusBadgeByPaymentType(data.summary.status)}
                  label={getLabelBadgeByPaymentType(data.summary.status)}
                />
              </td>
              <td className="px-4 py-8 font-bold">
                <div className={"flex items-center gap-2"}>
                  <EditIcon
                    className={"cursor-pointer"}
                    onClick={() => {
                      onEditAll(operatorName, data, date);
                    }}
                  />
                </div>
              </td>
            </tr>

            {data.day_payments.map((payment) => (
              <tr className={"border-t"} key={payment.id}>
                <td className="px-4 py-2 font-bold">
                  <div className={"flex items-center gap-6 text-gray-500"}>
                    <LineIcon className={"w-2 h-2"} /> {payment.name}
                  </div>
                </td>
                <td className="px-4 py-4 text-gray-500">
                  {payment.beds_changed_count}
                </td>
                <td className="px-4 py-4 text-gray-500">{payment.amount}€</td>
                <td className="px-4 py-4 text-gray-500">
                  {formatDateNumber(payment.end_date)}
                </td>
                <td className="px-4 py-4 text-gray-500"></td>
                <td className="px-4 py-4 text-gray-500">
                  <div className={"flex items-center gap-2"}>
                    <EditIcon
                      className={"cursor-pointer"}
                      onClick={() => {
                        onEditOne(operatorName, payment);
                      }}
                    />
                    <TrashIcon
                      className={"cursor-pointer"}
                      onClick={() => {
                        onDelete(payment.id, payment.amount);
                      }}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};
