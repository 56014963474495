import { EditContactForm } from "../../pages/Settings/Settings.type";
import {
  GuestPageLoginDetailsResponse,
  RentalResponse,
} from "../../types/GETTypes";
import paths from "../../constants/paths";
import {
  InstructionForm,
  WifiUpdateForm,
} from "../../components/Rental/TravelerPage/Instructions/InstructionCard.type";
import { del, post, get } from "../../helpers/APIHelper";
import { ValueType } from "../../types/commonTypes";

export const useGuestPage = () => {
  const getPage = async (
    guestPageId: string
  ): Promise<GuestPageLoginDetailsResponse> => {
    const res = await get(
      `${import.meta.env.VITE_API_URL}${
        paths.API.GUEST_PAGE.GET_SCREEN_DETAILS
      }/${guestPageId}`
    );

    if (res?.data?.success) {
      return res.data.result;
    } else {
      throw new Error(res?.data?.message ?? "An unexpected error occurred");
    }
  };

  return {
    getPage,
  };
};

export const useUpdateContact = async (
  rentalId: ValueType | undefined,
  guestPagelId: number | undefined,
  contacts: EditContactForm,
  onSuccess: (rental: RentalResponse) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  try {
    if (typeof onStart === "function") {
      onStart();
    }

    const jsonContacts = contacts.contacts.map((contact) =>
      JSON.stringify(contact)
    );

    const jsonContactsExtras = contacts.contacts_extras.map((contact) =>
      JSON.stringify(contact)
    );

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS_UPDATE}/${rentalId}${
        paths.API.GUEST_PAGE.INDEX
      }/${guestPagelId}/contacts`,
      {
        contacts: jsonContacts,
        contacts_extras: jsonContactsExtras,
      }
    );

    if (res?.data.success) {
      const data: RentalResponse = res?.data?.result;

      if (data) {
        console.log(data, "[onSuccess]");
        onSuccess(data);
      }
    } else {
      console.log(res?.data.message, "[onSuccess]");
      onError(res?.data.message ?? res.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

export const useUpdateInstruction = async (
  rentalId: ValueType | undefined,
  guestPageInstructionId: number | undefined,
  instruction: InstructionForm,
  onSuccess: (rental: RentalResponse) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  try {
    if (typeof onStart === "function") {
      onStart();
    }

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS_UPDATE}/${rentalId}${
        paths.API.GUEST_PAGE.INDEX
      }${paths.API.GUEST_INSTRUCTION}/${guestPageInstructionId}`,
      { ...instruction }
    );

    if (res?.data.success) {
      const data: RentalResponse = res?.data?.result;

      if (data) {
        console.log(data, "[onSuccess]");
        onSuccess(data);
      }
    } else {
      console.log(res?.data.message, "[onSuccess]");
      onError(res?.data.message ?? res.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

export const useDeleteContact = async (
  rentalId: ValueType | undefined,
  contactId: number | undefined,
  onSuccess: (rental: RentalResponse) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  try {
    if (typeof onStart === "function") {
      onStart();
    }

    const res = await del(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS_UPDATE}/${rentalId}${
        paths.API.GUEST_PAGE_CONTACTS_DELETE
      }/${contactId}`
    );

    if (res?.data.success) {
      const data: RentalResponse = res?.data?.result;

      if (data) {
        console.log(data, "[onSuccess]");
        onSuccess(data);
      }
    } else {
      console.log(res?.data.message, "[onSuccess]");
      onError(res?.data.message ?? res.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

export const useEnableInformations = async (
  rentalId: ValueType | undefined,
  onSuccess: (rental: RentalResponse) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  try {
    if (typeof onStart === "function") {
      onStart();
    }

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS_UPDATE}/${rentalId}${
        paths.API.GUEST_PAGE_ENABLE_INFORMATIONS
      }`
    );

    if (res?.data.success) {
      const data: RentalResponse = res?.data?.result;

      if (data) {
        console.log(data, "[onSuccess]");
        onSuccess(data);
      }
    } else {
      console.log(res?.data.message, "[onSuccess]");
      onError(res?.data.message ?? res.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

export const useDisableInformations = async (
  rentalId: ValueType | undefined,
  onSuccess: (rental: RentalResponse) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  try {
    if (typeof onStart === "function") {
      onStart();
    }

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS_UPDATE}/${rentalId}${
        paths.API.GUEST_PAGE_DISABLE_INFORMATIONS
      }`
    );

    if (res?.data.success) {
      const data: RentalResponse = res?.data?.result;

      if (data) {
        console.log(data, "[onSuccess]");
        onSuccess(data);
      }
    } else {
      console.log(res?.data.message, "[onSuccess]");
      onError(res?.data.message ?? res.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

export const useDisableReviews = async (
  rentalId: ValueType | undefined,
  onSuccess: (rental: RentalResponse) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  try {
    if (typeof onStart === "function") {
      onStart();
    }

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS_UPDATE}/${rentalId}${
        paths.API.GUEST_PAGE_DISABLE_REVIEWS
      }`
    );

    if (res?.data.success) {
      const data: RentalResponse = res?.data?.result;

      if (data) {
        console.log(data, "[onSuccess]");
        onSuccess(data);
      }
    } else {
      console.log(res?.data.message, "[onSuccess]");
      onError(res?.data.message ?? res.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

export const useEnableReviews = async (
  rentalId: ValueType | undefined,
  onSuccess: (rental: RentalResponse) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  try {
    if (typeof onStart === "function") {
      onStart();
    }

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS_UPDATE}/${rentalId}${
        paths.API.GUEST_PAGE_ENABLE_REVIEWS
      }`
    );

    if (res?.data.success) {
      const data: RentalResponse = res?.data?.result;

      if (data) {
        console.log(data, "[onSuccess]");
        onSuccess(data);
      }
    } else {
      console.log(res?.data.message, "[onSuccess]");
      onError(res?.data.message ?? res.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

export const useUpdateWifi = async (
  rentalId: ValueType | undefined,
  data: WifiUpdateForm,
  onSuccess: (rental: RentalResponse) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  try {
    if (typeof onStart === "function") {
      onStart();
    }
    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS_UPDATE}/${rentalId}${
        paths.API.GUEST_PAGE_UPDATE_WIFI
      }`,
      data
    );
    if (res?.data.success) {
      const data: RentalResponse = res?.data?.result;

      if (data) {
        onSuccess(data);
      }
    } else {
      onError(res?.data?.message ?? res.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};
export const useUpdateEnableOrDisableContract = async (
  rentalId: number | undefined,
  data: { status: boolean },
  onSuccess: (rental: RentalResponse) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  try {
    if (typeof onStart === "function") {
      onStart();
    }
    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS_UPDATE}/${rentalId}${
        paths.API.GUEST_PAGE_ENABLE_OR_DISABLE_CONTRACT
      }`,
      data
    );
    if (res?.data.success) {
      const data: RentalResponse = res?.data?.result;

      if (data) {
        onSuccess(data);
      }
    } else {
      onError(res?.data?.message ?? res.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

export const useUpdateFormGuestPage = async (
  rentalId: number | undefined,
  data: any,
  onSuccess: (rental: RentalResponse) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  try {
    if (typeof onStart === "function") {
      onStart();
    }

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS_UPDATE}/${rentalId}${
        paths.API.GUEST_PAGE_FORM
      }`,
      data
    );

    if (res?.data.success) {
      const data: RentalResponse = res?.data?.result;

      if (data) {
        console.log(data, "[onSuccess]");
        onSuccess(data);
      }
    } else {
      console.log(res?.data.message, "[onSuccess]");
      onError(res?.data.message ?? res.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

export const useEnableGuestPage = async (
  rentalId: string | undefined,
  onSuccess: (rental: RentalResponse) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  try {
    if (typeof onStart === "function") {
      onStart();
    }

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS_UPDATE}/${rentalId}${
        paths.API.GUEST_PAGE_ENABLE
      }`
    );

    if (res?.data.success) {
      const data: RentalResponse = res?.data?.result;

      if (data) {
        console.log(data, "[onSuccess]");
        onSuccess(data);
      }
    } else {
      console.log(res?.data.message, "[onSuccess]");
      onError(res?.data.message ?? res.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

export const useDisableGuestPage = async (
  rentalId: string | undefined,
  onSuccess: (rental: RentalResponse) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  try {
    if (typeof onStart === "function") {
      onStart();
    }

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS_UPDATE}/${rentalId}${
        paths.API.GUEST_PAGE_DISABLE
      }`
    );

    if (res?.data.success) {
      const data: RentalResponse = res?.data?.result;

      if (data) {
        console.log(data, "[onSuccess]");
        onSuccess(data);
      }
    } else {
      console.log(res?.data.message, "[onSuccess]");
      onError(res?.data.message ?? res.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

export const useDisableExtras = async (
  rentalId: ValueType | undefined,
  onSuccess: (rental: RentalResponse) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  try {
    if (typeof onStart === "function") {
      onStart();
    }

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS_UPDATE}/${rentalId}${
        paths.API.GUEST_PAGE_DISABLE_EXTRAS
      }`
    );

    if (res?.data.success) {
      const data: RentalResponse = res?.data?.result;

      if (data) {
        console.log(data, "[onSuccess]");
        onSuccess(data);
      }
    } else {
      console.log(res?.data.message, "[onSuccess]");
      onError(res?.data.message ?? res.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

export const useEnableExtras = async (
  rentalId: ValueType | undefined,
  onSuccess: (rental: RentalResponse) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  try {
    if (typeof onStart === "function") {
      onStart();
    }

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS_UPDATE}/${rentalId}${
        paths.API.GUEST_PAGE_ENABLE_EXTRAS
      }`
    );

    if (res?.data.success) {
      const data: RentalResponse = res?.data?.result;

      if (data) {
        console.log(data, "[onSuccess]");
        onSuccess(data);
      }
    } else {
      console.log(res?.data.message, "[onSuccess]");
      onError(res?.data.message ?? res.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};
