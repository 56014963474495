import {TFunction, t} from "i18next";
import React, {
  Dispatch,
  ReactNode,
  RefObject,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import ArrowRightIcon from "../../assets/icons/arrow-right-white.svg?react";
import CheckIcon from "../../assets/icons/check-white.svg?react";
import ChevronRightIcon from "../../assets/icons/chevron-right.svg?react";
import {CarouselWizardStep} from "../../components/Common/CarouselWizard/CarouselWizard.type";
import {
  ProgressionRentalsRulesModalNextStepOtherFields,
  ProgressionRentalsRulesModalRef,
  ProgressionRentalsRulesModalStep,
} from "../../components/Progression/ProgressionRentalsRulesModal/ProgressionRentalsRulesModal.type";
import {UseTablePageReturn} from "../../hooks/useTablePage";
import {RentalLightListItemResponse} from "../../types/GETTypes";
import {IconType, TextType, ValueType} from "../../types/commonTypes";

// * -- PROGRESSION RENTALS RULES PAGE --

/**
 * This hook can be used to initialize states of ProgressionRentalsRulesPage
 * @returns
 */
export const useInitProgressionRentalsRulesPageStates = () => {
  const [validationLoading, setValidationLoading] = useState<boolean>(false);
  const [errorValidate, setErrorValidate] = useState<string | undefined>();
  const [isRulesModalVisible, setIsRulesModalVisible] =
    useState<boolean>(false);
  const [currentRental, setCurrentRental] = useState<
    RentalLightListItemResponse | undefined
  >();

  return {
    validationLoadingState: [validationLoading, setValidationLoading],
    errorValidateState: [errorValidate, setErrorValidate],
    isRulesModalVisibleState: [isRulesModalVisible, setIsRulesModalVisible],
    currentRentalState: [currentRental, setCurrentRental],
  } as const;
};

/**
 * This hook can be used to initialize ProgressionRentalsRulesPage
 * @param tablePage
 */
export const useInitProgressionRentalsRulesPage = (
  tablePage: UseTablePageReturn
) => {
  useEffect(() => {
    tablePage.fetch({});
  }, []);
};

export const useHandleSuccess = (tablePage: UseTablePageReturn) => {
  tablePage.fetch({
    include_ids: tablePage.currentRentalsId,
    limit: tablePage.currentNbPerPage,
    page: tablePage.currentPage,
    search: tablePage.currentSearch,
    sort_direction: tablePage.currentSortDirection,
    sort_field: tablePage.currentSortField,
  });
};
/**
 * This hook can be used to click on rental item
 * @param id
 * @param tablePage
 * @param setCurrentRental
 * @param setIsRulesModalVisible
 */
export const useOnRentalClick = (
  id: ValueType,
  tablePage: UseTablePageReturn,
  setCurrentRental: Dispatch<
    SetStateAction<RentalLightListItemResponse | undefined>
  >,
  setIsRulesModalVisible: Dispatch<SetStateAction<boolean>>
) => {
  setCurrentRental(tablePage.data.find((d: any) => d.id === id));
  setIsRulesModalVisible(true);
};

export const useGetTitle = (t: TFunction<"translation", undefined>) => {
  return (
    <>
      <div className="flex flex-row items-center space-x-1">
        <h1 className="m-0 text-xl text-low-contrast">
          {t("Progression.progression")}
        </h1>

        <ChevronRightIcon />

        <h1 className="pl-1 text-xl text-high-contrast">
          {t("Progression.RentalsRules.pageTitle")}
        </h1>
      </div>
    </>
  );
};

// * -- PROGRESSION RENTALS RULES MODAL --

/**
 * This hook can be used to initialize states of ProgressionRentalsRulesModal
 * @param currentRental
 * @returns
 */
export const useInitProgressionRentalsRulesModalStates = (
  currentRental: RentalLightListItemResponse | undefined
) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [currentStep, setCurrentStep] =
    useState<ProgressionRentalsRulesModalStep>(
      ProgressionRentalsRulesModalStep.INFO_STEP
    );
  const [finalStep, setFinalStep] = useState<ProgressionRentalsRulesModalStep>(
    ProgressionRentalsRulesModalStep.PAYMENT_POLICIES_STEP
  );
  const [selectedRentals, setSelectedRentals] = useState<ValueType[]>([]);
  const [countRentals, setCountRentals] = useState<number>(0);
  const [newCurrentRental, setNewCurrentRental] = useState<
    RentalLightListItemResponse | undefined
  >(currentRental);
  const [otherFields, setOtherFields] = useState<
    ProgressionRentalsRulesModalNextStepOtherFields | undefined
  >(null);

  return {
    loadingState: [loading, setLoading],
    errorState: [error, setError],
    currentStepState: [currentStep, setCurrentStep],
    finalStepState: [finalStep, setFinalStep],
    selectedRentalsState: [selectedRentals, setSelectedRentals],
    countRentalsState: [countRentals, setCountRentals],
    newCurrentRentalState: [newCurrentRental, setNewCurrentRental],
    otherFieldsState: [otherFields, setOtherFields],
  } as const;
};

/**
 * This hook can be used to initialize ProgressionRentalsRulesModal
 * @param rentals
 * @param countRentalsState
 * @param currentStepState
 * @param finalStepState
 * @param selectedRentalsState
 * @param newCurrentRentalState
 * @param setOtherFields
 * @param setLoading
 * @param setError
 * @param onSuccess
 * @param onClose
 */
export const useInitProgressionRentalsRulesModal = (
  rentals: RentalLightListItemResponse[],
  countRentalsState: [number, Dispatch<SetStateAction<number>>],
  currentStepState: [
    ProgressionRentalsRulesModalStep,
    Dispatch<SetStateAction<ProgressionRentalsRulesModalStep>>
  ],
  finalStepState: [
    ProgressionRentalsRulesModalStep,
    Dispatch<SetStateAction<ProgressionRentalsRulesModalStep>>
  ],
  selectedRentalsState: [ValueType[], Dispatch<SetStateAction<ValueType[]>>],
  newCurrentRentalState: [
    RentalLightListItemResponse | undefined,
    Dispatch<SetStateAction<RentalLightListItemResponse | undefined>>
  ],
  setOtherFields: Dispatch<
    SetStateAction<ProgressionRentalsRulesModalNextStepOtherFields | undefined>
  >,
  setLoading: Dispatch<SetStateAction<boolean>>,
  setError: Dispatch<SetStateAction<string | undefined>>,
  onSuccess: () => void,
  onClose: () => void
) => {
  const [countRentals, setCountRentals] = countRentalsState;
  const [currentStep, setCurrentStep] = currentStepState;
  const [finalStep, setFinalStep] = finalStepState;
  const [selectedRentals, setSelectedRentals] = selectedRentalsState;
  const [newCurrentRental, setNewCurrentRental] = newCurrentRentalState;

  useEffect(() => {
    if (newCurrentRental) {
      setSelectedRentals([newCurrentRental.id]);
    }
  }, []);

  useEffect(() => {
    setCountRentals(rentals.length);
  }, [rentals]);

  useEffect(() => {
    if (countRentals === 1) {
      setFinalStep(ProgressionRentalsRulesModalStep.PAYMENT_POLICIES_STEP);
      setSelectedRentals(() => rentals.map((rental) => rental.id));
    }
  }, [countRentals]);
};

/**
 * This hook can be used to select rental
 * @param id
 * @param selectedRentalsState
 */
export const useSelectRental = (
  id: ValueType,
  selectedRentalsState: [ValueType[], Dispatch<SetStateAction<ValueType[]>>]
) => {
  const [selectedRentals, setSelectedRentals] = selectedRentalsState;
  const exists = selectedRentals?.findIndex((i) => i === id) > -1;

  if (exists) {
    setSelectedRentals((prev) => [...prev.filter((i) => i !== id)]);
  } else {
    setSelectedRentals((prev) => [...prev, id]);
  }
};

/**
 * This hook takes you to the next step
 * @param onNextStep
 * @param onNextRental
 * @param onNextOtherFields
 */
export const useChangeStep = (
  onNextStep: () => void,
  onNextRental?: () => void,
  onNextOtherFields?: () => void
) => {
  if (typeof onNextRental === "function") {
    onNextRental();
  }

  if (typeof onNextOtherFields === "function") {
    onNextOtherFields();
  }

  onNextStep();
};

/**
 * This hook retrieves the desired step
 * @param title
 * @param node
 * @returns
 */
export const useStep = (title: string, node: ReactNode): CarouselWizardStep => {
  return {
    title: title,
    Node: node,
  };
};

/**
 *this hook retrieves the icon inside submit button
 * @param currentStep
 * @param finalStep
 * @returns
 */
export const useIconSubmitButton = (
  currentStep: ProgressionRentalsRulesModalStep,
  finalStep: ProgressionRentalsRulesModalStep
): IconType => {
  if (currentStep >= finalStep) {
    return CheckIcon;
  }

  return ArrowRightIcon;
};

/**
 * This hook retrieves the text inside submit button
 * @param currentStep
 * @param finalStep
 * @returns
 */
export const useTextSubmitButton = (
  currentStep: ProgressionRentalsRulesModalStep,
  finalStep: ProgressionRentalsRulesModalStep
): TextType => {
  if (currentStep >= finalStep) {
    return t("Global.save");
  }

  return t("Global.next");
};

/**
 * This hook handle the method to use depending on step
 * @param currentStep
 * @param priceRef
 * @param rulesRef
 * @param rulesMultiRef
 */
export const useHandleSubmit = (
  currentStep: ProgressionRentalsRulesModalStep,
  infoRef: RefObject<ProgressionRentalsRulesModalRef>,
  reservationRulesRef: RefObject<ProgressionRentalsRulesModalRef>,
  priceRef: RefObject<ProgressionRentalsRulesModalRef>,
  paymentPoliciesRef: RefObject<ProgressionRentalsRulesModalRef>
) => {
  if (currentStep === ProgressionRentalsRulesModalStep.INFO_STEP) {
    infoRef.current?.submit();
  }

  if (
    currentStep === ProgressionRentalsRulesModalStep.RESERVATIONS_RULES_STEP
  ) {
    reservationRulesRef.current?.submit();
  }

  if (currentStep === ProgressionRentalsRulesModalStep.PRICE_STEP) {
    priceRef.current?.submit();
  }
  if (currentStep === ProgressionRentalsRulesModalStep.PAYMENT_POLICIES_STEP) {
    paymentPoliciesRef.current?.submit();
  }
};
