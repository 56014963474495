import React from "react";
import TitleBlockComponent from "../../Common/TitleBlockComponent";
import CheckCircleIcon from "../../../../../../assets/icons/check-circle.svg?react";
import useGuestWebsiteStore from "../../../../../../stores/guestWebsiteStore/useGuestWebsiteStore";
import {getFormattedPrice} from "../../../../../../helpers/currencyHelper";
import {useTranslation} from "react-i18next";
import {PaymentReservationStatusEnum} from "../../../../../../enums/GETenums";
import {cn} from "../../../../../../helpers/classHelper";
import moment from "moment";
import {Separator} from "../../../../../Common/Separator/Separator";

const PaymentResumeCard = () => {
  const {t} = useTranslation();
  const {step2Data, dataGuestWebsite, step1Data} = useGuestWebsiteStore();
  const {tarification} = step1Data || {};
  const {payment_schedule} = step2Data || {};
  const {styles} = dataGuestWebsite || {};
  const {color_text} = styles || {};
  const {total_ttc, total_ht, total_vat, vat_percentage} = tarification || {};
  return (
    <div className="bg-white rounded-lg shadow-md p-6 space-y-4">
      {/* Header */}
      <TitleBlockComponent
        title={t("Payments.PaymentReservation.secondTitle")}
      />
      <div className="flex flex-col space-y-2">
        {payment_schedule &&
        payment_schedule?.payments_schedule_items?.length > 0
          ? payment_schedule?.payments_schedule_items?.map((v) => (
              <div
                className="flex items-center justify-between text-base"
                key={v.id}
              >
                <div className="flex items-center space-x-3">
                  {v.payment_status === PaymentReservationStatusEnum.PAID ? (
                    <CheckCircleIcon width={25} height={25} />
                  ) : null}
                  <span
                    className={cn(`font-light text-low-contrast`, {
                      "text-high-contrast":
                        moment(new Date()).toDate() <=
                        moment(v.specific_date).toDate(),
                      "text-success":
                        v.payment_status === PaymentReservationStatusEnum.PAID,
                    })}
                  >
                    {v.specific_date &&
                    moment(new Date()).toDate() ===
                      moment(v.specific_date).toDate()
                      ? `${t("Global.today")}${
                          v.percentage_value
                            ? ", " + v.percentage_value + " %"
                            : ""
                        }`
                      : `${t("Payments.PaymentReservation.onCustomDate", {
                          date: moment(v.specific_date).format("DD/MM/YYYY"),
                        })}${
                          v.percentage_value
                            ? ", " + v.percentage_value + " %"
                            : ""
                        }`}
                  </span>
                </div>

                <div className="flex items-center space-x-3">
                  {/* TODO : [PaymentReservation] - Implement real value */}
                  <span
                    className={cn(`font-light`, {
                      "text-success":
                        v.payment_status === PaymentReservationStatusEnum.PAID,
                    })}
                  >
                    {t("Payments.PaymentReservation.byCb")}
                  </span>
                  <span
                    className={cn(`font-bold text-low-contrast`, {
                      "text-high-contrast":
                        moment(new Date()).toDate() <=
                        moment(v.specific_date).toDate(),
                      "text-success":
                        v.payment_status === PaymentReservationStatusEnum.PAID,
                    })}
                  >
                    {getFormattedPrice({
                      price: v.price_value,
                      decimals: 2,
                      locale: "fr-FR",
                      currency: "EUR",
                    })}{" "}
                    TTC
                  </span>
                </div>
              </div>
            ))
          : null}
      </div>

      <Separator accent="dark" />

      <div className="flex items-center justify-between">
        <p className="text-lg " style={{color: color_text}}>
          {t("Global.total")} {t("GuestWebsite.ht")}
        </p>
        <p className="text-lg font-bold" style={{color: color_text}}>
          {getFormattedPrice({price: total_ht ?? 0, decimals: 2})}{" "}
        </p>
      </div>
      <div className="flex items-center justify-between">
        <p className="text-lg  " style={{color: color_text}}>
          {t("GuestWebsite.tva")} {vat_percentage ?? 0} %
        </p>
        <p className="text-lg   font-bold" style={{color: color_text}}>
          {getFormattedPrice({price: total_vat ?? 0, decimals: 2})}
        </p>
      </div>
      <div className="flex items-center justify-between">
        <p className="text-lg uppercase" style={{color: color_text}}>
          {t("Global.total")} {t("GuestWebsite.ttc")}
        </p>
        <p className="text-lg font-bold" style={{color: color_text}}>
          {getFormattedPrice({price: total_ttc ?? 0, decimals: 2})}
        </p>
      </div>
    </div>
  );
};

export default PaymentResumeCard;
