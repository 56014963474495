import React, {useEffect, useState} from "react";
import {Card} from "../../Common/Card/Card";
import CartIcon from "../../../assets/icons/cart.svg?react";
import {useTranslation} from "react-i18next";
import {ReservationExtrasOrderProps} from "./ReservationExtrasCard.type";
import ExtraOrderCard from "./ExtraOrderCard";
import {GuestPageExtraOrder} from "../../../types/GETTypes";
import ExtraOrderModal from "./Modal/ExtraOrderModal";

const ReservationExtrasCard: React.FC<ReservationExtrasOrderProps> = ({
  reservation: resa,
  disabled,
  onUpdate,
}) => {
  const {t} = useTranslation();
  const [reservation, setReservation] = useState(resa);
  const {extra_orders} = reservation || {};
  const [extraOrders, setExtraOrders] = useState(extra_orders);
  const [extraOrderModal, setExtraOrderModal] = useState<
    GuestPageExtraOrder | undefined
  >();
  const [openExtraOrderModal, setOpenExtraOrderModal] = useState(false);

  useEffect(() => {
    setExtraOrders(extra_orders);
  }, [extra_orders, reservation]);

  useEffect(() => {
    setReservation(resa);
  }, [resa]);

  useEffect(() => {}, [reservation]);

  const openModal = (extraOrder: GuestPageExtraOrder) => {
    setExtraOrderModal(extraOrder);
    setOpenExtraOrderModal(true);
  };

  const closeModal = () => {
    setExtraOrderModal(undefined);
    setOpenExtraOrderModal(false);
  };

  const onSuccess = (result: any) => {
    setReservation((res) => {
      if (res) {
        return {
          ...res,
          extra_orders: res?.extra_orders
            ? res?.extra_orders?.map((extraOrder) =>
                extraOrder.id === result?.payment_extras_order?.id
                  ? result?.payment_extras_order
                  : extraOrder
              )
            : [],
        };
      }
    });

    setTimeout(() => {
      if (reservation?.extra_orders) {
        onUpdate(reservation.extra_orders);
      }
    }, 500);
  };

  return (
    <>
      <ExtraOrderModal
        isVisible={openExtraOrderModal}
        closeModal={closeModal}
        extraOrderModal={extraOrderModal}
        reservation={reservation}
        page="details"
        onSuccess={onSuccess}
      />
      <Card
        Icon={CartIcon}
        label={t("Booking.ExtrasOrder.title")}
        anchor="extras-card"
      >
        <div className="flex flex-col space-y-2">
          {extraOrders && extraOrders?.length > 0 ? (
            extraOrders?.map((extraOrder) => (
              <ExtraOrderCard
                extraOrder={extraOrder}
                openModal={openModal}
                disabled={disabled}
              />
            ))
          ) : (
            <span className="text-low-contrast text-center">
              {t("Booking.ExtrasOrder.noExtras")}
            </span>
          )}
        </div>
      </Card>
    </>
  );
};

export default ReservationExtrasCard;
