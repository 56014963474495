import React, { ReactElement, useMemo, useState } from "react";

import TaskNotAssigned from "./TaskNotAssigned";
import TaskAssigned from "./TaskAssigned";

import { FlexCenterBetween } from "../../Common/FlexComponents/FlexCenterBetween";
import { FlexItemCenter } from "../../Common/FlexComponents/FlexItemCenter";
import { HelpText } from "../../Common/TextComponents/HelpText";
import { Button } from "../../Common/Button/Button";
import {
  CalendarReservationResponse,
  ReservationResponse,
  TaskPriorityType,
  TaskStatus,
} from "../../../types/GETTypes";
import { useTasks } from "../../../hooks/api/automation";
import { useModal, UseModal } from "../../../hooks/useModal";
import { useTranslation } from "react-i18next";

import BlockedIcon from "../../../assets/icons/blocked.svg?react";
import PlusIcon from "../../../assets/icons/plus.svg?react";
import CheckIcon from "../../../assets/icons/check-white.svg?react";
import CancelIcon from "../../../assets/icons/close-circle.svg?react";

import { useCheckAuth } from "../../../hooks/useCheckAuth";
import ModalChoice from "../../Common/ModalChoice/ModalChoice";
import useText from "../../../hooks/useText";
import { FlexCol } from "../../Common/FlexComponents/FlexCol";
import { Badge } from "../../Common/Badge/Badge";
import { ReservationForCalendarList } from "../../../pages/Booking/BookingPage.type";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";

export default function CleaningSection({
  reservation,
  modalAssign,
  reloadCalendarList,
  setError,
  isCanceled,
  lightComponent = false,
  customNotAssignedText,
}: {
  reservation:
    | CalendarReservationResponse
    | ReservationResponse
    | ReservationForCalendarList;
  modalAssign?: UseModal<{
    reservationId: string;
    reloadCalendarList?: Function;
    taskType: TaskPriorityType;
  }>;
  reloadCalendarList?: Function;
  setError?: Function;
  isCanceled: boolean;
  lightComponent?: boolean;
  customNotAssignedText?: string;
}): ReactElement {
  const [loading, setLoading] = useState(false);
  const [localError, setLocalError] = useState();

  const { t } = useTranslation();
  const { getText } = useText("Calendar.Reservation");
  const { removeAssignTask, denyTask, assign } = useTasks();
  const { user } = useCheckAuth();
  const modalDenyTask = useModal();

  const cleaningIsNotAssigned = useMemo(() => {
    return reservation.priority_tasks.cleaning?.status === "NOT_ASSIGNED";
  }, [reservation.priority_tasks.cleaning?.status]);

  const hasCleaningCreated = useMemo(() => {
    return reservation.priority_tasks.cleaning?.status !== "NOT_CREATED";
  }, [reservation.priority_tasks.cleaning?.status]);

  const hasCleaningAssigned = (): boolean => {
    const statusAssigned: TaskStatus[] = [
      "ASSIGNED",
      "STARTED",
      "PARTIALLY_COMPLETED",
      "COMPLETED",
      "CANCELED",
    ];
    return statusAssigned.includes(reservation.priority_tasks.cleaning?.status);
  };

  const handleClickAssignOperators = (): void => {
    modalAssign?.open({
      reservationId: reservation.id as string,
      reloadCalendarList: reloadCalendarList,
      taskType: "CLEANING",
    });
  };

  const handleRemoveTask = (): void => {
    const reservationId = reservation.id;
    setLoading(true);
    removeAssignTask(reservationId, "CLEANING")
      .then((reservationResponse) => {
        reservation.priority_tasks = reservationResponse.priority_tasks;
        if (typeof reloadCalendarList === "function") {
          reloadCalendarList();
        }
      })
      .catch((error) => {
        if (typeof setError === "function") {
          setError(error?.message);
        } else {
          setLocalError(error?.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDenyTask = async () => {
    try {
      const userId = user?.id;
      await denyTask(reservation.priority_tasks.cleaning.task_id);

      reservation.priority_tasks.cleaning.operators_tasks.map(
        (operatorTask) => {
          if (operatorTask.operator_id === userId) {
            operatorTask.status = "DECLINED";
          }
        },
      );
    } catch (e: any) {
      if (typeof setError === "function") {
        setError(e.message);
      } else {
        setLocalError(e?.message);
      }
    }
  };

  const handleAcceptTask = () => {
    setLoading(true);
    assign(reservation.id as string, "OPERATORS", String(user?.id), "CLEANING")
      .then((reservationResponse) => {
        reservation.priority_tasks = reservationResponse.priority_tasks;
      })
      .catch((error: any) => {
        if (typeof setError === "function") {
          setError(error.message);
        } else {
          setLocalError(error?.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <ModalChoice
        modal={modalDenyTask}
        title={getText("denyTaskTitle")}
        description={getText("denyTaskDescription")}
        cancelTextButon={getText("cancel")}
        applyTextButton={getText("yesDeny")}
        onApply={handleDenyTask}
        IconButtonApply={CheckIcon}
      />
      <div className={"mt-5"}>
        {localError && (
          <ErrorMessage
            showCloseButton={true}
            onClickCloseButton={() => setLocalError(undefined)}
          >
            {localError}
          </ErrorMessage>
        )}
        <div className={"flex items-end justify-between"}>
          <FlexCol gap={0} className={hasCleaningAssigned() ? "w-full" : ""}>
            {!lightComponent ? (
              <HelpText className="mb-2">
                {t("Calendar.Reservation.cleaning")}
              </HelpText>
            ) : null}
            {isCanceled ? (
              <Badge
                Icon={CancelIcon}
                label={getText("cancel")}
                status={"basic"}
              />
            ) : null}
            {!isCanceled ? (
              <>
                {hasCleaningCreated && cleaningIsNotAssigned && (
                  <TaskNotAssigned
                    reservation={reservation}
                    onDeny={() => modalDenyTask.open({})}
                    onAccept={handleAcceptTask}
                    loading={loading}
                    taskType={"CLEANING"}
                    lightComponent={lightComponent}
                    customNotAssignedText={customNotAssignedText}
                  />
                )}
                {hasCleaningAssigned() && (
                  <TaskAssigned
                    loading={loading}
                    handleRemoveTask={handleRemoveTask}
                    reservation={reservation}
                    taskType={"CLEANING"}
                    lightComponent={lightComponent}
                  />
                )}
                {!hasCleaningCreated && !lightComponent && (
                  <FlexCenterBetween gap={10}>
                    <FlexItemCenter
                      gap={5}
                      className={"p-1 rounded bg-gray-200"}
                    >
                      <BlockedIcon />
                      <HelpText className={"text-md"}>
                        {t("Calendar.Reservation.notProgramedCleaning")}
                      </HelpText>
                    </FlexItemCenter>
                    {modalAssign ? (
                      <Button
                        onClick={handleClickAssignOperators}
                        RightIcon={PlusIcon}
                        type={"secondary"}
                        size={"small"}
                      />
                    ) : null}
                  </FlexCenterBetween>
                )}
              </>
            ) : null}
          </FlexCol>
        </div>
      </div>
    </>
  );
}
