import React from "react";

import { IconType } from "../../../types/commonTypes";
import { Button } from "../../Common/Button/Button";

import EditIcon from "../../../assets/icons/edit.svg?react";
import RefreshIcon from "../../../assets/icons/refresh.svg?react";

import { useTranslation } from "react-i18next";

export interface HeaderPolicyProps {
  Icon: IconType;
  title: string;
  edit?: boolean;
  altEditText?: string;
  onClickEdit?: () => void;
  refreshButton?: boolean;
  onRefresh?: () => void;
  refreshLoading?: boolean;
}

export default function HeaderPolicy({
  Icon,
  title,
  edit = true,
  altEditText = "",
  onClickEdit,
  refreshButton = false,
  onRefresh,
  refreshLoading = false,
}: HeaderPolicyProps) {
  const { t } = useTranslation();

  const handleClickEdit = (): void => {
    if (typeof onClickEdit === "function") {
      onClickEdit();
    }
  };

  return (
    <header className={"flex items-center justify-between w-full"}>
      <div className={"flex items-center gap-2"}>
        <Icon className={"w-5 h-5"} />
        <p className={"text-md font-bold"}>{title}</p>
      </div>
      <div className={edit || refreshButton ? "" : "w-1/3"}>
        {edit ? (
          <Button
            type={"secondary"}
            RightIcon={EditIcon}
            onClick={handleClickEdit}
          />
        ) : (
          <p className={"text-gray-400 text-end"}>{altEditText}</p>
        )}
        {refreshButton && (
          <Button
            type={"secondary"}
            RightIcon={RefreshIcon}
            loading={refreshLoading}
            onClick={() => {
              if (typeof onRefresh === "function") {
                onRefresh();
              }
            }}
          >
            {t("Global.refresh")}
          </Button>
        )}
      </div>
    </header>
  );
}
