import React from "react";
import CalendarIcon from "../../../assets/icons/calendar.svg?react";
import PlusIcon from "../../../assets/icons/plus-white.svg?react";
import {Button} from "../../Common/Button/Button";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import paths from "../../../constants/paths";
import useUserStore from "../../../stores/useUserStore";

export const NoReservationList: React.FC<{}> = ({}) => {
  const userStore = useUserStore();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleAdd = () => {
    navigate(paths.RENTALS_ADD);
  };

  return (
    <div
      className="flex bg-element-background w-full rounded-6px mt-2 items-center justify-center p-4"
      style={{ height: "calc(100vh - 150px)" }}
    >
      <div className="flex flex-col items-center">
        <CalendarIcon width={56} height={56} />

        <p className="my-4 font-semibold text-xl text-high-contrast w-[60%] text-center">
          {t("ReservationList.noReservationTitle")}
        </p>


        <div className="flex mt-4 gap-4">
          <div className="w-72">
            <Button
              RightIcon={PlusIcon}
              onClick={() => navigate(paths.RESERVATIONS_ADD)}
              disabled={false}
            >
              {t("Calendar.addBooking")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
