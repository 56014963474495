import React from "react";
import {
  CALENDAR_CELL_HEIGHT,
  CALENDAR_CELL_WIDTH,
  CALENDAR_RENTAL_NAME_WIDTH,
} from "../../constants/calendar";

import ArrowLeftIcon from "../../assets/icons/arrow-left.svg?react";
import ArrowRightIcon from "../../assets/icons/arrow-right.svg?react";

export const CalendarArrowToScroll: React.FC<{
  onRight: () => void;
  onLeft: () => void;
}> = ({ onRight, onLeft }) => {
  return (
    <>
      <div
        className="bg-transparent absolute z-30 flex items-center justify-center group cursor-pointer"
        style={{
          left: CALENDAR_RENTAL_NAME_WIDTH,
          top: 5,
          width: CALENDAR_CELL_WIDTH,
        }}
        onClick={onLeft}
      >
        <div className="p-2 mb-10 bg-low-contrast/[0.05] rounded-md">
          <ArrowLeftIcon className="group-hover:opacity-70 group-active:opacity-90 " />
        </div>
      </div>

      <div
        className="bg-transparent absolute z-30 flex items-center justify-center group cursor-pointer"
        style={{
          right: 0,
          top: 5,
          width: CALENDAR_CELL_WIDTH,
        }}
        onClick={onRight}
      >
        <div className="p-2 mb-10 bg-low-contrast/[0.05] rounded-md">
          <ArrowRightIcon className="group-hover:opacity-70 group-active:opacity-90" />
        </div>
      </div>
    </>
  );
};
