import React from "react";

import {cn} from "../../../helpers/classHelper";
import {ReactNode} from "react";

export interface TitleTextProps {
    children: ReactNode;
    className?: string;
}

export const TitleText = ({children, className = ""}: TitleTextProps) =>
{
    return (
        <>
            <h1 className={cn("font-bold text-lg", className)}>
                {children}
            </h1>
        </>
    );
}