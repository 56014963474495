import React from "react";
import {useTranslation} from "react-i18next";
import {MainLayout} from "../../components/Layout/MainLayout/MainLayout";
import ForbidIcon from "../../assets/icons/forbid.svg?react";

export const ForbiddenPage = () => {
    const { t } = useTranslation();
  return (
    <>
        <MainLayout
            title={t("Global.forbidden")}
            sidebarActiveItem="forbidden"
            sidebarActiveSubItem="general"
        >
            <div
                className="flex bg-element-background w-full rounded-6px mt-2 items-center justify-center p-4"
                style={{height: "calc(100vh - 200px)"}}
            >
                <div className="flex flex-col items-center">
                    <ForbidIcon width={56} height={56}/>

                    <p className="my-4 font-semibold text-xl text-high-contrast">
                        {t("Global.forbiddenTitle")}
                    </p>

                    <p className="mb-4 font-light text-base text-low-contrast text-center w-96">
                        {t("Global.forbiddenContent")}
                    </p>
                </div>
            </div>
        </MainLayout>
    </>
  );
};
