import React from "react";
import {GuestWebsiteServiceCard} from "../../../../types/GETTypes";
import useGuestWebsiteStore from "../../../../stores/guestWebsiteStore/useGuestWebsiteStore";

const ServicesCardComponent = ({
  logo,
  name,
  description,
}: GuestWebsiteServiceCard) => {
  const {dataGuestWebsite} = useGuestWebsiteStore();
  const {styles} = dataGuestWebsite || {};
  const {color_background,color_text} = styles || {};
  return (
    <div
      className="bg-white shadow-md rounded-lg p-6 text-center flex-1"
      // style={{backgroundColor: color_background}}
    >
      {/* Image */}
      <img
        src={logo}
        alt={name}
        className="w-16 md:w-20 h-16 md:h-20 mx-auto"
      />
      {/* Title */}
      <h3 className="text-lg font-bold " style={{color: color_text}}>
        {name}
      </h3>
      {/* Description */}
      <p className=" text-sm" style={{color: color_text}}>
        {description}
      </p>
    </div>
  );
};

export default ServicesCardComponent;
