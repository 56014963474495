import { t } from "i18next";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { RentalLightListItemResponse } from "../../../../../../types/GETTypes";
import {
  ProgressionMultiDepositSettings,
  ProgressionMultiDepositSettingsForm,
} from "../../../../MultiDepositSettings/MultiDepositSettings";
import { DIRECT_RESERVATIONS_STEP } from "../ConfigDirectReservations.type";

type ProgressionConfigDirectReservationDepositSettingsStepProps = {
  loading: boolean;
  rentals: RentalLightListItemResponse[] | undefined;
  form: UseFormReturn<ProgressionMultiDepositSettingsForm>;
  onNext: (step: DIRECT_RESERVATIONS_STEP) => void;
};

export const ProgressionConfigDirectReservationDepositSettingsStep: React.FC<
  ProgressionConfigDirectReservationDepositSettingsStepProps
> = ({ loading, rentals, form, onNext }) => {
  return (
    <div className="flex flex-col gap-y-3">
      <p className="text-md text-low-contrast">
        {t(
          "Progression.StepConfigDirectReservations.Modal.Steps.DepositSettings.description"
        )}
      </p>

      <ProgressionMultiDepositSettings
        loading={loading}
        rentals={rentals}
        form={form}
        onNext={onNext}
      />
    </div>
  );
};
