import React, {useState} from "react";
import PriceMultiplierTableHeader from "./PriceMultiplierTableHeader";
import PriceMultiplierTableBody from "./PriceMultiplierTableBody";
import {PriceMultiplierData} from "../../helpers/priceMultiplierHelper";


const PriceMultiplierTable = ({
  data,
}: {
  data: PriceMultiplierData | undefined;
}) => {

  const [openDetails, setOpenDetails] = useState(false);
  return (
    <div className="overflow-hidden rounded-lg border border-element-border">
      <table className="w-full border-collapse bg-element-border/10">
        <PriceMultiplierTableHeader
          isOpen={openDetails}
          open={() => setOpenDetails(!openDetails)}
        />
        <PriceMultiplierTableBody isOpen={openDetails} data={data}/>
      </table>
    </div>
  );
};

export default PriceMultiplierTable;
