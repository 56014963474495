import React, {useEffect} from "react";
import TitleBlockComponent from "../../Common/TitleBlockComponent";
import ChildrenIcon from "../../../../../../assets/icons/emoji-happy.svg?react";
import UsersIcon from "../../../../../../assets/icons/users.svg?react";
import NightIcon from "../../../../../../assets/icons/moon-2.svg?react";
import CheckinIcon from "../../../../../../assets/icons/log-in.svg?react";
import CheckoutIcon from "../../../../../../assets/icons/log-out.svg?react";
import MoneyIcon from "../../../../../../assets/icons/money.svg?react";
import useGuestWebsiteStore from "../../../../../../stores/guestWebsiteStore/useGuestWebsiteStore";
import moment from "moment";
import {capitalizeFirstLetter} from "../../../../../../helpers/stringHelper";
import {useTranslation} from "react-i18next";
import {getFormattedPrice} from "../../../../../../helpers/currencyHelper";

function ReservationResumeCard() {
  const {t} = useTranslation();
  const {rentalData, step2Data, dataGuestWebsite} = useGuestWebsiteStore();
  const {
    name,
    address,
    city,
    postal_code,
    cover,
    checkin_min_time,
    checkin_max_time,
    checkout_min_time,
    checkout_max_time,
  } = rentalData || {};

  const {general_informations, tarification} = step2Data || {};

  const {nights_count, adults_count, children_count, checkin, checkout} =
    general_informations || {};
  const {total} = tarification || {};
  const {styles} = dataGuestWebsite || {};
  const {color_text} = styles || {};

  const formatTime = (time: string | undefined) => {
    if (time) {
      return moment(time, "HH:mm").format("HH[h]mm");
    }
    return "";
  };

  const formatDate = (date: string | undefined) => {
    if (date) {
      return moment(date, "YYYY-MM-DD").format("dddd D MMMM YYYY");
    }
    return "";
  };

  useEffect(() => {}, [step2Data]);

  return (
    <div className=" bg-white rounded-lg shadow-md p-6">
      {/* Header */}
      <TitleBlockComponent
        title={t("GuestWebsite.Rental.Payment.yourReservation")}
      />

      {/* Property Info */}
      <div className="flex items-center mb-6">
        <img
          src={cover}
          alt=""
          className="w-20 h-20 rounded-md object-cover"
          style={{color: color_text}}
        />
        <div className="ml-4">
          <h3 className="text-lg font-semibold" style={{color: color_text}}>
            {name}
          </h3>
          <p className="text-sm text-gray-500">
            {address}, {postal_code} {city}
          </p>
        </div>
      </div>

      <div className="flex items-center justify-between">
        {/* Booking Details */}
        <div className="flex flex-col space-y-4">
          {/* Arrival */}
          <div className="flex items-center justify-between">
            <div>
              <div className="flex items-center space-x-2 text-right">
                <CheckinIcon className="h-5 w-5 text-low-contrast" />
                <span className="text-low-contrast text-base">
                  {t("GuestWebsite.checkin")}
                </span>
              </div>
              <p className="text-sm  font-bold" style={{color: color_text}}>
                {capitalizeFirstLetter(formatDate(checkin))}
              </p>
              <p className="text-sm text-low-contrast">
                {t("GuestWebsite.betweenHours", {
                  date: formatTime(checkin_min_time),
                  date2: formatTime(checkin_max_time),
                })}
              </p>
            </div>
          </div>

          {/* Departure */}
          <div className="flex items-center justify-between">
            <div>
              <div className="flex items-center space-x-2 text-right">
                <CheckoutIcon className="h-5 w-5 text-low-contrast" />
                <span className="text-low-contrast text-base">
                  {t("GuestWebsite.checkout")}
                </span>
              </div>
              <p className="text-sm  font-bold" style={{color: color_text}}>
                {capitalizeFirstLetter(formatDate(checkout))}
              </p>
              <p className="text-sm text-low-contrast">
                {t("GuestWebsite.betweenHours", {
                  date: formatTime(checkout_min_time),
                  date2: formatTime(checkout_max_time),
                })}
              </p>
            </div>
          </div>
        </div>

        {/* Guests and Price */}
        <div className="flex flex-col space-y-4 justify-end">
          <div className="flex justify-end space-x-2 text-right">
            <p className="text-base font-bold" style={{color: color_text}}>
              {nights_count} {t('GuestWebsite.nights')}
            </p>
            <NightIcon className="h-5 w-5 text-low-contrast" />
          </div>
          <div className="flex justify-end space-x-2 text-right">
            <p className="text-sm text-low-contrast">{adults_count} {t('GuestWebsite.adults_small')}</p>
            <UsersIcon className="h-5 w-5 text-low-contrast" />
          </div>
          <div className="flex justify-end space-x-2 text-right">
            <p className="text-sm text-low-contrast">{children_count} {t('GuestWebsite.children_small')}</p>
            <ChildrenIcon className="h-5 w-5 text-low-contrast" />
          </div>
          <div className="flex justify-end space-x-2 text-right">
            <p className="text-sm text-low-contrast">{getFormattedPrice({price:total ?? 0,decimals:2,withCurrency:false})} €</p>
            <MoneyIcon className="h-5 w-5 text-low-contrast" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReservationResumeCard;
