import React, { ReactElement, useMemo } from "react";

import { FlexItemCenter } from "../../Common/FlexComponents/FlexItemCenter";
import { useTranslation } from "react-i18next";
import {
  CalendarReservationResponse,
  ReservationResponse,
  TaskPriorityType,
} from "../../../types/GETTypes";

import CheckListBlueIcon from "../../../assets/icons/check-list-blue.svg?react";
import ClockIcon from "../../../assets/icons/clock.svg?react";
import CheckIcon from "../../../assets/icons/check-circle.svg?react";
import CloseIcon from "../../../assets/icons/close.svg?react";

import useText from "../../../hooks/useText";
import { cn } from "../../../helpers/classHelper";
import { ReservationForCalendarList } from "../../../pages/Booking/BookingPage.type";

export default function TaskAssigned({
  loading,
  handleRemoveTask,
  reservation,
  taskType,
  lightComponent = false,
}: {
  loading: boolean;
  handleRemoveTask: Function;
  reservation:
    | CalendarReservationResponse
    | ReservationResponse
    | ReservationForCalendarList;
  taskType: TaskPriorityType;
  lightComponent?: boolean;
}): ReactElement {
  const { t } = useTranslation();
  const { getText } = useText("Calendar.Reservation.tasks");

  const task = useMemo(() => {
    switch (taskType) {
      case "CHECKIN":
        return reservation.priority_tasks.checkin;
      case "CHECKOUT":
        return reservation.priority_tasks.checkout;
      case "CLEANING":
        return reservation.priority_tasks.cleaning;
    }
  }, [reservation.priority_tasks]);

  const getStateTextTask = (): string => {
    switch (task.status) {
      case "ASSIGNED":
        return getText("assigned");
      case "STARTED":
        return getText("started");
      case "COMPLETED":
        return getText("completed");
      case "CANCELED":
        return getText("canceled");
      case "PARTIALLY_COMPLETED":
        return getText("partiallyCompleted");
      default:
        return "404";
    }
  };

  const getStateColorTask = (): string => {
    switch (task.status) {
      case "ASSIGNED":
        return "bg-blue-100";
      case "STARTED":
        return "bg-yellow-100";
      case "COMPLETED":
        return "bg-[#7bed9f]/30";
      case "CANCELED":
        return "bg-red-200";
      case "PARTIALLY_COMPLETED":
        return "bg-yellow-100";
      default:
        return "bg-[#aab7b8]";
    }
  };

  const getStateIconTask = (): ReactElement => {
    switch (task.status) {
      case "ASSIGNED":
        return <CheckListBlueIcon />;
      case "STARTED":
        return <ClockIcon />;
      case "COMPLETED":
        return <CheckIcon />;
      case "CANCELED":
        return <CloseIcon />;
      case "PARTIALLY_COMPLETED":
        return <ClockIcon />;
      default:
        return <CheckListBlueIcon />;
    }
  };

  return (
    <FlexItemCenter gap={20}>
      <FlexItemCenter
        className={"p-2 rounded-lg border border-gray-300 bg-white relative"}
      >
        <FlexItemCenter gap={10} className={"font-bold"}>
          {task.photo ? (
            <img
              alt="operator photo"
              src={task.photo}
              height={30}
              width={30}
              className="rounded-full w-[30px] h-[30px] object-cover border"
            />
          ) : (
            <div className="rounded-full w-[30px] h-[30px] object-cover bg-gray-100 border"></div>
          )}
          <p className={" w-[100px]"}>{task.name ?? getText("person")}</p>
        </FlexItemCenter>
        <div
          className={`flex gap-2 ${getStateColorTask()} rounded-6px items-center w-fit p-3`}
        >
          {getStateIconTask()}
          <p className={cn("font-semibold", "text-black")}>
            {getStateTextTask()}
          </p>
        </div>
      </FlexItemCenter>
      {!lightComponent ? (
        <p className={"font-bold w-[110px]"}>
          {t("Automation.AssignModal.date", {
            date: task.date,
            time: task.time.replace(":", "h"),
          })}
        </p>
      ) : null}
    </FlexItemCenter>
  );
}
