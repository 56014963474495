import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import SignageIcon from "../../../assets/icons/signage.svg?react";
import { Card } from "../../Common/Card/Card";
import { WebsiteResponse } from "../../../types/GETTypes";
import { useTranslation } from "react-i18next";
import { useToggleCard } from "./useToggleCard";
import paths from "../../../constants/paths";
import CheckIcon from "../../../assets/icons/check-white.svg?react";
import EditIcon from "../../../assets/icons/edit.svg?react";
import ArrowLeftIcon from "../../../assets/icons/arrow-left.svg?react";
import { ValueType } from "../../../types/commonTypes";
import MinusIcon from "../../../assets/icons/minus.svg?react";
import PhotoIcon from "../../../assets/icons/photo.svg?react";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import { post } from "../../../helpers/APIHelper";

export const WebsiteLogosCard: React.FC<{
  website: WebsiteResponse | undefined;
  onUpdateWebsite: (nextWebsite: WebsiteResponse) => void;
}> = ({ website, onUpdateWebsite }) => {
  const { t } = useTranslation();

  const { isEnabled, isEnabledLoading, toggle } = useToggleCard({
    website,
    initialValue: website?.content.partners.enabled,
    baseUrl: `${
      import.meta.env.VITE_API_URL
    }${paths.API.WEBSITE.UPDATE.PARTNER_LOGO_TOGGLE(website?.id!)}`,
  });

  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const [updateError, setUpdateError] = useState<string | undefined>();

  const [editMode, setEditMode] = useState<boolean>(false);

  const editModeRef = useRef<{
    handleClear: () => void;
    handleCheckData: () => {
      logos: LogoType[];
    } | null;
  } | null>(null);

  const handleCancel = () => {
    setEditMode(false);
    editModeRef.current?.handleClear();
  };

  const handleUpdate = async () => {
    const values = editModeRef.current?.handleCheckData();

    if (!values) return;

    setUpdateLoading(true);
    setUpdateError(undefined);

    const formData = new FormData();
    formData.append(
      "del_photo_ids",
      values.logos
        .filter((l) => l.isDeleted && l.url !== undefined)
        .map((l) => l.id)
        .join(",")
    );

    values.logos
      .filter((l) => l.isDeleted === false && l.blob !== undefined)
      .forEach((logo, index) => {
        formData.append(`photos[${index}]`, logo.blob!);
      });

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.WEBSITE.UPDATE.PARTNER_LOGO(
        website?.id!
      )}`,
      formData
    );

    if (!res?.data?.success) {
      setUpdateError(res?.response?.data?.message);
    } else {
      onUpdateWebsite(res.data?.result);
      setEditMode(false);
    }

    setUpdateLoading(false);
  };

  useEffect(() => {
    setUpdateError(undefined);
  }, [editMode]);

  return (
    <Card
      Icon={SignageIcon}
      label={t("Website.Logos.title")}
      anchor="logos-card"
      switchButton={{
        value: isEnabled,
        label: "",
        loading: isEnabledLoading,
        onClick: toggle,
      }}
      button={{
        Icon: editMode ? CheckIcon : EditIcon,
        type: editMode ? "primary" : "secondary",
        label: editMode ? t("Global.record") : t("Global.edit"),
        onClick: editMode ? () => handleUpdate() : () => setEditMode(!editMode),
        disabled: !website || updateLoading,
      }}
      secondaryButton={
        editMode
          ? {
              label: t("Global.cancel"),
              LeftIcon: ArrowLeftIcon,
              onClick: handleCancel,
              disabled: updateLoading,
            }
          : undefined
      }
    >
      <ErrorMessage>{updateError}</ErrorMessage>

      {editMode ? (
        <EditMode ref={editModeRef} website={website} loading={updateLoading} />
      ) : (
        <ViewMode website={website} />
      )}
    </Card>
  );
};

const ViewMode: React.FC<{ website: WebsiteResponse | undefined }> = ({
  website,
}) => {
  if (!website) return <ViewModeSkeleton />;

  return (
    <div className="flex gap-6 rounded-lg bg-element-background justify-center items-center p-4 flex-wrap">
      {website?.content.partners.logos?.map((logo) => (
        <img key={logo.id} className="w-32 shrink-0" src={logo.original_url} />
      ))}
    </div>
  );
};

const ViewModeSkeleton: React.FC<{}> = ({}) => {
  return (
    <div className="flex gap-6 rounded-lg bg-element-background justify-center items-center p-4 flex-wrap animate-pulse">
      {Array.from({ length: 4 }).map((_l, index) => (
        <div
          key={index}
          className="w-32 h-16 bg-slate-200 shrink-0 rounded-lg"
        />
      ))}
    </div>
  );
};

interface EditModeProps {
  website: WebsiteResponse | undefined;
  loading: boolean;
}

type LogoType = {
  id: ValueType;
  blob: Blob | undefined;
  url: string | undefined;
  isDeleted: boolean;
};

const EditMode = forwardRef<
  {
    handleClear: () => void;
    handleCheckData: () => {
      logos: LogoType[];
    } | null;
  },
  EditModeProps
>(({ website, loading }, ref) => {
  const { t } = useTranslation();

  const [logos, setLogos] = useState<LogoType[]>([]);
  const [logosError, setLogosError] = useState<string | undefined>();

  const handleClear = () => {
    setLogos([]);
    setLogosError(undefined);
  };

  const handleCheckData = () => {
    setLogosError(undefined);

    let isError = false;

    if (logos.filter((l) => l.isDeleted === false).length >= 8) {
      setLogosError(t("Global.max", { max: 8 }));
      isError = true;
    }

    if (isError) return null;

    return {
      logos,
    };
  };

  useImperativeHandle(ref, () => ({
    handleClear,
    handleCheckData,
  }));

  useEffect(() => {
    if (website) {
      setLogos(
        website.content.partners.logos?.map((l) => ({
          id: l.id,
          url: l.original_url,
          blob: undefined,
          isDeleted: false,
        })) ?? []
      );
    }
  }, [website]);

  const logoInputRef = useRef<HTMLInputElement>(null);

  const handleRemoveLogo = (nextLogo: LogoType) => {
    setLogos((prevLogos) =>
      prevLogos.map((logo) =>
        logo.id === nextLogo.id ? { ...logo, isDeleted: true } : logo
      )
    );
  };

  const handleAddLogo = () => {
    logoInputRef.current?.click();
  };

  const handleAddLogoFromInput = (event: any) => {
    if (event.target.files?.length === 0) return;

    const newLogo = event.target.files[0];

    setLogos((prevLogos) => [
      ...prevLogos,
      {
        id: new Date().toString(),
        blob: newLogo,
        url: undefined,
        isDeleted: false,
      },
    ]);
  };

  return (
    <div className="flex flex-col gap-3">
      <ErrorMessage>{logosError}</ErrorMessage>

      <div className="grid grid-cols-2 gap-4">
        {logos
          .filter((l) => l.isDeleted === false)
          .map((logo) => (
            <div key={logo.id} className=" flex justify-center items-center ">
              <div className="relative max-w-[280px] min-w-[280px] max-h-[64px] min-h-[64px] group">
                <div
                  className="absolute items-center justify-center hidden w-6 h-6 rounded-sm cursor-pointer border-element-border border-1 group-hover:flex bg-white"
                  style={{ top: -6, right: -6 }}
                  onClick={() => handleRemoveLogo(logo)}
                >
                  <MinusIcon width={20} height={20} />
                </div>

                <img
                  className="max-w-[280px] min-w-[280px] max-h-[64px] min-h-[64px] object-cover shrink-0 border-element-border border-1 rounded-xl"
                  src={logo.blob ? URL.createObjectURL(logo.blob) : logo.url}
                />
              </div>
            </div>
          ))}

        {logos.filter((l) => l.isDeleted !== true).length < 8 && (
          <div className="flex justify-center items-center">
            <input
              type="file"
              multiple={false}
              style={{ display: "none" }}
              ref={logoInputRef}
              accept="image/jpeg, image/png"
              onChange={handleAddLogoFromInput}
            />

            <div
              className="flex items-center justify-center w-full gap-2 p-4 border-dashed cursor-pointer bg-element-background border-1 border-element-border rounded-6px hover:bg-element-background-active max-w-[280px] min-w-[280px] max-h-[64px] min-h-[64px]"
              onClick={handleAddLogo}
            >
              <PhotoIcon className="w-10 h-10" />
              <p className="font-semibold text-high-contrast">
                {t("Website.Logos.addLogo")}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
});
