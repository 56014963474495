import React from "react";
import {
  RentalLightBookingItemResponse,
  RentalLightListItemResponse,
} from "../../../../types/GETTypes";
import {CheckBox} from "../../../Common/CheckBox/CheckBox";
import {ValueType} from "../../../../types/commonTypes";

const BookingSynchroRentalRadio = ({
  property,
  onCheck,
  isSelect,
  loading,
}: {
  property: RentalLightBookingItemResponse;
  onCheck: (id: ValueType, value: boolean) => void;
  isSelect: boolean;
  loading: boolean;
}) => {
  return (
    <div className="flex gap-x-3 p-4 justify-center items-center">
      <div className="flex">
        <CheckBox
          value={isSelect}
          onChange={(e) => onCheck(property.id, e)}
          disabled={loading}
          isRadio={true}
        />
      </div>
      <div className="object-fill w-16 h-12 shrink-0 rounded-4px border-1 border-element-border/50 overflow-hidden">
        <img src={property?.photo ?? ""} className="object-cover w-16 h-12 shrink-0" />
      </div>

      <div className="flex flex-col gap-y-3 w-full">
        <div>
          <p className="font-semibold text-high-contrast underline underline-offset-4">
            {property?.name}
          </p>
          <p className="font-light text-low-contrast mt-1">
            {`${property?.address} ${property?.zip_code} ${property?.city}`}
          </p>
        </div>
      </div>
    </div>
  );
};

export default BookingSynchroRentalRadio;
