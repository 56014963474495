import { t } from "i18next";
import React, { useEffect } from "react";
import { Controller } from "react-hook-form";
import { Trans } from "react-i18next";
import AlertCircleIcon from "../../assets/icons/alert-circle.svg?react";
import { NotificationTriggerPaymentOrDeposit } from "../../enums/GETenums";
import { ValueType } from "../../types/commonTypes";
import InfoCard from "../Common/Card/InfoCard";
import { InputMultiSelect } from "../Common/InputMultiSelect/InputMultiSelect";
import { InputSelect } from "../Common/InputSelect/InputSelect";
import { AutoMessageUpsertGeneralForm } from "./AutoMessage.type";

export const AutoMessageUpsertGeneral: React.FC<{
  // refButton: React.MutableRefObject<HTMLInputElement | undefined>;
  form: any;
  general: AutoMessageUpsertGeneralForm | undefined;
  // onNextStep: (values?: AutoMessageUpsertGeneralForm) => void;
}> = ({ form, general }) => {
  useEffect(() => {
    if (general) {
      form.setValue(
        "trigger",
        general.trigger !== undefined ? general.trigger : "new_reservation"
      );
      form.setValue(
        "delay",
        general.delay !== undefined ? general.delay : "immediately"
      );
      form.setValue("conditions", general.conditions);
    }
  }, [general]);

  // const handleNext = (values?: AutoMessageUpsertGeneralForm) => {
  //   onNextStep(values);
  // };

  const delay_after_reservation = [
    {
      value: "immediately",
      label: t("AutoMessageList.Delay.immediately"),
    },
    {
      value: "plus_5_minutes",
      label: t("AutoMessageList.Delay.plus5Minutes"),
    },
    {
      value: "plus_30_minutes",
      label: t("AutoMessageList.Delay.plus30Minutes"),
    },
    {
      value: "plus_1_hour",
      label: t("AutoMessageList.Delay.plus1Hour"),
    },
    {
      value: "plus_3_hours",
      label: t("AutoMessageList.Delay.plus3Hours"),
    },
    {
      value: "plus_5_hours",
      label: t("AutoMessageList.Delay.plus5Hours"),
    },
    {
      value: "plus_12_hours",
      label: t("AutoMessageList.Delay.plus12Hours"),
    },

    {
      value: "plus_1_day",
      label: t("AutoMessageList.Delay.plus1Day"),
    },
    {
      value: "plus_2_days",
      label: t("AutoMessageList.Delay.plus2Days"),
    },
    {
      value: "plus_3_days",
      label: t("AutoMessageList.Delay.plus3Days"),
    },
    {
      value: "plus_5_days",
      label: t("AutoMessageList.Delay.plus5Days"),
    },
    {
      value: "plus_7_days",
      label: t("AutoMessageList.Delay.plus7Days"),
    },
    {
      value: "plus_10_days",
      label: t("AutoMessageList.Delay.plus10Days"),
    },
    {
      value: "plus_20_days",
      label: t("AutoMessageList.Delay.plus20Days"),
    },
    {
      value: "plus_30_days",
      label: t("AutoMessageList.Delay.plus30Days"),
    },
    {
      value: "plus_60_days",
      label: t("AutoMessageList.Delay.plus60Days"),
    },
  ];

  const delay_before_reservation = [
    {
      value: "minus_5_minutes",
      label: t("AutoMessageList.Delay.minus5Minutes"),
    },
    {
      value: "minus_30_minutes",
      label: t("AutoMessageList.Delay.minus30Minutes"),
    },
    {
      value: "minus_1_hour",
      label: t("AutoMessageList.Delay.minus1Hour"),
    },
    {
      value: "minus_3_hours",
      label: t("AutoMessageList.Delay.minus3Hours"),
    },
    {
      value: "minus_5_hours",
      label: t("AutoMessageList.Delay.minus5Hours"),
    },
    {
      value: "minus_12_hours",
      label: t("AutoMessageList.Delay.minus12Hours"),
    },

    {
      value: "minus_1_day",
      label: t("AutoMessageList.Delay.minus1Day"),
    },
    {
      value: "minus_2_days",
      label: t("AutoMessageList.Delay.minus2Days"),
    },
    {
      value: "minus_3_days",
      label: t("AutoMessageList.Delay.minus3Days"),
    },
    {
      value: "minus_5_days",
      label: t("AutoMessageList.Delay.minus5Days"),
    },
    {
      value: "minus_7_days",
      label: t("AutoMessageList.Delay.minus7Days"),
    },
    {
      value: "minus_10_days",
      label: t("AutoMessageList.Delay.minus10Days"),
    },
    {
      value: "minus_20_days",
      label: t("AutoMessageList.Delay.minus20Days"),
    },
    {
      value: "minus_30_days",
      label: t("AutoMessageList.Delay.minus30Days"),
    },
    {
      value: "minus_60_days",
      label: t("AutoMessageList.Delay.minus60Days"),
    },
  ];

  const [allDelay, setAllDelay] = React.useState<[]>([]);

  const allTriggers = [
    {
      value: "new_reservation",
      label: t("AutoMessageList.Trigger.reservation"),
    },
    {
      value: "checkin",
      label: t("AutoMessageList.Trigger.checkin"),
    },
    {
      value: "checkout",
      label: t("AutoMessageList.Trigger.checkout"),
    },
    {
      value: "caution_paid",
      label: t("AutoMessageList.Trigger.cautionPaid"),
    },
    {
      value: "payment_link_paid",
      label: t("AutoMessageList.Trigger.paymentLinkPaid"),
    },
    {
      value: "guest_form_fulfilled",
      label: t("AutoMessageList.Trigger.guestFormViewed"),
    },
  ];

  useEffect(() => {
    const trigger = form.watch("trigger");
    if (
      trigger === "new_reservation" ||
      trigger === "caution_paid" ||
      trigger === "payment_link_paid" ||
      trigger === "guest_form_fulfilled"
    ) {
      setAllDelay(delay_after_reservation);
    } else if (trigger === "checkin" || trigger === "checkout") {
      setAllDelay([...delay_before_reservation, ...delay_after_reservation]);
    }
  }, [form.watch("trigger")]);

  const handleDisplay = (delay: string, val: string) => {
    const word = allDelay.find((item) => item.value === delay);
    const word2 = allTriggers.find((item) => item.value === val);
    if (
      typeof word?.label === "undefined" ||
      typeof word2?.label === "undefined"
    )
      return "";
    return (word?.label + " " + word2?.label).toLowerCase();
  };

  const isPaymentOrDepositNotificationTrigger = (
    value: any
  ): value is NotificationTriggerPaymentOrDeposit => {
    return Object.values(NotificationTriggerPaymentOrDeposit).includes(value);
  };

  return (
    <>
      {isPaymentOrDepositNotificationTrigger(general?.trigger) ? (
        <InfoCard
          Node={
            <div className="flex flex-col gap-y-2">
              <p>
                <Trans
                  i18nKey="AutoMessageList.Upsert.General.infoTextPaymentOrDeposit1"
                  components={{
                    strong: <span className="font-bold"></span>,
                  }}
                />
              </p>

              <p>
                {t("AutoMessageList.Upsert.General.infoTextPaymentOrDeposit2")}
              </p>
              <p>
                {t("AutoMessageList.Upsert.General.infoTextPaymentOrDeposit3")}
              </p>
            </div>
          }
        />
      ) : (
        <div className="flex flex-col gap-3">
          <div className="flex flex-row gap-3">
            <div className="w-1/2">
              <Controller
                control={form.control}
                name="trigger"
                render={({ field: { value, onChange } }) => (
                  <InputSelect
                    required={true}
                    label={t("AutoMessageList.Upsert.General.trigger")}
                    items={allTriggers}
                    selectedValue={value}
                    onSelect={onChange}
                  />
                )}
              />
            </div>
            <div className="w-1/2">
              <Controller
                control={form.control}
                name="delay"
                render={({ field: { onChange, value } }) => (
                  <InputSelect
                    label={t("AutoMessageList.Upsert.General.delay")}
                    required={true}
                    items={allDelay}
                    onSelect={onChange}
                    selectedValue={value}
                  />
                )}
              />
            </div>
          </div>

          <div className="flex w-full p-3 mt-4 bg-element-background rounded-6px">
            <div className="flex flex-row gap-2 font-light text-low-contrast">
              <AlertCircleIcon
                className={`fill-secondary`}
                width={30}
                height={30}
              />
              <div className="flex flex-col">
                <p className="mt-1">
                  {t("AutoMessageList.Upsert.General.usage")},
                </p>
                <p className="font-bold">
                  {handleDisplay(form.watch("delay"), form.watch("trigger"))}
                </p>
              </div>
            </div>
          </div>

          <Controller
            control={form.control}
            name="conditions"
            rules={{
              required: t("AutoMessageList.Upsert.General.conditionsError"),
            }}
            render={({ field: { value, onChange } }) => {
              const handleChange = (values: ValueType[]) => {
                onChange(values);
                if (values.length === 0) {
                  form.setError("conditions", {
                    type: "manual",
                    message: t(
                      "AutoMessageList.Upsert.General.conditionsError"
                    ),
                  });
                } else {
                  form.clearErrors("conditions");
                }
              };

              return (
                <InputMultiSelect
                  label={t("AutoMessageList.Upsert.General.conditions")}
                  required={true}
                  items={[
                    {
                      value: "reservation_paid",
                      label: t("AutoMessageList.Conditions.reservation_paid"),
                    },
                    {
                      value: "reservation_not_paid",
                      label: t(
                        "AutoMessageList.Conditions.reservation_not_paid"
                      ),
                    },
                    {
                      value: "caution_paid",
                      label: t("AutoMessageList.Conditions.caution_paid"),
                    },
                    {
                      value: "caution_not_paid",
                      label: t("AutoMessageList.Conditions.caution_not_paid"),
                    },
                    {
                      value: "guest_contract_signed",
                      label: t(
                        "AutoMessageList.Conditions.guest_contract_signed"
                      ),
                    },
                    {
                      value: "guest_contract_not_signed",
                      label: t(
                        "AutoMessageList.Conditions.guest_contract_not_signed"
                      ),
                    },
                    {
                      value: "guest_form_completed",
                      label: t(
                        "AutoMessageList.Conditions.guest_form_completed"
                      ),
                    },
                    {
                      value: "guest_form_not_completed",
                      label: t(
                        "AutoMessageList.Conditions.guest_form_not_completed"
                      ),
                    },
                    {
                      value: "reservation_single_payment",
                      label: t(
                        "AutoMessageList.Conditions.reservation_single_payment"
                      ),
                    },
                    {
                      value: "reservation_two_payments",
                      label: t(
                        "AutoMessageList.Conditions.reservation_two_payments"
                      ),
                    },
                    {
                      value: "reservation_three_payments",
                      label: t(
                        "AutoMessageList.Conditions.reservation_three_payments"
                      ),
                    },
                  ]}
                  error={form.formState.errors.conditions?.message}
                  selectedValues={value}
                  onSelect={handleChange}
                />
              );
            }}
          />
          {/* <input
              type="submit"
              value="go"
              ref={refButton as React.MutableRefObject<HTMLInputElement>}
              className="hidden"
            /> */}
        </div>
      )}
    </>
  );
};
