import { t } from "i18next";
import React, { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import { Trans } from "react-i18next";
import { RequiredFields } from "../../../../types/commonTypes";
import {
  RentalChecklistsResponse,
  RentalLightListItemResponse,
} from "../../../../types/GETTypes";
import { InputSelect } from "../../../Common/InputSelect/InputSelect";
import { TextAreaInput } from "../../../Common/TextAreaInput/TextAreaInput";
import { TextInput } from "../../../Common/TextInput/TextInput";
import { ManagementAutomationListInformationsStepForm } from "./InformationsStep.type";
import { NumberInput } from "../../../Common/NumberInput/NumberInput";
import { log } from "node:util";

type ManagementAutomationListInformationsStepProps = {
  loading: boolean;
  mode: "create" | "update";
  form: UseFormReturn<ManagementAutomationListInformationsStepForm>;
  rentals: RentalLightListItemResponse[] | undefined;
  checklists: RentalChecklistsResponse[] | undefined;
};

export const ManagementAutomationListInformationsStep: React.FC<
  ManagementAutomationListInformationsStepProps
> = ({ loading, mode, form, rentals, checklists }) => {
  const requiredFields: RequiredFields<ManagementAutomationListInformationsStepForm> =
    {
      concernedRental: true,
      automationType: true,
      nameTask: form.getValues("automationType") === "OTHER",
      amount_global: form.getValues("automationType") === "OTHER",
      descriptionTask: form.getValues("automationType") === "OTHER",
      relatedChecklist: form.getValues("automationType") !== "OTHER",
      taskRemark: false,
    };

  useEffect(() => {
    if (mode === "create" && rentals && rentals.length > 0) {
      form.setValue("concernedRental", rentals[0].id);
    }
  }, [rentals]);

  const rentalName = rentals?.find(
    (rental) => rental.id === form.getValues("concernedRental"),
  )?.name;

  useEffect(() => {
    if (mode === "create" && checklists && checklists.length > 0) {
      form.setValue("relatedChecklist", checklists[0].id);
    }
  }, [checklists]);

  form.watch();

  return (
    <div className="flex flex-col gap-y-4 max-w-[27rem]">
      <InputSelect
        register={form.register("concernedRental", {
          required: {
            message: t("Global.Errors.requiredField", {
              fieldName: t(
                "Automation.AddOrUpdateModal.Steps.Informations.concernedRentalLabel",
              ),
            }),
            value: requiredFields.concernedRental,
          },
        })}
        required={requiredFields.concernedRental}
        disabled={loading}
        label={t(
          "Automation.AddOrUpdateModal.Steps.Informations.concernedRentalLabel",
        )}
        items={
          rentals && rentals.length > 0
            ? [
                {
                  label: t(
                    "Automation.AddOrUpdateModal.Steps.Informations.concernedRentalDefaultLabel",
                  ),
                  disabled: true,
                  value: "",
                },
                ...rentals?.map((rental) => {
                  return {
                    label: rental.name,
                    value: rental.id,
                  };
                }),
              ]
            : []
        }
        selectedValue={form.getValues("concernedRental")}
        error={form.formState.errors.concernedRental?.message}
      />

      <InputSelect
        register={form.register("automationType", {
          required: {
            message: t("Global.Errors.requiredField", {
              fieldName: t(
                "Automation.AddOrUpdateModal.Steps.Informations.automationTypeLabel",
              ),
            }),
            value: requiredFields.automationType,
          },
        })}
        required={requiredFields.automationType}
        disabled={loading}
        label={t(
          "Automation.AddOrUpdateModal.Steps.Informations.automationTypeLabel",
        )}
        items={[
          {
            label: t(
              "Automation.AddOrUpdateModal.Steps.Informations.AutomationTypeOptions.cleaning",
            ),
            value: "CLEANING",
          },
          {
            label: t(
              "Automation.AddOrUpdateModal.Steps.Informations.AutomationTypeOptions.checkin",
            ),
            value: "CHECKIN",
          },
          {
            label: t(
              "Automation.AddOrUpdateModal.Steps.Informations.AutomationTypeOptions.checkout",
            ),
            value: "CHECKOUT",
          },
          {
            label: t(
              "Automation.AddOrUpdateModal.Steps.Informations.AutomationTypeOptions.other",
            ),
            value: "OTHER",
          },
        ]}
        selectedValue={form.getValues("automationType")}
        error={form.formState.errors.automationType?.message}
      />

      {/* Name & Description of task */}
      {form.getValues("automationType") === "OTHER" ? (
        <>
          <TextInput
            register={form.register("nameTask", {
              required: {
                message: t("Global.Errors.requiredField", {
                  fieldName: t(
                    "Automation.AddOrUpdateModal.Steps.Informations.nameTaskLabel",
                  ),
                }),
                value: Boolean(requiredFields.nameTask),
              },
            })}
            required={requiredFields.nameTask}
            disabled={loading}
            label={t(
              "Automation.AddOrUpdateModal.Steps.Informations.nameTaskLabel",
            )}
            placeholder={t(
              "Automation.AddOrUpdateModal.Steps.Informations.nameTaskPlaceholder",
            )}
            value={form.getValues("nameTask")?.trim()}
            error={form.formState.errors.nameTask?.message}
          />
          <NumberInput
            register={form.register("amount_global", {
              required: {
                message: t("Global.Errors.requiredField", {
                  fieldName: t(
                    "Automation.AddOrUpdateModal.Steps.Informations.amountTask",
                  ),
                }),
                value: Boolean(requiredFields.amount_global),
              },
            })}
            required={requiredFields.amount_global}
            disabled={loading}
            label={t(
              "Automation.AddOrUpdateModal.Steps.Informations.amountTask",
            )}
            value={form.getValues("amount_global")}
            currency={"€"}
            error={form.formState.errors.amount_global?.message}
          />

          <TextAreaInput
            register={form.register("descriptionTask", {
              onChange: (e) => {
                form.setValue("descriptionTask", e.target.value);
              },
              required: {
                message: t("Global.Errors.requiredField", {
                  fieldName: t(
                    "Automation.AddOrUpdateModal.Steps.Informations.descriptionTaskLabel",
                  ),
                }),
                value: Boolean(requiredFields.descriptionTask),
              },
            })}
            required={requiredFields.descriptionTask}
            disabled={loading}
            label={t(
              "Automation.AddOrUpdateModal.Steps.Informations.descriptionTaskLabel",
            )}
            placeholder={t(
              "Automation.AddOrUpdateModal.Steps.Informations.descriptionTaskPlaceholder",
            )}
            resize={{
              x: false,
              y: true,
            }}
            value={form.getValues("descriptionTask")?.trim()}
            error={form.formState.errors.descriptionTask?.message}
          />
        </>
      ) : null}

      {/* Related checklist */}
      {form.getValues("automationType") !== "OTHER" ? (
        <div className="flex flex-col space-y-1">
          <InputSelect
            register={form.register("relatedChecklist", {
              required: {
                message: t("Global.Errors.requiredField", {
                  fieldName: t(
                    "Automation.AddOrUpdateModal.Steps.Informations.relatedChecklistLabel",
                  ),
                }),
                value: requiredFields.relatedChecklist,
              },
            })}
            required={requiredFields.relatedChecklist}
            disabled={loading}
            label={t(
              "Automation.AddOrUpdateModal.Steps.Informations.relatedChecklistLabel",
            )}
            items={
              checklists?.map((checklist) => {
                return {
                  label: checklist.name,
                  value: checklist.id,
                };
              }) ?? []
            }
            selectedValue={form.getValues("relatedChecklist")!}
            error={form.formState.errors.relatedChecklist?.message}
          />
          {rentalName ? (
            <p className="text-xs break-all text-light text-low-contrast text-wrap">
              <Trans
                i18nKey={
                  "Automation.AddOrUpdateModal.Steps.Informations.relatedChecklistDescription"
                }
                components={{
                  strong: <span className="font-bold"></span>,
                }}
                values={{
                  rentalName,
                }}
              />
            </p>
          ) : null}
        </div>
      ) : null}

      <TextAreaInput
        register={form.register("taskRemark", {
          onChange: (e) => {
            form.setValue("taskRemark", e.target.value);
          },
          required: {
            message: t("Global.Errors.requiredField", {
              fieldName: t(
                "Automation.AddOrUpdateModal.Steps.Informations.taskRemarkLabel",
              ),
            }),
            value: requiredFields.taskRemark,
          },
        })}
        required={requiredFields.taskRemark}
        disabled={loading}
        label={t(
          "Automation.AddOrUpdateModal.Steps.Informations.taskRemarkLabel",
        )}
        placeholder={t(
          "Automation.AddOrUpdateModal.Steps.Informations.taskRemarkPlaceholder",
        )}
        resize={{
          x: false,
          y: true,
        }}
        value={form.getValues("taskRemark")?.trim()}
        error={form.formState.errors.taskRemark?.message}
      />
    </div>
  );
};
