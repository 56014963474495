import React, {useEffect, useRef, useState} from "react";
import {RightModal} from "../../../Common/RightModal/RightModal";
import {ErrorMessage} from "../../../Common/ErrorMessage/ErrorMessage";
import {useTranslation} from "react-i18next";
import {Separator} from "../../../Common/Separator/Separator";
import {Button} from "../../../Common/Button/Button";
import {Controller, useForm} from "react-hook-form";
import {TextInput} from "../../../Common/TextInput/TextInput";
import {UseModal} from "../../../../hooks/useModal";
import {cn} from "../../../../helpers/classHelper";
import ArrowSendWhiteIcon from "../../../../assets/icons/send-white.svg?react";
import CheckWhiteIcon from "../../../../assets/icons/check-white.svg?react";
import {CustomEditor} from "../../../Common/CustomEditor/CustomEditor";
import {LexicalEditor} from "lexical";
import {ContractForm, DataContractModal, modeType} from "./ContractModal.type";
import paths from "../../../../constants/paths";
import {patch, post} from "../../../../helpers/APIHelper";
import {ResourceResponse} from "../../../Common/CardDocs/CardDocs.type";
import {
  ContractResponse,
  ReservationResponse,
  UserResponse,
} from "../../../../types/GETTypes";
import {getContractStatusBadge} from "../../../../helpers/contractHelper";
import ContractInfoComponent from "../Component/ContractInfoComponent";

type ContractModalProps = {
  modalContract: UseModal<
    {mode: modeType; contract: ContractResponse} | null | undefined
  >;
  modalSendContract: UseModal<DataContractModal | null | undefined>;
  data: {mode: modeType; contract: ContractResponse} | null | undefined;
  isVisible: boolean;
  onClose: () => void;
  reservationId: string | undefined;
  onSuccess: () => Promise<void>;
  reservation: ReservationResponse | undefined;
  user: UserResponse | undefined;
};

const ContractModal = ({
  isVisible,
  onClose,
  data,
  reservationId,
  modalContract,
  modalSendContract,
  onSuccess,
  reservation,
  user,
}: ContractModalProps) => {
  const {t} = useTranslation();
  const {mode, contract} = data || {};
  const [error, setError] = useState<string | undefined>();
  const [loadingValidation, setLoadingValidation] = useState<boolean>(false);
  const [openContract, setOpenContract] = useState<boolean>(false);
  const editorRef = useRef<LexicalEditor>();
  const form = useForm<ContractForm>({
    defaultValues: {
      name: "",
      content: "",
    },
  });

  const handleUpdateContract = async (data: ContractForm) => {
    console.log("🚀 ~ handleUpdateContract ~ data:", data);
    setLoadingValidation(true);
    if (contract && !contract?.is_signed) {
      const response = await patch(
        `${
          import.meta.env.VITE_API_URL
        }${paths.API.CONTRACTS_DOCS.UPDATE_CONTRACT_BY_RESERVATION(
          Number(reservationId),
          contract.id
        )}`,
        data
      );
      if (response?.data?.success) {
        onClose();
        onSuccess();
      }
    }
    setLoadingValidation(false);
  };

  useEffect(() => {
    if (mode === "edit") {
      form.setValue("name", contract?.name ?? "");
      form.setValue("content", contract?.content ?? "");
    }
  }, [mode]);

  const ButtonsByMode = () => {
    switch (mode) {
      case "display":
        return (
          <div className="flex flex-1 gap-x-3 justify-between">
            {contract && !contract?.is_signed && (
              <Button
                type="secondary"
                onClick={() =>
                  contract && modalContract.open({contract, mode: "edit"})
                }
                disabled={loadingValidation}
              >
                {t("Global.update")}
              </Button>
            )}
            <Button
              type="primary"
              loading={loadingValidation}
              onClick={() => contract && modalSendContract.open({contract})}
              RightIcon={ArrowSendWhiteIcon}
            >
              {t("Rental.Contracts.Modal.ContractModal.titleSendContract")}
            </Button>
          </div>
        );
      case "edit":
        return (
          <div className="flex flex-1 gap-x-3 justify-between">
            <Button
              type="secondary"
              onClick={() =>
                contract && modalContract.open({contract, mode: "display"})
              }
              disabled={loadingValidation}
            >
              {t("Global.cancel")}
            </Button>
            <Button
              type="primary"
              loading={loadingValidation}
              onClick={form.handleSubmit(handleUpdateContract)}
              RightIcon={CheckWhiteIcon}
            >
              {t("Global.save")}
            </Button>
          </div>
        );
    }
  };

  const getTitleByMode = () => {
    switch (mode) {
      case "display":
        return contract?.name;
      case "edit":
        return t("Rental.Contracts.Modal.ContractModal.updateContract");
    }
  };
  const getRightHeaderByMode = () => {
    switch (mode) {
      case "display":
        return getContractStatusBadge(contract as ResourceResponse, t);
      case "edit":
        return <></>;
    }
  };

  const {totalHT, totalTTC} = reservation?.payment_details?.reduce(
    (totals, paymentDetail) => {
      totals.totalHT += paymentDetail.total_ht ?? 0;
      totals.totalTTC += paymentDetail.total_ttc ?? 0;
      return totals;
    },
    {totalHT: 0, totalTTC: 0}
  ) || {totalHT: 0, totalTTC: 0};
  const currentPaymentSchedule =
    reservation?.payment_schedule
  const details = {
    // Informations sur le voyageur
    guest_first_name: reservation?.guest?.first_name ?? "",
    guest_last_name: reservation?.guest?.last_name?? "",
    guest_email: reservation?.guest?.email?? "",
    guest_phone: reservation?.guest?.phone?? "",
    guest_address: reservation?.guest?.address?? "",
    guest_postal_code: reservation?.guest?.postal_code?? "",
    guest_city: reservation?.guest?.city?? "",
    guest_country: reservation?.guest?.country ?? "",
    guest_business: reservation?.guest?.guest_type === "business",

    // Informations de facturation
    billing_host_name: reservation?.rental?.details?.billing_infos?.host_name?? "",
    billing_address: reservation?.rental?.details?.billing_infos?.address?? "",
    billing_city: reservation?.rental?.details?.billing_infos?.city?? "",
    billing_postal_code:
      reservation?.rental?.details?.billing_infos?.postal_code?? "",
    billing_country: reservation?.rental?.details?.billing_infos?.country?? "",
    billing_email: user?.email?? "",
    billing_phone: user?.phone?? "",

    // Informations sur la location
    rental_address: reservation?.rental?.details?.informations?.address?? "",
    rental_postal_code: reservation?.rental?.details?.informations?.postal_code?? "",
    rental_name: reservation?.rental?.details?.informations?.name?? "",
    rental_city: reservation?.rental?.details?.informations?.city?? "",

    // Informations sur la réservation
    reservation_checkin: reservation?.checkin?? "",
    reservation_checkout: reservation?.checkout?? "",
    reservation_night_counts: reservation?.nights_count?? 0,
    reservation_total_guests: reservation?.guests_count?? 0,
    reservation_adults_counts: reservation?.adults_count?? 0,
    reservation_children_counts: reservation?.children_count?? 0,
    reservation_payments_details: reservation?.payment_details ?? [],
    reservation_total_ht: totalHT ?? 0,
    reservation_total_ttc: totalTTC ?? 0,
    reservation_payment_schedule: currentPaymentSchedule ?? undefined,
  };

  const ContentsByMode = () => {
    switch (mode) {
      case "display":
        return (
          <>
            <ContractInfoComponent
              content={contract?.content}
              openContract={openContract}
              setOpenContract={setOpenContract}
              details={details}
            />
          </>
        );
      case "edit":
        return (
          <>
            <div>
              <Controller
                control={form.control}
                name="name"
                rules={{
                  required: {
                    value: true,
                    message: t("Rental.Contracts.Contracts.Modal.formRequired"),
                  },
                }}
                render={({field: {value, onChange}}) => (
                  <TextInput
                    label={t("Rental.Checklist.Upsert.name")}
                    required={true}
                    placeholder={t(
                      "Rental.Contracts.Contracts.Modal.formNamePlaceholder"
                    )}
                    disabled={loadingValidation}
                    value={value}
                    error={form.formState.errors.name?.message}
                    onChangeText={onChange}
                  />
                )}
              />
            </div>
            <div className="gap-2">
              <div>
                <CustomEditor
                  ref={editorRef}
                  form={form}
                  formControllerName="content"
                  error={form.formState.errors.content?.message}
                  disabled={loadingValidation}
                  label={t("Rental.Contracts.Contracts.Modal.formContent")}
                  placeholder={t(
                    "Rental.Contracts.Contracts.Modal.formContentPlaceholder"
                  )}
                  rows={27}
                  required
                  requiredMessage={t(
                    "Rental.Contracts.Contracts.Modal.formRequired"
                  )}
                  classNames={{
                    placeholder: error
                      ? "top-[30.9%]"
                      : form.formState.errors.content?.message
                      ? "top-[23.7%]"
                      : "top-[21%]",
                  }}
                  showToolbar
                />
              </div>
            </div>
          </>
        );
    }
  };

  return (
    <RightModal
      title={getTitleByMode()}
      rightHeaderNode={getRightHeaderByMode()}
      isVisible={isVisible}
      onClose={onClose}
      classNames={{divParent: "z-[90]", mainContentParent: "mb-4"}}
    >
      <>
        <div className={cn(`flex flex-col gap-3`, "w-full")}>
          <ErrorMessage>{error}</ErrorMessage>
          <ContentsByMode />
          <div className="flex-1"></div>
          <Separator />

          <div className="flex">
            <ButtonsByMode />
          </div>
        </div>
      </>
    </RightModal>
  );
};

export default ContractModal;
