import React from 'react'
import {GuestWebsiteCommonProps} from '../GuestWebsiteLayout'
import useGuestWebsiteStore from '../../../stores/guestWebsiteStore/useGuestWebsiteStore';
import {GuestWebsiteModel} from '../../../types/GETTypes';
import GuestWebsiteTemplate2ConditionsComponent from './Template2.0/GuestWebsiteTemplate2ConditionsComponent';



interface GuestWebsiteConditionsPageProps extends GuestWebsiteCommonProps {}

const RedirectToCorrectTemplate = (props: GuestWebsiteConditionsPageProps) => {
  const {templateGuestWebsite} = useGuestWebsiteStore();
  switch (templateGuestWebsite) {
    case GuestWebsiteModel.MODEL_2_0:
      return <GuestWebsiteTemplate2ConditionsComponent {...props} />;
    case GuestWebsiteModel.MODEL_CHIC:
      return <></>;
    case GuestWebsiteModel.MODEL_MINIMAL:
      return <></>;
    default:
      return <GuestWebsiteTemplate2ConditionsComponent {...props} />;
  }
};

const GuestWebsiteConditionsPage = (props:GuestWebsiteConditionsPageProps) => {
	return <RedirectToCorrectTemplate {...props} />;
}

export default GuestWebsiteConditionsPage