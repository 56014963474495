import { t } from "i18next";
import { ChecklistType } from "../../types/GETTypes";

export const useFormattedAutomationType = (
  automationType: ChecklistType,
): string => {
  let value = t("Global.notDefined");

  if (automationType === "CLEANING") {
    value = t(
      "Automation.AddOrUpdateModal.Steps.Informations.AutomationTypeOptions.cleaning",
    );
  } else if (automationType === "CHECKIN") {
    value = t(
      "Automation.AddOrUpdateModal.Steps.Informations.AutomationTypeOptions.checkin",
    );
  } else if (automationType === "CHECKOUT") {
    value = t(
      "Automation.AddOrUpdateModal.Steps.Informations.AutomationTypeOptions.checkout",
    );
  } else if (automationType === "INCIDENT") {
    value = t(
      "Automation.AddOrUpdateModal.Steps.Informations.AutomationTypeOptions.incident",
    );
  } else if (automationType === "OTHER") {
    value = t(
      "Automation.AddOrUpdateModal.Steps.Informations.AutomationTypeOptions.other",
    );
  }

  return value;
};
