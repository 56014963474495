import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../Common/Button/Button";
import ArrowRightWhiteIcon from "../../assets/icons/arrow-right-white.svg?react";
import LeftArrowIcon from "../../assets/icons/arrow-left.svg?react";
import { WebsiteAddStepDataType } from "../../pages/AddWebsite/AddWebsitePage";
import { post } from "../../helpers/APIHelper";
import paths from "../../constants/paths";
import { ErrorMessage } from "../Common/ErrorMessage/ErrorMessage";
import { WebsiteURL } from "../Website/WebsiteURL";

export type AddWesiteURLType = string;

export const AddWebsiteURL = ({
  stepData,
  onClose,
  onNextStep,
  onPreviousStep,
}: {
  stepData: WebsiteAddStepDataType;
  onClose: () => void;
  onNextStep: (values: AddWesiteURLType) => void;
  onPreviousStep: () => void;
}) => {
  const { t } = useTranslation();

  const [currentCustomURL, setCurrentCustomURL] = useState<
    string | undefined
  >();

  const postfix = ".superhote.com";
  const [currentURL, setCurrentURL] = useState<string>(postfix);
  const [currentURLError, setCurrentURLError] = useState<string | undefined>();

  const [loading, setLoading] = useState<boolean>(false);
  const [APIError, setAPIError] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (stepData.url) {
      if (stepData.url.endsWith(postfix)) setCurrentURL(stepData.url);
      else setCurrentCustomURL(stepData.url);
    }
  }, [stepData.url]);

  const handleNextStep = async () => {
    setAPIError(undefined);
    setCurrentURLError(undefined);

    if (currentURL === postfix) {
      setCurrentURLError(t("Global.required"));
      return;
    }

    setLoading(true);

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.WEBSITE.ADD.URL}`,
      {
        title: stepData.general?.title,
        subtitle: stepData.general?.subTitle,
        rental_ids: stepData?.rentals?.map((r) => r.id).join(","),
        model: stepData?.template,
        url: currentCustomURL ?? currentURL,
      }
    );

    setLoading(false);

    if (!res.data?.success) {
      setAPIError(res.response?.data?.message);
      return;
    }

    onNextStep(currentCustomURL ?? currentURL);
  };

  return (
    <>
      <div>
        <div className="w-52">
          <Button
            type="secondary"
            LeftIcon={LeftArrowIcon}
            onClick={onPreviousStep}
          >
            {t("Global.previousStep")}
          </Button>
        </div>

        <p className="text-lg font-semibold text-high-contrast mt-8">
          {t("Website.Add.URL.title")}
        </p>

        <p className="font-light text-low-contrast mt-4">
          {t("Website.Add.URL.description")}
        </p>

        <div className="h-10" />

        <ErrorMessage>{APIError}</ErrorMessage>

        <WebsiteURL
          URL={currentURL}
          customURL={currentCustomURL}
          URLError={currentURLError}
          onChangeCustomURL={setCurrentCustomURL}
          onChangeURL={setCurrentURL}
        />
      </div>

      <div className="flex gap-3 justify-end">
        <div>
          <Button type="secondary" onClick={onClose} disabled={loading}>
            {t("Global.cancel")}
          </Button>
        </div>
        <div>
          <Button
            RightIcon={ArrowRightWhiteIcon}
            onClick={handleNextStep}
            loading={loading}
          >
            {t("Global.nextStep")}
          </Button>
        </div>
      </div>
    </>
  );
};
