import { RightModal } from "../../Common/RightModal/RightModal";
import { useTranslation } from "react-i18next";
import { Button } from "../../Common/Button/Button";
import { useMake } from "../../../hooks/api/make";
import React, { useState } from "react";
import { TextInput } from "../../Common/TextInput/TextInput";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";

export const MakeModalConnect = ({
  isVisible,
  onClose,
  onFinish,
}: {
  isVisible: boolean;
  onClose: () => void;
  onFinish: () => void;
}) => {
  const { t } = useTranslation();
  const { generate } = useMake();

  const [error, setError] = useState(null);
  const [apiKey, setApiKey] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const generateApikey = async () => {
    setIsLoading(true);
    try {
      const apiKey = await generate();
      setApiKey(apiKey);
    } catch (error: any) {
      setError(error?.message);
    }
    setIsLoading(false);
  };

  const [isInCopy, setIsInCopy] = useState<boolean>(false);
  const [msgCopy, setMsgCopy] = useState<string>();

  const handleCopyApiKey = () => {
    setIsInCopy(true);
    navigator.clipboard
      .writeText(apiKey)
      .then(() => {
        setIsInCopy(false);
        setMsgCopy(t("ThirdParty.copied"));
      })
      .catch((err) => {
        setMsgCopy(err);
        setIsInCopy(false);
      });
  };

  return (
    <RightModal
      title={t("ThirdParty.Make.connection")}
      isVisible={isVisible}
      onClose={onClose}
      classNames={{
        mainContentParent: "overflow-y-auto",
      }}
    >
      <section className={"flex flex-col justify-between w-full"}>
        <div className={"flex flex-col gap-2"}>
          {!error && (
            <div className="border-l-4 border-gray-500 bg-gray-100 rounded-md p-4 text-gray-700 font-semibold flex items-center gap-2">
              <p>{t("ThirdParty.apiKey")}</p>
            </div>
          )}
          {msgCopy && (
            <div
              style={{ background: "#CDFFDE" }}
              className="border-l-4 border-success rounded-md p-4 text-green-700 font-semibold flex items-center gap-2"
            >
              <p>{msgCopy}</p>
            </div>
          )}
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <div className={"flex items-center justify-between gap-2"}>
            <TextInput
              placeholder={"7856.."}
              value={apiKey}
              classNames={{
                divInput: "p-4",
              }}
            />
            <div className={""}>
              <Button
                className={""}
                type={"secondary"}
                loading={isInCopy}
                onClick={handleCopyApiKey}
              >
                Copier
              </Button>
            </div>
          </div>
        </div>
        <div className={"flex items-center justify-between gap-4 w-full"}>
          {!isLoading && (
            <Button type={"secondary"}>{t("Global.cancel")}</Button>
          )}
          <Button loading={isLoading} type={"primary"} onClick={generateApikey}>
            {t("Global.generate")}
          </Button>
        </div>
      </section>
    </RightModal>
  );
};
