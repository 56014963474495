import React from "react";
import {RightModal} from "../../Common/RightModal/RightModal";
import {useTranslation} from "react-i18next";
import StripeLogo from "../../../assets/icons/stripe.svg?react";
import CheckWhiteIcon from "../../../assets/icons/check-white.svg?react";
import {Button} from "../../Common/Button/Button";
import {Separator} from "../../Common/Separator/Separator";
import {Link} from "react-router-dom";

type AddStripeAccountModalProps = {
  isVisible: boolean;
  onClose: () => void;
  page?: string;
};

export const AddStripeAccountModal: React.FC<AddStripeAccountModalProps> = ({
  isVisible,
  onClose,
  page="",
}) => {
  const { t } = useTranslation();

  const stripeURL = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${
    import.meta.env.VITE_STRIPE_CLIENT_ID
  }&scope=read_write&redirect_uri=${page==="progression" ? import.meta.env.VITE_STRIPE_ADD_ACCOUNT_CALLBACK_TWO :
    import.meta.env.VITE_STRIPE_ADD_ACCOUNT_CALLBACK
  }`;

  return (
    <RightModal
      isVisible={isVisible}
      onClose={onClose}
      title={t("Payments.addStripe")}
    >
      <div className="flex flex-col w-full">
        <div className="flex flex-col flex-1">
          <StripeLogo />

          <p className="font-semibold text-high-contrast mt-2">
            {t("Payments.Stripe.addTitle")}
          </p>

          <p className="text-low-contrast font-light mt-3">
            {t("Payments.Stripe.addContent")}
          </p>

          <div className="mt-4 w-52">
            <Link to={stripeURL}>
              <Button>{t("Payments.addStripe")}</Button>
            </Link>
          </div>

          <div className="flex gap-4 flex-col">
            <p className="font-light text-low-contrast mt-10">
              {t("Payments.Stripe.addTuto")}
            </p>
            <iframe
              width="470"
              height="315"
              src="https://www.youtube.com/embed/HQsBtunEwmM?si=WuBCmwEEDAvOykFU"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
        </div>

        <Separator />

        <div className="flex gap-3">
          <Button type="secondary" onClick={onClose}>
            {t("Global.cancel")}
          </Button>
          <Button RightIcon={CheckWhiteIcon} disabled={true}>
            {t("Global.done")}
          </Button>
        </div>
      </div>
    </RightModal>
  );
};
