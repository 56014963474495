import React, {useState} from "react";
import {RightModal} from "../../../Common/RightModal/RightModal";
import {useTranslation} from "react-i18next";
import {Button} from "../../../Common/Button/Button";
import CheckWhiteIcon from "../../../../assets/icons/check-white.svg?react";
import {PictureInput} from "../../../Common/PictureInput/PictureInput";
import {ValueType} from "../../../../types/commonTypes";
import {File} from "../../../../types/GETTypes";
import PhotoIcon from "../../../../assets/icons/photo.svg?react";
import {cn} from "../../../../helpers/classHelper";
import {CustomPictureInputProps} from "../../../Common/PictureInput/PictureInput.type";
import {Controller, useForm} from "react-hook-form";
import {TextInput} from "../../../Common/TextInput/TextInput";
import {ErrorMessage} from "../../../Common/ErrorMessage/ErrorMessage";
import paths from "../../../../constants/paths";
import {post} from "../../../../helpers/APIHelper";

interface AddResourceModalProps {
  isVisible: boolean;
  onClose: () => void;
  data: any;
  reservationId: string | undefined;
  onSuccess: () => Promise<void>;
}

const CUSTOMINPUT = React.forwardRef(
  ({disabled, errorFile}: CustomPictureInputProps, ref) => {
    const {t} = useTranslation();
    const fileRef = ref as React.RefObject<HTMLInputElement>;
    if (disabled) {
      return <></>;
    }
    return (
      <div className="flex flex-col">
        <div
          className={cn(
            `flex flex-col items-center justify-center w-full h-full bg-element-background rounded-6px border-1 border-element-border gap-4 border-dashed`,
            disabled
              ? "bg-element-border/50"
              : "cursor-pointer  hover:bg-element-background-active",
            errorFile ? "border-red-500" : ""
          )}
          onClick={() => fileRef.current?.click()}
        >
          <PhotoIcon className="w-20 h-20 pt-6" />
          <p className="font-semibold text-high-contrast text-center pb-6">
            {t("Rental.Contracts.Modal.AddModal.addFiles")}
          </p>
        </div>
        {errorFile && <ErrorMessage errorType="FORM">{errorFile}</ErrorMessage>}
      </div>
    );
  }
);

const AddResourceModal = ({
  isVisible,
  onClose,
  data,
  reservationId,
  onSuccess,
}: AddResourceModalProps) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [files, setFiles] = useState<Blob[]>([]);

  const form = useForm<{name: string; file: Blob}>({
    defaultValues: {
      name: "",
      file: "",
    },
  });

  const close = () => {
    form.setValue("name","")
    setFiles([])
    onClose();
  }

  const handleAdd = async (data: {name: string}) => {
    setError("")
    setLoading(true);
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("file", files?.[0]);
    const response = await post(
      `${
        import.meta.env.VITE_API_URL
      }${paths.API.CONTRACTS_DOCS.CREATE_RESOURCE(Number(reservationId))}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (response?.data?.success) {
      close()
      onSuccess();
    } else {
      setError(response?.response?.data?.message || response?.message);
    }
    setLoading(false);
  };

  const hasFile = () => {
    if (files.length > 0) {
      return true;
    }
    return false;
  };

  const onCancel = () => {
    close()
  };

  return (
    <RightModal
      title={t("Rental.Contracts.Modal.AddModal.title")}
      isVisible={isVisible}
      onClose={onCancel}
    >
      <div className="flex flex-col space-y-6 w-full">
        <div>
          <Controller
            control={form.control}
            name="name"
            rules={{
              required: {
                value: true,
                message: t("Rental.Contracts.Contracts.Modal.formRequired"),
              },
            }}
            render={({field: {value, onChange}}) => (
              <TextInput
                label={t("Rental.Checklist.Upsert.name")}
                required={true}
                placeholder={t(
                  "Rental.Contracts.Contracts.Modal.formNamePlaceholder"
                )}
                disabled={loading}
                value={value}
                error={form.formState.errors.name?.message}
                onChangeText={onChange}
              />
            )}
          />
        </div>
        <div className="flex flex-col space-y-4">
          <Controller
            control={form.control}
            name="file"
            rules={{
              validate: {
                notEmpty: (value) => {
                  if (!hasFile()) {
                    return t("Rental.Contracts.Contracts.Modal.formRequired");
                  }
                  return true;
                },
              },
            }}
            render={({field: {value, onChange}}) => (
              <PictureInput
                onChangeNewFiles={setFiles}
                newFiles={files}
                displayCover={false}
                disabled={loading}
                disabledCustomInput={hasFile()}
                activePreviewSystem={false}
                hideInput
                CUSTOMINPUT={CUSTOMINPUT}
                classNames={{
                  divInput: "flex-col",
                  img: "rounded h-50 w-50",
                  divImg: "h-50 w-50",
                }}
                errorFile={form.formState.errors.file?.message}
              />
            )}
          />
        </div>
        <ErrorMessage>{error}</ErrorMessage>
        <div className="flex flex-1 gap-x-3 justify-between w-full">
          <Button type="secondary" onClick={onCancel} disabled={loading}>
            {t("Global.cancel")}
          </Button>
          <Button
            type="primary"
            onClick={form.handleSubmit(handleAdd)}
            loading={loading}
            RightIcon={CheckWhiteIcon}
          >
            {t("Rental.Contracts.Modal.AddModal.title")}
          </Button>
        </div>
      </div>
    </RightModal>
  );
};

export default AddResourceModal;
