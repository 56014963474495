import React from "react";
import {RentalLightBookingItemResponse} from "../../../../types/GETTypes";
import CheckSuccessIcon from "../../../../assets/icons/check-circle-green.svg?react";
import {Badge} from "../../../Common/Badge/Badge";
import {useTranslation} from "react-i18next";
interface BookingConnectSuccessProps {}

const BookingSynchroSuccessCard = ({}: BookingConnectSuccessProps) => {
  const {t} = useTranslation();
  return (
    <div className="space-y-2">
      <div className="flex flex-col bg-success-light items-center justify-center p-8 py-6 my-3 space-y-4">
        <CheckSuccessIcon className="h-10 w-10" />
        <div className="flex flex-col text-success-text space-y-4 items-center justify-center text-center">
          <p>
           {t('Progression.synchroSuccessTitle')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default BookingSynchroSuccessCard;
