import React from "react";
import { Button } from "../../Common/Button/Button";
import { useTranslation } from "react-i18next";
import WebsiteModel2Image from "../../../assets/images/website-model2.svg?react";
import WebsiteModelChicImage from "../../../assets/images/website-model-chic.svg?react";
import EyeIcon from "../../../assets/icons/eye.svg?react";
import { cn } from "../../../helpers/classHelper";

export const WebsiteTemplateSelect: React.FC<{
  disabled?: boolean;
  selectedTemplate: "MODEL_2.0" | "MODEL_CHIC";
  onSelectTemplate: (value: "MODEL_2.0" | "MODEL_CHIC") => void;
}> = ({ disabled, selectedTemplate, onSelectTemplate }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-3">
      {/* Modèle 2.0 */}
      <div
        className={cn(
          "flex border-1 border-element-border rounded-6px h-32 overflow-hidden gap-3 items-center bg-element-background  cursor-pointer",
          selectedTemplate === "MODEL_2.0" && "border-active bg-white",
          disabled && "cursor-not-allowed"
        )}
        onClick={() => onSelectTemplate("MODEL_2.0")}
      >
        <WebsiteModel2Image className="h-full ml-[-8px]" />

        <div className="flex flex-col gap-2">
          <p className="font-semibold text-lg text-high-contrast">Modele 2.0</p>
          <p className="font-light text-low-contrast">Moderne, coloré, frais</p>
          <Button type="secondary" RightIcon={EyeIcon}>
            {t("Website.Add.Templates.view")}
          </Button>
        </div>
      </div>

      {/* Modèle Chic */}
      <div
        className={cn(
          "flex border-1 border-element-border rounded-6px h-32 overflow-hidden gap-3 items-center bg-element-background  cursor-pointer",
          selectedTemplate === "MODEL_CHIC" && "border-active bg-white",
          disabled && "cursor-not-allowed"
        )}
        onClick={() => onSelectTemplate("MODEL_CHIC")}
      >
        <WebsiteModelChicImage className="h-full ml-[-8px]" />

        <div className="flex flex-col gap-2">
          <p className="font-semibold text-lg text-high-contrast">
            Modele Chic
          </p>
          <p className="font-light text-low-contrast">
            Classe, luxueux, soigné
          </p>
          <Button type="secondary" RightIcon={EyeIcon}>
            {t("Website.Add.Templates.view")}
          </Button>
        </div>
      </div>
    </div>
  );
};
