import React, { Dispatch, SetStateAction } from "react";
import { PageMenu } from "../../../Common/PageMenu/PageMenu";
import ContractIcon from "../../../../assets/icons/contracts-paper.svg?react";
import ContractHighIcon from "../../../../assets/icons/contracts-paper.svg?react";

export const RentalContractMenu = ({
  anchor,
  setAnchor,
}: {
  anchor: string;
  setAnchor: Dispatch<SetStateAction<string>>;
}) => {
  return (
    <PageMenu
      anchor={anchor}
      setAnchor={setAnchor}
      items={[
        {
          Icon: ContractIcon,
          IconActive: ContractHighIcon,
          anchor: "contract-card",
          labelI18n: "Rental.Contracts.Contracts.title",
        },
      ]}
    />
  );
};
