import paths from "../../constants/paths";
import {
  ProgressionStepEnum,
  ProgressionStepStatusEnum,
} from "../../enums/GETenums";
import { post } from "../../helpers/APIHelper";
import { RentalLightListItemResponse } from "../../types/GETTypes";

export type CompleteCurrentStepProgressionPayload = {
  progression_step: ProgressionStepEnum;
  progression_step_pending_progress: string | null;
  progression_step_status: ProgressionStepStatusEnum;
};

/**
 * Completes the current progression step by making an API call.
 *
 * @param {Object} params - The parameters for the function.
 * @param {ProgressionStepEnum} params.step - The current progression step to be completed.
 * @param {Function} params.onSuccess - Callback function to be called on successful completion of the step.
 * @param {Function} params.onError - Callback function to be called if there is an error during the process.
 * @param {Function} [params.onStart] - Optional callback function to be called at the start of the process.
 * @param {Function} [params.onEnd] - Optional callback function to be called at the end of the process.
 *
 * @returns {Promise<void>} - A promise that resolves when the operation is complete.
 */
export const useCompleteCurrentStepProgression = async ({
  step,
  onSuccess,
  onError,
  onStart,
  onEnd,
}: {
  step: ProgressionStepEnum;
  onSuccess: (data: CompleteCurrentStepProgressionPayload) => void;
  onError: (message: string | null) => void;
  onStart?: () => void;
  onEnd?: () => void;
}) => {
  try {
    if (typeof onStart === "function") {
      onStart();
    }
    const response = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.COMPLETE_PROGRESSION_STEP}`,
      { progression_step: step }
    );

    if (response?.data?.success) {
      onSuccess(response?.data.result);
    } else {
      onError(response?.response?.data?.message ?? response.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

/**
 * Updates the progression of a rental item.
 *
 * @param {RentalLightListItemResponse | undefined} newCurrentRental - The new rental item data to update.
 * @param {() => void} onSuccess - Callback function to be executed on successful update.
 * @param {(message: string | undefined) => void} onError - Callback function to be executed on error with an optional error message.
 * @param {() => void} [onStart] - Optional callback function to be executed at the start of the update process.
 * @param {() => void} [onEnd] - Optional callback function to be executed at the end of the update process, regardless of success or failure.
 */
export const useUpdateProgression = async (
  newCurrentRental: RentalLightListItemResponse | undefined,
  onSuccess: () => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  try {
    if (typeof onStart === "function") {
      onStart();
    }

    let res = null;

    res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.UPDATE_FROM_PROGRESSION}`,
      newCurrentRental
    );

    if (res?.data?.success) {
      onSuccess();
    } else {
      onError(res?.response?.data?.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};
