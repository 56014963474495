import axios from "axios";

export const geocodeLatLng = (lat: number, lng: number) => {
  return new Promise<string>((resolve, reject) => {
    const geocoder = new window.google.maps.Geocoder();
    const latlng = { lat, lng };

    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === "OK") {
        if (results && results[0]) {
          resolve(results[0].formatted_address);
        } else {
          reject("No results found");
        }
      } else {
        reject("Geocoder failed due to: " + status);
      }
    });
  });
};

export const getCityAndZip = async (address: string) => {
  const apiKey = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
    address
  )}&key=${apiKey}`;

  try {
    const response = await axios.get(url);
    const results = response.data.results;

    if (results.length > 0) {
      const addressComponents = results[0].address_components;
      let city = "";
      let zip = "";
      let countryCode = "";
      const location = results[0].geometry.location;

      for (const component of addressComponents) {
        if (component.types.includes("locality")) {
          city = component.long_name;
        }

        if (component.types.includes("postal_code")) {
          zip = component.long_name;
        }

        if (component.types.includes("country")) {
          countryCode = component.short_name;
        }
      }

      return {
        city,
        zip,
        countryCode,
        latitude: location.lat,
        longitude: location.lng,
      };
    } else {
      throw new Error("No results found");
    }
  } catch (error) {
    console.error(error);
    return {
      city: "",
      zip: "",
      countryCode: "",
      longitude: null,
      latitude: null,
    };
  }
};
