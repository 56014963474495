import React from "react";
import { useTranslation } from "react-i18next";
import CameraIcon from "../../../../assets/icons/camera.svg?react";
import EditIcon from "../../../../assets/icons/edit.svg?react";
import { getCurrentCanEdit } from "../../../../helpers/workspaceHelper";
import useUserStore from "../../../../stores/useUserStore";
import { Card } from "../../../Common/Card/Card";
import { PictureInput } from "../../../Common/PictureInput/PictureInput";
import { RentalPhotosCardProps } from "./RentalPhotosCard.type";
import { RentalPhotosCardSkeleton } from "./RentalPhotosCardSkeleton";

export const RentalPhotosCard: React.FC<RentalPhotosCardProps> = ({
  loading,
  modal,
  title,
  part,
  oldFiles,
  newFiles,
  workspaceOwner,
}) => {
  const userStore = useUserStore();
  const { t } = useTranslation();

  if (loading) return <RentalPhotosCardSkeleton />;

  return (
    <Card
      Icon={CameraIcon}
      label={title ?? t("Rental.Infos.Photos.title")}
      anchor="photos-card"
      button={{
        Icon: EditIcon,
        label: t("Global.edit"),
        type: "secondary",
        disabled: !getCurrentCanEdit({
          user: userStore.user,
          workspaceOwner: workspaceOwner,
        }),
        onClick: modal.open,
      }}
    >
      <div className="flex flex-col space-y-3">
        {part === "website" && (
          <p className="text-xs text-center select-none text-low-contrast">
            {t("Rental.Website.CarouselPhotos.infos")}
          </p>
        )}

        <div className="flex flex-row flex-wrap">
          <PictureInput
            disabled={true}
            mode="wrap"
            oldFiles={oldFiles}
            newFiles={newFiles}
            onWrapButtonClick={modal.open}
            activePreviewSystem={false}
            activeMultiPreviewSystem={true}
          />
        </div>
      </div>
    </Card>
  );
};
