import React, { useCallback, useEffect, useState } from "react";
import { ManagementLayout } from "../../components/Layout/ManagementLayout/ManagementLayout";
import { useTranslation } from "react-i18next";
import { TextInput } from "../../components/Common/TextInput/TextInput";
import { Button } from "../../components/Common/Button/Button";
import SearchIcon from "../../assets/icons/search.svg?react";
import PlusWhiteIcon from "../../assets/icons/plus-white.svg?react";
import { useModal } from "../../hooks/useModal";
import { ViewMemberModal } from "../../components/Management/ViewMember/ViewMemberModal";
import {
  OperationalManagementRoleListItemResponse,
  OperationalManagementTeamListItemResponse,
  OperationalManagementTeamMemberResponse,
  WorkspaceResponse,
} from "../../types/GETTypes";
import { get } from "../../helpers/APIHelper";
import paths from "../../constants/paths";
import { ManagementTeamListGroupItem } from "../../components/Management/ManagementTeamList/ManagementTeamListGroupItem";
import { RemoveMemberModal } from "../../components/Management/RemoveMember/RemoveMemberModal";
import { debounce } from "lodash";
import { AddMemberModal } from "../../components/Management/AddMember/AddMemberModal";
import { ValueType } from "../../types/commonTypes";
import { EditTeamModal } from "../../components/Management/EditTeam/EditTeamModal";
import { AddTeamModal } from "../../components/Management/AddTeam/AddTeamModal";
import { RemoveTeamModal } from "../../components/Management/RemoveTeam/RemoveTeamModal";
import { EditMemberModal } from "../../components/Management/EditMember/EditMemberModal";
import { ManagementTeamListGroupItemSkeleton } from "../../components/Management/ManagementTeamList/ManagementTeamListGroupItemSkeleton";
import useUserStore from "../../stores/useUserStore";
import { LeaveTeamModal } from "../../components/Management/LeaveTeam/LeaveTeamModal";
import { WorkspaceActionModal } from "../../components/WorskspaceActionModal/WorkspaceActionModal";

export const ManagementTeamListPage = () => {
  const userStore = useUserStore();
  const { t } = useTranslation();

  const addMemberModal = useModal<{ teamId: ValueType }>();
  const viewMemberModal = useModal<{
    member: OperationalManagementTeamMemberResponse;
    team: OperationalManagementTeamListItemResponse;
  }>();
  const editMemberModal = useModal<{
    member: OperationalManagementTeamMemberResponse;
  }>();
  const removeMemberModal = useModal<{
    member: OperationalManagementTeamMemberResponse;
    team: OperationalManagementTeamListItemResponse;
  }>();

  const wsModal = useModal();
  const [selectedWS, setSelectedWS] = useState<WorkspaceResponse | undefined>();
  const addTeamModal = useModal();
  const editTeamModal = useModal<OperationalManagementTeamListItemResponse>();
  const removeTeamModal = useModal<OperationalManagementTeamListItemResponse>();
  const leaveTeamModal = useModal<OperationalManagementTeamListItemResponse>();

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [errorRoles, setErrorRoles] = useState<string | null>(null);
  const [errorMembers, setErrorMembers] = useState<string | null>(null);
  const [numberTeamsInWorkspace, setNumberTeamsInWorkspace] =
    useState<number>(0);

  const [teams, setTeams] = useState<
    OperationalManagementTeamListItemResponse[]
  >([]);

  const [roles, setRoles] = useState<
    OperationalManagementRoleListItemResponse[]
  >([]);

  const [members, setMembers] = useState<
    OperationalManagementTeamMemberResponse[]
  >([]);

  const fetchTeams = useCallback(async (text?: string) => {
    setLoading(true);

    let URL = `${import.meta.env.VITE_API_URL}${
      paths.API.OPERATIONNAL_MANAGEMENT.TEAMS
    }`;

    if (text && text !== "") URL += `?search=${text}`;

    const res = await get(URL);

    if (res.data?.success) {
      setTeams(res.data.result.teams);
    } else {
      setError(res.response.data.message);
    }

    setLoading(false);
  }, []);

  const fetchRoles = useCallback(async () => {
    let URL = `${import.meta.env.VITE_API_URL}${
      paths.API.OPERATIONNAL_MANAGEMENT.ROLES
    }`;

    const res = await get(URL);

    if (res.data?.success) {
      setRoles(res.data.result.postes);
    } else {
      setErrorRoles(res.response.data.message);
    }
  }, []);

  const fetchMembers = useCallback(async () => {
    let URL = `${import.meta.env.VITE_API_URL}${
      paths.API.OPERATIONNAL_MANAGEMENT.MEMBERS
    }`;

    const res = await get(URL);

    if (res.data?.success) {
      setMembers(res.data.result.members);
    } else {
      setErrorMembers(res.response.data.message);
    }
  }, []);

  const fetchNumberTeamsInWorkspace = useCallback(async (team) => {
    console.log("fetchNumberTeamsInWorkspace", leaveTeamModal.data);
    let URL = `${import.meta.env.VITE_API_URL}${
      paths.API.OPERATIONNAL_MANAGEMENT.ALL_TEAMS_IN_WORKSPACE
    }/${team.id}`;

    const res = await get(URL);

    if (res.data?.success) {
      setNumberTeamsInWorkspace(res.data.message.number);
    } else {
      //TODO: handle error
    }
  }, []);

  const handleLeaveTeam = (data: OperationalManagementTeamListItemResponse) => {
    fetchNumberTeamsInWorkspace(data);
    console.log("handleLeaveTeam", numberTeamsInWorkspace);
    leaveTeamModal.open(data);
  };

  useEffect(() => {
    fetchTeams();
    fetchRoles();
    fetchMembers();
  }, []);

  const debouncedSearch = React.useRef(
    debounce(async (search: string) => {
      fetchTeams(search);
    }, 300)
  ).current;

  React.useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const handleSearch = (text: string) => {
    setLoading(true);
    debouncedSearch(text);
  };

  const reload = () => {
    self.location.reload();
  };

  const handleUpdateTeams = (
    newTeam: OperationalManagementTeamListItemResponse
  ) => {
    reload();
    // const teamIndex = teams.findIndex((team) => team.id === newTeam.id);

    // let tmpTeams = teams;
    // tmpTeams[teamIndex] = newTeam;

    // setTeams(tmpTeams);
  };

  const handleAddToTeams = (
    newTeam: OperationalManagementTeamListItemResponse
  ) => {
    reload();
    // setTeams([...teams, newTeam]);
  };

  const handleRemoveToTeam = (teamId: ValueType) => {
    reload();

    //   setTeams(teams.filter((team) => team.id !== teamId));
    //   ("");
  };

  const handleAddMemberToTeam = (
    member: OperationalManagementTeamMemberResponse
  ) => {
    reload();
    //   const teamIndex = teams.findIndex(
    //     (team) => team.id === addMemberModal.data?.teamId
    //   );
    //   const newTeams = teams;
    //   newTeams[teamIndex].members.push(member);

    //   setTeams(newTeams);
  };

  // Event handlers

  const handleEditFromAddMember = (
    data:
      | {
          member: OperationalManagementTeamMemberResponse;
          team: OperationalManagementTeamListItemResponse;
        }
      | null
      | undefined
  ) => {
    viewMemberModal.close();
    editMemberModal.open({ member: data?.member! });
  };

  const handleSuccessRemove = (
    newTeam: OperationalManagementTeamListItemResponse
  ) => {
    handleUpdateTeams(newTeam);
    viewMemberModal.close();
  };

  const handleSuccessAdd = (
    newTeam: OperationalManagementTeamListItemResponse
  ) => {
    handleAddToTeams(newTeam);
    addTeamModal.close();
  };

  const handleSuccessEdit = (
    updatedTeam: OperationalManagementTeamListItemResponse
  ) => {
    handleUpdateTeams(updatedTeam);
    editTeamModal.close();
  };

  const handleSuccessRemoveTeam = (teamId: ValueType) => {
    handleRemoveToTeam(teamId);

    editTeamModal.close();
    removeTeamModal.close();
  };

  const handleSuccessLeaveTeam = (teamId: ValueType) => {
    handleRemoveToTeam(teamId);

    editTeamModal.close();
    leaveTeamModal.close();
  };

  const handleAddTeam = () => {
    wsModal.open();
  };

  const handleSelectWS = (nextWS: WorkspaceResponse) => {
    setSelectedWS(nextWS);
    addTeamModal.open();
  };

  return (
    <>
      <AddMemberModal
        isVisible={addMemberModal.isVisible}
        data={addMemberModal.data}
        roles={roles}
        onAddSuccess={handleAddMemberToTeam}
        onClose={addMemberModal.close}
      />

      <ViewMemberModal
        isVisible={viewMemberModal.isVisible}
        data={viewMemberModal.data}
        onRemove={(data) => removeMemberModal.open(data)}
        onEdit={handleEditFromAddMember}
        onClose={viewMemberModal.close}
      />

      <EditMemberModal
        isVisible={editMemberModal.isVisible}
        data={editMemberModal.data}
        roles={roles}
        teams={teams?.map((team) => {
          return { id: team.id, name: team.name };
        })}
        onClose={editMemberModal.close}
        onSuccess={reload}
      />

      <RemoveMemberModal
        isVisible={removeMemberModal.isVisible}
        data={removeMemberModal.data}
        onClose={removeMemberModal.close}
        onSuccess={handleSuccessRemove}
      />

      <WorkspaceActionModal
        action="operational_management_team"
        isVisible={wsModal.isVisible}
        onClose={wsModal.close}
        onSelectWS={handleSelectWS}
      />

      <AddTeamModal
        selectedWS={selectedWS}
        members={members}
        isVisible={addTeamModal.isVisible}
        onClose={addTeamModal.close}
        onSuccess={handleSuccessAdd}
      />

      <EditTeamModal
        members={members}
        team={editTeamModal.data}
        isVisible={editTeamModal.isVisible}
        onClose={editTeamModal.close}
        onRemoveTeam={removeTeamModal.open}
        onSuccess={handleSuccessEdit}
      />

      <RemoveTeamModal
        isVisible={removeTeamModal.isVisible}
        data={removeTeamModal.data}
        onClose={removeTeamModal.close}
        onSuccess={handleSuccessRemoveTeam}
      />

      <LeaveTeamModal
        isVisible={leaveTeamModal.isVisible}
        data={leaveTeamModal.data}
        onClose={leaveTeamModal.close}
        onSuccess={handleSuccessLeaveTeam}
        numberTeamsInWorkspace={numberTeamsInWorkspace}
      />

      <ManagementLayout subTitle="teams" sidebarActiveSubItem="teams">
        {/* Search and add bar */}
        <div className="flex flex-row gap-4 items-center mt-4">
          <TextInput
            Icon={SearchIcon}
            size="large"
            placeholder={t("Management.searchMember")}
            onChangeText={handleSearch}
          />
          <div>
            <Button
              RightIcon={PlusWhiteIcon}
              disabled={!userStore.canEdit}
              onClick={handleAddTeam}
            >
              {t("Management.addTeam")}
            </Button>
          </div>
        </div>

        <div className="mt-4 flex flex-col gap-4">
          {loading && <ManagementTeamListGroupItemSkeleton />}

          {!loading &&
            teams?.map((team) => (
              <ManagementTeamListGroupItem
                key={team.id}
                team={team}
                onAdd={(teamId) => addMemberModal.open({ teamId })}
                onEdit={(team) => editTeamModal.open(team)}
                onViewMember={(data) => viewMemberModal.open(data)}
                onEditMember={(data) => editMemberModal.open(data)}
                onLeave={(data) => handleLeaveTeam(data)}
              />
            ))}
        </div>
      </ManagementLayout>
    </>
  );
};
