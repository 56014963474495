import React, {useEffect, useState} from "react";
import ArrowLeftIcon from "../../../../../../assets/icons/arrow-left.svg?react";
import {useTranslation} from "react-i18next";
import useGuestWebsiteStore from "../../../../../../stores/guestWebsiteStore/useGuestWebsiteStore";
import {Button} from "../../../../../Common/Button/Button";
import TitleComponent from "../../../../Common/Template2.0/TitleComponent";
import ReservationDetailsComponent from "./ReservationDetailsComponent";
import ReservationCheckinsCard from "../../Common/ReservationCheckinsCard";
import InfoBlock from "./InfoBlock";
import InstantReservation from "./InstantReservation";
import TitleBlockComponent from "../../Common/TitleBlockComponent";
import ImageDescriptionCard from "./ImageDescriptionCard";
import MapComponent from "../../../../../GuestPageUser/Common/MapComponent";
import GalleryComponent from "./GalleryComponent";
import CheckWhiteIcon from "../../../../../../assets/icons/check-white.svg?react";
import {useForm} from "react-hook-form";
import useGuestWebsite from "../../../../../../hooks/useGuestWebsite";
import moment from "moment";
import DepositBlockComponent from "./DepositBlockComponent";
import RefundableBlockComponent from "./RefundableBlockComponent";
import {CalendarInputValueRange} from "../../../../../Common/CalendarInput/CalendarInput.type";

interface RentalDetailsHomePageProps {
  loading: boolean;
}
export interface ReservationStep1Form {
  checkinsDates: CalendarInputValueRange;
  rental_id: string;
  adults_count: number;
  children_count: number;
  platform_id: number;
}
export interface ReservationStep1Data {
  checkin: string;
  checkout: string;
  rental_id: string;
  adults_count: number;
  children_count: number;
  platform_id: number;
}

export const isValidDate = (dateString: string) => {
  if (dateString) {
    // Check if the date is in the format YYYY-MM-DD and is valid
    const isCorrectFormat = moment(dateString, "YYYY-MM-DD", true).isValid();

    if (!isCorrectFormat) {
      return false;
    }

    // Extract the year and ensure it is a valid 4-digit year starting with 1 or 2
    const year = parseInt(dateString.split("-")[0], 10);
    return year >= 1000 && year <= 2999;
  }
  return false;
};

const RentalDetailsHomePage = ({loading}: RentalDetailsHomePageProps) => {
  const {t} = useTranslation();
  const {
    step1Data,
    isLoadingReservationStep1: isLoadingStep1,
    reservationStep1,
    errorWebsite,
    setErrorWebsite,
  } = useGuestWebsite();
  const {
    setTabGuestWebsite,
    previousTab,
    tabGuestWebsite,
    setRentalDetailTab,
    rentalData,
    setStep1Data: setStep1DataStore,
    dataGuestWebsite,
    checkinsDates,
    setCheckinsDates,
  } = useGuestWebsiteStore();
  const {name, address, city, postal_code, guest_page} = rentalData || {};
  const {styles} = dataGuestWebsite || {};
  const {color_background, color_text} = styles || {};
  const location = `${address}, ${postal_code} ${city}`;
  const extras = guest_page?.extras_available;
  const services = rentalData?.website.services;
  const description = rentalData?.website?.description?.title;
  const [disableStep1Button, setDisableStep1Button] = useState(true);

  const form = useForm<ReservationStep1Form>({
    defaultValues: {
      checkinsDates: checkinsDates,
      adults_count: 2,
      children_count: 0,
      platform_id: 54, //DIRECT
      rental_id: String(rentalData?.id),
    },
  });

  const onSubmitStep1 = async (data: ReservationStep1Form) => {
    const checkin = moment(data.checkinsDates[0]).format("YYYY-MM-DD");
    const checkout = moment(data.checkinsDates[1]).format("YYYY-MM-DD");
    const { checkinsDates, ...newData } = data;
    if (checkin && checkout) {
      await reservationStep1({...newData, checkin, checkout});
    }
  };

  const onSubmitToStep2 = async () => {
    if (step1Data) {
      setCheckinsDates(form.getValues("checkinsDates"));
      setRentalDetailTab("information");
    }
  };

  const triggerSubmitIfFilled = () => {
    const {checkinsDates, adults_count, children_count} = form.getValues();
    const checkin = checkinsDates?.[0];
    const checkout = checkinsDates?.[1];
    if (checkinsDates.length !== 2 || checkinsDates.includes(null)) {
      setErrorWebsite(t("AddReservation.Infos.Error.dates"));
    } else if (
      checkin &&
      checkout &&
      adults_count !== undefined &&
      children_count !== undefined
    ) {
      if (moment(checkin).isSame(moment(checkout), "day")) {
        setErrorWebsite(
          t("GuestWebsite.Home.Error.diffDates")
        );
      } else if (moment(checkin).isAfter(moment(checkout), "day")) {
        setErrorWebsite(
          t("GuestWebsite.Home.Error.dateCheckinBeforeCheckout")
        );
      } else {
        form.handleSubmit(onSubmitStep1)();
      }
    }
  };

  const handleStep1Data = () => {
    if (step1Data) {
      setStep1DataStore({
        general_informations: step1Data.general_informations,
        tarification: step1Data.tarification,
      });
      setDisableStep1Button(false);
    } else {
      setStep1DataStore(undefined);
      setDisableStep1Button(true);
    }
  };

  useEffect(() => {
    const subscription = form.watch(() => {
      triggerSubmitIfFilled();
    });

    return () => subscription.unsubscribe();
  }, [form, checkinsDates]);

  useEffect(() => {
    handleStep1Data();
  }, [step1Data, errorWebsite]);

  useEffect(() => {
    if (rentalData?.id) {
      form.setValue("rental_id", String(rentalData.id));
    }
  }, [rentalData]);

  if (loading) {
    return <div className="p-4 md:px-contentWebsite animate-pulse" />;
  }

  return (
    <div
      className="p-4 md:px-contentWebsite"
      style={{backgroundColor: color_background}}
    >
      <Button
        LeftIcon={ArrowLeftIcon}
        type="secondary"
        displayLargeBtn={false}
        className=""
        buttonClassName="md:h-12"
        textClassName="md:text-xl"
        onClick={() =>
          setTabGuestWebsite(
            previousTab !== tabGuestWebsite ? previousTab : "rentals"
          )
        }
        buttonStyleCss={{color: color_text}}
      >
        {t("GuestPage.ExtrasPayment.goBack")}
      </Button>
      <TitleComponent
        title={name ?? ""}
        classNames={{
          title: "text-xxxxl",
          divParent: "justify-start pb-4",
          divLabel: "text-start",
          description: "text-low-contrast font-normal",
        }}
        subtitle={location}
      />
      <ReservationDetailsComponent />
      <GalleryComponent />
      <div className="md:flex md:flex-row p-6 space-y-6 md:space-y-0 pl-2 md:gap-6">
        <div className="md:order-2">
          <ReservationCheckinsCard
            buttonText={t("Global.book")}
            showInputsDate
            showPaymentBreakdownDetails
            RightIcon={CheckWhiteIcon}
            onClick={onSubmitToStep2}
            formStep1={form}
            loading={isLoadingStep1}
            disabledButton={disableStep1Button}
            classNames={{divButton: "mx-0"}}
            error={errorWebsite}
          />
        </div>
        <div className="md:max-w-2xl space-y-12 md:space-y-20 md:order-1">
          <div className="space-y-4 md:space-y-12 ">
            <InstantReservation />
            <InfoBlock />
          </div>
          <div className="">
            <TitleBlockComponent
              title={t("GuestWebsite.Rental.Home.titleBlock1")}
              subtitle={description}
            />
          </div>
          <div className="space-y-4">
            <TitleBlockComponent
              title={t("GuestWebsite.Rental.Home.titleBlock2")}
            />
            <div className="flex flex-wrap sh-rental-list w-full gap-6 items-stretch">
              {services
                ?.sort((a, b) => a.order - b.order)
                ?.map((service, index) => (
                  <div className="w-[45%] md:w-1/3 flex flex-col">
                    <ImageDescriptionCard
                      {...service}
                      index={index}
                      title={service.name ?? ""}
                      image={service.photo ?? ""}
                    />
                  </div>
                ))}
            </div>
          </div>
          <div className="space-y-4">
            <TitleBlockComponent
              title={t("GuestWebsite.Rental.Home.titleBlock3")}
              subtitle={t("GuestWebsite.Rental.Home.subTitleBlock3")}
              classNames={{title: "mb-2", description: "text-sm"}}
            />
            <div className="flex flex-wrap sh-rental-list w-full gap-4 p-4 pl-0 pr-0 rounded-lg items-stretch">
              {extras?.map((extra, index) => (
                <div className="w-[45%] md:w-1/3 flex flex-col">
                  <ImageDescriptionCard
                    {...extra}
                    index={index}
                    subtitle={`${extra?.unit_price ?? ""} ${
                      extra?.currency?.symbol ?? ""
                    }`}
                    title={extra?.name?.["fr"] ?? ""}
                    image={extra?.photo?.[0]?.original_url ?? ""}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="space-y-4 rounded-md">
            {/* <TitleBlockComponent title="Adresse" classNames={{title: "mb-2"}} /> */}
            <MapComponent
              label={t("GuestWebsite.Rental.Home.mapText")}
              address={location ?? ""}
              showGoMapsButton={false}
              classNames={{divParent: "w-full md:w-full"}}
              mapClassNames={{label: "text-xxl font-bold mb-2"}}
              mapContainerStyle={{
                height: "400px",
                width: "100%",
                borderRadius: "0.375rem",
              }}
              styleLabel={{color: color_text}}
            />
          </div>
          <div className="space-y-4">
            <TitleBlockComponent
              title={t("GuestWebsite.Rental.Home.titleBlock4")}
              classNames={{title: "mb-2"}}
            />
            <RefundableBlockComponent loading={loading} />
          </div>
          <div className="space-y-4">
            <TitleBlockComponent
              title={t("GuestWebsite.Rental.Home.titleBlock5")}
              classNames={{title: "mb-2"}}
            />
            <DepositBlockComponent loading={loading} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RentalDetailsHomePage;
