import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  InitialConfigType,
  LexicalComposer,
} from "@lexical/react/LexicalComposer";
import {ContentEditable} from "@lexical/react/LexicalContentEditable";
import {LexicalErrorBoundary} from "@lexical/react/LexicalErrorBoundary";
import {RichTextPlugin} from "@lexical/react/LexicalRichTextPlugin";
import {OnChangePlugin} from "@lexical/react/LexicalOnChangePlugin";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import {
  $getRoot,
  $getSelection,
  $isRangeSelection,
  EditorState,
  FORMAT_TEXT_COMMAND,
  LexicalEditor,
  ParagraphNode,
} from "lexical";
import {$generateHtmlFromNodes, $generateNodesFromDOM} from "@lexical/html";
import {Controller, UseFormReturn} from "react-hook-form";
import {ErrorMessage} from "../ErrorMessage/ErrorMessage";
import {cn} from "../../../helpers/classHelper";
import {ClassNamesType, TextType} from "../../../types/commonTypes";
import {ContractsModalForm} from "../../Rental/Contract/Modal/ContractsModal.type";
import BoldButton from "../../../assets/icons/bold-button.svg?react";
import ItalicButton from "../../../assets/icons/italic-button.svg?react";
import UnderlineButton from "../../../assets/icons/underline-button.svg?react";
import {ContractForm} from "../../Reservation/ContractDocs/Modal/ContractModal.type";
import {
  detectCurrentStyles,
  TextStyleToolbar,
  toggleStyle,
  updateHTML,
} from "../../../helpers/editorHelper";
import {UseModal} from "../../../hooks/useModal";

const Toolbar = () => {
  const [editor] = useLexicalComposerContext();
  const [textStyle, setTextStyle] = useState<TextStyleToolbar>({
    bold: false,
    italic: false,
    underline: false,
  });
  // Attach a listener to detect style changes when the selection changes
  useEffect(() => {
    const removeListener = editor.registerUpdateListener(() => {
      detectCurrentStyles(editor, setTextStyle);
    });
    return () => {
      removeListener();
    };
  }, [editor]);

  return (
    <div className="flex rounded mb-1">
      <button
        type="button"
        onClick={() => toggleStyle(editor, "bold", setTextStyle)}
        className={`px-2 py-2 border border-r-0 rounded-l-md ${
          textStyle.bold ? "bg-gray-200/50" : "bg-white"
        }`}
      >
        <BoldButton className="w-4 h-5" />
      </button>
      <button
        type="button"
        onClick={() => toggleStyle(editor, "italic", setTextStyle)}
        className={`px-2 py-2 border border-r-0 border-l-0 ${
          textStyle.italic ? "bg-gray-200/50" : "bg-white"
        }`}
      >
        <ItalicButton className="w-4 h-5" />
      </button>
      <button
        type="button"
        onClick={() => toggleStyle(editor, "underline", setTextStyle)}
        className={`px-2 py-2 border rounded-r-md border-l-0 ${
          textStyle.underline ? "bg-gray-200/50" : "bg-white"
        }`}
      >
        <UnderlineButton className="w-4 h-5" />
      </button>
    </div>
  );
};

function Placeholder({
  placeholder,
  error,
  classNames,
  modalShortCode,
}: {
  placeholder: string;
  error: string | null | undefined;
  classNames?: ClassNamesType;
  modalShortCode?: UseModal<unknown>;
}) {
  return (
    <div
      className={cn(
        "overflow-hidden absolute text-sm select-none inline-block pointer-events-none text-low-contrast/100 top-[25%] text-ellipsis",
        error ? "top-[25%]" : "",
        !modalShortCode?.isVisible ? "left-[25px]" : "",
        classNames?.placeholder
      )}
    >
      {placeholder}
    </div>
  );
}

const EditorCapturePlugin = React.forwardRef((props: any, ref: any) => {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    ref.current = editor;
    return () => {
      ref.current = null;
    };
  }, [editor, ref]);

  return null;
});

export default function DefaultValuePlugin({
  value = "",
  setFirstMount,
}: {
  value?: string | null;
  setFirstMount: React.Dispatch<React.SetStateAction<number>>;
}) {
  const [editor] = useLexicalComposerContext();
  // const moveCursorToEnd = (editor: LexicalEditor) => {
  //   editor.update(() => {
  //     const root = $getRoot();
  //     const lastChild = root.getLastChild();
  //     if (lastChild) {
  //       const selection = $createRangeSelection();

  //       if ($isTextNode(lastChild)) {
  //         // If the last child is a text node, move the cursor to the end of its text content
  //         const textContentSize = lastChild.getTextContentSize();
  //         selection.anchor.set(lastChild.getKey(), textContentSize, "text");
  //         selection.focus.set(lastChild.getKey(), textContentSize, "text");
  //       } else {
  //         // If the last child is not a text node, move the cursor to the end of the element
  //         selection.anchor.set(
  //           lastChild.getKey(),
  //           lastChild.getChildrenSize(),
  //           "element"
  //         );
  //         selection.focus.set(
  //           lastChild.getKey(),
  //           lastChild.getChildrenSize(),
  //           "element"
  //         );
  //       }

  //       // Apply the selection to the editor
  //       $setSelection(selection);
  //     }
  //   });
  // };

  useEffect(() => {
    if (editor && value) {
      editor.update(() => {
        updateHTML(editor, value, true);
      });
      // moveCursorToEnd(editor);
      editor.focus();
      setFirstMount((val) => val + 1);
    }
  }, [value]);

  return null;
}

const Editor = React.forwardRef(
  (
    {
      placeholder = "",
      rows = 27,
      error,
      disabled = false,
      resize = true,
      classNames,
      onChangeEditor,
      value,
      showToolbar = true,
      modalShortCode,
    }: CustomEditorProps & {
      onChangeEditor: (
        editorState: EditorState,
        editor: LexicalEditor,
        tags: Set<string>
      ) => void;
      value: string;
    },
    ref: any
  ) => {
    const editorConfig: InitialConfigType = {
      namespace: "LexicalEditor",
      onError(error: any) {
        throw error;
      },
      nodes: [ParagraphNode],
      theme: {
        text: {
          bold: "font-bold",
          italic: "italic",
          underline: "underline",
        },
      },
    };

    const [firstMount, setFirstMount] = useState(1);

    const getBorder = () => {
      if (error) return "border-error";
      else return "border-element-border";
    };

    const getTextColor = () => {
      return disabled ? "text-low-contrast" : "text-high-contrast";
    };

    const getOutline = () => {
      if (error) return "focus:outline-error";
      else
        return "focus-within:outline-none focus-within:ring-1  active:border-element-border-active";
    };

    const getRows = () => {
      const lineHeight = 20;
      const height = rows * lineHeight;

      return `${height}px`;
    };

    const getResize = () => {
      let classes: string[] = [];
      if (typeof resize === "boolean" && resize) {
        classes.push("resize-y");
      }

      if (typeof resize === "object") {
        if (resize.x) {
          classes.push("resize-x");
        }

        if (resize.y) {
          classes.push("resize-y");
        }

        if (resize.x && resize.y) {
          classes.push("resize");
        }
      }

      if (classes?.length === 0) {
        classes.push("resize-none");
      }

      return classes.join(" ");
    };

    return (
      <>
        <LexicalComposer initialConfig={editorConfig}>
          <div className="">
            {showToolbar && <Toolbar />}
            <div
              className={cn(
                `mt-1 border-1 rounded-6px p-2 ${getBorder()} ${getTextColor()} ${getResize()} ${getOutline()}`,
                {
                  "bg-element-background-disabled": disabled,
                },
                classNames?.input
              )}
              style={{minHeight: getRows()}}
              onClick={(e) => {
                // Force focus on the editor when clicking anywhere inside the container
                const editor = ref?.current as LexicalEditor;
                if (editor) {
                  editor.focus();
                }
              }}
            >
              <RichTextPlugin
                contentEditable={
                  <ContentEditable
                    disabled={disabled}
                    className="focus:outline-none focus:ring-0 focus:border-transparent w-full"
                    onFocus={(e) => {
                      e.currentTarget.focus();
                    }}
                    onClick={(e) => console.log(e, "ok")}
                  />
                }
                placeholder={
                  <Placeholder
                    placeholder={placeholder}
                    error={error}
                    classNames={classNames}
                    modalShortCode={modalShortCode}
                  />
                }
                ErrorBoundary={LexicalErrorBoundary}
              />
              {firstMount === 1 && (
                <DefaultValuePlugin
                  value={value}
                  setFirstMount={setFirstMount}
                />
              )}
              <OnChangePlugin onChange={onChangeEditor} />
              <EditorCapturePlugin ref={ref} />
            </div>
            <ErrorMessage errorType="FORM">{error}</ErrorMessage>
          </div>
        </LexicalComposer>
      </>
    );
  }
);

export interface GenericCustomEditContent {
  content: string;
}

export type CustomEditorProps = {
  error?: string | null;
  placeholder?: TextType;
  disabled?: boolean;
  rows?: number;
  onSelect?: (event: any) => void;
  classNames?: ClassNamesType;
  resize?: {x: boolean; y: boolean} | boolean;
  formControllerName: keyof (ContractsModalForm | ContractForm);
  form: UseFormReturn<
    ContractsModalForm | ContractForm | GenericCustomEditContent,
    any,
    undefined
  >;
  label?: string;
  required?: boolean;
  requiredMessage?: string;
  showToolbar?: boolean;
  modalShortCode?: UseModal<unknown>;
};

export const CustomEditor = forwardRef<any, CustomEditorProps>((props, ref) => {
  const {
    formControllerName,
    form,
    label,
    required = false,
    requiredMessage,
  } = props;

  const checkIfEditorIsEmpty = (editor: LexicalEditor) => {
    let isEmpty = false;
    editor.getEditorState().read(() => {
      const root = $getRoot();
      isEmpty = root.getTextContent().trim() === "";
    });
    return isEmpty;
  };

  return (
    <>
      <div>
        <p className={cn("mb-1 text-sm font-semibold text-left w-max")}>
          {label}
          {required && (
            <span className={cn("ml-1 text-lg text-active font-normal")}>
              *
            </span>
          )}
        </p>
      </div>
      <Controller
        control={form.control}
        name={formControllerName}
        rules={{
          validate: {
            notEmpty: (value) => {
              if (required) {
                //@ts-ignore
                const editor = ref?.current as LexicalEditor;
                if (editor) {
                  return !checkIfEditorIsEmpty(editor) || requiredMessage;
                }
                return true;
              }
              return true;
            },
          },
        }}
        render={({field: {value, onChange}}) => {
          const onChangeEditor = (
            editorState: EditorState,
            editor: LexicalEditor,
            tags: Set<string>
          ) => {
            editor.update(() => {
              const htmlString = $generateHtmlFromNodes(editor, null);
              onChange(htmlString);
            });
          };

          return (
            <div className="">
              <Editor
                ref={ref}
                {...props}
                onChangeEditor={onChangeEditor}
                value={value}
              />
            </div>
          );
        }}
      />
    </>
  );
});
