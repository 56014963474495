import React from "react";
import {Title} from "../../../Common/Title/Title";
import {cn} from "../../../../helpers/classHelper";
import {PhoneInput} from "../../../Common/PhoneInput/PhoneInput";
import {TextInput} from "../../../Common/TextInput/TextInput";
import {Separator} from "../../../Common/Separator/Separator";
import {SimpleRadio} from "../../../Common/SimpleRadio/SimpleRadio";
import {useTranslation} from "react-i18next";
import {Button} from "../../../Common/Button/Button";
import ArrowDownIcon from "../../../../assets/icons/arrow-down.svg?react";
import ArrowUpIcon from "../../../../assets/icons/arrow-up.svg?react";
import {ContractsDataDetails} from "../../../../types/GETTypes";
import {getFormattedPrice} from "../../../../helpers/currencyHelper";
import {displayDepositPaymentMethod} from "../../../../helpers/paymentHelper";
import moment from "moment";
import {capitalizeFirstLetter} from "../../../../helpers/stringHelper";
import useGuestPageDataStore from "../../../../stores/guestPageStore/useGuestPageDataStore";
import countries from "../../../../constants/countries";

interface ContractInfoProps {
  content: string | undefined;
  openContract: boolean;
  setOpenContract: React.Dispatch<React.SetStateAction<boolean>>;
  classNameContract?: string;
  details: ContractsDataDetails;
  isPreviewMode?: boolean;
}

const ContractInfoComponent = ({
  openContract,
  setOpenContract,
  content,
  classNameContract,
  details,
  isPreviewMode,
}: ContractInfoProps) => {
  const {t} = useTranslation();
  const {
    rental_name,
    rental_address,
    rental_postal_code,
    rental_city,
    billing_host_name,
    billing_address,
    billing_city,
    billing_country,
    billing_postal_code,
    billing_email,
    billing_phone,
    guest_address,
    guest_city,
    guest_first_name,
    guest_email,
    guest_phone,
    guest_business,
    guest_country,
    guest_postal_code,
    guest_last_name,
    reservation_total_ht,
    reservation_total_ttc,
    reservation_payment_schedule,
    reservation_children_counts,
    reservation_adults_counts,
    reservation_total_guests,
    reservation_night_counts,
    reservation_checkin,
    reservation_checkout,
    reservation_payments_details,
  } = details;
  const {userPreview} = useGuestPageDataStore();
  const {
    profile_administrative,
    email: emailPreview,
    phone: phonePreview,
    address: addressPreview,
    city: cityPreview,
    postal_code: postalCodePreview,
    country_id,
  } = userPreview || {};
  const {host_name: hostNamePreview} = profile_administrative || {};
  const countryPreview = countries.find(
    (country) => country.value === country_id
  )?.label;
  console.log("🚀 ~ userPreview:", userPreview);
  return (
    <>
      <div className={cn(classNameContract)}>
        <p
          dangerouslySetInnerHTML={{__html: content ?? ""}}
          className={cn(openContract ? "line-clamp-none" : "line-clamp-[17]")}
        />
        <Button
          RightIcon={openContract ? ArrowUpIcon : ArrowDownIcon}
          onClick={() => setOpenContract(!openContract)}
          type="secondary"
          divParentClassName={cn("relative", openContract ? "" : "mb-[-25px]")}
          buttonClassName={cn(
            "absolute right-[0px]",
            openContract ? "bottom-[5px]" : "bottom-[40px]"
          )}
          displayLargeBtn={false}
        >
          {openContract
            ? t("Rental.Contracts.Modal.ContractModal.closeContract")
            : t("Rental.Contracts.Modal.ContractModal.displayContract")}
        </Button>
      </div>
      <div className="space-y-4">
        {/* Recap Bloc*/}
        <div className="flex flex-col border-1 border-gray-300 bg-element-border/20 rounded-6px p-4 space-y-4">
          <Title>{t("Rental.Contracts.Preview.recapTitle")}</Title>
          <div className="flex flex-col space-y-2">
            <span className="text-high-contrast font-bold">{rental_name}</span>
            <span className="text-low-contrast text-xs">
              {rental_address} {rental_postal_code} {rental_city}
            </span>
          </div>
          <div className="flex flex-col space-y-2">
            <span className="text-high-contrast font-bold">
              {t("GuestPage.Contract.Info.guests")}
            </span>
            <span className="text-low-contrast text-xs">
              Du{" "}
              <span className="text-low-contrast text-xs font-bold">
                {capitalizeFirstLetter(
                  moment(reservation_checkin).format("dddd D MMMM YYYY")
                )}
              </span>{" "}
              au{" "}
              <span className="text-low-contrast text-xs font-bold">
                {capitalizeFirstLetter(
                  moment(reservation_checkout).format("dddd D MMMM YYYY")
                )}
              </span>{" "}
              ({isPreviewMode ? 0 : reservation_night_counts} nuits)
            </span>
          </div>
          <div className="flex flex-col space-y-2">
            <span className="text-high-contrast font-bold">
              {t("GuestPage.Contract.Info.guests")}
            </span>
            <span className="text-low-contrast text-xs">
              {isPreviewMode ? 0 : reservation_total_guests} voyageur(s) :{" "}
              {isPreviewMode ? 0 : reservation_adults_counts} adulte(s) -{" "}
              {isPreviewMode ? 0 : reservation_children_counts} enfant(s){" "}
            </span>
          </div>
          <div className="flex flex-col space-y-2">
            <span className="text-high-contrast font-bold">
              {t("GuestPage.Contract.Info.recap")}
            </span>
            <div className="overflow-hidden rounded-lg border border-element-border">
              <table className="w-full border-collapse">
                <thead className="bg-element-border/60">
                  <tr className="">
                    <th className="border-b py-4 underline text-left pl-4 text-sm md:text-base">
                      {t("GuestPage.Contract.Info.paymentDetailName")}
                    </th>
                    <th className="border-b py-4 pr-2 md:pr-0 text-sm md:text-base">
                      {t("GuestPage.Contract.Info.paymentDetailPrice")}
                    </th>
                    <th className="border-b py-4 text-sm md:text-base">
                      {t("GuestPage.Invoice.quantity")}
                    </th>
                    <th className="border-b py-4 text-sm md:text-base">
                      {t("GuestPage.Invoice.totalHT")}
                    </th>
                    <th className="border-b py-4 text-sm md:text-base">
                      {t("GuestPage.Invoice.tva")}
                    </th>
                    <th className="border-b py-4 text-right pr-4 text-sm md:text-base">
                      {t("GuestPage.Invoice.totalTTC")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {reservation_payments_details?.map((paymentDetail) => (
                    <tr className={cn("cursor-pointer")}>
                      <td className={cn("  text-high-contrast text-left pl-4 text-xs md:text-base")}>
                        {paymentDetail.label}
                      </td>

                      <td className={cn("py-4 text-high-contrast text-sm md:text-base")}>
                        {getFormattedPrice({
                          price: paymentDetail.amount,
                          decimals: 2,
                          currency: "EUR",
                        })}
                      </td>
                      <td
                        className={cn(" py-4 text-high-contrast text-center text-sm md:text-base")}
                      >
                        {paymentDetail.quantity}
                      </td>
                      <td className={cn("py-4 text-high-contrast text-sm md:text-base")}>
                        {getFormattedPrice({
                          price: paymentDetail.total_ht,
                          decimals: 2,
                          currency: "EUR",
                        })}
                      </td>
                      <td className={cn("py-4 text-high-contrast text-sm md:text-base")}>
                        {getFormattedPrice({
                          price: paymentDetail.tva,
                          decimals: 2,
                          symbol: "%",
                        })}
                      </td>
                      <td
                        className={cn(
                          "py-4 text-high-contrast text-right pr-4 text-sm md:text-base"
                        )}
                      >
                        {getFormattedPrice({
                          price: paymentDetail.total_ttc,
                          decimals: 2,
                          currency: "EUR",
                        })}
                      </td>
                    </tr>
                  ))}
                  <tr className={cn("cursor-pointer")}>
                    {/*Total row*/}
                    <td
                      className={cn(
                        "border-t py-4 text-high-contrast text-left font-bold pl-4 text-sm md:text-base"
                      )}
                    >
                      {t("GuestPage.Contract.Info.total")}
                    </td>
                    <td
                      className={cn(
                        "border-t py-4 text-high-contrast font-bold text-sm md:text-base"
                      )}
                    ></td>
                    <td
                      className={cn(
                        "border-t py-4 text-high-contrast  text-center font-bold text-sm md:text-base"
                      )}
                    >
                      {getFormattedPrice({
                        price: reservation_total_ht,
                        decimals: 2,
                        currency: "EUR",
                      })}
                    </td>
                    <td
                      className={cn(
                        "border-t py-4 text-high-contrast font-bold text-sm md:text-base"
                      )}
                    ></td>
                    <td
                      className={cn(
                        "border-t py-4 text-high-contrast font-bold text-sm md:text-base"
                      )}
                    ></td>
                    <td
                      className={cn(
                        "border-t py-4 text-high-contrast text-right font-bold pr-4 text-sm md:text-base"
                      )}
                    >
                      {getFormattedPrice({
                        price: reservation_total_ttc,
                        decimals: 2,
                        currency: "EUR",
                      })}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="flex flex-col space-y-2">
            <span className="text-high-contrast font-bold">
              {t("GuestPage.Contract.Info.cancel")}
            </span>
            <span className="text-low-contrast text-xs">
              {t(
                `${
                  Boolean(reservation_payment_schedule?.is_refundable)
                    ? "AddReservation.Payment.isRefundable"
                    : "AddReservation.Payment.isNotRefundable"
                }`
              )}
            </span>
          </div>
          <div className="flex flex-col space-y-2">
            <span className="text-high-contrast font-bold">
              {t("GuestPage.Contract.Info.deposit")}
            </span>
            <span className="text-low-contrast text-xs">
              {(!isPreviewMode &&
                reservation_payment_schedule?.deposit_value !== null) ||
              reservation_payment_schedule?.deposit_value! > 0
                ? t("AddReservation.Payment.isDeposit", {
                    amount: getFormattedPrice({
                      price: Number(
                        reservation_payment_schedule?.deposit_value
                      ),
                      locale: "fr-FR",
                      decimals: 0,
                    }),
                    paymentMethod:
                      reservation_payment_schedule &&
                      displayDepositPaymentMethod(
                        reservation_payment_schedule,
                        t
                      ),
                  })
                : t("AddReservation.Payment.isNotDeposit")}
            </span>
          </div>
        </div>
        {/* Bailleur Bloc*/}
        <div className="flex flex-col border-1 border-gray-300 bg-element-border/20 rounded-6px p-4 space-y-4">
          <Title>{t("Rental.Contracts.Preview.hostTitle")}</Title>
          <div className="mt-3">
            <TextInput
              label={t("GuestPage.Contract.Info.name")}
              value={isPreviewMode ? hostNamePreview ?? "" : billing_host_name}
              size="large"
              disabled={true}
            />
          </div>
          <div className="flex space-x-2">
            <div className="mt-3 w-1/2">
              <TextInput
                label={t("GuestPage.Contract.Info.address")}
                value={isPreviewMode ? addressPreview : billing_address}
                size="large"
                disabled={true}
              />
            </div>
            <div className="mt-3 w-1/2">
              <TextInput
                label={t("GuestPage.Contract.Info.city")}
                value={isPreviewMode ? cityPreview : billing_city}
                size="large"
                disabled={true}
              />
            </div>
          </div>
          <div className="flex space-x-2">
            <div className="mt-3 w-1/2">
              <TextInput
                label={t("GuestPage.Contract.Info.postalCode")}
                value={isPreviewMode ? postalCodePreview : billing_postal_code}
                size="large"
                disabled={true}
              />
            </div>
            <div className="mt-3 w-1/2">
              <TextInput
                label={t("GuestPage.Contract.Info.country")}
                value={isPreviewMode ? countryPreview : billing_country}
                size="large"
                disabled={true}
              />
            </div>
          </div>
          <div className="mt-3">
            <TextInput
              label={t("GuestPage.Contract.Info.email")}
              value={isPreviewMode ? emailPreview : billing_email}
              size="large"
              disabled={true}
            />
          </div>
          <div className="mt-3">
            <PhoneInput
              label={t("GuestPage.Contract.Info.phone")}
              value={isPreviewMode ? phonePreview : billing_phone}
              disabled={true}
              defaultCountry={"FR"}
            />
          </div>
        </div>
        {/* Preneur Bloc*/}
        <div className="flex flex-col border-1 border-gray-300 bg-element-border/20 rounded-6px p-4 space-y-4">
          <Title>{t("Rental.Contracts.Preview.guest")}</Title>
          <div className="flex space-x-2">
            <div className="mt-3 w-1/2">
              <TextInput
                label={t("GuestPage.Contract.Info.firstName")}
                value={isPreviewMode ? "Henry" : guest_first_name}
                size="large"
                disabled={true}
              />
            </div>
            <div className="mt-3 w-1/2">
              <TextInput
                label={t("GuestPage.Contract.Info.name")}
                value={isPreviewMode ? "Dupont" : guest_last_name}
                size="large"
                disabled={true}
              />
            </div>
          </div>
          <div className="mt-3">
            <TextInput
              label={t("GuestPage.Contract.Info.email")}
              value={
                isPreviewMode ? "henry.dupont@gmail.com" : guest_email ?? ""
              }
              size="large"
              disabled={true}
            />
          </div>
          <div className="mt-3">
            <PhoneInput
              label={t("GuestPage.Contract.Info.phone")}
              value={isPreviewMode ? "+33606060606" : guest_phone ?? ""}
              disabled={true}
              defaultCountry={"FR"}
            />
          </div>
          <Separator accent="dark" />
          <div className="mt-3">
            <span className="text-low-contrast text-xs font-bold">
              {t("GuestPage.Contract.Info.guestType")}
            </span>
          </div>
          <div className="flex items-center gap-3 w-full">
            <div className="flex items-center flex-1 gap-1">
              <SimpleRadio
                value={guest_business ? false : true}
                disabled={true}
              />
              <p
                className={
                  (cn("font-light"), true ? "text-active" : "text-low-contrast")
                }
              >
                {t("GuestPage.Contract.Info.particular")}
              </p>
            </div>

            <div className="flex items-center flex-1 gap-1">
              <SimpleRadio
                value={guest_business ? true : false}
                disabled={true}
              />
              <p
                className={
                  (cn("font-light"),
                  false ? "text-active" : "text-low-contrast")
                }
              >
                {t("GuestPage.Contract.Info.pro")}
              </p>
            </div>
          </div>
          <div className="mt-3 ">
            <TextInput
              label={t("GuestPage.Contract.Info.address")}
              value={
                isPreviewMode ? "Avenue des Champs-Élysées" : guest_address
              }
              size="large"
              disabled={true}
            />
          </div>
          <div className="flex space-x-2">
            <div className="w-1/2">
              <TextInput
                label={t("GuestPage.Contract.Info.postalCode")}
                value={isPreviewMode ? "75008" : guest_postal_code}
                size="large"
                disabled={true}
              />
            </div>
            <div className="w-1/2">
              <TextInput
                label={t("GuestPage.Contract.Info.city")}
                value={isPreviewMode ? "Paris" : guest_city}
                size="large"
                disabled={true}
              />
            </div>
          </div>
          <div className="mt-3">
            <TextInput
              label={t("GuestPage.Contract.Info.country")}
              value={isPreviewMode ? "France" : (guest_country as string)}
              size="large"
              disabled={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ContractInfoComponent;
