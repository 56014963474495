import React from "react";

import CodeIcon from "../../../assets/icons/code.svg?react";
import ArrowUpRightWhiteIcon from "../../../assets/icons/arrow-up-right-white.svg?react";

import { Card } from "../../Common/Card/Card";
import { WebsiteResponse } from "../../../types/GETTypes";
import { useTranslation } from "react-i18next";
import { IntegrateContent } from "../IntegrateContent";
import { Separator } from "../../Common/Separator/Separator";
import { TextInput } from "../../Common/TextInput/TextInput";
import { Button } from "../../Common/Button/Button";

export const WebsiteIntegrateCard: React.FC<{
  website: WebsiteResponse | undefined;
}> = ({ website }) => {
  const { t } = useTranslation();

  return (
    <Card
      Icon={CodeIcon}
      label={t("Website.Integrations.title")}
      anchor="integrations-card"
    >
      <ViewMode website={website} />
    </Card>
  );
};

const ViewMode: React.FC<{ website: WebsiteResponse | undefined }> = ({
  website,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-2">
      <IntegrateContent website={website} />

      <Separator />

      <div className="flex gap-3 items-end">
        <TextInput
          label={t("Website.Integrations.webkey")}
          placeholder=""
          type="clipboard"
          value={website?.integration.api_key ?? ""}
        />

        <Button size="small" RightIcon={ArrowUpRightWhiteIcon}>
          {t("Website.Integrations.tuto")}
        </Button>
      </div>
    </div>
  );
};
