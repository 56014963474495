/**
 * Card for interact with payment policy card
 * with Direct and Website reservation.
 */

import React, { ReactNode } from "react";

import PolicyPlatformCard from "../PolicyPlatformCard";
import HeaderPolicy from "../HeaderPolicy";
import PolicyItemsLayout from "../PolicyItemsLayout";
import BasicItemPolicy from "../BasicItemPolicy";
import { useTranslation } from "react-i18next";
import { Badge } from "../../../Common/Badge/Badge";
import { UseModal } from "../../../../hooks/useModal";
import {
  DirectAndWebsitePolicyResponse,
  RentalResponse,
} from "../../../../types/GETTypes";

import WebsiteIcon from "../../../../assets/logos/website.svg?react";
import moment from "moment/moment";
import { PaymentPolicyValueTypeEnum } from "../../../Payment/Payment.type";

export interface DirectPlatformPoliciesProps {
  rental: RentalResponse;
  modal?: UseModal<{
    policy: DirectAndWebsitePolicyResponse;
  }> | null;
  policy?: DirectAndWebsitePolicyResponse;
}

export default function DirectPlatformPolicies({
  rental,
  modal = null,
  policy,
}: DirectPlatformPoliciesProps) {
  const { t } = useTranslation();

  const directPolicy = policy;

  if (!directPolicy) return null;

  /**
   * function for get Calendar payment
   * translatable text with the payments items.
   */
  const getCalendarPaymentTextNumber = (): string => {
    let number = directPolicy.payments_policy_items.length;
    if (number === 1) {
      let trigger: string;
      let method: string;
      const firstPayment = directPolicy.payments_policy_items[0];

      switch (firstPayment.trigger) {
        case "SPECIFIC_DATE":
          trigger = t("Rental.Policies.Direct.specificDate", {
            date: moment(firstPayment.specific_date).format("DD/MM/YYYY"),
          });
          break;
        default:
        case "AT_RESERVATION":
          trigger = t("Rental.Policies.Direct.toReservation");
          break;
        case "AT_CHECKIN":
          trigger = t("Rental.Policies.Direct.checkin");
          break;
        case "5D_BEFORE_ARRIVAL":
          trigger = t("Rental.Policies.Direct.toDaysBefore", { days: 5 });
          break;
        case "7D_BEFORE_ARRIVAL":
          trigger = t("Rental.Policies.Direct.toDaysBefore", { days: 7 });
          break;
        case "14D_BEFORE_ARRIVAL":
          trigger = t("Rental.Policies.Direct.toDaysBefore", { days: 14 });
          break;
        case "30D_BEFORE_ARRIVAL":
          trigger = t("Rental.Policies.Direct.toDaysBefore", { days: 30 });
          break;
        case "60D_BEFORE_ARRIVAL":
          trigger = t("Rental.Policies.Direct.toDaysBefore", { days: 60 });
          break;
        case "24H_BEFORE_ARRIVAL":
          trigger = t("Rental.Policies.Direct.toDaysBefore", { days: 1 });
          break;
      }

      switch (firstPayment.payment_account_type) {
        default:
        case "by_platform":
          method = t("Rental.Policies.Direct.platform");
          break;
        case "iban":
          method = t("Rental.Policies.Direct.platform");
          break;
        case "cash":
          method = t("Rental.Policies.Direct.cash");
          break;
        case "bank_check":
          method = t("Rental.Policies.Direct.check");
          break;
        case "stripe":
          method = t("Rental.Policies.Direct.stripe");
          break;
        case "by_superhote":
          method = t("Rental.Policies.Direct.superhote");
          break;
      }

      return t("Rental.Policies.Direct.paymentSejourComplet", {
        number: t("Rental.Policies.Direct.une"),
        trigger: trigger,
        method: method,
      });
    }

    return t("Rental.Policies.Direct.paymentSejour", {
      number: number,
    });
  };

  const buildPaymentListComponent = (): ReactNode => {
    return directPolicy.payments_policy_items.map((payment, index) => {
      let labelBadge = String(payment.value);
      let trigger: string;
      let method: string;

      switch (payment.payment_account_type) {
        default:
        case "by_platform":
          method = t("Rental.Policies.Direct.platform");
          break;
        case "iban":
          method = t("Rental.Policies.Direct.platform");
          break;
        case "cash":
          method = t("Rental.Policies.Direct.cash");
          break;
        case "bank_check":
          method = t("Rental.Policies.Direct.check");
          break;
        case "stripe":
          method = t("Rental.Policies.Direct.stripe");
          break;
        case "by_superhote":
          method = t("Rental.Policies.Direct.superhote");
          break;
      }

      switch (payment.value_type) {
        case PaymentPolicyValueTypeEnum.PERCENT:
          labelBadge += "%";
          break;
        case PaymentPolicyValueTypeEnum.FIXED:
          labelBadge += rental.tarification.rules.currency.symbol;
          break;
      }

      switch (payment.trigger) {
        default:
        case "AT_RESERVATION":
          trigger = t("Rental.Policies.Direct.toReservation");
          break;
        case "AT_CHECKIN":
          trigger = t("Rental.Policies.Direct.checkin");
          break;
        case "5D_BEFORE_ARRIVAL":
          trigger = t("Rental.Policies.Direct.toDaysBefore", { days: 5 });
          break;
        case "7D_BEFORE_ARRIVAL":
          trigger = t("Rental.Policies.Direct.toDaysBefore", { days: 7 });
          break;
        case "14D_BEFORE_ARRIVAL":
          trigger = t("Rental.Policies.Direct.toDaysBefore", { days: 14 });
          break;
        case "30D_BEFORE_ARRIVAL":
          trigger = t("Rental.Policies.Direct.toDaysBefore", { days: 30 });
          break;
        case "60D_BEFORE_ARRIVAL":
          trigger = t("Rental.Policies.Direct.toDaysBefore", { days: 60 });
          break;
        case "24H_BEFORE_ARRIVAL":
          trigger = t("Rental.Policies.Direct.toDaysBefore", { days: 1 });
          break;
      }

      return (
        <div
          key={index}
          className={
            "p-1.5 bg-gray-200 rounded flex items-center justify-between"
          }
        >
          <h1 className={"text-gray-500 font-bold"}>
            {t("Rental.Policies.Direct.payment", {
              number: index,
              method: method,
            })}
          </h1>
          <div className={"flex items-center gap-2"}>
            <Badge status={"basic"} label={labelBadge} />
            <Badge status={"basic"} label={trigger} />
          </div>
        </div>
      );
    });
  };

  const getRefundableText = (): string => {
    if (directPolicy.is_refundable) {
      return t("Rental.Policies.Direct.refundable");
    }

    return t("Rental.Policies.Direct.notRefundable");
  };

  const getCautionText = (): string => {
    if (!directPolicy.is_deposit_required) {
      return t("Rental.Policies.Direct.cautionNotRequested");
    }

    let method: string;
    switch (directPolicy.deposit_payment_option) {
      default:
      case "BANK_TRANSFER_TO_REFUND":
        method = t("Rental.Policies.Direct.vir");
        break;
      case "CARD_PAYMENT_TO_REFUND":
        method = t("Rental.Policies.Direct.card");
        break;
      case "BANK_CHECK_OR_CASH_AT_ARRIVAL":
        method = t("Rental.Policies.Direct.bankOrCash");
        break;
      case "PRE_AUTHORISATION":
        method = t("Rental.Policies.Direct.pre");
        break;
    }

    return t("Rental.Policies.Direct.cautionText", {
      amount:
        directPolicy.deposit_value +
        " " +
        rental.tarification.rules.currency.symbol,
      method: method,
      delay:
        directPolicy?.deposit_payment_days_delay &&
        directPolicy?.deposit_payment_days_delay > 0
          ? t("Rental.Policies.Direct.delay", {
              days: directPolicy.deposit_payment_days_delay,
            })
          : "",
    });
  };

  return (
    <PolicyPlatformCard>
      <HeaderPolicy
        Icon={WebsiteIcon}
        title={t("Rental.Policies.Direct.title")}
        onClickEdit={() => {
          if (modal) {
            modal.open({
              policy: directPolicy,
            });
          }
        }}
      />
      <PolicyItemsLayout>
        <BasicItemPolicy
          title={"Calendrier de paiement"}
          text={getCalendarPaymentTextNumber()}
        >
          {directPolicy.payments_policy_items.length > 1 && (
            <div className={"flex flex-col gap-2"}>
              {buildPaymentListComponent()}
            </div>
          )}
        </BasicItemPolicy>

        <BasicItemPolicy
          title={t("Rental.Policies.Direct.cancelConditionTitle")}
          text={getRefundableText()}
        />

        <BasicItemPolicy
          title={t("Rental.Policies.Direct.caution")}
          text={getCautionText()}
        />
      </PolicyItemsLayout>
    </PolicyPlatformCard>
  );
}
