import React, {ReactNode, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {CenterModal} from "../../../Common/CenterModal/CenterModal";
import {Button} from "../../../Common/Button/Button";
import {del, post} from "../../../../helpers/APIHelper";
import paths from "../../../../constants/paths";
import {UseModal} from "../../../../hooks/useModal";
import ArrowSendWhiteIcon from "../../../../assets/icons/send-white.svg?react";
import {Controller, useForm} from "react-hook-form";
import {TextInput} from "../../../Common/TextInput/TextInput";
import {TextAreaInput} from "../../../Common/TextAreaInput/TextAreaInput";
import {InputSelect} from "../../../Common/InputSelect/InputSelect";
import {
  ContractResponse,
  LangType,
  ReservationResponse,
} from "../../../../types/GETTypes";
import {DataContractModal} from "./ContractModal.type";
import {IconType} from "../../../../types/commonTypes";
import {
  DEFAULT_CONTRACT_MESSAGE_DE,
  DEFAULT_CONTRACT_MESSAGE_EN,
  DEFAULT_CONTRACT_MESSAGE_ES,
  DEFAULT_CONTRACT_MESSAGE_FR,
  DEFAULT_CONTRACT_MESSAGE_PT,
  DEFAULT_CONTRACT_SUBJECT_DE,
  DEFAULT_CONTRACT_SUBJECT_EN,
  DEFAULT_CONTRACT_SUBJECT_ES,
  DEFAULT_CONTRACT_SUBJECT_FR,
  DEFAULT_CONTRACT_SUBJECT_PT,
} from "../../../../constants/contracts";
import {defaultLang} from "../../../../constants/lang";

interface SendContractModalProps {
  isVisible: boolean;
  onClose: () => void;
  data: DataContractModal | null | undefined;
  modal: UseModal<DataContractModal | null | undefined>;
  languages: {
    label: string;
    value: string;
    Icon: IconType | undefined;
  }[];
  reservation: ReservationResponse | undefined;
}

interface SendContractModalForm {
  lang: LangType;
  email: string;
  subject: string;
  message: string;
}

const SendContractModal = ({
  isVisible,
  onClose,
  data,
  modal,
  languages,
  reservation,
}: SendContractModalProps) => {
  const {contract} = data || {};
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);

  const form = useForm<SendContractModalForm>({
    defaultValues: {
      lang: "FR",
      email: "",
      subject: DEFAULT_CONTRACT_SUBJECT_FR,
      message: DEFAULT_CONTRACT_MESSAGE_FR,
    },
  });

  const handleSend = async (data: SendContractModalForm) => {
    console.log("🚀 ~ handleSend ~ data:", data);
    setLoading(true);
    if (reservation && contract) {
      const response = await post(
        `${
          import.meta.env.VITE_API_URL
        }${paths.API.CONTRACTS_DOCS.SEND_CONTRACT_BY_RESERVATION(
          reservation?.id,
          contract?.id
        )}`,
        data
      );
      if (response?.data?.success) {
        setLoading(false);
        onClose();
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (reservation && contract) {
      form.setValue("lang", (reservation.guest.locale as LangType) ?? defaultLang);
      form.setValue("email", reservation.guest.email);
      handleDefaultLangValues((reservation.guest.locale as LangType) ?? defaultLang);
    }
  }, [reservation, contract]);

  useEffect(() => {
    handleDefaultLangValues(form.getValues("lang"));
  }, [form.getValues("lang")]);

  const handleDefaultLangValues = (lang: LangType) => {
    let defaultSubject = "";
    let defaultMessage = "";
    if (contract) {
      const hostname = new URL(window.location.href).origin;
      const contractLink = `${hostname}/reservation/${reservation?.id}/guest-page/${reservation?.guest_page_link}?tab=contract&contractId=${contract.id}`;
      switch (lang) {
        case "FR":
          defaultSubject = DEFAULT_CONTRACT_SUBJECT_FR;
          defaultMessage = DEFAULT_CONTRACT_MESSAGE_FR;
          break;
        case "EN":
          defaultSubject = DEFAULT_CONTRACT_SUBJECT_EN;
          defaultMessage = DEFAULT_CONTRACT_MESSAGE_EN;
          break;
        case "ES":
          defaultSubject = DEFAULT_CONTRACT_SUBJECT_ES;
          defaultMessage = DEFAULT_CONTRACT_MESSAGE_ES;
          break;
        case "DE":
          defaultSubject = DEFAULT_CONTRACT_SUBJECT_DE;
          defaultMessage = DEFAULT_CONTRACT_MESSAGE_DE;
          break;
        case "PT":
          defaultSubject = DEFAULT_CONTRACT_SUBJECT_PT;
          defaultMessage = DEFAULT_CONTRACT_MESSAGE_PT;
          break;
        default:
          defaultSubject = DEFAULT_CONTRACT_SUBJECT_FR;
          defaultMessage = DEFAULT_CONTRACT_MESSAGE_FR;
          break;
      }
      const replacedMessage = defaultMessage.replace(
        "[LINK_PLACEHOLDER]",
        contractLink
      );
      form.setValue("subject", defaultSubject);
      form.setValue("message", replacedMessage);
    }
    return {defaultSubject, defaultMessage};
  };

  form.watch("lang");

  return (
    <CenterModal
      title={t("Rental.Contracts.Modal.SendContractModal.title")}
      isVisible={isVisible}
      onClose={onClose}
    >
      <div className="flex flex-col space-y-4">
        <Controller
          control={form.control}
          name="lang"
          rules={{
            required: {
              value: true,
              message: t("Rental.Contracts.Contracts.Modal.formRequired"),
            },
          }}
          render={({field: {value, onChange}}) => (
            <InputSelect
              label={t("Rental.Contracts.Modal.SendContractModal.lang")}
              required={true}
              disabled={loading}
              items={languages}
              selectedValue={value}
              error={form.formState.errors.lang?.message}
              onSelect={onChange}
            />
          )}
        />
        <Controller
          control={form.control}
          name="email"
          rules={{
            required: {
              value: true,
              message: t("Rental.Contracts.Contracts.Modal.formRequired"),
            },
            pattern: {
              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              message: t(
                "Rental.Contracts.Modal.SendContractModal.emailFormat"
              ), // Custom error message
            },
          }}
          render={({field: {value, onChange}}) => (
            <TextInput
              label={t("Rental.Contracts.Modal.SendContractModal.email")}
              required={true}
              disabled={loading}
              value={value}
              error={form.formState.errors.email?.message}
              onChangeText={onChange}
            />
          )}
        />
        <Controller
          control={form.control}
          name="subject"
          rules={{
            required: {
              value: true,
              message: t("Rental.Contracts.Contracts.Modal.formRequired"),
            },
          }}
          render={({field: {value, onChange}}) => (
            <TextInput
              label={t("Rental.Contracts.Modal.SendContractModal.subject")}
              required={true}
              disabled={loading}
              value={value}
              error={form.formState.errors.subject?.message}
              onChangeText={onChange}
            />
          )}
        />
        <Controller
          control={form.control}
          name="message"
          rules={{
            required: {
              value: true,
              message: t("Rental.Contracts.Contracts.Modal.formRequired"),
            },
          }}
          render={({field: {value, onChange}}) => (
            <TextAreaInput
              label={t("Rental.Contracts.Modal.SendContractModal.message")}
              required={true}
              disabled={loading}
              value={value}
              error={form.formState.errors.message?.message}
              onTextChange={onChange}
              rows={6}
            />
          )}
        />
        <div className="flex flex-1 gap-x-3 justify-between">
          <Button type="secondary" onClick={onClose} disabled={loading}>
            {t("Global.cancel")}
          </Button>
          <Button
            loading={loading}
            onClick={form.handleSubmit(handleSend)}
            RightIcon={ArrowSendWhiteIcon}
          >
            {t("Rental.Contracts.Modal.SendContractModal.titleButton")}
          </Button>
        </div>
      </div>
    </CenterModal>
  );
};

export default SendContractModal;
