import React, {useEffect, useState} from "react";
import {MainLayout} from "../../components/Layout/MainLayout/MainLayout";
import {useTranslation} from "react-i18next";
import ChevronRightIcon from "../../assets/icons/chevron-right.svg?react";
import {ValueType} from "../../types/commonTypes";
import Header from "../../components/Analytics/Header";
import {useGetRentals} from "../../hooks/useGetRentals";
import DetailsComponent from "../../components/Analytics/Details/DetailsComponent";
import {useModal} from "../../hooks/useModal";
import CalculateTaxModal from "../../components/Analytics/CalculateTaxModal";
import {useNavigate} from "react-router-dom";
import paths from "../../constants/paths";
import {get} from "../../helpers/APIHelper";
import moment from "moment";
import {debounce} from "lodash";
import {AnalyticsDetailedResponse} from "../../types/GETTypes";

const AnalyticsDetailsPage = () => {
  const {t} = useTranslation();
  const modal = useModal();
  const modalExport = useModal();
  const [selectedRentalsIds, setSelectedRentalsIds] = useState<ValueType[]>([]);
  const [currentPeriod, setCurrentPeriod] = useState<ValueType>(
    moment().year().toString()
  );
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [stats, setStats] = useState<AnalyticsDetailedResponse>();

  const fetchStatsDetailed = async ({
    years,
    rental_ids,
  }: {
    years?: ValueType;
    rental_ids?: ValueType[];
  }) => {
    setLoading(true);

    const params = {
      years: years ?? currentPeriod,
      rental_ids: rental_ids?.join(',') ?? selectedRentalsIds?.join(",") ?? "",
    };

    const response = await get(
      `${import.meta.env.VITE_API_URL}/${paths.API.ANALYTICS.DETAILED}`,
      {params}
    );

    if (response.data?.success) {
      setStats(response.data?.result);
    }

    setLoading(false);
  };

  const handleChangePeriod = (value: ValueType) => {
    setCurrentPeriod(value);
    fetchStatsDetailed({years: value});
  };

  const debouncedFilterRentals = React.useRef(
    debounce(async (ids: ValueType[]) => {
      await fetchStatsDetailed({
        years:currentPeriod,
        rental_ids: ids,
      });

      setLoadingSearch(false);
    }, 300)
  ).current;

  const handleFilterRentals = async (ids: ValueType[]) => {
    setLoadingSearch(true);

    debouncedFilterRentals(ids);

    setSelectedRentalsIds(ids);
  };

  const getTitle = () => {
    return (
      <p className="flex flex-row items-center gap-1">
        {t("Analytics.title")} <ChevronRightIcon />
        {t("Analytics.details")}
      </p>
    );
  };

  useEffect(() => {
    fetchStatsDetailed({});
  }, []);

  const isLoad = loading || loadingSearch;

  return (
    <>
      <MainLayout
        title={getTitle()}
        sidebarActiveItem="analytics"
        sidebarActiveSubItem="details"
      >
        {/* <CalculateTaxModal modal={modal} /> */}
        <Header
          page="detail"
          currentPeriod={currentPeriod}
          handleChangePeriod={handleChangePeriod}
          selectedRentals={selectedRentalsIds}
          handleFilterRentals={handleFilterRentals}
          modalExport={modalExport}
          handleModalTax={modal.open}
        />

        <DetailsComponent
          loading={isLoad}
          stats={stats}
          currentYear={currentPeriod}
        />
      </MainLayout>
    </>
  );
};

export default AnalyticsDetailsPage;
