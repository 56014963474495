import React from "react";
import { PageMenu } from "../../Common/PageMenu/PageMenu";

import HomeIcon from "../../../assets/icons/home.svg?react";
import HomeHighIcon from "../../../assets/icons/home-high.svg?react";
import UsersIcon from "../../../assets/icons/users.svg?react";
import UsersHighIcon from "../../../assets/icons/users-high.svg?react";

import KeyIcon from "../../../assets/icons/key.svg?react";
import KeyHighIcon from "../../../assets/icons/key-high.svg?react";
import CartIcon from "../../../assets/icons/cart.svg?react";
import CartHighIcon from "../../../assets/icons/cart-high.svg?react";
import FileTextIcon from "../../../assets/icons/file-text.svg?react";
import FileTextHighIcon from "../../../assets/icons/file-text-high.svg?react";
import AnnotationIcon from "../../../assets/icons/annotation-dots.svg?react";
import AnnotationHighIcon from "../../../assets/icons/annotation-dots-high.svg?react";

export const ReservationDetailsMenu = () => {
  return (
    <PageMenu
      items={[
        {
          Icon: HomeIcon,
          IconActive: HomeHighIcon,
          anchor: "reservation-card",
          labelI18n: "Booking.Reservation.title",
        },
        {
          Icon: UsersIcon,
          IconActive: UsersHighIcon,
          anchor: "guest-card",
          labelI18n: "Booking.Guest.title",
        },
        {
          Icon: KeyIcon,
          IconActive: KeyHighIcon,
          anchor: "lock-card",
          labelI18n: "Booking.Locks.title",
        },
        {
          Icon: CartIcon,
          IconActive: CartHighIcon,
          anchor: "extras-card",
          labelI18n: "Booking.ExtrasOrder.title",
        },
        {
          Icon: FileTextIcon,
          IconActive: FileTextHighIcon,
          anchor: "note-card",
          labelI18n: "Booking.Note.title",
        },
        {
          Icon: AnnotationIcon,
          IconActive: AnnotationHighIcon,
          anchor: "platform-note-card",
          labelI18n: "Booking.PlatformNote.title",
        },
      ]}
    />
  );
};
