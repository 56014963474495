import { get, post, update } from "../../helpers/APIHelper";
import paths from "../../constants/paths";

export const useMake = () => {
  const API_URL = import.meta.env.VITE_API_URL;

  const generate = async () => {
    const request = await post(
      `${API_URL}${paths.API.MAKE_MODULE.GENERATE_API_KEY}`,
    );

    if (request.status === 200) {
      return request.data.result.key;
    } else {
      throw new Error(request?.response.data?.message);
    }
  };

  const checkConnect = async (): Promise<boolean> => {
    const request = await post(
      `${API_URL}${paths.API.MAKE_MODULE.CHECK_CONNECTION}`,
    );

    if (request.status === 200) {
      return request.data.result.is_connected;
    } else {
      throw new Error(request?.response.data?.message);
    }
  };

  return { generate, checkConnect };
};
