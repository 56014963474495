import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {useTranslation} from "react-i18next";
import InformationIcon from "../../../../assets/icons/information.svg?react";
import paths from "../../../../constants/paths";
import {useTablePage} from "../../../../hooks/useTablePage";
import {ValueType} from "../../../../types/commonTypes";
import {RentalLightListItemResponse} from "../../../../types/GETTypes";
import {MultiSelectRentalsTable} from "../../../Common/MultiSelectRentalsTable/MultiSelectRentalsTable";
import {ProgressionRentalsRulesModalRef} from "../ProgressionRentalsRulesModal.type";
import {Rental} from "../../../../pages/RentalPage/RentalPage.type";
import {useRentalPageContext} from "../../../../pages/RentalPage/RentalPageContext";
import RentalPaymentPolicyCard from "../../../Rental/Pricing/PaymentPolicy/PaymentPolicyCard";
import {RentalPaymentPolicyModalMode} from "../../../Rental/Pricing/PaymentPolicy/PaymentPolicy.type";
import {RentalPaymentPolicyModal} from "../../../Rental/Pricing/PaymentPolicy/PaymentPolicyModal";
import {PaymentPolicyResponse} from "../../../Payment/Payment.type";
import {ErrorMessage} from "../../../Common/ErrorMessage/ErrorMessage";
import {get} from "../../../../helpers/APIHelper";

export const ProgressionRentalsPaymentPolicies = forwardRef<
  ProgressionRentalsRulesModalRef,
  {
    onNext: () => void;
  }
>(({onNext}, ref) => {
  const {t} = useTranslation();

  const {
    loadingState: {loading, setLoading},
    rental,
  } = useRentalPageContext();

  const [paymentPolicyModalMode, setPaymentPolicyModalMode] = useState<
    RentalPaymentPolicyModalMode | undefined
  >(undefined);
  const [isVisiblePaymentPolicyModal, setIsVisiblePaymentPolicyModal] =
    useState<boolean>(false);
  const [dataToUpdate, setDataToUpdate] = useState<PaymentPolicyResponse>();
  const [paymentPolicies, setPaymentPolicies] = useState<
    PaymentPolicyResponse[] | undefined
  >(undefined);
  const [paymentPoliciesError, setPaymentPoliciesError] = useState<
    string | undefined
  >();

  const handleClickPaymentPolicyItem = (
    paymentPolicy: PaymentPolicyResponse
  ) => {
    setPaymentPolicyModalMode("update");
    setDataToUpdate(paymentPolicy);
  };

  const handleUpdateFavoritePaymentPolicy = (
    paymentPolicyId: number | undefined,
    paymentPolicies: PaymentPolicyResponse[]
  ) => {
    if (paymentPolicyId && paymentPolicies && paymentPolicies.length > 0) {
      setDataToUpdate(paymentPolicies.find((pp) => pp.id === paymentPolicyId));
      setPaymentPolicies(paymentPolicies);
    }
  };

  const handleCreatePaymentPolicy = (
    paymentPolicies: PaymentPolicyResponse[]
  ) => {
    if (paymentPolicies) {
      setPaymentPolicies(paymentPolicies);
      setIsVisiblePaymentPolicyModal(false);
    }
  };

  const handleUpdatePaymentPolicy = (
    paymentPolicies: PaymentPolicyResponse[]
  ) => {
    if (paymentPolicies && paymentPolicies.length > 0) {
      setPaymentPolicies(paymentPolicies);
      setIsVisiblePaymentPolicyModal(false);
    }
  };

  const handleDeletePaymentPolicy = (
    paymentPolicies: PaymentPolicyResponse[]
  ) => {
    if (paymentPolicies) {
      setPaymentPolicies(paymentPolicies);
      setIsVisiblePaymentPolicyModal(false);
    }
  };

  // const fetchPaymentPolicies = async () => {
  //   if (paymentPolicies === undefined && rental?.id) {
  //     setLoading(true);
  //     setPaymentPoliciesError(undefined);

  //     const response = await get(
  //       `${import.meta.env.VITE_API_URL}${paths.API.RENTALS_UPDATE}/${
  //         rental?.id
  //       }${paths.API.PAYMENTS.POLICIES}`
  //     );

  //     if (response.data?.success) {
  //       setPaymentPolicies(response?.data.result);
  //     } else {
  //       setPaymentPoliciesError(response?.data?.message ?? response.data);
  //     }
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchPaymentPolicies();
  // }, []);
  
  return (
    <div className="flex flex-col gap-6">
      <ErrorMessage>{paymentPoliciesError}</ErrorMessage>
      {rental && (
        <>
          <RentalPaymentPolicyModal
            mode={paymentPolicyModalMode!}
            title={
              paymentPolicyModalMode === "create"
                ? t("Rental.Pricing.PaymentPolicy.createModalTitle")
                : t("Rental.Pricing.PaymentPolicy.updateModalTitle")
            }
            isVisible={isVisiblePaymentPolicyModal}
            formData={
              paymentPolicyModalMode === "create" ? undefined : dataToUpdate
            }
            rentalId={String(rental?.id)}
            onClose={() => setIsVisiblePaymentPolicyModal(false)}
            onCreate={handleCreatePaymentPolicy}
            onUpdate={handleUpdatePaymentPolicy}
            onDelete={handleDeletePaymentPolicy}
            onUpdateFavoritePaymentPolicy={handleUpdateFavoritePaymentPolicy}
          />
          <RentalPaymentPolicyCard
            loading={loading}
            rentalId={String(rental?.id)}
            paymentPolicies={paymentPolicies!}
            rental={rental}
            workspaceOwner={rental?.workspace_owner}
            onClickActionButton={() => setPaymentPolicyModalMode("create")}
            onClickItem={handleClickPaymentPolicyItem}
            onUpdateFavoritePaymentPolicy={handleUpdateFavoritePaymentPolicy}
          />
        </>
      )}
    </div>
  );
});
