import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import PlugIcon from "../..//assets/icons/plug.svg?react";
import DotsVerticalIcon from "../../assets/icons/dots-vertical.svg?react";
import EyeWhiteIcon from "../../assets/icons/eye-white.svg?react";
import PlugHighIcon from "../../assets/icons/plug-high.svg?react";
import PlusIcon from "../../assets/icons/plus-white.svg?react";
import TrashIcon from "../../assets/icons/trash.svg?react";
import { ChecklistList } from "../../components/Checklist/ChecklistList";
import { Button } from "../../components/Common/Button/Button";
import { InProgressPlaceholder } from "../../components/Common/Dashboard/InProgressPlaceholder";
import { ErrorMessage } from "../../components/Common/ErrorMessage/ErrorMessage";
import { RentalLayoutMenuSections } from "../../components/Layout/RentalLayout/Navbar/RentalLayoutNavbar.type";
import { RentalLayout } from "../../components/Layout/RentalLayout/RentalLayout";
import { DeleteRentalModal } from "../../components/Modal/Rental/DeleteRentalModal";
import { PaymentPolicyResponse } from "../../components/Payment/Payment.type";
import { RentalPhotosCard } from "../../components/Rental/Common/Photos/RentalPhotosCard";
import { RentalUpdatePhotosModal } from "../../components/Rental/Common/Photos/RentalUpdatePhotosModal";
import DeleteContractsModal from "../../components/Rental/Contract/Modal/DeleteContractModal";
import { DisableRentalModal } from "../../components/Rental/DisableRentalModal";
import { RentalFacilities } from "../../components/Rental/Infos/Facilities/FacilitiesCard";
import { RentalInformationsCard } from "../../components/Rental/Infos/Informations/InformationsCard";
import { RentalLocalisationCard } from "../../components/Rental/Infos/Localisation/LocalisationCard";
import { RentalNoteCard } from "../../components/Rental/Infos/Note/NoteCard";
import { RentalPlatformsCard } from "../../components/Rental/Infos/Platforms/PlatformsCard";
import { RentalReservationRulesCard } from "../../components/Rental/Infos/ReservationRules/ReservationRulesCard";
import { RentalRoomsCard } from "../../components/Rental/Infos/Rooms/RentalRoomsCard";
import { RentalAdditionalCost } from "../../components/Rental/Pricing/AdditionalCost/RentalAdditionalCost";
import { RentalPaymentPolicyModalMode } from "../../components/Rental/Pricing/PaymentPolicy/PaymentPolicy.type";
import { RentalPaymentPolicyCard } from "../../components/Rental/Pricing/PaymentPolicy/PaymentPolicyCard";
import { RentalPaymentPolicyModal } from "../../components/Rental/Pricing/PaymentPolicy/PaymentPolicyModal";
import { RentalPlatformRate } from "../../components/Rental/Pricing/PlatformRate/PlatformRate";
import { RentalPricingRules } from "../../components/Rental/Pricing/PricingRules/PricingRules";
import { PricelabsCard } from "../../components/Rental/Services/Pricelabs/PricelabsCard";
import { RentalSmartlocksAddModal } from "../../components/Rental/Services/Smartlocks/SmartlocksAddModal";
import { RentalSmartlocksCard } from "../../components/Rental/Services/Smartlocks/SmartlocksCard";
import { SmartlocksProvider } from "../../components/Rental/Services/Smartlocks/SmartlocksContext";
import { RentalSmartlocksDeleteModal } from "../../components/Rental/Services/Smartlocks/SmartlocksDeleteModal";
import { RentalSmartlocksUpdateModal } from "../../components/Rental/Services/Smartlocks/SmartlocksUpdateModal";
import { CgvCard } from "../../components/Rental/TravelerPage/Cgv/CgvCard";
import { CodeCard } from "../../components/Rental/TravelerPage/Code/CodeCard";
import { ContactCard } from "../../components/Rental/TravelerPage/Contact/ContactCard";
import { ExtrasCard } from "../../components/Rental/TravelerPage/Extras/ExtrasCard";
import { FormCard } from "../../components/Rental/TravelerPage/Form/FormCard";
import { FormCardSkeleton } from "../../components/Rental/TravelerPage/Form/FormCardSkeleton";
import { InstructionCard } from "../../components/Rental/TravelerPage/Instructions/InstructionCard";
import PreviewModal from "../../components/Rental/TravelerPage/Modal/PreviewModal";
import { PaymentExtraCard } from "../../components/Rental/TravelerPage/PaymentExtra/PaymentExtraCard";
import { RequestOpinionCard } from "../../components/Rental/TravelerPage/RequestOpinion/RequestOpinionCard";
import { WifiCard } from "../../components/Rental/TravelerPage/Wifi/WifiCard";
import { RentalIntegrateModal } from "../../components/Rental/Website/IntegrateModal/IntegrateModal";
import { RentalWebsiteMenu } from "../../components/Rental/Website/Menu/RentalWebsiteMenu";
import { RentalWebsitePaymentAndReceiptCard } from "../../components/Rental/Website/PaymentAndReceipt/PaymentAndReceiptCard";
import { RentalWebsiteRulesCard } from "../../components/Rental/Website/Rules/WebsiteRulesCard";
import { RentalWebsiteServicesCard } from "../../components/Rental/Website/Services/RentalWebsiteServicesCard";
import { RentalWebsiteServicesUpdateModal } from "../../components/Rental/Website/Services/RentalWebsiteServicesUpdateModal";
import { RentalWebsiteTermsCard } from "../../components/Rental/Website/Terms/TermsCard";
import paths from "../../constants/paths";
import { get } from "../../helpers/APIHelper";
import {
  useDisableGuestPage,
  useEnableGuestPage,
} from "../../hooks/api/guestPage";
import { useCheckAuth } from "../../hooks/useCheckAuth";
import { UseModal, useModal } from "../../hooks/useModal";
import { useUrlSearchParams } from "../../hooks/useUrlSearchParams";
import { ValueType } from "../../types/commonTypes";

import ArrowUpRightIcon from "../../assets/icons/arrow-up-right.svg?react";
import CodeIcon from "../../assets/icons/code.svg?react";
import { PageMenu } from "../../components/Common/PageMenu/PageMenu";
import PriceMultiplier from "../../components/PriceMultiplier/PriceMultiplier";
import { RentalDescriptionCard } from "../../components/Rental/Common/Description/DescriptionCard";
import RentalContractsCard from "../../components/Rental/Contract/Contracts/ContractsCard";
import { RentalContractMenu } from "../../components/Rental/Contract/Menu/RentalContractMenu";
import RentalCreateContractsModal from "../../components/Rental/Contract/Modal/CreateContractsModal";
import RentalEditContractsModal from "../../components/Rental/Contract/Modal/EditContractsModal";
import { ContactCardInfo } from "../../components/Rental/Infos/Contact/ContactCardInfo";
import { RentalInfosMenu } from "../../components/Rental/Infos/Menu/RentalInfosMenu";
import { RentalPricingMenu } from "../../components/Rental/Pricing/Menu/RentalPricingMenu";
import { RentalServicesMenu } from "../../components/Rental/Services/Menu/RentalServicesMenu";
import { CgvCardSkeleton } from "../../components/Rental/TravelerPage/Cgv/CgvCardSkeleton";
import { CodeCardSkeleton } from "../../components/Rental/TravelerPage/Code/CodeCardSkeleton";
import { ContactCardSkeleton } from "../../components/Rental/TravelerPage/Contact/ContactCardSkeleton";
import { ContractCard } from "../../components/Rental/TravelerPage/Contract/ContractCard";
import { ExtraCardSkeleton } from "../../components/Rental/TravelerPage/Extras/ExtraCardSkeleton";
import { InstructionCardSkeleton } from "../../components/Rental/TravelerPage/Instructions/InstructionCardSkeleton";
import { RentalTravelerMenu } from "../../components/Rental/TravelerPage/Menu/RentalTravelerMenu";
import { PaymentExtraCardSkeleton } from "../../components/Rental/TravelerPage/PaymentExtra/PaymentExtraCardSkeleton";
import { RequestOpinionCardSkeleton } from "../../components/Rental/TravelerPage/RequestOpinion/RequestOpinionCardSkeleton";
import { getCurrentCanEdit } from "../../helpers/workspaceHelper";
import useActiveSectionAnchor from "../../hooks/useActiveSectionAnchor";
import {
  ContractsResponse,
  PhotoListItemResponse,
  PhotoListResponse,
  RentalChecklistsResponse,
  RentalLightListItemResponse,
  RentalResponse,
  RentalWebsiteRulesResponse,
  RentalWebsiteServicesItem,
  RentalWebsiteStripeResponse,
} from "../../types/GETTypes";
import {
  ModalContractsDeleteData,
  ModalContractsEditData,
  RentalPageProps,
} from "./RentalPage.type";
import { useRentalPageContext } from "./RentalPageContext";

export const RentalPage: React.FC<RentalPageProps> = () => {
  const { t } = useTranslation();
  const { rentalId } = useParams();
  const { user } = useCheckAuth();
  const priceMultiplierPage = useModal<{
    onUpdateRental: (rental: RentalResponse | undefined) => void;
  }>();
  const {
    loadingState: { loading, setLoading },
    onUpdateRental,
    rental,
  } = useRentalPageContext();

  // * -- Handle URL parameters --
  const urlParams = useUrlSearchParams<"part">();
  const partParam = urlParams.get("part")?.trim().toLowerCase();
  // const tabParam = urlParams.get("tab")?.trim().toLowerCase();

  const [loadingTravelerPage, setLoadingTravelerPage] =
    useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [rentals, setRentals] = useState<RentalLightListItemResponse[]>();
  const [isLoadingContracts, setLoadingContracts] = useState<boolean>(false);
  const [contracts, setContracts] = useState<ContractsResponse[]>();
  const [defaultSelectedContract, setDefaultSelectedContract] =
    useState<number>();
  const [navbarActiveItem, setNavbarActiveItem] =
    useState<RentalLayoutMenuSections>(
      (partParam as RentalLayoutMenuSections) ?? "infos"
    );

  // const [navbarActiveSubItem, setNavbarActiveSubItem] =
  //   useState<RentalLayoutNavbarActiveSubItemType>(
  //     (tabParam as RentalLayoutNavbarActiveSubItemType) ?? "pricing_rules"
  //   );

  const integrateModal = useModal<{ apiKey: ValueType | undefined }>();

  const updatePhotosModal = useModal<{}>();

  const [currentOldFiles, setCurrentOldFiles] = useState<
    PhotoListItemResponse[]
  >([]);
  const [currentNewFiles, setCurrentNewFiles] = useState<Blob[]>([]);

  const handleUpdatePhotos = (photos: PhotoListResponse) => {
    setCurrentOldFiles(photos.photos);
    updatePhotosModal.close();
  };

  const modalCreate = useModal();
  const modalEdit = useModal<ModalContractsEditData>();
  const modalDelete = useModal<ModalContractsDeleteData>();

  const fetchRentals = async () => {
    const res = await get(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS}`
    );

    if (res.data.success) {
      setRentals(res.data.result.rentals);
    }
  };

  useEffect(() => {
    fetchRentals();
  }, []);

  useEffect(() => {
    if (navbarActiveItem) {
      urlParams.set("part", navbarActiveItem);
    }
  }, [navbarActiveItem]);

  // * DELETE RENTAL
  const [deleteRentalError, setDeleteRentalError] = useState<
    string | undefined
  >();
  const [deleteRentalModalVisible, setDeleteRentalModalVisible] =
    useState<boolean>(false);

  const handleDeleteRental = () => {
    setDeleteRentalError(undefined);
    setDeleteRentalModalVisible(!deleteRentalModalVisible);
  };

  const handleDeleteRentalError = (error: string | undefined) => {
    if (error) {
      setDeleteRentalError(error);
      setDeleteRentalModalVisible(false);
    }
  };

  const [activeTravelerPage, setActiveTravelerPage] = useState<boolean>(
    rental?.guest_page?.form_enabled === 1 ? true : false
  );
  const [openPreview, setOpenPreview] = useState(false);

  // * -- PRICES TAB --
  // * PAYMENT POLICIES SECTION
  const [isVisiblePaymentPolicyModal, setIsVisiblePaymentPolicyModal] =
    useState<boolean>(false);
  const [paymentPolicyModalMode, setPaymentPolicyModalMode] = useState<
    RentalPaymentPolicyModalMode | undefined
  >(undefined);

  // * -- FETCH PAYMENT POLICIES --
  const [paymentPolicies, setPaymentPolicies] = useState<
    PaymentPolicyResponse[] | undefined
  >(undefined);
  const [paymentPoliciesError, setPaymentPoliciesError] = useState<
    string | undefined
  >();

  const getContracts = async () => {
    setLoadingContracts(true);
    if (rentalId !== undefined) {
      const response = await get(
        `${
          import.meta.env.VITE_API_URL
        }${paths.API.CONTRACTS_DOCS.GET_BY_RENTAL(Number(rentalId))}`
      );
      if (response?.data?.success) {
        const res = response?.data?.result as ContractsResponse[];
        setContracts(res);
        setDefaultSelectedContract(
          res.find((contract) => contract.is_default)?.id
        );
      }
      console.log("🚀 ~ getContracts ~ contracts:", response?.data?.result);
    }
    setLoadingContracts(false);
  };

  useEffect(() => {
    getContracts();
  }, [navbarActiveItem, rentalId]);

  useEffect(() => {
    setActiveTravelerPage(
      rental?.guest_page?.form_enabled === 1 ? true : false
    );
  }, [rental?.guest_page?.form_enabled]);

  const handleCreatePaymentPolicy = (
    paymentPolicies: PaymentPolicyResponse[]
  ) => {
    if (paymentPolicies) {
      setPaymentPolicies(paymentPolicies);
      setIsVisiblePaymentPolicyModal(false);
    }
  };

  const handleUpdatePaymentPolicy = (
    paymentPolicies: PaymentPolicyResponse[]
  ) => {
    if (paymentPolicies && paymentPolicies.length > 0) {
      setPaymentPolicies(paymentPolicies);
      setIsVisiblePaymentPolicyModal(false);
    }
  };

  const handleDeletePaymentPolicy = (
    paymentPolicies: PaymentPolicyResponse[]
  ) => {
    if (paymentPolicies) {
      setPaymentPolicies(paymentPolicies);
      setIsVisiblePaymentPolicyModal(false);
    }
  };

  const handleUpdateFavoritePaymentPolicy = (
    paymentPolicyId: number | undefined,
    paymentPolicies: PaymentPolicyResponse[]
  ) => {
    if (paymentPolicyId && paymentPolicies && paymentPolicies.length > 0) {
      setDataToUpdate(paymentPolicies.find((pp) => pp.id === paymentPolicyId));
      setPaymentPolicies(paymentPolicies);
    }
  };

  // * -- On click payment policy item --
  const [dataToUpdate, setDataToUpdate] = useState<PaymentPolicyResponse>();
  const handleClickPaymentPolicyItem = (
    paymentPolicy: PaymentPolicyResponse
  ) => {
    setPaymentPolicyModalMode("update");
    setDataToUpdate(paymentPolicy);
  };

  useEffect(() => {
    if (paymentPolicyModalMode !== undefined) {
      setIsVisiblePaymentPolicyModal(!isVisiblePaymentPolicyModal);
    }
  }, [paymentPolicyModalMode]);

  useEffect(() => {
    if (!isVisiblePaymentPolicyModal) {
      setPaymentPolicyModalMode(undefined);
    }
  }, [isVisiblePaymentPolicyModal]);

  // * END PAYMENT POLICIES

  useEffect(() => {
    if (rentalId !== undefined) {
      const getRental = async () => {
        const response = await get(
          `${import.meta.env.VITE_API_URL}${paths.API.RENTALS}/${rentalId}`
        );

        if (response.data?.success) {
          setCurrentOldFiles(response.data.result.details.photos.photos);
        }
      };
      getRental();
    }
  }, [rentalId]);

  // * RENTAL LAYOUT
  const guestPagePreviewUrl =
    user && rental
      ? `${window.location.origin}/reservation/0/guest-page/${user.guest_page_link}?rentalId=${rental.id}`
      : "";

  const getRightChildren = () => {
    if (loading) {
      return (
        <div className="flex flex-row gap-2 animate-pulse">
          <div className="w-10 rounded h-9 bg-separator" />
        </div>
      );
    } else {
      return (
        <div className="flex flex-row justify-between w-full gap-2">
          {navbarActiveItem === "infos" ? (
            <Button
              LeftIcon={DotsVerticalIcon}
              type="secondary"
              dropItems={[
                {
                  Icon: TrashIcon,
                  value: "rental_remove",
                  label: t("Global.remove"),
                },
              ]}
              onClick={() => setDeleteRentalModalVisible(true)}
            ></Button>
          ) : null}

          {navbarActiveItem === "checklists" ? (
            <Button
              RightIcon={PlusIcon}
              disabled={
                !getCurrentCanEdit({
                  user,
                  workspaceOwner: rental?.workspace_owner,
                })
              }
              onClick={upsertModal.open}
            >
              {t("Rental.Checklist.Upsert.title")}
            </Button>
          ) : null}

          {navbarActiveItem === "traveler" ? (
            <div className="w-[200px]">
              <Button
                RightIcon={EyeWhiteIcon}
                onClick={() => guestPagePreviewUrl && setOpenPreview(true)}
                displayLargeBtn
                disabled={!guestPagePreviewUrl}
              >
                {t("Rental.Infos.TravelerPage.openPreview")}
              </Button>
            </div>
          ) : null}

          {navbarActiveItem === "website" && (
            <div className="flex justify-between space-x-3">
              <div className="flex space-x-3">
                <div className="w-max">
                  <Button
                    type="secondary"
                    RightIcon={CodeIcon}
                    onClick={() =>
                      integrateModal.open({ apiKey: rental?.website.api_key })
                    }
                  >
                    {t("Global.integrate")}
                  </Button>
                </div>
                <div className="w-max">
                  <Link to={paths.WEBSITES.LIST} target="_blank">
                    <Button type="primary" RightIcon={ArrowUpRightIcon}>
                      {t("Rental.Website.seeWebsiteList")}
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      );
    }
  };

  // * -- CHECKLIST SECTION --
  const upsertModal: UseModal<RentalChecklistsResponse> = useModal();

  // * -- GUEST SECTION --
  const handleActiveTravelerPage = () => {
    setLoadingTravelerPage(true);
    if (activeTravelerPage) {
      useDisableGuestPage(
        rentalId,
        (response) => {
          // setActiveTravelerPage(response.guest_page?.form_enabled);
          setLoadingTravelerPage(false);
        },
        (error) => {
          console.log("error", error);
        }
      );
    } else {
      useEnableGuestPage(
        rentalId,
        (response) => {
          setActiveTravelerPage(Boolean(response.guest_page?.form_enabled));
          setLoadingTravelerPage(false);
        },
        (error) => {
          console.log("error", error);
        }
      );
    }
  };

  // * -- WEBSITE SECTION --
  const handleWebsiteRulesUpdate = (
    rentalWebsiteRules: RentalWebsiteRulesResponse
  ) => {
    if (rental) {
      onUpdateRental({
        ...rental,
        website: {
          ...rental.website,
          rules: rentalWebsiteRules,
        },
      });
    }
  };

  const handleDescriptionUpdate = (rentalDescription: string) => {
    if (navbarActiveItem === "infos" && rental) {
      onUpdateRental({
        ...rental,
        details: {
          ...rental.details,
          descriptions: rental.details.descriptions.map((desc, index) => {
            if (index === 0) {
              return {
                ...desc,
                description: rentalDescription,
              };
            }

            return desc;
          }),
        },
      });
    } else if (navbarActiveItem === "website" && rental) {
      onUpdateRental({
        ...rental,
        website: {
          ...rental.website,
          description: rentalDescription,
        },
      });
    }
  };

  const updateServicesModal = useModal<
    RentalWebsiteServicesItem[] | undefined
  >();

  const handleTermsUpdate = (terms: string) => {
    if (rental) {
      onUpdateRental({
        ...rental,
        website: {
          ...rental.website,
          cgv: terms,
        },
      });
    }
  };

  const handlePaymentAndReceiptUpdate = (
    values: RentalWebsiteStripeResponse
  ) => {
    if (rental) {
      onUpdateRental({
        ...rental,
        website: {
          ...rental.website,
          stripe_payment_accounts: values,
        },
      });
    }
  };

  const handleContracts = async () => {
    await getContracts();
  };

  if (priceMultiplierPage.isVisible) {
    return (
      <PriceMultiplier
        onGoBack={priceMultiplierPage.close}
        from="rental"
        rental={rental}
        onUpdateRental={priceMultiplierPage.data?.onUpdateRental}
      />
    );
  }

  /** Parties hooks pour les anchor (pour qu'il s'update automatiquement) */

  const infoSection = useActiveSectionAnchor(
    [
      "infos-card",
      "localisation-card",
      "description-card",
      "photos-card",
      "reservation-rules-card",
      "facilities-card",
      "note-card",
      "contact",
      "bedrooms-card",
    ],
    "infos-container"
  );

  const priceSection = useActiveSectionAnchor(
    ["pricing-card", "additional-card", "policies-card", "rate-card"],
    "prices-container"
  );

  const platformSection = useActiveSectionAnchor(
    ["platforms-card"],
    "platform-container"
  );

  const serviceSection = useActiveSectionAnchor(
    ["smartlocks-card", "pricelabs-card"],
    "service-container"
  );

  const contractSection = useActiveSectionAnchor(
    ["contract-card"],
    "contract-container"
  );

  const guestSection = useActiveSectionAnchor(
    [
      "form-card",
      "contract-card",
      "contact-card",
      "instruction-card",
      "code-card",
      "wifi-card",
      "extra-payment-card",
      "extras-card",
      "opinion-card",
      "cgv-card",
    ],
    "guest-container"
  );

  const websiteSection = useActiveSectionAnchor(
    [
      "rules-card",
      "carrousel-photos-card",
      "description-card",
      "services-card",
      "extras-card",
      "rate-card",
      "upsell-card",
      "terms-card",
    ],
    "website-container"
  );

  return (
    <RentalLayout
      loading={loading}
      navbarActiveItem={navbarActiveItem}
      onActiveItem={(value: RentalLayoutMenuSections) => {
        infoSection.reset();
        priceSection.reset();
        platformSection.reset();
        serviceSection.reset();
        contractSection.reset();
        guestSection.reset();
        websiteSection.reset();

        setNavbarActiveItem(() => {
          urlParams.set("part", value);
          return value;
        });
      }}
      // navbarActiveSubItem={navbarActiveSubItem}
      // onActiveSubItem={(value: RentalLayoutNavbarActiveSubItemType) =>
      //   setNavbarActiveSubItem(value)
      // }

      rental={rental}
      rightChildren={getRightChildren()}
    >
      <ErrorMessage>{error}</ErrorMessage>
      <ErrorMessage>{paymentPoliciesError}</ErrorMessage>
      <ErrorMessage>{deleteRentalError}</ErrorMessage>
      <PreviewModal
        isOpen={openPreview}
        onClose={() => setOpenPreview(false)}
        url={guestPagePreviewUrl}
      />
      <DeleteRentalModal
        isVisible={deleteRentalModalVisible}
        rentalId={rentalId}
        onClose={() => setDeleteRentalModalVisible(false)}
        onDelete={handleDeleteRental}
        onError={handleDeleteRentalError}
      />
      {navbarActiveItem === "infos" ? (
        <>
          <DisableRentalModal />

          <RentalUpdatePhotosModal
            rentalId={rentalId}
            modal={updatePhotosModal}
            oldFiles={currentOldFiles}
            newFiles={currentNewFiles}
            onUpdatePhotos={handleUpdatePhotos}
          />

          <div className="grid h-screen grid-cols-12">
            {/* Menu Sticky */}
            <div className="sticky self-start col-span-2 pr-2 top-16 h-fit">
              <RentalInfosMenu
                anchor={infoSection.activeSection}
                setAnchor={infoSection.setActiveSection}
              />
            </div>

            <div
              id={"infos-container"}
              className="col-span-8 flex flex-col gap-3 overflow-y-auto h-[calc(100vh-170px)] no-scrollbar"
            >
              <RentalInformationsCard
                rentals={rentals}
                rental={rental}
                rentalInformations={rental?.details.informations}
                workspaceOwner={rental?.workspace_owner}
              />

              <RentalLocalisationCard
                rentalInformations={rental?.details.informations}
                workspaceOwner={rental?.workspace_owner}
              />

              <RentalDescriptionCard
                loading={loading}
                rentalId={rental?.id}
                part={navbarActiveItem}
                infosDescriptions={rental?.details?.descriptions}
                websiteDescription={rental?.website?.description}
                workspaceOwner={rental?.workspace_owner}
                onUpdate={handleDescriptionUpdate}
                onError={(message) => setError(message)}
              />

              <RentalPhotosCard
                loading={loading}
                modal={updatePhotosModal}
                part={navbarActiveItem}
                oldFiles={currentOldFiles}
                newFiles={currentNewFiles}
                workspaceOwner={rental?.workspace_owner}
              />

              <RentalRoomsCard
                loading={loading}
                canAddRoom={Boolean(
                  !rental?.details.external_connexions.platform_airbnb
                    ?.enabled &&
                    !rental?.details.external_connexions.platform_booking
                      ?.enabled &&
                    !rental?.details.external_connexions.platform_icalendar?.every(
                      (item) => !item.enabled
                    )
                )}
                rooms={rental?.details.bedrooms}
              />

              <RentalReservationRulesCard
                rentalReservationRules={rental?.details.rules}
                workspaceOwner={rental?.workspace_owner}
              />

              <RentalFacilities />

              <RentalNoteCard />

              {rental && <ContactCardInfo rental={rental} />}

              {/*<RentalBillingInfosCard*/}
              {/*  loading={loading}*/}
              {/*  rentalId={rental?.id}*/}
              {/*  billingInfos={rental?.details.billing_infos}*/}
              {/*/>*/}
            </div>

            {/* Empty */}
            <div />
          </div>
        </>
      ) : null}

      {navbarActiveItem === "prices" ? (
        <>
          <div className="grid h-screen grid-cols-12">
            {/* Menu Sticky */}
            <div className="sticky self-start col-span-2 pr-2 top-16 h-fit">
              <RentalPricingMenu
                anchor={priceSection.activeSection}
                setAnchor={priceSection.setActiveSection}
              />
            </div>

            <div
              id={"prices-container"}
              className="col-span-8 flex flex-col gap-3 overflow-y-auto h-[calc(100vh-170px)] no-scrollbar"
            >
              <RentalPricingRules workspaceOwner={rental?.workspace_owner} />

              <RentalAdditionalCost workspaceOwner={rental?.workspace_owner} />

              <RentalPaymentPolicyCard
                loading={loading}
                rentalId={rentalId}
                paymentPolicies={paymentPolicies!}
                rental={rental}
                workspaceOwner={rental?.workspace_owner}
                onClickActionButton={() => setPaymentPolicyModalMode("create")}
                onClickItem={handleClickPaymentPolicyItem}
                onUpdateFavoritePaymentPolicy={
                  handleUpdateFavoritePaymentPolicy
                }
              />

              <RentalPlatformRate
                workspaceOwner={rental?.workspace_owner}
                priceMultiplierPage={priceMultiplierPage}
              />

              {/* <RentalPaymentWays
                externalConnexions={rental?.details?.external_connexions}
                paymentPolicies={
                  rental?.tarification.policies_seasons.payment_policies
                }
                paymentWays={rental?.tarification.rules.payment_ways}
                onUpdateFavoritePaymentPolicy={
                  handleUpdateFavoritePaymentPolicy
                }
              /> */}
            </div>

            {/* Space */}
            <div />
          </div>

          <RentalPaymentPolicyModal
            mode={paymentPolicyModalMode!}
            title={
              paymentPolicyModalMode === "create"
                ? t("Rental.Pricing.PaymentPolicy.createModalTitle")
                : t("Rental.Pricing.PaymentPolicy.updateModalTitle")
            }
            isVisible={isVisiblePaymentPolicyModal}
            formData={
              paymentPolicyModalMode === "create" ? undefined : dataToUpdate
            }
            rentalId={rentalId}
            onClose={() => setIsVisiblePaymentPolicyModal(false)}
            onCreate={handleCreatePaymentPolicy}
            onUpdate={handleUpdatePaymentPolicy}
            onDelete={handleDeletePaymentPolicy}
            onUpdateFavoritePaymentPolicy={handleUpdateFavoritePaymentPolicy}
          />
        </>
      ) : null}

      {navbarActiveItem === "platforms" ? (
        <div className="grid h-screen grid-cols-12">
          {/* Menu Sticky */}
          <div className="sticky self-start col-span-2 pr-2 top-16 h-fit">
            <PageMenu
              setAnchor={platformSection.setActiveSection}
              anchor={platformSection.activeSection}
              items={[
                {
                  Icon: PlugIcon,
                  IconActive: PlugHighIcon,
                  anchor: "platforms-card",
                  labelI18n: "Rental.Infos.Platforms.title",
                },
              ]}
            />
          </div>

          <div
            id={"platform-container"}
            className="col-span-8 flex flex-col gap-3 overflow-y-auto h-[calc(100vh-170px)] no-scrollbar"
          >
            <RentalPlatformsCard
              externalConnections={rental?.details.external_connexions}
              workspaceOwner={rental?.workspace_owner}
            />
          </div>

          {/* Empty */}
          <div />
        </div>
      ) : null}

      {navbarActiveItem === "contract" ? (
        <>
          {/* <RentalUpdatePhotosModal
            isVisible={isUpdatePhotosVisible}
            rentalId={rentalId}
            oldFiles={currentOldFiles}
            newFiles={currentNewFiles}
            onClose={() => setIsUpdatePhotosVisible(false)}
          /> */}

          <RentalCreateContractsModal
            isVisible={modalCreate.isVisible}
            onClose={modalCreate.close}
            rentalId={rentalId}
            onSuccess={handleContracts}
          />
          <RentalEditContractsModal
            isVisible={modalEdit.isVisible}
            onClose={modalEdit.close}
            rentalId={rentalId}
            data={modalEdit.data}
            contracts={contracts}
            defaultSelectedContract={defaultSelectedContract}
            onSuccess={handleContracts}
            modalDelete={modalDelete}
          />
          <DeleteContractsModal
            isVisible={modalDelete.isVisible}
            onClose={modalDelete.close}
            rentalId={rentalId}
            data={modalDelete.data}
            defaultSelectedContract={defaultSelectedContract}
            onSuccess={handleContracts}
          />

          <div className="grid h-screen grid-cols-12">
            {/* Menu Sticky */}
            <div className="sticky self-start col-span-2 pr-2 top-16 h-fit">
              <RentalContractMenu
                anchor={contractSection.activeSection}
                setAnchor={contractSection.setActiveSection}
              />
            </div>

            <div
              id={"contract-container"}
              className="col-span-8 flex flex-col gap-3 overflow-y-auto h-[calc(100vh-170px)] no-scrollbar"
            >
              <RentalContractsCard
                modalCreate={modalCreate}
                modalEdit={modalEdit}
                modalDelete={modalDelete}
                rentalId={Number(rentalId)}
                contracts={contracts}
                isLoading={isLoadingContracts}
                defaultSelectedContract={defaultSelectedContract}
                setDefaultSelectedContract={setDefaultSelectedContract}
                onSuccess={handleContracts}
              />
            </div>

            {/* Empty */}
            <div />
          </div>
        </>
      ) : null}

      {navbarActiveItem === "services" ? (
        <>
          <div className="grid h-screen grid-cols-12">
            {/* Menu Sticky */}
            <div className="sticky self-start col-span-2 pr-2 top-16 h-fit">
              <RentalServicesMenu
                anchor={serviceSection.activeSection}
                setAnchor={serviceSection.setActiveSection}
              />
            </div>

            {/* Cards */}
            <div
              id={"service-container"}
              className="col-span-8 flex flex-col gap-3 overflow-y-auto h-[calc(100vh-170px)] no-scrollbar"
            >
              <SmartlocksProvider>
                <RentalSmartlocksAddModal />

                <RentalSmartlocksUpdateModal />

                <RentalSmartlocksDeleteModal />

                <RentalSmartlocksCard />
              </SmartlocksProvider>

              <PricelabsCard
                pricelabsData={rental?.services.pricelabs}
                workspaceOwner={rental?.workspace_owner}
              />
            </div>

            {/* Space */}
            <div />
          </div>
        </>
      ) : null}

      {navbarActiveItem === "invoice" ? <InProgressPlaceholder /> : null}

      {navbarActiveItem === "checklists" ? (
        <ChecklistList items={rental?.checklists} upsertModal={upsertModal} />
      ) : null}

      {navbarActiveItem === "traveler" && (
        <>
          {/* Menu Sticky */}
          <div className="grid h-screen grid-cols-12">
            <div className="sticky self-start col-span-2 pr-2 top-16 h-fit">
              <RentalTravelerMenu
                anchor={guestSection.activeSection}
                setAnchor={guestSection.setActiveSection}
                contracts={contracts}
              />
            </div>

            {/* Cards */}
            <div
              id={"guest-container"}
              className="col-span-8 flex flex-col gap-3 overflow-y-auto h-[calc(100vh-170px)] no-scrollbar"
            >
              {rental ? (
                <>
                  <FormCard
                    loading={loading}
                    rentals={rentals}
                    rental={rental}
                    guest_page={rental?.guest_page}
                    workspaceOwner={rental?.workspace_owner}
                    user={user}
                  />

                  <ContractCard
                    loading={loading}
                    rentals={rentals}
                    rental={rental}
                    guestPage={rental?.guest_page}
                    workspaceOwner={rental?.workspace_owner}
                    user={user}
                    contracts={contracts}
                  />

                  <ContactCard
                    loading={loading}
                    rentals={rentals}
                    rentalId={rental?.id}
                    guestPage={rental?.guest_page}
                    workspaceOwner={rental?.workspace_owner}
                  />

                  <InstructionCard
                    loading={loading}
                    rentals={rentals}
                    rentalId={rental?.id}
                    instructions={rental?.guest_page?.instructions}
                    guestPage={rental?.guest_page}
                    workspaceOwner={rental?.workspace_owner}
                  />

                  <CodeCard
                    loading={loading}
                    guestPage={rental?.guest_page}
                    rentalId={rental?.id}
                    part={navbarActiveItem}
                    workspaceOwner={rental?.workspace_owner}
                  />

                  <WifiCard
                    loading={loading}
                    guestPage={rental?.guest_page}
                    rentalId={rental?.id}
                    part={navbarActiveItem}
                    workspaceOwner={rental?.workspace_owner}
                  />

                  <PaymentExtraCard
                    loading={loading}
                    guestPage={rental?.guest_page}
                    rentalId={rental?.id}
                    part={navbarActiveItem}
                    workspaceOwner={rental?.workspace_owner}
                  />

                  <ExtrasCard
                    loading={loading}
                    guestPage={rental?.guest_page}
                    rentalId={rental?.id}
                    part={navbarActiveItem}
                    workspaceOwner={rental?.workspace_owner}
                  />

                  <RequestOpinionCard
                    loading={loading}
                    guestPage={rental?.guest_page}
                    rentalId={rental?.id}
                    part={navbarActiveItem}
                    workspaceOwner={rental?.workspace_owner}
                  />

                  <CgvCard
                    loading={loading}
                    guestPage={rental?.guest_page}
                    rentalId={rental?.id}
                    part={navbarActiveItem}
                    workspaceOwner={rental?.workspace_owner}
                  />
                </>
              ) : (
                <>
                  <ContactCardSkeleton />
                  <InstructionCardSkeleton />
                  <CodeCardSkeleton />
                  <PaymentExtraCardSkeleton />
                  <ExtraCardSkeleton />
                  <FormCardSkeleton />
                  <RequestOpinionCardSkeleton />
                  <CgvCardSkeleton />
                </>
              )}
            </div>

            {/* Space */}
            <div />
          </div>
        </>
      )}

      {navbarActiveItem === "website" && (
        <>
          <RentalIntegrateModal modal={integrateModal} />

          <RentalUpdatePhotosModal
            rentalId={rentalId}
            modal={updatePhotosModal}
            oldFiles={currentOldFiles}
            newFiles={currentNewFiles}
            onUpdatePhotos={handleUpdatePhotos}
          />

          <RentalWebsiteServicesUpdateModal
            rentalId={rentalId}
            modal={updateServicesModal}
            onUpdateServices={(rentalWebsite) => {
              if (rental) {
                onUpdateRental({ ...rental, website: rentalWebsite });
              }
            }}
          />

          <div className="grid h-screen grid-cols-12">
            {/* Menu Sticky */}
            <div className="sticky self-start col-span-2 pr-2 top-16 h-fit">
              <RentalWebsiteMenu
                anchor={websiteSection.activeSection}
                setAnchor={websiteSection.setActiveSection}
              />
            </div>

            <div
              id={"website-container"}
              className="col-span-8 flex flex-col gap-3 overflow-y-auto h-[calc(100vh-170px)] no-scrollbar"
            >
              <RentalWebsiteRulesCard
                loading={loading}
                rentalWebsiteRules={rental?.website.rules}
                workspaceOwner={rental?.workspace_owner}
                onUpdate={handleWebsiteRulesUpdate}
                onError={(message) => setError(message)}
              />

              <RentalPhotosCard
                loading={loading}
                modal={updatePhotosModal}
                title={t("Rental.Website.CarouselPhotos.title")}
                part={navbarActiveItem}
                oldFiles={currentOldFiles}
                newFiles={currentNewFiles}
                workspaceOwner={rental?.workspace_owner}
              />

              <RentalDescriptionCard
                loading={loading}
                rentalId={rentalId}
                part={navbarActiveItem}
                infosDescriptions={rental?.details?.descriptions}
                websiteDescription={rental?.website?.description}
                workspaceOwner={rental?.workspace_owner}
                onUpdate={handleDescriptionUpdate}
                onError={(message) => setError(message)}
              />

              <RentalWebsiteServicesCard
                loading={loading}
                rentalId={rentalId}
                modal={updateServicesModal}
                rentalServices={rental?.website.services}
                workspaceOwner={rental?.workspace_owner}
              />

              <ExtrasCard
                loading={loading}
                guestPage={rental?.guest_page}
                rentalId={rental?.id}
                part={navbarActiveItem}
                workspaceOwner={rental?.workspace_owner}
              />

              <RentalWebsitePaymentAndReceiptCard
                loading={loading}
                rentalId={rentalId}
                rentalWebsitePayments={rental?.website.stripe_payment_accounts}
                workspaceOwner={rental?.workspace_owner}
                onUpdate={handlePaymentAndReceiptUpdate}
              />

              <RentalWebsiteTermsCard
                loading={loading}
                rentalId={rentalId}
                terms={rental?.website.cgv}
                workspaceOwner={rental?.workspace_owner}
                onUpdate={handleTermsUpdate}
                onError={(message) => setError(message)}
              />
            </div>

            {/* Space */}
            <div />
          </div>
        </>
      )}
    </RentalLayout>
  );
};

// type RentalPlatformsConnectionsCardProps = {
//   loading: boolean;
//   rental?: Rental;
// };

// const RentalPlatformsConnectionsCard: React.FC<
//   RentalPlatformsConnectionsCardProps
// > = ({ loading, rental }) => {
//   const { t } = useTranslation();

//   if (loading) return <RentalPlatformsConnectionsCardSkeleton />;

//   return (
//     <Card label={t("Rental.platformsConnections")}>
//       <div className="grid grid-cols-3 gap-3">
//         <div className="flex flex-1 h-10 overflow-hidden border-1 border-element-border rounded-6px">
//           <div className="flex items-center flex-1 gap-2 px-2">
//             <Switch value={true} />

//             <AirbnbIcon className="w-7 h-7" />

//             <p className="font-semibold text-high-contrast">
//               {t("Rental.associateToAirbnbId")} ???????
//             </p>

//             <InfoCircleIcon className="w-4 h-4 cursor-pointer" />
//           </div>

//           <div className="flex items-center justify-center w-8 cursor-pointer bg-element-background border-l-1 border-element-border hover:bg-element-border-hover active:bg-element-background-active">
//             <CloseIcon className="w-4 h-4" />
//           </div>
//         </div>

//         <div className="flex flex-1 h-10 overflow-hidden border-1 border-element-border rounded-6px">
//           <div className="flex items-center flex-1 gap-2 px-2">
//             <Switch value={true} />

//             <BookingIcon className="w-7 h-7" />

//             <p className="font-semibold text-high-contrast">
//               {t("Rental.associateToBookingId")} ???????
//             </p>

//             <InfoCircleIcon className="w-4 h-4 cursor-pointer" />
//           </div>

//           <div className="flex items-center justify-center w-8 cursor-pointer bg-element-background border-l-1 border-element-border hover:bg-element-border-hover active:bg-element-background-active">
//             <CloseIcon className="w-4 h-4" />
//           </div>
//         </div>
//       </div>
//     </Card>
//   );
// };

// const RentalPlatformsConnectionsCardSkeleton: React.FC<{}> = () => {
//   const { t } = useTranslation();

//   return (
//     <Card label={t("Rental.platformsConnections")}>
//       <div className="grid grid-cols-3 gap-3 animate-pulse">
//         <div className="flex flex-1 h-10 overflow-hidden border-1 border-element-border rounded-6px">
//           <div className="flex items-center flex-1 gap-2 px-2">
//             <div className="w-10 h-6 rounded-l-full rounded-r-full bg-slate-200" />

//             <div className="rounded-full w-7 h-7 bg-slate-200" />

//             <p className="h-2 rounded w-52 bg-slate-300"></p>
//           </div>

//           <div className="flex items-center justify-center w-8 bg-element-background border-l-1 border-element-border"></div>
//         </div>

//         <div className="flex flex-1 h-10 overflow-hidden border-1 border-element-border rounded-6px">
//           <div className="flex items-center flex-1 gap-2 px-2">
//             <div className="w-10 h-6 rounded-l-full rounded-r-full bg-slate-200" />

//             <div className="rounded-full w-7 h-7 bg-slate-200" />

//             <p className="h-2 rounded w-52 bg-slate-300"></p>
//           </div>

//           <div className="flex items-center justify-center w-8 bg-element-background border-l-1 border-element-border"></div>
//         </div>

//         <div className="flex flex-1 h-10 overflow-hidden border-1 border-element-border rounded-6px">
//           <div className="flex items-center flex-1 gap-2 px-2">
//             <div className="w-10 h-6 rounded-l-full rounded-r-full bg-slate-200" />

//             <div className="rounded-full w-7 h-7 bg-slate-200" />

//             <p className="h-2 rounded w-52 bg-slate-300"></p>
//           </div>

//           <div className="flex items-center justify-center w-8 bg-element-background border-l-1 border-element-border"></div>
//         </div>
//       </div>
//     </Card>
//   );
// };

// type RentalActivitiesCardProps = { loading: boolean; rental?: Rental };

// const RentalActivitiesCard: React.FC<RentalActivitiesCardProps> = ({
//   loading,
//   rental,
// }) => {
//   const { t } = useTranslation();

//   if (loading) return <RentalActivitiesCardSkeleton />;

//   return (
//     <Card label={t("Rental.activites")}>
//       <div className="flex flex-col">
//         <p className="font-semibold text-low-contrast">
//           {t("Rental.historical")}
//         </p>

//         <div className="mt-3">
//           <HistoricalStep
//             title="???????"
//             description="Le ??/??/???? (??:??)"
//             Icon={DollarIcon}
//           />

//           <HistoricalStep
//             title="????????"
//             description="Le ??/??/???? (??:??)"
//             Icon={SendIcon}
//             isLast={true}
//           />
//         </div>
//       </div>
//     </Card>
//   );
// };

// const RentalActivitiesCardSkeleton: React.FC<{}> = () => {
//   const { t } = useTranslation();

//   return (
//     <Card label={t("Rental.activites")}>
//       <div className="flex flex-col">
//         <p className="font-semibold text-low-contrast">
//           {t("Rental.historical")}
//         </p>

//         <div className="mt-3">
//           <HistoricalStepSkeleton />

//           <HistoricalStepSkeleton isLast={true} />
//         </div>
//       </div>
//     </Card>
//   );
// };
