import React from "react";
import GalleryIcon from "../../../../../../assets/icons/photo.svg?react";
import {useModal} from "../../../../../../hooks/useModal";
import useGuestWebsiteStore from "../../../../../../stores/guestWebsiteStore/useGuestWebsiteStore";
import {CustomPreviewImage, ImageData} from "./CustomPreviewImage";
import {useTranslation} from "react-i18next";
import {cn} from "../../../../../../helpers/classHelper";

const GalleryComponent = () => {
  const {t} = useTranslation();
  const previewModal = useModal();
  const {rentalData, dataGuestWebsite} = useGuestWebsiteStore();
  const {photos} = rentalData || {};
  const {styles} = dataGuestWebsite || {};
  const {color_text} = styles || {};
  const getAllUrls = () => {
    let urls: ImageData[] = [];
    photos?.map((file) => {
      urls.push({url: file.original_url, name: file.name});
    });
    return urls;
  };

  const hasPhotos = photos && photos?.length > 0;
  const hasOnePhoto = photos && photos?.length === 1;
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 p-4 pl-2 ml-2 mr-2 md:mr-4 rounded-lg relative">
      {hasPhotos && (
        <CustomPreviewImage imageModal={previewModal} imgs={getAllUrls()} />
      )}
      {/* Main Image */}
      <div className={cn(`md:col-span-2 ${hasOnePhoto && "md:col-span-3"}`)}>
        {hasPhotos && (
          <img
            src={photos && photos[0]?.original_url}
            alt={photos && photos[0]?.name}
            className="w-full h-full object-cover rounded-lg"
          />
        )}
      </div>

      {/* Smaller Images */}
      {!hasOnePhoto && (
        <div className="grid grid-cols-2 gap-4">
          {photos
            ?.slice(1)
            .filter((image, index) => 5 > index)
            ?.map((image, index) => (
              <img
                key={index}
                src={image.original_url}
                alt={image.name}
                className="w-full h-full object-cover rounded-lg"
              />
            ))}
        </div>
      )}

      {/* View All Photos Button */}
      {hasPhotos && (
        <button
          // className="absolute -bottom-64 md:-bottom-20 right-10 md:right-60 bg-white text-high-contrast text-sm px-4 py-2 rounded-lg shadow-md flex items-center space-x-2 font-bold"
          className="absolute right-5 bottom-10 bg-white text-high-contrast text-sm px-4 py-2 rounded-lg shadow-md flex items-center space-x-2 font-bold"
          onClick={previewModal.open}
        >
          <span style={{color: color_text}}>{t("GuestWebsite.seePhotos")}</span>

          <GalleryIcon className="h-5 w-5" />
        </button>
      )}
    </div>
  );
};

export default GalleryComponent;
