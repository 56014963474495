import moment from "moment";
import React, { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import ChevronRightIcon from "../../assets/icons/chevron-right.svg?react";
import { CalendarInputValueRange } from "../../components/Common/CalendarInput/CalendarInput.type";
import { TableList } from "../../components/Common/TableList/TableList";
import { MainLayout } from "../../components/Layout/MainLayout/MainLayout";
import { PaymentExtrasReservationFilters } from "../../components/Payment/ExtrasList/Filters/PaymentExtrasReservationFilters";
import {
  PaymentReservationExtrasFiltersTotals,
  PaymentReservationExtrasStatusFilterButton,
} from "../../components/Payment/ExtrasList/Filters/PaymentReservationFilters.type";
import { PaymentExtrasListHeader } from "../../components/Payment/ExtrasList/PaymentExtrasListHeader";
import { PaymentExtrasListItem } from "../../components/Payment/ExtrasList/PaymentExtrasListItem";
import ExtraOrderModal from "../../components/Reservation/ExtrasOrder/Modal/ExtraOrderModal";
import paths from "../../constants/paths";
import { UseTableFetchParams, useTablePage } from "../../hooks/useTablePage";
import {
  GuestPageExtraOrder,
  PaymentExtraListItemResponse,
} from "../../types/GETTypes";
import { ValueType } from "../../types/commonTypes";
import { NoPaymentReservation } from "../PaymentReservationList/NoPaymentReservation";
import { PaymentExtrasListPageSkeleton } from "./PaymentExtrasListPageSkeleton";

export const PaymentExtrasListPage: React.FC<{}> = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const [paymentReservationExtraModal, setPaymentReservationExtraModal] =
    useState<PaymentExtraListItemResponse>();
  const [extraOrder, setExtraOrder] = useState<GuestPageExtraOrder>();
  const [openExtraOrderModal, setOpenExtraOrderModal] = useState(false);

  const tablePage = useTablePage(
    `${import.meta.env.VITE_API_URL}${paths.API.PAYMENTS.EXTRAS_ORDERS}`,
    "reservation_extras",
    {
      otherMetas: [
        "total",
        "total_canceled",
        "total_filtered",
        "total_paid",
        "total_partially_paid",
        "total_pre_authorization",
        "total_unpaid",
      ],
    }
  );

  const { currentOtherMetas, data } = tablePage;

  const reservations = (
    data && data?.length > 0 ? data : []
  ) as PaymentExtraListItemResponse[];

  const [currentActiveButtonFilter, setCurrentActiveButtonFilter] =
    useState<PaymentReservationExtrasStatusFilterButton>("ALL");

  const [currentRangeDate, setCurrentRangeDate] = useState<{
    startDate: Date;
    endDate: Date;
  } | null>(null);

  const [totals, setTotals] = useState<PaymentReservationExtrasFiltersTotals>({
    all: 0,
    unpaid: 0,
    partiallyPaid: 0,
    preAuthorized: 0,
    paid: 0,
    canceled: 0,
  });

  const [fetchParams, setFetchParams] = useState<UseTableFetchParams>({});

  const fetchWithFilters = () => {
    if (currentRangeDate !== null) {
      setFetchParams(() => {
        const nextParams: UseTableFetchParams = {
          status: currentActiveButtonFilter,
          from: moment(currentRangeDate.startDate).format("YYYY-MM-DD"),
          to: moment(currentRangeDate.endDate).format("YYYY-MM-DD"),
        };

        if (currentActiveButtonFilter === "ALL") {
          delete nextParams.status;
        }

        return nextParams;
      });
    }
  };

  useEffect(() => {
    fetchWithFilters();
  }, [currentActiveButtonFilter, currentRangeDate]);

  useEffect(() => {
    if (
      currentActiveButtonFilter === "ALL" &&
      currentRangeDate !== null &&
      currentOtherMetas.length > 0
    ) {
      setTotals({
        all:
          Number(
            currentOtherMetas.find((meta) => meta.key === "total_filtered")
              ?.value
          ) ?? 0,
        unpaid:
          Number(
            currentOtherMetas.find((meta) => meta.key === "total_unpaid")?.value
          ) ?? 0,
        partiallyPaid:
          Number(
            currentOtherMetas.find(
              (meta) => meta.key === "total_partially_paid"
            )?.value
          ) ?? 0,
        paid:
          Number(
            currentOtherMetas.find((meta) => meta.key === "total_paid")?.value
          ) ?? 0,
        preAuthorized:
          Number(
            currentOtherMetas.find(
              (meta) => meta.key === "total_pre_authorization"
            )?.value
          ) ?? 0,
        canceled:
          Number(
            currentOtherMetas.find((meta) => meta.key === "total_canceled")
              ?.value
          ) ?? 0,
      });
    }
  }, [currentActiveButtonFilter, currentRangeDate, currentOtherMetas]);

  useEffect(() => {
    if (Object.keys(fetchParams).length > 0) {
      tablePage.fetch(fetchParams);
    }
  }, [fetchParams]);

  const handleDatesChange = (dates: CalendarInputValueRange) => {
    if (Array.isArray(dates) && dates.length >= 2) {
      const startDate: Date | null = dates[0];
      const endDate: Date | null = dates[1];
      if (
        (startDate === null && endDate === null) ||
        (startDate instanceof Date && endDate instanceof Date)
      ) {
        if (startDate !== null && endDate !== null) {
          setCurrentRangeDate({
            startDate,
            endDate,
          });

          let fetchParams: UseTableFetchParams = {
            from: moment(startDate).format("YYYY-MM-DD"),
            to: moment(endDate).format("YYYY-MM-DD"),
          };

          if (currentActiveButtonFilter !== "ALL") {
            fetchParams = {
              ...fetchParams,
              status: currentActiveButtonFilter,
            };
          }

          tablePage.fetch(fetchParams);
        }
      }
    }
  };

  const getTitle = () => {
    return (
      <p className="flex flex-row items-center gap-1">
        {t("Payments.title")} <ChevronRightIcon />
        {t("Payments.extraList")}
      </p>
    );
  };

  const getFiltersNode = (): ReactNode => {
    return (
      <PaymentExtrasReservationFilters
        loading={tablePage.loading}
        onSearchText={tablePage.handleChangeSearch}
        totals={totals}
        currentActiveButtonFilter={currentActiveButtonFilter}
        onFilterButtonClick={(value) =>
          setCurrentActiveButtonFilter(
            value as PaymentReservationExtrasStatusFilterButton
          )
        }
        onDatesChanged={handleDatesChange}
      />
    );
  };

  const handleOpenExtraFromUrl = () => {
    const checkUrl = (url: string) => {
      if (!url) {
        return { isValid: false, params: "" };
      }
      try {
        const parsedUrl = new URL(url, window.location.origin); // Parse the URL
        if (parsedUrl.pathname !== paths.PAYMENTS_EXTRAS) {
          return { isValid: false, params: "" };
        }
        const openParam = parsedUrl.searchParams.get("openExtra");
        return {
          isValid: openParam && openParam.trim() !== "",
          params: openParam,
        };
      } catch (error) {
        return { isValid: false, params: "" };
      }
    };

    const currentUrl = `${window.location.origin}${location.pathname}${location.search}`;
    const { isValid, params } = checkUrl(currentUrl);
    if (isValid) {
      if (params) {
        window.history.pushState({}, "", location.pathname);
        setTimeout(() => {
          openModal(Number(params));
        }, 500);
      }
    }
  };

  const openModal = (paymentReservationId: ValueType) => {
    const reservation = reservations.find(
      (row) => row.id === paymentReservationId
    );
    if (reservation) {
      const order = reservation?.payment_extras_order;
      setPaymentReservationExtraModal(reservation);
      const array = order?.extras
        ? order.extras?.map((extra) => ({ ...extra, extra_available: true }))
        : [];
      if (order && array?.length > 0) {
        setExtraOrder({ ...order, extras: array });
        setOpenExtraOrderModal(true);
      }
    }
  };

  const closeModal = () => {
    setPaymentReservationExtraModal(undefined);
    setExtraOrder(undefined);
    setOpenExtraOrderModal(false);
  };

  const onSuccess = (resultReservation: any) => {
    closeModal();
    fetchWithFilters();
  };

  useEffect(() => {
    if (tablePage.data && tablePage?.data?.length > 0) {
      handleOpenExtraFromUrl();
    }
  }, [tablePage?.data]);

  return (
    <>
      <MainLayout
        title={getTitle()}
        sidebarActiveItem="payments"
        sidebarActiveSubItem="extras"
      >
        <ExtraOrderModal
          isVisible={openExtraOrderModal}
          closeModal={closeModal}
          extraOrder={extraOrder}
          setExtraOrder={setExtraOrder}
          reservation={paymentReservationExtraModal}
          page="payment"
          onSuccess={onSuccess}
        />
        <TableList
          i18nElement="Global.payment"
          Filters={getFiltersNode()}
          filterTotal={
            Number(
              currentOtherMetas.find((meta) => meta.key === "total_filtered")
                ?.value
            ) ?? 0
          }
          Header={PaymentExtrasListHeader}
          tablePage={tablePage}
          Item={PaymentExtrasListItem}
          Skeleton={PaymentExtrasListPageSkeleton}
          NoData={
            <NoPaymentReservation
              title={t("Global.noPaiementExtraTitle")}
              description={t("Global.noPaiementExtraContent")}
            />
          }
          onClick={openModal}
        />
      </MainLayout>
    </>
  );
};
