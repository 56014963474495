import React from "react";
import {RentalLightBookingItemResponse} from "../../../../types/GETTypes";
import CheckSuccessIcon from "../../../../assets/icons/check-circle-green.svg?react";
import {Badge} from "../../../Common/Badge/Badge";
import {useTranslation} from "react-i18next";
interface BookingConnectSuccessProps {
  properties: RentalLightBookingItemResponse[] | undefined;
}

const BookingConnectSuccessCard = ({
  properties,
}: BookingConnectSuccessProps) => {
  const {t} = useTranslation();
  return (
    <div className="space-y-2">
      <div className="flex flex-col bg-success-light items-center justify-center p-8 py-6 my-3 space-y-4">
        <CheckSuccessIcon className="h-10 w-10" />
        <div className="flex flex-col text-success-text space-y-4 items-center justify-center">
          <p className="">{t("Progression.importSuccessTitle")}</p>
          <p className="font-bold">{t("Progression.importSuccessTitle2")}</p>
          <p> {t("Progression.importSuccessTitle3")}</p>
        </div>
      </div>
      <div className="">
        {properties?.map((property) => (
          <div className="flex gap-x-3 px-0 p-4 items-center">
            <div className="object-fill w-16 h-12 shrink-0 rounded-4px border-1 border-element-border/50 overflow-hidden">
              <img
                src={property?.photo ?? ""}
                className="object-cover w-16 h-12 shrink-0"
              />
            </div>

            <div className="flex flex-col gap-y-3 w-2/4">
              <div>
                <p className="font-semibold text-high-contrast underline underline-offset-4">
                  {property?.name}
                </p>
                <p className="font-light text-low-contrast mt-1">
                  {`${property?.address} ${property?.zip_code} ${property?.city}`}
                </p>
              </div>
            </div>

            <div className="flex flex-1 justify-end">
              <Badge label={t("Booking.statusPending")} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BookingConnectSuccessCard;
