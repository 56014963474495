import React, { useEffect, useState } from "react";

import { MainLayout } from "../../components/Layout/MainLayout/MainLayout";
import { WebsiteTabs } from "../../components/Website/WebsiteTabs";
import { ValueType } from "../../types/commonTypes";
import { WebsiteContentCards } from "../../components/Website/WebsiteContentCards";
import { WebsiteStyleCards } from "../../components/Website/WebsiteStyleCards";
import { WebsiteSettingsCards } from "../../components/Website/WebsiteSettingsCards";
import { useParams } from "react-router-dom";
import { WebsiteResponse } from "../../types/GETTypes";
import { get } from "../../helpers/APIHelper";
import paths from "../../constants/paths";
import { Trans } from "react-i18next";
import ChevronRightIcon from "../../assets/icons/chevron-right.svg?react";
import { WebsiteRentals } from "../../components/Website/WebsiteRentals";

export const WebsitePage: React.FC<{}> = () => {
  const [currentTab, setCurrentTab] = useState<ValueType>("content");

  const [currentWebsite, setCurrentWebsite] = useState<WebsiteResponse>();

  const { websiteId } = useParams();

  const fetchWebsite = async () => {
    if (!websiteId) return;

    const res = await get(
      `${import.meta.env.VITE_API_URL}${
        paths.API.WEBSITE.GET_BY_ID
      }${websiteId}`
    );

    if (!res.data.success) return;

    setCurrentWebsite(res.data.result);
  };

  useEffect(() => {
    void fetchWebsite();
  }, [websiteId]);

  const getTitle = () => {
    return (
      <div className="flex gap-1 items-center">
        <p className="text-low-contrast font-semibold text-sm">
          <Trans i18nKey="Menu.websites" />
        </p>

        <ChevronRightIcon className="size-4" />

        <p className="text-high-contrast font-semibold text-base">
          {currentWebsite?.content?.general.title}
        </p>
      </div>
    );
  };

  const handleUpdateWebsite = (nextWebsite: WebsiteResponse) => {
    setCurrentWebsite(nextWebsite);
  };

  return (
    <MainLayout title={getTitle()} sidebarActiveItem="websites">
      <WebsiteTabs
        tab={currentTab}
        url={currentWebsite?.integration.url}
        onChange={setCurrentTab}
      />

      {currentTab === "content" && (
        <WebsiteContentCards
          website={currentWebsite}
          onUpdateWebsite={handleUpdateWebsite}
        />
      )}
      {currentTab === "rentals" && (
        <WebsiteRentals
          website={currentWebsite}
          onUpdateWebsite={handleUpdateWebsite}
        />
      )}
      {currentTab === "style" && (
        <WebsiteStyleCards
          website={currentWebsite}
          onUpdateWebsite={handleUpdateWebsite}
        />
      )}
      {currentTab === "settings" && (
        <WebsiteSettingsCards
          website={currentWebsite}
          onUpdateWebsite={handleUpdateWebsite}
        />
      )}
    </MainLayout>
  );
};
