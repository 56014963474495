import React, {useEffect, useRef, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useUpdateProgression} from "../../../hooks/api/progression";
import {
  useChangeStep,
  useHandleSubmit,
  useIconSubmitButton,
  useInitProgressionRentalsRulesModal,
  useInitProgressionRentalsRulesModalStates,
  useSelectRental,
  useStep,
  useTextSubmitButton,
} from "../../../pages/ProgressionRentalsRules/ProgressionRentalsRules.hooks";
import {ValueType} from "../../../types/commonTypes";
import {
  RentalLightListItemResponse,
  RentalResponse,
} from "../../../types/GETTypes";
import {Button} from "../../Common/Button/Button";
import {CarouselWizard} from "../../Common/CarouselWizard/CarouselWizard";
import {CarouselWizardStep} from "../../Common/CarouselWizard/CarouselWizard.type";
import {ErrorMessage} from "../../Common/ErrorMessage/ErrorMessage";
import {RightModal} from "../../Common/RightModal/RightModal";
import {Title} from "../../Common/Title/Title";
import {
  ProgressionRentalsRulesModalNextStepOtherFields,
  ProgressionRentalsRulesModalRef,
  ProgressionRentalsRulesModalStep,
} from "./ProgressionRentalsRulesModal.type";
import {ProgressionRentalsReservationRules} from "./Steps/ReservationRules";
import ProgressionRentalsInfosPrice from "./Steps/Infos";
import {ProgressionRentalsPrice} from "./Steps/Price";
import {ProgressionRentalsPaymentPolicies} from "./Steps/PaymentPolicies";
import {get} from "../../../helpers/APIHelper";
import paths from "../../../constants/paths";
import {RentalPageProvider} from "../../../pages/RentalPage/RentalPageContext";
import {Rental} from "../../../pages/RentalPage/RentalPage.type";
import CheckWhiteIcon from "../../../assets/icons/check-white.svg?react";

type ProgressionRentalsRulesModalProps = {
  currentRental?: RentalLightListItemResponse;
  rentals: RentalLightListItemResponse[];
  isVisible: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

export const ProgressionRentalsRulesModal: React.FC<
  ProgressionRentalsRulesModalProps
> = ({currentRental, rentals, isVisible, onClose, onSuccess}) => {
  const {t} = useTranslation();

  const initStates = useInitProgressionRentalsRulesModalStates(currentRental);
  const [loading, setLoading] = initStates.loadingState;
  const [loadingRental, setLoadingRental] = useState(false);
  const [error, setError] = initStates.errorState;
  const [currentStep, setCurrentStep] = initStates.currentStepState;
  const [finalStep, setFinalStep] = initStates.finalStepState;
  const [selectedRentals, setSelectedRentals] = initStates.selectedRentalsState;
  const [countRentals, setCountRentals] = initStates.countRentalsState;
  const [newCurrentRental, setNewCurrentRental] =
    initStates.newCurrentRentalState;
  const [otherFields, setOtherFields] = initStates.otherFieldsState;

  useInitProgressionRentalsRulesModal(
    rentals,
    [countRentals, setCountRentals],
    [currentStep, setCurrentStep],
    [finalStep, setFinalStep],
    [selectedRentals, setSelectedRentals],
    [newCurrentRental, setNewCurrentRental],
    setOtherFields,
    setLoading,
    setError,
    onSuccess,
    onClose
  );


  const handleNextStep = (
    nextRental?: RentalLightListItemResponse,
    nextOtherFields?: ProgressionRentalsRulesModalNextStepOtherFields
  ) => {
    useChangeStep(
      () => setCurrentStep((prev) => prev + 1),
      () => setNewCurrentRental(nextRental),
      () => setOtherFields(nextOtherFields)
    );
  };

  const infoRef = useRef<ProgressionRentalsRulesModalRef>(null);
  const getInfoStep = () =>
    useStep(
      t("Progression.RentalsRules.Infos.title"),
      <ProgressionRentalsInfosPrice
        rentals={rentals}
        onNext={handleNextStep}
        ref={infoRef}
      />
    );

  const reservationRulesRef = useRef<ProgressionRentalsRulesModalRef>(null);
  const getReservationRulesStep = (): CarouselWizardStep => {
    return {
      title: t("Progression.RentalsRules.Rules.title"),
      Node: (
        <ProgressionRentalsReservationRules
          onNext={handleNextStep}
          ref={reservationRulesRef}
        />
      ),
    };
  };

  const priceRef = useRef<ProgressionRentalsRulesModalRef>(null);
  const getPriceStep = () =>
    useStep(
      t("Progression.RentalsRules.Price.titleStep"),
      <ProgressionRentalsPrice onNext={handleNextStep} ref={priceRef} />
    );

  const paymentPoliciesRef = useRef<ProgressionRentalsRulesModalRef>(null);
  const getPaymentPoliciesStep = () =>
    useStep(
      t("Progression.RentalsRules.PaymentPolicies.title"),
      <ProgressionRentalsPaymentPolicies
        onNext={handleNextStep}
        ref={paymentPoliciesRef}
      />
    );

  const modalSteps = (): CarouselWizardStep[] => {
    const array: CarouselWizardStep[] = [
      getInfoStep(),
      getReservationRulesStep(),
      getPriceStep(),
      getPaymentPoliciesStep(),
    ];

    return array;
  };

  return (
    <RightModal
      isVisible={isVisible}
      onClose={loading ? () => {} : onClose}
      title={
        <Title>
          {t("Progression.RentalsRules.updateModalTitle")}
          <span className="underline">{currentRental?.name}</span>
        </Title>
      }
    >
      <div className="flex flex-col w-full gap-2">
        <div className="flex-1 overflow-y-scroll">
          <RentalPageProvider noNavigation rentalIdProps={String(currentRental?.id)}>
            <CarouselWizard
              disableClickBefore={loading || loadingRental}
              stepsUnlocked={true}
              currentStepIndex={
                currentStep > finalStep ? finalStep : currentStep
              }
              onChangeStep={(step: number) => {
                setCurrentStep(step);
              }}
              steps={modalSteps()}
              loading={loadingRental}
              isValidated={false}
              disableHeader={true}
            />
          </RentalPageProvider>
        </div>

        <ErrorMessage>{error}</ErrorMessage>

        {!loadingRental ? (
          <div className="flex gap-3 mt-3">
            <Button
              RightIcon={CheckWhiteIcon}
              onClick={onClose}
              loading={loading}
            >
              {t("Global.ok")}
            </Button>
          </div>
        ) : null}
      </div>
    </RightModal>
  );
};
