import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import BrushIcon from "../../../assets/icons/brush.svg?react";
import { Card } from "../../Common/Card/Card";
import { WebsiteResponse } from "../../../types/GETTypes";
import { Trans, useTranslation } from "react-i18next";
import CheckIcon from "../../../assets/icons/check-white.svg?react";
import EditIcon from "../../../assets/icons/edit.svg?react";
import ArrowLeftIcon from "../../../assets/icons/arrow-left.svg?react";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import { TextInput } from "../../Common/TextInput/TextInput";
import { TextAreaInput } from "../../Common/TextAreaInput/TextAreaInput";
import { post } from "../../../helpers/APIHelper";
import paths from "../../../constants/paths";

export const WebsiteColorsCard: React.FC<{
  website: WebsiteResponse | undefined;
  onUpdateWebsite: (nextWebsite: WebsiteResponse) => void;
}> = ({ website, onUpdateWebsite }) => {
  const { t } = useTranslation();

  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const [updateError, setUpdateError] = useState<string | undefined>();

  const [editMode, setEditMode] = useState<boolean>(false);

  const editModeRef = useRef<{
    handleClear: () => void;
    handleCheckData: () => {
      mainColor: string;
      textColor: string;
      bgColor: string;
    } | null;
  } | null>(null);

  const handleCancel = () => {
    setEditMode(false);
    editModeRef.current?.handleClear();
  };

  const handleUpdate = async () => {
    const values = editModeRef.current?.handleCheckData();

    if (!values) return;

    setUpdateLoading(true);
    setUpdateError(undefined);

    const formData = new FormData();
    formData.append("color_main", values.mainColor);
    formData.append("color_text", values.textColor);
    formData.append("color_background", values.bgColor);

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.WEBSITE.UPDATE.COLORS(
        website?.id!
      )}`,
      formData
    );

    if (!res?.data?.success) {
      setUpdateError(res?.response?.data?.message);
    } else {
      onUpdateWebsite(res.data?.result);
      setEditMode(false);
    }

    setUpdateLoading(false);
  };

  useEffect(() => {
    setUpdateError(undefined);
  }, [editMode]);

  return (
    <Card
      Icon={BrushIcon}
      label={t("Website.Colors.title")}
      anchor="colors-card"
      button={{
        Icon: editMode ? CheckIcon : EditIcon,
        type: editMode ? "primary" : "secondary",
        label: editMode ? t("Global.record") : t("Global.edit"),
        onClick: editMode ? () => handleUpdate() : () => setEditMode(!editMode),
        disabled: !website || updateLoading,
      }}
      secondaryButton={
        editMode
          ? {
              label: t("Global.cancel"),
              LeftIcon: ArrowLeftIcon,
              onClick: handleCancel,
              disabled: updateLoading,
            }
          : undefined
      }
    >
      <ErrorMessage>{updateError}</ErrorMessage>

      {editMode ? (
        <EditMode ref={editModeRef} website={website} loading={updateLoading} />
      ) : (
        <ViewMode website={website} />
      )}
    </Card>
  );
};

const ViewMode: React.FC<{ website: WebsiteResponse | undefined }> = ({
  website,
}) => {
  if (!website) return <ViewModeSkeleton />;

  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-col gap-1">
        <p className="text-low-contrast font-light">
          <Trans i18nKey="Website.Colors.mainColor" />
        </p>

        <div className="flex gap-2 items-center">
          <div
            className="size-6 border-1 border-element-border rounded-4px"
            style={{ backgroundColor: website?.styles.color_main }}
          />

          <p className="text-high-contrast font-semibold">
            {website?.styles.color_main}
          </p>
        </div>
      </div>

      {website.styles.model === "MODEL_2.0" && (
        <div className="flex flex-col gap-1">
          <p className="text-low-contrast font-light">
            <Trans i18nKey="Website.Colors.textColor" />
          </p>

          <div className="flex gap-2 items-center">
            <div
              className="size-6 border-1 border-element-border rounded-4px"
              style={{ backgroundColor: website?.styles.color_text }}
            />

            <p className="text-high-contrast font-semibold">
              {website?.styles.color_text}
            </p>
          </div>
        </div>
      )}

      <div className="flex flex-col gap-1">
        <p className="text-low-contrast font-light">
          <Trans i18nKey="Website.Colors.bgColor" />
        </p>

        <div className="flex gap-2 items-center">
          <div
            className="size-6 border-1 border-element-border rounded-4px"
            style={{ backgroundColor: website?.styles.color_background }}
          />

          <p className="text-high-contrast font-semibold">
            {website?.styles.color_background}
          </p>
        </div>
      </div>
    </div>
  );
};

const ViewModeSkeleton: React.FC<{}> = ({}) => {
  return (
    <div className="flex flex-col gap-3 animate-pulse">
      <div className="flex flex-col gap-1">
        <p className="text-low-contrast font-light">
          <Trans i18nKey="Website.Colors.mainColor" />
        </p>

        <div className="flex gap-2 items-center">
          <div className="size-6 border-1 border-element-border rounded-4px bg-slate-200" />

          <p className="text-high-contrast font-semibold h-2 bg-slate-300 w-16 rounded"></p>
        </div>
      </div>

      <div className="flex flex-col gap-1">
        <p className="text-low-contrast font-light">
          <Trans i18nKey="Website.Colors.textColor" />
        </p>

        <div className="flex gap-2 items-center">
          <div className="size-6 border-1 border-element-border rounded-4px bg-slate-200" />

          <p className="text-high-contrast font-semibold h-2 bg-slate-300 w-16 rounded"></p>
        </div>
      </div>

      <div className="flex flex-col gap-1">
        <p className="text-low-contrast font-light">
          <Trans i18nKey="Website.Colors.bgColor" />
        </p>

        <div className="flex gap-2 items-center">
          <div className="size-6 border-1 border-element-border rounded-4px bg-slate-200" />

          <p className="text-high-contrast font-semibold h-2 bg-slate-300 w-16 rounded"></p>
        </div>
      </div>
    </div>
  );
};

interface EditModeProps {
  website: WebsiteResponse | undefined;
  loading: boolean;
}

const EditMode = forwardRef<
  {
    handleClear: () => void;
    handleCheckData: () => {
      mainColor: string;
      textColor: string;
      bgColor: string;
    } | null;
  },
  EditModeProps
>(({ website, loading }, ref) => {
  const { t } = useTranslation();

  const [mainColor, setMainColor] = useState<string>("");
  const [mainColorError, setMainColorError] = useState<string | undefined>();

  const [textColor, setTextColor] = useState<string>("");
  const [textColorError, setTextColorError] = useState<string | undefined>();

  const [bgColor, setBgColor] = useState<string>("");
  const [textBgError, setBgColorError] = useState<string | undefined>();

  const handleClear = () => {
    setMainColor("");
    setMainColorError(undefined);

    setTextColor("");
    setTextColorError(undefined);

    setBgColor("");
    setBgColorError(undefined);
  };

  const handleCheckData = () => {
    let isError = false;

    setMainColorError(undefined);
    setTextColorError(undefined);
    setBgColorError(undefined);

    const hexColorRegex = /^#([A-Fa-f0-9]{3}|[A-Fa-f0-9]{6})$/;

    // Vérification mainColor
    if (!mainColor) {
      setMainColorError(t("Global.required"));
      isError = true;
    } else if (!hexColorRegex.test(mainColor)) {
      setMainColorError(t("Website.Colors.invalidHex"));
      isError = true;
    }

    // Vérification textColor
    if (!textColor) {
      setTextColorError(t("Global.required"));
      isError = true;
    } else if (!hexColorRegex.test(textColor)) {
      setTextColorError(t("Website.Colors.invalidHex"));
      isError = true;
    }

    // Vérification bgColor
    if (!bgColor) {
      setBgColorError(t("Global.required"));
      isError = true;
    } else if (!hexColorRegex.test(bgColor)) {
      setBgColorError(t("Website.Colors.invalidHex"));
      isError = true;
    }

    if (isError) {
      return null;
    }

    return {
      mainColor,
      textColor,
      bgColor,
    };
  };

  useImperativeHandle(ref, () => ({
    handleClear,
    handleCheckData,
  }));

  useEffect(() => {
    if (website) {
      setMainColor(website?.styles.color_main);
      setMainColorError(undefined);

      setTextColor(website?.styles.color_text);
      setTextColorError(undefined);

      setBgColor(website?.styles.color_background);
      setBgColorError(undefined);
    }
  }, [website]);

  const handleChangeMainColor = (text: string) => {
    setMainColor(text);

    setMainColorError(undefined);
  };

  const handleChangeTextColor = (text: string) => {
    setTextColor(text);

    setTextColorError(undefined);
  };

  const handleChangeBgColor = (text: string) => {
    setBgColor(text);

    setBgColorError(undefined);
  };

  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-row items-end gap-2">
        <div
          className="size-8 rounded-6px border-1 border-element-border"
          style={{ backgroundColor: mainColor }}
        />

        <TextInput
          label={t("Website.Colors.mainColor")}
          value={mainColor}
          onChangeText={handleChangeMainColor}
          error={mainColorError}
          required={true}
          disabled={loading}
        />
      </div>

      {website?.styles.model === "MODEL_2.0" && (
        <div className="flex flex-row items-end gap-2">
          <div
            className="size-8 rounded-6px border-1 border-element-border"
            style={{ backgroundColor: textColor }}
          />

          <TextInput
            label={t("Website.Colors.textColor")}
            value={textColor}
            onChangeText={handleChangeTextColor}
            error={textColorError}
            required={true}
            disabled={loading}
          />
        </div>
      )}

      <div className="flex flex-row items-end gap-2">
        <div
          className="size-8 rounded-6px border-1 border-element-border"
          style={{ backgroundColor: bgColor }}
        />

        <TextInput
          label={t("Website.Colors.bgColor")}
          value={bgColor}
          onChangeText={handleChangeBgColor}
          error={textBgError}
          required={true}
          disabled={loading}
        />
      </div>
    </div>
  );
});
