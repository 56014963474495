import React, {useEffect} from "react";
import {useParams, useSearchParams} from "react-router-dom";
import useGuestWebsiteStore, {
  GuestWebsiteTabUrlType,
} from "../../stores/guestWebsiteStore/useGuestWebsiteStore";
import GuestWebsiteLayout from "../../components/GuestWebsite/GuestWebsiteLayout";
import GuestWebsiteHomePage from "../../components/GuestWebsite/Home/GuestWebsiteHomePage";
import GuestWebsiteRentalsPage from "../../components/GuestWebsite/Rentals/GuestWebsiteRentalsPage";
import GuestWebsiteServicesPage from "../../components/GuestWebsite/Services/GuestWebsiteServicesPage";
import GuestWebsiteContactPage from "../../components/GuestWebsite/Contact/GuestWebsiteContactPage";
import GuestWebsiteAboutPage from "../../components/GuestWebsite/About/GuestWebsiteAboutPage";
import useGuestWebsite from "../../hooks/useGuestWebsite";
import GuestWebsiteRentalPage from "../../components/GuestWebsite/Rental/GuestWebsiteRentalPage";
import GuestWebsiteMentionsPage from "../../components/GuestWebsite/Mentions/GuestWebsiteMentionsPage";
import GuestWebsiteConditionsPage from "../../components/GuestWebsite/Conditions/GuestWebsiteConditionsPage";
import GuestWebsiteConfidentialityPage from "../../components/GuestWebsite/Privacy/GuestWebsitePrivacyPage";
import GuestWebsiteErrorPage from "../../components/GuestWebsite/Common/Template2.0/ErrorPage";

const GuestWebsitePage = () => {
  const {websiteName} = useParams();
  const [searchParams] = useSearchParams();
  const {
    tabGuestWebsite,
    setTabUrlGuestWebsite,
    setTabGuestWebsite,
    setDataGuestWebsite,
    tabRentalId,
    setTemplateGuestWebsite,
  } = useGuestWebsiteStore();
  const {getWebsite, isLoadingWebsite, dataWebsite, errorWebsite} =
    useGuestWebsite();

  const detectUrl = () => {
    const tabUrl = searchParams.get("tab") as GuestWebsiteTabUrlType;
    const rentalId = searchParams.get("rentalId");

    setTabUrlGuestWebsite(tabUrl || "home");

    if (tabUrl === "rental" && rentalId) {
      setTabGuestWebsite("rental", rentalId);
    } else {
      switch (tabUrl) {
        case "home":
          setTabGuestWebsite("home");
          break;
        case "rentals":
          setTabGuestWebsite("rentals");
          break;
        case "services":
          setTabGuestWebsite("services");
          break;
        case "about":
          setTabGuestWebsite("about");
          break;
        case "contact":
          setTabGuestWebsite("contact");
          break;
        case "mentions":
          setTabGuestWebsite("mentions");
          break;
        case "conditions":
          setTabGuestWebsite("conditions");
          break;
        case "privacy":
          setTabGuestWebsite("privacy");
          break;
        default:
          setTabGuestWebsite("home");
          break;
      }
    }
  };

  useEffect(() => {
    if (websiteName) {
      detectUrl();
      getWebsite(websiteName);
    }
  }, [websiteName, searchParams]);

  useEffect(() => {
    if (dataWebsite && !errorWebsite && websiteName) {
      setDataGuestWebsite({...dataWebsite, websiteName});
      setTemplateGuestWebsite(dataWebsite.styles.model);
    } else if (errorWebsite) {
      setTabGuestWebsite("error");
    }
  }, [dataWebsite, errorWebsite]);

  const pageByTab = () => {
    switch (tabGuestWebsite) {
      case "home":
        return <GuestWebsiteHomePage loading={isLoadingWebsite} />;
      case "rentals":
        return <GuestWebsiteRentalsPage loading={isLoadingWebsite} />;
      case "rental":
        const rentalId = searchParams.get("rentalId") ?? tabRentalId;
        if (rentalId) {
          return (
            <GuestWebsiteRentalPage
              loading={isLoadingWebsite}
              rentalId={rentalId}
            />
          );
        }
        return <GuestWebsiteHomePage loading={isLoadingWebsite} />;
      case "services":
        return <GuestWebsiteServicesPage loading={isLoadingWebsite} />;
      case "about":
        return <GuestWebsiteAboutPage loading={isLoadingWebsite} />;
      case "contact":
        return <GuestWebsiteContactPage loading={isLoadingWebsite} />;
      case "mentions":
        return <GuestWebsiteMentionsPage loading={isLoadingWebsite} />;
      case "conditions":
        return <GuestWebsiteConditionsPage loading={isLoadingWebsite} />;
      case "privacy":
        return <GuestWebsiteConfidentialityPage loading={isLoadingWebsite} />;
      default:
        return <GuestWebsiteHomePage loading={isLoadingWebsite} />;
    }
  };

  return tabGuestWebsite === "error" ? (
    <GuestWebsiteErrorPage />
  ) : (
    <div className="flex flex-col h-screen w-screen">
      <GuestWebsiteLayout loading={isLoadingWebsite}>
        {pageByTab()}
      </GuestWebsiteLayout>
    </div>
  );
};

export default GuestWebsitePage;
