import FranceFlag from "../assets/flags/France.svg?react";
import PortugalFlag from "../assets/flags/Portugal.svg?react";
import SpainFlag from "../assets/flags/Spain.svg?react";
import UKFlag from "../assets/flags/United Kingdom.svg?react";

const languages = [
  {
    label: "Français",
    value: "fr",
    Icon: FranceFlag,
  },
  {
    label: "English",
    value: "en",
    Icon: UKFlag,
  },
  {
    label: "Español",
    value: "es",
    Icon: SpainFlag,
  },
  {
    label: "Português",
    value: "pt",
    Icon: PortugalFlag,
  },
];

export default languages;
