import React, { Dispatch, SetStateAction } from "react";
import { PageMenu } from "../../../Common/PageMenu/PageMenu";

import ContactsIcon from "../../../../assets/icons/contacts.svg?react";
import ContactsHighIcon from "../../../../assets/icons/contacts-high.svg?react";
import InformationIcon from "../../../../assets/icons/information.svg?react";
import InformationHighIcon from "../../../../assets/icons/information-high.svg?react";
import KeyIcon from "../../../../assets/icons/key.svg?react";
import KeyHighIcon from "../../../../assets/icons/key-high.svg?react";
import WifiIcon from "../../../../assets/icons/wifi.svg?react";
import WifiHighIcon from "../../../../assets/icons/wifi-high.svg?react";
import MoneyIcon from "../../../../assets/icons/money.svg?react";
import MoneyHighIcon from "../../../../assets/icons/money-high.svg?react";
import SparklesIcon from "../../../../assets/icons/sparkles.svg?react";
import SparklesHighIcon from "../../../../assets/icons/sparkles-high.svg?react";
import FileCheckmarkIcon from "../../../../assets/icons/file-checkmark.svg?react";
import FileCheckmarkHighIcon from "../../../../assets/icons/file-checkmark-high.svg?react";
import StarIcon from "../../../../assets/icons/star.svg?react";
import StarHighIcon from "../../../../assets/icons/star-high.svg?react";
import ContractIcon from "../../../../assets/icons/contract.svg?react";
import ContractHighIcon from "../../../../assets/icons/contract-high.svg?react";
import WriteNoteIcon from "../../../../assets/icons/write-note.svg?react";
import ScrollIcon from "../../../../assets/icons/scroll.svg?react";
import { ContractsResponse, RentalResponse } from "../../../../types/GETTypes";

export const RentalTravelerMenu = ({
  contracts,
  anchor,
  setAnchor,
}: {
  contracts: ContractsResponse[] | undefined;
  anchor: string;
  setAnchor: Dispatch<SetStateAction<string>>;
}) => {
  const defaultContract = contracts?.find((contract) => contract.is_default);
  return (
    <PageMenu
      anchor={anchor}
      setAnchor={setAnchor}
      items={[
        {
          Icon: WriteNoteIcon,
          IconActive: WriteNoteIcon,
          anchor: "form-card",
          labelI18n: "Rental.Infos.TravelerPage.FormCard.title",
        },
        {
          Icon: ScrollIcon,
          IconActive: ScrollIcon,
          anchor: "contract-card",
          labelI18n: "Rental.Infos.TravelerPage.ContractCard.title",
          paramsi18: { contractName: defaultContract?.name },
        },
        {
          Icon: ContactsIcon,
          IconActive: ContactsHighIcon,
          anchor: "contact-card",
          labelI18n: "Rental.Infos.TravelerPage.contact",
        },
        {
          Icon: InformationIcon,
          IconActive: InformationHighIcon,
          anchor: "instruction-card",
          labelI18n: "Rental.Infos.TravelerPage.InstructionCard.title",
        },
        {
          Icon: KeyIcon,
          IconActive: KeyHighIcon,
          anchor: "code-card",
          labelI18n: "Rental.Infos.TravelerPage.CodeCard.title",
        },
        {
          Icon: WifiIcon,
          IconActive: WifiHighIcon,
          anchor: "wifi-card",
          labelI18n: "Rental.Infos.TravelerPage.WifiCard.title",
        },
        {
          Icon: MoneyIcon,
          IconActive: MoneyHighIcon,
          anchor: "extra-payment-card",
          labelI18n: "Rental.Infos.TravelerPage.PaymentExtraCard.title",
        },
        {
          Icon: SparklesIcon,
          IconActive: SparklesHighIcon,
          anchor: "extras-card",
          labelI18n: "Rental.Infos.TravelerPage.ExtraCard.title",
        },
        {
          Icon: StarIcon,
          IconActive: StarHighIcon,
          anchor: "opinion-card",
          labelI18n: "Rental.Infos.TravelerPage.RequestOptionCard.title",
        },
        {
          Icon: ContractIcon,
          IconActive: ContractIcon,
          anchor: "cgv-card",
          labelI18n: "Rental.Infos.TravelerPage.CgvCard.title",
        },
      ]}
    />
  );
};
