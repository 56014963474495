import React from "react";
import { useTranslation } from "react-i18next";
import ChevronDownIcon from "../../../assets/icons/chevron-down.svg?react";
import ChevronUpIcon from "../../../assets/icons/chevron-up.svg?react";
import { UseTableFetchParams } from "../../../hooks/useTablePage";

export type PaymentReservationListHeaderProps = {
  sortField: string;
  sortDirection: "asc" | "desc";
  onChangeSort: (
    field: string,
    excludeParams?: (keyof UseTableFetchParams)[]
  ) => void;
};

export const PaymentExtrasListHeader: React.FC<
  PaymentReservationListHeaderProps
> = ({ sortField, sortDirection, onChangeSort = () => {} }) => {
  const { t } = useTranslation();

  const handleChangeSort = (column: string) => {
    let nextDirection: "asc" | "desc" = "asc";
    if (column === sortField && sortDirection === "asc") nextDirection = "desc";
    onChangeSort(column, ["status"]);
  };

  const getIcon = (column: string) => {
    const isActive = sortField === column;

    if (isActive && sortDirection === "asc") return <ChevronDownIcon />;
    else if (isActive && sortDirection === "desc") return <ChevronUpIcon />;
    else return <ChevronDownIcon />;
  };

  return (
    <tr className="sticky top-0 z-10 border-b-1 border-element-border bg-element-background">
      <th
        className="w-3/12 py-3 text-sm ps-4 pe-2"
        onClick={() => handleChangeSort("rental_id")}
      >
        <div
          className={`flex flex-row items-center cursor-pointer hover:underline ${
            sortField === "rental_id" ? "underline" : null
          }`}
        >
          <p>{t("Payments.PaymentReservationList.rentalLabel")}</p>
          {getIcon("rental_id")}
        </div>
      </th>

      <th
        className="px-2 py-3 text-sm"
        onClick={() => handleChangeSort("guest_id")}
      >
        <div
          className={`flex flex-row items-center cursor-pointer hover:underline ${
            sortField === "guest_id" ? "underline" : null
          }`}
        >
          <p>{t("Payments.PaymentReservationList.guestLabel")}</p>{" "}
          {getIcon("guest_id")}
        </div>
      </th>

      <th className="px-2 py-3 text-sm" onClick={() => handleChangeSort("id")}>
        <div
          className={`flex flex-row items-center cursor-pointer hover:underline ${
            sortField === "id" ? "underline" : null
          }`}
        >
          <p>{t("Payments.PaymentReservationList.idCommandLabel")}</p>{" "}
          {getIcon("id")}
        </div>
      </th>

      <th
        className="px-2 py-3 text-sm"
        onClick={() => handleChangeSort("order_date")}
      >
        <div
          className={`flex flex-row items-center cursor-pointer hover:underline justify-center ${
            sortField === "order_date" ? "underline" : null
          }`}
        >
          <p>{t("Payments.PaymentDepositList.commandDateLabel")}</p>{" "}
          {getIcon("order_date")}
        </div>
      </th>

      <th
        className="w-1/12 px-2 py-3 text-sm"
        onClick={() => handleChangeSort("payment_status")}
      >
        <div
          className={`flex flex-row items-center cursor-pointer hover:underline ${
            sortField === "payment_status" ? "underline" : null
          }`}
        >
          <p>{t("Payments.PaymentReservationList.statusLabel")}</p>{" "}
          {getIcon("payment_status")}
        </div>
      </th>

      <th
        className="py-3 text-sm ps-2 pe-4"
        onClick={() => handleChangeSort("total_price_value")}
      >
        <div
          className={`flex flex-row items-center cursor-pointer hover:underline justify-end ${
            sortField === "total_price_value" ? "underline" : null
          }`}
        >
          <p>{t("Payments.PaymentReservationList.amountLabel")}</p>{" "}
          {getIcon("total_price_value")}
        </div>
      </th>
    </tr>
  );
};
