import moment from "moment";
import React from "react";
import {
  CALENDAR_CELL_HEIGHT,
  CALENDAR_CELL_WIDTH,
  CALENDAR_HEADER_HEIGHT,
} from "../../constants/calendar";
import { DateItemSkeletonProps } from "./Calendar.type";

export const CalendarDateItemSkeleton: React.FC<DateItemSkeletonProps> = ({
  index,
  daysOffset,
}) => {
  const date = moment(new Date()).add(index - daysOffset, "days");

  const getBorder = () => {
    if (date.format("DD") === "01") {
      return "border-l-4 border-[#5C6163]/20";
    } else return "";
  };

  return (
    <th
      className={`overflow-x-visible ${getBorder()}`}
      style={{
        minWidth: CALENDAR_CELL_WIDTH,
        maxWidth: CALENDAR_CELL_WIDTH,
        height: CALENDAR_HEADER_HEIGHT,
      }}
    >
      {/* Upper part */}
      {/* <div
        style={{ height: CALENDAR_CELL_HEIGHT }}
        className={`flex items-center overflow-x-visible`}
      ></div> */}

      {/* Day */}
      <div
        className="animate-pulse pt-[17px]"
        style={{ height: CALENDAR_CELL_HEIGHT + 5 }}
      >
        <div className={`flex justify-center mt-4`}>
          <div className="w-8 h-2 bg-slate-200" />
        </div>
        <div className={`flex justify-center mt-2`}>
          <div className="w-8 h-[17px] bg-slate-300" />
        </div>
      </div>
    </th>
  );
};
