import React from "react";
import {GuestWebsiteInfo2Card} from "../../../../types/GETTypes";
import useGuestWebsiteStore from "../../../../stores/guestWebsiteStore/useGuestWebsiteStore";

const Info2CardComponent = ({title, logo, text}: GuestWebsiteInfo2Card) => {
  const {dataGuestWebsite} = useGuestWebsiteStore();
  const {styles} = dataGuestWebsite || {};
  const {color_background, color_text} = styles || {};
  return (
    <div className="bg-white rounded-lg shadow-lg p-6 max-w-md text-center transition flex-1">
      <div className="flex justify-center mb-4">
        <div className="bg-gray-100 p-3 rounded-full">
          <img src={logo} className="h-8 w-8 text-gray-600" />
        </div>
      </div>
      <h2 className="text-xl font-bold  mb-4" style={{color: color_text}}>
        {title}
      </h2>
      <p className=" text-sm leading-relaxed" style={{color: color_text}}>
        {text}
      </p>
    </div>
  );
};

export default Info2CardComponent;
