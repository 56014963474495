import moment from "moment";
import { DeleteActionType } from "../../components/Rental/Platforms/ExternalDesyncModal";
import paths from "../../constants/paths";
import { get, post } from "../../helpers/APIHelper";
import { CalendarDay } from "../../pages/CalendarList/CalendarListPage.type";
import {
  CalendarRentalResponse,
  MultipleRestrictionsGet,
} from "../../types/GETTypes";

/**
 * Hook to fetch reservation calendar
 * @param {Function} onStart - Function to execute when the fetch starts.
 * @param {Function} onEnd - Function to execute when the fetch ends.
 * @param {Function} onSuccess - Function to execute when the fetch is successful.
 * @param {Function} onError - Function to execute when there is an error.
 */
export const useCalendar = async (
  onSuccess: (data: CalendarRentalResponse[] | undefined) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  if (typeof onStart === "function") {
    onStart();
  }
  try {
    const response = await get(
      `${import.meta.env.VITE_API_URL}${paths.API.CALENDAR}`
    );
    if (response?.data?.success) {
      onSuccess(response?.data?.result?.rentals);
    } else {
      onError(response?.data?.message ?? response.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

export const useGetRestriction = async (
  data: any,
  onSuccess: (data: CalendarRentalResponse[] | undefined) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  if (typeof onStart === "function") {
    onStart();
  }
  try {
    if (!data.rental_id) {
      onError("Rental ID not set.");
      return;
    }
    const response = await get(
      `${import.meta.env.VITE_API_URL}${paths.API.RESTRICTION}/${
        data.rental_id
      }/${data.date}`
    );
    if (response?.data?.success) {
      onSuccess(response?.data?.result);
    } else {
      onError(response?.data?.message ?? response.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

export const useGetMultipleRestriction = async ({
  rentalIds,
  onSuccess,
  onError,
  onStart,
  onEnd,
}: {
  rentalIds: string[];
  onSuccess: (data: MultipleRestrictionsGet[]) => void;
  onError: (message: string | null) => void;
  onStart?: () => void;
  onEnd?: () => void;
}) => {
  if (typeof onStart === "function") {
    onStart();
  }
  try {
    if (!rentalIds) {
      throw new Error("Rental IDs is required");
    }
    const response = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.MULTIPLE_RESTRICTION}`,
      {
        rental_ids: rentalIds,
      }
    );
    if (response?.data?.success) {
      onSuccess(response?.data?.result);
    } else {
      onError(response?.data?.message ?? response.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

/**
 *
 * @param from
 * @param to
 * @param {Function} onStart - Function to execute when the fetch starts.
 * @param {Function} onEnd - Function to execute when the fetch ends.
 * @param {Function} onSuccess - Function to execute when the fetch is successful.
 * @param {Function} onError - Function to execute when there is an error.
 */
export const useFetchDays = async ({
  from,
  to,
  onSuccess,
  onError,
  onStart,
  onEnd,
}: {
  from: Date | null;
  to: Date | null;
  onSuccess: (days: CalendarDay | undefined) => void;
  onError: (message: string | undefined) => void;
  onStart?: () => void;
  onEnd?: () => void;
}) => {
  if (from && to) {
    if (typeof onStart === "function") {
      onStart();
    }
    try {
      const response = await get(
        `${import.meta.env.VITE_API_URL}${paths.API.CALENDAR_LIST}`,
        {
          params: {
            from: from === null ? null : moment(from).format("YYYY-MM-DD"),
            to: to === null ? null : moment(to).format("YYYY-MM-DD"),
          },
        }
      );

      if (response?.data?.success) {
        onSuccess(response?.data?.result?.days);
      } else {
        onError(response?.response?.data?.message);
      }
    } catch (error: any) {
      onError(error.message);
    } finally {
      if (typeof onEnd === "function") {
        onEnd();
      }
    }
  }
};

/**
 * Hook for interact with externals connections
 * in rentals.
 */
export const useIcalendar = () => {
  /**
   * Function for connect rental to a new externals platform with
   * icalendarLink and connectionName.
   *
   * @param rentalId
   * @param icalendarLink
   * @param connectionName
   */
  const connectRental = async (
    rentalId: string,
    icalendarLink: string,
    connectionName: string
  ) => {
    /**
     * Prepare my request for call API.
     */
    const url = `${import.meta.env.VITE_API_URL}${paths.API.ICALENDAR.CONNECT}`;
    const data = {
      rental_id: rentalId,
      icalendar_link: icalendarLink,
      connexion_name: connectionName,
    };

    /**
     * Call API
     */
    const response = await post(url, data);
    console.log(response);
    if (response?.data?.success) {
      return response?.data?.result;
    } else {
      throw new Error(
        response?.response?.data?.message ?? "Internal Server Error."
      );
    }
  };

  /**
   * Function to disconnect external platform in rental,
   * with rentalId and icalendarID
   * (icalendarId present in rentals_platform_icalendar table)
   *
   * @param rentalId
   * @param icalendarId
   * @param action
   */
  const disconnectRental = async (
    rentalId: string,
    icalendarId: string,
    action: DeleteActionType
  ) => {
    /**
     * Prepare my request for call API.
     */
    const url = `${import.meta.env.VITE_API_URL}${
      paths.API.ICALENDAR.DISCONNECT
    }`;
    const data = {
      rental_id: rentalId,
      icalendar_connexion_id: icalendarId,
      action: action,
    };

    /**
     * Call API
     */
    const response = await post(url, data);
    if (response?.data?.success) {
      return response?.data?.result;
    } else {
      throw new Error(
        response?.response?.data?.message ?? "Internal Server Error."
      );
    }
  };

  /**
   * Function for export .ics file for user and generate link icalendar
   * SuperHote
   *
   * @param rentalId
   */
  const generateIcsFile = async (rentalId: string) => {
    /**
     * Prepare my request for call API.
     */
    const url = `${import.meta.env.VITE_API_URL}${paths.API.ICALENDAR.EXPORT}`;
    const data = {
      rental_id: rentalId,
    };

    /**
     * Call API
     */
    const response = await post(url, data);
    if (response?.data?.success) {
      return response?.data?.result;
    } else {
      throw new Error(
        response?.response?.data?.message ?? "Internal Server Error."
      );
    }
  };

  return {
    connectRental,
    disconnectRental,
    generateIcsFile,
  };
};
