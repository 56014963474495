import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip as ToolTipChart,
} from "recharts";
import BreakdownTableComponent from "./BreakdownTableComponent";
import {Tooltip} from "../../Common/Tooltip/Tooltip";
import {PlatformEnum} from "../../../enums/GETenums";
import {
  AnalyticsDetailedRevenueMetrics,
  AnalyticsDetailedSummary,
  DistributionKeyEnum,
  StatsNameKeysEnum,
} from "../../../types/GETTypes";
import moment from "moment-timezone";
import {capitalizeFirstLetter} from "../../../helpers/stringHelper";
import {ValueType} from "../../../types/commonTypes";
import ArrowRightIcon from "../../../assets/icons/arrow-right.svg?react";
import {useTranslation} from "react-i18next";

interface BarChartProps {
  data: AnalyticsDetailedSummary | undefined;
  chartData: AnalyticsDetailedRevenueMetrics | undefined;
  xAxisdataKey: string;
  foregroundBarDataKey: string;
  foregroundBarFillColor: string;
  foregroundBarName: string;
  backgroundBarDataKey: string;
  backgroundBarFillColor: string;
  backgroundBarName: string;
  heightChart?: number;
  openModalDetails: (
    statName: StatsNameKeysEnum,
    platform: DistributionKeyEnum
  ) => void;
  loading?: boolean;
  currentYear?: ValueType;
  currency: string | undefined;
}
const BarChartComponent = ({
  data,
  chartData,
  xAxisdataKey,
  foregroundBarDataKey,
  foregroundBarFillColor,
  foregroundBarName,
  backgroundBarDataKey,
  backgroundBarFillColor,
  backgroundBarName,
  heightChart = 300,
  openModalDetails,
  loading,
  currentYear = moment().year().toString(),
  currency,
}: BarChartProps) => {
  const {t} = useTranslation()
  const barChartData = chartData
    ? Object.entries(chartData?.monthly_gross_revenue_per_reservation || {})
        .sort(([keyA], [keyB]) => parseInt(keyA, 10) - parseInt(keyB, 10))
        .map(([key, value]) => ({
          monthNb: key,
          month: capitalizeFirstLetter(moment(key, "MM").format("MMM")),
          paid: value.paid?.toLocaleString('fr-FR'),
          upcoming: value.upcoming?.toLocaleString('fr-FR'),
        }))
    : [];

  if (loading || !data || !chartData || !barChartData?.length) {
    return (
      <div className="p-4 pt-0">
        <div className="p-2 animate-pulse ">
          <div className="p-2 rounded-lg pb-0">
            <div className="h-6 bg-gray-200 rounded"></div>
            <div className="flex items-center text-gray-600 text-sm mb-4 space-x-1">
              <div className="h-4 bg-gray-200 rounded w-full"></div>
            </div>

            <div className="h-64 bg-gray-200 rounded w-full"></div>

            <div className="flex gap-4 mt-6">
              <div className="flex flex-col space-y-2">
                <div className="flex space-x-2">
                  <div className="w-4 h-4 bg-gray-200 rounded"></div>
                </div>
              </div>
              <div className="flex flex-col space-y-2 ml-8">
                <div className="flex space-x-2">
                  <div className="w-4 h-4 bg-gray-200 rounded"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 mx-4 space-y-2 bg-element-border/25 mb-0">
          <div className="p-2 pb-0 animate-pulse">
            <div className="flex flex-col justify-between">
              <div className="flex justify-between py-4">
                <div className="flex items-center space-x-2">
                  <div className="flex items-center space-x-2 cursor-pointer">
                    <div className="h-4 w-28 bg-gray-200 rounded"></div>
                  </div>
                </div>
                <div className="flex items-center space-x-2 ">
                  <div className="h-4 w-28 bg-gray-200 rounded"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="p-2 pb-0 animate-pulse">
            <div className="flex flex-col justify-between">
              <div className="flex justify-between py-4">
                <div className="flex items-center space-x-2">
                  <div className="flex items-center space-x-2 cursor-pointer">
                    <div className="h-4 w-28 bg-gray-200 rounded"></div>
                  </div>
                </div>
                <div className="flex items-center space-x-2 ">
                  <div className="h-4 w-28 bg-gray-200 rounded"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-8 w-full border-1 border-element-border rounded-md">
      <div className="p-2">
        {/* Chart Bar */}
        <div className="p-2 rounded-lg pb-0">
          <div className="text-xxl font-bold">
            {data.revenue_breakdown.total?.toLocaleString('fr-FR')} {currency ?? "€"}
          </div>
          <div className="flex items-center text-gray-600 text-sm mb-4 space-x-1">
            <span>
              {t('Analytics.revenueBrutsDate')}{" "}
              {barChartData[0].month} {currentYear}
            </span>
            <ArrowRightIcon className="w-4 h-4" />
            <span>
              {" "}
              {barChartData[barChartData.length - 1].month} {currentYear}
            </span>
          </div>

          {/* Bar Chart */}
          <ResponsiveContainer width="100%" height={heightChart}>
            <BarChart data={barChartData}>
              <CartesianGrid
                horizontal={true}
                vertical={false}
                stroke="#ccc"
                strokeWidth={0.5}
              />
              <XAxis dataKey={xAxisdataKey} tickLine={false} axisLine={false} />
              <YAxis
                orientation="right"
                tickFormatter={(value) =>
                  `${value.toLocaleString("fr-FR")} ${currency ?? "€"}`
                }
                axisLine={false}
                // domain={[0, "dataMax"]}
                tickLine={false}
              />
              <ToolTipChart />
              <Bar
                stackId="a"
                dataKey={foregroundBarDataKey}
                fill={foregroundBarFillColor}
                name={foregroundBarName}
              />
              <Bar
                stackId="a"
                dataKey={backgroundBarDataKey}
                fill={backgroundBarFillColor}
                name={backgroundBarName}
              />
            </BarChart>
          </ResponsiveContainer>

          {/* Revenue Breakdown */}
          <div className="flex gap-4 mt-6">
            <div className="flex flex-col space-y-2">
              <div className="font-bold">
                {chartData?.total_paid?.toLocaleString('fr-FR')} {currency ?? "€"}
              </div>
              <div className="flex space-x-2">
                <div className="w-4 h-4 bg-[#e74c3c] rounded-4px" />
                <span className="text-low-contrast">{t('Analytics.alreadyPaid')}</span>
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <div className="font-bold">
                {chartData?.total_upcoming?.toLocaleString('fr-FR')} {currency ?? "€"}
              </div>
              <div className="flex space-x-2">
                <div className="w-4 h-4 bg-[#FFE6E2] rounded-4px" />
                <span className="text-low-contrast">{t('Analytics.upcomingPaid')}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Breakdown Table */}
      <BreakdownTableComponent
        data={data}
        openModalDetails={openModalDetails}
        currency={currency}
      />
    </div>
  );
};

export default BarChartComponent;
