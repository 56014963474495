import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import CheckIcon from "../../../assets/icons/check-white.svg?react";
import EditIcon from "../../../assets/icons/edit.svg?react";
import {Controller, useForm, UseFormReturn} from "react-hook-form";
import ArrowLeftIcon from "../../../assets/icons/arrow-left.svg?react";
import TagIcon from "../../../assets/icons/tag.svg?react";
import {
  RentalBillingInfosHostType,
  UserResponse,
} from "../../../types/GETTypes";
import {ValueType} from "../../../types/commonTypes";
import {TextInput} from "../../Common/TextInput/TextInput";
import {Card} from "../../Common/Card/Card";
import {ErrorMessage} from "../../Common/ErrorMessage/ErrorMessage";
import {InputSelect} from "../../Common/InputSelect/InputSelect";
import {UserAdministrativeCardSkeleton} from "./UserAdministrativeSkeleton";
import {post} from "../../../helpers/APIHelper";
import paths from "../../../constants/paths";

interface AdministrativeCardProps {
  loading: boolean;
  user: UserResponse | undefined;
  onUpdate: React.Dispatch<React.SetStateAction<UserResponse | undefined>>;
}
interface AdministrativeCardForm {
  host_type: RentalBillingInfosHostType;
  host_name: string | undefined;
  tax_identity_number: string | undefined;
  company_name: string | undefined;
  siren: string | undefined;
}

const CardForm = ({
  form,
  user,
}: {
  form: UseFormReturn<AdministrativeCardForm, any, undefined>;
  user: UserResponse | undefined;
}) => {
  const {t} = useTranslation();

  const hostType = form.watch("host_type");
  const isProForm = hostType === "professional";

  const hostTypeSelect = [
    {
      label: t("Profil.Administrative.particular"),
      value: RentalBillingInfosHostType.PARTICULAR,
    },
    {
      label: t("Profil.Administrative.pro"),
      value: RentalBillingInfosHostType.PROFESSIONAL,
    },
  ];

  const change = (value: ValueType, onChange: (...event: any[]) => void) => {
    if (value === RentalBillingInfosHostType.PROFESSIONAL) {
      form.setValue("host_name", "");
      form.setValue("tax_identity_number", "");
    } else if (value === RentalBillingInfosHostType.PARTICULAR) {
      form.setValue("company_name", "");
      form.setValue("siren", "");
    }
    onChange(value);
  };

  return isProForm ? (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2 gap-y-4">
        <div className="flex flex-col gap-y-2 w-full">
          <Controller
            control={form.control}
            name="host_type"
            rules={{
              required: {
                value: true,
                message: t("Global.Errors.requiredField", {
                  fieldName: t("Profil.Administrative.hostType"),
                }),
              },
            }}
            render={({field: {value, onChange}}) => {
              return (
                <div className="flex flex-col">
                  <InputSelect
                    required={true}
                    label={t("Profil.Administrative.hostType")}
                    selectedValue={value}
                    items={hostTypeSelect}
                    onSelect={(value) => change(value, onChange)}
                    error={form.formState.errors.host_type?.message}
                  />
                </div>
              );
            }}
          />
        </div>
        <div className="flex flex-row gap-2">
          <div className="flex flex-col gap-y-2 w-1/2">
            <Controller
              control={form.control}
              name="company_name"
              rules={{
                required: {
                  value: true,
                  message: t("Global.Errors.requiredField", {
                    fieldName: t("Profil.Administrative.Pro.companyName"),
                  }),
                },
              }}
              render={({field: {value, onChange}}) => {
                return (
                  <div className="flex flex-col">
                    <TextInput
                      label={t("Profil.Administrative.Pro.companyName")}
                      required={true}
                      value={value}
                      onChangeText={(text) => {
                        onChange(text);
                      }}
                      error={form.formState.errors.company_name?.message}
                    />
                  </div>
                );
              }}
            />
          </div>
          <div className="flex  flex-col gap-y-2 w-1/2">
            <Controller
              control={form.control}
              name="siren"
              rules={{
                required: {
                  value: true,
                  message: t("Global.Errors.requiredField", {
                    fieldName: t("Profil.Administrative.Pro.siren"),
                  }),
                },
              }}
              render={({field: {value, onChange}}) => {
                return (
                  <div className="flex flex-col">
                    <TextInput
                      label={t("Profil.Administrative.Pro.siren")}
                      required={true}
                      value={value}
                      onChangeText={(text) => {
                        onChange(text);
                      }}
                      error={form.formState.errors.siren?.message}
                    />
                  </div>
                );
              }}
            />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2 gap-y-4">
        <div className="flex flex-col gap-y-2 w-full">
          <Controller
            control={form.control}
            name="host_type"
            rules={{
              required: {
                value: true,
                message: t("Global.Errors.requiredField", {
                  fieldName: t("Profil.Administrative.hostType"),
                }),
              },
            }}
            render={({field: {value, onChange}}) => {
              return (
                <div className="flex flex-col">
                  <InputSelect
                    required={true}
                    label={t("Profil.Administrative.hostType")}
                    selectedValue={value}
                    items={hostTypeSelect}
                    onSelect={(value) => change(value, onChange)}
                    error={form.formState.errors.host_type?.message}
                  />
                </div>
              );
            }}
          />
        </div>
        <div className="flex flex-row gap-2">
          <div className="flex flex-col gap-y-2 w-1/2">
            <Controller
              control={form.control}
              name="host_name"
              rules={{
                required: {
                  value: true,
                  message: t("Global.Errors.requiredField", {
                    fieldName: t("Profil.Administrative.Particular.hostName"),
                  }),
                },
              }}
              render={({field: {value, onChange}}) => {
                return (
                  <div className="flex flex-col">
                    <TextInput
                      label={t("Profil.Administrative.Particular.hostName")}
                      required={true}
                      value={value}
                      onChangeText={(text) => {
                        onChange(text);
                      }}
                      error={form.formState.errors.host_name?.message}
                    />
                  </div>
                );
              }}
            />
          </div>
          <div className="flex  flex-col gap-y-2 w-1/2">
            <Controller
              control={form.control}
              name="tax_identity_number"
              rules={{
                required: {
                  value: true,
                  message: t("Global.Errors.requiredField", {
                    fieldName: t(
                      "Profil.Administrative.Particular.taxIdentityNumber"
                    ),
                  }),
                },
              }}
              render={({field: {value, onChange}}) => {
                return (
                  <div className="flex flex-col">
                    <TextInput
                      label={t(
                        "Profil.Administrative.Particular.taxIdentityNumber"
                      )}
                      required={true}
                      value={value}
                      onChangeText={(text) => {
                        onChange(text);
                      }}
                      error={form.formState.errors.tax_identity_number?.message}
                    />
                  </div>
                );
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const CardDisplay = ({user}: {user: UserResponse | undefined}) => {
  const administrative = user?.profile_administrative;
  const {t} = useTranslation();
  const hostTypeNameByType = (host_type?: RentalBillingInfosHostType) => {
    switch (host_type ? host_type : administrative?.host_type) {
      case RentalBillingInfosHostType.PARTICULAR:
        return t("Profil.Administrative.particular");
      case RentalBillingInfosHostType.PROFESSIONAL:
        return t("Profil.Administrative.pro");
      default:
        return "";
    }
  };

  const isPro =
    administrative?.host_type === RentalBillingInfosHostType.PROFESSIONAL;

  return isPro ? (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2 gap-y-4">
        <div className="flex flex-col gap-y-2 w-full">
          <p className="text-low-contrast">
            {t("Profil.Administrative.hostType")}
          </p>
          <p className="text-high-contrast font-bold">{hostTypeNameByType()}</p>
        </div>
        <div className="flex flex-row gap-y-2">
          <div className="flex flex-col gap-y-2 w-1/2">
            <p className="text-low-contrast">
              {t("Profil.Administrative.Pro.companyName")}
            </p>
            <p className="text-high-contrast font-bold">
              {administrative?.company_name}
            </p>
          </div>
          <div className="flex  flex-col gap-y-2 w-1/2">
            <p className="text-low-contrast">
              {t("Profil.Administrative.Pro.siren")}
            </p>
            <p className="text-high-contrast font-bold">
              {administrative?.siren}
            </p>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2 gap-y-4">
        <div className="flex flex-col gap-y-2 w-full">
          <p className="text-low-contrast">
            {t("Profil.Administrative.hostType")}
          </p>
          <p className="text-high-contrast font-bold">{hostTypeNameByType()}</p>
        </div>
        <div className="flex flex-row gap-y-2">
          <div className="flex flex-col gap-y-2 w-1/2">
            <p className="text-low-contrast">
              {t("Profil.Administrative.Particular.hostName")}
            </p>
            <p className="text-high-contrast font-bold">
              {administrative?.host_name}
            </p>
          </div>
          <div className="flex  flex-col gap-y-2 w-1/2">
            <p className="text-low-contrast">
              {t("Profil.Administrative.Particular.taxIdentityNumber")}
            </p>
            <p className="text-high-contrast font-bold">
              {administrative?.tax_identity_number}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export const UserAdministrativeCard: React.FC<AdministrativeCardProps> = ({
  loading,
  user,
  onUpdate,
}) => {
  const {t} = useTranslation();
  const [validationLoading, setValidationLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [editMode, setEditMode] = useState<boolean>(false);
  const defaultFormValues: AdministrativeCardForm = {
    host_type: RentalBillingInfosHostType.PARTICULAR,
    host_name: "",
    tax_identity_number: "",
    company_name: "",
    siren: "",
  };
  const form = useForm<AdministrativeCardForm>({
    defaultValues: defaultFormValues,
  });

  useEffect(() => {
    if (user && user?.profile_administrative) {
      form.setValue(
        "host_type",
        user?.profile_administrative?.host_type ??
          RentalBillingInfosHostType.PARTICULAR
      );
      if (
        user?.profile_administrative?.host_type ===
        RentalBillingInfosHostType.PARTICULAR
      ) {
        form.setValue(
          "host_name",
          user?.profile_administrative?.host_name ?? ""
        );
        form.setValue(
          "tax_identity_number",
          user?.profile_administrative?.tax_identity_number ?? ""
        );
      } else {
        form.setValue(
          "company_name",
          user?.profile_administrative?.company_name ?? ""
        );
        form.setValue("siren", user?.profile_administrative?.siren ?? "");
      }
    }
  }, [user, editMode]);

  const handleUpdateUserProfileAdministrative = async (
    data: AdministrativeCardForm
  ) => {
    setError(undefined);
    setValidationLoading(true);
    if (user) {
      const res = await post(
        `${import.meta.env.VITE_API_URL}${paths.API.USER.UPDATE_ADMINISTRATIVE(
          user?.id as number
        )}`,
        data
      );
      if (res?.data?.success) {
        if (res?.data?.result) {
          onUpdate(res?.data?.result);
        }
        setEditMode(false);
      } else {
        console.log("🚀 ~ handleUpdateBilling ~ res:", res);
        setError(res?.response?.data?.message);
      }
      setValidationLoading(false);
    }
  };

  if (loading) return <UserAdministrativeCardSkeleton />;
  return (
    <Card
      loading={validationLoading}
      Icon={TagIcon}
      label={t("Profil.Administrative.title")}
      anchor="admin-card"
      button={{
        Icon: editMode ? CheckIcon : EditIcon,
        type: editMode ? "primary" : "secondary",
        label: editMode ? t("Global.record") : t("Global.edit"),
        onClick: editMode
          ? form.handleSubmit(handleUpdateUserProfileAdministrative)
          : () => setEditMode(!editMode),
      }}
      secondaryButton={
        editMode
          ? {
              label: t("Global.cancel"),
              LeftIcon: ArrowLeftIcon,
              onClick: () => {
                setEditMode(false);
                form.reset(defaultFormValues);
              },
            }
          : undefined
      }
    >
      <div className="flex flex-col gap-y-4">
        {error ? <ErrorMessage>{error}</ErrorMessage> : null}
        {editMode ? (
          <CardForm form={form} user={user} />
        ) : (
          <CardDisplay user={user} />
        )}
      </div>
    </Card>
  );
};
