import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {get} from "../../../../helpers/APIHelper";
import paths from "../../../../constants/paths";
import {Button} from "../../../Common/Button/Button";
import {RentalLightBookingItemResponse} from "../../../../types/GETTypes";
import ArrowRight from "../../../../assets/icons/arrow-right-white.svg?react";
import {
  BookingConnectData,
  BookingConnectDataRentalRoomRates,
} from "./BookingConnectModal";
import {ErrorMessage} from "../../../Common/ErrorMessage/ErrorMessage";
import {UseFormReturn} from "react-hook-form";
import BookingConnectRentalRoomType, {
  BookingConnectRentalRoomTypeForm,
} from "./BookingConnectRentalRoomType";

type BookingConnect3Props = {
  properties: RentalLightBookingItemResponse[] | undefined;
  onNext: () => void;
  setData: React.Dispatch<React.SetStateAction<BookingConnectData | undefined>>;
  roomsRates: BookingConnectDataRentalRoomRates[] | undefined;
};

export const BookingConnectStep3: React.FC<BookingConnect3Props> = ({
  properties,
  roomsRates,
  setData,
  onNext,
}) => {
  const {t} = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState("");
  const [allFormsValid, setAllFormsValid] = React.useState(false);
  const childForms = useRef<UseFormReturn[]>([]);

  const handleValidateAll = async () => {
    const validations = await Promise.all(
      childForms.current.map(async (form) => {
        if (form) {
          const isValid = await form.trigger();
          return isValid;
        }
        return false;
      })
    );

    const allValid = validations.every((isValid) => isValid);

    if (allValid) {
      setLoading(true);
      try {
        const data = await handleGetAllFormValues();
        setData((oldConnectedData) => {
          if (oldConnectedData) {
            return {
              ...oldConnectedData,
              importData: data,
            };
          }
        });

        const response = await get(
          `${import.meta.env.VITE_API_URL}${paths.API.RENTALS}`
        );

        if (response?.data?.success) {
          const rentals = response?.data?.result?.rentals_access;
          setData((oldConnectedData) => {
            if (oldConnectedData) {
              return {
                ...oldConnectedData,
                rentals: rentals,
              };
            }
          });
        } else {
          setError(response?.response?.data?.message);
          setLoading(false);
          return;
        }
      } catch (e: any) {
        console.log(e.message);
        setLoading(false);
        return;
      }

      setLoading(false);
      onNext();
    }
  };

  const handleGetAllFormValues = async (): Promise<
    BookingConnectRentalRoomTypeForm[]
  > => {
    const allFormValues = await Promise.all(
      childForms.current.map(async (form) => {
        if (form) {
          const values = form.getValues();
          return values as BookingConnectRentalRoomTypeForm;
        }
        return null;
      })
    );

    const validFormValues = allFormValues.filter(
      (values): values is BookingConnectRentalRoomTypeForm => values !== null
    );

    return validFormValues;
  };

  const checkFormsValidity = async () => {
    const validations = await Promise.all(
      childForms.current.map((form: UseFormReturn) => form?.trigger())
    );
    setAllFormsValid(validations.every((isValid) => isValid));
  };

  const getRoomRatesByRental = (id: number) => {
    const find = roomsRates?.find((r) => Number(r.id) === id);
    return find ?? undefined;
  };

  return (
    <div className="flex flex-col">
      <div className="my-3 space-y-2">
        <p className="text-low-contrast font-bold">
          {t("Progression.verifBookingInfo")}
        </p>

        {properties?.map((property: RentalLightBookingItemResponse, index) => (
          <BookingConnectRentalRoomType
            // @ts-ignore
            ref={(el) => (childForms.current[index] = el)}
            property={property}
            roomsRates={getRoomRatesByRental(property.id)}
            onChange={checkFormsValidity}
            loading={loading}
          />
        ))}
      </div>
      <ErrorMessage>{error}</ErrorMessage>
      <Button
        onClick={handleValidateAll}
        RightIcon={ArrowRight}
        disabled={!allFormsValid}
        loading={loading}
      >
        {t("Global.next")}
      </Button>
    </div>
  );
};
