import React from "react";
import {TextInput} from "../TextInput/TextInput";
import {TimeInputProps} from "./TimeInput.type";

import ClockIcon from "../../../assets/icons/clock.svg?react";

export const TimeInput: React.FC<TimeInputProps> = ({
  register,
  required = false,
  label,
  tooltip,
  value = "",
  placeholder,
  size,
  disabled,
  infoTooltip,
  onChange = () => {},
  error,
  classNames = undefined,
  RightIcon,
  hideRightIcon = false,
}) => {
  const handleChangeText = (value: string) => {
    let cleanValue = value.replace(/\D/g, "");

    let hours: number | string = cleanValue.substring(0, 2);
    let minutes: number | string = cleanValue.substring(2, 4);

    if (parseInt(hours) > 23) hours = 23;
    if (parseInt(minutes) > 59) minutes = 59;

    if (cleanValue.length === 2) {
      cleanValue = hours.toString();
    } else if (cleanValue.length >= 3) {
      cleanValue = hours + ":" + minutes;
    }

    onChange(cleanValue);

    register?.onChange({target: {name: register?.name, value: cleanValue}});
  };

  return (
    <div className="flex flex-1">
      <TextInput
        label={label}
        tooltip={tooltip}
        value={value}
        placeholder={placeholder}
        RightIcon={hideRightIcon ? undefined : RightIcon ?? ClockIcon}
        onChangeText={handleChangeText}
        size={size}
        disabled={disabled}
        error={error}
        infoTooltip={infoTooltip}
        required={required}
        classNames={classNames}
      />
    </div>
  );
};
