import React, {useState} from "react";
import LayoutBlockComponent, {BlockStyle} from "./LayoutBlockComponent";
import {
  GuestPageConnectResponse,
  GuestPageExtra,
  GuestWebsiteRentalDetailsExtra,
  RentalExtraAvailableListItemResponse,
  ResultOrder,
  ValidateExtraResponse,
} from "../../../types/GETTypes";
import ExtraCardComponent from "./ExtraCardComponent";
import {Button} from "../../Common/Button/Button";
import {useTranslation} from "react-i18next";
import ArrowRightWhiteIcon from "../../../assets/icons/arrow-right-white.svg?react";
import CartIcon from "../../../assets/icons/shopping-cart-white.svg?react";
import CartSleeping from "../../../assets/images/cart-sleeping.svg?react";
import useExtrasStore from "../../../stores/guestPageStore/useExtrasStore";
import {tabType} from "../GuestPageHome/GuestPageHomeComponent";
import useGuestPageDataStore from "../../../stores/guestPageStore/useGuestPageDataStore";
import {post} from "../../../helpers/APIHelper";
import paths from "../../../constants/paths";
import {ErrorMessage} from "../../Common/ErrorMessage/ErrorMessage";
import useWindowSize from "../../../hooks/useWindowSize";


export interface ExtrasOrder {
  id: number;
  quantity?: number;
}

export const areAllExtrasDisabled = (extras: GuestPageExtra[] | GuestWebsiteRentalDetailsExtra[] | RentalExtraAvailableListItemResponse[] | undefined) => {
  return extras?.every((extra) => !extra?.extra_enabled);
};

const ExtraComponent = ({
  title,
  subTitle,
  icon,
  blockStyle,
  fromPage = "home",
  onChangeTab,
  displayTitles,
}: {
  title: string;
  subTitle?: string;
  icon?: string;
  blockStyle?: BlockStyle;
  fromPage: "home" | "extra" | "extraPayment" | "extraHistorical";
  onChangeTab?: (tab: tabType) => void;
  displayTitles?: boolean;
}) => {
  const {t} = useTranslation();
  const {isMobile} = useWindowSize();
  const {guestPageData, isPreviewMode,rentalPreview} = useGuestPageDataStore();
  const {
    extras_available_for_order,
    guest,
    id: reservation_id,
  } = guestPageData || {};
  const {guest_page} = rentalPreview || {}
  const {extras_available} = guest_page || {}
  const {email} = guest || {};
  const {extrasOrder, storeExtraOrder, storeExtraValidateData,storeSendExtraData} =
    useExtrasStore();
  const [loadingValidate, setLoadingValidate] = useState(false);
  const [error, setError] = useState("");
  const onChangeExtra = (extraOrder: ExtrasOrder, toRemove?: boolean) => {
    if (toRemove) {
      storeExtraOrder(
        extrasOrder.filter((extra) => extra.id !== extraOrder.id)
      );
    } else {
      const find = extrasOrder.find((extra) => extra.id === extraOrder.id);
      if (find) {
        find.quantity = extraOrder?.quantity ?? 1;
        storeExtraOrder(extrasOrder);
      } else {
        storeExtraOrder([...extrasOrder, extraOrder]);
      }
    }
  };

  const calculateTotalPrice = (
  ): number => {
    return extrasOrder.reduce((total, order) => {
      const matchingExtra = extras_available_for_order?.find(extra => extra.id === order.id);
      if (matchingExtra && order?.quantity) {
        total += matchingExtra.unit_price * order.quantity;
      }
      return total;
    }, 0);
  };

  const onValidate = async () => {
    setLoadingValidate(true);
    if (extrasOrder?.length > 0) {
      const orderedResult: ResultOrder = extrasOrder.reduce<ResultOrder>(
        (acc, item, index) => {
          if (index === 0) {
            acc.ordered_extras_ids = `${item.id}`;
            acc.ordered_extras_quantities = `${item.quantity}`;
          } else {
            acc.ordered_extras_ids += `,${item.id}`;
            acc.ordered_extras_quantities += `,${item.quantity}`;
          }
          return acc;
        },
        {ordered_extras_ids: "", ordered_extras_quantities: ""}
      );

      const data = {
        reservation_id: String(reservation_id),
        email,
        ...orderedResult,
      };
      

      const res = await post(
        `${import.meta.env.VITE_API_URL}${
          paths.API.GUEST_PAGE.VALIDATE_ORDER_EXTRAS
        }`,
        data
      );

      if (res?.data?.success) {
        storeSendExtraData(data)
        storeExtraValidateData({...res.data?.result});
        setLoadingValidate(false);
        onChangeTab && onChangeTab("extraPayment");
      } else {
        setError(res?.response?.data?.message);
      }
    }
    setLoadingValidate(false);
  };

  const onDisplayCart = () => {
    onChangeTab && onChangeTab("extra");
  };

  const displayButtonPerPage = () => {
    switch (fromPage) {
      case "home":
        return (
          <Button
            RightIcon={CartIcon}
            displayLargeBtn={false}
            onClick={onDisplayCart}
            className="flex flex-1 w-full"
            buttonClassName="md:h-12"
            textClassName="md:text-xl"
          >
            {t("GuestPage.Extras.displayCart")}
          </Button>
        );
      case "extra":
        return (
          // <div className="flex flex-col">
          <>
            <ErrorMessage childrenClassName="md:text-lg">{error}</ErrorMessage>
            <Button
              RightIcon={ArrowRightWhiteIcon}
              displayLargeBtn={false}
              onClick={onValidate}
              className="flex flex-1 w-full"
              buttonClassName="md:h-12"
              textClassName="md:text-xl"
              disabled={isPreviewMode || !extrasOrder?.length}
              loading={loadingValidate}
            >
              {t("GuestPage.Extras.validateExtras")}
            </Button>
          </>
          // </div>
        );
      default:
    }
  };

  const extras = isPreviewMode ? extras_available : extras_available_for_order

  const allDisabled = areAllExtrasDisabled(extras);

  const hasExtraAvailable =
  extras &&
  extras?.length > 0 &&
    !allDisabled;

  return (
    <LayoutBlockComponent
      title={title}
      subTitle={subTitle}
      icon={icon}
      blockStyle={blockStyle}
      displayTitles={displayTitles}
    >
      {!hasExtraAvailable ? (
        <div className="flex justify-center bg-transparent">
          <CartSleeping className="w-96 h-96 md:h-[400px] md:w-[400px]" />
        </div>
      ) : (
        <>
          {extras?.map((extra: GuestPageExtra | RentalExtraAvailableListItemResponse) => (
            <ExtraCardComponent
              extra={extra}
              guest={guest}
              onChangeExtra={onChangeExtra}
              fromPage={fromPage}
              loading={loadingValidate}
            />
          ))}
          {isMobile ? (
            displayButtonPerPage()
          ) : fromPage === "home" ? (
            <div className="flex flex-row justify-start w-full md:w-80 md:self-end mt-2 md:mt-0 md:mb-4">
              <Button
                RightIcon={CartIcon}
                displayLargeBtn={true}
                buttonClassName="md:h-12 ml-0"
                textClassName="md:text-xl"
                onClick={onDisplayCart}
              >
                {t("GuestPage.Extras.displayCart")}
              </Button>
            </div>
          ) : null}
        </>
      )}
    </LayoutBlockComponent>
  );
};

export default ExtraComponent;
