import React, {useState} from "react";
import DetailsTableLineComponent from "./DetailsTableLineComponent";
import {AnalyticsDetailedMonthlyDetails} from "../../../types/GETTypes";
import {useTranslation} from "react-i18next";

interface DetailsTableProps {
  data: AnalyticsDetailedMonthlyDetails[] | undefined;
  currency: string | undefined;
  loading?: boolean;
}

const DetailsTableComponent = ({
  data,
  loading,
  currency,
}: DetailsTableProps) => {
  const {t} = useTranslation();
  const [indexSelect, setIndexSelect] = useState<number | undefined>(undefined);
  const onChangeIndexSelect = (idx: number) => {
    setIndexSelect(
      idx === indexSelect ? undefined : idx !== undefined ? idx : undefined
    );
  };
  if (loading || !data) {
    return (
      <div className="overflow-hidden rounded-lg border border-gray-200 animate-pulse">
        <table className="w-full border-collapse">
          <thead className="bg-gray-200">
            <tr>
              <th className="border-b py-4 pl-2 text-right">
                <div className="h-4 bg-gray-300 rounded w-1/4"></div>
              </th>
              <th className="border-b py-4 text-right">
                <div className="h-4 bg-gray-300 rounded w-2/3"></div>
              </th>
              <th className="border-b py-4 text-right">
                <div className="h-4 bg-gray-300 rounded w-2/3"></div>
              </th>
              <th className="border-b py-4 text-right">
                <div className="h-4 bg-gray-300 rounded w-2/3"></div>
              </th>
              <th className="border-b py-4 text-right">
                <div className="h-4 bg-gray-300 rounded w-2/3"></div>
              </th>
              <th className="border-b py-4 text-right">
                <div className="h-4 bg-gray-300 rounded w-2/3"></div>
              </th>
              <th className="border-b py-4 text-right">
                <div className="h-4 bg-gray-300 rounded w-2/3"></div>
              </th>
              <th className="border-b py-4 pr-2 text-right">
                <div className="h-4 bg-gray-300 rounded w-1/4"></div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="">
              <td className="border-b py-4 pl-2 text-right text-high-contrast">
                <div className="h-4 bg-gray-300 rounded w-1/4"></div>
              </td>
              <td className="border-b py-4 text-right text-high-contrast">
                <div className="h-4 bg-gray-300 rounded w-2/3"></div>
              </td>
              <td className="border-b py-4 text-right text-high-contrast">
                <div className="h-4 bg-gray-300 rounded w-2/3"></div>
              </td>
              <td className="border-b py-4 text-right text-high-contrast">
                <div className="h-4 bg-gray-300 rounded w-2/3"></div>
              </td>
              <td className="border-b py-4 text-right text-high-contrast">
                <div className="h-4 bg-gray-300 rounded w-2/3"></div>
              </td>
              <td className="border-b py-4 text-right text-high-contrast">
                <div className="h-4 bg-gray-300 rounded w-2/3"></div>
              </td>
              <td className="border-b py-4 text-right text-high-contrast">
                <div className="h-4 bg-gray-300 rounded w-2/3"></div>
              </td>
              <td className="border-b py-4 pr-2 text-right text-high-contrast">
                <div className="h-4 bg-gray-300 rounded w-1/4"></div>
              </td>
            </tr>
            <tr>
              <td className="border-b py-4 pl-2 text-right text-high-contrast">
                <div className="h-4 bg-gray-300 rounded w-1/4"></div>
              </td>
              <td className="border-b py-4 text-right text-high-contrast">
                <div className="h-4 bg-gray-300 rounded w-2/3"></div>
              </td>
              <td className="border-b py-4 text-right text-high-contrast">
                <div className="h-4 bg-gray-300 rounded w-2/3"></div>
              </td>
              <td className="border-b py-4 text-right text-high-contrast">
                <div className="h-4 bg-gray-300 rounded w-2/3"></div>
              </td>
              <td className="border-b py-4 text-right text-high-contrast">
                <div className="h-4 bg-gray-300 rounded w-2/3"></div>
              </td>
              <td className="border-b py-4 text-right text-high-contrast">
                <div className="h-4 bg-gray-300 rounded w-2/3"></div>
              </td>
              <td className="border-b py-4 text-right text-high-contrast">
                <div className="h-4 bg-gray-300 rounded w-2/3"></div>
              </td>
              <td className="border-b py-4 pr-2 text-right text-high-contrast">
                <div className="h-4 bg-gray-300 rounded w-1/4"></div>
              </td>
            </tr>
            <tr>
              <td className="border-b py-4 pl-2 text-left text-high-contrast">
                <div className="h-4 bg-gray-300 rounded w-1/4"></div>
              </td>
              <td className="border-b py-4 text-right text-high-contrast">
                <div className="h-4 bg-gray-300 rounded w-2/3"></div>
              </td>
              <td className="border-b py-4 text-right text-high-contrast">
                <div className="h-4 bg-gray-300 rounded w-2/3"></div>
              </td>
              <td className="border-b py-4 text-right text-high-contrast">
                <div className="h-4 bg-gray-300 rounded w-2/3"></div>
              </td>
              <td className="border-b py-4 text-right text-high-contrast">
                <div className="h-4 bg-gray-300 rounded w-2/3"></div>
              </td>
              <td className="border-b py-4 text-right text-high-contrast">
                <div className="h-4 bg-gray-300 rounded w-2/3"></div>
              </td>
              <td className="border-b py-4 text-right text-high-contrast">
                <div className="h-4 bg-gray-300 rounded w-2/3"></div>
              </td>
              <td className="border-b py-4 pr-2 text-right text-high-contrast">
                <div className="h-4 bg-gray-300 rounded w-1/4"></div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
  return (
    <div className="overflow-hidden rounded-lg border border-element-border">
      <table className="w-full border-collapse">
        <thead className="bg-element-border/60">
          <tr className="">
            <th className="border-b py-4 pl-2 text-left">
              {t("Analytics.DetailsTable.period")}
            </th>
            <th className="border-b py-4 text-right">
              {t("Analytics.DetailsTable.nightsBooked")}
            </th>
            <th className="border-b py-4 text-right">
              {t("Analytics.DetailsTable.nightsAvailable")}
            </th>
            <th className="border-b py-4 text-right">
              {t("Analytics.DetailsTable.occupancyRate")}
            </th>
            <th className="border-b py-4 text-right">
              {t("Analytics.DetailsTable.commissions")}
            </th>
            <th className="border-b py-4 text-right">
              {t("Analytics.DetailsTable.tourist_tax")}
            </th>
            <th className="border-b py-4 text-right">
              {t("Analytics.revenue_single")}
            </th>
            <th className="border-b py-4 pr-2 text-right">
              {t("Analytics.net")}
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <DetailsTableLineComponent
              currency={currency}
              row={row}
              index={index}
              indexSelect={indexSelect}
              onChangeIndexSelect={onChangeIndexSelect}
              data={data}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DetailsTableComponent;
