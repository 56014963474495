import React, { useState } from "react";

import PolicyPlatformCard from "../PolicyPlatformCard";
import HeaderPolicy from "../HeaderPolicy";
import PolicyItemsLayout from "../PolicyItemsLayout";
import BasicItemPolicy from "../BasicItemPolicy";
import AirbnbIcon from "../../../../assets/icons/airbnb.svg?react";
import { RentalResponse } from "../../../../types/GETTypes";
import { useTranslation } from "react-i18next";
import { Simulate } from "react-dom/test-utils";
import load = Simulate.load;
import { usePoliciesPlatforms } from "../../../../hooks/api/paymentPolicies";

export interface AirbnbPlatformPoliciesProps {
  rental: RentalResponse;
  reloadRental?: () => void;
}

export default function AirbnbPlatformPolicies({
  rental,
  reloadRental,
}: AirbnbPlatformPoliciesProps) {
  const { t } = useTranslation();

  const { refreshAirbnb } = usePoliciesPlatforms();

  const [loading, setLoading] = useState(false);

  const text = (txt: string, params = {}): string => {
    return t("Rental.Policies.Airbnb." + txt, params);
  };

  const airbnbIsConnected = (): boolean => {
    return rental.details.external_connexions.platform_airbnb?.enabled === 1;
  };

  const isStrictCancel = (): boolean => {
    return rental.tarification.payment_policies.airbnb.cancelation === "strict";
  };

  const refreshAirbnbPolicies = () => {
    setLoading(true);
    refreshAirbnb(rental.id).then(() => {
      if (typeof reloadRental === "function") {
        reloadRental();
      }
      setLoading(false);
    });
  };

  if (!airbnbIsConnected()) return null;

  return (
    <PolicyPlatformCard>
      <HeaderPolicy
        Icon={AirbnbIcon}
        title={text("title")}
        edit={false}
        refreshButton={true}
        onRefresh={refreshAirbnbPolicies}
        refreshLoading={loading}
      />
      <PolicyItemsLayout>
        <BasicItemPolicy title={text("payment")} text={text("managed")} />
        <BasicItemPolicy
          title={text("cancel")}
          subTitle={isStrictCancel() ? text("strict") : ""}
          text={isStrictCancel() ? text("textStrictCancel") : ""}
        />
        <BasicItemPolicy title={text("caution")} text={text("managed")} />
      </PolicyItemsLayout>
    </PolicyPlatformCard>
  );
}
