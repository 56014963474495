import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ValueType } from "../../../types/commonTypes";
import { Button } from "../../Common/Button/Button";
import { MultiSelectRentals } from "../../Common/MultiSelectRentals/MultiSelectRentals";
import { TextInput } from "../../Common/TextInput/TextInput";

import ExportIcon from "../../../assets/icons/export.svg?react";
import PlusIcon from "../../../assets/icons/plus-white.svg?react";
import SearchIcon from "../../../assets/icons/search.svg?react";
import useUserStore from "../../../stores/useUserStore";
import { RentalResponse, ReservationResponse } from "../../../types/GETTypes";
import { UseTablePageReturn } from "../../../hooks/useTablePage";

export type BookingFiltersProps = {
  bookingsIds: ValueType[];
  onAddBooking: () => void;
  onSelectBookingIds: (values: ValueType[]) => void;
  onSearchText: (value: string) => void;
  allReservations: ReservationResponse[];
  onSelectedReservations?: (values: ReservationResponse[]) => void;
  setCheckboxIsFiltered: (value: boolean) => void;
  checkboxIsFiltered?: boolean;
};

export const BookingFilters: React.FC<BookingFiltersProps> = ({
  allReservations,
  onSelectedReservations = (values: ReservationResponse[]) => {},
  bookingsIds,
  onAddBooking,
  onSelectBookingIds,
  onSearchText,
  setCheckboxIsFiltered,
  checkboxIsFiltered = false,
}) => {
  const userStore = useUserStore();
  const { t } = useTranslation();

  return (
    <div className="flex flex-row items-center space-x-3">
      <div className="flex-1">
        <TextInput
          size="large"
          Icon={SearchIcon}
          placeholder={t("Global.search")}
          onChangeText={onSearchText}
        />
      </div>

      <div>
        <MultiSelectRentals
          initialRentals={bookingsIds}
          onSelect={onSelectBookingIds}
          hasCheckboxPlatforms={true}
          initialReservations={allReservations}
          onSelectedReservations={onSelectedReservations}
          setCheckboxIsFiltered={setCheckboxIsFiltered}
          checkboxIsFiltered={checkboxIsFiltered}
        />
      </div>

      <div title={t("Global.wip")}>
        <Button type="secondary" RightIcon={ExportIcon} disabled={true}>
          {t("Global.export")}
        </Button>
      </div>

      <div>
        <Button
          onClick={onAddBooking}
          RightIcon={PlusIcon}
          disabled={!userStore.canEdit}
        >
          {t("BookingList.addBooking")}
        </Button>
      </div>
    </div>
  );
};
