export const DEFAULT_CONTRACT_SUBJECT_FR = `Votre contrat de location [RENTAL_NAME] à signer`
export const DEFAULT_CONTRACT_MESSAGE_FR = `Bonjour [GUEST_FIRSTNAME],

Merci d’avoir réservé chez nous ! Vous trouverez ci-dessous le lien pour signer votre contrat de location :
[LINK_PLACEHOLDER]

Logement : [RENTAL_HOST_NAME]

Arrivée : [RESERVATION_CHECKIN_DATE] [RESERVATION_CHECKIN_TIME]

Départ : [RESERVATION_CHECKOUT_DATE] [RESERVATION_CHECKOUT_TIME]

Nombre d’adultes : [RESERVATION_NB_ADULTS]

Nombre d’enfants : [RESERVATION_NB_CHILDREN]

Montant total : [SINGLE_PAYMENT_AMOUNT]

N’hésitez pas à me contacter si vous avez la moindre question.
Cordialement,
[GUEST_FIRSTNAME] [GUEST_LASTNAME]
`;

export const DEFAULT_CONTRACT_SUBJECT_EN = `Your rental agreement [RENTAL_NAME] to sign`
export const DEFAULT_CONTRACT_MESSAGE_EN = `Hello [GUEST_FIRSTNAME],

Thank you for booking with us! Below you will find the link to sign your rental agreement:
[Here is the link to the contract]

Property: [RENTAL_HOST_NAME]

Check-in: [RESERVATION_CHECKIN_DATE] [RESERVATION_CHECKIN_TIME]
Check-out: [RESERVATION_CHECKOUT_DATE] [RESERVATION_CHECKOUT_TIME]

Number of adults: [RESERVATION_NB_ADULTS]
Number of children: [RESERVATION_NB_CHILDREN]

Total amount: [SINGLE_PAYMENT_AMOUNT]

Feel free to contact me if you have any questions.

Kind regards,
[GUEST_FIRSTNAME] [GUEST_LASTNAME]
`;

export const DEFAULT_CONTRACT_SUBJECT_ES = `Su contrato de alquiler [RENTAL_NAME] para firmar`
export const DEFAULT_CONTRACT_MESSAGE_ES = `Hola [GUEST_FIRSTNAME],

¡Gracias por reservar con nosotros! A continuación encontrará el enlace para firmar su contrato de alquiler:
[AQUÍ poner el enlace al contrato]

Alojamiento: [RENTAL_HOST_NAME]

Check-in: [RESERVATION_CHECKIN_DATE] [RESERVATION_CHECKIN_TIME]
Check-out: [RESERVATION_CHECKOUT_DATE] [RESERVATION_CHECKOUT_TIME]

Número de adultos: [RESERVATION_NB_ADULTS]
Número de niños: [RESERVATION_NB_CHILDREN]

Importe total: [SINGLE_PAYMENT_AMOUNT]

No dude en ponerse en contacto conmigo si tiene alguna pregunta.

Atentamente,
[GUEST_FIRSTNAME] [GUEST_LASTNAME]
`;

export const DEFAULT_CONTRACT_SUBJECT_DE = `Ihr Mietvertrag [RENTAL_NAME] zur Unterzeichnung`
export const DEFAULT_CONTRACT_MESSAGE_DE = `Hallo [GUEST_FIRSTNAME],

vielen Dank, dass Sie bei uns gebucht haben! Unten finden Sie den Link, um Ihren Mietvertrag zu unterschreiben:
[Hier den Link zum Vertrag einfügen]

Unterkunft: [RENTAL_HOST_NAME]

Anreise: [RESERVATION_CHECKIN_DATE] [RESERVATION_CHECKIN_TIME]
Abreise: [RESERVATION_CHECKOUT_DATE] [RESERVATION_CHECKOUT_TIME]

Anzahl Erwachsene: [RESERVATION_NB_ADULTS]
Anzahl Kinder: [RESERVATION_NB_CHILDREN]

Gesamtbetrag: [SINGLE_PAYMENT_AMOUNT]

Zögern Sie nicht, mich zu kontaktieren, falls Sie Fragen haben.

Mit freundlichen Grüßen,
[GUEST_FIRSTNAME] [GUEST_LASTNAME]
`;

export const DEFAULT_CONTRACT_SUBJECT_PT = `Seu contrato de locação [RENTAL_NAME] para assinar`
export const DEFAULT_CONTRACT_MESSAGE_PT = `Olá [GUEST_FIRSTNAME],

Obrigado por reservar conosco! Abaixo você encontrará o link para assinar seu contrato de locação:
[Aqui está o link para o contrato]

Propriedade: [RENTAL_HOST_NAME]

Check-in: [RESERVATION_CHECKIN_DATE] [RESERVATION_CHECKIN_TIME]
Check-out: [RESERVATION_CHECKOUT_DATE] [RESERVATION_CHECKOUT_TIME]

Número de adultos: [RESERVATION_NB_ADULTS]
Número de crianças: [RESERVATION_NB_CHILDREN]

Valor total: [SINGLE_PAYMENT_AMOUNT]

Sinta-se à vontade para entrar em contato comigo se tiver alguma dúvida.

Atenciosamente,
[GUEST_FIRSTNAME] [GUEST_LASTNAME]
`;

