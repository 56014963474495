import React, {useState} from "react";
import {ConversationListItem} from "./ConversationListItem";
import {ValueType} from "../../../types/commonTypes";
import {useTranslation} from "react-i18next";
import {TextInput} from "../../Common/TextInput/TextInput";
import {Button} from "../../Common/Button/Button";
import {GroupButton} from "../../Common/GroupButton/GroupButton";
import {Separator} from "../../Common/Separator/Separator";
import {ConversationListItemSkeleton} from "./ConversationListItemSkeleton";
import moment from "moment";
import {debouncedUseGetConversations} from "../../../hooks/api/reservations";
import FilterIcon from "../../../assets/icons/filter_.svg?react";
import FilterFullIcon from "../../../assets/icons/filter-active.svg?react";

export const ConversationList: React.FC<{
  conversations: any[];
  selectedReservationId: ValueType | undefined;
  loading: boolean;
  unreadConversations: string | undefined;
  readStatus: number;
  onSelect: (reservationId: string) => void;
  onReadStatus: (value: ValueType) => void;
  setFilterMessageModalVisible: (value: boolean) => void;
  isFiltered: boolean;
  setIsNewReservationsList: (value: boolean) => void;
}> = ({
  loading,
  selectedReservationId,
  conversations,
  unreadConversations,
  readStatus,
  onSelect,
  onReadStatus,
  setFilterMessageModalVisible,
  isFiltered,
  setIsNewReservationsList
}) => {
  const { t } = useTranslation();
  const [listconversations, setListConversations] = useState(conversations);
  const [loadingConversations, setLoadingConversations] = useState(loading);
  const handleSelectConversation = (reservationId: string) => {
    setIsNewReservationsList(false);
    const conversation_is_read = listconversations.find(
      (conversation) => conversation.reservation_id === reservationId
    ).is_conversation_read;
    if ((!conversation_is_read && readStatus === 0) || readStatus === -1) {
      setListConversations((prevConversations) =>
        prevConversations.map((conversation) =>
          conversation.reservation_id === reservationId
            ? { ...conversation, is_conversation_read: true }
            : conversation
        )
      );
      onSelect(reservationId);
    }
  };

  const searchConversation = (text: string) => {
    setLoadingConversations(true);
    const values =
      text != ""
        ? {
            search: text,
            page: 1,
            limit: 100,
            sort_field: "captured_at",
            sort_direction: "desc",
          }
        : {
            page: 1,
            limit: 100,
            sort_field: "captured_at",
            sort_direction: "desc",
          };

    debouncedUseGetConversations(
      values,
      (data) => {
        setListConversations(data.conversations);
        setLoadingConversations(false);
      },
      (message) => {
        console.log(message, "message");
      }
    );
  };

  return (
    <div className="w-80 border-r-1 border-element-border">
      {!loading && (
        <>
          <div className="flex flex-col p-3 pb-1">
            {!loading && (
              <div className="flex flex-row space-x-3 pb-3">
                <div className="flex-1">
                  <TextInput
                    placeholder={t("MessageList.search")}
                    onChangeText={searchConversation}
                  />
                </div>
                <div>
                  <Button
                    size="small"
                    type="secondary"
                    disabled={conversations.length === 0}
                    borderButton={
                      isFiltered
                        ? "border-1 border-active"
                        : "border-1 border-black/12"
                    }
                    onClick={() => {
                      setFilterMessageModalVisible(true);
                    }}
                  >
                    {isFiltered ? (
                      <FilterFullIcon className="w-4 h-4" />
                    ) : (
                      <FilterIcon className="w-4 h-4" />
                    )}
                  </Button>
                </div>
              </div>
            )}
            <div className="">
              <GroupButton
                items={[
                  {
                    label: t("MessageList.all"),
                    value: -1,
                    isActive: readStatus === -1,
                  },
                  {
                    label: t("MessageList.unread"),
                    value: 0,
                    badge:
                      unreadConversations === undefined
                        ? undefined
                        : unreadConversations >0 ? unreadConversations : undefined,
                    isActive: readStatus === 0,
                  },
                ]}
                width="full"
                onClick={onReadStatus}
              />
            </div>
          </div>

          <Separator />

          <div
            className="overflow-y-scroll"
            style={{
              height: "calc(100vh - 240px)",
            }}
          >
            {loadingConversations &&
              Array.from({ length: 7 }).map((value, index) => (
                <ConversationListItemSkeleton key={index} />
              ))}
            {/* Conversations */}
            {!loadingConversations &&
              (listconversations.length > 0 ? (
                listconversations.map((conversation) => {
                  return (
                    <ConversationListItem
                      key={conversation.reservation_id}
                      avatarSrc={conversation.guest?.photo}
                      status={conversation?.reservation?.status_reservation}
                      lastMessageDate={
                        conversation?.last_message_date != null
                          ? new Date(conversation?.last_message_date)
                          : new Date(conversation?.reservation.reservation_date)
                      }
                      fullName={`${conversation.guest?.first_name} ${conversation.guest?.last_name}`}
                      checkin={moment(
                        conversation.reservation.checkin
                      ).toDate()}
                      checkout={moment(
                        conversation.reservation.checkout
                      ).toDate()}
                      unread={!conversation?.is_conversation_read}
                      platform={conversation?.reservation?.platform}
                      isSelected={
                        conversation.reservation_id === selectedReservationId
                      }
                      rental_name={conversation.rental?.name}
                      messagePreview={conversation.last_message_preview}
                      onClick={() =>
                        handleSelectConversation(conversation.reservation_id)
                      }
                    />
                  );
                })
              ) : (
                <div className="flex flex-col items-center justify-center w-full h-full">
                  <p className="text-secondary">
                    {t("MessageList.noConversations")}
                  </p>
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  );
};
