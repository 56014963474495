import React from "react";
import { ErrorMessage } from "../../components/Common/ErrorMessage/ErrorMessage";
import { ProgressionStepProps } from "../../components/Progression/Progression.type";

import CheckIcon from "../../assets/icons/check-success.svg?react";
import ClockIcon from "../../assets/icons/clock.svg?react";
import CloseIcon from "../../assets/icons/close-red.svg?react";

export const ProgressionStep: React.FC<ProgressionStepProps> = ({
  number,
  title,
  children,
  status = "inactive",
  error,
  loading = false,
}) => {
  const getTitleColor = () => {
    if (status === "active") return "text-high-contrast";
    else if (status === "pending") return "text-high-contrast";
    else if (status === "error") return "text-red-600";
    else return "text-low-contrast";
  };

  return (
    <div
      className={`flex flex-col flex-1 p-4 border-b-1 border-element-border last:border-0 `}
    >
      <div className="flex flex-row items-center gap-3">
        {status === "active" && (
          <div className="w-8 h-8 rounded-full flex justify-center items-center bg-[#2B2F31] font-semibold text-lg text-white">
            {number}
          </div>
        )}

        {status === "inactive" && (
          <div className="flex items-center justify-center w-8 h-8 text-base font-semibold rounded-full bg-element-background-disabled text-low-contrast">
            {number}
          </div>
        )}

        {status === "success" && (
          <div className="flex items-center justify-center w-8 h-8 text-base font-semibold bg-green-100 rounded-full text-low-contrast">
            <CheckIcon className="w-4 h-4" />
          </div>
        )}

        {status === "error" && (
          <div className="flex items-center justify-center w-8 h-8 text-base font-semibold bg-red-100 rounded-full text-low-contrast">
            <CloseIcon className="w-6 h-6" />
          </div>
        )}

        {status === "pending" && (
          <div className="flex items-center justify-center w-8 h-8 text-lg font-semibold text-white rounded-full bg-element-background-disabled">
            <ClockIcon className="w-4 h-4" />
          </div>
        )}

        {loading ? (
          <p className="h-2 rounded bg-slate-200 w-96 animate-pulse"></p>
        ) : (
          <p className={`${getTitleColor()} font-semibold`}>{title}</p>
        )}
      </div>

      <div className="flex flex-row gap-3">
        <div className="w-8" />

        {!loading && (status === "active" || status === "error") && (
          <div className="flex flex-col w-full gap-3">
            <ErrorMessage errorType="FORM">{error}</ErrorMessage>

            <div className="flex-1 w-full">{children}</div>
          </div>
        )}
      </div>
    </div>
  );
};
