import { t } from "i18next";
import React from "react";
import { useForm } from "react-hook-form";
import CheckCircleGreen from "../../../../../../assets/icons/check-circle-green.svg?react";
import CheckIcon from "../../../../../../assets/icons/check-white.svg?react";
import PlusIcon from "../../../../../../assets/icons/plus-white.svg?react";
import StripeIcon from "../../../../../../assets/icons/stripe.svg?react";
import { RequiredFields } from "../../../../../../types/commonTypes";
import { Button } from "../../../../../Common/Button/Button";
import { TextInput } from "../../../../../Common/TextInput/TextInput";

type ProgressionConfigDirectReservationStripeCollectPaymentsStepProps = {
  loading: boolean;
  onValidate: (name: string) => void;
  onCollectPaymentsDifferently: () => void;
};

type ProgressionConfigDirectReservationStripeCollectPaymentsStepForm = {
  account_name: string | null;
};

export const ProgressionConfigDirectReservationStripeCollectPaymentsStep: React.FC<
  ProgressionConfigDirectReservationStripeCollectPaymentsStepProps
> = ({ loading, onValidate, onCollectPaymentsDifferently }) => {
  const parameters = new URLSearchParams(window.location.search);
  const codeParam = parameters.get("code");

  const form =
    useForm<ProgressionConfigDirectReservationStripeCollectPaymentsStepForm>({
      mode: "all",
      defaultValues: {
        account_name: null,
      },
    });

  const requiredFields: RequiredFields<ProgressionConfigDirectReservationStripeCollectPaymentsStepForm> =
    {
      account_name: true,
    };

  const stripeURL = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${
    import.meta.env.VITE_STRIPE_CLIENT_ID
  }&scope=read_write&redirect_uri=${
    import.meta.env.VITE_STRIPE_ADD_ACCOUNT_CALLBACK_TWO
  }`;

  form.watch();

  return (
    <div className="flex flex-col gap-y-3">
      <p className="text-md text-low-contrast">
        {t(
          "Progression.StepConfigDirectReservations.Modal.Steps.StripeCollectPayments.content1"
        )}
      </p>

      <p className="text-md text-low-contrast">
        {t(
          "Progression.StepConfigDirectReservations.Modal.Steps.StripeCollectPayments.content2"
        )}
      </p>

      <p className="text-md text-low-contrast">
        {t(
          "Progression.StepConfigDirectReservations.Modal.Steps.StripeCollectPayments.content3"
        )}
      </p>

      <p className="text-md text-low-contrast">
        {t(
          "Progression.StepConfigDirectReservations.Modal.Steps.StripeCollectPayments.content4"
        )}
      </p>

      <StripeIcon />

      <p className="text-lg font-bold text-high-contrast">
        {t(
          "Progression.StepConfigDirectReservations.Modal.Steps.StripeCollectPayments.loginOrRegisterStripeAccount"
        )}
      </p>

      <p className="text-base text-low-contrast">
        {t(
          "Progression.StepConfigDirectReservations.Modal.Steps.StripeCollectPayments.loginOrRegisterStripeAccountDescription"
        )}
      </p>

      <div className="flex flex-col gap-y-3">
        {codeParam && (
          <div className="flex flex-col gap-y-3">
            <div className="flex items-start p-4 rounded-lg gap-x-3 bg-element-background-success">
              <CheckCircleGreen className="size-12 h-max" />
              <div className="flex flex-col justify-start space-y-3">
                <p className="text-base font-extrabold text-success">
                  {t(
                    "Progression.StepConfigDirectReservations.Modal.Steps.StripeCollectPayments.addStripeAccountSuccessTitle"
                  )}
                </p>
                <p className="font-light text-success">
                  {t(
                    "Progression.StepConfigDirectReservations.Modal.Steps.StripeCollectPayments.addStripeAccountSuccessDescription"
                  )}
                </p>
              </div>
            </div>

            <TextInput
              label={t(
                "Progression.StepConfigDirectReservations.Modal.Steps.StripeCollectPayments.accountNameLabel"
              )}
              register={form.register("account_name", {
                required: {
                  value: requiredFields.account_name,
                  message: t(
                    "Progression.StepConfigDirectReservations.Modal.Steps.StripeCollectPayments.accountNameError"
                  ),
                },
              })}
              required={requiredFields.account_name}
              placeholder={t(
                "Progression.StepConfigDirectReservations.Modal.Steps.StripeCollectPayments.accountNamePlaceholder"
              )}
              value={form.getValues("account_name")!}
              error={form.formState.errors.account_name?.message}
            />
          </div>
        )}

        <Button
          type="primary"
          RightIcon={codeParam ? CheckIcon : PlusIcon}
          disabled={
            (codeParam && loading) ||
            Object.values(form.formState.errors).length > 0
          }
          loading={Boolean(codeParam) && loading}
          onClick={form.handleSubmit(() => {
            if (codeParam) {
              onValidate(form.getValues("account_name")!);
            } else {
              window.location.href = stripeURL;
            }
          })}
        >
          {codeParam
            ? t("Global.finish")
            : t(
                "Progression.StepConfigDirectReservations.Modal.Steps.StripeCollectPayments.addStripeAccount"
              )}
        </Button>

        {!codeParam && (
          <div>
            <Button
              type="secondary"
              onClick={onCollectPaymentsDifferently}
              loading={loading}
            >
              {t(
                "Progression.StepConfigDirectReservations.Modal.Steps.StripeCollectPayments.collectPaymentDifferent"
              )}
            </Button>

            <p className="mt-2 text-center text-low-contrast">
              {t(
                "Progression.StepConfigDirectReservations.Modal.Steps.StripeCollectPayments.collectPaymentDifferentInfo"
              )}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
