import React from "react";
import BrandsComponent from "./BrandsComponent";
import TitleComponent from "../../Common/Template2.0/TitleComponent";
import RentalCardComponent from "../../Common/Template2.0/RentalCardComponent";
import RentalsListComponent from "../../Common/Template2.0/RentalsListComponent";
import Info1Component from "./Info1Component";
import ReviewComponent from "./ReviewComponent";
import Info2Component from "./Info2Component";
import {FaqComponent} from "./FaqComponent";
import useGuestWebsiteStore from "../../../../stores/guestWebsiteStore/useGuestWebsiteStore";
import SkeletonRentalsList from "../../Common/Template2.0/SkeletonRentalsList";
import {useTranslation} from "react-i18next";

interface GuestWebsiteTemplate2HomeComponent {
  loading: boolean;
}
export const GuestWebsiteTemplate2HomeComponent = ({
  loading,
}: GuestWebsiteTemplate2HomeComponent) => {
  const {t} = useTranslation();
  const {dataGuestWebsite, loadingRentalsSearch} = useGuestWebsiteStore();
  const {content, rentals, styles} = dataGuestWebsite || {};
  const titleRentals = content?.rentals?.title;
  const subtitleRentals = content?.rentals?.text;
  const showBrands = content?.partners?.enabled;
  const showInfo1 = content?.info1?.enabled;
  const showReviews = content?.guest_reviews?.enabled;
  const showInfo2 = content?.info2?.enabled;
  const showFaq = content?.faq?.enabled;
  const {color_background} = styles || {};

  return (
    <div className="" style={{backgroundColor: color_background}}>
      {showBrands && <BrandsComponent loading={loading} />}

      <TitleComponent
        title={titleRentals ?? t("GuestWebsite.Rentals.title")}
        subtitle={subtitleRentals ?? ""}
        classNames={{divParent: !showBrands ? "pt-32 md:pt-[5rem]" : ""}}
      />
      <div className="p-4 pt-0 pb-8">
        {loadingRentalsSearch || loading ? (
          <SkeletonRentalsList />
        ) : (
          <RentalsListComponent rentals={rentals} />
        )}
      </div>
      {showInfo1 && (
        <div className="pt-8">
          <Info1Component />
        </div>
      )}
      {showReviews && (
        <div className="md:px-contentWebsite pt-8">
          <ReviewComponent />
        </div>
      )}
      {showInfo2 && (
        <div className="pt-8">
          <Info2Component />
        </div>
      )}
      {showFaq && (
        <div className="pt-8 md:px-contentWebsite">
          <FaqComponent />
        </div>
      )}
    </div>
  );
};
