import React, {useEffect, useState} from "react";
import CheckIcon from "../../../assets/icons/check-white.svg?react";
import MinusIcon from "../../../assets/icons/minus-white.svg?react";
import {cn} from "../../../helpers/classHelper";
import {CheckBoxOnBlur, CheckBoxProps} from "./CheckBox.type";

export const CheckBox: React.FC<CheckBoxProps> = ({
  register,
  label,
  value,
  isRadio = false,
  withBorder = false,
  Icon,
  disabled = false,
  error = null,
  required = false,
  onChange,
  classNames,
  toggleLabelActive = false,
}) => {
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [currentValue, setCurrentValue] = useState<boolean | "undefined">(
    false
  );

  useEffect(() => {
    setCurrentValue(value ?? false);
  }, [value]);

  const getBorder = () => {
    if (error) {
      return "border-1 border-element-border-error";
    } else if (!currentValue) {
      return `
      border-1
      border-element-border
      hover:border-element-border-hover 
      active:border-element-border-active`;
    }

    return "";
  };

  const getExternalBorder = () => {
    if (withBorder) {
      let border = `
      p-2
      my-1
      rounded-6px
      border-1`;

      if (error) {
        return `
          ${border}
          border-error
        `;
      } else {
        return `
          ${border}
          border-element-border
          hover:border-element-border-hover 
          active:border-element-border-active
        `;
      }
    }

    return "";
  };

  const getBackground = () => {
    if (currentValue) {
      return "bg-button-primary-default-top";
    }

    return "bg-white";
  };

  const getExternalBackground = () => {
    if (withBorder && disabled) {
      return "bg-element-background-disabled";
    }

    return "";
  };

  const getOutline = () => {
    if (isFocus && error) {
      return "outline outline-2 outline-error/50";
    } else if (isFocus && !error) {
      return "outline outline-2 outline-focus";
    } else {
      return "";
    }
  };

  const getActive = () => {
    if (toggleLabelActive && isRadio && currentValue) {
      return "text-active";
    }
    return "";
  };

  const getRounded = () => {
    if (isRadio) {
      return "rounded-full";
    } else {
      return "rounded-4px";
    }
  };

  const opacity = disabled ? "opacity-64" : "";
  const cursor = disabled ? "cursor-not-allowed" : "cursor-pointer";

  const handleBlur: CheckBoxOnBlur = (e) => {
    setIsFocus(false);
    register?.onBlur(e);
  };

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    // Returns if button is disabled or if click on link tag
    if (disabled || (e.target as HTMLElement).tagName === "A") {
      return;
    }

    let newValue = false;

    if (currentValue === "undefined") {
      newValue = false;
    } else if (isRadio && currentValue) {
      newValue = currentValue;
    } else {
      newValue = !currentValue;
    }

    register?.onChange({target: {name: register?.name, value: newValue}});
    setCurrentValue(newValue);
    if (typeof onChange === "function") {
      onChange(newValue);
    }
  };

  return (
    <div className={classNames?.parent}>
      <div
        onClick={(e) => handleClick(e)}
        className={cn(`
        flex
    ${cursor}
    ${getExternalBorder()}
    ${getExternalBackground()}
    `)}
      >
        <div className="flex flex-row items-center space-x-2">
          <div
            {...register}
            className={`
              flex w-4 h-4  
              items-center justify-center 
              ${getRounded()}
              ${getBorder()}
              ${getBackground()}
              ${getOutline()}
              ${opacity}
              `}
            onFocus={() => setIsFocus(true)}
            onBlur={handleBlur}
          >
            {!isRadio && currentValue === true ? (
              <CheckIcon className="block w-3 h-3 text-white " />
            ) : null}

            {!isRadio && currentValue === "undefined" ? (
              <MinusIcon className="block w-3 h-3 text-white " />
            ) : null}

            {isRadio && currentValue === true ? (
              <div className="w-1 h-1 bg-white rounded-full"></div>
            ) : null}
          </div>
          {Icon ? (
            <div>
              <Icon />
            </div>
          ) : null}
          <div
            className={cn(
              "flex-1 font-light text-left select-none",
              getActive()
            )}
          >
            {label}
            {required && (
              <span className="relative ml-1 text-lg -top-1 text-active">
                *
              </span>
            )}
          </div>
        </div>
      </div>

      {error ? (
        <p
          className={cn(
            "mt-1 text-sm font-light text-left text-error",
            classNames?.error
          )}
        >
          {error}
        </p>
      ) : null}
    </div>
  );
};
