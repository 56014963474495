import React from "react";

export const ContractsCardSkeleton = () => {
  return (
    <div
      className="flex flex-col border-1 border-gray-300 rounded-6px animate-pulse"
    >
      {/* Header */}
      <div className="flex flex-row items-center justify-between h-12 p-4 space-x-2 border-b-1 border-gray-200">
        <div className="flex items-center space-x-2">
          <div className="w-6 h-6 bg-gray-200 rounded"></div>
          <div className="flex-1 h-4 bg-gray-200 rounded"></div>
        </div>
        <div className="w-24 h-8 rounded bg-gray-200"></div>
      </div>

      {/* Body */}
      <div className="p-5 text-sm flex-1 rounded-b-6px bg-gray-100">
        <div className="h-4 bg-gray-200 rounded w-full my-1"></div>
        <div className="h-4 bg-gray-200 rounded w-2/3 my-1"></div>
        <div className="h-4 bg-gray-200 rounded w-1/2 my-1"></div>
      </div>
      <div className="p-5 text-sm flex-1 rounded-b-6px bg-gray-100">
        <div className="h-4 bg-gray-200 rounded w-full my-1"></div>
        <div className="h-4 bg-gray-200 rounded w-2/3 my-1"></div>
        <div className="h-4 bg-gray-200 rounded w-1/2 my-1"></div>
      </div>
      <div className="p-5 text-sm flex-1 rounded-b-6px bg-gray-100">
        <div className="h-4 bg-gray-200 rounded w-full my-1"></div>
        <div className="h-4 bg-gray-200 rounded w-2/3 my-1"></div>
        <div className="h-4 bg-gray-200 rounded w-1/2 my-1"></div>
      </div>
    </div>
  );
};
