import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import CheckWhiteIcon from "../../../../assets/icons/check-white.svg?react";
import SendWhiteIcon from "../../../../assets/icons/send-white.svg?react";
import CopyIcon from "../../../../assets/icons/copy-white.svg?react";
import EmojiHappyIcon from "../../../../assets/icons/emoji-happy.svg?react";
import EyeOffIcon from "../../../../assets/icons/eye-off.svg?react";
import MoneyIcon from "../../../../assets/icons/money.svg?react";
import MoonIcon from "../../../../assets/icons/moon.svg?react";
import UsersIcon from "../../../../assets/icons/user.svg?react";
import {
  PaymentAccountTypeEnum,
  PaymentReservationStatusEnum,
  PlatformNameEnum,
} from "../../../../enums/GETenums";
import { hiddenDevice } from "../../../../helpers/calendarHelper";
import { getPaymentStatusBadge } from "../../../../helpers/reservationHelper";
import { useReservationById } from "../../../../hooks/api/reservations";
import { useModal } from "../../../../hooks/useModal";
import { ValueType } from "../../../../types/commonTypes";
import {
  PaymentReservationScheduleListItemResponse,
  ReservationResponse,
} from "../../../../types/GETTypes";
import { Button } from "../../../Common/Button/Button";
import { ErrorMessage } from "../../../Common/ErrorMessage/ErrorMessage";
import { RightModal } from "../../../Common/RightModal/RightModal";
import { Separator } from "../../../Common/Separator/Separator";
import { PaymentReservationListInfo } from "..//PaymentReservationListInfo";
import { PaymentReservationDoneModal } from "./PaymentReservationDoneModal";
import { PaymentReservationListModalProps } from "./PaymentReservationListModal.type";
import { PaymentReservationListModalMultiInfos } from "./PaymentReservationListModalMultiInfos";
import { PaymentReservationListModalRefund } from "./PaymentReservationListModalRefund";
import { PaymentReservationListModalCancel } from "./PaymentReservationListModalCancel";
import { PaymentReservationListMulti } from "./PaymentReservationListMulti";
import { formatNumberWithSpace } from "../../../../helpers/numberHelper";
import { getPlatformIcon } from "../../../../helpers/platformHelper";
import { Rating } from "../../../Common/Rating/Rating";
import moment from "moment";
import { ReservationPaymentAndDepositLinksModal } from "../../../Reservation/PaymentAndDepositLinks/PaymentAndDepositLinksModal";
import { PaymentReservationListModalPaidByOtherWay } from "./PaymentReservationListModalPaidByOtherWay";

export const PaymentReservationListModal: React.FC<
  PaymentReservationListModalProps
> = ({
  loading,
  useMode,
  reservation,
  paymentReservation,
  paymentScheduleId,
  isVisible,
  onBack,
  onClose,
  onTransactionDetailClick,
  onPaymentStatusChanged,
}) => {
  const { t } = useTranslation();
  const [apiError, setApiError] = useState<string | undefined>();
  const [currentReservation, setCurrentReservation] = useState<
    ReservationResponse | undefined
  >(reservation);
  const [paymentReservationDetail, setPaymentReservationDetail] =
    useState<PaymentReservationScheduleListItemResponse>();
  const [paymentLink, setPaymentLink] = useState<string>("");

  useEffect(() => {
    if (paymentReservation && reservation?.id !== paymentReservation?.id) {
      useReservationById(
        paymentReservation?.id,
        (reservationResponse: ReservationResponse | undefined) => {
          setCurrentReservation(reservationResponse);
        },
        (message: string | undefined) => setApiError(message),
        () => {},
        () => {}
      );
    }
  }, [paymentReservation]);

  useEffect(() => {
    if (
      paymentReservation &&
      paymentReservation.payment_schedule?.is_single_payment &&
      paymentReservation.payment_schedule?.payments_schedule_items.length > 0
    ) {
      setPaymentReservationDetail(
        paymentReservation.payment_schedule?.payments_schedule_items[0]
      );
    }
  }, [paymentReservation]);

  useEffect(() => {
    if (
      paymentReservation &&
      paymentReservation.payment_schedule &&
      paymentReservation.payment_schedule.payments_schedule_items.length > 0 &&
      paymentScheduleId
    ) {
      const detail =
        paymentReservation.payment_schedule?.payments_schedule_items.find(
          (item) => item.id === paymentScheduleId
        );
      setPaymentReservationDetail(detail);
    }
  }, [paymentReservation, paymentScheduleId]);

  useEffect(() => {
    if (paymentReservationDetail) {
      setPaymentLink(
        location.protocol +
          "//" +
          location.host +
          "/reservation/" +
          paymentReservation?.id +
          "/payment/" +
          paymentReservationDetail.payment_link
      );
    }
  }, [paymentReservationDetail]);

  // * Cancel payment reservation
  const cancelModal = useModal<{
    reservationId: ValueType;
    paymentId: ValueType;
  }>();
  const handleCancelPaymentReservation = async () => {
    cancelModal.close();
    onPaymentStatusChanged();
  };

  // * Send payment reservation
  const sendModal = useModal<{ index: number; isDeposit: boolean }>();
  const handleOpenSendModal = (index: number, isDeposit: boolean) => {
    sendModal.open({ index, isDeposit });
  };
  const handleSendPaymentReservation = () => {
    sendModal.close();
  };

  // * Refund payment reservation
  const refundModal = useModal<{
    reservationId: ValueType;
    paymentLink: string;
    paymentAccountType: string;
  }>();
  const handleRefundPaymentReservation = () => {
    refundModal.close();
    onPaymentStatusChanged();
  };

  // * Set payment to done manually
  const modalChangeToDone = useModal<{
    reservationId: ValueType;
    paymentId: ValueType;
  }>();
  const canPaymentChangeToDone = () => {
    const statusCheck =
      paymentReservationDetail?.payment_status ===
      PaymentReservationStatusEnum.UNPAID;

    const platformCheck =
      currentReservation?.platform?.name?.toUpperCase() ===
      PlatformNameEnum.DIRECT;

    const wayCheck =
      paymentReservationDetail?.payment_account_type?.toLowerCase() !==
      PaymentAccountTypeEnum.STRIPE;

    return statusCheck && platformCheck && wayCheck;
  };
  const handlePaymentChangeToDone = () => {
    modalChangeToDone.close();
    onPaymentStatusChanged();
  };

  // * Set payment to done by other way
  const paidByOtherWayModal = useModal<{
    reservationId: ValueType;
    paymentId: ValueType;
  }>();
  const handlePaymentChangeToDoneByOtherWay = async () => {
    paidByOtherWayModal.close();
    onPaymentStatusChanged();
  };

  // * Select and show one of the payments (for multiple payments)
  const handleTransactionDetailClick = (
    paymentReservationId: number,
    paymentSchedule: PaymentReservationScheduleListItemResponse
  ) => {
    setPaymentReservationDetail(paymentSchedule);
    onTransactionDetailClick(paymentReservationId, Number(paymentSchedule.id));
  };

  // * Helpers
  const isStripePayment = (paymentStatus: PaymentReservationStatusEnum) => {
    const statusCheck =
      paymentReservationDetail?.payment_status === paymentStatus;

    const platformCheck =
      currentReservation?.platform?.name?.toUpperCase() ===
      PlatformNameEnum.DIRECT;

    const wayCheck =
      paymentReservationDetail?.payment_account_type?.toLowerCase() ===
      PaymentAccountTypeEnum.STRIPE;

    return statusCheck && platformCheck && wayCheck;
  };

  const isSinglePayment = () => {
    return (
      paymentReservation &&
      paymentReservation.payment_schedule &&
      paymentReservation.payment_schedule.payments_schedule_items.length === 1
    );
  };

  const isMultiplePayments = () => {
    return (
      paymentReservation &&
      paymentReservation.payment_schedule &&
      paymentReservation.payment_schedule.payments_schedule_items.length > 1
    );
  };

  const getTitle = () => {
    switch (currentReservation?.platform?.name?.toUpperCase()) {
      case PlatformNameEnum.DIRECT:
        const paymentType = t(
          `Global.PaymentAccounts.by_${
            paymentReservationDetail?.payment_account_type ?? ""
          }`
        )?.toLowerCase();

        if (isMultiplePayments() && useMode === "paymentReservation")
          return t("Payments.PaymentReservationList.platformDirect");

        if (isMultiplePayments() && useMode === "paymentReservationDetail")
          return `${t(
            "Payments.PaymentReservationList.platformDirectSubPayment"
          )} ${paymentType}`;

        return `${t(
          "Payments.PaymentReservationList.platformDirect"
        )} ${paymentType}`;
      case PlatformNameEnum.AIRBNB:
        return t("Payments.PaymentReservationList.platformAirbnb");
      case PlatformNameEnum.BOOKING:
        return t("Payments.PaymentReservationList.platformBooking");
      case PlatformNameEnum.ICALENDAR:
        return t("Payments.PaymentReservationList.platformIcalendar");
      default:
        return t("Payments.PaymentReservationList.platform");
    }
  };

  return (
    <>
      {/* REMOVE PAYMENT RESERVATION MODAL */}
      <PaymentReservationListModalCancel
        data={cancelModal.data}
        isVisible={cancelModal.isVisible}
        onSuccess={handleCancelPaymentReservation}
        onClose={cancelModal.close}
      />

      {/* SEND PAYMENT RESERVATION MODAL */}
      <ReservationPaymentAndDepositLinksModal
        reservation={currentReservation}
        data={sendModal.data}
        isVisible={sendModal.isVisible}
        onSuccess={handleSendPaymentReservation}
        onClose={sendModal.close}
      />

      {/* REFUND PAYMENT RESERVATION MODAL */}
      <PaymentReservationListModalRefund
        data={refundModal.data}
        isVisible={refundModal.isVisible}
        onSuccess={handleRefundPaymentReservation}
        onClose={refundModal.close}
      />

      {/* SET PAYMENT AS DONE MANUALLY (FOR PAYMENT TYPES LIKE CASH OR BANK CHECK) */}
      <PaymentReservationDoneModal
        data={modalChangeToDone.data}
        isVisible={modalChangeToDone.isVisible}
        onSuccess={handlePaymentChangeToDone}
        onClose={modalChangeToDone.close}
      />

      {/* SET PAYMENT AS DONE MANUALLY (FOR PAYMENT TYPE LIKE STRIPE) */}
      <PaymentReservationListModalPaidByOtherWay
        data={paidByOtherWayModal.data}
        isVisible={paidByOtherWayModal.isVisible}
        onSuccess={handlePaymentChangeToDoneByOtherWay}
        onClose={paidByOtherWayModal.close}
      />

      <RightModal
        title={<p className="text-xl font-semibold">{getTitle()}</p>}
        rightHeaderNode={
          useMode === "paymentReservation"
            ? getPaymentStatusBadge(
                paymentReservation?.payment_schedule?.payment_status!,
                t
              )
            : getPaymentStatusBadge(
                paymentReservationDetail?.payment_status!,
                t
              )
        }
        isVisible={loading ? false : isVisible}
        onClose={onClose}
        onBack={useMode === "paymentReservationDetail" ? onBack : undefined}
      >
        <div className="flex flex-col justify-between w-full">
          <div className="flex flex-col flex-1 overflow-y-auto gap-y-3">
            <p className="font-bold text-low-contrast">
              {t("Payments.PaymentReservationList.contextLabel")}
            </p>

            <div>
              <p className="font-bold text-high-contrast">
                {t("Payments.PaymentReservationList.guestLabel")}
              </p>
              <div className="flex flex-row items-center justify-between p-2 space-x-3">
                <div className="flex items-center space-x-4">
                  <div className="relative">
                    {/* LOGO */}
                    {paymentReservation?.platform ? (
                      <div
                        className="absolute z-10"
                        style={{ top: 22, left: 26, width: 16, height: 16 }}
                      >
                        {getPlatformIcon(paymentReservation?.platform, "small")}
                      </div>
                    ) : (
                      <div
                        className="absolute z-10 border-2 border-white rounded-full bg-slate-200"
                        style={{ top: 22, left: 26, width: 16, height: 16 }}
                      />
                    )}

                    {/* PHOTO */}
                    <img
                      src={paymentReservation?.guest.photo}
                      alt="Guest thumbnail"
                      className="w-10 h-10 rounded-full border-1 border-element-border/50"
                    />
                  </div>

                  <div>
                    {/* FIRSTNAME & LASTNAME */}
                    <>
                      <p className="font-semibold text-high-contrast">
                        {`${paymentReservation?.guest.first_name} ${paymentReservation?.guest.last_name}`}
                      </p>

                      {!paymentReservation?.guest.first_name &&
                        !paymentReservation?.guest.last_name && (
                          <div className="flex items-center space-x-2">
                            <EyeOffIcon width={15} height={15} />
                            <p className="text-low-contrast">
                              {t("Booking.Guest.hiddenInformation")}
                            </p>
                          </div>
                        )}
                    </>

                    {/* CITY & COUNTRY */}
                    <div>
                      <p className="font-light text-low-contrast">
                        {paymentReservation?.guest.city !== undefined &&
                          paymentReservation?.guest.city !== null &&
                          `${paymentReservation?.guest.city}, `}
                        {paymentReservation?.guest.country?.name}
                      </p>
                    </div>
                  </div>
                </div>

                {/* RATING */}
                <div>
                  <Rating
                    value={paymentReservation?.guest.average_rating}
                    maxStars={5}
                    loading={loading}
                  />
                </div>
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex flex-col space-y-3">
                <div className="space-y-1">
                  <p className="font-bold text-high-contrast">
                    {t("Payments.PaymentReservationList.reservationPlatform", {
                      name: paymentReservation?.platform.name,
                    })}
                  </p>

                  {paymentReservation?.platform?.reservation_reference && (
                    <p className="text-low-contrast">
                      {t("Payments.PaymentReservationList.reference", {
                        value: "",
                      })}
                      {paymentReservation?.platform.reservation_reference}
                    </p>
                  )}

                  <p className="text-low-contrast">
                    {t("Payments.PaymentReservationList.rangeDate", {
                      startDate: moment(paymentReservation?.checkin).format(
                        "DD MMM YYYY"
                      ),
                      endDate: moment(paymentReservation?.checkout).format(
                        "DD MMM YYYY"
                      ),
                    })}
                  </p>
                </div>

                <div className="flex items-center flex-1 space-x-3">
                  <img
                    src={paymentReservation?.rental?.cover}
                    alt=""
                    className="w-12 h-12 rounded-6px bg-slate-200"
                  />
                  <div className="flex flex-col">
                    <p className="text-base text-wrap w-52 text-high-contrast">
                      {paymentReservation?.rental.name}
                    </p>
                    <p className="text-wrap w-52 text-low-contrast">{`${paymentReservation?.rental.address} ${paymentReservation?.rental.postal_code} ${paymentReservation?.rental.city}`}</p>
                  </div>
                </div>
              </div>

              <div className="flex flex-col items-end flex-1 space-y-2">
                <div className="flex items-center space-x-2">
                  <p className="text-base font-bold text-high-contrast">
                    {t("Global.nights", {
                      count: paymentReservation?.nights_count ?? 0,
                    })}
                  </p>
                  <MoonIcon width={26} height={26} />
                </div>

                <div className="flex items-center space-x-2">
                  <p className="text-base text-low-contrast">
                    {t("Global.adults", {
                      count: paymentReservation?.adults_count,
                    })}
                  </p>
                  <UsersIcon width={20} height={20} />
                </div>

                <div className="flex items-center space-x-2">
                  <p className="text-base text-low-contrast">
                    {t("Global.children", {
                      count: paymentReservation?.children_count,
                    })}
                  </p>
                  <EmojiHappyIcon width={20} height={20} />
                </div>

                <div className="flex items-center space-x-2">
                  <p className="text-base text-low-contrast">
                    {`
                          ${
                            typeof paymentReservation?.payment_schedule
                              ?.total_price_value === "number"
                              ? paymentReservation?.payment_schedule
                                  ?.total_price_value
                              : paymentReservation?.payment_schedule
                                  ?.total_price_value
                          }${hiddenDevice(
                      paymentReservation?.payment_schedule?.total_price_value,
                      t("Global.currencySymbol")
                    )}
                       `}
                  </p>
                  <MoneyIcon width={26} height={26} />
                </div>
              </div>
            </div>

            <ErrorMessage>{apiError}</ErrorMessage>

            {useMode === "paymentReservationDetail" && isMultiplePayments() && (
              <PaymentReservationListModalMultiInfos onBack={onBack} />
            )}

            <Separator />

            {/* PAYMENT INFORMATIONS */}
            {(useMode === "paymentReservationDetail" ||
              !isMultiplePayments()) && (
              <div className="flex flex-col gap-y-5">
                <p className="font-bold text-low-contrast">
                  {t("Payments.PaymentReservationList.infosLabel")}
                </p>

                {paymentReservationDetail?.payment_link !== null && (
                  <div className="flex items-center justify-between">
                    <div className="flex flex-col">
                      <p className="text-low-contrast">
                        {t("Payments.PaymentReservationList.paymentLinkLabel")}
                      </p>
                      {paymentLink ? (
                        <Link
                          to={paymentLink}
                          className="truncate text-active w-72"
                          target="_blank"
                        >
                          {paymentLink}
                        </Link>
                      ) : (
                        <span className="font-semibold">
                          {t("Global.notDefined")}
                        </span>
                      )}
                    </div>
                    <div className="flex flex-col">
                      <Button
                        type="primary"
                        RightIcon={CopyIcon}
                        onClick={() => {
                          navigator.clipboard.writeText(paymentLink);
                        }}
                        disabled={!Boolean(paymentLink)}
                      >
                        {t("Payments.PaymentReservationList.copyPaymentLink")}
                      </Button>
                    </div>
                  </div>
                )}

                <div className="flex items-center justify-between">
                  <div className="flex flex-col items-start flex-1 space-y-0.5">
                    <p className="text-base text-low-contrast">
                      {t("Payments.PaymentReservationList.amountLabel")}
                    </p>
                    <p className="text-base font-bold text-high-contrast">
                      {paymentReservationDetail?.price_value
                        ? `${
                            typeof paymentReservationDetail?.price_value ===
                            "number"
                              ? formatNumberWithSpace(
                                  paymentReservationDetail?.price_value
                                )
                              : paymentReservationDetail?.price_value
                          }${hiddenDevice(
                            paymentReservationDetail?.price_value.toString(),
                            t("Global.currencySymbol")
                          )}`
                        : t("Global.notDefined")}
                    </p>
                  </div>

                  <div className="flex flex-col items-start flex-1">
                    <p className="text-base text-low-contrast">
                      {t("Payments.PaymentReservationList.paymentWaysLabel")}
                    </p>
                    <p className="text-base font-bold text-high-contrast">
                      {paymentReservationDetail?.payment_account_type ===
                      PaymentAccountTypeEnum.BY_PLATFORM
                        ? currentReservation?.platform?.name
                        : t(
                            `Global.PaymentAccounts.${
                              paymentReservationDetail?.payment_account_type ??
                              ""
                            }`
                          )}
                    </p>
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex flex-col items-start flex-1 space-y-0.5">
                    <p className="text-base text-low-contrast">
                      {t("Payments.PaymentReservationList.emailLabel")}
                    </p>

                    <p className="text-base font-semibold text-high-contrast">
                      {paymentReservation?.guest.email
                        ? paymentReservation.guest.email
                        : t("Global.notDefined")}
                    </p>
                  </div>

                  <div className="flex flex-col items-start flex-1">
                    <p className="text-base text-low-contrast">
                      {t("Payments.PaymentReservationList.paidOnLabel")}
                    </p>
                    <p className="text-base font-semibold text-high-contrast">
                      {paymentReservationDetail?.payment_success_date ??
                        t("Global.notDefined")}
                    </p>
                  </div>
                </div>

                {/* CTA TO SET A PAYMENT AS PAID MANUALLY (FOR PAYMENTS BY CASH OR BANK CHECK) */}
                {canPaymentChangeToDone() && (
                  <Button
                    RightIcon={CheckWhiteIcon}
                    onClick={() =>
                      modalChangeToDone.open({
                        reservationId: currentReservation?.id!,
                        paymentId: paymentReservationDetail?.id!,
                      })
                    }
                  >
                    {t("Payments.PaymentReservationList.paymentDone")}
                  </Button>
                )}
              </div>
            )}

            {/* CTA TO PAY BY ANOTHER WAY (FOR PAYMENTS BY STRIPE) */}
            {isStripePayment(PaymentReservationStatusEnum.UNPAID) &&
              (isSinglePayment() ||
                (isMultiplePayments() &&
                  useMode === "paymentReservationDetail")) && (
                <Button
                  type="secondary"
                  onClick={() =>
                    paidByOtherWayModal.open({
                      reservationId: currentReservation?.id!,
                      paymentId: paymentReservationDetail?.id!,
                    })
                  }
                >
                  {t("Payments.PaymentReservationList.paymentDoneByOtherWay")}
                </Button>
              )}

            {/* SINGLE OR MULTI PAYMENT ITEMS */}
            {isMultiplePayments() && useMode === "paymentReservation" && (
              <PaymentReservationListMulti
                paymentReservation={paymentReservation!}
                onTransactionDetailClick={handleTransactionDetailClick}
              />
            )}

            {/* PAYMENT DETAILS */}
            {useMode === "paymentReservation" && (
              <>
                <Separator />

                <PaymentReservationListInfo
                  reservation={currentReservation}
                  paymentReservation={paymentReservation!}
                  paymentReservationDetail={paymentReservationDetail!}
                />
              </>
            )}

            {/* CTA TO CANCEL PAYMENT AND TO SEND PAYMENT LINK MANUALLY */}
            {isStripePayment(PaymentReservationStatusEnum.UNPAID) &&
              (isSinglePayment() ||
                (isMultiplePayments() &&
                  useMode === "paymentReservationDetail")) && (
                <div className="flex flex-row gap-2 pt-2 border-t-1 border-element-border">
                  <Button
                    type="secondary"
                    onClick={() =>
                      cancelModal.open({
                        reservationId: currentReservation?.id!,
                        paymentId: paymentReservationDetail?.id!,
                      })
                    }
                  >
                    {t("Payments.PaymentReservationList.cancelPaymentButton")}
                  </Button>
                  <Button
                    RightIcon={SendWhiteIcon}
                    onClick={() =>
                      handleOpenSendModal(
                        paymentReservationDetail?.payment_position ?? 0,
                        false
                      )
                    }
                  >
                    {t("Payments.PaymentReservationList.sendPaymentButton")}
                  </Button>
                </div>
              )}

            {/* CTA TO REFUND A PAYMENT WHEN IT HAS BEEN PAID */}
            {isStripePayment(PaymentReservationStatusEnum.PAID) &&
              (isSinglePayment() ||
                (isMultiplePayments() &&
                  useMode === "paymentReservationDetail")) && (
                <Button
                  type="secondary"
                  onClick={() =>
                    refundModal.open({
                      reservationId: currentReservation?.id!,
                      paymentLink: paymentReservationDetail?.payment_link!,
                      paymentAccountType:
                        paymentReservationDetail?.payment_account_type!,
                    })
                  }
                >
                  {t("Payments.PaymentReservationList.refund")}
                </Button>
              )}
          </div>
        </div>
      </RightModal>
    </>
  );
};
