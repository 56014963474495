import { t } from "i18next";
import React from "react";
import WifiIcon from "../../../../assets/icons/wifi.svg?react";
import { Card } from "../../../Common/Card/Card";

export const RentalWebsiteServicesCardSkeleton: React.FC<{}> = () => {
  return (
    <Card
      Icon={WifiIcon}
      label={t("Rental.Website.Services.title")}
      anchor="services-card"
      button={{
        skeleton: true,
      }}
    >
      <>
        <div className="flex flex-wrap justify-center animate-pulse">
          <div className="grid grid-cols-8 gap-3">
            {Array.from({ length: 8 }).map((_, index) => (
              <div
                className="flex flex-col justify-center space-y-2 w-[12,5%]"
                key={index}
              >
                <div className="w-full h-48 border rounded border-element-border-light bg-slate-300 aspect-square"></div>

                <p className="w-full h-2 rounded-lg bg-slate-200"></p>
              </div>
            ))}
          </div>
        </div>
      </>
    </Card>
  );
};
