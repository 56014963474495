import React, { useEffect, useState } from "react";
import {
  RentalLightListItemResponse,
  WebsiteResponse,
} from "../../types/GETTypes";
import InfosIcon from "../../assets/icons/information.svg?react";
import { useTranslation } from "react-i18next";
import { TableList } from "../Common/TableList/TableList";
import { WebsiteRentalsHeader } from "./WebsiteRentalsHeader";
import { WebsiteRentalsItem } from "./WebsiteRentalsItem";
import { WebsiteRentalsItemSkeleton } from "./WebsiteRentalsItemSkeleton";
import { useTablePage } from "../../hooks/useTablePage";
import paths from "../../constants/paths";
import { ValueType } from "../../types/commonTypes";
import { Button } from "../Common/Button/Button";
import CloseCircleRedIcon from "../../assets/icons/close-circle-red.svg?react";
import CloseCircleIcon from "../../assets/icons/close-circle.svg?react";
import PlusIcon from "../../assets/icons/plus.svg?react";
import EditIcon from "../../assets/icons/edit.svg?react";
import CheckWhiteIcon from "../../assets/icons/check-white.svg?react";
import CheckCircleGreen from "../../assets/icons/check-circle-green.svg?react";
import { ErrorMessage } from "../Common/ErrorMessage/ErrorMessage";
import { post } from "../../helpers/APIHelper";
import { useNavigate } from "react-router-dom";

export const WebsiteRentals: React.FC<{
  website: WebsiteResponse | undefined;
  onUpdateWebsite: (nextWebsite: WebsiteResponse) => void;
}> = ({ website, onUpdateWebsite }) => {
  const { t } = useTranslation();

  const [currentSelected, setCurrentSelected] = useState<ValueType[]>([]);

  const [mode, setMode] = useState<"edit" | "view">("view");

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const rentalsTable = useTablePage(
    `${import.meta.env.VITE_API_URL}${paths.RENTALS}`,
    "rentals"
  );

  useEffect(() => {
    rentalsTable.fetch({});
  }, []);

  const handleSelectRental = (id: ValueType) => {
    setError(undefined);

    const exists = currentSelected?.findIndex((i) => i === id) > -1;

    if (exists) {
      setCurrentSelected((prev) => [...prev.filter((i) => i !== id)]);
    } else {
      setCurrentSelected((prev) => [...prev, id]);
    }
  };

  useEffect(() => {
    if (website?.rentals) {
      setCurrentSelected(website?.rentals?.map((r) => r.id) ?? []);
    }
  }, [website?.rentals]);

  const handleCancelEdit = () => {
    if (website?.rentals) {
      setCurrentSelected(website?.rentals?.map((r) => r.id) ?? []);
    }

    setMode("view");
  };

  const handleSave = async () => {
    setError(undefined);

    if (!currentSelected || currentSelected?.length === 0) {
      setError(t("Website.Rentals.required"));

      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append("rental_ids", currentSelected.join(","));

    const res = await post(
      `${
        import.meta.env.VITE_API_URL
      }${paths.API.WEBSITE.UPDATE.SELECTED_RENTALS(website?.id!)}`,
      formData
    );

    if (!res?.data?.success) {
      setError(res?.response?.data?.message);
    } else {
      onUpdateWebsite(res.data?.result);
      setMode("view");
    }

    setLoading(false);
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-col w-full rounded-6px bg-element-background p-2 text-sm gap-1">
        <div className="flex gap-2 items-start">
          <InfosIcon className="size-4" />

          <p className="text-low-contrast font-light flex-1">
            {t("Website.Rentals.infos")}
          </p>
        </div>
      </div>

      <div className="flex justify-between items-center">
        <div>
          <p className="font-semibold text-high-contrast mt-4">
            {t("Website.Rentals.rentalsVisible")}
          </p>

          <p className="text-low-contrast font-light my-2">
            {t("Website.Rentals.stripeActive")}
          </p>
        </div>

        <div className="flex gap-3">
          {mode === "view" && (
            <Button
              RightIcon={EditIcon}
              type="secondary"
              onClick={() => setMode("edit")}
              disabled={!website}
            >
              {t("Website.Rentals.edit")}
            </Button>
          )}

          {mode === "edit" && (
            <>
              <Button
                type="secondary"
                onClick={handleCancelEdit}
                disabled={loading}
              >
                {t("Global.cancel")}
              </Button>

              <Button
                onClick={handleSave}
                RightIcon={CheckWhiteIcon}
                loading={loading}
              >
                {t("Global.save")}
              </Button>
            </>
          )}
        </div>
      </div>

      {mode === "view" && <ViewMode website={website} />}

      {mode === "edit" && (
        <>
          <p className="font-light text-low-contrast mt-2">
            {t("Website.Rentals.checkRentals")}
          </p>

          <ErrorMessage>{error}</ErrorMessage>

          <TableList
            Header={WebsiteRentalsHeader}
            Item={WebsiteRentalsItem}
            NoData={null}
            Skeleton={WebsiteRentalsItemSkeleton}
            i18nElement="Global.rentals"
            tablePage={rentalsTable}
            selectedItems={currentSelected}
            onClick={handleSelectRental}
            disabled={false}
          />
        </>
      )}
    </div>
  );
};

const ViewMode: React.FC<{ website: WebsiteResponse | undefined }> = ({
  website,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (!website) return <ViewModeSkeleton />;

  return (
    <table className="w-full border-separate table-auto overflow rounded-8px border-1 border-element-border border-spacing-0">
      <tr className="bg-element-background border-b-1 border-element-border">
        <th className="p-2 text-left">
          <p className="font-semibold text-high-contrast">
            {t("Website.Rentals.rentals")}
          </p>
        </th>

        <th className="p-2 text-right">
          <p className="font-semibold text-high-contrast">
            {t("Website.Rentals.stripeAccount")}
          </p>
        </th>
      </tr>

      {website?.rentals.map((rental) => {
        const handleNavigate = () => {
          navigate(`/rentals/${rental.id}?part=website#rate-card`);
        };

        return (
          <tr className="cursor-pointer">
            <td className="p-2">
              <div className="flex items-center gap-2">
                <img
                  alt="Rental thumbnail"
                  src={rental?.cover!}
                  className="w-12 h-12 rounded-6px border-1 border-element-border-light"
                />

                <div className="flex flex-col">
                  <p className="font-semibold text-high-contrast">
                    {rental?.name}
                  </p>
                  <p className="font-light text-low-contrast">
                    {rental?.address}
                  </p>
                  <p className="font-light text-low-contrast">
                    {rental?.postal_code} {rental?.city} {rental?.country.name}
                  </p>
                </div>
              </div>
            </td>

            <td className="p-2">
              {rental?.payment_policy_stripe_account ? (
                <div className="flex items-center gap-2 justify-end">
                  <p className="text-success-text font-semibold">
                    {rental?.payment_policy_stripe_account}
                  </p>
                  <CheckCircleGreen className="size-4" />

                  <div>
                    <Button
                      onClick={handleNavigate}
                      type="secondary"
                      RightIcon={EditIcon}
                    ></Button>
                  </div>
                </div>
              ) : (
                <div className="flex items-center gap-2 justify-end">
                  <p className="font-semibold text-red-700">
                    {t("Website.Rentals.noStripe")}
                  </p>
                  <CloseCircleRedIcon className="size-4" />

                  <div>
                    <Button
                      onClick={handleNavigate}
                      type="secondary"
                      RightIcon={PlusIcon}
                    ></Button>
                  </div>
                </div>
              )}
            </td>
          </tr>
        );
      })}
    </table>
  );
};

const ViewModeSkeleton: React.FC<{}> = ({}) => {
  const { t } = useTranslation();

  return (
    <table className="w-full border-separate table-auto overflow rounded-8px border-1 border-element-border border-spacing-0 animate-pulse">
      <tr className="bg-element-background border-b-1 border-element-border">
        <th className="p-2 text-left">
          <p className="font-semibold text-high-contrast">
            {t("Website.Rentals.rentals")}
          </p>
        </th>

        <th className="p-2 text-right">
          <p className="font-semibold text-high-contrast">
            {t("Website.Rentals.stripeAccount")}
          </p>
        </th>
      </tr>

      {Array.from({ length: 5 }).map((_d, index) => (
        <tr className="cursor-pointer" key={index}>
          <td className="p-2">
            <div className="flex items-center gap-2">
              <div className="w-12 h-12 rounded-6px border-1 border-element-border-light" />

              <div className="flex flex-col">
                <p className="font-semibold text-high-contrast h-2 bg-slate-300 rounded w-72"></p>
                <p className="font-light text-low-contrast h-2 bg-slate-200 rounded w-96 mt-2"></p>
                <p className="font-light text-low-contrast h-2 bg-slate-200 rounded w-80 mt-1"></p>
              </div>
            </div>
          </td>

          <td className="p-2">
            <div className="flex items-center gap-2 justify-end">
              <p className="font-semibold text-red-700 h-2 bg-slate-300 rounded w-24"></p>
              <CloseCircleIcon className="size-4" />

              <div>
                <Button
                  type="secondary"
                  RightIcon={PlusIcon}
                  disabled={true}
                ></Button>
              </div>
            </div>
          </td>
        </tr>
      ))}
    </table>
  );
};
