/**
 * Component for contain form to add new traveler.
 *
 * @author Elysio
 */

import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { TextInput } from "../../Common/TextInput/TextInput";
import { PhoneInput } from "../../Common/PhoneInput/PhoneInput";
import { InputSelect } from "../../Common/InputSelect/InputSelect";
import { TextAreaInput } from "../../Common/TextAreaInput/TextAreaInput";
import { Button } from "../../Common/Button/Button";
import { RightModal } from "../../Common/RightModal/RightModal";

import TimeZone from "../../../res/timeZone";
import Countries from "../../../constants/countries";
import Currencies from "../../../res/currencies";

import CheckWhite from "../../../assets/icons/check-white.svg?react";
import { useForm } from "react-hook-form";
import paths from "../../../constants/paths";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import { update } from "../../../helpers/APIHelper";
import { UpdateTraveler } from "./UpdateTraveler.type";
import { CheckBox } from "../../Common/CheckBox/CheckBox";
import { ReviewInput } from "../../Common/ReviewInput/ReviewInput";
import { getPlatformIcon } from "../../../helpers/platformHelper";

import Moon from "../../../assets/icons/moon-2.svg?react";
import User from "../../../assets/icons/users.svg?react";
import Smiley from "../../../assets/icons/emoji-happy.svg?react";
import { getFormattedPrice } from "../../../helpers/currencyHelper";
import moment from "moment";

export const TravelerUpdateModal: React.FC<UpdateTraveler> = ({
  isVisible,
  setIsVisible,
  onUpdateTraveler,
  guest,
  setGuest,
}) => {
  const { t } = useTranslation();

  const [currentFile, setCurrentFile] = useState(null);
  const [currentPath, setCurrentPath] = useState(null);

  const [countryValue, setCountryValue] = useState(guest.country_id);
  const [currencyValue, setCurrencyValue] = useState(guest.currency_id);
  const [timezoneValue, setTimezoneValue] = useState(guest.timezone);
  const [languageValue, setLanguageValue] = useState(guest.locale);

  const [isBusiness, setIsBusiness] = useState(guest.guest_type === "business");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Function for format date 2024-03-24 to 24/03/2024
   * @param date
   */
  const getFormattedDate = (date: string): string => {
    return moment(date).format("DD/MM/YYYY");
  };


  const getTotalPriceAllReservations = (): number => {
    let count = 0;
    guest.reservations.map((reservation) => {
      count += reservation.total_guest_price;
    });
    return count;
  };

  /**
   * All values for sending form to API,
   * and handleSubmitForm call API.
   *
   * @author Elysio
   */
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      country_id: guest.country_id,
      locale: guest.locale,
      timezone: guest.timezone,
      currency_id: guest.currency_id,
      first_name: guest.first_name,
      last_name: guest.last_name,
      email: guest.email,
      phone: guest.phone,
      guest_type: guest.guest_type,
      address: guest.address,
      city: guest.city,
      postal_code: guest.postal_code,
      note: guest.note,
      photo: guest.photo,
      business_name: guest.business_name,
      invoice_address: guest.invoice_address,
      siret: guest.siret,
      average_rating: guest.average_rating,
    },
  });

  const updatePicture = (file: Blob | string) => {
    setCurrentFile(file);
    setCurrentPath(URL.createObjectURL(file[0]));
  };

  const handleSubmitForm = async (formData: any) => {
    console.log(guest);
    console.log(formData);
    setIsLoading(true);
    let request = await update(
      `${import.meta.env.VITE_API_URL}${paths.API.CRM_GUEST_MODULE.UPDATE(guest.id)}`,
      {
        ...formData,
      },
      {
        headers: { "Content-Type": "application/json" },
      },
    );

    if (request.status === 200) {
      onUpdateTraveler(request.data.result.id);
      handleCloseModalAddTraveler();
      reset();
      setError(null);
    } else {
      setError(request.response.data.message);
    }

    setIsLoading(false);
  };

  /**
   * Function to close modal form when button "Annuler" is
   * clicked.
   *
   * @author Elysio
   */
  const handleCloseModalAddTraveler = () => {
    setIsVisible(false);
    setGuest(null);
  };

  const handleCheckbox = (isBusiness: boolean) => {
    if (isBusiness) {
      setIsBusiness(true);
      setValue("guest_type", "business");
      setValue("business_name", guest.business_name);
      setValue("invoice_address", guest.invoice_address);
    } else {
      setIsBusiness(false);
      setValue("guest_type", "private");
      setValue("address", guest.address);
      setValue("invoice_address", guest.invoice_address);
    }
  };

  return (
    <>
      <RightModal
        title={t("Booking.Guest.updateTraveler")}
        isVisible={isVisible}
        onClose={handleCloseModalAddTraveler}
      >
        <form
          onSubmit={handleSubmit(handleSubmitForm)}
          className="flex flex-col text-high-contrast font-normal w-full"
        >
          <div
            className={
              "text-gray-500 mt-2 mb-6 font-bold text-xs flex justify-between items-center"
            }
          >
            <p>{t("Booking.Guest.profilLabel")}</p>
            <p>
              {t("Booking.Guest.accountAdd")} {guest.created_at}
            </p>
          </div>
          <div className={"w-full flex-1 py-2 rounded"}>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <div className={"flex justify-between items-center gap-4"}>
              <div className={"flex gap-2 flex-col w-full items-center"}>
                <div className={"w-full"}>
                  <TextInput
                    label={t("Booking.Guest.firstNameLabel")}
                    placeholder={"Louis"}
                    register={register("first_name", {
                      required: {
                        value: false,
                        message: t("RegisterMember.Infos.firstNameRequired"),
                      },
                    })}
                    error={errors.first_name && errors.first_name?.message}
                    required={false}
                  />
                </div>
                <div className={"w-full"}>
                  <TextInput
                    label={t("Booking.Guest.lastNameLabel")}
                    placeholder={"Dupont"}
                    register={register("last_name", {
                      required: {
                        value: false,
                        message: t("RegisterMember.Infos.lastNameRequired"),
                      },
                    })}
                    error={errors.last_name && errors.last_name?.message}
                    required={false}
                  />
                </div>
              </div>
            </div>
            <div className={"flex justify-between gap-4 mt-4"}>
              <TextInput
                label={t("Booking.Guest.emailLabel")}
                placeholder={"louis.dupont@mail.com"}
                register={register("email", {
                  required: {
                    value: false,
                    message: t("Recover.emailRequired"),
                  },
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, // Regex pour valider l'email
                    message: t("Recover.emailInvalid"),
                  },
                })}
                error={errors.email && errors.email?.message}
                required={false}
              />
              <PhoneInput
                label={t("Booking.Guest.phoneNumberLabel")}
                placeholder={"0 00 00 00 00"}
                onChangeText={(e) => {
                  setValue("phone", e);
                }}
                error={errors.phone && errors.phone?.message}
                required={false}
                value={getValues("phone")}
                {...register("phone", {
                  required: {
                    value: false,
                    message: t("Recover.phoneRequired"),
                  },
                  pattern: {
                    value: /^\+?[\d\s-]{7,15}$/,
                    message: t("Recover.phoneInvalid"),
                  },
                })}
              />
            </div>
            <hr className={"mt-4 mb-2 border-gray-300"} />
            <div className="flex flex-col gap-4">
              <p className={"text-gray-500 mt-2  font-bold text-xs"}>
                {t("Booking.Guest.travelerType")}
              </p>
              <div className={"flex justify-between gap-4 items-center"}>
                <div className={"flex flex-col gap-2 w-1/2"}>
                  <div className="flex gap-2 items-center">
                    <div className="flex gap-2 items-center">
                      <CheckBox
                        isRadio={true}
                        value={isBusiness}
                        onChange={(e) => handleCheckbox(e)}
                      />
                    </div>
                    <label
                      htmlFor={"business"}
                      className={"font-bold text-gray-500 cursor-pointer"}
                      onClick={() => handleCheckbox(!isBusiness)}
                    >
                      {t("Booking.Guest.businessType")}
                    </label>
                  </div>

                  {isBusiness ? (
                    <>
                      <TextInput
                        label={t("Booking.Guest.businessName")}
                        placeholder={"SAS Immozed"}
                        register={register("business_name", {
                          required: {
                            value: false,
                            message: t("Booking.Guest.businessNameRequired"),
                          },
                        })}
                        error={
                          errors.business_name && errors.business_name?.message
                        }
                        required={false}
                      />
                      <TextInput
                        label={t("Booking.Guest.invoiceAddress")}
                        placeholder={"3 rue de Source"}
                        register={register("invoice_address", {
                          required: {
                            value: false,
                            message: t("Booking.Guest.invoiceAddressRequired"),
                          },
                        })}
                        error={
                          errors.invoice_address &&
                          errors.invoice_address?.message
                        }
                        required={false}
                      />
                      <TextInput
                        label={t("Booking.Guest.postalCodeLabel")}
                        placeholder={"75000"}
                        register={register("postal_code", {
                          required: {
                            value: false,
                            message: t("Booking.Guest.zipRequired"),
                          },
                          pattern: {
                            value: /^[0-9]{4,10}$/,
                            message: t("Booking.Guest.zipCodeInvalid"),
                          },
                        })}
                        error={
                          errors.postal_code && errors.postal_code?.message
                        }
                        required={false}
                      />
                    </>
                  ) : (
                    <>
                      <TextInput
                        label={t("Booking.Guest.addressLabel")}
                        placeholder={"3 rue de Source"}
                        register={register("address", {
                          required: {
                            value: false,
                            message: t("Booking.Guest.addressRequired"),
                          },
                        })}
                        error={errors.address && errors.address?.message}
                        required={false}
                      />
                      <TextInput
                        label={t("Booking.Guest.postalCodeLabel")}
                        placeholder={"75000"}
                        register={register("postal_code", {
                          required: {
                            value: false,
                            message: t("Booking.Guest.zipRequired"),
                          },
                          pattern: {
                            value: /^[0-9]{4,10}$/,
                            message: t("Booking.Guest.zipCodeInvalid"),
                          },
                        })}
                        error={
                          errors.postal_code && errors.postal_code?.message
                        }
                        required={false}
                      />
                    </>
                  )}
                </div>
                <div className={"flex flex-col gap-2 w-1/2 "}>
                  <div className="flex gap-2 items-center translate-y-0.5">
                    <div className="grid place-items-center relative">
                      <div className="flex gap-2 items-center">
                        <CheckBox
                          isRadio={true}
                          value={!isBusiness}
                          register={register("guest_type")}
                          onChange={(e) => handleCheckbox(!e)}
                        />
                      </div>
                    </div>
                    <label
                      htmlFor={"private"}
                      className={"font-bold text-gray-500 cursor-pointer"}
                      onClick={() => handleCheckbox(!isBusiness)}
                    >
                      {t("Booking.Guest.guestType")}
                    </label>
                  </div>
                  {isBusiness && (
                    <>
                      <TextInput
                        label={t("Booking.Guest.siret")}
                        placeholder={"FR7645785895"}
                        register={register("siret", {
                          required: {
                            value: false,
                            message: t("Booking.Guest.siretRequired"),
                          },
                        })}
                        error={errors.siret && errors.siret?.message}
                        required={false}
                      />
                    </>
                  )}
                  <TextInput
                    label={t("Booking.Guest.cityLabel")}
                    placeholder={"Paris"}
                    register={register("city", {
                      required: {
                        value: false,
                        message: t("Booking.Guest.cityRequired"),
                      },
                    })}
                    error={errors.city && errors.city?.message}
                    required={false}
                  />
                  <InputSelect
                    label={t("Profil.country")}
                    items={Countries.map(({ value, label, Icon }) => ({
                      value,
                      label,
                      Icon,
                    }))}
                    error={errors.country_id && errors.country_id?.message}
                    defaultValue={getValues("country_id")}
                    register={register("country_id", {
                      required: {
                        value: false,
                        message: t("Booking.Guest.countryRequired"),
                      },
                      onChange: (e) => {
                        setCountryValue(e.target.value);
                      },
                    })}
                    selectedValue={countryValue}
                  />
                </div>
              </div>
            </div>
            <hr className={"mt-4 mb-4 border-gray-300"} />
            <p className={"text-gray-500 mt-2  font-bold text-xs"}>
              {t("Global.info")}
            </p>
            <div className={"flex justify-between items-center gap-4 mt-4"}>
              <div className={"w-1/2"}>
                <InputSelect
                  label={t("Profil.timeZone")}
                  items={TimeZone.map(({ value, label, Icon }) => ({
                    value: value,
                    label: label,
                  }))}
                  error={errors.timezone && errors.timezone?.message}
                  defaultValue={getValues("timezone")}
                  selectedValue={timezoneValue}
                  register={register("timezone", {
                    required: {
                      value: false,
                      message: t("Booking.Guest.timezoneRequired"),
                    },
                    onChange: (e) => {
                      setTimezoneValue(e.target.value);
                    },
                  })}
                  required={false}
                />
              </div>
              <div className={"w-1/2"}>
                <InputSelect
                  label={t("Booking.Guest.languageCommunication")}
                  items={Countries.map(({ value, label, Icon }) => ({
                    value: label,
                    label: label,
                    Icon: Icon,
                  }))}
                  error={errors.locale && errors.locale?.message}
                  defaultValue={getValues("locale")}
                  register={register("locale", {
                    required: {
                      value: false,
                      message: t("Booking.Guest.languageRequired"),
                    },
                    onChange: (e) => {
                      setLanguageValue(e.target.value);
                    },
                  })}
                  selectedValue={languageValue}
                />
              </div>
            </div>
            <div className={"w-full mt-2"}>
              <InputSelect
                label={t("Rental.Pricing.PricingRules.currencyTitle")}
                items={Currencies.map(({ code, name, symbol, id }) => ({
                  value: id,
                  label: name + " (" + symbol + ")",
                }))}
                register={register("currency_id", {
                  required: {
                    value: false,
                    message: t("Booking.Guest.currencyRequired"),
                  },
                  onChange: (e) => {
                    setCurrencyValue(e.target.value);
                  },
                })}
                defaultValue={getValues("currency_id")}
                error={errors.currency_id && errors.currency_id?.message}
                required={false}
                onSelect={(e) => {
                  setCurrencyValue(e);
                }}
                selectedValue={currencyValue}
              />
              <ReviewInput
                label={t("Booking.Guest.review")}
                rate={guest.average_rating}
                classNameDiv={"mt-4"}
                onChangeRate={(rate: number) => {
                  setValue("average_rating", rate);
                }}
              />
            </div>
            <hr className={"mt-6 mb-4 border-gray-300"} />
            <div>
              <TextAreaInput
                register={register("note", {})}
                error={errors.note && errors.note?.message}
                label={t("Booking.Guest.detailTraveler")}
                rows={4}
              />
            </div>
          </div>
          <hr className={"mt-6 mb-2 border-gray-300"} />
          <div>
            <p className={"text-gray-500 mt-2 font-bold text-xs"}>
              {t("Global.stays")}
            </p>
            <div className={"flex flex-col mt-2"}>
              {guest.reservations.length <= 0 && (
                <p>{t("Global.noReservations")}</p>
              )}
              {guest.reservations.map((reservation) => (
                <>
                  <div
                    className={
                      "flex justify-between items-center py-2 border-b border-gray-300 gap-2"
                    }
                  >
                    <div className={"relative flex items-center gap-4"}>
                      <img
                          src={reservation?.rental?.details?.photos?.cover}
                          className={"rounded-xl w-16 h-10"}
                      />
                      <div className={"absolute left-14"}>
                        {getPlatformIcon(reservation.platform, "small")}
                      </div>
                      <p
                          className={
                            "text-xxs font-bold break-words overflow-hidden text-ellipsis line-clamp-2 w-[150px]"
                          }
                      >
                        {reservation.rental.details.informations.name}
                      </p>

                    </div>
                    <div
                        className={"text-gray-500 text-xxs cursor-pointer"}
                        onClick={() => {
                          window.location.href =
                          paths.RESERVATIONS + "/" + reservation.id;
                      }}
                    >
                      {t("Global.checkinToCheckout", {
                        checkin: getFormattedDate(reservation.checkin),
                        checkout: getFormattedDate(reservation.checkout),
                      })}
                    </div>
                    <div className={"flex flex-col w-[190px]"}>
                      <div className={"flex items-center gap-2"}>
                        <Moon className={"w-5 h-5"} />
                        <p className={"text-xxs font-bold text-gray-500"}>
                          {t(
                            "Global.night_" +
                              (reservation.nights_count > 1 ? "other" : "one"),
                            {
                              count: reservation.nights_count,
                            },
                          )}
                        </p>
                      </div>
                      <div className={"flex items-center gap-2"}>
                        <User className={"w-5 h-5"} />
                        <p className={"text-xxs font-bold text-gray-500"}>
                          {t(
                            "Global.adults_" +
                              (reservation.adults_count > 1 ? "other" : "one"),
                            {
                              count: reservation.adults_count,
                            },
                          )}
                        </p>
                      </div>
                      <div className={"flex items-center gap-2"}>
                        <Smiley className={"w-5 h-5"} />
                        <p className={"text-xxs font-bold text-gray-500"}>
                          {t(
                            "Global.children_" +
                              (reservation.children_count > 1
                                ? "other"
                                : "one"),
                            {
                              count: reservation.children_count,
                            },
                          )}
                        </p>
                      </div>
                    </div>
                    <div className={"font-bold text-md"}>
                      {getFormattedPrice({
                        price: reservation.total_guest_price,
                      })}
                    </div>
                  </div>
                </>
              ))}
              <div
                className={
                  "flex justify-between items-center text-lg font-bold mt-4 mb-4"
                }
              >
                <p>{t("Global.total")}</p>
                <p>
                  {getFormattedPrice({
                    price: getTotalPriceAllReservations(),
                  })}
                </p>
              </div>
            </div>
          </div>
          <div className={"flex justify-between gap-4 pb-4"}>
            <Button type={"secondary"} onClick={handleCloseModalAddTraveler}>
              {t("Global.cancel")}
            </Button>
            <Button type={"primary"} RightIcon={CheckWhite} loading={isLoading}>
              {t("Booking.Guest.updateTravelerConfirm")}
            </Button>
          </div>
        </form>
      </RightModal>
    </>
  );
};
