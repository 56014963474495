import React from "react";
import {cn} from "../../../../../helpers/classHelper";
import {ClassNamesType} from "../../../../../types/commonTypes";
import useGuestWebsiteStore from "../../../../../stores/guestWebsiteStore/useGuestWebsiteStore";

const TitleBlockComponent = ({
  title,
  subtitle,
  classNames,
}: {
  title: string;
  subtitle?: string;
  classNames?: ClassNamesType;
}) => {
  const {dataGuestWebsite} = useGuestWebsiteStore();
  const subtitleParts = subtitle?.split("<br/>");
  return (
    <div className={cn("", classNames?.divLabel)}>
      <h3
        className={cn("text-xxl font-bold mb-4", classNames?.title)}
        style={{color: dataGuestWebsite?.styles.color_text}}
      >
        {title}
      </h3>
      <p
        className={cn(
          "text-base text-low-contrast space-y-2",
          classNames?.description
        )}
      >
        {subtitleParts?.map((part, index) => (
          <p key={index}>{part.trim()}</p>
        ))}
      </p>
    </div>
  );
};

export default TitleBlockComponent;
