import { t } from "i18next";
import React from "react";
import ArrowDownIcon from "../../../assets/icons/small-arrow-down.svg?react";
import { Button } from "../../Common/Button/Button";

export const ProgressionMultiDepositSettingsSkeleton: React.FC = () => {
  return (
    <div className="flex flex-col justify-between gap-y-6">
      <div className="flex flex-col overflow-y-auto border rounded-lg border-element-border">
        <div className="flex items-center p-3 space-x-2 bg-element-background">
          <span className="font-bold text-high-contrast">
            {t("Progression.MultiDepositSettings.title")}
          </span>
          <ArrowDownIcon />
        </div>

        <div className="flex flex-col animate-pulse">
          {Array.from({ length: 6 }).map((_, index) => (
            <div
              className="flex items-center justify-between h-full p-4 gap-x-3"
              key={index}
            >
              <div className="flex items-start h-full space-x-3">
                <div className="w-24 rounded-lg h-14 bg-slate-300"></div>

                <div className="flex flex-col space-y-1">
                  <p className="w-24 h-3 rounded-lg bg-slate-300"></p>
                  <p className="w-16 h-2 rounded-lg bg-slate-200"></p>
                </div>
              </div>

              <div className="flex flex-col items-start space-y-2">
                <div className="w-20 h-3 rounded-lg bg-slate-300"></div>
                <div className="rounded-lg h-9 w-36 bg-slate-200"></div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <Button type="primary" disabled={true}>
        {t("Global.next")}
      </Button>
    </div>
  );
};
