import React, {useEffect, useRef, useState} from "react";
import {UserResponse} from "../../../types/GETTypes";
import {Button} from "../../Common/Button/Button";
import FilterIcon from "../../../assets/icons/filter_.svg?react";
import FilterActiveIcon from "../../../assets/icons/filter-active.svg?react";
import ChevronDownIcon from "../../../assets/icons/chevron-down.svg?react";
import ChevronDownActiveIcon from "../../../assets/icons/chevron-down-active.svg?react";
import CheckWhiteIcon from "../../../assets/icons/check-white.svg?react";
import {Trans} from "react-i18next";
import {Separator} from "../../Common/Separator/Separator";
import {WorkspaceItem} from "./WorkspaceItem";
import {ValueType} from "../../../types/commonTypes";
import {useOutsideDetector} from "../../../hooks/useOutsideDetector";
import {Loader} from "../../Common/Loader/Loader";
import {cn} from "../../../helpers/classHelper";
import {post} from "../../../helpers/APIHelper";
import paths from "../../../constants/paths";
import {ErrorMessage} from "../../Common/ErrorMessage/ErrorMessage";

export const WorkspaceListSelect: React.FC<{
  user: UserResponse | undefined | null;
}> = ({ user }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<ValueType[]>([]);
  const ref = useRef(null);
  useOutsideDetector(ref, () => setIsOpen(false));

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (user) {
      setSelectedItems(user.current_workspaces_id);
    }
  }, [user]);

  const handleWorkspaceClick = (nextId: ValueType) => {
    const isAlreadySelected = selectedItems.includes(nextId);
    let nextSelected = [];
    if (isAlreadySelected) {
      nextSelected = selectedItems?.filter((id) => id !== nextId);
    } else {
      nextSelected = [...selectedItems, nextId];
    }
    nextSelected = nextSelected.filter((s) => s !== -1);
    setSelectedItems(nextSelected);
  };

  const isFiltered = selectedItems?.length !== user?.workspaces?.length;

  //if (isMainAccount(user)) return null;

  const handleSave = async () => {
    setLoading(true);
    setError(null);

    const res = await post(
      `${import.meta.env.VITE_API_URL}${
        paths.API.OPERATIONNAL_MANAGEMENT.CHANGE_WORKSPACE
      }`,
      {
        workspaces_id: selectedItems,
      }
    );

    if (res.data?.success) {
      self.location.reload();
    } else {
      setError(res?.response?.data?.message);
    }

    setLoading(false);
  };

  if (!user) return <Loader />;

  return (
    <div className="flex flex-col" ref={ref}>
      {
        user?.workspaces?.length>1 && (
              <Button
                  type="secondary"
                  LeftIcon={isFiltered ? FilterActiveIcon : FilterIcon}
                  RightIcon={isFiltered ? ChevronDownActiveIcon : ChevronDownIcon}
                  borderButton={
                    isFiltered ? "border-1 border-active" : "border-1 border-black/12"
                  }
                  onClick={() => setIsOpen(!isOpen)}
              >
                  <span className={cn(isFiltered ? "text-active" : "text-high-contrast")}>
                    {!isFiltered && user?.workspaces?.length === 1 ? (
                        <Trans i18nKey="Management.Workspace.myWorkpace" />
                    ) : (
                        <Trans
                            i18nKey="Management.Workspace.filter"
                            count={selectedItems?.length}
                        />
                    )}

                    {isFiltered && selectedItems?.length === 1 && (
                        <span>
                        {" "}
                          -{" "}
                          {user?.workspaces?.find((ws) => selectedItems[0] === ws.id)?.name}
                      </span>
                    )}
                  </span>
          </Button>
          )
      }

      {isOpen && (
        <div className="relative">
          <div className="absolute right-0 z-50 bg-white p-2 rounded-6px border-element-border min-w-52 shadow-md">
            <p className="text-sm font-light text-low-contrast">
              <Trans i18nKey="Management.Workspace.choose" />
            </p>
            <Separator />
            <div className="flex flex-col gap-1">
              {user?.workspaces?.map((w) => (
                <WorkspaceItem
                  key={w.id}
                  workspace={w}
                  isSelected={selectedItems?.includes(w.id)}
                  userId={user.id}
                  onClick={handleWorkspaceClick}
                />
              ))}

              <ErrorMessage>{error}</ErrorMessage>

              <div className="h-2" />

              <Button
                RightIcon={CheckWhiteIcon}
                onClick={handleSave}
                loading={loading}
              >
                <Trans i18nKey="Management.Workspace.save" />
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
