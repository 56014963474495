import React, {useState} from "react";
import {CenterModal} from "../Common/CenterModal/CenterModal";
import {useTranslation} from "react-i18next";
import {Button} from "../Common/Button/Button";
import {post} from "../../helpers/APIHelper";
import paths from "../../constants/paths";
import {ErrorMessage} from "../Common/ErrorMessage/ErrorMessage";
import {NotificationListItemResponse} from "../../types/GETTypes";

export const AutoMessageDuplicateModal: React.FC<{
  data: any;
  isVisible: boolean;
  onSuccess: () => void;
  onClose: () => void;
}> = ({ data, isVisible, onSuccess, onClose }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const messageAuto: NotificationListItemResponse = data;

  const handleRemove = async () => {
    setLoading(true);
    setError(null);

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.AUTO_MESSAGES}/${messageAuto.id}/duplicate`,
    );
    if (res?.data?.success) onSuccess();
    else setError(res?.response?.data?.message);
    setLoading(false);
  };

  const handleClose = () => {
    if (!loading) {
      onClose();
      setError(null);
    }
  };

  return (
    <CenterModal
      isVisible={isVisible}
      onClose={handleClose}
      title={t("AutoMessageList.DuplicateModal.title")}
    >
      <div>
        <p className="text-sm font-light text-low-contrast mb-4">
          {t("AutoMessageList.DuplicateModal.content")}
        </p>

        <ErrorMessage>{error}</ErrorMessage>

        <div className="flex gap-3">
          <Button type="secondary" onClick={handleClose} disabled={loading}>
            {t("Global.cancel")}
          </Button>
          <Button onClick={handleRemove} loading={loading}>
            {t("AutoMessageList.DuplicateModal.button")}
          </Button>
        </div>
      </div>
    </CenterModal>
  );
};
