/**
 * Component to display multiple images in full screen
 * with the ability to navigate between them and close the full-screen mode.
 * To be used for image display: when clicking on an image,
 * this component is triggered to show it in full screen.
 */
import React, { useEffect, useState } from "react";
import CloseIcon from "../../../assets/icons/close-white.svg?react";
import LeftArrowIcon from "../../../assets/icons/arrow-white-left.svg?react";
import RightArrowIcon from "../../../assets/icons/arrow-white-right.svg?react";
import { cn } from "../../../helpers/classHelper";
import { PreviewImageProps } from "./PreviewImage.type";

export const PreviewImage: React.FC<PreviewImageProps> = ({
  imageUrl,
  imageModal,
  altTexts = [],
  altText ="",
  isMulti = false,
  imgs = [],
  onClose = () => {},
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const imagesToUse = isMulti ? imgs : imageUrl;

  useEffect(() => {
    const body: HTMLElement | null = document.querySelector("body");
    if (body != null) {
      body.style.overflow = imageModal.isVisible ? "hidden" : "visible";
    }

    if (imageModal.isVisible) {
      setIsVisible(true);
    } else {
      setTimeout(() => setIsVisible(false), 300);
    }
  }, [imageModal.isVisible]);

  const handleOnCloseImage = () => {
    if (typeof onClose === "function") {
      onClose();
    }
    imageModal.close();
  };

  const handleNextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % imagesToUse.length);
  };

  const handlePrevImage = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + imagesToUse.length) % imagesToUse.length,
    );
  };

  return (
    <div
      className={cn(
        "!ml-0 !mr-0 fixed inset-0 z-[100] flex items-center justify-center bg-high-contrast bg-opacity-80 transition-opacity duration-300",
        {
          "opacity-100": imageModal.isVisible,
          "opacity-0 pointer-events-none": !imageModal.isVisible,
          visible: isVisible,
          invisible: !isVisible,
        },
      )}
      onClick={handleOnCloseImage}
    >
      <div className="relative w-full h-full">
        <CloseIcon
          className="absolute cursor-pointer right-5 top-5 size-8"
          onClick={handleOnCloseImage}
        />

        {isMulti && (
          <>
            <div
              onClick={(e) => {
                e.stopPropagation();
                handlePrevImage();
              }}
              className={
                "p-6 bg-red-600 absolute cursor-pointer left-40 top-1/2 transform -translate-y-1/2 size-8 rounded-full transition hover:bg-red-700"
              }
            >
              <LeftArrowIcon className="z-50 align-self-center absolute top-4 left-3.5 -translate-y-[2px]" />
            </div>

            <div
              onClick={(e) => {
                e.stopPropagation();
                handleNextImage();
              }}
              className={
                "p-6 bg-red-600 absolute cursor-pointer right-40 top-1/2 transform -translate-y-1/2 size-8 rounded-full transition hover:bg-red-700"
              }
            >
              <RightArrowIcon className="z-50 align-self-center absolute top-4 left-4 translate-y-[2.5px]" />
            </div>
          </>
        )}

        {isMulti ? (
          <img
            src={imgs[currentIndex]}
            className="absolute object-cover object-center w-4/6 max-h-full scale-100 -translate-x-1/2 -translate-y-1/2 rounded-lg cursor-pointer h-5/6 top-1/2 left-1/2"
          />
        ) : (
          <img
            src={imageUrl}
            className="absolute object-cover object-center w-4/6 max-h-full scale-100 -translate-x-1/2 -translate-y-1/2 rounded-lg cursor-pointer h-5/6 top-1/2 left-1/2"
            alt={altText}
          />
        )}
      </div>
    </div>
  );
};
