import { ValueType } from "../types/commonTypes";
import {
  OperationalManagementRolePermissionNameResponse,
  UserResponse,
} from "../types/GETTypes";

export const isMainAccount = (user: UserResponse | undefined | null) => {
  if (!user) return false;

  return user?.type === "owner";
};

export const hasPermissionWithUser = (
  user: UserResponse | null | undefined,
  name: OperationalManagementRolePermissionNameResponse
) => {
  if (isMainAccount(user)) return true;
  
  if (!user) return false;
  
  let result = false;
  
  user?.all_postes?.forEach((p) => {
    if (p.permissions_selected?.findIndex((p) => p.name === name) !== -1){
      result = true;
    }
  });

  return result;
};

export const formatWorkspaceParam = (
  selectedWorkspaces: ValueType[] | null
) => {
  if (selectedWorkspaces === null) return undefined;

  if (selectedWorkspaces?.length === 2) return undefined;

  return selectedWorkspaces?.join(",");
};

export const getCurrentCanEdit = (params: {
  user: UserResponse | undefined | null;
  workspaceOwner: ValueType | undefined;
}) => {
  if (!params.user) return false;
  if (!params.workspaceOwner) return false;

  let result = true;

  params.user?.all_postes?.forEach((p) => {
    if (p.workspace_owner === params.workspaceOwner) {
      result = !p?.all_rights?.includes("no_edit");
      if (result === undefined) result = true;
    }
  });

  return result;
};

export const getCurrentHidePrice = (params: {
  user: UserResponse | undefined | null;
  workspaceOwner: ValueType | undefined;
}) => {
  if (!params.user) return false;
  if (!params.workspaceOwner) return false;

  let result = false;

  params.user?.all_postes?.forEach((p) => {
    if (p.workspace_owner === params.workspaceOwner) {
      result = p?.all_rights?.includes("hide_price");
      if (result === undefined) result = false;
    }
  });

  return result;
};
