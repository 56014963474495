import React, { useEffect, useState } from "react";

import PolicyPlatformCard from "../PolicyPlatformCard";
import HeaderPolicy from "../HeaderPolicy";
import BasicItemPolicy from "../BasicItemPolicy";
import BookingIcon from "../../../../assets/icons/booking.svg?react";
import ItemPolicyWithBadgeLayout from "../ItemPolicyWithBadge";
import { usePoliciesPlatforms } from "../../../../hooks/api/paymentPolicies";
import {
  BookingPolicyResponse,
  RentalResponse,
} from "../../../../types/GETTypes";
import { useTranslation } from "react-i18next";
import { FlexCenterBetween } from "../../../Common/FlexComponents/FlexCenterBetween";
import { FlexCol } from "../../../Common/FlexComponents/FlexCol";
import { getFormattedPrice } from "../../../../helpers/currencyHelper";

export interface BookingPlatformPoliciesProps {
  rental: RentalResponse;
  reloadRental?: () => void;
}

export default function BookingPlatformPolicies({
  rental,
  reloadRental,
}: BookingPlatformPoliciesProps) {
  const { t } = useTranslation();
  const { refreshBooking } = usePoliciesPlatforms();

  const [policy, setPolicy] = useState<BookingPolicyResponse | null>();
  const [loading, setLoading] = useState(false);

  const refreshBookingPolicies = () => {
    setLoading(true);
    refreshBooking(rental.id).then(() => {
      if (typeof reloadRental === "function") {
        reloadRental();
      }
      setLoading(false);
    });
  };

  const isByPlatformCaution = (): boolean => {
    return policy?.deposit === "by_platform";
  };

  const isByPlatformPayment = (): boolean => {
    return policy?.payment === "by_platform";
  };

  useEffect(() => {
    setPolicy(rental.tarification.payment_policies.booking);
  }, []);

  const bookingIsConnected = (): boolean => {
    return rental.details.external_connexions.platform_booking?.enabled === 1;
  };

  if (!bookingIsConnected()) return null;

  return (
    <PolicyPlatformCard>
      <HeaderPolicy
        Icon={BookingIcon}
        title={t("Rental.Policies.Booking.title")}
        edit={false}
        refreshButton={true}
        onRefresh={refreshBookingPolicies}
        refreshLoading={loading}
      />
      <BasicItemPolicy
        title={t("Rental.Policies.Booking.payment")}
        text={
          isByPlatformPayment()
            ? t("Rental.Policies.Booking.managedAny")
            : t("Rental.Policies.Booking.managedBySuperhoteAny")
        }
      />

      <ItemPolicyWithBadgeLayout title={t("Rental.Policies.Booking.tarif")}>
        <div className={"border rounded border-gray-300 bg-gray-50 p-3"}>
          <FlexCenterBetween>
            <h1 className={"font-bold"}>{policy?.rate_plan.name}</h1>
            <span className={"uppercase text-gray-400 font-bold"}>
              ID {policy?.rate_plan.id}
            </span>
          </FlexCenterBetween>

          <FlexCol>
            <p className={"mt-2 text-gray-500"}>
              {t("Rental.Policies.Booking.cancellationTitle")}
            </p>
            <h2 className={"font-bold"}>
              {policy?.rate_plan.cancelation_policy_title}
            </h2>
            <p className={"text-gray-500"}>
              {policy?.rate_plan.prepayment_policy_description}
            </p>
            <h2 className={"font-bold"}>
              {t("Rental.Policies.Booking.cancellationDateLimit")}
              {policy?.rate_plan.cancelation_deadline_in_days}
              {policy?.rate_plan.cancelation_deadline_in_days !== undefined &&
              policy?.rate_plan.cancelation_deadline_in_days > 1
                ? " jours"
                : " jour"}
            </h2>
            <p className={"text-gray-500"}>
              {policy?.rate_plan.cancelation_policy_description
                .split("•")
                .map((line, index) => (
                  <div key={index}>{index > 0 ? "•" + line : ""}</div>
                ))}
            </p>
          </FlexCol>
        </div>
      </ItemPolicyWithBadgeLayout>

      {policy?.deposit ? (
        <BasicItemPolicy
          title={t("Rental.Policies.Booking.caution")}
          text={
            isByPlatformCaution()
              ? t("Rental.Policies.Booking.managed", {
                  amount: getFormattedPrice({
                    price: Number(policy?.deposit_amount),
                  }),
                })
              : t("Rental.Policies.Booking.managedBySuperhote", {
                  amount: getFormattedPrice({
                    price: Number(policy?.deposit_amount),
                  }),
                })
          }
        />
      ) : (
        <BasicItemPolicy
          title={t("Rental.Policies.Booking.anyCaution")}
          text={t("Rental.Policies.Booking.anyCautionDamage")}
        />
      )}
    </PolicyPlatformCard>
  );
}
