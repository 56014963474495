import React, {useEffect, useState} from "react";
import {ProgressionStep} from "../../../pages/Progression/ProgressionStep";
import {useTranslation} from "react-i18next";
import {Button} from "../../Common/Button/Button";
import {ProgressionStepStripeConnectProps,} from "../Progression.type";
import CheckIcon from "../../../assets/icons/plus-white.svg?react";
import {ValueType} from "../../../types/commonTypes";
import {InputSelect} from "../../Common/InputSelect/InputSelect";
import {PaymentAccountListItemResponse} from "../../../types/GETTypes";
import {get} from "../../../helpers/APIHelper";
import paths from "../../../constants/paths";


export const ProgressionConnectStripe: React.FC<ProgressionStepStripeConnectProps> = ({
  status,
  accounts,
  visible,
  number,
  error,
  loading,
  currentProgression,
  onStripeClick,
  onNextClick
}) => {
  const { t } = useTranslation();
  const [allAccounts, setAllAccounts] = useState<{label: string; value: ValueType}[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<ValueType>(allAccounts[allAccounts.length - 1]?.value);

  useEffect(() => {
    if (accounts?.length) {
      const formattedAccounts = accounts.map((account: PaymentAccountListItemResponse) => ({
        value: account?.id,
        label: account?.name,
      }));

      setAllAccounts(formattedAccounts);
      setSelectedAccount(formattedAccounts[formattedAccounts.length - 1]?.value);
    }
  }, [accounts]);

  const handleNextClick = async () => {
    const account  = accounts.find((account: any) => account.id === selectedAccount);
    console.log("handleNextClick selectedAccount", account);

    if(!account) return;
    const res = await get(
        `${import.meta.env.VITE_API_URL}${paths.API.PAYMENTS.CHANGE_DEFAULT}/${account.id}`,
    );

    if (res?.data?.success) {
      onNextClick();
    } else {
        console.log("handleNextClick error", res);
    }

  }

  if (!visible) return null;
//disabled={currentProgression.status === ProgressionStatusEnum.PENDING}
  return (
      <ProgressionStep
          number={number.toString()}
          title={t("Progression.ConnectStripe.title")}
          status={status}
          error={error}
          loading={loading}
      >
        <p className="font-light text-low-contrast">
          {t("Progression.ConnectStripe.content")}
        </p>
        {
            allAccounts.length > 0 && (
                <InputSelect
                    items={allAccounts}
                    defaultValue={selectedAccount}
                    selectedValue={selectedAccount}
                    onSelect={(value: ValueType) => {
                        setSelectedAccount(value);
                    }}
                />
            )
        }


        <div className="flex gap-x-3 mt-2">
          <Button
              RightIcon={CheckIcon}
              onClick={()=> onStripeClick()}
          >
            {t("Progression.ConnectStripe.addStripe")}
          </Button>
          <Button type="secondary"  onClick={ handleNextClick}>
            {t("Progression.ConnectStripe.nextStep")}
          </Button>
        </div>
      </ProgressionStep>
  );
};
