import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RightModal } from "../Common/RightModal/RightModal";
import { Separator } from "../Common/Separator/Separator";
import { Button } from "../Common/Button/Button";
import { PrestaInput } from "../Common/PrestaInput/PrestaInput";
import { InputSelect } from "../Common/InputSelect/InputSelect";
import { useRentalList } from "../../hooks/api/rental";
import { ErrorMessage } from "../Common/ErrorMessage/ErrorMessage";
import { NumberInput } from "../Common/NumberInput/NumberInput";
import { CheckBox } from "../Common/CheckBox/CheckBox";
import { Badge } from "../Common/Badge/Badge";
import { PaymentModalFiltersProps } from "./Payment.type";
import { RentalInformationsResponse } from "../../types/GETTypes";
import { FiltersPaymentsOperational } from "../../hooks/api/paymentOperational";

import moment from "moment/moment";
import { Controller } from "react-hook-form";
import rentals from "../../res/rentals";
import { Rental } from "../../pages/RentalPage/RentalPage.type";

export const PaymentModalFilters: React.FC<PaymentModalFiltersProps> = ({
  modal,
  rechargeData,
  filters,
  onResetFilters,
  onSubmitFilters,
}) => {
  const { t } = useTranslation();
  const [rentals, setRentals] = useState<RentalInformationsResponse[] | null>(
    null,
  );
  const [selectedRentals, setSelectedRentals] = useState<
    Array<string | number>
  >([]);
  const [error, setError] = useState<string | null>(null);
  const [priceFrom, setPriceFrom] = useState(filters.amount_min ?? 0);
  const [priceTo, setPriceTo] = useState(filters.amount_max ?? 200);

  const [paidType, setPaidType] = useState<boolean>(
    filters.status?.includes("PAID") ?? false,
  );
  const [inPaidType, setInPaidType] = useState<boolean>(
    filters.status?.includes("PENDING") ?? false,
  );
  const [invoiceReceivedType, setInvoiceReceivedType] = useState<boolean>(
    filters.status?.includes("INVOICE_RECEIVED") ?? false,
  );
  const [operatorsIds, setOperatorsIds] = useState<string[]>(
    filters.operator_ids ?? [],
  );

  const onChangeRentals = (rentals: Array<string | number>) => {
    setSelectedRentals(rentals);
  };

  useEffect(() => {
    useRentalList(
      (data) => {
        setRentals(data);
        // If filters already have selected rental IDs, load them into the state
        if (filters.rental_ids) {
          setSelectedRentals(filters.rental_ids);
        } else {
          setSelectedRentals([]);
        }
      },
      (err) => {
        setError(err ?? "");
      },
    );
  }, [t]);

  const handleSubmitNewFilters = () => {
    const filters: FiltersPaymentsOperational = {
      from: undefined,
      to: undefined,
      amount_min: priceFrom || undefined,
      amount_max: priceTo || undefined,
      operator_ids: operatorsIds.length > 0 ? operatorsIds : undefined,
      status: [],
      rental_ids: selectedRentals.length > 0 ? selectedRentals : undefined,
      workspace_id: undefined,
      page: 1,
      limit: 10,
    };

    if (paidType) filters.status!.push("PAID");
    if (inPaidType) filters.status!.push("PENDING");
    if (invoiceReceivedType) filters.status!.push("INVOICE_RECEIVED");

    if (filters.status!.length === 0) {
      delete filters.status;
    }

    rechargeData(filters);
  };

  const handleResetFilters = () => {
    onResetFilters();
    rechargeData({
      page: 1,
      limit: 10,
      from: moment().subtract(2, "weeks").startOf("day").format("DD-MM-YYYY"),
      to: moment().endOf("day").format("DD-MM-YYYY"),
      operator_ids: [],
      status: [],
      amount_min: 0,
      amount_max: 500,
      rental_ids: [],
    });
  };

  if (!rentals && !selectedRentals) return null;

  return (
    <RightModal
      title={t("Management.Payments.filtersTitle")}
      isVisible={modal.isVisible}
      onClose={modal.close}
      classNames={{
        mainContentParent: "overflow-y-auto",
      }}
    >
      {error && <ErrorMessage>{error}</ErrorMessage>}

      <main className="flex flex-col justify-between w-full">
        <section>
          <p className="text-gray-500 text-md font-bold">
            {t("Management.Payments.filterBy")}
          </p>
          <div className="flex flex-col gap-3">
            <PrestaInput
              onChange={(operators: string[]) => {
                setOperatorsIds(operators);
              }}
              membersId={operatorsIds}
              className="mt-4"
            />
            <div className="mt-4 flex flex-col gap-1">
              <Separator />
              <div className="flex-1">
                <p className="font-bold">
                  {t("Management.Payments.rentalIs")}
                  <span className="text-error font-normal"> *</span>
                </p>
                <div className="flex flex-col gap-2 mt-2">
                  {rentals?.map((rental) => {
                    const isChecked = selectedRentals.includes(rental.id);

                    const toggleCheck = () => {
                      if (isChecked) {
                        onChangeRentals(
                          selectedRentals.filter((id) => id !== rental.id),
                        );
                      } else {
                        onChangeRentals([...selectedRentals, rental.id]);
                      }
                    };

                    return (
                      <div key={rental.id} className="flex items-center gap-2">
                        <CheckBox value={isChecked} onChange={toggleCheck} />
                        <p
                          className="text-gray-500 cursor-pointer"
                          onClick={toggleCheck}
                        >
                          {rental.name}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
              <Separator />
            </div>

            <div className={"flex items-center gap-2"}>
              <NumberInput
                label={t("Management.Payments.amountFromTo")}
                onChangeText={(newNumber) => setPriceFrom(newNumber as number)}
                value={priceFrom}
                acceptDecimal={true}
              />
              <NumberInput
                label={t("Management.Payments.and")}
                value={priceTo}
                onChangeText={(newNumber) => setPriceTo(newNumber as number)}
                acceptDecimal={true}
              />
            </div>
            <div className={"flex flex-col gap-1"}>
              <p className={"font-bold"}>
                {t("Management.Payments.paymentType")}
              </p>
              <div className={"flex items-center gap-2"}>
                <CheckBox
                  value={inPaidType}
                  onChange={(value) => setInPaidType(value)}
                />
                <Badge
                  status={"fail"}
                  label={t("Management.Payments.toPaid")}
                />
              </div>
              <div className={"flex items-center gap-2"}>
                <CheckBox
                  value={paidType}
                  onChange={(value) => setPaidType(value)}
                />
                <Badge
                  status={"success"}
                  label={t("Management.Payments.paid")}
                />
              </div>
              <div className={"flex items-center gap-2"}>
                <CheckBox
                  value={invoiceReceivedType}
                  onChange={(value) => setInvoiceReceivedType(value)}
                />
                <Badge
                  status={"pending"}
                  label={t("Management.Payments.invoiceReceived")}
                />
              </div>
            </div>
          </div>
        </section>

        <section>
          <Separator />
          <div className="flex items-center justify-between gap-2 w-full">
            <Button
              type="secondary"
              onClick={() => {
                handleResetFilters();
                setSelectedRentals([]);
                setOperatorsIds([]);
                setPaidType(false);
                setInPaidType(false);
                setInvoiceReceivedType(false);
                modal.close();
              }}
            >
              {t("Global.resetFilter")}
            </Button>
            <Button
              onClick={() => {
                handleSubmitNewFilters();
                onSubmitFilters();
                modal.close();
              }}
            >
              {t("Global.apply")}
            </Button>
          </div>
        </section>
      </main>
    </RightModal>
  );
};
