import React from "react";
import { useTranslation } from "react-i18next";
import {
  CALENDAR_HEADER_HEIGHT,
  CALENDAR_NB_DAYS_AFTER,
  CALENDAR_NB_DAYS_BEFORE,
  CALENDAR_RENTAL_NAME_WIDTH,
} from "../../constants/calendar";
import { Button } from "../Common/Button/Button";
import { TextInput } from "../Common/TextInput/TextInput";
import { CalendarHeaderProps } from "./Calendar.type";
import { CalendarDateItem } from "./DateItem";
import { CalendarDateItemSkeleton } from "./DateItemSkeleton";

import DoubleChevronUpIcon from "../../assets/icons/chevron-up.svg?react";
import DoubleChevronDownIcon from "../../assets/icons/double-chevron-down.svg?react";
import SearchIcon from "../../assets/icons/search.svg?react";
import { MultiSelectRentals } from "../Common/MultiSelectRentals/MultiSelectRentals";

export const CalendarHeader: React.FC<CalendarHeaderProps> = ({
  from,
  to,
  total,
  loading,
  daysOffset,
  search,
  upToOneRentalOpen = false,
  selectedRentals,
  onSearchTextChange = () => {},
  onToggleAllRentals = () => {},
  onSelectRentals = () => {},
}) => {
  const { t } = useTranslation();
  const nbDays = to.diff(from, "days");

  const disabled = loading;

  return (
    <thead>
      <tr className="">
        <th
          className="relative "
          style={{
            minWidth: CALENDAR_RENTAL_NAME_WIDTH,
            height: CALENDAR_HEADER_HEIGHT,
          }}
        >
          <div className="flex justify-start p-2 pb-0 ">
            {disabled ? (
              <div className="w-32 h-2 mt-1 rounded bg-slate-300" />
            ) : (
              <div className="font-semibold text-high-contrast">
                {t("Global.rental_count", { count: total }).toLowerCase()}
              </div>
            )}
          </div>

          <div className="flex gap-2 pl-2 pr-3 mt-3 ">
            <TextInput
              placeholder={t("Global.search")}
              Icon={SearchIcon}
              value={search}
              onChangeText={onSearchTextChange}
              disabled={disabled}
            />

            <div>
              <MultiSelectRentals
                size="small"
                initialRentals={selectedRentals ?? []}
                onSelect={onSelectRentals}
              />
            </div>

            <div>
              <Button
                RightIcon={
                  upToOneRentalOpen
                    ? DoubleChevronUpIcon
                    : DoubleChevronDownIcon
                }
                type="secondary"
                size="small"
                onClick={onToggleAllRentals}
              ></Button>
            </div>
          </div>
          {/* Right separation */}
          <div
            className="absolute top-0 bg-element-border "
            style={{
              width: 1,
              height: CALENDAR_HEADER_HEIGHT + 3,
              left: CALENDAR_RENTAL_NAME_WIDTH - 2,
            }}
          />
        </th>

        {Array.from({ length: CALENDAR_NB_DAYS_BEFORE }).map((v, index) => (
          <CalendarDateItemSkeleton
            key={index}
            index={index}
            daysOffset={daysOffset}
          />
        ))}

        {Array.from({ length: nbDays }).map((v, index) => {
          return (
            <CalendarDateItem
              key={index}
              index={index}
              daysOffset={daysOffset}
            />
          );
        })}

        {Array.from({ length: CALENDAR_NB_DAYS_AFTER }).map((v, index) => (
          <CalendarDateItemSkeleton
            key={index}
            index={index}
            daysOffset={daysOffset}
          />
        ))}
      </tr>
    </thead>
  );
};
