import React from "react";
import {useTranslation} from "react-i18next";
import useGuestWebsiteStore from "../../../../stores/guestWebsiteStore/useGuestWebsiteStore";
import RentalDetailsHomePage from "./RentalDetails/Home/RentalDetailsHomePage";
import RentalDetailInformationPage from "./RentalDetails/Info/RentalDetailInformationPage";
import RentalDetailsPaymentPage from "./RentalDetails/Payment/RentalDetailsPaymentPage";
import RentalDetailsExtrasPage from "./RentalDetails/Extras/RentalDetailsExtrasPage";
import RentalDetailsExtrasPaymentPage from "./RentalDetails/ExtrasPayment/RentalDetailsExtrasPaymentPage";

interface GuestWebsiteTemplate2RentalComponentProps {
  loading: boolean;
}
const GuestWebsiteTemplate2RentalComponent = ({
  loading,
}: GuestWebsiteTemplate2RentalComponentProps) => {
  const {rentalDetailTab} = useGuestWebsiteStore();

  const pageByRentalDetailTab = () => {
    switch (rentalDetailTab) {
      case "rental":
        return <RentalDetailsHomePage loading={loading} />;
      case "information":
        return <RentalDetailInformationPage loading={loading} />;
      case "payment":
        return <RentalDetailsPaymentPage loading={loading} />;
      case "extras":
        return <RentalDetailsExtrasPage loading={loading} />;
      case "extrasPayment":
        return <RentalDetailsExtrasPaymentPage loading={loading} />;
      default:
        return <RentalDetailsHomePage loading={loading} />;
    }
  };

  if (loading) {
    return (
      <div className="p-4 md:px-contentWebsite bg-element-border">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 p-4 pl-2 ml-2 mr-2 md:mr-4 rounded-lg relative">
          <>
            {/* Main Image Skeleton */}
            <div className="md:col-span-2">
              <div className="w-full h-[272px] bg-gray-500 rounded-lg animate-pulse"></div>
            </div>

            {/* Smaller Images Skeleton */}
            <div className="grid grid-cols-2 gap-4">
              {[...Array(4)].map((_, index) => (
                <div
                  key={index}
                  className="w-full h-[128px] bg-gray-500 rounded-lg animate-pulse"
                ></div>
              ))}
            </div>
          </>
        </div>
      </div>
    );
  }

  return pageByRentalDetailTab();
};

export default GuestWebsiteTemplate2RentalComponent;
