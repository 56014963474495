import React, {useEffect, useState} from "react";
import {GuestPageTab} from "../GuestPageHome/GuestPageHomeComponent";
import LayoutBlockComponent from "../Common/LayoutBlockComponent";
import {useTranslation} from "react-i18next";
import {Button} from "../../Common/Button/Button";
import GuestPagePaymentItemComponent from "./GuestPagePaymentItemComponent";
import useGuestPageDataStore from "../../../stores/guestPageStore/useGuestPageDataStore";
import {
  PaymentReservationDepositStatusEnum,
  PaymentReservationStatusEnum,
} from "../../../enums/GETenums";
import moment from "moment";
import {getDepositStatusBadge} from "../../../helpers/reservationHelper";
import GuestPagePaymentForm from "./GuestPagePaymentForm";
import {
  PaymentMethodCreateParams,
  Stripe,
  StripeElementsOptions,
  loadStripe,
} from "@stripe/stripe-js";
import {get} from "../../../helpers/APIHelper";
import paths from "../../../constants/paths";
import {capitalizeFirstLetter} from "../../../helpers/stringHelper";
import {
  PaymentDepositReservationLinkResponse,
  PaymentReservationLinkResponse,
} from "../../../types/GETTypes";
import {StepForm} from "../../../pages/PaymentReservation/PaymentReservationFormOrInfo";
import ArrowWhiteRightIcon from "../../../assets/icons/arrow-right-white.svg?react";
import CartSleeping from "../../../assets/images/cart-sleeping.svg?react";
import GuestPagePaymentInvoice from "./GuestPagePaymentInvoice";
import useGuestPageTextCoverStore from "../../../stores/guestPageStore/useGuestPageTextCoverStore";
import {generateRandomPaymentsArray} from "./paymentDataPreview";
import {getPlatformByName} from "../../../helpers/platformHelper";
import {PLATFORM_RESERVATION} from "../../../pages/Booking/BookingPage.type";
import {Separator} from "../../Common/Separator/Separator";
import useWindowSize from "../../../hooks/useWindowSize";

const GuestPagePayment = ({tabUrl}: GuestPageTab) => {
  const {isMobile} = useWindowSize();
  const {t} = useTranslation();
  const {storeTitle, storeSubTitle} = useGuestPageTextCoverStore();
  const {guestPageData, storeGuestPageData, isPreviewMode, rentalPreview} =
    useGuestPageDataStore();
  const {
    payment_schedule,
    guest,
    id: reservationId,
    rental,
    platform_name,
  } = guestPageData || {};
  const {tarification: tarificationPreview} = rentalPreview || {};
  const {rules: {currency: currencyPreview} = {}} = tarificationPreview || {};
  const {
    deposit_payment_status,
    deposit_value,
    deposit_payment_specific_date,
    payments_schedule_items,
    total_price_value,
    is_deposit_required,
    deposit_payment_link,
  } = payment_schedule || {};
  const {currency} = rental || {};
  const depositDate = moment(deposit_payment_specific_date).format(
    "DD/MM/YYYY"
  );

  const [stepPage, setStepPage] = useState<StepForm>("list");
  const [loaderPaymentFormPage, setLoaderPaymentFormPage] = useState(false);
  const [billingDetails, setBillingDetails] = useState({});
  const [stripePromise, setStripePromise] = useState<
    Promise<Stripe | null> | undefined
  >();
  const [stripeOptions, setStripeOptions] = useState<
    StripeElementsOptions | undefined
  >();
  const [depositData, setDepositData] = useState<
    PaymentDepositReservationLinkResponse | undefined
  >();
  const [paymentData, setPaymentData] = useState<
    PaymentReservationLinkResponse | undefined
  >();

  useEffect(() => {
    storeTitle(t("GuestPage.Payment.title"));
    storeSubTitle && storeSubTitle(t("GuestPage.Payment.subTitle"));
  }, []);

  useEffect(() => {
    if (tabUrl === "invoice") {
      setStepPage("facture");
    }
  }, [tabUrl]);

  const initStripeDeposit = async () => {
    setLoaderPaymentFormPage(true);
    const response = await get(
      `${import.meta.env.VITE_API_URL}${
        paths.API.PAYMENTS.DEPOSITS
      }/${reservationId}${
        paths.API.PAYMENTS.GET_DEPOSIT_LINK
      }/${deposit_payment_link}`
    );
    if (response?.data?.success) {
      const depositData = response?.data?.result;
      if (depositData) {
        setDepositData(depositData);
        const {stripe_client_secret, stripe_account_id} = depositData || {};
        setStripePromise(
          loadStripe(import.meta.env.VITE_STRIPE_KEY, {
            stripeAccount: stripe_account_id,
          })
        );
        setStripeOptions({
          clientSecret: stripe_client_secret,
        });
        if (guest) {
          const {first_name, last_name, city, country} = guest;
          setBillingDetails({
            name: `${capitalizeFirstLetter(first_name)} ${capitalizeFirstLetter(
              last_name
            )}`,
            address: {
              city: capitalizeFirstLetter(city),
              country: capitalizeFirstLetter(country),
            },
          });
        }
      }
    } else {
      console.log(response?.data?.message ?? response.message);
    }

    setLoaderPaymentFormPage(false);
  };

  const initStripePayment = async ({payment_link}: {payment_link: string}) => {
    setLoaderPaymentFormPage(true);
    const response = await get(
      `${import.meta.env.VITE_API_URL}${
        paths.API.PAYMENTS.RESERVATIONS
      }/${reservationId}${paths.API.PAYMENTS.GET_LINK}/${payment_link}`
    );
    if (response?.data?.success) {
      const paymentData = response?.data?.result;
      if (paymentData) {
        setPaymentData(paymentData);
        const {stripe_client_secret, stripe_account_id} = paymentData || {};
        if (
          !stripePromise &&
          paymentData.payment_schedules.payment_schedule.payments_schedule_items
            .length > 0
        ) {
          setStripePromise(
            loadStripe(import.meta.env.VITE_STRIPE_KEY, {
              stripeAccount: stripe_account_id,
            })
          );
        }
        setStripeOptions({
          locale: "fr",
          clientSecret: stripe_client_secret,
        });
        if (guest) {
          const {first_name, last_name, city, country} = guest;
          setBillingDetails({
            name: `${capitalizeFirstLetter(first_name)} ${capitalizeFirstLetter(
              last_name
            )}`,
            address: {
              city: capitalizeFirstLetter(city),
              country: capitalizeFirstLetter(country),
            },
          });
        }
      }
    } else {
      console.log(response?.data?.message ?? response.message);
    }

    setLoaderPaymentFormPage(false);
  };
  const handleDepositPayment = () => {
    setStepPage("depositForm");
    initStripeDeposit();
  };
  const handlePayment = ({payment_link}: {payment_link: string}) => {
    setStepPage("paymentForm");
    initStripePayment({payment_link});
  };

  const changeStatusPayment = (
    status: PaymentReservationStatusEnum | undefined
  ) => {
    console.log("🚀 ~ GuestPagePayment ~ status:", status);
    const {payment_schedule_current_item: {id} = {}} = paymentData || {};
    if (status) {
      const updatedPaymentsScheduleItems =
        guestPageData?.payment_schedule?.payments_schedule_items?.map(
          (payment) =>
            payment.id === id ? {...payment, payment_status: status} : payment
        );
      if (guestPageData && updatedPaymentsScheduleItems) {
        const newGuestPageData = {
          ...guestPageData,
          payment_schedule: {
            ...guestPageData.payment_schedule,
            payments_schedule_items: updatedPaymentsScheduleItems,
          },
        };
        storeGuestPageData(newGuestPageData);
      }
    }
    setTimeout(() => {
      setStepPage("list");
    }, 500);
  };

  const changeStatusDeposit = (
    status: PaymentReservationDepositStatusEnum | undefined
  ) => {
    if (status) {
      if (guestPageData) {
        const newGuestPageData = {
          ...guestPageData,
          payment_schedule: {
            ...guestPageData.payment_schedule,
            deposit_payment_status: status,
          },
        };
        storeGuestPageData(newGuestPageData);
      }
    }
    setTimeout(() => {
      setStepPage("list");
    }, 500);
  };

  const disabledDepositBtn = !(
    deposit_payment_status === PaymentReservationDepositStatusEnum.UNPAID ||
    deposit_payment_status === PaymentReservationDepositStatusEnum.OVERDUE
  );

  const hasPaymentOrDeposit =
    (is_deposit_required && is_deposit_required >= 1) ||
    (payments_schedule_items && payments_schedule_items?.length >= 1);

  const payments = isPreviewMode
    ? generateRandomPaymentsArray(3)
    : payments_schedule_items;

  const hasData = isPreviewMode ? true : hasPaymentOrDeposit;

  const paymentStatuses = ["PAID", "PARTIALLY_PAID", "UNPAID"];
  const depositPreviewStatus = paymentStatuses[
    Math.floor(Math.random() * paymentStatuses.length)
  ] as PaymentReservationDepositStatusEnum;

  const displayDeposit =
    getPlatformByName(platform_name) !== PLATFORM_RESERVATION.AIRBNB;

  const RenderContent = () => {
    if (stepPage === "list") {
      return (
        <LayoutBlockComponent
          title={t("GuestPage.Payment.title")}
          subTitle={t("GuestPage.Payment.subTitle")}
          blockStyle={{
            titleStyle: "text-[32px] font-bold",
            childrenDivStyle: "pl-2 md:w-full md:h-full border-b-0",
            parentDivStyle: "md:w-full",
          }}
        >
          {!hasData ? (
            <div className="flex justify-center">
              <CartSleeping className="w-96 h-96" />
            </div>
          ) : (
            <div className="flex flex-col mb-4 md:w-full md:h-full">
              {displayDeposit && (
                <div className="mb-8 bg-element-background border-1 rounded-md border-element-border p-4">
                  <div className="flex-col">
                    <div className="text-high-contrast font-bold text-xl md:text-xl">
                      {t("GuestPage.Payment.deposit")}
                    </div>
                  </div>
                  {isPreviewMode ? (
                    <div className="flex flex-col md:flex-row justify-between">
                      <div className="flex flex-row justify-between items-center mt-8">
                        <div className="">
                          <div className="text-low-contrast md:text-base w-2/3 md:w-full">
                            {t("GuestPage.Payment.bankImprint", {
                              date: moment().format("DD/MM/YYYY"),
                            })}
                          </div>
                          <div className="text-high-contrast font-bold md:text-lg">
                            {(Math.random() * 1000).toFixed(2)}{" "}
                            {currencyPreview?.symbol ?? "€"}
                          </div>
                        </div>
                        {isMobile && (
                          <div className="flex flex-row justify-between items-center order-2 md:ml-2">
                            {depositPreviewStatus &&
                              getDepositStatusBadge(depositPreviewStatus)}
                          </div>
                        )}
                      </div>

                      <div className="flex items-center justify-between">
                        <div className="mt-2 md:mt-0 w-full md:w-52 order-1">
                          <Button
                            type="secondary"
                            onClick={handleDepositPayment}
                            disabled={disabledDepositBtn}
                            buttonClassName="w-fit"
                            textClassName="md:text-base font-bold"
                          >
                            {t("GuestPage.Payment.payNow")}
                          </Button>
                        </div>

                        {!isMobile && (
                          <div className="flex flex-row justify-between items-center order-2 md:ml-2">
                            {depositPreviewStatus &&
                              getDepositStatusBadge(depositPreviewStatus)}
                          </div>
                        )}
                      </div>
                    </div>
                  ) : is_deposit_required && is_deposit_required === 1 ? (
                    <div className="flex flex-col md:flex-row justify-between">
                      <div className="flex flex-row justify-between items-center mt-8">
                        <div className="">
                          <div className="text-low-contrast md:text-base w-2/3 md:w-full">
                            {t("GuestPage.Payment.bankImprint", {
                              date: depositDate,
                            })}
                          </div>
                          <div className="text-high-contrast font-bold md:text-lg">
                            {deposit_value} {currency?.symbol ?? "€"}
                          </div>
                        </div>
                        {isMobile && (
                          <div className="flex flex-row justify-between items-center order-2 md:ml-2">
                            {deposit_payment_status &&
                              getDepositStatusBadge(deposit_payment_status)}
                          </div>
                        )}
                      </div>

                      <div className="flex items-center justify-between">
                        <div className="mt-2 md:mt-0 w-full md:w-52 order-1">
                          <Button
                            type="secondary"
                            onClick={handleDepositPayment}
                            disabled={disabledDepositBtn}
                            buttonClassName="w-fit"
                            textClassName="md:text-base font-bold"
                          >
                            {t("GuestPage.Payment.payNow")}
                          </Button>
                        </div>

                        {!isMobile && (
                          <div className="flex flex-row justify-between items-center order-2 md:ml-2">
                            {deposit_payment_status &&
                              getDepositStatusBadge(deposit_payment_status)}
                          </div>
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>
              )}
              <div className="bg-element-background border-1 rounded-md border-element-border p-4">
                <div className="flex flex-row justify-between ">
                  <div className="text-high-contrast font-bold text-xl md:text-xl">
                    {t("GuestPage.Payment.title")}
                  </div>
                </div>
                {payments?.map((payment) => (
                  <GuestPagePaymentItemComponent
                    key={payment.id}
                    payment={payment}
                    onClick={handlePayment}
                  />
                ))}

                <Separator accent="dark" />

                <div className="flex flex-row justify-between">
                  <div className="flex flex-col">
                    <span className="text-low-contrast md:text-base">
                      {t("Global.total")}
                    </span>
                    {isPreviewMode ? (
                      <span className="md:text-lg font-bold">
                        {(Math.random() * 1000).toFixed(2)}{" "}
                        {currencyPreview?.symbol ?? "€"}
                      </span>
                    ) : (
                      <span className="md:text-lg font-bold">
                        {total_price_value} {currency?.symbol ?? "€"}
                      </span>
                    )}
                  </div>
                  <div className="flex justify-end items-end ">
                    <Button
                      RightIcon={ArrowWhiteRightIcon}
                      type="primary"
                      buttonClassName="w-fit"
                      textClassName="md:text-base font-bold"
                      onClick={() => setStepPage("facture")}
                    >
                      {t("GuestPage.Payment.displayInvoice")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </LayoutBlockComponent>
      );
    } else if (stepPage === "depositForm") {
      return (
        <GuestPagePaymentForm
          depositData={depositData}
          step={stepPage}
          setStep={setStepPage}
          loading={loaderPaymentFormPage}
          billingDetails={billingDetails}
          stripePromise={stripePromise}
          stripeOptions={stripeOptions}
          onSuccess={changeStatusDeposit}
          
        />
      );
    } else if (stepPage === "paymentForm") {
      return (
        <GuestPagePaymentForm
          paymentData={paymentData}
          setPaymentData={setPaymentData}
          step={stepPage}
          setStep={setStepPage}
          loading={loaderPaymentFormPage}
          billingDetails={billingDetails}
          stripePromise={stripePromise}
          stripeOptions={stripeOptions}
          onSuccess={changeStatusPayment}
          onCloseSuccess={changeStatusPayment}
        />
      );
    } else if (stepPage === "facture") {
      return <GuestPagePaymentInvoice onChangeTab={setStepPage} />;
    }
  };

  return (
    <>
      <RenderContent />
    </>
  );
};

export default GuestPagePayment;
