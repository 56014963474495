import React, { useEffect, useState } from "react";

import ScrollIcon from "../../../assets/icons/scroll.svg?react";
import EditIcon from "../../../assets/icons/edit.svg?react";

import { Card } from "../../Common/Card/Card";
import { WebsiteResponse } from "../../../types/GETTypes";
import { useTranslation } from "react-i18next";
import { Button } from "../../Common/Button/Button";
import { AddWebsiteLegalModal } from "../../AddWebsite/AddWebsiteLegalModal";
import { useModal } from "../../../hooks/useModal";
import { post } from "../../../helpers/APIHelper";
import paths from "../../../constants/paths";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import { AddWebsiteCGVModal } from "../../AddWebsite/AddWebsiteCGVModal";
import { AddWebsitePoliciesModal } from "../../AddWebsite/AddWebsitePoliciesModal";

export const WebsiteLegalCard: React.FC<{
  website: WebsiteResponse | undefined;
  onUpdateWebsite: (nextWebsite: WebsiteResponse) => void;
}> = ({ website, onUpdateWebsite }) => {
  const { t } = useTranslation();

  return (
    <Card
      Icon={ScrollIcon}
      label={t("Website.Legal.title")}
      anchor="legal-card"
    >
      <FullMode website={website} onUpdateWebsite={onUpdateWebsite} />
    </Card>
  );
};

const FullMode: React.FC<{
  website: WebsiteResponse | undefined;
  onUpdateWebsite: (nextWebsite: WebsiteResponse) => void;
}> = ({ website, onUpdateWebsite }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const [legals, setLegals] = useState<string>("");
  const legalsModal = useModal<string>();

  const [cgv, setCgv] = useState<string>("");
  const cgvModal = useModal<string>();

  const [policy, setPolicy] = useState<string>("");
  const policyModal = useModal<string>();

  useEffect(() => {
    if (website) {
      setLegals(website.integration.legal_terms);
      setCgv(website.integration.cgv);
      setPolicy(website.integration.privacy_policy);
    }
  }, [website]);

  const saveLegals = async (value: string) => {
    setLegals(value);

    setLoading(true);
    setError(undefined);

    const formData = new FormData();
    formData.append("legal_terms", value);

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.WEBSITE.UPDATE.LEGALS(
        website?.id!
      )}`,
      formData
    );

    if (!res?.data?.success) {
      setError(res?.response?.data?.message);
    } else {
      onUpdateWebsite(res.data?.result);
    }

    setLoading(false);
  };

  const saveCGV = async (value: string) => {
    setCgv(value);

    setLoading(true);
    setError(undefined);

    const formData = new FormData();
    formData.append("cgv", value);

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.WEBSITE.UPDATE.CGV(
        website?.id!
      )}`,
      formData
    );

    if (!res?.data?.success) {
      setError(res?.response?.data?.message);
    } else {
      onUpdateWebsite(res.data?.result);
    }

    setLoading(false);
  };

  const savePolicy = async (value: string) => {
    setPolicy(value);

    setLoading(true);
    setError(undefined);

    const formData = new FormData();
    formData.append("privacy_policy", value);

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.WEBSITE.UPDATE.PRIVACY(
        website?.id!
      )}`,
      formData
    );

    if (!res?.data?.success) {
      setError(res?.response?.data?.message);
    } else {
      onUpdateWebsite(res.data?.result);
    }

    setLoading(false);
  };

  return (
    <>
      <AddWebsiteLegalModal
        isVisible={legalsModal.isVisible}
        onClose={legalsModal.close}
        legals={legalsModal.data}
        onSave={saveLegals}
      />

      <AddWebsiteCGVModal
        isVisible={cgvModal.isVisible}
        onClose={cgvModal.close}
        cgv={cgvModal.data}
        onSave={saveCGV}
      />

      <AddWebsitePoliciesModal
        isVisible={policyModal.isVisible}
        onClose={policyModal.close}
        policies={policyModal.data}
        onSave={savePolicy}
      />

      <div className="flex flex-col gap-3">
        <ErrorMessage>{error}</ErrorMessage>

        <div className="flex justify-between items-center border-1 border-element-border rounded-6px bg-element-background p-4">
          <p className="text-high-contrast font-semibold">
            {t("Website.Legal.legalTerms")}
          </p>

          <div>
            <Button
              RightIcon={EditIcon}
              type="secondary"
              onClick={() => legalsModal.open(legals)}
              disabled={!website}
              loading={loading}
            >
              {t("Global.edit")}
            </Button>
          </div>
        </div>

        <div className="flex justify-between items-center border-1 border-element-border rounded-6px bg-element-background p-4">
          <p className="text-high-contrast font-semibold">
            {t("Website.Legal.cgv")}
          </p>

          <div>
            <Button
              RightIcon={EditIcon}
              type="secondary"
              onClick={() => cgvModal.open(cgv)}
              disabled={!website}
              loading={loading}
            >
              {t("Global.edit")}
            </Button>
          </div>
        </div>

        <div className="flex justify-between items-center border-1 border-element-border rounded-6px bg-element-background p-4">
          <p className="text-high-contrast font-semibold">
            {t("Website.Legal.privacy")}
          </p>

          <div>
            <Button
              RightIcon={EditIcon}
              type="secondary"
              onClick={() => policyModal.open(policy)}
              disabled={!website}
              loading={loading}
            >
              {t("Global.edit")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
