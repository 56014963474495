import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "../../../Common/Card/Card";

import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import ArrowLeftIcon from "../../../../assets/icons/arrow-left.svg?react";
import CheckWhiteIcon from "../../../../assets/icons/check-white.svg?react";
import EditIcon from "../../../../assets/icons/edit.svg?react";
import EuroIcon from "../../../../assets/icons/euro.svg?react";
import GivingMoneyIcon from "../../../../assets/icons/giving-money.svg?react";
import MoneyIcon from "../../../../assets/icons/money.svg?react";
import PercentageIcon from "../../../../assets/icons/percentage.svg?react";
import SendMoneyIcon from "../../../../assets/icons/send-money.svg?react";
import { useRentalPricingRulesUpdate } from "../../../../hooks/api/rental";
import { RentalRules2 } from "../../../../pages/RentalPage/RentalPage.type";
import { useRentalPageContext } from "../../../../pages/RentalPage/RentalPageContext";
import currencies from "../../../../res/currencies";
import { RequiredFields, ValueType } from "../../../../types/commonTypes";
import { ErrorMessage } from "../../../Common/ErrorMessage/ErrorMessage";
import { InputSelect } from "../../../Common/InputSelect/InputSelect";
import { InputSelectOptionProps } from "../../../Common/InputSelect/InputSelect.type";
import { NumberInput } from "../../../Common/NumberInput/NumberInput";
import { SettingElement } from "../../../Common/SettingElement/SettingElement";
import { SimpleRadio } from "../../../Common/SimpleRadio/SimpleRadio";
import {
  RentalPricingRulesForm,
  RentalPricingRulesRequest,
} from "./PricingRules.type";
import { RentalPricingView } from "./PricingRulesView";
import { RentalPricingViewSkeleton } from "./PricingRulesViewSkeleton";
import { getCurrentCanEdit } from "../../../../helpers/workspaceHelper";
import useUserStore from "../../../../stores/useUserStore";

export const RentalPricingRules: React.FC<{
  workspaceOwner: ValueType | undefined;
}> = ({ workspaceOwner }) => {
  const userStore = useUserStore();
  const { t } = useTranslation();

  const { rentalId } = useParams();
  const {
    loadingState: { loading },
    rental,
    onUpdateRental,
  } = useRentalPageContext();

  const {
    control,
    register,
    setValue,
    getValues,
    watch,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<RentalPricingRulesForm>({
    mode: "all",
  });

  const requiredFields: RequiredFields<RentalPricingRulesForm> = {
    currency: true,
    price_night_default: true,
    price_night_min: true,
    vat_percentage: true,
    city_tax_type: true,
    city_tax_default: true,
    city_tax_percent: true,
    city_tax_limit: true,
    city_tax_additional: false,
  };

  useEffect(() => {
    setValue(
      "currency",
      rental?.tarification.rules?.currency.id ??
        currencies.find((i) => i.id === 28)?.id! // ID:28 === EURO
    );

    setValue(
      "price_night_default",
      rental?.tarification.rules?.price_night_default ?? 0
    );

    setValue(
      "price_night_min",
      rental?.tarification.rules?.price_night_min ?? 0
    );

    setValue(
      "vat_percentage",
      rental?.tarification.rules?.vat_percentage ?? 0
    );
    setValue(
      "city_tax_type",
      rental?.tarification.rules?.city_tax_percent !== null
        ? "percent"
        : "fixed"
    );

    setValue(
      "city_tax_default",
      rental?.tarification.rules?.city_tax_default ?? 0
    );

    setValue(
      "city_tax_percent",
      rental?.tarification.rules?.city_tax_percent ?? 0
    );

    setValue(
      "city_tax_limit",
      rental?.tarification.rules?.city_tax_limit ?? 0
    );

    setValue(
      "city_tax_additional",
      rental?.tarification.rules?.city_tax_additional ?? 0
    );
  }, [rental?.tarification.rules]);

  watch();

  const [mode, setMode] = useState<"view" | "edit">("view");
  const [saving, setSaving] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();

  const [currencyItems, setCurrencyItems] = useState<InputSelectOptionProps[]>(
    []
  );
  useEffect(() => {
    setCurrencyItems(
      currencies.map((i: any): InputSelectOptionProps => {
        return {
          label: i.name,
          value: i.id,
        };
      })
    );
  }, []);

  const handleClickMode = () => {
    if (mode === "view") setMode("edit");
    else save();
  };

  const save = async () => {
    const data: RentalPricingRulesRequest = {
      currency_id: getValues("currency"),
      price_night_default: getValues("price_night_default"),
      price_night_min: getValues("price_night_min"),
      vat_percentage: getValues("vat_percentage"),
      city_tax_additional: getValues("city_tax_additional"),
      city_tax_default: getValues("city_tax_default"),
      city_tax_limit: getValues("city_tax_limit"),
      city_tax_percent: getValues("city_tax_percent"),
    };

    if (getValues("city_tax_type") === "fixed") {
      data.city_tax_percent = null;
      data.city_tax_limit = null;
    } else if (getValues("city_tax_type") === "percent") {
      data.city_tax_default = null;
    }

    useRentalPricingRulesUpdate(
      rentalId,
      data,
      (pricingRules: RentalRules2) => {
        if (pricingRules) {
          if (rental) {
            onUpdateRental({
              ...rental,
              tarification: {
                ...rental.tarification,
                rules: {
                  ...rental.tarification.rules,
                  city_tax_additional: getValues("city_tax_additional"),
                  city_tax_default:
                      getValues("city_tax_type") === "fixed"
                          ? getValues("city_tax_default")
                          : null,
                  city_tax_limit:
                      getValues("city_tax_type") === "percent"
                          ? getValues("city_tax_limit")
                          : null,
                  city_tax_percent:
                      getValues("city_tax_type") === "percent"
                          ? getValues("city_tax_percent")
                          : null,
                  currency: {
                    ...pricingRules.currency,
                    id: getValues("currency"),
                  },
                  price_night_default: getValues("price_night_default"),
                  price_night_min: getValues("price_night_min"),
                  vat_percentage: getValues("vat_percentage"),
                },
              },
            });
          }
          setMode("view");
        }
      },
      (message: string | undefined) => setError(message),
      () => {
        setError(undefined);
        setSaving(true);
      },
      () => {
        setSaving(false);
      }
    );
  };

  return (
    <Card
      label={t("Rental.Pricing.PricingRules.title")}
      Icon={SendMoneyIcon}
      anchor="pricing-card"
      loading={saving}
      button={{
        Icon: mode === "view" ? EditIcon : CheckWhiteIcon,
        label: mode === "view" ? t("Global.edit") : t("Global.record"),
        type: mode === "view" ? "secondary" : "primary",
        onClick: handleSubmit(handleClickMode),
        skeleton: loading,
        disabled:
          (mode === "edit" && !isValid) ||
          !getCurrentCanEdit({
            user: userStore.user,
            workspaceOwner,
          }) ||
          loading,
      }}
      secondaryButton={
        mode === "edit"
          ? {
              label: t("Global.cancel"),
              LeftIcon: ArrowLeftIcon,
              onClick: () => setMode("view"),
            }
          : undefined
      }
    >
      {loading ? <RentalPricingViewSkeleton /> : null}

      <ErrorMessage>{error}</ErrorMessage>

      {!loading && mode === "view" ? (
        <RentalPricingView pricingRules={rental?.tarification.rules} />
      ) : null}

      {!loading && mode === "edit" ? (
        <>
          <div className="flex flex-col gap-3">
            {/* CURRENCY */}
            <SettingElement
              Icon={EuroIcon}
              title={t("Rental.Pricing.PricingRules.currencyTitle")}
              description={t("Rental.Pricing.PricingRules.currencyDesc")}
            >
              <div className="w-56">
                <InputSelect
                  register={register("currency", {
                    required: {
                      value: requiredFields.currency,
                      message: t("Rental.Pricing.PricingRules.currencyError"),
                    },
                  })}
                  items={currencyItems}
                  selectedValue={
                    getValues("currency") ??
                    currencies.find((c) => c.name === "Euro")?.id
                  }
                  disabled={true}
                />
              </div>
            </SettingElement>

            {/* BASE PRICE */}
            <SettingElement
              Icon={MoneyIcon}
              title={t("Rental.Pricing.PricingRules.priceTitle")}
              description={t("Rental.Pricing.PricingRules.priceDesc")}
            >
              <div className="w-36">
                <NumberInput
                  register={register("price_night_default", {
                    required: {
                      value: requiredFields.price_night_default,
                      message: t("Rental.Pricing.PricingRules.priceError"),
                    },
                  })}
                  value={getValues("price_night_default")}
                  currency="€"
                  error={errors.price_night_default?.message}
                  disabled={saving}
                />
              </div>
            </SettingElement>

            {/* MINIMUM PRICE */}
            <SettingElement
              Icon={MoneyIcon}
              title={t("Rental.Pricing.PricingRules.minPriceTitle")}
              description={t("Rental.Pricing.PricingRules.minPriceDesc")}
            >
              <div className="w-36">
                <NumberInput
                  register={register("price_night_min", {
                    required: {
                      value: requiredFields.price_night_min,
                      message: t("Rental.Pricing.PricingRules.minPriceError"),
                    },
                  })}
                  value={getValues("price_night_min")}
                  currency="€"
                  error={errors.price_night_min?.message}
                  disabled={saving}
                />
              </div>
            </SettingElement>

            {/* VAT */}
            <SettingElement
              Icon={PercentageIcon}
              title={t("Rental.Pricing.PricingRules.vatTitle")}
              description={t("Rental.Pricing.PricingRules.vatDesc")}
            >
              <div className="w-36">
                <NumberInput
                  register={register("vat_percentage", {
                    required: {
                      value: requiredFields.vat_percentage,
                      message: t("Rental.Pricing.PricingRules.vatError"),
                    },
                  })}
                  acceptDecimal={false}
                  value={getValues("vat_percentage")}
                  currency="%"
                  error={errors.vat_percentage?.message}
                  disabled={saving}
                />
              </div>
            </SettingElement>

            {/* HOLIDAY TAX */}
            <SettingElement
              Icon={GivingMoneyIcon}
              title={t("Rental.Pricing.PricingRules.taxTitle")}
              description={t("Rental.Pricing.PricingRules.taxDesc")}
              classNames={{
                container: "flex-col items-start space-y-4",
              }}
            >
              <div className="flex flex-col w-full space-y-5">
                <div className="flex flex-col space-y-2">
                  <p className="font-bold text-md text-high-contrast">
                    {t("Rental.Pricing.PricingRules.taxType")}
                  </p>
                  <div className="flex space-x-6">
                    <Controller
                      control={control}
                      name="city_tax_type"
                      render={({ field: { onChange, value } }) => (
                        <>
                          <SimpleRadio
                            label={t("Rental.Pricing.PricingRules.taxFixed")}
                            onClick={() => onChange("fixed")}
                            value={value === "fixed"}
                            disabled={saving}
                          />
                          <SimpleRadio
                            label={t("Rental.Pricing.PricingRules.taxPercent")}
                            onClick={() => onChange("percent")}
                            value={value === "percent"}
                            disabled={saving}
                          />
                        </>
                      )}
                    />
                  </div>
                </div>

                {getValues("city_tax_type") === "fixed" ? (
                  <NumberInput
                    register={register("city_tax_default", {
                      required: {
                        value: requiredFields.city_tax_default,
                        message: t("Global.Errors.requiredField", {
                          fieldName: t(
                            "Rental.Pricing.PricingRules.priceNightPerPerson"
                          ),
                        }),
                      },
                    })}
                    required={requiredFields.city_tax_default}
                    acceptDecimal={false}
                    label={t("Rental.Pricing.PricingRules.priceNightPerPerson")}
                    value={getValues("city_tax_default")}
                    currency="€"
                    error={errors.city_tax_default?.message}
                    disabled={saving}
                  />
                ) : null}

                {getValues("city_tax_type") === "percent" ? (
                  <div className="flex flex-col space-y-3">
                    <div className="flex justify-between space-x-3">
                      <div className="flex-1">
                        <NumberInput
                          register={register("city_tax_percent", {
                            required: {
                              value: requiredFields.city_tax_percent,
                              message: t("Global.Errors.requiredField", {
                                fieldName: t(
                                  "Rental.Pricing.PricingRules.percentPriceNight"
                                ),
                              }),
                            },
                          })}
                          required={requiredFields.city_tax_percent}
                          acceptDecimal={false}
                          label={t(
                            "Rental.Pricing.PricingRules.percentPriceNight"
                          )}
                          value={getValues("city_tax_percent")}
                          currency="%"
                          error={errors.city_tax_percent?.message}
                          disabled={saving}
                        />
                      </div>
                      <div className="flex-1">
                        <NumberInput
                          register={register("city_tax_limit", {
                            required: {
                              value: requiredFields.city_tax_limit,
                              message: t("Global.Errors.requiredField", {
                                fieldName: t(
                                  "Rental.Pricing.PricingRules.ceilingPerPersonPerNight"
                                ),
                              }),
                            },
                          })}
                          required={requiredFields.city_tax_limit}
                          acceptDecimal={false}
                          label={t(
                            "Rental.Pricing.PricingRules.ceilingPerPersonPerNight"
                          )}
                          value={getValues("city_tax_limit")}
                          currency="€"
                          error={errors.city_tax_limit?.message}
                          disabled={saving}
                        />
                      </div>
                    </div>
                    <NumberInput
                      register={register("city_tax_additional", {
                        required: {
                          value: requiredFields.city_tax_additional,
                          message: t("Global.Errors.requiredField", {
                            fieldName: t(
                              "Rental.Pricing.PricingRules.additionalTax"
                            ),
                          }),
                        },
                      })}
                      acceptDecimal={false}
                      label={t("Rental.Pricing.PricingRules.additionalTax")}
                      value={getValues("city_tax_additional")}
                      currency="%"
                      error={errors.city_tax_additional?.message}
                      disabled={saving}
                    />
                  </div>
                ) : null}
              </div>
            </SettingElement>
          </div>
        </>
      ) : null}
    </Card>
  );
};
