import React from "react";
import languages from "../../../../res/languages";
import { ValueType } from "../../../../types/commonTypes";
import { GroupButton } from "../../../Common/GroupButton/GroupButton";
import { GroupButtonItemType } from "../../../Common/GroupButton/GroupButton.type";
import {
  useHandleClickNavbarItem,
  useRentalLayoutNavbarItems,
} from "./RentalLayoutNavbar.hooks";
import { RentalLayoutNavbarProps } from "./RentalLayoutNavbar.type";

export const RentalLayoutNavbar: React.FC<RentalLayoutNavbarProps> = ({
  activeItem,
  onActiveItem,
  // activeSubItem,
  // onActiveSubItem,
  rental,
}) => {
  const getLanguagesItems: GroupButtonItemType[] = languages.map((lang) => {
    return {
      Icon: lang.Icon,
      label: lang.label,
      value: lang.value,
      disabled: lang.value !== "fr",
      isActive: lang.value === "fr",
    };
  });

  return (
    <div className="flex flex-col space-y-6">
      <GroupButton
        items={useRentalLayoutNavbarItems(activeItem)}
        onClick={(value: ValueType) =>
          useHandleClickNavbarItem(value, rental, onActiveItem)
        }
      />

      {/* -- SUB MENU -- */}
      {/* <GroupButton
        items={useRentalLayoutNavbarSubItems(activeItem, activeSubItem)}
        onClick={(value: ValueType) =>
          useHandleClickNavbarSubItem(value, rental, onActiveSubItem)
        }
      /> */}

      {activeItem === "website" && <GroupButton items={getLanguagesItems} />}
    </div>
  );
};
