import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { hiddenDevice } from "../../../helpers/calendarHelper";
import { cn } from "../../../helpers/classHelper";
import { getPlatformNode } from "../../../helpers/platformHelper";
import { getDepositStatusBadge } from "../../../helpers/reservationHelper";
import { PaymentReservationResponse } from "../../../types/GETTypes";
import { TableListItemProps } from "../../Common/TableList/TableList.type";

export const PaymentDepositListItem: React.FC<TableListItemProps> = ({
  data,
  onClick = () => {},
}) => {
  const paymentReservation: PaymentReservationResponse = data;
  const { t } = useTranslation();

  const getDepositOption = () => {
    switch (paymentReservation.payment_schedule?.deposit_payment_option) {
      case "PRE_AUTHORISATION":
        return t("Payments.PaymentDepositList.preAuthorisationStatus");
      case "CARD_PAYMENT_TO_REFUND":
        return t("Payments.PaymentDepositList.cardPaymentStatus");
      case "BANK_TRANSFER_TO_REFUND":
        return t("Payments.PaymentDepositList.bankTransferStatus");
      case "BANK_CHECK_OR_CASH_AT_ARRIVAL":
        return t("Payments.PaymentDepositList.cashStatus");
    }
  };

  return (
    <>
      <tr
        className="cursor-pointer hover:bg-element-background/40"
        onClick={() => onClick(paymentReservation.id)}
      >
        <td
          className={cn(
            `flex flex-row items-center px-4 py-4 space-x-4 text-sm border-element-border border-t-1`
          )}
        >
          <div className="flex items-center">
            <div className="relative">
              <div className="object-fill w-12 h-12 overflow-hidden rounded-4px border-1 border-element-border/50">
                <img
                  src={paymentReservation.rental.cover}
                  alt={paymentReservation.rental.name}
                  className="object-fill w-full h-full"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col space-y-1">
            <p className="text-base font-semibold tracking-normal text-high-contrast">
              {paymentReservation.rental.name}
            </p>
            <div>
              <p className="font-light tracking-normal text-low-contrast">
                {paymentReservation.rental.address}
              </p>
              <p className="font-light tracking-normal text-low-contrast">
                {paymentReservation.rental.postal_code}{" "}
                {paymentReservation.rental.city}
              </p>
            </div>
          </div>
        </td>

        <td className={cn(`px-2 py-4 border-element-border border-t-1`)}>
          <span className="font-bold tracking-normal">{`${paymentReservation.guest.first_name} ${paymentReservation.guest.last_name}`}</span>
        </td>

        <td className={`border-element-border border-t-1`}>
          {getPlatformNode(paymentReservation.platform)}
        </td>

        <td className={cn(`py-4 text-center border-element-border border-t-1`)}>
          <span className="text-base">
            {paymentReservation.payment_schedule?.deposit_payment_specific_date
              ? moment(
                  paymentReservation.payment_schedule
                    .deposit_payment_specific_date
                )?.format("DD/MM/YYYY")
              : null}
          </span>
        </td>

        <td className={cn(`py-4 text-center border-element-border border-t-1`)}>
          <div className="flex flex-col items-center gap-1">
            <p className="text-base">{getDepositOption()}</p>
            <p>
              {getDepositStatusBadge(
                paymentReservation.payment_schedule?.deposit_payment_status
              )}
            </p>
          </div>
        </td>

        <td
          className={cn(
            `py-4 text-right pe-4 border-element-border border-t-1`
          )}
        >
          <span className="text-base font-bold tracking-wide">
            {`${
              paymentReservation.payment_schedule?.deposit_value
            }${hiddenDevice(
              paymentReservation.payment_schedule?.deposit_value?.toString()!,
              t("Global.currencySymbol")
            )}`}
          </span>
        </td>
      </tr>
    </>
  );
};
