import React from "react";
import useGuestWebsiteStore from "../../../../stores/guestWebsiteStore/useGuestWebsiteStore";

interface BrandsComponentProps {
  loading: boolean;
}
const BrandsComponent = ({loading}: BrandsComponentProps) => {
  const {dataGuestWebsite} = useGuestWebsiteStore();
  const {content} = dataGuestWebsite || {};
  const logos = content?.partners?.logos;
  if (loading) {
    return (
      <div className="bg-gray-100 py-4">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-3 gap-4 items-center justify-items-center sm:grid-cols-3 lg:grid-cols-6">
            {/* Skeleton for Airbnb */}
            <div className="h-12 w-32 bg-gray-400 rounded"></div>

            {/* Skeleton for Booking.com */}
            <div className="h-12 w-32 bg-gray-400 rounded"></div>

            {/* Skeleton for Abritel */}
            <div className="h-12 w-32 bg-gray-400 rounded"></div>

            {/* Skeleton for Vrbo */}
            <div className="h-12 w-32 bg-gray-400 rounded"></div>

            {/* Skeleton for Leboncoin */}
            <div className="h-12 w-32 bg-gray-400 rounded"></div>

            {/* Skeleton for Expedia */}
            <div className="h-12 w-32 bg-gray-400 rounded"></div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="md:flex bg-element-border py-4 md:items-center">
      <div className="container mx-auto px-4 md:py-8 pt-32 md:pt-[5.5rem]">
        <div className="flex flex-wrap justify-center gap-4 items-stretch">
          {logos?.map(({name, original_url}) => (
            <div key={name} className="w-1/3 md:w-1/6 flex flex-col h-12 md:h-10">
              <img
                src={original_url}
                alt={name}
                className="h-full object-contain mx-auto flex-1"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BrandsComponent;
