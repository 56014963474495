import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import FileErrorIcon from "../../assets/icons/file-error.svg?react";
import ArrowUpRightGreenBoldIcon from "../../assets/icons/arrow-up-right-green-bold.svg?react";
import DashboardServicesStatsItem, {
  DashboardServicesStatsItemProps,
  DashboardServicesStatsType,
} from "./DashboardServicesStatsItem";
import DashboardServicesStatsItemSkeleton from "./DashboardServicesStatsItemSkeleton";
import {DashboardData, StatsDashboard, UserResponse,} from "../../types/GETTypes";
import {capitalizeFirstLetter} from "../../helpers/stringHelper";
import paths from "../../constants/paths";
import {hasPermissionWithUser} from "../../helpers/workspaceHelper";

type DashboardServicesStatsProps = {
  dashboardData: DashboardData | undefined;
  isLoading: boolean;
  user: UserResponse | undefined;
};

const DashboardServicesStats: React.FC<DashboardServicesStatsProps> = ({
  dashboardData,
  isLoading,
  user,
}) => {
  const {t} = useTranslation();

  const data = dashboardData?.operator_tasks?.stats;

  const statsInitial: DashboardServicesStatsItemProps[] = [
    {
      header: "",
      title: "",
      description: "",
      url: paths.PAYMENTS,
      linkLabel: t("Dashboard.consultPayments"),
      type: "payments",
      hide:false
    },
    {
      header: "",
      title: "",
      description: "",
      url: paths.MANAGEMENT_TASKS,
      linkLabel: t("Dashboard.viewMyTasks"),
      type: "tasks_to_complete",
      hide:false
    },
    {
      header: "",
      title: "",
      description: "",
      url: paths.MANAGEMENT_TASKS,
      linkLabel: t("Dashboard.viewTasksAvailableNoneAssigned"),
      type: "tasks_not_assigned",
      hide:false
    },
  ];

  const [stats, setStats] =
    useState<DashboardServicesStatsItemProps[]>(statsInitial);

  useEffect(() => {
    replaceDataByType(data, "payments");
    replaceDataByType(data, "tasks_to_complete");
    replaceDataByType(data, "tasks_not_assigned");
  }, [data, user]);

  const replaceDataByType = (
    data: StatsDashboard | undefined,
    type: DashboardServicesStatsType
  ) => {
    setStats((oldStats) =>
      oldStats.map((stat) => {
        //!hasPermissionWithUser(user, "payment_reservations")
        if (stat.type === type) {
          switch (type) {
            case "payments":
              return {
                ...stat,
                header:
                  data?.invoice_amount?.value &&
                  Number(data.invoice_amount.value) >= 1
                    ? `${Number(data.invoice_amount.value)?.toLocaleString(
                        "fr-FR"
                      )}%`
                    : "0%",
                title: `${t(
                  "Dashboard.countTasksDoneDate"
                )} ${capitalizeFirstLetter(data?.invoice_amount?.month)} ${
                  data?.invoice_amount?.year
                }`,
                description: (
                  <div className="flex mt-2">
                    <span className="flex items-center text-[#297C3B] font-bold mr-2">
                      <ArrowUpRightGreenBoldIcon width={15} height={15} />
                      {data?.invoice_amount?.last_month_comp_percents?.toLocaleString(
                        "fr-FR"
                      )}
                    </span>
                    {` +${data?.invoice_amount?.last_month_comp_value?.toLocaleString(
                      "fr-FR"
                    )} € ${t("Dashboard.monthPrevious")}`}
                  </div>
                ),
                hide: false,
              };
            case "tasks_to_complete":
              return {
                ...stat,
                header:
                  data?.tasks_to_complete_today?.count &&
                  data.tasks_to_complete_today.count >= 1 ? (
                    data.tasks_to_complete_today.count?.toLocaleString("fr-FR")
                  ) : (
                    <FileErrorIcon width={30} height={30} />
                  ),
                title:
                  data?.tasks_to_complete_today?.count &&
                  data.tasks_to_complete_today.count >= 1
                    ? t("Dashboard.tasks")
                    : "",
                description:
                  data?.tasks_to_complete_today?.count &&
                  data.tasks_to_complete_today.count >= 1
                    ? t("Dashboard.tasksToday")
                    : t("Dashboard.noneTasks"),
                hide: !hasPermissionWithUser(
                  user,
                  "operational_management_tasks"
                ),
              };
            case "tasks_not_assigned":
              return {
                ...stat,
                header:
                  data?.tasks_not_assigned?.count &&
                  data.tasks_not_assigned.count >= 1 ? (
                    data.tasks_not_assigned.count?.toLocaleString("fr-FR")
                  ) : (
                    <FileErrorIcon width={30} height={30} />
                  ),
                title:
                  data?.tasks_not_assigned?.count &&
                  data.tasks_not_assigned.count >= 1
                    ? t("Dashboard.tasks")
                    : "",
                description:
                  data?.tasks_not_assigned?.count &&
                  data.tasks_not_assigned.count >= 1
                    ? t("Dashboard.noneAssigned")
                    : t("Dashboard.noneTasks"),
                hide: !hasPermissionWithUser(
                  user,
                  "operational_management_tasks"
                ),
              };
            default:
              return stat;
          }
        }
        return stat;
      })
    );
  };
  // .filter((stat) => !stat.hide)

  return (
    <div>
      <div className="w-full h-[200px] overflow-x-scroll relative">
        <div className="left-0 top-0 w-full h-full relative">
          {isLoading
            ? Array.from({length: 3}).map((nb, index) => (
                <div className="absolute" style={{left: index * 400}}>
                  <DashboardServicesStatsItemSkeleton />
                </div>
              ))
            : stats
                ?.map((stat, index) => (
                  <div className="absolute" style={{left: index * 400}}>
                    <DashboardServicesStatsItem
                      {...stat}
                    />
                  </div>
                ))}
        </div>
      </div>
    </div>
  );
};

export default DashboardServicesStats;
