/**
 * Class item navigator for
 * payment et tickets.
 */
import {MenuItemProps} from "./PaymentsTicket.type";
import {cn} from "../../../../helpers/classHelper";
import React from "react";
import {useTranslation} from "react-i18next";

export default function MenuItem ({onClick, Icon, text, isActive}: MenuItemProps)
{
    const { t } = useTranslation();

    return (
        <div
            onClick={onClick}
            className={cn("cursor-pointer flex items-center gap-3 text-lg text-gray-500", (isActive && "font-bold text-gray-700"))}
        >
            <Icon/> {t(text)}
        </div>
    );
}