import { del, get, patch, post, update } from "../../helpers/APIHelper";
import paths from "../../constants/paths";
import { PaymentType } from "../../components/ManagementPaymentListPage/Payment.type";

/**
 * Filters for payment operation,
 * "workspace_id" is deprecated.
 */
export interface FiltersPaymentsOperational {
  from?: string;
  to?: string;
  amount_min?: number;
  amount_max?: number;
  operator_ids?: Array<string>;
  status?: Array<string>;
  rental_ids?: Array<string | number>;
  search?: string;
  workspace_id?: string;
  page?: number;
  limit?: number;
}

/**
 * Hook for interact with operational management
 * payments.
 */
export const usePaymentOperational = () => {
  /**
   * Function to get all payments
   * asynchronous.
   * @param filters
   */
  const getAllPayment = async (filters: FiltersPaymentsOperational) => {
    const queryString = new URLSearchParams(
      Object.entries(filters)
        .filter(([_, value]) => value !== undefined)
        .reduce((acc, [key, value]) => {
          if (Array.isArray(value)) {
            value.forEach((v, index) =>
              acc.append(`${key}[${index}]`, String(v)),
            );
          } else {
            acc.append(key, String(value));
          }
          return acc;
        }, new URLSearchParams()),
    ).toString();

    const url = `${import.meta.env.VITE_API_URL}${paths.API.PAYMENT_MANAGEMENT.ALL}${
      queryString ? `?${queryString}` : ""
    }`;

    const response = await get(url);
    console.log(response);
    console.log("salyt");

    if (response.status === 200 && response?.data?.result) {
      return [
        response.data.result.payments_by_day_and_operator,
        response.data.result.total,
      ];
    } else {
      throw new Error(response?.data?.message ?? response.message);
    }
  };

  /**
   * Function for update payment
   * status operators.
   *
   * @param status
   * @param paymentsIds
   */
  const updatePaymentStatus = async (
    status: PaymentType,
    paymentsIds: number[],
  ) => {
    const url = `${import.meta.env.VITE_API_URL}${paths.API.PAYMENT_MANAGEMENT.UPDATE_STATUS}`;

    const response = await update(url, {
      status: status,
      payment_ids: paymentsIds,
    });

    if (response.status === 200 && response?.data?.result) {
      return true;
    } else {
      throw new Error(response?.data?.message ?? response.message);
    }
  };

  /**
   * Function for delete payment
   * status operators.
   *
   * @param paymentId
   */
  const deletePayment = async (paymentId: number) => {
    const url = `${import.meta.env.VITE_API_URL}${paths.API.PAYMENT_MANAGEMENT.DELETE(paymentId)}`;

    const response = await del(url);

    if (response.status === 200 && response?.data?.result) {
      return true;
    } else {
      throw new Error(response?.data?.message ?? response.message);
    }
  };

  /**
   * Function for update payment
   * status operators.
   *
   * @param paymentId
   * @param operatorId
   * @param name
   * @param endDate
   * @param rentalIds
   * @param bedsChangedAmount
   * @param amount
   */
  const updatePayment = async (
    paymentId: number,
    operatorId: string,
    name: string,
    endDate: Date,
    rentalIds: Array<string | number>,
    bedsChangedAmount: number,
    amount: number,
  ) => {
    const url = `${import.meta.env.VITE_API_URL}${paths.API.PAYMENT_MANAGEMENT.UPDATE(paymentId)}`;

    const year = endDate.getFullYear();
    const month = String(endDate.getMonth() + 1).padStart(2, "0");
    const day = String(endDate.getDate()).padStart(2, "0");
    const hours = String(endDate.getHours()).padStart(2, "0");
    const minutes = String(endDate.getMinutes()).padStart(2, "0");
    const seconds = String(endDate.getSeconds()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    const response = await update(url, {
      operator_id: operatorId,
      name: name,
      end_date: formattedDate,
      rental_ids: rentalIds,
      beds_changed_count: bedsChangedAmount,
      amount: amount,
    });

    if (response.status === 200 && response?.data?.result) {
      return true;
    } else {
      throw new Error(response?.data?.message ?? response.message);
    }
  };

  /**
   * Function for create payment
   * status operators.
   *
   * @param operatorId
   * @param name
   * @param endDate
   * @param rentalIds
   * @param bedsChangedAmount
   * @param amount
   * @param status
   */
  const createPayment = async (
    operatorId: string,
    name: string,
    endDate: Date,
    rentalIds: Array<string | number>,
    bedsChangedAmount: number,
    amount: number,
    status: PaymentType = "PENDING",
  ) => {
    const url = `${import.meta.env.VITE_API_URL}${paths.API.PAYMENT_MANAGEMENT.CREATE}`;

    const year = endDate.getFullYear();
    const month = String(endDate.getMonth() + 1).padStart(2, "0");
    const day = String(endDate.getDate()).padStart(2, "0");
    const hours = String(endDate.getHours()).padStart(2, "0");
    const minutes = String(endDate.getMinutes()).padStart(2, "0");
    const seconds = String(endDate.getSeconds()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    const response = await post(url, {
      operator_id: operatorId,
      name: name,
      end_date: formattedDate,
      rental_ids: rentalIds,
      beds_changed_count: bedsChangedAmount,
      amount: amount,
      status: status,
    });

    if (response.status === 200 && response?.data?.result) {
      return true;
    } else {
      throw new Error(response?.data?.message ?? response.message);
    }
  };

  return {
    getAllPayment,
    updatePaymentStatus,
    updatePayment,
    deletePayment,
    createPayment,
  };
};
