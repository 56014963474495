export const navigateWithParams = (
  href?: string,
  queryParameter?: string,
  value?: string
) => {
  const url = new URL(href ?? window.location.href);

  if (queryParameter) {
    url.searchParams.set(queryParameter, value ?? "");
  }

  window.location.href = url.toString();
};
