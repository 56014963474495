import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import ArrowRight from "../../../../assets/icons/arrow-right-white.svg?react";
import ArrowUpRight from "../../../../assets/icons/arrow-up-right-white.svg?react";
import AcceptBookingGif from "../../../../assets/images/accept_booking.gif";
import {Button} from "../../../Common/Button/Button";
import {Controller, useForm} from "react-hook-form";
import {TextInput} from "../../../Common/TextInput/TextInput";
import {get, post} from "../../../../helpers/APIHelper";
import paths from "../../../../constants/paths";
import {ErrorMessage} from "../../../Common/ErrorMessage/ErrorMessage";
import {BookingConnectData} from "./BookingConnectModal";

type BookingConnectStep1Props = {
  onNext: () => void;
  setData: React.Dispatch<React.SetStateAction<BookingConnectData | undefined>>;
};

interface ConnectedBookingChoiceForm {
  hotel_id: string;
  legal_entity_id: string;
}

type ConnectedMeaningType = "one" | "multiple";
export const BookingConnectStep1: React.FC<BookingConnectStep1Props> = ({
  onNext = () => {},
  setData,
}) => {
  const {t} = useTranslation();
  const [selectedConnected, setSelectedConnected] =
    useState<ConnectedMeaningType>("one");
  const form = useForm<ConnectedBookingChoiceForm>({
    //hotelId Test 9729330
    //leId Test 429330
    defaultValues: {hotel_id: "", legal_entity_id: ""},
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleConnectedMeaning = (type: ConnectedMeaningType) => {
    setError("");
    switch (type) {
      case "one":
        form.setValue("legal_entity_id", "");
        break;
      case "multiple":
        form.setValue("hotel_id", "");
        break;
    }
    setSelectedConnected(type);
  };

  const onQuickConnect = () => {
    window.open(
      "https://admin.booking.com/?groups_redirect=%2Fhotel%2Fhoteladmin%2Fextranet_ng%2Fmanage%2Fchannel-manager%2Fwidget.html?provider_id=1547",
      "_blank",
      "width=900,height=900"
    );
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError("");
    let url = "";
    if (selectedConnected === "one") {
      url = paths.API.BOOKING.GET_PROPERTY_BY_ID(form.getValues("hotel_id"));
    } else {
      url = paths.API.BOOKING.GET_PROPERTIES_BY_ID(
        form.getValues("legal_entity_id")
      );
    }
    const res = await get(`${import.meta.env.VITE_API_URL}${url}`);
    if (res.data?.success) {
      setData((oldConnectedData) => {
        if (oldConnectedData) {
          return {
            ...oldConnectedData,
            ...form.getValues(),
            properties: res.data?.result,
          };
        } else {
          return {
            ...form.getValues(),
            properties: res.data?.result,
          };
        }
      });
      setTimeout(() => {
        setLoading(false);
        onNext();
      }, 200);
    } else {
      setError(res?.response?.data?.message);
    }
    setLoading(false);
  };

  return (
    <div className="flex flex-col gap-3">
      <span className="text-low-contrast">
        {t("Progression.bookingConnectStep1Info1")}
      </span>
      <span className="text-low-contrast">
        {t("Progression.bookingConnectStep1Info2")}
      </span>
      <ul className="list-none">
        <li className="text-low-contrast">
          {t("Progression.bookingConnectStep1InfoList1")}
        </li>
        <li className="text-low-contrast">
          {t("Progression.bookingConnectStep1InfoList2")}
        </li>
        <li className="text-low-contrast">
          {t("Progression.bookingConnectStep1InfoList3")}
        </li>
        <li className="text-low-contrast">
          {t("Progression.bookingConnectStep1InfoList4")}
        </li>
      </ul>
      <img src={AcceptBookingGif} className="w-full rounded-6px" />
      <div className="space-y-2">
        <Button
          onClick={onQuickConnect}
          RightIcon={ArrowUpRight}
          className="bui-button__text"
        >
          {t("Progression.bookingConnectStep1InfoButton")}
        </Button>
        <span className="flex text-low-contrast justify-center items-center">
          ({t("Progression.bookingConnectStep1InfoButton2")})
        </span>
      </div>

      <span className="text-high-contrast font-bold">
        {t("Progression.bookingConnectStep1Content1")}
      </span>

      <div className="flex">
        <Button
          type="secondary"
          divParentClassName="flex-none w-[50%]"
          buttonClassName={`rounded-r-none ${
            selectedConnected === "one" && "bg-none bg-element-border/40"
          }`}
          onClick={() => handleConnectedMeaning("one")}
          disabled={loading}
        >
          {t("Progression.oneRental")}
        </Button>
        <Button
          type="secondary"
          divParentClassName="flex-none w-[50%]"
          buttonClassName={`rounded-l-none ${
            selectedConnected === "multiple" && "bg-none bg-element-border/40"
          }`}
          onClick={() => handleConnectedMeaning("multiple")}
          disabled={loading}
        >
          {t("Progression.multipleRentals")}
        </Button>
      </div>

      <div>
        {selectedConnected === "one" ? (
          <Controller
            control={form.control}
            name="hotel_id"
            rules={{
              required: {
                value: true,
                message: t("Global.Errors.requiredField", {
                  fieldName: t("Progression.hotelId"),
                }),
              },
            }}
            render={({field: {value, onChange}}) => {
              return (
                <div className="flex-1">
                  <TextInput
                    label={t("Progression.hotelId")}
                    value={value}
                    onChangeText={onChange}
                    error={form.formState.errors.hotel_id?.message}
                    disabled={loading}
                  />
                </div>
              );
            }}
          />
        ) : (
          <Controller
            control={form.control}
            name="legal_entity_id"
            rules={{
              required: {
                value: true,
                message: t("Global.Errors.requiredField", {
                  fieldName: t("Progression.legalEntityId"),
                }),
              },
            }}
            render={({field: {value, onChange}}) => {
              return (
                <div className="flex-1">
                  <TextInput
                    label={t("Progression.legalEntityId")}
                    value={value}
                    onChangeText={onChange}
                    error={form.formState.errors.legal_entity_id?.message}
                    disabled={loading}
                  />
                </div>
              );
            }}
          />
        )}
      </div>

      <span className="text-low-contrast">
        {t("Progression.infoLegalEntityId")}
      </span>

      <ErrorMessage>{error}</ErrorMessage>

      <Button
        onClick={form.handleSubmit(handleSubmit)}
        RightIcon={ArrowRight}
        disabled={!form.formState.isValid}
        className="bui-button__text"
        loading={loading}
      >
        {t("Global.next")}
      </Button>
      <script src="https://bstatic.com/psb/provdash/js/booking-widget.js"></script>
    </div>
  );
};
