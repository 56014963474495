import React from "react";
import backgroundImage from "../../../../assets/images/info2_website.jpeg";
import Info2CardComponent from "./Info2CardComponent";
import useGuestWebsiteStore from "../../../../stores/guestWebsiteStore/useGuestWebsiteStore";

const Info2Component = () => {
  const {dataGuestWebsite} = useGuestWebsiteStore();
  const {content} = dataGuestWebsite || {};
  const {info2} = content || {};
  const infos = info2?.cards;
  const url = info2?.background ? info2?.background : backgroundImage;
  return (
    <div
      className="relative bg-cover bg-center py-12 pb-16  px-4"
      style={{backgroundImage: `url(${url})`}}
    >
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>{" "}
      {/* Overlay */}
      <div className="relative z-10 flex flex-col items-center justify-center h-full text-center text-white ">
        {/* Title */}
        <h1 className="text-3xl md:text-5xl font-bold leading-tight">
          {info2?.title}
        </h1>
        {/* Subtitle */}
        <h2 className="text-lg md:text-2xl mt-4">{info2?.subtitle}</h2>
      </div>
      <div className="flex flex-wrap justify-center gap-4 px-6 pt-4 w-full md:px-contentWebsite items-stretch">
        {infos?.map((info, index) => (
          <div className="relative w-full md:w-[315px] flex flex-col">
            <Info2CardComponent key={index} {...info} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Info2Component;
