import React from "react";

const SkeletonRentalsList = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 space-y-6 md:space-y-0">
      {Array.from({length: 6}).map((_, index) => (
        <div key={index} className="animate-pulse">
          <div className="shadow-lg rounded-lg overflow-hidden">
            {/* Image Section */}
            <div className="relative bg-gray-500 h-48 w-full"></div>

            {/* Content Section */}
            <div className="p-4">
              {/* Title */}
              <div className="h-4 bg-gray-500 rounded w-3/4 mb-2"></div>
              {/* Address */}
              <div className="h-3 bg-gray-500 rounded w-1/2 mb-4"></div>

              {/* Info Section */}
              <div className="flex items-center justify-between mt-4">
                <div className="h-3 bg-gray-500 rounded w-1/4"></div>
                <div className="h-3 bg-gray-500 rounded w-1/4"></div>
              </div>

              {/* Button */}
              <div className="mt-4 h-8 bg-gray-500 rounded w-full"></div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SkeletonRentalsList;
