import React from "react";
import SkeletonHeaderTemplate2 from "./SkeletonHeaderTemplate2";
import HeaderTemplate2 from "./HeaderTemplate2";

interface GuestWebsiteTemplate2HeaderComponentProps {
  loading: boolean;
}
const GuestWebsiteTemplate2HeaderComponent = ({
  loading,
}: GuestWebsiteTemplate2HeaderComponentProps) => {
  if (loading) {
    return <SkeletonHeaderTemplate2 />;
  }
  return <HeaderTemplate2 />
};

export default GuestWebsiteTemplate2HeaderComponent;
