/**
 * Hook that interacts with the API solely
 * for displaying iframes on the user's browser side.
 */
import paths from "../../constants/paths";
import { get } from "../../helpers/APIHelper";
import {
  RentalLightListItemResponse,
  UrlWebsiteResponse,
} from "../../types/GETTypes";

export function useIframe() {
  /**
   * Function to retrieve all accommodations
   * using the API KEY provided in the iframe.
   */
  const getRentals = async (
    apiKey: string,
    search: string = "",
  ): Promise<RentalLightListItemResponse[]> => {
    let url;
    if (search === "") {
      url =
        import.meta.env.VITE_API_URL +
        paths.API.WEBSITE.IFRAMES.RENTALS_API(apiKey);
    } else {
      url =
        import.meta.env.VITE_API_URL +
        paths.API.WEBSITE.IFRAMES.RENTALS_SEARCH_API(apiKey) +
        "?search=" +
        search;
    }

    const response = await get(url);

    if (response.status === 200 && response?.data?.result) {
      return response?.data?.result;
    } else {
      throw new Error(response?.data?.message ?? response.message);
    }
  };

  /**
   * Function to retrieve url allowed
   * for show the iframe integration.
   */
  const getUrlAllowed = async (apiKey: string): Promise<UrlWebsiteResponse> => {
    const url =
      import.meta.env.VITE_API_URL +
      paths.API.WEBSITE.IFRAMES.URL_ALLOWED(apiKey);
    const response = await get(url);

    if (response.status === 200 && response?.data?.result) {
      return response?.data?.result;
    } else {
      throw new Error(response?.data?.message ?? response.message);
    }
  };

  return {
    getRentals,
    getUrlAllowed,
  };
}
