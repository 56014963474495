import React from "react";
import RentalsListComponent from "../../Common/Template2.0/RentalsListComponent";
import SearchBannerComponent from "../../Common/Template2.0/SearchBannerComponent";
import useGuestWebsiteStore from "../../../../stores/guestWebsiteStore/useGuestWebsiteStore";
import SkeletonRentalsList from "../../Common/Template2.0/SkeletonRentalsList";

interface GuestWebsiteTemplate2RentalsComponentProps {
  loading: boolean;
}
const GuestWebsiteTemplate2RentalsComponent = ({
  loading,
}: GuestWebsiteTemplate2RentalsComponentProps) => {
  const {dataGuestWebsite, loadingRentalsSearch} = useGuestWebsiteStore();
  const {rentals,styles} = dataGuestWebsite || {};
  const {color_background} = styles || {};

  return (
    <div className="" style={{backgroundColor:color_background}}>
      <div className="p-4 md:px-contentWebsite">
        <SearchBannerComponent />
      </div>
      <div className="p-4">
        {loadingRentalsSearch || loading ? (
          <SkeletonRentalsList />
        ) : (
          <RentalsListComponent rentals={rentals} />
        )}
      </div>
    </div>
  );
};

export default GuestWebsiteTemplate2RentalsComponent;
