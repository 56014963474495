import React, { useEffect, useState } from "react";
import { RightModal } from "../Common/RightModal/RightModal";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { NumberInput } from "../Common/NumberInput/NumberInput";
import { ValueType } from "../../types/commonTypes";
import { Controller, useForm } from "react-hook-form";
import { CalendarInput } from "../Common/CalendarInput/CalendarInput";
import { Switch } from "../Common/Switch/Switch";
import paths from "../../constants/paths";
import { useTablePage } from "../../hooks/useTablePage";
import { MultiSelectRentalsTable } from "../Common/MultiSelectRentalsTable/MultiSelectRentalsTable";
import { Button } from "../Common/Button/Button";
import { Daysfilter } from "../Common/DaysFilter/DaysFilter";
import { post } from "../../helpers/APIHelper";
import { ErrorMessage } from "../Common/ErrorMessage/ErrorMessage";
import { SimpleRadio } from "../Common/SimpleRadio/SimpleRadio";
import { InputSelect } from "../Common/InputSelect/InputSelect";
import { CalendarRestrictionUnavailabilityReasonEnum } from "../../enums/GETenums";
import { CalendarInputValue } from "../Common/CalendarInput/CalendarInput.type";
import { TextInput } from "../Common/TextInput/TextInput";

type formSchema = {
  edit: "price" | "availability";
  price: number;
  isAvailable: number;
  unavailabilityReason: string;
  unavailabilityReasonOther: string;
  range: [Date | null, Date | null];
  days: {
    isActive: boolean;
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
  };
  rentals: {
    isActive: false;
    ids: ValueType[];
  };
};

export const CalendarPriceAndAvailabilityModal: React.FC<{
  isAvailable: number;
  price: number;
  isVisible: boolean;
  reason: CalendarRestrictionUnavailabilityReasonEnum;
  reasonOther: string | undefined;
  rental: any;
  date: moment.Moment;
  onClose: () => void;
  onSuccessPrice: (
    startDate: string,
    endDate: string,
    weekDays: string,
    rentalIds: ValueType[],
    price: number,
  ) => void;
  onSuccessAvailability: (
    startDate: string,
    endDate: string,
    weekDays: string,
    rentalIds: ValueType[],
    isAvailable: number,
    reason: string,
    reasonOther: string | undefined,
  ) => void;
}> = ({
  isVisible,
  rental,
  isAvailable,
  reason,
  reasonOther,
  price,
  date,
  onClose,
  onSuccessPrice,
  onSuccessAvailability,
}) => {
  const { t } = useTranslation();
  const form = useForm<formSchema>({
    defaultValues: {
      edit: "price",
      price: 123,
      isAvailable: 1,
      unavailabilityReason:
        CalendarRestrictionUnavailabilityReasonEnum.NOT_AVAILABLE,
      unavailabilityReasonOther: "",
      range: [date.toDate(), date.toDate()],
      days: {
        isActive: false,
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true,
        sunday: true,
      },
      rentals: {
        isActive: false,
        ids: [],
      },
    },
  });

  const [selectedRentals, setSelectedRentals] = useState<ValueType[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const rentalsTable = useTablePage(
    `${import.meta.env.VITE_API_URL}${paths.RENTALS}`,
    "rentals",
  );

  useEffect(() => {
    setError(null);

    if (isVisible) {
      rentalsTable.handleFilterCustom("exclude_ids", rental?.id);
    } else {
      form.reset();
      setSelectedRentals([]);
    }
  }, [isVisible]);

  useEffect(() => {
    form.setValue("range", [date.toDate(), date.toDate()]);
    form.setValue("days", {
      isActive: false,
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    });
    form.setValue("rentals", {
      isActive: false,
      ids: [],
    });
  }, [date]);

  useEffect(() => {
    form.setValue("edit", isAvailable === 0 ? "availability" : "price");
    form.setValue("isAvailable", isAvailable);
  }, [isAvailable]);

  useEffect(() => {
    form.setValue("price", price);
  }, [price]);

  useEffect(() => {
    form.setValue(
      "unavailabilityReason",
      reason ?? CalendarRestrictionUnavailabilityReasonEnum.NOT_AVAILABLE,
    );
  }, [reason]);

  useEffect(() => {
    form.setValue("unavailabilityReasonOther", reasonOther ?? "");
  }, [reasonOther]);

  const handleSelectRental = (id: ValueType) => {
    const exists = selectedRentals?.findIndex((i) => i === id) > -1;

    if (exists) {
      setSelectedRentals((prev) => [...prev.filter((i) => i !== id)]);
    } else {
      setSelectedRentals((prev) => [...prev, id]);
    }
  };

  useEffect(() => {
    form.setValue("rentals.ids", selectedRentals);
  }, [selectedRentals]);

  function getDaysString(days: {
    isActive: boolean;
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
  }) {
    // Vérifie si isActive est à false
    if (!days.isActive) {
      return "0,1,2,3,4,5,6";
    }

    // Tableau pour stocker les jours actifs
    const activeDays = [];

    // Vérifie chaque jour et ajoute sa valeur si true
    if (days.monday) activeDays.push(1);
    if (days.tuesday) activeDays.push(2);
    if (days.wednesday) activeDays.push(3);
    if (days.thursday) activeDays.push(4);
    if (days.friday) activeDays.push(5);
    if (days.saturday) activeDays.push(6);
    if (days.sunday) activeDays.push(0);

    // Retourne la chaîne de caractères des jours actifs, séparés par des virgules
    return activeDays.join(",");
  }

  const handleSave = async (values: formSchema) => {
    setLoading(true);
    setError(null);

    const rental_ids = values.rentals.isActive
      ? [rental.id, ...values.rentals.ids].join(",")
      : rental.id.toString();

    let res = null;

    if (values.edit === "price") {
      res = await post(
        `${import.meta.env.VITE_API_URL}${paths.UPDATE_PRICES}`,
        {
          rental_ids,
          start_date: moment(values.range[0]).format("YYYY-MM-DD"),
          end_date: moment(values.range[1]).format("YYYY-MM-DD"),
          week_days: getDaysString(values.days),
          price: values.price,
        },
      );
    } else if (values.edit === "availability") {
      res = await post(
        `${import.meta.env.VITE_API_URL}${paths.UPDATE_AVALABILITIES}`,
        {
          rental_ids,
          start_date: moment(values.range[0]).format("YYYY-MM-DD"),
          end_date: moment(values.range[1]).format("YYYY-MM-DD"),
          week_days: getDaysString(values.days),
          is_available: values.isAvailable,
          unavailability_reason: values.unavailabilityReason,
          unavailability_reason_other: values.unavailabilityReasonOther,
        },
      );
    }

    setLoading(false);

    if (res?.data?.success) {
      if (values.edit === "price") {
        onSuccessPrice(
          moment(values.range[0]).format("YYYY-MM-DD"),
          moment(values.range[1]).format("YYYY-MM-DD"),
          getDaysString(values.days),
          values.rentals.isActive
            ? [rental.id, ...values.rentals.ids]
            : [rental.id],
          values.price,
        );
      } else if (values.edit === "availability") {
        onSuccessAvailability(
          moment(values.range[0]).format("YYYY-MM-DD"),
          moment(values.range[1]).format("YYYY-MM-DD"),
          getDaysString(values.days),
          values.rentals.isActive
            ? [rental.id, ...values.rentals.ids]
            : [rental.id],
          values.isAvailable,
          values.unavailabilityReason,
          values.unavailabilityReasonOther,
        );
      }
    } else {
      setError(res.response.data.message);
    }
  };

  return (
    <RightModal
      title={`${t("Calendar.PriceAndAvailabilityModal.title")} ${rental?.name}`}
      isVisible={isVisible}
      onClose={onClose}
    >
      <form onSubmit={form.handleSubmit(handleSave)} className="w-full">
        <div className="flex flex-col w-full h-full">
          {/* Fields */}
          <div className="flex flex-1 flex-col gap-5 w-full overflow-y-scroll">
            <Controller
              control={form.control}
              name="edit"
              render={({ field: { value, onChange } }) => (
                <div className="flex gap-1">
                  <SimpleRadio
                    value={value === "price"}
                    onClick={() => onChange("price")}
                  />
                  <p
                    className="font-semibold text-high-contrast cursor-pointer mr-2"
                    onClick={() => onChange("price")}
                  >
                    {t("Calendar.PriceAndAvailabilityModal.editPrice")}
                  </p>
                  <SimpleRadio
                    value={value === "availability"}
                    onClick={() => onChange("availability")}
                  />
                  <p
                    className="font-semibold text-high-contrast cursor-pointer"
                    onClick={() => onChange("availability")}
                  >
                    {t("Calendar.PriceAndAvailabilityModal.editAvailability")}
                  </p>
                </div>
              )}
            />

            {form.watch("edit") === "price" && (
              <Controller
                control={form.control}
                name="price"
                render={({ field: { value, onChange } }) => (
                  <div className="w-full">
                    <NumberInput
                      label={t("Calendar.PriceAndAvailabilityModal.price")}
                      min={0}
                      currency="€"
                      acceptDecimal={false}
                      value={value}
                      onChangeText={onChange}
                      disabled={loading}
                    />
                  </div>
                )}
              />
            )}

            {form.watch("edit") === "availability" && (
              <Controller
                control={form.control}
                name="isAvailable"
                render={({ field: { value, onChange } }) => (
                  <div className="flex gap-1">
                    <SimpleRadio
                      value={value === 1}
                      onClick={() => onChange(1)}
                    />
                    <p
                      className="font-semibold text-high-contrast cursor-pointer mr-2"
                      onClick={() => onChange(1)}
                    >
                      {t("Calendar.PriceAndAvailabilityModal.isAvailable")}
                    </p>
                    <SimpleRadio
                      value={value === 0}
                      onClick={() => onChange(0)}
                    />
                    <p
                      className="font-semibold text-high-contrast cursor-pointer"
                      onClick={() => onChange(0)}
                    >
                      {t("Calendar.PriceAndAvailabilityModal.isNotAvailable")}
                    </p>
                  </div>
                )}
              />
            )}

            {form.watch("edit") === "availability" &&
              form.watch("isAvailable") === 0 && (
                <Controller
                  control={form.control}
                  name="unavailabilityReason"
                  render={({ field: { value, onChange } }) => (
                    <div className="w-full">
                      <InputSelect
                        label={t(
                          "Calendar.PriceAndAvailabilityModal.unavailabilityReason",
                        )}
                        selectedValue={value}
                        onSelect={onChange}
                        disabled={loading}
                        items={[
                          {
                            value:
                              CalendarRestrictionUnavailabilityReasonEnum.NOT_AVAILABLE,
                            label: t(
                              "Calendar.UnavailabilityReason.unavailable",
                            ),
                          },
                          {
                            value:
                              CalendarRestrictionUnavailabilityReasonEnum.HOLIDAYS,
                            label: t("Calendar.UnavailabilityReason.holidays"),
                          },
                          {
                            value:
                              CalendarRestrictionUnavailabilityReasonEnum.CONSTRUCTION,
                            label: t(
                              "Calendar.UnavailabilityReason.construction",
                            ),
                          },
                          {
                            value:
                              CalendarRestrictionUnavailabilityReasonEnum.HOUSEWORK,
                            label: t("Calendar.UnavailabilityReason.housework"),
                          },
                          {
                            value:
                              CalendarRestrictionUnavailabilityReasonEnum.CLOSED,
                            label: t("Calendar.UnavailabilityReason.closed"),
                          },
                          {
                            value:
                              CalendarRestrictionUnavailabilityReasonEnum.OWNER,
                            label: t("Calendar.UnavailabilityReason.owner"),
                          },
                          {
                            value:
                              CalendarRestrictionUnavailabilityReasonEnum.OTHER,
                            label: t("Calendar.UnavailabilityReason.other"),
                          },
                        ]}
                      />
                    </div>
                  )}
                />
              )}

            {form.watch("edit") === "availability" &&
              form.watch("isAvailable") === 0 &&
              form.watch("unavailabilityReason") ===
                CalendarRestrictionUnavailabilityReasonEnum.OTHER && (
                <Controller
                  control={form.control}
                  name="unavailabilityReasonOther"
                  render={({ field: { value, onChange } }) => (
                    <div className="w-full">
                      <TextInput
                        placeholder={t(
                          "Calendar.PriceAndAvailabilityModal.unavailabilityReason",
                        )}
                        value={value}
                        onChangeText={onChange}
                      />
                    </div>
                  )}
                />
              )}

            <Controller
              control={form.control}
              name="range"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                const handleChange = (value: CalendarInputValue) => {
                  if (
                    !Array.isArray(value) ||
                    value.length !== 2 ||
                    !value[0] ||
                    !value[1]
                  ) {
                    form.setError("range", {
                      message: t("Calendar.rangeRequired"),
                    });
                  } else {
                    form.clearErrors("range");
                  }
                  onChange(value);
                };

                return (
                  <div>
                    <CalendarInput
                      label={t("Calendar.PriceAndAvailabilityModal.dates")}
                      classNames={{ button: "h-8" }}
                      dateType="range"
                      value={value}
                      height="full"
                      onChangeDate={handleChange}
                      onChangeEndDateInput={() => {}}
                      onChangeStartDateInput={() => {}}
                      error={error?.message}
                    />
                  </div>
                );
              }}
            />

            <Controller
              control={form.control}
              name="days"
              render={({ field: { value, onChange } }) => (
                <Daysfilter
                  value={value}
                  onChange={onChange}
                  disabled={loading}
                />
              )}
            />

            <Controller
              control={form.control}
              name="rentals"
              render={({ field: { value, onChange } }) => (
                <div className="flex flex-col gap-3">
                  <div className="flex flex-col">
                    <div
                      className="flex justify-between items-center cursor-pointer"
                      onClick={() =>
                        onChange({ ...value, isActive: !value.isActive })
                      }
                    >
                      <p className="font-semibold text-low-contrast">
                        {t("Global.applyOnOtherRentals")}
                      </p>

                      <Switch value={value.isActive} disabled={loading} />
                    </div>

                    {value.isActive && (
                      <div className="mt-2">
                        <MultiSelectRentalsTable
                          disabled={loading}
                          selectedRentals={selectedRentals}
                          tablePage={rentalsTable}
                          onSelectRental={handleSelectRental}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
            />

            <ErrorMessage>{error}</ErrorMessage>
          </div>

          {/* Buttons */}
          <div className="flex gap-4 border-t-1 border-element-border pt-4 mt-4">
            <Button type="secondary" onClick={onClose} disabled={loading}>
              {t("Global.cancel")}
            </Button>

            {form.watch("edit") === "price" && (
              <Button loading={loading}>
                {t("Calendar.PriceAndAvailabilityModal.savePrice")}
              </Button>
            )}

            {form.watch("edit") === "availability" && (
              <Button loading={loading}>
                {t("Calendar.PriceAndAvailabilityModal.saveAvailability")}
              </Button>
            )}
          </div>
        </div>
      </form>
    </RightModal>
  );
};
