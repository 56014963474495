import React from "react";
import { useTranslation } from "react-i18next";
import ArrowUpRight from "../../../../assets/icons/arrow-up-right-white.svg?react";
import paths from "../../../../constants/paths";
import { ValueType } from "../../../../types/commonTypes";
import { Button } from "../../../Common/Button/Button";
import { Separator } from "../../../Common/Separator/Separator";
import { TextInput } from "../../../Common/TextInput/TextInput";

export const IntegrateContent: React.FC<{ apiKey: ValueType | undefined }> = ({
  apiKey,
}) => {
  const { t } = useTranslation();

  const urlRental =
    import.meta.env.VITE_API_URL.replace(
      "/api",
      ":" + import.meta.env.VITE_PORT
    ) +
    paths.WEBSITES.IFRAMES.RENTAL.replace(":apiKey", (apiKey as string) ?? "");

  const urlRentals =
    import.meta.env.VITE_API_URL.replace(
      "/api",
      ":" + import.meta.env.VITE_PORT
    ) +
    paths.WEBSITES.IFRAMES.RENTALS.replace(":apiKey", (apiKey as string) ?? "");

  const urlRentalSearchEngine =
    import.meta.env.VITE_API_URL.replace(
      "/api",
      ":" + import.meta.env.VITE_PORT
    ) +
    paths.WEBSITES.IFRAMES.RENTALS_SEARCH_ENGINE.replace(
      ":apiKey",
      (apiKey as string) ?? ""
    );

  return (
    <div className="flex flex-col flex-1 overflow-y-auto gap-y-5">
      <div className="flex flex-col space-y-3">
        <p className="text-base font-semibold text-high-contrast">
          {t("Rental.Website.IntegrateModal.rentalIframeTitle")}
        </p>

        <p className="text-xs font-light text-low-contrast">
          {t("Rental.Website.IntegrateModal.rentalIframeDescription")}
        </p>

        <div>
          <TextInput
            type="clipboard"
            label={t("Rental.Website.IntegrateModal.rentalIframeLabel")}
            placeholder="CODE IFRAME"
            value={`<iframe src="${urlRental}" width="100%" height="100%" id="booking-rental"></iframe>`}
          />
        </div>
        <Separator accent="dark" />
      </div>

      <div className="flex flex-col space-y-3">
        <p className="text-base font-semibold text-high-contrast">
          {t("Rental.Website.IntegrateModal.rentalIframeTitle")}
        </p>

        <p className="text-xs font-light text-low-contrast">
          {t("Rental.Website.IntegrateModal.rentalIframeDescription")}
        </p>

        <TextInput
          type="clipboard"
          value={`<iframe src="${urlRentalSearchEngine}" width="100%" height="100%" id="booking-engine-rentals"></iframe>`}
          label={t("Rental.Website.IntegrateModal.rentalIframeLabel")}
          disabled={true}
        />
        <Separator accent="dark" />
      </div>

      <div className="flex flex-col space-y-3">
        <p className="text-base font-semibold text-high-contrast">
          {t("Rental.Website.IntegrateModal.rentalIframeListTitle")}
        </p>

        <p className="text-xs font-light text-low-contrast">
          {t("Rental.Website.IntegrateModal.rentalIframeListDescription")}
        </p>

        <div>
          <TextInput
            type="clipboard"
            label={t("Rental.Website.IntegrateModal.rentalIframeListLabel")}
            placeholder="CODE IFRAME"
            value={`<iframe src="${urlRentals}" width="100%" height="100%" id="booking-rentals"></iframe>`}
          />
        </div>

        <Separator accent="dark" />
      </div>

      <div className="w-max">
        {/* TODO: [RentalIntegateModal] - Ajouter le lien vers le tuto Crisp */}
        <Button RightIcon={ArrowUpRight} disabled={true}>
          {t("Rental.Website.IntegrateModal.seeTutorial")}
        </Button>
      </div>

      <div>
        <TextInput
          type="clipboard"
          value={apiKey?.toString()}
          label={t("Rental.Website.IntegrateModal.webkeyLabel")}
          disabled={true}
        />
      </div>
    </div>
  );
};
