import { RentalDescriptionPayload } from "../../components/Rental/Common/Description/DescriptionCard.type";
import { RentalInformationsPayload } from "../../components/Rental/Infos/Informations/InformationsCard.type";
import { RentalLocalisationPayload } from "../../components/Rental/Infos/Localisation/LocalisationCard.type";
import { RentalPricingRulesRequest } from "../../components/Rental/Pricing/PricingRules/PricingRules.type";
import { RentalWebsiteRulesPayload } from "../../components/Rental/Website/Rules/WebsiteRulesCard.type";
import { RentalWebsiteTermsPayload } from "../../components/Rental/Website/Terms/TermsCard.type";

import paths from "../../constants/paths";
import { get, post } from "../../helpers/APIHelper";
import { RentalRules2 } from "../../pages/RentalPage/RentalPage.type";
import { ValueType } from "../../types/commonTypes";
import {
  RentalDescriptionResponse,
  RentalInformationsResponse,
  RentalLightListItemResponse,
  RentalMultipleDepositUpdateResponse,
  RentalResponse,
  RentalWebsiteResponse,
  RentalWebsiteRulesResponse,
} from "../../types/GETTypes";

export const useRentalList = async (
  onSuccess: (data: any) => void,
  onError: (message: string | undefined | null) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  if (typeof onStart === "function") {
    onStart();
  }
  try {
    const response = await get(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS}`
    );
    if (response?.data?.success) {
      onSuccess(response?.data?.result?.rentals);
    } else {
      onError(response?.data?.message ?? response.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

/*
    Displays a list of all rental access without pagination.
 */
export const useAllRentalAccessList = async (
  onSuccess: (data: any) => void,
  onError: (message: string | undefined | null) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  if (typeof onStart === "function") {
    onStart();
  }
  try {
    const response = await get(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS}`
    );
    if (response?.data?.success) {
      onSuccess(response?.data?.result?.rentals_access);
    } else {
      onError(response?.data?.message ?? response.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

/**
 * Updates rental information by making an HTTP POST request.
 *
 * @param {string | undefined} rentalId - The ID of the rental to be updated.
 * @param {RentalInformationsPayload} data - The data to be sent in the request.
 * @param {(rentalInformations: RentalInformationsResponse) => void} onSuccess - Callback function called when the request is successful.
 * @param {(message: string | undefined) => void} onError - Callback function called when there is an error.
 * @param {() => void} [onStart] - Optional callback function called at the start of the request.
 * @param {() => void} [onEnd] - Optional callback function called at the end of the request, regardless of success or failure.
 *
 * @returns {Promise<void>} - A promise that resolves when the request is complete.
 */
export const useRentalInformationsUpdate = async (
  rentalId: string | undefined,
  data: RentalInformationsPayload,
  onSuccess: (rentalInformations: RentalInformationsResponse) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
): Promise<void> => {
  // Call onStart callback if provided
  if (typeof onStart === "function") {
    onStart();
  }

  try {
    // Commented-out code for potential future use
    // if (getValues("parentRental") !== 0) {
    //   data.parent_dependency_id = getValues("parentRental");
    // }

    // const childrenDependenciesString: string = childRentalFields
    //   .map((item) => (item.value !== 0 ? item.value : ""))
    //   .join(",");

    // if (childrenDependenciesString !== "") {
    //   data.children_dependencies_ids = childrenDependenciesString;
    // }

    // Make the HTTP POST request to update rental information
    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS_UPDATE}/${rentalId}${
        paths.API.INFORMATIONS_DETAILS
      }`,
      data
    );

    // Handle the response
    if (res?.data?.success) {
      onSuccess(res.data?.result);
    } else {
      onError(res?.response?.data?.message ?? res.message);
    }
  } catch (error: any) {
    // Handle any errors that occur during the request
    onError(error.message);
  } finally {
    // Call onEnd callback if provided
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

export const useRentalInfosDescriptionUpdate = async ({
  rentalId,
  data,
  onSuccess,
  onError,
  onStart,
  onEnd,
}: {
  rentalId: ValueType | undefined;
  data: RentalDescriptionPayload;
  onSuccess: (rentalDescription: RentalDescriptionResponse) => void;
  onError: (message: string | null) => void;
  onStart?: () => void;
  onEnd?: () => void;
}): Promise<void> => {
  // Call onStart callback if provided
  if (typeof onStart === "function") {
    onStart();
  }

  try {
    // Make the HTTP POST request to update rental description
    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS_UPDATE}/${rentalId}${
        paths.API.DESCRIPTION_DETAILS
      }`,
      data
    );

    if (res?.data?.success) {
      onSuccess(res.data?.result);
    } else {
      onError(res?.response?.data?.message ?? res.message);
    }
  } catch (error: any) {
    // Handle any errors that occur during the request
    onError(error.message);
  } finally {
    // Call onEnd callback if provided
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

/**
 * Updates the rental pricing rules for a given rental ID.
 *
 * @param {string | undefined} rentalId - The ID of the rental to update.
 * @param {RentalPricingRulesRequest} data - The data containing the new pricing rules.
 * @param {(pricingRules: RentalRules2) => void} onSuccess - Callback function to be called on successful update.
 * @param {(message: string | undefined) => void} onError - Callback function to be called on error.
 * @param {() => void} [onStart] - Optional callback function to be called at the start of the update process.
 * @param {() => void} [onEnd] - Optional callback function to be called at the end of the update process.
 * @returns {Promise<void>} - A promise that resolves when the update process is complete.
 */
export const useRentalPricingRulesUpdate = async (
  rentalId: string | undefined,
  data: RentalPricingRulesRequest,
  onSuccess: (pricingRules: RentalRules2) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
): Promise<void> => {
  if (typeof onStart === "function") {
    onStart();
  }
  try {
    const response = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS_UPDATE}/${rentalId}${
        paths.API.RENTAL.UPDATE_PRICING_RULES
      }`,
      data
    );
    if (response?.data?.success) {
      onSuccess(response?.data?.result?.tarification.rules);
    } else {
      onError(response?.data?.message ?? response.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

/**
 * Updates the localisation information for a rental.
 *
 * @param rentalId - The unique identifier of the rental to update. Can be undefined.
 * @param data - The payload containing the localisation information to update.
 * @param onSuccess - Callback function to be called when the update is successful. Receives the updated rental response.
 * @param onError - Callback function to be called when an error occurs. Receives an error message.
 * @param onStart - Optional callback function to be called before the update process starts.
 * @param onEnd - Optional callback function to be called after the update process ends, regardless of success or failure.
 *
 * @returns A promise that resolves when the update process is complete.
 */
export const useRentalLocalisationUpdate = async (
  rentalId: string | undefined,
  data: RentalLocalisationPayload,
  onSuccess: (rental: RentalResponse) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
): Promise<void> => {
  if (typeof onStart === "function") {
    onStart();
  }
  try {
    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS_UPDATE}/${rentalId}${
        paths.API.INFORMATIONS_LOCALISATION
      }`,
      data
    );

    // Handle the response
    if (res?.data?.success) {
      onSuccess(res.data?.result);
    } else {
      onError(res?.data?.message ?? res.message);
    }
  } catch (error: any) {
    // Handle any errors that occur during the request
    onError(error.message);
  } finally {
    // Call onEnd callback if provided
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

export type RentalMultipleDepositUpdatePayload = {
  rentals: {
    rental_id: ValueType;
    deposit: number;
  }[];
};
export const useRentalMultipleDepositUpdate = async ({
  data,
  onSuccess,
  onError,
  onStart,
  onEnd,
}: {
  data: RentalMultipleDepositUpdatePayload;
  onSuccess: (data: RentalMultipleDepositUpdateResponse) => void;
  onError: (message: string | undefined) => void;
  onStart?: () => void;
  onEnd?: () => void;
}): Promise<void> => {
  // Call onStart callback if provided
  if (typeof onStart === "function") {
    onStart();
  }

  try {
    // Make the HTTP POST request to update rental information
    const res = await post(
      `${import.meta.env.VITE_API_URL}${
        paths.API.RENTALS_UPDATE_MULTIPLE_DEPOSIT
      }`,
      data
    );

    // Handle the response
    if (res?.data?.success) {
      onSuccess(res.data?.result);
    } else {
      onError(res?.response?.data?.message ?? res.message);
    }
  } catch (error: any) {
    // Handle any errors that occur during the request
    onError(error.message);
  } finally {
    // Call onEnd callback if provided
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

/**
 * Updates the rental website rules for a given rental ID.
 *
 * @param {Object} params - The parameters for the update operation.
 * @param {ValueType | undefined} params.rentalId - The ID of the rental to update.
 * @param {RentalWebsiteRulesPayload} params.data - The data payload containing the new rules.
 * @param {Function} params.onSuccess - Callback function to be called on successful update.
 * @param {Function} params.onError - Callback function to be called on error with an error message.
 * @param {Function} [params.onStart] - Optional callback function to be called at the start of the operation.
 * @param {Function} [params.onEnd] - Optional callback function to be called at the end of the operation.
 *
 * @returns {Promise<void>} A promise that resolves when the operation is complete.
 *
 * @throws {Error} Throws an error if the rental ID is not defined.
 */
export const useRentalWebsiteRulesUpdate = async ({
  rentalId,
  data,
  onSuccess,
  onError,
  onStart,
  onEnd,
}: {
  rentalId: ValueType | undefined;
  data: RentalWebsiteRulesPayload;
  onSuccess: (rentalWebsiteRules: RentalWebsiteRulesResponse) => void;
  onError: (message: string | null) => void;
  onStart?: () => void;
  onEnd?: () => void;
}): Promise<void> => {
  if (typeof onStart === "function") {
    onStart();
  }

  if (!rentalId) {
    throw new Error("Rental ID is not defined !");
  }

  try {
    const response = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTAL.UPDATE_WEBSITE_RULES(
        rentalId
      )}`,
      data
    );
    if (response?.data?.success) {
      onSuccess(response?.data?.result.rules);
    } else {
      onError(response?.data?.message ?? response.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

/**
 * Updates the description of a rental website.
 *
 * @param {Object} params - The parameters for the update operation.
 * @param {ValueType | undefined} params.rentalId - The ID of the rental to update.
 * @param {RentalDescriptionPayload} params.data - The payload containing the new description data.
 * @param {Function} params.onSuccess - Callback function to be called on successful update.
 * @param {Function} params.onError - Callback function to be called if an error occurs.
 * @param {Function} [params.onStart] - Optional callback function to be called at the start of the operation.
 * @param {Function} [params.onEnd] - Optional callback function to be called at the end of the operation.
 *
 * @returns {Promise<void>} A promise that resolves when the operation is complete.
 *
 * @throws {Error} Throws an error if rentalId is not defined.
 */
export const useRentalWebsiteDescriptionUpdate = async ({
  rentalId,
  data,
  onSuccess,
  onError,
  onStart,
  onEnd,
}: {
  rentalId: ValueType | undefined;
  data: RentalDescriptionPayload;
  onSuccess: (rentalWebsite: RentalWebsiteResponse) => void;
  onError: (message: string | null) => void;
  onStart?: () => void;
  onEnd?: () => void;
}): Promise<void> => {
  // Call onStart callback if provided
  if (typeof onStart === "function") {
    onStart();
  }

  if (!rentalId) {
    throw new Error("RentalId is not defined ! ");
  }

  try {
    // Make the HTTP POST request to update rental description
    const res = await post(
      `${
        import.meta.env.VITE_API_URL
      }${paths.API.RENTAL.UPDATE_WEBSITE_DESCRIPTION(rentalId)}`,
      data
    );

    if (res?.data?.success) {
      onSuccess(res.data?.result);
    } else {
      onError(res?.response?.data?.message ?? res.message);
    }
  } catch (error: any) {
    // Handle any errors that occur during the request
    onError(error.message);
  } finally {
    // Call onEnd callback if provided
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

export type RentalWebsiteServicesPayload = {
  card_names: string[] | null;
  card_photos: File[] | null;
  del_ids: number[] | null;
  order_ids: number[] | null;
};

/**
 * Updates the rental website services for a given rental ID.
 *
 * @param {Object} params - The parameters for the update operation.
 * @param {ValueType | undefined} params.rentalId - The ID of the rental to update.
 * @param {RentalWebsiteServicesPayload} params.data - The data payload for the update.
 * @param {(rentalWebsite: RentalWebsiteResponse) => void} params.onSuccess - Callback function to be called on successful update.
 * @param {(message: string | null) => void} params.onError - Callback function to be called if an error occurs.
 * @param {() => void} [params.onStart] - Optional callback function to be called at the start of the operation.
 * @param {() => void} [params.onEnd] - Optional callback function to be called at the end of the operation.
 *
 * @returns {Promise<void>} A promise that resolves when the operation is complete.
 *
 * @throws {Error} Throws an error if rentalId is not defined.
 */
export const useRentalWebsiteServicesUpdate = async ({
  rentalId,
  data,
  onSuccess,
  onError,
  onStart,
  onEnd,
}: {
  rentalId: ValueType | undefined;
  data: RentalWebsiteServicesPayload;
  onSuccess: (rentalWebsite: RentalWebsiteResponse) => void;
  onError: (message: string | null) => void;
  onStart?: () => void;
  onEnd?: () => void;
}): Promise<void> => {
  // Call onStart callback if provided
  if (typeof onStart === "function") {
    onStart();
  }

  if (!rentalId) {
    throw new Error("RentalId is not defined ! ");
  }

  try {
    const newData = new FormData();
    data.card_names?.forEach((name, index) => {
      newData.append(`card_names[${index}]`, name);
    });

    data.card_photos?.forEach((photo, index) => {
      newData.append(`card_photos[${index}]`, photo);
    });

    data.del_ids?.forEach((id, index) => {
      newData.append(`del_ids[${index}]`, id.toString());
    });

    data.order_ids?.forEach((id, index) => {
      newData.append(`order_ids[${index}]`, id.toString());
    });

    // Make the HTTP POST request to update rental description
    const res = await post(
      `${
        import.meta.env.VITE_API_URL
      }${paths.API.RENTAL.UPDATE_WEBSITE_SERVICES(rentalId)}`,
      newData
    );

    if (res?.data?.success) {
      onSuccess(res.data?.result);
    } else {
      onError(res?.response?.data?.message ?? res.message);
    }
  } catch (error: any) {
    // Handle any errors that occur during the request
    onError(error.message);
  } finally {
    // Call onEnd callback if provided
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

/**
 * Updates the rental website terms for a given rental ID.
 *
 * @param {Object} params - The parameters for the update operation.
 * @param {ValueType | undefined} params.rentalId - The ID of the rental to update.
 * @param {RentalWebsiteTermsPayload} params.data - The payload containing the updated terms.
 * @param {Function} params.onSuccess - Callback function to be called on successful update.
 * @param {Function} params.onError - Callback function to be called on error with an error message.
 * @param {Function} [params.onStart] - Optional callback function to be called at the start of the operation.
 * @param {Function} [params.onEnd] - Optional callback function to be called at the end of the operation.
 *
 * @returns {Promise<void>} A promise that resolves when the operation is complete.
 *
 * @throws {Error} Throws an error if the rentalId is not defined.
 */
export const useRentalWebsiteTermsUpdate = async ({
  rentalId,
  data,
  onSuccess,
  onError,
  onStart,
  onEnd,
}: {
  rentalId: ValueType | undefined;
  data: RentalWebsiteTermsPayload;
  onSuccess: (rentalWebsite: RentalWebsiteResponse) => void;
  onError: (message: string | null) => void;
  onStart?: () => void;
  onEnd?: () => void;
}): Promise<void> => {
  // Call onStart callback if provided
  if (typeof onStart === "function") {
    onStart();
  }

  if (!rentalId) {
    throw new Error("RentalId is not defined ! ");
  }

  try {
    // Make the HTTP POST request to update rental description
    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTAL.UPDATE_WEBSITE_TERMS(
        rentalId
      )}`,
      data
    );

    if (res?.data?.success) {
      onSuccess(res.data?.result);
    } else {
      onError(res?.response?.data?.message ?? res.message);
    }
  } catch (error: any) {
    // Handle any errors that occur during the request
    onError(error.message);
  } finally {
    // Call onEnd callback if provided
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

export const useGetRental = async (
  rentalId: ValueType | undefined
): Promise<RentalResponse> => {
  if (!rentalId) {
    throw new Error("Rental ID is required");
  }

  const res = await get(
    `${import.meta.env.VITE_API_URL}${paths.API.RENTAL.FIND_BY_ID(rentalId)}`
  );

  if (res?.data?.success) {
    return res.data.result;
  } else {
    throw new Error(res?.data?.message ?? res.message);
  }
};

export const useFetchAllRental = async ({
  queryParams,
  onSuccess,
  onError,
  onStart,
  onEnd,
}: {
  queryParams: {
    limit: number;
  };
  onSuccess: (data: {
    rentals: RentalLightListItemResponse[];
    rentals_access: RentalLightListItemResponse[];
  }) => void;
  onError: (message: string | null) => void;
  onStart?: () => void;
  onEnd?: () => void;
}) => {
  // Call onStart callback if provided
  if (typeof onStart === "function") {
    onStart();
  }

  try {
    const newQueryParams = new URLSearchParams();
    if (queryParams) {
      const { limit } = queryParams;
      if (limit) {
        newQueryParams.append("limit", limit.toString());
      }
    }

    const res = await get(
      `${import.meta.env.VITE_API_URL}${
        paths.API.RENTALS
      }?${newQueryParams.toString()}`
    );

    // Handle the response
    if (res?.data?.success) {
      onSuccess(res.data?.result);
    } else {
      onError(res?.response?.data?.message ?? res.message);
    }
  } catch (error: any) {
    // Handle any errors that occur during the request
    onError(error.message);
  } finally {
    // Call onEnd callback if provided
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

export const useDisableRental = async ({
  rentalId,
  onSuccess,
  onError,
  onStart,
  onEnd,
}: {
  rentalId: ValueType | undefined;
  onSuccess: (data: RentalInformationsResponse) => void;
  onError: (message: string | null) => void;
  onStart?: () => void;
  onEnd?: () => void;
}): Promise<void> => {
  if (!rentalId) {
    throw new Error("Rental ID is required");
  }

  // Call onStart callback if provided
  if (typeof onStart === "function") {
    onStart();
  }

  try {
    // Make the HTTP POST request to update rental information
    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS_UPDATE}/${rentalId}${
        paths.API.RENTAL_DISABLE
      }`
    );

    // Handle the response
    if (res?.data?.success) {
      onSuccess(res.data?.result);
    } else {
      onError(res?.response?.data?.message ?? res.message);
    }
  } catch (error: any) {
    // Handle any errors that occur during the request
    onError(error.message);
  } finally {
    // Call onEnd callback if provided
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

export type DisableMultipleRentalPayload = {
  ids: ValueType[];
};

export type DisableMultipleRentalItemResponse = {
  id: ValueType;
  enabled: number;
};
/**
 * Disables multiple rental items by making an HTTP POST request.
 *
 * @param {Object} params - The parameters for the function.
 * @param {DisableMultipleRentalPayload} params.data - The payload containing rental items to be disabled.
 * @param {Function} params.onSuccess - Callback function to be called on successful request with the response data.
 * @param {Function} params.onError - Callback function to be called when an error occurs, with an error message.
 * @param {Function} [params.onStart] - Optional callback function to be called before the request starts.
 * @param {Function} [params.onEnd] - Optional callback function to be called after the request completes, regardless of success or failure.
 *
 * @returns {Promise<void>} A promise that resolves when the operation is complete.
 */
export const useDisableMultipleRental = async ({
  data,
  onSuccess,
  onError,
  onStart,
  onEnd,
}: {
  data: DisableMultipleRentalPayload;
  onSuccess: (data: DisableMultipleRentalItemResponse[]) => void;
  onError: (message: string | null) => void;
  onStart?: () => void;
  onEnd?: () => void;
}): Promise<void> => {
  // Call onStart callback if provided
  if (typeof onStart === "function") {
    onStart();
  }

  try {
    // Make the HTTP POST request to update rental information
    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTAL_MULTIPLE_DISABLE}`,
      data
    );

    // Handle the response
    if (res?.data?.success) {
      onSuccess(res.data?.result);
    } else {
      onError(res?.response?.data?.message ?? res.message);
    }
  } catch (error: any) {
    // Handle any errors that occur during the request
    onError(error.message);
  } finally {
    // Call onEnd callback if provided
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

type DeleteMultipleRentalPayload = {
  ids: ValueType[];
};
/**
 * Deletes multiple rental records by making an HTTP POST request.
 *
 * @param {Object} params - The parameters for the function.
 * @param {DeleteMultipleRentalPayload} params.data - The payload containing rental data to be deleted.
 * @param {Function} params.onSuccess - Callback function to be called when the deletion is successful.
 * @param {Function} params.onError - Callback function to be called when an error occurs during deletion.
 * @param {Function} [params.onStart] - Optional callback function to be called before the deletion process starts.
 * @param {Function} [params.onEnd] - Optional callback function to be called after the deletion process ends.
 *
 * @returns {Promise<void>} A promise that resolves when the deletion process is complete.
 */
export const useDeleteMultipleRental = async ({
  data,
  onSuccess,
  onError,
  onStart,
  onEnd,
}: {
  data: DeleteMultipleRentalPayload;
  onSuccess: (data: ValueType[]) => void;
  onError: (message: string | null) => void;
  onStart?: () => void;
  onEnd?: () => void;
}): Promise<void> => {
  // Call onStart callback if provided
  if (typeof onStart === "function") {
    onStart();
  }

  try {
    // Make the HTTP POST request to update rental information
    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTAL_MULTIPLE_DELETE}`,
      data
    );

    // Handle the response
    if (res?.data?.success) {
      onSuccess(res.data?.result);
    } else {
      onError(res?.response?.data?.message ?? res.message);
    }
  } catch (error: any) {
    // Handle any errors that occur during the request
    onError(error.message);
  } finally {
    // Call onEnd callback if provided
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};
