import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import paths from "../../../constants/paths";
import { ValueType } from "../../../types/commonTypes";
import { Button } from "../../Common/Button/Button";
import { GroupButton } from "../../Common/GroupButton/GroupButton";
import { InputSelect } from "../../Common/InputSelect/InputSelect";
import { MainLayout } from "../MainLayout/MainLayout";
import { CalendarLayoutProps } from "./CalendarLayout.type";

import CalendarIcon from "../../../assets/icons/calendar.svg?react";
import DotsVerticalIcon from "../../../assets/icons/dots-vertical.svg?react";
import MenuIcon from "../../../assets/icons/menu.svg?react";
import PlusIcon from "../../../assets/icons/plus-circle-white.svg?react";
import { CalendarCheckPriceBanner } from "../../Calendar/CheckPriceBanner";
import useUserStore from "../../../stores/useUserStore";
import { CalandarGoToDate } from "../../Calendar/CalendarGoToDate";
import { CheckPriceModal } from "../../Calendar/CheckPriceModal";
import { useModal } from "../../../hooks/useModal";

export const CalendarLayout: React.FC<CalendarLayoutProps> = ({
  children,
  displayBy = "platform",
  hasRentals,
  navbarActiveItem,
  isCheckinPrice,
  noRightButtons,
  onSidebarOpen,
  onSelectDisplayBy = () => {},
}) => {
  const userStore = useUserStore();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const checkPriceModal = useModal();

  useEffect(() => {
    if (isCheckinPrice) checkPriceModal.open();
  }, [isCheckinPrice]);

  const handleChangePage = (value: ValueType) => {
    switch (value) {
      case "list":
        navigate(paths.CALENDAR_LIST);
        return;
      case "week":
        navigate(paths.CALENDAR);
        return;
      case "month":
        navigate(paths.CALENDAR_MONTH);
        return;
      case "year":
        navigate(paths.CALENDAR_YEAR);
        return;
    }
  };

  return (
    <>
      <CheckPriceModal
        isVisible={checkPriceModal.isVisible}
        onClose={checkPriceModal.close}
      />

      <MainLayout
        title={t("Calendar.title")}
        sidebarActiveItem="calendar"
        onSidebarOpen={onSidebarOpen}
      >
        {isCheckinPrice && <CalendarCheckPriceBanner />}

        <div>
          <div className="flex flex-row justify-between">
            <div>
              <GroupButton
                items={[
                  {
                    value: "list",
                    label: t("Calendar.listView"),
                    Icon: MenuIcon,
                    isActive: navbarActiveItem === "list",
                  },
                  {
                    value: "week",
                    label: t("Calendar.weekView"),
                    Icon: CalendarIcon,
                    isActive: navbarActiveItem === "week",
                  },
                  // {
                  //   value: "month",
                  //   label: t("Calendar.monthView"),
                  //   Icon: CalendarIcon,
                  //   isActive: navbarActiveItem === "month",
                  // },
                  // {
                  //   value: "year",
                  //   label: t("Calendar.yearView"),
                  //   Icon: CalendarIcon,
                  //   isActive: navbarActiveItem === "year",
                  // },
                ]}
                onClick={handleChangePage}
              />
            </div>

            {!noRightButtons && (
              <div className="flex gap-3">
                {displayBy !== "none" && (
                  <div className="flex items-center gap-2">
                    <p className="font-semibold text-high-contrast">
                      {t("Calendar.displayBy")}
                    </p>

                    <div className="w-48">
                      <InputSelect
                        items={[
                          {
                            label: t("Calendar.displayByPlatforms"),
                            value: "platform",
                          },
                          {
                            label: t("Calendar.displayByPaymentStatus"),
                            value: "status",
                          },
                        ]}
                        selectedValue={displayBy}
                        //@ts-ignore
                        onSelect={onSelectDisplayBy}
                      />
                    </div>
                  </div>
                )}

                <Button
                  RightIcon={PlusIcon}
                  onClick={() => navigate(paths.RESERVATIONS_ADD)}
                  disabled={!hasRentals || !userStore.canEdit}
                >
                  {t("Calendar.addBooking")}
                </Button>
              </div>
            )}
          </div>
        </div>

        <CalandarGoToDate navbarActiveItem={navbarActiveItem} />

        {children}
      </MainLayout>
    </>
  );
};
