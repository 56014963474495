import React, { Dispatch, SetStateAction } from "react";
import { PageMenu } from "../../../Common/PageMenu/PageMenu";

import BookmarkHighIcon from "../../../../assets/icons/bookmark-2-high.svg?react";
import BookmarkIcon from "../../../../assets/icons/bookmark-2.svg?react";
import CameraHighIcon from "../../../../assets/icons/camera-high.svg?react";
import CameraIcon from "../../../../assets/icons/camera.svg?react";
import FileTextHighIcon from "../../../../assets/icons/file-text-high.svg?react";
import FileTextIcon from "../../../../assets/icons/file-text.svg?react";
import MoneyHighIcon from "../../../../assets/icons/money-high.svg?react";
import MoneyIcon from "../../../../assets/icons/money.svg?react";
import ShoppingCartIcon from "../../../../assets/icons/shopping-cart.svg?react";
import SparklesHighIcon from "../../../../assets/icons/sparkles-high.svg?react";
import SparklesIcon from "../../../../assets/icons/sparkles.svg?react";
import WifiHighIcon from "../../../../assets/icons/wifi-high.svg?react";
import WifiIcon from "../../../../assets/icons/wifi.svg?react";

export const RentalWebsiteMenu = ({
  anchor,
  setAnchor,
}: {
  anchor: string;
  setAnchor: Dispatch<SetStateAction<string>>;
}) => {
  return (
    <PageMenu
      anchor={anchor}
      setAnchor={setAnchor}
      items={[
        {
          Icon: BookmarkIcon,
          IconActive: BookmarkHighIcon,
          anchor: "rules-card",
          labelI18n: "Rental.Website.Rules.title",
        },
        {
          Icon: CameraIcon,
          IconActive: CameraHighIcon,
          anchor: "carrousel-photos-card",
          labelI18n: "Rental.Website.CarouselPhotos.title",
        },
        {
          Icon: FileTextIcon,
          IconActive: FileTextHighIcon,
          anchor: "description-card",
          labelI18n: "Rental.Website.Description.title",
        },
        {
          Icon: WifiIcon,
          IconActive: WifiHighIcon,
          anchor: "services-card",
          labelI18n: "Rental.Website.Services.title",
        },
        {
          Icon: SparklesIcon,
          IconActive: SparklesHighIcon,
          anchor: "extras-card",
          labelI18n: "Rental.Website.Extras.title",
        },
        {
          Icon: MoneyIcon,
          IconActive: MoneyHighIcon,
          anchor: "rate-card",
          labelI18n: "Rental.Website.PaymentAndReceipt.title",
        },
        {
          Icon: ShoppingCartIcon,
          IconActive: ShoppingCartIcon,
          anchor: "upsell-card",
          labelI18n: "Rental.Website.UpsellMarketing.title",
        },
        {
          Icon: FileTextIcon,
          IconActive: FileTextHighIcon,
          anchor: "terms-card",
          labelI18n: "Rental.Website.Terms.title",
        },
      ]}
    />
  );
};
