import React, { SyntheticEvent, useRef, useState } from "react";
import { ValueType } from "../../../types/commonTypes";
import { useTranslation } from "react-i18next";
import PhotoIcon from "../../../assets/icons/photo.svg?react";
import PlusIcon from "../../../assets/icons/plus.svg?react";
import FilePlusIcon from "../../../assets/icons/file-plus.svg?react";
import TrashIcon from "../../../assets/icons/trash.svg?react";
import EditIcon from "../../../assets/icons/edit-white.svg?react";
import {PictureWithDelete} from "../PictureWithDelete/PictureWithDelete";
import {PictureInputProps} from "./PictureInput.type";
import {MessageTemplatePhotoResponse} from "../../../types/GETTypes";
import {PreviewImage} from "../PreviewImage/PreviewImage";
import {useModal} from "../../../hooks/useModal";
import {cn} from "../../../helpers/classHelper";

export const PictureInput: React.FC<PictureInputProps> = ({
  label,
  mode = "all",
  oldFiles = [],
  newFiles = [],
  disabled = false,
  onChangeOldFiles = () => {},
  onChangeNewFiles = () => {},
  onDeleteFile = () => {},
  onWrapButtonClick = () => {},
  onChangeFile = () => {},
  setDisplayModal,
  photoPath,
  title = "Global.addPhoto",
  type = "image",
  displayCover = true,
  setErrors,
  activePreviewSystem = false,
  activeMultiPreviewSystem = false,
  classNames,
  hideInput = false,
  CUSTOMINPUT,
  disabledCustomInput = false,
  errorFile,
  draggablesPicture = false,
}) => {
  const {t} = useTranslation();
  const limitBeforeWrapping = 3;
  const previewModal = useModal();
  const fileInputRef = React.createRef<HTMLInputElement>();

  const getCursor = () => {
    if (disabled) return "cursor-not-allowed";
    else return "cursor-pointer";
  };

  const handleAdd = () => {
    fileInputRef.current?.click();
  };

  const resizeImage = (img, file, minWidth, minHeight, callback) => {
    let width = img.width;
    let height = img.height;

    const aspectRatio = width / height;
    if (width < minWidth) {
      width = minWidth;
      height = width / aspectRatio;
    }
    if (height < minHeight) {
      height = minHeight;
      width = height * aspectRatio;
    }

    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d");

    ctx && ctx.drawImage(img, 0, 0, width, height);

    canvas.toBlob((blob) => {
      if (blob) {
        const resizedFile = new File([blob], file.name, {
          type: file.type,
          lastModified: Date.now(),
        });

        callback(resizedFile);
      }
    }, file.type);
  };

  const handleChangeFile = (event: any) => {
    const files = event.target.files;
    Array.from(files).forEach((f) => {
      const file = f as File;
      if (file.type.startsWith("image/")) {
        const img = new Image();
        img.src = URL.createObjectURL(file);

        img.onload = () => {
          const width = img.width;
          const height = img.height;

          if (width >= 1024 && height >= 683) {
            console.log("Image dimensions are valid:", width, height);
            const nextFiles = [...newFiles, file];
            onChangeFile(nextFiles);
            setErrors
              ? setErrors("")
              : console.log("Image dimensions are valid:", width, height);
          } else {
            const message = `Image dimensions are invalid. The image must be at least 1024 pixels wide and 683 pixels tall. Current dimensions: Width: ${width}px, Height: ${height}px.`;
            console.log(message);

            // Appeler la fonction de redimensionnement
            resizeImage(img, file, 1024, 683, (resizedFile) => {
              console.log(
                `Image has been resized to Width: ${resizedFile.width}px, Height: ${resizedFile.height}px.`
              );
              const nextFiles = [...newFiles, resizedFile];
              onChangeFile(nextFiles);
              setErrors
                ? setErrors("")
                : console.log(
                    `Image has been resized to the correct dimensions.`
                  );
            });
          }
          URL.revokeObjectURL(img.src);
        };
      } else {
        const error = "Only images are allowed.";
        setErrors ? setErrors(error) : console.log(error);
      }
    });
  };

  const handleChangeNewFiles = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (!files) return;

    const processedFilesPromises = Array.from(files).map((f) => {
      const file = f as File;

      return new Promise<File>((resolve, reject) => {
        if (file.type.startsWith("image/")) {
          const img = new Image();
          img.src = URL.createObjectURL(file);

          img.onload = () => {
            const width = img.width;
            const height = img.height;

            if (width < 1024 || height < 683) {
              resizeImage(img, file, 1024, 683, (resizedFile: File) => {
                URL.revokeObjectURL(img.src);
                resolve(resizedFile);
              });
            } else {
              console.log("Image dimensions are valid:", width, height);
              URL.revokeObjectURL(img.src);
              resolve(file);
            }
          };

          img.onerror = () => {
            URL.revokeObjectURL(img.src);
            reject(new Error("Failed to load image"));
          };
        } else if (file.type.startsWith("video/")) {
          const fileSizeinMo = Number(file.size / 1024 / 1024);
          const maxSize = 500; // For 500 Mo
          if (fileSizeinMo > maxSize) {
            setErrors &&
              setErrors(
                t("Rental.Infos.TravelerPage.InstructionCard.videosSizeError")
              );
            if (fileInputRef.current) {
              fileInputRef.current.value = "";
            }
            reject(new Error("Video file too large"));
          } else {
            resolve(file);
          }
        } else {
          resolve(file);
        }
      });
    });

    try {
      const processedFiles = await Promise.all(processedFilesPromises);
      const nextFiles = [...newFiles, ...processedFiles];
      onChangeNewFiles(nextFiles);
      setErrors && setErrors("");
    } catch (error) {
      setErrors && setErrors(error?.toString() ?? "");
    }
  };

  const handleDeleteNewFiles = (file: Blob) => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    onChangeNewFiles(newFiles.filter((nf) => nf.name !== file.name));
  };

  const handleDeleteOldFiles = (fileId: ValueType, mimeType?: string) => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    onChangeOldFiles(oldFiles.filter((of) => of.id !== fileId));
    onDeleteFile(fileId, mimeType);
  };

  const handleDeletePicture = () => {
    setDisplayModal && setDisplayModal(true);
  };

  /**
   * Draggable System functions
   */
  const [elementOver, setElementOver] = React.useState<Element | null>();
  const [baseElement, setBaseElement] = React.useState<Element | null>();

  const handleDragStart = (e: React.SyntheticEvent) => {
    setBaseElement(e.target as HTMLElement);
    setElementOver(null);
  };
  const handleDragOver = (e: React.SyntheticEvent) => {
    const element = e.target as HTMLElement;
    setElementOver(element);
  };

  const handleDragEnd = () => {
    const files = [...oldFiles, ...newFiles];

    if (elementOver && baseElement) {
      const keyOver = parseInt(elementOver.getAttribute("data-key") || "", 10);
      const keyBase = parseInt(baseElement.getAttribute("data-key") || "", 10);

      if (!isNaN(keyOver) && !isNaN(keyBase)) {
        const updatedFiles = [...files];
        const [draggedFile] = updatedFiles.splice(keyBase, 1);
        updatedFiles.splice(keyOver, 0, draggedFile);

        onChangeOldFiles(updatedFiles);
      }
    }

    setBaseElement(null);
    setElementOver(null);
  };

  const fetchFiles = () => {
    const files: Array<MessageTemplatePhotoResponse | Blob> = [
      ...oldFiles,
      ...newFiles,
    ];
    type isImageTypeType = (typeof files)[number];
    type isBlobType = (typeof files)[number];

    const isImageType = (x: any): x is isImageTypeType => oldFiles.includes(x);
    const isBlobType = (x: any): x is isBlobType => newFiles.includes(x);

    const mapping = files.map((file: any, key: number) => {
      if (isImageType(file)) {
        const newFile: MessageTemplatePhotoResponse =
          file as MessageTemplatePhotoResponse;

        return (
          <PictureWithDelete
            dataKey={key}
            key={`${newFile.name}_${key}`}
            src={newFile.original_url}
            mime_type={newFile.mime_type}
            isCover={key === 0 && displayCover && true}
            type={type}
            name={newFile.name}
            disabled={disabled}
            onRemove={() => handleDeleteOldFiles(newFile.id, newFile.mime_type)}
            activePreview={activePreviewSystem}
            onClick={previewModal.open}
            classNames={classNames}
            draggable={draggablesPicture}
            onDragOver={handleDragOver}
            onDragEnd={handleDragEnd}
            onDragStart={handleDragStart}
          />
        );
      } else if (isBlobType(file)) {
        const newFile: Blob = file as Blob;
        return (
          <PictureWithDelete
            dataKey={key}
            key={`${newFile.name}_${key}`}
            src={URL.createObjectURL(newFile)}
            type={type}
            mime_type={newFile?.type}
            isCover={key === 0 && displayCover && true}
            disabled={disabled}
            name={newFile.name}
            onRemove={() => handleDeleteNewFiles(newFile)}
            activePreview={activePreviewSystem}
            onClick={previewModal.open}
            classNames={classNames}
            draggable={draggablesPicture}
            onDragOver={handleDragOver}
            onDragEnd={handleDragEnd}
            onDragStart={handleDragStart}
          />
        );
      }
    });

    return mapping;
  };

  const getAllUrls = () => {
    let urls: string[] = [];
    oldFiles.map((file) => {
      urls.push(file.original_url);
    });
    return urls;
  };

  return (
    <div className="flex flex-col gap-2">
      {activeMultiPreviewSystem && (
        <PreviewImage
          imageUrl={""}
          imageModal={previewModal}
          imgs={getAllUrls()}
          isMulti={true}
        />
      )}

      {label && <p className="text-high-contrast font-semibold">{label}</p>}

      <div
        className={cn(
          "flex flex-row gap-2 flex-wrap",
          activeMultiPreviewSystem && "cursor-pointer",
          classNames?.divInput
        )}
      >
        {mode === "all"
          ? fetchFiles()
          : fetchFiles().splice(0, limitBeforeWrapping)}

        {CUSTOMINPUT && (
          <CUSTOMINPUT ref={fileInputRef} disabled={disabledCustomInput} errorFile={errorFile}/>
        )}

        {mode !== "picture" && (
          <input
            type="file"
            multiple={true}
            style={{display: "none"}}
            ref={fileInputRef}
            accept="image/jpeg,image/png,.pdf,video/mp4,video/mkv,video/x-m4v,video/avi,.mov,video/quicktime"
            onChange={handleChangeNewFiles}
          />
        )}

        {mode == "picture" && (
          <input
            type="file"
            style={{display: "none"}}
            ref={fileInputRef}
            accept="image/jpeg,image/png,video/mp4,video/mkv,video/x-m4v,video/*,.mov,video/quicktime"
            onChange={handleChangeFile}
          />
        )}

        {mode === "all" && !hideInput && (
          <div
            className={`flex flex-col items-center justify-center w-24 h-24 bg-element-background rounded-6px border-1 border-element-border gap-2 ${getCursor()} hover:bg-element-background-active`}
            onClick={disabled ? () => {} : handleAdd}
          >
            {type === "file" ? (
              <FilePlusIcon className="w-8 h-8" />
            ) : (
              <PhotoIcon className="w-8 h-8" />
            )}
            <p className="font-semibold text-high-contrast text-center">
              {t(title)}
            </p>
          </div>
        )}

        {mode === "picture" && !hideInput && (
          <div
            className={`relative flex flex-col items-center justify-center w-24 h-24 bg-element-background rounded-6px border-1 border-element-border gap-2 ${getCursor()} hover:bg-element-background-active`}
          >
            <div className="overflow-hidden w-24 h-24 flex items-center justify-center  hover:opacity-75 ">
              {photoPath !== null ? (
                <img
                  src={photoPath}
                  className="h-24 w-24 border-2 border-element-border/50 rounded-6px object-cover object-center bg-element-background group-hover:scale-110 group-hover:opacity-75"
                />
              ) : (
                <div
                  className={`flex flex-col items-center justify-center w-32 h-32 bg-element-background rounded-6px border-1 border-element-border gap-2 ${getCursor()} hover:bg-element-background-active`}
                  onClick={disabled ? () => {} : handleAdd}
                >
                  <PhotoIcon className="w-8 h-8" />
                  <p className="font-semibold text-high-contrast text-center">
                    {t(title)}
                  </p>
                </div>
              )}

              <div
                className="absolute inset-y-0  flex items-center justify-center opacity-0 hover:opacity-100"
                onClick={disabled ? () => {} : handleAdd}
              >
                <EditIcon className="w-10 h-10" />
              </div>
              <div
                className="absolute right-0 inset-y-0 flex  justify-center opacity-0 hover:opacity-100"
                onClick={disabled ? () => {} : handleDeletePicture}
              >
                <TrashIcon className="w-5 h-5 text-white" fill="white" />
              </div>
            </div>
          </div>
        )}

        {mode === "wrap" &&
          fetchFiles().length - limitBeforeWrapping > 0 &&
          !hideInput && (
            <div
              className={`flex flex-col items-center justify-center w-24 h-24 bg-element-background rounded-6px border-1 border-element-border gap-2 ${getCursor()} hover:bg-element-background-active`}
              onClick={onWrapButtonClick}
            >
              <PlusIcon className="w-5 h-5 text-icon-default" />
              <p className="text-low-contrast text-center">
                {fetchFiles().length - limitBeforeWrapping} {t("Global.plus")}
              </p>
            </div>
          )}
      </div>
    </div>
  );
};
