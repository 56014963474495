import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextInput } from "../Common/TextInput/TextInput";
import { Button } from "../Common/Button/Button";
import ArrowRightWhiteIcon from "../../assets/icons/arrow-right-white.svg?react";
import LeftArrowIcon from "../../assets/icons/arrow-left.svg?react";
import { MultiSelectRentalsTable } from "../Common/MultiSelectRentalsTable/MultiSelectRentalsTable";
import { useTablePage } from "../../hooks/useTablePage";
import paths from "../../constants/paths";
import { ValueType } from "../../types/commonTypes";
import { ErrorMessage } from "../Common/ErrorMessage/ErrorMessage";
import { post } from "../../helpers/APIHelper";
import { WebsiteAddStepDataType } from "../../pages/AddWebsite/AddWebsitePage";

export type AddWesiteRentalsType = any[];

export const AddWebsiteRentals = ({
  stepData,
  onClose,
  onNextStep,
  onPreviousStep,
}: {
  stepData: WebsiteAddStepDataType;
  onClose: () => void;
  onNextStep: (values: AddWesiteRentalsType) => void;
  onPreviousStep: () => void;
}) => {
  const { t } = useTranslation();
  const [APIError, setAPIError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const [selectedRentals, setSelectedRentals] = useState<any[]>([]);

  const rentalsTable = useTablePage(
    `${import.meta.env.VITE_API_URL}${paths.RENTALS}`,
    "rentals"
  );

  useEffect(() => {
    setAPIError(undefined);

    rentalsTable.fetch({});
  }, []);

  useEffect(() => {
    if (stepData?.rentals) {
      setSelectedRentals(stepData.rentals);
    }
  }, [stepData?.rentals]);

  const handleNextStep = async () => {
    if (selectedRentals?.length === 0) {
      setAPIError(t("Global.required"));
      return;
    }

    setLoading(true);

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.WEBSITE.ADD.RENTALS}`,
      {
        title: stepData.general?.title,
        subtitle: stepData.general?.subTitle,
        rental_ids: selectedRentals?.map((r) => r.id).join(","),
      }
    );

    setLoading(false);

    if (!res.data?.success) {
      setAPIError(res.response?.data?.message);
      return;
    }

    onNextStep(selectedRentals);
  };

  const handleSelectRental = (id: any) => {
    setAPIError(undefined);

    setSelectedRentals((prev = []) => {
      const exists = prev.some((item) => item.id === id);

      if (exists) {
        return prev.filter((item) => item.id !== id);
      } else {
        const rental = rentalsTable.data?.find((r: any) => r.id === id);
        return [...prev, rental];
      }
    });
  };

  return (
    <>
      <div>
        <div className="w-52">
          <Button
            type="secondary"
            LeftIcon={LeftArrowIcon}
            onClick={onPreviousStep}
          >
            {t("Global.previousStep")}
          </Button>
        </div>

        <p className="text-lg font-semibold text-high-contrast mt-8">
          {t("Website.Add.Rentals.title")}
        </p>

        <p className="font-light text-low-contrast mt-4">
          {t("Website.Add.Rentals.description")}
        </p>

        <div className="h-12" />

        <MultiSelectRentalsTable
          disabled={false}
          selectedRentals={selectedRentals?.map((r) => r.id)}
          tablePage={rentalsTable}
          onSelectRental={handleSelectRental}
        />

        <div className="h-8" />

        <ErrorMessage>{APIError}</ErrorMessage>
      </div>

      <div className="flex gap-3 justify-end">
        <div>
          <Button type="secondary" onClick={onClose} disabled={loading}>
            {t("Global.cancel")}
          </Button>
        </div>
        <div>
          <Button
            RightIcon={ArrowRightWhiteIcon}
            onClick={handleNextStep}
            loading={loading}
          >
            {t("Global.nextStep")}
          </Button>
        </div>
      </div>
    </>
  );
};
