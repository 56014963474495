import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useParams, useSearchParams} from "react-router-dom";
import AlertCircle from "../../assets/icons/alert-circle.svg?react";
import SuperhoteIcon from "../../assets/icons/superhote.svg";
import {InputSelectOptionProps} from "../../components/Common/InputSelect/InputSelect.type";
import GuestPageFormComponent from "../../components/GuestPageUser/GuestPageForm/GuestPageForm";
import GuestPageHomeComponent, {
  getTabAfterUuid,
  tabUrlType,
} from "../../components/GuestPageUser/GuestPageHome/GuestPageHomeComponent";
import paths from "../../constants/paths";
import {get, post} from "../../helpers/APIHelper";
import {getLanguageIcon} from "../../helpers/languageHelper";
import {capitalizeFirstLetter} from "../../helpers/stringHelper";
import useGuestPageDataStore from "../../stores/guestPageStore/useGuestPageDataStore";
import {
  ContractResponse,
  GuestPageConnectResponse,
  GuestPageLoginDetailsResponse,
  LanguageResponse,
  RentalResponse,
} from "../../types/GETTypes";
import GuestPageSkeleton from "./GuestPageSkeleton";
import {scrollToTop} from "../../helpers/eventHelper";

export const changeTitle = (title: string) => {
  document.title = title;
};

const GuestPageUser = () => {
  const {t} = useTranslation();
  const {guestPageLink} = useParams();
  const [searchParams] = useSearchParams();
  const rentalId = searchParams.get("rentalId");
  const [step, setStep] = useState(0);
  const [loaderLoginDetails, setLoaderLoginDetails] = useState(true);
  const [guestLoginDetails, setGuestLoginDetails] = useState<
    GuestPageLoginDetailsResponse | undefined
  >();
  const [languages, setLanguages] = useState<InputSelectOptionProps[]>([]);
  const [contracts, setContracts] = useState<ContractResponse[]>([]);
  const {
    guestPageData,
    storeGuestPageData,
    isPreviewMode,
    storeIsPreviewMode,
    storeUserPreview,
    storeRentalPreview,
  } = useGuestPageDataStore();
  const [errorLink, setErrorLink] = useState(false);
  const [guestPageDisable, setGuestPageDisable] = useState(false);
  const [openContractPage, setOpenContractPage] = useState(false);

  const detectUrl = () => {
    const url = window.location.href;
    const tabUrl = getTabAfterUuid(url) as tabUrlType;
    const urlIncludes = ["contract"];
    if (urlIncludes.includes(tabUrl)) {
      switch (tabUrl) {
        case "contract":
          const title = `Signature du contrat`;
          changeTitle(title);
          break;
      }
      setStep(1);
    }
  };

  const detectIfIsPreviewMode = async () => {
    const responseUser = await get(
      `${import.meta.env.VITE_API_URL}${paths.API.USER.GET_USER}`
    );
    const user = responseUser?.data?.result;
    if (
      responseUser?.data?.success &&
      guestPageLink === user?.guest_page_link &&
      rentalId &&
      rentalId !== "0"
    ) {
      //Is previewMode
      const responseRental = await get(
        `${import.meta.env.VITE_API_URL}${paths.API.RENTALS}/${rentalId}`
      );
      const rental = responseRental?.data?.result as RentalResponse;
      const {guest_page} = rental || {};
      const {form_enabled, contract_enabled} = guest_page || {};

      storeRentalPreview(rental);
      storeUserPreview(user);
      await loadLanguages();
      storeIsPreviewMode(true);
      setErrorLink(false);
      if (!form_enabled) {
        if (contract_enabled) {
          setOpenContractPage(true);
          setLoaderLoginDetails(false);
          return true;
        } else {
          setStep(1);
        }
      }
      setLoaderLoginDetails(false);
      return true;
    }
    storeIsPreviewMode(false);
    return false;
  };

  const getLoginDetailsData = async () => {
    const {data, ...res} =
      (await get(
        `${import.meta.env.VITE_API_URL}${
          paths.API.GUEST_PAGE.GET_SCREEN_DETAILS
        }/${guestPageLink}`
      )) || {};
    const {result, success} =
      (data as {result: GuestPageLoginDetailsResponse; success: boolean}) || {};
    if (!res?.response?.data?.success) {
      if (res?.response?.data?.error_code === 1) {
        setErrorLink(true);
        setLoaderLoginDetails(false);
        return;
      }
    }

    return {result, success};
  };

  const connect = async (dataConnected: {
    reservation_id: string;
    guest_page_link: string | undefined;
    guest_page_connected: number;
    email: any;
    phone: any;
    verification_only: boolean;
  }) => {
    const resConnected = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.GUEST_PAGE.CONNECT}`,
      dataConnected
    );
    if (resConnected?.data?.success) {
      const guestDataConnected = resConnected?.data?.result;
      const {rental, guest, checkin, checkout} = guestDataConnected || {};
      const title = `${capitalizeFirstLetter(
        rental?.name
      )} - ${capitalizeFirstLetter(guest?.first_name)} ${capitalizeFirstLetter(
        guest?.last_name
      )} - ${capitalizeFirstLetter(checkin)} - ${capitalizeFirstLetter(
        checkout
      )}`;
      changeTitle(title);
      handleLogin(guestDataConnected);
      setLoaderLoginDetails(false);
      return;
    } else {
      setLoaderLoginDetails(false);
    }
  };

  const detectIfIsConnected = async ({
    guest_page_connected,
    reservation_id,
    guest_email,
    guest_phone,
  }: {
    guest_page_connected: boolean | undefined;
    reservation_id: number | undefined;
    guest_email: string | null | undefined;
    guest_phone: string | null | undefined;
  }) => {
    setGuestPageDisable(false);
    if (guest_page_connected) {
      const dataConnected = {
        reservation_id: String(reservation_id),
        guest_page_link: guestPageLink,
        guest_page_connected: 1,
        email: guest_email,
        phone: guest_phone,
        verification_only: false,
      };
      try {
        await connect(dataConnected);
      } catch (e) {
        setLoaderLoginDetails(false);
      }
    }
  };

  const setDataForLogin = async ({
    success,
    result,
    languages,
  }: {
    success: boolean | undefined;
    result: GuestPageLoginDetailsResponse | undefined;
    languages: LanguageResponse[] | undefined;
  }) => {
    if (success && result) {
      const {rental_name, guest_last_name, guest_first_name} = result || {};
      const title = `${capitalizeFirstLetter(
        rental_name
      )} - ${capitalizeFirstLetter(guest_first_name)} ${capitalizeFirstLetter(
        guest_last_name
      )}`;
      changeTitle(title);
      setContracts(result?.contracts?.contracts);
      setGuestLoginDetails(result);
    }
    loadLanguages(languages);
  };
  const loadData = async () => {
    setLoaderLoginDetails(true);
    if (await detectIfIsPreviewMode()) {
      return;
    }

    // Récupérer guest login screen details
    const {result, success} = (await getLoginDetailsData()) || {};
    const {
      guest_page_connected,
      guest_email,
      guest_phone,
      reservation_id,
      languages,
      form_enabled,
      contract_enabled,
      reservation_airbnb,
    } = result || {};

    await detectIfIsConnected({
      guest_page_connected,
      guest_email,
      guest_phone,
      reservation_id,
    });

    await setDataForLogin({languages, success, result});

    if (!form_enabled) {
      if (contract_enabled && !reservation_airbnb) {
        setOpenContractPage(true);
        setLoaderLoginDetails(false);
        return;
      } else {
        const dataConnected = {
          reservation_id: String(reservation_id),
          guest_page_link: guestPageLink,
          guest_page_connected: 1,
          email: guest_email,
          phone: guest_phone,
          verification_only: false,
        };
        await connect(dataConnected);
      }
    }
    setLoaderLoginDetails(false);
  };

  const loadLanguages = async (languages?: LanguageResponse[]) => {
    let langs;
    let success = false;
    if (languages) {
      langs = languages;
      success = true;
    } else {
      // Récupérer les langues
      const {data: dataLanguages} =
        (await get(`${import.meta.env.VITE_API_URL}${paths.API.LANGUAGES}`)) ||
        {};
      const {result: resultLanguages, success: successLanguages} =
        dataLanguages || {};
      langs = resultLanguages?.languages;
      success = successLanguages;
    }

    if (success) {
      const excludedCodes = ["PT", "IT"];
      const nextLanguages = langs
        ?.filter((l: LanguageResponse) => !excludedCodes.includes(l.code))
        .map((l: LanguageResponse) => {
          return {
            label: l.name,
            value: l.code,
            Icon: getLanguageIcon(l.code),
          };
        });

      setLanguages(nextLanguages);
    }

    return {resultLanguages: langs, successLanguages: success};
  };

  const handleLogin = (data?: GuestPageConnectResponse) => {
    if (isPreviewMode) {
      setStep(1);
      scrollToTop()
      return;
    }
    if (data) {
      storeGuestPageData({...data});
      setStep(1);
      scrollToTop()
    }
  };

  useEffect(() => {
    detectUrl();
    if (step === 0) {
      loadData();
    }
  }, [guestPageLink]);

  useEffect(() => {}, [guestLoginDetails, languages, guestPageData]);
  

  const isForm = step === 0;

  if (loaderLoginDetails) {
    return <GuestPageSkeleton />;
  }

  if (errorLink) {
    return (
      <div className="flex flex-col flex-1 w-screen h-screen">
        <img
          src={`${SuperhoteIcon}`}
          className="w-screen h-32 px-8 mt-16 bg-cover md:h-32"
        />
        <div className="flex flex-col items-center justify-center flex-1 mx-4">
          <AlertCircle className={`fill-error`} width={50} height={50} />
          <div
            className={` text-xl font-light text-center flex flex-row items-center justify-center text-error mt-8`}
          >
            {t("GuestPage.Global.guestPageErrorLink")}
          </div>
        </div>
      </div>
    );
  }

  if (guestPageDisable) {
    return (
      <div className="flex flex-col flex-1 w-screen h-screen">
        <img
          src={`${SuperhoteIcon}`}
          className="w-screen h-32 px-8 mt-16 bg-cover md:h-32"
        />
        <div className="flex flex-col items-center justify-center flex-1 mx-4">
          <AlertCircle className={`fill-error`} width={50} height={50} />
          <div
            className={` text-xl font-light text-center flex flex-row items-center justify-center text-error mt-8`}
          >
            {t("GuestPage.Global.guestPageDisable")}
          </div>
        </div>
      </div>
    );
  }

  return isForm ? (
    <GuestPageFormComponent
      guestLoginDetails={guestLoginDetails}
      languages={languages}
      contracts={contracts}
      handleLogin={handleLogin}
      openContract={openContractPage}
      setOpenContract={setOpenContractPage}
    />
  ) : (
    <GuestPageHomeComponent />
  );
};

export default GuestPageUser;
