import React from "react";
import { useTranslation } from "react-i18next";
import { WebsiteListItemResponse } from "../../../types/GETTypes";
import { TableListItemProps } from "../../Common/TableList/TableList.type";
import Template2Image from "../../../assets/images/website-model2.svg?react";
import moment from "moment";

export const WebsiteListItem: React.FC<TableListItemProps> = ({
  data,
  onClick = () => {},
}) => {
  const { t } = useTranslation();
  const website: WebsiteListItemResponse = data;

  return (
    <tr
      className="cursor-pointer hover:bg-element-background/40"
      onClick={() => onClick(website?.id!)}
    >
      {/* Site de réservation */}
      <td className="flex flex-row items-center px-4 py-2 space-x-4 text-sm border-t-1 border-element-border">
        <div className="flex gap-2 items-center">
          <img
            src={website.background}
            className="size-12 rounded-6px border-1 border-element-border object-cover"
          />
          <div className="flex flex-col gap-1">
            <p className="text-high-contrast font-semibold">{website.title}</p>
            <p className="text-low-contrast font-light">
              {t("Website.List.rentalCount", { count: website.rentals_count })}
            </p>
          </div>
        </div>
      </td>

      {/* Adresse web */}
      <td className="py-2 text-sm align-middle border-t-1 border-element-border">
        <div className="inline-flex bg-element-background border-1 rounded-6px p-1">
          <p className="text-center font-light text-low-contrast">
            {website.website_url}
          </p>
        </div>
      </td>

      {/* Modèle */}
      <td className="py-2 text-sm align-center border-t-1 border-element-border text-low-contrast">
        <div className="bg-element-background inline-flex p-2 rounded-6px gap-2 items-center">
          <Template2Image className="size-8 rounded-6px border-1 border-element-border" />
          <p className="text-low-contrast font-semibold">Modèle 2.0</p>
        </div>
      </td>

      {/* Date de création */}
      <td className="py-2 text-sm align-middle border-t-1 border-element-border">
        <p className="text-low-contrast font-light text-right pr-2">
          {moment(website.created_at).format("DD/MM/YYYY HH:mm")}
        </p>
      </td>
    </tr>
  );
};
