import { CheckDeletePopupProps } from "./CheckDeletePopup.type";
import Cross from "../../../assets/icons/close.svg?react";
import { Button } from "../Button/Button";
import { useTranslation } from "react-i18next";
import React from "react";

export const CheckDeletePopup = ({
  modal,
  title = "",
  description = "",
  onAcceptDelete = () => {},
  onRefuse = () => {},
}: CheckDeletePopupProps) => {
  const { t } = useTranslation();

  return (
    <>
      {/* Fond gris transparent */}
      {modal.isVisible && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 z-40 flex justify-center items-center">
          <div
            className={
              "relative rounded-xl bg-white border shadow-lg " +
              "border-gray-300 p-5 w-[380px] flex gap-4 flex-col"
            }
          >
            <Cross
              onClick={() => {
                onRefuse(modal.data);
                modal.close();
              }}
              className={"cursor-pointer absolute right-3 top-3"}
            />
            <div className={"font-bold w-[90%]"}>
              <p>{title}</p>
            </div>
            <div className={"text-gray-500"}>
              <p>{description}</p>
            </div>
            <div className={"flex items-center gap-6"}>
              <Button
                onClick={() => {
                  onRefuse(modal.data);
                  modal.close();
                }}
                type={"secondary"}
              >
                {t("Global.cancel")}
              </Button>
              <Button
                onClick={() => {
                  onAcceptDelete(modal.data);
                  modal.close();
                }}
              >
                {t("Global.confirmeDelete")}
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
