import { useEffect, useState } from "react";
import { WebsiteResponse } from "../../../types/GETTypes";
import { post } from "../../../helpers/APIHelper";

export function useToggleCard({
  website,
  initialValue,
  baseUrl,
}: {
  website: WebsiteResponse | undefined;
  initialValue: number | undefined;
  baseUrl: string;
}) {
  const [isEnabled, setIsEnabled] = useState<boolean>(false);
  const [isEnabledLoading, setIsEnabledLoading] = useState<boolean>(true);

  useEffect(() => {
    if (initialValue) setIsEnabled(true);
    else setIsEnabled(false);

    if (website) setIsEnabledLoading(false);
  }, [website]);

  const toggle = async () => {
    setIsEnabledLoading(true);

    const URL = `${baseUrl}${isEnabled ? "disable" : "enable"}`;

    const res = await post(URL);

    if (res?.data?.success) {
      setIsEnabled(!isEnabled);
    }

    setIsEnabledLoading(false);
  };

  return { isEnabled, isEnabledLoading, toggle };
}
