import React, {useEffect} from "react";
import {Controller, useForm} from "react-hook-form";
import {TextInput} from "../../../Common/TextInput/TextInput";
import {useTranslation} from "react-i18next";
import useGuestWebsite from "../../../../hooks/useGuestWebsite";
import {TextAreaInput} from "../../../Common/TextAreaInput/TextAreaInput";
import useGuestWebsiteStore from "../../../../stores/guestWebsiteStore/useGuestWebsiteStore";
import CheckCircleGreenIcon from "../../../../assets/icons/check-circle-green.svg?react";
import {Button} from "../../../Common/Button/Button";
import {DEFAULT_COLOR_WEBSITE_TEMPLATE_2} from "../../../../constants/colors";

export interface FormContactData {
  first_name: string;
  last_name: string;
  email: string;
  subject: string;
  message: string;
  url: string;
}

export const FormContactComponent = () => {
  const {contact, isLoadingContact: isLoading, contactSend} = useGuestWebsite();
  const {dataGuestWebsite} = useGuestWebsiteStore();
  const {styles} = dataGuestWebsite || {};
  const {color_main, color_background, color_text} = styles || {};
  const {t} = useTranslation();

  const {
    control,
    handleSubmit,
    formState: {errors},
  } = useForm<FormContactData>({
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      subject: "",
      message: "",
      url: dataGuestWebsite?.integration.url ?? "",
    },
  });

  const onSubmit = async (data: FormContactData) => {
    await contact(data);
  };

  useEffect(() => {}, [contactSend]);

  if (contactSend) {
    return (
      <div className="flex rounded-lg space-y-4 w-full md:w-3/5 p-6 h-full justify-center items-center space-x-4">
        <div className="flex flex-col items-center justify-center mt-4">
          <CheckCircleGreenIcon width={40} height={40} />
        </div>
        <div className="flex flex-col space-y-2">
          <h3 className="text-base font-bold text-success">
            {t("GuestWebsite.Contact.congrats")}
          </h3>
          <p className="font-light text-success text-xs space-y-2">
            {t("GuestWebsite.Contact.sendSuccess")}
          </p>
        </div>
      </div>
    );
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className=" shadow-xl rounded-lg space-y-4 w-full md:w-3/5 p-6 h-full"
      style={{backgroundColor: color_background}}
    >
      <h2 className="text-xl font-bold " style={{color: color_text}}>
        {t("GuestWebsite.Contact.formContact")}
      </h2>

      {/* First Name and Last Name */}
      <div className="flex flex-row items-center space-x-2">
        <Controller
          control={control}
          name="first_name"
          rules={{required: t("GuestWebsite.required",{input:t("GuestWebsite.Contact.firstName")})}}
          render={({field: {value, onChange}}) => (
            <div className="w-1/2">
              <TextInput
                label={t("GuestWebsite.Contact.firstName")}
                value={value}
                size="large"
                required
                placeholder={""}
                onChangeText={onChange}
                error={errors.first_name?.message}
                disabled={isLoading}
                labelStyle={{color: color_text}}
                classNames={{
                  divInput:
                    "outline-none focus:outline-none outline-none focus:ring-offset-0 focus:outline-hidden outline-transparent",
                  input: "outline-none",
                }}
              />
            </div>
          )}
        />
        <Controller
          control={control}
          name="last_name"
          rules={{required: t("GuestWebsite.required",{input:t("GuestWebsite.Contact.lastName")})}}
          render={({field: {value, onChange}}) => (
            <div className="w-1/2">
              <TextInput
                label={t("GuestWebsite.Contact.lastName")}
                value={value}
                size="large"
                required
                placeholder={""}
                onChangeText={onChange}
                error={errors.last_name?.message}
                disabled={isLoading}
                labelStyle={{color: color_text}}
                classNames={{
                  divInput:
                    "outline-none focus:outline-none outline-none focus:ring-offset-0 focus:outline-hidden outline-transparent",
                  input: "outline-none",
                }}
              />
            </div>
          )}
        />
      </div>
      
      {/* Email */}
      <Controller
        control={control}
        name="email"
        rules={{
          required: t("GuestWebsite.required",{input:t("GuestWebsite.Contact.email")}),
          pattern: {
            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            message: t("GuestWebsite.Contact.invalidEmail"),
          },
        }}
        render={({field: {value, onChange}}) => (
          <div className="w-full">
            <TextInput
              label={t("GuestWebsite.Contact.email")}
              value={value}
              size="large"
              required
              placeholder={""}
              onChangeText={onChange}
              error={errors.email?.message}
              disabled={isLoading}
              labelStyle={{color: color_text}}
              classNames={{
                divInput:
                  "outline-none focus:outline-none outline-none focus:ring-offset-0 focus:outline-hidden outline-transparent",
                input: "outline-none",
              }}
            />
          </div>
        )}
      />

      {/* Subject */}
      <Controller
        control={control}
        name="subject"
        rules={{required: t("GuestWebsite.required",{input:t("GuestWebsite.Contact.subject")})}}
        render={({field: {value, onChange}}) => (
          <div className="w-full">
            <TextInput
              label={t("GuestWebsite.Contact.subject")}
              value={value}
              size="large"
              required
              placeholder={""}
              onChangeText={onChange}
              error={errors.subject?.message}
              disabled={isLoading}
              labelStyle={{color: color_text}}
              classNames={{
                divInput:
                  "outline-none focus:outline-none outline-none focus:ring-offset-0 focus:outline-hidden outline-transparent",
                input: "outline-none",
              }}
            />
          </div>
        )}
      />

      {/* Message */}
      <Controller
        control={control}
        name="message"
        rules={{required: t("GuestWebsite.required",{input:t("GuestWebsite.Contact.message")})}}
        render={({field: {value, onChange}}) => (
          <div className="w-full">
            <TextAreaInput
              label={t("GuestWebsite.Contact.message")}
              value={value}
              required
              placeholder={""}
              onTextChange={onChange}
              error={errors.message?.message}
              disabled={isLoading}
              rows={3}
              labelStyle={{color: color_text}}
              classNames={{
                input:
                  "hover:border-element-border-hover  outline-none focus:outline-none focus:ring-offset-0 focus:outline-transparent",
              }}
            />
          </div>
        )}
      />

      {/* Submit Button */}
      <Button
        typeButton="submit"
        buttonClassName="w-full bg-none text-white font-semibold py-2 rounded hover:brightness-[0.8]"
        disabled={isLoading}
        loading={isLoading}
        colorLoader={color_main}
        buttonStyleCss={{backgroundColor: color_main}}
      >
        {t('Global.send')}
      </Button>
    </form>
  );
};
