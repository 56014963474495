import React from "react";
import TitleComponent from "../../Common/Template2.0/TitleComponent";
import ReviewCardComponent from "./ReviewCardComponent";
import useGuestWebsiteStore from "../../../../stores/guestWebsiteStore/useGuestWebsiteStore";
import {useTranslation} from "react-i18next";

const ReviewComponent = () => {
  const {t} = useTranslation();
  const {dataGuestWebsite} = useGuestWebsiteStore();
  const {content} = dataGuestWebsite || {};
  const {guest_reviews} = content || {};
  const reviews = guest_reviews?.cards;

  return (
    <div className="py-12 pb-16">
      <TitleComponent title={t("GuestWebsite.Home.reviewTitle")} />
      <div className="flex flex-wrap justify-center md:gap-6 md:px-6 items-stretch">
        {reviews?.map((review, index) => (
          <div
            key={review.name}
            className="w-[160px] md:h-auto md:w-1/3 mx-4 flex flex-col"
          >
            <ReviewCardComponent key={index} {...review} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReviewComponent;
