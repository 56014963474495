export const shorterStringBeforeSpace = (
  message: string,
  maxLength: number = 50
) => {
  // Si le message est plus court que la limite, le retourner tel quel
  if (message.length <= maxLength) {
    return message;
  }

  // Trouver l'index du dernier espace avant la limite de caractères
  let lastSpaceIndex = message.substring(0, maxLength).lastIndexOf(" ");

  // Si aucun espace n'est trouvé, retourner les 50 premiers caractères suivi de "..."
  if (lastSpaceIndex === -1) {
    return message.substring(0, maxLength) + "...";
  }

  // Retourner le message jusqu'au dernier espace trouvé suivi de "..."
  return message.substring(0, lastSpaceIndex) + "...";
};

export const insertTextAtPosition = (
  original: string,
  toInsert: string,
  position: number
) => {
  if (!original) return toInsert;

  const before = original.substring(0, position);
  const after = original.substring(position);

  // Insérer la nouvelle chaîne entre les deux parties et retourner le résultat
  return `${before}${toInsert}${after}`;
};

export const capitalizeFirstLetter = (value: string | undefined) => {
  return value ? value.charAt(0).toUpperCase() + value.slice(1) : "";
};

export const capitalizeLetters = (word: string, indexes: number[]): string => {
  return word
    .split("")
    .map((char, index) => {
      // Si l'index est dans la liste des indexes à capitaliser, on met la lettre en majuscule
      if (indexes.includes(index)) {
        return char.toUpperCase();
      }
      return char;
    })
    .join("");
};

export const generateSentenceForPlatforms = (value: string[]) => {
  if (value.length > 1) {
    return `via ${capitalizeFirstLetter(
      value.slice(0, -1).join(", ")
    )} et ${capitalizeFirstLetter(value[value.length - 1])}`;
  } else if (value.length === 1) {
    return `via ${capitalizeFirstLetter(value[0])}`;
  } else {
    return "";
  }
};

export const lowerFirstLetter = (value: string) => {
  return value?.charAt(0).toLowerCase() + value?.slice(1);
};

export const displayPriceWith2DigitsIfNeeded = (price: number) => {
  const device = price === "*****" ? "*" : "€";
  if (typeof price !== "number") return `${price}${device}`;

  if (Number.isInteger(price)) {
    return `${price} ${device}`;
  } else {
    return `${price.toFixed(2).replace(".", ",")} ${device}`;
  }
};

export function replaceWithBr(text: string) {
  if (!text) {
    return "";
  }
  return text.replace(/\n/g, "<br />");
}

/**
 * Removes special characters and accents from the input string.
 *
 * This function normalizes the input string by decomposing combined graphemes into their constituent parts,
 * then removes diacritical marks (accents). It also removes any non-alphanumeric characters (except spaces),
 * and replaces spaces with underscores.
 *
 * @param {string} input - The string to be processed.
 * @returns {string} - The processed string with special characters and accents removed, and spaces replaced by underscores.
 */
export const removeSpecialsCharactersAndAccents = (input: string) => {
  const normalizedString = input
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");

  const cleanString = normalizedString
    .replace(/[^a-zA-Z0-9\s]/g, "")
    .replace(/\s+/g, "_");

  return cleanString;
};

export function replaceAsteriskWithStrongBalise(text: string) {
  if (!text) {
    return "";
  }
  return text.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");
}

export function changeGroupCodeName(type: string) {
  switch (type) {
    case "guest":
      return "Global.rentals_guest";
    case "reservation":
      return "Global.rentals_reservation";
    case "payment":
      return "Global.rentals_payments";
    case "guest_page":
      return "Global.rentals_guest_page";
    case "rental":
      return "Global.rentals_rental";
    case "custom":
      return "Global.rentals_custom";
    default:
      return "";
  }
}

// defaultMessages.ts

export const defaultMessages = {
  french: {
    title: "Séjour [RENTAL_NAME]",
    message: `Merci pour votre réservation [GUEST_FIRSTNAME] 🙏

Pour vous accueillir au mieux dans notre hébergement et découvrir l'ensemble des instructions d'accès, je vous laisse accéder à notre page voyageur où nous avons renseigné l'ensemble des informations :

[GUESTPAGE_URL]

N'hésitez pas si vous avez la moindre question, nous sommes à votre disposition.

A bientôt ☀️
[RENTAL_HOST_NAME]`,
  },
  english: {
    title: "Your booking [RENTAL_NAME]",
    message: `Thank you for your reservation [GUEST_FIRSTNAME] 🙏

To best welcome you to our accommodation and see all the access instructions, I will let you access our traveler page where we have provided all the information:

[GUESTPAGE_URL]

Do not hesitate if you have any questions, we are at your disposal. See you soon ☀️
[RENTAL_HOST_NAME]`,
  },
  spanish: {
    title: "Tu reserva [RENTAL_NAME]",
    message: `Gracias por tu reserva [GUEST_FIRSTNAME] 🙏

Para darle la mejor bienvenida a nuestro alojamiento y ver todas las instrucciones de acceso, le dejaré acceder a nuestra página de viajero donde le hemos proporcionado toda la información:

[GUESTPAGE_URL]

No lo dudes si tienes alguna pregunta, estamos a tu disposición. Nos vemos pronto ☀️
[RENTAL_HOST_NAME]`,
  },
  german: {
    title: "Ihre Buchung [RENTAL_NAME]",
    message: `Vielen Dank für Ihre Reservierung [GUEST_FIRSTNAME] 🙏

Um Sie bestmöglich in unserer Unterkunft willkommen zu heißen und alle Zugangsanweisungen einzusehen, erlaube ich Ihnen den Zugriff auf unsere Reiseseite, auf der wir alle Informationen bereitgestellt haben:

[GUESTPAGE_URL]

Zögern Sie nicht, wenn Sie Fragen haben, wir stehen Ihnen gerne zur Verfügung. Bis bald ☀️
[RENTAL_HOST_NAME]`,
  },
};

export const removeSpaceFromString = (str: string) => str.replace(/\s+/g, "");

export function formatPhoneNumberWithIndicatif(
  phoneNumber: string,
  countryCode = "33"
) {
  const numericOnly = phoneNumber.replace(/\D/g, "");
  const withoutLeadingZeros = numericOnly.replace(/^0+/, "");
  return `+${countryCode}${withoutLeadingZeros}`;
}
