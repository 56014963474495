import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import BoxesIcon from "../../../assets/icons/boxes.svg?react";

import WebsiteModel2 from "../../../assets/images/website-model2.svg?react";
import WebsiteModelChicImage from "../../../assets/images/website-model-chic.svg?react";

import { Card } from "../../Common/Card/Card";
import { WebsiteResponse } from "../../../types/GETTypes";
import { useTranslation } from "react-i18next";
import { Badge } from "../../Common/Badge/Badge";
import { t } from "i18next";
import CheckIcon from "../../../assets/icons/check-white.svg?react";
import EditIcon from "../../../assets/icons/edit.svg?react";
import ArrowLeftIcon from "../../../assets/icons/arrow-left.svg?react";
import { WebsiteTemplateSelect } from "./WebsiteTemplateSelect";
import { post } from "../../../helpers/APIHelper";
import paths from "../../../constants/paths";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";

type TemplateType = "MODEL_2.0" | "MODEL_CHIC";

export const WebsiteTemplatesCard: React.FC<{
  website: WebsiteResponse | undefined;
  onUpdateWebsite: (nextWebsite: WebsiteResponse) => void;
}> = ({ website, onUpdateWebsite }) => {
  const { t } = useTranslation();

  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const [updateError, setUpdateError] = useState<string | undefined>();

  const [editMode, setEditMode] = useState<boolean>(false);
  const editModeRef = useRef<{
    handleClear: () => void;
    handleCheckData: () => {
      template: TemplateType;
    } | null;
  } | null>(null);

  const handleCancel = () => {
    setEditMode(false);
    editModeRef.current?.handleClear();
  };

  const handleUpdate = async () => {
    const values = editModeRef.current?.handleCheckData();

    if (!values) return;
    setUpdateLoading(true);
    setUpdateError(undefined);

    const formData = new FormData();
    formData.append("model", values.template);

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.WEBSITE.UPDATE.MODEL(
        website?.id!
      )}`,
      formData
    );

    if (!res?.data?.success) {
      setUpdateError(res?.response?.data?.message);
    } else {
      onUpdateWebsite(res.data?.result);
      setEditMode(false);
    }
    setUpdateLoading(false);
  };

  return (
    <Card
      Icon={BoxesIcon}
      label={t("Website.Templates.title")}
      anchor="templates-card"
      button={{
        Icon: editMode ? CheckIcon : EditIcon,
        type: editMode ? "primary" : "secondary",
        label: editMode ? t("Global.record") : t("Global.edit"),
        onClick: editMode ? () => handleUpdate() : () => setEditMode(!editMode),
        disabled: !website || updateLoading,
      }}
      secondaryButton={
        editMode
          ? {
              label: t("Global.cancel"),
              LeftIcon: ArrowLeftIcon,
              onClick: handleCancel,
              disabled: updateLoading,
            }
          : undefined
      }
    >
      <ErrorMessage>{updateError}</ErrorMessage>

      {editMode ? (
        <EditMode ref={editModeRef} website={website} loading={updateLoading} />
      ) : (
        <ViewMode website={website} />
      )}
    </Card>
  );
};

const ViewMode: React.FC<{ website: WebsiteResponse | undefined }> = ({
  website,
}) => {
  if (!website) return <ViewModeSkeleton />;

  if (website?.styles.model === "MODEL_2.0")
    return (
      <div className="flex gap-4 bg-element-background border-1 border-element-border p-0 rounded-6px m-0 items-center pr-4 overflow-hidden">
        <WebsiteModel2 className="h-32 ml-[-5px]" />

        <div className="flex flex-col gap-2 flex-1">
          <p className="font-semibold text-high-contrast text-lg">Modele 2.0</p>

          <p className="font-light text-low-contrast">Moderne, coloré, frais</p>
        </div>

        <Badge
          status="success"
          label={t("Website.Templates.choosenTemplate")}
        />
      </div>
    );

  if (website?.styles.model === "MODEL_CHIC")
    return (
      <div className="flex gap-4 bg-element-background border-1 border-element-border p-0 rounded-6px m-0 items-center pr-4 overflow-hidden">
        <WebsiteModelChicImage className="h-32 ml-[-5px]" />

        <div className="flex flex-col gap-2 flex-1">
          <p className="font-semibold text-high-contrast text-lg">
            Modele Chic
          </p>

          <p className="font-light text-low-contrast">
            Classe, luxueux, soigné
          </p>
        </div>

        <Badge
          status="success"
          label={t("Website.Templates.choosenTemplate")}
        />
      </div>
    );
};

const ViewModeSkeleton: React.FC<{}> = ({}) => {
  return (
    <div className="flex gap-4 bg-element-background border-1 border-element-border p-0 rounded-6px m-0 items-center pr-4 overflow-hidden animate-pulse">
      <div className="w-64 h-32 ml-[-5px] rounded-l-lg bg-slate-200" />

      <div className="flex flex-col gap-2 flex-1">
        <p className="font-semibold text-high-contrast text-lg h-2 rounded bg-slate-300 w-32"></p>

        <p className="font-light text-low-contrast h-2 rounded bg-slate-200 w-52 mt-2"></p>
      </div>

      <Badge status="success" label={t("Website.Templates.choosenTemplate")} />
    </div>
  );
};

interface EditModeProps {
  website: WebsiteResponse | undefined;
  loading: boolean;
}

const EditMode = forwardRef<
  {
    handleClear: () => void;
    handleCheckData: () => {
      template: TemplateType;
    } | null;
  },
  EditModeProps
>(({ website, loading }, ref) => {
  const [currentTemplate, setCurrentTemplate] =
    useState<TemplateType>("MODEL_2.0");

  useEffect(() => {
    setCurrentTemplate(website?.styles.model as TemplateType);
  }, [website]);

  useImperativeHandle(ref, () => ({
    handleClear,
    handleCheckData,
  }));

  const handleCheckData = () => {
    return {
      template: currentTemplate,
    };
  };

  const handleClear = () => {
    setCurrentTemplate(website?.styles.model as TemplateType);
  };

  return (
    <WebsiteTemplateSelect
      disabled={loading}
      selectedTemplate={currentTemplate}
      onSelectTemplate={setCurrentTemplate}
    />
  );
});
