import React from "react";
import useGuestWebsite from "../../../../hooks/useGuestWebsite";
import useGuestWebsiteStore, {
  GuestWebsiteTabType,
} from "../../../../stores/guestWebsiteStore/useGuestWebsiteStore";
import {useTranslation} from "react-i18next";
import {cn} from "../../../../helpers/classHelper";
import useWindowSize from "../../../../hooks/useWindowSize";
import HeaderTemplate2Mobile from "./HeaderTemplate2Mobile";
import SearchBannerComponent from "../../Common/Template2.0/SearchBannerComponent";
import CalendarIcon from "../../../../assets/icons/calendar.svg?react";

const HeaderTemplate2 = () => {
  const {t} = useTranslation();
  const {isMobile} = useWindowSize();
  const {dataGuestWebsite, setTabGuestWebsite, tabGuestWebsite} =
    useGuestWebsiteStore();
  const {content} = dataGuestWebsite || {};
  const {
    background,
    title: titleHome,
    subtitle: subtitleHome,
    logo,
  } = content?.general || {};
  const titleServices = content?.services?.title;
  const subtitleServices = content?.services?.subtitle;
  const showService = content?.services?.enabled;
  const showAbout = content?.about?.enabled;
  const showContact = content?.contact?.enabled;

  const getTitle = () => {
    switch (tabGuestWebsite) {
      case "home":
        return titleHome;
      case "rentals":
      case "rental":
        return t("GuestWebsite.Header.titleRentals");
      case "services":
        return titleServices ?? t("GuestWebsite.Header.titleServices");
      case "about":
        return t("GuestWebsite.Header.titleAbout");
      case "contact":
        return t("GuestWebsite.Header.titleContact");
      case "mentions":
        return t("GuestWebsite.Header.titleMentions");
      case "conditions":
        return t("GuestWebsite.Header.titleConditions");
      case "privacy":
        return t("GuestWebsite.Header.titlePrivacy");
      default:
        return titleHome;
    }
  };
  const getSubtitle = () => {
    switch (tabGuestWebsite) {
      case "home":
        return subtitleHome;
      case "rentals":
      case "rental":
        return t("GuestWebsite.Header.subtitleRentals");
      case "services":
        return subtitleServices ?? t("GuestWebsite.Header.subtitleServices");
      case "about":
        return t("GuestWebsite.Header.subtitleAbout");
      case "contact":
        return t("GuestWebsite.Header.subtitleContact");
      case "mentions":
      case "conditions":
      case "privacy":
        return "";
      default:
        return subtitleHome;
    }
  };

  const getUnderline = (nav: GuestWebsiteTabType) => {
    if (
      nav === "rentals" &&
      (tabGuestWebsite === "rentals" || tabGuestWebsite === "rental")
    ) {
      return "underline";
    }
    return nav === tabGuestWebsite ? "underline" : "";
  };

  if (isMobile) {
    return (
      <HeaderTemplate2Mobile
        getUnderline={getUnderline}
        getSubtitle={getSubtitle}
        getTitle={getTitle}
        logo={logo}
        background={background}
      />
    );
  }

  return (
    <div className="relative">
      <header
        className="flex flex-col p-4 text-white pb-24 relative"
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/* Overlay */}
        <div className="absolute inset-0 bg-black/50"></div>
        {/* Top Section */}
        <div className="relative flex justify-between items-center">
          {/* Logo */}
          <div
            className="flex items-center cursor-pointer"
            onClick={() => setTabGuestWebsite("home")}
          >
            <img
              src={logo}
              alt="Le Super Template Logo"
              className="w-44 h-16"
            />
          </div>

          {/* Navigation and Actions */}
          <div className="relative flex items-center space-x-6">
            {/* Navigation Links */}
            <nav className="hidden md:flex space-x-6">
              <a
                className={cn(
                  `font-barlow-condensed text-lg font-normal cursor-pointer ${getUnderline(
                    "rentals"
                  )}`
                )}
                onClick={() => setTabGuestWebsite("rentals")}
              >
                {t("GuestWebsite.Header.Nav.rentals")}
              </a>
              {showService && (
                <a
                  className={cn(
                    `font-barlow-condensed text-lg font-normal cursor-pointer ${getUnderline(
                      "services"
                    )}`
                  )}
                  onClick={() => setTabGuestWebsite("services")}
                >
                  {t("GuestWebsite.Header.Nav.services")}
                </a>
              )}
              {showAbout && (
                <a
                  className={cn(
                    `font-barlow-condensed text-lg font-normal cursor-pointer ${getUnderline(
                      "about"
                    )}`
                  )}
                  onClick={() => setTabGuestWebsite("about")}
                >
                  {t("GuestWebsite.Header.Nav.about")}
                </a>
              )}
              {showContact && (
                <a
                  className={cn(
                    `font-barlow-condensed text-lg font-normal cursor-pointer ${getUnderline(
                      "contact"
                    )}`
                  )}
                  onClick={() => setTabGuestWebsite("contact")}
                >
                  {t("GuestWebsite.Header.Nav.contact")}
                </a>
              )}
            </nav>

            {/* Flag */}
            <div className="flex items-center space-x-1">
              {/* <span className="text-base">🇫🇷</span>
              <span className="text-base">▼</span> */}
              <span className="text-base"></span>
              <span className="text-base"></span>
            </div>

            {/* Reservation Button */}
            <button
              className="font-barlow-condensed bg-white text-black text-lg font-normal px-4 py-2 rounded shadow hover:bg-gray-200 flex justify-center items-center gap-x-1"
              onClick={() => setTabGuestWebsite("rentals")}
            >
              {t("GuestWebsite.book")} <CalendarIcon />
            </button>
          </div>
        </div>

        {/* Center Section */}
        <div className="relative flex flex-col items-center justify-center mt-12 space-y-4">
          {/* Title */}
          <h1 className="text-headerWebsite font-bold text-center">
            {getTitle()}
          </h1>

          {/* Subtitle */}
          <p className="text-xxxl text-center">{getSubtitle()}</p>
          <SearchBannerComponent isAbsolute />
        </div>
      </header>
    </div>
  );
};

export default HeaderTemplate2;
