import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import AirbnbIcon from "../../../../assets/icons/airbnb.svg?react";
import ArrowLeftIcon from "../../../../assets/icons/arrow-left.svg?react";
import BookingIcon from "../../../../assets/icons/booking.svg?react";
import DirectIcon from "../../../../assets/icons/tv-circle.svg?react";
import CheckIcon from "../../../../assets/icons/check-white.svg?react";
import EditIcon from "../../../../assets/icons/edit.svg?react";
import TrendingUpIcon from "../../../../assets/icons/trending-up.svg?react";
import paths from "../../../../constants/paths";
import {post} from "../../../../helpers/APIHelper";
import {useRentalPageContext} from "../../../../pages/RentalPage/RentalPageContext";
import {RequiredFields, ValueType} from "../../../../types/commonTypes";
import {Card} from "../../../Common/Card/Card";
import {ErrorMessage} from "../../../Common/ErrorMessage/ErrorMessage";
import {NumberInput} from "../../../Common/NumberInput/NumberInput";
import {SettingElement} from "../../../Common/SettingElement/SettingElement";
import {
  RentalPlatformRateForm,
  RentalPlatformRateUpdateRequest,
} from "./PlatformRate.type";
import {RentalPlatformRateSkeleton} from "./PlatformRateSkeleton";
import useUserStore from "../../../../stores/useUserStore";
import {getCurrentCanEdit} from "../../../../helpers/workspaceHelper";
import PriceMultiplier from "../../../PriceMultiplier/PriceMultiplier";
import {UseModal} from "../../../../hooks/useModal";
import {RentalResponse} from "../../../../types/GETTypes";
import {getSignNumber} from "../../../../helpers/priceMultiplierHelper";

export const RentalPlatformRate: React.FC<{
  workspaceOwner: ValueType | undefined;
  priceMultiplierPage: UseModal<{
    onUpdateRental: (rental: RentalResponse | undefined) => void;
  }>;
}> = ({workspaceOwner, priceMultiplierPage}) => {
  const userStore = useUserStore();
  const {t} = useTranslation();

  const {rentalId} = useParams();
  const {
    loadingState: {loading},
    rental,
    onUpdateRental,
  } = useRentalPageContext();

  const [error, setError] = useState<string | undefined>();
  const [validationLoading, setValidationLoading] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: {errors, isValid},
    getValues,
    setValue,
    watch,
  } = useForm<RentalPlatformRateForm>({
    mode: "all",
  });

  const requiredFields: RequiredFields<RentalPlatformRateForm> = {
    airbnb_price_multiplier_value: true,
    booking_price_multiplier_value: true,
    website_price_multiplier_value: true,
  };

  useEffect(() => {
    setValue(
      "airbnb_price_multiplier_value",
      rental?.tarification?.price_multipliers?.platform_airbnb ?? 0
    );

    setValue(
      "booking_price_multiplier_value",
      rental?.tarification.price_multipliers?.platform_booking ?? 0
    );

    setValue(
      "website_price_multiplier_value",
      rental?.tarification.price_multipliers?.platform_website ?? 0
    );
  }, [rental?.tarification.price_multipliers]);

  const updatePlatformRate = async () => {
    setError(undefined);
    setValidationLoading(true);

    const data: RentalPlatformRateUpdateRequest = {
      price_multiplier_airbnb: getValues("airbnb_price_multiplier_value"),
      price_multiplier_booking: getValues("booking_price_multiplier_value"),
      price_multiplier_platform_website: getValues(
        "website_price_multiplier_value"
      ),
    };

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS_UPDATE}/${rentalId}${
        paths.API.RENTAL.UPDATE_PRICE_MULTIPLIERS
      }`,
      data
    );

    if (res?.data?.success) {
      if (rental) {
        onUpdateRental({
          ...rental,
          tarification: {
            ...rental.tarification,
            price_multipliers: {
              platform_airbnb: getValues("airbnb_price_multiplier_value"),
              platform_booking: getValues("booking_price_multiplier_value"),
              platform_website: getValues("website_price_multiplier_value"),
            },
          },
        });
      }

      setEditMode(!editMode);
    } else {
      setError(res?.response?.data?.message);
    }
    setValidationLoading(false);
  };

  const handleUpdate = () => {
    updatePlatformRate();
  };

  const isEditButtonDisabled = () => {
    if (!getCurrentCanEdit({user: userStore.user, workspaceOwner})) return true;

    return priceMultiplierPage.isVisible
      ? !isValid || validationLoading
      : rental?.tarification.price_multipliers?.platform_airbnb === null &&
          rental?.tarification.price_multipliers?.platform_booking === null &&
          rental?.tarification.price_multipliers?.platform_website === null;
  };

  watch();

  if (loading) return <RentalPlatformRateSkeleton />;

  return (
    <Card
      Icon={TrendingUpIcon}
      label={t("Rental.Pricing.PriceMultipliers.title")}
      anchor="rate-card"
      tooltip={{
        value: t("Rental.Pricing.PriceMultipliers.tooltip"),
        size: "full",
      }}
      loading={validationLoading}
      button={{
        Icon: EditIcon,
        type: "secondary",
        label: t("Global.edit"),
        onClick: () => priceMultiplierPage.open({onUpdateRental}),
        disabled: isEditButtonDisabled(),
      }}
    >
      <>
        <ErrorMessage>{error}</ErrorMessage>
        <div className="flex flex-col space-y-3">
          {/* AIRBNB PRICE MULTIPLIER */}
          <SettingElement
            Icon={AirbnbIcon}
            title={t("Rental.Pricing.PriceMultipliers.airbnbTitle")}
            description={t("Rental.Pricing.PriceMultipliers.airbnbDescription")}
          >
            <p className="text-base font-bold text-active">
              {`${
                rental?.tarification.price_multipliers?.platform_airbnb === 0
                  ? rental?.tarification.price_multipliers?.platform_airbnb
                  : getSignNumber({
                      number: String(
                        rental?.tarification.price_multipliers
                          ?.platform_airbnb ?? 0
                      ),
                      showNegativeSign: false,
                      withNumber: true,
                    }).result
              }%`}
            </p>
          </SettingElement>

          {/* BOOKING PRICE MULTIPLIER */}
          <SettingElement
            Icon={BookingIcon}
            title={t("Rental.Pricing.PriceMultipliers.bookingTitle")}
            description={t(
              "Rental.Pricing.PriceMultipliers.bookingDescription"
            )}
          >
            <p className="text-base font-bold text-active">{`${
              rental?.tarification.price_multipliers?.platform_booking === 0
                ? rental?.tarification.price_multipliers?.platform_booking
                : getSignNumber({
                    number: String(
                      rental?.tarification.price_multipliers
                        ?.platform_booking ?? 0
                    ),
                    showNegativeSign: false,
                    withNumber: true,
                  }).result
            }%`}</p>
          </SettingElement>

          {/* WEBSITE PRICE MULTIPLIER */}
          <SettingElement
            Icon={DirectIcon}
            title={t("Rental.Pricing.PriceMultipliers.websiteTitle")}
            description={t(
              "Rental.Pricing.PriceMultipliers.websiteDescription"
            )}
          >
            <p className="text-base font-bold text-active">{`${
              rental?.tarification.price_multipliers?.platform_website === 0
                ? rental?.tarification.price_multipliers?.platform_website
                : getSignNumber({
                    number: String(
                      rental?.tarification.price_multipliers
                        ?.platform_website ?? 0
                    ),
                    showNegativeSign: false,
                    withNumber: true,
                  }).result
            }%`}</p>
          </SettingElement>
        </div>
      </>
    </Card>
  );
};
