/**
 * Modal form Right for edit all payments status in period
 * defined (only payment status, no other data).
 *
 * kakou kakou reviewer :=)
 */

import { RightModal } from "../Common/RightModal/RightModal";
import { PaymentModalEditAllprops, PaymentType } from "./Payment.type";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../Common/Button/Button";
import useFormattedMonthYear from "../../hooks/useDate";
import { getFormattedPrice } from "../../helpers/currencyHelper";
import { CheckBox } from "../Common/CheckBox/CheckBox";
import { Badge } from "../Common/Badge/Badge";
import { usePaymentOperational } from "../../hooks/api/paymentOperational";
import { ErrorMessage } from "../Common/ErrorMessage/ErrorMessage";

export const PaymentModalEditAll = ({
  modal,
  handleUpdateSuccess,
}: PaymentModalEditAllprops) => {
  const { t } = useTranslation();
  const { formatMonthYear } = useFormattedMonthYear();
  const { updatePaymentStatus } = usePaymentOperational();

  const [error, setError] = useState("");
  const [paymentType, setPaymentType] = useState<PaymentType>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setPaymentType(modal.data?.paymentData?.summary?.status);
  }, [modal]);

  /**
   * Function for extract profil photo of
   * operator person.
   */
  const extractOperatorPhotoFromPayment = (): string | null => {
    const payment = modal.data?.paymentData.day_payments.find(
      (payment) => payment.operator?.photo,
    );
    return (
      payment?.operator.photo ??
      "https://cdn-icons-png.flaticon.com/512/3135/3135715.png"
    ); // Return `null` when no photo is found
  };

  /**
   * Function for updates
   * all status payments.
   */
  const handleSubmit = () => {
    const allPaymentsIds = modal.data?.paymentData.summary.payment_ids;
    if (!allPaymentsIds) return;
    setIsLoading(true);
    setError("");

    if (!paymentType) {
      setError(t("Management.Payments.paymentTypeRequired"));
      setIsLoading(false);
      return;
    }

    updatePaymentStatus(paymentType, allPaymentsIds)
      .then((isUpdate) => {
        if (isUpdate) {
          handleUpdateSuccess();
          modal.close();
        }
      })
      .catch((error: Error) => {
        setError(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <RightModal
        classNames={{ mainContentParent: "overflow-y-auto" }}
        title={t("Management.Payments.paidFor")}
        isVisible={modal.isVisible}
        onClose={modal.close}
      >
        <main className="flex justify-between flex-col w-full">
          <section id="form">
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <p className="text-xs text-gray-500 font-bold">
              {t("Global.info")}
            </p>
            <div className="mt-4 flex flex-col gap-1">
              <p className={"font-bold"}>
                {t("Management.Payments.personToPaid")}
              </p>
              <div className="p-1 px-3 border bg-gray-100 flex items-center gap-3 rounded-md font-bold text-gray-500">
                {extractOperatorPhotoFromPayment() && (
                  <img
                    src={extractOperatorPhotoFromPayment()?.toString()}
                    alt="operator photo"
                    className={"rounded-full w-6 h-6"}
                  />
                )}
                <p>{modal.data?.operatorName}</p>
              </div>
              <p className={"font-bold mt-4"}>
                {t("Management.Payments.period")}
              </p>
              <div className="p-2 px-3 border bg-gray-100 flex items-center gap-3 rounded-md font-bold text-gray-500">
                <p>{formatMonthYear(modal.data?.period)}</p>
              </div>
              <p className={"font-bold mt-4"}>
                {t("Management.Payments.total")}
              </p>
              <div className="p-2 px-3 border bg-gray-100 flex items-center gap-3 rounded-md font-bold text-gray-500">
                <p>
                  {getFormattedPrice({
                    price: modal.data?.paymentData.summary.total_amount ?? 0,
                  })}
                </p>
              </div>
              <p className={"text-gray-500 text-xs"}>
                {t("Management.Payments.calculateAt", {
                  from: modal.data?.fromDate.replaceAll("-", "/"),
                  to: modal.data?.toDate.replaceAll("-", "/"),
                })}
              </p>
              <p className={"font-bold mt-4"}>
                {t("Management.Payments.paymentState")}
              </p>
              <div className={"flex items-center gap-4"}>
                <div className={"flex items-center gap-1"}>
                  <CheckBox
                    isRadio={true}
                    value={paymentType == "INVOICE_RECEIVED"}
                    onChange={(value) => {
                      if (!value) {
                        setPaymentType(undefined);
                        return;
                      }
                      setPaymentType("INVOICE_RECEIVED");
                    }}
                  />
                  <Badge
                    labelStyle={"cursor-pointer"}
                    status={"pending"}
                    label={t("Management.Payments.invoiceReceived")}
                    onClick={() => {
                      setPaymentType("INVOICE_RECEIVED");
                    }}
                  />
                </div>
                <div className={"flex items-center gap-1"}>
                  <CheckBox
                    isRadio={true}
                    value={paymentType == "PENDING"}
                    onChange={(value) => {
                      if (!value) {
                        setPaymentType(undefined);
                        return;
                      }
                      setPaymentType("PENDING");
                    }}
                  />
                  <Badge
                    labelStyle={"cursor-pointer"}
                    status={"fail"}
                    label={t("Management.Payments.toPaid")}
                    onClick={() => {
                      setPaymentType("PENDING");
                    }}
                  />
                </div>
                <div className={"flex items-center gap-1"}>
                  <CheckBox
                    isRadio={true}
                    value={paymentType == "PAID"}
                    onChange={(value) => {
                      if (!value) {
                        setPaymentType(undefined);
                        return;
                      }
                      setPaymentType("PAID");
                    }}
                  />
                  <Badge
                    labelStyle={"cursor-pointer"}
                    status={"success"}
                    label={t("Management.Payments.paid")}
                    onClick={() => {
                      setPaymentType("PAID");
                    }}
                  />
                </div>
              </div>
            </div>
          </section>
          <section
            id="buttons"
            className="flex items-center justify-between gap-2"
          >
            {!isLoading && (
              <Button onClick={modal.close} type="secondary">
                {t("Global.cancel")}
              </Button>
            )}
            <Button loading={isLoading} onClick={handleSubmit}>
              {t("Global.record")}
            </Button>
          </section>
        </main>
      </RightModal>
    </>
  );
};
