import React from "react";
import {useTranslation} from "react-i18next";
import DetailsTableComponent from "./DetailsTableComponent";
import BarChartComponent from "./BarChartComponent";
import PieChartComponent from "./PieChartComponent";
import LineChartComponent from "./LineChartComponent";
import StackedBarChartComponent from "./StackedBarChartComponent";
import EvaluationsTableComponent from "./EvaluationsTableComponent";
import DetailsModal from "../DetailsModal";
import {useModal} from "../../../hooks/useModal";
import {
  AnalyticsDetailedResponse,
  DistributionKeyEnum,
  StatsNameKeysEnum,
} from "../../../types/GETTypes";
import {ValueType} from "../../../types/commonTypes";

interface DetailsComponentProps {
  stats: AnalyticsDetailedResponse | undefined;
  loading?: boolean;
  currentYear: ValueType;
}

const DetailsComponent = ({
  loading = false,
  stats,
  currentYear,
}: DetailsComponentProps) => {
  const {t} = useTranslation();
  const modalDetails = useModal();

  // const evaluationsData = [
  //   {
  //     name: "Airbnb",
  //     rating: "8/10",
  //     icon: <AirbnbIcon className="w-4 h-4 rounded-full mr-2" />,
  //   },
  //   {
  //     name: "Booking.com",
  //     rating: "8,9/10",
  //     icon: <BookingIcon className="w-4 h-4 rounded-full mr-2" />,
  //   },
  //   {
  //     name: "Réservations en direct",
  //     rating: "7/10",
  //     icon: <SuperhoteIcon className="w-4 h-4 rounded-full mr-2" />,
  //   },
  // ];

  const openModalDetails = (
    statName: StatsNameKeysEnum,
    platform: DistributionKeyEnum
  ) => {
    modalDetails.open({
      statName,
      platform,
      years: currentYear,
      currency: stats?.currency,
    });
  };

  return (
    <div className="mt-2 space-y-8 w-full">
      <DetailsModal modal={modalDetails} />
      <div className="flex space-x-4">
        <div className="flex flex-col w-full space-y-6">
          <BarChartComponent
            data={stats?.summary}
            chartData={stats?.summary.revenue_metrics}
            xAxisdataKey="month"
            foregroundBarDataKey="paid"
            foregroundBarFillColor="#e74c3c"
            foregroundBarName={t("Analytics.alreadyPaid")}
            backgroundBarDataKey="upcoming"
            backgroundBarFillColor="#F8E6E3"
            backgroundBarName={t("Analytics.upcomingPaid")}
            openModalDetails={openModalDetails}
            currentYear={currentYear}
            loading={loading}
            currency={stats?.currency}
          />
          <PieChartComponent
            data={stats?.revenue_by_rental}
            currency={stats?.currency}
            loading={loading}
          />
        </div>
        <div className="flex flex-col w-full space-y-6">
          <LineChartComponent
            data={stats?.occupancy_statistics}
            xAxisDataKey={"month"}
            lineDataKey="occupancy"
            lineStrokeColor="#e74c3c"
            openModalDetails={openModalDetails}
            loading={loading}
            currency={stats?.currency}
          />
          <StackedBarChartComponent
            data={stats?.summary.revenue_breakdown.distribution}
            loading={loading}
            barDataKey="value"
            currency={stats?.currency}
          />
          {/* <EvaluationsTableComponent data={evaluationsData} loading={loading}/> */}
        </div>
      </div>
      <DetailsTableComponent
        data={stats?.monthly_details}
        currency={stats?.currency}
        loading={loading}
      />
    </div>
  );
};

export default DetailsComponent;
