import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "../../../Common/Card/Card";
import { ErrorMessage } from "../../../Common/ErrorMessage/ErrorMessage";
import { PaymentExtraProps } from "../Contact/ContactCard.type";
import { WifiCardSkeleton } from "./WifiCardSkeleton";
import CheckIcon from "../../../../assets/icons/check-white.svg?react";
import EditIcon from "../../../../assets/icons/edit.svg?react";
import { Controller, useForm } from "react-hook-form";
import ArrowLeftIcon from "../../../../assets/icons/arrow-left.svg?react";
import { TextAreaInput } from "../../../Common/TextAreaInput/TextAreaInput";
import { WifiForm } from "../Instructions/InstructionCard.type";
import { useUpdateWifi } from "../../../../hooks/api/guestPage";
import { TextInput } from "../../../Common/TextInput/TextInput";
import { useCheckAuth } from "../../../../hooks/useCheckAuth";
import { capitalizeFirstLetter } from "../../../../helpers/stringHelper";
import WifiIcon from "../../../../assets/icons/wifi.svg?react";
import { getCurrentCanEdit } from "../../../../helpers/workspaceHelper";

export const WifiCard: React.FC<PaymentExtraProps> = ({
  loading,
  guestPage,
  rentalId,
  workspaceOwner,
}) => {
  const { user } = useCheckAuth();
  const { t } = useTranslation();
  const [validationLoading, setValidationLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [editMode, setEditMode] = useState<boolean>(false);

  const [wifiName, setWifiName] = useState(guestPage?.wifi_name);
  const [wifiPassword, setWifiPassword] = useState(guestPage?.wifi_password);
  const [activeWifi, setActiveWifi] = useState<boolean>(
    guestPage?.wifi_enabled === 1 ? true : false
  );
  const form = useForm<WifiForm>({
    defaultValues: {
      wifi_name: wifiName,
      wifi_password: wifiPassword,
    },
  });

  const initSubmit = () => {
    setError("");
    setValidationLoading(true);
  };

  const handleUpdateWifi = async (data: WifiForm) => {
    if (!data.wifi_name || !data.wifi_password) {
      setError(t("Rental.Infos.TravelerPage.WifiCard.wifiRequired"));
      return;
    }
    initSubmit();

    useUpdateWifi(
      rentalId,
      { wifi_enabled: Number(activeWifi), ...data },
      (result) => {
        setWifiName(result?.guest_page?.wifi_name);
        setWifiPassword(result?.guest_page?.wifi_password);
        setValidationLoading(false);
        setEditMode(false);
      },
      (error) => {
        setError(error);
        setValidationLoading(false);
      }
    );
  };

  const handleStatusReview = () => {
    setActiveWifi(!activeWifi);
    setValidationLoading(true);

    if (activeWifi) {
      useUpdateWifi(
        rentalId,
        { wifi_enabled: 0 },
        (result) => {
          setValidationLoading(false);
        },
        () => {
          setActiveWifi(true);
          setValidationLoading(false);
        }
      );
    } else {
      useUpdateWifi(
        rentalId,
        { wifi_enabled: 1 },
        () => {
          setValidationLoading(false);
        },
        () => {
          setActiveWifi(false);
          setValidationLoading(false);
        }
      );
    }
  };

  useEffect(() => {}, [user]);

  if (loading) return <WifiCardSkeleton />;
  return (
    <Card
      loading={validationLoading}
      Icon={WifiIcon}
      label={t("Rental.Infos.TravelerPage.WifiCard.title")}
      anchor="wifi-card"
      switchButton={{
        label: t("Rental.Infos.TravelerPage.ContactCard.switch"),
        value: activeWifi,
        loading: validationLoading,
        disabled: !getCurrentCanEdit({ user, workspaceOwner }),
        onClick: () => {
          handleStatusReview();
        },
      }}
      button={{
        Icon: editMode ? CheckIcon : EditIcon,
        type: editMode ? "primary" : "secondary",
        label: editMode ? t("Global.record") : t("Global.edit"),
        disabled: !getCurrentCanEdit({ user, workspaceOwner }),
        onClick: editMode
          ? form.handleSubmit((data: WifiForm) => {
              handleUpdateWifi(data);
            })
          : () => setEditMode(!editMode),
      }}
      secondaryButton={
        editMode
          ? {
              label: t("Global.cancel"),
              LeftIcon: ArrowLeftIcon,
              onClick: () => {
                setEditMode(false);
                form.setValue("wifi_password", wifiPassword);
                form.setValue("wifi_name", wifiName);
              },
            }
          : undefined
      }
    >
      <div className="flex flex-col gap-y-4">
        {error ? <ErrorMessage>{error}</ErrorMessage> : null}
        {editMode ? (
          <div className="flex flex-col gap-3">
            <Controller
              control={form.control}
              name="wifi_name"
              render={({ field: { value, onChange } }) => {
                return (
                  <div className="flex flex-col">
                    <TextInput
                      label={t("Rental.Infos.TravelerPage.WifiCard.wifiName")}
                      placeholder={t(
                        "Rental.Infos.TravelerPage.WifiCard.wifiNamePlaceholder",
                        { hostname: capitalizeFirstLetter(user?.last_name) }
                      )}
                      required={true}
                      value={value}
                      onChangeText={(text) => {
                        onChange(text);
                      }}
                    />
                  </div>
                );
              }}
            />
            <Controller
              control={form.control}
              name="wifi_password"
              render={({ field: { value, onChange } }) => {
                return (
                  <div className="flex flex-col">
                    <TextInput
                      label={t(
                        "Rental.Infos.TravelerPage.WifiCard.wifiPassword"
                      )}
                      required={true}
                      value={value}
                      onChangeText={(text) => {
                        onChange(text);
                      }}
                    />
                  </div>
                );
              }}
            />
          </div>
        ) : (
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <div className="flex flex-col gap-y-2">
                <p className="text-low-contrast">
                  {t("Rental.Infos.TravelerPage.WifiCard.wifiName")}
                </p>
                <p className="text-high-contrast font-bold">{wifiName}</p>
              </div>
              <div className="flex flex-col gap-y-2">
                <p className="text-low-contrast">
                  {t("Rental.Infos.TravelerPage.WifiCard.wifiPassword")}
                </p>
                <p className="text-high-contrast font-bold">{wifiPassword}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </Card>
  );
};
