import React from "react";
import {useTranslation} from "react-i18next";
import EditIcon from "../../../assets/icons/edit.svg?react";
import TagIcon from "../../../assets/icons/tag.svg?react";
import {Card} from "../../Common/Card/Card";
import {Separator} from "../../Common/Separator/Separator";

export const UserAdministrativeCardSkeleton: React.FC<{}> = () => {
  const {t} = useTranslation();

  return (
    <Card
      Icon={TagIcon}
      label={t("Profil.Administrative.title")}
      button={{Icon: EditIcon, label: t("Global.edit"), skeleton: true}}
    >
      <div className="flex flex-col animate-pulse">
        <div className="flex flex-col justify-between gap-3 space-y-8">
          <div className="flex flex-col gap-3 mt-2">
            <p className="w-52 h-4 rounded bg-slate-200"></p>
            <p className="h-4 rounded-lg w-56 bg-slate-200"></p>
          </div>

          <div className="flex flex-row justify-between gap-3">
            <div className="flex flex-col gap-3 mt-2">
              <p className="w-52 h-4 rounded bg-slate-200"></p>
              <p className="h-4 rounded-lg w-56 bg-slate-200"></p>
            </div>
            <div className="flex flex-col gap-3 mt-2">
              <p className="w-52 h-4 rounded bg-slate-200"></p>
              <p className="h-4 rounded-lg w-56 bg-slate-200"></p>
            </div>
          </div>
        </div>

        <Separator />
      </div>
    </Card>
  );
};
