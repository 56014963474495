import React from "react";
import { PageMenu } from "../Common/PageMenu/PageMenu";

import InformationIcon from "../../assets/icons/information.svg?react";
import InformationHighIcon from "../../assets/icons/information.svg?react";

import SignageIcon from "../../assets/icons/signage.svg?react";
import SignageHighIcon from "../../assets/icons/signage-high.svg?react";

import ListIcon from "../../assets/icons/list.svg?react";
import ListHighIcon from "../../assets/icons/list-high.svg?react";

import LayoutBottomIcon from "../../assets/icons/layout-bottom.svg?react";
import LayoutBottomHighIcon from "../../assets/icons/layout-bottom-high.svg?react";

import DoubleChatIcon from "../../assets/icons/double-chat-bubble.svg?react";
import DoubleChatHighIcon from "../../assets/icons/double-chat-bubble-high.svg?react";

import QuestionMarkCircleIcon from "../../assets/icons/question-mark-circle.svg?react";
import QuestionMarkCircleHighIcon from "../../assets/icons/question-mark-circle.svg?react";

import FileTextIcon from "../../assets/icons/file-text.svg?react";
import FileTextHighIcon from "../../assets/icons/file-text-high.svg?react";

import KeyIcon from "../../assets/icons/key.svg?react";
import KeyHighIcon from "../../assets/icons/key-high.svg?react";

import PhoneIcon from "../../assets/icons/phone.svg?react";
import PhoneHighIcon from "../../assets/icons/phone-high.svg?react";

export const WebsiteContentMenu: React.FC<{}> = () => {
  return (
    <PageMenu
      items={[
        {
          Icon: InformationIcon,
          IconActive: InformationHighIcon,
          anchor: "infos-card",
          labelI18n: "Website.Infos.title",
        },
        {
          Icon: SignageIcon,
          IconActive: SignageHighIcon,
          anchor: "logos-card",
          labelI18n: "Website.Logos.title",
        },
        {
          Icon: ListIcon,
          IconActive: ListHighIcon,
          anchor: "rentals-card",
          labelI18n: "Website.Rentals.title",
        },
        {
          Icon: LayoutBottomIcon,
          IconActive: LayoutBottomHighIcon,
          anchor: "moreinfos1-card",
          labelI18n: "Website.MoreInfos1.title",
        },
        {
          Icon: DoubleChatIcon,
          IconActive: DoubleChatHighIcon,
          anchor: "testimonials-card",
          labelI18n: "Website.Testimonials.title",
        },
        {
          Icon: LayoutBottomIcon,
          IconActive: LayoutBottomHighIcon,
          anchor: "moreinfos2-card",
          labelI18n: "Website.MoreInfos2.title",
        },
        {
          Icon: QuestionMarkCircleIcon,
          IconActive: QuestionMarkCircleHighIcon,
          anchor: "faq-card",
          labelI18n: "Website.FAQ.title",
        },
        {
          Icon: FileTextIcon,
          IconActive: FileTextHighIcon,
          anchor: "about-card",
          labelI18n: "Website.About.title",
        },
        {
          Icon: KeyIcon,
          IconActive: KeyHighIcon,
          anchor: "services-card",
          labelI18n: "Website.Services.title",
        },
        {
          Icon: PhoneIcon,
          IconActive: PhoneHighIcon,
          anchor: "contact-card",
          labelI18n: "Website.Contact.title",
        },
      ]}
    />
  );
};
