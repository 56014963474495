import React from "react";
import { useTranslation } from "react-i18next";
import FileTextIcon from "../../../../assets/icons/file-text.svg?react";
import HomeIcon from "../../../../assets/icons/home.svg?react";
import { Card } from "../../../Common/Card/Card";

export const RentalWebsiteTermsCardSkeleton: React.FC<{}> = () => {
  const { t } = useTranslation();

  return (
    <Card
      Icon={FileTextIcon}
      label={t("Rental.Website.Terms.title")}
      button={{ Icon: HomeIcon, label: t("Global.edit"), skeleton: true }}
    >
      <div className="flex flex-col gap-2 animate-pulse">
        <p className="w-full h-3 rounded bg-slate-200"></p>
        <p className="w-full h-3 rounded bg-slate-200"></p>
        <p className="w-full h-3 rounded bg-slate-200"></p>
        <p className="w-full h-3 rounded bg-slate-200"></p>
        <p className="w-32 h-3 rounded bg-slate-200"></p>
      </div>
    </Card>
  );
};
