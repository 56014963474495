import React, {useEffect, useState} from "react";

import {RightModal} from "../../Common/RightModal/RightModal";
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import {TextInput} from "../../Common/TextInput/TextInput";
import {Button} from "../../Common/Button/Button";
import CheckWhiteIcon from "../../../assets/icons/check-white.svg?react";
import {ErrorMessage} from "../../Common/ErrorMessage/ErrorMessage";
import {ValueType} from "../../../types/commonTypes";
import {
  OperationalManagementRoleListItemResponse,
  OperationalManagementRolePermissionNameResponse,
  OperationalManagementRolePermissionResponse,
  OperationalManagementRoleRightResponse,
} from "../../../types/GETTypes";
import {post} from "../../../helpers/APIHelper";
import paths from "../../../constants/paths";
import {Separator} from "../../Common/Separator/Separator";
import {ManagementAccessControl} from "../ManagementAccessControl/ManagementAccessControl";

type EditRoleFormSchema = {
  name: string;
  access: {
    permissions: OperationalManagementRolePermissionNameResponse[];
    rights: OperationalManagementRoleRightResponse[];
  };
};

export const EditRoleModal: React.FC<{
  permissions: OperationalManagementRolePermissionResponse[];
  role: OperationalManagementRoleListItemResponse | undefined | null;
  isVisible: boolean;
  onClose: () => void;
  onSuccess: () => void;
  onRemove: (role: OperationalManagementRoleListItemResponse) => void;
}> = ({ permissions, role, isVisible, onClose, onSuccess, onRemove }) => {
  const { t } = useTranslation();

  const form = useForm<EditRoleFormSchema>({
    defaultValues: {
      name: "",
      access: {
        permissions: [],
        rights: [],
      },
    },
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (isVisible && role) {
      form.setValue("name", role.name);
      form.setValue(
        "access.permissions",
        role.permissions_selected?.map((p) => p.name)
      );
      form.setValue("access.rights", role.all_rights ?? []);
    }
  }, [isVisible, role]);

  const handleClose = () => {
    if (loading) return;

    onClose();
    form.setValue("name", "");
    form.setValue("access.permissions", []);
    form.setValue("access.rights", []);
  };

  const getSelectedPermissionsId = (values: EditRoleFormSchema) => {
    const result: ValueType[] = [];

    values.access.permissions?.map((selectedPerm) => {
      const permIndex = permissions.findIndex((p) => p.name === selectedPerm);
      if (permIndex !== -1) result.push(permissions[permIndex].id);
    });

    return result;
  };

  const save = async (values: EditRoleFormSchema) => {
    setLoading(true);

    const res = await post(
      `${import.meta.env.VITE_API_URL}${
        paths.API.OPERATIONNAL_MANAGEMENT.UPDATE_ROLE
      }/${role?.id}`,
      {
        name: values.name,
        permissions: getSelectedPermissionsId(values),
        all_rights: values.access.rights,
      }
    );

    if (res.data?.success) {
      onSuccess();
    } else {
      setError(res.response.data.message);
    }

    setLoading(false);
  };

  return (
    <RightModal
      title={t("Management.EditRole.title", { roleName: role?.name })}
      isVisible={isVisible}
      onClose={handleClose}
    >
      <form
        onSubmit={form.handleSubmit(save)}
        className="flex flex-col w-full flex-1"
      >
        <div className="w-full flex-1 flex flex-col gap-4 overflow-y-auto">
          <p className="text-low-contrast font-semibold">
            {t("Management.EditRole.informations")}
          </p>

          <div>
            <Controller
              control={form.control}
              name="name"
              rules={{
                required: {
                  value: true,
                  message: t("Management.EditRole.nameError"),
                },
              }}
              render={({ field: { value, onChange } }) => (
                <div className="flex flex-col gap-1">
                  <TextInput
                    label={t("Management.EditRole.name")}
                    placeholder={t("Management.EditRole.namePlaceholder")}
                    size="large"
                    required={true}
                    value={value}
                    error={form.formState.errors.name?.message}
                    disabled={loading || role?.type !== "custom"}
                    onChangeText={onChange}
                  />

                  {role?.type !== "custom" && (
                    <p className="text-low-contrast font-light text-xs">
                      {t("Management.EditRole.nameHint")}
                    </p>
                  )}
                </div>
              )}
            />
          </div>

          <Separator />

          <div>
            <ManagementAccessControl
              value={form.watch("access")}
              onChange={(value) => form.setValue("access", value)}
            />
          </div>
        </div>

        {/* Buttons */}
        <div className="pt-4 border-t-1 border-element-border flex flex-col gap-3 mt-4 pb-1">
          <ErrorMessage errorType="API">{error}</ErrorMessage>

          <div className="flex flex-row gap-4">
            <Button type="secondary" disabled={loading} onClick={handleClose}>
              {t("Global.cancel")}
            </Button>

            {role?.type === "custom" && (
              <Button
                type="alert"
                disabled={loading}
                onClick={() => onRemove(role!)}
              >
                {t("Global.remove")}
              </Button>
            )}

            <Button RightIcon={CheckWhiteIcon} loading={loading} disabled={role?.type !== "custom" && role?.name=== "Manager"} >
              {t("Global.save")}
            </Button>
          </div>
        </div>
      </form>
    </RightModal>
  );
};
