import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import DoubleChatIcon from "../../../assets/icons/double-chat-bubble.svg?react";
import { Card } from "../../Common/Card/Card";
import { WebsiteResponse } from "../../../types/GETTypes";
import { useTranslation } from "react-i18next";
import { useToggleCard } from "./useToggleCard";
import paths from "../../../constants/paths";
import CheckIcon from "../../../assets/icons/check-white.svg?react";
import EditIcon from "../../../assets/icons/edit.svg?react";
import TrashIcon from "../../../assets/icons/trash.svg?react";
import ArrowLeftIcon from "../../../assets/icons/arrow-left.svg?react";
import PlusIcon from "../../../assets/icons/plus.svg?react";
import MinusIcon from "../../../assets/icons/minus.svg?react";
import PhotoIcon from "../../../assets/icons/photo.svg?react";
import { ValueType } from "../../../types/commonTypes";
import { TextInput } from "../../Common/TextInput/TextInput";
import { Button } from "../../Common/Button/Button";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import { TextAreaInput } from "../../Common/TextAreaInput/TextAreaInput";
import { post } from "../../../helpers/APIHelper";

export const WebsiteTestimonalsCard: React.FC<{
  website: WebsiteResponse | undefined;
  onUpdateWebsite: (nextWebsite: WebsiteResponse) => void;
}> = ({ website, onUpdateWebsite }) => {
  const { t } = useTranslation();

  const { isEnabled, isEnabledLoading, toggle } = useToggleCard({
    website,
    initialValue: website?.content.guest_reviews.enabled,
    baseUrl: `${
      import.meta.env.VITE_API_URL
    }${paths.API.WEBSITE.UPDATE.REVIEW_TOGGLE(website?.id!)}`,
  });

  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const [updateError, setUpdateError] = useState<string | undefined>();

  const [editMode, setEditMode] = useState<boolean>(false);

  const editModeRef = useRef<{
    handleClear: () => void;
    handleCheckData: () => {
      title: string;
      subTitle: string;
      testimonials: TestimonialType[];
    } | null;
  } | null>(null);

  const handleCancel = () => {
    setEditMode(false);
    editModeRef.current?.handleClear();
  };

  const handleUpdate = async () => {
    const values = editModeRef.current?.handleCheckData();

    if (!values) return;

    setUpdateLoading(true);
    setUpdateError(undefined);

    const formData = new FormData();
    formData.append("guest_review_section_title", values.title);
    formData.append("guest_review_section_subtitle", values.subTitle);

    values.testimonials?.forEach((f, index) => {
      if (f.isDeleted) formData.append(`del_ids[${index}]`, f.id.toString());
    });

    formData.append(
      "reviews",
      JSON.stringify(
        values.testimonials
          .filter((t) => t.isDeleted === false && t.isNew)
          .map((t) => ({ name: t.name, review: t.review }))
      )
    );

    values.testimonials
      .filter((t) => t.isDeleted === false && t.isNew)
      .forEach((t, index) => {
        if (t.photoBlob !== undefined) {
          formData.append(`review_photos[${index}]`, t.photoBlob);
        }
      });

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.WEBSITE.UPDATE.REVIEW(
        website?.id!
      )}`,
      formData
    );

    if (!res?.data?.success) {
      setUpdateError(res?.response?.data?.message);
    } else {
      onUpdateWebsite(res.data?.result);
      setEditMode(false);
    }

    setUpdateLoading(false);
  };

  useEffect(() => {
    setUpdateError(undefined);
  }, [editMode]);

  return (
    <Card
      Icon={DoubleChatIcon}
      label={t("Website.Testimonials.title")}
      anchor="testimonials-card"
      switchButton={{
        value: isEnabled,
        label: "",
        loading: isEnabledLoading,
        onClick: toggle,
      }}
      button={{
        Icon: editMode ? CheckIcon : EditIcon,
        type: editMode ? "primary" : "secondary",
        label: editMode ? t("Global.record") : t("Global.edit"),
        onClick: editMode ? () => handleUpdate() : () => setEditMode(!editMode),
        disabled: !website || updateLoading,
      }}
      secondaryButton={
        editMode
          ? {
              label: t("Global.cancel"),
              LeftIcon: ArrowLeftIcon,
              onClick: handleCancel,
              disabled: updateLoading,
            }
          : undefined
      }
    >
      <ErrorMessage>{updateError}</ErrorMessage>

      {editMode ? (
        <EditMode ref={editModeRef} website={website} loading={updateLoading} />
      ) : (
        <ViewMode website={website} />
      )}
    </Card>
  );
};

const ViewMode: React.FC<{ website: WebsiteResponse | undefined }> = ({
  website,
}) => {
  if (!website) return <ViewModeSkeleton />;

  return (
    <div className="flex flex-col gap-4">
      <p className="text-center text-base font-semibold">
        {website?.content.guest_reviews.title}
      </p>

      <p className="text-center font-light text-low-contrast text-xs">
        {website?.content.guest_reviews.subtitle}
      </p>

      <div className="grid grid-cols-2 flex-wrap gap-3">
        {website?.content.guest_reviews.cards?.map((gr) => (
          <div className="border-1 border-element-border rounded-6px bg-element-background p-2 flex flex-col gap-2">
            <p>{gr.review}</p>

            <div className="flex flex-row gap-1 items-center">
              {gr.photo && (
                <img
                  className="rounded-full border-1 border-element-border size-8 shrink-0"
                  src={gr.photo}
                />
              )}
              <p className="font-semibold text-high-contrast">{gr.name}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const ViewModeSkeleton: React.FC<{}> = ({}) => {
  return (
    <div className="flex flex-col items-center gap-4 animate-pulse">
      <p className="text-center text-base font-semibold h-2 bg-slate-300 w-72 rounded"></p>

      <p className="text-center font-light text-low-contrast text-xs h-2 bg-slate-200 w-52 rounded mt-2"></p>

      <div className="grid grid-cols-2 flex-wrap gap-3 w-full">
        {Array.from({ length: 4 }).map((gr) => (
          <div className="border-1 border-element-border rounded-6px bg-element-background p-2 flex flex-col gap-2 w-full flex-1">
            <p className="h-2 bg-slate-200 w-52 rounded"></p>

            <div className="flex flex-row gap-1 items-center">
              <div className="rounded-full border-1 border-element-border size-8 shrink-0" />
              <p className="font-semibold text-high-contrast h-2 bg-slate-300 w-72 rounded"></p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

interface EditModeProps {
  website: WebsiteResponse | undefined;
  loading: boolean;
}

type TestimonialType = {
  id: ValueType;
  name: string;
  review: string;
  photoBlob: Blob | undefined;
  photoUrl: string | undefined;
  isDeleted: boolean;
  isNew?: boolean;
  error: string | undefined;
};

const EditMode = forwardRef<
  {
    handleClear: () => void;
    handleCheckData: () => {
      title: string;
      subTitle: string;
      testimonials: TestimonialType[];
    } | null;
  },
  EditModeProps
>(({ website, loading }, ref) => {
  const { t } = useTranslation();

  const [title, setTitle] = useState<string>("");
  const [titleError, setTitleError] = useState<string | undefined>();

  const [subTitle, setSubTitle] = useState<string>("");

  const [testimonials, setTestimonials] = useState<TestimonialType[]>([]);

  const handleClear = () => {
    setTitle("");
    setTitleError(undefined);

    setSubTitle("");
  };

  const handleCheckData = () => {
    setTitleError(undefined);

    let isError = false;

    if (!title) {
      setTitleError(t("Global.required"));
      isError = true;
    }

    testimonials
      .filter((t) => t.isDeleted === false)
      .forEach((t) => {
        if (t.error) {
          isError = true;
        }
      });

    if (isError) return null;

    return {
      title,
      subTitle,
      testimonials,
    };
  };

  useImperativeHandle(ref, () => ({
    handleClear,
    handleCheckData,
  }));

  useEffect(() => {
    if (website) {
      setTitle(website?.content.guest_reviews.title);
      setTitleError(undefined);

      setSubTitle(website?.content.guest_reviews.subtitle);

      setTestimonials(
        website?.content.guest_reviews.cards.map((g) => ({
          id: g.id,
          name: g.name,
          review: g.review,
          photoBlob: undefined,
          photoUrl: g.photo,
          isDeleted: false,
          error: undefined,
        }))
      );
    }
  }, [website]);

  // Title and sub title
  const handleChangeTitle = (text: string) => {
    setTitle(text);

    setTitleError(undefined);
  };

  const handleChangeSubTitle = (text: string) => {
    setSubTitle(text);
  };

  const handleChangeTestimonial = (nextTestimonial: TestimonialType) => {
    setTestimonials((prev) =>
      prev.map((t) => (t.id === nextTestimonial.id ? nextTestimonial : t))
    );
  };

  const handleAddTestimonial = () => {
    setTestimonials((prev) => [
      ...prev,
      {
        id: new Date().toString(),
        name: "",
        review: "",
        photoBlob: undefined,
        photoUrl: undefined,
        error: t("Website.Testimonials.required"),
        isDeleted: false,
        isNew: true,
      },
    ]);
  };

  return (
    <div className="flex flex-col gap-3">
      <TextInput
        label={t("Website.Infos.titleLabel")}
        value={title}
        onChangeText={handleChangeTitle}
        error={titleError}
        required={true}
        disabled={loading}
      />

      <TextInput
        label={t("Website.Infos.subTitleLabel")}
        value={subTitle}
        onChangeText={handleChangeSubTitle}
        disabled={loading}
      />

      {testimonials
        .filter((t) => t.isDeleted === false)
        .map((t, index) => (
          <TestimonialItem
            key={t.id?.toString() ?? index}
            testimonial={t}
            loading={loading}
            onChange={(value) => handleChangeTestimonial(value)}
          />
        ))}

      <Button
        type="secondary"
        RightIcon={PlusIcon}
        onClick={handleAddTestimonial}
      >
        {t("Website.Testimonials.addTestimonial")}
      </Button>
    </div>
  );
});

const TestimonialItem: React.FC<{
  testimonial: TestimonialType;
  loading: boolean;
  onChange: (value: TestimonialType) => void;
}> = ({ testimonial, loading, onChange }) => {
  const { t } = useTranslation();

  const [name, setName] = useState<string>(testimonial.name);

  const [review, setReview] = useState<string>(testimonial.review);

  const [error, setError] = useState<string | undefined>(testimonial.error);

  // Logo
  const [photo, setPhoto] = useState<Blob>();
  const [photoURL, setPhotoURL] = useState<string>(testimonial.photoUrl ?? "");

  const photoInputRef = useRef<HTMLInputElement>(null);
  const handleAddPhoto = () => {
    photoInputRef.current?.click();
  };

  const handleAddPhotoFromInput = (event: any) => {
    if (event.target.files?.length === 0) return;

    setPhoto(event.target.files[0]);

    onChange({ ...testimonial, photoBlob: event.target.files[0] });
  };

  const handleRemovePhoto = () => {
    setPhoto(undefined);
    setPhotoURL(undefined);

    onChange({ ...testimonial, photoBlob: undefined, photoUrl: undefined });
  };

  const handleChangeName = (value: string) => {
    let nextError = undefined;

    setError(undefined);

    setName(value);

    if (value === "") {
      nextError = t("Website.Testimonials.required");
    }

    setError(nextError);
    onChange({ ...testimonial, name: value, error: nextError });
  };

  const handleChangeReview = (value: string) => {
    let nextError = undefined;

    setError(undefined);

    setReview(value);

    if (value === "") {
      nextError = t("Website.Testimonials.required");
    }

    setError(nextError);
    onChange({ ...testimonial, review: value, error: nextError });
  };

  return (
    <div className="flex flex-col gap-2 p-4 border-1 border-element-border rounded-6px bg-element-background">
      <div className="flex items-end gap-3 relative">
        <div className="flex flex-1 items-center gap-3">
          <div className="relative w-40 flex flex-col gap-4 mt-2">
            <input
              type="file"
              multiple={true}
              style={{ display: "none" }}
              ref={photoInputRef}
              accept="image/jpeg, image/png"
              onChange={handleAddPhotoFromInput}
            />

            {photo || photoURL ? (
              <div className="w-full h-full group">
                {testimonial.isNew && (
                  <div
                    className="absolute items-center justify-center hidden w-6 h-6 rounded-sm cursor-pointer border-element-border border-1 group-hover:flex bg-white"
                    style={{ top: -6, right: -6 }}
                    onClick={handleRemovePhoto}
                  >
                    <MinusIcon width={20} height={20} />
                  </div>
                )}

                <div className="flex flex-col items-center justify-center w-full h-40 gap-2 overflow-hidden border-dashed bg-element-background border-1 border-element-border rounded-6px hover:bg-element-background-active">
                  <img
                    src={photo ? URL.createObjectURL(photo) : photoURL}
                    className="object-cover w-full h-full"
                  />
                </div>
              </div>
            ) : (
              <div
                className="flex flex-col items-center justify-center w-full h-full gap-2 p-4 border-dashed cursor-pointer bg-element-background border-1 border-element-border rounded-6px hover:bg-element-background-active"
                onClick={handleAddPhoto}
              >
                <PhotoIcon className="w-10 h-10" />
                <p className="font-semibold text-high-contrast text-center">
                  {t("Website.Testimonials.addPhoto")}
                </p>
              </div>
            )}
          </div>

          <div className="w-52">
            <TextInput
              label={t("Website.Testimonials.name")}
              value={name}
              onChangeText={handleChangeName}
              required={true}
              disabled={loading}
              size="large"
            />
          </div>
        </div>

        <div className="w-12 absolute top-0 right-0">
          <Button
            type="secondary"
            size="small"
            onClick={() =>
              onChange({
                id: testimonial.id,
                name,
                review,
                photoBlob: photo,
                photoUrl: photoURL,
                isDeleted: true,
                error,
              })
            }
          >
            <TrashIcon />
          </Button>
        </div>
      </div>

      <TextAreaInput
        label={t("Website.Testimonials.review")}
        value={review}
        onTextChange={handleChangeReview}
        required={true}
        disabled={loading || !testimonial.isNew}
      />

      <ErrorMessage errorType="FORM">{error}</ErrorMessage>
    </div>
  );
};
