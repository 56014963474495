import React from "react";
import useGuestWebsiteStore from "../../../../stores/guestWebsiteStore/useGuestWebsiteStore";
import UsersIcon from "../../../../assets/icons/users.svg?react";
import BedIcon from "../../../../assets/icons/bed.svg?react";
import {GuestWebsiteRental} from "../../../../types/GETTypes";
import {useTranslation} from "react-i18next";

const RentalCardComponent = ({
  id,
  cover,
  name,
  price_night_default,
  address,
  postal_code,
  city,
  guests_max_capacity,
  bedrooms_count,
}: GuestWebsiteRental) => {
  const {t} = useTranslation();
  const {setTabGuestWebsite, dataGuestWebsite} = useGuestWebsiteStore();
  const {styles} = dataGuestWebsite || {};
  const {color_main, color_text} = styles || {};

  return (
    <div className="shadow-xl rounded-lg sh-rental-div-parent bg-white flex-1 flex flex-col h-full">
      {/* Image Section */}
      <div className="p-2 sh-rental-div-img relative">
        <img
          src={"https://placehold.co/300x200"}
          alt={name}
          className="w-full h-48 object-cover rounded sh-rental-img"
        />
        <div
          className="absolute top-2 right-2 bg-white text-gray-800 text-sm font-semibold px-2 py-1 rounded sh-rental-div-img-text"
          style={{color: color_text}}
        >
          À partir de <br />
          <span className="text-lg font-bold sh-rental-div-img-text-price">
            {price_night_default} €
          </span>
        </div>
      </div>

      {/* Content Section */}
      <div className="p-4 sh-rental-div-content flex flex-col flex-grow">
        <h2
          className="text-lg font-bold sh-rental-content-title"
          style={{color: color_text}}
        >
          {name}
        </h2>
        <p className="text-sm text-gray-600 sh-rental-content-address">
          {address}, {postal_code} {city}
        </p>

        {/* Info Section */}
        <div className="flex items-center justify-between mt-4 text-sm text-gray-600 sh-rental-info-section">
          <div className="flex items-center space-x-1 sh-rental-info-section-guests">
            <span className="sh-rental-info-section-guests-icon">
              <UsersIcon className="h-5 w-5" />
            </span>
            <span className="sh-rental-info-section-guests-text">
              {guests_max_capacity} {t('GuestWebsite.Rentals.guests')}
            </span>
          </div>
          <div className="flex items-center space-x-1 sh-rental-info-section-rooms">
            <span className="sh-rental-info-section-rooms-icon">
              <BedIcon className="h-5 w-5" />
            </span>
            <span className="sh-rental-info-section-rooms-text">
              {bedrooms_count} {t('GuestWebsite.Rentals.rooms')}
            </span>
          </div>
        </div>

        {/* Button */}
        <div className="w-full flex flex-col mt-auto">
          <button
            className="mt-4 w-full text-white font-semibold py-2 rounded sh-rental-button hover:brightness-[0.8]"
            onClick={() => setTabGuestWebsite("rental", String(id))}
            style={{backgroundColor: color_main}}
          >
            {t("GuestWebsite.Rentals.bookNow")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RentalCardComponent;
