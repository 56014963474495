import React, { useEffect, useState } from "react";
import CheckIcon from "../../../assets/icons/check.svg?react";
import CopyIcon from "../../../assets/icons/copy.svg?react";
import EyeOffIcon from "../../../assets/icons/eye-off.svg?react";
import EyeIcon from "../../../assets/icons/eye.svg?react";
import InformationIcon from "../../../assets/icons/information-fill.svg?react";
import { cn } from "../../../helpers/classHelper";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import { Tooltip } from "../Tooltip/Tooltip";
import {
  TextInputOnBlur,
  TextInputOnChange,
  TextInputProps,
} from "./TextInput.type";

export const TextInput: React.FC<TextInputProps> = ({
  ref,
  register,
  required = false,
  label,
  tooltip,
  value,
  placeholder = "",
  Icon,
  RightIcon,
  type = "text",
  size = "normal",
  disabled = false,
  error,
  infoText,
  infoTooltip,
  onChangeText,
  autoComplete,
  onFocus = () => {},
  onClick,
  onSelect = () => {},
  onBlur,
  onPointerDown,
  onMouseDown,
  classNames = undefined,
  leftButton,
  autoFocus,
  labelStyle
}) => {
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [isPasswordShown, setIsPasswordShown] = useState<boolean>(false);
  const [isCopied, setIsCopied] = useState(false);

  const [currentText, setCurrentText] = useState<string>("");

  useEffect(() => {
    setCurrentText(value ?? "");
  }, [value]);

  const getBorder = () => {
    return error
      ? "border-element-border-error"
      : `
      border-element-border
      hover:border-element-border-hover 
      active:border-element-border-active`;
  };

  const getOutline = () => {
    if (isFocus && error) {
      return "outline outline-2 outline-error/50";
    } else if (isFocus && !error) {
      return "outline outline-2 outline-focus";
    } else {
      return "";
    }
  };

  const bgColor =
    disabled || type === "clipboard"
      ? "bg-element-background-disabled"
      : "bg-white";
  const cursor =
    disabled || type === "clipboard" ? "cursor-not-allowed" : "cursor-text";
  const textColor =
    disabled || type === "clipboard"
      ? "text-low-contrast "
      : "text-high-contrast ";
  const textSize = size === "large" ? "text-sm" : "text-sm";

  const getInputType = () => {
    return type === "password" && !isPasswordShown ? "password" : "text";
  };

  const handleClickCopy = () => {
    navigator.clipboard.writeText(currentText);
    setIsCopied(true);
  };

  const handleBlur: TextInputOnBlur = (e) => {
    setIsFocus(false);
    register?.onBlur(e);
  };

  const handleChange: TextInputOnChange = (e) => {
    if (typeof onChangeText === "function") {
      onChangeText(e.currentTarget.value);
    }
  };

  return (
    <div className="flex flex-col flex-1">
      <div
        className={cn(
          `flex items-start space-x-1 `,
          {
            "items-center space-x-1.5": tooltip,
          },
          classNames?.divLabel
        )}
      >
        <div
          className={cn(`flex flex-1 items-start space-x-1 `, {
            "items-center space-x-1.5": tooltip,
          })}
        >
          {label ? (
            <label
              className={cn(
                "mb-1 text-sm font-semibold text-left w-max",
                classNames?.label
              )}
              htmlFor={register?.name ? `input-${register.name}` : undefined}
              style={labelStyle}
            >
              {label}
            </label>
          ) : null}
          {tooltip ? <div className="mb-1">{tooltip}</div> : null}
          {label && required ? (
            <span
              className={cn(
                "relative text-lg -top-1 text-active",
                classNames?.labelRequired
              )}
            >
              *
            </span>
          ) : null}
          {infoTooltip ? (
            <Tooltip Icon={InformationIcon} value={infoTooltip} />
          ) : null}
        </div>
        {leftButton && leftButton}
      </div>

      <div
        className={cn(
          `
        flex flex-row items-center
        overflow-hidden
        ${getBorder()}
        ${bgColor}
        ${cursor}
        ${getOutline()}
        ${type === "phone" ? "pl-1 pr-2" : "px-2"}
        ${size === "large" ? "h-9" : "h-8"}
        border-1 rounded-6px`,
          classNames?.divInput
        )}
      >
        {Icon ? (
          <label
            htmlFor={register?.name ? `input-${register.name}` : undefined}
          >
            <Icon className="mr-2 text-icon-default" />
          </label>
        ) : null}

        <input
          {...register}
          value={register ? undefined : value}
          id={register?.name ? `input-${register.name}` : undefined}
          className={cn(
            `flex-1
            w-12
            ${textColor}
          placeholder:text-low-contrast placeholder:font-light
            focus:outline-none
            ${bgColor}
            ${cursor}
            ${textSize}  
            `,
            classNames?.input
          )}
          placeholder={placeholder?.toString()}
          disabled={disabled}
          onChange={register?.onChange ?? handleChange}
          onFocus={() => setIsFocus(true)}
          onBlur={onBlur ? onBlur : handleBlur}
          onClick={onClick}
          onPointerDown={onPointerDown}
          onMouseDown={onMouseDown}
          type={getInputType()}
          autoComplete={
            typeof autoComplete != "undefined" ? autoComplete : "on"
          }
          onFocusCapture={onFocus}
          onSelect={onSelect}
          {...(ref ? ref : undefined)}
          autoFocus={autoFocus}
        />

        {RightIcon && <RightIcon />}

        {type === "password" && !isPasswordShown && (
          <EyeIcon
            onClick={() => setIsPasswordShown(true)}
            className="ml-2 text-icon-default hover:cursor-pointer"
          />
        )}
        {type === "password" && isPasswordShown && (
          <EyeOffIcon
            onClick={() => setIsPasswordShown(false)}
            className="ml-2 text-icon-default hover:cursor-pointer"
          />
        )}
        {type === "clipboard" && !isCopied && (
          <CopyIcon
            onClick={handleClickCopy}
            className="ml-2 text-icon-default hover:cursor-pointer"
          />
        )}
        {type === "clipboard" && isCopied && (
          <CheckIcon
            onClick={handleClickCopy}
            className="w-5 h-5 ml-2 text-icon-default hover:cursor-pointer"
          />
        )}
      </div>

      {!error && infoText?.trim() && (
        <p
          className={cn(
            "mt-2 text-sm font-light text-left text-low-contrast",
            classNames?.error
          )}
        >
          {infoText}
        </p>
      )}

      <ErrorMessage errorType="FORM">{error}</ErrorMessage>
    </div>
  );
};

