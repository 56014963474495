import { t } from "i18next";
import React, { useState } from "react";
import {
  DisableMultipleRentalItemResponse,
  useDisableMultipleRental,
} from "../../hooks/api/rental";
import { UseModal } from "../../hooks/useModal";
import { ValueType } from "../../types/commonTypes";
import { Button } from "../Common/Button/Button";
import { CenterModal } from "../Common/CenterModal/CenterModal";

type RentalListDeactivateModalProps = {
  modal: UseModal<ValueType | ValueType[]>;
  selectedRentals: ValueType[];
  onDeactivate: (data: DisableMultipleRentalItemResponse[]) => void;
  onError: (message: string | null) => void;
};

export const RentalListDeactivateModal: React.FC<
  RentalListDeactivateModalProps
> = ({ modal, selectedRentals, onDeactivate, onError }) => {
  const [validationLoading, setValidationLoading] = useState<boolean>(false);

  const disableMultipleRentals = async (selectedRentals: ValueType[]) => {
    await useDisableMultipleRental({
      data: {
        ids: selectedRentals,
      },
      onSuccess: (data) => {
        if (data) {
          onDeactivate(data);
          modal.close();
        }
      },
      onError: (message: string | null) => onError(message),
      onStart: () => {
        onError(null);
        setValidationLoading(true);
      },
      onEnd: () => setValidationLoading(false),
    });
  };

  return (
    <CenterModal
      title={t("RentalList.DeactivateModal.title", {
        count: Array.isArray(modal.data) ? modal.data.length : 1,
      })}
      isVisible={modal.isVisible}
      onClose={validationLoading ? () => {} : modal.close}
    >
      <div className="flex flex-col gap-y-6">
        <div className="flex flex-col gap-y-4">
          <p className="text-base leading-relaxed tracking-wide text-low-contrast">
            {t("RentalList.DeactivateModal.description1", {
              count: Array.isArray(modal.data) ? modal.data.length : 1,
            })}
          </p>
          <p className="text-base leading-relaxed tracking-wide text-low-contrast">
            {t("RentalList.DeactivateModal.description2", {
              count: Array.isArray(modal.data) ? modal.data.length : 1,
            })}
          </p>
        </div>

        <div className="flex justify-between space-x-2">
          <Button
            type="secondary"
            disabled={validationLoading}
            onClick={modal.close}
          >
            {t("Global.cancel")}
          </Button>
          <Button
            type="destructive"
            disabled={validationLoading}
            loading={validationLoading}
            onClick={() => disableMultipleRentals(selectedRentals)}
          >
            {t("Global.deactivateWithConfirmation")}
          </Button>
        </div>
      </div>
    </CenterModal>
  );
};
