import React from "react";
import {Tooltip} from "../../Common/Tooltip/Tooltip";
import DetailsLineComponent, {DetailsLineItem} from "../DetailsLineComponent";
import {PlatformEnum} from "../../../enums/GETenums";
import {
  AnalyticsDetailedOccupancyStatistics,
  AnalyticsDetailsSimpleResponse,
  DistributionKeyEnum,
  StatsNameKeysEnum,
} from "../../../types/GETTypes";
import {useTranslation} from "react-i18next";

interface OccupancyTableProps {
  data: AnalyticsDetailedOccupancyStatistics;
  openModalDetails: (
    statName: StatsNameKeysEnum,
    platform: DistributionKeyEnum
  ) => void;
  currency: string | undefined;
}
const OccupancyTableComponent = ({
  openModalDetails,
  data,
  currency,
}: OccupancyTableProps) => {
  const {t} = useTranslation();

  const transformToBreakdown = (
    nights_booked: AnalyticsDetailsSimpleResponse,
    nights_available: AnalyticsDetailsSimpleResponse,
    cancelled_nights: AnalyticsDetailsSimpleResponse,
    cancellation_rates: AnalyticsDetailsSimpleResponse,
    average_nights_per_reservation: AnalyticsDetailsSimpleResponse,
    average_price_per_reservation: AnalyticsDetailsSimpleResponse
  ): DetailsLineItem[] => {
    return [
      {
        label: t("Analytics.rentedNights"),
        subLabel: `${nights_booked.total?.toLocaleString('fr-FR')} ${currency}`,
        info: t("Analytics.Tooltip.Details.Occupancy.rentedNights"),
        alert: false,
        distribution: nights_booked.distribution,
        distributionSymbol:currency ?? "€",
        statName: StatsNameKeysEnum.REVENUE_BREAKDOWN,
      },
      {
        label: t("Analytics.notRentedNights"),
        subLabel: `${nights_available.total?.toLocaleString('fr-FR')} ${currency}`,
        info: t("Analytics.Tooltip.Details.Occupancy.notRentedNights"),
        alert: false,
        distribution: nights_available.distribution,
        distributionSymbol:currency ?? "€",
        statName: StatsNameKeysEnum.REVENUE_BREAKDOWN,
      },
      {
        label: t("Analytics.canceledNights"),
        subLabel: `${cancelled_nights.total?.toLocaleString('fr-FR')} ${currency}`,
        info: t("Analytics.Tooltip.Details.Occupancy.canceledNights"),
        alert: false,
        distribution: cancelled_nights.distribution,
        distributionSymbol:currency ?? "€",
        statName: StatsNameKeysEnum.REVENUE_BREAKDOWN,
      },
      {
        label: t("Analytics.cancelRate"),
        subLabel: `${cancellation_rates.total?.toLocaleString('fr-FR')} %`,
        info: t("Analytics.Tooltip.Details.Occupancy.cancelRate"),
        alert: false,
        distribution: cancellation_rates.distribution,
        distributionSymbol:"%",
        statName: StatsNameKeysEnum.REVENUE_BREAKDOWN,
      },
      {
        label: t("Analytics.averageNightsPerResa"),
        subLabel: `${average_nights_per_reservation.total?.toLocaleString('fr-FR')} ${currency}`,
        info: t("Analytics.Tooltip.Details.Occupancy.averageNightsPerResa"),
        alert: false,
        distribution: average_nights_per_reservation.distribution,
        distributionSymbol:currency ?? "€",
        statName: StatsNameKeysEnum.REVENUE_BREAKDOWN,
      },
      {
        label: t("Analytics.averagePricePerResa"),
        subLabel: `${average_price_per_reservation.total?.toLocaleString('fr-FR')} ${currency}`,
        info: t("Analytics.Tooltip.Details.Occupancy.averagePricePerResa"),
        alert: false,
        distribution: average_price_per_reservation.distribution,
        distributionSymbol:currency ?? "€",
        statName: StatsNameKeysEnum.REVENUE_BREAKDOWN,
      },
    ];
  };

  const breakdown = transformToBreakdown(
    data?.nights_booked,
    data?.nights_available,
    data?.cancelled_nights,
    data?.cancellation_rates,
    data?.average_nights_per_reservation,
    data?.average_price_per_reservation
  );

  return (
    <div className="mt-4 mb-0 bg-element-border/25">
      <ul className="p-2 px-4 pb-0 text-sm space-y-2">
        {breakdown.map((item, index) => (
          <DetailsLineComponent
            item={item}
            openModalDetails={openModalDetails}
            border={index !== breakdown.length - 1}
            currency={currency}
          />
        ))}
      </ul>
    </div>
  );
};

export default OccupancyTableComponent;
