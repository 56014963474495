import React from "react";
import {
  TaskItemResponse,
  TaskListByDateResponse,
} from "../../../types/GETTypes";
import { TaskListWithTasks } from "./TaskListWithTasks";
import { TaskListNoItem } from "./TaskListNoItem";

export const TaskList: React.FC<{
  tasksListByDate: TaskListByDateResponse | undefined;
  openEditTaskModal: (task: TaskItemResponse) => void;
  onAcceptTask: (task: TaskItemResponse | undefined) => void;
  onDeclineTask: (task: TaskItemResponse | undefined) => void;
}> = ({ tasksListByDate, openEditTaskModal, onAcceptTask, onDeclineTask }) => {
  if (!tasksListByDate) return null;

  return (
    <div className="flex flex-col gap-3 mt-4">
      {Object.entries(tasksListByDate).map(
        ([date, taskListDay], index: number) => {
          if (taskListDay?.day_tasks?.length === 0) {
            //return <TaskListNoItem date={date} key={index} />;
            return null;
          }

          return (
            <TaskListWithTasks
              key={index}
              date={date}
              taskListDay={taskListDay}
              openEditTaskModal={openEditTaskModal}
              onAcceptTask={onAcceptTask}
              onDeclineTask={onDeclineTask}
            />
          );
        },
      )}
    </div>
  );
};
