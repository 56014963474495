import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CheckWHiteIcon from "../../../assets/icons/check-white.svg?react";
import TrashWhiteIcon from "../../../assets/icons/trash-white.svg?react";
import { ValueType } from "../../../types/commonTypes";
import { RentalBedRoomResponse } from "../../../types/GETTypes";
import { Button } from "../../Common/Button/Button";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import { InputSelect } from "../../Common/InputSelect/InputSelect";
import { NumberInput } from "../../Common/NumberInput/NumberInput";
import { RightModal } from "../../Common/RightModal/RightModal";
import { Separator } from "../../Common/Separator/Separator";
import { Switch } from "../../Common/Switch/Switch";
import { TextInput } from "../../Common/TextInput/TextInput";

export const UpsertRoomModal: React.FC<{
  isFirstRoom: boolean;
  room?: RentalBedRoomResponse;
  isVisible: boolean;
  onAdd?: (room: RentalBedRoomResponse) => void;
  onEdit?: (room: RentalBedRoomResponse) => void;
  onClose?: () => void;
  onRemove?: () => void;
  nbRooms?: number;
}> = ({
  isFirstRoom,
  room,
  isVisible,
  onAdd = () => {},
  onEdit = () => {},
  onClose = () => {},
  onRemove = () => {},
  nbRooms,
}) => {
  const { t } = useTranslation();

  const [errorBeds, setErrorBeds] = useState<string | null>(null);

  const form = useForm<RentalBedRoomResponse>({
    defaultValues: {
      name: "",
      adults_count: 2,
      children_count: 2,
      children_beds_count: 0,
      double_beds_count: 0,
      infants_beds_count: 0,
      king_beds_count: 0,
      mezannine_beds_count: 0,
      queen_beds_count: 0,
      single_beds_count: 0,
      sofa_beds_count: 0,
      wall_beds_count: 0,
      private_bathroom: 0,
      private_room: 1,
      room_type_id: 1,
    },
  });

  useEffect(() => {
    if (room) {
      form.setValue("name", room.name);
      form.setValue("adults_count", room.adults_count);
      form.setValue("children_count", room.children_count);
      form.setValue("children_beds_count", room.children_beds_count);
      form.setValue("double_beds_count", room.double_beds_count);
      form.setValue("infants_beds_count", room.infants_beds_count);
      form.setValue("king_beds_count", room.king_beds_count);
      form.setValue("mezannine_beds_count", room.mezannine_beds_count);
      form.setValue("queen_beds_count", room.queen_beds_count);
      form.setValue("single_beds_count", room.single_beds_count);
      form.setValue("sofa_beds_count", room.sofa_beds_count);
      form.setValue("wall_beds_count", room.wall_beds_count);
      form.setValue("private_bathroom", room.private_bathroom);
      form.setValue("private_room", room.private_room);
      form.setValue("room_type_id", room.room_type_id);
    } else {
      form.setValue(
        "name",
        t("AddRental.Rooms.namePlaceholder") + " " + nbRooms
      );
      form.setValue("adults_count", 2);
      form.setValue("children_count", 0);
      form.setValue("children_beds_count", 0);
      form.setValue("double_beds_count", 1);
      form.setValue("infants_beds_count", 0);
      form.setValue("king_beds_count", 0);
      form.setValue("mezannine_beds_count", 0);
      form.setValue("queen_beds_count", 0);
      form.setValue("single_beds_count", 0);
      form.setValue("sofa_beds_count", 0);
      form.setValue("wall_beds_count", 0);
      form.setValue("private_bathroom", 0);
      form.setValue("private_room", 1);
      form.setValue("room_type_id", 1);
    }
  }, [room, isVisible]);

  const getTitle = () => {
    if (room) return t("AddRental.Rooms.editTitle");
    else return t("AddRental.Rooms.addTitle");
  };

  const handleSave = (values: RentalBedRoomResponse) => {
    setErrorBeds(null);

    let isError = false;
    if (isFirstRoom && values.adults_count < 1) {
      form.setError("adults_count", {
        message: t("AddRental.Rooms.nbAdultsMinAdultError").toString(),
      });
      isError = true;
    } else if (values.adults_count + values.children_count === 0) {
      form.setError("adults_count", {
        message: t("AddRental.Rooms.nbAdultsMinError").toString(),
      });
      isError = true;
    }

    if (
      values.children_beds_count +
        values.double_beds_count +
        values.infants_beds_count +
        values.king_beds_count +
        values.mezannine_beds_count +
        values.queen_beds_count +
        values.single_beds_count +
        values.sofa_beds_count +
        values.wall_beds_count ===
      0
    ) {
      setErrorBeds(t("AddRental.Rooms.bedsMinError"));
      isError = true;
    }

    if (isError) return;

    if (room) onEdit(values);
    else onAdd(values);
  };

  return (
    <RightModal isVisible={isVisible} onClose={onClose} title={getTitle()}>
      <form onSubmit={form.handleSubmit(handleSave)} className="w-full">
        <div className="flex flex-col w-full h-full">
          {/* Fields */}
          <div className="flex flex-col flex-1 w-full gap-3 overflow-y-scroll">
            <p className="font-semibold text-low-contrast">
              {t("AddRental.Rooms.informations")}
            </p>

            <div className="flex gap-3">
              <Controller
                control={form.control}
                name="name"
                rules={{
                  required: {
                    value: true,
                    message: t("AddRental.Rooms.nameError"),
                  },
                }}
                render={({ field: { value, onChange } }) => (
                  <TextInput
                    label={t("AddRental.Rooms.name")}
                    placeholder={
                      t("AddRental.Rooms.namePlaceholder") + " " + nbRooms
                    }
                    value={value}
                    onChangeText={onChange}
                    error={form.formState.errors.name?.message}
                  />
                )}
              />
            </div>

            <div className="flex gap-3">
              <Controller
                control={form.control}
                name="room_type_id"
                render={({ field: { value, onChange } }) => {
                  const handleChange = (value: ValueType) => {
                    onChange(value);
                  };

                  return (
                    <div className="flex-1">
                      <InputSelect
                        label={t("AddRental.Rooms.roomType")}
                        items={[
                          { value: 1, label: "Chambre" },
                          { value: 2, label: "Salon" },
                        ]}
                        selectedValue={value!}
                        onSelect={handleChange}
                        error={form.formState.errors.room_type_id?.message}
                      />
                    </div>
                  );
                }}
              />

              <Controller
                control={form.control}
                name="private_bathroom"
                render={({ field: { value, onChange } }) => (
                  <div className="flex-1">
                    <InputSelect
                      label={t("AddRental.Rooms.privateBathroom")}
                      items={[
                        { value: 1, label: t("Global.yes") },
                        { value: 0, label: t("Global.no") },
                      ]}
                      selectedValue={Number(value)}
                      onSelect={onChange}
                      error={form.formState.errors.private_bathroom?.message}
                    />
                  </div>
                )}
              />
            </div>

            <Controller
              control={form.control}
              name="private_room"
              render={({ field: { value, onChange } }) => (
                <div
                  className="flex items-center justify-between cursor-pointer"
                  onClick={() => onChange(!value)}
                >
                  <div className="flex flex-col">
                    <p className="font-semibold text-high-contrast">
                      {t("AddRental.Rooms.privateRoom")}
                    </p>
                    <p className="font-light text-low-contrast">
                      {t("AddRental.Rooms.privateRoomDesc")}
                    </p>
                  </div>
                  <Switch value={!value} />
                </div>
              )}
            />

            <Separator accent="dark" />

            <p className="font-semibold text-low-contrast">
              {t("AddRental.Rooms.bedsInRoom")}
            </p>

            <ErrorMessage errorType="FORM">{errorBeds}</ErrorMessage>

            <div className="flex gap-3">
              <Controller
                control={form.control}
                name="double_beds_count"
                render={({ field: { value, onChange } }) => (
                  <div className="flex-1">
                    <NumberInput
                      label={t("AddRental.Rooms.nbDoubleBeds")}
                      value={value}
                      onChangeText={onChange}
                      min={0}
                      error={form.formState.errors.double_beds_count?.message}
                    />
                  </div>
                )}
              />

              <Controller
                control={form.control}
                name="single_beds_count"
                render={({ field: { value, onChange } }) => (
                  <div className="flex-1">
                    <NumberInput
                      label={t("AddRental.Rooms.nbSingleBeds")}
                      value={value}
                      onChangeText={onChange}
                      min={0}
                      error={form.formState.errors.single_beds_count?.message}
                    />
                  </div>
                )}
              />
            </div>

            <div className="flex gap-3">
              <Controller
                control={form.control}
                name="queen_beds_count"
                render={({ field: { value, onChange } }) => (
                  <div className="flex-1">
                    <NumberInput
                      label={t("AddRental.Rooms.nbQueenBeds")}
                      value={value}
                      onChangeText={onChange}
                      min={0}
                      error={form.formState.errors.queen_beds_count?.message}
                    />
                  </div>
                )}
              />

              <Controller
                control={form.control}
                name="king_beds_count"
                render={({ field: { value, onChange } }) => (
                  <div className="flex-1">
                    <NumberInput
                      label={t("AddRental.Rooms.nbKingBeds")}
                      value={value}
                      onChangeText={onChange}
                      min={0}
                      error={form.formState.errors.king_beds_count?.message}
                    />
                  </div>
                )}
              />
            </div>

            <div className="flex gap-3">
              <Controller
                control={form.control}
                name="infants_beds_count"
                render={({ field: { value, onChange } }) => (
                  <div className="flex-1">
                    <NumberInput
                      label={t("AddRental.Rooms.nbInfantsBeds")}
                      value={value}
                      onChangeText={onChange}
                      min={0}
                      error={form.formState.errors.infants_beds_count?.message}
                    />
                  </div>
                )}
              />

              <Controller
                control={form.control}
                name="children_beds_count"
                render={({ field: { value, onChange } }) => (
                  <div className="flex-1">
                    <NumberInput
                      label={t("AddRental.Rooms.nbChildrenBeds")}
                      value={value}
                      onChangeText={onChange}
                      min={0}
                      error={form.formState.errors.children_beds_count?.message}
                    />
                  </div>
                )}
              />
            </div>

            <div className="flex gap-3">
              <Controller
                control={form.control}
                name="mezannine_beds_count"
                render={({ field: { value, onChange } }) => (
                  <div className="flex-1">
                    <NumberInput
                      label={t("AddRental.Rooms.nbMezzanineBeds")}
                      value={value}
                      onChangeText={onChange}
                      min={0}
                      error={
                        form.formState.errors.mezannine_beds_count?.message
                      }
                    />
                  </div>
                )}
              />

              <Controller
                control={form.control}
                name="sofa_beds_count"
                render={({ field: { value, onChange } }) => (
                  <div className="flex-1">
                    <NumberInput
                      label={t("AddRental.Rooms.nbSofaBeds")}
                      value={value}
                      onChangeText={onChange}
                      min={0}
                      error={form.formState.errors.sofa_beds_count?.message}
                    />
                  </div>
                )}
              />
            </div>

            <div className="flex gap-3">
              <Controller
                control={form.control}
                name="wall_beds_count"
                render={({ field: { value, onChange } }) => (
                  <div className="flex-1">
                    <NumberInput
                      label={t("AddRental.Rooms.nbWallBeds")}
                      value={value}
                      onChangeText={onChange}
                      min={0}
                      error={form.formState.errors.wall_beds_count?.message}
                    />
                  </div>
                )}
              />

              <div className="flex-1" />
            </div>
          </div>

          {/* Buttons on add mode*/}
          {!room && (
            <div className="flex gap-3 pt-4 mt-4 border-t-1 border-element-border">
              <Button type="secondary" onClick={onClose}>
                {t("Global.cancel")}
              </Button>

              <Button RightIcon={CheckWHiteIcon}>{t("Global.add")}</Button>
            </div>
          )}

          {/* Buttons on edit mode*/}
          {room && (
            <div className="flex gap-3 pt-4 mt-4 border-t-1 border-element-border">
              <Button type="secondary" onClick={onClose}>
                {t("Global.cancel")}
              </Button>

              <Button
                RightIcon={TrashWhiteIcon}
                type="destructive"
                onClick={onRemove}
              >
                {t("Global.remove")}
              </Button>

              <Button RightIcon={CheckWHiteIcon}>{t("Global.edit")}</Button>
            </div>
          )}
        </div>
      </form>
    </RightModal>
  );
};
