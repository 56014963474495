import React from "react";
import {ExtraOrderProps} from "./ReservationExtrasCard.type";
import {useTranslation} from "react-i18next";
import moment from "moment-timezone";
import {Badge} from "../../Common/Badge/Badge";
import {getReservationExtraOrderStatusBadge} from "../../../helpers/reservationHelper";
import {getColorTextValidate} from "../../../helpers/extraOrderHelper";
import {cn} from "../../../helpers/classHelper";

const ExtraOrderCard = ({extraOrder, disabled, openModal}: ExtraOrderProps) => {
  const {t} = useTranslation();
  const {id, order_date, payment_status} = extraOrder;
  const userTimezone = moment.tz.guess();

  const handleClick = () => {
    if (disabled) return;

    openModal(extraOrder);
  };

  return (
    <div
      className={cn(
        "flex flex-row rounded-6px bg-element-border/15 w-full justify-between p-2 border-1 border-element-border items-center",
        disabled ? "cursor-not-allowed" : "cursor-pointer"
      )}
      onClick={handleClick}
    >
      <div className="flex flex-col space-y-1">
        <span className="font-bold">
          {t("Booking.ExtrasOrder.order", {uuid: id})}
        </span>{" "}
        <span>
          {t("Booking.ExtrasOrder.orderDate", {
            date: moment.utc(order_date).tz(userTimezone).format("DD/MM/YYYY"),
            hour: moment.utc(order_date).tz(userTimezone).format("HH:mm"),
          })}
        </span>{" "}
      </div>
      <div className="flex">
        {getReservationExtraOrderStatusBadge(payment_status)}
      </div>
      <div
        className={`flex mr-1 ${getColorTextValidate(payment_status)?.color}`}
      >
        {(payment_status && getColorTextValidate(payment_status)?.text) ?? ""}
      </div>
    </div>
  );
};

export default ExtraOrderCard;
