/**
 * Filters of the page
 * "/operational-management/payments"
 * @constructor
 */
import { DateFilter } from "../Common/DateFilter/DateFilter";
import moment from "moment/moment";
import React from "react";
import { Button } from "../Common/Button/Button";
import FilterIcon from "../../assets/icons/filter_.svg?react";
import { useTranslation } from "react-i18next";
import { ManagementPaymentPageListFiltersProps } from "./Payment.type";

import FilterFullIcon from "../../assets/icons/filter-active.svg?react";

export const PaymentFilters: React.FC<
  ManagementPaymentPageListFiltersProps
> = ({ onChangeDates, onClickFilterButton, hasFilter }) => {
  const { t } = useTranslation();

  /**
   * Function for format date to day-month-year (01-01-2025)
   * is utils for filters.
   *
   * see hook : useDate :)
   * @param dateString
   */
  const formatDate = (dateString: string | undefined) => {
    if (!dateString) return "";
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  return (
    <section className={"flex items-center gap-2 mt-4"}>
      <div className={"flex gap-2"}>
        <DateFilter
          defaultPreset={"current_month"}
          combineCustomAndDefault={false}
          customDateRanges={[
            {
              label: t("Components.DateFilter.currentMonth"),
              value: "current_month",
              startDate: () => moment().startOf("month").toDate(),
              endDate: () => moment().endOf("month").toDate(),
            },
            {
              label: t("Components.DateFilter.nextMonth"),
              value: "next_month",
              startDate: () =>
                moment().add(1, "month").startOf("month").toDate(),
              endDate: () => moment().add(1, "month").endOf("month").toDate(),
            },
            {
              label: t("Components.DateFilter.nextThreeMonths"),
              value: "next_three_months",
              startDate: () => moment().startOf("month").toDate(),
              endDate: () => moment().add(3, "months").endOf("month").toDate(),
            },
            {
              label: t("Components.DateFilter.custom"),
              value: "custom_range",
              startDate: () => moment().add(-7, "days").toDate(),
              endDate: () => moment().toDate(),
            },
          ]}
          onDatesChanged={(dates) => {
            const formattedStartDate = formatDate(dates[0]?.toString());
            const formattedEndDate = formatDate(dates[1]?.toString());
            onChangeDates(formattedStartDate, formattedEndDate);
          }}
        />
      </div>
      <div>
        <Button
          type={"secondary"}
          RightIcon={hasFilter ? FilterFullIcon : FilterIcon}
          onClick={onClickFilterButton}
          borderButton={
            hasFilter ? "border-1 border-active" : "border-1 border-black/12"
          }
        />
      </div>
    </section>
  );
};
