import React from "react";
import { CenterModal } from "../Common/CenterModal/CenterModal";
import { useTranslation } from "react-i18next";

import SettingsSlidersIcon from "../../assets/icons/settings-sliders.svg?react";
import SquarePlusIcon from "../../assets/icons/square-plus.svg?react";

export const AddChoiceModal: React.FC<{
  isVisible: boolean;
  onClose: () => void;
  onAdd: () => void;
  onIntegrate: () => void;
}> = ({ isVisible, onAdd, onClose, onIntegrate }) => {
  const { t } = useTranslation();

  const handleClose = () => {
    onClose();
  };

  const handleIntegrate = () => {
    handleClose();
    onIntegrate();
  };

  const handleAdd = () => {
    handleClose();
    onAdd();
  };

  return (
    <CenterModal
      isVisible={isVisible}
      onClose={handleClose}
      title={t("Website.AddChoice.title")}
    >
      <div className="text-sm flex flex-col gap-3">
        <p className="text-low-contrast font-light">
          {t("Website.AddChoice.description")}
        </p>

        <div
          className="w-full border-1 border-element-border bg-element-background p-4 rounded-6px hover:bg-element-background-active cursor-pointer flex flex-col gap-2 items-center justify-center"
          onClick={handleIntegrate}
        >
          <SettingsSlidersIcon className="size-12" />

          <p className="font-semibold text-high-contrast text-center">
            {t("Website.AddChoice.integrateTitle")}
          </p>

          <p className="font-light text-low-contrast text-center">
            {t("Website.AddChoice.integrateDescription")}
          </p>
        </div>

        <div
          className="w-full border-1 border-element-border bg-element-background p-4 rounded-6px hover:bg-element-background-active cursor-pointer flex flex-col gap-2 items-center justify-center"
          onClick={handleAdd}
        >
          <SquarePlusIcon className="size-12" />

          <p className="font-semibold text-high-contrast text-center">
            {t("Website.AddChoice.addTitle")}
          </p>
        </div>
      </div>
    </CenterModal>
  );
};
