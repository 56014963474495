import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ArrowRightWhiteIcon from "../../../assets/icons/arrow-right-white.svg?react";
import { Button } from "../../Common/Button/Button";
import { AddRentalTarificationType, AddRentalType } from "../AddRental.type";

import ArrowLeftIcon from "../../../assets/icons/arrow-left.svg?react";
import countries from "../../../constants/countries";
import paths from "../../../constants/paths";
import { post } from "../../../helpers/APIHelper";
import { getCurrencySigle } from "../../../helpers/currencyHelper";
import currencies from "../../../res/currencies";
import { ValueType } from "../../../types/commonTypes";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import { InputSelect } from "../../Common/InputSelect/InputSelect";
import { NumberInput } from "../../Common/NumberInput/NumberInput";
import { SimpleRadio } from "../../Common/SimpleRadio/SimpleRadio";

export const AddRentalTarification: React.FC<{
  rental: AddRentalType | undefined;
  onCancel: () => void;
  onNext: (
    values: AddRentalTarificationType,
    rental_id: ValueType,
    isActive: boolean
  ) => void;
  onBack: () => void;
  onChange: (values: AddRentalTarificationType) => void;
}> = ({ rental, onCancel, onNext, onBack, onChange }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const privateRoomCount = rental?.rooms?.filter(
    (room) => room.private_room
  )?.length;

  const priceDefault = () => {
    if (privateRoomCount) {
      if (1 >= privateRoomCount) {
        return 50;
      } else if (2 === privateRoomCount) {
        return 60;
      } else if (3 === privateRoomCount) {
        return 80;
      }
    }
    return 1;
  };
  const form = useForm<AddRentalTarificationType>({
    defaultValues: {
      priceByNight: priceDefault(),
      currency: 23,
      vat: 0,
      cleaning: 0,
      linen: 0,
      extraGuestPrice: 0,
      extraGuestCapacity: 0,
      cityTax: {
        type: "fix",
        fixValue: 0,
        percentValue: 0,
        percentMax: 0,
        percentExtra: 0,
      },
    },
  });

  const watchCurrency = form.watch("currency");

  useEffect(() => {
    if (rental) {
      form.setValue(
        "priceByNight",
        rental.tarification?.priceByNight ?? priceDefault()
      );
      form.setValue("currency", rental.tarification?.currency ?? 23);
      form.setValue("vat", rental.tarification?.vat ?? 0);
      form.setValue("cleaning", rental.tarification?.cleaning ?? 0);
      form.setValue("linen", rental.tarification?.linen ?? 0);
      form.setValue(
        "extraGuestPrice",
        rental.tarification?.extraGuestPrice ?? 0
      );
      form.setValue(
        "extraGuestCapacity",
        rental.tarification?.extraGuestCapacity ?? 0
      );
      form.setValue("cityTax.type", rental.tarification?.cityTax.type ?? "fix");
      form.setValue(
        "cityTax.fixValue",
        rental.tarification?.cityTax.fixValue ?? 0
      );
      form.setValue(
        "cityTax.percentValue",
        rental.tarification?.cityTax.percentValue ?? 0
      );
      form.setValue(
        "cityTax.percentMax",
        rental.tarification?.cityTax.percentMax ?? 0
      );
      form.setValue(
        "cityTax.percentExtra",
        rental.tarification?.cityTax.percentExtra ?? 0
      );
    }
  }, [rental]);

  const handleNextStep = async (values: AddRentalTarificationType) => {
    setLoading(true);
    setError(null);

    const formData = new FormData();
    formData.append("name", rental?.infos?.name ?? "");
    formData.append(
      "rental_category_id",
      rental?.infos?.rentalCategory?.toString() ?? ""
    );
    formData.append(
      "rental_type_id",
      rental?.infos?.rentalType?.toString() ?? ""
    );
    formData.append(
      "renting_type",
      rental?.infos?.rentingType?.toString() ?? ""
    );
    formData.append(
      "management_type",
      rental?.infos?.managementType?.toString() ?? ""
    );

    formData.append("longitude", String(rental?.infos?.longitude ?? ""));
    formData.append("latitude", String(rental?.infos?.latitude ?? ""));
    formData.append("address", String(rental?.infos?.address ?? ""));
    formData.append("postal_code", rental?.infos?.postal_code ?? "");
    formData.append("city", rental?.infos?.city ?? "");
    formData.append(
      "country_code",
      countries.find((country) => country.value === rental?.infos?.country)
        ?.code ?? ""
    );
    formData.append(
      "guests_max_capacity",
      rental?.infos?.maxGuests?.toString() ?? ""
    );
    formData.append(
      "adults_max_capacity",
      rental?.infos?.maxAdults?.toString() ?? ""
    );
    formData.append(
      "children_max_capacity",
      rental?.infos?.maxChildren?.toString() ?? ""
    );
    formData.append(
      "bathrooms_count",
      rental?.infos?.bathrooms?.toString() ?? ""
    );

    rental?.rooms?.forEach((room, i) => {
      formData.append(`bedrooms[${i}]`, JSON.stringify(room));
    });

    formData.append("description", rental?.photos?.description ?? "");

    rental?.photos?.photos?.forEach((photo, i) => {
      formData.append(`photos[${i}]`, photo);
    });

    formData.append(
      "equipments",
      rental?.facilities?.join(",").toUpperCase() ?? ""
    );
    formData.append("checkin_min_time", rental?.rules?.minTimein ?? "");
    formData.append("checkin_max_time", rental?.rules?.maxTimein ?? "");
    formData.append("checkout_min_time", rental?.rules?.minTimeout ?? "");
    formData.append("checkout_max_time", rental?.rules?.maxTimeout ?? "");
    formData.append(
      "min_nights_default",
      rental?.rules?.minNights.toString() ?? ""
    );
    formData.append(
      "max_nights_default",
      rental?.rules?.maxNights.toString() ?? ""
    );
    formData.append(
      "price_night_default",
      values.priceByNight.toString() ?? ""
    );
    formData.append("currency_id", values.currency.toString() ?? "");
    formData.append("vat_percentage", values.vat.toString() ?? "");
    formData.append("cleaning_default", values.cleaning.toString() ?? "");
    formData.append("linen_default", values.linen.toString() ?? "");
    formData.append(
      "price_night_added_per_guests",
      values.extraGuestPrice.toString() ?? ""
    );
    formData.append(
      "guests_default_capacity",
      values.extraGuestCapacity.toString() ?? ""
    );
    formData.append(
      "city_tax_default",
      values.cityTax.type === "fix" ? values.cityTax.fixValue.toString() : ""
    );
    formData.append(
      "city_tax_percent",
      values.cityTax.type === "percent"
        ? values.cityTax.percentValue.toString()
        : ""
    );
    formData.append(
      "city_tax_limit",
      values.cityTax.type === "percent"
        ? values.cityTax.percentMax.toString()
        : ""
    );
    formData.append(
      "city_tax_additional",
      values.cityTax.type === "percent"
        ? values.cityTax.percentExtra.toString()
        : ""
    );

    const res = await post(
      `${import.meta.env.VITE_API_URL}${
        paths.API.RENTAL_ADD_CHECK_TARIFICATION
      }`,
      formData
    );

    if (res.data?.success) {
      onNext(
        values,
        res.data?.result?.id,
        res.data?.result?.details?.informations?.enabled
      );
    } else {
      setError(res.response.data.message);
    }

    setLoading(false);
  };

  const handleChange = (
    key: keyof AddRentalTarificationType,
    value: number | string | ValueType,
    subKey?: string
  ) => {
    const formData = form.getValues() as AddRentalTarificationType;

    if (subKey) {
      onChange({
        ...formData,
        [key]: {
          ...(formData[key] as object),
          [subKey]: value,
        },
      });
      return;
    }

    onChange({
      ...formData,
      [key]: value,
    });
  };

  return (
    <form onSubmit={form.handleSubmit(handleNextStep)}>
      <div className="flex flex-col w-full text-sm">
        <div className="flex justify-start pb-12">
          <div className="w-44">
            <Button
              type="secondary"
              disabled={loading}
              LeftIcon={ArrowLeftIcon}
              onClick={onBack}
            >
              {t("Global.previousStep")}
            </Button>
          </div>
        </div>

        <p className="text-lg font-semibold text-high-contrast">
          {t("AddRental.Tarification.title")}
        </p>

        <p className="mt-4 mb-6 font-light text-low-contrast">
          {t("AddRental.Tarification.subTitle")}
        </p>
      </div>

      <div className="flex gap-4 mt-4">
        <Controller
          control={form.control}
          name="priceByNight"
          render={({ field: { value, onChange, name } }) => {
            const setChange = (value: number | string) => {
              onChange(value);
              handleChange(name, value);
            };
            return (
              <NumberInput
                label={t("AddRental.Tarification.priceByNight")}
                value={value}
                onChangeText={setChange}
                min={1}
                acceptDecimal={true}
                size="large"
                currency={getCurrencySigle(watchCurrency)}
                error={form.formState.errors.priceByNight?.message}
                disabled={loading}
              />
            );
          }}
        />

        <div className="flex-1">
          <Controller
            control={form.control}
            name="currency"
            render={({ field: { value, onChange, name } }) => {
              const setChange = (value: ValueType) => {
                onChange(value);
                handleChange(name, value);
              };
              return (
                <InputSelect
                  label={t("AddRental.Tarification.currency")}
                  items={currencies.map((c) => {
                    return { value: c.id, label: `${c.name} (${c.symbol})` };
                  })}
                  selectedValue={value}
                  onSelect={setChange}
                  error={form.formState.errors.currency?.message}
                  disabled={true}
                />
              );
            }}
          />
        </div>
      </div>

      <div className="mt-3">
        <Controller
          control={form.control}
          name="vat"
          render={({ field: { value, onChange, name } }) => {
            const setChange = (value: number | string) => {
              onChange(value);
              handleChange(name, value);
            };
            return (
              <NumberInput
                label={t("AddRental.Tarification.vat")}
                value={value}
                onChangeText={setChange}
                min={0}
                acceptDecimal={true}
                size="large"
                currency="%"
                error={form.formState.errors.vat?.message}
                disabled={loading}
              />
            );
          }}
        />
      </div>

      <div className="flex gap-4 mt-3">
        <Controller
          control={form.control}
          name="cleaning"
          render={({ field: { value, onChange, name } }) => {
            const setChange = (value: number | string) => {
              onChange(value);
              handleChange(name, value);
            };
            return (
              <NumberInput
                label={t("AddRental.Tarification.cleaning")}
                value={value}
                onChangeText={setChange}
                min={0}
                acceptDecimal={true}
                size="large"
                currency={getCurrencySigle(watchCurrency)}
                error={form.formState.errors.cleaning?.message}
                disabled={loading}
              />
            );
          }}
        />

        <div className="flex-1">
          <Controller
            control={form.control}
            name="linen"
            render={({ field: { value, onChange, name } }) => {
              const setChange = (value: number | string) => {
                onChange(value);
                handleChange(name, value);
              };
              return (
                <NumberInput
                  label={t("AddRental.Tarification.linen")}
                  value={value}
                  onChangeText={setChange}
                  min={0}
                  acceptDecimal={true}
                  size="large"
                  currency={getCurrencySigle(watchCurrency)}
                  error={form.formState.errors.linen?.message}
                  disabled={loading}
                />
              );
            }}
          />
        </div>
      </div>

      <div className="flex gap-4 mt-3">
        <Controller
          control={form.control}
          name="extraGuestPrice"
          render={({ field: { value, onChange, name } }) => {
            const setChange = (value: number | string) => {
              onChange(value);
              handleChange(name, value);
            };
            return (
              <NumberInput
                label={t("AddRental.Tarification.extraGuestPrice")}
                value={value}
                onChangeText={setChange}
                min={0}
                acceptDecimal={true}
                size="large"
                currency={getCurrencySigle(watchCurrency)}
                error={form.formState.errors.extraGuestPrice?.message}
                disabled={loading}
              />
            );
          }}
        />

        <div className="flex-1">
          <Controller
            control={form.control}
            name="extraGuestCapacity"
            rules={{
              max: {
                value: rental?.infos?.maxGuests ?? 100,
                message: t("AddRental.Tarification.extraGuestCapacityError", {
                  count: rental?.infos?.maxGuests ?? 100,
                }),
              },
            }}
            render={({ field: { value, onChange, name } }) => {
              const setChange = (value: number | string) => {
                onChange(value);
                handleChange(name, value);
              };
              return (
                <NumberInput
                  label={t("AddRental.Tarification.extraGuestCapacity")}
                  value={value}
                  onChangeText={setChange}
                  min={0}
                  max={rental?.infos?.maxGuests}
                  size="large"
                  error={form.formState.errors.extraGuestCapacity?.message}
                  disabled={loading}
                />
              );
            }}
          />
        </div>
      </div>

      <div className="flex flex-col mt-5">
        <p className="text-sm font-semibold text-high-contrast">
          {t("AddRental.Tarification.taxType")}
        </p>

        <div>
          <Controller
            control={form.control}
            name="cityTax.type"
            render={({ field: { value, onChange } }) => {
              const setChange = (value: string) => {
                onChange(value);
                handleChange("cityTax", value, "type");
              };
              return (
                <div className="flex gap-3 mt-2 text-sm font-semibold text-low-contrast">
                  <div
                    className="flex items-center gap-1 cursor-pointer"
                    onClick={() => setChange("fix")}
                  >
                    <SimpleRadio value={value === "fix"} />
                    <p>{t("AddRental.Tarification.taxTypeFix")}</p>
                  </div>

                  <div
                    className="flex items-center gap-1 cursor-pointer"
                    onClick={() => setChange("percent")}
                  >
                    <SimpleRadio value={value === "percent"} />
                    <p>{t("AddRental.Tarification.taxTypePercent")}</p>
                  </div>
                </div>
              );
            }}
          />
        </div>

        {form.watch("cityTax.type") === "fix" && (
          <div className="mt-3">
            <Controller
              control={form.control}
              name="cityTax.fixValue"
              render={({ field: { value, onChange } }) => {
                const setChange = (value: number | string) => {
                  onChange(value);
                  handleChange("cityTax", value, "fixValue");
                };
                return (
                  <NumberInput
                    label={t("AddRental.Tarification.fixValue")}
                    value={value}
                    onChangeText={setChange}
                    min={0}
                    acceptDecimal={true}
                    size="large"
                    currency={getCurrencySigle(watchCurrency)}
                    error={form.formState.errors.cityTax?.fixValue?.message}
                    disabled={loading}
                  />
                );
              }}
            />
          </div>
        )}

        {form.watch("cityTax.type") === "percent" && (
          <div>
            <div className="flex gap-4 mt-3">
              <Controller
                control={form.control}
                name="cityTax.percentValue"
                render={({ field: { value, onChange } }) => {
                  const setChange = (value: number | string) => {
                    onChange(value);
                    handleChange("cityTax", value, "percentValue");
                  };
                  return (
                    <NumberInput
                      label={t("AddRental.Tarification.percentValue")}
                      value={value}
                      onChangeText={setChange}
                      min={0}
                      acceptDecimal={true}
                      size="large"
                      currency="%"
                      error={
                        form.formState.errors.cityTax?.percentValue?.message
                      }
                      disabled={loading}
                    />
                  );
                }}
              />

              <Controller
                control={form.control}
                name="cityTax.percentMax"
                render={({ field: { value, onChange } }) => {
                  const setChange = (value: number | string) => {
                    onChange(value);
                    handleChange("cityTax", value, "percentMax");
                  };
                  return (
                    <NumberInput
                      label={t("AddRental.Tarification.percentMax")}
                      value={value}
                      onChangeText={setChange}
                      min={0}
                      acceptDecimal={true}
                      size="large"
                      currency={getCurrencySigle(watchCurrency)}
                      error={form.formState.errors.cityTax?.percentMax?.message}
                      disabled={loading}
                    />
                  );
                }}
              />
            </div>

            <div className="mt-3">
              <Controller
                control={form.control}
                name="cityTax.percentExtra"
                render={({ field: { value, onChange } }) => {
                  const setChange = (value: number | string) => {
                    onChange(value);
                    handleChange("cityTax", value, "percentExtra");
                  };
                  return (
                    <NumberInput
                      label={t("AddRental.Tarification.percentExtra")}
                      value={value}
                      onChangeText={setChange}
                      min={0}
                      acceptDecimal={true}
                      size="large"
                      currency={getCurrencySigle(watchCurrency)}
                      error={
                        form.formState.errors.cityTax?.percentExtra?.message
                      }
                      disabled={loading}
                    />
                  );
                }}
              />
            </div>
          </div>
        )}
      </div>

      <ErrorMessage>{error}</ErrorMessage>

      <div className="flex gap-4 pb-4 mt-8">
        <Button type="secondary" onClick={onCancel} disabled={loading}>
          {t("Global.cancel")}
        </Button>
        <Button RightIcon={ArrowRightWhiteIcon} loading={loading}>
          {t("AddRental.Tarification.nextStep")}
        </Button>
      </div>
    </form>
  );
};
