import { ValueType } from "../types/commonTypes";

/**
 * Remove items in an array based duplicated values for given key
 *
 * @param {Array} array - The array to check.
 * @param {string} params.price - The price value to be formatted.
 * 
 * @returns {Array} The array without duplicated key values.
 */
export const getUniqueArrayFromKey = (array: any, key: string) => {
    if (typeof key !== "function") {
        const property = key;
        key = function (item) {
            return item[property];
        };
    }
    
    return Array.from(
        array
        .reduce(function (map, item) {
            const k = key(item);
            if (!map.has(k)) map.set(k, item);
            return map;
        }, new Map())
        .values()
    );
};