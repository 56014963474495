import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../Button/Button";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import { RightModal } from "../RightModal/RightModal";
import { TextInput } from "../TextInput/TextInput";
import { MultiSelectRentalItem } from "./MultiSelectRentalItem";
import { MultiSelectRentalsModalProps } from "./MultiSelectRentals.type";

import { debounce } from "lodash";
import CheckWhiteIcon from "../../../assets/icons/check-white.svg?react";
import SearchIcon from "../../../assets/icons/search.svg?react";
import { ValueType } from "../../../types/commonTypes";
import { MultiSelectRentalItemSkeleton } from "./MultiSelectRentalItemSkeleton";
import { HelpText } from "../TextComponents/HelpText";
import useText from "../../../hooks/useText";
import { FlexItemCenter } from "../FlexComponents/FlexItemCenter";
import { CheckBox } from "../CheckBox/CheckBox";
import { Badge } from "../Badge/Badge";

import AirBnBWhiteIcon from "../../../assets/icons/airbnb-white.svg?react";
import BookingIcon from "../../../assets/icons/booking.svg?react";
import WebsiteIcon from "../../../assets/logos/website.svg?react";
import HandIcon from "../../../assets/icons/hand.svg?react";
import OtherPlatformIcon from "../../../assets/icons/globe.svg?react";
import { PlatformEnum } from "../../../enums/GETenums";

export type CheckboxState = {
  airbnb: boolean;
  booking: boolean;
  ical: boolean;
  website: boolean;
  direct: boolean;
};

export const MultiSelectRentalsModal: React.FC<
  MultiSelectRentalsModalProps
> = ({
  isVisible,
  items,
  allRentals,
  selectedRentals = [],
  loading,
  loadingSearch,
  error,
  onApply,
  onSelect = () => {},
  onClose,
  onSearch,
  hasCheckboxPlatforms = false,
  onSelectedReservations = () => {},
  initialReservations = [],
  setIsFiltered,
  setCheckboxIsFiltered,
}) => {
  const { t } = useTranslation();
  const { getText } = useText("Booking.Reservation.Filters");

  const [filter, setFilter] = useState<string>("");

  const [checkboxs, setCheckboxs] = useState<CheckboxState>({
    airbnb: false,
    booking: false,
    ical: false,
    website: false,
    direct: false,
  });

  const resetCheckboxes = () => {
    setCheckboxs({
      airbnb: false,
      booking: false,
      ical: false,
      website: false,
      direct: false,
    });

    if (typeof setCheckboxIsFiltered === "function") {
      setCheckboxIsFiltered(false);
    }
  };

  const handleChange = (name: keyof CheckboxState) => {
    setCheckboxs((prev) => {
      const updatedCheckboxes = {
        ...prev,
        [name]: !prev[name],
      };

      handleSelectValues(updatedCheckboxes);

      return updatedCheckboxes;
    });
  };

  const handleSelectValues = (allCheckboxs: CheckboxState): void => {
    if (!hasCheckboxPlatforms) {
      return;
    }

    if (typeof setCheckboxIsFiltered === "function") {
      setCheckboxIsFiltered(Object.values(allCheckboxs).some(Boolean));
    }

    const selectedPlatforms = Object.entries(allCheckboxs)
      .filter(([_, isChecked]) => isChecked)
      .map(
        ([key]) => PlatformEnum[key.toUpperCase() as keyof typeof PlatformEnum],
      );

    const allReservations = initialReservations.filter((reservation) =>
      selectedPlatforms.includes(reservation.platform.id),
    );

    onSelectedReservations(allReservations);
  };

  const handleSelectOne = (value: ValueType) => {
    if (selectedRentals.findIndex((r) => r === value) > -1)
      onSelect(selectedRentals.filter((r) => r !== value));
    else onSelect([...selectedRentals, value]);
  };

  const countRentals = () => {
    return allRentals?.length ?? 0;
  };

  const getFullSelectMode = () => {
    if (selectedRentals?.length === countRentals()) return "full";
    else if (selectedRentals?.length === 0) return "none";
    else return "semi";
  };

  const handleSelectAll = () => {
    if (selectedRentals?.length === countRentals()) onSelect([]);
    else onSelect(allRentals);
  };

  const debouncedSearchRentals = React.useRef(
    debounce(async (text: string) => {
      onSearch(text);
    }, 300),
  ).current;

  const handleChangeText = (text: string) => {
    setFilter(text);
    debouncedSearchRentals(text);
  };

  const isLoading = loading || loadingSearch;

  return (
    <RightModal
      isVisible={isVisible}
      onClose={onClose}
      title={t("Components.MultiFilterRentals.title")}
      classNames={{
        mainContentParent: "overflow-y-auto",
      }}
    >
      <div className="flex flex-col w-full font-normal select-none text-high-contrast">
        {hasCheckboxPlatforms ? (
          <div className={"mb-6"}>
            <HelpText className={"mt-2 mb-4 font-bold"}>
              {getText("filterPlatform")}
            </HelpText>
            <div className={"grid grid-cols-2 gap-4"}>
              <FlexItemCenter gap={5}>
                <CheckBox
                  value={checkboxs.airbnb}
                  onChange={(value: boolean) => {
                    handleChange("airbnb");
                  }}
                />
                <Badge
                  Icon={AirBnBWhiteIcon}
                  customColor={"bg-red-100"}
                  label={getText("airbnb")}
                  className={"text-red-500 cursor-pointer"}
                  onClick={() => handleChange("airbnb")}
                />
              </FlexItemCenter>
              <FlexItemCenter gap={5}>
                <CheckBox
                  value={checkboxs.website}
                  onChange={(value: boolean) => {
                    handleChange("website");
                  }}
                />
                <Badge
                  Icon={WebsiteIcon}
                  customColor={"bg-red-200"}
                  label={getText("website")}
                  className={"text-red-500 cursor-pointer"}
                  onClick={() => handleChange("website")}
                />
              </FlexItemCenter>
              <FlexItemCenter gap={5}>
                <CheckBox
                  value={checkboxs.booking}
                  onChange={(value: boolean) => {
                    handleChange("booking");
                  }}
                />
                <Badge
                  Icon={BookingIcon}
                  customColor={"bg-blue-500/20"}
                  label={getText("booking")}
                  className={"text-blue-800 cursor-pointer"}
                  onClick={() => handleChange("booking")}
                />
              </FlexItemCenter>
              <FlexItemCenter gap={5}>
                <CheckBox
                  value={checkboxs.direct}
                  onChange={(value: boolean) => {
                    handleChange("direct");
                  }}
                />
                <Badge
                  Icon={HandIcon}
                  status={"basic"}
                  className={"cursor-pointer"}
                  label={getText("manual")}
                  onClick={() => handleChange("direct")}
                />
              </FlexItemCenter>
              <FlexItemCenter gap={5}>
                <CheckBox
                  value={checkboxs.ical}
                  onChange={(value: boolean) => {
                    handleChange("ical");
                  }}
                />
                <Badge
                  Icon={OtherPlatformIcon}
                  status={"basic"}
                  label={getText("otherIcal")}
                  className={"cursor-pointer"}
                  onClick={() => handleChange("ical")}
                />
              </FlexItemCenter>
            </div>
          </div>
        ) : null}
        <div className="w-full">
          <HelpText className={"mb-4 font-bold"}>
            {getText("filterRentals")}
          </HelpText>
          <TextInput
            label={t("Global.rental")}
            placeholder={`${t("Global.search")} ...`}
            Icon={SearchIcon}
            value={filter}
            disabled={loading}
            onChangeText={(text) => handleChangeText(text)}
          />
        </div>

        {isLoading && (
          <div className="flex-1 w-full mt-4 overflow-y-auto">
            {/* ALL HEADER */}
            <div className="rounded-t-6px bg-element-background border-1 border-element-border">
              <MultiSelectRentalItem
                key={`group-all`}
                label={t("Global.allRentalsSelected")}
                selectMode={"none"}
                isBold={true}
                onClick={() => handleSelectAll()}
              />
            </div>

            <div className="mb-4 border-t-0 border-1 border-element-border rounded-b-6px">
              {Array.from({ length: 5 }).map((v, index) => (
                <MultiSelectRentalItemSkeleton key={index} />
              ))}
            </div>
          </div>
        )}

        {!isLoading && (
          <div className="flex-1 w-full mt-4 overflow-y-auto">
            {/* ALL HEADER */}
            <div className="rounded-t-6px bg-element-background border-1 border-element-border">
              <MultiSelectRentalItem
                key={`group-all`}
                label={t("Global.allRentalsSelected")}
                selectMode={getFullSelectMode()}
                isBold={true}
                onClick={() => handleSelectAll()}
              />
            </div>

            <div className="mb-4 border-t-0 border-1 border-element-border rounded-b-6px">
              {items?.map((rental) => {
                const isSelected =
                  selectedRentals?.findIndex((v) => v === rental.id) > -1;

                return (
                  <MultiSelectRentalItem
                    key={`rental-filter-${rental.id}`}
                    rental={rental}
                    selectMode={isSelected ? "full" : "none"}
                    onClick={() => handleSelectOne(rental.id)}
                  />
                );
              })}
            </div>
          </div>
        )}

        <ErrorMessage>{error}</ErrorMessage>

        {/* Buttons */}
        <div className="flex gap-3 pt-4 border-t-1 border-element-border">
          <Button
            type="secondary"
            onClick={onClose}
            disabled={loading || loadingSearch}
          >
            {t("Global.cancel")}
          </Button>
          <Button
            type="secondary"
            onClick={() => {
              if (typeof setIsFiltered === "function") {
                setIsFiltered(false);
              }

              if (typeof setCheckboxIsFiltered === "function") {
                setCheckboxIsFiltered(false);
              }

              onSelect(allRentals);

              if (hasCheckboxPlatforms) {
                resetCheckboxes();
              }
            }}
            disabled={loading || loadingSearch}
          >
            {t("Global.reinit")}
          </Button>
          <Button
            RightIcon={CheckWhiteIcon}
            onClick={() => {
              if (
                checkboxs.direct ||
                checkboxs.ical ||
                checkboxs.airbnb ||
                checkboxs.direct ||
                checkboxs.website ||
                checkboxs.booking
              ) {
                onSelect(allRentals);
                if (typeof setCheckboxIsFiltered === "function") {
                  setCheckboxIsFiltered(true);
                }
              }
              if (typeof setIsFiltered === "function") {
                setIsFiltered(true);
              }
              onApply(selectedRentals);
            }}
            disabled={loading || loadingSearch}
          >
            {t("Global.filter")}
          </Button>
        </div>
      </div>
    </RightModal>
  );
};
