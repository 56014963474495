import React, {useEffect, useState} from "react";
import {cn} from "../../../../helpers/classHelper";
import useWindowSize from "../../../../hooks/useWindowSize";
import useGuestWebsiteStore from "../../../../stores/guestWebsiteStore/useGuestWebsiteStore";
import {Controller, useForm} from "react-hook-form";
import {NumberInput} from "../../../Common/NumberInput/NumberInput";
import {Button} from "../../../Common/Button/Button";
import useGuestWebsite from "../../../../hooks/useGuestWebsite";
import CalendarIcon from "../../../../assets/icons/calendar.svg?react";
import ArrowRightIcon from "../../../../assets/icons/arrow-right.svg?react";
import {TimeInput} from "../../../Common/TimeInput/TimeInput";
import {DateInput} from "../../../Common/DateInput/DateInput";
import {DEFAULT_COLOR_WEBSITE_TEMPLATE_2} from "../../../../constants/colors";
import {isValidDate} from "../../Rental/Template2.0/RentalDetails/Home/RentalDetailsHomePage";
import moment from "moment";
import {CalendarInput} from "../../../Common/CalendarInput/CalendarInput";
import {useTranslation} from "react-i18next";
import {CalendarInputValueRange} from "../../../Common/CalendarInput/CalendarInput.type";

export interface SearchBannerForm {
  checkinsDates: CalendarInputValueRange;
  adults: number;
  children: number;
  url: string;
}
export interface SearchBannerData {
  checkin: string;
  checkout: string;
  adults: number;
  children: number;
  url: string;
}

const SearchBannerComponent = ({
  isAbsolute = false,
}: {
  isAbsolute?: boolean;
}) => {
  const {t} = useTranslation();
  const {isMobile} = useWindowSize();
  const [notValid, setNotValid] = useState<boolean>(false);
  const {
    searchRentals,
    isLoadingSearchRentals: isLoading,
    dataSearch,
    errorWebsite: error,
  } = useGuestWebsite();

  const {
    tabGuestWebsite,
    dataGuestWebsite,
    setDataGuestWebsite,
    setLoadingRentalsSearch,
    checkinsDates,
    setCheckinsDates,
  } = useGuestWebsiteStore();
  const {integration, styles} = dataGuestWebsite || {};
  const {color_main, color_background, color_text} = styles || {};

  const form = useForm<SearchBannerForm>({
    defaultValues: {
      checkinsDates: checkinsDates,
      adults: 2,
      children: 0,
      url: integration?.url,
    },
  });

  const handleSubmitSearch = async (data: SearchBannerForm) => {
    const checkin = moment(data.checkinsDates[0]).format("YYYY-MM-DD");
    const checkout = moment(data.checkinsDates[1]).format("YYYY-MM-DD");
    await searchRentals({...data, checkin, checkout});
  };

  useEffect(() => {
    form.setValue("url", integration?.url ?? "");
  }, [dataGuestWebsite]);

  useEffect(() => {
    if (dataSearch && !error && dataGuestWebsite) {
      setLoadingRentalsSearch(true);
      setDataGuestWebsite({
        ...dataGuestWebsite,
        rentals: dataSearch,
      });
      setCheckinsDates(form.getValues("checkinsDates"));
      setTimeout(() => {
        setLoadingRentalsSearch(false);
      }, 2000);
    }
  }, [error, dataSearch]);

  const triggerOnFormChange = () => {
    const {checkinsDates} = form.getValues();
    const checkin = checkinsDates?.[0];
    const checkout = checkinsDates?.[1];
    if (checkinsDates.length !== 2 || checkinsDates.includes(null)) {
      setNotValid(true);
    } else if (checkin && checkout) {
      setNotValid(false);
    }
  };

  const checkinsDatesForm = form.watch("checkinsDates");

  useEffect(() => {
    triggerOnFormChange();
  }, [checkinsDatesForm]);

  if (isAbsolute && tabGuestWebsite !== "home") {
    return <></>;
  }

  return (
    <div
      className={cn(
        `${
          isAbsolute
            ? "absolute top-48 md:top-40 left-0 right-0 md:mx-auto mt-4 z-50 md:w-full mx-6 "
            : "relative mx-auto my-8 rounded-lg"
        } bg-white shadow-lg  p-4 md:p-6 flex flex-col md:flex-row items-center max-w-4xl md:space-x-8`,
        isAbsolute && isMobile ? "rounded-lg" : ""
      )}
      // style={{backgroundColor: color_background}}
    >
      {/* Date Section */}
      <div className="flex flex-col justify-center md:flex-row items-center md:space-x-8 space-y-2 md:space-y-0 w-full md:w-auto">
        <Controller
          control={form.control}
          name="checkinsDates"
          rules={{
            required: true,
          }}
          render={({field: {onChange, value}}) => (
            <CalendarInput
              classNames={{
                button: `rounded-md bg-white p-2 ${
                  notValid && "border-error/50"
                }`,
                divLabel: "hidden",
                divParent: "w-full md:w-auto",
                popUp: "w-full md:w-auto",
              }}
              disabled={isLoading}
              label={`${t("AddReservation.Infos.dates")}`}
              height="full"
              dateType={"range"}
              allowSingleDateInRange={false}
              value={value}
              onChangeDate={onChange}
              onChangeStartDateInput={() => {}}
              onChangeEndDateInput={() => {}}
              error={form.formState.errors?.checkinsDates?.message}
            />
          )}
        />

        <div className="flex flex-row items-center space-x-4 md:mt-0 pb-4 justify-between md:justify-normal w-full md:w-auto">
          {/* Adults Section */}
          <div className="flex items-center">
            <Controller
              control={form?.control}
              name="adults"
              rules={{
                required: t("GuestWebsite.required", {
                  input: t("GuestWebsite.adults"),
                }),
              }}
              render={({field: {value, onChange}}) => (
                <div className="w-full">
                  <NumberInput
                    label={t("GuestWebsite.adults")}
                    value={value}
                    size="large"
                    onChangeText={onChange}
                    error={form?.formState.errors.adults?.message}
                    disabled={isLoading}
                    labelStyle={{color: color_text}}
                    classNames={{
                      divInput:
                        "outline-none focus:outline-none outline-none focus:ring-offset-0 focus:outline-hidden outline-transparent",
                      input: "outline-none",
                    }}
                  />
                </div>
              )}
            />
          </div>

          {/* Children Section */}
          <div className="flex items-center gap-2">
            <Controller
              control={form?.control}
              name="children"
              rules={{
                required: t("GuestWebsite.required", {
                  input: t("GuestWebsite.children"),
                }),
              }}
              render={({field: {value, onChange}}) => (
                <div className="w-full">
                  <NumberInput
                    label={t("GuestWebsite.children")}
                    value={value}
                    size="large"
                    onChangeText={onChange}
                    error={form?.formState.errors.children?.message}
                    disabled={isLoading}
                    labelStyle={{color: color_text}}
                    classNames={{
                      divInput:
                        "outline-none focus:outline-none outline-none focus:ring-offset-0 focus:outline-hidden outline-transparent",
                      input: "outline-none",
                    }}
                  />
                </div>
              )}
            />
          </div>
        </div>
      </div>

      {/* Search Button */}
      <Button
        classNames={{divParent: "w-full md:w-auto"}}
        buttonClassName=" md:mt-0  bg-none shadow-md hover:brightness-[0.8]"
        onClick={form.handleSubmit(handleSubmitSearch)}
        disabled={!form.formState.isValid || notValid}
        loading={isLoading}
        colorLoader={color_main}
        buttonStyleCss={{backgroundColor: color_main}}
      >
        {t("Global.search")}
      </Button>
    </div>
  );
};

export default SearchBannerComponent;
