import React, {ReactNode, useEffect, useState} from "react";
import DoorEnterIcon from "../../../../../../assets/icons/door-enter.svg?react";
import DoorExitIcon from "../../../../../../assets/icons/door-exit.svg?react";
import SmokeIcon from "../../../../../../assets/icons/smoking.svg?react";
import DogIcon from "../../../../../../assets/icons/dog.svg?react";
import CakeIcon from "../../../../../../assets/icons/cake.svg?react";
import TitleBlockComponent from "../../Common/TitleBlockComponent";
import useGuestWebsiteStore from "../../../../../../stores/guestWebsiteStore/useGuestWebsiteStore";
import moment from "moment";
import {IconType} from "../../../../../../types/commonTypes";
import {useTranslation} from "react-i18next";

interface Item {
  display: boolean;
  text: string;
  icon: ReactNode;
}
const InfoBlock = () => {
  const {t} = useTranslation();
  const {rentalData, dataGuestWebsite} = useGuestWebsiteStore();
  const {styles} = dataGuestWebsite || {};
  const {color_text} = styles || {};
  const {rules, checkout_max_time, checkin_min_time} = rentalData || {};

  const [infoItems, setItems] = useState<Item[]>([]);

  useEffect(() => {
    setItems([
      {
        icon: <DoorEnterIcon />,
        text: t("GuestWebsite.Rental.Home.checkinStart", {
          hour: moment(checkin_min_time, "HH:mm").format("HH [h]"),
        }),
        display: checkin_min_time ? true : false,
      },
      {
        icon: <DoorExitIcon />,
        text: t("GuestWebsite.Rental.Home.checkinEnd", {
          hour: moment(checkout_max_time, "HH:mm").format("HH [h]"),
        }),
        display: checkout_max_time ? true : false,
      },
      {
        icon: <SmokeIcon />,
        text: t("GuestWebsite.Rental.Home.noSmoking"),
        display: Boolean(rules?.smoking_allowed),
      },
      {
        icon: <DogIcon />,
        text: t("GuestWebsite.Rental.Home.noPets"),
        display: Boolean(rules?.pets_allowed),
      },
      {
        icon: <CakeIcon />,
        text: t("GuestWebsite.Rental.Home.noParties"),
        display: Boolean(rules?.events_allowed),
      },
    ]);
  }, [rentalData]);
  return (
    <div>
      <TitleBlockComponent
        title={t("GuestWebsite.Rental.Home.titleBlockInfo")}
      />
      <div className="grid grid-cols-3 md:grid-cols-5 gap-4">
        {infoItems
          ?.filter((item) => item.display)
          ?.map((item, index) => (
            <div
              key={index}
              className="flex flex-col items-center justify-center bg-white shadow-lg p-2 rounded-lg"
            >
              <div className=" text-xl text-center">{item.icon}</div>
              <p
                className="text-sm font-medium text-center"
                style={{color: color_text}}
              >
                {item.text}
              </p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default InfoBlock;
