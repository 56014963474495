import React, {useState, useRef, useEffect} from "react";
import useGuestWebsiteStore, {
  GuestWebsiteTabType,
} from "../../../../stores/guestWebsiteStore/useGuestWebsiteStore";
import {useTranslation} from "react-i18next";
import {cn} from "../../../../helpers/classHelper";
import HambergerMenu from "../../../../assets/icons/menu_website.svg?react";
import CalendarIcon from "../../../../assets/icons/calendar.svg?react";
import SearchBannerComponent from "../../Common/Template2.0/SearchBannerComponent";

interface HeaderTemplate2MobileProps {
  background: string | undefined;
  logo: string | undefined;
  getUnderline: (nav: GuestWebsiteTabType) => "" | "underline";
  getTitle: () => string | undefined;
  getSubtitle: () => string | undefined;
}

const HeaderTemplate2Mobile = ({
  background,
  logo,
  getUnderline,
  getTitle,
  getSubtitle,
}: HeaderTemplate2MobileProps) => {
  const {t} = useTranslation();
  const {setTabGuestWebsite, dataGuestWebsite} = useGuestWebsiteStore();
  const {content} = dataGuestWebsite || {};
  const showServices = content?.services?.enabled;
  const showAbout = content?.about?.enabled;
  const showContact = content?.contact?.enabled;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  // Close menu on outside click
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        closeMenu();
      }
    };

    if (isMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen]);

  return (
    <div className="relative">
      <header
        className="relative flex flex-col p-4  text-white pb-24 "
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/* Overlay */}
        <div className="absolute inset-0 bg-black/50"></div>

        {/* Top Section */}
        <div className="relative flex justify-between items-center">
          {/* Logo */}
          <div
            className="flex items-center cursor-pointer"
            onClick={() => setTabGuestWebsite("home")}
          >
            <img
              src={logo}
              alt="Le Super Template Logo"
              className="w-32 h-11"
            />
          </div>

          {/* Hamburger Icon */}
          <button
            className="bg-white focus:outline-none rounded-6px"
            onClick={toggleMenu}
          >
            <HambergerMenu className="h-11 w-11 " />
          </button>
        </div>

        {/* Center Section */}
        <div className="relative flex flex-col items-center justify-center mt-12 space-y-4">
          {/* Title */}
          <h1 className="text-xxl font-bold text-center">{getTitle()}</h1>

          {/* Subtitle */}
          <p className="text-xl text-center">{getSubtitle()}</p>
        </div>

        {/* Hamburger Menu */}
        <div
          ref={menuRef}
          className={cn(
            "fixed top-0 right-0 h-full w-3/5 bg-white text-black transform transition-transform duration-300 ease-in-out z-[99]",
            isMenuOpen ? "translate-x-0" : "translate-x-full"
          )}
        >
          <div className="flex flex-col p-6 space-y-6">
            <div className="flex flex-col gap-y-12">
              {/* Navigation Links */}
              <a
                className={cn(
                  `font-barlow-condensed text-xl font-medium cursor-pointer ${getUnderline(
                    "rentals"
                  )}`
                )}
                onClick={() => {
                  setTabGuestWebsite("rentals");
                  closeMenu();
                }}
              >
                {t("GuestWebsite.Header.Nav.rentals")}
              </a>
              {showServices && (
                <a
                  className={cn(
                    `font-barlow-condensed text-xl font-medium cursor-pointer ${getUnderline(
                      "services"
                    )}`
                  )}
                  onClick={() => {
                    setTabGuestWebsite("services");
                    closeMenu();
                  }}
                >
                  {t("GuestWebsite.Header.Nav.services")}
                </a>
              )}
              {showAbout && (
                <a
                  className={cn(
                    `font-barlow-condensed text-xl font-medium cursor-pointer ${getUnderline(
                      "about"
                    )}`
                  )}
                  onClick={() => {
                    setTabGuestWebsite("about");
                    closeMenu();
                  }}
                >
                  {t("GuestWebsite.Header.Nav.about")}
                </a>
              )}
              {showContact && (
                <a
                  className={cn(
                    `font-barlow-condensed text-xl font-medium cursor-pointer ${getUnderline(
                      "contact"
                    )}`
                  )}
                  onClick={() => {
                    setTabGuestWebsite("contact");
                    closeMenu();
                  }}
                >
                  {t("GuestWebsite.Header.Nav.contact")}
                </a>
              )}

              {/* Language Selector */}
              <div className="flex items-center space-x-2">
                {/* <span className="text-lg">🇫🇷</span>
              <span className="text-lg">▼</span> */}
                <span className="text-lg"></span>
                <span className="text-lg"></span>
              </div>
            </div>

            {/* Reservation Button */}
            <button
              className="font-barlow-condensed border border-element-border bg-white text-black text-xl font-medium p-2 rounded shadow hover:bg-gray-200 flex justify-center items-center gap-x-1"
              onClick={() => {
                setTabGuestWebsite("rentals");
                closeMenu();
              }}
            >
              {t("GuestWebsite.book")} <CalendarIcon />
            </button>
          </div>
        </div>
        <SearchBannerComponent isAbsolute />
      </header>
    </div>
  );
};

export default HeaderTemplate2Mobile;
