/**
 * Hook for interact with
 * payment receipt traveler.
 */
import paths from "../../constants/paths";
import { get, post } from "../../helpers/APIHelper";
import { PaymentReceiptsData } from "../../types/GETTypes";
import { ReceiptType } from "../../types/commonTypes";

export const usePaymentReceipt = () => {
  /**
   * Function for get all receipts in
   * reservation by ID.
   * @param reservationId
   */
  const getAll = async (
    reservationId: string,
  ): Promise<PaymentReceiptsData> => {
    const url =
      import.meta.env.VITE_API_URL +
      paths.API.RESERVATION.GET_ALL_RECEIPT(reservationId);
    const response = await get(url);

    if (response?.data?.success) {
      return response.data.result;
    }

    throw new Error(response?.data?.message ?? response.message);
  };

  /**
   * Function for send ticket receipt with SMS
   */
  const sendReceiptWithSms = async (
    reservationId: string,
    subject: string,
    message: string,
    langId: number,
    phoneNumber: string,
    receiptType: ReceiptType,
  ) => {
    const url =
      import.meta.env.VITE_API_URL +
      paths.API.RESERVATION.SEND_RECEIPT_SMS_OR_EMAIL(
        reservationId,
        receiptType,
      );
    const response = await post(url, {
      type: "SMS",
      subject: subject,
      message: message,
      langId: langId,
      contact: phoneNumber,
    });

    if (response?.data?.success) {
      return response.data.result;
    }

    throw new Error(response?.data?.message ?? response.message);
  };

  /**
   * Function for send ticket receipt with Email.
   */
  const sendReceiptWithEmail = async (
    reservationId: string,
    subject: string,
    message: string,
    langId: number,
    email: string,
    receiptType: ReceiptType,
  ) => {
    const url =
      import.meta.env.VITE_API_URL +
      paths.API.RESERVATION.SEND_RECEIPT_SMS_OR_EMAIL(
        reservationId,
        receiptType,
      );
    const response = await post(url, {
      type: "EMAIL",
      subject: subject,
      message: message,
      langId: langId,
      contact: email,
    });

    if (response?.data?.success) {
      return response.data.result;
    }

    throw new Error(response?.data?.message ?? response.message);
  };

  /**
   * Function for send payment with Email.
   */
  const sendPaymentWithEmail = async (
    reservationId: string,
    subject: string,
    message: string,
    langId: number,
    email: string,
    paymentId: number,
  ) => {
    const url =
      import.meta.env.VITE_API_URL +
      paths.API.RESERVATION.SEND_PAYMENT_SMS_OR_EMAIL(reservationId, paymentId);
    const response = await post(url, {
      type: "EMAIL",
      subject: subject,
      message: message,
      langId: langId,
      contact: email,
      paymentId: paymentId,
    });

    if (response?.data?.success) {
      return response.data.result;
    }

    throw new Error(response?.data?.message ?? response.message);
  };

  /**
   * Function for send payment with Email.
   */
  const sendPaymentWithSms = async (
    reservationId: string,
    subject: string,
    message: string,
    langId: number,
    email: string,
    paymentId: number,
  ) => {
    const url =
      import.meta.env.VITE_API_URL +
      paths.API.RESERVATION.SEND_PAYMENT_SMS_OR_EMAIL(reservationId, paymentId);
    const response = await post(url, {
      type: "SMS",
      subject: subject,
      message: message,
      langId: langId,
      contact: email,
      paymentId: paymentId,
    });

    if (response?.data?.success) {
      return response.data.result;
    }

    throw new Error(response?.data?.message ?? response.message);
  };

  /**
   * Function for send payment with Email.
   */
  const sendCautionWithEmail = async (
    reservationId: string,
    subject: string,
    message: string,
    langId: number,
    email: string,
  ) => {
    const url =
      import.meta.env.VITE_API_URL +
      paths.API.RESERVATION.SEND_CAUTION_SMS_OR_EMAIL(reservationId);
    const response = await post(url, {
      type: "EMAIL",
      subject: subject,
      message: message,
      langId: langId,
      contact: email,
    });

    if (response?.data?.success) {
      return response.data.result;
    }

    throw new Error(response?.data?.message ?? response.message);
  };

  /**
   * Function for send payment with Email.
   */
  const sendCautionWithSms = async (
    reservationId: string,
    subject: string,
    message: string,
    langId: number,
    email: string,
  ) => {
    const url =
      import.meta.env.VITE_API_URL +
      paths.API.RESERVATION.SEND_CAUTION_SMS_OR_EMAIL(reservationId);
    const response = await post(url, {
      type: "SMS",
      subject: subject,
      message: message,
      langId: langId,
      contact: email,
    });

    if (response?.data?.success) {
      return response.data.result;
    }

    throw new Error(response?.data?.message ?? response.message);
  };

  /**
   * Return all actions
   */
  return {
    getAll,
    sendReceiptWithEmail,
    sendReceiptWithSms,
    sendPaymentWithEmail,
    sendPaymentWithSms,
    sendCautionWithEmail,
    sendCautionWithSms,
  };
};
